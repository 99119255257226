import { postEntity } from 'api/apiEntity';

const promisePostConfirmation = ({ reqId, userId }) => {
  const onFulfilled = (response) => response;

  const args = {
    type: 'auth/confirmation',
    data: {
      type: 'users',
      attributes: {
        id: userId,
        requisitionOpeningId: reqId,
      },
    },
  };

  return postEntity(args).then(onFulfilled);
};

export default promisePostConfirmation;
