import * as genericCreators from 'shared/store/app/creators';

import * as creators from './creators';
import selector from './selectors';
import reducer from './reducers';

const duck = {
  creators: {
    ...genericCreators,
    ...creators,
  },
  selector,
  reducer,
};

export default duck;
