import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import isEmpty from 'lodash.isempty';
import { Prompt } from 'react-router-dom';
import { push } from 'connected-react-router';

import ConnectContainer from 'containers/ConnectContainer';
import { CandidateAuthHOC } from 'containers/HOC';

import ReadyContainer from 'connectors/ReadyContainer';

import Button from 'components/Button';
import FontIcon from 'components/FontIcon';
import RainCam from 'components/RainCam';
import TableOverlay from 'components/TableOverlay';
import Title from 'components/Title';

import ProgressNavSection from '../components/ProgressNavSection';

import duck from '../redux';

import styles from '../Onboarding.scss';

@CandidateAuthHOC()
class AddVideo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      unsavedData: false,
      uploadComplete: false,
    };
  }

  componentDidMount() {
    const {
      actions: { serverErrorSet, nestedEntityLoadStarted },
      user: { currentProfile: { id: candidateId } = {} } = {},
    } = this.props;

    serverErrorSet({ errors: [] });

    nestedEntityLoadStarted({
      id: candidateId,
      nestedType: 'vimeo_video',
      slice: 'candidateEditor',
      type: 'candidates',
    });
  }

  handleDeleteVideo = () => {
    const {
      actions: { deleteResource },
      vimeoVideos: { byId: vimeoVideosById = {}, allIds: vimeoVideosAllIds = [] },
      user: { currentProfile: { id: candidateId } = {} } = {},
    } = this.props;

    const vimeoVideoId = vimeoVideosAllIds.find(
      (id) => vimeoVideosById[id].attributes.ownerId === candidateId
    );

    if (vimeoVideoId) {
      deleteResource({
        slice: 'candidateEditor',
        type: 'vimeo_videos',
        id: vimeoVideoId,
      });
    }
  };

  render() {
    const { uploadComplete, unsavedData } = this.state;

    const {
      actions: { deleteResource, setAppModalError, addVideoStarted },
      dispatch,
      serverErrors = [],
      user: { currentProfile: { id: candidateId, profileLocked } = {}, isMobile, isSafari } = {},
      vimeoVideos,
      vimeoVideos: { byId: vimeoVideosById = {}, allIds: vimeoVideosAllIds = [] } = {},
    } = this.props;

    const vimeoVideoId = vimeoVideosAllIds.find(
      (id) => vimeoVideosById[id].attributes.ownerId === candidateId
    );

    const { [vimeoVideoId]: { attributes: { vimeoResponse = {} } = {} } = {} } = vimeoVideosById;

    const { embed: { html: __html } = {} } = vimeoResponse || {};

    const serverErrorMessage = serverErrors.length > 0 ? serverErrors[0] : null;

    const serverErrorContent = serverErrorMessage ? (
      <div className={styles.errorMessage}>{serverErrorMessage}</div>
    ) : null;

    const titleProps = {
      string: "Let's add a video introduction",
    };

    const nextButtonProps = {
      circle: true,
      active: __html || uploadComplete,
      onClick: addVideoStarted,
      className: styles.actionButton,
      disabled: !(__html || uploadComplete),
    };

    const prevButtonProps = {
      circle: true,
      onClick: () => dispatch(push('/add-sales-info')),
      className: styles.actionButton,
    };

    const rainCamProps = {
      instructionsOnTop: true,
      deleteResource,
      isMobile,
      isSafari,
      onCompleteCallback: ({ uploadComplete }) => this.setState({ uploadComplete }),
      ownerId: candidateId,
      ownerType: 'Candidate',
      profileLocked,
      setUnsavedData: (state) => this.setState({ unsavedData: state }),
      setAppModalError,
      slice: 'candidateEditor',
    };

    const deleteButtonProps = {
      onClick: this.handleDeleteVideo,
      warning: true,
      className: styles.delete,
      disabled: profileLocked,
    };

    const innerHTML = { __html };

    const embedContent = <div dangerouslySetInnerHTML={innerHTML} />;

    const innerContent = __html ? (
      <div className={styles.playerContainer}>
        {embedContent}
        <Button {...deleteButtonProps}>
          <FontIcon iconName="trash" />
          Discard
        </Button>
      </div>
    ) : (
      <div className={styles.imageContainer}>
        <RainCam {...rainCamProps} />
      </div>
    );

    const skipButtonProps = {
      onClick: () => addVideoStarted({ skippedVideo: true }),
      className: styles.skipButton,
      tertiary: true,
      disabled: unsavedData,
    };

    const skipTextLong = <span className={styles.skipTextLong}>Skip for now</span>;
    const skipTextShort = <span className={styles.skipTextShort}>Skip</span>;

    const skipButtonContent =
      __html || uploadComplete ? null : (
        <Button {...skipButtonProps}>
          {skipTextLong}
          {skipTextShort}
        </Button>
      );

    const mainContent = isEmpty(vimeoVideos) ? (
      <div className={styles.emptyVideo}>
        <TableOverlay table="inPlace" />
      </div>
    ) : (
      innerContent
    );

    return [
      <Helmet key="helmet" title="Add Video" />,

      <ReadyContainer key="AddVideo" className={styles.CandidateRegistration}>
        <div className={styles.prevButtonContainer}>
          <Button {...prevButtonProps}>
            <FontIcon iconName="caret-left" />
          </Button>
        </div>

        <div className={styles.mainContainer}>
          <ProgressNavSection numFilled={4} />

          <Title {...titleProps} />

          {serverErrorContent}

          {mainContent}

          <Prompt
            when={unsavedData}
            message={
              'Are you sure you want to leave this page? You have uploaded a video without submitting and your video will be lost.'
            }
          />
        </div>

        <div className={styles.nextButtonContainer}>
          <Button {...nextButtonProps}>
            <FontIcon className={styles.largeIcon} iconName="checkmark" />
          </Button>

          {skipButtonContent}
        </div>
      </ReadyContainer>,
    ];
  }
}

export default ConnectContainer(duck)(AddVideo);
