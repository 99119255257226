import { all, fork } from 'redux-saga/effects';

import sagaSignupErrorsCheck from './sagaSignupErrorsCheck';
import sagaSignupStarted from './sagaSignupStarted';

const sagaSignup = function* sagaSignup() {
  yield all([fork(sagaSignupErrorsCheck), fork(sagaSignupStarted)]);
};

export default sagaSignup;
