import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import ReadyContainer from 'connectors/ReadyContainer';

import Block from 'components/Block';
import Footer from 'pages/CandidatesHome/components/Footer';

import styles from './EmployerFAQ.scss';

const EmployerFAQ = () => {
  const blockProps = {
    addWhiteBG: true,
    addPadding: true,
  };

  const title = 'Hiring Sales Talent? Learn About Rainmakers | Employer FAQ';
  const metaDescription =
    'Hiring sales talent? Learn how Rainmakers can help. Check out our most frequently asked questions today.';
  const canonicalUrl = `https://${process.env.HOST}/employer-faq/`;

  const helmetProps = {
    key: 'helmet',
    title,
    meta: [
      { name: 'description', content: metaDescription },
      { property: 'og:url', content: canonicalUrl },
      { property: 'og:title', content: title },
      { property: 'og:description', content: metaDescription },
    ],
    link: [{ rel: 'canonical', href: canonicalUrl }],
  };

  return (
    <React.Fragment>
      <Helmet {...helmetProps} />
      <ReadyContainer key="readyContainer" className={styles.EmployerFAQ}>
        <Block {...blockProps}>
          <div className={styles.content}>
            <h1 className={styles.header}>Employer FAQ</h1>

            <div className={styles.question}>
              <span className={styles.bold}>Why should I join Rainmakers?</span>
            </div>

            <div className={styles.answer}>
              Rainmakers offers employers a pool of prequalified, highly skilled salespeople. You
              can view candidates&apos; sales specific profiles as well as employment requirements,
              adding transparency and helping you save time when looking to fill sales roles.
            </div>

            <div className={styles.question}>
              <span className={styles.bold}>How do I join?</span>
            </div>

            <div className={styles.answer}>
              Fill out an application <Link to="/employer-signup">here</Link> with your
              company&apos;s details and we will be in touch upon review.
            </div>

            <div className={styles.question}>
              <span className={styles.bold}>How much do I have to pay?</span>
            </div>

            <div className={styles.answer}>
              Rainmakers offers various plans to fit the needs of all companies.
            </div>

            <div className={styles.question}>
              <span className={styles.bold}>I am a not for profit, can I use Rainmakers?</span>
            </div>

            <div className={styles.answer}>
              We love to help out not for profits. Get in touch with us!
            </div>

            <div className={styles.supportText}>
              Contact us at
              <span className={styles.contact}>
                <a
                  href="mailto:support@rainmakers.co"
                  className={styles.mailLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {' '}
                  support@rainmakers.co{' '}
                </a>
              </span>
              with any of your unanswered questions.
            </div>
          </div>
        </Block>
        <Footer />
      </ReadyContainer>
    </React.Fragment>
  );
};

export default EmployerFAQ;
