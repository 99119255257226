/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import jobsPageMetaData, {
  JOB_PAGE_AE,
  JOB_PAGE_CSM,
  JOB_PAGE_EAE,
  JOB_PAGE_MMAE,
  JOB_PAGE_SD,
  JOB_PAGE_SDR,
} from 'pages/JobsPage/jobsPageMetaData';
import marketsJobsPageMetaData, {
  MK_JB_SDR_CHI_PAGE,
  MK_JB_AE_CHI_PAGE,
} from 'pages/MarketsJobsPage/marketsJobsPageMetaData';

import { getAltName } from 'utils/urlUtils';

import pic1 from './assets/1-Chicago-Tech-Sales-Recruiters.png';
import pic2 from './assets/2-Chicago-Tech-Industry-Sales-Recruiters.png';
import pic3 from './assets/3-Chicago-Technology-Sales-Headhunters.png';
import cta from './assets/4-Contact-Sales-Recruitment-Agency.png';

import styles from './ChicagoContent.scss';

export function ChicagoContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt={getAltName(pic1)} />

      <h2>Careers in Technical Sales</h2>

      <p>Chicago is a legendary city.</p>

      <p>
        And thanks to the combined growth of tech businesses and a rich sales environment, Chicago
        is an excellent city to launch and nurture an ambitious career in SaaS, fintech, and
        innovative tech sales.
      </p>

      <p>
        As tech companies abandon the high costs of living in coastal states, they find Chicago
        wonderfully supportive. Compared to New York and California, Illinois’ flat-rate income tax
        is advantageous to most technology workers, and the cost of office space in this state is
        around half that of San Francisco. With a lower cost of living compared to other tech sales
        hubs, it’s not surprising Chicago has experienced nearly 20% growth in its tech sector over
        the past few years.
      </p>

      <h2>Life In Chicago</h2>

      <p>
        Chicago is the third-most populous city in the United States and the most populous city in
        Illinois. The Chicago metropolitan area, one of the largest urban areas in the world, is a
        great place to live and is regarded as one of the best places to live in Illinois.
      </p>

      <p>
        Chicago enjoys a rich history and a distinctive, friendly culture.{' '}
        <strong>
          It’s a city with some of the best food in the country, the home to legendary sports teams,
          and an essential hub of American art, music, and comedy.
        </strong>
      </p>

      <p>
        Located in the Great Lakes and Midwest regions of the United States, Chicago provides its
        residents with first-rate amenities and dependable public transportation to help them adjust
        to city life. Tech sales specialists who find themselves in Chicago quickly realize what a
        welcoming, supportive city it is.
      </p>

      <img src={pic2} alt={getAltName(pic2)} />

      <h2>Tech and Financial Growth In Chicago</h2>

      <p>
        According to the Chicago Tech Effect report from the Chicagoland Chamber of Congress,
        <strong>
          {' '}
          Chicago’s technology environment has grown by 18% over the past ten years.
        </strong>{' '}
        Chicago’s tech sector supports over 8% of the city’s workforce and accounts for 87% of all
        new jobs created in the region over the past ten years.
      </p>

      <p>
        With the median wage in the tech ecosystem 1.5 times higher than the median wage for the
        entire economy, Chicago is fueling upward mobility and better economic equality than many
        other regions in the US. Many people find Chicago particularly welcoming because nearly half
        of Chicago’s tech sector hires are in non-technical roles such as sales and support.
      </p>

      <h2>Sales Opportunities In Chicago</h2>

      <p>
        In Chicago, there are openings at every level of the technology sales industry. Rainmakers
        matches exceptional applicants with companies that are the most compatible with their
        skills. This way, everyone comes out ahead.
      </p>

      <h3>Sales Development Representative</h3>

      <p>
        Good SaaS sales teams rely on the contributions of their{' '}
        <a href={jobsPageMetaData[JOB_PAGE_SDR].localUrl} target="_blank" rel="noreferrer">
          Sales Development Representatives (SDRs)
        </a>
        . An SDR mainly performs the duties of an inside salesperson, focusing on cold calling to
        create sales pipelines for the rest of the team. Their position is typically entry-level,
        doesn’t require much initial expertise, and serves as a great starting point for a long,
        successful sales career.
      </p>

      <h3>Account Executive</h3>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_AE].localUrl} target="_blank" rel="noreferrer">
          Account Executives (AE)
        </a>{' '}
        close deals with new clients, attend to the needs of existing clients, and draw in new ones.
        AEs strive to fulfill predetermined quotas and revenue targets, with commissions accounting
        for a sizeable amount of their remuneration.
      </p>

      <h3>Mid-Market Account Executive</h3>

      <p>
        This is the ideal next step for sales professionals who are passionate about their jobs and
        seeking to advance them.{' '}
        <a href={jobsPageMetaData[JOB_PAGE_MMAE].localUrl} target="_blank" rel="noreferrer">
          Mid-Market Account Executives
        </a>{' '}
        use SaaS/cloud technology to manage training, assist sales growth, and provide services
        while facilitating sales, closing agreements, and upholding solid client relationships.
        Middle-market businesses are their primary source of new sales.
      </p>

      <img src={pic3} alt={getAltName(pic3)} />

      <h3>Enterprise Account Executive</h3>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_EAE].localUrl} target="_blank" rel="noreferrer">
          Enterprise Account Executives
        </a>{' '}
        serve manufacturers, software developers, telecommunications businesses, and retail chains.
        The level of responsibilities that come with this position makes it one with high earning
        potential. Enterprise Account Executives must be committed, capable, and well-prepared for
        businesses to maintain positive sales partnerships and promote innovation on both ends.
      </p>

      <h3>Customer Success Manager</h3>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_CSM].localUrl} target="_blank" rel="noreferrer">
          Customer Success Managers (CSMs)
        </a>{' '}
        strive to build solid, enduring client connections. Businesses must demonstrate the worth of
        their products after concluding a sale, and it’s up to the Customer Success Manager to
        encourage success and repeat business by promoting goods and services. CSMs are used to
        great effect by nearly half of high-tech enterprises, despite being a relatively new type of
        sales function.
      </p>

      <h3>Sales Director</h3>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_SD].localUrl} target="_blank" rel="noreferrer">
          Sales Directors
        </a>{' '}
        ensure that the sales force completes all necessary responsibilities, creates and offers new
        products and services strategically, and upholds the company’s commitment to providing
        excellent customer service. Sales Directors oversee the sales staff, conduct training, and
        ensure that staff achieves their goals. Strong organizational, leadership, and sales skills
        are required.
      </p>

      <Link to="/apply-now" title="Apply Now">
        <img src={cta} alt={getAltName(cta)} />
      </Link>

      <h2>Sales Career Opportunities at Rainmakers</h2>
      <p>
        Successful sales careers often pay well and offer substantial opportunities for personal
        growth. For individuals willing to put in the time and effort required to exceed
        expectations and prove their value, many career prospects provide good avenues for
        promotion.
      </p>
      <p>
        The sales funnel of any successful organization depends on every role, from Sales
        Development Rep to VP in Sales. Anyone can succeed if they put in the necessary effort, have
        the required talents, and are dedicated to the task at hand.
      </p>
      <p>
        If you're curious about tech sales jobs in high demand in Chicago, make sure to check out
        these existing opportunities.
      </p>
      <ul>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={marketsJobsPageMetaData[MK_JB_AE_CHI_PAGE].localUrl}
          >
            {' '}
            Account Executive Jobs
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={marketsJobsPageMetaData[MK_JB_SDR_CHI_PAGE].localUrl}
          >
            {' '}
            Sales Development Representative Jobs
          </a>
        </li>
      </ul>

      <p>
        Create a profile on Rainmakers right away to find out more about these fantastic
        opportunities and{' '}
        <a href={`https://${process.env.HOST}/apply-now/`}>
          Start connecting with Chicago tech employers
        </a>
        .
      </p>
    </div>
  );
}
