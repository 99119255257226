import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash.isempty';

import { handleError } from 'utils/common';

import { promiseGetCohort } from 'pages/AdminPortal/ToolboxCohortEditorPage/promises';

import TableOverlay from 'components/TableOverlay';

import styles from './ContributingCohorts.scss';

class ContributingCohorts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      loading: false,
      cohorts: [],
      didEnter: false,
    };
  }

  onCohortSuccess = (cohort) => {
    const { cohortIds = [] } = this.props;

    const { cohorts } = this.state;

    const newCohorts = cohorts.slice();

    newCohorts.push(cohort);

    const numCohorts = cohortIds.length;

    const doneLoading = numCohorts.length === newCohorts.length;

    this.setState({
      cohorts: newCohorts,
      loading: doneLoading,
    });
  };

  onCohortFail = (error) => {
    handleError(error);
    this.setState({
      loading: false,
    });
  };

  handleOnMouseEnter = () => {
    const { didEnter } = this.state;

    if (didEnter) {
      return null;
    }

    const { cohortIds } = this.props;

    if (!cohortIds || (cohortIds && cohortIds.length === 0)) {
      return null;
    }

    this.setState({
      active: true,
      loading: true,
      didEnter: true,
    });

    return (
      cohortIds &&
      cohortIds.forEach((cohortId) => {
        promiseGetCohort({ cohortId }).then(this.onCohortSuccess).catch(this.onCohortFail);
      })
    );
  };

  render() {
    const { cohortIds, router, modal, showModal } = this.props;

    const { cohorts, loading, active } = this.state;

    const numCohorts = (cohortIds && cohortIds.length) || '0';

    const cohortsContent = loading ? (
      <div className={styles.cohortsLoadingContainer}>
        <TableOverlay table="inPlace" />
      </div>
    ) : (
      cohorts.map((cohort) => {
        const { name, category, id } = cohort;

        const openModal =
          modal && router && router.location && router.location.state
            ? () =>
                showModal({
                  key: 'CohortEditorModal',
                  originalUrl: modal.originalUrl,
                  parent: router.location.state.parent,
                  route: `/toolbox/cohort/${cohort.id}/edit-main`,
                  backPath: router.location.pathname,
                  backKey: 'EmployerEditorModal',
                })
            : null;

        const linkProps = isEmpty(modal)
          ? {
              to: `/toolbox/cohort/${cohort.id}/edit-main`,
            }
          : {
              onClick: openModal,
            };

        const categoryString = category === 'search' ? 'S' : 'F';

        return (
          <div key={id} className={styles.cohortsHoverContainer}>
            <Link {...linkProps}>
              {name}
              <div className={styles.cohortType}>{categoryString}</div>
            </Link>
          </div>
        );
      })
    );

    const cohortsContentFinal = active ? (
      cohortsContent
    ) : (
      <div className={styles.cohortNum}>{numCohorts} contributing</div>
    );

    const cohortContainerProps = {
      className: styles.cohortsContainer,
      onMouseEnter: this.handleOnMouseEnter,
      onMouseLeave: this.handleOnMouseLeave,
    };

    return <div {...cohortContainerProps}>{cohortsContentFinal}</div>;
  }
}

export default ContributingCohorts;
