import React from 'react';

import Block from 'components/Block';

import styles from '../../ToolboxRequisitionEditor.scss';

const ESQuery = ({ query, ReactJson }) => {
  const blockProps = {
    addWhiteBG: true,
    boxShadow: true,
    largeTitleFont: true,
    className: styles.esQueryBlock,
  };

  const reactJsonProps = {
    src: query,
    theme: 'apathy:inverted',
    iconStyle: 'triangle',
    displayObjectSize: false,
    displayDataTypes: false,
  };

  const content = ReactJson ? <ReactJson {...reactJsonProps} /> : null;

  return (
    <div className={styles.ReqForm}>
      <Block {...blockProps}>{content}</Block>
    </div>
  );
};

export default ESQuery;
