import React from 'react';

import { handleError } from 'utils/common';
import { patchEntity } from 'api/apiEntity';
import { VisibleHOC } from 'containers/HOC';

import DropdownPane from 'components/DropdownPane';
import VerificationContent from 'components/VerificationContent';

const ToolboxCompanyActionDropdown = ({
  unverifiedData: { id, attributes: { name, webSite, picture = {} } = {} } = {},
  actions: { resourceUpdate, resourceDelete },
  company,
  isVisible,
  refreshCallback,
  sliceToUpdate = 'toolboxCompanies',
}) => {
  const verifyOrRejectCompany = async (verify) => {
    const attrs = company.attributes || company;

    if (verify) {
      resourceUpdate({
        slice: sliceToUpdate,
        type: 'companies',
        id: company.id,
        resource: {
          attributes: {
            ...attrs,
            webSite: webSite || company.attributes.webSite,
            picture: picture || company.attributes.picture,
          },
        },
      });
    }

    try {
      await patchEntity({
        id,
        type: 'unverified_company_data',
        data: {
          attributes: {
            state: verify ? 'verified' : 'rejected',
          },
          id,
          type: 'unverified_company_data',
        },
        config: {
          params: {
            camelizeIt: true,
          },
        },
      });
    } catch (error) {
      handleError(error);
    }

    if (refreshCallback) {
      refreshCallback();
    }

    resourceDelete({
      id,
      slice: sliceToUpdate,
      type: 'unverifiedCompanyData',
    });
  };

  return (
    <DropdownPane
      above={!isVisible}
      element={
        <VerificationContent
          name={name}
          picture={picture}
          webSite={webSite}
          editCompanyLink={`/toolbox/company/${company.id}/edit-profile`}
          onVerify={() => verifyOrRejectCompany(true)}
          onReject={() => verifyOrRejectCompany(false)}
        />
      }
      label="Actions"
      left={true}
    />
  );
};

export default VisibleHOC()(ToolboxCompanyActionDropdown);
