import React from 'react';

import Content from 'components/Content';
import ContentTitle from 'components/ContentTitle';
import FormattedArray from 'components/FormattedArray';

import styles from './Opportunity.scss';

const Opportunity = ({ requisitionOpening: { attributes: { opportunities } = {} } = {} }) => {
  const props = { array: opportunities };

  return opportunities && opportunities.length > 0 && opportunities.every((opp) => opp !== '') ? (
    <div className={styles.Opportunity}>
      <ContentTitle>WHY THIS IS A GREAT OPPORTUNITY</ContentTitle>
      <Content className={styles.OpportunityContent}>
        <FormattedArray {...props} />
      </Content>
    </div>
  ) : null;
};

export default Opportunity;
