import { call, put, select, cancel, take, fork } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { handleError } from 'utils/common';

import { patchEntity } from 'api/apiEntity';

import { selectCandidate } from '../selectors';

import { SALES_PERIOD_PATCH_STARTED } from '../actions';

import {
  candidateSaveStarted,
  candidateSaveDone,
  candidateSaveError,
  workHistoryPatchDone,
} from '../creators';

const TYPES = {
  AESalesPeriod: 'ae_sales_period',
  CSMSalesPeriod: 'csm_sales_period',
};

export const patchSalesPeriod = function* patchSalesPeriod(action) {
  const {
    payload: { newValue, name, workHistoryId, salesPeriodId },
  } = action;

  let type = null;

  const { workHistories } = yield select(selectCandidate);

  const [workHistoryToUpdate] = workHistories.filter((wh) => wh.id === workHistoryId);

  const newSalesPeriods = workHistoryToUpdate.salesPeriods.map((sp) => {
    if (sp.id === salesPeriodId) {
      const { type: typeFound } = sp;
      type = typeFound;

      return {
        ...sp,
        [name]: newValue,
      };
    }

    return sp;
  });

  const newWorkHistory = {
    ...workHistoryToUpdate,
    salesPeriods: newSalesPeriods,
  };

  const newWorkHistories = workHistories.map((wh) => {
    if (wh.id === workHistoryId) {
      return newWorkHistory;
    }
    return wh;
  });

  yield put(workHistoryPatchDone({ newWorkHistories }));
  yield put(candidateSaveStarted());

  yield call(delay, 500);

  const configKey = TYPES[type];

  const attributes = {
    [name]: newValue,
  };

  try {
    const putSalesPeriodsArgs = {
      type: `${configKey}s`,
      data: {
        type: configKey,
        attributes,
        id: salesPeriodId,
      },
      id: salesPeriodId,
      config: {
        params: {
          camelizeIt: true,
        },
      },
    };

    yield call(patchEntity, putSalesPeriodsArgs);

    yield put(candidateSaveDone());
  } catch (error) {
    handleError(error);
    yield put(candidateSaveError(error));
  }
};

const sagaSalesPeriodPatchStarted = function* sagaSalesPeriodPatchStarted() {
  let lastTask;
  let lastAction;
  while (true) {
    const action = yield take(SALES_PERIOD_PATCH_STARTED);
    if (
      lastTask &&
      lastAction.payload.name === action.payload.name &&
      lastAction.payload.salesPeriodId === action.payload.salesPeriodId
    ) {
      yield cancel(lastTask);
    }
    lastAction = action;
    lastTask = yield fork(patchSalesPeriod, action);
  }
};

export default sagaSalesPeriodPatchStarted;
