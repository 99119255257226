import { createSelector } from 'reselect';
import { getApp } from 'shared/store/app/selectors';

const selectUser = createSelector(getApp, (state) => state.user);

const selectCandidate = createSelector(selectUser, (user = {}) => user.currentProfile);

const selectProfileLocked = createSelector(
  selectCandidate,
  (candidate = {}) => candidate.profileLocked
);

export default selectProfileLocked;
