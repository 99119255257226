import React, { Component } from 'react';

import { handleError } from 'utils/common';

import EmployeeImageUploader from 'components/EmployeeImageUploader';
import Block from 'components/Block';
import Button from 'components/Button';
import ContentTitle from 'components/ContentTitle';
import Input from 'components/Input';
import Label from 'components/Label';

import EmailChangeModal from 'pages/CandidatePortal/CandidateEditorPages/EditPersonal/components/EmailChangeModal';

import { promiseEmailValid } from 'pages/CandidatePortal/CandidateEditorPages/promises';

import styles from '../../EditEmployee.scss';

class Personal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEmailChangeModal: false,
      emailError: null,
    };
  }

  updateEmailStart = ({ password, newEmail }) => {
    const {
      actions: { patchUserEmail },
      employee: { relationships: { user: { data: userIds = [] } = {} } = {} } = {},
    } = this.props;

    this.setState({ emailError: null });

    promiseEmailValid({ email: newEmail })
      .then((response) => {
        if (response.email) {
          this.setState({ emailError: 'Email has been taken' });
        } else {
          patchUserEmail({
            attributes: {
              password,
              email: newEmail,
            },
            id: userIds[0],
            slice: 'employerEditor',
          });
          this.setState({ showEmailChangeModal: false });
        }
      })
      .catch(handleError);
  };

  render() {
    const { showEmailChangeModal, emailError } = this.state;

    const {
      actions: { patchUserResource },
      employee: {
        attributes: {
          jobTitle,
          phoneNumber,
          firstName,
          lastName,
          picture: { xxlarge: pictureUrl = '' } = {},
        } = {},
        id: employeeId,
        relationships: { user: { data: userIds = [] } = {} } = {},
      } = {},
      employeeErrors = {},
      handleEmployeeInputChange,
      handleUserTextInputChange,
      user: { currentProfileId } = {},
      userErrors = {},
      users: { byId: usersById = {} } = {},
    } = this.props;

    const { firstName: firstNameErrors, lastName: lastNameErrors } = employeeErrors;

    const { email: emailErrors } = userErrors;

    const { attributes: { email } = {} } = usersById[userIds[0]] || {};

    const genInfoBlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      largeTitleFont: true,
      addPadding: true,
    };

    const commonUserInputProps = {
      type: 'text',
      size: 'xxlarge',
      disabled: false,
      required: true,
      handleInputChange: handleUserTextInputChange,
    };

    const commonEmployeeInputProps = {
      handleInputChange: handleEmployeeInputChange,
      type: 'text',
      size: 'xxlarge',
    };

    const firstNameInputProps = {
      ...commonEmployeeInputProps,
      required: true,
      name: 'firstName',
      value: firstName || '',
      label: 'First Name',
      size: 'full',
      placeholder: '',
      errors: firstName !== null ? firstNameErrors : {},
    };

    const lastNameInputProps = {
      ...commonEmployeeInputProps,
      required: true,
      name: 'lastName',
      value: lastName || '',
      label: 'Last Name',
      size: 'full',
      placeholder: '',
      errors: lastName !== null ? lastNameErrors : {},
    };

    const phoneInputProps = {
      ...commonEmployeeInputProps,
      name: 'phoneNumber',
      value: phoneNumber || '',
      label: 'Phone #',
      placeholder: 'Area code first',
      type: 'tel',
      size: 'phone',
    };

    const jobTitleInputProps = {
      ...commonEmployeeInputProps,
      name: 'jobTitle',
      value: jobTitle || '',
      label: 'Job Title',
      placeholder: 'Recruiter, e.g.',
      size: 'full',
    };

    const imageUploaderProps = {
      employeeId,
      pictureUrl,
      employeeImageUploadStarted: ({ picture }) =>
        patchUserResource({
          attributes: {
            picture: {
              file_data: picture,
              large: picture,
              medium: picture,
              original: picture,
              small: picture,
              thumb: picture,
              xlarge: picture,
              xxlarge: picture,
            },
          },
          id: employeeId,
          slice: 'employerEditor',
          type: 'employees',
        }),
    };

    const emailInputProps = {
      ...commonUserInputProps,
      name: 'email',
      value: email || '',
      label: 'Email',
      size: 'xlarge',
      placeholder: '',
      autoFocus: true,
      className: styles.firstInput,
      errors: email !== null ? emailErrors : {},
    };

    const emailChangeModalProps = {
      updateEmailStart: this.updateEmailStart,
      handleCloseDialogue: () => this.setState({ showEmailChangeModal: false }),
      emailError,
    };

    const emailChangeModalContent = showEmailChangeModal ? (
      <EmailChangeModal {...emailChangeModalProps} />
    ) : null;

    const updateEmailButtonProps = {
      tertiaryThin: true,
      onClick: () => this.setState({ showEmailChangeModal: true }),
    };

    const emailInput =
      employeeId && currentProfileId === employeeId ? (
        <div className={styles.emailContainer}>
          <Label standalone>Email Address</Label>
          <div className={styles.emailContent}>
            {email}
            <Button {...updateEmailButtonProps}>Update</Button>
          </div>
        </div>
      ) : (
        <Input {...emailInputProps} />
      );

    return (
      <div className={styles.scrollDiv}>
        <Block {...genInfoBlockProps}>
          <ContentTitle>General</ContentTitle>
          <div className={styles.blockFlex}>
            <EmployeeImageUploader {...imageUploaderProps} />
            <div className={styles.inputsContainer}>
              {emailInput}
              <div className={styles.nameInputs}>
                <Input {...firstNameInputProps} />
                <Input {...lastNameInputProps} />
              </div>
              <div className={styles.nameInputs}>
                <Input {...jobTitleInputProps} />
                <Input {...phoneInputProps} />
              </div>
            </div>
          </div>
        </Block>
        {emailChangeModalContent}
      </div>
    );
  }
}

export default Personal;
