import { makeLikeParam } from 'utils/paramUtils';
import { getEntity } from 'api/apiEntity';

const promiseRequisitionOpenings = ({
  employerId,
  includeSet,
  page,
  requisitionCandidateId,
  requisitionName,
  sortBy,
  state,
  suggestionSetId,
}) => {
  const params = {
    employerId,
    includeSet,
    page,
    requisitionCandidateId,
    sortBy,
    state,
    suggestionSetId,
  };

  if (requisitionName) {
    params.externalName = makeLikeParam(requisitionName);
  }

  const onFulfilled = (response) => {
    const {
      data: {
        requisition_openings: requisitionDetails = [],
        meta: {
          pagination: { current_page: page, total_count: total, total_pages: totalPages },
        },
      },
    } = response;

    return {
      requisitionDetails,
      page,
      total,
      totalPages,
    };
  };

  const args = {
    type: 'requisition_openings',
    config: {
      params,
    },
  };

  return getEntity(args).then(onFulfilled);
};

export default promiseRequisitionOpenings;
