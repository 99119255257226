/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';

import navMetaData from 'pages/LocationsPage/locationsPageMetaData';
import styles from './TopMarketsLinks.scss';

export function TopMarketsLinks() {
  return (
    <div className={styles.container}>
      <h4>Top markets</h4>
      <div>
        {Object.values(navMetaData).map((meta) =>
          meta.sideNav ? (
            meta.localUrl ? (
              <div key={meta.sideNavName}>
                <Link to={meta.localUrl}>
                  <div className={styles.itemLink}>{meta.sideNavName}</div>
                </Link>
              </div>
            ) : (
              <div className={styles.itemLink}>{meta.sideNavName}</div>
            )
          ) : null
        )}
      </div>
    </div>
  );
}
