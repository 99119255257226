import { createSelector } from 'reselect';
import { entitySelectorFactory } from 'reduxStore/selectorFactories';

const selectRequisitionOpening = createSelector(
  entitySelectorFactory('toolboxRequisitions', 'requisitionOpening'),
  (state, props) => {
    const {
      match: { params: { id } = {} },
    } = props || {};

    return id;
  },
  (requisitionOpenings, id) => {
    const { byId: { [id]: requisitionOpening = {} } = {} } = requisitionOpenings;

    return requisitionOpening;
  }
);

export default selectRequisitionOpening;
