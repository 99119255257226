import { createSelector } from 'reselect';
import { getApp } from 'shared/store/app/selectors';

const selectCandidateEditor = createSelector(getApp, (state) => state.candidateEditor);

const selectCandidateSaving = createSelector(
  selectCandidateEditor,
  (editor = {}) => editor.candidateSaving
);

export default selectCandidateSaving;
