import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';

import Block from 'components/Block';
import EducationOrgImageUploader from 'components/EducationOrgImageUploader';
import Input from 'components/Input';
import Button from 'components/Button';

import Validator from 'connectors/Validator';
import selectImageUploadCompany from 'connectors/App/redux/selectors/selectImageUploadCompany';

import { employerInputChange } from 'utils/formHelpers/EmployerHelpers';
import { handleError } from 'utils/common';

import { savingSelectorFactory } from 'reduxStore/selectorFactories';

import { patchResource, resourceUpdate, savingUpdate } from 'shared/store/app/creators';

import { patchResource as patchResourceApi } from 'api/apiResource';
import patchCandidateRefreshTriggers from 'api/apiCandidates/patchCandidateRefreshTriggers';

import styles from './InlineEducationEditor.scss';

const InlineEducationEditor = ({
  unverifiedData,
  onVerify = () => {},
  onReject = () => {},
  publicProfileId,
}) => {
  const company = useSelector(selectImageUploadCompany) || {};
  const attributes = company.attributes || company || {};

  const {
    id: companyId,
    name: origCompanyName,
    webSite: origCompanyWebSite,
    picture: { xlarge: pictureUrl } = {},
  } = attributes;

  const saving = useSelector(savingSelectorFactory('toolboxEducationOrgs'));

  const dispatch = useDispatch();

  const { attributes: { name: suggestedName, webSite: suggestedWebSite, picture = {} } = {} } =
    unverifiedData || {};
  const { xlarge: url } = picture;

  const [companyNameError, setCompanyNameError] = useState({});
  const [companyWebsiteError, setCompanyWebsiteError] = useState({});

  const [errorSavingCompany, setErrorSavingCompany] = useState(false);

  const hasErrors =
    Object.keys(companyWebsiteError).length > 0 || Object.keys(companyNameError).length > 0;

  const [companyName, setCompanyName] = useState(origCompanyName);
  const [website, setWebsite] = useState(origCompanyWebSite);

  useEffect(() => {
    setCompanyName(origCompanyName);
  }, [origCompanyName]);

  useEffect(() => {
    setWebsite(origCompanyWebSite);
  }, [origCompanyWebSite]);

  const handleCompanyInputChange = (event) => {
    const { newValue, name } = employerInputChange(event, attributes);

    if (name === 'website') {
      if (newValue === '' || Validator.isUrl({ newValue })) {
        setCompanyWebsiteError({});
      } else {
        setCompanyWebsiteError({ isUrl: [false] });
        return;
      }

      setWebsite(newValue);
    } else if (name === 'name') {
      if (!Validator.isNotEmpty({ newValue })) {
        setCompanyNameError({ isNotEmpty: [false] });
      } else {
        setCompanyNameError({});
      }

      setCompanyName(newValue);
    }
  };

  const onSave = useCallback(() => {
    const slice = 'toolboxEducationOrgs';
    const type = 'education_organizations';
    dispatch(savingUpdate({ saving: true, slice }));

    const args = {
      config: {
        params: {
          normalizeIt: true,
        },
      },
      data: {
        attributes: {
          name: companyName,
          webSite: website,
        },
        id: companyId,
        slice,
        type,
      },
      id: companyId,
      slice,
      type,
    };

    setErrorSavingCompany(false);
    patchResourceApi(args)
      .then(() => {
        if (publicProfileId) {
          patchCandidateRefreshTriggers(publicProfileId).catch(handleError);
        }
      })
      .catch((error) => {
        console.error('error', error);

        const {
          request: { status },
        } = error;

        if (status !== 200) {
          setErrorSavingCompany(true);

          dispatch(
            resourceUpdate({
              slice: 'toolboxCandidates',
              type: 'education_organizations',
              id: companyId,
              resource: {
                attributes: {
                  ...attributes,
                  name: origCompanyName,
                  webSite: origCompanyWebSite,
                },
              },
            })
          );
        }
      })
      .finally(() => {
        dispatch(savingUpdate({ saving: false, slice }));
      });

    dispatch(
      resourceUpdate({
        slice: 'toolboxCandidates',
        type: 'education_organizations',
        id: companyId,
        resource: {
          attributes: {
            ...attributes,
            name: companyName,
            webSite: website,
          },
        },
      })
    );
  }, [
    attributes,
    companyId,
    companyName,
    dispatch,
    origCompanyName,
    origCompanyWebSite,
    publicProfileId,
    website,
  ]);

  const handleCompanyImageUploadStarted = useCallback(
    ({ picture }) => {
      dispatch(
        patchResource({
          attributes: {
            ...attributes,
            picture: {
              file_data: picture,
              large: picture,
              medium: picture,
              original: picture,
              small: picture,
              thumb: picture,
              xlarge: picture,
              xxlarge: picture,
            },
          },
          id: companyId,
          normalizeIt: true,
          requiredFields: ['name'],
          slice: 'toolboxEducationOrgs',
          type: 'education_organizations',
          successCallback: () => {
            if (publicProfileId) {
              patchCandidateRefreshTriggers(publicProfileId).catch(handleError);
            }
          },
        })
      );

      dispatch(
        resourceUpdate({
          slice: 'toolboxCandidates',
          type: 'education_organizations',
          id: companyId,
          resource: {
            attributes: {
              ...attributes,
              picture: {
                file_data: picture,
                large: picture,
                medium: picture,
                original: picture,
                small: picture,
                thumb: picture,
                xlarge: picture,
                xxlarge: picture,
              },
            },
          },
        })
      );
    },
    [attributes, companyId, dispatch, publicProfileId]
  );

  return (
    <div>
      {unverifiedData && (
        <Block boxShadow addPadding title="Unverified Education Org Data" addChildFlex>
          <div className={styles.unverifiedContentContainer}>
            <div>
              Does this information look correct? If so, click verify. If not, click reject and
              manually input the proper info.
            </div>
            <div className={styles.actions}>
              <Button primary onClick={onVerify}>
                Verify
              </Button>
              <Button warning onClick={onReject}>
                Reject
              </Button>
            </div>
            <div className={styles.suggestedFieldsContainer}>
              <div className={styles.suggestedField}>
                <h4>Suggested logo</h4>
                <img className={styles.unverifiedImage} src={url} alt="unverified pic" />
              </div>
              <div className={styles.suggestedField}>
                <h4>Suggested name</h4>
                <div>{suggestedName || 'not set'}</div>
              </div>
              <div className={styles.suggestedField}>
                <h4>Suggested website</h4>
                <div>{suggestedWebSite || 'not set'}</div>
              </div>
            </div>
          </div>
        </Block>
      )}
      <Block boxShadow addPadding title="Education Organization Profile" addChildFlex>
        <div className={styles.containerFlexRow}>
          <EducationOrgImageUploader
            companyId={null} // get education org id from redux
            pictureUrl={pictureUrl}
            educationOrgImageUploadStarted={handleCompanyImageUploadStarted}
          />
          <div className={styles.containerFlexColumn}>
            <div className={styles.basicInfoTop}>
              <Input
                placeholder="Institution Name"
                size="full"
                name="name"
                value={companyName || ''}
                label="Institution Name"
                handleInputChange={handleCompanyInputChange}
                handleOnBlur={handleCompanyInputChange}
                topLabel={true}
                required={true}
                className={cn(styles.noPadding, styles.inputField)}
                errors={companyNameError}
                showErrorsImmediately
              />
              <Input
                placeholder="Website"
                size="full"
                name="website"
                value={website || ''}
                label="Website"
                handleInputChange={handleCompanyInputChange}
                handleOnBlur={handleCompanyInputChange}
                topLabel={true}
                className={cn(styles.noPadding, styles.inputField)}
                errors={companyWebsiteError}
              />
            </div>
          </div>
        </div>
        <Button
          primary
          onClick={onSave}
          className={styles.saveButton}
          disabled={saving || hasErrors}
        >
          {saving ? 'Saving...' : 'Save'}
        </Button>
        {errorSavingCompany && <div className={styles.inputError}>Error saving education org</div>}
      </Block>
    </div>
  );
};

export default InlineEducationEditor;
