import { createSelector } from 'reselect';
import { getApp } from 'shared/store/app/selectors';

const selectEmployeeEditor = createSelector(getApp, (state) => state.employerEditor);

const selectEmployeeServerError = createSelector(
  selectEmployeeEditor,
  (editor = {}) => editor.employeeServerError
);

export default selectEmployeeServerError;
