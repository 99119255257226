import React from 'react';

import Button from 'components/Button';

const CohortActionDropdown = ({ cohortEmployerId, deleteResource }) => {
  const removeButtonProps = {
    onClick: () =>
      deleteResource({
        slice: 'toolboxEmployers',
        type: 'cohort_employers',
        id: cohortEmployerId,
      }),
    warning: true,
  };

  return <Button {...removeButtonProps}>Remove</Button>;
};

export default CohortActionDropdown;
