import { createSelector } from 'reselect';
import { entitySelectorFactory } from 'reduxStore/selectorFactories';

const selectRequisitionOpening = createSelector(
  entitySelectorFactory('employerRequisitions', 'requisitionOpening'),
  (state, props) => {
    const {
      match: { params: { id, reqCandId } = {} },
    } = props || {};

    return { id, reqCandId };
  },
  (requisitionOpenings, { id, reqCandId }) => {
    const { byId, byId: { [id]: requisitionOpening = {} } = {} } = requisitionOpenings;

    if (reqCandId) {
      const requisitionOpeningId = Object.keys(byId).find(
        (id) => byId[id].attributes.requisitionCandidateId === reqCandId
      );

      return byId[requisitionOpeningId];
    }

    return requisitionOpening;
  }
);

export default selectRequisitionOpening;
