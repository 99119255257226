import React from 'react';
import classnames from 'classnames';

import styles from './Layout.scss';

const Layout = ({ children, className }) => {
  const layoutClasses = classnames(styles.Layout, className);

  return <div className={layoutClasses}>{children}</div>;
};

export default Layout;
