import { createSelector } from 'reselect';
import { entitySelectorFactory } from 'reduxStore/selectorFactories';
import defaultErrors from 'reduxStore/errors';

const selectEmployerErrors = createSelector(
  entitySelectorFactory('toolboxEmployers', 'employer'),
  (state, props) => {
    const { match: { params: { id } = {} } = {} } = props || {};

    return id;
  },
  (employers, id) => {
    const { byId: { [id]: { errors } = {} } = {} } = employers;

    return errors || defaultErrors.employers;
  }
);

export default selectEmployerErrors;
