import { post } from '../api';

const postCandidateBullhornInfo = (candidateId) =>
  post('/bullhorn_pushes', {
    data: {
      type: 'bullhorn_push',
      attributes: {
        candidate_id: candidateId,
      },
    },
  });

export default postCandidateBullhornInfo;
