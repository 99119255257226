import { call, put, select, takeLatest } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { patchEntity } from 'api/apiEntity';

import { selectUser } from 'connectors/User/redux/selectors';

import { EMPLOYEE_IMG_UPLOAD_STARTED } from '../actions';

import {
  employeeImageUploadDone,
  employeeSaveStarted,
  employeeSaveDone,
  employeeSaveError,
} from '../creators';

const patchEmployeeImage = function* patchEmployeeImage(action) {
  const {
    payload: { picture },
  } = action;

  yield put(employeeSaveStarted());

  const { currentProfile: { id: employeeId } = {} } = yield select(selectUser);

  try {
    const args = {
      type: 'employees',
      id: employeeId,
      data: {
        type: 'employees',
        id: employeeId,
        attributes: {
          picture: {
            file_data: picture,
          },
        },
      },
    };

    const {
      data: {
        employee: { picture: newPicture },
      },
    } = yield call(patchEntity, args);

    yield put(employeeImageUploadDone({ picture: newPicture }));
    yield put(employeeSaveDone());
  } catch (error) {
    handleError(error);

    yield put(employeeSaveError(error));
  }
};

const sagaEmployeeImageUpload = function* sagaEmployeeImageUpload() {
  yield takeLatest(EMPLOYEE_IMG_UPLOAD_STARTED, patchEmployeeImage);
};

export default sagaEmployeeImageUpload;
