import { handleError } from 'utils/common';
import { getCandidateRelationship } from 'api/apiCandidates';

const promiseGetWorkHistories = ({
  isEmployee,
  candidateId,
  sortBy = 'end_date_desc_nulls_first,start_date_desc',
  includeSet = 'company_sales_periods',
  normalizeIt = false,
  fullEntity = false,
}) => {
  const onFulfilled = ({
    data: {
      entities = {
        workHistory: {
          allIds: [],
          byId: {},
        },
      },
      entities: {
        company: { byId: companyById = {} } = {},
        workHistory: { allIds = [], byId = {} } = {},
      } = {},
    } = {},
  }) =>
    fullEntity
      ? { ...entities }
      : {
          workHistories: allIds.map((id) => {
            const {
              [id]: {
                relationships: {
                  company: { data: companyIds = [] } = {},
                  salesPeriods: { data: salesPeriodsIds = [], type: salesPeriodsTypes = [] } = {},
                } = {},
              } = {},
            } = byId || {};

            const salesPeriods = isEmployee
              ? []
              : salesPeriodsIds.map((spId, idx) => {
                  const {
                    [salesPeriodsTypes[idx]]: {
                      byId: { [spId]: { attributes = {} } = {} } = {},
                    } = {},
                  } = entities;

                  return attributes;
                });

            const wh = {
              ...byId[id].attributes,
              company: companyById[companyIds[0]].attributes,
              salesPeriods,
            };

            return wh;
          }),
        };

  const onFailed = (error) => {
    handleError(error);

    return { workHistories: [] };
  };

  const getArgs = {
    candidateId,
    config: {
      params: {
        includeSet: isEmployee ? 'company' : includeSet,
        sortBy,
        normalizeIt,
      },
    },
    type: 'workHistory',
  };

  return getCandidateRelationship(getArgs).then(onFulfilled).catch(onFailed);
};

export default promiseGetWorkHistories;
