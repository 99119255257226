import React, { Component, Fragment } from 'react';

import { VisibleHOC } from 'containers/HOC';

import Dropdown from 'components/Dropdown';
import ConfirmModal from 'components/ConfirmModal';

@VisibleHOC()
class ToolboxCandidatesActionDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = { promptType: null };
  }

  handleClick = (value) => {
    const {
      actions: { patchResource },
      candidateId,
    } = this.props;

    if (value.value === 'archived') {
      this.setState({ promptType: 'archiveConfirm' });
    } else {
      patchResource({
        type: 'candidates',
        slice: 'toolboxCandidates',
        attributes: {
          [value.field]: value.value,
        },
        id: candidateId,
      });
    }
  };

  render() {
    const {
      actions: { patchResource },
      isVisible,
      candidateId,
      firstName,
      lastName,
      userId,
      candidate,
    } = this.props;

    const { promptType } = this.state;

    const source = [];

    switch (candidate.lifecycleStatus) {
      case null:
        source.push({
          label: 'Set Inbound',
          value: { field: 'lifecycleStatus', value: 'inbound' },
        });
        break;
      case 'inbound':
        source.push({
          label: 'Enrichment Required',
          value: { field: 'lifecycleStatus', value: 'enrichment_required' },
        });
        source.push({
          label: 'Cancel Enrichment',
          value: { field: 'lifecycleStatus', value: null },
        });
        break;
      case 'enrichment_required':
        source.push({
          label: 'Enrichment Complete',
          value: { field: 'lifecycleStatus', value: 'enrichment_complete' },
        });
        source.push({
          label: 'Enrichment Error',
          value: { field: 'lifecycleStatus', value: 'enrichment_error' },
        });
        break;
      case 'enrichment_error':
        source.push({
          label: 'Enrichment Required',
          value: { field: 'lifecycleStatus', value: 'enrichment_required' },
        });
        source.push({
          label: 'Cancel Enrichment',
          value: { field: 'lifecycleStatus', value: null },
        });
        source.push({
          label: 'Ready to Host',
          value: { field: 'lifecycleStatus', value: 'ready_to_host' },
        });
        break;
      case 'enrichment_complete':
        source.push({
          label: 'Ready to Host',
          value: { field: 'lifecycleStatus', value: 'ready_to_host' },
        });
        source.push({
          label: 'Cancel Enrichment',
          value: { field: 'lifecycleStatus', value: null },
        });
        break;
      case 'ready_to_host':
        source.push({
          label: 'Candidate was/is Hosted',
          value: { field: 'lifecycleStatus', value: null },
        });
        source.push({
          label: 'Cancel Enrichment',
          value: { field: 'lifecycleStatus', value: null },
        });
        break;
    }

    switch (candidate.applicationStatus) {
      case 'pending':
        source.push({
          label: 'Approve',
          value: { field: 'applicationStatus', value: 'approved' },
        });
        break;
      case 'approved':
        source.push({
          label: 'Archive',
          value: { field: 'applicationStatus', value: 'archived' },
        });
        break;
    }

    const dropDownProps = {
      source: source,
      onChange: this.handleClick,
      value: 'send_message',
      defaultValue: 'Actions',
      above: !isVisible,
      left: true,
      actions: true,
      defaultIcon: 'caret-down',
      activeIcon: 'caret-up',
    };

    const confirmModalProps = {
      handleCancel: () => this.setState({ promptType: null }),
      handleConfirm: () => {
        patchResource({
          type: 'candidates',
          slice: 'toolboxCandidates',
          attributes: {
            applicationStatus: 'archived',
          },
          id: candidateId,
        });

        patchResource({
          type: 'users',
          slice: 'toolboxCandidates',
          attributes: {
            accountLocked: true,
          },
          id: userId,
        });
      },
      confirmText: 'Yes',
      cancelText: 'No',
      text: `Are you sure you want to archive ${firstName} ${lastName}?`,
      title: 'Confirm Archive',
    };

    const confirmModal =
      promptType === 'archiveConfirm' ? <ConfirmModal {...confirmModalProps} /> : null;

    return (
      <Fragment>
        <Dropdown {...dropDownProps} />
        {confirmModal}
      </Fragment>
    );
  }
}

export default ToolboxCandidatesActionDropdown;
