import React from 'react';
import classnames from 'classnames';

import FormattedName from 'components/FormattedName';
import { useImageField } from 'hooks';

import styles from './EmployeeData.scss';

const EmployeeData = ({
  employee = {},
  className,
  onClick,
  subtitle,
  boldSubtitle,
  largeAvatar,
  sideTable,
}) => {
  const {
    attributes: { firstName, lastName },
  } = employee;

  const classes = classnames(
    styles.EmployeeData,
    {
      [styles.sideTable]: sideTable,
    },
    className
  );

  const infoWrapperClasses = classnames(styles.employeeDataInfoWrapper, {
    [styles.employeeDataInfoWrapperShort]: sideTable,
  });

  const titleClasses = classnames(styles.employeeDataTitle, {
    [styles.employeeDataTitleSmall]: sideTable,
  });

  const subtitleClasses = classnames(styles.employeeDataSubtitle, {
    [styles.employeeDataSubtitleBold]: boldSubtitle,
  });

  const avatarClass = classnames(styles.employeeDataAvatarWrapper, {
    [styles.employeeDataAvatarLargeWrapper]: largeAvatar,
    [styles.employeeDataAvatarSmallWrapper]: sideTable,
  });

  const { imageComponent } = useImageField({
    model: employee,
    avatarClass,
  });

  const employeeProps = {
    className: classes,
    onClick,
  };

  const formattedNameProps = {
    className: styles.employeeName,
    firstName,
    lastName,
  };

  const subtitleString = subtitle ? <div className={subtitleClasses}>{subtitle}</div> : null;

  return (
    <div {...employeeProps}>
      {imageComponent}

      <div className={infoWrapperClasses}>
        <div className={titleClasses}>
          <FormattedName {...formattedNameProps} />
        </div>

        {subtitleString}
      </div>
    </div>
  );
};

export default EmployeeData;
