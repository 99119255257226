import React from 'react';
import ReactMarkdown from 'react-markdown';

import styles from './ExecutiveSummary.scss';

export const ExecutiveSummary = ({ candidateData }) => {
  const { summary } = candidateData || {};

  return (
    <div className={styles.executiveSummaryContainer}>
      {summary && (
        <>
          <h2>Executive summary</h2>
          <ReactMarkdown>{summary}</ReactMarkdown>
        </>
      )}
    </div>
  );
};
