import { createAction } from 'redux-actions';

import { SHOW_MODAL, HIDE_MODAL } from 'shared/store/app/actions';

import {
  TEMPLATES_LOAD_STARTED,
  TEMPLATES_LOAD_DONE,
  TEMPLATE_SAVE_STARTED,
  TEMPLATE_SAVE_DONE,
  TEMPLATE_DELETE_STARTED,
  TEMPLATE_DELETE_DONE,
  TEMPLATE_UPDATE_STARTED,
  TEMPLATE_UPDATE_DONE,
} from '../actions';

export const showModal = createAction(SHOW_MODAL);
export const hideModal = createAction(HIDE_MODAL);

export const templatesLoadStarted = createAction(TEMPLATES_LOAD_STARTED);
export const templatesLoadDone = createAction(TEMPLATES_LOAD_DONE);

export const templateSaveStarted = createAction(TEMPLATE_SAVE_STARTED);
export const templateSaveDone = createAction(TEMPLATE_SAVE_DONE);

export const templateDeleteStarted = createAction(TEMPLATE_DELETE_STARTED);
export const templateDeleteDone = createAction(TEMPLATE_DELETE_DONE);

export const templateUpdateStarted = createAction(TEMPLATE_UPDATE_STARTED);
export const templateUpdateDone = createAction(TEMPLATE_UPDATE_DONE);
