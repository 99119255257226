import { handleError } from 'utils/common';
import { getNestedEntity } from 'api/apiEntity';

const promiseGetProfileCards = ({ candidateId }) => {
  const onFulfilled = ({ data: { profile_cards: profileCards = [] } }) => profileCards;

  const onFailed = (error) => {
    handleError(error);

    return [];
  };

  const args = {
    id: candidateId,
    type: 'candidates',
    nestedType: 'profile_cards',
  };

  const promise = getNestedEntity(args).then(onFulfilled).catch(onFailed);

  return promise;
};

export default promiseGetProfileCards;
