import React from 'react';

import FormattedArray from 'components/FormattedArray';

import styles from './CompanyPerks.scss';

const CompanyPerks = ({ perks }) => {
  const filteredPerks = perks.filter((perk) => perk !== '');

  const content = (
    <div className={styles.CompanyPerks}>
      <FormattedArray array={filteredPerks} />
    </div>
  );

  return content;
};

export default CompanyPerks;
