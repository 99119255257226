import { createSelector } from 'reselect';
import { entitySelectorFactory } from 'reduxStore/selectorFactories';
import defaultErrors from 'reduxStore/errors';

const selectRainmakersAdminErrors = createSelector(
  entitySelectorFactory('toolboxAdmins', 'rainmakersAdmin'),
  (state, props) => {
    const { match: { params: { id } = {} } = {} } = props || {};

    return id;
  },
  (rainmakersAdmins, id) => {
    const { byId: { [id]: { errors } = {} } = {} } = rainmakersAdmins;

    return errors || defaultErrors.rainmakersAdmins;
  }
);

export default selectRainmakersAdminErrors;
