import { getEntity } from 'api/apiEntity';

const promiseGetCompany = ({ companyId }) => {
  const onFulfilled = (response) => {
    const {
      data: { company = {} },
    } = response;

    return company;
  };

  const args = {
    type: 'companies',
    id: companyId,
    config: {
      params: {
        includeSet: 'unverified_data',
        camelizeIt: true,
      },
    },
  };

  return getEntity(args).then(onFulfilled);
};

export default promiseGetCompany;
