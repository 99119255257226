import React, { useState, useCallback } from 'react';

import { handleError } from 'utils/common';

import { promiseEmailValid } from 'pages/CandidatePortal/CandidateEditorPages/promises';

import ConnectContainer from 'containers/ConnectContainer';

import RegistrationForm from './components/RegistrationForm';

import duck from './redux';

import styles from './EmployerSignupForm.scss';

const EmployerSignupForm = ({
  actions: { signupStarted, signupError, signupErrorsCheck, signupErrorsSet },
  serverErrors,
  signupErrors,
}) => {
  const [state, setState] = useState({
    firstName: null,
    lastName: null,
    email: null,
    name: null,
    phoneNumber: null,
    password: null,
    hiringLocations: [],
    autoCapFirstName: false,
    autoCapLastName: false,
    workRemote: null,
  });

  const {
    firstName,
    lastName,
    email,
    name,
    phoneNumber,
    password,
    hiringLocations,
    autoCapFirstName,
    autoCapLastName,
    workRemote,
  } = state;

  const handleOnBlur = useCallback(
    ({ target: { name, value: newValue } }) => {
      if (name === 'email' && newValue) {
        promiseEmailValid({ email: newValue })
          .then((response) => {
            if (response.email) {
              signupErrorsSet({
                inputErrors: {
                  ...signupErrors,
                  email: {
                    ...signupErrors.email,
                    isAvailable: [false, 'email'],
                  },
                },
              });
            }
          })
          .catch(handleError);
      }

      signupErrorsCheck({ newValue, name });

      setState((state) => ({ ...state, [name]: newValue }));
    },
    [signupErrors, signupErrorsCheck, signupErrorsSet]
  );

  const handleHiringLocationBlur = useCallback(
    (event) => {
      const {
        target: { value: place },
      } = event;

      if (!place) {
        signupErrorsCheck({ newValue: hiringLocations, name: 'hiringLocations' });
      }
    },
    [hiringLocations, signupErrorsCheck]
  );

  const handleHiringLocationSelect = useCallback(
    (place) => {
      if (hiringLocations.includes(place)) {
        const newLocations = hiringLocations.filter((loc) => loc !== place);

        signupErrorsCheck({ newValue: newLocations, name: 'hiringLocations' });
        setState((state) => ({ ...state, hiringLocations: newLocations }));
      } else {
        const newLocations = hiringLocations.slice();

        newLocations.push(place);

        signupErrorsCheck({ newValue: newLocations, name: 'hiringLocations' });
        setState((state) => ({ ...state, hiringLocations: newLocations }));
      }
    },
    [hiringLocations, signupErrorsCheck]
  );

  const handleDeleteHiringLocation = useCallback(
    ({ value }) => {
      const newLocations = hiringLocations.filter((loc) => loc !== value);

      signupErrorsCheck({ newValue: newLocations, name: 'hiringLocations' });

      setState((state) => ({ ...state, hiringLocations: newLocations }));
    },
    [hiringLocations, signupErrorsCheck]
  );

  const handleRequirementInputChange = useCallback(
    (event) => {
      const {
        target: { name, checked, value, type },
      } = event;

      const newValue = type === 'checkbox' ? checked : value;

      if (name === 'firstName') {
        if (newValue === '') {
          return setState((state) => ({
            ...state,
            autoCapFirstName: false,
            [name]: newValue,
          }));
        }
        if (!autoCapFirstName) {
          return setState((state) => ({
            ...state,
            autoCapFirstName: true,
            [name]: autoCap(newValue),
          }));
        }
      }

      if (name === 'lastName') {
        if (newValue === '') {
          return setState((state) => ({
            ...state,
            autoCapLastName: false,
            [name]: newValue,
          }));
        }
        if (!autoCapLastName) {
          return setState((state) => ({
            ...state,
            autoCapLastName: true,
            [name]: autoCap(newValue),
          }));
        }
      }

      if (name === 'email') {
        signupErrorsSet({
          inputErrors: {
            ...signupErrors,
            email: {
              ...signupErrors.email,
              isAvailable: [true],
            },
          },
        });
      }

      signupErrorsCheck({ newValue, name });

      setState((state) => ({
        ...state,
        [name]: newValue,
      }));
    },
    [autoCap, autoCapFirstName, autoCapLastName, signupErrors, signupErrorsCheck, signupErrorsSet]
  );

  const autoCap = useCallback((value) => value.charAt(0).toUpperCase() + value.slice(1), []);

  const handleSubmitForm = useCallback(
    (event) => {
      event.preventDefault();

      const formData = {
        user: {
          email,
          password,
          passwordConfirmation: password,
        },
        company: {
          name,
        },
        employee: {
          phoneNumber,
          firstName,
          lastName,
        },
        employer: {
          hiringLocations,
          name,
          workRemote,
        },
      };

      signupError({ errors: [] });
      promiseEmailValid({ email })
        .then((response) => {
          if (response.email) {
            signupErrorsSet({
              inputErrors: {
                ...signupErrors,
                email: {
                  ...signupErrors.email,
                  isAvailable: [false, 'email'],
                },
              },
            });
          } else {
            signupStarted(formData);
          }
        })
        .catch(handleError);
    },
    [
      email,
      firstName,
      hiringLocations,
      lastName,
      name,
      password,
      phoneNumber,
      signupError,
      signupErrors,
      signupErrorsSet,
      signupStarted,
      workRemote,
    ]
  );

  const serverNameError = serverErrors.name ? 'Business name' : null;
  const serverErrorString = serverNameError || null;
  const serverErrorMessage = serverErrorString
    ? `${serverErrorString} has already been taken`
    : null;

  const serverErrorContent = serverErrorMessage ? (
    <div className={styles.errorMessage}>{serverErrorMessage}</div>
  ) : null;

  return (
    <RegistrationForm
      {...state}
      signupErrors={signupErrors}
      serverErrorContent={serverErrorContent}
      handleHiringLocationSelect={handleHiringLocationSelect}
      handleDeleteHiringLocation={handleDeleteHiringLocation}
      handleRequirementInputChange={handleRequirementInputChange}
      handleOnBlur={handleOnBlur}
      handleHiringLocationBlur={handleHiringLocationBlur}
      handleSubmitForm={handleSubmitForm}
    />
  );
};

export default ConnectContainer(duck)(EmployerSignupForm);
