import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import queryString from 'query-string';

import { handleError } from 'utils/common';

import ConnectContainer from 'containers/ConnectContainer';
import { CandidateAuthHOC } from 'containers/HOC';
import ReadyContainer from 'connectors/ReadyContainer';

import { promiseGetFullCandidate } from 'pages/EmployeePortal/CandidateProfilePages/promises';

import { SALES_EXPERIENCE_QUESTIONS } from 'pages/CandidatePortal/OnboardingPages/constants';
import { HIRING_STATUS_SOURCE } from 'connectors/Defaults/constants';

import { qAndAToggleArrayValue, qAndATextInputChange } from 'utils/formHelpers/CandidateHelpers';

import { toggleArray } from 'utils/formUtils';

import Block from 'components/Block';
import Input from 'components/Input';
import InputRadio from 'components/InputRadio';
import InputAutoComplete from 'components/InputAutoComplete';
import InputCheckboxMany from 'components/InputCheckboxMany';
import Label from 'components/Label';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import Sidebar from 'components/Sidebar';
import TableOverlay from 'components/TableOverlay';
import Tag from 'components/Tag';

import duck from '../redux';
import CandReqInputs from './components/CandReqInputs';

import styles from './EditRecruiting.scss';

@CandidateAuthHOC()
class EditRecruiting extends Component {
  componentDidMount() {
    const {
      candidate,
      actions: { pickerOptionsLoadStarted },
    } = this.props;

    const { id: candidateId } = candidate || {};

    promiseGetFullCandidate({ candidateId }).then(this.onCandidateSuccess).catch(handleError);

    pickerOptionsLoadStarted();
  }

  onCandidateSuccess = (candidateProfile) => {
    const {
      actions: { candidateProfilePatchDone },
    } = this.props;

    candidateProfilePatchDone({ candidateProfile });
  };

  handleQAndAToggleArrayValue = ({ event, idx }) => {
    const {
      candidate: { qAndA },
      actions: { candidatePatchStarted },
    } = this.props;

    const patchArgs = qAndAToggleArrayValue(event, qAndA, idx);

    candidatePatchStarted(patchArgs);
  };

  handleQAndATextInputChange = ({ event, idx }) => {
    const {
      candidate: { qAndA },
      actions: { candidatePatchStarted },
    } = this.props;

    const patchArgs = qAndATextInputChange(event, qAndA, idx);

    candidatePatchStarted(patchArgs);
  };

  handleCandidateInputChange = (event) => {
    const {
      target: { type, name, value, checked },
    } = event;
    const newValue = type === 'checkbox' ? checked : value;

    const {
      actions: { candidatePatchStarted },
      candidate,
    } = this.props;
    const splitName = name.split(',');
    const newName = splitName[0];

    if (splitName.length === 2) {
      const composedValue = {
        ...candidate[splitName[0]],
        [splitName[1]]: newValue,
      };
      return candidatePatchStarted({ name: newName, newValue: composedValue });
    }

    return candidatePatchStarted({ name: newName, newValue });
  };

  handleCandReqInputChange = (event) => {
    const {
      target: { name, value, checked, type },
    } = event;

    const newValue = type === 'checkbox' ? checked : value;

    const {
      actions: { candReqPatchStarted },
    } = this.props;

    candReqPatchStarted({
      name,
      newValue,
    });
  };

  handleCandReqRoleChange = (event) => {
    const {
      target: {
        value: { role: value },
        name,
      },
    } = event;

    const {
      actions: { candReqPatchStarted },
      candidate: { candidateRequirement: { [name]: array = [] } = {} } = {},
    } = this.props;

    const newArray = toggleArray({ array, value });

    candReqPatchStarted({
      newValue: newArray,
      name,
    });
  };

  handleCandReqWorkModelChange = (event) => {
    const {
      target: {
        value: { workModel: value },
        name,
      },
    } = event;

    const {
      actions: { candReqPatchStarted },
      candidate: { candidateRequirement: { [name]: array = [] } = {} } = {},
    } = this.props;

    const newArray = toggleArray({ array, value });

    candReqPatchStarted({
      newValue: newArray,
      name,
    });
  };

  handleToggleArray = (event) => {
    const {
      target: { name, label: region },
    } = event;

    const {
      // eslint-disable-next-line no-unused-vars
      candidate: { candidateRequirement: { [name]: arrayToUpdate = [] } = {} } = {},
      actions: { candReqPatchStarted },
    } = this.props;

    if (region) {
      const regValue = region;

      // Multiselect:
      // const newArray = arrayToUpdate.map((item) => item.region).includes(regValue)
      //   ? arrayToUpdate.filter((r) => r.region !== regValue)
      //   : [...arrayToUpdate, { type: 'region', region: regValue }];

      // Single select:
      const newArray = arrayToUpdate.map((item) => item.region).includes(regValue)
        ? []
        : [{ type: 'region', region: regValue }];

      console.log('newArray', newArray);

      return candReqPatchStarted({
        name,
        newValue: newArray,
      });
    }
  };

  makeCandReqInputs = (candReq) => {
    const {
      isProfileLocked,
      location: { search },
      pickerOptions,
    } = this.props;
    const { 'fields[]': fields = [] } = queryString.parse(search);

    let autofocusFields = fields;

    if (fields && typeof fields === 'string') {
      autofocusFields = [fields];
    }

    const candReqInputsProps = {
      candReq: candReq || {},
      handleToggleArray: this.handleToggleArray,
      handleCandReqInputChange: this.handleCandReqInputChange,
      handleCandReqRoleChange: this.handleCandReqRoleChange,
      handleCandReqWorkModelChange: this.handleCandReqWorkModelChange,
      pickerOptions,
      isProfileLocked,
      autofocusFields,
    };

    const candReqBlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      addPadding: false,
      className: styles.candReqBlock,
      title: 'Your Next Role',
    };

    return (
      <Block {...candReqBlockProps}>
        <CandReqInputs {...candReqInputsProps} />
      </Block>
    );
  };

  render() {
    const {
      candidate = {},
      candidate: { candidateRequirement: candReq, qAndA, hiringStatus, workHistories = [] } = {},
      candidateSaving,
      isProfileLocked,
      location: { search },
    } = this.props;

    const { 'fields[]': fields = [] } = queryString.parse(search);

    let autofocusFields = fields;

    if (fields && typeof fields === 'string') {
      autofocusFields = [fields];
    }

    const { role: recentRole = null } = workHistories[0] || {};

    const savingContent = candidateSaving ? (
      <div className={styles.autoSaving}>Saving...</div>
    ) : (
      <div className={styles.autoSaved}>Profile Saved</div>
    );

    const statusContent = isProfileLocked ? (
      <div className={styles.profileLocked}>Profile Locked</div>
    ) : null;

    const saveContent = candidateSaving === undefined ? null : statusContent || savingContent;

    const { multiAnswer = [], singleAnswer = [] } = qAndA || {};

    const qAndABlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      addPadding: false,
      className: styles.qAndABlock,
      title: 'Q & A',
    };

    const commonQAndAProps = {
      type: 'textarea',
      size: 'xxlarge',
      topLabel: true,
      rows: '5',
      disabled: isProfileLocked,
    };

    const singleAnswerInputs = singleAnswer.map((questionObj, idx) => {
      const props = {
        ...commonQAndAProps,
        handleInputChange: (event) => this.handleQAndATextInputChange({ event, idx }),
        name: questionObj.question,
        label: questionObj.question,
        value: questionObj.answer || '',
        placeholder: '',
        key: idx,
      };

      return <Input key={idx} {...props} />;
    });

    const multiAnswerInputs = multiAnswer.map((questionObj, idx) => {
      if (questionObj.question === 'Are you receiving additional incentives?') {
        const source = ['Stock Options', 'Equity', 'Gym Membership', 'Company Car', 'Other'];

        const props = {
          label: questionObj.question,
          source,
          name: questionObj.question,
          handleInputChange: (event) => this.handleQAndAToggleArrayValue({ event, idx }),
          selectedValues: questionObj.answer || [],
          key: idx,
          disabled: isProfileLocked,
        };

        return <InputCheckboxMany {...props} />;
      }
      if (questionObj.question === 'Awards and honors') {
        const source = [
          {
            label: "President's Club",
            value: "President's Club",
          },
          {
            label: 'Top 1% Club',
            value: 'Top 1% Club',
          },
        ];

        const filteredSource = source.filter((option) => {
          if (questionObj.answer) {
            return !questionObj.answer.includes(option);
          }
          return true;
        });

        const props = {
          label: questionObj.question,
          source: filteredSource,
          name: questionObj.question,
          handleInputChange: (event) => this.handleQAndAToggleArrayValue({ event, idx }),
          placeholder: '',
          size: 'full',
          hasAddButton: true,
          characterLimit: 100,
          disabled: isProfileLocked,
        };

        const hasAnswers = questionObj.answer !== null && questionObj.answer !== undefined;

        const answersSelected = hasAnswers
          ? questionObj.answer.map((answer) => {
              const tagProps = {
                key: answer,
                name: answer,
                inputName: questionObj.question,
                disabled: isProfileLocked,
                onDeleteClick: (name, inputName) =>
                  this.handleQAndAToggleArrayValue({
                    event: {
                      target: {
                        value: name,
                        name: inputName,
                      },
                    },
                    idx,
                  }),
              };
              return <Tag key={answer} {...tagProps} />;
            })
          : null;

        return (
          <div key={questionObj.question} className={styles.aggroQuestionContainer}>
            <InputAutoComplete {...props} />
            <div className={styles.aggroAnswerContainer}>{answersSelected}</div>
          </div>
        );
      }
      return null;
    });

    const hiringStatusInputProps = {
      required: true,
      source: HIRING_STATUS_SOURCE,
      name: 'hiringStatus',
      handleInputChange: this.handleCandidateInputChange,
      selectedValues: hiringStatus !== '' ? [hiringStatus] : [],
      blockStyle: true,
      flexFit: true,
      hasLabel: true,
      disabled: isProfileLocked,
      autoFocus: autofocusFields.includes('hiring_status'),
      highlight: autofocusFields.includes('hiring_status'),
    };

    const questions = recentRole
      ? SALES_EXPERIENCE_QUESTIONS[recentRole] || SALES_EXPERIENCE_QUESTIONS.default
      : SALES_EXPERIENCE_QUESTIONS['Non-sales'];

    const questionsContent = questions
      ? questions.map(({ key, camelKey, question, options }) => {
          const props = {
            disabled: isProfileLocked,
            key,
            options,
            name: camelKey,
            value: candidate[camelKey],
            handleInputChange: this.handleCandidateInputChange,
            autoFocus: autofocusFields.includes(key),
            highlight: autofocusFields.includes(key),
          };

          const labelProps = {
            key: question,
            required: true,
            className: styles.label,
          };

          return (
            <React.Fragment key={key}>
              <Label {...labelProps}>{question}</Label>
              <InputRadio {...props} />
            </React.Fragment>
          );
        })
      : null;

    const qaLabelProps = {
      standalone: true,
      required: true,
    };

    const qAndAInputContent = (
      <Block {...qAndABlockProps}>
        <div className={styles.basicInfoContainer}>
          <Label {...qaLabelProps}>Where are you in your job search?</Label>
          <InputCheckboxMany {...hiringStatusInputProps} />

          {questionsContent}

          {singleAnswerInputs}

          {multiAnswerInputs}
        </div>
      </Block>
    );

    const titleBlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      addPadding: true,
      className: styles.titleBlock,
    };

    const skillsBlockProps = {
      noTitlePadding: true,
      largeTitleFont: true,
    };

    const candReqInputContent = this.makeCandReqInputs(candReq);

    const profileLockOverlay = isProfileLocked ? <TableOverlay lockedNotFixed /> : null;

    const content = (
      <div className={styles.EditorSkills}>
        <Block {...titleBlockProps}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>Recruiting Report</div>
            <div className={styles.saveContent}>{saveContent}</div>
          </div>
        </Block>
        <div className={styles.scrollDiv}>
          {profileLockOverlay}
          <Block {...skillsBlockProps}>
            <div className={styles.candReqInputContainer}>{candReqInputContent}</div>
            <div className={styles.qAndAInputContainer}>{qAndAInputContent}</div>
          </Block>
        </div>
      </div>
    );

    const layoutWithoutSidebarProps = {
      content,
    };

    const sidebarProps = {
      type: 'candidateEditor',
      page: 'edit-recruiting',
      candidate,
    };

    return (
      <Fragment>
        <Helmet title="Candidate Editor" />
        <ReadyContainer className={styles.CandidateEditorSkills}>
          <Sidebar {...sidebarProps} />
          <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />
        </ReadyContainer>
      </Fragment>
    );
  }
}

export default ConnectContainer(duck)(EditRecruiting);
