import { useEffect, useState, useRef, useCallback } from 'react';
import { handleError } from 'utils/common';

export const useRetryLoadData = ({
  loadData,
  ifSuccessful,
  successCallback,
  timeout,
  retries,
  enabled,
}) => {
  const _mounted = useRef(false);
  const [retriesLeft, setRetriesLeft] = useState(retries);

  useEffect(() => {
    _mounted.current = true;

    return () => {
      _mounted.current = false;
    };
  }, []);

  const retry = useCallback(
    () =>
      loadData()
        .then((response) => {
          if (ifSuccessful(response)) {
            successCallback(response);
          } else {
            setTimeout(() => {
              if (_mounted) {
                setRetriesLeft((retries) => retries - 1);
              }
            }, timeout);
          }
        })
        .catch(handleError),
    [ifSuccessful, loadData, successCallback, timeout]
  );

  useEffect(() => {
    if (enabled && retriesLeft > 0) retry();
  }, [enabled, retries, retriesLeft, retry]);

  return {
    retriesLeft,
    reset: () => setRetriesLeft(retries),
  };
};
