import { call, put, select, take } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import Cookies from 'js-cookie';
import { push } from 'connected-react-router';

import { handleError } from 'utils/common';
import { reset, trackEvent } from 'utils/analytics';
import { segmentUserLogoutEmployee, segmentUserLogoutCandidate } from 'segment/eventNames';

import { deleteSignOut } from 'api/apiAuth';

import { LOGOUT_STARTED } from 'shared/store/app/actions';

import {
  logoutError,
  logoutDone,
  removeRequestInterceptor,
  userRemove,
} from 'shared/store/app/creators';

import { selectIsCandidate, selectIsEmployee } from 'connectors/Login/redux/selectors';

const trackLogout = (isCandidate) => {
  const event = isCandidate ? segmentUserLogoutCandidate : segmentUserLogoutEmployee;

  const properties = {};

  const eventObject = { event, properties };

  trackEvent(eventObject);
};

const sagaLogoutStarted = function* sagaLogoutStarted() {
  while (true) {
    const { payload: { noRedirect = false } = {} } = yield take(LOGOUT_STARTED);

    try {
      yield call(deleteSignOut);
    } catch (error) {
      handleError(error);
    }

    try {
      const isCandidate = yield select(selectIsCandidate);
      const isEmployee = yield select(selectIsEmployee);

      Cookies.remove('accessToken');
      Cookies.remove('client');
      Cookies.remove('uid');

      yield put(userRemove({ unauthorized: noRedirect }));
      yield put(removeRequestInterceptor());

      if (isCandidate || isEmployee) {
        yield call(trackLogout, isCandidate);
      }

      yield call(reset);

      if (!noRedirect) {
        yield put(push('/'));
      }
      yield delay(500);
      yield put(logoutDone());
    } catch (error) {
      handleError(error);
      yield put(logoutError(error));
      yield put(logoutDone());
    }
  }
};

export default sagaLogoutStarted;
