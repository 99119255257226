import React, { Component, Fragment } from 'react';

import { handleError } from 'utils/common';
import { SALUTATIONS } from 'utils/constants';
import { getInitials } from 'utils/generalUtils';

import ConnectContainer from 'containers/ConnectContainer';

import Button from 'components/Button';
import FontIcon from 'components/FontIcon';
import Input from 'components/Input';
import InputSelect from 'components/InputSelect';
import RainEditor from 'components/RainEditor';

import { promisePostTemplate } from './promises';

import TemplateNameModal from './components/TemplateNameModal';

import duck from './redux';

import styles from './TemplateComposer.scss';

class TemplateComposer extends Component {
  constructor(props) {
    super(props);

    const { templateId = '', salutationOriginal = 'Hi <first_name>', message = null } = props;

    this.state = {
      markdown: '',
      html: '',
      salutation: salutationOriginal || 'Hi <first_name>',
      searchName: '',
      selectedId: templateId,
      showPrompt: '',
      showSaved: false,
      templateName: null,
      value: message,
    };
  }

  componentDidMount() {
    const {
      actions: { templatesLoadStarted },
      adminEditor,
      employeeId,
    } = this.props;

    // needed to sync initial salutation state in RequestIntroModal
    this.handleSalutationChange({ target: { value: this.state.salutation } });

    if (!adminEditor) {
      templatesLoadStarted({ page: 1 });
    }
    if (adminEditor && employeeId) {
      templatesLoadStarted({
        page: 1,
        employeeId,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { employeeId: prevEmployeeId } = prevProps;
    const {
      actions: { templatesLoadStarted },
      employeeId,
    } = this.props;

    if (employeeId && employeeId !== prevEmployeeId) {
      this.setState({
        html: '',
        markdown: '',
        searchName: '',
        selectedId: '',
        templateName: '',
      });

      templatesLoadStarted({
        page: 1,
        employeeId,
      });
    }
  }

  componentWillUnmount() {
    const {
      actions: { templatesLoadDone },
    } = this.props;

    templatesLoadDone({
      templates: [],
      page: 1,
      total: 0,
    });
  }

  handleInputChange = ({ markdown, html, value }) => {
    const { handleSetState, name } = this.props;

    handleSetState({
      target: {
        name,
        value,
      },
    });

    this.setState({
      markdown,
      html,
      value,
    });
  };

  handleSalutationChange = (event) => {
    const {
      target: { value },
    } = event;
    const { handleSetState } = this.props;

    const { replacer, string } = SALUTATIONS.find((sal) => sal.string === value) || {};

    let salutation = string;

    if (replacer) {
      const { [replacer]: substitution } = this.props;
      salutation = string.replace(`<${replacer}>`, substitution);
    }

    this.setState({
      salutation: value,
    });

    handleSetState({
      target: {
        name: 'salutation',
        value: salutation,
      },
    });

    handleSetState({
      target: {
        name: 'salutationOriginal',
        value,
      },
    });
  };

  handleSaveTemplate = ({ saveAs }) => {
    const {
      actions: { templateSaveDone, templateUpdateStarted },
      employeeId,
      handleSetState,
      templates,
    } = this.props;

    const { templateName, selectedId, markdown, html, salutation } = this.state;

    const selectedTemplate = templates.find((temp) => temp.id === selectedId) || {};

    if (saveAs === false) {
      templateUpdateStarted({
        templateId: selectedTemplate.id,
        name: selectedTemplate.name,
        salutation,
        template_markdown: markdown,
        template_html: html,
      });

      this.setState({
        showPrompt: '',
        templateName: '',
        selectedId: selectedTemplate.id,
      });
    }

    if (saveAs === 'templateEditName') {
      templateUpdateStarted({
        templateId: selectedTemplate.id,
        name: templateName,
      });

      this.setState({
        showPrompt: '',
        templateName: '',
        selectedId: selectedTemplate.id,
      });

      handleSetState({
        target: {
          name: 'templateId',
          value: selectedTemplate.id,
        },
      });
    }

    if (saveAs === 'templateName') {
      promisePostTemplate({
        employee_id: employeeId,
        name: templateName,
        salutation,
        template_markdown: markdown,
        template_html: html,
      })
        .then(({ data: { message_template: template } }) => {
          templateSaveDone({ template });
          this.setState({
            showPrompt: '',
            templateName: '',
            selectedId: template.id,
          });
        })
        .catch(handleError);
    }
  };

  handleSaveTemplateNameInputChange = (event) => {
    const {
      target: { value },
    } = event;

    this.setState({ templateName: value });
  };

  handleTemplateNameSearch = (event) => {
    const {
      target: { value: searchName },
    } = event;

    const {
      actions: { templatesLoadStarted },
      employeeId,
    } = this.props;

    this.setState({ searchName });

    templatesLoadStarted({
      employeeId,
      page: 1,
      searchName,
    });
  };

  render() {
    const {
      actions: { templateDeleteStarted },
      handleSetState,
      hideNames,
      templates = [],
      autoFocus,
      placeholder,
      title,
    } = this.props;

    const {
      html,
      markdown,
      salutation,
      searchName,
      selectedId,
      showPrompt,
      showSaved,
      templateName,
      value,
    } = this.state;

    const rainEditorProps = {
      setValue: this.handleInputChange,
      selectedId,
      html,
      markdown,
      value,
      autoFocus,
      placeholder,
    };

    const nameSearchProps = {
      placeholder: 'Find a template',
      size: 'full',
      handleInputChange: this.handleTemplateNameSearch,
      isSearch: true,
      value: searchName,
      disabled: templates.length === 0 && !searchName,
    };

    const saveTemplateButtonProps = {
      onClick: () => {
        this.handleSaveTemplate({ saveAs: false });
        this.setState({ showSaved: true });
        setTimeout(() => this.setState({ showSaved: false }), 2000);
      },
      tertiary: true,
      className: showSaved ? styles.savedButton : styles.saveButton,
      disabled: !salutation || !markdown || !selectedId,
    };

    const saveAsTemplateButtonProps = {
      onClick: () => this.setState({ showPrompt: 'templateName' }),
      tertiary: true,
      className: styles.saveButton,
      disabled: !salutation || !markdown,
    };

    const showModal = showPrompt === 'templateEditName' || showPrompt === 'templateName';

    const modalProps = {
      showPrompt,
      selectedId,
      templateName,
      handleSaveTemplate: () => this.handleSaveTemplate({ saveAs: showPrompt }),
      handleSaveTemplateNameCloseClick: () => this.setState({ showPrompt: '' }),
      handleSaveTemplateNameInputChange: this.handleSaveTemplateNameInputChange,
    };

    const templateNameModal = showModal ? <TemplateNameModal {...modalProps} /> : null;

    const salutationSource = SALUTATIONS.map((sal) => {
      const { label, string, replacer } = sal;

      let newLabel = label;

      if (replacer) {
        if (replacer === 'first_name' && hideNames) {
          const { firstName, lastName } = this.props;
          const initials = getInitials({ firstName, lastName });

          newLabel = string.replace(`<${replacer}>`, initials);
        } else {
          const { [replacer]: substitution } = this.props;

          newLabel = string.replace(`<${replacer}>`, substitution);
        }
      }

      return {
        label: newLabel,
        value: string,
      };
    });

    const selectSalutationProps = {
      name: 'salutation',
      source: salutationSource,
      value: salutation,
      defaultIcon: 'caret-down',
      activeIcon: 'caret-up',
      handleInputChange: this.handleSalutationChange,
      defaultLabel: 'Select salutation',
      bigInput: true,
    };

    const templateSelections = templates.map((temp) => {
      const {
        id: templateId,
        name,
        salutation,
        template_html: html,
        template_markdown: markdown,
      } = temp;

      const buttonProps = {
        onClick: () => {
          if (templateId === selectedId) {
            this.setState({
              html: '',
              selectedId: '',
              salutation: '',
              markdown: '',
            });

            handleSetState({
              target: {
                name: 'salutation',
                value: '',
              },
            });
          } else {
            this.setState({
              html,
              selectedId: templateId,
              salutation,
              markdown,
            });

            const { string, replacer } = SALUTATIONS.find((sal) => sal.string === salutation) || {};

            let value = string;

            if (replacer) {
              const { [replacer]: substitution } = this.props;

              value = string.replace(`<${replacer}>`, substitution);
            }

            handleSetState({
              target: {
                name: 'salutation',
                value,
              },
            });

            handleSetState({
              target: {
                name: 'salutationOriginal',
                value: string,
              },
            });

            handleSetState({
              target: {
                name: 'templateId',
                value: name,
              },
            });
          }
        },
        quaternary: true,
        className: selectedId === templateId ? styles.selectedTemplate : null,
        key: name,
      };

      const editProps = {
        title: 'Edit',
        className: styles.edit,
        onClick: () => {
          this.setState({
            showPrompt: 'templateEditName',
            selectedId: templateId,
          });
        },
      };

      const deleteProps = {
        title: 'Delete',
        className: styles.delete,
        onClick: () => {
          templateDeleteStarted({ templateId });
          this.setState({
            html: '',
            selectedId: '',
            salutation: '',
            markdown: '',
          });

          handleSetState({
            target: {
              name: 'salutation',
              value: '',
            },
          });
        },
      };

      const editButtons =
        selectedId === templateId ? (
          <Fragment>
            <div {...editProps}>
              <FontIcon iconName="cog" />
            </div>
            <div {...deleteProps}>
              <FontIcon iconName="trash" />
            </div>
          </Fragment>
        ) : null;

      return (
        <div
          key={templateId}
          className={selectedId === templateId ? styles.selectedTemplateContainer : styles.template}
        >
          <Button {...buttonProps}>{name}</Button>
          {editButtons}
        </div>
      );
    });

    return (
      <Fragment>
        <div className={styles.main}>
          <div className={styles.left}>
            <Input {...nameSearchProps} />

            <div className={styles.templates}>{templateSelections}</div>
          </div>

          <div className={styles.right}>
            {title}

            <InputSelect {...selectSalutationProps} />

            <RainEditor {...rainEditorProps} />

            <div className={styles.buttons}>
              <Button {...saveTemplateButtonProps}>{showSaved ? 'Saved' : 'Save'}</Button>

              <Button {...saveAsTemplateButtonProps}>Save as new</Button>
            </div>
          </div>
        </div>
        {templateNameModal}
      </Fragment>
    );
  }
}

export default ConnectContainer(duck)(TemplateComposer);
