import { postNestedEntity } from 'api/apiEntity';
import promisePostCohortEmployers from './promisePostCohortEmployers';

const promisePostAddEmployers = ({ cohortId, employersToAdd }) => {
  const args = {
    id: cohortId,
    type: 'cohorts',
    nestedType: 'add_employers',
  };

  const onFulfilledAll = (responses) => ({ cohortEmployers: responses });

  const onFulfilled = (response) => {
    const {
      data: { cohort_employers: cohortEmployers },
    } = response;

    return { cohortEmployers };
  };

  const promises = employersToAdd
    ? employersToAdd.map((empId) =>
        promisePostCohortEmployers({
          cohortId,
          employerId: empId,
          employerState: 'enabled',
        })
      )
    : null;

  const promise = promises
    ? Promise.all(promises).then(onFulfilledAll)
    : postNestedEntity(args).then(onFulfilled);

  return promise;
};

export default promisePostAddEmployers;
