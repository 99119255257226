import React, { Fragment, useState } from 'react';

import { ComponentErrorBoundaryHOC } from 'containers/HOC';

import PrivateReqCanFeedbackEmployerPass from 'connectors/DialogModals/PrivateReqCanFeedbackEmployerPass';
import PrivateReqCanFeedbackEmployerHire from 'connectors/DialogModals/PrivateReqCanFeedbackEmployerHire';

import Button from 'components/Button';

import styles from './DashboardButtons.scss';

const PASSED_STATES = ['passed', 'candiate_passed', 'employer_passed'];
const HIRED_STATES = ['hired'];

export const DashboardButtons = ({
  candidateId,
  firstName,
  handleMakeOffer,
  handlePass,
  lastName,
  requisitionCandidates: { byId: requisitionCandidatesById = {} } = {},
}) => {
  const [promptType, setPromptType] = useState(null);

  const requisitionCandidateId = Object.keys(requisitionCandidatesById).find(
    (id) => requisitionCandidatesById[id].attributes.candidateId === candidateId
  );

  const {
    [requisitionCandidateId]: { attributes: { state } = {} } = {},
  } = requisitionCandidatesById;

  const isPassed = PASSED_STATES.includes(state);
  const isHired = HIRED_STATES.includes(state);
  const isDecided = isPassed || isHired;

  const passButtonProps = {
    key: 'pass',
    warningTertiary: true,
    className: styles.passButton,
    onClick: () => setPromptType('pass'),
    disabled: isDecided,
  };

  const passButton = isHired ? null : (
    <Button {...passButtonProps}>{isPassed ? 'Passed' : 'Pass'}</Button>
  );

  const makeOfferButtonProps = {
    key: 'makeOffer',
    primary: true,
    className: styles.makeOfferButton,
    onClick: () => setPromptType('hire'),
    disabled: isDecided,
  };

  const makeOfferButton = isPassed ? null : <Button {...makeOfferButtonProps}>Hired</Button>;

  const handleCancel = () => setPromptType(null);

  const passModalProps = {
    firstName,
    lastName,
    handleSubmit: (attributes) => {
      handlePass({
        ...attributes,
        candidateId,
      });

      setPromptType(null);
    },
    handleCancel,
  };

  const hireModalProps = {
    firstName,
    lastName,
    handleSubmit: (attributes) => {
      handleMakeOffer({
        ...attributes,
        candidateId,
      });

      setPromptType(null);
    },
    handleCancel,
  };

  const hireModal =
    promptType === 'hire' ? <PrivateReqCanFeedbackEmployerHire {...hireModalProps} /> : null;
  const passModal =
    promptType === 'pass' ? <PrivateReqCanFeedbackEmployerPass {...passModalProps} /> : null;
  const promptContent = hireModal || passModal;

  return requisitionCandidateId ? (
    <Fragment>
      {makeOfferButton}
      {passButton}
      {promptContent}
    </Fragment>
  ) : null;
};

export default ComponentErrorBoundaryHOC()(DashboardButtons);
