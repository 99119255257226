import React, { Component } from 'react';

import Button from 'components/Button';

import TemplateComposer from 'connectors/TemplateComposer';

import RoleEditor from '../RoleEditor';

import styles from './CreateMessage.scss';

class CreateMessage extends Component {
  constructor(props) {
    super(props);

    const {
      handleSetState,
      oteMaxCents: origOteMaxCents,
      oteMinCents: origOteMinCents,
      requisition: { ote_min_cents: oteMinCents, ote_max_cents: oteMaxCents } = {},
    } = props;

    if (!origOteMinCents) {
      handleSetState({
        target: {
          name: 'oteMinCents',
          value: oteMinCents,
        },
      });
    }

    if (!origOteMaxCents) {
      handleSetState({
        target: {
          name: 'oteMaxCents',
          value: oteMaxCents,
        },
      });
    }
  }

  handleInputChange = (event) => {
    const {
      target: { name, value },
    } = event;
    const { handleSetState } = this.props;

    handleSetState({
      target: {
        name,
        value,
      },
    });
  };

  render() {
    const {
      handleSetState,
      hideNames,
      candidateProfile: { firstName, id: candidateId, lastName } = {},
      message,
      oteCents,
      oteMinCents,
      oteMaxCents,
      salutationOriginal,
      templateId,
    } = this.props;

    const templateComposerProps = {
      autoFocus: true,
      candidateId,
      first_name: firstName,
      firstName,
      lastName,
      hideNames,
      handleSetState,
      message,
      name: 'message',
      placeholder: 'Requests with custom messages have a higher response rate!',
      salutationOriginal,
      templateId,
    };

    const sendMessageButtonProps = {
      onClick: () =>
        handleSetState({
          target: {
            name: 'step',
            value: '3',
          },
        }),
      primary: true,
      className: styles.reviewButton,
      disabled: !oteMinCents || !oteMaxCents,
    };

    const sendMessageButton =
      message.toString('html') !== '<p><br></p>' ? (
        <Button {...sendMessageButtonProps}>Review Intro</Button>
      ) : (
        <Button {...sendMessageButtonProps}>Continue without message</Button>
      );

    const roleProps = {
      firstName,
      oteCents,
      oteMaxCents,
      oteMinCents,
      handleInputChange: this.handleInputChange,
    };

    return (
      <div className={styles.adminRequestOptionsContainer}>
        <div className={styles.header}>Add a Message</div>
        <div className={styles.subheader}>
          Candidates want to hear from you, so make a good first impression by writing something
          personal.
        </div>

        <div className={styles.messageCreator}>
          <TemplateComposer {...templateComposerProps} />
        </div>

        <RoleEditor {...roleProps} />

        <div className={styles.requestIntroButtons}>{sendMessageButton}</div>
      </div>
    );
  }
}

export default CreateMessage;
