import { handleError } from 'utils/common';
import { getCandidateRelationship } from 'api/apiCandidates';

const promiseGetCandidateRequirement = ({ candidateId, normalizeIt, fullEntity }) => {
  const onFulfilled = ({
    data: {
      entities: {
        candidateRequirement = {
          allIds: [],
          byId: {},
        },
        candidateRequirement: { allIds = [], byId = {} } = {},
      } = {},
    } = {},
  }) =>
    fullEntity
      ? { candidateRequirement }
      : { candidateRequirement: byId[allIds[0]].attributes || {} };

  const getArgs = {
    candidateId,
    config: {
      params: {
        normalizeIt,
      },
    },
    type: 'candidateRequirement',
  };

  return getCandidateRelationship(getArgs).then(onFulfilled).catch(handleError);
};

export default promiseGetCandidateRequirement;
