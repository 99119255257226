/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import jobsPageMetaData, {
  JOB_PAGE_AE,
  JOB_PAGE_CSM,
  JOB_PAGE_EAE,
  JOB_PAGE_MMAE,
  JOB_PAGE_SD,
  JOB_PAGE_SDR,
  JOB_PAGE_SM,
  JOB_PAGE_VP,
} from 'pages/JobsPage/jobsPageMetaData';

import { getAltName } from 'utils/urlUtils';

import pic1 from './assets/1-Denver-Tech-Sales-Opportunities.png';
import pic2 from './assets/2-Denver-Sales-Careers-Tech.png';
import pic3 from './assets/3-Denver-Sales-Jobs-Roles.png';
import pic4 from './assets/4-Contact-Sales-Recruitment-Agency.png';

import styles from './DenverContent.scss';

export function DenverContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt={getAltName(pic1)} />

      <h2>Tech Sales Opportunities In Colorado</h2>

      <p>
        Colorado’s main economic drivers are no longer just agriculture and natural resources.
        Instead, a diversified ecosystem has been sparked by a rapid technological revolution of
        business. Colorado has become one of the best places to start and build a company because of
        its leadership in various industries, including cybersecurity, health, aerospace, cloud
        computing, and engineering.
      </p>

      <p>
        The Centennial State has emerged as a leading innovation hotspot between the coasts thanks
        to Colorado’s exceptional work-life balance and rising tech pay. The growth of tech business
        directly leads to numerous sales opportunities for those residing in or moving to the Mile
        High City.
      </p>

      <h2>Living Well In Denver</h2>

      <p>
        When considering the advantages of Denver as a place to live and work, it’s difficult to
        know where to begin.
      </p>

      <p>
        To start with, the weather is consistently beautiful, with an average of 300 days a year of
        sunshine. With summer temperatures of around 90 and winter of around 40, residents of Denver
        don’t experience the sort of weather extremes other cities do. And it’s a beautiful and
        varied place to live. The mountain scenery is breathtaking, the nature trails showcase the
        best of the American west, and there is always something to do. Downtown Denver boasts a
        lively nightlife with excellent restaurants and clubs, legendary sports centers, and
        top-rated entertainment.
      </p>

      <p>
        Neighborhoods in Denver run the range from metro and modern to contemporary
        classic-American. Whether someone is looking to live in a trendy studio, suburban home, or
        luxury urban development, Denver can provide.
      </p>

      <p>
        And, of course, the incredible economy in Denver is one of the main benefits of moving
        there.{' '}
        <strong>
          Denver’s median annual salary is roughly $57,700, but average earnings are expected to
          increase due to the city’s anticipated growth.
        </strong>
      </p>

      <p>
        This growth is due to an increasing number of tech startups and established companies, such
        as HomeAdvisor and Google, leading to increased work opportunities. In addition, the
        aerospace industry is also booming in the Denver area, with enterprise businesses like
        Raytheon and the Boeing Company leading the way.
      </p>

      <img src={pic2} alt={getAltName(pic2)} />

      <h2>Tech Business Growth In Denver</h2>
      <p>
        Of Colorado’s cities, it’s no surprise Denver has consistently led the pack in attracting
        the most growth. Denver provides budding tech enterprises with a number of attractive
        advantages. For instance, the Office of Economic Development and the Denver Metro Chamber of
        Commerce offer tech companies incentives such as tax credits, grants, financing options, and
        more.
      </p>

      <p>
        By all reports, it seems to be working. Startups from Denver, like Dizzion, Pax8, JumpCloud,
        and Gtmhub, are drawing in considerable venture capital funding and using that to build and
        expand. Each year, more tech business comes to Denver to experience the same benefits.
      </p>

      <h2>Sales Positions Available In Denver</h2>

      <p>
        Positions are opening up all over Denver, Colorado, in the technology sales sector.
        Rainmakers matches great job prospects with their ideal sales opportunities and vice versa.
        Everyone benefits when companies partner with talented salespeople.
      </p>

      <p>Here’s a rundown of what is available for all levels of sales experience.</p>

      <h2>Sales Development Representative</h2>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_SDR].localUrl} target="_blank" rel="noreferrer">
          Sales Development Representatives (SDRs)
        </a>{' '}
        are vital to the success of any SaaS sales team. To create sales pipelines for the rest of
        the team, an SDR specializes in inside sales activities, focusing on research and cold
        calling. An entry-level SDR position is often the best way to embark upon a long-term sales
        career. The position requires little to no prerequisite knowledge and is perfect for those
        with a drive for success.
      </p>

      <h2>Account Executive</h2>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_AE].localUrl} target="_blank" rel="noreferrer">
          Account Executives (AE)
        </a>{' '}
        bring in sales, maintain current accounts, close transactions with new clients, and attend
        to the needs of all a company’s clients. Since commissions make up a sizable amount of an
        AE’s pay, the position requires the individual to put in considerable work to achieve
        predetermined quotas and revenue targets.
      </p>

      <img src={pic3} alt={getAltName(pic3)} />

      <h2>Mid-Market Account Executive</h2>

      <p>
        This is the best next move for sales professionals who are passionate about their careers
        and want to advance.{' '}
        <a href={jobsPageMetaData[JOB_PAGE_MMAE].localUrl} target="_blank" rel="noreferrer">
          Mid-Market Account Executives
        </a>{' '}
        facilitate sales, close deals, and uphold strong client relationships while also managing
        training, fostering growth, and leveraging SaaS/cloud technology. Middle-market businesses
        are their primary source of new sales and commissions.
      </p>

      <h2>Enterprise Account Executive</h2>

      <p>
        Employing{' '}
        <a href={jobsPageMetaData[JOB_PAGE_EAE].localUrl} target="_blank" rel="noreferrer">
          Enterprise Account Executives
        </a>{' '}
        can be advantageous for manufacturers, software developers, telecommunications firms, retail
        chains, and other major businesses. This role has considerable earning potential due to the
        level of responsibility it requires. Large companies use Enterprise Account Executives to
        help maintain profitable sales relationships and promote innovation. Ideal candidates for
        this position should be committed, capable, and organized.
      </p>

      <h2>Customer Success Manager</h2>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_CSM].localUrl} target="_blank" rel="noreferrer">
          Customer Success Managers (CSMs)
        </a>{' '}
        put forth a lot of effort to build trustworthy, enduring client relationships. Successful
        companies must constantly demonstrate the worth of their products, and it is the customer
        success manager’s job to market goods and services to encourage repeat business and client
        success. Despite being a relatively new sales function, CSMs are now successfully used by
        almost half of high-tech companies.
      </p>

      <h2>Sales Director</h2>

      <p>
        A company’s commitment to providing excellent customer service is upheld by its{' '}
        <a href={jobsPageMetaData[JOB_PAGE_SD].localUrl} target="_blank" rel="noreferrer">
          Sales Directors
        </a>
        . Their primary responsibilities are ensuring the sales team completes all necessary tasks
        and strategically develops and delivers new goods and services. Sales managers oversee the
        sales staff, conduct training sessions, and make sure everyone is pursuing their goals.
        Strong organizational, sales, and leadership skills are highly valued for this position.
      </p>

      <Link to="/apply-now" title="Apply Now">
        <img src={pic4} alt={getAltName(pic4)} />
      </Link>

      <h2>Sales Manager</h2>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_SM].localUrl} target="_blank" rel="noreferrer">
          Sales Managers
        </a>{' '}
        are in charge of both sales staff and daily sales activity. They create sales and growth
        strategies, establish and monitor sales objectives, and carefully monitor profit expansion.
        Depending on the business, a Sales Manager may have a wide range of duties, such as finding
        sales talent, developing the sales plan, mentoring, and monitoring operations.
      </p>

      <h2>Vice President of Sales</h2>

      <p>
        A{' '}
        <a href={jobsPageMetaData[JOB_PAGE_VP].localUrl} target="_blank" rel="noreferrer">
          Vice President (VP) of Sales
        </a>{' '}
        may be responsible for various crucial duties. They frequently serve as managers, mentors,
        and strategists but are also responsible for improving the performance of the sales division
        and hitting quotas. Their tasks often include creating objectives and sales strategies,
        leading sales teams, controlling spending, hiring salespeople, and more. It’s a position
        with a lot of responsibility and excellent compensation.
      </p>

      <h2>Managing Tech Sales Careers at Rainmakers</h2>

      <p>
        Careers in tech sales usually provide a wealth of professional and personal growth
        opportunities. There are many superb avenues for advancement available for individuals ready
        to invest the time and effort necessary to exceed expectations and prove their value.
      </p>

      <p>
        The sales funnel of any expanding business depends on every person on the team, from the
        Vice President of Sales to Sales Development Representatives. Candidates who put in the
        necessary effort, possess the necessary talents, and are dedicated to the task will find
        success and satisfaction.
      </p>

      <p>
        <a href="/apply-now/">Contact Rainmakers</a> to find out more about these fantastics
        opportunities.
      </p>
    </div>
  );
}
