import { call, put, cancel, take, fork } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { promiseGetTemplates } from 'connectors/TemplateComposer/promises';

import { TEMPLATES_LOAD_STARTED } from '../actions';

import { templatesLoadDone } from '../creators';

const templatesLoadStarted = function* templatesLoadStarted(action) {
  const {
    payload: { page = 1, searchName, sortBy, employeeId },
  } = action;

  try {
    const promiseParams = {
      page,
      searchName,
      sortBy,
      employeeId,
    };

    const data = yield call(promiseGetTemplates, promiseParams);

    yield put(templatesLoadDone(data));
  } catch (error) {
    handleError(error);
    yield put(templatesLoadDone({ templates: [] }));
  }
};

const sagaTemplatesLoadStarted = function* sagaTemplatesLoadStarted() {
  let lastTask;
  while (true) {
    const action = yield take(TEMPLATES_LOAD_STARTED);
    if (lastTask && action.payload.page === 1) {
      yield cancel(lastTask);
    }
    lastTask = yield fork(templatesLoadStarted, action);
  }
};

export default sagaTemplatesLoadStarted;
