import React, { Component } from 'react';

import Validator from 'connectors/Validator';
import Input from 'components/Input';
import Button from 'components/Button';
import Label from 'components/Label';

import styles from './LinkInputs.scss';

class LinkInputs extends Component {
  constructor(props) {
    super(props);

    this.state = { linkAdded: false };
  }

  render() {
    const { links, handleAddArrayInput, handleAddArrayValue, handleDeleteArrayInput } = this.props;

    const { linkAdded } = this.state;

    const deleteIcon = <span>&times;</span>;

    const addLinkInputs =
      links && links.length > 0
        ? links.map((link, key) => {
            const errors = {
              isUrl: [Validator.isUrl({ newValue: link })],
            };

            const linkInputProps = {
              handleInputChange: (event) => handleAddArrayValue(event, 'links'),
              name: key,
              value: link,
              size: 'full',
              placeholder: 'Add link',
              className: styles.inputContainer,
              autoFocus: linkAdded && key === links.length - 1,
              errors,
            };

            const deleteIconProps = {
              className: styles.deleteLink,
              onClick: () => handleDeleteArrayInput(key, 'links'),
            };

            return (
              <div key={key.toString()} className={styles.link}>
                <Input {...linkInputProps} />
                <div {...deleteIconProps}>{deleteIcon}</div>
              </div>
            );
          })
        : null;

    const addLinkButtonProps = {
      className: styles.addLink,
      onClick: (event) => {
        event.preventDefault();
        this.setState({ linkAdded: true });
        handleAddArrayInput('links');
      },
      tertiary: true,
    };

    const addLinkButton = <Button {...addLinkButtonProps}>+ Add Link</Button>;

    return (
      <div className={styles.LinkInputs}>
        <div />
        <div className={styles.links}>
          <Label required className={styles.linkLabel}>
            Press about you and other relevant links
          </Label>
          <div className={styles.linksArray}>
            {addLinkInputs}
            {addLinkButton}
          </div>
        </div>
      </div>
    );
  }
}

export default LinkInputs;
