import { createSelector } from 'reselect';
import { entitySelectorFactory } from 'reduxStore/selectorFactories';
import defaultErrors from 'reduxStore/errors';

const selectEmployeeErrors = createSelector(
  entitySelectorFactory('toolboxEmployers', 'employee'),
  (state, props) => {
    const { match: { params: { employeeId: id } = {} } = {} } = props || {};

    return id;
  },
  (employees, id) => {
    const { byId: { [id]: { errors } = {} } = {} } = employees;

    return errors || defaultErrors.employees;
  }
);

export default selectEmployeeErrors;
