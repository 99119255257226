/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import { Link } from 'react-router-dom';
import { getAltName } from 'utils/urlUtils';

import pic1 from './assets/los-angeles-sales-recruiting-agency-1.png';
import pic2 from './assets/best-sales-recruiters-los-angeles-2.png';
import pic3 from './assets/los-angeles-sales-recruiters-3.png';
import pic4 from './assets/find-top-sales-talent-4.png';

import styles from './LosAngelesContent.scss';

export function LosAngelesContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt={getAltName(pic1)} />
      <h2>The Secret to Optimizing Your Sales Team</h2>
      <p>
        A top sales professional can mean the difference between struggling and excelling as a
        company. But first, you need to find a rainmaker for your Los Angeles company, and
        Rainmakers is the recruiting firm to help you do so.
      </p>
      <p>
        Rainmakers is a sales recruiting firm that connects Los Angeles companies with the sales
        professionals they need for success. We maintain a database of the industry's top talent and
        connect those professionals with hiring companies. Because we help talent showcase their
        best achievements and dig deep into their abilities, we help companies find the salespeople
        that match their needs while also partnering with talent to find the ideal opportunities.
      </p>
      <h2>Why Choose Us as Your Los Angeles Sales Recruiters?</h2>
      <p>
        At Rainmakers, all we do is sales recruiting. This fact means we know the sales world inside
        and out and understand what it takes to succeed. Because we focus only on sales recruiting,
        we are uniquely positioned to shorten your search for your next sales professional.
      </p>
      <p>
        <strong>
          Our team members carefully identify the industry's best talent, and we maintain a database
          of potential applicants with in-depth sales profiles that show more than just the simple
          sales metrics.
        </strong>{' '}
        When you're ready to hire, we have a pool of people who are pre-screened and ready to apply.
      </p>
      <p>
        In addition, our team will help you define exactly what you are looking for in a new sales
        prospect. Finally, we will assist you with fine-tuning your job ad, so you improve the
        chances of finding the right person and finding them quickly.
      </p>
      <p>
        Working with Rainmakers will shorten the time from job placement to new hire. We provide a
        short list of highly qualified people, so your hiring team can focus on interviewing and
        onboarding rather than recruiting. Shortening the process and improving the quality of
        candidates you interview all add up to cost savings for your organization.
      </p>
      <img src={pic2} alt={getAltName(pic2)} />
      <h2>Our Sales Recruitment Process in Los Angeles</h2>
      <p>
        You can expect a streamlined, effective process when you partner with Rainmakers to help you
        find your next sales team member. Here's how it works:
      </p>
      <h3>Step 1: Define the Role</h3>
      <p>
        Our recruiting professionals will help you define precisely what you're looking for in a new
        team member. Then, by refining your job ad and description of the role, you will attract
        better potential candidates.
      </p>
      <h3>Step 2: Get Matched with Talent</h3>
      <p>
        Once you showcase what you're looking for in a candidate, we'll tap into our pool of
        existing talent and our network of potential applicants to find people who fit your role
        perfectly. By pre-screening these people before they apply, you will have a better list of
        qualified people.
      </p>
      <p>
        This step is where Rainmakers stands out. Because we maintain detailed records about each
        candidate that goes beyond a standard LinkedIn profile, CV, or resume, we can screen them
        more thoroughly to find the people who fit your needs well.{' '}
        <strong>
          As a result, our screening and interviewing process saves your hiring team time, and the
          people we present to you are perfectly equipped to meet your company's unique needs.
        </strong>
      </p>
      <h3>Step 3: Hire the Right Person</h3>
      <p>
        This process makes it easier and faster to find a good fit and the best fit. You'll
        interview the best matches possible and hire your new employee quickly.
      </p>
      <img src={pic3} alt={getAltName(pic3)} />
      <h2>Sales Industries We Serve in Los Angeles</h2>
      <p>
        Many industries can benefit from vetted sales team members. For example, the retail,
        medical, and travel industries, as do many others, need sales professionals. Yet the tech
        industry in Los Angeles has the highest demand.
      </p>
      <p>
        Los Angeles is the third-largest market for tech startups in the United States. There are
        nearly 4,000 startups in the city, many of which are within the tech industry. We are
        uniquely positioned at Rainmakers to help these companies grow through qualified, vetted
        sales professionals.
      </p>
      <p>
        We focus our efforts on sales professionals and the companies who need them.{' '}
        <strong>
          We specialize in sales professionals emphasizing the B2B technology industry, making us
          uniquely suited to the needs of this growing demographic in Los Angeles.
        </strong>{' '}
        If you are jumping into this market or are already an established company looking to be more
        competitive, let our team help you connect with the right sales professionals to push you
        toward success.
      </p>
      <h2>Los Angeles Sales Job Market Insights</h2>
      <p>
        The Los Angeles job market shows signs of strength, and employment numbers are up. In 2023,
        employment in California was up 1.7%. Los Angeles is slightly behind this but is seeing
        growth in the healthcare and tech industries.
      </p>
      <p>
        Though the area is seeing signs of growth, the overall job market could be healthier than
        other major metro areas. The many creative and entertainment businesses in the area are
        still feeling the effects of the pandemic and the changes to their industry. However,
        technology is starting to take over, and with it comes a demand for sales professionals.
        Today, the largest industries in need of sales professionals in the Los Angeles area are:
      </p>
      <ul>
        <li>Information Technology</li>
        <li>Bioscience</li>
        <li>Food Manufacturing</li>
        <li>Trade and Logistics</li>
      </ul>
      <p>
        Finally, the Los Angeles area is seeing a considerable increase in venture-backed companies,
        most in the tech industry. Year-on-year, the area has seen a 20% growth in this region.
        Though New York and the Bay area have more of these startups, their growth has been slower.
      </p>
      <p>
        What does all of this mean? For sales professionals, Los Angeles is an excellent place to
        look for a new position. Businesses looking to hire will face significant competition for
        top talent. Working with Rainmakers can add a competitive advantage for both talent and
        companies, leading to a better hiring process.
      </p>
      <Link to="/employer-signup/" title="Apply Now">
        <img src={pic4} alt={getAltName(pic4)} />
      </Link>
      <h2>How to Get Started with Our Los Angeles Sales Recruiters</h2>
      <p>
        If you are ready to start building a sales team that will push you past the competition to
        find success in the growing markets of the Los Angeles region, Rainmakers is the partner you
        want on your side. Tap into our solid talent pool and work with our proactive team to find
        the best candidates. So{' '}
        <a href="https://www.rainmakers.co/employer-signup/">sign up with Rainmakers</a> today, and
        start screening the best candidates for your open sales positions.
      </p>
    </div>
  );
}
