import { createSelector } from 'reselect';
import { getApp } from 'shared/store/app/selectors';

const selectNeedReload = createSelector(
  getApp,
  (state) => state.candidateProfile || {},
  (candidateProfile = {}) => candidateProfile.needReload
);

export default selectNeedReload;
