import React from 'react';

import Block from 'components/Block';
import Content from 'components/Content';
import ContentTitle from 'components/ContentTitle';
import FormattedName from 'components/FormattedName';
import { ReferralSourceSelect } from 'components/ReferralSourceSelect';
import TableOverlay from 'components/TableOverlay';

import { useImageField } from 'hooks';

import styles from './CandidateReferrals.scss';

const CandidateReferrals = ({
  actions: { patchResource },
  user: { attributes: { id, referredBySource, referredByEmail, referredByCode } = {} } = {},
  referredByUser,
  referredByUserCurrentProfile,
}) => {
  const { attributes: { email } = {} } = referredByUser || {};

  const { imageComponent } = useImageField({
    model: referredByUserCurrentProfile.attributes
      ? referredByUserCurrentProfile
      : { attributes: {} },
    avatarClass: styles.avatarWrapper,
  });

  const handleOnChange = (value) => {
    patchResource({
      attributes: {
        referredBySource: value,
      },
      id,
      slice: 'toolboxCandidates',
      type: 'users',
    });
  };

  const { firstName, lastName } = referredByUserCurrentProfile.attributes || {};

  const noReferralInfo = !referredByUser && !referredByEmail && !referredByCode;
  const referralProfileLoading = referredByUser && !referredByUserCurrentProfile.attributes;

  return (
    <Block addWhiteBG={true} boxShadow={true} addPadding={true}>
      <ContentTitle>REFERRALS</ContentTitle>
      {!noReferralInfo ? (
        <Content className={styles.subheader}>
          <div>
            <strong>Referred By:</strong>
          </div>
        </Content>
      ) : null}
      {noReferralInfo ? null : referralProfileLoading ? (
        <TableOverlay table="inPlace" />
      ) : (
        <Content className={styles.flexContainer}>
          <div className={styles.imageContainer}>{imageComponent}</div>
          <div className={styles.dataContainer}>
            {(firstName || lastName) && (
              <div>
                <strong>
                  <FormattedName firstName={firstName} lastName={lastName} />
                </strong>
              </div>
            )}
            <div>{email || referredByEmail || `Referral code: ${referredByCode}`}</div>
          </div>
        </Content>
      )}
      <Content className={styles.flexContainer}>
        <div className={styles.detailContainer}>
          <strong>Source:</strong>
          <div>
            <ReferralSourceSelect
              defaultReferralSource={referredBySource}
              onChange={handleOnChange}
            />
          </div>
        </div>
      </Content>
    </Block>
  );
};

export default CandidateReferrals;
