const config = Object.assign({
  host: process.env.HOST || '0.0.0.0',
  port: process.env.PORT || 8888,

  cookies: {
    /**
     *
     * # of milliseconds/year =
     *     365 days/year
     *  x   24 hours/day
     *  x   60 minutes/hour
     *  x   60 seconds/minute
     *  x 1000 milliseconds/second
     *
     */

    maxAge: process.env.COOKIES_MAX_AGE || 365 * 24 * 60 * 60 * 1000,

    secure: process.env.COOKIES_SECURE || false,
  },

  gpKey: process.env.GOOGLE_PLACES_KEY,

  segmentKey: process.env.SEGMENT_KEY,

  apiBaseURL: process.env.API_BASE_URL,

  apiBaseVersion: process.env.API_BASE_VERSION,

  cdnPath: process.env.CDN_PATH,

  app: {
    title: 'Rainmakers',

    description: 'Rainmakers connects Top Sales Candidates with Top Sales Jobs.',

    head: {
      /**
       *
       * Note: html attributes are set directly on the <html> tag
       *
       */

      defaultTitle: 'Sales Jobs for Top Sales Talent.',

      titleTemplate: '%s',

      meta: [
        {
          charset: 'utf-8',
        },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1',
        },
        {
          name: 'description',
          content: 'Rainmakers connects Top Sales Candidates with Top Sales Jobs.',
        },
        {
          name: 'theme-color',
          content: '#3c3f46',
        },
        /**
         *
         * Prevents numbers from being accidentally interpretted as a dialable number.
         *
         */
        {
          name: 'format-detection',
          content: 'telephone=no',
        },
        {
          property: 'og:url',
          content: `https://${process.env.HOST}`,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:site_name',
          content: 'Rainmakers',
        },
        {
          property: 'og:locale',
          content: 'en_US',
        },
        {
          property: 'og:title',
          content: 'Sales Jobs for Top Sales Talent',
        },
        {
          property: 'og:description',
          content: 'Rainmakers connects Top Sales Candidates with Top Sales Jobs.',
        },
        {
          property: 'og:image',
          content: require('shared/assets/candidate-links-logo.png'),
        },
        {
          property: 'og:image:width',
          content: '500',
        },
        {
          property: 'og:image:height',
          content: '360',
        },

        /**
         *
         * Twitter
         *
         */

        {
          property: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          property: 'twitter:site',
          content: '@rainmakers',
        },
        {
          property: 'twitter:creator',
          content: '@rainmakers',
        },
      ],
      /**
       *
       * Structured data
       *
       */

      script: [
        {
          type: 'application/ld+json',

          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',

            '@type': 'Organization',

            'name': 'Rainmakers',

            'url': `https://${process.env.HOST}`,

            'logo': require('shared/assets/rm-logo-short.png'),

            'sameAs': [
              'https://www.facebook.com/rainmakersonly/',
              'https://www.linkedin.com/company/17877072/',
            ],
          }),
        },
      ],
    },
    candidateHead: {
      /**
       *
       * Note: html attributes are set directly on the <html> tag
       *
       */

      defaultTitle: 'Sales Jobs for Top Sales Talent.',

      titleTemplate: '%s',

      meta: [
        {
          charset: 'utf-8',
        },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1',
        },
        {
          name: 'description',
          content: 'Rainmakers connects Top Sales Candidates with Top Sales Jobs.',
        },
        {
          name: 'theme-color',
          content: '#3c3f46',
        },
        /**
         *
         * Prevents numbers from being accidentally interpretted as a dialable number.
         *
         */
        {
          name: 'format-detection',
          content: 'telephone=no',
        },
        {
          property: 'og:url',
          content: `https://${process.env.HOST}`,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:site_name',
          content: 'Rainmakers',
        },
        {
          property: 'og:locale',
          content: 'en_US',
        },
        {
          property: 'og:title',
          content: 'Sales Jobs for Top Sales Talent',
        },
        {
          property: 'og:description',
          content: 'Rainmakers connects Top Sales Candidates with Top Sales Jobs.',
        },
        {
          property: 'og:image',
          content: require('shared/assets/candidate-profile-logo.png'),
        },
        {
          property: 'og:image:width',
          content: '75',
        },
        {
          property: 'og:image:height',
          content: '75',
        },

        /**
         *
         * Twitter
         *
         */

        {
          property: 'twitter:card',
          content: 'summary',
        },
        {
          property: 'twitter:site',
          content: '@rainmakers',
        },
        {
          property: 'twitter:creator',
          content: '@rainmakers',
        },
      ],
      /**
       *
       * Structured data
       *
       */

      script: [
        {
          type: 'application/ld+json',

          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',

            '@type': 'Organization',

            'name': 'Rainmakers',

            'url': `https://${process.env.HOST}`,

            'logo': require('shared/assets/rm-logo-short-2.png'),

            'sameAs': [
              'https://www.facebook.com/rainmakersonly/',
              'https://www.linkedin.com/company/17877072/',
            ],
          }),
        },
      ],
    },
    employerHead: {
      /**
       *
       * Note: html attributes are set directly on the <html> tag
       *
       */

      defaultTitle: 'Sales Jobs for Top Sales Talent.',

      titleTemplate: '%s',

      meta: [
        {
          charset: 'utf-8',
        },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1',
        },
        {
          name: 'description',
          content: 'Rainmakers connects Top Sales Candidates with Top Sales Jobs.',
        },
        {
          name: 'theme-color',
          content: '#3c3f46',
        },
        /**
         *
         * Prevents numbers from being accidentally interpretted as a dialable number.
         *
         */
        {
          name: 'format-detection',
          content: 'telephone=no',
        },
        {
          property: 'og:url',
          content: `https://${process.env.HOST}`,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:site_name',
          content: 'Rainmakers',
        },
        {
          property: 'og:locale',
          content: 'en_US',
        },
        {
          property: 'og:title',
          content: 'Sales Jobs for Top Sales Talent',
        },
        {
          property: 'og:description',
          content: 'Rainmakers connects Top Sales Candidates with Top Sales Jobs.',
        },
        {
          property: 'og:image',
          content: require('shared/assets/employer-links-logo.jpg'),
        },
        {
          property: 'og:image:width',
          content: '500',
        },
        {
          property: 'og:image:height',
          content: '360',
        },

        /**
         *
         * Twitter
         *
         */

        {
          property: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          property: 'twitter:site',
          content: '@rainmakers',
        },
        {
          property: 'twitter:creator',
          content: '@rainmakers',
        },
      ],
      /**
       *
       * Structured data
       *
       */

      script: [
        {
          type: 'application/ld+json',

          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',

            '@type': 'Organization',

            'name': 'Rainmakers',

            'url': `https://${process.env.HOST}`,

            'logo': require('shared/assets/rm-logo-short-2.png'),

            'sameAs': [
              'https://www.facebook.com/rainmakersonly/',
              'https://www.linkedin.com/company/17877072/',
            ],
          }),
        },
      ],
    },
  },
});

export default config;
