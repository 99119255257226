import { call, put, select, take } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { promiseRequisitionOpenings } from 'pages/EmployeePortal/ManageReqsPage/promises';

import { selectUser } from 'connectors/User/redux/selectors';

import { REQUISITION_DETAILS_LOAD_STARTED } from '../actions';

import { requisitionDetailsLoadDone } from '../creators';

const sagaRequisitionDetailsLoadStarted = function* sagaRequisitionDetailsLoadStarted() {
  while (true) {
    const {
      payload: { page = 1, employerId: adminEmployerId },
    } = yield take(REQUISITION_DETAILS_LOAD_STARTED);

    const { currentProfile = {} } = yield select(selectUser);

    const employerId = currentProfile.employerId || adminEmployerId;

    try {
      const promiseParams = {
        employerId,
        includeSet: 'requirement_creator',
        page,
        requisitionCandidateId: 'null',
        sortBy: 'created_at_desc',
        state: 'open',
        suggestionSetId: 'null',
      };

      const data = yield call(promiseRequisitionOpenings, promiseParams);

      let { page: currentPage } = data;

      const { requisitionDetails, total, totalPages } = data;

      yield put(
        requisitionDetailsLoadDone({
          requisitionDetails,
          page: currentPage,
          total,
        })
      );

      while (currentPage < totalPages) {
        const newPromiseParams = {
          ...promiseParams,
          page: currentPage + 1,
        };

        const { requisitionDetails, page, total } = yield call(
          promiseRequisitionOpenings,
          newPromiseParams
        );

        yield put(
          requisitionDetailsLoadDone({
            requisitionDetails,
            page,
            total,
          })
        );
        currentPage += 1;
      }
    } catch (error) {
      handleError(error);
    }
  }
};

export default sagaRequisitionDetailsLoadStarted;
