import React, { Component } from 'react';
import classnames from 'classnames';

import { HIRING_STATUS_SOURCE } from 'connectors/Defaults/constants';

import Bold from 'components/Bold';
import Bolder from 'components/Bolder';
import Content from 'components/Content';
import FontIcon from 'components/FontIcon';
import InputCheckboxMany from 'components/InputCheckboxMany';
import TableOverlay from 'components/TableOverlay';

import ProfileCard from './components/ProfileCard';
import ChecklistIcon from './components/ChecklistIcon';
import GetListed from './components/GetListed';

import styles from './Checklist.scss';

const STATUS_STRINGS = {
  inactive: [
    <Content key="inactivePart1">
      You&apos;re marked as <Bolder>&ldquo;Not looking&rdquo;</Bolder>, which means you&apos;ll miss
      potential opportunities for cool jobs.
    </Content>,
    <Content key="inactivePart2" className={styles.padded}>
      If you&apos;d like to change this, select <Bolder>&ldquo;Actively interviewing&rdquo;</Bolder>{' '}
      or <Bolder>&ldquo;Just browsing&rdquo;</Bolder> from the options below.
    </Content>,
  ],
  active: [
    <Content key="activePart1">
      You&apos;re marked as <Bolder>&ldquo;Actively interviewing&rdquo;</Bolder>, which means
      recruiters can potentially see your profile and reach out to you with opportunities.
    </Content>,
  ],
  passive: [
    <Content key="passivePart1">
      You&apos;re marked as <Bolder>&ldquo;Just browsing&rdquo;</Bolder>, which means recruiters can
      potentially see your profile and reach out to you with opportunities.
    </Content>,
  ],
};

class Checklist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showOne: false,
      showTwo: false,
      showThree: false,
      showFour: false,
    };
  }

  componentDidMount() {
    const { handleCandidateInputChange, candidate } = this.props;

    const { approvedViewSeen = true, applicationStatus: appStatus } = candidate || {};

    if (appStatus === 'approved' && !approvedViewSeen) {
      handleCandidateInputChange({ target: { name: 'approvedViewSeen', value: true } });
    }
  }

  render() {
    const {
      candidate,
      candidateProfilePatchDone,
      handleBatchRequest,
      handleBatchRerequest,
      handleCandidateInputChange,
      setBackLink,
      profileCards = [],
      profileCardsLoading,
      batchRequest,
      batchRequestLoading,
    } = this.props;

    const { showOne, showTwo, showThree, showFour } = this.state;

    const {
      hiringStatus,
      applicationStatus: appStatus,
      profileLocked = false,
      showProfileCards,
    } = candidate || {};

    const hasCards = !profileCardsLoading && profileCards && profileCards.length > 0;

    const allOptional = hasCards && profileCards.every((card) => card.optional);

    const hasProfileCards = hasCards && !profileLocked;

    const setBackLinkToHome = () =>
      setBackLink({
        link: '/candidate/home',
        title: 'Back to checklist',
      });

    const profCards = hasProfileCards
      ? profileCards.map((profileCard) => {
          const profCardProps = {
            ...profileCard,
            setBackLinkToHome,
          };

          return <ProfileCard key={profileCard.id} {...profCardProps} />;
        })
      : null;

    const profileCardsInfo =
      hasProfileCards && !allOptional ? (
        <Content className={styles.secondary}>
          You have incomplete items in your profile. You&apos;ll need to finish these tasks before
          you can be listed on the platform.
        </Content>
      ) : null;

    const profileCardsOptionalInfo =
      hasProfileCards && allOptional ? (
        <Content className={styles.secondary}>
          Your profile is almost complete! There are a few more things you can do to make yourself
          stand out from the crowd!
        </Content>
      ) : null;

    const profileCardsCopy = profileCardsInfo || profileCardsOptionalInfo || null;

    const profileCardsContent = hasProfileCards ? (
      <div className={styles.profileCardsContainer} data-testid="profileCardsContainer">
        {profCards}
      </div>
    ) : (
      <Content className={styles.secondary}>
        Your profile is complete! Don&apos;t forget: Keep your profile up-to-date to get matched
        with the best opportunities!
      </Content>
    );

    const itemOneSetting =
      showOne ||
      (hasProfileCards && !allOptional) ||
      (hasProfileCards && allOptional && showProfileCards);

    const profileCardsFinal = itemOneSetting ? profileCardsContent : null;

    const checklistArray = [
      {
        loading: profileCardsLoading,
        complete: !hasProfileCards || profileLocked || allOptional,
        open: hasProfileCards && !allOptional,
        idx: 1,
      },
      {
        loading: profileCardsLoading,
        complete: hiringStatus !== 'inactive',
        open: (!hasProfileCards || allOptional) && hiringStatus === 'inactive',
        idx: 2,
      },
      {
        loading: profileCardsLoading,
        complete: appStatus === 'approved',
        open:
          (!hasProfileCards || allOptional) &&
          hiringStatus !== 'inactive' &&
          appStatus !== 'approved',
        idx: 3,
      },
      {
        loading: profileCardsLoading || batchRequestLoading,
        open: false,
        complete:
          batchRequest.state &&
          (batchRequest.state === 'accepted' || batchRequest.state === 'active'),
        idx: 4,
      },
    ];

    // Item 4 -- Get Listed
    const getListedProps = {
      batchRequest,
      handleBatchRequest,
      handleBatchRerequest,
    };

    const showGetListedStates = ['active', 'accepted', 'pending'];

    const showGetListedContent =
      showGetListedStates.includes(batchRequest.state) ||
      ((!hasProfileCards || allOptional) &&
        hiringStatus !== 'inactive' &&
        appStatus === 'approved');

    const getListedContent = showGetListedContent ? <GetListed {...getListedProps} /> : null;

    const getListedFinal = showFour || showGetListedContent ? getListedContent : null;

    // Item 2 -- Hiring Status
    const hiringStatusInputProps = {
      required: true,
      source: HIRING_STATUS_SOURCE.slice(0, 2),
      name: 'hiringStatus',
      handleInputChange: handleCandidateInputChange,
      selectedValues: hiringStatus !== '' ? [hiringStatus] : [],
      blockStyle: true,
      hasLabel: true,
      disabled: profileLocked,
    };

    const statusString = STATUS_STRINGS[hiringStatus];

    const replaceSecondLine =
      batchRequest.state && (batchRequest.state === 'accepted' || batchRequest.state === 'active');

    const acceptedBatchContent =
      replaceSecondLine && batchRequest.state === 'accepted' ? (
        <Content key="acceptedBatch" className={styles.padded}>
          Since you are on deck to be listed for new job opportunities, you cannot change your
          status at this time. Contact{' '}
          <a href="mailto:support@rainmakers.co">support@rainmakers.co</a> for more help.
        </Content>
      ) : null;

    const activeBatchContent =
      replaceSecondLine && batchRequest.state === 'active' ? (
        <Content key="activeBatch" className={styles.padded}>
          Since you are currently listed for new job opportunities, you cannot change your status at
          this time. Contact <a href="mailto:support@rainmakers.co">support@rainmakers.co</a> for
          more help.
        </Content>
      ) : null;

    // If a profile is locked, need to change the message
    if (replaceSecondLine && statusString) {
      const newContent = acceptedBatchContent || activeBatchContent;
      statusString.splice(1, 1, newContent);
    }

    const showHiringStatusContent =
      hiringStatus === 'inactive' && (!hasProfileCards || allOptional);

    const hiringStatusContent = (
      <Content className={styles.secondary}>
        {statusString}
        <InputCheckboxMany {...hiringStatusInputProps} />
      </Content>
    );

    const hiringStatusFinal = showTwo || showHiringStatusContent ? hiringStatusContent : null;

    // Item 3 -- Get Approved
    const showGetApprovedContent = (!hasProfileCards || allOptional) && hiringStatus !== 'inactive';

    const getApprovedEnabledContent =
      appStatus !== 'approved' ? (
        <Content className={styles.secondary}>
          We review all profiles before listing them on the platform. Once approved, you&apos;ll be
          able to request to be shown to great companies!
        </Content>
      ) : (
        <Content className={styles.secondary}>
          You&apos;re approved! We like what we see and think that you have a great sales profile.
          Now you are ready to be shown to great companies!
        </Content>
      );

    const getApprovedContent = showGetApprovedContent ? (
      getApprovedEnabledContent
    ) : (
      <Content className={styles.secondary}>
        Your profile will be reviewed by someone on the Rainmakers team. If approved, you will
        receive an email and/or call from a Rainmakers Concierge.
      </Content>
    );

    const getApprovedFinal = showThree || showGetApprovedContent ? getApprovedContent : null;

    // Toggles
    const showOneToggleProps = {
      className: styles.toggle,
      onClick: () => {
        if (showProfileCards && allOptional && hasProfileCards) {
          candidateProfilePatchDone({
            candidateProfile: {
              showProfileCards: false,
            },
          });
        } else {
          this.setState({ showOne: !showOne });
        }
      },
    };

    const showTwoToggleProps = {
      className: styles.toggle,
      onClick: () => this.setState({ showTwo: !showTwo }),
    };

    const showThreeToggleProps = {
      className: styles.toggle,
      onClick: () => this.setState({ showThree: !showThree }),
    };

    const showFourToggleProps = {
      className: styles.toggle,
      onClick: () => this.setState({ showFour: !showFour }),
    };

    // Title Classes
    const titleOneClasses = classnames({
      [styles.danger]: checklistArray[0].open && !checklistArray[0].complete,
      [styles.normal]: !checklistArray[0].open && !checklistArray[0].complete,
      [styles.gray]: checklistArray[0].complete,
      [styles.optional]: checklistArray[0].optional,
    });
    const titleTwoClasses = classnames({
      [styles.danger]: checklistArray[1].open && !checklistArray[1].complete,
      [styles.normal]: !checklistArray[1].open && !checklistArray[1].complete,
      [styles.gray]: checklistArray[1].complete,
    });
    const titleThreeClasses = classnames({
      [styles.danger]: checklistArray[2].open && !checklistArray[2].complete,
      [styles.normal]: !checklistArray[2].open && !checklistArray[2].complete,
      [styles.gray]: checklistArray[2].complete,
    });
    const titleFourClasses = classnames({
      [styles.danger]: checklistArray[3].open && !checklistArray[3].complete,
      [styles.normal]: !checklistArray[3].open && !checklistArray[3].complete,
      [styles.gray]: checklistArray[3].complete,
    });

    const showGetListedCaret =
      (!hasProfileCards || allOptional) && hiringStatus !== 'inactive' && appStatus === 'approved';

    const getListedCaret = showGetListedCaret ? (
      <FontIcon iconName={showFour || showGetListedContent ? 'caret-up' : 'caret-down'} />
    ) : null;

    // Final Content
    const checklistContent = [
      <div key="item1" className={styles.checklistItemContainer}>
        <ChecklistIcon {...checklistArray[0]} />
        <Content className={styles.contentContainer}>
          <div {...showOneToggleProps}>
            <Bold className={titleOneClasses}>Complete your profile</Bold>
            <FontIcon iconName={itemOneSetting ? 'caret-up' : 'caret-down'} />
          </div>
          {profileCardsCopy}
          {profileCardsFinal}
        </Content>
      </div>,
      <div key="item2" className={styles.checklistItemContainer}>
        <ChecklistIcon {...checklistArray[1]} />
        <Content className={styles.contentContainer}>
          <div {...showTwoToggleProps}>
            <Bold className={titleTwoClasses}>Update your status</Bold>
            <FontIcon iconName={showTwo || showHiringStatusContent ? 'caret-up' : 'caret-down'} />
          </div>
          {hiringStatusFinal}
        </Content>
      </div>,
      <div key="item3" className={styles.checklistItemContainer}>
        <ChecklistIcon {...checklistArray[2]} />
        <Content className={styles.contentContainer}>
          <div {...showThreeToggleProps}>
            <Bold className={titleThreeClasses}>Get approved</Bold>
            <FontIcon iconName={showThree || showGetApprovedContent ? 'caret-up' : 'caret-down'} />
          </div>
          {getApprovedFinal}
        </Content>
      </div>,
      <div key="item4" className={styles.checklistItemContainer}>
        <ChecklistIcon {...checklistArray[3]} />
        <Content className={styles.contentContainer}>
          <div {...showFourToggleProps}>
            <Bold className={titleFourClasses}>Get listed</Bold>
            {getListedCaret}
          </div>
          {getListedFinal}
        </Content>
      </div>,
    ];

    return (
      <div className={styles.checklistContainer} data-testid="candidateChecklist">
        {profileCardsLoading ? <TableOverlay table="image" /> : checklistContent}
      </div>
    );
  }
}

export default Checklist;
