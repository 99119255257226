const prefix = 'Rainmakers/TemplateComposer/';

export const TEMPLATES_LOAD_STARTED = `${prefix}TEMPLATES_LOAD_STARTED`;
export const TEMPLATES_LOAD_DONE = `${prefix}TEMPLATES_LOAD_DONE`;

export const TEMPLATE_SAVE_STARTED = `${prefix}TEMPLATE_SAVE_STARTED`;
export const TEMPLATE_SAVE_DONE = `${prefix}TEMPLATE_SAVE_DONE`;

export const TEMPLATE_DELETE_STARTED = `${prefix}TEMPLATE_DELETE_STARTED`;
export const TEMPLATE_DELETE_DONE = `${prefix}TEMPLATE_DELETE_DONE`;

export const TEMPLATE_UPDATE_STARTED = `${prefix}TEMPLATE_UPDATE_STARTED`;
export const TEMPLATE_UPDATE_DONE = `${prefix}TEMPLATE_UPDATE_DONE`;
