import { take } from 'redux-saga/effects';

import api from 'api/api';

import { SAVE_REQUEST_INTERCEPTOR, REMOVE_REQUEST_INTERCEPTOR } from 'shared/store/app/actions';

const sagaRemoveRequestInterceptor = function* sagaRemoveRequestInterceptor() {
  while (true) {
    // We call SAVE_REQUEST_INTERCEPTOR immediately after login to save
    // authInterceptor and then wait for REMOVE_REQUEST_INTERCEPTOR
    const {
      payload: { authInterceptor },
    } = yield take(SAVE_REQUEST_INTERCEPTOR);

    yield take(REMOVE_REQUEST_INTERCEPTOR);

    api.interceptors.request.eject(authInterceptor);
  }
};

export default sagaRemoveRequestInterceptor;
