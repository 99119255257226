import { getEntity } from 'api/apiEntity';

const promiseGetBookmark = ({ candidateId }) => {
  const params = {
    candidate_id: [candidateId],
  };

  const onFulfilled = (response) => {
    const {
      data: { bookmarks = [] },
    } = response;

    if (bookmarks.length > 0) {
      return bookmarks[0];
    }
    return null;
  };

  const args = {
    type: 'bookmarks',
    config: {
      params,
    },
  };

  return getEntity(args).then(onFulfilled);
};

export default promiseGetBookmark;
