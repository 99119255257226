import React, { useState } from 'react';
import classnames from 'classnames';

import { timeAgoInHours } from 'utils/dateUtils';
import { camelToHuman } from 'utils/generalUtils';

import CandidateData from 'components/CandidateData';
import FormattedArray from 'components/FormattedArray';
import FormattedDate from 'components/FormattedDate';
import TableCell from 'components/Table/components/TableCell';

import ToolboxApproachesActionDropdown from '../ToolboxApproachesActionDropdown';

import styles from '../ToolboxApproachesTable/ToolboxApproachesTable.scss';

const ToolboxApproachesRow = ({
  actions,
  approach,
  approach: {
    attributes: {
      attentionRequired = false,
      attentionRequiredReasons = [],
      createdAt,
      id: reqCandidateId,
      lastActivityAt,
      lastActivityType,
      lastActivityByType,
      state,
    },
  } = {},
  candidate,
  candidate: { attributes: { firstName, lastName } = {} } = {},
  employer: { attributes: { name: employerName } = {} } = {},
  requisitionOpening: { attributes: { role, externalName = '' } = {} } = {},
  messageGroup: { attributes: { messagesCount } = {} } = {},
  openApproachEditor,
  privateReqCanFeedbackCandidate,
  privateReqCanFeedbackEmployee,
}) => {
  const [showReasons, setShowReasons] = useState(false);

  const candidateDataProps = {
    onClick: () => openApproachEditor(reqCandidateId),
    candidate,
    subtitle: role,
  };

  const candidateDataContent = (
    <div className={styles.candidateContent}>
      <CandidateData {...candidateDataProps} />
    </div>
  );

  const actionsProps = {
    actions,
    firstName,
    lastName,
    privateReqCanFeedbackCandidate,
    privateReqCanFeedbackEmployee,
    requisitionCandidate: approach,
    slice: 'toolboxApproaches',
    state,
  };

  const lastActivityInHours = timeAgoInHours(lastActivityAt);

  const lastActivityClasses = classnames(styles.lastActivityContent, {
    [styles.okay]: lastActivityAt && lastActivityInHours < 24,
    [styles.notice]: lastActivityInHours >= 24 && lastActivityInHours < 48,
    [styles.warning]: lastActivityInHours >= 48,
  });

  const messagesCountContent = (
    <span className={styles.messagesCount}>({messagesCount || '0'})</span>
  );

  const lastActivityDate = (
    <div className={lastActivityClasses}>
      <FormattedDate timeAgo timeAgoFormat="relativeDays" date={lastActivityAt} parse />{' '}
      {messagesCountContent}
    </div>
  );

  const createdAtContent = (
    <div className={styles.lastActivityContent}>
      <FormattedDate format="M/D/YY" date={createdAt} parse />
    </div>
  );

  const lastActivityByTypeContent = lastActivityByType === 'RainmakersAdmin' ? '(RM)' : '';

  const lastActivityTypeContent = lastActivityType ? (
    <div className={styles.lastActivityContent}>
      {`${lastActivityType} ${lastActivityByTypeContent}`}
    </div>
  ) : (
    <div className={styles.lastActivityContent} />
  );

  const employerNameContent = <div className={styles.lastActivityContent}>{employerName}</div>;

  const roleContent = <div className={styles.lastActivityContent}>{externalName}</div>;

  const attentionRequiredProps = {
    onMouseEnter: () => setShowReasons(true),
    onMouseLeave: () => setShowReasons(false),
    className: showReasons ? styles.iconActive : styles.iconDanger,
  };

  const attentionRequiredReasonsContent =
    showReasons && attentionRequiredReasons.length > 0 ? (
      <div className={styles.reasonsModal}>
        <FormattedArray array={attentionRequiredReasons.map((str) => camelToHuman(str))} />
      </div>
    ) : null;

  const attentionRequiredContent = attentionRequired ? (
    <div {...attentionRequiredProps}>!{attentionRequiredReasonsContent}</div>
  ) : (
    ''
  );

  const stateContent = (
    <div className={styles.stateContent}>
      {camelToHuman(state)}
      {attentionRequiredContent}
    </div>
  );

  const actionsContent = (
    <div className={styles.actionsContent}>
      <ToolboxApproachesActionDropdown {...actionsProps} />
    </div>
  );

  return [
    <TableCell key={1}>{candidateDataContent}</TableCell>,
    <TableCell key={2}>{roleContent}</TableCell>,
    <TableCell key={3}>{employerNameContent}</TableCell>,
    <TableCell key={4}>{createdAtContent}</TableCell>,
    <TableCell key={5}>{stateContent}</TableCell>,
    <TableCell key={6}>{lastActivityDate}</TableCell>,
    <TableCell key={7}>{lastActivityTypeContent}</TableCell>,
    <TableCell noFlex key={8}>
      {actionsContent}
    </TableCell>,
  ];
};

export default ToolboxApproachesRow;
