export const GO_TO_HOME_STATUSES = [
  'added_video',
  'skipped_video',
  'admin_created',
  'bullhorn_import',
];

export const ONBOARDING_ROUTES_MAP = {
  applied: '/set-requirements',
  applied_forum: '/set-requirements',
  set_requirements: '/create-profile',
  created_profile: '/add-sales-info',
  added_sales_info: '/add-video',
};

export const DEFAULT_LOGIN_LINKS = {
  RainmakersAdmin: '/toolbox/candidates',
  Employee: '/featured',
  Candidate: '/candidate/home',
};
