import { handleActions } from 'redux-actions';

import {
  NEW_PASSWORD_STARTED,
  NEW_PASSWORD_ERROR,
  NEW_PASSWORD_DONE,
  CONFIRM_PASSWORD_DONE,
  NEW_PASSWORD_ERROR_RESET,
} from '../actions';

const initialState = {
  newPasswordErrors: '',
  newPasswordDone: false,
};

const handleNewPasswordStarted = (state = initialState) => ({
  ...state,
});

const handleNewPasswordDone = (state = initialState) => ({
  ...state,
  newPasswordDone: true,
});

const handleNewPasswordError = (state = initialState, action) => {
  const {
    payload: { errors },
  } = action;

  return {
    ...state,
    newPasswordErrors: errors,
    newPasswordDone: false,
  };
};

const handleNewPasswordErrorReset = () => initialState;

const actionHandlers = {
  [NEW_PASSWORD_STARTED]: handleNewPasswordStarted,
  [NEW_PASSWORD_DONE]: handleNewPasswordDone,
  [CONFIRM_PASSWORD_DONE]: handleNewPasswordDone,
  [NEW_PASSWORD_ERROR]: handleNewPasswordError,
  [NEW_PASSWORD_ERROR_RESET]: handleNewPasswordErrorReset,
};

const reduceNewPassword = handleActions(actionHandlers, initialState);

export default reduceNewPassword;
