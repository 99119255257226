import { MainContent, ProspectingContent } from './components';

export const SKILL_PAGE_MAIN = 'main';
export const SKILL_PAGE_PROSPECTING = 'prospecting';
export const SKILL_PAGE_UPSELLING = 'upselling';
export const SKILL_PAGE_MANAGING = 'managing';
export const SKILL_PAGE_COMMUNICATION = 'communication';
export const SKILL_PAGE_LISTENING = 'listening';
export const SKILL_PAGE_EMOTIONAL_INT = 'emotional-intelligence';
export const SKILL_PAGE_NEGOTIATION = 'negotiation';
export const SKILL_PAGE_DOMAIN_EXPERTISE = 'domain-expertise';

export default {
  [SKILL_PAGE_MAIN]: {
    title: 'Essential Sales Abilities to Highlight on Your Sales Resume',
    sideNavName: 'Top Sales Skills',
    h1: 'Essential Sales Abilities to Highlight on Your Sales Resume',
    description:
      'Prospecting, upselling, cold-calling, emotional intelligence, and domain expertise are among the top skills hiring managers want to see when interviewing salespeople.',
    url: `https://${process.env.HOST}/top-skills/`,
    localUrl: '/top-skills/',
    header: 'Top Sales Skills',
    content: MainContent,
    sideNav: false,
  },
  [SKILL_PAGE_LISTENING]: {
    title: '',
    sideNavName: 'Active Listening',
    h1: '',
    description: '',
    url: `https://${process.env.HOST}/top-skills/#listening`,
    localUrl: '/top-skills/#listening',
    header: 'Active Listening',
    content: null,
    sideNav: true,
  },
  [SKILL_PAGE_COMMUNICATION]: {
    title: '',
    sideNavName: 'Communication',
    h1: '',
    description: '',
    url: `https://${process.env.HOST}/top-skills/#canaging`,
    localUrl: '/top-skills/#communication',
    header: 'Communication',
    content: null,
    sideNav: true,
  },
  [SKILL_PAGE_DOMAIN_EXPERTISE]: {
    title: '',
    sideNavName: 'Domain Expertise',
    h1: '',
    description: '',
    url: `https://${process.env.HOST}/top-skills/#domain-expertise`,
    localUrl: '/top-skills/#domain-expertise',
    header: 'Domain Expertise',
    content: null,
    sideNav: true,
  },
  [SKILL_PAGE_EMOTIONAL_INT]: {
    title: '',
    sideNavName: 'Emotional Intelligence',
    h1: '',
    description: '',
    url: `https://${process.env.HOST}/top-skills/#emotional-intelligence`,
    localUrl: '/top-skills/#emotional-intelligence',
    header: 'Emotional Intelligence',
    content: null,
    sideNav: true,
  },
  [SKILL_PAGE_MANAGING]: {
    title: '',
    sideNavName: 'Managing',
    h1: '',
    description: '',
    url: `https://${process.env.HOST}/top-skills/#managing`,
    localUrl: '/top-skills/#managing',
    header: 'Managing',
    content: null,
    sideNav: true,
  },
  [SKILL_PAGE_NEGOTIATION]: {
    title: '',
    sideNavName: 'Negotiation',
    h1: '',
    description: '',
    url: `https://${process.env.HOST}/top-skills/#negotiation`,
    localUrl: '/top-skills/#negotiation',
    header: 'Negotiation',
    content: null,
    sideNav: true,
  },
  [SKILL_PAGE_PROSPECTING]: {
    title: 'Prospecting In Technology Sales',
    sideNavName: 'Prospecting',
    description:
      'Prospecting is an essential skill for a salesperson because it helps them to find new business opportunities. Specifically, it allows them to identify and target the right customers, build relationships, and ultimately increase sales.',
    url: `https://${process.env.HOST}/jobs/sales-development-representative/`,
    localUrl: '/top-skills/prospecting',
    header: 'Prospecting',
    content: ProspectingContent,
    sideNav: true,
  },
  [SKILL_PAGE_UPSELLING]: {
    title: '',
    sideNavName: 'Upselling',
    h1: '',
    description: '',
    url: `https://${process.env.HOST}/top-skills/#upselling`,
    localUrl: '/top-skills/#upselling',
    header: 'Upselling',
    content: null,
    sideNav: true,
  },
};
