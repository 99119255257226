/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import jobsPageMetaData, {
  JOB_PAGE_SDR,
  JOB_PAGE_MMAE,
  JOB_PAGE_EAE,
  JOB_PAGE_CSM,
  JOB_PAGE_SM,
  JOB_PAGE_SD,
  JOB_PAGE_VP,
  JOB_PAGE_AE,
} from 'pages/JobsPage/jobsPageMetaData';
import marketsJobsPageMetaData, {
  MK_JB_SDR_NYC_PAGE,
  MK_JB_AE_NYC_PAGE,
} from 'pages/MarketsJobsPage/marketsJobsPageMetaData';

import { getAltName } from 'utils/urlUtils';

import pic1 from './assets/1-NYC-Tech-Sales-Jobs.png';
import pic2 from './assets/2-NYC-Tech-Sales-Careers.png';
import pic3 from './assets/3-NYC-Tech-Sales-Recruiters.png';
import cta from './assets/4-Contact-Sales-Recruiters.png';

import styles from './NYCContent.scss';

export function NYCContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt={getAltName(pic1)} />

      <h2>Technology Sales Jobs in NYC</h2>

      <p>
        When you need to put your sales organization into high gear, there's no better place to
        recruit than New York City. The Big Apple is the headquarters of some of the nation's top
        talent and a hub for tech innovation. As a result, the demand for tech sales professionals
        in NYC, from startups to established enterprises, has grown exponentially.
      </p>

      <h3>For Organizations - Build Your Team Without The Hassle</h3>

      <p>
        That's where we come in. First, we know where to find the best talent. Then, after
        understanding the client's needs, we help build a team of top performers.
      </p>

      <p>
        When resources and time matter, our services help streamline the hiring process. We help cut
        through the noise to find the right salespeople for your business.
      </p>

      <h3>Rainmakers' Top Roles And Recruits</h3>

      <p>Here are some of the top sales roles we recruit for in NYC:</p>

      <h3>Sales Development Representatives</h3>

      <p>
        The first step in any good sales process is identifying potential customers. That's where{' '}
        <a href={jobsPageMetaData[JOB_PAGE_SDR].localUrl} target="_blank" rel="noreferrer">
          Sales Development Representatives (SDRs)
        </a>{' '}
        come in. SDRs are responsible for generating leads and setting up appointments for the sales
        team. They play a vital role in the success of any sales organization, and we have a proven
        track record of placing top SDRs in NYC businesses.
      </p>

      <h3>Mid-Market Account Executives</h3>

      <p>
        Once you've generated leads, it's time to start closing deals. That's where{' '}
        <a href={jobsPageMetaData[JOB_PAGE_MMAE].localUrl} target="_blank" rel="noreferrer">
          Mid-Market Account Executives (MMAEs)
        </a>{' '}
        enter the sales funnel. AEs are responsible for managing relationships with key accounts and
        closing deals. We deeply understand the NYC tech sales market and help clients source the
        best AEs to grow their businesses.
      </p>

      <img src={pic2} alt={getAltName(pic2)} />

      <h3>SMB Account Executive</h3>

      <p>
        To be successful in sales, you must build relationships and close deals. That's where{' '}
        <a href={jobsPageMetaData[JOB_PAGE_AE].localUrl} target="_blank" rel="noreferrer">
          Account Executives (AEs)
        </a>{' '}
        excel. AEs are responsible for managing relationships with key accounts and closing deals.
      </p>

      <h3>Enterprise Account Executive</h3>

      <p>
        Regarding sales, bigger isn't always better. But in some cases, you need a proven closer who
        can deal with high-level executives and close six and seven-figure deals. That's when having
        an{' '}
        <a href={jobsPageMetaData[JOB_PAGE_EAE].localUrl} target="_blank" rel="noreferrer">
          Enterprise Account Executive (EAE)
        </a>{' '}
        helps. EAEs are responsible for managing relationships with large enterprise accounts and
        closing deals.
      </p>

      <h3>Customer Success Manager</h3>

      <p>
        The work doesn't stop once a deal is closed. You must continue to ensure customers are happy
        and keep doing business with you.{' '}
        <a href={jobsPageMetaData[JOB_PAGE_CSM].localUrl} target="_blank" rel="noreferrer">
          Customer Success Managers (CSMs)
        </a>{' '}
        provide ongoing support and services. Among their responsibilities are ensuring customer
        satisfaction and retention.
      </p>

      <img src={pic3} alt={getAltName(pic3)} />

      <h3>Business Development Representative</h3>

      <p>
        The first step in any good sales process is identifying potential customers. That's where{' '}
        <a href={jobsPageMetaData[JOB_PAGE_SDR].localUrl} target="_blank" rel="noreferrer">
          Business Development Representatives (BDRs)
        </a>{' '}
        shine. BDRs are responsible for generating leads and setting up appointments for sales team
        members.
      </p>

      <h3>Sales Director</h3>

      <p>
        When it's time to take your sales organization to the next level, you need a{' '}
        <a href={jobsPageMetaData[JOB_PAGE_SD].localUrl} target="_blank" rel="noreferrer">
          Sales Director
        </a>
        . Sales Directors are responsible for leading and mentoring a team of salespeople. In
        addition, they develop and implement sales strategies that help take the tech company to the
        next level.
      </p>

      <h3>Sales Management</h3>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_SM].localUrl} target="_blank" rel="noreferrer">
          Sales management
        </a>{' '}
        professionals are responsible for leading and mentoring a team of salespeople. In addition,
        sales management spearheads initiatives and regularly motivates team members to meet goals
        and objectives.
      </p>

      <h3>VP of Sales</h3>

      <p>
        Whether entering the tech industry or expanding your business to NYC, you need a{' '}
        <a href={jobsPageMetaData[JOB_PAGE_VP].localUrl} target="_blank" rel="noreferrer">
          VP of Sales
        </a>{' '}
        who can provide strategic direction and achieve results.
      </p>

      <Link to="/apply-now" title="Apply Now">
        <img src={cta} alt={getAltName(cta)} />
      </Link>

      <h2>Why NYC is the Ideal Place for Tech Companies</h2>
      <p>
        New York City is the perfect place to launch or grow a tech company. First of all, it's a
        global center for business and finance. If you want to raise capital, you'll have no
        shortage of potential investors. And if you find customers, you'll have no lack of potential
        buyers.
      </p>
      <p>
        NYC is home to a large and growing tech talent pool. According to the NYC Economic
        Development Corporation, there are over 300,000 tech workers in NYC. And this figure and the
        demand for professionals are only expected to increase in the years ahead.
      </p>
      <p>
        Finally, NYC is a great place to live and work. It's a vibrant and exciting city with
        something for everyone and the perfect place for your technology-driven sales team to call
        home.
      </p>
      <p>
        If you are interested in learning more about tech sales jobs that are in high demand in New
        York City, be sure to learn more about these existing opportunities:
      </p>
      <ul>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={marketsJobsPageMetaData[MK_JB_AE_NYC_PAGE].localUrl}
          >
            {' '}
            Account Executive Jobs
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={marketsJobsPageMetaData[MK_JB_SDR_NYC_PAGE].localUrl}
          >
            {' '}
            Sales Development Representative Jobs
          </a>
        </li>
      </ul>

      <p>
        If you are searching for top tech sales talent in NYC, look no further than Rainmakers.
        We're a boutique sales recruiting firm hiring sales professionals in NYC tech companies. We
        deeply understand the NYC tech sales market and can help you source the best sales talent to
        grow your business.{' '}
        <a href={`https://${process.env.HOST}/apply-now/`}>Sign up today with Rainmakers</a>, to
        connect with New York City tech companies.
      </p>
    </div>
  );
}
