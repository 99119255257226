import React from 'react';

import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import Block from 'components/Block';

import CompanyHeader from './components/CompanyHeader';
import CompanyAbout from './components/CompanyAbout';
import CompanyBenefits from './components/CompanyBenefits';
import CompanyPerks from './components/CompanyPerks';
import CompanyPress from './components/CompanyPress';

import styles from './EmployerEditorPreview.scss';

const EmployerEditorPreview = ({
  employer,
  employer: { attributes: { name, about, benefits = [], perks = [], links = [] } = {} } = {},
}) => {
  const commonBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    addPadding: true,
  };

  const headerBlockProps = {
    ...commonBlockProps,
    className: styles.titleBlock,
  };

  const aboutBlockProps = {
    ...commonBlockProps,
    title: `About ${name}`,
    addFlex: true,
  };

  const aboutProps = {
    about,
  };

  const perksBlockProps = {
    ...commonBlockProps,
    title: 'Perks',
  };

  const perksProps = {
    perks,
  };

  const benefitsBlockProps = {
    ...commonBlockProps,
    title: 'Benefits',
  };

  const benefitsProps = {
    benefits,
  };

  const pressBlockProps = {
    ...commonBlockProps,
    title: 'Press',
  };

  const pressProps = {
    links: links || [],
  };

  const content = (
    <div className={styles.EmployerEditorPreviewContainer}>
      <div className={styles.previewHeaderContainer}>
        <Block {...headerBlockProps}>
          <CompanyHeader employer={employer} />
        </Block>
      </div>
      <div className={styles.previewMainContainer}>
        <div className={styles.previewAboutContainer}>
          <Block {...aboutBlockProps}>
            <CompanyAbout {...aboutProps} />
          </Block>
        </div>
        <div className={styles.previewSideContainer}>
          <div className={styles.previewSideSmallContainer}>
            <Block {...perksBlockProps}>
              <CompanyPerks {...perksProps} />
            </Block>
          </div>
          <div className={styles.previewSideSmallContainer}>
            <Block {...benefitsBlockProps}>
              <CompanyBenefits {...benefitsProps} />
            </Block>
          </div>
          <div className={styles.previewSideSmallContainer}>
            <Block {...pressBlockProps}>
              <CompanyPress {...pressProps} />
            </Block>
          </div>
        </div>
      </div>
    </div>
  );

  const layoutWithoutSidebarProps = {
    content,
  };

  return <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />;
};

export default EmployerEditorPreview;
