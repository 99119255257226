import * as creators from 'shared/store/app/creators';
import selector from './selectors';
import reducer from './reducers';

const duck = {
  selector,
  reducer,
  creators,
};

export default duck;
