import { call, put, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { identify, sendGAClientId, trackEvent } from 'utils/analytics';
import { segmentUserLoginEmployee } from 'segment/eventNames';

import { promiseGetEmployer } from 'pages/EmployeePortal/EmployeeEditorPages/promises/';

import { EMPLOYEE_LOGIN_DONE } from 'shared/store/app/actions';

import { addRequestInterceptor, loginDone, setModal, userSave } from 'shared/store/app/creators';

import { employerHomePageValues } from 'utils/constants';

const identifySegment = (user) => {
  const {
    blocked,
    createdAt,
    currentProfile: {
      employer: {
        applicationStatus: employerAppStatus,
        applicationStatusUpdatedAt: approvedOn,
        name: company,
      } = {},
      employerId,
      firstName,
      id: employeeId,
      jobTitle,
      lastName,
      phoneNumber: phone,
      type,
    } = {},
    email,
  } = user;

  const traits = {
    email,
    firstName,
    lastName,
    createdAt,
    blocked,
    employerId,
    employeeId,
    phone,
    jobTitle,
    type,
    company,
    employerAppStatus,
    approvedOn,
  };

  identify({ userId: user.id, traits });
};

const trackLogin = (isAutoLogin) => {
  const event = segmentUserLoginEmployee;

  const properties = { isAdmin: false, isAutoLogin };

  const eventObject = { event, properties };

  trackEvent(eventObject);
};

const sagaEmployeeLoginDone = function* sagaEmployeeLoginDone(browserHistory) {
  while (true) {
    const {
      payload: { user, headers, autoLoginPath, noAddInterceptor },
    } = yield take(EMPLOYEE_LOGIN_DONE);

    const { currentProfile: { employerId } = {} } = user;

    if (!noAddInterceptor) {
      yield call(identifySegment, user);
      yield put(addRequestInterceptor({ headers }));
    }

    const promiseParams = {
      employerId,
      camelizeIt: true,
    };

    const { employer } = yield call(promiseGetEmployer, promiseParams);

    const fullUser = {
      ...user,
      currentProfile: {
        ...user.currentProfile,
        employer,
      },
    };

    yield put(userSave({ user: fullUser }));

    // Send ga cookie client id for analytics
    yield call(sendGAClientId, user.id);

    const { state } = browserHistory.location;

    const originalPath = state && state.originalPath ? state.originalPath : null;

    const redirectPath = autoLoginPath || originalPath;

    const isAutoLogin = !!autoLoginPath;

    yield call(trackLogin, isAutoLogin);

    yield put(loginDone());

    if (
      redirectPath &&
      redirectPath.pathname &&
      redirectPath.pathname.includes('profile') &&
      !redirectPath.pathname.includes('my-hiring') &&
      !redirectPath.pathname.includes('create-profile') &&
      !redirectPath.pathname.includes('edit-profile')
    ) {
      const {
        currentProfile: {
          employer: { homepage },
        },
      } = fullUser;

      // This will open the profile with the featured (or Matches - depending on homepage settings) page in background,
      // the user was given a locked link and we need to redirect them
      // to the page after they have logged in
      yield put(
        setModal({
          key: 'CandidateProfileModal',
          originalUrl: employerHomePageValues[homepage]?.homepage || '/featured',
          initialRender: true,
        })
      );

      yield put(
        push({
          ...redirectPath,
          state: {
            modal: 'CandidateProfileModal',
            parent: 'CandidateSearchPage',
          },
        })
      );
      // } else if (!isApproved || (isApproved && !hasSeenApproved)) {
      // if the Employee is not approved or they are approved but have not seen
      // the approved view, we direct them to the signup status page
      // yield put(push('/employer-signup-status'));
    }
  }
};

export default sagaEmployeeLoginDone;
