import * as Sentry from '@sentry/browser';

export const handleError = (error) => {
  if (error.response) {
    Sentry.addBreadcrumb({
      category: 'response_data',
      message: JSON.stringify(error.response.data),
    });
    Sentry.addBreadcrumb({
      category: 'status',
      message: JSON.stringify(error.response.status),
    });
  } else if (error.request) {
    Sentry.addBreadcrumb({
      category: 'request',
      message: JSON.stringify(error.request),
    });
  }

  Sentry.captureException(error);
  console.log(error);
};
