import { createAction } from 'redux-actions';

import { CANDIDATE_PROFILE_PATCH_DONE } from 'shared/store/app/actions';

import {
  CANDIDATE_LOAD_STARTED,
  CANDIDATE_PROFILE_PATCH_STARTED,
  CANDIDATE_COMPANY_BLOCKS_ERROR_SET,
  CANDIDATE_COMPANY_BLOCKS_LOAD_STARTED,
  CANDIDATE_COMPANY_BLOCKS_LOAD_DONE,
  CANDIDATE_COMPANY_BLOCKS_DELETE_STARTED,
  CANDIDATE_COMPANY_BLOCKS_DELETE_DONE,
  CANDIDATE_COMPANY_BLOCKS_CREATE_STARTED,
  CANDIDATE_COMPANY_BLOCKS_CREATE_DONE,
  CREATE_COMPANY_AND_BLOCK_STARTED,
  CREATE_COMPANY_AND_BLOCK_DONE,
} from '../actions';

export const candidateProfilePatchStarted = createAction(CANDIDATE_PROFILE_PATCH_STARTED);
export const candidateProfilePatchDone = createAction(CANDIDATE_PROFILE_PATCH_DONE);

export const candidateCompanyBlocksErrorSet = createAction(CANDIDATE_COMPANY_BLOCKS_ERROR_SET);
export const candidateCompanyBlocksLoadStarted = createAction(
  CANDIDATE_COMPANY_BLOCKS_LOAD_STARTED
);
export const candidateCompanyBlocksLoadDone = createAction(CANDIDATE_COMPANY_BLOCKS_LOAD_DONE);

export const candidateCompanyBlocksDeleteStarted = createAction(
  CANDIDATE_COMPANY_BLOCKS_DELETE_STARTED
);
export const candidateCompanyBlocksDeleteDone = createAction(CANDIDATE_COMPANY_BLOCKS_DELETE_DONE);

export const candidateCompanyBlocksCreateStarted = createAction(
  CANDIDATE_COMPANY_BLOCKS_CREATE_STARTED
);
export const candidateCompanyBlocksCreateDone = createAction(CANDIDATE_COMPANY_BLOCKS_CREATE_DONE);

export const createCompanyAndBlockStarted = createAction(CREATE_COMPANY_AND_BLOCK_STARTED);
export const createCompanyAndBlockDone = createAction(CREATE_COMPANY_AND_BLOCK_DONE);

export const candidateLoadStarted = createAction(CANDIDATE_LOAD_STARTED);
