import { handleActions } from 'redux-actions';

import {
  OPPORTUNITIES_LOAD_DONE,
  OPPORTUNITIES_LOADING_STARTED,
  OPPORTUNITIES_LOADING_DONE,
  PATCH_REQUISITION_CANDIDATE_DONE,
  REQUISITION_CANDIDATE_LOAD_DONE,
  RAINMAKERS_ADMIN_LOAD_DONE,
} from '../actions';

const initialState = {
  data: [],
  isLoading: true,
  rainmakersAdmin: {},
};

const handleRequisitionCandidateLoadDone = (state = initialState, action) => {
  const {
    payload: { requisitionCandidate },
  } = action;

  const { data: requisitionCandidates = [] } = state;

  const origCandidate = requisitionCandidates.find((cand) => cand.id === requisitionCandidate.id);

  if (origCandidate) {
    const newReqCandidates = requisitionCandidates.map((cand) => {
      if (cand.id === requisitionCandidate.id) {
        return {
          ...cand,
          ...requisitionCandidate,
        };
      }
      return cand;
    });

    return {
      ...state,
      data: newReqCandidates,
    };
  }
  const newReqCandidates = requisitionCandidates.slice();

  newReqCandidates.unshift(requisitionCandidate);

  return {
    ...state,
    data: newReqCandidates,
  };
};

const handleRainmakersAdminLoadDone = (state = initialState, action) => {
  const {
    payload: { rainmakersAdmin },
  } = action;

  return {
    ...state,
    rainmakersAdmin,
  };
};

const handleOpportunitiesLoadingStarted = (state = initialState) => ({ ...state, isLoading: true });

const handleOpportunitiesLoadingDone = (state = initialState) => ({ ...state, isLoading: false });

const handleOpportunitiesLoadDone = (state = initialState, action) => {
  const {
    payload: { opportunities, page, total },
  } = action;

  if (page === 1) {
    return {
      ...state,
      data: [...initialState.data, ...opportunities],
      page,
      total,
      isLoading: false,
    };
  }

  return {
    ...state,
    data: [...state.data, ...opportunities],
    page,
    total,
    isLoading: false,
  };
};

const handlePatchRequisitionCandidateDone = (state = initialState, action) => {
  const {
    payload: { requisitionCandidate },
  } = action;

  const { data: requisitionCandidates } = state;

  const newReqCandidates = requisitionCandidates.map((cand) => {
    if (cand.id === requisitionCandidate.id) {
      return {
        ...cand,
        ...requisitionCandidate,
      };
    }
    return cand;
  });

  return {
    ...state,
    data: newReqCandidates,
  };
};

const actionHandlers = {
  [OPPORTUNITIES_LOAD_DONE]: handleOpportunitiesLoadDone,
  [OPPORTUNITIES_LOADING_STARTED]: handleOpportunitiesLoadingStarted,
  [OPPORTUNITIES_LOADING_DONE]: handleOpportunitiesLoadingDone,
  [PATCH_REQUISITION_CANDIDATE_DONE]: handlePatchRequisitionCandidateDone,
  [RAINMAKERS_ADMIN_LOAD_DONE]: handleRainmakersAdminLoadDone,
  [REQUISITION_CANDIDATE_LOAD_DONE]: handleRequisitionCandidateLoadDone,
};

const reduceOpportunities = handleActions(actionHandlers, initialState);

export default reduceOpportunities;
