import * as genericCreators from 'shared/store/app/creators';

import * as creators from './creators';
import reducer from './reducers';
import selector from './selectors';

const duck = {
  reducer,
  creators: {
    ...genericCreators,
    ...creators,
  },
  selector,
};

export default duck;
