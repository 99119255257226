import React, { useEffect, useState, useRef } from 'react';

import Input from 'components/Input';
import DropdownSelectMany from 'components/DropdownSelectMany';

import { promiseCandidateSources, promiseRecruiters } from 'connectors/Defaults/promises';

import { handleError } from 'utils/common';

import styles from './ToolboxReferralsSearchBar.scss';

const ToolboxReferralsSearchBar = ({
  handleSearchInputChange,
  handleSearchFilterChange,
  userReferredByEmail,
  userReferredBySource,
  userReferredByUserId,
}) => {
  const [sources, setSources] = useState([]);
  const [recruiters, setRecruiters] = useState([]);
  const _mounted = useRef();

  useEffect(() => {
    _mounted.current = true;

    return () => {
      _mounted.current = false;
    };
  }, []);

  useEffect(() => {
    promiseCandidateSources()
      .then((arr) => arr.filter(Boolean).map((item) => ({ label: item, value: item })))
      .then((arr) =>
        arr.map((state) => ({
          indent: 0,
          label: state.label,
          optionType: 'role',
          subCategories: [],
          value: {
            type: 'state',
            state: state.value,
          },
        }))
      )
      .then((data) => _mounted.current && setSources(data))
      .catch(handleError);
  }, []);

  useEffect(() => {
    promiseRecruiters()
      .then((response) =>
        response.data.rainmakers_admins.map(({ first_name, last_name, user: { id, email } }) => ({
          label: (
            <div>
              <div>{`${first_name} ${last_name}`}</div>
              <div>{email}</div>
            </div>
          ),
          value: email,
          id,
        }))
      )
      .then((arr) =>
        arr.map((state) => ({
          indent: 0,
          label: state.label,
          optionType: 'role',
          subCategories: [],
          value: {
            type: 'state',
            state: state.id,
          },
        }))
      )
      .then((data) => _mounted.current && setRecruiters(data))
      .catch(handleError);
  }, []);

  const emailSearchProps = {
    autoComplete: 'off',
    name: 'userReferredByEmail',
    placeholder: 'Search by referrer by email',
    size: 'full',
    handleInputChange: handleSearchInputChange,
    isSearch: true,
    value: userReferredByEmail,
  };

  const recruitersFilterProps = {
    className: styles.searchBarFilter,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    source: recruiters,
    name: 'userReferredByUserId',
    key: 'userReferredByUserId',
    optionsLarge: true,
    isLocation: true,
    onChange: (value) =>
      handleSearchFilterChange({
        target: {
          subCategories: value.subCategories,
          label: value.label,
          value: value.value,
          name: 'userReferredByUserId',
          optionType: value.optionType,
        },
      }),
    selectedValues: userReferredByUserId.map((state) => ({ state, type: 'state' })),
    defaultLabel: 'Click to select recruiter',
  };

  const sourcesFilterProps = {
    className: styles.searchBarFilter,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    source: sources,
    name: 'userReferredBySource',
    key: 'userReferredBySource',
    optionsLarge: true,
    isLocation: true,
    onChange: (value) =>
      handleSearchFilterChange({
        target: {
          subCategories: value.subCategories,
          label: value.label,
          value: value.value,
          name: 'userReferredBySource',
          optionType: value.optionType,
        },
      }),
    selectedValues: userReferredBySource.map((state) => ({ state, type: 'state' })),
    defaultLabel: 'Click to select candidate sources',
  };

  return (
    <>
      <div className={styles.SearchBarOne}>
        <DropdownSelectMany {...recruitersFilterProps} />
        <DropdownSelectMany {...sourcesFilterProps} />
      </div>
      <div className={styles.SearchBarTwo}>
        <Input {...emailSearchProps} />
      </div>
    </>
  );
};

export default ToolboxReferralsSearchBar;
