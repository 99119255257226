import React, { useEffect } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

import ReadyContainer from 'connectors/ReadyContainer';

import { AdminAuthHOC, ModalHOC } from 'containers/HOC';
import ConnectContainer from 'containers/ConnectContainer';

import { usePrevious } from 'hooks';
import duck from 'pages/AdminPortal/ToolboxCompaniesPage/redux';

import CompanyEditorProfile from './components/CompanyEditorProfile';

const ToolboxCompanyEditor = (props) => {
  const params = useParams();
  const { id } = params;
  const prevId = usePrevious(id);

  useEffect(() => {
    const companyId = id;
    const {
      actions: { entityLoadStarted, pickerOptionsLoadStarted, tagsLoadStarted },
      company: { attributes: { temporary = false } = {} } = {},
    } = props;

    if (!temporary) {
      entityLoadStarted({
        type: 'companies',
        slice: 'toolboxCompanies',
        id: companyId,
        params: {
          includeSet: 'unverified_data',
          sortBy: 'created_at_desc',
        },
        updateOnly: true,
      });
    }

    pickerOptionsLoadStarted();
    tagsLoadStarted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currId = prevId;
    const nextId = id;

    const {
      actions: { entityLoadStarted },
    } = props;

    if (nextId !== currId) {
      entityLoadStarted({
        type: 'companies',
        slice: 'toolboxCompanies',
        id: nextId,
        params: {
          includeSet: 'unverified_data',
          sortBy: 'created_at_desc',
        },
        updateOnly: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <Helmet key="helmet" title="Company Editor" />
      <ReadyContainer key="readyContainer">
        <Switch>
          <Route exact path={'/toolbox/company/:id/edit-profile'}>
            <CompanyEditorProfile {...props} />
          </Route>
        </Switch>
      </ReadyContainer>
    </>
  );
};

export default ConnectContainer(duck)(
  AdminAuthHOC()(
    ModalHOC({
      paginatorUrl: '/toolbox/company/:id/edit-profile',
      centered: true,
    })(ToolboxCompanyEditor)
  )
);
