const errors = {
  firstName: {
    isNotEmpty: [null],
  },
  lastName: {
    isNotEmpty: [null],
  },
  greenhouseUserEmail: {
    isEmail: [null],
  },
};

export default errors;
