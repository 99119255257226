import React, { Component } from 'react';
import classnames from 'classnames';
import { DateUtils } from 'react-day-picker';

import { makeLikeParam } from 'utils/paramUtils';
import { toggleArray } from 'utils/formUtils';

import Button from 'components/Button';
import CandidateData from 'components/CandidateData';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import InputCheckbox from 'components/InputCheckbox';
import InputCheckboxMany from 'components/InputCheckboxMany';
import Table from 'components/Table';
import TableCell from 'components/Table/components/TableCell';
import TableRow from 'components/Table/components/TableRow';
import DropdownSelectMany from 'components/DropdownSelectMany';

import {
  candidateLifecycleOptions,
  getLifecycleStatusTitle,
} from 'utils/constants/lifecycleStatus';

import CohortScope from './components/CohortScope';
import NameScope from './components/NameScope';
import DateScope from './components/DateScope';
import ScopeContent from './components/ScopeContent';

import styles from './AddCandidatesTable.scss';

const SCOPE_SOURCE = [
  {
    label: 'in any future cohort',
    value: 'inFutureCohort',
  },
  {
    label: 'not in any future cohort',
    value: 'notInFutureCohort',
  },
  {
    label: 'in any current cohort',
    value: 'inCurrentCohort',
  },
  {
    label: 'not in any current cohort',
    value: 'notInCurrentCohort',
  },
  {
    label: 'in any past cohort',
    value: 'inPastCohort',
  },
  {
    label: 'not in any past cohort',
    value: 'notInPastCohort',
  },
  {
    label: 'not in any cohort',
    value: 'inNoCohort',
  },
];

const STATUS_SCOPE_SOURCE = [
  {
    label: 'Actively Interviewing',
    value: 'active',
  },
  {
    label: 'Passively Looking',
    value: 'passive',
  },
  {
    label: 'Not Looking',
    value: 'inactive',
  },
];

class AddCandidatesTable extends Component {
  constructor(props) {
    super(props);

    const { cohortId } = props;

    this.state = {
      applicationStatusUpdatedAt: [],
      from: null,
      fullName: '',
      hideSearchOptions: false,
      hiringStatus: [],
      profileComplete: null,
      scope: [],
      to: null,
      inFutureCohort: null,
      notInFutureCohort: null,
      inCurrentCohort: null,
      notInCurrentCohort: null,
      inPastCohort: null,
      notInPastCohort: null,
      inNoCohort: null,
      lifecycleStatus: [],
    };

    this.defaultLoadParams = {
      applicationStatus: 'approved',
      applicationStatusUpdatedAt: [],
      fullName: null,
      hiringStatus: [],
      includeSet: 'admin_search',
      inFutureCohort: null,
      notInFutureCohort: null,
      inCurrentCohort: null,
      notInCurrentCohort: null,
      inPastCohort: null,
      notInPastCohort: null,
      inNoCohort: null,
      page: 1,
      profileComplete: null,
      notInCohortId: cohortId,
      sortBy: 'full_name_asc',
      lifecycleStatus: [],
    };

    this.defaultLoadArgs = {
      type: 'candidates',
      typeOverride: 'addable_candidates',
      slice: 'toolboxCohorts',
    };
  }

  componentDidMount() {
    const {
      actions: { entityLoadStarted },
    } = this.props;

    entityLoadStarted({
      ...this.defaultLoadArgs,
      params: {
        ...this.getLoadParams(),
        page: 1,
      },
    });
  }

  getLoadParams = () => {
    const { addableCandidatesPage = 1 } = this.props;

    const {
      applicationStatusUpdatedAt,
      fullName,
      hiringStatus,
      inCurrentCohort,
      inFutureCohort,
      inNoCohort,
      inPastCohort,
      notInCurrentCohort,
      notInFutureCohort,
      notInPastCohort,
      profileComplete,
      lifecycleStatus,
    } = this.state;

    return {
      ...this.defaultLoadParams,
      applicationStatusUpdatedAt,
      fullName: makeLikeParam(fullName),
      hiringStatus,
      inCurrentCohort,
      inFutureCohort,
      inNoCohort,
      inPastCohort,
      notInCurrentCohort,
      notInFutureCohort,
      notInPastCohort,
      page: addableCandidatesPage,
      profileComplete,
      lifecycleStatus,
    };
  };

  handleAddableStartDateClick = (day) => {
    const {
      actions: { entityLoadStarted },
    } = this.props;
    const { applicationStatusUpdatedAt } = this.state;
    const { from, to } = DateUtils.addDayToRange(day, this.state);

    let newDateRange = applicationStatusUpdatedAt.slice();

    if (from) {
      from.setHours(0);
    }

    if (to) {
      to.setHours(23);
      to.setMinutes(59);
      to.setSeconds(59);
    }

    if (from && !to) {
      newDateRange = [`gte:${from.toISOString()}`];
    }

    if (!from && to) {
      newDateRange = [`lte:${to.toISOString()}`];
    }

    if (from && to) {
      newDateRange = [`gte:${from.toISOString()}`, `lte:${to.toISOString()}`];
    }

    this.setState({ from, to, applicationStatusUpdatedAt: newDateRange }, () =>
      entityLoadStarted({
        ...this.defaultLoadArgs,
        params: {
          ...this.getLoadParams(),
          page: 1,
        },
      })
    );
  };

  handleClearDates = () => {
    const {
      actions: { entityLoadStarted },
    } = this.props;

    this.setState({ from: null, to: null, applicationStatusUpdatedAt: [] }, () =>
      entityLoadStarted({
        ...this.defaultLoadArgs,
        params: {
          ...this.getLoadParams(),
          page: 1,
        },
      })
    );
  };

  loadMore = () => {
    const {
      actions: { entityLoadStarted },
      addableCandidatesPage: page,
    } = this.props;

    entityLoadStarted({
      ...this.defaultLoadArgs,
      params: {
        ...this.getLoadParams(),
        page: page + 1,
      },
    });
  };

  handleScopeChange = (event) => {
    const {
      actions: { entityLoadStarted },
      category,
    } = this.props;

    let value = category;

    const {
      target: { value: name },
    } = event;

    const { [name]: scope } = this.state;

    if (scope) {
      value = null;
    }

    this.setState({ [name]: value }, () =>
      entityLoadStarted({
        ...this.defaultLoadArgs,
        params: {
          ...this.getLoadParams(),
          page: 1,
        },
      })
    );
  };

  handleArrayChange = (event) => {
    const {
      target: { value, name },
    } = event;

    const {
      actions: { entityLoadStarted },
    } = this.props;

    const { [name]: array } = this.state;

    const newArray = toggleArray({ array, value });

    this.setState({ [name]: newArray }, () =>
      entityLoadStarted({
        ...this.defaultLoadArgs,
        params: {
          ...this.getLoadParams(),
          page: 1,
        },
      })
    );
  };

  handleSearchInputChange = (event) => {
    const {
      target: { name, value },
    } = event;

    const {
      actions: { entityLoadStarted },
    } = this.props;

    this.setState({ [name]: value }, () =>
      entityLoadStarted({
        ...this.defaultLoadArgs,
        params: {
          ...this.getLoadParams(),
          page: 1,
        },
      })
    );
  };

  handleSearchCheckboxChange = (event) => {
    const {
      target: { name, checked },
    } = event;

    const {
      actions: { entityLoadStarted },
    } = this.props;

    const value = checked || null;

    this.setState({ [name]: value }, () =>
      entityLoadStarted({
        ...this.defaultLoadArgs,
        params: {
          ...this.getLoadParams(),
          page: 1,
        },
      })
    );
  };

  render() {
    const {
      actions: { resourceUpdate },
      cohortId,
      isReadOnly,
      addableCandidatesLoading,
      addableCandidates: { allIds = [], byId = {} } = {},
      addableCandidatesTotal,
      handleAddAddableCandidates,
      handleHideAddCandidatesOptions,
      month,
    } = this.props;

    const {
      from: fromDate,
      to: toDate,
      hideSearchOptions,
      fullName,
      hiringStatus,
      profileComplete,
      inCurrentCohort,
      inFutureCohort,
      inNoCohort,
      inPastCohort,
      notInCurrentCohort,
      notInFutureCohort,
      notInPastCohort,
      lifecycleStatus,
    } = this.state;

    const cancelButtonProps = {
      warningTertiary: true,
      onClick: handleHideAddCandidatesOptions,
    };

    const cancelButton = <Button {...cancelButtonProps}>Cancel</Button>;

    const buttonString = hideSearchOptions ? 'Show Search Options' : 'Hide Search Options';

    const hideSearchOptionsButtonProps = {
      onClick: () => this.setState({ hideSearchOptions: !hideSearchOptions }),
      tertiaryThin: true,
      className: styles.hideButton,
    };

    const scope = SCOPE_SOURCE.filter(({ value }) => {
      const { [value]: scope } = this.state;

      return Boolean(scope);
    }).map(({ value }) => value);

    const scopeContentProps = {
      scope,
      toDate,
      fromDate,
      scopeSource: SCOPE_SOURCE,
    };

    const currentSortContent =
      scope.length > 0 || toDate || fromDate ? <ScopeContent {...scopeContentProps} /> : null;

    const selectAllButtonProps = {
      onClick: handleAddAddableCandidates,
      primary: true,
      disabled: !allIds.some((id) => byId[id].attributes.checked),
    };

    const selectAllButton = <Button {...selectAllButtonProps}>Add All Selected</Button>;

    const submitButtons =
      allIds.length > 0 ? <div className={styles.actionButtons}>{selectAllButton}</div> : null;

    const cohortScopeProps = {
      scopeSource: SCOPE_SOURCE,
      handleScopeChange: this.handleScopeChange,
      inCurrentCohort,
      inFutureCohort,
      inNoCohort,
      inPastCohort,
      notInCurrentCohort,
      notInFutureCohort,
      notInPastCohort,
    };

    const hiringStatusProps = {
      source: STATUS_SCOPE_SOURCE,
      name: 'hiringStatus',
      handleInputChange: this.handleArrayChange,
      selectedValues: hiringStatus,
      blockStyle: true,
      flexFit: true,
      hasLabel: true,
      smallBlock: true,
    };

    const nameScopeProps = {
      fullName,
      handleSearchInputChange: this.handleSearchInputChange,
    };

    const profileCompleteProps = {
      name: 'profileComplete',
      label: 'Show completed profiles only',
      checked: profileComplete,
      handleInputChange: this.handleSearchCheckboxChange,
      checkboxMany: true,
    };

    const dateScopeProps = {
      handleClearDates: this.handleClearDates,
      handleAddableStartDateClick: this.handleAddableStartDateClick,
      fromDate,
      toDate,
      month,
    };

    const candidateLifecycleSource = candidateLifecycleOptions.map((state) => ({
      indent: 0,
      label: state.label,
      optionType: 'role',
      subCategories: [],
      value: {
        type: 'state',
        state: state.value,
      },
    }));

    const selectedLifecycleStatus = lifecycleStatus.map((state) => ({ state, type: 'state' }));

    const candidateLifecycleFilterProps = {
      className: styles.lifecycleFilter,
      defaultIcon: 'caret-down',
      activeIcon: 'caret-up',
      source: candidateLifecycleSource,
      name: 'lifecycleStatus',
      key: 'lifecycleStatus',
      optionsLarge: true,
      isLocation: true,
      onChange: (item) => {
        this.handleArrayChange({
          target: {
            value: item.value.state,
            name: 'lifecycleStatus',
          },
        });
      },
      selectedValues: selectedLifecycleStatus,
      defaultLabel: 'Click to select lifecycle statuses',
    };

    const searchOptions = hideSearchOptions ? null : (
      <>
        <div className={styles.info}>
          The options below will display candidates in the table for a more refined selection
          process:
        </div>
        <CohortScope {...cohortScopeProps} />
        <div className={styles.dateNameContainer}>
          <DateScope {...dateScopeProps} />
          <NameScope {...nameScopeProps} />
          <InputCheckbox {...profileCompleteProps} />
        </div>
        <InputCheckboxMany {...hiringStatusProps} />
        <DropdownSelectMany {...candidateLifecycleFilterProps} />
      </>
    );

    const addCandidatesTitle = (
      <div className={styles.addCandidatesTitle}>
        <div className={styles.addCandTitle}>
          <div className={styles.titleText}>
            <Button {...hideSearchOptionsButtonProps}>{buttonString}</Button>
            {cancelButton}
          </div>
        </div>
        {searchOptions}
        {currentSortContent}
        {submitButtons}
      </div>
    );

    const addCandidatesBlockProps = {
      boxShadow: true,
      addWhiteBG: true,
      addFlex: true,
      addChildFlex: true,
      addBottomFade: true,
      disabled: !cohortId,
    };

    const infiniteScrollProps = {
      loadMore: this.loadMore,
      hasMore: addableCandidatesTotal > allIds.length,
      loader: addableCandidatesLoading ? <div /> : <InfiniteScrollLoader />,
      useWindow: false,
    };

    const makeCandidateCells = (id) => {
      const {
        [id]: candidate,
        [id]: { attributes: { active, hiringStatus, checked = false, lifecycleStatus } = {} } = {},
      } = byId || {};

      const candidateDataProps = { candidate };

      const candidateDataContent = (
        <div className={styles.candidateContent}>
          <CandidateData {...candidateDataProps} />
        </div>
      );

      const lookingStatusClasses = classnames(styles.statusContent);

      const activeText = hiringStatus === 'active' ? 'Actively Interviewing' : null;
      const inactiveText = hiringStatus === 'inactive' ? 'Not Looking' : null;
      const passiveText = hiringStatus === 'passive' ? 'Passively Looking' : null;

      const hiringStatusText = activeText || inactiveText || passiveText || '-';

      const lookingStatusContent = <div className={lookingStatusClasses}>{hiringStatusText}</div>;

      const statusClasses = classnames(styles.statusContent, {
        [styles.greenActive]: active,
        [styles.inactiveStatus]: !active,
      });

      const selectCandidateProps = {
        checked,
        name: 'selectCandidate',
        handleInputChange: () =>
          resourceUpdate({
            type: 'addable_candidates',
            slice: 'toolboxCohorts',
            id,
            resource: {
              ...candidate,
              attributes: {
                ...candidate.attributes,
                checked: !checked,
              },
            },
          }),
      };

      const selectCandidateContent = isReadOnly ? null : (
        <TableCell key={1}>
          <InputCheckbox {...selectCandidateProps} />
        </TableCell>
      );

      const cells = [
        selectCandidateContent,
        <TableCell key={2}>{candidateDataContent}</TableCell>,
        <TableCell key={3}>
          <div className={styles.statusContainer}>
            <div className={statusClasses}>{active ? 'Active' : 'Inactive'}</div>
            {lifecycleStatus && (
              <div className={styles.lifecycleStatus}>
                {getLifecycleStatusTitle(lifecycleStatus)}
              </div>
            )}
          </div>
        </TableCell>,
        <TableCell flex={isReadOnly} key={4}>
          {lookingStatusContent}
        </TableCell>,
      ];

      return cells;
    };

    const makeCandidateRows = (id) => {
      const { attributes: { active, hiringStatus } = {} } = byId[id] || {};

      return (
        <TableRow id={id} disabled={!active || hiringStatus === 'inactive'} key={id}>
          {makeCandidateCells(id)}
        </TableRow>
      );
    };

    return (
      <div className={styles.content}>
        <Table
          blockProps={addCandidatesBlockProps}
          titleContent={addCandidatesTitle}
          tableContent={allIds.map(makeCandidateRows)}
          infiniteScrollProps={infiniteScrollProps}
          tableName="addableCohortCandidates"
          isEmpty={!addableCandidatesLoading && allIds.length === 0}
          emptyTextString="Select options from above to see addable candidates here"
          isLoading={addableCandidatesLoading}
        />
      </div>
    );
  }
}

export default AddCandidatesTable;
