import React from 'react';

import Input from 'components/Input';
import InputCheckbox from 'components/InputCheckbox';

import styles from '../ReqDetail.scss';

const Headcount = ({
  handleInputChange,
  requisitionOpening: { attributes: { headcount, unlimitedHeadcount, state } = {} } = {},
  errors: { headcount: headcountErrors } = {},
}) => {
  const headcountInputProps = {
    disabled: state === 'closed' || unlimitedHeadcount === true,
    errors: headcountErrors,
    handleInputChange,
    label: 'Headcount',
    min: '0',
    name: 'headcount',
    placeholder: '1',
    required: true,
    size: 'small',
    type: 'number',
    ultraDisabled: unlimitedHeadcount === true,
    value: headcount || '',
  };

  const unlimitedHeadcountInputProps = {
    disabled: state === 'closed',
    name: 'unlimitedHeadcount',
    label: 'Unlimited Headcount',
    checked: unlimitedHeadcount || false,
    handleInputChange,
  };

  return (
    <div className={styles.headcount}>
      <Input {...headcountInputProps} />
      <div className={styles.headcountCheckbox}>
        <InputCheckbox {...unlimitedHeadcountInputProps} />
      </div>
    </div>
  );
};

export default Headcount;
