/* eslint react/prefer-stateless-function: "off" */
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import UnauthorizedPage from 'pages/UtilPages/UnauthorizedPage';

const AdminAuthHOC = () => (WrappedComponent) => {
  const _AdminAuthHOC = (props) => {
    const { isAdmin, logoutPending = false, user: { currentProfileType } = {} } = props;

    const location = useLocation();
    const { search } = location;

    if (!currentProfileType && !logoutPending) {
      const to = {
        pathname: '/login/candidate',
        search,
        state: { originalPath: location },
      };

      return <Redirect to={to} />;
    }

    if (!isAdmin && !logoutPending) {
      return <UnauthorizedPage />;
    }

    return <WrappedComponent {...props} />;
  };

  return _AdminAuthHOC;
};

export default AdminAuthHOC;
