import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import styles from './MenuAccordion.scss';

const MenuAccordion = ({ className, label, items, target = '_self' }) => {
  const [active, setActive] = useState(false);

  const handleOnClick = (ev) => {
    ev.stopPropagation();
    setActive((active) => !active);
  };

  return (
    <div className={cn(className, styles.container)}>
      <div className={styles.title} onClick={handleOnClick}>
        {label}
      </div>
      <div className={styles.optionsContainer} aria-expanded={active}>
        {items.map(({ title, url }) => (
          <Link
            key={title}
            className={styles.option}
            to={url}
            target={target}
            onClick={() => setActive(false)}
          >
            {title}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default MenuAccordion;
