import React from 'react';
import classnames from 'classnames';

import DropdownSelectMany from 'components/DropdownSelectMany';
import Table from 'components/Table';
import TableRow from 'components/Table/components/TableRow';
import TableCell from 'components/Table/components/TableCell';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import FormattedDate from 'components/FormattedDate';

import CohortActionDropdown from '../CohortActionDropdown';

import styles from './CohortEmployersTable.scss';

const STATUSES = [
  { label: 'Active', value: 'active' },
  { label: 'Open', value: 'open' },
  { label: 'Complete', value: 'complete' },
];

const TYPES = [
  { label: 'Featured', value: 'search' },
  { label: 'Match', value: 'featured' },
];

const CohortEmployersTable = ({
  actions: { deleteResource },
  cohorts: { byId: cohortsById = {} } = {},
  cohortEmployers: { allIds: cohortEmployersAllIds = [], byId: cohortEmployersById = {} },
  cohortEmployersTotal,
  cohortEmployersLoading,
  loadMoreCohortEmployers,
  selectedCohort,
  handleSelectCohort,
  handleSearchFilterChange,
  cohortState,
  cohortCategory,
}) => {
  const tableHeader = [
    {
      content: 'Cohort Name',
      headerClassName: styles.firstLabel,
    },
    {
      content: 'Status',
      headerClassName: styles.cohortStatusHeader,
    },
    {
      content: 'Type',
      headerClassName: styles.cohortStatusHeader,
    },
    {
      content: 'Start',
      headerClassName: styles.cohortStatusHeader,
    },
    {
      content: 'End',
      headerClassName: styles.cohortStatusHeader,
    },
    {
      content: '',
      headerClassName: styles.actionHeader,
    },
  ];

  const makeCohortCells = (id) => {
    const { relationships: { cohort: { data: cohortIds = [] } = {} } = {} } =
      cohortEmployersById[id] || {};

    const { attributes: { name = '', startDate, endDate, state, category, locked } = {} } =
      cohortsById[cohortIds[0]] || {};

    const cohortNameCellProps = {
      className: styles.cohortNameCell,
      onClick: () => handleSelectCohort(cohortsById[cohortIds[0]]),
    };

    const cohortNameCell = <div {...cohortNameCellProps}>{name}</div>;

    const statusClasses = classnames(styles.cohortStatusCell, {
      [styles.activeStatus]: state === 'open' || state === 'active',
      [styles.inactiveStatus]: state === 'complete',
    });

    const statusText = `${state.charAt(0).toUpperCase()}${state.slice(1)}`;

    const cohortStatusCell = <div className={statusClasses}>{statusText}</div>;

    const cohortTypeCell = (
      <div className={styles.cohortTypeCell}>{category === 'featured' ? 'Match' : 'Featured'}</div>
    );

    const cohortStartCell = startDate ? (
      <div className={styles.cohortDate}>
        <FormattedDate format="MMM D YYYY" date={startDate} parse />
      </div>
    ) : (
      <div className={styles.cohortDate}>-</div>
    );

    const cohortEndCell = endDate ? (
      <div className={styles.cohortDate}>
        <FormattedDate format="MMM D YYYY" date={endDate} parse />
      </div>
    ) : (
      <div className={styles.cohortDate}>-</div>
    );

    const cohortActionDropdownProps = {
      cohortEmployerId: id,
      deleteResource,
    };

    const actionsCell =
      !locked && (state === 'open' || state === 'active') ? (
        <div className={styles.actionsCell}>
          <CohortActionDropdown {...cohortActionDropdownProps} />
        </div>
      ) : (
        <div className={styles.actionsCell} />
      );

    const cells = [
      <TableCell key={1}>{cohortNameCell}</TableCell>,
      <TableCell key={2}>{cohortStatusCell}</TableCell>,
      <TableCell key={3}>{cohortTypeCell}</TableCell>,
      <TableCell key={4}>{cohortStartCell}</TableCell>,
      <TableCell key={5}>{cohortEndCell}</TableCell>,
      <TableCell noFlex key={6}>
        {actionsCell}
      </TableCell>,
    ];

    return cells;
  };

  const makeCohortRows = (id) => (
    <TableRow id={id} highlight={id === selectedCohort.id} cohortRow key={id}>
      {makeCohortCells(id)}
    </TableRow>
  );

  const tableContent = cohortEmployersAllIds.map(makeCohortRows);

  const cohortStateSource = STATUSES.map(({ label, value }) => ({
    indent: 0,
    label,
    optionType: 'role',
    subCategories: [],
    value: {
      type: 'state',
      state: value,
    },
  }));

  const selectedStates = cohortState.map((state) => ({ state, type: 'state' }));

  const statusFilterProps = {
    className: styles.searchBarFilter,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    source: cohortStateSource,
    name: 'cohortState',
    key: 'cohortState',
    optionsLarge: true,
    isLocation: true,
    onChange: (value) =>
      handleSearchFilterChange({
        target: {
          subCategories: value.subCategories,
          label: value.label,
          value: value.value,
          name: 'cohortState',
          optionType: value.optionType,
        },
      }),
    selectedValues: selectedStates,
    defaultLabel: 'Click to select status',
  };

  const cohortCategorySource = TYPES.map(({ label, value }) => ({
    indent: 0,
    label,
    optionType: 'role',
    subCategories: [],
    value: {
      type: 'cat',
      cat: value,
    },
  }));

  const selectedCategories = cohortCategory.map((cat) => ({ cat, type: 'cat' }));

  const typeFilterProps = {
    className: styles.searchBarFilter,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    source: cohortCategorySource,
    name: 'cohortCategory',
    key: 'cohortCategory',
    optionsLarge: true,
    isLocation: true,
    onChange: (value) =>
      handleSearchFilterChange({
        target: {
          subCategories: value.subCategories,
          label: value.label,
          value: value.value,
          name: 'cohortCategory',
          optionType: value.optionType,
        },
      }),
    selectedValues: selectedCategories,
    defaultLabel: 'Click to select type',
  };

  const filtersContent = (
    <div className={styles.cohortHeader}>
      <DropdownSelectMany {...statusFilterProps} />
      <DropdownSelectMany {...typeFilterProps} />
    </div>
  );

  const infiniteScrollProps = {
    pageStart: 0,
    loadMore: loadMoreCohortEmployers,
    hasMore: cohortEmployersTotal > cohortEmployersAllIds.length,
    loader: <InfiniteScrollLoader key="infiniteScrollLoader" />,
    useWindow: false,
  };

  const tableProps = {
    noBlockFlex: true,
    titleContent: filtersContent,
    tableContent,
    header: tableHeader,
    cohort: true,
    tableName: 'cohorts',
    isEmpty: !cohortEmployersLoading && cohortEmployersAllIds.length === 0,
    emptyTextString: 'No Cohorts Available',
    infiniteScrollProps,
  };

  return <Table {...tableProps} />;
};

export default CohortEmployersTable;
