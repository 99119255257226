import React from 'react';

import Bolder from 'components/Bolder';
import Content from 'components/Content';
import ContentTitle from 'components/ContentTitle';

import styles from './YourReferrals.scss';

const YourReferrals = ({ referredHires, referredSignUps }) => {
  return (
    <Content className={styles.container}>
      <ContentTitle>YOUR REFERRALS</ContentTitle>

      <Content className={styles.detailContainer}>
        <Content className={styles.padded}>
          <Bolder>Total referral sign ups</Bolder>
          <Content>{referredSignUps}</Content>
        </Content>

        <Content className={styles.padded}>
          <Bolder>Total hires</Bolder>
          <Content>{referredHires}</Content>
        </Content>
      </Content>
    </Content>
  );
};

export default YourReferrals;
