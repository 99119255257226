import React from 'react';

import AutocompleteServer from 'connectors/AutocompleteServer';
import Tag from 'components/Tag';

import styles from './InvestorInputs.scss';

const InvestorInputs = ({ investors, handleToggleArrayValue }) => {
  const investorsInputProps = {
    placeholder: "Type an investor's name and press 'Enter'",
    value: '',
    size: 'xxlarge',
    name: 'investors',
    label: 'Who are your investors?',
    handleInputChange: (event) =>
      handleToggleArrayValue({
        ...event,
        target: {
          ...event.target,
          name: event.target.name,
          value: event.target.value.name,
        },
      }),
    handleOnBlur: handleToggleArrayValue,
    topLabel: true,
    autocompleteType: 'investors',
    field: 'name',
    needReset: true,
  };

  const investorsSelected = investors
    ? investors.map((inv) => {
        const tagProps = {
          name: inv,
          inputName: 'investors',
          onDeleteClick: (name, inputName) =>
            handleToggleArrayValue({
              target: {
                value: name,
                name: inputName,
              },
            }),
        };
        return <Tag key={inv} {...tagProps} />;
      })
    : null;

  return (
    <div className={styles.InvestorInputs}>
      <AutocompleteServer {...investorsInputProps} />
      <div className={styles.autocompleteContainer}>{investorsSelected}</div>
    </div>
  );
};

export default InvestorInputs;
