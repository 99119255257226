import { call, put, take } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { promiseGetRequisitionCandidate } from 'pages/AdminPortal/ToolboxApproachEditorPage/promises';

import { REQUISITION_CANDIDATE_LOAD_STARTED } from '../actions';

import { requisitionCandidateLoadDone } from '../creators';

const sagaRequisitionCandidateLoadStarted = function* sagaRequisitionCandidateLoadStarted() {
  while (true) {
    const {
      payload: { requisitionCandidateId },
    } = yield take(REQUISITION_CANDIDATE_LOAD_STARTED);

    try {
      const params = {
        requisitionCandidateId,
        includeSet: 'employer',
      };

      const { requisitionCandidate } = yield call(promiseGetRequisitionCandidate, params);

      yield put(requisitionCandidateLoadDone({ requisitionCandidate }));
    } catch (error) {
      handleError(error);
    }
  }
};

export default sagaRequisitionCandidateLoadStarted;
