import { getCurrentUser } from 'api/apiAuth';

const promiseSignupStatus = () => {
  const onFulfilled = (response) => {
    const {
      data: { user = {} },
    } = response;

    return { user };
  };

  return getCurrentUser().then(onFulfilled);
};

export default promiseSignupStatus;
