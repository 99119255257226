import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import styles from './Timer.scss';

const Timer = ({ seconds: secondsMax, className, isRecord }) => {
  const [seconds, setSeconds] = useState(secondsMax);
  const elapse = useRef();

  useEffect(() => {
    elapse.current = setInterval(() => {
      if (seconds === 0) {
        clearInterval(elapse.current);
      } else {
        setSeconds((seconds) => seconds - 1);
      }
    }, 1000);

    return () => {
      if (elapse.current) {
        clearInterval(elapse.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = classnames(
    styles.Timer,
    {
      [styles.recording]: isRecord,
      [styles.panic]: isRecord && seconds < 6,
    },
    className
  );

  return seconds >= 0 ? <div className={classes}>{seconds}</div> : null;
};

export default Timer;
