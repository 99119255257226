import React from 'react';

import Bold from 'components/Bold';
import Button from 'components/Button';
import Content from 'components/Content';
import DialogueModal from 'components/DialogueModal';

import styles from './ErrorModal.scss';

const ErrorModal = ({ resetAppError }) => {
  const dismissButtonProps = {
    primary: true,
    onClick: resetAppError,
  };

  return (
    <DialogueModal>
      <div className={styles.errorMessage}>
        <Bold className={styles.title}>Something went wrong!</Bold>
        <Content className={styles.body}>
          Please try again. Email <a href="mailto:support@rainmakers.co">support@rainmakers.co</a>{' '}
          if error persists.
        </Content>
        <div className={styles.buttons}>
          <Button {...dismissButtonProps}>Dismiss</Button>
        </div>
      </div>
    </DialogueModal>
  );
};

export default ErrorModal;
