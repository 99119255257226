import { handleError } from 'utils/common';
import { getCandidateRelationship } from 'api/apiCandidates';

const promiseGetCandidateEducations = ({
  candidateId,
  sortBy = 'finished_at_desc_nulls_first,started_at_desc',
  includeSet = 'education_organization',
  normalizeIt,
  fullEntity,
}) => {
  const onFulfilled = ({
    data: {
      entities = {
        candidateEducation: {
          allIds: [],
          byId: {},
        },
      },
      entities: {
        educationOrganization: { byId: educationOrganizationById = {} } = {},
        candidateEducation: { allIds = [], byId = {} } = {},
      } = {},
    } = {},
  }) =>
    fullEntity
      ? { ...entities }
      : {
          candidateEducations: allIds.map((id) => {
            const {
              [id]: {
                relationships: {
                  educationOrganization: { data: educationOrganizationIds = [] } = {},
                } = {},
              } = {},
            } = byId || {};

            return {
              ...byId[id].attributes,
              educationOrganization:
                educationOrganizationById[educationOrganizationIds[0]].attributes,
            };
          }),
        };

  const getArgs = {
    candidateId,
    config: {
      params: {
        includeSet,
        sortBy,
        normalizeIt,
      },
    },
    type: 'candidateEducation',
  };

  return getCandidateRelationship(getArgs).then(onFulfilled).catch(handleError);
};

export default promiseGetCandidateEducations;
