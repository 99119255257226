const prefix = 'Rainmakers/CandidateProfile/';

export const REQUISITION_DETAILS_LOAD_STARTED = `${prefix}REQUISITION_DETAILS_LOAD_STARTED`;
export const REQUISITION_DETAILS_LOAD_DONE = `${prefix}REQUISITION_DETAILS_LOAD_DONE`;

export const REQUISITION_CANDIDATES_CREATE_STARTED = `${prefix}REQUISITION_CANDIDATES_CREATE_STARTED`;
export const REQUISITION_CANDIDATES_CREATE_DONE = `${prefix}REQUISITION_CANDIDATES_CREATE_DONE`;

export const RESET_CANDIDATE = `${prefix}RESET_CANDIDATE`;

export const SET_NEED_RELOAD = `${prefix}SET_NEED_RELOAD`;
export const RESET_NEED_RELOAD = `${prefix}RESET_NEED_RELOAD`;

export const MESSAGE_CREATE_STARTED = `${prefix}MESSAGE_CREATE_STARTED`;
export const MESSAGE_ERROR_SET = `${prefix}MESSAGE_ERROR_SET`;
