import React from 'react';
import Slider from 'react-rangeslider';

import InputCheckbox from 'components/InputCheckbox';
import Label from 'components/Label';

import styles from '../ReqRequirement.scss';

const StartupExperience = ({
  disabled,
  requisitionRequirement: { attributes: { startupExperience, startupExperienceWt } = {} } = {},
  handleInputChange,
}) => {
  const startupExperienceProps = {
    name: 'startupExperience',
    label: 'Does this role require startup experience?',
    checked: startupExperience,
    handleInputChange,
    checkboxMany: true,
    disabled,
  };

  const startupExperienceWtProps = {
    disabled,
    className: styles.rangeSlider,
    min: 0,
    max: 10,
    step: 5,
    value: startupExperienceWt || 0,
    onChange: disabled
      ? () => null
      : (value) =>
          handleInputChange({
            target: {
              value,
              name: 'startupExperienceWt',
            },
          }),
    tooltip: false,
    labels: {
      0: '0',
      5: '5',
      10: '10',
    },
  };

  return (
    <div className={styles.rangeInputs}>
      <div className={styles.inputCheckbox}>
        <InputCheckbox {...startupExperienceProps} />
      </div>
      <div className={styles.column}>
        <Label className={styles.labelStyle}>How much do you value this?</Label>
        <Slider {...startupExperienceWtProps} />
      </div>
    </div>
  );
};

export default StartupExperience;
