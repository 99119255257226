import { createSelector } from 'reselect';
import { getApp } from 'shared/store/app/selectors';

const selectCandidateOpportunities = createSelector(
  getApp,
  (state) => state.candidateOpportunities
);

const selectLoading = createSelector(
  selectCandidateOpportunities,
  (opportunities) => opportunities.isLoading
);

export default selectLoading;
