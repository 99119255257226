import React from 'react';

import styles from './Partners.scss';

const Partners = () => {
  return (
    <div id="Partners" className={styles.Partners}>
      <h3 className={styles.Title}>Connect with Great Companies</h3>

      <div className={styles.Container}>
        <div className={styles.LogosContainer}>
          <div className={styles.Logo}>
            <img src={require('../../assets/partners/dialpad.png')} alt="Dialpad" />
          </div>

          <div className={styles.Logo}>
            <img src={require('../../assets/partners/envoy.png')} alt="Envoy" />
          </div>

          <div className={styles.Logo}>
            <img src={require('../../assets/partners/freshworks.png')} alt="Freshworks" />
          </div>
        </div>

        <div className={styles.LogosContainer}>
          <div className={styles.Logo}>
            <img src={require('../../assets/partners/guru.png')} alt="Guru" />
          </div>

          <div className={styles.Logo}>
            <img src={require('../../assets/partners/kandji.png')} alt="Kandji" />
          </div>

          <div className={styles.LogoModHealth}>
            <img src={require('../../assets/partners/modernhealth.png')} alt="Modern Health" />
          </div>
        </div>

        <div className={styles.LogosContainer}>
          <div className={styles.Logo}>
            <img src={require('../../assets/partners/nextroll.png')} alt="Nextroll" />
          </div>

          <div className={styles.Logo}>
            <img src={require('../../assets/partners/nylas.png')} alt="Nylas" />
          </div>

          <div className={styles.Logo}>
            <img src={require('../../assets/partners/sendoso.png')} alt="Sendoso" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
