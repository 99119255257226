import { postNestedEntity } from 'api/apiEntity';

const promisePostCohortEmployers = ({ cohortId, employerId, employerState }) => {
  const onFulfilled = (response) => {
    const {
      data: { cohort_employer: cohortEmployer },
    } = response;

    return cohortEmployer;
  };

  const args = {
    id: cohortId,
    type: 'cohorts',
    nestedType: 'cohort_employers',
    data: {
      type: 'cohort_employer',
      attributes: {
        employer_id: employerId,
        state: employerState,
      },
    },
  };

  return postNestedEntity(args).then(onFulfilled);
};

export default promisePostCohortEmployers;
