import React, { useState } from 'react';

import { employerInputChange } from 'utils/formHelpers/EmployerHelpers';
import { toggleArray } from 'utils/formUtils';

import { COMPANY_SIZE_SOURCE } from 'connectors/Defaults/constants';

import AutocompleteServer from 'connectors/AutocompleteServer';
import Block from 'components/Block';
import Button from 'components/Button';
import Content from 'components/Content';
import EmployerImageUploader from 'components/EmployerImageUploader';
import GooglePlacesAutocomplete from 'components/GooglePlacesAutocomplete';
import Input from 'components/Input';
import InputRichText from 'components/InputRichText';
import InputBullets from 'components/InputBullets';
import InputCheckbox from 'components/InputCheckbox';
import InputSelect from 'components/InputSelect';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import Label from 'components/Label';
import Tag from 'components/Tag';
import Validator from 'connectors/Validator';

import PerkInputs from 'pages/EmployeePortal/EmployeeEditorPages/EditCompany/components/PerkInputs';
import InvestorInputs from 'pages/EmployeePortal/EmployeeEditorPages/EditCompany/components/InvestorInputs';
import LinkInputs from 'pages/EmployeePortal/EmployeeEditorPages/EditCompany/components/LinkInputs';
import BenefitInputs from 'pages/EmployeePortal/EmployeeEditorPages/EditCompany/components/BenefitInputs';

import styles from './EmployerEditorProfile.scss';

const EmployerEditorProfile = (props) => {
  const [companyWebsiteError, setCompanyWebsiteError] = useState('');

  const handlePatchResource = (attributes) => {
    const {
      actions: { patchResource },
      employer: { id },
    } = props;

    patchResource({
      id,
      attributes,
      requiredFields: ['name', 'email'],
      slice: 'toolboxEmployers',
      type: 'employers',
    });
  };

  const handleHiringLocationSelect = (place) => {
    const { employer: { attributes: { hiringLocations } = {} } = {} } = props;

    handlePatchResource({ hiringLocations: [...hiringLocations, place] });
  };

  const handleDeleteHiringLocation = ({ value }) => {
    const { employer: { attributes: { hiringLocations } = {} } = {} } = props;

    handlePatchResource({
      hiringLocations: hiringLocations.filter((loc) => loc !== value),
    });
  };

  const handleEmployerInputChange = (event) => {
    const {
      employer: { attributes },
    } = props;

    const {
      target: { name, value },
    } = event;

    let newAttributes = {};

    if (name === 'size') {
      const arrayFromValue = value.split(',');

      newAttributes = {
        sizeMin: arrayFromValue[0],
        sizeMax: arrayFromValue[1],
      };
    } else {
      const results = employerInputChange(event, attributes);
      let { newValue } = results;
      const { name } = results;

      if (name === 'webSite') {
        if (Validator.isUrl({ newValue })) {
          setCompanyWebsiteError('');
        } else {
          setCompanyWebsiteError('Illegal website url');
          return;
        }
      }

      // AutocompleteServer returns Company obj { name, id, picture }
      if (typeof newValue === 'object' && 'name' in newValue) {
        newValue = newValue.name;
      }

      newAttributes = { [name]: newValue };
    }

    handlePatchResource(newAttributes);
  };

  const handleAddArrayInput = (key) => {
    const {
      actions: { resourceUpdate },
      employer,
      employer: { attributes, attributes: { [key]: value = [] } = {}, id } = {},
    } = props;

    resourceUpdate({
      slice: 'toolboxEmployers',
      type: 'employers',
      id,
      resource: {
        ...employer,
        attributes: {
          ...attributes,
          [key]: [...value, ''],
        },
      },
    });
  };

  const handleAddArrayValue = (event, key) => {
    const {
      target: { name, value },
    } = event;
    const { employer: { attributes: { [key]: arrayToUpdate = [] } = {} } = {} } = props;

    let newValue = arrayToUpdate ? arrayToUpdate.slice() : [];

    newValue[name] = value;

    if (newValue.every((el) => !el)) {
      newValue = [];
    }

    handlePatchResource({ [key]: newValue });
  };

  const handleReorderArray = ({ startIndex, endIndex, name }) => {
    const { employer: { attributes: { [name]: arrayToUpdate } = {} } = {} } = props;

    const newArray = arrayToUpdate.slice();

    const [removed] = newArray.splice(startIndex, 1);

    newArray.splice(endIndex, 0, removed);

    handlePatchResource({ [name]: newArray });
  };

  const handleToggleArrayValue = (event) => {
    const {
      target: { name, value },
    } = event;
    const { employer: { attributes: { [name]: arrayToUpdate } = {} } = {} } = props;

    const newValue = toggleArray({
      array: arrayToUpdate,
      value,
    });

    handlePatchResource({ [name]: newValue });
  };

  const handleDeleteArrayInput = (indexToRemove, arrayName) => {
    const { employer: { attributes: { [arrayName]: arrayToUpdate } = {} } = {} } = props;

    const newValue = arrayToUpdate.filter((id, index) => index !== indexToRemove);

    handlePatchResource({ [arrayName]: newValue });
  };

  const {
    employer: {
      attributes: {
        about = '',
        benefits = [],
        email = '',
        foundedOn = '',
        highlights,
        hiringLocations = [],
        industry = [],
        investors = [],
        links = [],
        location = [],
        opportunities = [],
        perks = [],
        picture: { xlarge: pictureUrl } = {},
        products = [],
        name = '',
        sizeMin = '',
        socialMedia = {},
        stage = '',
        tagline = '',
        webSite = '',
        workRemote,
      } = {},
      id,
    } = {},
    handlePreviewButtonClick,
    pickerOptions: { stage: stageOptions = [], industry: industryOptionsRaw = [] } = {},
    tags: { perks: perksOptions = [], benefits: benefitsOptions = [] } = {},
    employerErrors: { name: nameErrors, email: emailErrors } = {},
    actions: { patchResource },
    saving = false,
  } = props;

  const industryOptions = industryOptionsRaw.filter((opt) => opt !== 'Many' && opt !== 'Other');

  const { linkedin = {}, facebook = {}, twitter = {} } = socialMedia || {};

  const { url: linkedinUrl = '' } = linkedin || {};

  const { url: facebookUrl = '' } = facebook || {};

  const { url: twitterUrl = '' } = twitter || {};

  const savingContent = saving ? (
    <div className={styles.autoSaving}>Saving...</div>
  ) : (
    <div className={styles.autoSaved}>Profile Saved</div>
  );

  const saveContent = saving === undefined ? null : savingContent;

  const previewButtonProps = {
    secondary: true,
    centered: true,
    onClick: handlePreviewButtonClick,
    className: styles.previewButton,
  };

  const titleBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    addPadding: true,
    className: styles.titleBlock,
  };

  const commonBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    addPadding: true,
  };

  const basicInfoBlockProps = {
    ...commonBlockProps,
    title: 'Basic Info',
    addChildFlex: true,
  };
  const socialMediaBlockProps = {
    ...commonBlockProps,
    title: 'Social Media',
    className: styles.firstBlock,
  };
  const perksBenefitsBlockProps = {
    ...commonBlockProps,
    title: 'Perks and Benefits',
    className: styles.firstBlock,
  };
  const investmentBlockProps = {
    ...commonBlockProps,
    title: 'Investment',
    className: styles.firstBlock,
  };
  const pressBlockProps = {
    ...commonBlockProps,
    title: 'Press & Media Exposure',
    className: styles.firstBlock,
  };

  const commonTextInputProps = {
    handleInputChange: handleEmployerInputChange,
    type: 'text',
    size: 'full',
  };

  const companyNameInputProps = {
    placeholder: 'Company Name',
    size: 'full',
    name: 'name',
    value: name || '',
    label: 'Company Name',
    handleInputChange: handleEmployerInputChange,
    handleOnBlur: handleEmployerInputChange,
    required: true,
    className: styles.noPadding,
    autocompleteType: 'companies',
    field: 'name',
    errors: nameErrors,
  };

  const companyEmailInputProps = {
    ...commonTextInputProps,
    required: true,
    name: 'email',
    label: 'Company Email',
    value: email || '',
    placeholder: 'Company Email',
    errors: emailErrors,
  };

  const companyLocationInputProps = {
    value: location,
    name: 'location',
    placeholder: 'Where is the company headquartered?',
    handleSelect: (place) =>
      handleEmployerInputChange({
        target: {
          value: place,
          name: 'location',
        },
      }),
  };

  const industrySource = industryOptions.map((ind) => ({ label: ind, value: ind }));

  const [industryValue] = industrySource.filter(({ label }) => label === industry);

  const companyIndustryInputProps = {
    ...commonTextInputProps,
    name: 'industry',
    label: 'Industry Type',
    value: industryValue ? industryValue.label : '',
    source: industrySource,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    defaultLabel: 'Industry',
    bigInput: true,
    required: true,
  };

  const [sizeValue] = COMPANY_SIZE_SOURCE.filter(
    ({ min }) => parseInt(min, 10) === parseInt(sizeMin, 10)
  );

  const companySizeInputProps = {
    ...commonTextInputProps,
    name: 'size',
    label: 'Company Size',
    value: sizeValue ? sizeValue.value : '',
    source: COMPANY_SIZE_SOURCE,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    defaultLabel: 'Size',
    bigInput: true,
    required: true,
  };

  const companyLinkedInInputProps = {
    ...commonTextInputProps,
    name: 'socialMedia,linkedin,url',
    size: 'xlarge',
    type: 'url',
    label: 'Company LinkedIn',
    value: linkedinUrl || '',
    placeholder: 'www.linkedin.com/in/awesome-company',
    className: styles.noPadding,
    required: true,
  };

  const companyTwitterInputProps = {
    ...commonTextInputProps,
    size: 'xlarge',
    name: 'socialMedia,twitter,url',
    label: 'Company Twitter',
    autoCapitalize: 'none',
    value: twitterUrl || '',
    placeholder: 'www.twitter.com/awesome-company',
    required: true,
  };

  const companyFacebookInputProps = {
    ...commonTextInputProps,
    name: 'socialMedia,facebook,url',
    size: 'xlarge',
    label: 'Company Facebook',
    value: facebookUrl || '',
    placeholder: 'www.facebook.com/awesome-company',
    required: true,
  };

  const companyWebSiteInputProps = {
    ...commonTextInputProps,
    required: true,
    name: 'webSite',
    label: 'Company Website',
    value: webSite || '',
    placeholder: 'www.awesome-company.com',
    errors: {
      isUrl: [companyWebsiteError.length === 0],
    },
    showErrorsImmediately: true,
  };

  const currentDate = new Date();

  const companyFoundedOnInputProps = {
    ...commonTextInputProps,
    size: 'small',
    name: 'foundedOn',
    label: 'Founding Year',
    value: foundedOn || '',
    centered: true,
    type: 'number',
    max: `${currentDate.getFullYear()}`,
    required: true,
  };

  const companyTaglineInputProps = {
    ...commonTextInputProps,
    name: 'tagline',
    label: 'Company Tagline',
    value: tagline || '',
    placeholder: 'The essence of who you are',
  };

  const aboutInputProps = {
    value: about,
    name: 'about',
    handleInputChange: handleEmployerInputChange,
  };

  const companyPerksInputProps = {
    handleToggleArrayValue,
    perks,
    perksOptions,
  };
  const companyLinksInputProps = {
    handleAddArrayInput,
    handleAddArrayValue,
    handleDeleteArrayInput,
    links: links || [],
  };
  const companyInvestorsInputProps = {
    handleToggleArrayValue,
    investors,
  };

  const companyBenefitsInputProps = {
    handleToggleArrayValue,
    benefits,
    benefitsOptions,
  };

  const stageSource = stageOptions.map((stage) => ({ label: stage, value: stage }));

  const [stageValue] = stageSource.filter(({ label }) => label === stage);

  const companyStageInputProps = {
    ...commonTextInputProps,
    name: 'stage',
    label: 'Funding',
    value: stageValue ? stageValue.label : '',
    source: stageSource,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    defaultLabel: 'Stage',
    noMargin: true,
    bigInput: true,
    required: true,
  };

  const hiringLocationsInputProps = {
    name: 'hiringLocations',
    placeholder: 'San Francisco, CA',
    handleSelect: handleHiringLocationSelect,
    needReset: true,
  };

  const hiringLocationsSelected = hiringLocations
    ? hiringLocations.map((loc) => {
        const tagProps = {
          name: loc,
          inputName: 'hiringLocations',
          onDeleteClick: (name, inputName) =>
            handleDeleteHiringLocation({
              value: name,
              name: inputName,
            }),
        };
        return <Tag key={loc} {...tagProps} />;
      })
    : null;

  const imageUploaderProps = {
    employerId: id,
    pictureUrl,
    employerImageUploadStarted: ({ picture }) =>
      patchResource({
        attributes: {
          picture: {
            file_data: picture,
          },
        },
        id,
        normalizeIt: true,
        requiredFields: ['name', 'email'],
        slice: 'toolboxEmployers',
        type: 'employers',
      }),
  };

  const highlightsInputProps = {
    label: 'Highlights',
    secondaryLabel: 'What are the key features or highlights of the company that you want to show?',
    array: highlights,
    name: 'highlights',
    handleAddArrayInput,
    handleAddArrayValue,
    handleDeleteArrayInput,
    handleReorderArray,
    pad: true,
    required: true,
  };

  const productsInputProps = {
    label: 'Products',
    secondaryLabel: 'What products do you sell?',
    array: products,
    name: 'products',
    handleAddArrayInput,
    handleAddArrayValue,
    handleDeleteArrayInput,
    handleReorderArray,
    pad: true,
    required: true,
  };

  const opportunitiesInputProps = {
    label: 'Why is this a great opportunity?',
    array: opportunities,
    name: 'opportunities',
    handleAddArrayInput,
    handleAddArrayValue,
    handleDeleteArrayInput,
    handleReorderArray,
    pad: true,
    required: true,
  };

  const workRemoteProps = {
    name: 'workRemote',
    label: 'Remote positions',
    checked: workRemote,
    handleInputChange: handleEmployerInputChange,
    className: styles.checkbox,
    checkboxMany: true,
  };

  const hiringLocationsContent =
    hiringLocations && hiringLocations.length > 0 ? (
      <div className={styles.autocompleteContainer}>{hiringLocationsSelected}</div>
    ) : null;

  const content = (
    <div className={styles.EditorProfile}>
      <Block {...titleBlockProps}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Company Profile</div>
          <div className={styles.saveContent}>
            {saveContent}
            <Button {...previewButtonProps}>Preview Profile</Button>
          </div>
        </div>
      </Block>
      <div className={styles.scrollDiv}>
        <form method="post" autoComplete="off" role="presentation">
          <Block {...basicInfoBlockProps}>
            <div className={styles.containerFlexRow}>
              <EmployerImageUploader {...imageUploaderProps} />
              <div className={styles.containerFlexColumn}>
                <div className={styles.basicInfoTop}>
                  <AutocompleteServer {...companyNameInputProps} />
                  <Input {...companyEmailInputProps} />
                  <Label required standalone className={styles.label}>
                    Company HQ Location
                  </Label>
                  <GooglePlacesAutocomplete {...companyLocationInputProps} />
                  <div className={styles.basicInfoRowApart}>
                    <InputSelect {...companySizeInputProps} />
                    <InputSelect {...companyIndustryInputProps} />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.basicInfoTop}>
              <Input {...companyWebSiteInputProps} />
              <Label standalone className={styles.label}>
                Hiring Locations
              </Label>
              <GooglePlacesAutocomplete {...hiringLocationsInputProps} />
              {hiringLocationsContent}

              <InputCheckbox {...workRemoteProps} />
            </div>
            <div className={styles.basicInfoRow}>
              <div className={styles.taglineContainer}>
                <Input {...companyTaglineInputProps} />
              </div>
              <div className={styles.foundedContainer}>
                <Input {...companyFoundedOnInputProps} />
              </div>
            </div>
            <Label required className={styles.aboutLabel}>
              Company Description
            </Label>
            <Content className={styles.aboutSublabel}>
              Let candidates know why they should work for a cool company like yours.
            </Content>
            <InputRichText {...aboutInputProps} />
            <InputBullets {...highlightsInputProps} />
            <InputBullets {...productsInputProps} />
            <InputBullets {...opportunitiesInputProps} />
          </Block>
          <Block {...socialMediaBlockProps}>
            <Input {...companyLinkedInInputProps} />
            <Input {...companyFacebookInputProps} />
            <Input {...companyTwitterInputProps} />
          </Block>
          <Block {...perksBenefitsBlockProps}>
            <PerkInputs {...companyPerksInputProps} />
            <BenefitInputs {...companyBenefitsInputProps} />
          </Block>
          <Block {...investmentBlockProps}>
            <InputSelect {...companyStageInputProps} />
            <InvestorInputs {...companyInvestorsInputProps} />
          </Block>
          <Block {...pressBlockProps}>
            <LinkInputs {...companyLinksInputProps} />
          </Block>
        </form>
      </div>
    </div>
  );

  const layoutWithoutSidebarProps = {
    content,
  };

  return <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />;
};

export default EmployerEditorProfile;
