import React from 'react';

import EmptyEducationLogo from 'shared/assets/missing-education-organization-picture.png';
import { ComponentErrorBoundaryHOC } from 'containers/HOC';

import Bold from 'components/Bold';
import Content from 'components/Content';
import FormattedArray from 'components/FormattedArray';

import styles from './EducationBlob.scss';

const EducationBlob = ({ education, recentGrad }) => {
  const { degree, educationOrganization: educationOrg, gpa, extracurricularActivities } = education;

  const { name, picture } = educationOrg || {};

  const { xlarge: pictureUrl } = picture || {};

  const picUrl = pictureUrl === '' ? EmptyEducationLogo : pictureUrl;

  const gpaContent =
    recentGrad && gpa ? (
      <Content className={styles.secondaryDetailsOne}>
        <Bold className={styles.educationTitle}>GPA</Bold>
        <Content className={styles.gpa}>{gpa}</Content>
      </Content>
    ) : null;

  const extraContent =
    recentGrad && extracurricularActivities ? (
      <Content className={styles.secondaryDetailsTwo}>
        <Bold className={styles.educationTitle}>Extracurricular Activities</Bold>
        <FormattedArray array={extracurricularActivities} />
      </Content>
    ) : null;

  const recentGradDetails =
    recentGrad && (gpaContent || extraContent) ? (
      <Content className={styles.secondaryContainer}>{extraContent}</Content>
    ) : null;

  return (
    <div className={styles.EducationBlob}>
      <div className={styles.educationPicture}>
        <img src={picUrl} alt="" />
      </div>
      <Content className={styles.educationDetails}>
        <span className={styles.educationName}>{name}</span>
        <span className={styles.educationDegree}>{degree}</span>
      </Content>
      {recentGradDetails}
    </div>
  );
};

export default ComponentErrorBoundaryHOC()(EducationBlob);
