import React from 'react';

import MikeImage from 'shared/assets/mike-fe.jpg';
import styles from './Greeting.scss';

const Greeting = () => (
  <div className={styles.welcomeBottom}>
    <div className={styles.greetingContainer}>
      <div className={styles.mikeImage}>
        <img src={MikeImage} alt="Michael Ferguson" />
      </div>
      <div className={styles.mikeGreeting}>Hi, I&apos;m Michael.</div>
      <div className={styles.mikeWelcome}>
        Let me know if you have questions about the approval process or anything else you&apos;d
        like to know about Rainmakers!
        <span className={styles.mikeEmail}>
          <a href="mailto:michael@rainmakers.co?subject=Rainmakers">michael@rainmakers.co</a>
        </span>
      </div>
    </div>
  </div>
);

export default Greeting;
