import React from 'react';

import Button from 'components/Button';

import styles from './MassAdd.scss';

const MassAdd = ({ show, handleAddAllEmployers }) => {
  const addAllEmployersProps = {
    onClick: handleAddAllEmployers,
    secondary: true,
    className: styles.addAllEmployersButton,
  };

  return show ? (
    <div className={styles.massAddContainer}>
      <div className={styles.info}>This option will automatically begin adding all employers:</div>
      <div className={styles.addButtons}>
        <Button {...addAllEmployersProps}>Mass Add All Employers</Button>
      </div>
    </div>
  ) : null;
};

export default MassAdd;
