import { call, put, select, takeLatest } from 'redux-saga/effects';

import { handleError } from 'utils/common';
import { deleteEntity } from 'api/apiEntity';

import { candidateProfilePatchDone } from 'connectors/User/redux/creators';

import { selectUser } from 'connectors/User/redux/selectors';

import { CANDIDATE_EDUCATION_DELETE_STARTED } from '../actions';

const deleteCandidateEducation = function* deleteCandidateEducation(action) {
  const {
    payload: { educationId },
  } = action;

  if (educationId) {
    try {
      const args = {
        id: educationId,
        type: 'candidate_educations',
      };

      yield call(deleteEntity, args);

      const { currentProfile = {} } = yield select(selectUser);

      const { candidateEducations: candEdus } = currentProfile;

      const newCandEdus = candEdus.filter((edu) => edu.id !== educationId);

      const candidateProfile = {
        ...currentProfile,
        candidateEducations: newCandEdus,
      };

      yield put(candidateProfilePatchDone({ candidateProfile }));
    } catch (error) {
      handleError(error);
    }
  }
};

const sagaCandidateEducationDeleteStarted = function* sagaCandidateEducationDeleteStarted() {
  yield takeLatest(CANDIDATE_EDUCATION_DELETE_STARTED, deleteCandidateEducation);
};

export default sagaCandidateEducationDeleteStarted;
