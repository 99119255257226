import { put, take } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { ADMIN_LOGIN_DONE, SAVE_REQUEST_INTERCEPTOR } from 'shared/store/app/actions';

import { addRequestInterceptor, loginDone, userSave } from 'shared/store/app/creators';

const sagaAdminLoginDone = function* sagaAdminLoginDone() {
  while (true) {
    const {
      payload: { user, headers },
    } = yield take(ADMIN_LOGIN_DONE);

    try {
      yield put(addRequestInterceptor({ headers }));

      yield take(SAVE_REQUEST_INTERCEPTOR);

      yield put(userSave({ user }));
      yield put(loginDone());
    } catch (error) {
      handleError(error);
    }
  }
};

export default sagaAdminLoginDone;
