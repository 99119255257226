import { postEntity } from 'api/apiEntity';

const promisePostTemplate = ({
  employee_id,
  name,
  salutation,
  template_markdown,
  template_html,
}) => {
  const onFulfilled = (response) => response;

  const args = {
    type: 'message_templates',
    data: {
      type: 'message_templates',
      attributes: {
        employee_id,
        name,
        salutation,
        template_markdown,
        template_html,
      },
    },
  };

  return postEntity(args).then(onFulfilled);
};

export default promisePostTemplate;
