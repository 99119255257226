import React, { Component } from 'react';

import * as Sentry from '@sentry/browser';
import { handleError } from 'utils/common';

import ConnectContainer from 'containers/ConnectContainer';

import duck from 'shared/store/app';

const ComponentErrorBoundaryHOC = () => (WrappedComponent) => {
  class _ComponentErrorBoundaryHOC extends Component {
    constructor(props) {
      super(props);

      this.state = { hasError: false };
    }

    componentDidCatch(error, errorInfo) {
      const {
        actions: { setAppError },
      } = this.props;

      Sentry.addBreadcrumb({
        category: 'extra_info',
        message: JSON.stringify(errorInfo),
      });
      handleError(error);

      this.setState({ hasError: true });

      setAppError();
    }

    render() {
      const { hasError } = this.state;

      if (hasError) {
        return null;
      }

      return <WrappedComponent {...this.props} />;
    }
  }

  return ConnectContainer(duck)(_ComponentErrorBoundaryHOC);
};

export default ComponentErrorBoundaryHOC;
