import { postEntity } from 'api/apiEntity';

const promisePostRequisitionCandidates = ({ candidateId, origin, reqId, ownerId }) => {
  const attributes = {
    candidateId,
    requisitionOpeningId: reqId,
    origin,
  };

  if (ownerId) {
    attributes.ownerId = ownerId;
    attributes.ownerType = 'Employee';
  }

  const onFulfilled = (response) => response;

  const args = {
    type: 'requisition_candidates',
    data: {
      type: 'requisition_candidate',
      attributes,
    },
    config: {
      params: {
        includeSet: 'message_groups',
        camelizeIt: true,
      },
    },
  };

  return postEntity(args).then(onFulfilled);
};

export default promisePostRequisitionCandidates;
