import * as genericCreators from 'shared/store/app/creators';
import * as creators from './creators';
import reducer from './reducers';
import selector from './selectors';

const duck = {
  creators: {
    ...genericCreators,
    ...creators,
  },
  reducer,
  selector,
};

export default duck;
