import React from 'react';

import Bold from 'components/Bold';
import Block from 'components/Block';
import Content from 'components/Content';
import ContentTitle from 'components/ContentTitle';

import styles from './CandidateUTMInfo.scss';

const CandidateUTMInfo = ({
  creatorType,
  referredByUser: { attributes: { currentProfileType: referredByUserProfileType } = {} } = {},
  user: {
    attributes: {
      utmSource = '-',
      utmMedium = '-',
      utmCampaign = '-',
      utmTerm = '-',
      utmContent = '-',
      referralCode = 'no code',
      referredByCode,
      referredUserCount = 0,
      referralsHired = 0,
    } = {},
  } = {},
}) => {
  const blockProps = {
    addWhiteBG: true,
    boxShadow: true,
    addPadding: true,
  };

  const referralType = referredByUserProfileType || null;

  const referralUserHighlight =
    referredByCode && referredByUserProfileType ? (
      <div className={styles.highlighted}>{referralType} Referral</div>
    ) : null;

  const referralUrlHighlight =
    !referredByCode && utmSource ? (
      <div className={styles.highlighted}>URL Referred</div>
    ) : (
      <div className={styles.highlightedRed}>No Referrer</div>
    );

  const referralHighlight = referralUrlHighlight || referralUserHighlight;

  const acquiredViaContent =
    creatorType === 'RainmakersAdmin' ? (
      <Content className={styles.flexContainer}>
        <div key="highlight" className={styles.detailContainer}>
          <div className={styles.highlighted}>Created</div>
        </div>
      </Content>
    ) : (
      [
        <div key="highlight" className={styles.detailContainer}>
          {referralHighlight}
        </div>,
        <Content key="utmInfo" className={styles.flexContainer}>
          <div className={styles.detailContainer}>
            <Bold>utm_source</Bold>
            <Content>{utmSource || '-'}</Content>
          </div>
          <div className={styles.detailContainer}>
            <Bold>utm_medium</Bold>
            <Content>{utmMedium || '-'}</Content>
          </div>
          <div className={styles.detailContainer}>
            <Bold>utm_campaign</Bold>
            <Content>{utmCampaign || '-'}</Content>
          </div>
          <div className={styles.detailContainer}>
            <Bold>utm_term</Bold>
            <Content>{utmTerm || '-'}</Content>
          </div>
          <div className={styles.detailContainer}>
            <Bold>utm_content</Bold>
            <Content>{utmContent || '-'}</Content>
          </div>
        </Content>,
      ]
    );

  return (
    <Block {...blockProps}>
      <ContentTitle>REFERRAL CODE</ContentTitle>
      <Content className={styles.flexContainer}>
        <div className={styles.detailContainer}>
          <Bold>Code #</Bold>
          <Content>{referralCode}</Content>
        </div>
        <div className={styles.detailContainer}>
          <Bold># of referrals</Bold>
          <Content>{referredUserCount}</Content>
        </div>
        <div className={styles.detailContainer}>
          <Bold># of hires</Bold>
          <Content>{referralsHired}</Content>
        </div>
      </Content>

      <ContentTitle className={styles.title}>ACQUIRED VIA</ContentTitle>

      {acquiredViaContent}
    </Block>
  );
};

export default CandidateUTMInfo;
