import React from 'react';
import { Helmet } from 'react-helmet-async';

import RainmakersLogo from 'shared/assets/rm-logo-short.png';
import HirewellLogo from 'shared/assets/hirewell-logo-short.png';
import ReadyContainer from 'connectors/ReadyContainer';

import Block from 'components/Block';
import Bolder from 'components/Bolder';
import Content from 'components/Content';
import Footer from 'pages/CandidatesHome/components/Footer';
import styles from './AboutUs.scss';

const AboutUs = () => {
  const blockProps = {
    addWhiteBG: true,
    addPadding: true,
  };

  const title = 'Sales Career Marketplace | Learn About Rainmakers';
  const metaDescription =
    'Rainmakers is the leading career marketplace that connects elite sales talent with the hottest tech sales jobs. Learn more about Rainmakers today.';
  const canonicalUrl = `https://${process.env.HOST}/about-us/`;

  const helmetProps = {
    key: 'helmet',
    title,
    meta: [
      { name: 'description', content: metaDescription },
      { property: 'og:url', content: canonicalUrl },
      { property: 'og:title', content: title },
      { property: 'og:description', content: metaDescription },
    ],
    link: [{ rel: 'canonical', href: canonicalUrl }],
  };

  return (
    <React.Fragment>
      <Helmet {...helmetProps} />
      <ReadyContainer key="readyContainer" className={styles.AboutUs}>
        <Block {...blockProps}>
          <h1 className={styles.header}>Our Story</h1>

          <Content className={styles.Content}>
            Founded in 2016, Rainmakers created a reputation for scaling high-performing sales teams
            for SaaS companies through their talent marketplace platform. In 2022, Hirewell, a
            Talent Solutions company, acquired Rainmakers to serve as both a self-serve and hiring
            experience platform for its clients. The integration of Rainmakers within Hirewell has
            further enabled Hirewell to provide its clients talent solutions powered by next gen
            tech.
          </Content>

          <div className={styles.headerTwo}>
            <Bolder>Founders</Bolder>
          </div>

          <Content className={styles.rainmaker}>
            <div className={styles.founderLogo}>
              <img src={RainmakersLogo} alt="" />
            </div>

            <Content className={styles.founderDetail}>
              <div className={styles.founderName}>About Rainmakers</div>

              <Content className={styles.founderDesc}>
                Rainmakers serves as a self-serve sales talent marketplace and as Hirewell’s
                candidate presentation platform.
              </Content>
            </Content>
          </Content>

          <Content className={styles.rainmaker}>
            <div className={styles.founderLogo}>
              <img src={HirewellLogo} alt="" />
            </div>

            <Content className={styles.founderDetail}>
              <div className={styles.founderName}>About Hirewell</div>

              <Content className={styles.founderDesc}>
                Hirewell is a global talent solutions company that delivers Advisory, RPO, Search,
                and Interim solutions across Go-To-Market (Sales, Marketing, Design), Corporate
                Functions (HR/TA, F&A), Tech (Dev, Product, Cloud, AI/MLL, Infrastructure), and
                Industrial (Supply Chain, Manufacturing, Engineering) roles.
              </Content>
            </Content>
          </Content>
        </Block>

        <Footer />
      </ReadyContainer>
    </React.Fragment>
  );
};

export default AboutUs;
