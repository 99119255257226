import { call, put, select, cancel, take, fork } from 'redux-saga/effects';

import { delay } from 'redux-saga';

import { handleError } from 'utils/common';

import { patchNestedEntity, postNestedEntity } from 'api/apiEntity';

import { selectCandidate } from '../selectors';

import { CAND_REQ_PATCH_STARTED } from '../actions';

import {
  candReqPatchDone,
  candidateSaveStarted,
  candidateSaveDone,
  candidateSaveError,
} from '../creators';

const patchCandReq = function* patchCandReq(action) {
  const {
    payload: { newValue, name },
  } = action;

  const { candidateRequirement: candReq } = yield select(selectCandidate);

  if (!candReq) {
    const emptyCandReq = {
      companySizeMax: '',
      companySizeMin: '',
      industry: [],
      locations: [],
      oteCents: '',
      roles: [],
      salaryCents: '',
    };

    yield put(candReqPatchDone({ newCandReq: { ...emptyCandReq, [name]: newValue } }));
  } else {
    const newCandReq = {
      ...candReq,
      [name]: newValue,
    };

    yield put(candReqPatchDone({ newCandReq }));
  }

  yield put(candidateSaveStarted());

  yield call(delay, 750);

  const { id: candidateId, candidateRequirement: updatedCandReq } = yield select(selectCandidate);

  if (
    updatedCandReq.id === undefined &&
    updatedCandReq.locations.length > 0 &&
    updatedCandReq.oteCents !== '' &&
    updatedCandReq.salaryCents !== ''
  ) {
    try {
      const args = {
        data: {
          type: 'candidate_requirement',
          attributes: {
            ...updatedCandReq,
            candidateId,
          },
        },
        id: candidateId,
        type: 'candidates',
        nestedType: 'candidate_requirement',
        config: {
          params: {
            camelizeIt: true,
          },
        },
      };

      const {
        data: { candidateRequirement: newestCandReq },
      } = yield call(postNestedEntity, args);

      yield put(candReqPatchDone({ newCandReq: newestCandReq }));
      yield put(candidateSaveDone());
    } catch (error) {
      handleError(error);
      yield put(candidateSaveError(error));
    }
  }

  if (updatedCandReq.id) {
    try {
      const args = {
        data: {
          type: 'candidate_requirement',
          id: updatedCandReq.id,
          attributes: {
            [name]: newValue,
          },
        },
        id: candidateId,
        type: 'candidates',
        nestedType: 'candidate_requirement',
        config: {
          params: {
            camelizeIt: true,
          },
        },
      };

      const {
        data: { candidateRequirement: newerCandReq },
      } = yield call(patchNestedEntity, args);

      const { candidateRequirement: latestCandReq } = yield select(selectCandidate);

      yield put(
        candReqPatchDone({
          newCandReq: {
            ...latestCandReq,
            [name]: newerCandReq[name],
          },
        })
      );

      yield put(candidateSaveDone());
    } catch (error) {
      handleError(error);
      yield put(candidateSaveError(error));
    }
  } else {
    yield put(candidateSaveDone());
  }
};

const sagaCandReqPatchStarted = function* sagaCandReqPatchStarted() {
  let lastTask;
  let lastAction;
  while (true) {
    const action = yield take(CAND_REQ_PATCH_STARTED);
    if (lastTask && lastAction.payload.name === action.payload.name) {
      yield cancel(lastTask);
    }
    lastAction = action;
    lastTask = yield fork(patchCandReq, action);
  }
};

export default sagaCandReqPatchStarted;
