import { createStructuredSelector } from 'reselect';

import { getCurrentUser, getApprovedStatus, getHasSeenApproved } from 'shared/store/app/selectors';

import { selectLogoutPending } from 'connectors/Login/redux/selectors';

import selectSignupErrors from './selectSignupErrors';
import selectServerErrors from './selectServerErrors';

const selector = createStructuredSelector({
  logoutPending: selectLogoutPending,
  signupErrors: selectSignupErrors,
  serverErrors: selectServerErrors,
  isApproved: getApprovedStatus,
  hasSeenApproved: getHasSeenApproved,
  user: getCurrentUser,
});

export { selectSignupErrors, selectServerErrors, selector as default };
