import { call, cancel, put, select, take, fork } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { push } from 'connected-react-router';
import { handleError } from 'utils/common';

import { patchNestedResource, postNestedResource } from 'api/apiResource';

import sagaValidateResource from 'connectors/Validator/redux/sagas/sagaValidateResource';

import { resourceSelectorFactory, errorsSelectorFactory } from 'reduxStore/selectorFactories';

import { formatType } from 'reduxStore/utils';

import { PATCH_NESTED_RESOURCE } from '../../actions';

import {
  apiErrorsUpdate,
  resourceErrorsUpdate,
  resourceUpdate,
  resourceDelete,
  savingUpdate,
} from '../../creators';

const doPatchNestedResource = function* doPatchNestedResource(action, browserHistory) {
  const {
    payload: {
      attributes,
      id,
      nestedId,
      ignoreValidations,
      ignoreNestedId,
      params,
      requiredFields = [],
      slice,
      successCallback,
      type,
      nestedType,
    },
  } = action;

  if (id && slice && type && nestedType) {
    yield put(savingUpdate({ saving: true, slice }));

    const resource = yield select(resourceSelectorFactory(slice, nestedType, nestedId));

    const { attributes: origAttributes = {}, attributes: { temporary } = {} } = resource || {};

    const newResource = {
      ...resource,
      attributes: {
        ...origAttributes,
        ...attributes,
      },
    };

    if (nestedId) {
      newResource.id = nestedId;
    }

    if (nestedId) {
      yield put(
        resourceUpdate({
          resource: newResource,
          id: nestedId,
          slice,
          type: nestedType,
        })
      );
    }

    yield call(delay, 750);

    let valid = true;

    if (nestedId) {
      const errors = yield select(errorsSelectorFactory(slice, nestedType, nestedId));

      const { inputErrors, isValid } = yield call(sagaValidateResource, {
        resource: newResource.attributes,
        errors,
      });

      valid = isValid;

      yield put(
        resourceErrorsUpdate({
          id: nestedId,
          inputErrors,
          slice,
          type: nestedType,
        })
      );
    }

    const needPost =
      requiredFields.every((field) => newResource.attributes[field]) &&
      (valid || ignoreValidations) &&
      temporary;

    const cleanAttributes = Object.keys(attributes).reduce((acc, curr) => {
      if (Array.isArray(attributes[curr])) {
        acc[curr] = attributes[curr].filter((el) => el);

        return acc;
      }

      if (curr !== 'temporary') {
        acc[curr] = attributes[curr];
      }

      return acc;
    }, {});

    if (needPost) {
      if (nestedId) {
        yield put(
          resourceUpdate({
            resource: {
              ...newResource,
              attributes: {
                ...newResource.attributes,
                isPosting: true,
              },
            },
            id: nestedId,
            slice,
            type: nestedType,
          })
        );
      }
      const cleanAllAttributes = Object.keys(newResource.attributes).reduce((acc, curr) => {
        if (Array.isArray(newResource.attributes[curr])) {
          acc[curr] = newResource.attributes[curr].filter((el) => el);
        } else {
          acc[curr] = newResource.attributes[curr];
        }

        return acc;
      }, {});
      try {
        const args = {
          config: {
            params: {
              normalizeIt: true,
              ...params,
            },
          },
          data: {
            attributes: cleanAllAttributes,
            type,
          },
          type,
          nestedType,
        };

        const {
          data: {
            entities: { [formatType(nestedType)]: { byId = {}, allIds = [] } = {} } = {},
          } = {},
        } = yield call(postNestedResource, args);

        const resourceWithId = {
          ...byId[allIds[0]],
        };

        yield put(
          resourceUpdate({
            id: resourceWithId.id,
            resource: resourceWithId,
            slice,
            type: nestedType,
          })
        );

        const { pathname, ...browserState } = browserHistory.location;

        if (pathname.includes(resource.id)) {
          const newPathname = pathname.replace(resource.id, resourceWithId.id);

          yield put(
            push({
              ...browserState,
              pathname: newPathname,
            })
          );
        }

        if (resourceWithId.id !== nestedId) {
          yield put(
            resourceDelete({
              id: nestedId,
              slice,
              type: nestedType,
            })
          );
        }

        successCallback && successCallback();
      } catch (error) {
        handleError(error);

        yield put(apiErrorsUpdate({ slice, error }));
      }
    }

    const needPatch = !temporary && newResource && (valid || ignoreValidations);

    if (needPatch) {
      try {
        const args = {
          config: {
            params,
          },
          data: {
            attributes: cleanAttributes,
            id: nestedId,
            type: nestedType,
          },
          id,
          type,
          nestedType,
        };

        if (!ignoreNestedId) {
          args.nestedId = nestedId;
        }

        yield call(patchNestedResource, args);

        successCallback && successCallback();
      } catch (error) {
        handleError(error);

        yield put(apiErrorsUpdate({ slice, error }));
      }
    }

    yield put(savingUpdate({ saving: false, slice }));
  }

  if (id && type && nestedType && !slice) {
    const cleanAttributes = Object.keys(attributes).reduce((acc, curr) => {
      if (Array.isArray(attributes[curr])) {
        acc[curr] = attributes[curr].filter((el) => el);
      } else {
        acc[curr] = attributes[curr];
      }

      return acc;
    }, {});

    try {
      const args = {
        config: {
          params,
        },
        data: {
          attributes: cleanAttributes,
          id: nestedId,
          type: nestedType,
        },
        id,
        nestedId,
        type,
        nestedType,
      };

      yield call(patchNestedResource, args);
    } catch (error) {
      handleError(error);
    }
  }
};

const patchNestedResourceSaga = function* patchNestedResourceSaga(browserHistory) {
  let lastTask;
  let lastAction = {};
  while (true) {
    const action = yield take(PATCH_NESTED_RESOURCE);

    const { payload: { attributes = {}, nestedId, nestedType, id, slice, type } = {} } = action;

    const {
      payload: {
        attributes: previousAttributes = {},
        nestedId: previousNestedId,
        id: previousId,
      } = {},
    } = lastAction;

    const attributesToUpdate = Object.keys(attributes);
    const previousAttributesToUpdate = Object.keys(previousAttributes);

    const sameAttributes =
      attributesToUpdate.length === previousAttributesToUpdate.length &&
      attributesToUpdate.every((attr) => previousAttributesToUpdate.includes(attr));

    if (nestedId && id && slice && type && nestedType) {
      const resource = yield select(resourceSelectorFactory(slice, nestedType, nestedId));

      const { attributes: { isPosting = false } = {} } = resource || {};

      if (
        !isPosting &&
        lastTask &&
        sameAttributes &&
        id === previousId &&
        nestedId === previousNestedId
      ) {
        yield cancel(lastTask);
      }

      if (!isPosting) {
        lastAction = action;
        lastTask = yield fork(doPatchNestedResource, action, browserHistory);
      }
    } else {
      if (lastTask && sameAttributes && id === previousId && nestedId === previousNestedId) {
        yield cancel(lastTask);
      }

      lastAction = action;
      lastTask = yield fork(doPatchNestedResource, action, browserHistory);
    }
  }
};

export default patchNestedResourceSaga;
