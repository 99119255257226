import React from 'react';

const FormattedCountdown = ({ time }) => {
  const renderTime = () => {
    const timeInHours = time / 3600000;

    if (timeInHours > 24) {
      return <span>{Math.round(timeInHours / 24)}</span>;
    }
    if (timeInHours >= 2) {
      return <span>1</span>;
    }
    if (timeInHours >= 0) {
      return <span>1</span>;
    }
    return <span>Already Happened</span>;
  };

  return renderTime();
};

export default FormattedCountdown;
