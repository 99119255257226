import React from 'react';

import Input from 'components/Input';
import DropdownSelectMany from 'components/DropdownSelectMany';

import styles from '../../ToolboxCohortsPage.scss';

const ToolboxCohortsSearchBar = ({
  category,
  handleSearchInputChange,
  handleSearchFilterChange,
  name,
  state,
}) => {
  const nameSearchProps = {
    name: 'name',
    placeholder: 'Search by cohort name',
    size: 'full',
    handleInputChange: handleSearchInputChange,
    isSearch: true,
    value: name,
  };

  const stateOptions = [
    {
      label: 'Open',
      value: 'open',
    },
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Complete',
      value: 'complete',
    },
  ];

  const categoryOptions = [
    {
      label: 'Featured',
      value: 'search',
    },
    {
      label: 'Match',
      value: 'featured',
    },
  ];

  const categorySource = categoryOptions.map((category) => ({
    indent: 0,
    label: category.label,
    optionType: 'category',
    subCategories: [],
    value: {
      type: 'category',
      category: category.value,
    },
  }));

  const selectedCategories = category.map((cat) => ({ category: cat, type: 'category' }));

  const categoryFilterProps = {
    className: styles.searchBarFilter,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    source: categorySource,
    name: 'category',
    key: 'category',
    optionsLarge: true,
    isLocation: true,
    onChange: (value) =>
      handleSearchFilterChange({
        target: {
          subCategories: value.subCategories,
          label: value.label,
          value: value.value,
          name: 'category',
          optionType: value.optionType,
        },
      }),
    selectedValues: selectedCategories,
    defaultLabel: 'Click to select category',
  };

  const stateSource = stateOptions.map((state) => ({
    indent: 0,
    label: state.label,
    optionType: 'state',
    subCategories: [],
    value: {
      type: 'state',
      state: state.value,
    },
  }));

  const selectedStates = state.map((state) => ({ state, type: 'state' }));

  const stateFilterProps = {
    className: styles.searchBarFilter,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    source: stateSource,
    name: 'state',
    key: 'state',
    optionsLarge: true,
    isLocation: true,
    onChange: (value) =>
      handleSearchFilterChange({
        target: {
          subCategories: value.subCategories,
          label: value.label,
          value: value.value,
          name: 'state',
          optionType: value.optionType,
        },
      }),
    selectedValues: selectedStates,
    defaultLabel: 'Click to select states',
  };

  return (
    <div>
      <div className={styles.SearchBarOne}>
        <DropdownSelectMany {...stateFilterProps} />
        <DropdownSelectMany {...categoryFilterProps} />
      </div>
      <div className={styles.SearchBarTwo}>
        <Input {...nameSearchProps} />
      </div>
    </div>
  );
};

export default ToolboxCohortsSearchBar;
