import { createStructuredSelector } from 'reselect';

import {
  selectIsApproved,
  selectIsAdmin,
  selectIsLoggedIn,
  selectIsEmployee,
  selectLogoutPending,
} from 'connectors/Login/redux/selectors';
import { selectUser } from 'connectors/User/redux/selectors';

import {
  entitySelectorFactory,
  loadingSelectorFactory,
  pageSelectorFactory,
  totalSelectorFactory,
} from 'reduxStore/selectorFactories';

import { getHideNames } from 'shared/store/app/selectors';

import selectRequestedCandidates from './selectRequestedCandidates';
import selectRequestConfirmation from './selectRequestConfirmation';
import selectSearchEmployerId from './selectSearchEmployerId';
import selectSearchParams from './selectSearchParams';
import selectSearchString from './selectSearchString';
import selectSearchEmployer from './selectSearchEmployer';
import selectSearchOptions from './selectSearchOptions';

const selector = createStructuredSelector({
  searchParams: selectSearchParams,
  searchString: selectSearchString,
  employerId: selectSearchEmployerId,
  employer: selectSearchEmployer,
  logoutPending: selectLogoutPending,
  user: selectUser,
  hideNames: getHideNames,
  isLoggedIn: selectIsLoggedIn,
  isApproved: selectIsApproved,
  isAdmin: selectIsAdmin,
  isEmployee: selectIsEmployee,
  candidates: entitySelectorFactory('employerSearch', 'candidate'),
  page: pageSelectorFactory('employerSearch', 'candidate'),
  total: totalSelectorFactory('employerSearch', 'candidate'),
  isSearching: loadingSelectorFactory('employerSearch', 'candidate'),
  savedSearches: entitySelectorFactory('employerSearch', 'savedSearch'),
  savedSearchesPage: pageSelectorFactory('employerSearch', 'savedSearch'),
  savedSearchesLoading: loadingSelectorFactory('employerSearch', 'savedSearch'),
  savedSearchesTotal: totalSelectorFactory('employerSearch', 'savedSearch'),
  requestConfirmation: selectRequestConfirmation,
  requestedCandidates: selectRequestedCandidates,
  candidateSearchOptions: selectSearchOptions,
});

export {
  selectRequestedCandidates,
  selectSearchEmployer,
  selectSearchEmployerId,
  selectSearchParams,
  selectSearchString,
  selectRequestConfirmation,
  selectSearchOptions,
  selector as default,
};
