import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { handleError } from 'utils/common';

import ReadyContainer from 'connectors/ReadyContainer';

import NotFound from 'pages/UtilPages/NotFound';

import RainTheater from 'components/RainTheater';

import { promiseGetVimeoVideo } from './promises';

import styles from './VideoPlayer.scss';

const VideoPlayer = (props) => {
  const [state, setState] = useState({ vimeoVideo: undefined });

  useEffect(() => {
    const {
      match: {
        params: { id },
      },
    } = props;

    promiseGetVimeoVideo({ id })
      .then((vimeoVideo) => setState({ vimeoVideo }))
      .catch((error) => {
        handleError(error);

        setState({ vimeoVideo: null });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    match: {
      params: { id },
    },
  } = props;

  const { vimeoVideo } = state;

  const { attributes: { vimeoResponse = {} } = {} } = vimeoVideo || {};

  const { files = [] } = vimeoResponse || {};

  const title = 'Intro Video';
  const metaDescription = 'Rainmakers Candidate Introduction Video';
  const canonicalUrl = `https://${process.env.HOST}/intro-video/${id}/`;

  const helmetProps = {
    key: 'helmet',
    title,
    meta: [
      { name: 'description', content: metaDescription },
      { property: 'og:url', content: canonicalUrl },
      { property: 'og:title', content: title },
      { property: 'og:description', content: metaDescription },
    ],
    link: [{ rel: 'canonical', href: canonicalUrl }],
  };

  if (vimeoVideo === null) {
    return <NotFound />;
  }

  return (
    <>
      <Helmet {...helmetProps} />
      <ReadyContainer key="readyContainer" className={styles.readyContainer}>
        <RainTheater files={files} />
      </ReadyContainer>
    </>
  );
};

export default VideoPlayer;
