import React, { Component } from 'react';
import { DateUtils } from 'react-day-picker';
import moment from 'moment-timezone';

import DatePicker from 'components/DatePicker';
import DropdownContent from 'components/DropdownContent';
import FormattedDate from 'components/FormattedDate';

import styles from './CohortDateRangePicker.scss';

class CohortDateRangePicker extends Component {
  constructor(props) {
    super(props);
    const { startDate: from, endDate: to } = this.props;

    const startDateInMS = from ? Date.parse(from) : null;
    const endDateInMS = to ? Date.parse(to) : null;

    const actualStartDate = from ? new Date(startDateInMS) : '';
    const actualEndDate = to ? new Date(endDateInMS) : '';

    this.state = {
      from: actualStartDate,
      to: actualEndDate,
      closeDropdown: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { startDate: from, endDate: to } = nextProps;

    if (from || to) {
      const startDateInMS = from ? Date.parse(from) : null;
      const endDateInMS = to ? Date.parse(to) : null;

      const actualStartDate = from ? new Date(startDateInMS) : '';
      const actualEndDate = to ? new Date(endDateInMS) : '';

      this.setState({
        from: actualStartDate,
        to: actualEndDate,
      });
    }
  }

  componentWillUnmount() {
    this.setState({
      from: '',
      to: '',
    });
  }

  handleStartDateClick = (day) => {
    const {
      patchResource,
      cohort: { id },
    } = this.props;

    const { to: stateTo } = this.state;

    const { from, to } = DateUtils.addDayToRange(day, this.state);

    const startDateInMS = from ? Date.parse(from) : null;
    const endDateInMS = to ? Date.parse(to) : null;

    const actualStartDate = from ? new Date(startDateInMS) : '';
    const actualEndDate = to ? new Date(endDateInMS) : '';

    this.setState({
      closeDropdown: to !== stateTo && to !== '',
      from: actualStartDate,
      to: actualEndDate,
    });

    if (from && to) {
      const startDate = from.toISOString().substring(0, 10);
      const endDate = to.toISOString().substring(0, 10);
      const momentStartDate = moment.tz(startDate, 'America/Los_Angeles').format();

      const momentEndDate = moment.tz(endDate, 'America/Los_Angeles').format();

      patchResource({
        type: 'cohorts',
        slice: 'toolboxCohorts',
        attributes: {
          startDate: momentStartDate,
          endDate: momentEndDate,
        },
        requiredFields: ['name', 'startDate', 'endDate'],
        id,
      });
    }
  };

  render() {
    const { disabled } = this.props;

    const { from, to, closeDropdown } = this.state;

    const fromDatePickerProps = {
      onDayClick: this.handleStartDateClick,
      selectedDays: [from, { from, to }],
    };

    const startDateDropdownProps = {
      label: 'Choose Dates',
      defaultIcon: 'caret-down',
      activeIcon: 'caret-up',
      dropRight: true,
      closeDropdown,
    };

    const momentStartDate = from ? moment.tz(from, 'America/Los_Angeles') : null;
    const momentEndDate = to ? moment.tz(to, 'America/Los_Angeles') : null;

    const formattedStart = from ? (
      <FormattedDate parse date={momentStartDate.format()} />
    ) : (
      <div className={styles.placeholderDate} />
    );
    const formattedEnd = to ? (
      <FormattedDate parse date={momentEndDate.format()} />
    ) : (
      <div className={styles.placeholderDate} />
    );

    const dropdownContent = disabled ? null : (
      <DropdownContent {...startDateDropdownProps}>
        <DatePicker {...fromDatePickerProps} />
      </DropdownContent>
    );

    return (
      <div className={styles.dateContent}>
        {dropdownContent}
        <div className={styles.copy}>From</div>
        <div className={styles.date}>{formattedStart}</div>
        <div className={styles.copy}>to</div>
        <div className={styles.date}>{formattedEnd}</div>
        <div className={styles.date}>PST</div>
      </div>
    );
  }
}

export default CohortDateRangePicker;
