import { createSelector } from 'reselect';
import { getApp } from 'shared/store/app/selectors';

const selectEmployeeEditor = createSelector(getApp, (state) => state.employerEditor);

const selectEmployeeSaving = createSelector(
  selectEmployeeEditor,
  (editor = {}) => editor.employeeSaving
);

export default selectEmployeeSaving;
