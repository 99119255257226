import React from 'react';

import DialogueModal from 'components/DialogueModal';
import Input from 'components/Input';
import Button from 'components/Button';

import styles from './SearchSaveModal.scss';

const SearchSaveModal = ({
  type,
  title,
  handleSaveSearch,
  handleEditSearch,
  handleCloseClick,
  handleInputChange,
}) => {
  const saveSearchInputProps = {
    handleInputChange,
    value: title,
    label: 'Name',
    name: 'Name',
    placeholder: 'Name this search',
    autoFocus: true,
    size: 'full',
  };

  const closeButtonProps = {
    onClick: handleCloseClick,
    quaternary: true,
  };

  const closeButton = <Button {...closeButtonProps}>Cancel</Button>;

  const actionButtonProps = {
    onClick: type === 'edit' ? handleEditSearch : handleSaveSearch,
    primary: true,
  };

  const actionButton = <Button {...actionButtonProps}>Save</Button>;

  const mainTitle = type === 'edit' ? 'Edit Saved Search' : 'Save Your Current Search';

  const input = (
    <div className={styles.saveSearchInput}>
      <Input {...saveSearchInputProps} />
    </div>
  );

  return (
    <DialogueModal>
      <div className={styles.saveSearchModal}>
        <div className={styles.saveSearchTitle}>{mainTitle}</div>
        {input}
        <div className={styles.saveSearchButtons}>
          {closeButton}
          {actionButton}
        </div>
      </div>
    </DialogueModal>
  );
};

export default SearchSaveModal;
