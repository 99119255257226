import { createSelector } from 'reselect';
import { getApp } from 'shared/store/app/selectors';

const selectCandidateSettings = createSelector(getApp, (state) => state.candidateSettings);

const selectCandidateCompanyBlocks = createSelector(
  selectCandidateSettings,
  (candidateSettings = {}) => candidateSettings.candidateCompanyBlocks.data
);

export default selectCandidateCompanyBlocks;
