import { all, fork } from 'redux-saga/effects';

import sagaAutoLogin from './sagaAutoLogin';
import sagaAddRequestInterceptor from './sagaAddRequestInterceptor';
import sagaRemoveRequestInterceptor from './sagaRemoveRequestInterceptor';
import sagaWaitForJob from './sagaWaitForJob';
import sagaPingSession from './sagaPingSession';

const sagaApp = function* sagaApp(history) {
  yield all([
    fork(sagaAutoLogin, history),
    fork(sagaAddRequestInterceptor),
    fork(sagaRemoveRequestInterceptor),
    fork(sagaWaitForJob),
    fork(sagaPingSession),
  ]);
};

export default sagaApp;
