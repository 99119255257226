import React from 'react';

import styles from './EmptyTable.scss';

const EmptyTable = ({ emptyTextString, emptyActionButton }) => {
  return (
    <div className={styles.emptyTable}>
      <div className={styles.emptyTableString}>{emptyTextString}</div>
      {emptyActionButton}
    </div>
  );
};

export default EmptyTable;
