import React, { useState } from 'react';
import classnames from 'classnames';
import InputMask from 'react-input-mask';

import InputErrors from 'components/InputErrors';
import Label from 'components/Label';

import styles from './InputTitle.scss';

const InputTitle = ({
  errors,
  name,
  label,
  placeholder,
  titleInput,
  required,
  type,
  handleInputChange,
  handleInputKeyPress,
  handleInputKeyDown,
  handleOnBlur,
  value,
  disabled,
  ultraDisabled,
  className,
  autoFocus,
  autoComplete,
}) => {
  const [state, setState] = useState({
    showErrors: false,
  });

  const { showErrors } = state;

  const handleSelfOnBlur = () => {
    const hasErrors = errors
      ? Object.keys(errors).filter((rule) => errors[rule][0] === false).length > 0
      : false;
    setState((prevState) => ({ ...prevState, showErrors: hasErrors }));
  };

  const divClasses = classnames(styles.Input, {
    [styles.flexColumn]: titleInput,
  });

  const containerClasses = className || styles.InputContainer;

  const errorsClasses = classnames(styles.inputErrors);

  const inputClasses = classnames(styles.inputStyle, {
    [styles.ultraDisabledInput]: ultraDisabled,
    [styles.hasErrorTitle]: showErrors,
  });

  const inputProps = {
    name,
    type,
    value,
    onChange: handleInputChange,
    onKeyPress: handleInputKeyPress,
    onKeyDown: handleInputKeyDown,
    onBlur: (event) => {
      !!handleOnBlur && handleOnBlur(event);
      return handleSelfOnBlur();
    },
    placeholder,
    className: inputClasses,
    disabled,
    autoFocus,
    autoComplete,
  };

  const labelClasses = classnames(styles.titleLabel, {
    [styles.ultraDisabledLabel]: ultraDisabled,
  });

  const labelContainerClasses = classnames(styles.regularLabel);

  return (
    <div className={containerClasses}>
      <div className={divClasses}>
        <div className={labelContainerClasses}>
          {label ? (
            <Label required={required} className={labelClasses} htmlFor={name}>
              {label}
            </Label>
          ) : null}
          {showErrors ? <InputErrors className={errorsClasses} errors={errors} /> : null}
        </div>
        <InputMask {...inputProps} />
      </div>
    </div>
  );
};

export default InputTitle;
