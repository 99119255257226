import { call, select, put, take } from 'redux-saga/effects';

import { entitySelectorFactory } from 'reduxStore/selectorFactories';

import { handleError } from 'utils/common';
import { trackEvent } from 'utils/analytics';
import { segmentAddBookmark } from 'segment/eventNames';

import { promiseBookmarksAdd } from '../../promises';

import { BOOKMARKS_ADD_STARTED } from '../actions';

import { resultsUpdate } from '../creators';

const trackBookmarkAdd = (bookmarkId, candidate, viewedFromPage) => {
  const event = segmentAddBookmark;

  const properties = {
    bookmarkId,
    candidateId: candidate.id,
    candidateFirstName: candidate.firstName,
    candidateLastName: candidate.lastName,
    candidateFullName: candidate.fullName,
    viewedFromPage,
  };

  const eventObject = { event, properties };

  trackEvent(eventObject);
};

const sagaBookmarksAdd = function* sagaBookmarksAdd() {
  while (true) {
    const {
      payload: { candidateId, viewedFromPage },
    } = yield take(BOOKMARKS_ADD_STARTED);

    const promiseParams = {
      candidateId,
    };

    const { byId, allIds } = yield select(entitySelectorFactory('employerSearch', 'candidate'));

    try {
      const newById = {
        ...byId,
        [candidateId]: {
          ...byId[candidateId],
          attributes: {
            ...byId[candidateId].attributes,
            bookmark: {
              id: 'fakeId',
              createdAt: new Date(),
            },
          },
        },
      };

      yield put(resultsUpdate({ type: 'candidate', entity: { allIds, byId: newById } }));

      const {
        data: {
          bookmark: { id, createdAt },
        },
      } = yield call(promiseBookmarksAdd, promiseParams);

      const newerById = {
        ...newById,
        [candidateId]: {
          ...newById[candidateId],
          attributes: {
            ...byId[candidateId].attributes,
            bookmark: {
              id,
              createdAt,
            },
          },
        },
      };

      yield put(resultsUpdate({ type: 'candidate', entity: { allIds, byId: newerById } }));

      const newCandidate = byId[candidateId].attributes;

      yield call(trackBookmarkAdd, id, newCandidate, viewedFromPage);
    } catch (error) {
      handleError(error);

      yield put(resultsUpdate({ type: 'candidate', entity: { allIds, byId } }));
    }
  }
};

export default sagaBookmarksAdd;
