import React, { Component } from 'react';

import DialogueModal from 'components/DialogueModal';
import Button from 'components/Button';
import Input from 'components/Input';

import styles from './EmailChangeModal.scss';

class EmailChangeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      newEmail: '',
    };
  }

  handleInputChange = (event) => {
    const {
      target: { name, value },
    } = event;

    this.setState({
      [name]: value,
    });
  };

  handleSubmitEmailAddressChange = () => {
    const { password, newEmail } = this.state;
    const { updateEmailStart } = this.props;

    updateEmailStart({ password, newEmail });
  };

  render() {
    const { handleCloseDialogue, emailError } = this.props;

    const { password, newEmail } = this.state;

    const actionButton = (
      <Button onClick={this.handleSubmitEmailAddressChange} primary>
        Submit
      </Button>
    );

    const closeButton = (
      <Button onClick={handleCloseDialogue} quaternary>
        Cancel
      </Button>
    );

    const title = 'Update Email Address';

    const newEmailInputProps = {
      handleInputChange: this.handleInputChange,
      value: newEmail,
      label: 'New Email Address',
      name: 'newEmail',
      placeholder: 'hangten@me.com',
      autoFocus: true,
      size: 'full',
      topLabel: true,
    };

    const newEmailInput = <Input {...newEmailInputProps} />;

    const passwordInputProps = {
      handleInputChange: this.handleInputChange,
      value: password,
      label: 'Current Password',
      name: 'password',
      type: 'password',
      placeholder: '*********',
      size: 'full',
      topLabel: true,
    };

    const passwordInput = <Input {...passwordInputProps} />;

    const fakeInput1 = <input style={{ display: 'none' }} type="text" name="email" />;
    const fakeInput2 = <input style={{ display: 'none' }} type="password" name="password" />;

    return (
      <DialogueModal>
        <div className={styles.emailChangeModal}>
          <div className={styles.emailChangeTitle}>{title}</div>
          <div className={styles.emailError}>{emailError}</div>
          <div className={styles.emailChangeContent}>
            Please provide your new email address as well as your current password to complete the
            update.
          </div>
          <div className={styles.emailChangeInputs}>
            <form method="post" autoComplete="off">
              {fakeInput1}
              {fakeInput2}
              {newEmailInput}
              {passwordInput}
            </form>
          </div>
          <div className={styles.emailChangeButtons}>
            {closeButton}
            {actionButton}
          </div>
        </div>
      </DialogueModal>
    );
  }
}

export default EmailChangeModal;
