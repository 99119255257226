import { createStructuredSelector } from 'reselect';

import selectIsAdmin from '../../../Login/redux/selectors/selectIsAdmin';
import selectIsCandidate from '../../../Login/redux/selectors/selectIsCandidate';
import selectOnboardingStatus from '../../../Login/redux/selectors/selectOnboardingStatus';

import selectUser from './selectUser';
import selectUTMInfo from './selectUTMInfo';
import selectIsIE from './selectIsIE';
import selectBackLink from './selectBackLink';
import selectDelayProfileCards from './selectDelayProfileCards';

const selector = createStructuredSelector({
  user: selectUser,
  isAdmin: selectIsAdmin,
  isIE: selectIsIE,
  isCandidate: selectIsCandidate,
  onboardingStatus: selectOnboardingStatus,
});

export {
  selectDelayProfileCards,
  selectBackLink,
  selectIsIE,
  selectUser,
  selectUTMInfo,
  selector as default,
};
