import React from 'react';

import Input from 'components/Input';

const BonusStructure = ({
  requisitionOpening: { attributes: { bonusStructure, state } = {} } = {},
  handleInputChange,
}) => {
  const bonusInputProps = {
    disabled: state === 'closed',
    name: 'bonusStructure',
    value: bonusStructure || '',
    label: 'Bonus Structure',
    placeholder: 'How will the Candidate make their OTE?',
    type: 'textarea',
    size: 'full',
    rows: '4',
    handleInputChange,
  };

  return <Input {...bonusInputProps} />;
};

export default BonusStructure;
