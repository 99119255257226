/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import locPageMetaData, { LOCATIONS_PAGE_CHICAGO } from 'pages/LocationsPage/locationsPageMetaData';
import jobsPageMetaData, { JOB_PAGE_AE } from 'pages/JobsPage/jobsPageMetaData';

import pic1 from './assets/1.png';
import pic2 from './assets/2.png';
import pic3 from './assets/3.png';
import pic4 from './assets/4.png';
import cta from './assets/CTA.png';

import styles from './ChicagoAEContent.scss';

export function ChicagoAEContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt="" />
      <h2>Chicago Is Made For Account Executives</h2>
      <p>
        Chicago is a perfect city to nurture an ambitious career in SaaS, fintech, and innovative
        technology sales, thanks to a combined expansion of tech enterprises and a rich sales
        environment.
      </p>
      <p>
        When tech businesses arrive in
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={locPageMetaData[LOCATIONS_PAGE_CHICAGO].localUrl}
        >
          {' '}
          Chicago{' '}
        </a>
        —especially after spending time in expensive coastal areas—they find Chicago to be
        incredibly supportive. Many information technology professionals benefit from Illinois’
        flat-rate income tax. For businesses, Chicago office space costs about half as much as it
        would in places like San Francisco or New York City.
      </p>
      <p>
        Chicago’s IT sector has grown by almost 20% over the past several years, which is not
        surprising given that the city has a cheaper cost of living than other tech hubs.
      </p>

      <h2>Why Live in Chicago?</h2>
      <p>
        Chicago is the most populated city in Illinois and the third-most populous city in the
        United States. One of the most influential cities in the world, the Chicago metropolitan
        region is a terrific place to live and is regarded as one of the top locations to live in
        the state.
      </p>
      <p>
        Chicago has a long history and a unique, welcoming culture. It is a city with some of the
        best cuisine in the country, storied sports franchises, and an indispensable center for
        American music, art, dance, and comedy.
      </p>
      <p>
        Situated in the Midwest and Great Lakes areas of the United States, Chicago offers its
        citizens first-rate amenities and dependable public transit to assist them in city living.
        Tech sales professionals that end up in Chicago immediately learn what a welcoming, friendly
        city it is.
      </p>

      <img src={pic2} alt="" />
      <h2>Tech Growth and Careers in Chicago</h2>
      <p>
        <a target="_blank" rel="noopener noreferrer" href={jobsPageMetaData[JOB_PAGE_AE].localUrl}>
          Experienced Account Executives{' '}
        </a>
        looking at Chicago can find employment with a wide range of tech-related companies,
        including fintech, IT, communications, and tech development. The wealth of opportunities
        will allow AEs to achieve considerable personal and professional growth, which could lead to
        more advanced tech sales careers.
      </p>
      <p>
        The Chicago Tech Effect report from the Chicagoland Chamber of Congress indicates that over
        the last ten years, Chicago’s technological environment has increased by 18%. In addition,
        around 8% of Chicago’s workforce is employed in the city’s tech industry, which also
        accounts for 87% of all new jobs produced in the area over the past ten years.
      </p>
      <p>
        Chicago is fostering upward mobility and greater economic equality than many other US
        regions because the median wage in the tech ecosystem is one and a half times higher than
        the median wage for the whole economy. In addition, the city attracts many people because
        almost half of Chicago’s tech sector jobs are in non-technical positions like sales and
        support.
      </p>

      <img src={pic3} alt="" />
      <h2>What Are an Account Executive’s Duties and Responsibilities?</h2>
      <p>
        Many firms depend on AEs as they generate a sizeable portion of the company’s revenue and
        act as its public face to clients.
      </p>
      <p>Their primary responsibilities may include:</p>
      <ul>
        <li>Having a thorough understanding of the product or service being sold</li>
        <li>Following up on leads that SDRs have provided</li>
        <li>Quarterbacking deals through education, demonstration, and contract signing</li>
        <li>Maintaining communication by phone, e-mail, and voicemail</li>
        <li>Preparing for sales meetings by doing research and producing sales decks</li>
        <li>
          After closing deals, nurturing and developing clients while looking for opportunities to
          upsell or cross-sell
        </li>
        <li>Attracting new clients and maintaining old ones</li>
        <li>Submitting fresh pitches to boost sales</li>
        <li>Assist with the training of new SDRs and new Account Executives</li>
        <li>Creating sales reports</li>
        <li>Attending team and department meetings</li>
      </ul>

      <img src={pic4} alt="" />
      <h2>A Typical Day as an Account Executive</h2>
      <p>
        What does a day-to-day rundown of an Account Executive’s tasks look like? Here’s a summary
        of what an AE’s primary daily responsibilities tend to be at most tech businesses.
      </p>
      <ul>
        <li>
          <p>
            <strong>Closing sales</strong> - After receiving leads from SDRs, AEs meet with the
            prospects, demonstrate their company’s products or services, and fine-tune the pitch to
            meet their needs. This is all working toward closing the deal and registering sales.
          </p>
        </li>
        <li>
          <p>
            <strong>Nurturing accounts</strong> - Account Executives also seek out and create
            opportunities to increase business with current clients. Successful AEs don’t wait for a
            customer to ask. Instead, they actively look for ways to meet a client’s developing
            needs.
          </p>
        </li>
        <li>
          <p>
            <strong>Maintaining customer satisfaction</strong> - AEs regularly ask clients for
            feedback on the company’s performance as their vendor. This way AEs can learn more about
            the customer’s feelings toward the vendor—both positive and negative—and allow the AE to
            improve relations and guarantee customer retention.
          </p>
        </li>
        <li>
          <p>
            <strong>Countering the competition</strong> - AEs must also ensure rival companies don’t
            poach clients. This can mean researching ways a rival might succeed and then working
            with your teams to counter that, whether it’s more engagement with the client, prompt
            service, or an improved product.
          </p>
        </li>
      </ul>

      <Link to="/apply-now" title="Apply Now">
        <img src={cta} alt="" />
      </Link>
      <h2>What Are the Key Metrics for Success in This Role?</h2>
      <p>
        Account Executives are usually evaluated on their ability to close sales, hit quotas, and
        retain clients. Typically, quotas are set by the month or the quarter. They include targets
        for the number of deals closed or achieving a predetermined revenue amount.
      </p>
      <p>
        A part of an Account Executive’s performance bonus or commission may be based on customer
        retention and the ability to close deals with desirable clients. Other factors for bonuses
        include customer satisfaction with the quality of service, retention length, and how many
        upselling and cross-selling opportunities are taken advantage of.
      </p>

      <p>
        To learn more about Account Executive roles in Chicago,{' '}
        <a href={`https://${process.env.HOST}/apply-now/`}>sign up with Rainmakers</a> today to get
        started on your path to success.
      </p>
    </div>
  );
}
