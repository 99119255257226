import React from 'react';
import Slider from 'react-rangeslider';

import DropdownSelectMany from 'components/DropdownSelectMany';
import Label from 'components/Label';

import styles from '../ReqRequirement.scss';

const SoldToIndustries = ({
  disabled,
  handleArrayChange,
  handleInputChange,
  requisitionRequirement: { attributes: { soldToIndustries, soldToIndustriesWt } = {} } = {},
  industryOptions,
}) => {
  const source = industryOptions.map((ind) => ({
    indent: 0,
    label: ind,
    optionType: 'ind',
    subCategories: [],
    value: {
      type: 'ind',
      ind,
    },
  }));

  const selectedIndustries = soldToIndustries
    ? soldToIndustries.map((ind) => ({ ind, type: 'ind' }))
    : [];

  const industryInputProps = {
    className: styles.searchBarFilter,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    onChange: (value) =>
      handleArrayChange({
        target: {
          subCategories: value.subCategories,
          label: value.label,
          value: value.value,
          name: 'soldToIndustries',
          optionType: value.optionType,
        },
      }),
    optionsLarge: true,
    source,
    name: 'soldToIndustries',
    key: 'soldToIndustries',
    isLocation: true,
    selectedValues: selectedIndustries || [],
    defaultLabel: 'Click to select an industry',
    disabled,
  };

  const industryWtProps = {
    className: styles.rangeSlider,
    disabled,
    min: 0,
    max: 10,
    step: 5,
    value: soldToIndustriesWt || 0,
    onChange: disabled
      ? () => null
      : (value) =>
          handleInputChange({
            target: {
              value,
              name: 'soldToIndustriesWt',
            },
          }),
    tooltip: false,
    labels: {
      0: '0',
      5: '5',
      10: '10',
    },
  };

  return (
    <div className={styles.rangeInputs}>
      <div className={styles.column}>
        <Label className={styles.label}>Industries sold to?</Label>
        <DropdownSelectMany {...industryInputProps} />
      </div>
      <div className={styles.column}>
        <Label className={styles.labelStyle}>How much do you value this?</Label>
        <Slider {...industryWtProps} />
      </div>
    </div>
  );
};

export default SoldToIndustries;
