import { select, put, take } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { entitySelectorFactory, totalSelectorFactory } from 'reduxStore/selectorFactories';

import { selectRequestedCandidates } from 'pages/EmployeePortal/CandidateSearchPage/redux/selectors';

import { REMOVE_CANDIDATES_FROM_SEARCH } from '../actions';

import { resultsUpdate, clearRequestedCandidates } from '../creators';

const sagaRemoveCandidatesFromSearch = function* sagaRemoveCandidatesFromSearch() {
  while (true) {
    yield take(REMOVE_CANDIDATES_FROM_SEARCH);

    try {
      const candidateIds = yield select(selectRequestedCandidates);

      const { byId, allIds } = yield select(entitySelectorFactory('employerSearch', 'candidate'));
      const total = yield select(totalSelectorFactory('employerSearch', 'candidate'));

      const newById = { ...byId };

      candidateIds.forEach((id) => {
        delete newById[id];
      });

      const newAllIds = allIds.filter((id) => !candidateIds.includes(id));

      yield put(
        resultsUpdate({
          type: 'candidate',
          entity: {
            byId: newById,
            allIds: newAllIds,
          },
          pagination: {
            totalCount: total - 1,
          },
        })
      );

      yield put(clearRequestedCandidates());
    } catch (error) {
      handleError(error);
    }
  }
};

export default sagaRemoveCandidatesFromSearch;
