import { createAction } from 'redux-actions';

import {
  HIDE_MODAL,
  SHOW_MODAL,
  NEXT_MODAL,
  SET_MODAL,
  PICKER_OPTIONS_LOAD_STARTED,
} from 'shared/store/app/actions';

import {
  ADD_REQUESTED_CANDIDATE,
  CLEAR_REQUESTED_CANDIDATES,
  REQUEST_SENT_DONE,
  PROFILE_BOOKMARK_ADD,
  PROFILE_BOOKMARK_REMOVE,
  ADMIN_SEARCH_CANDIDATE_HIDE_STARTED,
  RESULTS_LOAD_STARTED,
  SEARCH_CANDIDATE_HIDE_STARTED,
  SEARCH_CANDIDATE_UNDO_HIDE_STARTED,
} from 'pages/EmployeePortal/CandidateSearchPage/redux/actions';

import { EMPLOYEE_PATCH_STARTED } from 'pages/EmployeePortal/EmployeeEditorPages/redux/actions';

import {
  REQUISITION_DETAILS_LOAD_STARTED,
  REQUISITION_DETAILS_LOAD_DONE,
  REQUISITION_CANDIDATES_CREATE_STARTED,
  REQUISITION_CANDIDATES_CREATE_DONE,
  RESET_CANDIDATE,
  SET_NEED_RELOAD,
  RESET_NEED_RELOAD,
  MESSAGE_CREATE_STARTED,
  MESSAGE_ERROR_SET,
} from '../actions';

export const resultsLoadStarted = createAction(RESULTS_LOAD_STARTED);

export const addRequestedCandidate = createAction(ADD_REQUESTED_CANDIDATE);
export const clearRequestedCandidates = createAction(CLEAR_REQUESTED_CANDIDATES);

export const messageCreateStarted = createAction(MESSAGE_CREATE_STARTED);
export const messageErrorSet = createAction(MESSAGE_ERROR_SET);

export const pickerOptionsLoadStarted = createAction(PICKER_OPTIONS_LOAD_STARTED);

export const setNeedReload = createAction(SET_NEED_RELOAD);
export const resetNeedReload = createAction(RESET_NEED_RELOAD);

export const hideModal = createAction(HIDE_MODAL);
export const showModal = createAction(SHOW_MODAL);
export const nextModal = createAction(NEXT_MODAL);
export const setModal = createAction(SET_MODAL);

export const profileBookmarkAdd = createAction(PROFILE_BOOKMARK_ADD);
export const profileBookmarkRemove = createAction(PROFILE_BOOKMARK_REMOVE);

export const requisitionDetailsLoadStarted = createAction(REQUISITION_DETAILS_LOAD_STARTED);
export const requisitionDetailsLoadDone = createAction(REQUISITION_DETAILS_LOAD_DONE);
export const resetCandidate = createAction(RESET_CANDIDATE);

export const requisitionCandidatesCreateStarted = createAction(
  REQUISITION_CANDIDATES_CREATE_STARTED
);
export const requisitionCandidatesCreateDone = createAction(REQUISITION_CANDIDATES_CREATE_DONE);

export const requestSentDone = createAction(REQUEST_SENT_DONE);

export const searchCandidateUndoHideStarted = createAction(SEARCH_CANDIDATE_UNDO_HIDE_STARTED);
export const searchCandidateHideStarted = createAction(SEARCH_CANDIDATE_HIDE_STARTED);
export const adminSearchCandidateHideStarted = createAction(ADMIN_SEARCH_CANDIDATE_HIDE_STARTED);

export const employeePatchStarted = createAction(EMPLOYEE_PATCH_STARTED);
