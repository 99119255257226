import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import Sidebar from 'components/Sidebar';
import Title from 'components/Title';

import { CandidateAuthHOC } from 'containers/HOC';
import ConnectContainer from 'containers/ConnectContainer';

import ReadyContainer from 'connectors/ReadyContainer';

import duck from '../redux';
import styles from '../Settings.scss';

import Status from './components/Status';
import BlockedCompanies from './components/BlockedCompanies';

const PrivacySettings = (props) => {
  useEffect(() => {
    const {
      actions: { candidateLoadStarted, candidateCompanyBlocksLoadStarted },
      candidate: { id: candidateId },
    } = props;

    candidateLoadStarted();
    candidateCompanyBlocksLoadStarted({ candidateId, page: 1 });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMoreCandidateCompanyBlocks = () => {
    const {
      actions: { candidateCompanyBlocksLoadStarted },
      candidateCompanyBlocksPage: page,
      candidate: { id: candidateId },
    } = props;

    const nextPage = page + 1;

    candidateCompanyBlocksLoadStarted({
      page: nextPage,
      candidateId,
    });
  };

  const handleCandidateInputChange = ({ target: { value, name } }) => {
    const {
      actions: { candidateProfilePatchStarted },
    } = props;

    candidateProfilePatchStarted({
      name,
      newValue: value,
    });
  };

  const {
    candidate: {
      hiringStatus = '',
      hiringStatusResetAt = null,
      profileLocked = false,
      id: candidateId,
    } = {},
    candidateCompanyBlocks,
    candidateCompanyBlocksPage,
    candidateCompanyBlocksTotal,
    candidateCompanyBlocksLoading,
    candidateCompanyBlocksErrors,
    actions: {
      candidateCompanyBlocksCreateStarted,
      createCompanyAndBlockStarted,
      candidateCompanyBlocksErrorSet,
    },
  } = props;

  return (
    <>
      <Helmet title="Privacy Settings" />
      <ReadyContainer className={styles.EmployerStatusPage}>
        <Sidebar type={'candidateSettings'} page={'privacy-settings'} />
        <LayoutWithoutSidebar
          content={
            <div className={styles.WelcomePage}>
              <Title className={styles.title}>Manage Privacy</Title>

              <Status
                profileLocked={profileLocked}
                hiringStatus={hiringStatus}
                hiringStatusResetAt={hiringStatusResetAt}
                handleCandidateInputChange={handleCandidateInputChange}
              />

              <BlockedCompanies
                candidateCompanyBlocks={candidateCompanyBlocks}
                candidateCompanyBlocksPage={candidateCompanyBlocksPage}
                candidateCompanyBlocksTotal={candidateCompanyBlocksTotal}
                candidateCompanyBlocksLoading={candidateCompanyBlocksLoading}
                candidateCompanyBlocksErrors={candidateCompanyBlocksErrors}
                loadMoreCandidateCompanyBlocks={loadMoreCandidateCompanyBlocks}
                candidateCompanyBlocksErrorSet={candidateCompanyBlocksErrorSet}
                candidateCompanyBlocksCreateStarted={candidateCompanyBlocksCreateStarted}
                createCompanyAndBlockStarted={createCompanyAndBlockStarted}
                candidateId={candidateId}
              />
            </div>
          }
        />
      </ReadyContainer>
    </>
  );
};

export default ConnectContainer(duck)(CandidateAuthHOC()(PrivacySettings));
