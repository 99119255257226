import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import queryString from 'query-string';

import { trackEvent } from 'utils/analytics';

import { segmentOpportunitiesPageViewCandidate } from 'segment/eventNames';

import { CandidateAuthHOC } from 'containers/HOC';

import ConnectContainer from 'containers/ConnectContainer';

import ReadyContainer from 'connectors/ReadyContainer';

import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import TalentConcierge from 'components/TalentConcierge';

import OpportunitiesTable from './components/OpportunitiesTable';

import duck from './redux';

import styles from './CandidateOpportunities.scss';

@CandidateAuthHOC()
class CandidateOpportunities extends Component {
  constructor(props) {
    super(props);
    const {
      match: { params: { state: tableStatus = 'active' } = {} },
      location: { search },
    } = props;

    const { sortBy = 'created_at_desc' } = queryString.parse(search);

    const event = segmentOpportunitiesPageViewCandidate;
    const eventObject = { event, properties: {} };
    trackEvent(eventObject);

    this.state = {
      tableStatus,
      sortBy,
    };
  }

  componentDidMount() {
    const {
      actions: { opportunitiesLoadStarted, rainmakersAdminLoadStarted },
      candidate: { rainmakersAdminId: adminId },
    } = this.props;

    const { tableStatus, sortBy } = this.state;

    opportunitiesLoadStarted({
      page: 1,
      state: tableStatus,
      sortBy,
    });

    if (adminId) {
      rainmakersAdminLoadStarted({ adminId });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      match: { params: { state } = {} },
      isLoading,
    } = nextProps;

    if (!isLoading && state) {
      this.setState({
        tableStatus: state,
      });
    }
  }

  loadMoreOpportunities = () => {
    const {
      actions: { opportunitiesLoadStarted },
      page,
    } = this.props;

    const { tableStatus, sortBy } = this.state;

    const nextPage = page + 1;

    opportunitiesLoadStarted({
      page: nextPage,
      state: tableStatus,
      sortBy,
    });
  };

  handleChangeStatus = (status) => {
    const {
      actions: { opportunitiesLoadStarted },
    } = this.props;

    const { tableStatus, sortBy } = this.state;

    if (status !== tableStatus) {
      const newRoute = `/candidate/opportunities/${status}`;

      opportunitiesLoadStarted({
        page: 1,
        state: status,
        newRoute,
        sortBy,
      });
    }
  };

  handleSortChange = (value) => {
    const {
      actions: { opportunitiesLoadStarted },
    } = this.props;

    const { tableStatus, sortBy } = this.state;

    if (sortBy && sortBy.includes(value)) {
      const newDirection = sortBy.includes('asc') ? '_desc' : '_asc';

      const newSort = `${value}${newDirection}`;

      this.setState({ sortBy: newSort });

      const newRoute = `/candidate/opportunities/${tableStatus}?sortBy=${newSort}`;

      opportunitiesLoadStarted({
        page: 1,
        state: tableStatus,
        newRoute,
        sortBy: newSort,
      });
    } else {
      const newSort = `${value}_asc`;

      this.setState({ sortBy: `${value}_asc` });

      const newRoute = `/candidate/opportunities/${tableStatus}?sortBy=${newSort}`;

      opportunitiesLoadStarted({
        page: 1,
        state: tableStatus,
        newRoute,
        sortBy: newSort,
      });
    }
  };

  render() {
    const {
      actions: { showModal },
      candidate,
      candidate: { fullName } = {},
      children,
      isLoading,
      opportunities,
      rainmakersAdmin,
      total,
      user,
    } = this.props;

    const { sortBy, tableStatus: status } = this.state;

    const tableProps = {
      candidate,
      handleChangeStatus: this.handleChangeStatus,
      handleSortChange: this.handleSortChange,
      isLoading,
      loadMoreOpportunities: this.loadMoreOpportunities,
      opportunities,
      rainmakersAdmin,
      showModal,
      status,
      sortBy,
      total,
      user,
    };

    const talentConciergeProps = {
      admin: rainmakersAdmin,
      candidateName: fullName,
    };

    const content = (
      <div className={styles.candidateOpportunities}>
        <TalentConcierge {...talentConciergeProps} />
        <OpportunitiesTable {...tableProps} />
      </div>
    );

    const layoutWithoutSidebarProps = {
      content,
    };

    return [
      <Helmet key="helmet" title="Opportunities" />,

      <ReadyContainer key="readyContainer">
        <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />

        {children}
      </ReadyContainer>,
    ];
  }
}

export default ConnectContainer(duck)(CandidateOpportunities);
