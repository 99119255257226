import React from 'react';

import InputCheckboxMany from 'components/InputCheckboxMany';

const StateScope = ({ applicationStatus, stateSource, handleStateChange: handleInputChange }) => {
  const scopeInputProps = {
    source: stateSource,
    name: 'applicationStatus',
    handleInputChange,
    selectedValues: applicationStatus,
    blockStyle: true,
    hasLabel: true,
    smallBlock: true,
  };

  return <InputCheckboxMany {...scopeInputProps} />;
};

export default StateScope;
