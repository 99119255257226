import * as creators from './creators';

import selector from './selectors';

const duck = {
  creators,
  selector,
};

export default duck;
