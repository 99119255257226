import { handleError } from 'utils/common';
import { getNestedEntity } from 'api/apiEntity';

const promiseGetBatchRequest = ({ candidateId }) => {
  const onFulfilled = ({ data: { batchRequest = {} } }) => batchRequest;

  const onFailed = (error) => {
    handleError(error);

    return [];
  };

  const args = {
    id: candidateId,
    type: 'candidates',
    nestedType: 'batch_request',
    config: {
      params: {
        camelizeIt: true,
      },
    },
  };

  const promise = getNestedEntity(args).then(onFulfilled).catch(onFailed);

  return promise;
};

export default promiseGetBatchRequest;
