import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';

import ConnectContainer from 'containers/ConnectContainer';
import Validator from 'connectors/Validator';

import Block from 'components/Block';
import Input from 'components/Input';

import duck from './redux';

import styles from './ResetPassword.scss';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errors: {
        email: {
          isNotEmpty: [null],
          isEmail: [null],
        },
      },
    };
  }

  componentWillUnmount() {
    const {
      actions: { resetPasswordErrorReset },
      resetPasswordDone,
    } = this.props;

    if (resetPasswordDone) {
      resetPasswordErrorReset();
    }
  }

  handleOnBlur = (event) => {
    const {
      target: { name, value: newValue },
    } = event;

    const { errors } = this.state;

    const { inputErrors } = Validator.checkErrors({
      errorObject: errors,
      newValue,
      name,
    });

    this.setState({
      errors: inputErrors,
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const {
      actions: { resetPasswordStarted },
    } = this.props;

    const { email } = this.state;

    resetPasswordStarted({ email });
  };

  makeErrorText = (error) => {
    switch (error) {
      case 'Invalid login credentials. Please try again.':
        return 'The email does not match our records.';
      case 'cannot reset password for unconfirmed user':
        return 'Cannot reset password, please contact support@rainmakers.co';
      default:
        return 'Unable to reset password, please try again or contact support@rainmakers.co';
    }
  };

  render() {
    const { children, resetPasswordDone, resetPasswordErrors } = this.props;

    const {
      email,
      errors: { email: emailErrors },
    } = this.state;

    const errorText =
      resetPasswordErrors && resetPasswordErrors.confirmed_at
        ? this.makeErrorText(resetPasswordErrors.confirmed_at[0])
        : null;

    const pageTitle = <div className={styles.loginTitle}>Reset Password</div>;
    const subtitle = (
      <div className={styles.loginSubtitle}>
        Type your email and we&apos;ll send you information on how to reset your password.
      </div>
    );

    const blockProps = {
      addWhiteBG: true,
      addPadding: true,
    };

    const emailInputProps = {
      label: 'Email',
      placeholder: 'name@me.com',
      value: email,
      handleInputChange: this.handleChange,
      handleOnBlur: this.handleOnBlur,
      name: 'email',
      className: styles.loginInput,
      errors: emailErrors,
      size: 'full',
      topLabel: true,
    };

    if (resetPasswordDone) {
      return (
        <div className={styles.LoginContainer}>
          <Block {...blockProps}>
            <div className={styles.innerLoginContainer}>
              <div className={styles.loginTitle}>Email Sent</div>
              <div className={styles.loginSubtitle}>An email has been sent to</div>
              <div className={styles.loginSubtitle}>{email}</div>
            </div>
          </Block>
        </div>
      );
    }

    const title = 'Reset Password';
    const metaDescription =
      'Reset your password. Sales Jobs for Top Sales Talent. Sales jobs for top sales talent. Rainmakers connects top sales candidates with top companies.';
    const canonicalUrl = `https://${process.env.HOST}/reset-password/`;

    const helmetProps = {
      title,
      meta: [
        { name: 'description', content: metaDescription },
        { property: 'og:url', content: canonicalUrl },
        { property: 'og:title', content: title },
        { property: 'og:description', content: metaDescription },
      ],
      link: [{ rel: 'canonical', href: canonicalUrl }],
    };

    return (
      <div className={styles.LoginContainer}>
        <Helmet {...helmetProps} />
        <Block {...blockProps}>
          <div className={styles.innerLoginContainer}>
            {pageTitle}
            {subtitle}
            <form method="post" className={styles.loginForm} onSubmit={this.handleSubmit}>
              <div className={styles.loginError}>{errorText}</div>
              <div className={styles.loginInput}>
                <Input {...emailInputProps} />
              </div>

              <div className={styles.loginOptions}>
                <input className={styles.submitInput} type="submit" value="Send Email" />
              </div>
              {children}
            </form>
          </div>
        </Block>
      </div>
    );
  }
}

export default ConnectContainer(duck)(ResetPassword);
