import { createAction } from 'redux-actions';

import { SAVE_UTM_INFO } from 'shared/store/app/actions';

import {
  SIGNUP_STARTED,
  SIGNUP_DONE,
  SIGNUP_ERROR,
  SIGNUP_ERRORS_CHECK,
  SIGNUP_ERRORS_SET,
} from '../actions';

export const signupStarted = createAction(SIGNUP_STARTED);
export const signupDone = createAction(SIGNUP_DONE);
export const signupError = createAction(SIGNUP_ERROR);

export const signupErrorsCheck = createAction(SIGNUP_ERRORS_CHECK);
export const signupErrorsSet = createAction(SIGNUP_ERRORS_SET);

export const saveUTMInfo = createAction(SAVE_UTM_INFO);
