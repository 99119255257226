import React from 'react';
import classnames from 'classnames';

import Content from 'components/Content';
import FontIcon from 'components/FontIcon';

import TableCell from '../TableCell';

import styles from './TableHeader.scss';

const TableHeader = ({ className, header, sortBy, tableName }) => {
  const classes = classnames(className, styles.TableHeader, {
    [styles.candTable]: tableName === 'candidateDashboard',
    [styles.decidedTable]: tableName === 'decidedDashboard',
    [styles.reqTable]: tableName === 'reqManager',
    [styles.adminReqTable]: tableName === 'adminRequisitions',
  });

  const makeCell = ({ content, headerClassName, onClick, value }) => {
    const active = sortBy && sortBy.includes(value);

    const sortable = Boolean(onClick);

    const asc = active && sortBy.includes('asc');
    const desc = active && sortBy.includes('desc');

    const tableCellClasses = classnames(
      styles.TableHeaderCell,
      {
        [styles.asc]: asc,
        [styles.desc]: desc,
        [styles.clickable]: sortable,
      },
      headerClassName
    );

    const upArrowClasses = classnames(styles.UpArrow, {
      [styles.active]: asc,
    });

    const downArrowClasses = classnames(styles.Arrow, {
      [styles.active]: desc,
    });

    const sortableIcons = sortable ? (
      <div className={styles.sortable}>
        <FontIcon iconName="caret-up" className={upArrowClasses} />
        <FontIcon iconName="caret-down" className={downArrowClasses} />
      </div>
    ) : null;

    const cellProps = {
      key: content,
      className: tableCellClasses,
      onClick: sortable ? () => onClick(value) : () => null,
    };

    return (
      <TableCell {...cellProps}>
        {content}
        {sortableIcons}
      </TableCell>
    );
  };

  const headerContent = Array.isArray(header) ? header.map(makeCell) : <Content>{header}</Content>;

  return <div className={classes}>{headerContent}</div>;
};

export default TableHeader;
