import React, { useState } from 'react';
import classnames from 'classnames';

import FontIcon from 'components/FontIcon';

import styles from './SavedSearch.scss';

const SavedSearch = ({
  savedSearch,
  savedSearch: { attributes: { title } = {}, id } = {},
  handleEditSearchClick,
  handleDeleteSearchClick,
  handleLoadSavedSearch,
  searchActivatedId,
}) => {
  const [active, setActive] = useState(false);

  return (
    <div
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      className={styles.savedSearch}
    >
      <div
        onClick={() => handleLoadSavedSearch({ savedSearch })}
        className={classnames(styles.savedSearchTitle, {
          [styles.savedSearchActivated]: searchActivatedId === id,
        })}
      >
        {title}
      </div>
      <div
        className={classnames(styles.hoverContent, {
          [styles.activeHoverContent]: active,
        })}
      >
        <div
          title="Edit"
          className={styles.edit}
          onClick={() => handleEditSearchClick({ title, id })}
        >
          Edit
        </div>
        <div title="Delete" className={styles.delete} onClick={handleDeleteSearchClick}>
          <FontIcon iconName="trash" />
        </div>
      </div>
    </div>
  );
};

export default SavedSearch;
