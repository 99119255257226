import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch, useParams, useLocation } from 'react-router-dom';
import isEmpty from 'lodash.isempty';

import { handleError } from 'utils/common';
import { getEntity } from 'api/apiEntity';

import duck from 'pages/AdminPortal/ToolboxRequisitionsPage/redux';

import ReadyContainer from 'connectors/ReadyContainer';
import Validator from 'connectors/Validator';

import { AdminAuthHOC, ModalHOC } from 'containers/HOC';
import ConnectContainer from 'containers/ConnectContainer';
import { usePrevious } from 'hooks';

import Sidebar from 'components/Sidebar';

import Header from './components/Header';
import ESQuery from './components/ESQuery';
import RequisitionCandidates from './components/RequisitionCandidates';
import RequisitionOpening from './components/RequisitionOpening';
import RequisitionOpeningView from './components/RequisitionOpeningView';
import RequisitionRequirement from './components/RequisitionRequirement';
import RequisitionIntegrations from './components/RequisitionIntegrations';
import Suggestions from './components/Suggestions';
import SuggestionSets from './components/SuggestionSets';

import styles from './ToolboxRequisitionEditor.scss';

const ToolboxRequisitionEditor = (props) => {
  const location = useLocation();
  const params = useParams();
  const { id, view } = params;
  const prevId = usePrevious(id);

  const [state, setState] = useState({
    changedAttributes: [],
    changeMade: false,
    query: {},
    ReactJson: null,
  });

  useEffect(() => {
    const {
      actions: { pickerOptionsLoadStarted },
      requisitionOpening: { attributes: { temporary = false } = {} } = {},
    } = props;

    if (!temporary) {
      doLoad(id);
    }

    pickerOptionsLoadStarted();

    // TODO: refactor
    const ReactJson = require('react-json-view').default;
    setState((state) => ({ ...state, ReactJson }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id !== prevId) {
      doLoad(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const doLoad = (id) => {
    const {
      actions: { entityLoadStarted, nestedEntityLoadStarted },
    } = props;

    entityLoadStarted({
      type: 'requisition_openings',
      slice: 'toolboxRequisitions',
      id,
      params: {
        includeSet: 'employer',
        sortBy: 'created_at_desc',
      },
      updateOnly: true,
    });

    nestedEntityLoadStarted({
      nestedType: 'requisition_requirement',
      type: 'requisition_openings',
      id,
      slice: 'toolboxRequisitions',
      updateOnly: true,
    });

    getEntity({
      type: 'search/query',
      config: {
        params: {
          'filter[requisition_opening_id]': id,
        },
      },
    })
      .then(({ data: { data: query } }) => setState((state) => ({ ...state, query })))
      .catch(handleError);
  };

  const {
    employers: { byId: employersById = {} } = {},
    requisitionOpening,
    requisitionOpening: {
      attributes = {},
      attributes: {
        baseSalaryMaxCents,
        baseSalaryMinCents,
        employerId,
        oteMaxCents,
        oteMinCents,
        unlimitedHeadcount,
        workModel = [],
      } = {},
    } = {},
    requisitionOpeningErrors,
    requisitionRequirements: { byId = {} } = {},
  } = props;

  const requisitionRequirementIds = Object.keys(byId);

  const requisitionRequirementId = requisitionRequirementIds.find((requisitionRequirementId) => {
    const { attributes: { requisitionOpeningId } = {} } = byId[requisitionRequirementId];

    return requisitionOpeningId === id;
  });

  const requisitionRequirement = byId[requisitionRequirementId] || {};

  const { inputErrors } = Validator.checkAllErrors({
    errorObject: requisitionOpeningErrors,
    objectToCheck: attributes,
  });

  const invalidInputs = Object.keys(inputErrors).filter((input) => {
    const invalidRules = Object.keys(inputErrors[input]).filter((rule) => {
      if (input === 'locations' && workModel.length > 0) {
        return false;
      }
      if (input === 'headcount' && unlimitedHeadcount === true) {
        return false;
      }

      if (
        input === 'baseSalaryMaxCents' &&
        baseSalaryMaxCents &&
        baseSalaryMaxCents < baseSalaryMinCents
      ) {
        return true;
      }

      if (input === 'oteMaxCents' && oteMaxCents && oteMaxCents < oteMinCents) {
        return true;
      }

      return inputErrors[input][rule][0] === false || inputErrors[input][rule][0] === null;
    });
    return invalidRules.length > 0;
  });

  const requirementViews = ['requirements', 'requisition-requirement'];

  const newProps = {
    ...props,
    match: { params }, // TODO: remove when child components are refactored
    location, // TODO: remove when child components are refactored
    ...state,
    handleSetState: (state) => setState((prevState) => ({ ...prevState, ...state })),
    isValid: invalidInputs.length === 0,
    isRequirements: requirementViews.includes(view),
    requisitionRequirement,
    slice: 'toolboxRequisitions',
  };

  const { [employerId]: { attributes: employer = {} } = {} } = employersById || {};

  const sidebarProps = {
    employer,
    type: 'toolboxRequisitionEditor',
    page: view,
    id,
  };

  return isEmpty(requisitionOpening) ? null : (
    <>
      <Helmet key="helmet" title="Requisition Opening" />
      <ReadyContainer key="readyContainer">
        <Sidebar {...sidebarProps} />

        <div className={styles.mainContent}>
          <Header {...newProps} />
          <Switch>
            <Route exact path={'/toolbox/requisition/:id/view'}>
              <RequisitionOpeningView {...newProps} />
            </Route>
            <Route exact path={'/toolbox/requisition/:id/requisition-opening'}>
              <RequisitionOpening {...newProps} />
            </Route>
            <Route exact path={'/toolbox/requisition/:id/requisition-requirement'}>
              <RequisitionRequirement {...newProps} />
            </Route>
            <Route exact path={'/toolbox/requisition/:id/requisition-integrations'}>
              <RequisitionIntegrations {...newProps} />
            </Route>
            <Route exact path={'/toolbox/requisition/:id/es-query'}>
              <ESQuery {...newProps} />
            </Route>
            <Route exact path={'/toolbox/requisition/:id/suggestion-sets'}>
              <SuggestionSets {...newProps} />
            </Route>
            <Route exact path={'/toolbox/requisition/:id/suggestions'}>
              <Suggestions {...newProps} />
            </Route>
            <Route exact path={'/toolbox/requisition/:id/requisition-candidates'}>
              <RequisitionCandidates {...newProps} />
            </Route>
          </Switch>
        </div>
      </ReadyContainer>
    </>
  );
};

export default ConnectContainer(duck)(
  AdminAuthHOC()(
    ModalHOC({
      paginatorUrl: '/toolbox/requisition/:id/requisition-opening',
    })(ToolboxRequisitionEditor)
  )
);
