/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import jobsPageMetaData, {
  JOB_PAGE_AE,
  JOB_PAGE_CSM,
  JOB_PAGE_EAE,
  JOB_PAGE_MMAE,
  JOB_PAGE_SD,
  JOB_PAGE_SDR,
  JOB_PAGE_SM,
  JOB_PAGE_VP,
} from 'pages/JobsPage/jobsPageMetaData';

import { getAltName } from 'utils/urlUtils';

import pic1 from './assets/1-Austin-Tech-Sales-Career.png';
import pic2 from './assets/2-Austin-Sales-Careers-Tech.png';
import pic3 from './assets/3-Sales-Recruitment-Agency.png'; //CTA
import pic4 from './assets/4-Talent-Acquisition-Specialists.png';
import pic5 from './assets/5-Austin-Technology-Sales-Positions.png';

import styles from './AustinContent.scss';

export function AustinContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt={getAltName(pic1)} />

      <h2>Tech Sales Opportunities In Austin, TX</h2>

      <p>
        Austin, Texas, is internationally recognized for its role in the world of art, music, and
        culture. But there’s a lot more happening there, particularly in tech growth. Austin has
        proven to be a cultural, economic, and technological powerhouse and shows no signs of
        slowing down. Everything about Austin is expanding and changing, from the abundance of
        digital start-ups and corporations to the city’s infamous annual SXSW festival.
      </p>

      <p>What does this mean for Austin’s sales industry?</p>

      <p>
        It means Austin will continue to be a popular destination for companies trying to assemble a
        strong sales team and sales professionals looking for new career possibilities.
      </p>

      <h2>Living Well In Austin</h2>

      <p>
        Thanks to its pleasant climate, strong economy, and vibrant cultural scene, Austin is
        frequently praised as one of the best locations in the United States. Austin boasts several
        diverse housing options for those looking to settle long-term, from family-friendly suburbs
        to vibrant, lively metropolitan areas.
      </p>

      <p>
        In addition to being the state capital, Austin serves as the center of Texas’ creative
        scene. It is the location of the University of Texas at Austin, and the city is well-known
        for both Tex-Mex food and live music. Each spring and fall, the cities are overrun by the
        South by Southwest and Austin City Limits music festivals. Each one offers one-of-a-kind
        chances to see some of the most incredible musical acts ever touring, as well as interactive
        media presentations and entertainment-related technology debuts.
      </p>

      <p>
        <strong>
          With a median household income of around $100K, Austin’s wages are generally higher than
          other cities.
        </strong>{' '}
        Austin also has a low unemployment rate of 3.2%, less than Texas’s overall rate of 5.2% and
        the national average of 4.2%. This indicates Austin is an excellent place to find a job,
        particularly within the high-tech sector.
      </p>

      <img src={pic2} alt={getAltName(pic2)} />

      <h2>Tech and Financial Growth In Austin</h2>
      <p>
        Austin has long been a center for the software and technology industries. Along with other
        well-known tech businesses, top employers include such recognized names as Dell and Apple.
        In addition, Austin is home to a considerable number of high-tech manufacturing, video game
        development, and space technologies.
      </p>

      <p>
        According to the Austin City Chamber of Commerce,{' '}
        <strong>
          high-tech employment in Austin has increased by 24.4% over the past five years
        </strong>
        , compared to an overall increase of 11.6% for all industries. High tech has grown more than
        all other industries during the past ten years. Austin’s tech sector is expanding faster
        than the national average, 7.4% during the past five years and 17.3% over the previous ten
        years.
      </p>

      <p>
        This means many opportunities for sales professionals looking to launch or develop a
        long-term tech sales career.
      </p>

      <h2>Sales Opportunities In Austin</h2>

      <p>
        More and more positions are becoming available in Austin, Texas, at all levels in the
        technology sales sector. Rainmakers helps match exceptional candidates with the best
        employers for them. When quality sales staff and businesses come together, everyone wins.
      </p>

      <Link to="/apply-now" title="Apply Now">
        <img src={pic3} alt={getAltName(pic3)} />
      </Link>

      <h2>Sales Development Representative</h2>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_SDR].localUrl} target="_blank" rel="noreferrer">
          Sales Development Representatives (SDRs)
        </a>{' '}
        are valued and essential members of any SaaS sales team. An SDR primarily conducts inside
        sales activities, concentrating on cold calling and cold email outreach to build sales
        pipeline for the rest of the team. These are often entry-level roles that don’t require a
        lot of experience and are excellent places to start if you want to build a long-term sales
        career.
      </p>

      <h2>Account Executive</h2>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_AE].localUrl} target="_blank" rel="noreferrer">
          Account Executives (AE)
        </a>{' '}
        close deals with new customers, look after the needs of current clients, and bring in new
        sales business. Since a significant portion of an AE’s compensation comes from commissions,
        they work hard to meet set quotas and revenue targets.
      </p>

      <h2>Mid-Market Account Executive</h2>

      <p>
        This is the best next step for sales professionals who are enthusiastic about their careers
        and want to grow it.{' '}
        <a href={jobsPageMetaData[JOB_PAGE_MMAE].localUrl} target="_blank" rel="noreferrer">
          Mid-Market Account Executives
        </a>{' '}
        handle training, support growth, and offer services using SaaS/cloud technology while
        facilitating sales, concluding deals, and maintaining strong client relationships. Their
        primary source of new sales comes from middle-market companies.
      </p>

      <img src={pic4} alt={getAltName(pic4)} />

      <h2>Enterprise Account Executive</h2>

      <p>
        Manufacturers, software companies, telecommunications companies, and retail chains can
        benefit from employing enterprise account executives. This role has tremendous earning
        potential due to the level of responsibility it entails.{' '}
        <a href={jobsPageMetaData[JOB_PAGE_EAE].localUrl} target="_blank" rel="noreferrer">
          Enterprise Account Executives{' '}
        </a>{' '}
        are essential for organizations to sustain successful sales partnerships and foster
        innovation. They must be dedicated, competent, and well-prepared.
      </p>

      <h2>Customer Success Manager</h2>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_CSM].localUrl} target="_blank" rel="noreferrer">
          Customer Success Managers (CSMs)
        </a>{' '}
        work hard to create dependable, long-lasting client relationships. After a transaction,
        companies must prove the value of their products, and it is the responsibility of the
        Customer Success Manager to promote products and services to foster success and repeat
        business. Even though CSMs are a relatively new sales function, nearly half of high-tech
        enterprises employ them effectively today.
      </p>

      <h2>Sales Director</h2>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_SD].localUrl} target="_blank" rel="noreferrer">
          Sales Directors
        </a>{' '}
        uphold the company’s dedication to providing top-notch customer service, ensure that the
        sales team completes all necessary tasks, and strategically develop and deliver new products
        and services. Sales Directors supervise the sales team, hold training sessions, and ensure
        everyone works toward their objectives. Strong leadership, sales, and organizational
        abilities are of great value.
      </p>

      <img src={pic5} alt={getAltName(pic5)} />

      <h2>Sales Manager</h2>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_SM].localUrl} target="_blank" rel="noreferrer">
          Sales Managers
        </a>{' '}
        oversee both daily sales activities and sales representatives. They develop sales
        strategies, set and track sales goals, and keep a close eye on profit growth. A Sales
        Manager may have numerous responsibilities, depending on the company. Typical duties include
        finding sales talent, creating the sales plan, coaching, and keeping track of sales
        operations.
      </p>

      <h2>Vice President of Sales</h2>

      <p>
        A{' '}
        <a href={jobsPageMetaData[JOB_PAGE_VP].localUrl} target="_blank" rel="noreferrer">
          Vice President (VP) of Sales
        </a>{' '}
        may have several essential responsibilities. They frequently serve as a manager, mentor, and
        strategist, but they can also be tasked with enhancing the performance of the sales division
        and meeting quotas. Duties will include developing goals and sales strategies, managing
        sales teams, managing budgets, acquiring sales talent, and more.
      </p>

      <h2>Sales Career Opportunities at Rainmakers</h2>

      <p>
        Tech sales careers frequently offer abundant opportunities for personal and professional
        development. In addition, there are many excellent opportunities for promotion for those
        prepared to put in the time and effort necessary to surpass expectations and demonstrate
        their value.
      </p>

      <p>
        Any growing company’s sales funnel depends on every position—from Sales Development
        Representatives to the Vice President of Sales. Success is assured if candidates put in the
        requisite work, have the appropriate skills, and are committed to the task at hand.
      </p>

      <p>
        <a href="/apply-now/">Create your profile</a> with Rainmakers today to learn more about
        these excellent opportunities.
      </p>
    </div>
  );
}
