import { createAction } from 'redux-actions';

import {
  USER_UPDATE,
  USER_PATCH_DONE,
  CANDIDATE_PROFILE_PATCH_DONE,
  SET_APP_MODAL_ERROR,
  PICKER_OPTIONS_LOAD_STARTED,
} from 'shared/store/app/actions';

import {
  CAND_REQ_POST_STARTED,
  CAND_REQ_POST_DONE,
  CAND_REQ_PATCH_STARTED,
  CAND_REQ_PATCH_DONE,
  CANDIDATE_ERRORS_SET,
  CANDIDATE_ERRORS_RESET,
  CANDIDATE_ERRORS_UPDATE,
  CANDIDATE_FILE_UPLOAD_STARTED,
  CANDIDATE_FILE_UPLOAD_DONE,
  CANDIDATE_IMG_UPLOAD_STARTED,
  CANDIDATE_IMG_UPLOAD_DONE,
  CANDIDATE_PATCH_ALL_STARTED,
  CANDIDATE_PATCH_DONE,
  CANDIDATE_PATCH_STARTED,
  CANDIDATE_POST_STARTED,
  CANDIDATE_POST_DONE,
  CANDIDATE_RESET,
  CANDIDATE_SAVE_STARTED,
  CANDIDATE_SAVE_DONE,
  CANDIDATE_SAVE_ERROR,
  CANDIDATE_UPDATE,
  PRIVATE_CANDIDATE_UPDATE,
  CANDIDATE_VALID_SET,
  CANDIDATE_VALID_RESET,
  REORDER_EDUCATION_STARTED,
  REORDER_EDUCATION_DONE,
  EDUCATION_DELETE_STARTED,
  EDUCATION_DELETE_DONE,
  EDUCATION_PATCH_STARTED,
  EDUCATION_PATCH_DONE,
  EDUCATION_POST_STARTED,
  EDUCATION_POST_DONE,
  EMAIL_CHANGE_STARTED,
  EMAIL_CHANGE_DONE,
  RESUME_SAVE_STARTED,
  RESUME_SAVE_DONE,
  SALES_PERIOD_POST_STARTED,
  SALES_PERIOD_POST_DONE,
  SALES_PERIOD_PATCH_STARTED,
  SALES_PERIOD_PATCH_DONE,
  USER_PATCH_STARTED,
  USER_ERRORS_SET,
  USER_ERRORS_RESET,
  VALIDATE_ALL,
  REORDER_WORK_STARTED,
  REORDER_WORK_DONE,
  WORK_HISTORY_PATCH_STARTED,
  WORK_HISTORY_PATCH_DONE,
  WORK_HISTORY_POST_STARTED,
  WORK_HISTORY_POST_DONE,
  WORK_HISTORY_DELETE_STARTED,
  WORK_HISTORY_DELETE_DONE,
} from '../actions';

export const setAppModalError = createAction(SET_APP_MODAL_ERROR);

export const candidatePatchStarted = createAction(CANDIDATE_PATCH_STARTED);
export const candidatePatchAllStarted = createAction(CANDIDATE_PATCH_ALL_STARTED);
export const candidatePatchDone = createAction(CANDIDATE_PATCH_DONE);

export const candidatePostStarted = createAction(CANDIDATE_POST_STARTED);
export const candidatePostDone = createAction(CANDIDATE_POST_DONE);
export const candidateUpdate = createAction(CANDIDATE_UPDATE);
export const privateCandidateUpdate = createAction(PRIVATE_CANDIDATE_UPDATE);
export const candidateSaveStarted = createAction(CANDIDATE_SAVE_STARTED);
export const candidateSaveDone = createAction(CANDIDATE_SAVE_DONE);
export const candidateSaveError = createAction(CANDIDATE_SAVE_ERROR);
export const candidateReset = createAction(CANDIDATE_RESET);
export const candidateErrorsReset = createAction(CANDIDATE_ERRORS_RESET);
export const candidateErrorsSet = createAction(CANDIDATE_ERRORS_SET);
export const candidateErrorsUpdate = createAction(CANDIDATE_ERRORS_UPDATE);
export const candidateValidSet = createAction(CANDIDATE_VALID_SET);
export const candidateValidReset = createAction(CANDIDATE_VALID_RESET);

export const emailChangeStarted = createAction(EMAIL_CHANGE_STARTED);
export const emailChangeDone = createAction(EMAIL_CHANGE_DONE);

export const validateAll = createAction(VALIDATE_ALL);

export const userPatchStarted = createAction(USER_PATCH_STARTED);
export const userPatchDone = createAction(USER_PATCH_DONE);
export const userUpdate = createAction(USER_UPDATE);
export const userErrorsSet = createAction(USER_ERRORS_SET);
export const userErrorsReset = createAction(USER_ERRORS_RESET);

export const candidateImageUploadStarted = createAction(CANDIDATE_IMG_UPLOAD_STARTED);
export const candidateImageUploadDone = createAction(CANDIDATE_IMG_UPLOAD_DONE);
export const candidateFileUploadStarted = createAction(CANDIDATE_FILE_UPLOAD_STARTED);
export const candidateFileUploadDone = createAction(CANDIDATE_FILE_UPLOAD_DONE);

export const reorderWorkStarted = createAction(REORDER_WORK_STARTED);
export const reorderWorkDone = createAction(REORDER_WORK_DONE);
export const workHistoryPatchStarted = createAction(WORK_HISTORY_PATCH_STARTED);
export const workHistoryPatchDone = createAction(WORK_HISTORY_PATCH_DONE);
export const workHistoryPostStarted = createAction(WORK_HISTORY_POST_STARTED);
export const workHistoryPostDone = createAction(WORK_HISTORY_POST_DONE);
export const workHistoryDeleteStarted = createAction(WORK_HISTORY_DELETE_STARTED);
export const workHistoryDeleteDone = createAction(WORK_HISTORY_DELETE_DONE);

export const salesPeriodPostStarted = createAction(SALES_PERIOD_POST_STARTED);
export const salesPeriodPostDone = createAction(SALES_PERIOD_POST_DONE);
export const salesPeriodPatchStarted = createAction(SALES_PERIOD_PATCH_STARTED);
export const salesPeriodPatchDone = createAction(SALES_PERIOD_PATCH_DONE);

export const reorderEducationStarted = createAction(REORDER_EDUCATION_STARTED);
export const reorderEducationDone = createAction(REORDER_EDUCATION_DONE);
export const educationPostStarted = createAction(EDUCATION_POST_STARTED);
export const educationPostDone = createAction(EDUCATION_POST_DONE);
export const educationPatchStarted = createAction(EDUCATION_PATCH_STARTED);
export const educationPatchDone = createAction(EDUCATION_PATCH_DONE);
export const educationDeleteStarted = createAction(EDUCATION_DELETE_STARTED);
export const educationDeleteDone = createAction(EDUCATION_DELETE_DONE);

export const candReqPostStarted = createAction(CAND_REQ_POST_STARTED);
export const candReqPostDone = createAction(CAND_REQ_POST_DONE);
export const candReqPatchStarted = createAction(CAND_REQ_PATCH_STARTED);
export const candReqPatchDone = createAction(CAND_REQ_PATCH_DONE);

export const pickerOptionsLoadStarted = createAction(PICKER_OPTIONS_LOAD_STARTED);

export const resumeSaveStarted = createAction(RESUME_SAVE_STARTED);
export const resumeSaveDone = createAction(RESUME_SAVE_DONE);

export const candidateProfilePatchDone = createAction(CANDIDATE_PROFILE_PATCH_DONE);
