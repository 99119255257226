import { getEntity } from 'api/apiEntity';

const promiseGetRequisitionCandidate = ({
  includeSet = 'admin_toolbox',
  requisitionCandidateId,
}) => {
  const onFulfilled = ({ data: { requisitionCandidate = {} } }) => ({ requisitionCandidate });

  const args = {
    config: {
      params: {
        includeSet,
        camelizeIt: true,
      },
    },
    type: 'requisition_candidates',
    id: requisitionCandidateId,
  };

  return getEntity(args).then(onFulfilled);
};

export default promiseGetRequisitionCandidate;
