import { createStructuredSelector } from 'reselect';

import {
  entitySelectorFactory,
  loadingSelectorFactory,
  pageSelectorFactory,
  savingSelectorFactory,
  totalSelectorFactory,
} from 'reduxStore/selectorFactories';

import { getModal } from 'shared/store/app/selectors';

import {
  selectIsLoggedIn,
  selectIsAdmin,
  selectLogoutPending,
} from 'connectors/Login/redux/selectors';

import { selectUser } from 'connectors/User/redux/selectors';

import selectEducationOrg from './selectEducationOrg';

const selector = createStructuredSelector({
  educationOrg: selectEducationOrg,
  educationOrgs: entitySelectorFactory('toolboxEducationOrgs', 'educationOrganization'),
  isAdmin: selectIsAdmin,
  isLoading: loadingSelectorFactory('toolboxEducationOrgs', 'educationOrganization'),
  isLoggedIn: selectIsLoggedIn,
  logoutPending: selectLogoutPending,
  modal: getModal,
  page: pageSelectorFactory('toolboxEducationOrgs', 'educationOrganization'),
  saving: savingSelectorFactory('toolboxEducationOrgs'),
  total: totalSelectorFactory('toolboxEducationOrgs', 'educationOrganization'),
  user: selectUser,
  unverifiedEducationOrganizationData: entitySelectorFactory(
    'toolboxEducationOrgs',
    'unverifiedEducationOrganizationData'
  ),
});

export { selectEducationOrg, selector as default };
