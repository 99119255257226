import { all, fork } from 'redux-saga/effects';

import sagaCandidateProfilePatchStarted from './sagaCandidateProfilePatchStarted';
import sagaCandidateLoadStarted from './sagaCandidateLoadStarted';
import sagaCandidateCompanyBlocksLoadStarted from './sagaCandidateCompanyBlocksLoadStarted';
import sagaCandidateCompanyBlocksDeleteStarted from './sagaCandidateCompanyBlocksDeleteStarted';
import sagaCandidateCompanyBlocksCreateStarted from './sagaCandidateCompanyBlocksCreateStarted';
import sagaCreateCompanyAndBlockStarted from './sagaCreateCompanyAndBlockStarted';

const sagaCandidateProfile = function* sagaCandidateProfile() {
  yield all([
    fork(sagaCandidateProfilePatchStarted),
    fork(sagaCandidateLoadStarted),
    fork(sagaCandidateCompanyBlocksLoadStarted),
    fork(sagaCandidateCompanyBlocksDeleteStarted),
    fork(sagaCandidateCompanyBlocksCreateStarted),
    fork(sagaCreateCompanyAndBlockStarted),
  ]);
};

export default sagaCandidateProfile;
