import { call, select, put, take } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { entitySelectorFactory } from 'reduxStore/selectorFactories';

import { getEntity, postEntity } from 'api/apiEntity';

import { ADMIN_FEATURED_CANDIDATE_PASS_STARTED } from '../actions';

import { featuredCandidateUpdate } from '../creators';

const sagaFeaturedCandidatePassStarted = function* sagaFeaturedCandidatePassStarted() {
  while (true) {
    const {
      payload: { candidateId, loggedInEmployerId },
    } = yield take(ADMIN_FEATURED_CANDIDATE_PASS_STARTED);

    const { byId } = yield select(entitySelectorFactory('featured', 'candidate'));

    try {
      const {
        data: {
          employer: { company_id: companyId },
        },
      } = yield call(getEntity, {
        id: loggedInEmployerId,
        type: 'employers',
      });

      yield put(
        featuredCandidateUpdate({
          candidate: {
            ...byId[candidateId].attributes,
            hide: true,
          },
        })
      );

      const expiresAt = new Date();

      expiresAt.setMonth(expiresAt.getMonth() + 6);

      const args = {
        type: 'candidate_company_blocks',
        data: {
          type: 'candidate_company_block',
          attributes: {
            candidateId,
            companyId,
            ownerId: companyId,
            ownerType: 'Company',
            blockType: 'featured_pass',
          },
        },
      };

      const {
        data: {
          candidate_company_block: { id: candidateCompanyBlockId },
        },
      } = yield call(postEntity, args);

      yield put(
        featuredCandidateUpdate({
          candidate: {
            ...byId[candidateId].attributes,
            hide: true,
            candidateCompanyBlockId,
          },
        })
      );
    } catch (error) {
      handleError(error);

      yield put(
        featuredCandidateUpdate({
          candidate: byId[candidateId].attributes,
        })
      );
    }
  }
};

export default sagaFeaturedCandidatePassStarted;
