import { call, put, select, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { handleError } from 'utils/common';

import { postSignIn } from 'api/apiAuth';
import { patchEntity } from 'api/apiEntity';

import {
  apiErrorsUpdate,
  adminLoginDone,
  candidateLoginDone,
  employeeLoginDone,
  removeRequestInterceptor,
  resourceUpdate,
  savingUpdate,
} from 'shared/store/app/creators';

import { resourceSelectorFactory } from 'reduxStore/selectorFactories';

import { PATCH_USER_EMAIL } from '../../actions';

const LOGIN_DONE_CREATORS = {
  RainmakersAdmin: adminLoginDone,
  Employee: employeeLoginDone,
  Candidate: candidateLoginDone,
};

const doPatchUserEmail = function* doPatchUserEmail(action) {
  const {
    payload: {
      attributes: { email, password },
      id,
      slice,
    },
  } = action;

  if (id && slice) {
    yield put(savingUpdate({ saving: true, slice }));
    const resource = yield select(resourceSelectorFactory(slice, 'users', id));

    const { attributes: origAttributes, attributes: { email: originalEmail } = {} } = resource;

    const newResource = {
      ...resource,
      attributes: {
        ...origAttributes,
        email,
      },
      id,
    };

    yield put(
      resourceUpdate({
        resource: newResource,
        id,
        slice,
        type: 'users',
      })
    );

    yield call(delay, 750);

    if (id) {
      try {
        const loginArgs = {
          data: {
            password,
            email: originalEmail,
          },
        };

        yield call(postSignIn, loginArgs);

        try {
          const args = {
            data: {
              type: 'users',
              id,
              attributes: {
                email,
              },
            },
            type: 'users',
            id,
          };

          yield call(patchEntity, args);

          const loginArgs = {
            data: {
              password,
              email,
            },
            config: {
              params: {
                includeSet: 'login',
              },
            },
          };

          const {
            data: { user },
            headers,
          } = yield call(postSignIn, loginArgs);

          if (user) {
            yield put(removeRequestInterceptor());

            const { pathname, query, state } = window.browserHistory.location;

            const autoLoginPath = { pathname, query, state };

            const loginDoneArgs = {
              user,
              headers,
              autoLoginPath,
            };

            const { current_profile_type: currentProfileType } = user;

            const { [currentProfileType]: loginDoneFunction } = LOGIN_DONE_CREATORS;

            yield put(loginDoneFunction(loginDoneArgs));
          }
        } catch (error) {
          handleError(error);

          const { response } = error;

          yield put(apiErrorsUpdate({ slice, error: response }));
        }

        yield put(savingUpdate({ saving: false, slice }));
      } catch (error) {
        handleError(error);

        const { response } = error;

        yield put(apiErrorsUpdate({ slice, error: response }));
      }
    } else {
      yield put(savingUpdate({ saving: false, slice }));
    }
  }
};
const patchUserEmailSaga = function* patchUserEmailSaga() {
  yield takeLatest(PATCH_USER_EMAIL, doPatchUserEmail);
};

export default patchUserEmailSaga;
