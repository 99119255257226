import React from 'react';
import isEmpty from 'lodash.isempty';

import Content from 'components/Content';
import ContentTitle from 'components/ContentTitle';
import FormattedObjectPills from 'components/FormattedObjectPills';

import { camelizeLocations } from 'utils/locationUtils';

import styles from './HiringLocations.scss';

const HiringLocations = ({
  // pickerOptions: { candReqLocations = [] } = {},
  requisitionOpening: { attributes: { locations = [], workModel = [] } = {} } = {},
}) => {
  const locationNames = locations.map((loc) => loc[loc.type]);

  if (locationNames[0] === 'Other') return null;

  // const locsWithSubs = candReqLocations.filter((loc) => loc.subcategories);

  const subLocations = {};
  const subWorkModel = {};

  // locsWithSubs.forEach((loc) => {
  //   locationNames.forEach((locName) => {
  //     const subNames = loc.subcategories.map((sub) => sub.name);
  //     if (subNames.includes(locName)) {
  //       locationNames = locationNames.filter((name) => locName !== name);
  //       if (subLocations[loc.name]) {
  //         subLocations[loc.name].push(locName);
  //       } else {
  //         subLocations[loc.name] = [locName];
  //       }
  //     }
  //   });
  //   if (subLocations[loc.name] && subLocations[loc.name].length === loc.subcategories.length) {
  //     subLocations[loc.name] = ['All areas'];
  //   }
  // });

  if (locationNames.length > 0) {
    subLocations['Location Names'] = camelizeLocations(locationNames);
  }

  // locationNames.forEach((locName) => {
  //   subLocations[locName] = locName === 'Other' ? [] : ['All areas'];
  // });

  if (workModel.length > 0) {
    subWorkModel['Work Model'] = workModel;
  }

  const contentLocations = !isEmpty(subLocations) ? (
    <Content className={styles.Locations}>
      <FormattedObjectPills object={subLocations} cutOff={3} hideKey={true} />
    </Content>
  ) : null;

  const contentWorkModel = !isEmpty(subWorkModel) ? (
    <Content className={styles.Locations}>
      <FormattedObjectPills object={subWorkModel} cutOff={3} hideKey={true} />
    </Content>
  ) : null;

  return contentLocations || contentWorkModel ? (
    <div className={styles.HiringLocations}>
      {contentLocations && (
        <div className={styles.HiringLocationsColLeft}>
          <ContentTitle>HIRING REGION</ContentTitle>
          {contentLocations}
        </div>
      )}
      {contentWorkModel && (
        <div className={styles.HiringLocationsColRight}>
          <ContentTitle>WORK MODEL</ContentTitle>
          {contentWorkModel}
        </div>
      )}
    </div>
  ) : null;
};

export default HiringLocations;
