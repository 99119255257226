import React, { useEffect } from 'react';
import isEmpty from 'lodash.isempty';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, useParams } from 'react-router-dom';

import ReadyContainer from 'connectors/ReadyContainer';

import Sidebar from 'components/Sidebar';
import Empty from 'components/Empty';

import { AdminAuthHOC, ModalHOC } from 'containers/HOC';
import ConnectContainer from 'containers/ConnectContainer';

import duck from 'pages/AdminPortal/ToolboxAdminsPage/redux';

import { usePrevious } from 'hooks';

import AdminEditorPersonal from './components/AdminEditorPersonal';
import AdminEditorSettings from './components/AdminEditorSettings';
import AdminEditorSuggestionSets from './components/AdminEditorSuggestionSets';
import AdminEditorReferrals from './components/AdminEditorReferrals';

const ToolboxAdminEditor = (props) => {
  const {
    actions: { pickerOptionsLoadStarted },
    rainmakersAdmin,
    rainmakersAdmin: { attributes: { temporary = false } = {} } = {},
    rainmakersAdmin: { relationships: { user: { data: userIds = [] } = {} } = {} } = {},
    actions: { entityLoadStarted, patchUserResource },
  } = props;

  const { id, view } = useParams();
  const prevId = usePrevious(id);

  useEffect(() => {
    pickerOptionsLoadStarted();

    if (!temporary) {
      doLoad(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id !== prevId) {
      doLoad(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const doLoad = (id) => {
    entityLoadStarted({
      type: 'rainmakers_admins',
      slice: 'toolboxAdmins',
      id,
      params: {
        includeSet: 'user',
        sortBy: 'created_at_desc',
      },
      updateOnly: true,
    });

    entityLoadStarted({
      type: 'suggestion_sets',
      slice: 'toolboxAdmins',
      params: {
        includeSet: 'list_component',
        page: 1,
        rainmakersAdminId: id,
        sortBy: 'created_at_desc',
        complete: null,
      },
    });
  };

  const handlePatchResource = (attributes, type) => {
    patchUserResource({
      attributes,
      id: type === 'users' ? userIds[0] : id,
      slice: 'toolboxAdmins',
      type,
    });
  };

  const handleAdminInputChange = (event) => {
    const {
      target: { name, value, type, checked },
    } = event;

    const newValue = type === 'checkbox' ? checked : value;

    handlePatchResource({ [name]: newValue }, 'rainmakers_admins');
  };

  const handleUserInputChange = (event) => {
    const {
      target: { name, value },
    } = event;

    handlePatchResource({ [name]: value }, 'users');
  };

  const newProps = {
    ...props,
    handleAdminInputChange,
    handleUserInputChange,
  };

  if (isEmpty(rainmakersAdmin)) {
    return (
      <>
        <Helmet key="helmet" title="Admin Editor" />
        <Empty />
      </>
    );
  }

  return (
    <>
      <Helmet key="helmet" title="Admin Editor" />
      <ReadyContainer key="readyContainer">
        <Sidebar type="toolboxAdminEditor" page={view} id={id} />

        <Switch>
          <Route exact path={'/toolbox/admin/:id/edit-profile'}>
            <AdminEditorPersonal {...newProps} />
          </Route>
          <Route exact path={'/toolbox/admin/:id/edit-settings'}>
            <AdminEditorSettings {...newProps} />
          </Route>
          <Route exact path={'/toolbox/admin/:id/suggestion-sets/:nestedId?'}>
            <AdminEditorSuggestionSets {...newProps} />
          </Route>
          <Route exact path={'/toolbox/admin/:id/referrals'}>
            <AdminEditorReferrals {...newProps} />
          </Route>
        </Switch>
      </ReadyContainer>
    </>
  );
};

export default ConnectContainer(duck)(
  AdminAuthHOC()(ModalHOC({ paginatorUrl: '/toolbox/admin/:id/edit-profile' })(ToolboxAdminEditor))
);
