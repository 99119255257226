import React from 'react';
import styles from './SocialProof.scss';

const SocialProof = () => (
  <div className={styles.SocialProof}>
    <div className={styles.TopQuoteContainer}>
      <div className={styles.QuoteContainer}>
        <div className={styles.QuoteOne}>
          &ldquo;Rainmakers is awesome! They helped me land a role that is absolutely perfect for
          me.&rdquo;
        </div>
        <div className={styles.Person}>
          <div className={styles.Avatar}>
            <img src={require('../../assets/quote_avatar_1.png')} alt="Sarah" />
          </div>
          <div className={styles.Info}>
            <div className={styles.Name}>Sarah Draxton</div>
            <div className={styles.Role}>SDR</div>
          </div>
        </div>
      </div>

      <div className={styles.QuoteContainer}>
        <div className={styles.QuoteTwo}>
          &ldquo;Working with Rainmakers was an easy and seamless experience. I had an offer in hand
          in a little over a week and am flourishing in my new role.&rdquo;
        </div>
        <div className={styles.Person}>
          <div className={styles.Avatar}>
            <img src={require('../../assets/quote_avatar_2.png')} alt="Matt" />
          </div>
          <div className={styles.Info}>
            <div className={styles.Name}>Matt Garcia</div>
            <div className={styles.Role}>Account Executive</div>
          </div>
        </div>
      </div>
    </div>

    <div className={styles.BottomQuoteContainer}>
      <div className={styles.QuoteContainer}>
        <div className={styles.QuoteThree}>
          &ldquo;If you take your career as a salesperson seriously, Rainmakers is the place to be.
          I am ecstatic about the company that hired me and I would not have found it without the
          platform.&rdquo;
        </div>
        <div className={styles.Person}>
          <div className={styles.Avatar}>
            <img src={require('../../assets/quote_avatar_3.png')} alt="Palmer" />
          </div>
          <div className={styles.Info}>
            <div className={styles.Name}>Palmer Johnson</div>
            <div className={styles.Role}>SDR</div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default SocialProof;
