import React, { Fragment } from 'react';

import Input from 'components/Input';
import InputCheckbox from 'components/InputCheckbox';

import styles from './ToolboxCompaniesSearchBar.scss';

const ToolboxCompaniesSearchBar = ({
  handleSearchInputChange,
  handleSearchCheckboxChange,
  name,
  unverifiedDataState,
}) => {
  const autoCompleteProps = {
    name: 'name',
    placeholder: 'Search by company name',
    size: 'full',
    handleInputChange: handleSearchInputChange,
    isSearch: true,
    value: name,
  };

  const unverifiedProps = {
    name: 'unverifiedDataState',
    label: 'Unverified Companies Only',
    checked: unverifiedDataState && unverifiedDataState.includes('unverified'),
    handleInputChange: () =>
      handleSearchCheckboxChange({
        target: {
          name: 'unverifiedDataState',
          value: 'unverified',
        },
      }),
    className: styles.checkbox,
    checkboxMany: true,
  };

  const unverifiedEmployerProps = {
    name: 'unverifiedDataState',
    label: 'Unverified Employers Only',
    checked: unverifiedDataState && unverifiedDataState.includes('unverified_employer'),
    handleInputChange: () =>
      handleSearchCheckboxChange({
        target: {
          name: 'unverifiedDataState',
          value: 'unverified_employer',
        },
      }),
    className: styles.checkbox,
    checkboxMany: true,
  };

  return (
    <Fragment>
      <div className={styles.SearchBarOne}>
        <Input {...autoCompleteProps} />
      </div>
      <div className={styles.SearchBarTwo}>
        <InputCheckbox {...unverifiedProps} />
        <InputCheckbox {...unverifiedEmployerProps} />
      </div>
    </Fragment>
  );
};

export default ToolboxCompaniesSearchBar;
