import React from 'react';

import styles from './HowItWorks.scss';

const HowItWorks = () => {
  return (
    <div id="How-it-works" className={styles.HowItWorks}>
      <div className={styles.Container}>
        <h3 className={styles.Title}>How Rainmakers Works</h3>

        <div className={styles.Column}>
          <div className={styles.Image}>
            <img src={require('../../assets/profile.svg')} alt="Share your sales history" />
          </div>
          <h4 className={styles.StepTitle}>Build your unique sales profile</h4>
        </div>

        <div className={styles.Column}>
          <div className={styles.Image}>
            <img src={require('../../assets/bids.svg')} alt="Set your requirements" />
          </div>
          <h4 className={styles.StepTitle}>Companies bid on you</h4>
        </div>

        <div className={styles.Column}>
          <div className={styles.Image}>
            <img src={require('../../assets/hands.svg')} alt="Companies will approach you" />
          </div>
          <h4 className={styles.StepTitle}>Get job offers</h4>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
