import { createSelector } from 'reselect';
import { getApp } from 'shared/store/app/selectors';

const selectCandidateOpportunities = createSelector(
  getApp,
  (state) => state.candidateOpportunities
);

const selectPage = createSelector(
  selectCandidateOpportunities,
  (opportunities) => opportunities.page
);

export default selectPage;
