import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import isEmpty from 'lodash.isempty';

import ConnectContainer from 'containers/ConnectContainer';

import FontIcon from 'components/FontIcon';

import SIDEBAR_LINKS from './constants';

import duck from './redux';

import styles from './Sidebar.scss';

const Sidebar = ({
  className,
  type,
  page,
  id,
  viewType,
  modal,
  excludedLinks = [],
  sideFiltersPane,
  ...others
}) => {
  const { state: { parent } = {} } = useLocation();

  const isModal = !isEmpty(modal);

  const makeLinks = (link) => {
    const {
      active,
      disableable,
      disabled,
      featureFlags,
      icon,
      isNew,
      mobileTitle,
      needBorder,
      title,
      to,
    } = link;

    if (featureFlags) {
      const isEnabled = featureFlags.some((ff) => {
        const { type, attributes = [] } = ff;

        return attributes.some((attr) => {
          const { [type]: { [attr]: featureFlag } = {} } = others || {};

          return featureFlag;
        });
      });

      if (!isEnabled) {
        return null;
      }
    }

    const isActiveArray = Array.isArray(active);

    const activeLink = isActiveArray ? active.includes(page) : active === page;

    const toWithId = id ? to.replace(/:id/, id) : to;

    const toWithType = type ? toWithId.replace(/:type/, viewType) : toWithId;

    const disabledLink = disabled || (disableable && (!id || id === 'id'));

    const linkClassName = activeLink ? 'activeSidebar' : null;
    const secondaryClassName = classnames(styles.secondaryLinkContainer, {
      [styles.secondaryLinkActive]: activeLink,
      [styles.disabledLink]: disabledLink,
    });

    const borderDiv = needBorder ? <div className={styles.borderDiv} /> : null;

    const newContent = isNew ? <div className={styles.new}>NEW</div> : null;

    return (
      <div key={title}>
        {borderDiv}
        <Link to={disabledLink ? null : toWithType} className={linkClassName}>
          <div className={secondaryClassName}>
            <FontIcon
              className={classnames(styles.sidebarIcon, {
                [styles.secondaryLinkIconActive]: activeLink,
              })}
              iconName={icon}
            />
            <span className={styles.linkTitleMobile}>
              {mobileTitle || title}
              {newContent}
            </span>
            <span className={styles.linkTitle}>
              {title}
              {newContent}
            </span>
          </div>
        </Link>
      </div>
    );
  };

  const makeModalLinks = (link) => {
    const {
      active,
      disableable,
      disabled,
      featureFlags,
      icon,
      isNew,
      mobileTitle,
      modalKey,
      needBorder,
      title,
      to,
    } = link;

    if (featureFlags) {
      const isEnabled = featureFlags.some((ff) => {
        const { type, attributes = [] } = ff;

        return attributes.some((attr) => {
          const { [type]: { [attr]: featureFlag } = {} } = others || {};

          return featureFlag;
        });
      });

      if (!isEnabled) {
        return null;
      }
    }

    const isActiveArray = Array.isArray(active);

    const activeLink = isActiveArray ? active.includes(page) : active === page;

    const toWithId = id ? to.replace(/:id/, id) : to;

    const toWithType = type ? toWithId.replace(/:type/, viewType) : toWithId;

    const disabledLink = disabled || (disableable && (!id || id === 'id'));

    const linkClassName = activeLink ? 'activeSidebar' : null;

    const secondaryClassName = classnames(styles.secondaryLinkContainer, {
      [styles.secondaryLinkActive]: activeLink,
      [styles.disabledLink]: disabledLink,
    });

    const iconClassName = classnames(styles.sidebarIcon, {
      [styles.secondaryLinkIconActive]: activeLink,
    });

    const linkTo = disabledLink
      ? '#'
      : {
          pathname: toWithType,
          state: {
            modal: modalKey,
            parent,
          },
        };

    const linkProps = {
      to: linkTo,
      className: linkClassName,
    };

    const borderDiv = needBorder ? <div className={styles.borderDiv} /> : null;

    const newContent = isNew ? (
      <div className={disabledLink ? styles.newDisabled : styles.new}>NEW</div>
    ) : null;

    return (
      <div key={title}>
        {borderDiv}
        <Link {...linkProps}>
          <div className={secondaryClassName}>
            <FontIcon className={iconClassName} iconName={icon} />
            <span className={styles.linkTitleMobile}>
              {mobileTitle || title}
              {newContent}
            </span>
            <span className={styles.linkTitle}>
              {title}
              {newContent}
            </span>
          </div>
        </Link>
      </div>
    );
  };

  const { [type]: typeLinks } = SIDEBAR_LINKS;

  const lessExcluded = typeLinks.filter((link) => !excludedLinks.includes(link.to));
  const links = isModal ? lessExcluded.map(makeModalLinks) : lessExcluded.map(makeLinks);

  return (
    <>
      <nav className={classnames(className, styles.FakeSidebar)}>{links}</nav>
      <nav className={classnames(className, styles.Sidebar)}>
        {links}
        {sideFiltersPane}
      </nav>
    </>
  );
};

export default ConnectContainer(duck)(Sidebar);
