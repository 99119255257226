import React, { Component, Fragment } from 'react';

import GoogleChromeLogo from 'shared/assets/google_chrome.png';
import MozillaFirefoxLogo from 'shared/assets/mozilla_firefox.png';

import { trackEvent } from 'utils/analytics';
import { segmentProfileVideoAdded } from 'segment/eventNames';

import { handleError } from 'utils/common';
import { hasGetUserMedia } from 'utils/mediaUtils';

import Block from 'components/Block';
import Bold from 'components/Bold';
import Button from 'components/Button';
import Content from 'components/Content';
import DialogueModal from 'components/DialogueModal';
import FontIcon from 'components/FontIcon';
import InputFile from 'components/InputFile';
import Timer from 'components/Timer';

import { promisePostVimeoVideo } from './promises';

import styles from './RainCam.scss';

class RainCam extends Component {
  constructor(props) {
    super(props);

    this.state = {
      blob: null,
      errorHeader: null,
      errorMessage: null,
      EBML: null,
      hasUserMedia: true,
      isRecording: null,
      percentage: null,
      postComplete: null,
      RecordRTC: null,
      showRecordTimer: false,
      showStartTimer: false,
      tus: null,
      uploadComplete: false,
      vimeoVideoId: null,
    };
  }

  componentDidMount() {
    // Important: RecordRTC affects global level vars (such as URL) and should not be top level imported
    //
    const RecordRTC = require('recordrtc');
    const EBML = require('ts-ebml');
    const tus = require('tus-js-client');

    this.setState({
      hasUserMedia: hasGetUserMedia(),
      RecordRTC,
      EBML,
      tus,
    });
  }

  componentWillUnmount() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  startRecording = (stream, lengthInMS) => {
    const { RecordRTC } = this.state;

    if (RecordRTC) {
      this.setState({ showRecordTimer: true });

      this.recorder = new RecordRTC(stream, {
        type: 'video',
        mimeType: 'video/webm;codecs=vp9',
        audioBitsPerSecond: 128000,
        videoBitsPerSecond: 2400000,
      });

      this.recorder.setRecordingDuration(lengthInMS).onRecordingStopped(() => {
        this.preview.srcObject.getTracks().forEach((track) => track.stop());
        const url = this.recorder.toURL();
        const origBlob = this.recorder.getBlob();
        this.getSeekableBlob(origBlob, (blob) => {
          this.handleSaveRecording(blob, url);
        });
      });

      this.recorder.startRecording();
    }
  };

  getSeekableBlob = (blob, callback) => {
    const { EBML } = this.state;

    if (typeof EBML === 'undefined') {
      console.log('Cannot convert to seekable file');
      return blob;
    }

    const { Reader, Decoder, tools } = EBML;

    const reader = new Reader();
    const decoder = new Decoder();
    const fileReader = new FileReader();

    fileReader.onload = () => {
      const ebmlElms = decoder.decode(fileReader.result);

      ebmlElms.forEach((element) => {
        if (element.type !== 'unknown') {
          reader.read(element);
        }
      });

      reader.stop();

      const refinedMetadataBuf = tools.makeMetadataSeekable(
        reader.metadatas,
        reader.duration,
        reader.cues
      );

      const body = fileReader.result.slice(reader.metadataSize);

      const newBlob = new Blob([refinedMetadataBuf, body], {
        type: 'video/webm',
      });

      callback(newBlob);
    };

    fileReader.readAsArrayBuffer(blob);
  };

  handleSaveRecording = (blob) => {
    this.preview.src = URL.createObjectURL(blob);
    this.preview.srcObject = undefined;

    this.setState({
      blob,
      isRecording: false,
      showRecordTimer: false,
    });
  };

  handleStartRecording = () => {
    const { setUnsavedData } = this.props;

    navigator.mediaDevices.getUserMedia =
      navigator.mediaDevices.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;

    navigator.mediaDevices
      .getUserMedia({
        video: {
          width: { min: 640, ideal: 1920, max: 1920 },
          height: { min: 480, ideal: 1080, max: 1080 },
          aspectRatio: { ideal: 1.7777777778 },
          facingMode: 'user',
        },
        audio: true,
      })
      .then((stream) => {
        this.setState({
          isRecording: true,
          showStartTimer: true,
          showRecordTimer: false,
        });

        this.delayedRecord = setTimeout(() => {
          this.setState({
            showStartTimer: false,
          });

          setUnsavedData(true);

          this.startRecording(stream, 31000);
        }, 5000);

        this.preview.src = undefined;
        this.preview.srcObject = stream;
      })
      .catch(handleError);
  };

  handleStopRecording = () => {
    const { RecordRTC } = this.state;

    this.preview.srcObject.getTracks().forEach((track) => track.stop());

    this.setState({
      isRecording: null,
      showRecordTimer: false,
      showStartTimer: false,
    });

    if (this.delayedRecord) {
      clearTimeout(this.delayedRecord);
    }

    if (RecordRTC && this.recorder) {
      this.recorder.stopRecording(() => {
        const url = this.recorder.toURL();
        const origBlob = this.recorder.getBlob();
        this.getSeekableBlob(origBlob, (blob) => {
          this.handleSaveRecording(blob, url);
        });
      });
    }
  };

  handleUploadVideo = async () => {
    const {
      isAdmin,
      onCompleteCallback,
      ownerId,
      ownerType,
      setAppModalError,
      setUnsavedData,
    } = this.props;

    const { blob, tus } = this.state;

    this.setState({
      percentage: 0,
      uploadComplete: false,
      postComplete: false,
    });

    if (blob && ownerId && ownerType) {
      const uploadSize = blob.size;

      if (uploadSize > 75000000) {
        return this.setState({
          errorHeader: 'Upload Error',
          errorMessage: 'Please select a video file with a size less than 75MB',
        });
      }

      const filetype = blob.type;

      try {
        const { id: vimeoVideoId, attributes: { uploadUrl } = {} } = await promisePostVimeoVideo({
          uploadSize,
          ownerId,
          ownerType,
        });

        this.setState({
          vimeoVideoId,
          postComplete: true,
        });

        if (uploadUrl && tus) {
          const options = {
            uploadUrl,
            uploadSize,
            resume: true,
            metadata: {
              filename: 'CandidateIntro.webm',
              filetype,
            },
            onError: (error) => {
              console.log('error: ', error);
              this.setState({
                percentage: null,
                uploadComplete: false,
                postComplete: true,
              });
              setUnsavedData(false);
            },
            onProgress: (bytesUploaded, bytesTotal) => {
              const percentage = Math.round((bytesUploaded / bytesTotal) * 100);
              this.setState({ percentage });
            },
            onSuccess: () => {
              this.setState({ uploadComplete: true });
              setUnsavedData(false);
              onCompleteCallback && onCompleteCallback({ uploadComplete: true });

              if (!isAdmin) {
                const event = segmentProfileVideoAdded;

                const properties = {
                  ownerId,
                  ownerType,
                  vimeoVideoId,
                  uploadUrl,
                };

                const eventObject = { event, properties };

                trackEvent(eventObject);
              }
            },
          };

          this.upload = new tus.Upload(blob, options);
          this.upload.start();
        }
      } catch (error) {
        handleError(error);
        setAppModalError && setAppModalError(true);

        this.handleResetVideo({ keepVideo: true });
      }
    }
  };

  handleResetVideo = ({ keepVideo = false }) => {
    const { vimeoVideoId } = this.state;

    const { slice, deleteResource, setUnsavedData, onCompleteCallback } = this.props;

    const newState = {
      isRecording: null,
      percentage: null,
      postComplete: null,
      showRecordTimer: false,
      showStartTimer: false,
      uploadComplete: false,
      vimeoVideoId: null,
    };

    if (!keepVideo) {
      newState.blob = null;

      this.preview.src = '';
      this.preview.srcObject = null;
    }

    this.setState(newState);

    setUnsavedData && setUnsavedData(false);
    onCompleteCallback && onCompleteCallback({ uploadComplete: false });

    this.upload && this.upload.abort();

    this.refreshInterval && clearInterval(this.refreshInterval);

    vimeoVideoId &&
      deleteResource({
        slice,
        type: 'vimeo_videos',
        id: vimeoVideoId,
      });
  };

  handleSelectVideo = (event) => {
    const video = event.target.files[0];
    const { size, type } = video;
    const { setUnsavedData } = this.props;

    if (size > 75000000) {
      return this.setState({
        errorHeader: 'Upload Error',
        errorMessage: 'Please select a video file with a size less than 75MB',
      });
    }

    if (!type.includes('video')) {
      return this.setState({
        errorHeader: 'Upload Error',
        errorMessage:
          'Invalid file type, please use one of the following file types: MP4, MOV, WMV, AVI, or FLV',
      });
    }

    this.preview.srcObject = null;
    this.preview.src = URL.createObjectURL(video);

    this.preview.onloadedmetadata = () => {
      if (this.preview.duration <= 31) {
        setUnsavedData(true);

        this.setState({
          blob: video,
          isRecording: false,
        });

        this.preview.onloadedmetadata = undefined;
      } else {
        URL.revokeObjectURL(this.preview.src);

        this.preview.srcObject = null;
        this.preview.src = null;
        this.setState({
          errorHeader: 'Upload Error',
          errorMessage: 'Please select a video that is 30 seconds or less in length',
        });

        this.preview.onloadedmetadata = undefined;
      }
    };
  };

  render() {
    const { instructionsOnTop, isMobile, isSafari, profileLocked } = this.props;

    const {
      blob,
      errorHeader,
      errorMessage,
      hasUserMedia,
      isRecording,
      percentage,
      postComplete,
      showStartTimer,
      showRecordTimer,
      uploadComplete,
    } = this.state;

    const previewProps = {
      id: 'preview',
      width: 640,
      height: 360,
      autoPlay: isRecording,
      muted: isRecording,
      controls: isRecording === false,
      ref: (ref) => {
        this.preview = ref;
      },
      className: styles.player,
    };

    const startProps = {
      onClick: this.handleStartRecording,
      warning: true,
      disabled: profileLocked || isRecording,
      className: styles.startButton,
      title: 'Start Recording',
    };

    const stopProps = {
      onClick: this.handleStopRecording,
      warning: true,
      disabled: profileLocked || !isRecording,
      className: styles.stopButton,
      title: 'Stop Recording',
    };

    const startTimerProps = {
      className: styles.startTimer,
      seconds: 5,
    };

    const startTimer = showStartTimer ? <Timer {...startTimerProps} /> : null;

    const recordTimerProps = {
      className: styles.recordTimer,
      isRecord: true,
      seconds: 30,
    };

    const recordTimer = showRecordTimer ? <Timer {...recordTimerProps} /> : null;

    const selectVideoProps = {
      disabled: profileLocked || isRecording,
      isButton: true,
      placeholder: '',
      containerClassName: styles.selectVideoInput,
      className: styles.videoInput,
      name: 'uploadVideo',
      accept: 'video/*',
      onChange: this.handleSelectVideo,
    };

    const uploadVideoProps = {
      onClick: this.handleUploadVideo,
      primary: true,
      disabled: profileLocked || percentage || percentage === 0 || uploadComplete || !blob,
      className: styles.uploadButton,
      title: 'Submit',
    };

    const selectButtonProps = {
      fakeButton: true,
      className: styles.selectButton,
      secondary: true,
      title: 'Upload File',
      disabled: profileLocked || isRecording,
    };

    const mobileStartProps = {
      fakeButton: true,
      warning: true,
      title: 'Record',
      className: styles.startButton,
      disabled: profileLocked,
    };

    const resetVideoProps = {
      onClick:
        postComplete === false ? () => console.log('discard disabled') : this.handleResetVideo,
      warning: true,
      className: styles.startButton,
      title: 'Discard',
      disabled: profileLocked,
    };

    const startButtonContent =
      !blob && !isRecording && !isMobile ? (
        <Button {...startProps}>
          <FontIcon iconName="video-camera" /> Record
        </Button>
      ) : null;

    const mobileStartButtonContent =
      !blob && !isRecording && isMobile ? (
        <InputFile {...selectVideoProps}>
          <Button {...mobileStartProps}>
            <FontIcon iconName="video-camera" /> Record
          </Button>
        </InputFile>
      ) : null;

    const stopButtonContent =
      !blob && isRecording ? (
        <Button {...stopProps}>
          <FontIcon iconName="stop" /> Stop
        </Button>
      ) : null;

    const selectButtonContent =
      !blob && !isMobile ? (
        <InputFile {...selectVideoProps}>
          <Button {...selectButtonProps}>
            <FontIcon iconName="video-file" /> Select file
          </Button>
        </InputFile>
      ) : null;

    const resetButtonContent = blob ? (
      <Button {...resetVideoProps}>
        <FontIcon iconName="trash" /> Discard
      </Button>
    ) : null;

    const percentageContent = percentage || percentage === 0 ? `${percentage}%` : null;

    const saveContent = percentageContent || (
      <Fragment>
        <FontIcon iconName="upload" /> Submit
      </Fragment>
    );

    const uploadButtonContent =
      blob && !uploadComplete ? <Button {...uploadVideoProps}>{saveContent}</Button> : null;

    const closeButtonProps = {
      onClick: () => this.setState({ errorHeader: '', errorMessage: '' }),
      quaternary: true,
    };

    const popUpErrorMessage =
      errorHeader || errorMessage ? (
        <DialogueModal>
          <div className={styles.modal}>
            <div className={styles.header}>{errorHeader}</div>
            <div className={styles.message}>{errorMessage}</div>
            <div className={styles.actionButtons}>
              <Button {...closeButtonProps}>Close</Button>
            </div>
          </div>
        </DialogueModal>
      ) : null;

    const isSafariContent = isSafari ? (
      <div className={styles.recordingContainer}>
        Rainmakers webcam recording requires Mozilla Firefox or Google Chrome:
        <div className={styles.logoContainer}>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.google.com/chrome/browser/"
          >
            <div className={styles.browser}>
              <img className={styles.logo} src={GoogleChromeLogo} alt="" />
              Get Google Chrome
            </div>
          </a>
          <a rel="noopener noreferrer" target="_blank" href="https://www.mozilla.org/firefox">
            <div className={styles.browser}>
              <img className={styles.logo} src={MozillaFirefoxLogo} alt="" />
              Get Mozilla Firefox
            </div>
          </a>
        </div>
      </div>
    ) : null;

    const mainBlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      addPadding: true,
      className: styles.mainBlock,
    };

    const instructionsBlockProps = {
      ...mainBlockProps,
      className: instructionsOnTop ? styles.instructionsBlockTop : styles.instructionsBlock,
    };

    const deleteButtonProps = {
      onClick: this.handleResetVideo,
      warning: true,
      className: styles.delete,
      disabled: profileLocked,
    };

    const discardText = <span className={styles.discard}>Discard</span>;

    const onboardingInstructions =
      instructionsOnTop && uploadComplete ? (
        <div className={styles.instructions}>
          <div className={styles.instructionsLeft}>
            <Content className={styles.instructionsHead}>
              Your video has uploaded successfully.
            </Content>
            <Content>
              <Bold>Click the checkmark to complete your profile.</Bold>
            </Content>
          </div>
          <Button {...deleteButtonProps}>
            <FontIcon iconName="trash" />
            {discardText}
          </Button>
        </div>
      ) : null;

    const instructionsContent = uploadComplete ? (
      <div className={styles.instructions}>
        <div className={styles.instructionsLeft}>
          <Content className={styles.instructionsHead}>Your video is processing.</Content>
          <Content>
            <Bold>It will be available on your profile in a few minutes.</Bold>
          </Content>
        </div>
        <Button {...deleteButtonProps}>
          <FontIcon iconName="trash" />
          {discardText}
        </Button>
      </div>
    ) : (
      <Fragment>
        <Content className={styles.instructionsHead}>
          <Bold>Intro videos</Bold> are a great way to show your unique personality to employers. We
          suggest talking briefly about your background and what you&apos;re looking for in a new
          role.
        </Content>
        <Content>
          <Bold>Please keep it under 30 seconds.</Bold>
        </Content>
      </Fragment>
    );

    const buttonContent = uploadComplete ? null : (
      <div className={styles.buttonContainer}>
        {startButtonContent}
        {mobileStartButtonContent}
        {stopButtonContent}
        {selectButtonContent}
        {resetButtonContent}
        {uploadButtonContent}
      </div>
    );

    const mainContent = hasUserMedia ? (
      <div className={styles.RainCam}>
        {instructionsOnTop && (
          <Block {...instructionsBlockProps}>{onboardingInstructions || instructionsContent}</Block>
        )}
        <div className={styles.previewContainer}>
          <video {...previewProps} />
          {startTimer}
          {recordTimer}
        </div>

        {buttonContent}

        {!instructionsOnTop && <Block {...instructionsBlockProps}>{instructionsContent}</Block>}
        {popUpErrorMessage}
      </div>
    ) : (
      <div className={styles.RainCam}>
        Sorry, your browser does not support media recording. Please try Chrome or Firefox.
      </div>
    );

    return isSafariContent || mainContent;
  }
}

export default RainCam;
