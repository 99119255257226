import React from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';

import emptyAvatar from 'shared/assets/missing-user-picture.png';

import { extractLocation } from 'utils/locationUtils';

import commonStyles from '../../CandidateTurboProfile.scss';
import styles from './AvatarSection.scss';

export const AvatarSection = ({ candidateData, currentCompanyName, currentPositionTitle }) => {
  const { search } = useLocation();

  const { screenshot } = queryString.parse(search);
  const showSocialLabels = screenshot !== 'true';

  const { fullName, picture: { xxlarge } = {}, socialMedia, resume, location } = candidateData;

  const pictureUrl = xxlarge || emptyAvatar;

  const formattedLocation = location ? extractLocation(location) : ' ';

  const { linkedin: { url: linkedinUrl } = {} } = socialMedia || {};
  const linkedinDisabled = !linkedinUrl;
  const fullLinkedinUrl = linkedinUrl
    ? linkedinUrl.includes('http')
      ? linkedinUrl
      : `https://${linkedinUrl}`
    : '';

  const resumeUrl = resume !== '/resumes/original/missing.png' ? resume : '';
  const resumeDisabled = !resumeUrl;

  return (
    <div className={styles.avatarSectionContainer}>
      <div className={styles.avatarImageBlock}>
        <img alt="user pic" src={pictureUrl} className={styles.avatarImage} />
        {showSocialLabels && (
          <div className={styles.socialLabels}>
            <div>
              {linkedinDisabled ? (
                <a className={styles.disabled}>
                  <img src="/svgs/linkedin-disabled.svg" />
                </a>
              ) : (
                <a href={fullLinkedinUrl} target="_blank" rel="noreferrer">
                  <img src="/svgs/linkedin.svg" />
                </a>
              )}
              {resumeDisabled ? (
                <a className={styles.disabled}>
                  <img src="/svgs/resume-disabled.svg" />
                </a>
              ) : (
                <a href={resumeUrl} target="_blank" rel="noreferrer">
                  <img src="/svgs/resume.svg" />
                </a>
              )}
            </div>
          </div>
        )}
      </div>
      <div className={styles.avatarTextSection}>
        <div>
          <h1>{fullName}</h1>
          <div className={styles.location}>{formattedLocation}</div>
        </div>
        <div className={styles.positionCompanyName}>
          <div className={commonStyles.bodyBold}>{currentPositionTitle}</div>
          <div>{currentCompanyName}</div>
        </div>
      </div>
    </div>
  );
};
