import React from 'react';
import classnames from 'classnames';

import Button from 'components/Button';
import CandidateData from 'components/CandidateData';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import Table from 'components/Table';
import TableRow from 'components/Table/components/TableRow';
import TableCell from 'components/Table/components/TableCell';

import { getLifecycleStatusTitle } from 'utils/constants/lifecycleStatus';

import styles from './SelectedCandidatesTable.scss';

const SelectedCandidatesTable = ({
  candidates: { byId: candidatesById = {} } = {},
  cohortId,
  isReadOnly,
  addingCandidates,
  cohortCandidatesLoading,
  cohortCandidates: { allIds = [], byId = {} } = {},
  cohortCandidatesTotal,
  loadMoreCohortCandidates,
  handleCohortCandidateRemove,
  handleShowAddCandidatesOptions,
}) => {
  const addCandidatesButtonProps = {
    onClick: handleShowAddCandidatesOptions,
    primary: true,
  };

  const addCandidatesButton = <Button {...addCandidatesButtonProps}>+ Add Candidates</Button>;

  const addCandidatesTitle = (
    <div className={styles.addCandTitle}>
      <div>Selected Candidates</div>
      {addCandidatesButton}
    </div>
  );

  const addCandidatesBlockProps = {
    boxShadow: true,
    addWhiteBG: true,
    addFlex: true,
    addChildFlex: true,
    addBottomFade: true,
    noTitlePadding: !isReadOnly,
    disabled: !cohortId,
  };

  const infiniteScrollProps = {
    loadMore: loadMoreCohortCandidates,
    hasMore: cohortCandidatesTotal > allIds.length,
    loader: cohortCandidatesLoading ? (
      <div key="doneLoading" />
    ) : (
      <InfiniteScrollLoader key="isLoading" />
    ),
    useWindow: false,
  };

  const makeCandidateCells = (id) => {
    const { relationships: { candidate: { data: candidateIds = [] } = {} } = {} } = byId[id] || {};

    const { attributes: { active, hiringStatus, lifecycleStatus } = {} } =
      candidatesById[candidateIds[0]] || {};

    const candidateDataProps = {
      candidate: candidatesById[candidateIds[0]],
    };

    const candidateDataContent = (
      <div className={styles.candidateContent}>
        <CandidateData {...candidateDataProps} />
      </div>
    );

    const lookingStatusClasses = classnames(styles.statusContent);

    const activeText = hiringStatus === 'active' ? 'Actively Interviewing' : null;
    const inactiveText = hiringStatus === 'inactive' ? 'Not Looking' : null;
    const passiveText = hiringStatus === 'passive' ? 'Passively Looking' : null;

    const hiringStatusText = activeText || inactiveText || passiveText || '-';

    const lookingStatusContent = <div className={lookingStatusClasses}>{hiringStatusText}</div>;

    const statusClasses = classnames(styles.statusContent, {
      [styles.greenActive]: active,
      [styles.inactiveStatus]: !active,
    });

    const statusContent = (
      <div className={styles.statusContainer}>
        <div className={statusClasses}>{active ? 'Active' : 'Inactive'}</div>
        {lifecycleStatus && (
          <div className={styles.lifecycleStatus}>{getLifecycleStatusTitle(lifecycleStatus)}</div>
        )}
      </div>
    );

    const removeCandidateProps = {
      onClick: () => handleCohortCandidateRemove({ cohortCandidateId: id }),
      className: styles.removeCandContent,
    };

    const removeCandidateContent = isReadOnly ? null : (
      <TableCell key={4}>
        <div {...removeCandidateProps}>&times;</div>
      </TableCell>
    );

    const cells = [
      <TableCell key={1}>{candidateDataContent}</TableCell>,
      <TableCell key={2}>{statusContent}</TableCell>,
      <TableCell flex={isReadOnly} key={3}>
        {lookingStatusContent}
      </TableCell>,
      removeCandidateContent,
    ];

    return cells;
  };

  const makeCandidateRows = (id) => {
    const { relationships: { candidate: { data: candidateIds = [] } = {} } = {} } = byId[id] || {};

    const { attributes: { hiringStatus, active } = {} } = candidatesById[candidateIds[0]] || {};

    return (
      <TableRow id={id} disabled={!active || hiringStatus === 'inactive'} key={id}>
        {makeCandidateCells(id)}
      </TableRow>
    );
  };

  const tableContent = allIds.map(makeCandidateRows);

  const tableProps = {
    blockProps: addCandidatesBlockProps,
    titleContent: addCandidatesTitle,
    tableContent,
    infiniteScrollProps,
    tableName: 'adminCohortCandidates',
    isEmpty: !cohortCandidatesLoading && allIds.length === 0,
    emptyTextString: 'No Candidates Added',
    isLoading: cohortCandidatesLoading || addingCandidates,
  };

  return (
    <div className={styles.content}>
      <Table {...tableProps} />
    </div>
  );
};

export default SelectedCandidatesTable;
