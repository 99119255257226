import { call, put, takeLatest } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { deleteEntity } from 'api/apiEntity';

import { EDUCATION_DELETE_STARTED } from '../actions';

import {
  candidateUpdate,
  candidateSaveStarted,
  candidateSaveDone,
  candidateSaveError,
} from '../creators';

const deleteEducation = function* deleteEducation(action) {
  const {
    payload: { educationId, newEducations },
  } = action;

  yield put(candidateSaveStarted());

  if (newEducations) {
    yield put(candidateUpdate({ newValue: newEducations, name: 'candidateEducations' }));
    yield put(candidateSaveDone());
  }

  if (educationId) {
    try {
      const args = {
        id: educationId,
        type: 'candidate_educations',
      };

      yield call(deleteEntity, args);
      yield put(candidateSaveDone());
    } catch (error) {
      handleError(error);
      yield put(candidateSaveError(error));
    }
  }
};

const sagaEducationDeleteStarted = function* sagaEducationDeleteStarted() {
  yield takeLatest(EDUCATION_DELETE_STARTED, deleteEducation);
};

export default sagaEducationDeleteStarted;
