import React from 'react';

export const doMoney = (amount) => {
  if (amount >= 100000000) {
    return `${(Math.round(amount / 10000000) / 10).toFixed(1)}M`;
  }
  if (amount >= 100000) {
    return `${Math.round(amount / 100000)}K`;
  }
  if (amount >= 100) {
    return `${Math.round(amount / 100)}`;
  }
  return `0.${amount}`;
};

const FormattedMoney = ({ className, amount, currencySymbol = '$', format }) => {
  if (!amount || Number.isNaN(parseInt(amount, 10))) return '';

  const formattedMoney = parseInt(amount, 10) === 0 ? '0' : doMoney(amount);

  if (format === 'string') {
    return `${currencySymbol}${formattedMoney}`;
  }

  return (
    <span className={className}>
      {currencySymbol}
      {formattedMoney}
    </span>
  );
};

export default FormattedMoney;
