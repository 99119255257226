import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash.isempty';

import Block from 'components/Block';
import Button from 'components/Button';
import FormattedDate from 'components/FormattedDate';
import InputTitle from 'components/InputTitle';
import Label from 'components/Label';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import InputCheckboxSlider from 'components/InputCheckboxSlider';

import CohortDateRangePicker from './components/CohortDateRangePicker';

import styles from './CohortEditorMain.scss';

const CohortEditorMain = ({
  cohort,
  cohort: {
    attributes: {
      name = '',
      category,
      startDate,
      endDate,
      cohortSaving,
      locked,
      state,
      cohortCandidatesCount = 0,
      autoAddEmployers,
      ignoreBatches,
      emailsEnabled,
      emailCategoryEnabled,
      emailCategorySentAt,
      temporary,
    } = {},
  } = {},
  handleCohortInputChange,
  handleCohortEmailInputChange,
  router,
  modal,
  actions: { showModal, patchResource },
}) => {
  const isReadOnly = locked || state === 'active' || cohortCandidatesCount > 0;

  const savingContent = cohortSaving ? (
    <div className={styles.autoSaving}>Saving...</div>
  ) : (
    <div className={styles.autoSaved}>Cohort Saved</div>
  );

  const saveContent = cohortSaving === undefined ? null : savingContent;

  const titleBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    addPadding: true,
    className: styles.titleBlock,
  };

  const cohortNameInputProps = {
    className: styles.titleInputContainer,
    handleInputChange: (event) =>
      handleCohortInputChange({
        target: {
          name: 'name',
          value: event.target.value,
        },
      }),
    fontSize: 'huge',
    fontWeight: 'bold',
    titleInput: true,
    type: 'text',
    topLabel: true,
    value: name ? name.substr(0, 25) : '',
    size: 'full',
    placeholder: 'Name this Cohort',
    autoFocus: true,
    disabled: isReadOnly,
  };

  const dateRangePickerProps = {
    cohort,
    startDate,
    endDate,
    patchResource,
    disabled: isReadOnly,
  };

  const dateRangeBlockProps = {
    boxShadow: true,
    addWhiteBG: true,
    addPadding: true,
    title: 'Active Date Range',
    className: styles.titleBlock,
  };

  const backToModal =
    modal && router && router.location && router.location.state
      ? () =>
          showModal({
            key: modal.backKey,
            originalUrl: modal.originalUrl,
            parent: router.location.state.parent,
            route: modal.backPath,
          })
      : null;

  const backLinkProps = isEmpty(modal)
    ? {}
    : {
        onClick: backToModal,
      };

  const backButtonProps = {
    quaternary: true,
    className: styles.backButton,
  };

  const backButton =
    modal.backPath && modal.backKey ? (
      <div className={styles.backButtonContainer}>
        <Link {...backLinkProps}>
          <Button {...backButtonProps}>&#60; Back</Button>
        </Link>
      </div>
    ) : null;

  const autoAddEmployersInputProps = {
    handleInputChange: (event) => {
      handleCohortInputChange({
        target: {
          value: event.target.checked,
          name: event.target.name,
        },
      });
    },
    name: 'autoAddEmployers',
    checked: autoAddEmployers === true,
    label: autoAddEmployers === true ? 'Yes' : 'No',
    placeholder: '',
    disabled: temporary || isReadOnly,
  };

  const cohortIgnoreBatchesInputProps = {
    handleInputChange: (event) => {
      handleCohortInputChange({
        target: {
          value: event.target.checked,
          name: event.target.name,
        },
      });
    },
    name: 'ignoreBatches',
    checked: ignoreBatches === true,
    label: ignoreBatches === true ? 'Yes' : 'No',
    placeholder: '',
    disabled: temporary || isReadOnly,
  };

  const settingsBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    addPadding: true,
    className: styles.titleBlock,
    title: 'Settings',
  };

  const candidateSettingsBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    addPadding: true,
    className: styles.titleBlock,
    title: 'Candidate Settings',
  };

  const emailSettingsBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    addPadding: true,
    className: styles.titleBlock,
    title: 'Email Settings',
  };

  const emailsEnabledInputProps = {
    handleInputChange: (event) => {
      handleCohortInputChange({
        target: {
          value: event.target.checked,
          name: event.target.name,
        },
      });
    },
    name: 'emailsEnabled',
    checked: emailsEnabled === true,
    label: emailsEnabled === true ? 'Yes' : 'No',
    placeholder: '',
  };

  const startEmailsInputProps = {
    handleInputChange: (event) => {
      handleCohortEmailInputChange(
        {
          target: {
            value: event.target.checked,
            name: event.target.name,
          },
        },
        emailCategoryEnabled
      );
    },
    name: 'start',
    checked: emailCategoryEnabled?.start === true,
    label: emailCategoryEnabled?.start === true ? 'Yes' : 'No',
    placeholder: '',
  };

  const preEndEmailsInputProps = {
    handleInputChange: (event) => {
      handleCohortEmailInputChange(
        {
          target: {
            value: event.target.checked,
            name: event.target.name,
          },
        },
        emailCategoryEnabled
      );
    },
    name: 'preEnd',
    checked: emailCategoryEnabled?.preEnd === true,
    label: emailCategoryEnabled?.preEnd === true ? 'Yes' : 'No',
    placeholder: '',
  };

  const startEmailsSent = emailCategorySentAt?.start ? (
    <Fragment>
      sent on <FormattedDate format="M/D/YY" parse date={emailCategorySentAt?.start} />
    </Fragment>
  ) : null;
  const preEndEmailsSent = emailCategorySentAt?.preEnd ? (
    <Fragment>
      sent on <FormattedDate format="M/D/YY" parse date={emailCategorySentAt?.preEnd} />
    </Fragment>
  ) : null;

  const content = (
    <div className={styles.EditorMain}>
      {backButton}
      <Block {...titleBlockProps}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            <InputTitle {...cohortNameInputProps} />
          </div>
          <div className={styles.saveContent}>
            {saveContent}
            <div className={styles.category}>{category === 'featured' ? 'Match' : 'Featured'}</div>
            <div className={state === 'active' ? styles.active : styles.inactive}>
              {state === 'active' ? 'Active' : 'Inactive'}
            </div>
            <div className={isReadOnly ? styles.locked : styles.unlocked}>
              {isReadOnly ? 'Locked' : ''}
            </div>
          </div>
        </div>
      </Block>
      <Block {...dateRangeBlockProps}>
        <CohortDateRangePicker {...dateRangePickerProps} />
      </Block>
      <Block {...settingsBlockProps}>
        <div className={styles.socialMediaInputs}>
          <Label standalone>Auto Add Employers</Label>
          <InputCheckboxSlider {...autoAddEmployersInputProps} />
        </div>
      </Block>
      <Block {...candidateSettingsBlockProps}>
        <div className={styles.socialMediaInputs}>
          <Label standalone>Disable Batch System (Candidate Emails & Homepage Hosted Status)</Label>
          <InputCheckboxSlider {...cohortIgnoreBatchesInputProps} />
        </div>
      </Block>
      <Block {...emailSettingsBlockProps}>
        <div>
          <Label standalone>All emails</Label>
          <InputCheckboxSlider {...emailsEnabledInputProps} />
          <Label standalone>Start emails {startEmailsSent}</Label>
          <InputCheckboxSlider {...startEmailsInputProps} />
          <Label standalone>Pre-end emails {preEndEmailsSent}</Label>
          <InputCheckboxSlider {...preEndEmailsInputProps} />
        </div>
      </Block>
    </div>
  );

  const layoutWithoutSidebarProps = {
    content,
  };

  return <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />;
};

export default CohortEditorMain;
