import React from 'react';
import classnames from 'classnames';

import Button from 'components/Button';

import EmptyAvatar from 'shared/assets/missing-user-picture.png';

import styles from './CandidateSuggestion.scss';

const CandidateSuggestion = ({
  candidate,
  handleOnClick,
  handleOnLoad,
  number,
  patchResource,
  selected,
}) => {
  const { candidateSuggestionId, suggested } = candidate || {};

  const picUrl = EmptyAvatar;

  const classes = classnames(styles.resultImage, {
    [styles.selected]: selected,
  });

  const picContainerProps = {
    className: classes,
    onClick: handleOnClick,
  };

  const containerClasses = classnames(styles.container);

  const numberOverlay = <div className={styles.numberOverlay}>{number}</div>;

  const topOverlay = !suggested ? (
    <div className={styles.topOverlay} />
  ) : (
    <div className={styles.topOverlaySelected} />
  );

  const bottomOverlay =
    suggested || suggested === null ? (
      <div className={styles.bottomOverlay} />
    ) : (
      <div className={styles.bottomOverlaySelected} />
    );

  const imgProps = {
    src: picUrl,
    onLoad: handleOnLoad,
  };

  const upVoteProps = {
    onClick: () =>
      patchResource({
        id: candidateSuggestionId,
        attributes: {
          suggested: true,
        },
        normalizeIt: true,
        requiredFields: [],
        slice: 'toolboxAdmins',
        type: 'candidate_suggestions',
      }),
    className: styles.nahButton,
    tertiary: true,
    fakeDisabled: suggested === false,
  };

  const upVoteButton = <Button {...upVoteProps}>Yah</Button>;

  const downVoteProps = {
    onClick: () =>
      patchResource({
        id: candidateSuggestionId,
        attributes: {
          suggested: false,
        },
        normalizeIt: true,
        requiredFields: [],
        slice: 'toolboxAdmins',
        type: 'candidate_suggestions',
      }),
    className: styles.nahButton,
    warningTertiary: true,
    fakeDisabled: suggested === true,
  };

  const downVoteButton = <Button {...downVoteProps}>Nah</Button>;

  const buttons = (
    <div className={styles.voteContainer}>
      {upVoteButton}
      {downVoteButton}
    </div>
  );

  return selected ? (
    <div className={styles.mainContainer}>
      <div className={containerClasses}>
        <div {...picContainerProps}>
          <img {...imgProps} alt="" />
          {numberOverlay}
          {topOverlay}
          {bottomOverlay}
        </div>
        <div className={styles.selectedDiv} />
      </div>
      {buttons}
    </div>
  ) : (
    <div className={styles.mainContainer}>
      <div className={containerClasses}>
        <div {...picContainerProps}>
          <img {...imgProps} alt="" />
          {numberOverlay}
          {topOverlay}
          {bottomOverlay}
        </div>
      </div>
      {buttons}
    </div>
  );
};

export default CandidateSuggestion;
