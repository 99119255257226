import { call, select, put, take } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { getNestedEntity, postNestedEntity, patchNestedEntity } from 'api/apiEntity';

import { candidateProfilePatchDone } from 'connectors/User/redux/creators';

import { selectUser } from 'connectors/User/redux/selectors';

import { REORDER_EDUCATION_STARTED } from '../actions';

import { serverErrorSet } from '../creators';

const sagaReorderCandidateEducationsStarted = function* sagaReorderCandidateEducationsStarted() {
  while (true) {
    const {
      payload: { origCandidateEducations, origWorkHistories, idx: currentIndex },
    } = yield take(REORDER_EDUCATION_STARTED);

    const candidateEducation = origCandidateEducations[currentIndex];

    const origEditingCandidateEducations = origCandidateEducations.filter(
      (edu, idx) => edu.isEditing && !edu.id && idx !== currentIndex
    );

    const user = yield select(selectUser);

    const {
      currentProfile: { id: candidateId },
    } = user;

    try {
      const eduArgs = {
        data: {
          type: 'candidate_education',
          id: candidateEducation.id || '',
          attributes: {
            ...candidateEducation,
            candidateId,
          },
        },
        nestedType: 'candidate_educations',
        id: candidateId,
        type: 'candidates',
        nestedId: candidateEducation.id || '',
        config: {
          params: {
            camelizeIt: true,
          },
        },
      };

      const {
        data: { candidateEducation: newCandidateEducation },
      } = yield candidateEducation.id
        ? call(patchNestedEntity, eduArgs)
        : call(postNestedEntity, eduArgs);

      const args = {
        id: candidateId,
        type: 'candidates',
        nestedType: 'candidate_educations',
        config: {
          params: {
            includeSet: 'education_organization',
            sortBy: 'finished_at_desc_nulls_first,started_at_desc',
            normalizeIt: true,
          },
        },
      };

      const {
        data: {
          entities: {
            educationOrganization: { byId: educationOrganizationById = {} } = {},
            candidateEducation: { allIds = [], byId = {} } = {},
          } = {},
        } = {},
      } = yield call(getNestedEntity, args);

      const didMove = currentIndex !== allIds.indexOf(newCandidateEducation.id);

      const orderedCandidateEducations = allIds.map((id) => {
        const {
          [id]: {
            relationships: {
              educationOrganization: { data: educationOrganizationIds = [] } = {},
            } = {},
          } = {},
        } = byId || {};

        return {
          ...byId[id].attributes,
          educationOrganization: educationOrganizationById[educationOrganizationIds[0]].attributes,
        };
      });

      const newCandidateEducations = orderedCandidateEducations.map((edu) => {
        const wasEditing = origCandidateEducations.findIndex(
          (candEdu) => candEdu.id && candEdu.id === edu.id && edu.isEditing
        );
        if (didMove && edu.id === newCandidateEducation.id) {
          return {
            ...edu,
            moved: true,
            isEditing: false,
          };
        }
        return {
          ...edu,
          isEditing: wasEditing >= 0,
        };
      });

      const candidateProfile = {
        ...user.currentProfile,
        candidateEducations: [...newCandidateEducations, ...origEditingCandidateEducations],
        workHistories: origWorkHistories,
      };

      yield put(candidateProfilePatchDone({ candidateProfile }));
    } catch (error) {
      handleError(error);

      const {
        response: {
          data: { errors: { full_messages: errors = ['Please try again'] } = {} } = {},
        } = {},
      } = error || {};

      yield put(serverErrorSet({ errors }));
    }
  }
};

export default sagaReorderCandidateEducationsStarted;
