import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import { handleError } from 'utils/common';

import ConnectContainer from 'containers/ConnectContainer';
import { useImageField } from 'hooks';

import { promiseGetRequisitionCandidates } from 'pages/EmployeePortal/CandidateProfilePages/promises';

import FormattedName from 'components/FormattedName';

import duck from 'shared/store/app';

import styles from './CandidateData.scss';

const CandidateData = ({
  boldSubtitle,
  candidate,
  className,
  hideNames,
  largeAvatar,
  noHighlight,
  onClick,
  sideTable,
  subtitle,
  useHideNames,
}) => {
  const { attributes: { id: candidateId, firstName, lastName } = {} } = candidate || {};

  const [noReqCand, setNoReqCand] = useState(null);

  const infoWrapperClasses = classnames(styles.candidateDataInfoWrapper, {
    [styles.candidateDataInfoWrapperShort]: sideTable,
  });

  const titleClasses = classnames(styles.candidateDataTitle, {
    [styles.candidateDataTitleSmall]: sideTable,
    [styles.noHighlight]: noHighlight,
  });

  const subtitleClasses = classnames(styles.candidateDataSubtitle, {
    [styles.candidateDataSubtitleBold]: boldSubtitle,
  });

  const classes = classnames(
    styles.CandidateData,
    {
      [styles.sideTable]: sideTable,
    },
    className
  );

  const candidateProps = {
    className: classes,
    onClick,
  };

  const useBlur = hideNames && useHideNames && (noReqCand || noReqCand === null);

  const avatarClass = classnames(styles.candidateDataAvatarWrapper, {
    [styles.candidateDataAvatarLargeWrapper]: largeAvatar,
    [styles.candidateDataAvatarSmallWrapper]: sideTable,
    [styles.blur]: useBlur,
  });

  const { imageComponent } = useImageField({
    model: candidate,
    avatarClass,
  });

  useEffect(() => {
    if (useHideNames && hideNames) {
      promiseGetRequisitionCandidates({ candidateId })
        .then((reqCandidates) => {
          setNoReqCand(reqCandidates.length === 0);
        })
        .catch((error) => {
          handleError(error);
          setNoReqCand(true);
        });
    }
  }, [candidateId, hideNames, useHideNames]);

  const formattedNameProps = {
    useHideNames,
    candidateId,
    className: styles.candidateName,
    firstName,
    lastName: lastName || '',
  };

  const subtitleString = subtitle ? <div className={subtitleClasses}>{subtitle}</div> : null;

  return (
    <div {...candidateProps}>
      {imageComponent}

      <div className={infoWrapperClasses}>
        <div className={titleClasses}>
          <FormattedName {...formattedNameProps} />
        </div>
        {subtitleString}
      </div>
    </div>
  );
};

export default ConnectContainer(duck)(CandidateData);
