import { handleActions } from 'redux-actions';

import {
  SET_MODAL,
  RESET_MODAL,
  NEXT_MODAL,
  SET_CURRENT_IDX,
  RESET_CURRENT_IDX,
} from 'shared/store/app//actions';

const initialState = {};

const handleSetModal = (state, action) => {
  const {
    payload: {
      backKey = null,
      backPath = null,
      originalUrl = null,
      initialRender = false,
      key,
      closeHook,
      silentClose,
      loadMore,
    },
  } = action;

  return {
    ...state,
    originalUrl,
    key,
    backPath,
    backKey,
    initialRender,
    closeHook,
    silentClose,
    loadMore,
  };
};

const handleResetModal = () => initialState;

const handleSetCurrentIdx = (state = initialState, action) => {
  const {
    payload: { currentIdx },
  } = action;

  return {
    ...state,
    currentIdx,
  };
};

const handleResetCurrentIdx = (state = initialState) => ({
  ...state,
  currentIdx: '',
});

const handleNextModal = (state = initialState) => ({
  ...state,
  currentIdx: undefined,
});

const actionHandlers = {
  [NEXT_MODAL]: handleNextModal,
  [SET_MODAL]: handleSetModal,
  [RESET_MODAL]: handleResetModal,
  [SET_CURRENT_IDX]: handleSetCurrentIdx,
  [RESET_CURRENT_IDX]: handleResetCurrentIdx,
};

const reduceModal = handleActions(actionHandlers, initialState);

export default reduceModal;
