import React, { Fragment } from 'react';
import classnames from 'classnames';
import fecha from 'fecha';

import { HIRING_STATUS_SOURCE } from 'connectors/Defaults/constants';

import Bolder from 'components/Bolder';
import Content from 'components/Content';
import ContentTitle from 'components/ContentTitle';
import ErrorMessage from 'components/ErrorMessage';
import FormattedCountdown from 'components/FormattedCountdown';
import InputCheckboxMany from 'components/InputCheckboxMany';
import Oblique from 'components/Oblique';
import TableOverlay from 'components/TableOverlay';

import styles from './Status.scss';

const STATUS_STRINGS = {
  inactive: (
    <Fragment>
      <Content>
        You&apos;re marked as <Bolder>&ldquo;Not looking&rdquo;</Bolder>, which means recruiters
        will not see your profile.
      </Content>
      <Content className={styles.padded}>
        If you&apos;d like to change this, select{' '}
        <Bolder>&ldquo;Actively interviewing&rdquo;</Bolder> or{' '}
        <Bolder>&ldquo;Just browsing&rdquo;</Bolder> from the menu below.
      </Content>
    </Fragment>
  ),
  active: (
    <Fragment>
      <Content>
        You&apos;re marked as <Bolder>&ldquo;Actively interviewing&rdquo;</Bolder>, which means
        recruiters can potentially see your profile and reach out to you with opportunities.
      </Content>
      <Content className={styles.padded}>
        If you&apos;d like to change this, select <Bolder>&ldquo;Not looking&rdquo;</Bolder> from
        the menu below and your profile will be hidden from everyone.
      </Content>
    </Fragment>
  ),
  passive: (
    <Fragment>
      <Content>
        You&apos;re marked as <Bolder>&ldquo;Just browsing&rdquo;</Bolder>, which means recruiters
        can potentially see your profile and reach out to you with opportunities.
      </Content>
      <Content className={styles.padded}>
        If you&apos;d like to change this, select <Bolder>&ldquo;Not looking&rdquo;</Bolder> from
        the menu below and your profile will be hidden from everyone.
      </Content>
    </Fragment>
  ),
};

const Status = ({
  profileLocked,
  hiringStatus,
  hiringStatusResetAt,
  handleCandidateInputChange,
}) => {
  const statusClasses = classnames({
    [styles.inactive]: hiringStatus === 'inactive',
    [styles.passive]: hiringStatus === 'passive',
    [styles.active]: hiringStatus === 'active',
  });

  const hiringStatusString = hiringStatus === 'inactive' ? 'Inactive' : 'Active';

  const hiringStatusKey =
    hiringStatusResetAt && hiringStatus === 'inactive' ? 'inactiveReset' : hiringStatus;

  const statusString = STATUS_STRINGS[hiringStatusKey];

  const hiringStatusInputProps = {
    required: true,
    source: HIRING_STATUS_SOURCE,
    name: 'hiringStatus',
    handleInputChange: handleCandidateInputChange,
    selectedValues: hiringStatus !== '' ? [hiringStatus] : [],
    blockStyle: true,
    flexFit: true,
    hasLabel: true,
    disabled: profileLocked,
  };

  const dayOfReset = fecha.parse(`${hiringStatusResetAt} +0000`, 'YYYY-MM-DD HH:mm:ss ZZ');

  const timeUntilReset = Date.parse(dayOfReset) - Date.now();

  const daysUntilPassed = <FormattedCountdown time={timeUntilReset} />;

  const hiringStatusReset =
    hiringStatusResetAt && hiringStatus === 'inactive' ? (
      <Bolder className={styles.inactive}>
        Inactive{' '}
        <span className={styles.resetStatus}>
          (Active in <Oblique>{daysUntilPassed}</Oblique> days)
        </span>
      </Bolder>
    ) : null;

  const resetStatusContent =
    hiringStatusResetAt && hiringStatus === 'inactive' ? (
      <Content>
        You&apos;re currently marked as <Bolder>&ldquo;Not looking&rdquo;</Bolder> because you were
        recently hired through Rainmakers. However, in <Oblique>{daysUntilPassed}</Oblique> days,
        we&apos;ll change your status to <Bolder>&ldquo;Just browsing&rdquo;</Bolder> and allow
        recruiters to potentially see your profile and reach out to you with even better
        opportunities.
      </Content>
    ) : null;

  const profileLockedContent = profileLocked ? (
    <ErrorMessage className={styles.profileLocked}>Profile Locked</ErrorMessage>
  ) : null;

  const profileLockOverlay = profileLocked ? <TableOverlay lockedNotFixed /> : null;

  return (
    <Content className={styles.container}>
      <ContentTitle>YOUR STATUS {profileLockedContent}</ContentTitle>

      <div className={styles.contentContainer}>
        {profileLockOverlay}

        <div className={statusClasses}>
          <Bolder>{hiringStatusReset || hiringStatusString}</Bolder>
        </div>

        {resetStatusContent || statusString}

        <div className={styles.inputContainer}>
          <InputCheckboxMany {...hiringStatusInputProps} />
        </div>
      </div>
    </Content>
  );
};

export default Status;
