export const employerHomePageValues = {
  search: {
    label: 'Featured',
    homepage: '/featured',
    disabled_pages: null,
  },
  search_only: {
    label: 'Featured Only',
    homepage: '/featured',
    disabled_pages: ['/matches'],
  },
  featured: {
    label: 'Matches',
    homepage: '/matches',
    disabled_pages: null,
  },
  featured_only: {
    label: 'Matches Only',
    homepage: '/matches',
    disabled_pages: ['/featured'],
  },
  search_exclusive: {
    label: 'Featured Exclusive',
    homepage: '/featured',
    disabled_pages: [],
    excluded_pages: ['/matches'],
  },
  featured_exclusive: {
    label: 'Matches Exclusive',
    homepage: '/matches',
    disabled_pages: [],
    excluded_pages: ['/featured'],
  },
};
