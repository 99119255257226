import React from 'react';
import { push } from 'connected-react-router';

import Table from 'components/Table';
import TableRow from 'components/Table/components/TableRow';
import TableCell from 'components/Table/components/TableCell';
import EmployeeData from 'components/EmployeeData';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';

import EmployeesActionDropdown from '../EmployeesActionDropdown';

import styles from '../../EmployerEditorEmployees.scss';

const EMPLOYEE_EDIT_ROUTES = {
  ToolboxEmployersPage: '/toolbox/employer/__employerId__/edit-employee/__employeeId__',
  EmployerEmployeesPage: '/employee/edit-employees/__employeeId__/profile',
};

const EmployeesTable = ({
  dispatch,
  employees: { byId = {}, allIds = [] } = {},
  employerId,
  isLoading,
  isAdmin,
  loadMore,
  modal: { originalUrl } = {},
  modalKey,
  parent,
  resourceUpdate,
  showModal,
  titleContent,
  total,
  users: { byId: usersById = {} } = {},
}) => {
  const tableHeader = [
    {
      content: 'Name',
      headerClassName: styles.firstLabel,
    },
    {
      content: 'Email',
      headerClassName: styles.emailHeader,
    },
    {
      content: 'Title',
      headerClassName: styles.employeeStatusHeader,
    },
    {
      content: '',
      headerClassName: styles.actionHeader,
    },
  ];

  const makeEmployeeCells = (id) => {
    const employee = byId[id];
    const {
      [id]: {
        attributes: { jobTitle },
        relationships: { user: { data: userIds = [] } = {} } = {},
      },
    } = byId;

    const { [userIds[0]]: user = {} } = usersById || {};

    const { attributes: { email, confirmedAt, confirmationSent } = {} } = user;

    const onClick = () => {
      const baseRoute = EMPLOYEE_EDIT_ROUTES[parent];

      const route = baseRoute.replace('__employerId__', employerId).replace('__employeeId__', id);

      if (!originalUrl && isAdmin) {
        dispatch(push(route));
      } else {
        // originalUrl needed only for the Admin view
        const show_modal_args = {
          key: modalKey,
          parent,
          route,
        };
        if (isAdmin) {
          show_modal_args.originalUrl = originalUrl;
        }

        showModal(show_modal_args);
      }
    };

    const employeeNameCellProps = {
      className: styles.employeeName,
    };
    const employeeDataProps = {
      onClick,
      employee,
      employeeId: id,
    };

    const employeeNameCell = (
      <div {...employeeNameCellProps}>
        <EmployeeData {...employeeDataProps} />
      </div>
    );

    const emailCell = <div className={styles.emailContent}>{email}</div>;

    const jobTitleCell = <div className={styles.jobTitle}>{jobTitle}</div>;

    const employeeActionProps = {
      confirmedAt,
      confirmationSent,
      resourceUpdate,
      user,
      userId: userIds[0],
    };

    const actionsCell = isAdmin ? (
      <TableCell noFlex key={4}>
        <div className={styles.actionsContent}>
          <EmployeesActionDropdown {...employeeActionProps} />
        </div>
      </TableCell>
    ) : null;

    const cells = [
      <TableCell key={1}>{employeeNameCell}</TableCell>,
      <TableCell key={2}>{emailCell}</TableCell>,
      <TableCell key={3}>{jobTitleCell}</TableCell>,
      actionsCell,
    ];

    return cells.filter((cell) => cell);
  };

  const makeEmployeeRows = (id) => (
    <TableRow id={id} key={id}>
      {makeEmployeeCells(id)}
    </TableRow>
  );

  const tableContent = allIds.filter((id) => !byId[id].attributes.temporary).map(makeEmployeeRows);

  const infiniteScrollProps = {
    pageStart: 0,
    loadMore,
    hasMore: total > allIds.length,
    loader: isLoading ? <div /> : <InfiniteScrollLoader />,
    useWindow: false,
  };

  const tableProps = {
    titleContent,
    tableName: 'adminRequisitions',
    tableContent,
    infiniteScrollProps,
    header: isAdmin ? tableHeader : tableHeader.slice(0, -1),
    isEmpty: !isLoading && allIds.length === 0,
    emptyTextString: 'No Employees',
    isLoading,
  };

  return <Table {...tableProps} />;
};

export default EmployeesTable;
