import React, { Component } from 'react';

import { VisibleHOC } from 'containers/HOC';

import Dropdown from 'components/Dropdown';

@VisibleHOC()
class PermBlockActionDropdown extends Component {
  handleClick = (value) => {
    const { candidateCompanyBlockId, deleteResource, slice } = this.props;

    switch (value) {
      case 'delete':
        return deleteResource({
          id: candidateCompanyBlockId,
          type: 'candidate_company_blocks',
          slice,
        });
      default:
        return console.log('cannot perform this action');
    }
  };

  render() {
    const source = [
      {
        label: 'Remove Block',
        value: 'delete',
      },
    ];

    const { isVisible } = this.props;

    const dropDownProps = {
      above: !isVisible,
      source,
      onChange: this.handleClick,
      value: 'send_message',
      defaultValue: 'Actions',
      left: true,
      actions: true,
      defaultIcon: 'caret-down',
      activeIcon: 'caret-up',
    };

    return <Dropdown {...dropDownProps} />;
  }
}

export default PermBlockActionDropdown;
