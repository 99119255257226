import React from 'react';
import DayPicker from 'react-day-picker';

import styles from './DatePicker.scss';

const DatePicker = ({ onDayClick, selectedDays, disabledDays, month }) => {
  const props = {
    onDayClick: (day, modifiers) => onDayClick(day, modifiers, styles),
    selectedDays,
    disabledDays,
    month,
    classNames: styles,
  };

  return <DayPicker {...props} />;
};

export default DatePicker;
