/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import pic1 from './assets/1-vp-of-sales-job-description.png';
import pic2 from './assets/2-contact-rainmakers-find-vp-of-sales-jobs.png';
import pic3 from './assets/3-vp-sales-recruiters.png';
import pic4 from './assets/4-vice-president-of-sales.png';
import pic5 from './assets/5-create-profile-browse-vp-of-sales-jobs.png';

import styles from './VPJobsContent.scss';

export function VPJobsContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt="" />

      <h2>VP of Sales Job Information</h2>

      <p>
        A Vice President (VP) of Sales is in charge of training, motivating, and sometimes hiring a
        company’s sales staff to achieve and surpass targets. The primary responsibilities include
        recruiting, fostering talent inside the organization, and educating employees on the various
        skills required to meet key performance indicators.
      </p>

      <p>
        The success of selling goods or services daily pivots on the ability of the VP of Sales to
        manage their team. This entails selecting individuals who can achieve sales and performance
        objectives and collaborating with other departments to meet regular targets. An effective VP
        of Sales needs to possess excellent communication skills and be a strong leader who knows
        what needs to be done to accomplish objectives.
      </p>

      <h2>Duties and Responsibilities of a VP of Sales</h2>

      <p>
        The Vice President of Sales handles several different tasks. Senior sales executives such as
        VPs frequently serve as managers, mentors, teachers, and strategists. A sales leader must
        perform several essential duties to achieve quotas and promote business success. Some of
        those include:
      </p>

      <ul>
        <li>Oversee recruiting sales personnel.</li>
        <li>Lead the sales team in meeting objectives</li>
        <li>Create sales training courses</li>
        <li>Develop strategies and plans</li>
        <li>Manage sales teams</li>
        <li>Promote company products and services.</li>
        <li>Monitor sales activities.</li>
        <li>Oversee sales budget</li>
        <li>Track market activity</li>
        <li>
          Identify areas for improvement and create programs that address these areas with a
          success-mindset
        </li>
        <li>Collaborate with the marketing division</li>
        <li>Track consumer behavior</li>
        <li>Maintain positive and supportive relationships with clients</li>
      </ul>

      <p>
        Some specific tasks may differ based on the individual company and its objectives. In
        general, the VP of Sales is responsible for setting sales goals, developing strategies to
        meet those goals, and reporting to the CEO on performance metrics.
      </p>

      <Link to="/apply-now" title="Apply Now">
        <img src={pic2} alt="" />
      </Link>

      <h2>What Makes a Good VP of Sales?</h2>

      <p>
        Because the VP of Sales role is vital to a company, it’s essential to be the right person
        for the job. Probably the three most important qualities are:
      </p>

      <ol>
        <li>Fitting in with the company and its culture</li>
        <li>Possessing intimate knowledge of the sales environment</li>
        <li>Being willing to commit to the business for the long term</li>
      </ol>

      <p>
        Considering those qualities, it’s equally essential to possess the following attributes to
        be a top-performing VP of Sales.
      </p>

      <ul>
        <li>
          Instead of simply observing the sales team, they are fully committed to and invested in
          their activities and success. Rather than spending all day in an office with the door
          closed, they may be out in the field or on the sales floor. In addition, they are
          dedicated to coaching and improving the sales team and themselves.
        </li>
        <li>
          They are very knowledgeable of their company’s top 20 deals and accounts. They are
          intimately connected to the customer and know their position in these significant
          accounts. They oversee these accounts to ensure the client doesn’t look elsewhere, the
          relationship is strong, and the accounts still bring in solid revenue.
        </li>
        <li>
          They determine the sales team’s strategy and course of action. They can envision and lay
          out a sales process for the team to follow. They take charge of making this vision a
          reality through intelligent leadership and a systematic approach to sales coverage and
          territory management.
        </li>
        <li>
          They enhance the company’s reputation. The VP of Sales is frequently the face of the
          business and oversees the sales organization. The finest vice presidents of sales are
          excellent at networking, public speaking, and interpersonal communication, and they
          consistently present a favorable image of the organization for which they work.
        </li>
        <li>
          They are continually aware of their position in the sales funnel. The VP of Sales is
          always aware of the projects being worked on by their sales staff and the tasks required
          to maintain a smoothly running sales funnel.
        </li>
        <li>
          They concentrate on the prospect and the client. They adopt an outward-looking mindset and
          consistently keep prospects and customers at the forefront of all decisions and sales
          operations.
        </li>
      </ul>

      <p>
        Directors and Sales managers often become VPs at the next stage of their careers. While they
        may have a lot of experience within a particular company, the scope of responsibilities is
        more extensive, and the expectations are higher.
      </p>

      <p>
        To be a successful VP of Sales, it’s essential to not only be able to handle all of the
        above but to be able to commit to the position and the mission of the company and its sales
        division.
      </p>

      <img src={pic3} alt="" />

      <h2>What Attributes Does a VP of Sales Need?</h2>

      <p>
        There are six characteristics a top VP of Sales needs to execute their job well and be
        recognized as a leader by both the sales team they manage and the executives they report to.
      </p>

      <p>These attributes are:</p>

      <ul>
        <li>
          <strong>Ability to adapt</strong> – Because what worked last year might not work again
          this year, a VP of Sales must apply lessons learned from past experiences to achieve new
          results. A VP of Sales needs to keep track of the latest trends and technology and apply
          them to current situations.
        </li>
        <li>
          <strong>Business command</strong> – Knowing the numbers and offering insights is crucial
          for a VP of Sales since doing so strategically gives an impression of credibility,
          competence, and assurance.
        </li>
        <li>
          <strong>Possess an eye for talent</strong> – Hiring the best sales leaders is crucial
          since it is one of the most expensive mistakes a business can make to give someone a
          salary who cannot perform at the required level.
        </li>
        <li>
          <strong>Thinks strategically</strong> – Business and sales are constantly evolving, and
          the VP of Sales needs to be aware of this. They must plan their strategy based on how they
          wish to increase their sales. Are they aiming for a rise in sales in a particular
          industry? Or perhaps a surge in sales brought on via outbound email prospecting? Truly
          effective VPs ensure that sales objectives are precise enough to support equally precise
          approaches. This makes the impact of various strategies on a company’s sales measurable
          and repeatable.
        </li>
        <li>
          <strong>Builds strong relationships</strong> – The VP of Sales must nurture a strong
          connection with the sales department, the marketing department, the C-suite executives,
          and the clients. They should know that building and maintaining customer relationships is
          essential in the B2B sector. The top VPs of Sales act as relationship-building role models
          for the rest of the sales organization, and they understand the importance of soft skills
        </li>
        <li>
          <strong>Possess Emotional Intelligence</strong> – A top vice president of sales possesses
          considerable emotional intelligence and works to nurture the same among everyone in the
          sales organization. They are aware of the critical role that emotional intelligence plays
          in sales performance, particularly relationship selling. The top sales vice presidents
          will consciously think of strategies to help the sales staff develop fundamental skills
          and traits such as self-awareness and control, self-motivation, resilience, empathy,
          active listening, intellectual curiosity, and stress management.
        </li>
      </ul>

      <img src={pic4} alt="" />

      <h2>Becoming a Vice President of Sales</h2>

      <p>
        To become a Vice President of Sales, one must obtain years of expertise in sales, have a
        track record of profitable deals, and established experience in managerial positions. Most
        potential VPs possess a bachelor’s degree or higher in marketing, business administration,
        or a comparable field. Some businesses may want their Vice Presidents of Sales to hold a
        master’s degree in business administration (MBA).
      </p>

      <p>
        However, the most critical requirement for advancement is a sizable portfolio of profitable
        closed deals. In addition, candidates must have had previous sales experience where they can
        pick up tips from seasoned salespeople and roll that into their skill set. Furthermore,
        leadership positions allow candidates to develop sales tactics and guide sales teams to
        successful closures. Managerial experience also will enable candidates to demonstrate their
        responsibility and dedication when working with major clients.
      </p>

      <h2>How Much Does a Vice President of Sales Make?</h2>

      <p>
        As of the third quarter of 2022, the average VP of Sales salary in the United States was
        around $262,000, with a broad range between approximately $222,000 and $304,000. However,
        salary compensation varies depending on several factors, such as education, credentials,
        supplementary talents, work history, and the amount of experience held in sales.
      </p>

      <Link to="/apply-now" title="Apply Now">
        <img src={pic5} alt="" />
      </Link>

      <h2>VP of Sales As a Career Goal</h2>

      <p>
        Vice Presidents of Sales run the company’s sales division. It’s a huge responsibility and,
        therefore, comes with a full roster of essential duties requiring a full suite of skills and
        a need for seasoned experience.
      </p>

      <p>
        The reward is not only an excellent compensation package but also a great sense of
        satisfaction in not only rising to the challenges of running a sales division but making it
        a success.
      </p>

      <p>
        If you’re interested in pursuing the position of VP of Sales, or need one for your growing
        business, <a href={`https://${process.env.HOST}/`}>contact us</a> today.
      </p>
    </div>
  );
}
