/* eslint react/prefer-stateless-function: "off" */
import React, { Component } from 'react';
import UnauthorizedPage from 'pages/UtilPages/UnauthorizedPage';

const ApprovedUserAuthHOC = () => (WrappedComponent) => {
  class _ApprovedUserAuthHOC extends Component {
    render() {
      const { isApproved = true, logoutPending = false } = this.props;

      if (!isApproved && !logoutPending) return <UnauthorizedPage />;

      return <WrappedComponent {...this.props} />;
    }
  }

  return _ApprovedUserAuthHOC;
};

export default ApprovedUserAuthHOC;
