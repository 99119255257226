import queryString from 'query-string';

export const getDefaultParams = (params) =>
  Object.keys(params).reduce((acc, curr) => {
    acc[curr] = params[curr].defaultValue;

    return acc;
  }, {});

export const makeLike8Param = (searchName) => {
  if (searchName) {
    return `ilike8:${searchName}`;
  }

  return null;
};

export const makeLikeParam = (searchName) => {
  if (searchName) {
    return searchName.length < 3 ? `ilike8:${searchName}` : `8ilike8:${searchName}`;
  }

  return null;
};

export const joinParams = (array) => `&${array.join('&')}`;

export const createRoute = ({ route = '', location = {}, name = '', value }) => {
  const { search = {} } = location;

  const urlParams = new URLSearchParams(search);

  urlParams.delete(name);

  if (Array.isArray(value)) {
    value.forEach((el) => urlParams.append(name, el));
  } else {
    value && urlParams.set(name, value);
  }

  return `${route}?${urlParams.toString()}`;
};

export const queryStringParse = ({ search, params }) => {
  const newParams = queryString.parse(search);

  const keys = Object.keys(params);

  keys.forEach((key) => {
    if (newParams[key] && params[key].type === 'array') {
      newParams[key] = Array.isArray(newParams[key]) ? newParams[key] : [newParams[key]];
    }

    if (!newParams[key]) {
      newParams[key] = params[key].defaultValue;
    }
  });

  return newParams;
};

const NO_SECONDARY_SORT = ['created_at', 'published_on', 'updated_at'];

const ADD_NULLS_LAST = ['last_request_at'];

export const hackSortBy = ({ sortBy }) => {
  const sortByItems = sortBy.split(',');

  const newSortByItems = sortByItems.map((sortByItem) => {
    let newSortByItem = sortByItem;
    ADD_NULLS_LAST.forEach((addNullsSort) => {
      if (sortByItem.includes(addNullsSort)) {
        newSortByItem = `${sortByItem}_nulls_last`;
      }
    });

    return newSortByItem;
  });

  let newSortBy = newSortByItems.join(',');

  const needSecondarySort = NO_SECONDARY_SORT.every((sort) => !newSortBy.includes(sort));

  if (needSecondarySort) {
    newSortBy = `${newSortBy},created_at_desc`;
  }

  return newSortBy;
};
