import React, { useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';

import { handleError } from 'utils/common';
import { promisePickerOptions } from 'connectors/Defaults/promises';
import {
  promiseGetEmployees,
  promiseGetEmployer,
} from 'pages/AdminPortal/ToolboxEmployerEditorPage/promises';
import { promisePostRequisitions } from 'pages/EmployeePortal/RequisitionOpeningPages/promises';

import Button from 'components/Button';
import CandidateData from 'components/CandidateData';
import DialogueModal from 'components/DialogueModal';
import FontIcon from 'components/FontIcon';
import TableOverlay from 'components/TableOverlay';

import SelectGreenhouseJob from 'connectors/DialogModals/SelectGreenhouseJob';

import { toggleArray } from 'utils/formUtils';

import AddToRequisition from './components/AddToRequisition';
import CreateNewRequisition from './components/CreateNewRequisition';
import CreateMessage from './components/CreateMessage';
import ReviewRequest from './components/ReviewRequest';

import styles from './RequestIntroModal.scss';

const STEPS = {
  '1': AddToRequisition,
  '1a': SelectGreenhouseJob,
  '1b': CreateNewRequisition,
  '2': CreateMessage,
  '3': ReviewRequest,
};

const PREVIOUS_STEPS = {
  '1': '1',
  '1a': '1',
  '1b': '1',
  '2': '1',
  '3': '2',
};

export const RequestIntroModal = (props) => {
  const [state, setState] = useState({
    step: '1',
    role: null,
    creatingReq: false,
    employees: [],
    employer: {},
    location: '',
    locations: [],
    message: '',
    oteMinCents: null,
    oteMaxCents: null,
    overview: [],
    reqs: props.reqs,
    requestPending: false,
    salutation: '',
    salutationOriginal: '',
    selectedReqId: '',
    selectedEmpId: '',
    serverError: null,
    templateId: '',
    workModel: [],
  });

  useEffect(() => {
    const { loggedInEmployerId } = props;

    promisePickerOptions()
      .then((pickerOptions) => setState((prevState) => ({ ...prevState, pickerOptions })))
      .catch(handleError);

    if (loggedInEmployerId) {
      promiseGetEmployer({ employerId: loggedInEmployerId })
        .then(({ employer }) => setState((prevState) => ({ ...prevState, employer })))
        .catch(handleError);

      promiseGetEmployees({ employerId: loggedInEmployerId })
        .then(({ employees }) => setState((prevState) => ({ ...prevState, employees })))
        .catch(handleError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [handleSendIntroReqId, setHandleSendIntroReqId] = useState(null);

  useEffect(() => {
    if (handleSendIntroReqId) {
      handleSendIntro({ reqId: handleSendIntroReqId });
      setHandleSendIntroReqId(null);
    }
  }, [handleSendIntro, handleSendIntroReqId]);

  const handleSendMessageClick = () => {
    const {
      candidateId,
      employerName,
      firstName,
      handleRequestIntroCloseClick,
      lastName,
      parentView,
      rainmakersAdmin,
      messageCreateStarted,
    } = props;

    const { message, oteMaxCents, oteMinCents, reqs, salutation, selectedReqId } = state;

    setState((prevState) => ({ ...prevState, requestPending: true }));

    const selectedReq = reqs.find((req) => req.id === selectedReqId);

    const archivedCategory = parentView === 'archived' ? 'archived' : null;
    const bookmarkedCategory = parentView === 'bookmarked' ? 'bookmarked' : null;
    const lockedLinkCategory = parentView === 'locked_link' ? 'locked_link' : null;
    const featuredCategory = parentView === 'matches' ? 'featured' : null;
    const searchCategory = parentView === 'featured' ? 'search' : null;

    const category =
      featuredCategory ||
      searchCategory ||
      archivedCategory ||
      lockedLinkCategory ||
      bookmarkedCategory;

    messageCreateStarted({
      candidateId,
      category,
      employerName,
      firstName,
      lastName,
      message: message.toString('markdown'),
      messageHtml: message.toString('html'),
      oteMinCents,
      oteMaxCents,
      rainmakersAdmin,
      requisition: selectedReq,
      reqId: selectedReqId,
      salutation,
      successCallback: handleRequestIntroCloseClick,
    });
  };

  const handleSendIntro = useCallback(
    ({ reqId }) => {
      const {
        candidateId,
        requisitionCandidatesCreateStarted,
        employerName,
        enableMessaging,
        firstName,
        lastName,
        loggedInEmployerId,
        handleRequestIntroCloseClick,
        parentView,
      } = props;

      const { selectedReqId: selectedId, reqs, selectedEmpId } = state;

      const selectedReqId = reqId || selectedId;

      const selectedReq = reqs.find((req) => req.id === selectedReqId);

      const archivedCategory = parentView === 'archived' ? 'archived' : null;
      const bookmarkedCategory = parentView === 'bookmarked' ? 'bookmarked' : null;
      const lockedLinkCategory = parentView === 'locked_link' ? 'locked_link' : null;
      const featuredCategory = parentView === 'matches' ? 'featured' : null;
      const searchCategory = parentView === 'featured' ? 'search' : null;

      const category =
        featuredCategory ||
        searchCategory ||
        archivedCategory ||
        lockedLinkCategory ||
        bookmarkedCategory;

      if (enableMessaging) {
        setState((prevState) => ({ ...prevState, step: '2' }));
      } else {
        requisitionCandidatesCreateStarted({
          candidateId,
          category,
          employerName,
          employerId: loggedInEmployerId,
          firstName,
          lastName,
          newReq: selectedReq.state === 'pending',
          ownerId: selectedEmpId,
          reqId: selectedReqId,
        });

        handleRequestIntroCloseClick();
      }
    },
    [props, state]
  );

  const handleResetCreateRequisition = () =>
    setState((prevState) => ({
      ...prevState,
      oteMinCents: null,
      oteMaxCents: null,
      locations: [],
      overview: [],
      role: null,
      externalName: null,
      workModel: [],
    }));

  const handleSetState = ({ target: { name, value, type, checked } = {} }) => {
    const newValue = type === 'checkbox' ? checked : value;

    setState((prevState) => ({ ...prevState, [name]: newValue }));
  };

  const handleWorkModelChange = ({ target: { name, value } = {} }) => {
    const { workModel: array } = state;
    const { workModel } = value || {};

    const newArray = toggleArray({ array, value: workModel });

    setState((prevState) => ({ ...prevState, [name]: newArray }));
  };

  const handleAddArrayInput = (name) => {
    const { [name]: arrayToUpdate = [] } = state;

    const newValue = arrayToUpdate;
    newValue.push('');

    setState((prevState) => ({ ...prevState, [name]: newValue }));
  };

  const handleAddArrayValue = (event, arrayName) => {
    const {
      target: { name, value },
    } = event;
    const { [arrayName]: arrayToUpdate } = state;

    let newValue = arrayToUpdate;

    newValue[name] = value;

    if (newValue.every((el) => !el)) {
      newValue = [];
    }

    setState((prevState) => ({ ...prevState, [arrayName]: newValue }));
  };

  const handleReorderArray = ({ startIndex, endIndex, name }) => {
    const { [name]: arrayToUpdate = [] } = state;

    const newArray = arrayToUpdate ? arrayToUpdate.slice() : [];

    const [removed] = newArray.splice(startIndex, 1);

    newArray.splice(endIndex, 0, removed);

    setState((prevState) => ({ ...prevState, [name]: newArray }));
  };

  const handleDeleteArrayInput = (indexToRemove, arrayName) => {
    const { [arrayName]: arrayToUpdate } = state;

    const newValue = arrayToUpdate.filter((id, index) => index !== indexToRemove);

    setState((prevState) => ({ ...prevState, [arrayName]: newValue }));
  };

  const handleToggleLocation = (event) => {
    const {
      target: { subCategories, name, value, optionType },
    } = event;

    const { [name]: arrayToUpdate = [] } = state;

    if (subCategories && optionType === 'folder') {
      let newArray = arrayToUpdate.slice();

      const subCatValues = subCategories.map((sub) => `${sub.value[sub.value.type]}`);

      const filterValues = newArray.map((val) => `${val[val.type]}`);

      const allSelected = subCatValues.every((val) => filterValues.includes(val));

      if (allSelected) {
        newArray = newArray.filter((val) => `${val[val.type]}` !== `${value[value.type]}`);
        newArray = newArray.filter(
          (val) =>
            !subCategories.map((sub) => `${sub.value[sub.value.type]}`).includes(`${val[val.type]}`)
        );
      } else {
        subCategories.forEach((subCat) => {
          if (
            !newArray
              .map((val) => `${val[val.type]}`)
              .includes(`${subCat.value[subCat.value.type]}`)
          ) {
            newArray.push(subCat.value);
          }
        });
      }

      return setState((prevState) => ({ ...prevState, [name]: newArray }));
    }

    if (arrayToUpdate.map((val) => val[val.type]).includes(value[value.type])) {
      let newArray = arrayToUpdate.slice();

      newArray = arrayToUpdate.filter((val) => `${val[val.type]}` !== `${value[value.type]}`);

      return setState((prevState) => ({ ...prevState, [name]: newArray }));
    }

    const newArray = arrayToUpdate.slice();

    newArray.push(value);

    if (subCategories && optionType === 'folder') {
      subCategories.forEach((subCat) => {
        if (!newArray.map((val) => val[val.type]).includes(subCat.label)) {
          newArray.push(subCat.value);
        }
      });
    }

    return setState((prevState) => ({ ...prevState, [name]: newArray }));
  };

  const handleToggleRegions = (event) => {
    const {
      target: { name, label: region },
    } = event;

    const { [name]: arrayToUpdate = [] } = state;

    if (region) {
      const regValue = region;

      // Multiselect:
      // const newArray = arrayToUpdate.map((item) => item.region).includes(regValue)
      //   ? arrayToUpdate.filter((r) => r.region !== regValue)
      //   : [...arrayToUpdate, { type: 'region', region: regValue }];

      // Single select:
      const newArray = arrayToUpdate.map((item) => item.region).includes(regValue)
        ? []
        : [{ type: 'region', region: regValue }];

      return setState((prevState) => ({ ...prevState, [name]: newArray }));
    }
  };

  const handleCreateReqFromJobClick = async (selectedJobId, job = {}) => {
    setState((prevState) => ({ ...prevState, serverError: null }));

    const { attributes: { name: jobName = '' } = {} } = job;

    setState((prevState) => ({
      ...prevState,
      externalName: jobName,
      oteMinCents: null,
      oteMaxCents: null,
      locations: [],
      overview: [],
      role: null,
      workModel: [],
      step: '1b',
      selectedJobId,
    }));
  };

  const handleCreateReqClick = () => {
    const { enableMessaging, loggedInEmployerId } = props;

    const {
      externalName,
      role,
      oteMinCents,
      oteMaxCents,
      locations = [],
      overview = [],
      reqs,
      selectedJobId,
      workModel,
      location,
    } = state;

    const promiseParams = {
      requisition: {
        externalName,
        oteMinCents,
        oteMaxCents,
        locations,
        overview,
        role,
        workModel,
        ...(location ? { location } : {}),
      },
      employerId: loggedInEmployerId,
      greenhouseJobId: selectedJobId,
    };

    const onFulfilled = ({ reqDetail }) => {
      const newReqs = reqs.slice();

      newReqs.unshift(reqDetail);

      setState((prevState) => ({
        ...prevState,
        reqs: newReqs,
        role: '',
        externalName: '',
        creatingReq: false,
        locations: [],
        overview: [],
        oteMinCents,
        oteMaxCents,
        selectedReqId: reqDetail.id,
        selectedJobId: null,
        workModel: [],
        location: '',
      }));

      if (enableMessaging) {
        setState((prevState) => ({ ...prevState, step: '2' }));
      } else {
        setHandleSendIntroReqId(reqDetail.id);
      }
    };

    promisePostRequisitions(promiseParams).then(onFulfilled).catch(handleError);

    setState((prevState) => ({ ...prevState, creatingReq: true }));
  };

  const {
    candidateId,
    candidateProfile,
    handleRequestIntroCloseClick,
    hideNames,
    isAdmin,
    loggedInEmployerId,
    oteCents,
  } = props;

  const {
    creatingReq,
    employees,
    employer: { greenhouse_ready: employerHasGreenhouseEnabled } = {},
    externalName,
    location,
    locations,
    message,
    oteMinCents,
    oteMaxCents,
    overview,
    pickerOptions: { candReqLocations: locationSource = [], role: roleOptions = [] } = {},
    reqs,
    requestPending,
    role,
    salutation,
    salutationOriginal,
    selectedEmpId,
    selectedReqId,
    serverError,
    step,
    templateId,
    workModel,
  } = state;

  const requisition = reqs.find((req) => req.id === selectedReqId) || {};

  const { external_name: currExternalName } = requisition;

  const renderProps = {
    ...props,
    asModal: false,
    creatingReq,
    employees,
    employerHasGreenhouseEnabled,
    externalName,
    handleAction: handleCreateReqFromJobClick,
    handleAddArrayValue,
    handleAddArrayInput,
    handleCreateReqClick,
    handleUpdateReqClick: undefined,
    handleDeleteArrayInput,
    handleReorderArray,
    handleReqInputChange: handleSetState,
    handleWorkModelChange,
    handleRequestIntroCloseClick,
    handleSetState,
    handleResetCreateRequisition,
    handleSkip: () => setState((prevState) => ({ ...prevState, step: '1b' })),
    handleToggleLocation,
    handleToggleRegions,
    handleSendIntro,
    handleCancelMessageClick: handleRequestIntroCloseClick,
    handleSendMessageClick,
    hasCancel: false,
    hideNames,
    isAdmin,
    location,
    locations,
    locationSource,
    loggedInEmployerId,
    message,
    oteMinCents,
    oteMaxCents,
    oteCents,
    overview,
    reqs,
    requisition,
    role,
    roleOptions,
    salutation,
    salutationOriginal,
    selectedReqId,
    selectedEmpId,
    templateId,
    workModel,
  };

  const Component = STEPS[step] || null;

  const modalClassnames = classnames({
    [styles.messageModal]: true,
  });

  const closeButtonProps = {
    onClick: handleRequestIntroCloseClick,
    quaternary: true,
    className: styles.close,
  };

  const backButtonProps = {
    quaternary: true,
    className: styles.backButton,
    onClick: () => setState((prevState) => ({ ...prevState, step: PREVIOUS_STEPS[step] })),
  };

  const candidateDataProps = {
    candidateId,
    useHideNames: true,
    candidate: {
      attributes: candidateProfile,
    },
    subtitle: currExternalName,
    noHighlight: true,
  };

  return (
    <DialogueModal className={modalClassnames} data-testid="RequestIntroModal">
      <div className={styles.requestIntroModal}>
        {requestPending ? (
          <TableOverlay />
        ) : (
          <>
            <div className={styles.top}>
              {step !== '1' ? (
                <Button {...backButtonProps}>
                  <FontIcon iconName="caret-left" className={styles.leftArrow} />
                </Button>
              ) : (
                <div className={styles.emptyBackButton} />
              )}
              <CandidateData {...candidateDataProps} />
              <Button {...closeButtonProps} data-testid="closeButton">
                <FontIcon iconName="close" />
              </Button>
            </div>
            {serverError ? (
              <div className={styles.errorMessage}>
                Sorry, something has gone wrong. Please try again or contact{' '}
                <a href="mailto:support@rainmakers.co">support@rainmakers.co</a> if the problem
                persists.
              </div>
            ) : null}
            <Component {...renderProps} />
          </>
        )}
      </div>
    </DialogueModal>
  );
};

export default RequestIntroModal;
