import React, { Component } from 'react';

import { handleError } from 'utils/common';
import EmptyEducationLogo from 'shared/assets/missing-education-organization-picture.png';

import { patchEntity } from 'api/apiEntity';

import Block from 'components/Block';
import Button from 'components/Button';
import Content from 'components/Content';
import EducationOrgImageUploader from 'components/EducationOrgImageUploader';
import Input from 'components/Input';
import Label from 'components/Label';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';

import styles from './EducationOrgEditorProfile.scss';

class EducationOrgEditorProfile extends Component {
  verifyEducationOrg = async () => {
    const {
      actions: { resourceUpdate, resourceDelete },
      educationOrg,
      educationOrg: {
        relationships: {
          unverifiedEducationOrganizationData: { data: unverifiedDataIds = [] } = {},
        } = {},
      } = {},
      unverifiedEducationOrganizationData: { byId: unverifiedDataById = {} } = {},
    } = this.props;

    const {
      [unverifiedDataIds[0]]: { attributes: { webSite, picture = {} } = {}, id },
    } = unverifiedDataById || {};

    const resource = {
      ...educationOrg,
      attributes: {
        ...educationOrg.attributes,
        webSite: webSite || educationOrg.attributes.webSite,
        picture: picture || educationOrg.attributes.picture,
      },
    };

    resourceUpdate({
      slice: 'toolboxEducationOrgs',
      type: 'educationOrganization',
      id: educationOrg.id,
      resource,
    });

    const args = {
      id,
      type: 'unverified_education_organization_data',
      data: {
        attributes: {
          state: 'verified',
        },
        id,
        type: 'unverified_education_organization_data',
      },
      config: {
        params: {
          camelizeIt: true,
        },
      },
    };

    try {
      await patchEntity(args);
    } catch (error) {
      handleError(error);
    }

    resourceDelete({
      id,
      slice: 'toolboxEducationOrgs',
      type: 'unverifiedEducationOrganizationData',
    });
  };

  rejectEducationOrg = async () => {
    const {
      actions: { resourceDelete },
      educationOrg: {
        relationships: {
          unverifiedEducationOrganizationData: { data: unverifiedDataIds = [] } = {},
        } = {},
      } = {},
    } = this.props;

    const args = {
      id: unverifiedDataIds[0],
      type: 'unverified_education_organization_data',
      data: {
        attributes: {
          state: 'rejected',
        },
        id: unverifiedDataIds[0],
        type: 'unverified_education_organization_data',
      },
      config: {
        params: {
          camelizeIt: true,
        },
      },
    };

    try {
      await patchEntity(args);
    } catch (error) {
      handleError(error);
    }

    resourceDelete({
      id: unverifiedDataIds[0],
      slice: 'toolboxEducationOrgs',
      type: 'unverifiedEducationOrganizationData',
    });
  };

  handleEducationOrgInputChange = (event) => {
    const {
      target: { name, value: newValue },
    } = event;

    const {
      actions: { patchResource },
      educationOrg: { id },
    } = this.props;

    patchResource({
      id,
      attributes: {
        [name]: newValue,
      },
      normalizeIt: true,
      requiredFields: ['name'],
      slice: 'toolboxEducationOrgs',
      type: 'education_organizations',
    });
  };

  render() {
    const {
      actions: { patchResource },
      educationOrg = {},
      saving,
      unverifiedEducationOrganizationData: { byId: unverifiedDataById = {} } = {},
    } = this.props;

    const {
      attributes: {
        name: educationOrgName,
        description,
        picture: { xlarge: pictureUrl } = {},
        webSite,
      } = {},
      id: educationOrgId,
      relationships: {
        unverifiedEducationOrganizationData: { data: unverifiedDataIds = [] } = {},
      } = {},
    } = educationOrg;

    const {
      attributes: {
        picture: { xlarge: unverifiedPictureUrl = EmptyEducationLogo } = {},
        webSite: unverifiedWebsite = 'No website available',
      } = {},
    } = unverifiedDataById[unverifiedDataIds[0]] || {};

    const verified = unverifiedDataIds[0]
      ? !unverifiedDataById[unverifiedDataIds[0]]
      : !unverifiedDataIds[0];

    const savingContent = saving ? (
      <div className={styles.autoSaving}>Saving...</div>
    ) : (
      <div className={styles.autoSaved}>Profile Saved</div>
    );

    const saveContent = saving === undefined ? null : savingContent;

    const commonBlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      addPadding: true,
    };

    const firstBlockProps = {
      ...commonBlockProps,
      className: styles.firstBlock,
    };

    const titleBlockProps = {
      ...commonBlockProps,
      className: styles.titleBlock,
    };

    const basicInfoBlockProps = {
      ...commonBlockProps,
      title: 'Basic Info',
    };

    const commonTextInputProps = {
      handleInputChange: this.handleEducationOrgInputChange,
      type: 'text',
      size: 'full',
    };

    const educationOrgNameInputProps = {
      placeholder: '',
      size: 'full',
      name: 'name',
      value: educationOrgName || '',
      label: 'Institution Name',
      handleInputChange: this.handleEducationOrgInputChange,
      handleOnBlur: this.handleEducationOrgInputChange,
      required: true,
      className: styles.noPadding,
    };

    const educationOrgDescriptionInputProps = {
      ...commonTextInputProps,
      name: 'description',
      label: 'Description',
      value: description || '',
      placeholder: '',
      type: 'textarea',
      rows: '5',
    };

    const educationOrgWebSiteInputProps = {
      ...commonTextInputProps,
      name: 'webSite',
      label: 'Website',
      value: webSite || '',
      placeholder: '',
    };

    const educationOrgVerifiedInputProps = {
      onClick: this.verifyEducationOrg,
      primary: true,
      disabled: verified,
    };

    const educationOrgRejectedInputProps = {
      onClick: this.rejectEducationOrg,
      warning: true,
      disabled: verified,
    };

    const imageUploaderProps = {
      educationOrgId,
      pictureUrl,
      educationOrgImageUploadStarted: ({ picture }) =>
        patchResource({
          id: educationOrgId,
          attributes: {
            picture: {
              file_data: picture,
            },
          },
          normalizeIt: true,
          requiredFields: ['name'],
          slice: 'toolboxEducationOrgs',
          type: 'education_organizations',
        }),
    };

    const containerProps = {
      className: styles.profileAvatarWrapper,
    };

    const logoProps = {
      className: styles.profileAvatar,
      src: unverifiedPictureUrl,
    };

    const unverifiedContent = !verified ? (
      <Block {...firstBlockProps}>
        <Content className={styles.info}>
          Does this information look correct? If so, click verify. If not, click reject and manually
          input the proper info.
          <Button {...educationOrgVerifiedInputProps}>Verify</Button>
          <Button {...educationOrgRejectedInputProps}>Reject</Button>
        </Content>
        <Content className={styles.unverifiedContainer}>
          <div {...containerProps}>
            <Label standalone>Suggested logo</Label>
            <img {...logoProps} alt="" />
          </div>
          <div className={styles.basicInfoTop}>
            <Label standalone>Suggested website</Label>
            <Content className={styles.unverifiedData}>{unverifiedWebsite}</Content>
          </div>
        </Content>
      </Block>
    ) : null;

    const content = (
      <div className={styles.EditorProfile}>
        {unverifiedContent}
        <Block {...titleBlockProps}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>Education Organization Profile</div>
            <div className={styles.saveContent}>{saveContent}</div>
          </div>
        </Block>
        <div className={styles.scrollDiv}>
          <Block {...basicInfoBlockProps}>
            <div className={styles.containerFlexRow}>
              <EducationOrgImageUploader {...imageUploaderProps} />
              <div className={styles.containerFlexColumn}>
                <Input {...educationOrgNameInputProps} />
                <Input {...educationOrgWebSiteInputProps} />
              </div>
            </div>
            <div className={styles.basicInfoTop}>
              <Input {...educationOrgDescriptionInputProps} />
            </div>
          </Block>
        </div>
      </div>
    );

    const layoutWithoutSidebarProps = {
      content,
    };

    return <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />;
  }
}

export default EducationOrgEditorProfile;
