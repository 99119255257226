import React from 'react';
import { Link } from 'react-router-dom';

import styles from './BecomeRainmaker.scss';

const BecomeRainmaker = () => {
  return (
    <div className={styles.BecomeRainmaker}>
      <h3 className={styles.Title}>Looking to build a sales dream team?</h3>

      <div className={styles.Description}>
        Rainmakers has helped hundreds of B2B businesses start and grow their sales teams by
        leveraging efficient technology, proprietary sales data, and a highly-experienced sales
        recruiting team. Start the process now by filling out our quick contact form and get
        connected with the talent that will take your company to the next level.
      </div>

      <Link className={styles.Request} to="/employer-signup/">
        Join Now
      </Link>
    </div>
  );
};

export default BecomeRainmaker;
