import { getEntity } from 'api/apiEntity';

const promiseTags = () => {
  const onFulfilled = ({ data: tags = [] }) => tags;

  const args = { type: 'data_store/tags' };

  return getEntity(args).then(onFulfilled);
};

export default promiseTags;
