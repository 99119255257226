import React from 'react';

import EmptyAvatar from 'shared/assets/missing-user-picture.png';

import Button from 'components/Button';
import FormattedName from 'components/FormattedName';
import FontIcon from 'components/FontIcon';

import styles from '../../ApproachEditorEmployee.scss';

const GroupMember = ({
  deleteResource,
  member: {
    id: memberId,
    attributes: { creatorType } = {},
    relationships: { profile: { data: profileIds = [], type: profileTypes = [] } = {} } = {},
  } = {},
  user: { currentProfile: { id: currentProfileId } = {} } = {},
  ...others
}) => {
  const profileType = `${profileTypes[0]}s`;

  const {
    byId: {
      [profileIds[0]]: {
        attributes: {
          firstName,
          jobTitle,
          lastName,
          picture: { xlarge: pictureUrl = '' } = {},
          type,
        } = {},
        id: profileId,
      } = {},
    } = {},
  } = others[profileType] || {};

  const picUrl = pictureUrl === '' ? EmptyAvatar : pictureUrl;

  const avatarProps = { src: picUrl };

  const formattedNameProps = {
    firstName,
    lastName,
  };

  const deleteProps = {
    className: styles.deleteButton,
    onClick: () =>
      deleteResource({
        type: 'message_group_members',
        slice: 'toolboxApproaches',
        id: memberId,
      }),
    warning: true,
  };

  const deleteButton =
    deleteResource && creatorType !== null && currentProfileId !== profileId ? (
      <Button {...deleteProps}>
        <FontIcon iconName="close" />
      </Button>
    ) : null;

  const subtitle = type === 'Employee' ? jobTitle : type;

  return (
    <div className={styles.container}>
      <div className={styles.avatarContainer}>
        <img alt="" {...avatarProps} />
      </div>

      <div className={styles.infoContainer}>
        <div className={styles.nameContainer}>
          <FormattedName {...formattedNameProps} />
        </div>

        <div className={styles.jobContainer}>{subtitle}</div>
      </div>

      {deleteButton}
    </div>
  );
};

export default GroupMember;
