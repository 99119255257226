import React, { useState } from 'react';

import VisibilitySensor from 'react-visibility-sensor';

const VisibleHOC =
  (bottom = 186) =>
  (WrappedComponent) => {
    const _VisibleHOC = (props) => {
      const [isVisible, setIsVisible] = useState(null);

      const { containerRef } = props;

      const visProps = {
        onChange: (isVisible) => setIsVisible(isVisible),
        intervalDelay: 200,
        partialVisibility: 'bottom',
        offset: {
          bottom,
        },
      };

      if (containerRef) visProps.containment = containerRef;

      const wrappedComponentProps = {
        ...props,
        isVisible,
      };

      return (
        <VisibilitySensor {...visProps}>
          <WrappedComponent {...wrappedComponentProps} />
        </VisibilitySensor>
      );
    };

    return _VisibleHOC;
  };

export default VisibleHOC;
