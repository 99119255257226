import React, { Fragment } from 'react';
import classnames from 'classnames';

import Bolder from 'components/Bolder';
import Button from 'components/Button';
import InputSelect from 'components/InputSelect';
import FontIcon from 'components/FontIcon';
import FormattedMoney from 'components/FormattedMoney';

import styles from './AddToRequisition.scss';

const AddToRequisition = ({
  employerHasGreenhouseEnabled,
  employees,
  enableMessaging,
  greenhouseReady,
  handleResetCreateRequisition,
  handleSendIntro,
  handleSetState,
  isAdmin,
  reqs,
  selectedEmpId,
  selectedReqId,
}) => {
  const commonNewReqButtonProps = {
    onClick: () => {
      handleResetCreateRequisition();
      handleSetState({
        target: {
          name: 'step',
          value: greenhouseReady || employerHasGreenhouseEnabled ? '1a' : '1b',
        },
      });
    },
  };

  const createNewReqButtonProps = {
    ...commonNewReqButtonProps,
    className: styles.createNewReqButton,
    tertiary: true,
  };

  const createNewReqButtonPrimaryProps = {
    ...commonNewReqButtonProps,
    className: styles.createNewReqButtonPrimary,
    primary: true,
  };

  const sendIntroButtonProps = {
    onClick: handleSendIntro,
    primary: true,
    disabled: (isAdmin && selectedEmpId === '') || selectedReqId === '',
    className: styles.nextButton,
  };

  const sendIntroButton =
    !isAdmin && enableMessaging ? (
      <Button {...sendIntroButtonProps} data-testid="sendIntroButton">
        Next
      </Button>
    ) : (
      <Button {...sendIntroButtonProps} data-testid="sendIntroButton">
        Add and send intro request
      </Button>
    );

  const requisitionRows = reqs.map((req) => {
    const {
      external_name: externalName,
      id,
      abbreviated_locations: locations = [],
      ote_min_cents: oteMinCents,
      ote_max_cents: oteMaxCents,
    } = req || {};

    const selected = selectedReqId === id;

    const rowProps = {
      onClick: () => {
        handleSetState({
          target: {
            name: 'oteMinCents',
            value: oteMinCents,
          },
        });
        handleSetState({
          target: {
            name: 'oteMaxCents',
            value: oteMaxCents,
          },
        });
        handleSetState({
          target: {
            name: 'selectedReqId',
            value: id,
          },
        });
      },
      className: classnames(styles.row, styles.mainRow, {
        [styles.selected]: selected,
      }),
    };

    const selectedContent = selected ? <FontIcon iconName="checkmark-circle" /> : null;

    const locationsContent = locations.map((loc) => (
      <div key={loc} className={styles.location}>
        {loc}
      </div>
    ));

    const oteMaxContent =
      oteMaxCents && oteMaxCents !== 0 ? <FormattedMoney amount={oteMaxCents} /> : null;

    const oteMinContent =
      oteMinCents && oteMinCents !== 0 ? <FormattedMoney amount={oteMinCents} /> : null;

    const dashContent = oteMinContent && oteMaxContent ? <span> - </span> : null;

    return (
      <div key={id} {...rowProps}>
        <div className={styles.selectedCell}>{selectedContent}</div>
        <div className={styles.cell}>{externalName}</div>
        <div className={styles.cell}>{locationsContent}</div>
        <div className={styles.cell}>
          {oteMinContent}
          {dashContent}
          {oteMaxContent}
        </div>
      </div>
    );
  });

  const empSource = employees.map((emp) => {
    const { firstName = '', lastName = '', jobTitle = '', id: empId } = emp;

    const label = jobTitle ? `${firstName} ${lastName}, ${jobTitle}` : `${firstName} ${lastName}`;

    return {
      label,
      value: empId,
    };
  });

  const selectEmpInputProps = {
    name: 'selectedEmpId',
    source: empSource,
    value: selectedEmpId,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    handleInputChange: handleSetState,
    defaultLabel: 'Pick an Employee',
    bigInput: true,
  };

  const createNewReq = <Button {...createNewReqButtonProps}>+ Create a new Req</Button>;

  const createNewReqButton = (
    <Button {...createNewReqButtonPrimaryProps}>+ Create a Requisition</Button>
  );

  const empCreateContent = isAdmin ? <InputSelect {...selectEmpInputProps} /> : null;

  const content =
    reqs && reqs.length > 0 ? (
      <div className={styles.adminRequestOptionsContainer}>
        <div className={styles.header}>Add to a Requisition</div>
        <div className={styles.subheader}>Select a req from the list below.</div>
        {createNewReq}
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.selectedCell} />
            <div className={styles.cell}>
              <Bolder>Requisition Name</Bolder>
            </div>
            <div className={styles.cell}>
              <Bolder>Location</Bolder>
            </div>
            <div className={styles.cell}>
              <Bolder>OTE</Bolder>
            </div>
          </div>
          {requisitionRows}
        </div>
        {empCreateContent}
      </div>
    ) : (
      <div className={styles.adminRequestOptionsContainer}>
        <div className={styles.header}>Add to a Requisition</div>
        <div className={styles.emptyTable}>
          <div className={styles.subheader}>You currently have no active requisitions</div>
          {createNewReqButton}
        </div>
        {empCreateContent}
      </div>
    );

  return (
    <Fragment>
      {content}
      <div className={styles.requestIntroButtons}>{sendIntroButton}</div>
    </Fragment>
  );
};

export default AddToRequisition;
