import React from 'react';
import classnames from 'classnames';

import InputAutoComplete from 'components/InputAutoComplete';

import styles from './SearchKeyword.scss';

const SearchKeyword = ({
  placeholder,
  className,
  label = 'Keyword(s)',
  handleKeywordAdd,
  noAddButton,
  ...otherProps
}) => {
  return (
    <div className={classnames(styles.SearchKeyword, className)} {...otherProps}>
      <InputAutoComplete
        placeholder={placeholder}
        source={[]}
        size={'full'}
        handleInputChange={handleKeywordAdd}
        value={''}
        needReset={true}
        hasAddButton={!noAddButton}
        label={label}
      />
    </div>
  );
};

export default SearchKeyword;
