import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { resourceSelectorFactory } from 'reduxStore/selectorFactories';

import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import Block from 'components/Block';

import { toggleArray } from 'utils/formUtils';

import CandidateApproachesTable from './components/CandidateApproachesTable';
import CandidateBookmarksTable from './components/CandidateBookmarksTable';
import CandidateUTMInfo from './components/CandidateUTMInfo';
import CandidateReferrals from './components/CandidateReferrals';

import styles from './CandidateEditorStats.scss';

const CandidateEditorStats = (props) => {
  const [state, setState] = useState({ state: ['active', 'candidate_pass_pending'] });
  const newArrayRef = useRef();

  const {
    actions: { entityLoadStarted },
    match: { params },
  } = props;

  useEffect(() => {
    if (newArrayRef.current) {
      const { id: candidateId } = params;

      entityLoadStarted({
        type: 'requisition_candidates',
        slice: 'toolboxCandidates',
        params: {
          page: 1,
          candidateId,
          sortBy: 'created_at_desc',
          includeSet: 'admin_toolbox',
          state: newArrayRef.current,
        },
      });

      newArrayRef.current = undefined;
    }
  }, [entityLoadStarted, params, state]);

  const handleSearchFilterChange = (event) => {
    const {
      target: { name, value },
    } = event;

    const { [name]: arrayToUpdate } = state;

    const actualValue = value[value.type];

    const newArray = toggleArray({ array: arrayToUpdate, value: actualValue });

    newArrayRef.current = newArray;

    setState((prevState) => ({ ...prevState, [name]: newArray }));
  };

  const loadMoreRequisitionCandidates = () => {
    const {
      actions: { entityLoadStarted },
      requisitionCandidatesPage: page,
      match: {
        params: { id: candidateId },
      },
    } = props;

    entityLoadStarted({
      type: 'requisition_candidates',
      slice: 'toolboxCandidates',
      params: {
        page: page + 1,
        candidateId,
        sortBy: 'created_at_desc',
        includeSet: 'admin_toolbox',
        state,
      },
    });
  };

  const loadMoreBookmarks = () => {
    const {
      actions: { entityLoadStarted },
      bookmarksPage: page,
      match: { params: { id } = {} } = {},
    } = props;

    entityLoadStarted({
      type: 'companies',
      typeOverride: 'bookmarks',
      slice: 'toolboxCandidates',
      params: {
        page: page + 1,
        bookmarkedCandidateId: id,
      },
    });
  };

  const {
    actions,
    candidate: {
      id: candidateId,
      attributes: { firstName, lastName, userId, creatorType } = {},
    } = {},
    bookmarks,
    bookmarksTotal,
    employees,
    employers,
    users: { byId: usersById = {} } = {},
    privateReqCanFeedback,
    requisitionCandidates,
    requisitionCandidatesTotal,
    requisitionCandidatesLoading,
    requisitionOpenings,
  } = props;

  const user = usersById[userId] || {};

  const { attributes: { referredByUserId } = {} } = user;
  const referredByUser = usersById[referredByUserId] || {};
  const { relationships: { currentProfile: { data, type } = {} } = {} } = referredByUser;

  const referredByUserCurrentProfile = useSelector(
    resourceSelectorFactory(
      'toolboxCandidates',
      (type && type[0]) || 'user',
      (data && data[0]) || 0
    )
  );

  const content = (
    <div className={styles.EditorStats}>
      <Block addWhiteBG={true} boxShadow={true} addPadding={true} className={styles.titleBlock}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Candidate Stats</div>
        </div>
      </Block>
      <div className={styles.scrollDiv}>
        <div className={styles.contentTop}>
          <Block
            addWhiteBG={true}
            boxShadow={true}
            largeTitleFont={true}
            title="Candidate ID"
            addPadding={true}
          >
            {candidateId}
          </Block>
        </div>

        <div className={styles.content}>
          <CandidateUTMInfo
            referredByUser={usersById[referredByUserId]}
            user={user}
            creatorType={creatorType}
          />
        </div>

        <div className={styles.content}>
          <CandidateReferrals
            referredByUser={usersById[referredByUserId]}
            referredByUserCurrentProfile={referredByUserCurrentProfile}
            user={user}
            creatorType={creatorType}
            actions={actions}
          />
        </div>

        <div className={styles.content}>
          <CandidateApproachesTable
            actions={actions}
            firstName={firstName}
            handleSearchFilterChange={handleSearchFilterChange}
            lastName={lastName}
            loadMoreRequisitionCandidates={loadMoreRequisitionCandidates}
            employees={employees}
            employers={employers}
            privateReqCanFeedback={privateReqCanFeedback}
            requisitionCandidates={requisitionCandidates}
            requisitionCandidatesTotal={requisitionCandidatesTotal}
            requisitionCandidatesLoading={requisitionCandidatesLoading}
            requisitionOpenings={requisitionOpenings}
            state={state.state}
          />
        </div>

        <div className={styles.content}>
          <CandidateBookmarksTable
            bookmarks={bookmarks}
            bookmarksTotal={bookmarksTotal}
            loadMoreBookmarks={loadMoreBookmarks}
          />
        </div>
      </div>
    </div>
  );

  return <LayoutWithoutSidebar content={content} />;
};

export default CandidateEditorStats;
