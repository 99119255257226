import React, { useState } from 'react';

import AutocompleteServer from 'connectors/AutocompleteServer';
import Button from 'components/Button';
import Content from 'components/Content';
import ContentTitle from 'components/ContentTitle';
import EmployerData from 'components/EmployerData';
import Table from 'components/Table';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import TableRow from 'components/Table/components/TableRow';
import TableCell from 'components/Table/components/TableCell';

import ActionDropdown from './components/ActionDropdown';

import styles from './BlockedCompanies.scss';

const BlockedCompanies = (props) => {
  const [showCompanyInput, setShowCompanyInput] = useState(false);
  const {
    candidateCompanyBlocks,
    candidateCompanyBlocksTotal,
    candidateCompanyBlocksLoading,
    candidateCompanyBlocksErrors,
    loadMoreCandidateCompanyBlocks,
    candidateCompanyBlocksCreateStarted,
    candidateCompanyBlocksErrorSet,
    createCompanyAndBlockStarted,
    candidateId,
  } = props;

  const addCompanyBlockButtonProps = {
    tertiaryThin: true,
    onClick: () => {
      candidateCompanyBlocksErrorSet({ errors: null });
      setShowCompanyInput(true);
    },
    className: styles.button,
  };

  const makeBlockRows = (block) => {
    const { company, id: candidateCompanyBlockId } = block;

    const { id: companyId } = company;

    const companyDataProps = {
      employer: company,
    };

    const companyDataContent = (
      <div className={styles.companyContent}>
        <EmployerData {...companyDataProps} />
      </div>
    );

    return (
      <TableRow key={companyId} id={candidateCompanyBlockId}>
        <TableCell className={styles.companyData} flex={true} key={1}>
          {companyDataContent}
        </TableCell>
        <TableCell className={styles.noPaddingCell} noFlex={true} key={2}>
          <div className={styles.actionsContent}>
            <ActionDropdown candidateCompanyBlockId={candidateCompanyBlockId} />
          </div>
        </TableCell>
      </TableRow>
    );
  };

  const infiniteScrollProps = {
    pageStart: 0,
    loadMore: loadMoreCandidateCompanyBlocks,
    hasMore: candidateCompanyBlocksTotal > candidateCompanyBlocks.length,
    loader: candidateCompanyBlocksLoading ? <div /> : <InfiniteScrollLoader />,
    useWindow: false,
  };

  const tableProps = {
    tableContent: candidateCompanyBlocks.map(makeBlockRows),
    infiniteScrollProps,
    tableName: 'candidateCompanyBlocks',
    isEmpty: candidateCompanyBlocks.length === 0,
    emptyTextString: 'No Blocked Companies',
    isLoading: candidateCompanyBlocksLoading,
    noFade: true,
    noBoxShadow: true,
    noBlockFlex: true,
  };

  const addCompanyBlockInputProps = {
    placeholder: 'Start typing and select a company or add if not found',
    value: '',
    size: 'full',
    name: '',
    handleInputChange: (event) => {
      candidateCompanyBlocksCreateStarted({
        companyId: event.target.value.id,
        candidateId,
        ownerId: candidateId,
      });
      setShowCompanyInput(false);
    },
    handleOnBlur: (event) =>
      createCompanyAndBlockStarted({
        companyName: event.target.value,
        candidateId,
      }),
    topLabel: true,
    autocompleteType: 'companies',
    field: 'name',
    needReset: true,
    hasAddButton: true,
  };

  return (
    <Content className={styles.container}>
      <ContentTitle>YOUR BLOCKED COMPANIES</ContentTitle>

      <Content className={styles.content}>
        Keep certain companies from seeing your profile. We automatically block your previous
        employers from seeing your profile, but you can change this any time you want.
      </Content>

      {candidateCompanyBlocksErrors ? (
        <div className={styles.error}>{candidateCompanyBlocksErrors}</div>
      ) : null}

      {showCompanyInput ? (
        <div className={styles.inputContainer}>
          <AutocompleteServer {...addCompanyBlockInputProps} />
        </div>
      ) : (
        <Button {...addCompanyBlockButtonProps}>+ Add a company</Button>
      )}

      <Table {...tableProps} />
    </Content>
  );
};

export default BlockedCompanies;
