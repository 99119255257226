import React from 'react';
import classnames from 'classnames';
import Layout from 'components/Layout';

import styles from './LayoutWithoutSidebar.scss';

const LayoutWithoutSidebar = ({ content, className, isModal = false, isPrint = false }) => {
  const contentClassnames = classnames({
    [styles.content]: !isPrint,
    [styles.contentPrint]: isPrint,
    [styles.contentModal]: isModal,
  });

  return (
    <Layout className={className} isModal={isModal}>
      <div className={contentClassnames}>{content}</div>
    </Layout>
  );
};

export default LayoutWithoutSidebar;
