import React from 'react';

import InputCheckboxSlider from 'components/InputCheckboxSlider';
import Label from 'components/Label';

import styles from '../ReqRequirement.scss';

const RecentGrad = ({
  disabled,
  handleInputChange,
  requisitionRequirement: { attributes: { recentGrad } = {} } = {},
}) => {
  const recentGradInputProps = {
    disabled,
    name: 'recentGrad',
    label: recentGrad ? 'Yes' : 'No',
    checked: recentGrad || false,
    handleInputChange,
  };

  return (
    <div className={styles.rangeInputs}>
      <div className={styles.column}>
        <Label className={styles.label}>Would you accept a recent graduate?</Label>
        <InputCheckboxSlider {...recentGradInputProps} />
      </div>
    </div>
  );
};

export default RecentGrad;
