import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import Sidebar from 'components/Sidebar';
import Title from 'components/Title';

import { CandidateAuthHOC } from 'containers/HOC';
import ConnectContainer from 'containers/ConnectContainer';

import ReadyContainer from 'connectors/ReadyContainer';

import YourReferralCode from 'components/YourReferralCode';
import YourReferrals from 'components/YourReferrals';

import duck from '../redux';
import { candidateLoadStarted } from '../redux/creators';

import styles from '../Settings.scss';

const Referrals = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(candidateLoadStarted());
  }, [dispatch]);

  const { user: { referralCode = 'No Code', email, referredSignUps = 0, referredHires = 0 } = {} } =
    props;

  const content = (
    <div className={styles.WelcomePage}>
      <Title className={styles.title}>Referrals</Title>

      <YourReferralCode
        referralCode={referralCode}
        referralEmail={encodeURIComponent(email)}
        showEmailLink={false}
      />

      <YourReferrals referredHires={referredHires} referredSignUps={referredSignUps} />
    </div>
  );

  return (
    <>
      <Helmet title="Referrals" />
      <ReadyContainer className={styles.EmployerStatusPage}>
        <Sidebar type="candidateSettings" page="referrals" />
        <LayoutWithoutSidebar content={content} />
      </ReadyContainer>
    </>
  );
};

export default ConnectContainer(duck)(CandidateAuthHOC()(Referrals));
