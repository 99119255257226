import React from 'react';

import Button from 'components/Button';

import styles from './MainContent.scss';

const MainContent = ({
  workHistories,
  handleAddWorkHistory,
  makeWorkHistoryInputs,
  recentGrad,
}) => {
  const workHistoryInputs = workHistories.map(makeWorkHistoryInputs);

  const addWorkHistoryProps = {
    tertiary: true,
    onClick: handleAddWorkHistory,
    className: styles.addButton,
    disabled: workHistories.some((wh) => wh.isEditing),
  };

  const addWorkHistoryButton =
    workHistories.length > 0 ? null : <Button {...addWorkHistoryProps}>+ Add work history</Button>;

  return (
    <div className={styles.contentContainer}>
      <div className={styles.container}>
        <div className={recentGrad ? styles.containerTitle : styles.containerTitleRequired}>
          MOST RECENT SALES ROLE
        </div>
        {workHistoryInputs}
        {addWorkHistoryButton}
      </div>
    </div>
  );
};

export default MainContent;
