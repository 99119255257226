import React, { Component } from 'react';

import Block from 'components/Block';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import InputSelect from 'components/InputSelect';

import TemplateComposer from 'connectors/TemplateComposer';

import styles from './EmployerEditorTemplates.scss';

class EmployerEditorTemplates extends Component {
  constructor(props) {
    super(props);

    const { employees: { allIds = [], byId = {} } = {} } = props;

    this.state = {
      message: '',
      salutationOriginal: '',
      selectedEmployee: byId[allIds[0]],
      template: '',
    };
  }

  loadMoreEmployees = () => {
    const {
      actions: { entityLoadStarted },
      employeesPage: page,
      employer: { id } = {},
    } = this.props;

    entityLoadStarted({
      type: 'employees',
      slice: 'toolboxEmployers',
      params: {
        sortBy: 'created_at_desc',
        employerId: id,
        page: page + 1,
        includeSet: 'employer_company',
      },
    });
  };

  handleSetState = ({ target: { name, value } = {} }) => this.setState({ [name]: value });

  handleSetEmployee = (event) => {
    const { employees: { byId = {} } = {} } = this.props;

    const {
      target: { value: newValue },
    } = event;

    this.setState({ selectedEmployee: byId[newValue] });
  };

  render() {
    const {
      employees: { allIds = [], byId = {} },
    } = this.props;

    const {
      message,
      salutationOriginal,
      selectedEmployee: { id: employeeId } = {},
      template,
    } = this.state;

    const employeeSource = allIds.map((id) => {
      const { attributes: { firstName, lastName } = {} } = byId[id];

      return {
        label: `${firstName} ${lastName}`,
        value: id,
      };
    });

    const employeeInputProps = {
      source: employeeSource,
      name: 'employeeId',
      handleInputChange: this.handleSetEmployee,
      value: employeeId || '',
      defaultLabel: 'Select Employee',
      defaultIcon: 'caret-down',
      noMargin: true,
      setWidth: '160px',
      bigInput: true,
    };

    const filtersContent = (
      <div className={styles.templatesHeader}>
        <span>Templates for </span>
        <InputSelect {...employeeInputProps} />
      </div>
    );

    const templateComposerProps = {
      autoFocus: false,
      first_name: '<First Name>',
      employeeId,
      handleSetState: this.handleSetState,
      message,
      name: 'message',
      salutationOriginal,
      template,
      adminEditor: true,
    };

    const contentBlockProps = {
      boxShadow: true,
      addChildFlex: true,
      title: filtersContent,
      addWhiteBG: true,
      className: styles.firstBlock,
    };

    const content = (
      <Block {...contentBlockProps}>
        <TemplateComposer {...templateComposerProps} />
      </Block>
    );

    const layoutWithoutSidebarProps = {
      content,
    };

    return <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />;
  }
}

export default EmployerEditorTemplates;
