import React from 'react';

import styles from './Candidates.scss';

const Candidates = () => {
  return (
    <div className={styles.Candidates}>
      <div className={styles.Container}>
        <div className={styles.Column1}>
          <h3 className={styles.SecondaryTitle}>Candidates who fit the role</h3>
          <div className={styles.Description}>
            {
              "Save valuable time by easily identifying the candidates who have the skills, experience and fit you're looking for."
            }
          </div>
        </div>

        <div className={styles.Column2}>
          <img
            src={require('../../assets/img_candidates_fit.png')}
            alt="Candidates who are right for the role"
            className={styles.Image}
          />
        </div>
      </div>
    </div>
  );
};

export default Candidates;
