import {
  MainContent,
  NYCContent,
  AustinContent,
  DenverContent,
  AtlantaContent,
  PhoenixContent,
  RaleighContent,
  LAContent,
  ChicagoContent,
  SanFranciscoContent,
} from './components';

export const LOCATIONS_PAGE_MAIN = 'main';
export const LOCATIONS_PAGE_NYC = 'new-york-city';
export const LOCATIONS_PAGE_AUSTIN = 'austin-tx';
export const LOCATIONS_PAGE_DENVER = 'denver-co';
export const LOCATIONS_PAGE_ATLANTA = 'atlanta-ga';
export const LOCATIONS_PAGE_PHOENIX = 'phoenix-az';
export const LOCATIONS_PAGE_RALEIGH = 'raleigh-nc';
export const LOCATIONS_PAGE_LA = 'los-angeles-ca';
export const LOCATIONS_PAGE_CHICAGO = 'chicago-il';
export const LOCATIONS_PAGE_SANFRANCISCO = 'san-francisco-ca';

export default {
  [LOCATIONS_PAGE_MAIN]: {
    title: 'Top Cities Recruiting Sales Professional',
    sideNavName: 'Markets Overview',
    h1: 'Discover a World of Sales Opportunities with Rainmakers',
    description:
      'A comprehensive analysis of the employment landscape across major US cities, identifying dominant industries, associated challenges, and the pivotal role of specialized recruitment services in bridging the gap between top talent and the flourishing sectors in these regions.',
    url: `https://${process.env.HOST}/markets/`,
    localUrl: '/markets/',
    header: 'All Markets',
    content: MainContent,
    sideNav: false,
  },
  [LOCATIONS_PAGE_ATLANTA]: {
    title: 'Find Sales Jobs In The Tech Industry in Atlanta, GA',
    sideNavName: 'Atlanta',
    h1: 'Best Tech Sales Jobs in Atlanta, GA',
    description:
      'Find the best tech sales job for you! Research salary information, job descriptions, and start connecting with Atlanta’s top tech companies',
    url: `https://${process.env.HOST}/markets/atlanta-ga/`,
    localUrl: '/markets/atlanta-ga',
    header: 'Atlanta, GA',
    content: AtlantaContent,
    sideNav: true,
  },
  [LOCATIONS_PAGE_AUSTIN]: {
    title: 'Top Tech Sales Jobs in Austin, TX',
    sideNavName: 'Austin',
    h1: 'Technology Sales Jobs in Austin',
    description:
      'Let Rainmakers help you find the perfect tech sales career in the greater Austin, Texas, area. Get salary information, view top employers, and start browsing jobs!',
    url: `https://${process.env.HOST}/markets/austin-tx/`,
    localUrl: '/markets/austin-tx',
    header: 'Austin, TX',
    content: AustinContent,
    sideNav: true,
  },
  [LOCATIONS_PAGE_CHICAGO]: {
    title: 'Tech Sales Jobs in Chicago, IL',
    sideNavName: 'Chicago',
    h1: 'Technology Sales Careers in Chicago',
    description:
      'Rainmakers connects tech sales professionals with top technology companies in the greater Chicago area. Get salary information, view job descriptions, and start browsing career opportunities now!',
    url: `https://${process.env.HOST}/markets/chicago-il/`,
    localUrl: '/markets/chicago-il',
    header: 'Chicago, IL',
    content: ChicagoContent,
    sideNav: true,
  },
  [LOCATIONS_PAGE_DENVER]: {
    title: 'Best Tech Sales Jobs in Denver, CO',
    sideNavName: 'Denver',
    h1: 'Best Technology Sales Jobs in Denver',
    description:
      'If you are searching for a new career in tech sales, or are looking for top talent to join your team, reach out to Rainmakers! Get salary information, position descriptions, and browse available opportunities',
    url: `https://${process.env.HOST}/markets/denver-co/`,
    localUrl: '/markets/denver-co',
    header: 'Denver, CO',
    content: DenverContent,
    sideNav: true,
  },
  [LOCATIONS_PAGE_LA]: {
    title: 'Best Tech Sales Jobs in Los Angeles, CA',
    sideNavName: 'Los Angeles',
    h1: 'Technology Sales Jobs in LA',
    description:
      'Get important information about LA’s top tech sales job opportunities. Learn about top employers, get salary information, and connect with recruiters',
    url: `https://${process.env.HOST}/markets/los-angeles-ca/`,
    localUrl: '/markets/los-angeles-ca',
    header: 'Los Angeles, CA',
    content: LAContent,
    sideNav: true,
  },
  [LOCATIONS_PAGE_NYC]: {
    title: 'Best Tech Sales Jobs in New York City, NY',
    sideNavName: 'NYC',
    h1: 'Tech Sales Careers in New York City',
    description:
      'New York City is one of the best markets in the country for technology companies to thrive. Learn more about tech sales jobs and top local companies actively hiring',
    url: `https://${process.env.HOST}/markets/new-york-city/`,
    localUrl: '/markets/new-york-city',
    header: 'New York City, NY',
    content: NYCContent,
    sideNav: true,
  },
  [LOCATIONS_PAGE_PHOENIX]: {
    title: 'Tech Sales Jobs in Phoenix, AZ',
    sideNavName: 'Phoenix',
    h1: 'Technology Sales Jobs in Arizona',
    description:
      'Find your next career opportunity in Phoenix, Arizona, with Rainmakers! Connect with top local companies, read job descriptions, get salary information, and find your dream job in this growing tech hub!',
    url: `https://${process.env.HOST}/markets/phoenix-az/`,
    localUrl: '/markets/phoenix-az',
    header: 'Phoenix, AZ',
    content: PhoenixContent,
    sideNav: true,
  },
  [LOCATIONS_PAGE_RALEIGH]: {
    title: 'Find Tech Sales Job in Raleigh, North Carolina',
    sideNavName: 'Raleigh',
    h1: 'Technology Sales Jobs in Raleigh, North Carolina',
    description:
      'Start connecting with the top tech companies in Raleigh, Durham, Cary, or Research Triangle Park. Browse job descriptions and get salary information',
    url: `https://${process.env.HOST}/markets/raleigh-nc/`,
    localUrl: '/markets/raleigh-nc',
    header: 'Raleigh, NC',
    content: RaleighContent,
    sideNav: true,
  },
  [LOCATIONS_PAGE_SANFRANCISCO]: {
    title: 'Tech Sales Jobs in San Francisco, CA',
    sideNavName: 'San Francisco',
    h1: 'Technology Sales Careers in San Francisco',
    description:
      'Learn about the most desired sales jobs in tech in the greater San Francisco Bay area. Browse current openings, connect with top local employers, get salary information, and find your new dream job',
    url: `https://${process.env.HOST}/markets/san-francisco-ca/`,
    localUrl: '/markets/san-francisco-ca',
    header: 'San Francisco, CA',
    content: SanFranciscoContent,
    sideNav: true,
  },
};
