import React from 'react';
import classnames from 'classnames';
import { v4 } from 'uuid';

import styles from './InputRadio.scss';

const InputRadio = ({
  name,
  handleInputChange,
  value,
  disabled,
  className,
  containerClassName,
  options,
  autoFocus,
  highlight,
}) => {
  const inputClasses = classnames(styles.inputStyle);

  const labelClasses = classnames(styles.labelStyle, {
    [styles.disabled]: disabled,
  });

  const inputs = options.map((opt, idx) => {
    const mainClasses = classnames(
      styles.InputRadio,
      {
        [styles.InputRadioFirst]: idx === 0,
      },
      className
    );

    const uniqueId = v4();
    const labelProps = {
      className: labelClasses,
    };
    const inputProps = {
      name,
      type: 'radio',
      checked: value === opt.value,
      value: opt.value,
      disabled,
      className: inputClasses,
      id: uniqueId,
      onChange: () =>
        handleInputChange({
          target: {
            name,
            value: opt.value,
          },
        }),
      autoFocus,
    };

    return (
      <div key={`${opt.label}_${opt.value}`} className={mainClasses}>
        <input {...inputProps} />
        <label htmlFor={uniqueId} {...labelProps}>
          {opt.label}
        </label>
      </div>
    );
  });

  const containerClasses = classnames(
    styles.inputContainer,
    {
      [styles.focus]: highlight,
    },
    containerClassName
  );

  return <div className={containerClasses}>{inputs}</div>;
};

export default InputRadio;
