import React from 'react';

import Layout from '../../components/Layout';
import NewPassword from '../../connectors/NewPassword';

const NewPasswordPage = (props) => (
  <Layout>
    <NewPassword {...props} />
  </Layout>
);

export default NewPasswordPage;
