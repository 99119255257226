import { createSelector } from 'reselect';
import { getApp } from 'shared/store/app/selectors';

const selectOnboardingData = createSelector(getApp, (state = {}) => {
  const { onboardingData = {} } = state.onboarding || {};

  return onboardingData;
});

export default selectOnboardingData;
