/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import pic1 from './assets/1-mid-market-account-executive-job-information.png';
import pic2 from './assets/2-contact-rainmakers-recruiters-for-account-executives.png';
import pic3 from './assets/3-best-mid-market-sales-jobs.png';
import pic4 from './assets/4-mid-market-account-executive-salary.png';
import pic5 from './assets/5-tech-sales-careers.png';
import pic6 from './assets/6-tech-sales-jobs.png';
import pic7 from './assets/7-tech-sales-recruiters.png';
import pic8 from './assets/8-create-profile-to-browse-mid-market-account-executive-jobs.png';

import styles from './MMAEJobsContent.scss';

export function MMAEJobsContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt="" />

      <h2>Mid-Market Account Executive Jobs</h2>

      <p>
        Becoming a Mid-Market Account Executive is an excellent opportunity for committed and
        enthusiastic sales professionals to take their sales career to the next level.
      </p>

      <p>
        In addition to facilitating sales, closing deals, and maintaining positive-growth client
        relations, they are also expected to provide training and encourage sales growth via
        SaaS/cloud technology and service delivery to the entire sales department. Their primary
        target for sales development is among middle-market companies.
      </p>

      <h2>What Does Mid-Market Mean?</h2>

      <p>
        Typically, the business world categorizes companies into three common categories: small,
        middle-market, and enterprise. A middle-market business is generally defined as one whose
        yearly revenue falls between $10 million and $1 billion.
      </p>

      <p>
        These categories can be further subdivided. In the case of middle-markets, you can find
        lower middle-market companies that rake in approximately $5 million to $50 million annually,
        and upper middle-market businesses, which usually bring in between $500 million to $1
        billion annually.
      </p>

      <p>
        Most Mid-Market Account Executives prefer not to restrict themselves to narrow subcategories
        but, instead, target the whole range of mid-market sales opportunities.
      </p>

      <Link to="/apply-now" title="Apply Now">
        <img src={pic2} alt="" />
      </Link>

      <h2>Qualities Needed To Be an Effective Mid-Market Account Executive</h2>

      <p>
        The position of Mid-Market Account Executive comes with a significant amount of
        responsibility. The ideal candidate should possess the following qualities:
      </p>

      <ul>
        <li>
          Demonstrate ambition, authenticity, resilience, and the ability to adapt to situations as
          needed.
        </li>
        <li>A relentless focus on maintaining and enhancing positive customer experiences.</li>
        <li>A demonstrated knowledge and enthusiasm for their company’s product or service.</li>
        <li>
          Extensive experience in sales that leans heavily on building relationships with clients.
        </li>
        <li>
          Consistent annual earnings that demonstrate past success as an Account Executive managing
          mid-market industries.
        </li>
        <li>
          A proven top sales performer with a reputation for delivering customer
          satisfaction—preferably with mid-market sectors.
        </li>
        <li>
          Ability and willingness to travel to visit customers either on-site or at industry-related
          events.
        </li>
      </ul>

      <img src={pic3} alt="" />

      <h2>Mid-Market Account Executive Responsibilities</h2>

      <p>
        The role of a Mid-Market Account Executive covers a wide range of duties beyond general
        sales prospecting and development.
      </p>

      <p>Responsibilities for this position can include:</p>

      <ul>
        <li>Manage relationships with existing customers.</li>
        <li>Negotiate and close new business opportunities.</li>
        <li>Engage in upselling and cross-selling with existing accounts.</li>
        <li>
          Search and prospect new clients by phone and email using websites and advertiser databases
          for leads.
        </li>
        <li>Perpetuate activity that results in high-volume revenue.</li>
        <li>
          Work with Account Management and Sales teams to bolster revenue and customer satisfaction.
        </li>
        <li>
          Cooperate with various teams, including Account Management, Finance, Marketing, and Legal,
          Executives, as needed to advance and close deals.
        </li>
        <li>
          Create and deliver presentations, product demonstrations, and value-based solution
          offerings.
        </li>
        <li>Adapt sales presentation content to target audiences.</li>
        <li>
          Engage with clients over the phone, through virtual video meetings, or in face-to-face
          meetings as needed.
        </li>
        <li>
          Identify key regional mid-market prospects. Build and manage sales pipelines in those
          areas.
        </li>
        <li>
          Identify target company sizes and engage in prospecting activities, cold calling, and
          nurturing new business relationships.
        </li>
        <li>
          Using a consultative approach, provide strategic advice to help prospects get the most out
          of a company’s products or services.
        </li>
        <li>Maintain a professional and positive relationship with colleagues and stakeholders.</li>
      </ul>

      <img src={pic4} alt="" />

      <h2>How Much Can a Mid-Market Account Executive Earn?</h2>

      <p>
        Unsurprisingly, a Mid-Market Account Executive earns considerably more than an SMB Account
        Executive. Of course, they also have markedly more responsibilities. As with the Account
        Executive position, a Mid-Market Account Executive’s earnings combine a base salary with a
        sales commission. In some situations, the position may also come with a signing bonus.
      </p>

      <p>
        On average, a Mid-Market Account Executive in the United States earns about $160,000 in
        compensation annually. The amount is roughly split between base salary and commissions, with
        both amounts increasing with experience and success. Mid-Market Account Executives also
        sometimes receive bonuses for exceeding performance expectations.
      </p>

      <h2>Top Paying Companies Hiring Mid-Market Account Executives</h2>

      <p>
        By total average compensation, the top 20 companies hiring Mid-Market Account Executives in
        2022 are:
      </p>

      <ol>
        <li>Workday ($261K)</li>
        <li>Paycom ($266K)</li>
        <li>Google Cloud ($252K)</li>
        <li>Coupa ($249K)</li>
        <li>Workiva ($233K)</li>
        <li>Segment ($230K)</li>
        <li>ServiceNow ($223K)</li>
        <li>Braze ($223K)</li>
        <li>Pendo ($220K)</li>
        <li>Cloudera ($220K)</li>
        <li>Aventri ($220K)</li>
        <li>DocuSign ($218K)</li>
        <li>Plaid ($217K)</li>
        <li>Zoom ($216K)</li>
        <li>Financial Force ($215K)</li>
        <li>Box ($214K)</li>
        <li>Twilio ($213K)</li>
        <li>Human Interest ($209K)</li>
        <li>SHI ($208K)</li>
        <li>Slack ($207K)</li>
      </ol>

      <p>
        Other top companies include such familiar names as Amazon Web Services ($205K), FIS ($199K),
        Autodesk ($198K), GitHub ($195K), and Microsoft ($193K).
      </p>

      <p>
        Note that these companies are not themselves considered mid-market; some of these companies
        are among the most successful in the world. They all, however, work with and offer solutions
        beneficial to mid-market companies across the globe.
      </p>

      <img src={pic5} alt="" />

      <h2>Why Target Mid-Market Companies?</h2>

      <p>It’s more than simply a matter of preference.</p>

      <p>
        While it’s true that inking deals with large businesses pulling in billions of dollars a
        year in revenue can add to your business’s reputation, a company’s resources run the risk of
        becoming overwhelmed in dealing with too large a client. Along those lines, if a business
        puts most of its resources into one major client company, it runs the risk of getting into
        severe financial trouble should that account suddenly disappear.
      </p>

      <p>That’s why, for many businesses, an ideal target is the mid-market.</p>

      <p>
        These companies are big enough to offer considerable revenue but small enough that you can
        support multiple accounts without spreading yourself too thin.
      </p>

      <p>
        In addition, mid-market companies are better prepared to invest in growth strategies that go
        beyond short-term sales. They tend to understand that success requires combining excellent
        products or services with superior customer experiences. As their account executive, they
        are looking to you to help them with those goals.
      </p>

      <img src={pic6} alt="" />

      <h3>Defining the Middle Market</h3>

      <p>
        The middle market is the business sector with annual earnings usually between $10 million
        (low middle market) and $1 billion or more (high middle market). At current estimates,
        approximately 200,000 middle-market businesses are operating in the United States alone. The
        majority of these companies are privately held or closely held, and their combined annual
        revenues exceed $10 trillion.
      </p>

      <h3>An Alternative Definition</h3>

      <p>
        You could also identify some companies as mid-market by the number of employees they have,
        Companies with 100 to 500 employees would be considered to be in the lower mid-market.
        Companies in the upper mid-market would have between 500 and 2,500 employees. By looking at
        employee numbers instead of revenue alone, a Mid-Market Account Executive can study customer
        segmentation, sales attribution, and demand generation to help determine the best approaches
        and solutions when engaging with a prospect.
      </p>

      <p>
        Outside the mid-market definition, you could view small-to-medium sized businesses (SMBs) as
        organizations with fewer than 100 employees, while more than 2,500 people would make that
        business an enterprise.
      </p>

      <img src={pic7} alt="" />

      <h3>Looking Beyond the Numbers</h3>

      <p>When examining a mid-market prospect, another facet to consider is attitude.</p>

      <p>
        Does the prospective client see themselves as a small or large player in the field? Do they
        seem themselves as, say, no longer small but also not yet big?
      </p>

      <p>
        Just about any company you can think of has gone through growing pains and experienced
        plateaus—those moments where they seem to have reached their limit but, in truth, have far
        greater potential than they realize.
      </p>

      <p>
        As a Mid-Market Account Executive, it’s up to you to help these businesses realize their
        full potential and to provide solutions. How can your product or service help this business
        grow in ways they may not have considered? What’s holding them back, and how can you address
        that?
      </p>

      <p>
        That’s why <i>creativity</i> is another essential skill any successful Mid-Market Account
        Executive must nurture. Through careful study of the prospect, the market, and the product,
        an effective executive finds ways to help an opportunity succeed.
      </p>

      <Link to="/apply-now" title="Apply Now">
        <img src={pic8} alt="" />
      </Link>

      <h2>The Next Step of a Successful Sales Career</h2>

      <p>Mid-market sales development can be seen as the very definition of win-win.</p>

      <p>
        As a market segment, it’s ideal for many businesses looking to increase sales with growing
        companies without taking too many risks or spreading resources too thin. It also presents
        unique challenges to the Mid-Market Account Executive by offering them opportunities to hone
        their sales skills, creativity, and inter-departmental managing abilities.
      </p>

      <p>
        As a Mid-Market Account Executive, you’re perfectly positioned to aid in every stage of the
        deal, from lead generation to customer care and retention.
      </p>

      <p>
        By cultivating the right skills and putting in the work required, there’s no reason you
        couldn’t benefit—both personally and financially—from a successful career as a Mid-Market
        Account Executive.
      </p>

      <p>
        Whether you are a company looking to{' '}
        <a href={`https://${process.env.HOST}/employers`}>
          hire a new Mid-Market Account Executive
        </a>
        , or looking for <a href={`https://${process.env.HOST}/apply-now/`}>AE jobs near you</a>,
        our team is here to help! Contact us now to get started.
      </p>
    </div>
  );
}
