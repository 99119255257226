import { createSelector } from 'reselect';
import { getApp } from 'shared/store/app/selectors';

const selectSearch = createSelector(getApp, (state) => state.employerSearch);

const selectRequestedCandidates = createSelector(
  selectSearch,
  (search = {}) => search.requestedCandidates
);

export default selectRequestedCandidates;
