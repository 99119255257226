import React from 'react';
import Slider from 'react-rangeslider';

import Input from 'components/Input';
import Label from 'components/Label';

import { toCents, toDollars } from 'utils/moneyUtils';

import styles from '../ReqRequirement.scss';

const DealsAverage = ({
  disabled,
  handleInputChange,
  requisitionRequirement: {
    attributes: { dealsAverageMaxCents, dealsAverageMinCents, dealsAverageWt } = {},
  } = {},
}) => {
  const commonTextInputProps = {
    type: 'text',
    size: 'large',
    disabled,
    handleInputChange: (event) =>
      handleInputChange({
        ...event,
        target: {
          ...event.target,
          value: toCents(event.target.value),
          name: event.target.name,
        },
      }),
  };

  const dealsAverageMinCentsInputProps = {
    ...commonTextInputProps,
    size: 'full',
    value:
      dealsAverageMinCents || dealsAverageMinCents === 0 ? toDollars(dealsAverageMinCents) : '',
    name: 'dealsAverageMinCents',
    moneyInput: true,
    salaryInputOne: true,
  };

  const dealsAverageMaxCentsInputProps = {
    ...commonTextInputProps,
    size: 'full',
    value:
      dealsAverageMaxCents || dealsAverageMaxCents === 0 ? toDollars(dealsAverageMaxCents) : '',
    name: 'dealsAverageMaxCents',
    moneyInput: true,
    salaryInputTwo: true,
  };

  const dealsAverageWtProps = {
    disabled,
    className: styles.rangeSlider,
    min: 0,
    max: 10,
    step: 5,
    value: dealsAverageWt || 0,
    onChange: disabled
      ? () => null
      : (value) =>
          handleInputChange({
            target: {
              value,
              name: 'dealsAverageWt',
            },
          }),
    tooltip: false,
    labels: {
      0: '0',
      5: '5',
      10: '10',
    },
  };

  return (
    <div className={styles.rangeInputs}>
      <div className={styles.column}>
        <Label className={styles.labelStyle}>Ideal Deal Size</Label>
        <div className={styles.rangeInputsContainer}>
          <Input {...dealsAverageMinCentsInputProps} />
          <div className={styles.rangeSpan}>to</div>
          <Input {...dealsAverageMaxCentsInputProps} />
        </div>
      </div>
      <div className={styles.column}>
        <Label className={styles.labelStyle}>How much do you value this?</Label>
        <Slider {...dealsAverageWtProps} />
      </div>
    </div>
  );
};

export default DealsAverage;
