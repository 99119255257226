import React from 'react';

import Button from 'components/Button';
import DatePicker from 'components/DatePicker';
import DropdownContent from 'components/DropdownContent';
import DropdownSelectOne from 'components/DropdownSelectOne';
import FontIcon from 'components/FontIcon';
import FormattedDate from 'components/FormattedDate';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import InputCheckbox from 'components/InputCheckbox';
import Table from 'components/Table';

import SearchResult from 'pages/EmployeePortal/CandidateSearchPage/components/SearchResult';

import styles from '../../../../ToolboxRequisitionEditor.scss';

const SuggestionsTable = ({
  applicationStatusUpdatedAt,
  candidates,
  cohorts = [],
  cohortId,
  currentPage,
  handleSetSimulatedEmployerId,
  handleSetDate,
  handleToggleArray,
  isLoading,
  loadMoreSuggestions,
  openProfileModal,
  simulatedEmployerId,
  requisitionOpeningId,
  totalCount,
}) => {
  const tableContent = candidates.map((candidate, idx) => {
    const props = {
      searchString: `filter[requisition_opening_id]=${requisitionOpeningId}`,
      candidate: {
        ...candidate.attributes,
      },
      handleOnClick: openProfileModal,
      isAdmin: true,
      index: idx - (currentPage - 1) * 20,
      requisitionOpeningId,
    };

    return <SearchResult key={candidate.id} {...props} />;
  });

  const infiniteScrollProps = {
    pageStart: 0,
    loadMore: loadMoreSuggestions,
    hasMore: candidates.length > 0 && totalCount > candidates.length,
    loader: <InfiniteScrollLoader key={0} />,
    useWindow: false,
    className: styles.Table,
  };

  const currentCohortProps = {
    name: 'simulatedEmployerId',
    label: 'Current Cohort Only',
    checked: Boolean(simulatedEmployerId),
    handleInputChange: handleSetSimulatedEmployerId,
    className: styles.checkbox,
    checkboxMany: true,
  };

  const cohortsSource = cohorts.map((co) => ({
    indent: 0,
    label: co.name,
    optionType: 'cohortId',
    subCategories: [],
    value: {
      type: 'cohortId',
      cohortId: co.id,
    },
  }));

  const cohortsInputProps = {
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    source: cohortsSource,
    name: 'filter[cohort_id]',
    key: 'cohortIds',
    optionsLarge: true,
    onChange: ({ filter, value }) => handleToggleArray({ name: filter, value }),
    selectedValues: cohortId ? [{ type: 'cohortId', cohortId }] : [],
    defaultLabel: 'Click to select cohorts',
  };

  const cohortSelector =
    cohorts && cohorts.length ? <DropdownSelectOne {...cohortsInputProps} /> : null;

  const applicationStatusUpdatedAtProps = {
    onDayClick: handleSetDate,
    selectedDays: [applicationStatusUpdatedAt],
  };

  const applicationStatusUpdatedAtContent = <DatePicker {...applicationStatusUpdatedAtProps} />;

  const applicationStatusUpdatedAtDropdownProps = {
    label: applicationStatusUpdatedAt ? (
      <FormattedDate date={applicationStatusUpdatedAt} />
    ) : (
      'Approved Since'
    ),
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    dropRight: true,
  };

  const clearDatesProps = {
    onClick: () => handleSetDate(null),
    quaternary: true,
    className: styles.cancelButton,
  };

  const clearDateButton = applicationStatusUpdatedAt ? (
    <Button {...clearDatesProps}>
      <FontIcon iconName="close" />
    </Button>
  ) : null;

  const titleContent = (
    <div className={styles.searchBar}>
      {cohortSelector}
      <div className={styles.searchDate}>
        <DropdownContent {...applicationStatusUpdatedAtDropdownProps}>
          {applicationStatusUpdatedAtContent}
        </DropdownContent>
        {clearDateButton}
      </div>
      <InputCheckbox {...currentCohortProps} />
    </div>
  );

  const tableProps = {
    titleContent,
    tableContent,
    header: null,
    infiniteScrollProps,
    tableName: 'adminRequisitionSuggestions',
    isEmpty: !isLoading && candidates.length === 0,
    emptyTextString: 'No Suggestions',
    isLoading,
  };

  return <Table {...tableProps} />;
};

export default SuggestionsTable;
