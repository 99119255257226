import { all, fork } from 'redux-saga/effects';

import sagaProfileCardsLoadStarted from './sagaProfileCardsLoadStarted';
import sagaBatchRequestLoadStarted from './sagaBatchRequestLoadStarted';
import sagaBatchRequestPatchStarted from './sagaBatchRequestPatchStarted';
import sagaBatchRequestRerequestStarted from './sagaBatchRequestRerequestStarted';

const sagaCandidateHome = function* sagaCandidateHome() {
  yield all([
    fork(sagaProfileCardsLoadStarted),
    fork(sagaBatchRequestLoadStarted),
    fork(sagaBatchRequestPatchStarted),
    fork(sagaBatchRequestRerequestStarted),
  ]);
};

export default sagaCandidateHome;
