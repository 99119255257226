import React, { Fragment } from 'react';

import EmployerData from 'components/EmployerData';
import FormattedDate from 'components/FormattedDate';
import TableCell from 'components/Table/components/TableCell';
import FontIcon from 'components/FontIcon';

import ToolboxEmployersActionDropdown from '../ToolboxEmployersActionDropdown';

import styles from '../ToolboxEmployersTable/ToolboxEmployersTable.scss';

const ToolboxEmployersRow = ({
  employer: {
    attributes: employer,
    attributes: {
      applicationStatusUpdatedAt,
      applicationStatus,
      createdAt,
      creatorType,
      location,
      saas,
    } = {},
    id,
  } = {},
  patchResource,
  showModal,
}) => {
  const employerDataProps = {
    onClick: () =>
      showModal({
        key: 'EmployerEditorModal',
        parent: 'ToolboxEmployersPage',
        route: `/toolbox/employer/${id}/edit-profile`,
      }),
    employer,
    applicationStatus,
    verified: applicationStatus === 'approved',
  };

  const employerDataContent = (
    <div className={styles.employerContent}>
      <EmployerData {...employerDataProps} />
    </div>
  );

  const actionsContent = ['pending', 'approved'].includes(applicationStatus) ? (
    <div className={styles.actionsContent}>
      <ToolboxEmployersActionDropdown id={id} patchResource={patchResource} employer={employer} />
    </div>
  ) : (
    <div className={styles.actionsContent} />
  );

  const locationContent = <div className={styles.locationContent}>{location}</div>;

  const saasContent = (
    <div className={styles.saasContent}>{saas ? <FontIcon iconName="checkmark-circle" /> : ''}</div>
  );

  const creatorContent = <div className={styles.creatorContent}>{creatorType}</div>;

  const employerUpdatedContent = applicationStatusUpdatedAt ? (
    <div className={styles.updatedAtContent}>
      <FormattedDate format="M/D/YY" parse date={applicationStatusUpdatedAt} />
    </div>
  ) : (
    <div className={styles.updatedAtContent}>-</div>
  );

  const createdAtContent = (
    <div className={styles.lastActivityContent}>
      <FormattedDate format="M/D/YY" date={createdAt} parse />
    </div>
  );

  const cells = (
    <Fragment>
      <TableCell key={1}>{employerDataContent}</TableCell>
      <TableCell key={2}>{locationContent}</TableCell>
      <TableCell key={3}>{saasContent}</TableCell>
      <TableCell key={4}>{createdAtContent}</TableCell>
      <TableCell key={5}>{employerUpdatedContent}</TableCell>
      <TableCell key={6}>{creatorContent}</TableCell>
      <TableCell noFlex key={7}>
        {actionsContent}
      </TableCell>
    </Fragment>
  );

  return cells;
};

export default ToolboxEmployersRow;
