import React from 'react';

import ProgressNav from 'components/ProgressNav';

import styles from './ProgressNavSection.scss';

const ProgressNavSection = ({ numFilled }) => {
  const segments = ['Apply', 'Set Requirements', 'Create Profile', 'Add Sales Info', 'Add Video'];

  const progressNavProps = {
    segments,
    numFilled,
  };

  return (
    <div className={styles.progressBarContainer} data-testid="ProgressNavSection">
      <ProgressNav {...progressNavProps} />
    </div>
  );
};

export default ProgressNavSection;
