import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch, useParams } from 'react-router-dom';

import { AdminAuthHOC, ModalHOC } from 'containers/HOC';
import ConnectContainer from 'containers/ConnectContainer';

import ReadyContainer from 'connectors/ReadyContainer';
import Sidebar from 'components/Sidebar';

import duck from 'pages/AdminPortal/ToolboxCohortsPage/redux';

import { usePrevious } from 'hooks';

import CohortEditorMain from './components/CohortEditorMain';
import CohortEditorCandidates from './components/CohortEditorCandidates';
import CohortEditorEmployers from './components/CohortEditorEmployers';

const ToolboxCohortEditor = (props) => {
  const params = useParams();
  const { id, view } = params;
  const prevId = usePrevious(id);

  useEffect(() => {
    const { cohort: { attributes: { temporary = false } = {} } = {} } = props;

    if (!temporary) {
      doLoad(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const nextId = id;
    const currId = prevId;

    if (nextId !== currId) {
      doLoad(nextId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const doLoad = (id) => {
    const {
      actions: { entityLoadStarted, nestedEntityLoadStarted },
    } = props;

    entityLoadStarted({
      type: 'cohorts',
      slice: 'toolboxCohorts',
      id,
      updateOnly: true,
    });

    nestedEntityLoadStarted({
      slice: 'toolboxCohorts',
      type: 'cohorts',
      id,
      nestedType: 'cohort_candidates',
      params: {
        page: 1,
        includeSet: 'candidate',
        sortBy: 'candidate_first_name_asc,candidate_last_name_asc',
      },
    });

    nestedEntityLoadStarted({
      slice: 'toolboxCohorts',
      type: 'cohorts',
      id,
      nestedType: 'cohort_employers',
      params: {
        page: 1,
        includeSet: 'employer',
        sortBy: 'employer_name_asc',
      },
    });
  };

  const loadMoreCohortCandidates = () => {
    const {
      actions: { nestedEntityLoadStarted },
      cohortCandidatesPage: page,
      cohort: { id },
    } = props;

    nestedEntityLoadStarted({
      slice: 'toolboxCohorts',
      type: 'cohorts',
      id,
      nestedType: 'cohort_candidates',
      params: {
        page: page + 1,
        includeSet: 'candidate',
        sortBy: 'candidate_first_name_asc,candidate_last_name_asc',
      },
    });
  };

  const loadMoreCohortEmployers = () => {
    const {
      actions: { nestedEntityLoadStarted },
      cohortEmployersPage: page,
      cohort: { id },
    } = props;

    nestedEntityLoadStarted({
      slice: 'toolboxCohorts',
      type: 'cohorts',
      id,
      nestedType: 'cohort_employers',
      params: {
        page: page + 1,
        includeSet: 'employer',
        sortBy: 'employer_name_asc',
      },
    });
  };

  const handleCohortEmailInputChange = (event, existingValue) => {
    const {
      target: { value, name },
    } = event;

    const {
      actions: { patchResource },
      cohort: { id },
    } = props;

    const returnHash = { emailCategoryEnabled: { ...existingValue, [name]: value } };

    patchResource({
      type: 'cohorts',
      slice: 'toolboxCohorts',
      attributes: returnHash,
      requiredFields: ['name', 'startDate', 'endDate'],
      id,
    });
  };

  const handleCohortInputChange = (event) => {
    const {
      target: { value, name },
    } = event;

    const {
      actions: { patchResource },
      cohort: { id },
    } = props;

    patchResource({
      type: 'cohorts',
      slice: 'toolboxCohorts',
      attributes: {
        [name]: value,
      },
      requiredFields: ['name', 'startDate', 'endDate'],
      id,
    });
  };

  const handleCohortCandidateAdd = ({
    target: {
      value: { id: candidateId },
    },
  }) => {
    const {
      actions: { patchNestedResource },
      cohort: { id },
      cohortCandidates: { allIds = [], byId = {} } = {},
    } = props;

    if (!allIds.some((id) => byId[id].attributes.candidateId === candidateId)) {
      patchNestedResource({
        id,
        type: 'cohorts',
        nestedType: 'cohort_candidates',
        attributes: {
          candidateId,
        },
        requiredFields: ['candidateId'],
        slice: 'toolboxCohorts',
      });
    }
  };

  const handleCohortCandidateRemove = ({ cohortCandidateId }) => {
    const {
      actions: { deleteResource },
    } = props;

    deleteResource({
      type: 'cohort_candidates',
      id: cohortCandidateId,
      slice: 'toolboxCohorts',
    });
  };

  const handleCohortEmployerRemove = ({ cohortEmployerId }) => {
    const {
      actions: { deleteResource },
    } = props;

    deleteResource({
      type: 'cohort_employers',
      id: cohortEmployerId,
      slice: 'toolboxCohorts',
    });
  };

  const handleCohortEmployerToggle = ({ event, cohortEmployerId }) => {
    const {
      target: { name, checked },
    } = event;
    const {
      actions: { patchResource },
    } = props;

    if (name === 'toggle_enable') {
      patchResource({
        slice: 'toolboxCohorts',
        type: 'cohort_employers',
        id: cohortEmployerId,
        attributes: {
          state: checked ? 'enabled' : 'blocked',
        },
      });
    }

    if (name === 'toggle_disable') {
      patchResource({
        slice: 'toolboxCohorts',
        type: 'cohort_employers',
        id: cohortEmployerId,
        attributes: {
          state: checked ? 'blocked' : 'enabled',
        },
      });
    }
  };

  const handleSetAllEmployersToggle = ({ type }) => {
    const {
      actions: { patchResource },
      cohort: { id } = {},
    } = props;

    patchResource({
      slice: 'toolboxCohorts',
      type: 'cohorts',
      id,
      attributes: {
        cohortEmployer: {
          state: type,
        },
      },
      requiredFields: [],
    });
  };

  const { cohort: { attributes: { temporary } = {} } = {} } = props;

  const newProps = {
    ...props,
    handleCohortInputChange,
    handleCohortEmailInputChange,
    handleCohortCandidateAdd,
    handleCohortCandidateRemove,
    loadMoreCohortCandidates,
    loadMoreCohortEmployers,
    handleCohortEmployerRemove,
    handleCohortEmployerToggle,
    handleSetAllEmployersToggle,
  };

  const sidebarProps = {
    type: 'toolboxCohortEditor',
    page: view,
    id: temporary ? null : id,
  };

  return (
    <React.Fragment>
      <Helmet key="helmet" title="Cohort Editor" />
      <ReadyContainer key="readyContainer">
        <Sidebar {...sidebarProps} />

        <Switch>
          <Route exact path={'/toolbox/cohort/:id/edit-main'}>
            <CohortEditorMain {...newProps} />
          </Route>
          <Route exact path={'/toolbox/cohort/:id/edit-candidates'}>
            <CohortEditorCandidates {...newProps} />
          </Route>
          <Route exact path={'/toolbox/cohort/:id/edit-employers'}>
            <CohortEditorEmployers {...newProps} />
          </Route>
        </Switch>
      </ReadyContainer>
    </React.Fragment>
  );
};

export default ConnectContainer(duck)(
  AdminAuthHOC()(
    ModalHOC({
      paginatorUrl: '/toolbox/cohort/:id/edit-main',
    })(ToolboxCohortEditor)
  )
);
