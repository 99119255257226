import React, { useState, useRef, useCallback } from 'react';
import cn from 'classnames';

import { useOnClickOutside } from 'hooks';
import FontIcon from 'components/FontIcon';

import styles from './DropdownPane.scss';

const DropdownPane = ({
  element,
  label,
  defaultIcon = 'caret-down',
  activeIcon = 'caret-up',
  left,
  above,
  className,
  disabled,
  onOpenCallback,
  ...restProps
}) => {
  const [active, setActive] = useState(false);
  const ref = useRef();
  const scrollParentRef = useRef();

  useOnClickOutside(
    ref,
    useCallback(() => setActive(false), [])
  );

  const onClick = () =>
    setActive((active) => {
      if (!active && onOpenCallback) {
        onOpenCallback();
      }

      return !active;
    });

  const containerClasses = cn(
    styles.Dropdown,
    {
      [styles.active]: active,
      [styles.left]: left,
      [styles.above]: above,
      [styles.disabled]: disabled,
    },
    className
  );

  const defaultIconContent = defaultIcon ? <FontIcon iconName={defaultIcon} /> : null;
  const activeIconContent = activeIcon ? <FontIcon iconName={activeIcon} /> : defaultIconContent;

  return (
    <div className={containerClasses} onClick={disabled ? null : onClick} ref={ref} {...restProps}>
      <div className={styles.DefaultLabel} data-testid="defaultLabel">
        {label}
      </div>
      {active ? activeIconContent : defaultIconContent}
      <div
        className={styles.OptionsContainer}
        ref={scrollParentRef}
        onClick={(ev) => {
          ev.stopPropagation();
        }}
      >
        {element}
      </div>
    </div>
  );
};

export default DropdownPane;
