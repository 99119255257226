import React from 'react';

import Content from 'components/Content';
import ContentTitle from 'components/ContentTitle';
import CompanyAbout from 'pages/EmployeePortal/EmployeeEditorPages/EditPreview/components/CompanyAbout';

import styles from './AboutCompany.scss';

const AboutCompany = ({ employer: { attributes: { about } = {} } = {} }) => {
  const aboutProps = { about };

  return about ? (
    <div className={styles.AboutCompany}>
      <ContentTitle>ABOUT THE COMPANY</ContentTitle>
      <Content className={styles.AboutContent}>
        <CompanyAbout {...aboutProps} />
      </Content>
    </div>
  ) : null;
};

export default AboutCompany;
