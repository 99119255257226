import { handleActions } from 'redux-actions';

import {
  LOGIN_STARTED,
  LOGIN_DONE,
  LOGIN_ERROR,
  LOGIN_ERROR_RESET,
  LOGIN_NOTIFICATION,
  LOGOUT_STARTED,
  LOGOUT_DONE,
} from 'shared/store/app/actions';

const initialState = {
  loginNotification: '',
  loginErrors: '',
  loginPending: null,
  logoutPending: false,
  loggedOutPath: '',
};

const handleLoginStarted = (state = initialState) => ({
  ...state,
  loginPending: true,
});

const handleLoginDone = (state = initialState) => ({
  ...state,
  loginPending: false,
});

const handleLogoutStarted = (state = initialState) => ({
  ...state,
  logoutPending: true,
});

const handleLogoutDone = (state = initialState) => ({
  ...state,
  logoutPending: false,
});

const handleLoginNotification = (state = initialState, action) => {
  const {
    payload: { notification },
  } = action;

  return {
    ...state,
    loginNotification: notification,
  };
};

const handleLoginError = (state = initialState, action) => {
  const {
    payload: { errors },
  } = action;

  console.log('login error', errors);

  return {
    ...state,
    loginErrors: errors,
    loginPending: false,
  };
};

const handleLoginErrorReset = (state = initialState) => ({
  ...state,
  loginErrors: '',
});

const actionHandlers = {
  [LOGIN_DONE]: handleLoginDone,
  [LOGIN_NOTIFICATION]: handleLoginNotification,
  [LOGIN_STARTED]: handleLoginStarted,
  [LOGIN_ERROR]: handleLoginError,
  [LOGIN_ERROR_RESET]: handleLoginErrorReset,
  [LOGOUT_STARTED]: handleLogoutStarted,
  [LOGOUT_DONE]: handleLogoutDone,
};

const reduceLogin = handleActions(actionHandlers, initialState);

export default reduceLogin;
