import { call, put, take } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { promisePatchTemplate } from '../../promises';

import { TEMPLATE_UPDATE_STARTED } from '../actions';

import { templateUpdateDone } from '../creators';

const sagaTemplateUpdateStarted = function* sagaTemplateUpdateStarted() {
  while (true) {
    const { payload } = yield take(TEMPLATE_UPDATE_STARTED);

    try {
      const {
        data: { message_template: template },
      } = yield call(promisePatchTemplate, payload);

      yield put(templateUpdateDone({ template }));
    } catch (error) {
      handleError(error);
    }
  }
};

export default sagaTemplateUpdateStarted;
