import {
  NYCSDRContent,
  SFAEContent,
  SFSDRContent,
  ChicagoSDRContent,
  NYCAEContent,
  ChicagoAEContent,
} from './components';

export const MK_JB_SDR_NYC_PAGE = 'new-york-city/sales-development-representative';
export const MK_JB_AE_SF_PAGE = 'san-francisco/account-executive';
export const MK_JB_SDR_SF_PAGE = 'san-francisco/sales-development-representative';
export const MK_JB_SDR_CHI_PAGE = 'chicago/sales-development-representative';
export const MK_JB_AE_NYC_PAGE = 'new-york-city/account-executive';
export const MK_JB_AE_CHI_PAGE = 'chicago/account-executive';

export default {
  [MK_JB_AE_CHI_PAGE]: {
    title: 'Account Executive (AE) Jobs in Chicago, IL',
    sideNavName: 'Account Executive in Chicago',
    description:
      'Find Account Executive jobs in Chicago, Illinois. Browse opportunities, get salary information, and learn about daily responsibilities and tasks.',
    url: `https://${process.env.HOST}/markets/chicago/account-executive`,
    localUrl: '/markets/chicago/account-executive/',
    header: 'Career Opportunities for AE’s in Chicago',
    content: ChicagoAEContent,
    sideNav: true,
  },
  [MK_JB_AE_NYC_PAGE]: {
    title: 'Pursue Your Dream Career as an Account Executive in New York City',
    sideNavName: 'Account Executive in NYC',
    description:
      'Discover the abundance of tech sales opportunities available in the city that never sleeps. Build your career with Rainmakers and become a part of the tech sales elite in NYC.',
    url: `https://${process.env.HOST}/markets/new-york-city/account-executive`,
    localUrl: '/markets/new-york-city/account-executive/',
    header: 'Unleash Your Sales Potential in New York City',
    content: NYCAEContent,
    sideNav: true,
  },
  [MK_JB_AE_SF_PAGE]: {
    title: 'Find Your Next Account Executive Job in San Francisco',
    sideNavName: 'Account Executive in San Francisco',
    description:
      "Learn about the responsibilities and key skills required to become a successful Account Executive in San Francisco's flourishing tech sector.",
    url: `https://${process.env.HOST}/markets/san-francisco/account-executive`,
    localUrl: '/markets/san-francisco/account-executive/',
    header: 'Join the Tech Sales Community in San Francisco',
    content: SFAEContent,
    sideNav: true,
  },
  [MK_JB_SDR_CHI_PAGE]: {
    title: 'Sales Development Representative Jobs in Chicago',
    sideNavName: 'Sales Development Rep in Chicago',
    description:
      "Explore the limitless opportunities for growth and success as a Sales Development Representative in one of America's premier cities. Join the vibrant tech industry in Chicago today.",
    url: `https://${process.env.HOST}/markets/chicago/sales-development-representative`,
    localUrl: '/markets/chicago/sales-development-representative/',
    header: 'Land Your Dream Sales Development Representative Job in Chicago',
    content: ChicagoSDRContent,
    sideNav: true,
  },
  [MK_JB_SDR_NYC_PAGE]: {
    title: 'Sales Development Representative Jobs in New York City',
    sideNavName: 'Sales Development Rep in NYC',
    description:
      'Take your sales career to the next level in the world’s tech capital. Learn about the exciting Sales Development Representative roles available in New York City.',
    url: `https://${process.env.HOST}/markets/new-york-city/sales-development-representative`,
    localUrl: '/markets/new-york-city/sales-development-representative/',
    header: 'SDR Jobs in New York',
    content: NYCSDRContent,
    sideNav: true,
  },
  [MK_JB_SDR_SF_PAGE]: {
    title: 'Embrace the Excitement of San Francisco as a Sales Development Rep',
    sideNavName: 'Sales Development Rep in San Francisco',
    description:
      'Join one of the most innovative cities in the world and experience endless opportunities as a Sales Development Representative in San Francisco, CA.',
    url: `https://${process.env.HOST}/markets/san-francisco/sales-development-representative`,
    localUrl: '/markets/san-francisco/sales-development-representative/',
    header: 'Sales Development Representative Jobs in San Francisco',
    content: SFSDRContent,
    sideNav: true,
  },
};
