import React from 'react';

import InputRichText from 'components/InputRichText';
import Label from 'components/Label';

import styles from './InputRichTextLabeled';

export default function InputRichTextLabeled({
  name,
  value,
  label,
  autoFocus,
  highlight,
  handleInputChange,
  ...rest
}) {
  return (
    <div {...rest}>
      <Label standalone className={styles.label}>
        {label}
      </Label>
      <div className={styles.workDescriptionContainer}>
        <InputRichText
          name={name}
          value={value}
          autoFocus={autoFocus}
          highlight={highlight}
          handleInputChange={handleInputChange}
        />
      </div>
    </div>
  );
}
