import { createAction } from 'redux-actions';

import { EMPLOYER_PATCH_STARTED } from 'pages/EmployeePortal/EmployeeEditorPages/redux/actions';

import {
  // APP
  ADD_REQUEST_INTERCEPTOR,
  PING_SESSION,
  SAVE_REQUEST_INTERCEPTOR,
  REMOVE_REQUEST_INTERCEPTOR,
  SET_APP_ERROR,
  SET_APP_MODAL_ERROR,
  RESET_APP_ERROR,
  WAIT_FOR_JOB,
  SET_NOTIFICATION,
  RESET_NOTIFICATION,
  // MAIN
  API_ERRORS_UPDATE,
  API_ERRORS_DELETE,
  ENTITY_LOAD_STARTED,
  ENTITY_LOAD_DONE,
  NESTED_ENTITY_LOAD_STARTED,
  DELETE_RESOURCE,
  PATCH_RESOURCE,
  PATCH_NESTED_RESOURCE,
  PATCH_USER_RESOURCE,
  PATCH_USER_EMAIL,
  POST_RESOURCE,
  RESOURCE_DELETE,
  RESOURCE_ERRORS_UPDATE,
  RESOURCE_UPDATE,
  SAVING_UPDATE,
  // LOGIN
  DIRECT_LOGIN_STARTED,
  LOGIN_STARTED,
  LOGIN_DONE,
  CANDIDATE_LOGIN_DONE,
  EMPLOYEE_LOGIN_DONE,
  ADMIN_LOGIN_DONE,
  LOGIN_ERROR,
  LOGIN_ERROR_RESET,
  LOGIN_NOTIFICATION,
  LOGOUT_STARTED,
  LOGOUT_DONE,
  LOGOUT_ERROR,
  IDENTIFY_SEGMENT_USER,
  // USER
  USER_SAVE,
  USER_REMOVE,
  USER_PATCH_DONE,
  USER_UPDATE,
  CANDIDATE_PROFILE_PATCH_DONE,
  SAVE_UTM_INFO,
  SET_BACK_LINK,
  RESET_BACK_LINK,
  SET_DELAY_PROFILE_CARDS,
  // MODAL
  SHOW_MODAL,
  HIDE_MODAL,
  NEXT_MODAL,
  SET_MODAL,
  RESET_MODAL,
  SET_CURRENT_IDX,
  RESET_CURRENT_IDX,
  // DEFAULTS
  PICKER_OPTIONS_LOAD_STARTED,
  PICKER_OPTIONS_LOAD_DONE,
  TAGS_LOAD_STARTED,
  TAGS_LOAD_DONE,
  // OTHER
  SET_IMAGE_UPLOAD_COMPANY,
} from './actions';

// APP
export const pingSession = createAction(PING_SESSION);
export const waitForJob = createAction(WAIT_FOR_JOB);
export const setAppError = createAction(SET_APP_ERROR);
export const setAppModalError = createAction(SET_APP_MODAL_ERROR);
export const resetAppError = createAction(RESET_APP_ERROR);
export const addRequestInterceptor = createAction(ADD_REQUEST_INTERCEPTOR);
export const saveRequestInterceptor = createAction(SAVE_REQUEST_INTERCEPTOR);
export const removeRequestInterceptor = createAction(REMOVE_REQUEST_INTERCEPTOR);
export const setNotification = createAction(SET_NOTIFICATION);
export const resetNotification = createAction(RESET_NOTIFICATION);

// MAIN
export const apiErrorsUpdate = createAction(API_ERRORS_UPDATE);
export const apiErrorsDelete = createAction(API_ERRORS_DELETE);

export const entityLoadStarted = createAction(ENTITY_LOAD_STARTED);
export const entityLoadDone = createAction(ENTITY_LOAD_DONE);
export const nestedEntityLoadStarted = createAction(NESTED_ENTITY_LOAD_STARTED);

export const resourceDelete = createAction(RESOURCE_DELETE);
export const resourceErrorsUpdate = createAction(RESOURCE_ERRORS_UPDATE);
export const resourceUpdate = createAction(RESOURCE_UPDATE);

export const patchUserResource = createAction(PATCH_USER_RESOURCE);
export const patchUserEmail = createAction(PATCH_USER_EMAIL);

export const patchResource = createAction(PATCH_RESOURCE);
export const patchNestedResource = createAction(PATCH_NESTED_RESOURCE);

export const postResource = createAction(POST_RESOURCE);
export const deleteResource = createAction(DELETE_RESOURCE);

export const savingUpdate = createAction(SAVING_UPDATE);

// LOGIN
export const identifySegmentUser = createAction(IDENTIFY_SEGMENT_USER);
export const directLoginStarted = createAction(DIRECT_LOGIN_STARTED);
export const loginStarted = createAction(LOGIN_STARTED);
export const loginDone = createAction(LOGIN_DONE);
export const candidateLoginDone = createAction(CANDIDATE_LOGIN_DONE);
export const adminLoginDone = createAction(ADMIN_LOGIN_DONE);
export const employeeLoginDone = createAction(EMPLOYEE_LOGIN_DONE);
export const loginError = createAction(LOGIN_ERROR);
export const loginErrorReset = createAction(LOGIN_ERROR_RESET);
export const loginNotification = createAction(LOGIN_NOTIFICATION);

export const logoutStarted = createAction(LOGOUT_STARTED);
export const logoutDone = createAction(LOGOUT_DONE);
export const logoutError = createAction(LOGOUT_ERROR);

// USER
export const userSave = createAction(USER_SAVE);
export const userRemove = createAction(USER_REMOVE);
export const userUpdate = createAction(USER_UPDATE);
export const userPatchDone = createAction(USER_PATCH_DONE);

export const candidateProfilePatchDone = createAction(CANDIDATE_PROFILE_PATCH_DONE);
export const employerPatchStarted = createAction(EMPLOYER_PATCH_STARTED);

export const saveUTMInfo = createAction(SAVE_UTM_INFO);

export const resetBackLink = createAction(RESET_BACK_LINK);
export const setBackLink = createAction(SET_BACK_LINK);

export const setDelayProfileCards = createAction(SET_DELAY_PROFILE_CARDS);

// MODAL
export const showModal = createAction(SHOW_MODAL);
export const hideModal = createAction(HIDE_MODAL);
export const nextModal = createAction(NEXT_MODAL);

export const setModal = createAction(SET_MODAL);
export const resetModal = createAction(RESET_MODAL);

export const setCurrentIdx = createAction(SET_CURRENT_IDX);
export const resetCurrentIdx = createAction(RESET_CURRENT_IDX);

// DEFAULT
export const pickerOptionsLoadStarted = createAction(PICKER_OPTIONS_LOAD_STARTED);
export const pickerOptionsLoadDone = createAction(PICKER_OPTIONS_LOAD_DONE);

export const tagsLoadStarted = createAction(TAGS_LOAD_STARTED);
export const tagsLoadDone = createAction(TAGS_LOAD_DONE);

// OTHER
export const setImageUploadCompany = createAction(SET_IMAGE_UPLOAD_COMPANY);
