import { handleActions } from 'redux-actions';

import {
  SET_APP_ERROR,
  SET_APP_MODAL_ERROR,
  RESET_APP_ERROR,
  SET_IMAGE_UPLOAD_COMPANY,
} from 'shared/store/app/actions';

const initialState = {
  appError: null,
  appModalError: null,
};

const handleSetAppError = (state = initialState) => ({
  ...state,
  appError: true,
});

const handleSetAppModalError = (state = initialState) => ({
  ...state,
  appModalError: true,
});

const handleResetAppError = (state = initialState) => ({
  ...state,
  appError: false,
  appModalError: false,
});

const handleSetImageUploadCompany = (state = initialState, action) => {
  const { payload: data } = action;

  return {
    ...state,
    imageUploadCompany: data,
  };
};

const actionHandlers = {
  [SET_APP_ERROR]: handleSetAppError,
  [SET_APP_MODAL_ERROR]: handleSetAppModalError,
  [RESET_APP_ERROR]: handleResetAppError,
  [SET_IMAGE_UPLOAD_COMPANY]: handleSetImageUploadCompany,
};

const reduceApp = handleActions(actionHandlers, initialState);

export default reduceApp;
