import React from 'react';
import { Helmet } from 'react-helmet-async';

import { AdminAuthHOC } from 'containers/HOC';

import { makeLikeParam } from 'utils/paramUtils';

import ConnectContainer from 'containers/ConnectContainer';

import ReadyContainer from 'connectors/ReadyContainer';

import { useTableQuery } from 'hooks';

import Sidebar from 'components/Sidebar';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';

import ToolboxAdminsTable from './components/ToolboxAdminsTable';

import duck from './redux';

import styles from './ToolboxAdminsPage.scss';

const route = '/toolbox/admins/';

const ToolboxAdminsPage = ({
  actions,
  page = 1,
  children,
  isAdmin,
  isLoading,
  rainmakersAdmins,
  total,
  users,
}) => {
  const { queryParams, loadMore, handleSearchCheckboxChange } = useTableQuery(
    route,
    page,
    {
      fullName: {
        type: 'string',
        defaultValue: null,
      },
      sortBy: {
        type: 'string',
        defaultValue: 'created_at_desc',
      },
      userAccountLocked: {
        type: 'boolean',
        defaultValue: false,
      },
      userEmail: {
        type: 'string',
        defaultValue: null,
      },
    },
    {
      type: 'rainmakers_admins',
      slice: 'toolboxAdmins',
    },
    {
      includeSet: 'user',
    },
    {
      fullName: makeLikeParam,
    }
  );

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      <Helmet key="helmet" title="Toolbox | Admins" />
      <ReadyContainer key="readyContainer">
        <Sidebar type="toolbox" page="toolboxAdmins" />
        <LayoutWithoutSidebar
          content={
            <div className={styles.toolboxAdminsMainContent}>
              <ToolboxAdminsTable
                {...queryParams}
                actions={actions}
                isLoading={isLoading}
                users={users}
                rainmakersAdmins={rainmakersAdmins}
                total={total}
                loadMore={loadMore}
                handleSearchCheckboxChange={handleSearchCheckboxChange}
              />
            </div>
          }
        />
        {children}
      </ReadyContainer>
    </>
  );
};

export default ConnectContainer(duck)(AdminAuthHOC()(ToolboxAdminsPage));
