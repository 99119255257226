import { all, fork } from 'redux-saga/effects';

import { handleError } from 'utils/common';

// MAIN
import appSaga from 'connectors/App/redux/sagas';
import loginSaga from 'connectors/Login/redux/sagas';
import modalSaga from 'connectors/Modal/redux/sagas';
import newPasswordSaga from 'connectors/NewPassword/redux/sagas';
import resetPasswordSaga from 'connectors/ResetPassword/redux/sagas';
import templateComposerSaga from 'connectors/TemplateComposer/redux/sagas';
import defaultsSaga from 'connectors/Defaults/redux/sagas';

// EMPLOYEE PORTAL
import employersSaga from 'pages/EmployeePortal/sagas';

// ADMIN PORTAL
import toolboxSaga from 'pages/AdminPortal/sagas';

// CANDIDATE PORTAL
import candidateOpportunitiesSaga from 'pages/CandidatePortal/CandidateOpportunitiesPage/redux/sagas';
import candidateSettingsSaga from 'pages/CandidatePortal/CandidateSettingsPages/redux/sagas';
import candidateEditorSaga from 'pages/CandidatePortal/CandidateEditorPages/redux/sagas';
import onboardingSaga from 'pages/CandidatePortal/OnboardingPages/redux/sagas';
import candidateHomeSaga from 'pages/CandidatePortal/CandidateHomePage/redux/sagas';

// shared
import entitySaga from './entitySaga';
import resourceSaga from './resourceSaga';
import userResourceSaga from './userResourceSaga';

export default function* root(history) {
  try {
    yield all([
      fork(loginSaga, history),
      fork(appSaga, history),
      fork(modalSaga, history),
      fork(newPasswordSaga, history),
      fork(resetPasswordSaga, history),
      fork(templateComposerSaga, history),
      fork(defaultsSaga, history),

      fork(entitySaga, history),
      fork(resourceSaga, history),
      fork(userResourceSaga, history),

      fork(employersSaga, history),

      fork(toolboxSaga, history),

      fork(candidateOpportunitiesSaga, history),
      fork(candidateSettingsSaga, history),
      fork(candidateEditorSaga, history),
      fork(onboardingSaga, history),
      fork(candidateHomeSaga, history),
    ]);
  } catch (error) {
    handleError(error);
  }
}
