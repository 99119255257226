import { get } from '../api';

const route = '/candidates';

const typeMap = {
  candidateRequirement: 'candidate_requirement',
  privateCandidateProfile: 'private_candidate_profile',
  workHistory: 'work_histories',
  candidateEducation: 'candidate_educations',
  user: 'user',
  vimeoVideo: 'vimeo_video',
};

const getCandidateRelationship = ({ candidateId = '', config, entityId = '', type }) =>
  get(`${route}/${candidateId}/${typeMap[type]}/${entityId}`, config);

export default getCandidateRelationship;
