import { handleActions } from 'redux-actions';
import union from 'lodash.union';

import {
  EMPLOYEE_SAVE_STARTED,
  EMPLOYEE_SAVE_DONE,
  EMPLOYEE_SAVE_ERROR,
  EMPLOYER_SAVE_STARTED,
  EMPLOYER_SAVE_DONE,
  EMPLOYER_ERRORS_SET,
  EMPLOYEE_ERRORS_RESET,
  REMOVE_EMPLOYEE_SAVE_ERROR,
} from '../actions';

const initialState = {
  employees: {
    byId: {},
    allIds: [],
  },
  employeeSaving: false,
  employeeServerError: [],
  errors: {
    name: {
      isNotEmpty: [null],
    },
    email: {
      isNotEmpty: [null],
      isEmail: [null],
    },
    location: {
      isNotEmpty: [null],
    },
    industry: {
      isNotEmpty: [null],
    },
    size: {
      isNotEmpty: [null],
    },
    about: {
      isNotEmpty: [null],
    },
    stage: {
      isNotEmpty: [null],
    },
    founded_on: {
      isNumeric: [null],
    },
    web_site: {
      isNotEmpty: [null],
    },
  },
};

const handleEmployeeSaveStarted = (state = initialState) => ({
  ...state,
  employeeSaving: true,
});

const handleEmployeeSaveDone = (state = initialState) => ({
  ...state,
  employeeSaving: false,
});

const handleEmployerSaveStarted = (state = initialState) => ({
  ...state,
  employer: {
    ...state.employer,
    employerSaving: true,
  },
});

const handleEmployerSaveDone = (state = initialState) => ({
  ...state,
  employer: {
    ...state.employer,
    employerSaving: false,
  },
});

const handleRemoveEmployeeSaveError = (state = initialState, action) => {
  const {
    payload: { errorName },
  } = action;

  const { employeeServerError = [] } = state;

  const newErrors = employeeServerError.filter((err) => err !== errorName);

  return {
    ...state,
    employeeServerError: newErrors,
  };
};

const handleEmployeeSaveError = (state = initialState, action) => {
  const { payload: { data: { errors } } = {} } = action;

  const errorNames = errors.map((err) => err.name);

  const cleanErrorNames = errorNames.filter((err) => err);

  const { employeeServerError = [] } = state;

  const newErrors = union(employeeServerError, cleanErrorNames);

  return {
    ...state,
    employeeSaving: false,
    employeeServerError: newErrors,
  };
};

const handleResetEmployeeSaveError = (state = initialState) => ({
  ...state,
  employeeServerError: [],
});

const handleEmployerErrorsSet = (state = initialState, action) => {
  const {
    payload: { inputErrors },
  } = action;

  return { ...state, errors: inputErrors };
};

const actionHandlers = {
  [EMPLOYEE_ERRORS_RESET]: handleResetEmployeeSaveError,
  [EMPLOYEE_SAVE_STARTED]: handleEmployeeSaveStarted,
  [EMPLOYEE_SAVE_DONE]: handleEmployeeSaveDone,
  [EMPLOYEE_SAVE_ERROR]: handleEmployeeSaveError,
  [EMPLOYER_SAVE_STARTED]: handleEmployerSaveStarted,
  [EMPLOYER_SAVE_DONE]: handleEmployerSaveDone,
  [REMOVE_EMPLOYEE_SAVE_ERROR]: handleRemoveEmployeeSaveError,
  [EMPLOYER_ERRORS_SET]: handleEmployerErrorsSet,
};

const reduceEmployee = handleActions(actionHandlers, initialState);

export default reduceEmployee;
