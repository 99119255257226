import React from 'react';

import styles from './Copy.scss';

const Copy = () => {
  return (
    <div className={styles.Graphic}>
      <div className={styles.QuoteContainerOne}>
        <div className={styles.QuoteOne}>
          &ldquo;Rainmakers is a simple to use tool that integrates nicely with our applicant
          tracking system. They have been an outstanding partner to our business, helping us close
          and stay connected with their talent pool.&rdquo;
        </div>
        <div className={styles.Avatar0}>
          <img src={require('../../assets/nextroll.png')} alt="" />
        </div>
      </div>

      <div className={styles.QuoteContainer}>
        <div className={styles.QuoteOne}>
          &ldquo;Rainmakers has been a great partner for our sales hiring needs. Getting a new
          cohort of candidates to review every two weeks was quite convenient and the Rainmakers
          team did an excellent job of calling specific profiles to our attention that they believed
          to be a good fit. We look forward to working with Mike and the team for our next
          hire.&rdquo;
        </div>
        <div className={styles.Avatar}>
          <img src={require('../../assets/parspec.png')} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Copy;
