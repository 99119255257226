import React from 'react';
import classnames from 'classnames';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import Button from 'components/Button';
import FontIcon from 'components/FontIcon';
import Input from 'components/Input';
import Label from 'components/Label';

import styles from './InputBullets.scss';

const InputBullets = (props) => {
  const onDragEnd = (result) => {
    const { handleReorderArray, name } = props;

    if (!result.destination) return;

    handleReorderArray({
      startIndex: result.source.index,
      endIndex: result.destination.index,
      name,
    });
  };

  const {
    addDisabled,
    array = [],
    disabled,
    handleAddArrayInput,
    handleAddArrayValue,
    handleDeleteArrayInput,
    inputClass,
    inputDisabled,
    label,
    name,
    pad,
    placeholder,
    required,
    secondaryLabel,
  } = props;

  const deleteIcon = <span>&times;</span>;

  const emptyArray = !array || (array && array.length === 0);

  const arrayToUse = emptyArray ? [''] : array;

  const singleElement = arrayToUse && arrayToUse.length === 1;
  const hasOneEmptyField = arrayToUse && arrayToUse.some((field) => field === '');

  const addInputs = arrayToUse.map((item, key) => {
    const inputProps = {
      disabled: disabled || inputDisabled,
      handleInputChange: (event) => handleAddArrayValue(event, name),
      name: key,
      value: item,
      size: 'full',
      placeholder,
      className: classnames(inputClass, styles.inputContainer),
    };

    const deleteIconProps = {
      className: singleElement ? styles.disabledDeleteItem : styles.deleteItem,
      onClick: singleElement ? () => null : () => handleDeleteArrayInput(key, name),
    };

    return (
      <Draggable key={key.toString()} draggableId={key} index={key}>
        {(provided, snapshot) => {
          const toolbarButtons = disabled ? (
            <div {...provided.dragHandleProps} />
          ) : (
            <>
              <div {...provided.dragHandleProps}>
                <FontIcon
                  title="Click and drag to rearrange"
                  className={styles.dragIcon}
                  iconName="drag"
                />
              </div>
              <div {...deleteIconProps}>{deleteIcon}</div>
            </>
          );

          return (
            <div
              className={snapshot.isDragging ? styles.itemDrag : styles.item}
              ref={provided.innerRef}
              style={provided.draggableProps.style}
              {...provided.draggableProps}
            >
              <Input {...inputProps} />
              {toolbarButtons}
            </div>
          );
        }}
      </Draggable>
    );
  });

  const secondaryLabelContent = secondaryLabel ? (
    <div className={styles.secondaryInputLabel}>{secondaryLabel}</div>
  ) : null;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={pad ? styles.InputBulletsPad : styles.InputBullets}>
        <div className={styles.inputs}>
          <Label
            required={required}
            className={secondaryLabel ? styles.inputLabelMain : styles.inputLabel}
          >
            {label}
          </Label>
          {secondaryLabelContent}
          <Droppable droppableId={`droppable-${name}`}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                className={snapshot.isDraggingOver ? styles.inputsArrayInDrag : styles.inputsArray}
              >
                {addInputs}
                {provided.placeholder}
                <Button
                  className={styles.addInput}
                  onClick={(event) => {
                    event.preventDefault();
                    handleAddArrayInput(name);
                  }}
                  tertiary={true}
                  disabled={disabled || hasOneEmptyField || addDisabled}
                >
                  + Add
                </Button>
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </DragDropContext>
  );
};

export default InputBullets;
