import React from 'react';

import { useImageField } from 'hooks';

import RainmakersLogo from 'shared/assets/rm-logo-short.png';

import Bolder from 'components/Bolder';
import FormattedName from 'components/FormattedName';

import styles from './TalentConcierge.scss';

const TalentConcierge = ({
  onClick,
  admin,
  admin: { firstName, lastName, phoneNumber, user: { email = 'support@rainmakers.co' } = {} } = {},
  adminInput,
  candidateName,
  adminTitle = 'Talent Concierge',
  emailSubject = 'General Rainmakers Inquiry',
}) => {
  const { imageComponent } = useImageField({
    model: { attributes: admin },
    avatarClass: styles.adminDataAvatarWrapper,
    emptyLogo: RainmakersLogo,
  });

  const name = firstName ? (
    <Bolder>
      <FormattedName className={styles.adminName} firstName={firstName} lastName={lastName || ''} />
    </Bolder>
  ) : (
    <Bolder>Contact Rainmakers:</Bolder>
  );

  const body = `Hi ${firstName},`;
  const date = new Date();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const subject = `${candidateName} - ${emailSubject} - ${month}/${day}`;

  return (
    <div className={styles.TalentConcierge} onClick={onClick}>
      {imageComponent}

      <div className={adminInput ? styles.adminDataInfoWrapper : styles.dataInfoWrapper}>
        {firstName ? (
          <div className={styles.adminDataSubtitle}>Your {adminTitle} is:</div>
        ) : (
          <div className={styles.adminDataSubtitle} />
        )}

        <div className={styles.bottom}>
          {adminInput || name}
          <div className={styles.smallText}>{phoneNumber}</div>
          <div className={styles.smallText}>
            <a href={`mailto:${email}?subject=${subject}&body=${body}`}>{email}</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TalentConcierge;
