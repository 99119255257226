import React from 'react';

import { camelToHuman } from 'utils/generalUtils';

import Block from 'components/Block';
import Content from 'components/Content';
import FormattedArray from 'components/FormattedArray';
import FormattedName from 'components/FormattedName';
import FormattedDate from 'components/FormattedDate';
import Label from 'components/Label';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import TableOverlay from 'components/TableOverlay';

import GroupMembers from '../ApproachEditorEmployee/components/GroupMembers';
import Feedback from '../ApproachEditorEmployee/components/Feedback';
import Message from '../ApproachEditorEmployee/components/Message';

import styles from '../ApproachEditorEmployee/ApproachEditorEmployee.scss';

const ApproachEditorMessages = ({
  actions,
  actions: { patchResource },
  candidates,
  candidates: { byId: candidatesById = {} } = {},
  employees,
  employers: { byId: employersById = {} } = {},
  handleSetState,
  isAdmin,
  messages: { byId: messagesById = {}, allIds: messagesAllIds = [] } = {},
  messagesLoading,
  messageGroups: { byId: messageGroupsById = {} } = {},
  messageGroupMembers,
  messageRecipients,
  privateReqCanFeedback: { byId: privateReqCanFeedbackById = {} } = {},
  rainmakersAdmins,
  rainmakersAdmins: { byId: rainmakersAdminsById = {} } = {},
  requisitionOpenings: { byId: requisitionOpeningsById = {} },
  requisitionCandidate: {
    attributes: {
      attentionRequiredReasons = [],
      companyFeedback = [],
      createdAt,
      passedOn,
      lastActivityAt,
      lastActivityType,
      lastActivityByType,
      offerFeedback = [],
      offerSignedDate,
      reason = '',
      roleFeedback = [],
      startDate,
      state = '',
    } = {},
    id: requisitionCandidateId,
    relationships: {
      candidate: { data: candidateIds = [] } = {},
      employer: { data: employerIds = [] } = {},
      requisitionOpeningCopy: { data: requisitionOpeningIds = [] } = {},
    } = {},
  } = {},
  sendgridEmailEvents,
  showFeedback,
  user,
}) => {
  const messageGroupId = Object.keys(messageGroupsById).find(
    (id) => messageGroupsById[id].attributes.linkedObjectId === requisitionCandidateId
  );

  const { [candidateIds[0]]: { attributes: { fullName, rainmakersAdminId } = {} } = {} } =
    candidatesById || {};

  const { [employerIds[0]]: { attributes: { name } = {} } = {} } = employersById || {};

  const privateReqCanFeedbackIds = Object.keys(privateReqCanFeedbackById).filter(
    (id) =>
      privateReqCanFeedbackById[id] &&
      privateReqCanFeedbackById[id].attributes.requisitionCandidateId === requisitionCandidateId
  );

  const privateReqCanFeedbackEmployeeId = privateReqCanFeedbackIds.find(
    (id) => privateReqCanFeedbackById[id].attributes.ownerType === 'Employee'
  );
  const privateReqCanFeedbackCandidateId = privateReqCanFeedbackIds.find(
    (id) => privateReqCanFeedbackById[id].attributes.ownerType === 'Candidate'
  );

  const {
    [privateReqCanFeedbackEmployeeId]: {
      attributes: { feedback: employeeFeedback = '', rating: employeeRating = '' } = {},
    } = {},
  } = privateReqCanFeedbackById || {};

  const {
    [privateReqCanFeedbackCandidateId]: {
      attributes: { feedback: candidateFeedback = '' } = {},
    } = {},
  } = privateReqCanFeedbackById || {};

  const { [rainmakersAdminId]: { attributes: { firstName, lastName } = {} } = {} } =
    rainmakersAdminsById || {};

  const { [requisitionOpeningIds[0]]: { attributes: { role = 'N/A' } = {} } = {} } =
    requisitionOpeningsById || {};

  const messageBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    largeTitleFont: true,
    className: styles.block,
    addPadding: true,
  };

  const currentMessageIds = messagesAllIds.filter(
    (id) => messagesById[id].attributes.messageGroupId === messageGroupId
  );

  const messagesLoadingContent =
    messagesLoading || messagesLoading === undefined ? (
      <div className={styles.empty}>
        <TableOverlay table="image" />
      </div>
    ) : null;

  const noMessagesContent =
    !messagesLoading && currentMessageIds.length === 0 ? <div className={styles.empty} /> : null;

  const messagesContent =
    currentMessageIds.length > 0 ? (
      currentMessageIds.map((id, idx) => {
        const messageProps = {
          actions,
          candidates,
          employees,
          idx,
          isAdmin: true,
          message: messagesById[id],
          messageGroupMembers,
          messageRecipients,
          rainmakersAdmins,
          sendgridEmailEvents,
        };

        return <Message key={id} {...messageProps} />;
      })
    ) : (
      <Block {...messageBlockProps}>{messagesLoadingContent || noMessagesContent}</Block>
    );

  const titleBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    addPadding: true,
    noTitlePadding: true,
    className: styles.titleBlock,
    largeTitleFont: false,
  };

  const titleTwoBlockProps = {
    ...titleBlockProps,
    className: styles.titleTwoBlock,
  };

  const lastActivityByTypeContent = lastActivityByType === 'RainmakersAdmin' ? '(RM)' : '';

  const attentionRequiredReasonsContent =
    attentionRequiredReasons.length > 0 ? (
      <Content className={styles.attribute}>
        <Label>Attention Required</Label>
        <Content className={styles.value}>
          <FormattedArray array={attentionRequiredReasons.map((str) => camelToHuman(str))} />
        </Content>
      </Content>
    ) : null;

  const formattedNameProps = {
    firstName,
    lastName,
  };

  const conciergeContent = rainmakersAdminId ? (
    <Content className={styles.value}>
      <FormattedName {...formattedNameProps} />
    </Content>
  ) : (
    <Content className={styles.value}>None</Content>
  );

  const groupMembersProps = {
    actions,
    candidates,
    employees,
    messageGroupId,
    messageGroupMembers,
    rainmakersAdmins,
    user,
  };

  const feedbackProps = {
    employeeFeedback,
    employeeRating,
    candidateFeedback,
    companyFeedback,
    handleSetState,
    isAdmin,
    offerFeedback,
    offerSignedDate,
    passedOn,
    patchResource,
    reqCandidateId: requisitionCandidateId,
    reqId: requisitionOpeningIds[0],
    roleFeedback,
    reason,
    showFeedback,
    startDate,
    state,
  };

  const content = (
    <div className={styles.ApproachMessages}>
      <Block {...titleBlockProps}>
        <Content className={styles.tier}>
          <Content className={styles.tierRowOne}>
            <Content className={styles.attribute}>
              <Label>Name</Label>
              <Content className={styles.value}>{fullName}</Content>
            </Content>

            <Content className={styles.attribute}>
              <Label>Employer</Label>
              <Content className={styles.value}>{name}</Content>
            </Content>

            <Content className={styles.attribute}>
              <Label>Role</Label>
              <Content className={styles.value}>{role}</Content>
            </Content>

            <Content className={styles.attribute}>
              <Label>Concierge</Label>
              {conciergeContent}
            </Content>

            <Content className={styles.attribute}>
              <Label>Created At</Label>
              <Content className={styles.value}>
                <FormattedDate format="M/D/YY" parse date={createdAt} />
              </Content>
            </Content>

            <Content className={styles.attribute}>
              <Label>State</Label>
              <Content className={styles.value}>{camelToHuman(state)}</Content>
            </Content>

            <Content className={styles.attribute}>
              <Label>Last Activity</Label>
              <Content className={styles.value}>
                <FormattedDate parse timeAgo timeAgoFormat="relativeDays" date={lastActivityAt} />
              </Content>
            </Content>

            <Content className={styles.attribute}>
              <Label>Activity Type</Label>
              <Content className={styles.value}>
                {`${lastActivityType} ${lastActivityByTypeContent}`}
              </Content>
            </Content>

            {attentionRequiredReasonsContent}
          </Content>
        </Content>
        <Feedback {...feedbackProps} />
      </Block>

      <Block {...titleTwoBlockProps}>
        <GroupMembers {...groupMembersProps} />
      </Block>

      <div className={styles.scrollDiv}>{messagesContent}</div>
    </div>
  );

  const layoutWithoutSidebarProps = {
    content,
    className: styles.overflow,
  };

  return <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />;
};

export default ApproachEditorMessages;
