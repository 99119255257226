import { getEntity } from 'api/apiEntity';

const STATE_MAP = {
  all: [
    'active',
    'passed',
    'employer_passed',
    'candidate_pass_pending',
    'candidate_passed',
    'hired',
  ],
  active: ['active'],
  closed: ['passed', 'candidate_passed', 'candidate_pass_pending', 'hired', 'employer_passed'],
};

const promiseGetOpportunities = ({ page, includeSet = 'employer', sortBy, state }) => {
  const newSortBy = sortBy.includes('created_at')
    ? `${sortBy}_nulls_last`
    : `${sortBy}_nulls_last,created_at_desc`;

  const newerSortBy = newSortBy.includes('state_enum') ? newSortBy : `state_enum_asc,${newSortBy}`;

  const params = {
    state: STATE_MAP[state] || 'ne:inactive',
    page,
    sortBy: newerSortBy,
    includeSet,
    camelizeIt: true,
  };

  const onFulfilled = (response) => {
    const {
      data: {
        requisitionCandidates: opportunities = [],
        meta: { pagination: { currentPage: page, totalCount: total } } = {},
      } = {},
    } = response;

    return { opportunities, page, total };
  };

  const args = {
    type: 'requisition_candidates',
    config: {
      params,
    },
  };

  const promise = getEntity(args).then(onFulfilled);

  return promise;
};

export default promiseGetOpportunities;
