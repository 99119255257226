import { createStructuredSelector } from 'reselect';

import selectResetPasswordErrors from './selectResetPasswordErrors';
import selectResetPasswordDone from './selectResetPasswordDone';

const selector = createStructuredSelector({
  resetPasswordErrors: selectResetPasswordErrors,
  resetPasswordDone: selectResetPasswordDone,
});

export { selectResetPasswordErrors, selectResetPasswordDone, selector as default };
