import React from 'react';
import { Helmet } from 'react-helmet-async';

import ReadyContainer from 'connectors/ReadyContainer';

import Block from 'components/Block';
import Footer from 'pages/CandidatesHome/components/Footer';

import styles from './Terms.scss';

const Terms = () => {
  const blockProps = {
    addWhiteBG: true,
  };

  const title = 'Rainmakers TOS';
  const metaDescription =
    'Rainmakers Terms of Service for Users. Sales jobs for top sales talent. Rainmakers connects top sales candidates with top companies.';
  const canonicalUrl = `https://${process.env.HOST}/terms/`;

  const helmetProps = {
    key: 'helmet',
    title,
    meta: [
      { name: 'description', content: metaDescription },
      { property: 'og:url', content: canonicalUrl },
      { property: 'og:title', content: title },
      { property: 'og:description', content: metaDescription },
    ],
    link: [{ rel: 'canonical', href: canonicalUrl }],
  };

  return (
    <div className={styles.Terms}>
      <Helmet {...helmetProps} />
      <ReadyContainer key="readyContainer" className={styles.Terms}>
        <Block {...blockProps}>
          <div className={styles.termsText}>
            <h1 className={styles.termsHeader}>Rainmakers Terms & Conditions</h1>
            <div className={styles.termsContent}>Effective March 1, 2017</div>
            <div className={styles.termsContent}>
              Welcome to Rainmakers, the next-generation tool for identifying and connecting
              high-performing sales professionals with potential employers. Below, you will find the
              “<span className={styles.termsBold}>Terms of Service</span>” that apply to anyone who
              uses Rainmakers to help in the process of finding a new job, hiring a new salesperson
              (and even to individuals who just visit our site). But if you want to know what it
              says in English as opposed to legal, we suggest you take a couple of minutes to read
              the next few paragraphs before continuing. If you are one of those people who actually
              reads the terms and conditions of a website, feel free to jump ahead to the more
              confusing stuff below.
            </div>
            <div className={styles.termsContent}>
              <div className={styles.termsContentIndented}>
                Rainmakers facilitates the search for skilled sales professionals by maintaining a
                database of pre-screened sales reps and execs (“
                <span className={styles.termsBold}>Candidates</span>”) who are actively seeking new
                opportunities. Prospective employers can search for Candidates by skills,
                experience, and requirements like desired salary, and reach out directly to schedule
                interviews with the most promising ones. Candidates can view Employer profiles to
                determine if a potential employer is a good match and are under no obligation to
                accept an interview.
              </div>
              <div className={styles.termsContentIndented}>
                There is no contract or any other obligation created between employers and
                Candidates by using Rainmakers, and no charge for using the service unless an
                employer hires a Candidate they have been introduced to through the service, in
                which case the employer pays us a modest success fee. And if you are a candidate who
                is hired through Rainmakers, you may be eligible for a hiring “bonus” from us as a
                thank you for using our Rainmakers to find your new role—think of it as a referral
                fee for yourself.
              </div>
              <div className={styles.termsContentIndented}>
                Everyone who uses Rainmakers is responsible for the accuracy of the information they
                provide, and uses the service at their own risk. We may verify submissions, and may
                reject registrations or remove registered users at any time and for any reason. By
                using our service, you consent to our using salary, marketplace, experience, and any
                other non-personal information you provide to Rainmakers, (i.e., any information
                that CAN’T be used to identify a specific individual) to improve the service and in
                our other offerings.
              </div>
            </div>
            <div className={styles.termsContent}>
              We make every effort to keep Rainmakers secure, but our services are not guaranteed,
              and we cannot promise that they will meet your expectations. If you do not like the
              services, you may cancel your registration at any time. We hope you don’t, and invite
              you to contact us at{' '}
              <span className={styles.emailLink}>
                <a href="mailto:support@rainmakers.co">support@rainmakers.co</a>
              </span>{' '}
              with any problems and we will work to resolve them. We really hope we don’t get into a
              dispute over the services, but if we do, we will resolve the dispute in small claims
              court or through arbitration, and unless we do something really terrible, your damages
              are limited to $100 or, in the case of an employer who paid Rainmakers a success fee,
              the amount of that success fee.
            </div>
            <div className={styles.termsContent}>
              Our lawyers insist that the above may be understandable, but they are not the “real”
              terms. Those are below:
            </div>
            <div className={styles.termsContent}>
              <div className={styles.termsBoldCentered}>
                <p>Terms of Use</p>
                <p>Version 1</p>
                <p>Effective Date: March 1, 2017</p>
                <p>Last Updated Date: May 22, 2020</p>
              </div>
            </div>
            <div className={styles.termsContent}>
              These Terms of Use (the &ldquo;<span className={styles.termsBold}>Terms</span>&rdquo;)
              are a legally binding agreement between Rainmaker Labs, Inc., a Delaware corporation
              (&ldquo;<span className={styles.termsBold}>Rainmakers</span>&rdquo;, &ldquo;
              <span className={styles.termsBold}>we</span>&rdquo;, or &ldquo;
              <span className={styles.termsBold}>our</span>&rdquo;), and visitors to the Rainmakers
              website, {`https://${process.env.HOST}`} (&ldquo;
              <span className={styles.termsBold}>site</span>
              &rdquo;) and users of the proprietary sales professional placement and recruiting
              platform that we offer to registered users of the site (collectively with the site,
              the “<span className={styles.termsBold}>Services</span>”). As used herein &ldquo;
              <span className={styles.termsBold}>you</span>&rdquo; means any user of the Services or
              site visitor, as applicable. A &ldquo;
              <span className={styles.termsBold}>Candidate</span>&rdquo; is a sales professional who
              registers (and is accepted by us) to use the Services to aid in their employment
              search, and an &ldquo;<span className={styles.termsBold}>Employer</span>&rdquo; is an
              entity that registers (and is accepted by us) to use the Services to identify and
              contact potential sales professionals for employment, in each case subject to these
              Terms. A &ldquo;<span className={styles.termsBold}>User</span>&rdquo; may be either an
              Employer or Candidate, or both, as the circumstances suggest, who has registered to
              use the Services.
            </div>
            <div className={styles.termsContent}>
              <span className={styles.termsBold}>
                PLEASE READ THESE TERMS CAREFULLY. By using or accessing the Services, you agree to
                these terms.
              </span>
              If you don’t accept these Terms, you may not use the site or Services. All Candidates
              and Employers are required to affirmatively accept the Terms as part of the
              registration process. You may not use the Services if you are under 18 years of age,
              and the site is not intended for children under the age of 13.
            </div>
            <div className={styles.termsContent}>
              <span className={styles.termsBold}>
                SECTION 8 OF THESE TERMS REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO
                RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTION PROCEEDINGS, AND ALSO
                LIMITS THE REMEDIES AVAILABLE IN CASE OF A DISPUTE.
              </span>
            </div>
            <div className={styles.termsContent}>
              <span className={styles.termsBold}>
                WE MAY CHANGE THESE TERMS, INCLUDING THE FEES PAYABLE RELATED TO THE SERVICE, AT ANY
                TIME IN OUR SOLE DISCRETION.
              </span>
              We will post any revisions and the date they were made to this page. We will also send
              an email to the registered email address of all Users informing them of the updated
              Terms. Revised Terms will take effect immediately for new users and thirty (30) days
              after the date the revised Terms are posted to this page for existing Users. We may
              require affirmative consent to revised Terms at our sole discretion.
            </div>
            <div className={styles.termsContent}>
              <span className={styles.termsBold}>1. The Services</span>
              <div className={styles.termsContentIndented}>
                1.1.
                <span className={styles.termsBoldUnderline}>Provision of the Services</span>
                <div className={styles.termsContentDoubleIndented}>
                  (a) Subject to your compliance with the Terms, we will provide the Services to you
                  for your personal or internal business purposes. Use of the Services to submit or
                  review Candidate or Employer information or contact other Users requires
                  registration pursuant to Section 2 below.
                </div>
                <div className={styles.termsContentDoubleIndented}>
                  (b) While a single User may access the Services through multiple devices (i.e., a
                  computer at home, a computer at work, and a mobile device), Candidate Users may
                  not share their sign-on credentials or otherwise provide anyone else with access
                  to the Services, and all employees of an Employer authorized to access an Employer
                  User Account (as such term is defined in Section 2) must be included in the
                  registration information for such Account, and not share the User credentials with
                  anyone not so authorized. We may suspend the Account of any User who we reasonably
                  believe has violated this restriction.
                </div>
                <div className={styles.termsContentDoubleIndented}>
                  (c) The Services are exclusively provided remotely, via registered access to the
                  site, and we will never provide you with a tangible copy of our software or
                  documentation related to such software or the Services. Copying or redistributing
                  any aspect of the Services, including copying the software underlying the Services
                  to any other server or location, redistributing such software, or using it on a
                  service bureau basis is prohibited.
                </div>
              </div>
              <div className={styles.termsContentIndented}>
                1.2.
                <span className={styles.termsBoldUnderline}>Updates.</span>
                We are constantly improving the Services and may update the software supporting the
                Services at any time and without notice. You may need to update third-party software
                (i.e., web browsers) from time to time in order to continue using the Services or to
                access all features of the Services.
              </div>
              <div className={styles.termsContentIndented}>
                1.3.
                <span className={styles.termsBoldUnderline}>Restrictions.</span>
                The license granted to you in this Section 1 is subject to the following
                restrictions. You will not:
                <div className={styles.termsContentDoubleIndented}>
                  (a) license, sell, rent, lease, transfer, assign, reproduce, distribute, host or
                  otherwise commercially exploit the Services or any portion thereof, including the
                  site,
                </div>
                <div className={styles.termsContentDoubleIndented}>
                  (b) frame or use framing techniques to enclose any trademark, logo, or other of
                  our marks (or use any metatags or other “hidden text” using our name or
                  trademarks);
                </div>
                <div className={styles.termsContentDoubleIndented}>
                  (c) attempt to interfere with or compromise the system integrity or security, or
                  decipher any transmissions to or from the servers running the site or Service;
                </div>
                <div className={styles.termsContentDoubleIndented}>
                  (d) modify, translate, adapt, merge, make derivative works of, disassemble,
                  decompile, or reverse engineer or compile any element of the Services, except to
                  the extent the foregoing restrictions are expressly prohibited by applicable law;
                </div>
                <div className={styles.termsContentDoubleIndented}>
                  (e) use any manual or automated means to “scrape” or download data from the site
                  (except that public search engines may do so to create publicly available
                  searchable indices, but may not cache or archive any information from the site);
                </div>
                <div className={styles.termsContentDoubleIndented}>
                  (f) access the Services in order to build a similar or competitive website,
                  application or service;
                </div>
                <div className={styles.termsContentDoubleIndented}>
                  (g) remove or destroy any copyright notices or other proprietary markings
                  contained on or in the Services; or
                </div>
                <div className={styles.termsContentDoubleIndented}>
                  (h) use the site or Services in violation of any other provision of these Terms.
                </div>
              </div>
              <div className={styles.termsContentIndented}>
                1.4. Any future release, update or other change to the Services will be subject to
                the Terms. We and our suppliers and service providers reserve all rights not granted
                in the Terms.
              </div>
            </div>
            <div className={styles.termsContent}>
              <span className={styles.termsBold}>2. Account</span>
              <div className={styles.termsContentIndented}>
                2.1.
                <span className={styles.termsBoldUnderline}>Registration.</span>
                You must register as a User and create an account with specific information about
                yourself or your company, as applicable (an “
                <span className={styles.termsBold}>Account</span>”) in order to access the Services.
                There is no charge to register for the Services. You must be at least eighteen (18)
                years old and legally permitted (and, if registering on behalf of an Employer,
                properly authorized by the Employer) to create an Account. We may accept or refuse
                any Account registration in our sole discretion. WE DO NOT PERFORM OR HAVE PERFORMED
                FORMAL BACKGROUND CHECKS AS PART OF THE ACCOUNT APPROVAL PROCESS; every Employer is
                responsible for performing its standard background checks regarding a Candidate as
                part of the hiring process. You are responsible for all activities that occur under
                your Account, including any unauthorized use of the Services by minors. You will not
                share your Account credentials with anyone or otherwise allow others to access your
                Account (except that multiple individuals registered under an Employer Account may
                share the User credentials for such Account) and will notify us immediately of any
                unauthorized use of your password or other breach of security. Each Candidate and
                Employer may only have one Account at any given time. You may not create an Account
                using false or misleading information, or if we have previously barred you from
                using the Services.
                <div className={styles.termsContentDoubleIndented}>
                  (a)
                  <span className={styles.termsBoldUnderline}>Candidates.</span>
                  Candidate Accounts must include your name, contact information, current resume,
                  and such other information as we may reasonably request, including employment
                  history, work experience, professional skills and certifications, compensation
                  expectations, and educational background. We may allow you to link your Account
                  with a third party service such as LinkedIn (in which case you are solely
                  responsible for complying with the terms of such third party service). We may
                  review your Account information and any other information about you that is
                  publicly available and may, in our sole discretion, accept or refuse your Account.
                  You may use the Service to respond to Employer inquiries only after you receive
                  notification from us that we have activated your Account. Candidates may prevent
                  their current employer from accessing their information on the Service via Account
                  settings. This feature works by matching the name of the Candidate’s current
                  employer AS ENTERED INTO THE SERVICE BY THE CANDIDATE with the company name used
                  by such Employer to register with the Service (if applicable). Candidates are
                  responsible for ensuring that the name they enter exactly matches the name used by
                  their employer in the Service.
                </div>
                <div className={styles.termsContentDoubleIndented}>
                  (b)
                  <span className={styles.termsBoldUnderline}>Employers.</span>
                  Employer Accounts must include a company profile, hiring manager contact
                  information, the names of all authorized users of the Account, and such other
                  information as we may reasonably request, including the roles you are actively
                  recruiting for. We may allow you to link your account with a third party service
                  such as LinkedIn (in which case you are solely responsible for complying with the
                  terms of such third party service). We may review your Account information and any
                  other information about you that is publicly available and may, in our sole
                  discretion, accept or refuse your Account. You may use the Service to review
                  Candidates’ profiles and reach out to Candidates who you would like to interview
                  only after we notify you that we have activated your Account.
                </div>
              </div>
              <div className={styles.termsContentIndented}>
                2.2.
                <span className={styles.termsBoldUnderline}>User Information.</span>
                <div className={styles.termsContentDoubleIndented}>
                  (a) You agree to provide true, accurate, current and complete information about
                  yourself and / or your company when registering an Account and to keep such
                  information updated and accurate for so long as you have an Account.
                </div>
                <div className={styles.termsContentDoubleIndented}>
                  (b) Your Account information may be made available (i) to other Users as an
                  essential part of the Services (i.e., in connection with Employer reviews of
                  Candidates, and (ii) on publicly available portions of the Services, in which case
                  the Account information displayed will not include compensation information and
                  will otherwise reasonably limit the Personal Data available.
                </div>
              </div>
              <div className={styles.termsContentIndented}>
                2.3.
                <span className={styles.termsBoldUnderline}>Necessary Equipment and Software.</span>
                You are responsible for providing all equipment and software you need to access
                Services and for any fees, including Internet connection or mobile fees, that you
                incur when accessing the Services.
              </div>
              <div className={styles.termsContentIndented}>
                2.4.
                <span className={styles.termsBoldUnderline}>Use.</span>
                You acknowledge that Rainmakers only provides a service that allows potential
                employers and employees to identify and communicate with each other and does not act
                as an agent or representative for either Candidates or Employers. Use of the
                Services does not create any contractual obligation on Candidates or Employers with
                respect to each: Employer interview requests do not bind the Employer to offer the
                Candidate a position; Candidates are not required to accept or respond to interview
                requests from Employers (though it is rude not to); and accepting an interview
                request does not oblige a Candidate to accept an offer of employment. You
                acknowledge that you are solely responsible for (a) the accuracy of Your Content,
                (b) validating the accuracy of any information provided by other Users, whether in
                Content they submit or through interviews arranged through the Services, and (c) for
                any employment decisions you make with respect to other Users. Only an executed
                offer letter, consulting agreement, or other document setting forth the terms under
                which a Candidate will perform services for an Employer (the “Employment Agreement”
                which, for the avoidance of doubt, may refer to agreements to retain a Candidate as
                either a full-time employee or a contractor) creates an obligation between a
                particular Candidate and Employer. Rainmakers is not a party to any Employment
                Agreement, is not responsible for Employment Agreements, and expressly disclaims any
                liability arising from any Employment Agreement. Employers are responsible for
                updating Rainmakers on the status of their communications with Candidates, including
                contract offers and acceptances.
              </div>
            </div>
            <div className={styles.termsContent}>
              <span className={styles.termsBold}>3. Ownership</span>
              <div className={styles.termsContentIndented}>
                3.1.
                <span className={styles.termsBoldUnderline}>Services.</span>
                Except with respect to information in your Profile and any other information you
                post to the Services (“Your Content”), Rainmakers and our suppliers own all right,
                title and interest, including all intellectual property rights, in the Services.
                Without limiting the previous sentence, we own (a) all data about the use of the
                Services, including analytics and metrics at the individual Account level, and (b)
                all information that may not be used to identify individuals and Employers derived
                from Your Content, and we may use such information for our internal business
                purposes.
              </div>
              <div className={styles.termsContentIndented}>
                3.2.
                <span className={styles.termsBoldUnderline}>Trademarks</span>
                The Rainmakers logo and any related graphics, logos, service marks and trade names
                (collectively, “<span className={styles.termsBold}>Marks</span>”) used in connection
                with the Services are ours and may not be used without our permission for any other
                purpose. Other Marks that may appear on or in the Services, if applicable, are the
                property of their respective owners. You will not remove, alter or obscure any Mark
                or other proprietary rights notices in or related to the Services.
              </div>
              <div className={styles.termsContentIndented}>
                3.3.
                <span className={styles.termsBoldUnderline}>Your Content.</span>
                You are solely responsible for Your Content that you post to or make available
                through the Services, and you grant us a fully-paid, royalty-free, worldwide,
                non-exclusive and fully sublicensable (including with respect to moral rights)
                license to use, reproduce and display such content (in whole, in part, or in
                combination with other material) (a) in order to provide the Services, and (b) for
                any other legitimate business purpose, subject to all applicable laws, provided that
                such purpose will not identify individuals or Employers. You represent and warrant
                that you own all rights in Your Content, have the rights required to grant us the
                license above to Your Content, and that Your Content does not infringe any third
                party’s intellectual property rights. You acknowledge that we are not required to
                use or display distribute Your Content and may, in our sole discretion, remove or
                delete any of Your Content from the Services at any time.
              </div>
              <div className={styles.termsContentIndented}>
                3.4.
                <span className={styles.termsBoldUnderline}>Third-Party Links & Ads.</span>
                The site and/or Services may contain links to third-party websites and services,
                and/or display advertisements for third parties (collectively, “
                <span className={styles.termsBold}>Third-Party Links & Ads</span>”). Such
                Third-Party Links & Ads are not under our control, and we are not responsible for
                any Third-Party Links & Ads. ClipCall provides access to these Third-Party Links &
                Ads only as a convenience to you, and does not review, approve, monitor, endorse,
                warrant, or make any representations with respect to Third-Party Links & Ads. You
                use all Third-Party Links & Ads at your own risk, and should apply a suitable level
                of caution and discretion in doing so. When you click on any of the Third-Party
                Links & Ads, the applicable third party’s terms and policies apply, including the
                third party’s privacy and data gathering practices. You should make whatever
                investigation you feel necessary or appropriate before proceeding with any
                transaction in connection with such Third-Party Links & Ads.
              </div>
              <div className={styles.termsContentIndented}>
                3.5.
                <span className={styles.termsBoldUnderline}>Feedback.</span>
                Any ideas, suggestions, documents, and/or proposals related to the Services you
                provide us through any means (“<span className={styles.termsBold}>Feedback</span>”)
                is at your own risk and we have no obligations with respect to such Feedback. You
                represent and warrant that you have all rights necessary to submit the Feedback and
                you hereby grant us a fully paid, royalty-free, perpetual, irrevocable, worldwide,
                non-exclusive, and fully sublicensable right and license to use the Feedback in our
                sole discretion.
              </div>
            </div>
            <div className={styles.termsContent}>
              <span className={styles.termsBold}>4. User Conduct</span>
              <div className={styles.termsContentIndented}>
                4.1. You will not use the Services for any purpose that is prohibited by these Terms
                or applicable law. You will not attempt to initially contact Candidates or Employers
                of whom you have become aware through the Service outside the Service, and are
                required to regularly inform us via the site or email of the progress of your
                contacts with Candidates or Employers that occur outside of the Service (i.e.,
                through direct email exchanges, interviews, etc.). You will only use Personal Data
                of other Users for the express purposes of the Services. In addition to the
                restrictions set forth in Section 1.3, you will not yourself or through a third
                party (a) take any action or (b) upload to the Services Your Content, that: (i)
                infringes any intellectual property rights, right of publicity or other right of any
                person or entity; (ii) is unlawful, threatening, abusive, harassing, defamatory,
                libelous, deceptive, fraudulent, invasive of another’s privacy, tortious, obscene,
                or offensive; (iii) constitutes unauthorized or unsolicited advertising, junk or
                bulk e-mail; (iv) involves commercial activities and/or sales (such as contests,
                sweepstakes, barter, advertising, or pyramid schemes) without our prior written
                consent; (v) impersonates any person or entity, or (vi) interferes with or attempts
                to interfere with the proper functioning of the Services or use of the Services by
                any other user, host or network, including by means of overloading, “flooding,”
                “spamming,” “mail bombing,” or “crashing” the Services or elements thereof.
              </div>
              <div className={styles.termsContentIndented}>
                4.2. Although we do not generally monitor User activity, we may do so, and if we
                become aware of a potential violation of the Terms, we may investigate such
                incidents, and, in our sole discretion, take whatever steps we deem necessary to
                remedy such violation, including immediately terminating your Account. The Service
                automatically tracks all User activity, including with respect to viewing
                information about other Users, requesting interviews, etc. We may use this
                information to improve the Services and to enforce our rights under these Terms.
              </div>
            </div>
            <div className={styles.termsContent}>
              <span className={styles.termsBold}>5. Fees and Payment</span>
              <div className={styles.termsContentIndented}>
                5.1.
                <span className={styles.termsBoldUnderline}>Qualified Placement.</span>
                <div className={styles.termsContentDoubleIndented}>
                  (a) A “<span className={styles.termsBold}>Qualified Placement</span>” means that,
                  (i) within one (1) year of initially being presented with a Candidate in any
                  search result, viewing a Candidate profile or otherwise learning about a Candidate
                  via the Services, an Employer enters into an Employment Agreement with such
                  Candidate, or (ii), after initially entering into an Employment Agreement for
                  consulting or contractor services with a Candidate, an Employer enters into an
                  Employment Agreement for full-time employment with such Candidate within one (1)
                  year of the last date on which the Candidate performed services under the initial
                  Employment Agreement.
                </div>
                <div className={styles.termsContentDoubleIndented}>
                  (b) Each Candidate agrees to promptly (i) inform us of the receipt of any formal,
                  accepted offer for a Qualified Placement, including the start date and key
                  financial terms of the offer (and if, for whatever reason, the offer or the
                  Candidate’s acceptance of it is rescinded), (ii) send us either (x) a fully
                  executed copy of the Employment Agreement, or (y) the key financial terms of such
                  Employment Agreement confirmed by the Employer (which may be via a form submitted
                  within the Service or as we otherwise reasonably request), and (iii) where the
                  Employment Agreement is for a full-time position, inform us if the Candidate,
                  within ninety (90) days of his or her start date, is (1) terminated for
                  unsatisfactory performance or (2) voluntarily resigns.
                </div>
                <div className={styles.termsContentDoubleIndented}>
                  (c) Each Employer agrees that the notice requirements regarding Qualified
                  Placements in the prior provision are an essential element of the Services, and
                  irrevocably consents to the Candidate’s providing such information, provided,
                  however, that where the Employer reasonably believes that the applicable
                  Employment Agreement contains confidential information about the Employer that is
                  not relevant to the Services, Employer will promptly cooperate with Candidate to
                  nevertheless provide the key financial terms of the applicable Employment
                  Agreement to Rainmaker as set forth above. All information and notice required of
                  Candidates by this Section 5.1 may be provided by Employer, in which case we may
                  verify the information with the Candidate at our sole discretion.
                </div>
              </div>
              <div className={styles.termsContentIndented}>
                5.2.
                <span className={styles.termsBoldUnderline}>Placement Fees.</span>
                <div className={styles.termsContentDoubleIndented}>
                  (a)
                  <span className={styles.termsBoldUnderline}>General.</span>
                  After approval, each employer agrees to pay a subscription and/or placement fee
                  based on the agreed upon terms.
                </div>
                <div className={styles.termsContentDoubleIndented}>
                  (b)
                  <span className={styles.termsBoldUnderline}>MATERIALITY.</span>
                  THE EMPLOYER’S PAYMENT OF PLACEMENT FEES IS RAINMAKER’S SOLE COMPENSATION FOR
                  PROVIDING THE SERVICES, AND IF YOU ARE AN EMPLOYER YOU AGREE TO PAY PLACEMENT FEES
                  AS SET FORTH HEREIN AS A CONDITION OF YOUR ACCESS TO THE SERVICES. IF YOU DO NOT
                  CONSENT TO PAYMENT OF PLACEMENT FEES, YOU MAY NOT ACCESS THE SERVICES.
                </div>
                <div className={styles.termsContentDoubleIndented}>
                  (c)
                  <span className={styles.termsBoldUnderline}>Exceptions.</span>
                  Where, prior to viewing a Candidate’s information via the Service, an Employer had
                  (i) received the resume of such Candidate from a third party to which Employer has
                  an obligation to pay a fee similar to the Placement Fee, or (ii) already contacted
                  such Candidate directly to discuss potential employment, (an “
                  <span className={styles.termsBold}>Exempt Candidate</span>”) then the Employer
                  will not owe us a Placement Fee for an engagement with such Candidate that would
                  otherwise be a Qualifying Placement. If you are an Employer, you agree to promptly
                  (and in any event within less than three (3) business days of first viewing the
                  Candidate information via the Services) inform us in writing of the potential
                  Exempt Candidate and provide reasonable evidence documenting the existence of the
                  conditions set forth above. We will respond within three (3) business days to
                  acknowledge the exemption or request additional information if needed to verify
                  such Candidate’s status as exempt. The Parties will use good faith efforts to
                  resolve any disputes about potential Exempt Candidates, but we will have sole,
                  reasonable discretion over the final determination of an Exempt Candidate.
                </div>
                <div className={styles.termsContentDoubleIndented}>
                  (d)
                  <span className={styles.termsBoldUnderline}>Guarantee.</span>
                  Where a Candidate either (i) is terminated for unsatisfactory performance or (ii)
                  voluntarily resigns from, a Qualified Placement for full-time employment within
                  ninety (90) calendar days of the start date of the applicable Employment
                  Agreement, then, upon written receipt and confirmation of such event, we will
                  promptly credit the total amount you have paid us which will be applied to a
                  replacement hire. In the event that Rainmaker Labs doesn’t replace the position,
                  then the credit will be applied to any subsequent placement. Credits are not
                  available for Placement Fees paid for Employment Agreements for consulting or
                  contracting agreements, for terminations related to a reduction in force or
                  equivalent measures taken by Employer, or for any resignation or termination that
                  occurs more than ninety (90) calendar days following the start date of the
                  applicable Employment Agreement, regardless of the reason for such termination or
                  resignation.
                </div>
              </div>
              <div className={styles.termsContentIndented}>
                5.3.
                <span className={styles.termsBoldUnderline}>Referral Fees.</span>
                There is never any fee owed by a Candidate for his or her use of the Services. We
                may pay a “Referral Fee” to a Candidate who refers another individual to Rainmakers
                and that individual (a) obtains employment with an Employer who pays us a Placement
                Fee and (b) remains so employed more than ninety (90) days after the start date of
                the applicable Employment Agreement). Referral fees will be paid within 120 days of
                the qualifying Candidate’s start date with their applicable Employer.
              </div>
              <div className={styles.termsContentIndented}>
                5.4.
                <span className={styles.termsBoldUnderline}>Tax.</span>
                To the extent that any payments for the Services are subject to any sales or use
                tax, or any equivalent tax measured by sales proceeds that we are permitted to pass
                through to our customers in any jurisdiction (“Sales Tax”) and you have not remitted
                the applicable Sales Tax to us, you will be responsible for the payment of such
                Sales Tax and any related penalties or interest, and you will indemnify us for any
                liability or expense we may incur in connection with such Sales Tax. Upon our
                request, you will provide us with official receipts issued by the appropriate taxing
                authority, or other such evidence that you have paid all applicable taxes. All
                Placement Bonuses are “Miscellaneous Income” as that term is defined by the Internal
                Revenue Service, will be paid in full to Candidates and reported to the IRS on
                1099-Misc. forms.
              </div>
            </div>
            <div className={styles.termsContent}>
              <span className={styles.termsBold}>6. Indemnification and Liability</span>
              <div className={styles.termsContentIndented}>
                6.1.
                <span className={styles.termsBoldUnderline}>Indemnification.</span>
                You will indemnify, defend and hold us, our affiliates, if any, and our officers,
                directors, employees and agents (the “
                <span className={styles.termsBold}>Indemnified Parties</span>”) harmless from any
                claims, demands, actions, costs, liabilities, losses and damages of any kind
                (including attorney&apos;s fees) resulting from: (a) your use, misuse or abuse of
                the Services; or (b) Your Content. You will reasonably cooperate in our defense of
                any claim. We may, at our own expense, assume the exclusive defense and control of
                any matter otherwise subject to indemnification by you, and you will not settle any
                matter without our written consent. This provision does not apply to any
                unconscionable commercial practice or for fraud, deception, false promise,
                misrepresentation or concealment, suppression or omission of any material fact by
                any Indemnified Party in connection with the Services.
              </div>
              <div className={styles.termsContentIndented}>
                6.2.
                <span className={styles.termsBoldUnderline}>DISCLAIMER.</span>
                YOU ACKNOWLEDGE THAT YOU ARE USING THE SERVICES AT YOUR OWN RISK. THE SERVICES ARE
                PROVIDED &ldquo;AS IS,&rdquo; AND TO THE FULLEST EXTENT PERMITTED BY LAW, RAINMAKERS
                EXPRESSLY DISCLAIMS ANY WARRANTIES, EXPRESS AND IMPLIED, INCLUDING ANY WARRANTIES OF
                ACCURACY, COMPLETENESS OR RELIABILITY, TITLE, MERCHANTABILITY OR FITNESS FOR A
                PARTICULAR PURPOSE. WE DO NOT REPRESENT OR WARRANT THAT ACCESS TO THE SERVICES WILL
                BE UNINTERRUPTED OR THAT THERE WILL BE NO FAILURES, ERRORS OR OMISSIONS, OR LOSS OR
                SECURITY BREACH OF TRANSMITTED INFORMATION, OR THAT NO VIRUSES WILL BE TRANSMITTED
                THROUGH ACCESS TO OR USE OF THE SERVICES.
              </div>
              <div className={styles.termsContentIndented}>
                6.3.
                <span className={styles.termsBoldUnderline}>LIMITATION OF LIABILITY.</span>
                WE WILL NOT BE LIABLE TO YOU OR ANY THIRD PARTIES FOR ANY INDIRECT, SPECIAL,
                CONSEQUENTIAL OR PUNITIVE DAMAGES ARISING OUT OF THESE TERMS, YOUR ACCESS TO OR
                INABILITY TO ACCESS THE SERVICES, INCLUDING FOR VIRUSES ALLEGED TO HAVE BEEN
                OBTAINED FROM THE SERVICES, YOUR USE OF OR RELIANCE ON THE SERVICES OR MATERIALS
                AVAILABLE THROUGH THIRD PARTY SITES LINKED TO SITE, REGARDLESS OF THE TYPE OF CLAIM
                OR THE NATURE OF THE CAUSE OF ACTION, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES. UNDER NO CIRCUMSTANCES WILL WE BE LIABLE TO YOU FOR MORE THAN ONE HUNDRED
                DOLLARS ($100.00). ANY CAUSE OF ACTION RELATED TO THE TERMS OR SERVICES MUST
                COMMENCE WITHIN ONE (1) YEAR AFTER IT ACCRUES OR SUCH CAUSE OF ACTION WILL BE
                PERMANENTLY BARRED. THE FOREGOING LIMITATIONS OF LIABILITY APPLY TO THE FULLEST
                EXTENT ALLOWED BY LAW.
              </div>
              <div className={styles.termsContentIndented}>
                6.4.
                <span className={styles.termsBoldUnderline}>USER CONTENT.</span>
                EXCEPT FOR OUR OBLIGATIONS TO PROTECT YOUR PERSONAL DATA AS SET FORTH IN OUR PRIVACY
                POLICY, WE ASSUME NO RESPONSIBILITY FOR THE TIMELINESS, DELETION, MIS-DELIVERY OR
                FAILURE TO STORE ANY CONTENT (INCLUDING YOUR CONTENT AND USER CONTENT), OR
                PERSONALIZATION SETTINGS.
              </div>
              <div className={styles.termsContentIndented}>
                6.5.
                <span className={styles.termsBoldUnderline}>Rainmakers Defined.</span>
                For purposes of this Section 6 only, “
                <span className={styles.termsBold}>Rainmakers</span>,” “
                <span className={styles.termsBold}>we</span>,” or “
                <span className={styles.termsBold}>our</span>” includes our affiliates and
                subsidiaries, if any, and our third party service providers, suppliers, and vendors.
              </div>
            </div>
            <div className={styles.termsContent}>
              <span className={styles.termsBold}>7. Term and Termination</span>
              <div className={styles.termsContentIndented}>
                7.1.
                <span className={styles.termsBoldUnderline}>General.</span>
                The Terms commence on the earlier of the date when you accept them (as described in
                the preamble above), or the date you first used the Services, and remain in effect
                while you use the Services. Either Party may terminate these Terms, for any or no
                reason, immediately upon notice to the other Party. We may provide such notice via
                the site or by email to the address included in the User Information of your
                Account. You may provide such notice by email to{' '}
                <span className={styles.emailLink}>
                  <a href="mailto:support@rainmakers.co">support@rainmakers.co</a>
                </span>{' '}
                or by cancelling on your Account in the administration settings.
              </div>
              <div className={styles.termsContentIndented}>
                7.2.
                <span className={styles.termsBoldUnderline}>Effect of Termination.</span>
                Termination of your Account will remove access to all Services, and we will also
                delete all information associated with your Account, subject to the license rights
                granted in Section 3.3, and provided that we may retain such information solely to
                the extent required to ensure compliance with the payment obligations that survive
                termination pursuant to this Section 7.2. We will have no liability for the
                consequences of any suspension or termination of your Account pursuant to these
                Terms, including for deletion of Your Content. All provisions of the Terms which by
                their nature should survive termination of the Services, including ownership
                provisions, indemnification obligations, payment obligations for Qualified
                Placements that occur following termination, warranty disclaimers, and limitation of
                liability, will do so.
              </div>
              <div className={styles.termsContentIndented}>
                7.3.
                <span className={styles.termsBoldUnderline}>No Subsequent Registration.</span>
                If we terminate your Account for your violation of the Terms, you will not attempt
                to re-register with or access the Services through creation of a new Account or
                otherwise. If you violate the immediately preceding sentence, we may, in our sole
                discretion, immediately take any of the actions set forth herein without advance
                notice to you.
              </div>
            </div>
            <div className={styles.termsContent}>
              <span className={styles.termsBold}>
                8. Jurisdiction, Governing Law and Dispute Resolution.
              </span>
              These Terms, for all purposes, will be governed and interpreted according to the laws
              of the State of California without giving effect to its conflicts of laws provisions
              that would require a different result.
              <div className={styles.termsContentIndented}>
                8.1. Any dispute that may not be brought in small claims court will be resolved by
                binding and confidential arbitration conducted before one commercial arbitrator from
                the American Arbitration Association (“<span className={styles.termsBold}>AAA</span>
                ”) with substantial experience in resolving commercial contract disputes and
                governed by the AAA’s Commercial Arbitration Rules and, if the arbitrator deems them
                applicable, the AAA’s Supplementary Procedures for Consumer Related Disputes
                (collectively “<span className={styles.termsBold}>Rules and Procedures</span>”).
                <div className={styles.termsContentDoubleIndented}>
                  (a) YOU ARE GIVING UP YOUR RIGHT TO GO TO COURT FOR ANY DIPUTE ARISING UNDER THESE
                  TERMS EXCEPT FOR MATTERS THAT MAY BE TAKEN TO SMALL CLAIMS COURT. YOUR RIGHTS WILL
                  BE DETERMINED BY A NEUTRAL ARBITRATOR AND NOT A JUDGE OR JURY. YOU ARE ENTITLED TO
                  A FAIR HEARING, BUT THE ARBITRATION PROCEDURES ARE SIMPLER AND MORE LIMITED THAN
                  RULES APPLICABLE IN COURT. ARBITRATOR DECISIONS ARE AS ENFORCEABLE AS ANY COURT
                  ORDER AND ARE SUBJECT TO VERY LIMITED REVIEW BY A COURT.
                </div>
                <div className={styles.termsContentDoubleIndented}>
                  (b) ANY CLAIMS BROUGHT BY EITHER PARTY MUST BE BROUGHT IN THE PARTIES’ INDIVIDUAL
                  CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
                  REPRESENTATIVE PROCEEDING, AND THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE
                  PERSON’S CLAIMS, MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR
                  CLASS PROCEEDING, AND MAY NOT AWARD CLASS-WIDE RELIEF.
                </div>
                <div className={styles.termsContentDoubleIndented}>
                  (c) The arbitration award will be final and binding upon the parties without
                  appeal or review except as permitted by State of California law or United States
                  federal law.
                </div>
                <div className={styles.termsContentDoubleIndented}>
                  (d) Notwithstanding the foregoing (i) either party may bring an individual action
                  in small claims court, and (ii) claims of (A) defamation, (B) violation of the
                  Computer Fraud and Abuse Act, or (C) infringement or misappropriation of the other
                  party’s intellectual property rights may be exclusively brought in the state or
                  federal courts located in the San Francisco County, California, and the Parties
                  agree to submit to the exclusive personal jurisdiction of such courts for such
                  purpose. A request for equitable relief will not be deemed a waiver of the right
                  to arbitrate.
                </div>
              </div>
              <div className={styles.termsContentIndented}>
                8.2. With the exception of Section 8.1(b), if any part of Section 8.1 is deemed to
                be invalid or unenforceable for any reason, then the balance of Section 8.1 will
                remain in effect. If, however, Section 8.1(b) is found to be invalid or
                unenforceable for any reason, then Section 8.1 will be null and void, neither party
                will be entitled to arbitration, and any claims relating to the Terms or Services
                will be exclusively brought in the state or federal courts located in San Francisco
                County, California.
              </div>
            </div>
            <div className={styles.termsContent}>
              <span className={styles.termsBold}>9. Miscellaneous</span>
              <div className={styles.termsContentIndented}>
                9.1.
                <span className={styles.termsBoldUnderline}>
                  Electronic Communications and Notice.
                </span>
                You consent to receive operational communications from us electronically, including
                via email, text (if you have provided your cell phone number in connection with the
                Services) or our posting of notices on the site. You may cancel the Services as set
                forth in Section 7.1. Communications intended to have legal effect should be sent to
                1110 Montgomery St., San Francisco, CA 94133 by personal delivery, certified mail
                (postage pre-paid, return receipt requested), or a commercial courier requiring
                signature for delivery, and will be effective on receipt or when delivery is
                refused.
              </div>
              <div className={styles.termsContentIndented}>
                9.2.
                <span className={styles.termsBoldUnderline}>International Users.</span>
                The Services can be accessed from countries around the world and may contain
                references to Services that are not available in your country. These references do
                not imply that we intend to announce such Services in your country. We control and
                offer the Services from our facilities in the United States of America. We make no
                representations that the Services are legal, appropriate or available for use in
                other locations.
              </div>
              <div className={styles.termsContentIndented}>
                9.3.
                <span className={styles.termsBoldUnderline}>Assignment.</span>
                The Terms, and your rights and obligations hereunder, may not be assigned,
                subcontracted, delegated or otherwise transferred by you without our prior written
                consent, and any attempted assignment, subcontract, delegation, or transfer in
                violation of the foregoing will be null and void.
              </div>
              <div className={styles.termsContentIndented}>
                9.4.
                <span className={styles.termsBoldUnderline}>Force Majeure.</span>
                We will not be liable for any delay or failure to perform resulting from causes
                outside our reasonable control, including acts of God, war, terrorism, riots,
                embargos, acts of civil or military authorities, fire, floods, accidents, strikes or
                shortages of transportation facilities, fuel, energy, labor or materials.
              </div>
              <div className={styles.termsContentIndented}>
                9.5.
                <span className={styles.termsBoldUnderline}>Severability.</span>
                If one or more portions of these Terms is held to be unenforceable, the remaining
                portions will remain in full force and effect.
              </div>
              <div className={styles.termsContentIndented}>
                9.6.
                <span className={styles.termsBoldUnderline}>Headings and Construction.</span>
                The headings used throughout these Terms are solely for convenience of reference and
                are not to be used as an aid in the interpretation of the Terms. As used herein,
                “may” means “has the right, but not the obligation to,” “including” means
                “including, without limitation,” and “will” means “is required to.
              </div>
              <div className={styles.termsContentIndented}>
                9.7.
                <span className={styles.termsBoldUnderline}>Waiver.</span>
                If we fail to enforce any provision of these Terms, it will not be considered
                consent to a breach of such provision or a waiver of our right to enforce such
                provision in the future.
              </div>
              <div className={styles.termsContentIndented}>
                9.8.
                <span className={styles.termsBoldUnderline}>Entire Agreement.</span>
                These Terms make up the entire agreement between you and us regarding the Services
                and supersede any prior agreements.
              </div>
            </div>
            <div className={styles.termsContent}>
              If you have any questions about these terms, contact us at{' '}
              <span className={styles.emailLink}>
                <a href="mailto:support@rainmakers.co">support@rainmakers.co</a>
              </span>
              .
            </div>
          </div>
        </Block>
        <Footer />
      </ReadyContainer>
    </div>
  );
};

export default Terms;
