import React, { Component } from 'react';
import queryString from 'query-string';

import ConnectContainer from '../../containers/ConnectContainer';
import Validator from '../../connectors/Validator';

import Block from '../../components/Block';
import Input from '../../components/Input';

import duck from './redux';

import styles from './NewPassword.scss';

class NewPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      passwordConfirmation: '',
      errors: {
        password: {
          isNotEmpty: [null],
          minLength: [null, 8],
          hasANumber: [null],
          isPassword: [null],
        },
        passwordConfirmation: {
          doesEqual: [false, ''],
        },
      },
      passwordValid: false,
      passwordConfirmationValid: false,
    };
  }

  handleChange = (event) => {
    const {
      target: { name, value },
    } = event;

    const { errors } = this.state;

    if (name === 'password') {
      const newErrors = {
        ...errors,
        passwordConfirmation: {
          doesEqual: [errors.passwordConfirmation.doesEqual[0], value],
        },
      };

      const { inputErrors, isValid } = Validator.checkErrors({
        errorObject: newErrors,
        newValue: value,
        name,
      });

      this.setState({
        [name]: value,
        errors: inputErrors,
        passwordValid: isValid,
      });
    } else {
      const { inputErrors, isValid } = Validator.checkErrors({
        errorObject: errors,
        newValue: value,
        name,
      });

      this.setState({
        [name]: value,
        passwordConfirmationValid: isValid,
        errors: inputErrors,
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const {
      actions: { newPasswordStarted, confirmPasswordStarted },
      location: { search, pathname },
    } = this.props;

    const isActivateRoute = pathname === '/activate-account';

    const {
      'reset_password_token': newResetPasswordToken,
      'reset-password-token': oldResetPasswordToken,
      'confirmation_token': newConfirmationToken,
      'requisition_opening_id': requisitionOpeningId,
    } = queryString.parse(search);

    const resetPasswordToken = newResetPasswordToken || oldResetPasswordToken || null;

    const confirmationToken = newConfirmationToken || oldResetPasswordToken || null;

    const token = isActivateRoute ? confirmationToken : resetPasswordToken;

    const { password, passwordConfirmation } = this.state;

    const args = {
      password,
      passwordConfirmation,
      requisitionOpeningId,
      token,
    };

    isActivateRoute ? confirmPasswordStarted(args) : newPasswordStarted(args);
  };

  makeErrorText = (error) => {
    switch (error) {
      case 'Invalid login credentials. Please try again.':
        return 'The email does not match our records.';
      default:
        return 'Login failed';
    }
  };

  render() {
    const {
      children,
      location: { pathname },
      newPasswordErrors,
    } = this.props;

    const isActivateRoute = pathname === '/activate-account';

    const {
      password,
      passwordConfirmation,
      errors: { password: passwordErrors, passwordConfirmation: passwordConfirmationErrors },
      passwordValid,
      passwordConfirmationValid,
    } = this.state;

    const isValid = passwordValid && passwordConfirmationValid;

    const errorText = newPasswordErrors ? this.makeErrorText(newPasswordErrors[0]) : null;

    const title = isActivateRoute ? (
      <div className={styles.loginTitle}>Set your password</div>
    ) : (
      <div className={styles.loginTitle}>Reset your password</div>
    );

    const blockProps = {
      addWhiteBG: true,
      addPadding: true,
    };

    const passwordInputProps = {
      autoComplete: 'new-password',
      label: 'New password',
      placeholder: '********',
      value: password,
      handleInputChange: this.handleChange,
      type: 'password',
      name: 'password',
      size: 'full',
      className: styles.loginInput,
      errors: passwordErrors,
    };

    const passwordConfirmationInputProps = {
      autoComplete: 'new-password',
      label: 'Confirm new password',
      placeholder: '********',
      value: passwordConfirmation,
      handleInputChange: this.handleChange,
      type: 'password',
      name: 'passwordConfirmation',
      size: 'full',
      className: styles.loginInput,
      errors: passwordConfirmationErrors,
    };

    const buttonValue = isActivateRoute ? 'Set password' : 'Update';

    const submitProps = {
      className: isValid ? styles.submitInput : styles.inputDisabled,
      disabled: !isValid,
      type: 'submit',
      value: buttonValue,
    };

    return (
      <div className={styles.LoginContainer}>
        <Block {...blockProps}>
          <div className={styles.innerLoginContainer}>
            {title}
            <form method="post" className={styles.loginForm} onSubmit={this.handleSubmit}>
              <div className={styles.loginError}>{errorText}</div>
              <div className={styles.loginInput}>
                <Input {...passwordInputProps} />
              </div>
              <div className={styles.loginInput}>
                <Input {...passwordConfirmationInputProps} />
              </div>

              <div className={styles.loginOptions}>
                <input {...submitProps} />
              </div>
              {children}
            </form>
          </div>
        </Block>
      </div>
    );
  }
}

export default ConnectContainer(duck)(NewPassword);
