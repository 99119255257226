import React, { useState } from 'react';
import classnames from 'classnames';
import v4 from 'uuid';

import Button from 'components/Button';

import styles from './FormattedArray.scss';

const FormattedArray = ({ className, array = [], format, icon, cutOff, charMax, flat }) => {
  const [state, setState] = useState({ showAll: !cutOff || cutOff >= array.length });

  if (!array || array.every((el) => !el)) return null;

  const { showAll } = state;

  const cutArray = showAll ? array : array.slice(0, cutOff);

  const listContent = cutArray.map((item) => {
    const itemContent = item && item.length > charMax ? `${item.substr(0, charMax)}...` : item;

    return flat ? (
      <li title={item} key={v4()}>
        {itemContent}
      </li>
    ) : (
      <li title={item} key={v4()} className={icon ? styles.listItemIcon : styles.listItem}>
        {icon}
        {itemContent}
      </li>
    );
  });

  const stringFormat =
    format === 'string' ? (
      <span className={className}>{array.length > 1 ? array.join(', ') : array[0]}</span>
    ) : null;

  const showMoreButton = !showAll ? (
    <Button
      onClick={() => setState((prevState) => ({ ...prevState, showAll: true }))}
      className={styles.showMore}
      tertiaryThin={true}
    >
      Show more
    </Button>
  ) : (
    <Button
      onClick={() => setState((prevState) => ({ ...prevState, showAll: false }))}
      className={styles.showMore}
      tertiaryThin={true}
    >
      Show less
    </Button>
  );

  const listFormat = (
    <ul className={classnames(styles.list, className)}>
      {listContent}
      {cutOff && array.length > cutOff ? showMoreButton : null}
    </ul>
  );

  return stringFormat || listFormat;
};

export default FormattedArray;
