export function handleWebUrl(value) {
  if (value === '' || value === null || value === undefined) return null;

  return value.startsWith('https://') || value.startsWith('http://') ? value : `https://${value}`;
}

export function getAltName(pic) {
  // http://localhost:7778/dist/assets/3-Atlanta-Best-Tech-Sales-Opportunities.60821369.png
  // to
  // 3-Atlanta-Best-Tech-Sales-Opportunities
  try {
    const filename = pic.substring(pic.lastIndexOf('/') + 1);

    return filename.slice(0, filename.indexOf('.'));
  } catch (error) {
    return 'alt';
  }
}
