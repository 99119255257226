import React from 'react';

import ContentTitle from 'components/ContentTitle';
import DropdownSelectMany from 'components/DropdownSelectMany';
import EmployerData from 'components/EmployerData';
import FormattedName from 'components/FormattedName';
import FormattedDate from 'components/FormattedDate';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import Table from 'components/Table';
import TableRow from 'components/Table/components/TableRow';
import TableCell from 'components/Table/components/TableCell';

import ToolboxApproachesActionDropdown from 'pages/AdminPortal/ToolboxApproachesPage/components/ToolboxApproachesActionDropdown';

import styles from './CandidateApproachesTable.scss';

const STATUSES = [
  { label: 'Active', value: 'active' },
  { label: 'Hired', value: 'hired' },
  { label: 'Passed', value: 'passed' },
  { label: 'Candidate Passed', value: 'candidate_passed' },
  { label: 'Candidate Pass Pending', value: 'candidate_pass_pending' },
  { label: 'Employer Passed', value: 'employer_passed' },
  { label: 'Inactive', value: 'inactive' },
];

const CandidateApproachesTable = ({
  actions,
  employers: { byId: employersById = {} } = {},
  firstName,
  handleSearchFilterChange,
  lastName,
  loadMore,
  privateReqCanFeedback: { byId: privateReqCanFeedbackById = {} } = {},
  requisitionCandidates: { byId = {}, allIds = [] } = {},
  requisitionCandidatesTotal,
  requisitionOpenings: { byId: requisitionOpeningsById = {} } = {},
  state,
  ...others
}) => {
  const tableHeader = [
    {
      content: 'Employer',
      headerClassName: styles.companyHeader,
    },
    {
      content: 'Status',
      headerClassName: styles.otherHeader,
    },
    {
      content: 'Created At',
      headerClassName: styles.otherHeader,
    },
    {
      content: 'Creator Name',
      headerClassName: styles.otherHeader,
    },
    {
      content: '',
      headerClassName: styles.candActionHeader,
    },
  ];

  const makeEmployerRows = (candidateApproach) => {
    const {
      attributes: { attentionRequired = false, createdAt, state } = {},
      id,
      relationships: {
        employer: { data: employerIds = [] } = {},
        creator: { data: creatorIds = [], type: creatorTypes = [] } = {},
        requisitionOpeningCopy: { data: requisitionOpeningIds = [] } = {},
      } = {},
    } = candidateApproach || {};

    const creatorType = `${creatorTypes[0]}s`;

    const { [creatorType]: { byId: creatorsById = {} } = {} } = others;

    const { attributes: { externalName = '' } = {} } =
      requisitionOpeningsById[requisitionOpeningIds[0]] || {};

    const {
      attributes: {
        firstName: creatorFirstName = '',
        lastName: creatorLastName = '',
        type = '',
      } = {},
    } = creatorsById[creatorIds[0]] || {};

    const { attributes: employer = {} } = employersById[employerIds[0]] || {};

    const employerDataProps = {
      employer,
      subtitle: externalName,
    };

    const employerContent = (
      <div className={styles.employerContent}>
        <EmployerData {...employerDataProps} />
      </div>
    );

    const statusContent = <div className={styles.statusContent}>{state}</div>;

    const createdAtContent = (
      <div className={styles.statusContent}>
        <FormattedDate parse date={createdAt} format="MMM D YYYY" />
      </div>
    );

    const creatorNameContent =
      type === 'RainmakersAdmin' ? (
        <div className={styles.statusContent}>Admin</div>
      ) : (
        <div className={styles.statusContent}>
          <FormattedName firstName={creatorFirstName} lastName={creatorLastName} />
        </div>
      );

    const privateReqCanFeedbackIds = Object.keys(privateReqCanFeedbackById).filter(
      (privateId) =>
        privateReqCanFeedbackById[privateId] &&
        privateReqCanFeedbackById[privateId].attributes.requisitionCandidateId === id
    );

    const privateReqCanFeedbackCandidateId = privateReqCanFeedbackIds.find(
      (id) => privateReqCanFeedbackById[id].attributes.ownerType === 'Candidate'
    );

    const privateReqCanFeedbackEmployeeId = privateReqCanFeedbackIds.find(
      (id) => privateReqCanFeedbackById[id].attributes.ownerType === 'Employee'
    );

    const privateReqCanFeedbackCandidate =
      privateReqCanFeedbackById[privateReqCanFeedbackCandidateId] || {};
    const privateReqCanFeedbackEmployee =
      privateReqCanFeedbackById[privateReqCanFeedbackEmployeeId] || {};

    const actionsProps = {
      actions,
      attentionRequired,
      firstName,
      lastName,
      privateReqCanFeedbackCandidate,
      privateReqCanFeedbackEmployee,
      requisitionCandidate: candidateApproach,
      slice: 'toolboxCandidates',
      state,
    };

    const actionsContent = (
      <div className={styles.actionsContent}>
        <ToolboxApproachesActionDropdown {...actionsProps} />
      </div>
    );

    const row = (
      <TableRow id={id} key={id} className={styles.employerRow}>
        <TableCell key={1}>{employerContent}</TableCell>
        <TableCell flex key={2}>
          {statusContent}
        </TableCell>
        <TableCell flex key={4}>
          {createdAtContent}
        </TableCell>
        <TableCell flex key={5}>
          {creatorNameContent}
        </TableCell>
        <TableCell noFlex key={6}>
          {actionsContent}
        </TableCell>
      </TableRow>
    );

    return row;
  };

  const tableContent = allIds.map((id) => byId[id]).map(makeEmployerRows);

  const infiniteScrollProps = {
    pageStart: 0,
    loadMore,
    hasMore: requisitionCandidatesTotal > allIds.length,
    loader: <InfiniteScrollLoader key="infiniteScrollLoader" />,
    useWindow: false,
  };

  const stateSource = STATUSES.map((state) => ({
    indent: 0,
    label: state.label,
    optionType: 'role',
    subCategories: [],
    value: {
      type: 'state',
      state: state.value,
    },
  }));

  const selectedStates = state.map((state) => ({ state, type: 'state' }));

  const stateFilterProps = {
    className: styles.searchBarFilter,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    source: stateSource,
    name: 'state',
    key: 'state',
    optionsLarge: true,
    isLocation: true,
    onChange: (value) =>
      handleSearchFilterChange({
        target: {
          subCategories: value.subCategories,
          label: value.label,
          value: value.value,
          name: 'state',
          optionType: value.optionType,
        },
      }),
    selectedValues: selectedStates,
    defaultLabel: 'Click to select status',
  };

  const filtersContent = (
    <div className={styles.candidateHeader}>
      <ContentTitle className={styles.title}>APPROACHES</ContentTitle>
      <div className={styles.stack}>
        <DropdownSelectMany {...stateFilterProps} />
      </div>
    </div>
  );

  const tableProps = {
    titleContent: filtersContent,
    noBlockFlex: true,
    header: tableHeader,
    tableContent,
    infiniteScrollProps,
    tableName: 'candidateApproaches',
    isEmpty: allIds.length === 0,
    emptyTextString: 'No Approaches',
  };

  return <Table {...tableProps} />;
};

export default CandidateApproachesTable;
