import React, { useState, useEffect, useRef } from 'react';

import { handleError } from 'utils/common';
import { getInitials } from 'utils/generalUtils';

import { promiseGetRequisitionCandidates } from 'pages/EmployeePortal/CandidateProfilePages/promises';

import ConnectContainer from 'containers/ConnectContainer';

import duck from 'shared/store/app';
import { usePrevious } from 'hooks';

const FormattedName = (props) => {
  const [noReqCand, setNoReqCand] = useState(null);
  const _mounted = useRef(false);
  const prevCandidateId = usePrevious(props.candidateId);

  useEffect(() => {
    _mounted.current = true;

    const { useHideNames, hideNames } = props;

    if (useHideNames && hideNames) {
      const { candidateId } = props;

      handleGetReqCand(candidateId);
    }

    return () => {
      _mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { candidateId, useHideNames, hideNames } = props;

    if (useHideNames && hideNames && candidateId !== prevCandidateId) {
      handleGetReqCand(candidateId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.candidateId]);

  const handleGetReqCand = (candidateId) => {
    promiseGetRequisitionCandidates({ candidateId })
      .then((reqCandidates) => _mounted && setNoReqCand(reqCandidates.length === 0))
      .catch((error) => {
        handleError(error);
        _mounted && setNoReqCand(true);
      });
  };

  const {
    useHideNames,
    useBlankHideNames,
    className,
    firstName = '',
    hideNames,
    lastName = '',
    lastInitial,
    name,
    placeholderClassname,
    alwaysUseHideName = false,
  } = props;

  const first = name ? name.split(' ')[0] : firstName;

  const last = name ? name.split(' ')[1] : lastName;

  const lastNameInitial = `${last[0]}.`;

  const lastNameFormatted = lastInitial ? lastNameInitial : last;

  const formattedName = `${first} ${lastNameFormatted}`;
  const initializedName = getInitials({ firstName, lastName });

  if (!useBlankHideNames && (!useHideNames || !hideNames)) {
    return <span className={className}>{formattedName}</span>;
  }

  if (useBlankHideNames && hideNames) {
    const nameToUse = noReqCand ? initializedName : formattedName;
    const actualNameToUse =
      noReqCand === null ? <div className={placeholderClassname} /> : nameToUse;
    return <span className={className}>{actualNameToUse}</span>;
  }

  if (useBlankHideNames && !hideNames && (noReqCand || noReqCand === null)) {
    return <span className={className}>{formattedName}</span>;
  }

  const nameToUse =
    alwaysUseHideName || noReqCand || noReqCand === null ? initializedName : formattedName;
  return <span className={className}>{nameToUse}</span>;
};

export default ConnectContainer(duck)(FormattedName);
