import React, { useState, useRef, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';

import { useOnClickOutside } from 'hooks';

import User from 'connectors/User';
import Navigation from 'components/Navigation';

import HeaderLogo from '../HeaderLogo';
import FontIcon from '../FontIcon';

import styles from './AdminHeader.scss';

const ADMIN_LINKS = [
  {
    to: `https://${process.env.FORUM_LINK}`,
    title: 'Community',
    target: '_self',
    activePaths: [],
    hide: !process.env.FORUM_LINK,
  },
  {
    to: '/featured',
    title: 'Find Talent',
    activePaths: ['matches', 'featured', 'bookmarks'],
  },
  {
    to: '/toolbox/candidates/',
    title: 'Toolbox',
    activePaths: ['toolbox'],
  },
];

const AdminHeader = () => {
  const location = useLocation();
  const [showNav, setShowNav] = useState(false);
  const ref = useRef();

  useOnClickOutside(
    ref,
    useCallback(() => setShowNav(false), [])
  );

  const makeLinks = (link) => {
    const { title, to, button = false, activePaths, width, target } = link;

    const isActive = activePaths && activePaths.includes(location.pathname.split('/')[1]);

    const linkClasses = classnames(styles.navLink, {
      [styles.activeNavLink]: isActive,
      [styles.button]: button,
    });

    const linkProps = {
      href: to,
      to,
      key: title,
      className: linkClasses,
      style: { width },
      target,
      ['data-title']: title,
    };

    return target ? <a {...linkProps}>{title}</a> : <Link {...linkProps}>{title}</Link>;
  };

  const linksToUse = ADMIN_LINKS.filter((link) => !link.hide);

  const links = linksToUse.map(makeLinks);

  const nav = <Navigation className={styles.NavBlock}>{links}</Navigation>;

  const navDropdown = showNav ? (
    <div className={styles.NavDropdown} onClick={() => setShowNav(false)}>
      {links}
    </div>
  ) : null;

  const hamburgerProps = {
    onClick: () => setShowNav((showNav) => !showNav),
    className: classnames(styles.NavHamburger, {
      [styles.seafoam]: showNav,
    }),
  };

  const navHamburger = (
    <div {...hamburgerProps}>
      <FontIcon iconName="menu" />
    </div>
  );

  const logoLink = '/toolbox/candidates/';

  const headerContainerClasses = classnames(styles.HeaderContainer, {
    [styles.headerBoxShadow]: true,
  });

  return (
    <header className={headerContainerClasses}>
      <div className={styles.Header}>
        <div className={styles.LogoBlock}>
          <Link to={logoLink}>
            <HeaderLogo />
          </Link>
        </div>

        <div className={styles.navLinksContainer}>
          {navHamburger}
          {navDropdown}
          {nav}
          <div onClick={() => setShowNav(false)}>
            <User />
          </div>
        </div>
      </div>
    </header>
  );
};

export default AdminHeader;
