import { all, call, select, put, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { handleError } from 'utils/common';
import { trackEvent } from 'utils/analytics';
import { segmentUploadResumeCandidate } from 'segment/eventNames';

import { getNestedEntity, patchNestedEntity, patchEntity, postNestedEntity } from 'api/apiEntity';

import { candidateProfilePatchDone } from 'connectors/User/redux/creators';

import { selectUser } from 'connectors/User/redux/selectors';

import { UPLOAD_RESUME_STARTED } from '../actions';

import { serverErrorSet, parseResumeStarted, parseResumeDone } from '../creators';

import { selectRecentRole } from '../selectors';

export const trackUploadResumeCandidate = () => {
  const event = segmentUploadResumeCandidate;

  const properties = {};

  const eventObject = { event, properties };

  trackEvent(eventObject);
};

const sagaUploadResumeStarted = function* sagaUploadResumeStarted() {
  while (true) {
    const {
      payload: { resume, redirect, recentGrad },
    } = yield take(UPLOAD_RESUME_STARTED);

    if (redirect) {
      yield put(push('/verify-profile'));
    }

    const {
      currentProfile: { id: candidateId },
    } = yield select(selectUser);

    const recentRole = yield select(selectRecentRole);

    yield put(parseResumeStarted());

    try {
      const formData = new FormData();
      formData.append('data[attributes][resume]', resume);
      formData.append('data[type]', 'candidate');
      formData.append('data[id]', candidateId);

      const args = {
        data: formData,
        id: candidateId,
        type: 'candidates',
      };

      const {
        data: { candidate: origCandidate },
      } = yield call(patchEntity, args);

      const postArgs = {
        id: candidateId,
        type: 'candidates',
        nestedType: 'parse_resume',
      };

      yield call(postNestedEntity, postArgs);

      const candEduArgs = {
        id: candidateId,
        type: 'candidates',
        nestedType: 'candidate_educations',
        config: {
          params: {
            includeSet: 'education_organization',
            sortBy: 'finished_at_desc_nulls_first,started_at_desc',
            camelizeIt: true,
          },
        },
      };

      const workHistArgs = {
        id: candidateId,
        type: 'candidates',
        nestedType: 'work_histories',
        config: {
          params: {
            includeSet: 'company_sales_periods',
            sortBy: 'end_date_desc_nulls_first,start_date_desc',
            camelizeIt: true,
          },
        },
      };

      const [
        { data: { workHistories = [] } = {} },
        { data: { candidateEducations = [] } = {} },
      ] = yield all([call(getNestedEntity, workHistArgs), call(getNestedEntity, candEduArgs)]);

      const latestWorkHistory = workHistories[0] || {};

      if (
        recentRole &&
        latestWorkHistory &&
        latestWorkHistory.id &&
        latestWorkHistory.role !== recentRole
      ) {
        const putWhArgs = {
          data: {
            id: latestWorkHistory.id,
            type: 'work_history',
            attributes: {
              role: recentRole,
            },
          },
          config: {
            params: {
              includeSet: 'company_sales_periods',
              camelizeIt: true,
            },
          },
          type: 'candidates',
          nestedType: 'work_histories',
          id: candidateId,
          nestedId: latestWorkHistory.id,
        };

        const {
          data: { workHistory: newWorkHistory },
        } = yield call(patchNestedEntity, putWhArgs);

        workHistories[0] = {
          ...workHistories[0],
          role: newWorkHistory.role,
          salesPeriods: newWorkHistory.salesPeriods,
        };

        const fullCandidate = {
          ...origCandidate,
          workHistories,
          candidateEducations: recentGrad
            ? candidateEducations.map((edu, idx) => {
                if (idx === 0) {
                  return {
                    ...edu,
                    isEditing: true,
                  };
                }

                return edu;
              })
            : candidateEducations,
        };

        yield call(trackUploadResumeCandidate);

        yield put(candidateProfilePatchDone({ candidateProfile: fullCandidate }));

        yield put(parseResumeDone());
      } else {
        const fullCandidate = {
          ...origCandidate,
          workHistories,
          candidateEducations,
        };

        yield call(trackUploadResumeCandidate);

        yield put(candidateProfilePatchDone({ candidateProfile: fullCandidate }));

        yield put(parseResumeDone());
      }
    } catch (error) {
      handleError(error);

      const {
        response: {
          data: { errors: { full_messages: errors = ['Please try again'] } = {} } = {},
        } = {},
      } = error || {};

      yield put(serverErrorSet({ errors }));
    }
  }
};

export default sagaUploadResumeStarted;
