import React, { Component, Fragment } from 'react';

import Button from 'components/Button';
import Content from 'components/Content';
import DialogueModal from 'components/DialogueModal';
import DropdownContent from 'components/DropdownContent';
import Label from 'components/Label';

import GroupMember from '../GroupMember';
import SendgridEmailEvents from '../SendgridEmailEvents';

import styles from '../Message/Message.scss';

class MessageDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      messageRecipientId: null,
    };
  }

  handleMessageDetailsClick = () => {
    const {
      actions: { entityLoadStarted },
      messageId,
    } = this.props;

    entityLoadStarted({
      type: 'message_recipients',
      slice: 'toolboxApproaches',
      params: {
        page: 1,
        messageId,
        includeSet: 'sendgrid_email_events',
        sortBy: 'profile_type_desc,profile_id_desc,created_at_desc',
      },
    });
  };

  handleResendMessageClick = () => {
    const {
      actions: { patchResource, resourceUpdate } = {},
      messageRecipients: { byId },
    } = this.props;

    const { messageRecipientId } = this.state;

    const resource = {
      ...byId[messageRecipientId],
      attributes: {
        ...byId[messageRecipientId].attributes,
        sendCount: byId[messageRecipientId].attributes.sendCount + 1,
      },
    };

    resourceUpdate({
      id: messageRecipientId,
      type: 'message_recipients',
      slice: 'toolboxApproaches',
      resource,
    });

    patchResource({
      id: messageRecipientId,
      type: 'message_recipients',
      attributes: {
        emailSent: null,
      },
    });

    this.setState({ messageRecipientId: null });
  };

  render() {
    const { messageRecipientId } = this.state;

    const {
      isAdmin,
      candidates,
      employees,
      messageGroupMembers: {
        allIds: messageGroupMembersAllIds = {},
        byId: messageGroupMembersById = {},
      } = {},
      messageRecipients: {
        allIds: messageRecipientsAllIds = [],
        byId: messageRecipientsById = {},
      } = {},
      rainmakersAdmins,
      sendgridEmailEvents: { byId: sendgridEmailEventsById = {} } = {},
    } = this.props;

    const dropdownContent =
      messageRecipientsAllIds.length > 0 ? (
        messageRecipientsAllIds.map((id) => {
          const {
            attributes: { profileId, sendCount } = {},
            relationships: { sendgridEmailEvents: { data: sendgridEmailEventsIds = [] } = {} } = {},
          } = messageRecipientsById[id] || {};

          const memberId = messageGroupMembersAllIds.find(
            (id) => messageGroupMembersById[id].attributes.profileId === profileId
          );

          const groupMemberProps = {
            member: messageGroupMembersById[memberId],
            employees,
            candidates,
            rainmakersAdmins,
          };

          const sendgridEmailEventsProps = {
            sendgridEmailEvents: sendgridEmailEventsIds.map((id) => sendgridEmailEventsById[id]),
          };

          const resendButtonProps = {
            onClick: () => this.setState({ messageRecipientId: id }),
            tertiary: true,
            className: styles.resendButton,
          };

          const resendButton = sendCount ? <Button {...resendButtonProps}>Resend</Button> : null;

          return (
            <div key={id} className={styles.messageRecipientColumn}>
              <GroupMember {...groupMemberProps} />
              <Content className={styles.sendCount}>
                <Content>
                  <Label># of times sent:</Label> {sendCount}
                </Content>{' '}
                {resendButton}
              </Content>
              <SendgridEmailEvents {...sendgridEmailEventsProps} />
            </div>
          );
        })
      ) : (
        <Content className={styles.emptyContainer}>No message recipients</Content>
      );

    const dropdownProps = {
      onClick: this.handleMessageDetailsClick,
      className: styles.addSomeCandidatesDropdown,
      label: 'Details',
      defaultIcon: 'caret-down',
      activeIcon: 'caret-up',
      dropLeft: true,
    };

    const cancelButtonProps = {
      onClick: () => this.setState({ messageRecipientId: null }),
      quaternary: true,
      className: 'ignore-react-onclickoutside',
    };

    const confirmButtonProps = {
      onClick: this.handleResendMessageClick,
      primary: true,
      className: 'ignore-react-onclickoutside',
    };

    const warningModal = messageRecipientId ? (
      <DialogueModal>
        <Content className={styles.modalContainer}>
          <Content>This will re-send the message through email.</Content>
          <Content className={styles.buttonContainer}>
            <Button {...cancelButtonProps}>Cancel</Button>
            <Button {...confirmButtonProps}>Confirm</Button>
          </Content>
        </Content>
      </DialogueModal>
    ) : null;

    const dropdownContentFinal =
      isAdmin > 0 ? (
        <DropdownContent {...dropdownProps}>
          <div className={styles.detailsContainer}>{dropdownContent}</div>
        </DropdownContent>
      ) : null;

    return (
      <Fragment>
        {dropdownContentFinal}
        {warningModal}
      </Fragment>
    );
  }
}

export default MessageDetails;
