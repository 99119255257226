import { call, select, put, take } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { selectUser } from 'connectors/User/redux/selectors';

import { batchRequestLoadDone } from '../creators';

import { BATCH_REQUEST_LOAD_STARTED } from '../actions';

import { promiseGetBatchRequest } from '../../promises';

const sagaBatchRequestLoadStarted = function* sagaBatchRequestLoadStarted() {
  while (true) {
    yield take(BATCH_REQUEST_LOAD_STARTED);

    const { currentProfile: { id: candidateId } = {} } = yield select(selectUser);

    if (candidateId) {
      try {
        const promiseParams = {
          candidateId,
        };

        const batchRequest = yield call(promiseGetBatchRequest, promiseParams);

        yield put(batchRequestLoadDone({ batchRequest }));
      } catch (error) {
        handleError(error);
      }
    }
  }
};

export default sagaBatchRequestLoadStarted;
