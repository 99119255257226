import React from 'react';
import classnames from 'classnames';
import styles from './CharacterCounter.scss';

const CharacterCounter = ({ charactersToCount, numCharacters, className }) => {
  if (!charactersToCount || !numCharacters) {
    return null;
  }

  const currentNumCharacters = charactersToCount.length;

  const content = `${currentNumCharacters}/${numCharacters}`;

  return <span className={classnames(styles.characterCount, className)}>{content}</span>;
};

export default CharacterCounter;
