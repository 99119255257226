import React from 'react';
import classnames from 'classnames';
import { v4 } from 'uuid';

import styles from './InputCheckboxSlider.scss';

const InputCheckboxSlider = ({
  name,
  label,
  thinLabel,
  required,
  handleInputChange,
  checked,
  disabled,
  className,
}) => {
  const mainClasses = classnames(styles.InputCheckboxSlider, className);

  const inputClasses = classnames(styles.inputStyle, {
    [styles.required]: required,
    [styles.disabled]: disabled,
  });

  const labelClasses = classnames(styles.labelStyle, {
    [styles.thinLabel]: thinLabel,
    [styles.disabledLabel]: disabled,
  });

  const uniqueId = v4();

  const inputProps = {
    name,
    type: 'checkbox',
    checked,
    onChange: handleInputChange,
    className: inputClasses,
    disabled,
    id: uniqueId,
  };

  return (
    <div className={mainClasses}>
      <input {...inputProps} />
      <label className={labelClasses} htmlFor={uniqueId}>
        <span className={styles.labelContent}>{label}</span>
      </label>
    </div>
  );
};

export default InputCheckboxSlider;
