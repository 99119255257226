import { call, put, take } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { deleteEntity } from 'api/apiEntity';

import { TEMPLATE_DELETE_STARTED } from '../actions';

import { templateDeleteDone } from '../creators';

const sagaTemplateDeleteStarted = function* sagaTemplateDeleteStarted() {
  while (true) {
    const {
      payload: { templateId },
    } = yield take(TEMPLATE_DELETE_STARTED);

    const config = {
      type: 'message_templates',
      id: templateId,
    };

    try {
      yield call(deleteEntity, config);

      yield put(templateDeleteDone({ templateId }));
    } catch (error) {
      handleError(error);
    }
  }
};

export default sagaTemplateDeleteStarted;
