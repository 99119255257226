import React, { useContext } from 'react';

import FontIcon from 'components/FontIcon';

import { NotesContext } from 'pages/AdminPortal/ToolboxCandidateEditorPage/NotesContext';

import styles from './NotesButton.scss';

export const NotesButton = () => {
  const { setShowNotes } = useContext(NotesContext);

  return (
    <div
      className={styles.notesButton}
      onClick={() => {
        setShowNotes(true);
      }}
    >
      <FontIcon iconName="caret-left" />
      <div className={styles.notesButton_label}>Notes</div>
    </div>
  );
};
