import { call } from 'redux-saga/effects';

import Validator from 'connectors/Validator';

const sagaValidateResource = function* sagaValidateResource({ resource, errors }) {
  const checkErrorsArgs = {
    errorObject: errors,
    objectToCheck: resource,
  };

  const { inputErrors, isValid } = yield call(Validator.checkAllErrors, checkErrorsArgs);
  return { inputErrors, isValid };
};

export default sagaValidateResource;
