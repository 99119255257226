import { call, put, select, cancel, take, fork } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { handleError } from 'utils/common';

import { patchEntity } from 'api/apiEntity';

import { selectUser } from 'connectors/User/redux/selectors';

import { USER_PATCH_STARTED } from '../actions';

import {
  userUpdate,
  userPatchDone,
  employeeSaveStarted,
  employeeSaveDone,
  employeeSaveError,
} from '../creators';

const patchUser = function* patchUser(action) {
  const {
    payload: { newValue, name },
  } = action;

  yield put(userUpdate({ newValue, name }));
  yield put(employeeSaveStarted());

  yield call(delay, 750);

  const userObject = yield select(selectUser);

  if (userObject.id) {
    try {
      const args = {
        config: {
          params: {
            camelizeIt: true,
          },
        },
        data: {
          attributes: {
            [name]: newValue,
            type: 'users',
            id: userObject.id,
          },
        },
        id: userObject.id,
        type: 'users',
      };

      const {
        data: { user },
      } = yield call(patchEntity, args);

      yield put(userPatchDone({ name, newValue: user[name] }));
      yield put(employeeSaveDone());
    } catch (error) {
      handleError(error);

      yield put(employeeSaveError(error));
    }
  } else {
    yield put(employeeSaveDone());
  }
};

const sagaUserPatchStarted = function* sagaUserPatchStarted() {
  let lastTask;
  let lastAction;
  while (true) {
    const action = yield take(USER_PATCH_STARTED);
    if (lastTask && lastAction.payload.name === action.payload.name) {
      yield cancel(lastTask);
    }
    lastAction = action;
    lastTask = yield fork(patchUser, action);
  }
};

export default sagaUserPatchStarted;
