import React, { Component, Fragment } from 'react';

import { handleError } from 'utils/common';

import { createRequisitionOpening } from 'reduxStore/schema';

import { postEntity } from 'api/apiEntity';

import { promiseGetEmployer } from 'pages/AdminPortal/ToolboxEmployerEditorPage/promises';

import AutocompleteServer from 'connectors/AutocompleteServer';
import SelectGreenhouseJob from 'connectors/DialogModals/SelectGreenhouseJob';

import Button from 'components/Button';
import DialogueModal from 'components/DialogueModal';
import Table from 'components/Table';
import TableRow from 'components/Table/components/TableRow';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';

import ToolboxRequisitionsRow from '../ToolboxRequisitionsRow';
import ToolboxRequisitionsSearchBar from '../ToolboxRequisitionsSearchBar';

import styles from './ToolboxRequisitionsTable.scss';

class ToolboxRequisitionsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      requisitionId: null,
      employerId: null,
      showGreenhousePrompt: false,
      showPrompt: false,
    };
  }

  render() {
    const { employerId, requisitionId, showGreenhousePrompt, showPrompt } = this.state;

    const {
      complete,
      duplicateRequisition,
      employerName,
      employersById,
      entityLoadStarted,
      externalName,
      handleSearchInputChange,
      handleSearchFilterChange,
      handleSearchCheckboxChange,
      handleSortChange,
      isLoading,
      loadMoreRequisitions,
      modal,
      patchResource,
      resourceUpdate,
      resourceDelete,
      requisitionOpenings,
      role,
      roleOptions,
      showModal,
      sortBy,
      state,
      total,
    } = this.props;

    const header = [
      {
        content: 'Employer/Role',
        headerClassName: styles.nameHeader,
        value: 'employer.name',
        onClick: handleSortChange,
      },
      {
        content: 'Title/Location',
        headerClassName: styles.lastActivityHeader,
      },
      {
        content: 'Created At',
        headerClassName: styles.lastActivityHeader,
        value: 'created_at',
        onClick: handleSortChange,
      },
      {
        content: 'Last Request',
        headerClassName: styles.lastActivityHeader,
        value: 'last_request_at',
        onClick: handleSortChange,
      },
      {
        content: 'State',
        headerClassName: styles.smallHeader,
        value: 'state',
        onClick: handleSortChange,
      },
      {
        content: 'Hired',
        headerClassName: styles.smallHeader,
        value: 'hired_requisition_candidates',
        onClick: handleSortChange,
      },
      {
        content: 'Active',
        headerClassName: styles.smallHeader,
        value: 'active_requisition_candidates',
        onClick: handleSortChange,
      },
      {
        content: 'Total',
        headerClassName: styles.smallHeader,
        value: 'total_requisition_candidates',
        onClick: handleSortChange,
      },
      {
        content: '',
        headerClassName: styles.candActionHeader,
      },
    ];

    const makeCells = (requisitionOpening) => {
      const {
        relationships: { employer: { data: employerIds = [] } = {} } = {},
      } = requisitionOpening;

      const rowProps = {
        duplicateRequisition,
        employer: employersById[employerIds[0]],
        modal,
        patchResource,
        requisitionOpening,
        resourceUpdate,
        showModal,
      };

      return <ToolboxRequisitionsRow {...rowProps} />;
    };

    const makeRows = (requisitionOpening) => (
      <TableRow id={requisitionOpening.id} key={requisitionOpening.id}>
        {makeCells(requisitionOpening)}
      </TableRow>
    );

    const tableContent = requisitionOpenings
      .filter((req) => !req.attributes.temporary)
      .map(makeRows);

    const searchBarProps = {
      complete,
      employerName,
      externalName,
      handleSearchInputChange,
      handleSearchFilterChange,
      handleSearchCheckboxChange,
      role,
      roleOptions,
      state,
    };

    const createRequisitionOpeningProps = {
      placeholder: 'Start typing and select an employer',
      value: '',
      size: 'full',
      name: '',
      handleInputChange: (event) => {
        const {
          target: {
            value: { id },
          },
        } = event;

        const resource = createRequisitionOpening();

        resourceUpdate({
          slice: 'toolboxRequisitions',
          type: 'requisition_openings',
          id: resource.id,
          resource: {
            ...resource,
            attributes: {
              ...resource.attributes,
              employerId: id,
              temporary: true,
            },
            relationships: {
              employer: {
                data: [id],
              },
            },
          },
        });

        entityLoadStarted({
          id,
          params: {
            page: 1,
          },
          slice: 'toolboxRequisitions',
          type: 'employers',
          updateOnly: true,
        });

        promiseGetEmployer({ employerId: id })
          .then((employer) => {
            if (employer.greenhouse_ready) {
              this.setState({
                employerId: id,
                requisitionId: resource.id,
                showGreenhousePrompt: true,
                showPrompt: false,
              });
            } else {
              this.setState({ showPrompt: false });

              showModal({
                key: 'RequisitionEditorModal',
                parent: 'ToolboxRequisitionsPage',
                route: `/toolbox/requisition/${resource.id}/requisition-opening`,
              });
            }
          })
          .catch(handleError);
      },
      handleOnBlur: (event) => console.log(event.target.value, ' is not a valid employer'),
      autocompleteType: 'employers',
      field: 'name',
      needReset: true,
    };

    const createRequisitionOpeningClick = () => this.setState({ showPrompt: true });

    const createProps = {
      onClick: createRequisitionOpeningClick,
      primary: true,
      className: styles.newCandButton,
    };

    const titleContent = (
      <div className={styles.candidateHeader}>
        <div className={styles.candidateHeaderLeft}>
          <ToolboxRequisitionsSearchBar {...searchBarProps} />
        </div>
        <Button {...createProps}>+ Requisition</Button>
      </div>
    );

    const infiniteScrollProps = {
      loadMore: loadMoreRequisitions,
      hasMore: !isLoading && total > requisitionOpenings.length,
      loader: isLoading ? (
        <div key="infiniteScrollLoader" />
      ) : (
        <InfiniteScrollLoader key="infiniteScrollLoader" />
      ),
      useWindow: false,
    };

    const tableProps = {
      blockProps: {
        titleClass: styles.dashboardTitleContainer,
      },
      titleContent,
      tableContent,
      status: state,
      header,
      sortBy,
      infiniteScrollProps,
      tableName: 'adminRequisitions',
      isEmpty: !isLoading && requisitionOpenings.length === 0,
      emptyTextString: 'No Requisitions',
      isLoading,
    };

    const createReqModal = showPrompt ? (
      <DialogueModal>
        <div className={styles.requestConfirmationModal}>
          <div className={styles.requestConfirmationTitle}>Choose an employer.</div>

          <div className={styles.requestConfirmationContent}>
            <AutocompleteServer {...createRequisitionOpeningProps} />
          </div>
          <div className={styles.requestConfirmationButtons}>
            <Button onClick={() => this.setState({ showPrompt: false })} quaternary>
              Cancel
            </Button>
          </div>
        </div>
      </DialogueModal>
    ) : null;

    const greenhouseJobProps = {
      asModal: true,
      hasCancel: true,
      employerId,
      handleSkip: () => {
        this.setState({ showGreenhousePrompt: false });
        showModal({
          key: 'RequisitionEditorModal',
          parent: 'ToolboxRequisitionsPage',
          route: `/toolbox/requisition/${requisitionId}/requisition-opening`,
        });
      },
      handleCancel: () => {
        this.setState({
          showGreenhousePrompt: false,
          employerId: null,
          requisitionId: null,
        });

        if (requisitionId) {
          resourceDelete({
            id: requisitionId,
            slice: 'toolboxRequisitions',
            type: 'requisitionOpening',
          });
        }
      },
      handleAction: async (greenhouseJobId) => {
        const args = {
          type: 'requisition_openings',
          data: {
            type: 'requisition_opening',
            attributes: {
              greenhouseJobId,
              employerId,
            },
          },
          config: {
            params: {
              normalizeIt: true,
            },
          },
        };

        let byId;
        let allIds;
        try {
          ({
            data: { entities: { requisitionOpening: { byId = {}, allIds = [] } = {} } = {} } = {},
          } = await postEntity(args));
        } catch (error) {
          handleError(error);
        }

        const resourceWithId = {
          ...byId[allIds[0]],
        };

        resourceUpdate({
          id: resourceWithId.id,
          resource: resourceWithId,
          slice: 'toolboxRequisitions',
          type: 'requisitionOpening',
        });

        if (requisitionId && resourceWithId.id !== requisitionId) {
          resourceDelete({
            id: requisitionId,
            slice: 'toolboxRequisitions',
            type: 'requisitionOpening',
          });
        }

        this.setState({ showGreenhousePrompt: false });

        showModal({
          key: 'RequisitionEditorModal',
          parent: 'ToolboxRequisitionsPage',
          route: `/toolbox/requisition/${resourceWithId.id}/requisition-opening`,
        });
      },
    };

    const greenhouseModal = showGreenhousePrompt ? (
      <SelectGreenhouseJob {...greenhouseJobProps} />
    ) : null;

    return (
      <Fragment>
        <Table {...tableProps} />
        {createReqModal}
        {greenhouseModal}
      </Fragment>
    );
  }
}

export default ToolboxRequisitionsTable;
