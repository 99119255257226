import { call, put, select, takeLatest } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { patchEntity } from 'api/apiEntity';

import { getCurrentUser } from 'shared/store/app/selectors';

import { CANDIDATE_PROFILE_PATCH_STARTED } from '../actions';

import { candidateProfilePatchDone } from '../creators';

const patchCandidateProfile = function* patchCandidateProfile(action) {
  const {
    payload: { newValue, name },
  } = action;

  const { currentProfile, currentProfile: { id: candidateId } = {} } = yield select(getCurrentUser);

  if (candidateId) {
    yield put(
      candidateProfilePatchDone({
        candidateProfile: {
          ...currentProfile,
          [name]: newValue,
        },
      })
    );

    try {
      const args = {
        id: candidateId,
        type: 'candidates',
        data: {
          attributes: {
            [name]: newValue,
          },
        },
        config: {
          params: {
            camelizeIt: true,
          },
        },
      };

      const {
        data: { candidate: candidateProfile },
      } = yield call(patchEntity, args);

      yield put(candidateProfilePatchDone({ candidateProfile }));
    } catch (error) {
      handleError(error);
    }
  }
};

const sagaCandidateProfilePatchStarted = function* sagaCandidateProfilePatchStarted() {
  yield takeLatest(CANDIDATE_PROFILE_PATCH_STARTED, patchCandidateProfile);
};

export default sagaCandidateProfilePatchStarted;
