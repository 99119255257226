import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch, useParams } from 'react-router-dom';

import ReadyContainer from 'connectors/ReadyContainer';

import { usePrevious } from 'hooks';
import { AdminAuthHOC, ModalHOC } from 'containers/HOC';
import ConnectContainer from 'containers/ConnectContainer';

import duck from 'pages/AdminPortal/ToolboxEducationOrgsPage/redux';

import EducationOrgEditorProfile from './components/EducationOrgEditorProfile';

const ToolboxEducationOrgEditor = (props) => {
  const params = useParams();
  const { id } = params;
  const prevId = usePrevious(id);

  useEffect(() => {
    const {
      match: {
        params: { id: educationOrgId },
      },
      actions: { entityLoadStarted },
      educationOrg: { attributes: { temporary = false } = {} } = {},
    } = props;

    if (!temporary) {
      entityLoadStarted({
        type: 'education_organizations',
        slice: 'toolboxEducationOrgs',
        id: educationOrgId,
        params: {
          includeSet: 'unverified_data',
          sortBy: 'created_at_desc',
          unverifiedData: null,
        },
        updateOnly: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currId = prevId;
    const nextId = id;

    const {
      actions: { entityLoadStarted },
    } = props;

    if (nextId !== currId) {
      entityLoadStarted({
        type: 'education_organizations',
        slice: 'toolboxEducationOrgs',
        id: nextId,
        params: {
          includeSet: 'unverified_data',
          sortBy: 'created_at_desc',
          unverifiedData: null,
        },
        updateOnly: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <Helmet key="helmet" title="EducationOrg Editor" />
      <ReadyContainer key="readyContainer">
        <Switch>
          <Route exact path={'/toolbox/education-org/:id/edit-profile'}>
            <EducationOrgEditorProfile {...props} />
          </Route>
        </Switch>
      </ReadyContainer>
    </>
  );
};

export default ConnectContainer(duck)(
  AdminAuthHOC()(
    ModalHOC({
      paginatorUrl: '/toolbox/education-org/:id/edit-profile',
      centered: true,
    })(ToolboxEducationOrgEditor)
  )
);
