import { call, takeEvery, put } from 'redux-saga/effects';

import { createRequisitionOpening } from 'reduxStore/schema';

import { entityLoadDone, resourceUpdate, showModal } from 'shared/store/app/creators';

import { getRelationship, postResource, patchRelationship } from 'api/apiResource';

import { DUPLICATE_REQUISITION } from '../actions';

const duplicateRequisition = function* duplicateRequisition(action) {
  const {
    payload: { modal, slice, requisitionOpening: { attributes = {}, id } = {} } = {},
  } = action;

  const blankRequisitionOpening = yield call(createRequisitionOpening);

  // Create new req with old req attributes, with a few overrides
  const newAttributes = {
    ...blankRequisitionOpening.attributes,
    ...attributes,
    id: blankRequisitionOpening.id,
    complete: false,
    externalName: attributes.externalName
      ? `Copy of ${attributes.externalName}`
      : 'Copy of Untitled Req',
    state: 'pending',
  };

  delete newAttributes.greenhouseJobId;
  delete newAttributes.greenhouseState;
  delete newAttributes.greenhouseJobName;

  const args = {
    config: {
      params: {
        normalizeIt: true,
        includeSet: 'employer',
      },
    },
    data: {
      attributes: newAttributes,
      type: 'requisition_openings',
    },
    type: 'requisition_openings',
  };

  // Post new duplicate req
  const {
    data: { entities: { requisitionOpening: { byId = {}, allIds = [] } = {} } = {} } = {},
  } = yield call(postResource, args);

  const resourceWithId = {
    ...byId[allIds[0]],
  };

  // Save new duplicate req to store with new id
  // yield put(entityLoadDone({
  //   slice: 'toolboxRequisitions',
  //   type: 'requisition_openings',
  //   data: postData,
  //   updateOnly: true,
  // }));

  yield put(
    resourceUpdate({
      id: resourceWithId.id,
      resource: resourceWithId,
      slice,
      type: 'requisition_openings',
    })
  );

  // Get requisition_requirement of original requisition_opening
  const getArgs = {
    config: {
      params: {
        normalizeIt: true,
      },
    },
    id,
    relationship: 'requisition_requirement',
    type: 'requisition_openings',
  };

  const {
    data: {
      entities: {
        requisitionRequirement: {
          byId: requisitionRequirementById = {},
          allIds: requisitionRequirementAllIds = [],
        } = {},
      } = {},
    } = {},
  } = yield call(getRelationship, getArgs);

  const { attributes: requisitionRequirementAttributes = {} } =
    requisitionRequirementById[requisitionRequirementAllIds[0]] || {};

  // Patch new requisition_requirement with attributes of old
  // requisition_opening's requisition_requirement
  const patchArgs = {
    config: {
      params: {
        normalizeIt: true,
      },
    },
    data: {
      type: 'requisition_requirement',
      attributes: requisitionRequirementAttributes,
    },
    id: allIds[0],
    relationship: 'requisition_requirement',
    type: 'requisition_openings',
  };

  const { data } = yield call(patchRelationship, patchArgs);

  // Save new requisition_requirement to store
  yield put(
    entityLoadDone({
      slice,
      type: 'requisition_requirement',
      data,
      updateOnly: true,
    })
  );

  // Pull up modal of new duplicate requisition_opening
  yield put(
    showModal({
      useSameOriginalUrl: true,
      key: modal.key,
      parent: modal.parent,
      route: modal.route.replace(':id', allIds[0]),
    })
  );
};

const sagaDuplicateRequisition = function* sagaDuplicateRequisition() {
  yield takeEvery(DUPLICATE_REQUISITION, duplicateRequisition);
};

export default sagaDuplicateRequisition;
