import React from 'react';
import classnames from 'classnames';
import { v4 } from 'uuid';

import styles from './InputCheckbox.scss';

const InputCheckbox = ({
  name,
  label,
  required,
  handleInputChange,
  checked,
  disabled,
  className,
  checkboxMany,
  blockStyle,
  smallBlock,
  autoFocus,
}) => {
  const mainClasses = classnames(
    {
      [styles.InputCheckbox]: !checkboxMany,
      [styles.InputCheckboxMany]: checkboxMany,
      [styles.smallBlock]: smallBlock,
    },
    className
  );

  const inputClasses = classnames(styles.inputStyle, {
    [styles.required]: required,
  });

  const labelClasses = classnames(styles.labelStyle, {
    [styles.blockStyle]: blockStyle,
    [styles.disabled]: disabled,
    [styles.smallBlock]: smallBlock,
  });

  const uniqueId = v4();

  const inputProps = {
    name,
    type: 'checkbox',
    checked: checked || false,
    onChange: handleInputChange,
    className: inputClasses,
    disabled,
    id: uniqueId,
    autoFocus,
  };

  const labelProps = {
    className: labelClasses,
  };

  const spanProps = {
    className: classnames({
      [styles.marginLeft]: !blockStyle,
      [styles.centered]: blockStyle,
    }),
  };

  return (
    <div className={mainClasses}>
      <input {...inputProps} />
      <label htmlFor={uniqueId} {...labelProps}>
        <span {...spanProps}>{label}</span>
      </label>
      <div className={styles.printOnly}>{checked ? <span>&#10003;</span> : ''}</div>
    </div>
  );
};

export default InputCheckbox;
