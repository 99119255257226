import React, { useState } from 'react';
import dayjs from 'dayjs';

import EmptyCompanyLogo from 'shared/assets/missing-company-picture.png';

import Block from 'components/Block';
import Button from 'components/Button';
import Input from 'components/Input';
import FontIcon from 'components/FontIcon';
import FormattedDate from 'components/FormattedDate';

import { toDollars, toCents } from 'utils/moneyUtils';

import styles from './SalesPeriodInputs.scss';

const SalesPeriodInputs = ({
  handleSalesPeriodInputChange,
  workHistory: {
    attributes: {
      company: { name: companyName = '', picture: { xlarge: pictureUrl } = {} } = {},
      id: workHistoryId,
      position: { name: positionName = '' } = {},
      startDate = '',
      endDate = '',
      salesPeriods = [],
    } = {},
  } = {},
  isProfileLocked = false,
  autofocusFields = [],
  modelIndex = null,
  workHistoryIdx = null,
}) => {
  const [showAll, setShowAll] = useState(false);

  const picUrl = pictureUrl === '' ? EmptyCompanyLogo : pictureUrl;

  const salesPeriodsToShow = showAll ? salesPeriods : salesPeriods.slice(0, 2);

  const salesInputs = salesPeriodsToShow.map((salesPeriod, idx) => {
    const {
      endDate: salesEndDate = '',
      dealsAverageCents: dealsAverage,
      dealsVolumeCents: dealsVolume,
      id: salesPeriodId,
      leadsGeneratedPercentage: leadsGenPercentage,
      oteCents: ote,
      quotaAmountCents: quotaAmount,
      quotaHitPercentage,
      salaryCents: salary,
      saleCycleDays,
      startDate: salesStartDate = '',
    } = salesPeriod;

    const salesYear = salesStartDate.substring(0, 4);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    const startYear = dayjs(salesStartDate, 'YYYY-MM-DD').get('year');
    const startMonth = dayjs(salesStartDate, 'YYYY-MM-DD').get('month');
    const startDateFecha = new Date(startYear, startMonth, 3);
    const startMonthFormatted = <FormattedDate date={startDateFecha} format="MMM" />;

    const endYear = salesEndDate ? dayjs(salesEndDate, 'YYYY-MM-DD').get('year') : currentYear;
    const endMonth = salesEndDate ? dayjs(salesEndDate, 'YYYY-MM-DD').get('month') : currentMonth;
    const endDateFecha = new Date(endYear, endMonth, 3);
    const endMonthFormatted = salesEndDate ? (
      <FormattedDate date={endDateFecha} format="MMM" />
    ) : (
      'Present'
    );

    const isFullYear = startMonth === 0 && endMonth === 11;

    const monthsString =
      startMonth === endMonth ? (
        startMonthFormatted
      ) : (
        <span>
          {startMonthFormatted} - {endMonthFormatted}
        </span>
      );

    const monthsContent = isFullYear ? null : (
      <span className={styles.salesPeriodMonths}>{monthsString}</span>
    );

    const isOpenIdx = idx === parseInt(modelIndex, 10);

    const commonTextInputProps = {
      handleInputChange: (event) =>
        handleSalesPeriodInputChange({
          event: {
            ...event,
            target: {
              ...event.target,
              value: toCents(event.target.value),
              name: event.target.name,
            },
          },
          salesPeriodId,
          type: 'AESalesPeriod',
          workHistoryId,
        }),
      type: 'text',
      moneyInput: true,
      size: 'full',
      disabled: isProfileLocked,
    };

    const salaryInputProps = {
      ...commonTextInputProps,
      name: 'salaryCents',
      value: salary || salary === 0 ? toDollars(salary) : '',
      label: 'Base Salary',
      placeholder: '',
      autoFocus: autofocusFields.includes('salary_cents') && isOpenIdx,
      highlight: autofocusFields.includes('salary_cents') && isOpenIdx,
    };

    const currentOteInputProps = {
      ...commonTextInputProps,
      name: 'oteCents',
      value: ote || ote === 0 ? toDollars(ote) : '',
      label: 'OTE',
      placeholder: '',
      autoFocus: autofocusFields.includes('ote_cents') && isOpenIdx,
      highlight: autofocusFields.includes('ote_cents') && isOpenIdx,
    };

    const pastOteInputProps = {
      ...commonTextInputProps,
      name: 'oteCents',
      value: ote || ote === 0 ? toDollars(ote) : '',
      label: 'Earned Income',
      placeholder: '',
      autoFocus: autofocusFields.includes('ote_cents') && isOpenIdx,
      highlight: autofocusFields.includes('ote_cents') && isOpenIdx,
    };

    const quotaInputProps = {
      ...commonTextInputProps,
      name: 'quotaAmountCents',
      value: quotaAmount || quotaAmount === 0 ? toDollars(quotaAmount) : '',
      label: 'Quota',
      placeholder: '',
      autoFocus: autofocusFields.includes('quota_amount_cents') && isOpenIdx,
      highlight: autofocusFields.includes('quota_amount_cents') && isOpenIdx,
    };

    const quotaPercentInputProps = {
      ...commonTextInputProps,
      name: 'quotaHitPercentage',
      value:
        quotaHitPercentage || quotaHitPercentage === 0
          ? Math.floor((quotaHitPercentage * 1000) / 10)
          : '',
      label: '% Quota Hit',
      placeholder: '',
      type: 'number',
      min: '0',
      size: 'small',
      centered: true,
      moneyInput: false,
      percentInput: true,
      handleInputChange: (event) =>
        handleSalesPeriodInputChange({
          event: {
            ...event,
            target: {
              ...event.target,
              value:
                event.target.value || event.target.value === 0 ? event.target.value / 100 : null,
              name: event.target.name,
            },
          },
          salesPeriodId,
          type: 'AESalesPeriod',
          workHistoryId,
        }),
      autoFocus: autofocusFields.includes('quota_hit_percentage') && isOpenIdx,
      highlight: autofocusFields.includes('quota_hit_percentage') && isOpenIdx,
    };

    const leadGenPercentInputProps = {
      ...commonTextInputProps,
      name: 'leadsGeneratedPercentage',
      value:
        leadsGenPercentage || leadsGenPercentage === 0
          ? Math.floor((leadsGenPercentage * 1000) / 10)
          : '',
      label: '% Outbound',
      placeholder: '',
      size: 'small',
      type: 'number',
      min: '0',
      max: '100',
      moneyInput: false,
      percentInput: true,
      centered: true,
      handleInputChange: (event) => {
        const newValue = event.target.value && event.target.value > 100 ? 100 : event.target.value;
        return handleSalesPeriodInputChange({
          event: {
            ...event,
            target: {
              ...event.target,
              value: newValue || newValue === 0 ? newValue / 100 : null,
              name: event.target.name,
            },
          },
          salesPeriodId,
          type: 'AESalesPeriod',
          workHistoryId,
        });
      },
      autoFocus: autofocusFields.includes('leads_generated_percentage') && isOpenIdx,
      highlight: autofocusFields.includes('leads_generated_percentage') && isOpenIdx,
    };

    const dealsAverageInputProps = {
      ...commonTextInputProps,
      name: 'dealsAverageCents',
      value: dealsAverage || dealsAverage === 0 ? toDollars(dealsAverage) : '',
      label: 'Avg. Deal Size',
      placeholder: '',
      autoFocus: autofocusFields.includes('deals_average_cents') && isOpenIdx,
      highlight: autofocusFields.includes('deals_average_cents') && isOpenIdx,
    };

    const dealsVolumeInputProps = {
      ...commonTextInputProps,
      name: 'dealsVolumeCents',
      value: dealsVolume || dealsVolume === 0 ? toDollars(dealsVolume) : '',
      label: 'Deal Volume',
      placeholder: '',
      autoFocus: autofocusFields.includes('deals_volume_cents') && isOpenIdx,
      highlight: autofocusFields.includes('deals_volume_cents') && isOpenIdx,
    };

    const salesCycleDaysInputProps = {
      ...commonTextInputProps,
      type: 'number',
      handleInputChange: (event) =>
        handleSalesPeriodInputChange({
          event,
          salesPeriodId,
          type: 'AESalesPeriod',
          workHistoryId,
        }),
      name: 'saleCycleDays',
      value: saleCycleDays || saleCycleDays === 0 ? saleCycleDays : '',
      label: 'Sale Cycle (days)',
      placeholder: '',
      secondaryType: 'days',
      min: '0',
      centered: true,
      moneyInput: false,
      autoFocus: autofocusFields.includes('sale_cycle_days') && isOpenIdx,
      highlight: autofocusFields.includes('sale_cycle_days') && isOpenIdx,
    };

    if (!salesEndDate || (workHistoryIdx === 0 && idx === 0)) {
      return (
        <div key={salesPeriodId} className={styles.salesPeriodContainer}>
          <div className={styles.salesPeriodTitle}>
            {salesYear}
            {monthsContent}
          </div>
          <div className={styles.rowInputs}>
            <Input {...currentOteInputProps} />
            <Input {...salaryInputProps} />
            <Input {...quotaInputProps} />
            <Input {...dealsAverageInputProps} />
            <Input {...leadGenPercentInputProps} />
            <Input {...salesCycleDaysInputProps} />
          </div>
        </div>
      );
    }

    return (
      <div key={salesPeriodId} className={styles.salesPeriodContainer}>
        <div className={styles.salesPeriodTitle}>
          {salesYear}
          {monthsContent}
        </div>
        <div className={styles.rowInputs}>
          <Input {...pastOteInputProps} />
          <Input {...salaryInputProps} />
          <Input {...dealsVolumeInputProps} />
          <Input {...dealsAverageInputProps} />
          <Input {...leadGenPercentInputProps} />
          <Input {...salesCycleDaysInputProps} />
          <Input {...quotaPercentInputProps} />
        </div>
      </div>
    );
  });

  const startYear = startDate.substring(0, 4);
  const endYear = endDate ? endDate.substring(0, 4) : 'Present';

  const salesPeriodBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    addPadding: false,
    className: styles.salesBlock,
    key: workHistoryId,
  };

  const showAllButtonProps = {
    secondary: true,
    onClick: () => setShowAll(!showAll),
  };

  const showAllButtonContent = showAll ? 'Show Fewer Years' : 'Show More Years';

  const showMoreIcon = showAll ? 'caret-up' : 'caret-down';

  const showAllButtonContainer =
    salesPeriods.length > 2 ? (
      <div className={styles.buttonContainer}>
        <Button {...showAllButtonProps}>
          {showAllButtonContent}
          <FontIcon className={styles.showMoreIcon} iconName={showMoreIcon} />
        </Button>
      </div>
    ) : null;

  return (
    <Block {...salesPeriodBlockProps}>
      <div className={styles.inputsContainer}>
        <div className={styles.companyContainer}>
          <div className={styles.companyLogo}>
            <img src={picUrl} alt="company" />
          </div>
          <div className={styles.companyDetails}>
            <div className={styles.companyName}>{companyName}</div>
            <div className={styles.companyPosition}>{positionName}</div>
            <div className={styles.companyYears}>
              {startYear} - {endYear}
            </div>
          </div>
        </div>
        <div className={styles.columnInputs}>{salesInputs}</div>
        {showAllButtonContainer}
      </div>
    </Block>
  );
};

export default SalesPeriodInputs;
