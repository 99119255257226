import React, { useState, Fragment } from 'react';

import Bold from 'components/Bold';
import Bolder from 'components/Bolder';
import Button from 'components/Button';
import DatePicker from 'components/DatePicker';
import DialogueModal from 'components/DialogueModal';
import DropdownContent from 'components/DropdownContent';
import FormattedDate from 'components/FormattedDate';
import Input from 'components/Input';
import InputRadio from 'components/InputRadio';
import Label from 'components/Label';

import styles from '../DialogModal.scss';

const PrivateReqCanFeedbackEmployerPass = ({ firstName, lastName, handleSubmit, handleCancel }) => {
  const [feedback, setFeedback] = useState('');
  const [rating, setRating] = useState(null);
  const [passedOn, setPassedOn] = useState(null);

  const isValid = (value) => value !== null && value !== undefined && value !== '';

  const disabled = !isValid(rating) || !isValid(passedOn);

  const confirmButtonProps = {
    onClick: () =>
      handleSubmit({
        feedback,
        passedOn,
        rating,
      }),
    primary: true,
    className: styles.confirm,
    disabled,
  };

  const confirmButton = <Button {...confirmButtonProps}>Confirm</Button>;

  const closeButtonProps = {
    quaternary: true,
    onClick: handleCancel,
  };

  const closeButton = <Button {...closeButtonProps}>Cancel</Button>;

  const promptString = (
    <span>
      Pass on{' '}
      <Bolder>
        {firstName} {lastName}
      </Bolder>
      ?
    </span>
  );

  const passedOnProps = {
    onDayClick: (day) => setPassedOn(day),
    selectedDays: [passedOn],
  };

  const passedOnContent = (
    <div className={styles.addCandidatesOptionsContainer}>
      <DatePicker {...passedOnProps} />
    </div>
  );

  const passedOnDropdownProps = {
    label: passedOn ? <FormattedDate date={passedOn} /> : 'Select date',
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    dropRight: true,
  };

  const feedbackProps = {
    type: 'textarea',
    size: 'full',
    handleInputChange: (event) => setFeedback(event.target.value),
    value: feedback,
    rows: 3,
    name: 'feedback',
    label: 'Let us know why you are passing on this candidate (optional).',
  };

  const ratingProps = {
    containerClassName: styles.inputRadio,
    name: 'rating',
    handleInputChange: (event) => setRating(event.target.value),
    value: rating,
    options: [
      {
        label: 'Candidate was not a good fit for the role.',
        value: 0,
      },
      {
        label: 'Candidate seemed to be a good fit, but I passed for other reasons.',
        value: 5,
      },
    ],
  };

  const inputs = (
    <Fragment>
      <div className={styles.inputsColumn}>
        <Label standalone required>
          Passed On Date
        </Label>
        <DropdownContent {...passedOnDropdownProps}>{passedOnContent}</DropdownContent>
      </div>
      <InputRadio {...ratingProps} />
      <Input {...feedbackProps} />
    </Fragment>
  );

  return (
    <DialogueModal>
      <div className={styles.promptModal}>
        <Bold className={styles.promptContent}>{promptString}</Bold>

        <div className={styles.inputContent}>{inputs}</div>

        <div className={styles.promptButtons}>
          {closeButton}
          {confirmButton}
        </div>
      </div>
    </DialogueModal>
  );
};

export default PrivateReqCanFeedbackEmployerPass;
