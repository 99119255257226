import React from 'react';
import classnames from 'classnames';
import dayjs from 'dayjs';

import Table from 'components/Table';
import TableRow from 'components/Table/components/TableRow';
import TableCell from 'components/Table/components/TableCell';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import FormattedDate from 'components/FormattedDate';

import styles from './CohortsTable.scss';

const CohortsTable = ({
  cohorts: { allIds = [], byId = {} } = {},
  cohortsTotal,
  loadMoreCohorts,
  selectedCohorts,
  handleSelectCohort,
  titleContent,
}) => {
  const tableHeader = [
    {
      content: 'Cohort Name',
      headerClassName: styles.firstLabel,
    },
    {
      content: 'Status',
      headerClassName: styles.cohortStatusHeader,
    },
    {
      content: 'Start',
      headerClassName: styles.cohortStatusHeader,
    },
    {
      content: 'End',
      headerClassName: styles.cohortStatusHeader,
    },
  ];

  const makeCohortCells = (id) => {
    const { [id]: { attributes: { name = '', startDate, endDate, state } = {} } = {} } = byId || {};

    const startDateFecha = startDate ? dayjs(startDate, 'YYYY-MM-DD').toDate() : null;
    const endDateFecha = endDate ? dayjs(endDate, 'YYYY-MM-DD').toDate() : null;

    const cohortNameCellProps = {
      className: styles.cohortNameCell,
    };

    const cohortNameCell = <div {...cohortNameCellProps}>{name}</div>;

    const statusClassnames = classnames(styles.cohortStatusCell, {
      [styles.activeStatus]: state === 'open' || state === 'active',
      [styles.inactiveStatus]: state === 'complete',
    });

    const statusText = `${state.charAt(0).toUpperCase()}${state.slice(1)}`;

    const cohortStatusCell = <div className={statusClassnames}>{statusText}</div>;

    const cohortStartCell = startDateFecha ? (
      <div className={styles.cohortDate}>
        <FormattedDate format="MMM D YYYY" date={startDateFecha} />
      </div>
    ) : (
      <div className={styles.cohortDate}>-</div>
    );

    const cohortEndCell = endDateFecha ? (
      <div className={styles.cohortDate}>
        <FormattedDate format="MMM D YYYY" date={endDateFecha} />
      </div>
    ) : (
      <div className={styles.cohortDate}>-</div>
    );

    const cells = [
      <TableCell key={1}>{cohortNameCell}</TableCell>,
      <TableCell key={2}>{cohortStatusCell}</TableCell>,
      <TableCell key={3}>{cohortStartCell}</TableCell>,
      <TableCell key={4}>{cohortEndCell}</TableCell>,
    ];

    return cells;
  };

  const selectedCohortIds = selectedCohorts.map((co) => co.id);

  const makeCohortRows = (id) => {
    const rowProps = {
      highlight: selectedCohortIds.includes(id),
      cohortRow: true,
      key: id,
      onClick: () => handleSelectCohort({ cohort: byId[id].attributes }),
      id,
    };

    return <TableRow {...rowProps}>{makeCohortCells(id)}</TableRow>;
  };

  const tableContent = allIds.map(makeCohortRows);

  const infiniteScrollProps = {
    pageStart: 0,
    loadMore: loadMoreCohorts,
    hasMore: cohortsTotal > allIds.length,
    loader: <InfiniteScrollLoader />,
    useWindow: false,
  };

  const tableProps = {
    titleContent,
    tableContent,
    header: tableHeader,
    cohort: true,
    tableName: 'cohorts',
    isEmpty: allIds.length === 0,
    emptyTextString: 'No Cohorts Available',
    infiniteScrollProps,
  };

  return <Table {...tableProps} />;
};

export default CohortsTable;
