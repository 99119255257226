import React, { useState, useEffect, useRef, useCallback } from 'react';

import classnames from 'classnames';

import { useOnClickOutside } from 'hooks';

import Button from 'components/Button';
import Input from 'components/Input';

import styles from './InputAutoComplete.scss';

const InputAutoComplete = ({
  value,
  needReset,
  hasAddButton,
  handleInputChange,
  name,
  matchLabel,
  disabled,
  isAdminEditor,
  characterLimit,
  type,
  source,
  label,
  required,
  placeholder,
  size,
  errors,
  isSearch,
  hasLabel,
  alwaysAdd,
  className,
  handleOnBlur,
}) => {
  const [active, setActive] = useState(false);
  const [search, setSearch] = useState(value);
  const [origValue, setOrigValue] = useState(value);
  const [options, setOptions] = useState([]);
  const [selectedItemIdx, setSelectedItemIdx] = useState(0);
  const ref = useRef();

  useEffect(() => {
    if (value !== origValue && !needReset) {
      setSearch(value);
      setOrigValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useOnClickOutside(
    ref,
    useCallback(() => {
      if (isAdminEditor) {
        onSelect({ option: { value: search } });
      }

      setActive(false);
    }, [isAdminEditor, onSelect, search])
  );

  const onSelect = useCallback(
    (option = {}) => {
      if (active) {
        const event = {
          target: {
            name,
            value: option.value || search,
            type: 'select',
          },
        };

        handleInputChange(event);

        setActive(false);
        setSelectedItemIdx(0);
        setSearch(hasAddButton || needReset ? '' : option.label);
      }
    },
    [active, handleInputChange, hasAddButton, name, needReset, search]
  );

  const filterOptions = ({ query }) => {
    const filterRegex = (value) => {
      const escapedQuery = query
        .replace(/([-()\[\]{}+?*.$\^|,:#<!\\])/g, '\\$1')
        .replace(/\x08/g, '\\x08');
      const regex = new RegExp(`${escapedQuery}`, 'i');
      return regex.test(value);
    };

    matchLabel
      ? setOptions(source.filter((opt) => filterRegex(opt.label)))
      : setOptions(source.filter((opt) => filterRegex(opt.value)));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown') {
      const newIdx =
        selectedItemIdx + 1 > options.length - 1 ? options.length - 1 : selectedItemIdx + 1;
      return setSelectedItemIdx(newIdx);
    }
    if (event.key === 'ArrowUp') {
      const newIdx = selectedItemIdx - 1 < 0 ? 0 : selectedItemIdx - 1;
      return setSelectedItemIdx(newIdx);
    }
    if (event.key === 'Enter' && selectedItemIdx >= 0) {
      const option = options.length > 0 ? options[selectedItemIdx] : { value: search };
      onSelect(option);
    }
  };

  const handleTextInputChange = (event) => {
    const {
      target: { value },
    } = event;

    if (!disabled) {
      setActive(value !== '');
      setSearch(value);
    }

    filterOptions({
      query: value,
    });
  };

  const selectClasses = classnames({
    [styles.search]: isSearch,
    [styles.selectStyle]: !isSearch,
  });

  const inputClasses = classnames(styles.inputStyle, styles.flexContainer, className, {
    [styles.active]: active && !isSearch,
    [styles.searchActive]: active && isSearch,
    [styles.searchInputContainer]: isSearch,
  });

  const optionsContainerClasses = classnames(styles.OptionsContainer, {
    [styles.searchOptionsContainer]: isSearch,
    [styles.inputOptionsContainer]: hasLabel,
  });

  const makeOptions = (option, idx) => {
    const optionClasses = classnames(styles.Option, {
      [styles.optionActive]: idx === selectedItemIdx,
    });
    const onClickHandler = () => onSelect(option, true);

    const logoContent = option.picture ? (
      <div className={styles.logoWrapper}>
        <img src={option.picture.medium} alt="company" />
      </div>
    ) : null;

    const optionProps = {
      className: optionClasses,
      key: option.value,
      onClick: onClickHandler,
      value: option.value,
    };

    return (
      <div {...optionProps}>
        {logoContent}
        {option.label}
      </div>
    );
  };

  const content = options.slice(0, 5).map(makeOptions);

  const wrappedContent =
    active && content.length > 0 ? <div className={optionsContainerClasses}>{content}</div> : null;

  const inputProps = {
    characterLimit,
    label,
    required,
    name,
    type,
    value: search,
    handleInputChange: handleTextInputChange,
    handleInputKeyDown: handleKeyDown,
    placeholder,
    className: inputClasses,
    size,
    disabled,
    errors,
    isSearch,
    hasFlex: true,
    handleOnBlur,
  };

  const valueExists =
    hasLabel && !alwaysAdd ? source.map((item) => item.label).includes(search) : true;

  const buttonProps = {
    onClick: () => onSelect(search),
    primary: true,
    disabled: !valueExists,
    className: styles.addButton,
  };

  return (
    <div className={selectClasses} ref={ref}>
      <div className={styles.buttonContainer}>
        <Input {...inputProps} />
        {hasAddButton && <Button {...buttonProps}>Add</Button>}
      </div>
      {wrappedContent}
    </div>
  );
};

export default InputAutoComplete;
