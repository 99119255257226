import React from 'react';

import Button from 'components/Button';
import DatePicker from 'components/DatePicker';
import DropdownContent from 'components/DropdownContent';

import styles from './DateScope.scss';

const DateScope = ({ handleClearDates, fromDate, toDate, month, handleAddableStartDateClick }) => {
  const clearDateProps = {
    quaternary: true,
    onClick: handleClearDates,
  };

  const clearDateButton =
    fromDate || toDate ? <Button {...clearDateProps}>Clear Dates</Button> : null;

  const approvedCandidatesProps = {
    label: 'Approved Date Range',
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    dropRight: true,
  };

  const approvedDatePickerProps = {
    onDayClick: handleAddableStartDateClick,
    selectedDays: [fromDate, { from: fromDate, to: toDate }],
    month,
  };

  const approvedDateDropdownContent = (
    <div className={styles.addCandidatesOptionsContainer}>
      <DatePicker {...approvedDatePickerProps} />
    </div>
  );

  return (
    <div className={styles.approvedCandidateDate}>
      <DropdownContent {...approvedCandidatesProps}>{approvedDateDropdownContent}</DropdownContent>
      {clearDateButton}
    </div>
  );
};

export default DateScope;
