import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';

import { handleError } from 'utils/common';

import EmptyAvatar from 'shared/assets/missing-user-picture.png';
import { promiseGetRequisitionCandidates } from 'pages/EmployeePortal/CandidateProfilePages/promises';

import { usePrevious } from 'hooks';

import ConnectContainer from 'containers/ConnectContainer';

import duck from 'shared/store/app';

import styles from './CandidateImage.scss';

const CandidateImage = (props) => {
  const [noReqCand, setNoReqCand] = useState(null);
  const _mounted = useRef(false);
  const prevCandidateId = usePrevious(props.candidateId);

  useEffect(() => {
    _mounted.current = true;

    if (useHideNames && hideNames) {
      const { candidateId } = props;
      handleGetReqCand(candidateId);
    }

    return () => {
      _mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { candidateId, useHideNames, hideNames } = props;

    if (candidateId !== prevCandidateId) {
      setNoReqCand(null);

      if (useHideNames && hideNames) {
        handleGetReqCand(candidateId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.candidateId]);

  const handleGetReqCand = (candidateId) => {
    promiseGetRequisitionCandidates({ candidateId })
      .then((reqCandidates) => {
        if (_mounted.current) {
          setNoReqCand(reqCandidates.length === 0);
        }
      })
      .catch((error) => {
        handleError(error);
        if (_mounted.current) {
          setNoReqCand(true);
        }
      });
  };

  const { alwaysUseHideName, hideNames, pictureUrl, className, containerClassname, useHideNames } =
    props;

  const picUrl = pictureUrl || EmptyAvatar;

  const useBlur =
    pictureUrl &&
    hideNames &&
    useHideNames &&
    (alwaysUseHideName || noReqCand || noReqCand === null);

  const avatarClassnames = classnames(styles.profileAvatar, { [styles.blur]: useBlur }, className);
  const containerClassnames = classnames(styles.profileAvatarWrapper, containerClassname);

  return (
    <div className={containerClassnames} data-testid="CandidateImage">
      <img className={avatarClassnames} src={picUrl} alt="" />
    </div>
  );
};

export default ConnectContainer(duck)(CandidateImage);
