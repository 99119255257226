import React from 'react';
import classnames from 'classnames';

import styles from './ErrorMessage.scss';

const ErrorMessage = ({ children, className }) => {
  const classes = classnames(styles.ErrorMessage, className);

  return children ? <span className={classes}>{children}</span> : null;
};

export default ErrorMessage;
