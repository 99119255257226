import React, { Fragment } from 'react';

import { camelToHuman } from 'utils/generalUtils';

import EmployerData from 'components/EmployerData';
import FormattedDate from 'components/FormattedDate';
import TableCell from 'components/Table/components/TableCell';

import ToolboxRequisitionsActionDropdown from '../ToolboxRequisitionsActionDropdown';

import styles from '../ToolboxRequisitionsTable/ToolboxRequisitionsTable.scss';

const ToolboxRequisitionsRow = ({
  duplicateRequisition,
  requisitionOpening,
  requisitionOpening: {
    attributes: {
      activeRequisitionCandidates,
      abbreviatedLocations = [],
      complete,
      createdAt,
      externalName = '',
      hiredRequisitionCandidates,
      lastRequestAt,
      role = '',
      state,
      totalRequisitionCandidates,
    } = {},
    id,
  } = {},
  employer: { attributes: employer } = {},
  patchResource,
  resourceUpdate,
  showModal,
}) => {
  const employerDataProps = {
    onClick: () =>
      showModal({
        key: 'RequisitionEditorModal',
        parent: 'ToolboxRequisitionsPage',
        route: `/toolbox/requisition/${id}/requisition-opening`,
      }),
    employer,
    subtitle: role,
    verified: complete,
  };

  const employerDataContent = (
    <div className={styles.employerContent}>
      <EmployerData {...employerDataProps} />
    </div>
  );

  const externalNameContent =
    externalName && externalName.length > 40
      ? `${externalName.trim().substring(0, 37)}...`
      : externalName;

  const abbreviatedLocationsCells = abbreviatedLocations.map((loc) => (
    <div key={loc} className={styles.location}>
      {loc}
    </div>
  ));

  const abbreviatedLocationsContent =
    abbreviatedLocations && abbreviatedLocations.length > 0 ? (
      <Fragment>
        <div className={styles.hairline} />
        {abbreviatedLocationsCells}
      </Fragment>
    ) : null;

  const roleContent = (
    <div title={externalName} className={styles.columnCell}>
      {externalNameContent}
      {abbreviatedLocationsContent}
    </div>
  );

  const createdAtContent = (
    <div className={styles.lastActivityContent}>
      <FormattedDate format="M/D/YY" date={createdAt} parse />
    </div>
  );

  const lastRequestAtContent = (
    <div className={styles.lastActivityContent}>
      <FormattedDate format="M/D/YY" date={lastRequestAt} parse />
    </div>
  );

  const stateContent = <div className={styles.stateContent}>{camelToHuman(state)}</div>;

  const hiredContent = <div className={styles.stateContent}>{hiredRequisitionCandidates}</div>;

  const activeContent = <div className={styles.stateContent}>{activeRequisitionCandidates}</div>;

  const totalContent = <div className={styles.stateContent}>{totalRequisitionCandidates}</div>;

  const actionsProps = {
    duplicateRequisition,
    patchResource,
    requisitionOpening,
    resourceUpdate,
    showModal,
  };

  const actionsContent = (
    <div className={styles.actionsContent}>
      <ToolboxRequisitionsActionDropdown {...actionsProps} />
    </div>
  );

  const cells = (
    <Fragment>
      <TableCell key={1}>{employerDataContent}</TableCell>
      <TableCell key={2}>{roleContent}</TableCell>
      <TableCell key={3}>{createdAtContent}</TableCell>
      <TableCell key={4}>{lastRequestAtContent}</TableCell>
      <TableCell key={5}>{stateContent}</TableCell>
      <TableCell key={6}>{hiredContent}</TableCell>
      <TableCell key={7}>{activeContent}</TableCell>
      <TableCell key={8}>{totalContent}</TableCell>
      <TableCell noFlex key={9}>
        {actionsContent}
      </TableCell>
    </Fragment>
  );

  return cells;
};

export default ToolboxRequisitionsRow;
