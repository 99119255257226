import { handleActions } from 'redux-actions';

const initialState = {
  entities: {
    cohort: {
      byId: {},
      allIds: [],
    },
    cohortCandidate: {
      byId: {},
      allIds: [],
    },
    cohortEmployer: {
      byId: {},
      allIds: [],
    },
  },
  meta: {
    cohort: {
      pagination: {},
    },
    cohortCandidate: {
      pagination: {},
    },
    cohortEmployer: {
      pagination: {},
    },
  },
};

const reduceToolboxCohorts = handleActions({}, initialState);

export default reduceToolboxCohorts;
