import { call, put, take } from 'redux-saga/effects';
import camelCase from 'lodash.camelcase';

import { handleError } from 'utils/common';
import { setUserContext } from 'utils/ravenUtils';

import { DIRECT_LOGIN_STARTED } from 'shared/store/app/actions';

import { promiseGetCurrentUser } from 'shared/promises';

import {
  candidateLoginDone,
  adminLoginDone,
  employeeLoginDone,
  loginError,
  addRequestInterceptor,
} from 'shared/store/app/creators';

const sagaDirectLoginStarted = function* sagaDirectLoginStarted() {
  while (true) {
    const {
      payload: { accessToken, client, uid },
    } = yield take(DIRECT_LOGIN_STARTED);

    const headers = { 'access-token': accessToken, client, uid };

    yield put(addRequestInterceptor({ headers }));

    try {
      const user = yield call(promiseGetCurrentUser, headers);

      const { currentProfileType } = user || {};

      const type = camelCase(currentProfileType);

      yield call(setUserContext, user);

      if (type === 'candidate') {
        yield put(candidateLoginDone({ user, headers }));
      }
      if (type === 'employee') {
        yield put(employeeLoginDone({ user, headers }));
      }
      if (type === 'rainmakersAdmin') {
        yield put(adminLoginDone({ user, headers }));
      }
      if (!type) {
        yield put(loginError({ errors: ['Archived'] }));
      }
    } catch (error) {
      handleError(error);

      const {
        response: {
          data: { errors: { full_messages: errors = ['Please try again'] } = {} } = {},
        } = {},
      } = error || {};

      yield put(loginError({ errors }));
    }
  }
};

export default sagaDirectLoginStarted;
