import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import classnames from 'classnames';

import { UsMapView } from 'components/UsMapView';

import { VisibleHOC } from 'containers/HOC';
import { useOnClickOutside } from 'hooks';

import FontIcon from 'components/FontIcon';

import styles from './DropdownUsRegions.scss';

export const PILLS_PLACEMENT_ASIDE = 'aside';
export const PILLS_PLACEMENT_HIDDEN = 'hidden';
export const PILLS_PLACEMENT_SELECT_MANY = 'select-many';

const DropdownUsRegions = ({
  activeIcon,
  className,
  containerClassName,
  defaultIcon,
  defaultLabel,
  disabled,
  highlight,
  isVisible,
  labelPrefix,
  optionsLarge,
  optionsMedium,
  selectedValues = [], // list of IDs
  source,
  onChange,
  name,
  pillsPlacement = PILLS_PLACEMENT_ASIDE,
  closeOnSelect = false,
}) => {
  const [active, setActive] = useState(false);
  const [clientHeight, setClientHeight] = useState(
    pillsPlacement === PILLS_PLACEMENT_SELECT_MANY ? 42 : 39
  );
  const _mounted = useRef(false);
  const container = useRef();

  useOnClickOutside(
    container,
    useCallback(() => setActive(false), [])
  );

  useEffect(() => {
    _mounted.current = true;
    if (container.current) {
      setTimeout(() => {
        const { clientHeight = 39 } = container.current || {};
        if (_mounted.current) {
          setClientHeight(clientHeight + 1);
        }
      }, 1);
    }

    return () => {
      _mounted.current = false;
    };
  }, []);

  useEffect(() => {
    const { clientHeight = 39 } = container.current || {};
    const newClientHeight = clientHeight + 1;
    setClientHeight(newClientHeight);
  }, [selectedValues.length]);

  const selectedItems = useMemo(
    () =>
      Array.isArray(source)
        ? source.reduce((acc, item) => {
            const { value, optionType } = item;
            return selectedValues.includes(value) && optionType !== 'folder'
              ? [...acc, item]
              : [...acc];
          }, [])
        : [],
    [selectedValues, source]
  );

  const selectedRegions = useMemo(() => selectedItems.map((item) => item.label), [selectedItems]);

  const [highlightedSelectableOption, setHighlightedSelectableOption] = useState();

  const selectableOptions = useMemo(
    () =>
      source &&
      source.map((item) => {
        const { id, label, value, optionType, subcategories, indent } = item;

        const isSelected = selectedValues.includes(value);

        const subValues = optionType === 'folder' ? subcategories?.map(({ id }) => id) : [];
        const selectedSubValues = subValues?.filter((subVal) => selectedValues.includes(subVal));

        const isFolderSelected =
          optionType === 'folder' && selectedSubValues?.length === subValues?.length;

        const isFolderPartialSelected =
          optionType === 'folder' &&
          selectedSubValues?.length > 0 &&
          selectedSubValues?.length < subValues?.length;

        return (
          <div
            className={classnames(styles.Option, {
              [styles.activeOption]: (isSelected && optionType !== 'folder') || isFolderSelected,
              [styles.partialActiveOption]: isFolderPartialSelected,
              [styles.highlightedSelectableOption]: highlightedSelectableOption === label,
            })}
            key={id}
            onClick={(event) => {
              event.stopPropagation();

              if (active) {
                onChange({
                  ...item,
                  filter: name,
                });

                if (closeOnSelect) setActive(false);
              }
            }}
            onMouseEnter={() => {
              const optionText = label;
              if (highlightedSelectableOption !== optionText) {
                setHighlightedSelectableOption(optionText);
              }
            }}
            onMouseLeave={() => {
              setHighlightedSelectableOption(null);
            }}
            value={value}
          >
            {indent > 0 && <span style={{ width: 16 * indent }} />}
            <span
              className={classnames(styles.label, {
                [styles.activeLabel]: isSelected || isFolderSelected,
                [styles.partialActiveLabel]: isFolderPartialSelected,
              })}
            >
              {label}
            </span>
          </div>
        );
      }),
    [active, closeOnSelect, highlightedSelectableOption, name, onChange, selectedValues, source]
  );

  const selectedPills = useMemo(
    () =>
      selectedItems.length > 0
        ? selectedItems.map((item) => (
            <div
              key={item.label}
              className={classnames(
                styles.pill,
                pillsPlacement === PILLS_PLACEMENT_SELECT_MANY && styles.selectManyPills
              )}
              onClick={(event) => event.stopPropagation()}
            >
              {item.label}
              <FontIcon
                iconName={'close'}
                className={styles.closeIcon}
                onClick={(event) => {
                  event.stopPropagation();
                  onChange({
                    ...item,
                    filter: name,
                  });
                }}
              />
            </div>
          ))
        : null,
    [name, onChange, pillsPlacement, selectedItems]
  );

  const onClickRegion = useCallback(
    (region) => {
      const item = source.find(({ label }) => label === region);

      if (item) {
        onChange({
          ...item,
          filter: name,
        });

        if (closeOnSelect) setActive(false);
      }
    },
    [closeOnSelect, name, onChange, source]
  );

  const onClick = useCallback(
    () => (disabled ? () => null : setActive((active) => !active)),
    [disabled]
  );

  const defaultIconContent = defaultIcon && (
    <FontIcon className={styles.mainIcon} iconName={defaultIcon} />
  );

  const activeIconContent = activeIcon ? (
    <FontIcon className={styles.mainIcon} iconName={activeIcon} />
  ) : (
    defaultIconContent
  );

  const classes = classnames(
    styles.Dropdown,
    {
      [styles.active]: active,
      [styles.focus]: highlight && !active,
      [styles.disabled]: disabled,
      [styles.selectMany]: pillsPlacement === PILLS_PLACEMENT_SELECT_MANY,
    },
    className
  );

  const selectedLabelContent =
    pillsPlacement === PILLS_PLACEMENT_SELECT_MANY && selectedPills ? (
      <div className={styles.pillContainer}>{selectedPills}</div>
    ) : (
      <div
        className={classnames(
          styles.inPlaceDefaultLabel,
          pillsPlacement === PILLS_PLACEMENT_SELECT_MANY && styles.selectManyDefaultLabel
        )}
      >
        {labelPrefix && <span className={styles.labelPrefix}>{labelPrefix}</span>}
        {defaultLabel}
      </div>
    );

  return (
    <div className={classnames(styles.mainContainer, containerClassName)}>
      <div className={classes} onClick={onClick} ref={container}>
        <div
          className={classnames(
            styles.inPlaceDefaultLabel,
            disabled && styles.disabled,
            pillsPlacement === PILLS_PLACEMENT_SELECT_MANY && styles.selectManyDefaultLabel
          )}
        >
          {selectedLabelContent}
        </div>
        {active ? activeIconContent : defaultIconContent}
        <div
          style={{ [isVisible ? 'top' : 'bottom']: `${clientHeight}px` }}
          className={classnames(styles.OptionsContainer, {
            [styles.largeContainer]: optionsLarge,
            [styles.mediumContainer]: optionsMedium,
          })}
        >
          {active && (
            <div className={styles.OptionsWrapper}>
              <div className={styles.selectableOptionsContainer}>{selectableOptions}</div>
              <div
                className={styles.MapContainer}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <UsMapView
                  selectedRegions={selectedRegions}
                  onClickRegion={onClickRegion}
                  highlightedRegion={highlightedSelectableOption}
                  onHighlightRegion={setHighlightedSelectableOption}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {pillsPlacement === PILLS_PLACEMENT_ASIDE && selectedPills && (
        <div className={styles.pillContainer}>{selectedPills}</div>
      )}
    </div>
  );
};

export default VisibleHOC()(DropdownUsRegions);
