import React from 'react';

import Button from 'components/Button';
import InputFile from 'components/InputFile';

import styles from './InitialContent.scss';

const InitialContent = ({ resumeTypeError, handleSubmitResume, handleSkipResume }) => {
  const uploadResumeButtonProps = {
    primary: true,
    className: styles.uploadButton,
    fakeButton: true,
  };

  const resumeInputFileProps = {
    placeholder: '',
    className: styles.candidateResumeInput,
    containerClassName: styles.candidateResumeInputContainer,
    onChange: handleSubmitResume,
    isButton: true,
    name: 'resume',
    accept: 'application/pdf',
  };

  const skipResumeButtonProps = {
    secondary: true,
    className: styles.skipButton,
    onClick: handleSkipResume,
  };

  return (
    <div className={styles.initialContent} data-testid="InitialContent">
      <div className={styles.errorMessage}>{resumeTypeError}</div>
      <InputFile {...resumeInputFileProps}>
        <Button {...uploadResumeButtonProps}>Upload Resume</Button>
      </InputFile>
      <div className={styles.gray}>*PDF required</div>
      <Button {...skipResumeButtonProps}>I don&apos;t have a resume</Button>
    </div>
  );
};

export default InitialContent;
