import React from 'react';
import { Helmet } from 'react-helmet-async';

import { EmployeeAuthHOC } from 'containers/HOC';

import ConnectContainer from 'containers/ConnectContainer';

import ReadyContainer from 'connectors/ReadyContainer';
import EmployeeDisabledPageContent from 'components/EmployeeDisabledPageContent';

import Block from 'components/Block';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import Sidebar from 'components/Sidebar';

import duck from './redux';

import styles from './FeaturedPage.scss';

const FeaturedPageDisabled = (props) => {
  const {
    actions: { employerPatchStarted },
    user: { currentProfile: { employer: { searchRequested } = {} } = {} } = {},
  } = props;

  const actionButtonText = searchRequested === true ? 'Recruiter Notified' : 'Notify my recruiter';
  const actionButtonDisabled = searchRequested === true ? true : false;

  const handleActionButtonClick = () => {
    employerPatchStarted({ name: 'searchRequested', newValue: true });
  };

  const contentBlockProps = {
    boxShadow: true,
    addWhiteBG: true,
    addFlex: true,
    addChildFlex: true,
    addBottomFade: true,
  };

  return (
    <>
      <Helmet key="helmet" title="Matched Candidates" />
      <ReadyContainer key="readyContainer" className={styles.FeaturedPage}>
        <Sidebar type="search" page="featuredCandidates" />

        <LayoutWithoutSidebar
          content={
            <div className={styles.featuredMainContent}>
              <Block {...contentBlockProps}>
                <EmployeeDisabledPageContent
                  actionButtonText={actionButtonText}
                  actionButtonDisabled={actionButtonDisabled}
                  handleActionButtonClick={handleActionButtonClick}
                />
              </Block>
            </div>
          }
        />
      </ReadyContainer>
    </>
  );
};

export default ConnectContainer(duck)(EmployeeAuthHOC()(FeaturedPageDisabled));
