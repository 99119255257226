import { call } from 'redux-saga/effects';

import Validator from 'connectors/Validator';

const sagaValidateInput = function* sagaValidateInput({ newValue, name, errors }) {
  const checkErrorsArgs = {
    errorObject: errors,
    newValue,
    name,
  };

  const { inputErrors, isValid } = yield call(Validator.checkErrors, checkErrorsArgs);
  return { isValid, inputErrors };
};

export default sagaValidateInput;
