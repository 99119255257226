import React from 'react';
import Slider from 'react-rangeslider';

import AutocompleteServer from 'connectors/AutocompleteServer';
import Label from 'components/Label';
import Tag from 'components/Tag';

import styles from '../ReqRequirement.scss';

const SoldToCompanies = ({
  disabled,
  handleToggleArrayValue,
  handleInputChange,
  requisitionRequirement: { attributes: { soldToCompanies, soldToCompaniesWt } = {} } = {},
}) => {
  const soldToCompaniesInputProps = {
    placeholder: 'Start typing and select a company or add if not found',
    value: '',
    size: 'full',
    name: 'soldToCompanies',
    label: 'Companies previously sold to',
    handleInputChange: (event) =>
      handleToggleArrayValue({
        ...event,
        target: {
          ...event.target,
          name: event.target.name,
          value: event.target.value.name,
        },
      }),
    handleOnBlur: handleToggleArrayValue,
    topLabel: true,
    autocompleteType: 'companies',
    field: 'name',
    needReset: true,
    hasAddButton: true,
    disabled,
  };

  const soldToCompaniesSelected =
    soldToCompanies && soldToCompanies.length > 0
      ? soldToCompanies.map((type) => {
          const tagProps = {
            name: type,
            inputName: 'soldToCompanies',
            onDeleteClick: (name, inputName) =>
              handleToggleArrayValue({
                target: {
                  value: name,
                  name: inputName,
                },
              }),
          };
          return <Tag key={type} {...tagProps} />;
        })
      : null;

  const soldToCompaniesWtProps = {
    disabled,
    className: styles.rangeSlider,
    min: 0,
    max: 10,
    step: 5,
    value: soldToCompaniesWt || 0,
    onChange: disabled
      ? () => null
      : (value) =>
          handleInputChange({
            target: {
              value,
              name: 'soldToCompaniesWt',
            },
          }),
    tooltip: false,
    labels: {
      0: '0',
      5: '5',
      10: '10',
    },
  };

  return (
    <div className={styles.rangeInputs}>
      <div className={styles.column}>
        <AutocompleteServer {...soldToCompaniesInputProps} />
        <div className={styles.autocompleteContainer}>{soldToCompaniesSelected}</div>
      </div>
      <div className={styles.column}>
        <Label className={styles.labelStyle}>How much do you value this?</Label>
        <Slider {...soldToCompaniesWtProps} />
      </div>
    </div>
  );
};

export default SoldToCompanies;
