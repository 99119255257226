import React, { Fragment } from 'react';

import Input from 'components/Input';
import Dropdown from 'components/Dropdown';

import RequisitionFilter from '../RequisitionFilter';

import styles from '../../Dashboard.scss';

const SearchBar = ({
  candidateFullName,
  employees,
  handleSearchInputChange,
  // handleSearchFilterChange,
  loadMoreRequisitionOpenings,
  rainmakersAdmins,
  requisitionOpenings,
  requisitionOpeningsLoading,
  requisitionOpeningsTotal,
  requisitionOpeningId,
  state,
  user,
}) => {
  const nameSearchProps = {
    name: 'candidateFullName',
    placeholder: 'Search by candidate name',
    size: 'full',
    handleInputChange: handleSearchInputChange,
    isSearch: true,
    value: candidateFullName,
  };

  const stateOptions = [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Hired',
      value: 'hired',
    },
    {
      label: 'Passed',
      value: 'passed',
    },
    {
      label: 'Archived',
      value: 'archived',
    },
  ];

  const stateFilterProps = {
    source: stateOptions,
    onChange: (value) =>
      handleSearchInputChange({
        target: {
          value: value,
          name: 'state',
        },
      }),
    value: state,
    defaultValue: 'active',
    defaultIcon: 'up-down-caret',
  };

  const requisitionFilterProps = {
    handleSearchInputChange,
    loadMoreRequisitionOpenings,
    employees,
    rainmakersAdmins,
    requisitionOpenings,
    requisitionOpeningsLoading,
    requisitionOpeningsTotal,
    requisitionOpeningId,
    user,
  };

  const extraSearchOptions =
    state === 'archived' ? null : (
      <Fragment>
        <span className={styles.normalText}>
          in
          <RequisitionFilter {...requisitionFilterProps} />
        </span>
        <Input {...nameSearchProps} />
      </Fragment>
    );

  return (
    <Fragment>
      <div className={styles.SearchBarOne}>
        Candidates <span className={styles.normalText}>with status: </span>
        <Dropdown {...stateFilterProps} />
        {extraSearchOptions}
      </div>
    </Fragment>
  );
};

export default SearchBar;
