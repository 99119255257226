import React from 'react';
import classnames from 'classnames';

import styles from './TableCell.scss';

const TableCell = ({ center, children, className, noFlex, flex, onClick }) => {
  const classes = classnames(className, styles.TableCell, {
    [styles.noFlex]: noFlex,
    [styles.flex]: flex,
    [styles.centerText]: center,
  });

  return (
    <div onClick={onClick} className={classes}>
      {children}
    </div>
  );
};

export default TableCell;
