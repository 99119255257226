import { createSelector } from 'reselect';
import { getApp } from 'shared/store/app/selectors';

const selectCandidateOpportunities = createSelector(
  getApp,
  (state) => state.candidateOpportunities
);

const selectTotal = createSelector(
  selectCandidateOpportunities,
  (opportunities) => opportunities.total
);

export default selectTotal;
