import React from 'react';

import { redirectHomepage } from 'utils/navHelpers/index';
import Button from 'components/Button';

import styles from './Approved.scss';

const Approved = (props) => {
  const { user, isApproved, loginPending, hasSeenApproved } = props;

  const redirectPath = redirectHomepage(user, isApproved, hasSeenApproved, loginPending);

  const createAccountButtonProps = {
    className: styles.publishButton,
    primary: true,
    disabled: false,
    to: redirectPath,
  };

  return (
    <div className={styles.welcomeTop}>
      <div className={styles.approvedContainer}>
        <h2 className={styles.approvedHeader}>Congrats, you&apos;ve been approved!</h2>
        <div className={styles.approvedMessage}>
          You&apos;re ready to start finding top sales talent!
        </div>
        <Button {...createAccountButtonProps}>Find Talent</Button>
      </div>
    </div>
  );
};

export default Approved;
