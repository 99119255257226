import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import isEmpty from 'lodash.isempty';

import ReadyContainer from 'connectors/ReadyContainer';
import Validator from 'connectors/Validator';

import ConnectContainer from 'containers/ConnectContainer';
import { ModalHOC } from 'containers/HOC';

import Sidebar from 'components/Sidebar';
import Empty from 'components/Empty';

import UnauthorizedPage from 'pages/UtilPages/UnauthorizedPage';

import { usePrevious } from 'hooks';
import duck from 'pages/EmployeePortal/ManageReqsPage/redux';

import Header from 'pages/AdminPortal/ToolboxRequisitionEditorPage/components/Header';
import RequisitionOpening from 'pages/AdminPortal/ToolboxRequisitionEditorPage/components/RequisitionOpening';
import RequisitionPreview from 'pages/AdminPortal/ToolboxRequisitionEditorPage/components/RequisitionOpeningView';
import RequisitionRequirements from 'pages/AdminPortal/ToolboxRequisitionEditorPage/components/RequisitionRequirement';
import RequisitionIntegrations from 'pages/AdminPortal/ToolboxRequisitionEditorPage/components/RequisitionIntegrations';

import styles from './RequisitionOpening.scss';

const RequisitionOpeningEditor = (props) => {
  const params = useParams();
  const { id, reqCandId, view } = params;
  const prevId = usePrevious(id);

  const [state, setState] = useState({
    changedAttributes: [],
    changeMade: false,
  });

  useEffect(() => {
    const {
      actions: { pickerOptionsLoadStarted },
      requisitionOpening: { attributes: { temporary = false } = {} } = {},
    } = props;

    if (id && !temporary) {
      doLoad(id, false);
    }

    pickerOptionsLoadStarted();

    if (reqCandId) {
      doLoad(reqCandId, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id !== prevId) {
      doLoad(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const doLoad = (id, isReqCopy) => {
    const {
      actions: { entityLoadStarted, nestedEntityLoadStarted },
    } = props;

    if (isReqCopy) {
      entityLoadStarted({
        id,
        params: {
          includeSet: 'employer',
          sortBy: 'created_at_desc',
        },
        slice: 'employerRequisitions',
        type: 'requisition_candidates',
        updateOnly: true,
      });

      entityLoadStarted({
        id,
        params: {
          includeSet: 'dashboard',
          sortBy: 'created_at_desc',
        },
        slice: 'employerRequisitions',
        type: 'requisition_candidates',
        updateOnly: true,
      });

      nestedEntityLoadStarted({
        id,
        nestedType: 'requisition_opening_copy',
        slice: 'employerRequisitions',
        type: 'requisition_candidates',
        irregularType: 'requisition_openings',
        typeOverride: 'requisition_openings',
        updateOnly: true,
        params: {
          includeSet: 'employer',
        },
      });
    } else {
      entityLoadStarted({
        id,
        params: {
          includeSet: 'employer',
          sortBy: 'created_at_desc',
        },
        slice: 'employerRequisitions',
        type: 'requisition_openings',
        updateOnly: true,
      });

      nestedEntityLoadStarted({
        id,
        nestedType: 'requisition_requirement',
        slice: 'employerRequisitions',
        type: 'requisition_openings',
        updateOnly: true,
      });
    }
  };

  const {
    employers: { byId: employersById = {} } = {},
    isEmployee,
    isCandidate,
    location,
    location: { search },
    logoutPending,
    requisitionOpening,
    requisitionOpening: {
      attributes = {},
      attributes: {
        baseSalaryMaxCents,
        baseSalaryMinCents,
        employerId,
        oteMaxCents,
        oteMinCents,
        unauthorized,
        unlimitedHeadcount,
        workModel = [],
      } = {},
    } = {},
    requisitionOpeningErrors,
    requisitionRequirements: { byId = {} } = {},
    user: { currentProfileType, currentProfile: { employer: userEmployer = {} } = {} } = {},
  } = props;

  if (!currentProfileType && !logoutPending) {
    const to = {
      pathname: '/login/candidate',
      search,
      state: { originalPath: location },
    };

    return <Redirect to={to} />;
  }

  const employer = employersById[employerId];

  const requisitionRequirementIds = Object.keys(byId);

  const requisitionRequirementId = requisitionRequirementIds.find((requisitionRequirementId) => {
    const { attributes: { requisitionOpeningId } = {} } = byId[requisitionRequirementId];

    return requisitionOpeningId === id;
  });

  const requisitionRequirement = byId[requisitionRequirementId] || {};

  const { inputErrors } = Validator.checkAllErrors({
    errorObject: requisitionOpeningErrors,
    objectToCheck: attributes,
  });

  const invalidInputs = Object.keys(inputErrors).filter((input) => {
    const invalidRules = Object.keys(inputErrors[input]).filter((rule) => {
      if (input === 'locations' && workModel.length > 0) {
        return false;
      }
      if (input === 'headcount' && unlimitedHeadcount === true) {
        return false;
      }

      if (
        input === 'baseSalaryMaxCents' &&
        baseSalaryMaxCents &&
        baseSalaryMaxCents < baseSalaryMinCents
      ) {
        return true;
      }

      if (input === 'oteMaxCents' && oteMaxCents && oteMaxCents < oteMinCents) {
        return true;
      }

      return inputErrors[input][rule][0] === false || inputErrors[input][rule][0] === null;
    });
    return invalidRules.length > 0;
  });

  const requirementViews = ['requirements', 'requisition-requirement'];

  const newProps = {
    ...props,
    match: { params }, // TODO: remove when child components are refactored
    ...state,
    employer,
    handleSetState: (state) => setState(state),
    isValid: invalidInputs.length === 0,
    requisitionRequirement,
    isRequirements: requirementViews.includes(view),
    slice: 'employerRequisitions',
  };

  const sidebarProps = {
    employer: userEmployer,
    type: 'employerRequisitionEditor',
    page: view,
    id,
  };

  if (unauthorized || (isEmployee && requisitionOpening && employerId !== userEmployer.id)) {
    return <UnauthorizedPage />;
  }

  const header = isCandidate ? null : <Header {...newProps} />;

  return isEmpty(requisitionOpening) ? (
    <Empty />
  ) : (
    <>
      <Helmet key="helmet" title="Requisition Opening" />
      <ReadyContainer key="readyContainer">
        {isEmployee && !reqCandId && <Sidebar {...sidebarProps} />}

        <div className={styles.mainContent}>
          {header}
          <Switch>
            <Route exact path={'/requisition_candidates/:reqCandId/requisition_opening/preview'}>
              <RequisitionPreview {...newProps} />
            </Route>
            <Route exact path={'/requisitions/:id/preview'}>
              <RequisitionPreview {...newProps} />
            </Route>
            <Route exact path={'/openings/:id'}>
              <RequisitionPreview {...newProps} />
            </Route>
            <Route exact path={'/requisitions/:id/edit'}>
              <RequisitionOpening {...newProps} />
            </Route>
            <Route exact path={'/requisitions/:id/view'}>
              <RequisitionOpening {...newProps} />
            </Route>
            <Route exact path={'/requisitions/:id/requirements'}>
              <RequisitionRequirements {...newProps} />
            </Route>
            <Route exact path={'/requisitions/:id/integrations'}>
              <RequisitionIntegrations {...newProps} />
            </Route>
          </Switch>
        </div>
      </ReadyContainer>
    </>
  );
};

export default ConnectContainer(duck)(
  ModalHOC({
    paginatorUrl: '/requisitions/:id/preview',
  })(RequisitionOpeningEditor)
);
