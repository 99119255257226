import React from 'react';

import EmployerData from 'components/EmployerData';
import FormattedDate from 'components/FormattedDate';
import FormattedMoney from 'components/FormattedMoney';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import StatusFilter from 'components/StatusFilter';
import Table from 'components/Table';
import TableCell from 'components/Table/components/TableCell';
import TableRow from 'components/Table/components/TableRow';

import OpportunitiesActionDropdown from '../OpportunitiesActionDropdown';

import styles from './OpportunitiesTable.scss';

const STATUSES = [
  { label: 'All', value: 'all' },
  { label: 'Active', value: 'active' },
  { label: 'Closed', value: 'closed' },
];

const OpportunitiesTable = ({
  showModal,
  candidate = {},
  handleChangeStatus,
  handleSortChange,
  isLoading,
  loadMoreOpportunities,
  rainmakersAdmin,
  opportunities,
  sortBy,
  status,
  total,
}) => {
  const tableHeader = (currentStatus) => {
    switch (currentStatus) {
      default:
        return [
          {
            content: 'Company Name',
            headerClassName: styles.nameHeader,
            value: 'employer.name',
            onClick: handleSortChange,
          },
          {
            content: 'Job Title',
            headerClassName: styles.lastActivityHeader,
          },
          {
            content: 'Compensation',
            headerClassName: styles.lastActivityHeader,
          },
          {
            content: 'Status',
            headerClassName: styles.lastActivityHeader,
            value: 'state_enum',
            onClick: handleSortChange,
          },
          {
            content: 'Initial Request',
            headerClassName: styles.lastActivityHeader,
            value: 'created_at',
            onClick: handleSortChange,
          },
          {
            content: '',
            headerClassName: styles.candActionHeader,
          },
        ];
    }
  };

  const makeOpportunityCells = (opp) => {
    const {
      createdAt,
      id: reqCandidateId,
      employer,
      employer: { name: employerName } = {},
      requisitionOpeningCopy: { externalName, oteMaxCents: oteMax, oteMinCents: oteMin } = {},
      state,
    } = opp;

    const employerDataProps = {
      employer,
      onClick: () =>
        showModal({
          parent: 'CandidateOpportunitiesPage',
          route: `/requisition_candidates/${reqCandidateId}/requisition_opening/preview`,
          key: 'ReqDetailModal',
          originalUrl: `/candidate/opportunities/${status}`,
        }),
    };

    const employerDataContent = (
      <div className={styles.candidateContent}>
        <EmployerData {...employerDataProps} />
      </div>
    );

    const actionsProps = {
      candidate,
      rainmakersAdmin,
      employerName,
      reqCandidateId,
    };

    const jobTitleContent = <div className={styles.lastActivityContent}>{externalName}</div>;

    const passedStates = ['candidate_pass_pending', 'employer_passed', 'candidate_passed'];

    const stateString = passedStates.includes(state) ? 'Passed' : state;

    const stateContent = <div className={styles.lastActivityContent}>{stateString}</div>;

    const actionsContent =
      state === 'active' ? (
        <div className={styles.actionsContent}>
          <OpportunitiesActionDropdown {...actionsProps} />
        </div>
      ) : (
        <div className={styles.actionsContent} />
      );

    const compensationContent = (
      <div className={styles.lastActivityContent}>
        <FormattedMoney amount={oteMin} /> - <FormattedMoney amount={oteMax} />
      </div>
    );

    const createdAtContent = (
      <div className={styles.lastActivityContent}>
        <FormattedDate format="M/D/YY" date={createdAt} parse />
      </div>
    );

    const cells = [
      <TableCell key={1}>{employerDataContent}</TableCell>,
      <TableCell key={2}>{jobTitleContent}</TableCell>,
      <TableCell key={3}>{compensationContent}</TableCell>,
      <TableCell key={4}>{stateContent}</TableCell>,
      <TableCell key={5}>{createdAtContent}</TableCell>,
      <TableCell noFlex key={6}>
        {actionsContent}
      </TableCell>,
    ];

    return cells;
  };

  const makeOpportunityRows = (opp) => (
    <TableRow id={opp.id} key={opp.id}>
      {makeOpportunityCells(opp)}
    </TableRow>
  );

  const tableContent = opportunities.map(makeOpportunityRows);

  const statusFilterProps = {
    statuses: STATUSES,
    value: status || 'all',
    handleChangeStatus,
  };

  const filtersContent = (
    <div className={styles.candidateHeader}>
      <div className={styles.candidateHeaderLeft}>
        <span className={styles.candidateHeaderText}>
          <span className={styles.candidateHeaderBold}>Opportunities </span>with status:
        </span>
        <StatusFilter {...statusFilterProps} />
      </div>
    </div>
  );

  const infiniteScrollProps = {
    loadMore: loadMoreOpportunities,
    hasMore: !isLoading && total > opportunities.length,
    loader: isLoading ? (
      <div key="infiniteScrollLoader" />
    ) : (
      <InfiniteScrollLoader key="infiniteScrollLoader" />
    ),
    useWindow: false,
  };

  const tableProps = {
    blockProps: {
      titleClass: styles.dashboardTitleContainer,
    },
    titleContent: filtersContent,
    tableContent,
    status,
    header: tableHeader(status),
    sortBy,
    infiniteScrollProps,
    tableName: 'candidateOpportunities',
    isEmpty: !isLoading && opportunities.length === 0,
    emptyTextString: 'No Current Opportunities',
    isLoading,
  };

  return <Table {...tableProps} />;
};

export default OpportunitiesTable;
