import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';

import { EmployeeAuthHOC } from 'containers/HOC';
import ConnectContainer from 'containers/ConnectContainer';
import ReadyContainer from 'connectors/ReadyContainer';

import { promiseGetEmployer } from 'pages/EmployeePortal/EmployeeEditorPages/promises';

import { handleError } from 'utils/common';
import { employerInputChange } from 'utils/formHelpers/EmployerHelpers';

import Block from 'components/Block';
import Button from 'components/Button';
import Input from 'components/Input';
import InputCheckboxSlider from 'components/InputCheckboxSlider';
import Label from 'components/Label';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import Sidebar from 'components/Sidebar';

import duck from '../redux';

import styles from './EditIntegrations.scss';

const ERROR_NAMES = {
  greenhouseApiKey: 'APIExceptions::Greenhouse::InvalidAPIKey',
  greenhouseUserEmail: 'APIExceptions::Greenhouse::InvalidUserEmail',
};

@EmployeeAuthHOC()
class EditIntegrations extends Component {
  constructor(props) {
    super(props);

    const { employer: { greenhouseApiKey = null, greenhouseUserEmail = null } = {} } = props;

    this.state = {
      originalGreenhouseApiKey: greenhouseApiKey,
      originalGreenhouseUserEmail: greenhouseUserEmail,
    };
  }

  componentDidMount() {
    const {
      actions: { employerLoadDone, pickerOptionsLoadStarted, tagsLoadStarted },
      user,
    } = this.props;

    const onFulfilled = ({ employer = {} }) => {
      const { greenhouseApiKey, greenhouseUserEmail } = employer;

      this.setState({
        originalGreenhouseApiKey: greenhouseApiKey,
        originalGreenhouseUserEmail: greenhouseUserEmail,
      });

      employerLoadDone({ employer });
    };

    const currentProfile = user.loginDone ? user.currentProfile : {};

    const employerId =
      currentProfile && currentProfile.type === 'Employee' ? currentProfile.employerId : null;

    pickerOptionsLoadStarted();

    tagsLoadStarted();

    const promiseParams = {
      employerId,
      camelizeIt: true,
    };

    promiseGetEmployer(promiseParams).then(onFulfilled).catch(handleError);
  }

  componentWillUnmount() {
    const {
      actions: { employeeErrorsReset },
    } = this.props;

    employeeErrorsReset();
  }

  handleEmployerInputChange = (event) => {
    const {
      actions: { employerPatchStarted, removeEmployeeSaveError },
      employer,
    } = this.props;

    const {
      target: { name: inputName },
    } = event;

    const errorToRemove = ERROR_NAMES[inputName];

    if (errorToRemove) {
      removeEmployeeSaveError({ errorName: errorToRemove });
    }

    const { newValue, name } = employerInputChange(event, employer);

    if (newValue === '') {
      employerPatchStarted({
        newValue: null,
        name,
      });
    } else {
      employerPatchStarted({
        newValue,
        name,
      });
    }
  };

  handleIntegrationRequest = (event) => {
    const {
      actions: { employerPatchStarted },
    } = this.props;

    const {
      target: { name: inputName },
    } = event;

    employerPatchStarted({ name: inputName, newValue: true });
  };

  commonProps = () => {
    return {
      addWhiteBG: true,
      boxShadow: true,
      addPadding: true,
    };
  };

  savingHeader = () => {
    const { employeeSaving } = this.props;

    const titleBlockProps = {
      ...this.commonProps(),
      className: styles.titleBlock,
    };

    const savingContent = employeeSaving ? (
      <div className={styles.autoSaving}>Saving...</div>
    ) : (
      <div className={styles.autoSaved}>Profile Saved</div>
    );

    const saveContent = employeeSaving === undefined ? null : savingContent;

    return (
      <Block {...titleBlockProps}>
        <div className={styles.companyProfileHeader}>
          <div className={styles.companyProfileTitle}>Integrations</div>
          {saveContent}
        </div>
      </Block>
    );
  };

  ssoContent = () => {
    const { employer: { ssoEnabled, ssoRequested } = {} } = this.props;

    const ssoInfoBlockProps = {
      ...this.commonProps(),
      className: styles.mainBlock,
      addChildFlex: true,
      title: 'Single Sign On',
    };

    const ssoButtonProps = {
      primary: true,
      onClick: this.handleIntegrationRequest,
      className: styles.integrationButton,
      name: 'ssoRequested',
      disabled: ssoEnabled || ssoRequested,
    };

    const buttonTitle = ssoEnabled ? 'SSO Enabled' : ssoRequested ? 'SSO Requested' : 'Request SSO';

    const ssoInstructions = ssoEnabled ? (
      <div className={styles.needContact}>
        SSO is enabled. Contact your Rainmakers representative or{' '}
        <a href="mailto:support@rainmakers.co" rel="noopener noreferrer" target="_blank">
          support@rainmakers.co
        </a>{' '}
        for support.
      </div>
    ) : ssoRequested ? (
      <div className={styles.needContact}>
        Your Rainmakers representative will contact you regarding SSO integration.
      </div>
    ) : null;

    return (
      <Block {...ssoInfoBlockProps}>
        {ssoInstructions}
        <Button {...ssoButtonProps}>{buttonTitle}</Button>
      </Block>
    );
  };

  atsInfoBlockProps = () => {
    return {
      ...this.commonProps(),
      className: styles.mainBlock,
      addChildFlex: true,
      title: 'Applicant Tracking System',
    };
  };

  greenhouseEnabled = () => {
    const {
      user: { currentProfile: { editGreenhouse } = {} } = {},
      employer: {
        greenhouseApiKey = '',
        greenhouseUserEmail,
        greenhouseApiKeyIsValid,
        greenhouseUserEmailIsValid,
        pushProspects,
      },
      employeeServerError = [],
    } = this.props;

    const { originalGreenhouseApiKey, originalGreenhouseUserEmail } = this.state;

    const apiKeyError = employeeServerError.includes('APIExceptions::Greenhouse::InvalidAPIKey');

    const emailError = employeeServerError.includes('APIExceptions::Greenhouse::InvalidUserEmail');

    const apiValidationStatusPending =
      !originalGreenhouseApiKey ||
      !greenhouseApiKey ||
      originalGreenhouseApiKey !== greenhouseApiKey
        ? 'Pending'
        : null;

    const apiValidationStatusVerified =
      greenhouseApiKeyIsValid ||
      (originalGreenhouseApiKey && originalGreenhouseApiKey === greenhouseApiKey)
        ? 'Valid'
        : null;

    const apiValidationStatusInvalid = apiKeyError ? 'Invalid' : null;

    const apiValidationStatus =
      apiValidationStatusInvalid ||
      apiValidationStatusVerified ||
      apiValidationStatusPending ||
      'Pending';

    const emailValidationStatusPending =
      !originalGreenhouseUserEmail ||
      !greenhouseUserEmail ||
      originalGreenhouseUserEmail !== greenhouseUserEmail
        ? 'Pending'
        : null;

    const emailValidationStatusVerified =
      greenhouseUserEmailIsValid ||
      (originalGreenhouseUserEmail && originalGreenhouseUserEmail === greenhouseUserEmail)
        ? 'Valid'
        : null;

    const emailValidationStatusInvalid = emailError ? 'Invalid' : null;

    const emailValidationStatus =
      emailValidationStatusInvalid ||
      emailValidationStatusVerified ||
      emailValidationStatusPending ||
      'Pending';

    const greenhouseApiKeyProps = {
      handleInputChange: this.handleEmployerInputChange,
      type: 'text',
      size: 'xlarge',
      name: 'greenhouseApiKey',
      value: greenhouseApiKey || '',
      placeholder: '',
      autoFocus: true,
      className: styles.noPadding,
      disabled: !editGreenhouse,
    };

    const canEnterEmail =
      apiValidationStatusPending === 'Pending' || apiValidationStatus === 'Valid';

    const greenhouseUserEmailInputProps = {
      handleInputChange: this.handleEmployerInputChange,
      type: 'text',
      name: 'greenhouseUserEmail',
      value: greenhouseUserEmail || '',
      size: 'xlarge',
      placeholder: '',
      disabled: !editGreenhouse || !canEnterEmail,
    };

    const pushProspectsProps = {
      name: 'pushProspects',
      checked: pushProspects,
      handleInputChange: this.handleEmployerInputChange,
      label: pushProspects ? 'On' : 'Off',
      disabled: !editGreenhouse,
    };

    const getValidationColor = (status) => {
      switch (status) {
        case 'Valid':
          return styles.valid;
        case 'Invalid':
          return styles.invalid;
        case 'Pending':
          return styles.pending;
        default:
          return styles.pending;
      }
    };

    return (
      <Block {...this.atsInfoBlockProps()}>
        <div className={styles.basicInfoTop}>
          <Label className={styles.labelFlex} disabled={!editGreenhouse} standalone>
            <div>Greenhouse Harvest API Key</div>{' '}
            <div>
              Status:{' '}
              <span className={getValidationColor(apiValidationStatus)}>{apiValidationStatus}</span>
            </div>
          </Label>
          <Input {...greenhouseApiKeyProps} />
          <Label className={styles.label} disabled={!editGreenhouse || !canEnterEmail}>
            <div>Greenhouse User Email</div>{' '}
            <div>
              Status:{' '}
              <span className={getValidationColor(emailValidationStatus)}>
                {emailValidationStatus}
              </span>
            </div>
          </Label>
          <Input {...greenhouseUserEmailInputProps} />
          <div className={styles.hairline} />
          <Label disabled={!editGreenhouse} className={styles.label}>
            <div>
              <div>Push Prospects</div>
              <div className={styles.sublabel}>
                Candidates requested to Requisitions not linked to Greenhouse Jobs will be pushed as
                prospects
              </div>
            </div>
          </Label>
          <InputCheckboxSlider {...pushProspectsProps} />
        </div>
      </Block>
    );
  };

  greenhouseDisabled = () => {
    const { employer: { atsRequested } = {} } = this.props;

    const atsButtonProps = {
      primary: true,
      onClick: this.handleIntegrationRequest,
      className: styles.integrationButton,
      name: 'atsRequested',
      disabled: atsRequested,
    };

    const buttonTitle = atsRequested ? 'ATS Integration Requested' : 'Request ATS Integration';

    const instructionsContent = atsRequested ? (
      <div className={styles.needContact}>
        Your Rainmakers representative will contact you regarding ATS integration.
      </div>
    ) : null;

    return (
      <Block {...this.atsInfoBlockProps()}>
        {instructionsContent}
        <Button {...atsButtonProps}>{buttonTitle}</Button>
      </Block>
    );
  };

  greenhouseContent = () => {
    const {
      employer: { enableGreenhouse },
    } = this.props;

    return enableGreenhouse ? this.greenhouseEnabled() : this.greenhouseDisabled();
  };

  blindHiringContent = () => {
    const { employer: { hideCandidateNames, blindHiringRequested } = {} } = this.props;

    const blindHiringInfoBlockProps = {
      ...this.commonProps(),
      className: styles.mainBlock,
      addChildFlex: true,
      title: 'Blind Hiring',
    };

    const blindHiringButtonProps = {
      primary: true,
      onClick: this.handleIntegrationRequest,
      className: styles.integrationButton,
      name: 'blindHiringRequested',
      disabled: hideCandidateNames || blindHiringRequested,
    };

    const buttonTitle = hideCandidateNames
      ? 'Blind Hiring Enabled'
      : blindHiringRequested
      ? 'Blind Hiring Requested'
      : 'Request Blind Hiring';

    const blindHiringInstructions = hideCandidateNames ? (
      <div className={styles.needContact}>
        Blind Hiring is enabled. Contact your Rainmakers representative or{' '}
        <a href="mailto:support@rainmakers.co" rel="noopener noreferrer" target="_blank">
          support@rainmakers.co
        </a>{' '}
        for support.
      </div>
    ) : blindHiringRequested ? (
      <div className={styles.needContact}>
        Your Rainmakers representative will contact you regarding Blind Hiring.
      </div>
    ) : null;

    return (
      <Block {...blindHiringInfoBlockProps}>
        {blindHiringInstructions}
        <Button {...blindHiringButtonProps}>{buttonTitle}</Button>
      </Block>
    );
  };

  render() {
    const { isApproved, employer } = this.props;

    const content = (
      <div className={styles.CompanyForm}>
        <div className={styles.scrollDiv}>
          {this.savingHeader()}
          {this.greenhouseContent()}
          {this.ssoContent()}
          {this.blindHiringContent()}
        </div>
      </div>
    );

    const layoutWithoutSidebarProps = {
      content,
    };

    const sidebarProps = {
      type: isApproved ? 'employeeStatusApproved' : 'employeeStatusUnapproved',
      page: 'edit-integrations',
      employer,
    };

    return [
      <Helmet key="helmet" title="Settings" />,
      <ReadyContainer key="readyContainer">
        <Sidebar {...sidebarProps} />
        <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />
      </ReadyContainer>,
    ];
  }
}

export default ConnectContainer(duck)(EditIntegrations);
