import React from 'react';

import Table from 'components/Table';
import TableRow from 'components/Table/components/TableRow';
import TableCell from 'components/Table/components/TableCell';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import InputCheckbox from 'components/InputCheckbox';
import CandidateData from 'components/CandidateData';

import styles from './CohortLinksTable.scss';

const CohortLinksTable = ({
  candidates: { byId: candidatesById = {} } = {},
  cohortLinks: { allIds = [], byId = {} } = {},
  cohortLinksLoading,
  cohortLinksTotal,
  loadMoreCohortLinks,
  titleContent,
}) => {
  const tableHeader = [
    {
      content: 'Candidate',
      headerClassName: styles.companyHeader,
    },
    {
      content: 'Enabled',
      headerClassName: styles.checkboxHeader,
    },
    {
      content: 'Disabled',
      headerClassName: styles.checkboxHeader,
    },
  ];

  const makeCandidateRows = (id) => {
    const {
      attributes: { state } = {},
      relationships: { candidate: { data: candidateIds = [] } = {} } = {},
    } = byId[id] || {};

    const candidateDataProps = {
      candidate: candidatesById[candidateIds[0]] || {},
    };

    const candidateContent = (
      <div className={styles.candidateContent}>
        <CandidateData {...candidateDataProps} />
      </div>
    );

    const enableCheckboxProps = {
      name: 'toggle_enable',
      checked: state === 'enabled',
      disabled: true,
    };

    const disableCheckboxProps = {
      name: 'toggle_disable',
      checked: state === 'blocked',
      disabled: true,
    };

    const row = (
      <TableRow id={id} key={id} className={styles.candidateRow}>
        <TableCell key={1}>{candidateContent}</TableCell>
        <TableCell flex key={2}>
          <div className={styles.candidateCheckbox}>
            <InputCheckbox {...enableCheckboxProps} />
          </div>
        </TableCell>
        <TableCell flex key={3}>
          <div className={styles.candidateCheckbox}>
            <InputCheckbox {...disableCheckboxProps} />
          </div>
        </TableCell>
      </TableRow>
    );

    return row;
  };

  const tableContent = allIds.map(makeCandidateRows);

  const infiniteScrollProps = {
    pageStart: 0,
    loadMore: loadMoreCohortLinks,
    hasMore: !cohortLinksLoading && cohortLinksTotal > allIds.length,
    loader: <InfiniteScrollLoader />,
    useWindow: false,
  };

  const tableProps = {
    blockProps: {
      titleClass: styles.mainBlockTitle,
    },
    titleContent,
    header: tableHeader,
    noBlockFlex: true,
    tableName: 'candidates',
    infiniteScrollProps,
    tableContent,
    isEmpty: !cohortLinksLoading && allIds.length === 0,
    isLoading: cohortLinksLoading,
    emptyTextString: 'No Candidates',
  };

  return <Table {...tableProps} />;
};

export default CohortLinksTable;
