import { handleActions } from 'redux-actions';

import { SIGNUP_ERROR, SIGNUP_ERRORS_SET } from 'pages/EmployeePortal/SignupPage/redux/actions';

const initialState = {
  errors: {
    firstName: {
      isNotEmpty: [null],
    },
    lastName: {
      isNotEmpty: [null],
    },
    email: {
      isNotEmpty: [null],
      isEmail: [null],
      isAvailable: [true, 'email'],
    },
    name: {
      isNotEmpty: [null],
      isAvailable: [true, 'name'],
    },
    phoneNumber: {
      isNotEmpty: [null],
      isPhoneNumber: [null],
    },
    hiringLocations: {
      hasXElements: [null, 1],
    },
    password: {
      isNotEmpty: [null],
      minLength: [null, 8],
      hasANumber: [null],
      isPassword: [null],
    },
  },
  serverErrors: [],
};

const handleSignupError = (state = initialState, action) => {
  const {
    payload: { errors },
  } = action;

  return {
    ...state,
    serverErrors: errors,
  };
};

const handleSignupErrorsSet = (state = initialState, action) => {
  const {
    payload: { inputErrors },
  } = action;

  return {
    ...state,
    errors: {
      ...state.errors,
      ...inputErrors,
    },
  };
};

const actionHandlers = {
  [SIGNUP_ERROR]: handleSignupError,
  [SIGNUP_ERRORS_SET]: handleSignupErrorsSet,
};

const reduceSignup = handleActions(actionHandlers, initialState);

export default reduceSignup;
