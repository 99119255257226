import React, { useState } from 'react';

import EmptyCompanyLogo from 'shared/assets/missing-company-picture.png';

import Bolder from 'components/Bolder';
import TableOverlay from 'components/TableOverlay';

import { handleWebUrl } from 'utils/urlUtils';

import styles from './Header.scss';

const Header = ({
  employer: {
    attributes: {
      name,
      socialMedia: {
        twitter: { url: twitterUrl = '' } = {},
        linkedin: { url: linkedInUrl = '' } = {},
        facebook: { url: facebookUrl = '' } = {},
      } = {},
      picture: { xlarge: pictureUrl, xxlarge } = {},
      webSite,
    } = {},
  } = {},
  requisitionOpening: { attributes: { externalName } = {} } = {},
}) => {
  const [showImageOverlay, setShowImageOverlay] = useState(false);

  const handleImageError = (event) => {
    event.persist();
    event.target.src = EmptyCompanyLogo;

    setShowImageOverlay(true);

    setTimeout(() => {
      event.target.src = xxlarge;
      setShowImageOverlay(true);
    }, 5000);
  };

  const generateLink = (url, displayText) => {
    const isValid = url !== '' && url !== null && url !== undefined;

    const siteUrl = handleWebUrl(url);

    return isValid ? (
      <div className={styles.headerLink}>
        <a href={siteUrl} rel="noreferrer" target="_blank">
          {displayText}
        </a>
      </div>
    ) : null;
  };

  return (
    <div className={styles.Header}>
      <div className={styles.headerLogoContainer}>
        <img
          onError={handleImageError}
          src={pictureUrl === '' ? EmptyCompanyLogo : pictureUrl}
          alt=""
        />
        {showImageOverlay ? <TableOverlay table="image" /> : null}
      </div>
      <div className={styles.rightContent}>
        <div className={styles.mainDetails}>
          <Bolder className={styles.company}>{name}</Bolder>
          <div className={styles.reqTitle}>{externalName}</div>
        </div>
        <div className={styles.links}>
          {generateLink(webSite, 'Website')}
          {generateLink(linkedInUrl, 'LinkedIn')}
          {generateLink(twitterUrl, 'Twitter')}
          {generateLink(facebookUrl, 'Facebook')}
        </div>
      </div>
    </div>
  );
};

export default Header;
