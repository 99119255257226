import { call, put, take } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { promiseGetCandidateCompanyBlocks } from 'pages/AdminPortal/ToolboxCandidateEditorPage/promises';

import { CANDIDATE_COMPANY_BLOCKS_LOAD_STARTED } from '../actions';

import { candidateCompanyBlocksLoadDone } from '../creators';

const sagaCandidateCompanyBlocksLoadStarted = function* sagaCandidateCompanyBlocksLoadStarted() {
  while (true) {
    const {
      payload: { candidateId, page },
    } = yield take(CANDIDATE_COMPANY_BLOCKS_LOAD_STARTED);

    try {
      const promiseParams = {
        candidateId,
        includeSet: 'company',
        page,
      };

      const { candidateCompanyBlocks, page: newPage, total } = yield call(
        promiseGetCandidateCompanyBlocks,
        promiseParams
      );

      yield put(
        candidateCompanyBlocksLoadDone({
          candidateCompanyBlocks,
          page: newPage,
          total,
        })
      );
    } catch (error) {
      handleError(error);
    }
  }
};

export default sagaCandidateCompanyBlocksLoadStarted;
