import { take, call, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { getEntity } from 'api/apiEntity';

import { WAIT_FOR_JOB } from 'shared/store/app/actions';

const sagaWaitForJob = function* sagaWaitForJob() {
  while (true) {
    const {
      payload: { jobId, onCompleteAction, onCompletePayload },
    } = yield take(WAIT_FOR_JOB);

    let jobFinished = false;
    let delayTime = 500;
    let timeout = 20;

    const args = {
      type: 'jobs',
      id: jobId,
    };

    while (!jobFinished && timeout > 0) {
      const {
        data: { job },
      } = yield call(getEntity, args);

      jobFinished = job.status === 'complete';

      if (job.status === 'retrying') {
        delayTime += 250;
      }

      if (!jobFinished) {
        timeout -= 1;
        yield call(delay, delayTime);
      }
    }

    yield put(onCompleteAction(onCompletePayload));
  }
};

export default sagaWaitForJob;
