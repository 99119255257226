import { call, put, take, select } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { deleteEntity } from 'api/apiEntity';

import { CANDIDATE_COMPANY_BLOCKS_DELETE_STARTED } from '../actions';

import { candidateCompanyBlocksLoadDone } from '../creators';

import { selectCandidateCompanyBlocks, selectCandidateCompanyBlocksTotal } from '../selectors';

const sagaCandidateCompanyBlocksDeleteStarted = function* sagaCandidateCompanyBlocksDeleteStarted() {
  while (true) {
    const {
      payload: { candidateCompanyBlockId },
    } = yield take(CANDIDATE_COMPANY_BLOCKS_DELETE_STARTED);

    try {
      const args = {
        id: candidateCompanyBlockId,
        type: 'candidate_company_blocks',
      };

      yield call(deleteEntity, args);

      const candidateCompanyBlocks = yield select(selectCandidateCompanyBlocks);
      const candidateCompanyBlocksTotal = yield select(selectCandidateCompanyBlocksTotal);

      const newBlocks = candidateCompanyBlocks.filter(
        (block) => block.id !== candidateCompanyBlockId
      );
      const newTotal = candidateCompanyBlocksTotal - 1;

      yield put(
        candidateCompanyBlocksLoadDone({
          candidateCompanyBlocks: newBlocks,
          total: newTotal,
        })
      );
    } catch (error) {
      handleError(error);
    }
  }
};

export default sagaCandidateCompanyBlocksDeleteStarted;
