import React, { useState, useRef, useCallback } from 'react';
import classnames from 'classnames';

import { useOnClickOutside } from 'hooks';

import ConnectContainer from 'containers/ConnectContainer';

import FontIcon from 'components/FontIcon';

import duck from './redux';

import styles from './User.scss';

const User = ({
  isAdmin,
  isCandidate,
  actions: { logoutStarted },
  user,
  user: { currentProfile: { employer: { name: companyName = '' } = {} } = {} } = {},
}) => {
  const [active, setActive] = useState(false);
  const ref = useRef();

  useOnClickOutside(
    ref,
    useCallback(() => setActive(false), [])
  );

  const handleSignOut = () => {
    logoutStarted();
  };

  const handleOnClick = () => setActive((active) => !active);

  const signOutClasses = classnames(styles.Option, styles.signOutOption);

  const signOutProps = {
    onClick: handleSignOut,
    className: signOutClasses,
  };

  const userProps = {
    onClick: handleOnClick,
    className: styles.User,
  };

  const icon = active ? 'caret-up' : 'caret-down';

  if (isAdmin) {
    const dropdown = active ? (
      <div className={styles.userOptionsContainer}>
        <div {...signOutProps}>Sign Out</div>
      </div>
    ) : null;

    return (
      <div {...userProps} ref={ref}>
        <div className={styles.avatarContainer}>
          RM Admin
          <FontIcon className={styles.userCaret} iconName={icon} />
        </div>
        {dropdown}
      </div>
    );
  }

  if (isCandidate) {
    const { currentProfile: { firstName = '' } = {} } = user;

    const dropdown = active ? (
      <div className={styles.userOptionsContainer}>
        <div {...signOutProps}>Sign Out</div>
      </div>
    ) : null;

    return (
      <div {...userProps} ref={ref}>
        <div className={styles.avatarContainerDark}>
          <div className={styles.companyName}>{firstName}</div>
          <FontIcon className={styles.userCaret} iconName={icon} />
        </div>
        {dropdown}
      </div>
    );
  }

  const dropdown = active ? (
    <div className={styles.userOptionsContainer}>
      <div {...signOutProps}>Sign Out</div>
    </div>
  ) : null;

  return (
    <div {...userProps} ref={ref}>
      <div className={styles.avatarContainer}>
        <div className={styles.companyName}>{companyName}</div>
        <FontIcon className={styles.userCaret} iconName={icon} />
      </div>
      {dropdown}
    </div>
  );
};

export default ConnectContainer(duck)(User);
