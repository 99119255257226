import { handleActions } from 'redux-actions';

import {
  ADD_REQUESTED_CANDIDATE,
  CLEAR_REQUESTED_CANDIDATES,
  BOOKMARKS_ADD_DONE,
  BOOKMARKS_REMOVE_DONE,
  PROFILE_BOOKMARK_ADD,
  PROFILE_BOOKMARK_REMOVE,
  REQUEST_SENT_DONE,
  REQUEST_ERROR_SET,
  RESET_EMULATED_EMPLOYER,
  RESULTS_LOAD_STARTED,
  RESULTS_LOAD_DONE,
  RESULTS_RESET,
  RESULTS_UPDATE,
  SET_EMULATED_EMPLOYER,
  SET_SEARCH_PARAMS,
  RESET_SEARCH_PARAMS,
  SAVE_SEARCH_OPTIONS,
} from '../actions';

const initialState = {
  entities: {
    candidate: {
      byId: {},
      allIds: [],
    },
    savedSearch: {
      byId: {},
      allIds: [],
    },
  },
  meta: {
    candidate: {
      pagination: {},
    },
    savedSearch: {
      pagination: {},
    },
  },
  requestConfirmation: {
    show: false,
    firstName: '',
    lastName: '',
  },
  employer: {},
  searchParams: null,
  searchString: null,
  requestedCandidates: [],
  candidateSearchOptions: {},
};

const handleSaveSearchOptions = (state = initialState, action) => ({
  ...state,
  candidateSearchOptions: action.payload.candidateSearchOptions,
});

const handleClearRequestedCandidates = (state = initialState) => ({
  ...state,
  requestedCandidates: [],
});

const handleAddRequestedCandidate = (state = initialState, action) => {
  const {
    payload: { candidateId },
  } = action;

  const { requestedCandidates } = state;

  const newCandidates = requestedCandidates.slice();

  newCandidates.push(candidateId);

  return {
    ...state,
    requestedCandidates: newCandidates,
  };
};

const handleSetSearchParams = (state = initialState, action) => {
  const {
    payload: { searchParams, searchString },
  } = action;

  return {
    ...state,
    searchParams,
    searchString,
  };
};

const handleResetSearchParams = (state = initialState) => ({
  ...state,
  searchParams: null,
  searchString: null,
});

const handleProfileBookmarkAdd = (state = initialState, action) => {
  const {
    payload: { bookmark, candidateId },
  } = action;

  if (state.entities.candidate.byId[candidateId]) {
    return {
      ...state,
      entities: {
        ...state.entities,
        candidate: {
          ...state.entities.candidate,
          byId: {
            ...state.entities.candidate.byId,
            [candidateId]: {
              ...state.entities.candidate.byId[candidateId],
              attributes: {
                ...state.entities.candidate.byId[candidateId].attributes,
                bookmark,
              },
            },
          },
        },
      },
    };
  }
};

const handleProfileBookmarkRemove = (state = initialState, action) => {
  const {
    payload: { candidateId },
  } = action;

  if (state.entities.candidate.byId[candidateId]) {
    return {
      ...state,
      entities: {
        ...state.entities,
        candidate: {
          ...state.entities.candidate,
          byId: {
            ...state.entities.candidate.byId,
            [candidateId]: {
              ...state.entities.candidate.byId[candidateId],
              attributes: {
                ...state.entities.candidate.byId[candidateId].attributes,
                bookmark: null,
              },
            },
          },
        },
      },
    };
  }
};

const handleSetEmployer = (state = initialState, action) => {
  const {
    payload: { employer },
  } = action;

  return {
    ...state,
    employer,
  };
};

const handleResetEmployer = (state = initialState) => ({
  ...state,
  employer: {},
});

const handleEntityUpdate = (state = initialState, action) => {
  const {
    payload: { entity, type, pagination = {} },
  } = action;

  return {
    ...state,
    entities: {
      ...state.entities,
      [type]: {
        ...state.entities[type],
        ...entity,
      },
    },
    meta: {
      ...state.meta,
      [type]: {
        ...state.meta[type],
        pagination: {
          ...state.meta[type].pagination,
          ...pagination,
        },
      },
    },
  };
};

const handleResultsLoadStarted = (state = initialState, action) => {
  const {
    payload: { page = 1, secondPageOne = false },
  } = action;

  if (page === 1 && !secondPageOne) {
    return {
      ...state,
      meta: {
        ...state.meta,
        candidate: {
          ...state.meta.candidate,
          isLoading: true,
        },
      },
    };
  }

  return state;
};

const handleResultsLoadDone = (state = initialState, action) => {
  const {
    payload: { data: { entities = {}, meta = {} } = {}, type },
  } = action;

  // Isn't this the same as clearing with isFirstPage?
  // entities is {} when empty, so this doesn't ever run?
  if (!entities) {
    return {
      ...state,
      entities: {
        [type]: {
          ...initialState.entities[type],
        },
      },
      meta: {
        ...state.meta,
        [type]: {
          ...meta,
          isLoading: false,
        },
      },
    };
  }

  const isFirstPage = meta?.pagination?.sortSearchAfter ? false : true;

  if (isFirstPage) {
    const newState = {
      ...state,
      entities: {
        ...entities,
      },
      meta: {
        ...state.meta,
        [type]: {
          ...meta,
          isLoading: false,
        },
      },
    };

    return newState;
  }

  const entityKeys = Object.keys(entities);

  const newEntities = entityKeys.reduce((acc, curr) => {
    const currState = state.entities[curr];

    acc[curr] = {
      byId: {
        ...currState.byId,
        ...entities[curr].byId,
      },
      allIds: currState.allIds.concat(entities[curr].allIds),
    };

    return acc;
  }, {});

  return {
    ...state,
    entities: {
      ...state.entities,
      ...newEntities,
    },
    meta: {
      ...state.meta,
      [type]: {
        ...meta,
        isLoading: false,
      },
    },
  };
};

const handleBookmarksAddDone = (state = initialState, action) => {
  const {
    payload: { candidates },
  } = action;

  return {
    ...state,
    data: candidates,
  };
};

const handleBookmarksRemoveDone = (state = initialState, action) => {
  const {
    payload: { candidates },
  } = action;

  return {
    ...state,
    data: candidates,
  };
};

const handleResultsReset = (state = initialState) => ({
  ...state,
  entities: {
    ...state.entities,
    candidate: {
      byId: {},
      allIds: [],
    },
  },
  meta: {
    ...state.meta,
    candidate: {
      pagination: {},
    },
  },
});

const handleRequestSentDone = (state = initialState, action) => {
  const {
    payload: { show, firstName, lastName },
  } = action;

  return {
    ...state,
    requestConfirmation: {
      show,
      firstName,
      lastName,
    },
  };
};

const handleRequestErrorSet = (state = initialState, action) => {
  const {
    payload: { errorMessage, firstName, lastName, show },
  } = action;

  return {
    ...state,
    requestConfirmation: {
      show,
      firstName,
      lastName,
      errorMessage,
    },
  };
};

const actionHandlers = {
  [BOOKMARKS_ADD_DONE]: handleBookmarksAddDone,
  [BOOKMARKS_REMOVE_DONE]: handleBookmarksRemoveDone,
  [PROFILE_BOOKMARK_ADD]: handleProfileBookmarkAdd,
  [PROFILE_BOOKMARK_REMOVE]: handleProfileBookmarkRemove,
  [RESULTS_LOAD_STARTED]: handleResultsLoadStarted,
  [RESULTS_LOAD_DONE]: handleResultsLoadDone,
  [RESULTS_RESET]: handleResultsReset,
  [RESULTS_UPDATE]: handleEntityUpdate,
  [REQUEST_SENT_DONE]: handleRequestSentDone,
  [REQUEST_ERROR_SET]: handleRequestErrorSet,
  [RESET_EMULATED_EMPLOYER]: handleResetEmployer,
  [SET_EMULATED_EMPLOYER]: handleSetEmployer,
  [SET_SEARCH_PARAMS]: handleSetSearchParams,
  [RESET_SEARCH_PARAMS]: handleResetSearchParams,
  [ADD_REQUESTED_CANDIDATE]: handleAddRequestedCandidate,
  [CLEAR_REQUESTED_CANDIDATES]: handleClearRequestedCandidates,
  [SAVE_SEARCH_OPTIONS]: handleSaveSearchOptions,
};

const reduceSearch = handleActions(actionHandlers, initialState);

export default reduceSearch;
