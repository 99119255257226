import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import isEmpty from 'lodash.isempty';

import { handleError } from 'utils/common';
import ConnectContainer from 'containers/ConnectContainer';

import {
  promiseGetFullCandidate,
  promiseGetCandidate,
} from 'pages/EmployeePortal/CandidateProfilePages/promises';
import { promiseGetAdmin } from 'pages/AdminPortal/ToolboxAdminsPage/promises';

import NotFound from 'pages/UtilPages/NotFound';
import CandidateProfile from '../CandidateProfile';
import CandidateEmpty from '../CandidateEmpty';

import duck from '../redux';

class CandidateNonModalWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = props.candidate
      ? {
          isCandidateLoading: false,
          candidateProfile: props.candidate,
        }
      : {
          isCandidateLoading: true,
          candidateProfile: {},
        };
  }

  componentDidMount() {
    const {
      actions: { requisitionDetailsLoadStarted },
      match: { params: { id: candidateId } = {} } = {},
      isEmployee,
      isAdmin,
      candidate = {},
      loggedInSearchEmployerId,
    } = this.props;

    if (isEmployee || (isAdmin && loggedInSearchEmployerId)) {
      requisitionDetailsLoadStarted({
        page: 1,
        employerId: loggedInSearchEmployerId,
      });
    }

    if (candidateId && isEmpty(candidate)) {
      promiseGetFullCandidate({
        isEmployee,
        candidateId,
        contextOrigin: 'featured',
        includeBookmark: isEmployee,
        noPrivate: true,
        noUser: true,
      })
        .then(this.onCandidateSuccess)
        .catch(this.onCandidateFail);
    } else {
      this.onCandidateFail(Error('no candidateId in CandidateNonModalWrapper'));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      match: { params: { id } = {} } = {},
      actions: { requisitionDetailsLoadStarted, resetNeedReload },
      candidate = {},
      loggedInSearchEmployerId,
      isEmployee,
      isAdmin,
      needReload,
    } = nextProps;

    const {
      loggedInSearchEmployerId: currLoggedInSearchEmployerId,
      match: { params: { id: currId } = {} } = {},
    } = this.props;

    if (!isEmpty(candidate)) {
      this.setState({
        candidateProfile: candidate,
        isCandidateLoading: false,
      });
    }

    if (loggedInSearchEmployerId && loggedInSearchEmployerId !== currLoggedInSearchEmployerId) {
      requisitionDetailsLoadStarted({
        page: 1,
        employerId: loggedInSearchEmployerId,
      });
    }

    if (isEmployee || (isAdmin && loggedInSearchEmployerId)) {
      if (id && id !== currId) {
        requisitionDetailsLoadStarted({
          page: 1,
          employerId: loggedInSearchEmployerId,
        });
      }
    }

    if (id && id !== currId) {
      this.handleScrollToTop();
    }

    if (needReload || (id && id !== currId && isEmpty(candidate))) {
      if (needReload) {
        resetNeedReload();
      }
      this.setState({ isCandidateLoading: !needReload });
      promiseGetFullCandidate({
        isEmployee,
        candidateId: id,
        contextOrigin: 'featured',
        includeBookmark: isEmployee,
        noPrivate: true,
        noUser: true,
      })
        .then(this.onCandidateSuccess)
        .catch(this.onCandidateFail);
    }

    if (id && id !== currId && !isAdmin) {
      promiseGetCandidate({ candidateId: id, contextOrigin: 'featured' });
    }
  }

  componentWillUnmount() {
    const {
      actions: { resetCandidate },
    } = this.props;

    resetCandidate();
  }

  onCandidateSuccess = (candidateProfile) => {
    const { rainmakers_admin_id: adminId } = candidateProfile;
    this.setState({
      candidateProfile: {
        ...candidateProfile,
        rainmakers_admin: {},
      },
      isCandidateLoading: false,
    });

    if (adminId) {
      promiseGetAdmin({
        adminId,
        includeSet: 'user',
      })
        .then(({ admin }) =>
          this.setState((prevState) => ({
            candidateProfile: {
              ...prevState.candidateProfile,
              rainmakers_admin: admin,
            },
          }))
        )
        .catch(handleError);
    }
  };

  onCandidateFail = (error) => {
    handleError(error);
    this.setState({
      isCandidateLoading: false,
    });
  };

  setScrollDivRef = (ref) => {
    this.scrollDiv = ref;
  };

  handleScrollToTop = () => {
    if (this.scrollDiv) {
      this.scrollDiv.scrollTop = 0;
    }
  };

  handleGetCandidate = ({ showLoader = true }) => {
    const { match: { params: { id: candidateId } = {} } = {}, isEmployee } = this.props;

    if (showLoader) {
      this.setState({
        isCandidateLoading: true,
      });
    }

    promiseGetFullCandidate({
      isEmployee,
      candidateId,
      noPrivate: true,
      includeBookmark: isEmployee,
      noUser: true,
    })
      .then(this.onCandidateSuccess)
      .catch(this.onCandidateFail);
  };

  render() {
    const {
      match: { params: { id, view } = {} } = {},
      candidateProfileReqs,
      isMatchLoading,
    } = this.props;

    const { candidateProfile, isCandidateLoading, loadError } = this.state;

    const newProps = {
      setScrollDivRef: this.setScrollDivRef,
      key: 'candidateProfile',
      handleGetCandidate: this.handleGetCandidate,
      candidateProfile,
      candidateProfileReqs,
      isCandidateLoading,
      useHideNames: true,
      ...this.props,
    };

    const content = <CandidateProfile {...newProps} />;

    if (loadError) {
      return <NotFound />;
    }

    const title = 'Candidate Profile';
    const metaDescription = 'Rainmakers Candidate Profile';
    const ogDescription = "Visit Rainmakers today to view this sales candidate's profile.";
    const canonicalUrl = `https://${process.env.HOST}/${id}/${view}/`;

    const helmetProps = {
      key: 'helmet',
      title,
      meta: [
        { name: 'description', content: metaDescription },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: canonicalUrl },
        { property: 'og:title', content: title },
        { property: 'og:description', content: ogDescription },
        { property: 'og:image', content: require('shared/assets/candidate-profile-logo.jpg') },
        { property: 'og:image:width', content: '75' },
        { property: 'og:image:height', content: '75' },
      ],
      link: [{ rel: 'canonical', href: canonicalUrl }],
    };

    const showContent = id && !isMatchLoading && !isCandidateLoading && !isEmpty(candidateProfile);

    return (
      <>
        <Helmet {...helmetProps} />
        {showContent ? content : <CandidateEmpty {...newProps} />}
      </>
    );
  }
}

export default ConnectContainer(duck)(CandidateNonModalWrapper);
