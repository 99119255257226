import React from 'react';

export const MarginContainer = ({ top, bottom, left, right, children }) => {
  return (
    <div
      style={{
        ...(top === undefined ? {} : { marginTop: `${top}px` }),
        ...(bottom === undefined ? {} : { marginBottom: `${bottom}px` }),
        ...(left === undefined ? {} : { marginLeft: `${left}px` }),
        ...(right === undefined ? {} : { marginRight: `${right}px` }),
      }}
    >
      {children}
    </div>
  );
};
