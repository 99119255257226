import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import classnames from 'classnames';

import Overlay from 'components/Overlay';

import styles from './DialogueModal.scss';

const DialogueModal = ({ className, children, ...others }) => {
  const [showModal, setShowModal] = useState(false);
  const classes = classnames(styles.DialogueModal, className);

  useEffect(() => {
    setShowModal(true);
  }, []);

  return (
    <Overlay isOpened {...others}>
      <CSSTransition in={showModal} timeout={0} classNames={{ ...styles }}>
        <div className={classes}>{children}</div>
      </CSSTransition>
    </Overlay>
  );
};

export default DialogueModal;
