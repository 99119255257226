import { call, put, select, take } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { selectIsAdmin } from 'connectors/Login/redux/selectors';

import {
  promisePostMessage,
  promisePostRequisitionCandidates,
  promisePatchRequisitionOpening,
} from 'pages/EmployeePortal/CandidateProfilePages/promises';

import {
  requestSentDone,
  requestErrorSet,
  addRequestedCandidate,
} from 'pages/EmployeePortal/CandidateSearchPage/redux/creators';
import { selectSearchEmployer } from 'pages/EmployeePortal/CandidateSearchPage/redux/selectors';

import { REQUISITION_CANDIDATES_CREATE_STARTED } from '../actions';

const sagaRequisitionCandidatesCreateStarted = function* sagaRequisitionCandidatesCreateStarted() {
  while (true) {
    const {
      payload: { candidateId, category, employerId, firstName, lastName, newReq, ownerId, reqId },
    } = yield take(REQUISITION_CANDIDATES_CREATE_STARTED);

    try {
      const promiseParams = {
        origin: category,
        candidateId,
        reqId,
        ownerId,
      };

      if (newReq) {
        const patchParams = {
          newValue: 'open',
          name: 'state',
          reqId,
        };

        yield call(promisePatchRequisitionOpening, patchParams);
      }

      const { data: { requisitionCandidate: { messageGroups = [] } = {} } = {} } = yield call(
        promisePostRequisitionCandidates,
        promiseParams
      );

      yield put(addRequestedCandidate({ candidateId }));

      const isAdmin = yield select(selectIsAdmin);

      if (isAdmin && employerId) {
        const { enableMessaging, name: employerName } = yield select(selectSearchEmployer);

        if (enableMessaging) {
          const promiseMessageParams = {
            subject: `<Rainmakers> ${employerName} / ${firstName} ${lastName} Interview Request`,
            messageGroupId: messageGroups[0].id,
            template: 'linked_obj_req_can',
            creatorId: ownerId,
            creatorType: 'Employee',
          };

          yield call(promisePostMessage, promiseMessageParams);
        }
      }

      yield put(
        requestSentDone({
          show: true,
          firstName,
          lastName,
        })
      );
    } catch (error) {
      handleError(error);

      const errorMessage =
        'We could not process your request for introduction at this time. If the issue persists, please contact support@rainmakers.co';

      yield put(
        requestErrorSet({
          show: true,
          firstName,
          lastName,
          errorMessage,
        })
      );
    }
  }
};

export default sagaRequisitionCandidatesCreateStarted;
