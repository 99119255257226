import React, { useEffect, useState, useRef, useCallback } from 'react';
import classnames from 'classnames';

import { VisibleHOC } from 'containers/HOC';
import { useOnClickOutside } from 'hooks';

import FontIcon from 'components/FontIcon';

import styles from '../DropdownSelectMany/DropdownSelectMany.scss';

// TODO: too many duplicated code with Dropdown.js

const DropdownSelectOne = ({
  activeIcon,
  className,
  defaultIcon,
  defaultLabel,
  disabled,
  highlight,
  isVisible,
  labelPrefix,
  optionsLarge,
  optionsMedium,
  selectedValues: origSelectedValues = [],
  source,
  onChange,
  name,
}) => {
  const [active, setActive] = useState(false);
  const [clientHeight, setClientHeight] = useState(39);
  const _mounted = useRef(false);
  const container = useRef();

  useOnClickOutside(
    container,
    useCallback(() => setActive(false), [])
  );

  useEffect(() => {
    _mounted.current = true;
    if (container.current) {
      setTimeout(() => {
        const { clientHeight = 39 } = container.current || {};
        if (_mounted.current) {
          setClientHeight(clientHeight + 1);
        }
      }, 1);
    }

    return () => {
      _mounted.current = false;
    };
  }, []);

  useEffect(() => {
    const { clientHeight = 39 } = container.current || {};
    const newClientHeight = clientHeight + 1;
    updateClientHeight(newClientHeight);
  }, [origSelectedValues.length]);

  const onClick = () => {
    setActive((active) => !active);
  };

  const onSelect = (item) => {
    if (active) {
      onChange({
        ...item,
        filter: name,
      });
    }
  };

  const onDeselect = (item) => {
    onChange({
      ...item,
      filter: name,
    });
  };

  const updateClientHeight = (clientHeight) => setClientHeight(clientHeight);

  const makePills = (item) => {
    return (
      <div key={item.label} className={styles.pill} onClick={(event) => event.stopPropagation()}>
        {item.label}
        <FontIcon
          iconName={'close'}
          className={styles.closeIcon}
          onClick={
            disabled
              ? () => null
              : (event) => {
                  event.stopPropagation();
                  onDeselect(item);
                }
          }
        />
      </div>
    );
  };

  const selectedValues = origSelectedValues || [];

  const optionsContainerClasses = classnames(styles.OptionsContainer, {
    [styles.largeContainer]: optionsLarge,
    [styles.mediumContainer]: optionsMedium,
  });

  const selectedItems = [];

  const makeOptions = (item) => {
    const { label, value, optionType } = item;

    const hasSelection = selectedValues.length > 0;

    const isSelected = selectedValues
      .map((val) => `${val[val.type]}`)
      .includes(`${value[value.type]}`);

    const subValues =
      optionType === 'folder'
        ? item.subCategories.map((subCat) => `${subCat.value[subCat.value.type]}`)
        : [];

    const selectedSubValues = subValues
      ? subValues.filter((subVal) =>
          selectedValues.map((val) => `${val[val.type]}`).includes(subVal)
        )
      : [];

    const isFolderSelected =
      optionType === 'folder' && selectedSubValues.length === subValues.length;

    const isFolderPartialSelected =
      optionType === 'folder' &&
      selectedSubValues.length > 0 &&
      selectedSubValues.length < subValues.length;

    const folders = source && source.filter((loc) => loc.optionType === 'folder');

    const selectedFolders =
      folders &&
      folders.filter((folder) => {
        const subValues = folder.subCategories.map(
          (subCat) => `${subCat.value[subCat.value.type]}`
        );

        const selectedSubValues = subValues
          ? subValues.filter((subVal) =>
              selectedValues.map((val) => `${val[val.type]}`).includes(subVal)
            )
          : [];

        return selectedSubValues.length > 0;
      });

    const selectedFolderSubvalues =
      selectedFolders && selectedFolders[0]
        ? selectedFolders[0].subCategories.map((subCat) => `${subCat.value[subCat.value.type]}`)
        : [];

    const isParentSelected =
      selectedFolderSubvalues.includes(`${value[value.type]}`) && optionType !== 'folder';

    const isNotSelected =
      !(isSelected && optionType !== 'folder') && !isFolderSelected && hasSelection;

    if (isSelected && optionType !== 'folder') selectedItems.push(item);

    const optionClasses = classnames(styles.Option, {
      [styles.activeOption]: (isSelected && optionType !== 'folder') || isFolderSelected,
      [styles.inactiveOption]:
        isNotSelected && !isFolderSelected && !isFolderPartialSelected && !isParentSelected,
      [styles.partialActiveOption]: isFolderPartialSelected,
    });

    const onClickHandler = (event) => {
      event.stopPropagation();

      onSelect(item);
    };

    const optionProps = {
      className: optionClasses,
      key: value.type ? value[value.type] : value,
      onClick:
        isNotSelected && !isFolderSelected && !isFolderPartialSelected && !isParentSelected
          ? () => null
          : onClickHandler,
      value,
    };

    const indentString =
      item.indent > 0 ? new Array(item.indent).fill(<span className={styles.indent} />) : null;

    const labelClasses = classnames(styles.label, {
      [styles.activeLabel]: isSelected || isFolderSelected,
      [styles.inactiveLabel]: !isSelected && !isFolderSelected && hasSelection && !isParentSelected,
      [styles.partialActiveLabel]: isFolderPartialSelected,
    });

    const labelContent = <span className={labelClasses}>{label}</span>;

    return (
      <div {...optionProps}>
        {indentString}
        {labelContent}
      </div>
    );
  };

  const selectableOptions = source && source.map(makeOptions);

  const content = active ? selectableOptions : null;

  const prefixForLabel = labelPrefix ? (
    <span className={styles.labelPrefix}>{labelPrefix}</span>
  ) : null;

  const selectedPills = selectedItems.length > 0 ? selectedItems.map(makePills) : null;

  const selectedLabelContent = selectedPills ? (
    <div className={styles.pillContainer}>{selectedPills}</div>
  ) : (
    <div className={styles.inPlaceDefaultLabel}>
      {prefixForLabel}
      {defaultLabel}
    </div>
  );

  const defaultIconContent = defaultIcon ? (
    <FontIcon className={styles.mainIcon} iconName={defaultIcon} />
  ) : null;

  const activeIconContent = activeIcon ? (
    <FontIcon className={styles.mainIcon} iconName={activeIcon} />
  ) : (
    defaultIconContent
  );

  const iconContent = active ? activeIconContent : defaultIconContent;

  const classes = classnames(
    styles.Dropdown,
    {
      [styles.active]: active,
      [styles.focus]: highlight,
      [styles.disabled]: disabled,
    },
    className
  );

  const styleKey = isVisible ? 'top' : 'bottom';

  const optionsContainerStyle = { [styleKey]: `${clientHeight}px` };

  return (
    <div className={classes} onClick={disabled ? () => null : onClick} ref={container}>
      {selectedLabelContent}
      {iconContent}
      <div style={optionsContainerStyle} className={optionsContainerClasses}>
        {content}
      </div>
    </div>
  );
};

export default VisibleHOC()(DropdownSelectOne);
