import { createSelector } from 'reselect';
import { entitySelectorFactory } from 'reduxStore/selectorFactories';

const selectEmployer = createSelector(
  entitySelectorFactory('toolboxEmployers', 'employer'),
  (state, props) => {
    const { match: { params: { id } = {} } = {} } = props || {};

    return id;
  },
  (employers, id) => {
    const { byId: { [id]: employer = {} } = {} } = employers;

    return employer;
  }
);

export default selectEmployer;
