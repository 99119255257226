/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import jobsPageMetaData, {
  JOB_PAGE_AE,
  JOB_PAGE_CSM,
  JOB_PAGE_EAE,
  JOB_PAGE_MMAE,
  JOB_PAGE_SD,
  JOB_PAGE_SDR,
  JOB_PAGE_SM,
  JOB_PAGE_VP,
} from 'pages/JobsPage/jobsPageMetaData';

import { getAltName } from 'utils/urlUtils';

import pic1 from './assets/1-Atlanta-Best-Tech-Sales-Jobs.png';
import pic2 from './assets/2-Atlanta-Best-Tech-Sales-Careers.png';
import pic3 from './assets/3-Atlanta-Best-Tech-Sales-Opportunities.png';
import cta from './assets/4-Contact-Sales-Recruiters.png';

import styles from './AtlantaContent.scss';

export function AtlantaContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt={getAltName(pic1)} />

      <h2>Tech Sales Opportunities In Atlanta</h2>

      <p>
        Over the past decade, Atlanta has developed into an attractive economic opportunity for
        many.
      </p>

      <p>
        Atlanta and its surrounding areas have witnessed robust job growth in various business and
        IT service sectors. Still, they have also managed to maintain a relatively low cost of
        living, particularly regarding housing.
      </p>

      <p>
        In addition, state and local governments are simultaneously working hard to entice more
        businesses to the area and to build the infrastructure required to support the region’s
        growing number of start-up businesses.
      </p>

      <p>
        This makes Atlanta particularly encouraging for businesses trying to grow their sales teams
        and bring in new high-paying clients.
      </p>

      <h2>Atlanta As a Bustling, Growing Tech Center</h2>

      <p>
        Tech companies have begun to focus on Atlanta because of its rapid growth. Atlanta saw over
        20 acquisitions in 2021 as larger companies found value in local firms. Tech fields that
        have enjoyed explosive growth in the area include cybersecurity, cloud computing, software,
        fintech, and digital media.
      </p>

      <p>
        Atlanta has trained its workforce for a digital future in response to sophisticated
        cyberattacks. As a result, many companies are tackling security issues while the next
        generation prepares for what’s ahead. In addition, 165 digital media firms have set up
        businesses in Georgia, contributing significantly to the entertainment industry.
        World-recognized brands such as HBO, Discovery, The Weather Channel, and Cartoon Network all
        set up shop in the Atlanta area.
      </p>

      <p>
        Atlanta software businesses such as iZotope are improving audio technology, and Intelsat is
        busy streamlining communication possibilities for business and innovation. In addition,
        well-known tech giants like Google and Twilio in Atlanta continue to provide even more
        opportunities.
      </p>

      <p>
        Atlanta also conducts 70% of US debit, credit, and prepaid card transactions. Businesses
        continue to uphold the city’s image as a fintech center with growing financial services and
        products. Companies like Veeam and Siemens have adopted cloud platforms and other methods to
        improve efficiency and security.
      </p>

      <p>
        Tech professionals across all fields are finding more projects that benefit clients in every
        industry imaginable.
      </p>

      <img src={pic2} alt={getAltName(pic2)} />

      <h2>Benefits of Living In Atlanta</h2>

      <p>There are many reasons people call Atlanta home.</p>

      <p>
        Atlanta offers excellent weather year-round, despite the South’s reputation for heat.
        Atlanta’s climate makes it one of the world’s most beautiful and relaxing cities. Atlanta
        has mild winters and extended summers, so there is plenty to do outdoors.
      </p>

      <p>
        Atlanta has a very affordable cost of living compared to other major cities. Unlike other
        major cities, homes can be found on reasonably sizable lots, and construction costs aren’t
        too high. In addition, for young professionals and seasoned pros, there are neighborhoods,
        recreation areas, and activities to meet any need and lifestyle.
      </p>

      <p>
        As a world-famous hub city with a rich history, Atlanta is a center for diverse culture with
        access to fine art, dining, music, film, and much more. In addition, it boasts one of the
        largest pop culture conventions in the country, DragonCon, and is home to major sports teams
        such as the Atlanta Braves and the Atlanta Falcons.
      </p>

      <p>
        Atlanta is also great for shopping. From haute couture to bargains. Atlanta shoppers can
        find it all. It’s also where many retailers, large and small, go to discover the latest
        fashion and market trends.
      </p>

      <p>
        For those considering relocating to Atlanta, they will quickly understand why the city is so
        adored. Atlanta has everything, from special events and venues to world-famous cuisine and
        style.
      </p>

      <h2>Sales Positions Opening In Atlanta</h2>

      <p>
        Throughout Atlanta, job opportunities in tech sales have been sprouting up. By combining
        talented people with top companies, Rainmakers offers extraordinary employment prospects.
      </p>

      <p>
        Here are some of the IT sales jobs with the highest potential currently available in the
        Atlanta area.
      </p>

      <h2>Sales Development Representative</h2>

      <p>
        A tech sales team’s effectiveness depends on the{' '}
        <a href={jobsPageMetaData[JOB_PAGE_SDR].localUrl} target="_blank" rel="noreferrer">
          Sales Development Representatives’ (SDRs’)
        </a>{' '}
        performance. To create sales pipelines for the rest of the team, an SDR specializes in
        inside sales activities, concentrating on research and cold calling. An entry-level SDR role
        is typically the ideal setting to begin a long-term sales career. People with a strong will
        to succeed are best suited for the position, which calls for little to no prior experience.
      </p>

      <img src={pic3} alt={getAltName(pic3)} />

      <h2>Account Executive</h2>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_AE].localUrl} target="_blank" rel="noreferrer">
          Account Executives (AE)
        </a>{' '}
        are responsible for acquiring new clients, managing client accounts, nurturing potential
        clients, and attending to the needs of current clients. Since a sizable portion of an AE’s
        pay is made up of commissions, the position demands that the AE put forth a concentrated
        effort to meet predetermined quotas and earning targets.
      </p>

      <h2>Mid-Market Account Executive</h2>

      <p>
        A significant next step for sales professionals who are enthusiastic about their work and
        want to advance is becoming a{' '}
        <a href={jobsPageMetaData[JOB_PAGE_MMAE].localUrl} target="_blank" rel="noreferrer">
          Mid-Market Account Executive
        </a>
        . These sales specialists supervise training, foster development, close deals, use
        SaaS/cloud technology, and manage client relationships. Mid-Market Account Managers work
        primarily with middle-market companies as their main source of new sales and commissions.
      </p>

      <h2>Enterprise Account Executive</h2>

      <p>
        With the aid of an{' '}
        <a href={jobsPageMetaData[JOB_PAGE_EAE].localUrl} target="_blank" rel="noreferrer">
          Enterprise Account Executive
        </a>
        , manufacturers, software developers, telecommunications companies, retail chains, and other
        ambitious businesses can target large corporations that have the potential to generate
        enormous profits. Enterprise Account Managers support profit-driven sales strategies,
        encourage innovation, and cultivate profitable business relationships. The ideal candidate
        for this position should be capable, organized, and devoted. This career frequently has a
        high earning potential due to the level of responsibility required.
      </p>

      <h2>Customer Success Manager</h2>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_CSM].localUrl} target="_blank" rel="noreferrer">
          Customer Success Managers (CSMs)
        </a>{' '}
        put a lot of effort into creating enduring relationships with a company’s clients. Selling
        goods and services to businesses is one of their primary duties to promote client success
        and repeat business. Although CSMs are a relatively new position in the sales world, they
        are now being used successfully by over half of high-tech companies.
      </p>

      <Link to="/apply-now" title="Apply Now">
        <img src={cta} alt={getAltName(cta)} />
      </Link>

      <h2>Sales Director</h2>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_SD].localUrl} target="_blank" rel="noreferrer">
          Sales Directors
        </a>{' '}
        sustain a business’s dedication to providing excellent customer service. Their main
        responsibilities include developing services, introducing new products to customers, and
        making sure the sales team completes all necessary tasks. Additionally, Sales Directors
        oversee the sales team, conduct training sessions, and make sure everyone is pursuing their
        objectives. A candidate for this role must have outstanding leadership, sales, and
        organizational abilities.
      </p>

      <h2>Sales Manager</h2>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_SM].localUrl} target="_blank" rel="noreferrer">
          Sales Managers
        </a>{' '}
        are in charge of the sales staff and their actions and are responsible for creating growth
        and sales strategies, establishing and maintaining sales goals, and monitoring profits. In
        addition, a Sales Manager may also be in charge of hiring salespeople, creating sales
        strategies, providing coaching, and managing operations. The size of the business and the
        sales department frequently dictate the range of a Sales Manager’s responsibilities.
      </p>

      <h2>Vice President of Sales</h2>

      <p>
        A company’s{' '}
        <a href={jobsPageMetaData[JOB_PAGE_VP].localUrl} target="_blank" rel="noreferrer">
          Vice President (VP)
        </a>{' '}
        of Sales handles many important tasks, not the least of which are improving the performance
        of the sales division and assisting them in exceeding quotas. VPs of Sales often function as
        managers, coaches, and business strategists in addition to their regular duties. Vice
        Presidents of Sales are responsible for hiring salespeople, managing sales teams, and
        establishing business goals and plans. Because this role comes with considerable
        responsibilities, the job typically pays reasonably well.
      </p>

      <h2>Find Out More About Tech Sales Careers at Rainmakers</h2>

      <p>
        IT sales careers provide numerous opportunities for both professional and personal growth.
        Many lucrative career paths are available for those willing to put in the time and effort
        required to meet and exceed expectations and demonstrate their value to the team.
      </p>

      <p>
        The success of expanding enterprises depends on every team member, from the Vice President
        of Sales to the Sales Development Representatives. All parties gain from sales team members
        who possess the necessary abilities and are dedicated to achieving their goals.
      </p>

      <p>
        <a href="/apply-now">Contact Rainmakers today</a> to find out more about these exciting
        opportunities.
      </p>
    </div>
  );
}
