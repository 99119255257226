import { handleActions } from 'redux-actions';

import {
  FEATURED_CANDIDATES_LOAD_DONE,
  FEATURED_CANDIDATE_UPDATE,
  FEATURED_CANDIDATES_RESET,
  SET_EMPLOYER_ID,
  RESET_EMPLOYER_ID,
} from '../actions';

const initialState = {
  entities: {
    candidate: {
      byId: {},
      allIds: [],
    },
  },
  meta: {
    candidate: {
      pagination: {},
    },
  },
  requestConfirmation: {
    show: false,
    firstName: '',
    lastName: '',
  },
  employerId: null,
};

const handleSetEmployerId = (state = initialState, action) => {
  const {
    payload: { employerId },
  } = action;

  return {
    ...state,
    employerId,
  };
};

const handleResetEmployerId = (state = initialState) => ({
  ...state,
  employerId: null,
});

const handleFeaturedCandidatesReset = () => ({
  ...initialState,
  featuredCandidates: {
    ...initialState.featuredCandidates,
  },
});

const handleFeaturedCandidateUpdate = (state = initialState, action) => {
  const {
    payload: { candidate, candidate: { id: candidateId } = {} },
  } = action;

  if (candidateId) {
    return {
      ...state,
      entities: {
        ...state.entities,
        candidate: {
          ...state.entities.candidate,
          byId: {
            ...state.entities.candidate.byId,
            [candidateId]: {
              ...state.entities.candidate.byId[candidateId],
              attributes: candidate,
            },
          },
        },
      },
    };
  }
};

const handleFeaturedCandidatesLoadDone = (state = initialState, action) => {
  const {
    payload: { data: { entities = {}, meta = {} } = {} },
  } = action;

  const { pagination: { currentPage = 1 } = {} } = meta;

  if (!entities) {
    return {
      ...initialState,
      meta: { ...state.meta, ...meta },
    };
  }

  if (currentPage === 1) {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...entities,
      },
      meta: {
        ...state.meta,
        candidate: {
          ...meta,
        },
      },
    };
  }

  const entityKeys = Object.keys(entities);

  const newEntities = entityKeys.reduce((acc, curr) => {
    const currState = state.entities[curr];

    acc[curr] = {
      byId: {
        ...currState.byId,
        ...entities[curr].byId,
      },
      allIds: currState.allIds.concat(entities[curr].allIds),
    };

    return acc;
  }, {});

  return {
    ...state,
    entities: newEntities,
    meta: {
      ...state.meta,
      candidate: {
        ...meta,
      },
    },
  };
};

const actionHandlers = {
  [FEATURED_CANDIDATES_LOAD_DONE]: handleFeaturedCandidatesLoadDone,
  [FEATURED_CANDIDATE_UPDATE]: handleFeaturedCandidateUpdate,
  [FEATURED_CANDIDATES_RESET]: handleFeaturedCandidatesReset,
  [RESET_EMPLOYER_ID]: handleResetEmployerId,
  [SET_EMPLOYER_ID]: handleSetEmployerId,
};

const reduceFeatured = handleActions(actionHandlers, initialState);

export default reduceFeatured;
