import candidates from './candidates';
import companies from './companies';
import educationOrganizations from './educationOrganizations';
import employers from './employers';
import employees from './employees';
import rainmakersAdmins from './rainmakersAdmins';
import requisitionOpenings from './requisitionOpenings';
import savedSearches from './savedSearches';
import users from './users';

export default {
  candidates,
  companies,
  educationOrganizations,
  employers,
  employees,
  rainmakersAdmins,
  requisitionOpenings,
  savedSearches,
  users,
};
