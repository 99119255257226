import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import ReadyContainer from 'connectors/ReadyContainer';

import { CandidateAuthHOC, ModalHOC } from 'containers/HOC';
import ConnectContainer from 'containers/ConnectContainer';

import {
  promiseGetRequisitionCandidate,
  promiseGetPrivateRequisitionCandidateFeedback,
} from 'pages/AdminPortal/ToolboxApproachEditorPage/promises';

import { handleError } from 'utils/common';
import { toggleArray } from 'utils/formUtils';

import TableOverlay from 'components/TableOverlay';

import { usePrevious } from 'hooks';

import duck from '../CandidateOpportunitiesPage/redux';
import PassForm from './components/PassForm';

const CandidatePass = (props) => {
  const params = useParams();
  const { id } = params;

  const prevId = usePrevious(id);

  const [state, setState] = useState(() => {
    const { isAdmin, opportunities } = props;

    let output;

    const reqCand = opportunities.find((opp) => opp.id === id);
    if (reqCand) {
      const {
        companyFeedback = [],
        offerFeedback = [],
        privateReqCanFeedback: { feedback = '' } = {},
        roleFeedback = [],
        reason = '',
      } = reqCand;

      output = {
        loading: false,
        feedback: isAdmin ? feedback || '' : '',
        companyFeedback: isAdmin ? companyFeedback || [] : [],
        offerFeedback: isAdmin ? offerFeedback || [] : [],
        reason: isAdmin ? reason || '' : '',
        roleFeedback: isAdmin ? roleFeedback || [] : [],
      };
    } else {
      output = { loading: true };
    }
    console.log(output);
    return output;
  });

  useEffect(() => {
    const {
      actions: { requisitionCandidateLoadStarted },
      isAdmin,
      match: {
        params: { id: requisitionCandidateId },
      },
    } = props;

    const params = {
      requisitionCandidateId,
      includeSet: 'employer',
    };

    if (isAdmin) {
      promiseGetPrivateRequisitionCandidateFeedback(params)
        .then(({ privateRequisitionCandidateFeedback: { feedback = '' } = {} }) => {
          setState((prevState) => ({ ...prevState, feedback }));
        })
        .catch(handleError);

      promiseGetRequisitionCandidate(params)
        .then(
          ({
            requisitionCandidate: {
              companyFeedback = [],
              offerFeedback = [],
              roleFeedback = [],
              reason = '',
            },
          }) => {
            setState((prevState) => ({
              ...prevState,
              loading: false,
              companyFeedback,
              offerFeedback,
              roleFeedback,
              reason,
            }));
          }
        )
        .catch((error) => {
          handleError(error);
          setState((prevState) => ({ ...prevState, loading: false }));
        });
    } else {
      setState((prevState) => ({ ...prevState, loading: false }));
    }

    requisitionCandidateLoadStarted({ requisitionCandidateId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const {
      match: {
        params: { id: nextId },
      },
      actions: { requisitionCandidateLoadStarted },
      isAdmin,
    } = props;

    const currId = prevId;

    if (nextId !== currId) {
      const params = {
        requisitionCandidateId: nextId,
        includeSet: 'employer',
      };

      if (isAdmin) {
        promiseGetPrivateRequisitionCandidateFeedback(params)
          .then(({ privateRequisitionCandidateFeedback: { feedback = '' } = {} }) => {
            setState((prevState) => ({ ...prevState, feedback }));
          })
          .catch(handleError);

        promiseGetRequisitionCandidate(params)
          .then(
            ({
              requisitionCandidate: {
                companyFeedback = [],
                offerFeedback = [],
                roleFeedback = [],
                reason = '',
              } = {},
            }) => {
              setState((prevState) => ({
                ...prevState,
                loading: false,
                companyFeedback,
                offerFeedback,
                roleFeedback,
                reason,
              }));
            }
          )
          .catch((error) => {
            handleError(error);
            setState((prevState) => ({ ...prevState, loading: false }));
          });
      } else {
        setState((prevState) => ({ ...prevState, loading: false }));
      }

      requisitionCandidateLoadStarted({ requisitionCandidateId: nextId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match?.params?.id]);

  const handleFeedbackChange = (event) => {
    const {
      target: { name, value },
    } = event;

    const { [name]: array } = state;

    setState((prevState) => ({ ...prevState, [name]: toggleArray({ array, value }) }));
  };

  const handleReasonChange = (event) => {
    const {
      target: { name, value },
    } = event;

    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmitFeedback = () => {
    const {
      actions: {
        patchRequisitionCandidateStarted,
        patchPrivateRequisitionCandidateFeedbackStarted,
      },
      match: {
        params: { id: reqCandidateId },
      },
      location: { query: { pending } = {} } = {},
      isAdmin,
    } = props;

    const { feedback, companyFeedback, offerFeedback, reason, roleFeedback } = state;

    patchRequisitionCandidateStarted({
      reqCandidateId,
      attributes: {
        companyFeedback,
        offerFeedback,
        reason,
        roleFeedback,
        state: pending === 'false' ? 'candidate_passed' : 'candidate_pass_pending',
      },
    });

    if (feedback) {
      patchPrivateRequisitionCandidateFeedbackStarted({
        isAdmin,
        reqCandidateId,
        attributes: {
          feedback,
        },
      });
    }
  };

  const { loading } = state;
  const formProps = {
    ...props,
    ...state,
    handleFeedbackChange,
    handleReasonChange,
    handleSubmitFeedback,
  };

  return [
    <Helmet key="helmet" title="Approach Editor" />,
    <ReadyContainer key="readyContainer">
      {loading ? <TableOverlay /> : null}
      <PassForm {...formProps} />
    </ReadyContainer>,
  ];
};

export default ConnectContainer(duck)(
  CandidateAuthHOC()(
    ModalHOC({
      paginatorUrl: '/candidate/opportunity/:id',
      centered: true,
    })(CandidatePass)
  )
);
