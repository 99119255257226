import { getEntity } from 'api/apiEntity';

const promiseGetPrivateRequisitionCandidateFeedback = ({ requisitionCandidateId }) => {
  const onFulfilled = (response) => {
    const {
      data: { entities: { privateReqCanFeedback: { byId = {}, allIds = [] } = {} } = {} } = {},
    } = response;

    const id = allIds.find((id) => byId[id].attributes.ownerType === 'Candidate');

    return { privateRequisitionCandidateFeedback: byId[id].attributes };
  };

  const getArgs = {
    config: {
      params: {
        normalizeIt: true,
        requisitionCandidateId,
      },
    },
    type: 'private_req_can_feedbacks',
  };

  return getEntity(getArgs).then(onFulfilled);
};

export default promiseGetPrivateRequisitionCandidateFeedback;
