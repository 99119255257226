import { createStructuredSelector } from 'reselect';

import { entitySelectorFactory } from 'reduxStore/selectorFactories';

import {
  selectIsAdmin,
  selectIsApproved,
  selectIsCandidate,
  selectIsEmployee,
  selectLogoutPending,
} from 'connectors/Login/redux/selectors';

import { selectPickerOptions } from 'connectors/Defaults/redux/selectors';

import {
  selectRequestConfirmation,
  selectSearchEmployerId,
  selectSearchParams,
  selectRequestedCandidates,
} from 'pages/EmployeePortal/CandidateSearchPage/redux/selectors';

import { selectUser } from 'connectors/User/redux/selectors';
import { getHideNames, getModal } from 'shared/store/app/selectors';

import selectCandidateProfileReqs from './selectCandidateProfileReqs';
import selectNeedReload from './selectNeedReload';

const selector = createStructuredSelector({
  bookmarks: entitySelectorFactory('featured', 'bookmark'),
  candidateProfileReqs: selectCandidateProfileReqs,
  candidates: entitySelectorFactory('employerCandidates', 'candidate'),
  requisitionCandidates: entitySelectorFactory('employerCandidates', 'requisitionCandidate'),
  featuredCandidates: entitySelectorFactory('featured', 'candidate'),
  bookmarkCandidates: entitySelectorFactory('employerBookmarks', 'candidate'),
  hideNames: getHideNames,
  isAdmin: selectIsAdmin,
  isApproved: selectIsApproved,
  isCandidate: selectIsCandidate,
  isEmployee: selectIsEmployee,
  loggedInSearchEmployerId: selectSearchEmployerId,
  logoutPending: selectLogoutPending,
  modal: getModal,
  needReload: selectNeedReload,
  pickerOptions: selectPickerOptions,
  requestedCandidates: selectRequestedCandidates,
  requestConfirmation: selectRequestConfirmation,
  results: entitySelectorFactory('employerSearch', 'candidate'),
  searchParams: selectSearchParams,
  user: selectUser,
});

export { selectCandidateProfileReqs, selectNeedReload, selector as default };
