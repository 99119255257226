import {
  promiseGetPrivateProfile,
  promiseGetCandidateUser,
  promiseGetWorkHistories,
  promiseGetCandidateEducations,
  promiseGetCandidateRequirement,
  promiseGetCandidateVideo,
} from '.';

const promiseGetCandidateRelationships = ({
  candidateId,
  isEmployee,
  noPrivate,
  noUser,
  normalizeIt = false,
  fullEntity = false,
}) => {
  const onFulfilled = (responses) => ({
    ...responses[0],
    ...responses[1],
    ...responses[2],
    ...responses[3],
    ...responses[4],
    ...responses[5],
    ...responses[6],
  });

  const mockPromise = new Promise((resolve) => setTimeout(resolve, 0));

  const args = {
    candidateId,
    normalizeIt,
    fullEntity,
    isEmployee,
  };

  const privatePromise = noPrivate ? mockPromise : promiseGetPrivateProfile(args);

  const userPromise = noUser ? mockPromise : promiseGetCandidateUser(args);

  const promises = [
    promiseGetWorkHistories(args),
    promiseGetCandidateEducations(args),
    userPromise,
    promiseGetCandidateRequirement(args),
    privatePromise,
    promiseGetCandidateVideo(args),
  ];

  return Promise.all(promises).then(onFulfilled);
};

export default promiseGetCandidateRelationships;
