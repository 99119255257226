import { getCandidateRelationship } from 'api/apiCandidates';

const promiseGetCandidateUser = ({ candidateId, normalizeIt, fullEntity }) => {
  const onFulfilled = ({
    data: {
      entities: {
        user = {
          allIds: [],
          byId: {},
        },
        user: { allIds = [], byId = {} } = {},
      } = {},
    } = {},
  }) => (fullEntity ? { user } : { user: byId[allIds[0]].attributes || {} });

  const getArgs = {
    candidateId,
    config: {
      params: {
        normalizeIt,
      },
    },
    type: 'user',
  };

  return getCandidateRelationship(getArgs).then(onFulfilled);
};

export default promiseGetCandidateUser;
