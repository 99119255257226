/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import locationsPageMetaData, {
  LOCATIONS_PAGE_ATLANTA,
  LOCATIONS_PAGE_AUSTIN,
  LOCATIONS_PAGE_CHICAGO,
  LOCATIONS_PAGE_DENVER,
  LOCATIONS_PAGE_LA,
  LOCATIONS_PAGE_NYC,
  LOCATIONS_PAGE_PHOENIX,
  LOCATIONS_PAGE_RALEIGH,
  LOCATIONS_PAGE_SANFRANCISCO,
} from 'pages/LocationsPage/locationsPageMetaData';

import pic1 from './assets/1.jpg';
import pic2 from './assets/2.jpg';
import pic3 from './assets/CTA1.png';
import pic4 from './assets/4.jpg';
import pic5 from './assets/5.jpg';
import pic6 from './assets/CTA2.png';

import styles from './MainContent.scss';

export function MainContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt="" />
      <h2>Top Sales Skills</h2>
      <p>
        Strong sales skills are essential whether you want to market a service, an idea, a product,
        or even yourself as the most qualified applicant for a tech sales position. The good news is
        that selecting the most appropriate sales-related skills for your resume is relatively
        simple.
      </p>
      <p>
        Your most significant advantage comes when hiring managers specify precisely what sales
        abilities you should have if you're looking for a sales position within that company.
      </p>
      <p>
        That said, you'll often have to make educated guesses as to what sales skills a recruiter is
        seeking. Fortunately, many valued skills are common to all types of sales positions. Here
        are some of the top talents in demand and where tech sales jobs are waiting for you.
      </p>

      <h2>Most Valued Skills For Your Tech Sales Resume</h2>
      <p>
        To close deals, salespeople must use a wide range of talents. These skills are often learned
        through experience but can also be obtained through education courses and certification
        programs.
      </p>
      <p>
        To work in sales, you should build a well-rounded skill set that incorporates technical
        understanding and interpersonal practices. Here are skills most recruiters are on the
        lookout for.
      </p>

      <h3>Prospecting</h3>
      <p>
        Prospecting is one of the most time-consuming and challenging tasks that salespeople face.
        But, if done right, it allows you to find potential customers who are the perfect fit for
        your product.
      </p>
      <p>
        Successful prospecting includes identifying potential markets, contacting the right
        individuals, building outreach lists, establishing contact, and opening a qualified sales
        conversation with new clients.
      </p>
      <p>Prospecting requires resilience and a willingness to follow up on stalled contacts.</p>

      <img src={pic2} alt="" />
      <div id="coldcalling">
        <h3>Cold Calling</h3>
      </div>
      <p>
        Cold calling—one of the first steps toward converting new customers—is crucial for many tech
        sales specialists, especially at the beginning of their sales career.
      </p>
      <p>
        Add a bullet or two in your resume about your experience with cold calling or cold outreach
        and, if possible, mention your success rate. Recruiters frequently prefer candidates with
        experience making cold calls and generating solid sales leads.
      </p>

      <div id="upselling">
        <h3>Upselling</h3>
      </div>
      <p>
        Upselling is a sales approach that informs and guides customers to buy an advanced or better
        version of a product or service the customer is presently using or intends to buy. The goal
        of upselling is to increase the customer's lifetime value of a product or service the
        customer already owns.
      </p>
      <p>
        A proven ability to upsell products and services can make you a particularly valuable member
        of any tech sales staff.
      </p>
      <div id="managing">
        <h3>Managing</h3>
      </div>
      <p>
        When looking for a tech sales position, it would be helpful to outline what leadership
        qualities you've developed—particularly if you want to work as part of a group. Applicants
        who demonstrate promise as leaders are frequently sought after by employers for sales
        management positions.
      </p>

      <Link to="/apply-now" title="Apply Now">
        <img src={pic3} alt="" />
      </Link>
      <div id="communication">
        <h3>Communication</h3>
      </div>
      <p>
        As someone familiar with tech sales, you should already know how much engaging in verbal and
        written conversation is necessary for success.
      </p>
      <p>
        While communication can sometimes feel draining, it's still an essential skill to nurture.
        Employers want sales staff who can effectively handle all forms of interpersonal contact to
        help engage customers and close sales. So on your resume, emphasize your work and
        communicate effectively with customers by describing the breadth of clients you've managed.
      </p>

      <div id="listening">
        <h3>Active Listening</h3>
      </div>
      <p>
        Mastering sales requires more than just talking. Active listening is just as necessary. As
        it relates to sales skills, active listening is the practice of paying close attention,
        reflecting on what is being said, and responding appropriately.
      </p>
      <p>
        This skill helps you prepare for every client's justification for not becoming a customer
        and enables you to react in a way that would move you closer to success.
      </p>
      <p>
        In the experience or summary portion of your resume, discuss how your active listening
        skills have assisted you in generating productive intro and discovery conversations with
        prospects.
      </p>

      <div id="emotional-intelligence">
        <h3>Emotional Intelligence</h3>
      </div>
      <p>
        High emotional intelligence makes it easier to connect with a prospect and show compassion
        for their needs. Tech sales specialists must be aware of their personality strengths and
        weaknesses and use this knowledge to their advantage.
      </p>

      <img src={pic4} alt="" />
      <div id="negotiation">
        <h3>Negotiation</h3>
      </div>
      <p>
        An effective salesperson always wants what is best for the business. The simplest way to
        accomplish that is to master negotiation and learn to come to agreements that benefit all
        sides. Skills such as conflict management and the capacity to remain composed under pressure
        are essential in a negotiation.
      </p>
      <p>
        You should list moments in your resume where your practiced negotiation skills have
        benefited you and your previous company.
      </p>

      <div id="domain-expertise">
        <h3>Domain Expertise</h3>
      </div>
      <p>
        Being an effective salesperson requires in-depth knowledge of your company's products and
        services and the overall industry itself. Therefore, when you list this ability on your
        resume, you're in an excellent position to impress the recruiter by demonstrating you are
        familiar with the advantages of working with a company's product, services, or even buyers.
      </p>

      <h2>Prioritize Skills Relevant To the Position</h2>
      <p>
        Although it is tempting to mention every sales talent you possess, doing so may give the
        impression that you need more experience and confidence.
      </p>
      <p>
        Perhaps surprisingly, many hiring managers favor candidates with a modest number of relevant
        skills over those with an endless number of unrelated skills. So if you're considering
        including programming experience on your resume, it might be better to emphasize skills like
        effective communication or the ability to close deals with challenging clients instead.
      </p>
      <p>
        Look for listed prerequisite skills in the job description and then incorporate those you
        can into your resume. In addition to making you look better, it will improve your chances of
        passing applicant tracking systems and getting an interview.
      </p>

      <img src={pic5} alt="" />
      <h2>Be Detailed</h2>
      <p>
        Include as much information as possible about your sales experience on your resume. Rather
        than simply stating "cold calling" in your talents section, elaborate on how your
        cold-calling abilities have contributed to a steady rise in new clients over several months.
        Recruiting managers will find this helpful information when coming to hiring decisions.
      </p>

      <h2>Show Your Numbers</h2>
      <p>
        The best sales resumes are rich with metrics and numbers. You need to provide concrete
        evidence to demonstrate your bonafide sales skills. Failing to do so puts you at risk of
        coming across as unreliable and not genuinely qualified.
      </p>
      <p>
        Where you can, provide some quantitative data, such as your highest number of leads
        generated, the number of new sales you've closed, your performance against quota, and the
        percentage increase in customer renewal and retention attributed towards your efforts.
      </p>
      <p>
        Doing this will highlight how having you on the sales team will produce measurable outcomes
        and genuine advantages for the entire business.
      </p>

      <Link to="/apply-now" title="Apply Now">
        <img src={pic6} alt="" />
      </Link>
      <h2>Career Opportunities In Tech Sales</h2>
      <p>
        Depending on the specific business and role occupied, tech sales compensation can be
        profitably competitive. Most reward outstanding performers with commission-based incentives
        and other bonuses, which can significantly boost one's overall pay.
      </p>
      <p>
        Two appealing aspects of tech sales positions are the potential for quick advancement into
        more extensive sales opportunities and the fact that jobs are available nationwide. Cities
        with exceptionally high demand include{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={locationsPageMetaData[LOCATIONS_PAGE_SANFRANCISCO].localUrl}
        >
          San Francisco
        </a>
        ,{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={locationsPageMetaData[LOCATIONS_PAGE_LA].localUrl}
        >
          Los Angeles
        </a>
        ,{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={locationsPageMetaData[LOCATIONS_PAGE_PHOENIX].localUrl}
        >
          Phoenix
        </a>
        ,{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={locationsPageMetaData[LOCATIONS_PAGE_AUSTIN].localUrl}
        >
          Phoenix
        </a>
        ,{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={locationsPageMetaData[LOCATIONS_PAGE_DENVER].localUrl}
        >
          Denver
        </a>
        ,{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={locationsPageMetaData[LOCATIONS_PAGE_CHICAGO].localUrl}
        >
          Chicago
        </a>
        ,{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={locationsPageMetaData[LOCATIONS_PAGE_NYC].localUrl}
        >
          NYC
        </a>
        ,{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={locationsPageMetaData[LOCATIONS_PAGE_RALEIGH].localUrl}
        >
          Raleigh
        </a>
        {' and '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={locationsPageMetaData[LOCATIONS_PAGE_ATLANTA].localUrl}
        >
          Atlanta
        </a>
        .
      </p>
      <p>
        Many companies offer work-from-home and hybrid office solutions, giving employees more
        freedom and control.
      </p>
      <p>
        Interested in finding out more about tech sales positions available near you?{' '}
        <Link to="/apply-now/">Sign up with Rainmakers</Link> today!
      </p>
    </div>
  );
}
