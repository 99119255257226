import { patchEntity } from 'api/apiEntity';

const promisePatchTemplate = ({ templateId, ...others }) => {
  const onFulfilled = (response) => response;

  const args = {
    type: 'message_templates',
    id: templateId,
    data: {
      type: 'message_templates',
      id: templateId,
      attributes: {
        ...others,
      },
    },
  };

  return patchEntity(args).then(onFulfilled);
};

export default promisePatchTemplate;
