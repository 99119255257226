import React from 'react';
import { useDispatch } from 'react-redux';

import { handleError } from 'utils/common';
import { patchEntity } from 'api/apiEntity';
import { VisibleHOC } from 'containers/HOC';

import DropdownPane from 'components/DropdownPane';
import InlineEducationEditor from 'components/InlineEducationEditor';

import { useCandidatePublicProfile } from 'hooks/useCandidatePublicProfile';

import patchCandidateRefreshTriggers from 'api/apiCandidates/patchCandidateRefreshTriggers';

import { resourceDelete, resourceUpdate, setImageUploadCompany } from 'shared/store/app/creators';

const ToolboxEducationOrgEditorDropdown = ({
  unverifiedData,
  educationOrg,
  isVisible,
  refreshCallback,
  sliceToUpdate = 'toolboxEducationOrgs',
  candidateId,
}) => {
  const attrs = educationOrg.attributes || educationOrg;
  const { id, attributes: { webSite, picture = {} } = {} } = unverifiedData || {};

  const dispatch = useDispatch();

  const publicProfileId = useCandidatePublicProfile(candidateId);

  const verifyOrReject = async (verify) => {
    if (verify) {
      dispatch(
        resourceUpdate({
          slice: sliceToUpdate,
          type: 'educationOrganization',
          id: educationOrg.id,
          resource: {
            attributes: {
              ...attrs,
              webSite: webSite || attrs.webSite,
              picture: picture || attrs.picture,
            },
          },
        })
      );
    }

    try {
      await patchEntity({
        id,
        type: 'unverified_education_organization_data',
        data: {
          attributes: {
            state: verify ? 'verified' : 'rejected',
          },
          id,
          type: 'unverified_education_organization_data',
        },
        config: {
          params: {
            camelizeIt: true,
          },
        },
      });

      if (publicProfileId) {
        patchCandidateRefreshTriggers(publicProfileId).catch(handleError);
      }
    } catch (error) {
      handleError(error);
    }

    if (refreshCallback) {
      refreshCallback();
    }

    dispatch(
      resourceDelete({
        id,
        slice: sliceToUpdate,
        type: 'unverifiedEducationOrganizationData',
      })
    );
  };

  return (
    <DropdownPane
      above={!isVisible}
      element={
        <InlineEducationEditor
          unverifiedData={unverifiedData}
          onVerify={() => verifyOrReject(true)}
          onReject={() => verifyOrReject(false)}
          publicProfileId={publicProfileId}
        />
      }
      label="Institution Editor"
      left={true}
      onOpenCallback={() => {
        dispatch(setImageUploadCompany(educationOrg));
      }}
    />
  );
};

export default VisibleHOC()(ToolboxEducationOrgEditorDropdown);
