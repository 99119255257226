import { handleActions } from 'redux-actions';

const initialState = {
  entities: {
    requisitionOpening: {
      byId: {},
      allIds: [],
    },
    requisitionRequirement: {
      byId: {},
      allIds: [],
    },
  },
  meta: {
    requisitionOpening: {
      pagination: {},
    },
  },
};

const reduceToolboxRequisitions = handleActions({}, initialState);

export default reduceToolboxRequisitions;
