import { createAction } from 'redux-actions';

import {
  SHOW_MODAL,
  HIDE_MODAL,
  SET_CURRENT_IDX,
  PICKER_OPTIONS_LOAD_STARTED,
  TAGS_LOAD_STARTED,
} from 'shared/store/app/actions';

export const pickerOptionsLoadStarted = createAction(PICKER_OPTIONS_LOAD_STARTED);
export const tagsLoadStarted = createAction(TAGS_LOAD_STARTED);

export const showModal = createAction(SHOW_MODAL);
export const hideModal = createAction(HIDE_MODAL);
export const setCurrentIdx = createAction(SET_CURRENT_IDX);
