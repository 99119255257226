import { handleActions } from 'redux-actions';

const initialState = {
  entities: {
    bookmark: {
      byId: {},
      allIds: [],
    },
  },
  meta: {
    bookmark: {
      pagination: {},
    },
  },
};

const reduceBookmarks = handleActions({}, initialState);

export default reduceBookmarks;
