export const camelizeLocations = (locations = []) =>
  locations.map((loc) =>
    typeof loc === 'string' && loc.length > 0
      ? `${loc[0].toUpperCase()}${loc.substring(1).toLowerCase()}`
      : loc
  );

export const extractLocation = (location) => {
  const postfixToRemove = ', USA';
  return location && location.endsWith(postfixToRemove)
    ? location.substring(0, location.length - postfixToRemove.length)
    : location;
};
