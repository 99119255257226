import React, { Fragment } from 'react';

import Input from 'components/Input';
import InputCheckbox from 'components/InputCheckbox';
import DropdownSelectMany from 'components/DropdownSelectMany';

import styles from './ToolboxRequisitionsSearchBar.scss';

const ToolboxApproachesSearchBar = ({
  complete,
  employerName,
  externalName,
  handleSearchInputChange,
  handleSearchFilterChange,
  handleSearchCheckboxChange,
  role,
  roleOptions,
  state,
}) => {
  const employerSearchProps = {
    name: 'employerName',
    placeholder: 'Search by employer name',
    size: 'full',
    handleInputChange: handleSearchInputChange,
    isSearch: true,
    value: employerName,
  };

  const externalNameSearchProps = {
    name: 'externalName',
    placeholder: 'Search by job title',
    size: 'full',
    handleInputChange: handleSearchInputChange,
    isSearch: true,
    value: externalName,
  };

  const roleSource = roleOptions.map((role) => ({
    indent: 0,
    label: role,
    optionType: 'role',
    subCategories: [],
    value: {
      type: 'role',
      role,
    },
  }));

  const selectedRoles = role.map((role) => ({ role, type: 'role' }));

  const roleFilterProps = {
    className: styles.searchBarFilter,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    source: roleSource,
    name: 'role',
    key: 'role',
    optionsLarge: true,
    isLocation: true,
    onChange: (value) =>
      handleSearchFilterChange({
        target: {
          subCategories: value.subCategories,
          label: value.label,
          value: value.value,
          name: 'role',
          optionType: value.optionType,
        },
      }),
    selectedValues: selectedRoles,
    defaultLabel: 'Click to select roles',
  };

  const stateOptions = [
    {
      label: 'Published',
      value: 'open',
    },
    {
      label: 'Pending',
      value: 'pending',
    },
    {
      label: 'Closed',
      value: 'closed',
    },
  ];

  const stateSource = stateOptions.map((state) => ({
    indent: 0,
    label: state.label,
    optionType: 'role',
    subCategories: [],
    value: {
      type: 'state',
      state: state.value,
    },
  }));

  const selectedStates = state.map((state) => ({ state, type: 'state' }));

  const stateFilterProps = {
    className: styles.searchBarFilter,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    source: stateSource,
    name: 'state',
    key: 'state',
    optionsLarge: true,
    isLocation: true,
    onChange: (value) =>
      handleSearchFilterChange({
        target: {
          subCategories: value.subCategories,
          label: value.label,
          value: value.value,
          name: 'state',
          optionType: value.optionType,
        },
      }),
    selectedValues: selectedStates,
    defaultLabel: 'Click to select states',
  };

  const completeProps = {
    name: 'complete',
    label: 'Complete',
    checked: complete,
    handleInputChange: handleSearchCheckboxChange,
    className: styles.checkbox,
    checkboxMany: true,
  };

  return (
    <Fragment>
      <div className={styles.SearchBarOne}>
        <div className={styles.stack}>
          <DropdownSelectMany {...stateFilterProps} />
          <DropdownSelectMany {...roleFilterProps} />
          <InputCheckbox {...completeProps} />
        </div>
        <div className={styles.stack}>
          <Input {...employerSearchProps} />
          <Input {...externalNameSearchProps} />
        </div>
      </div>
    </Fragment>
  );
};

export default ToolboxApproachesSearchBar;
