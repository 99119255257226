import { call, put, select, takeLatest } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { promiseGetFullCandidate } from 'pages/EmployeePortal/CandidateProfilePages/promises';

import { getCurrentUser } from 'shared/store/app/selectors';

import { CANDIDATE_LOAD_STARTED } from '../actions';

import { candidateProfilePatchDone } from '../creators';

const candidateLoadStarted = function* candidateLoadStarted() {
  const { currentProfile: { id: candidateId = null } = {} } = yield select(getCurrentUser);

  if (candidateId) {
    try {
      const getCandidateArgs = {
        candidateId,
      };

      const candidateProfile = yield call(promiseGetFullCandidate, getCandidateArgs);

      yield put(candidateProfilePatchDone({ candidateProfile }));
    } catch (error) {
      handleError(error);
    }
  }
};

const sagaCandidateLoadStarted = function* sagaCandidateLoadStarted() {
  yield takeLatest(CANDIDATE_LOAD_STARTED, candidateLoadStarted);
};

export default sagaCandidateLoadStarted;
