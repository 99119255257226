/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { USAMap } from 'components/USAMap';

import styles from './USMapView.scss';

// Color & opacity vars:
const color1 = '#495057';
const color2 = '#6c757d';
const color3 = '#212529';

const colorWest = color1;
const colorSouthWest = color3;
const colorMidWest = color2;
const colorNorthEast = color3;
const colorSouthEast = color1;
const colorSelected = 'var(--seafoam-02)';
const opacityRegular = '1';
const opacityHighlighted = '0.7';

const regions = {
  ['West']: {
    states: ['WA', 'ID', 'MT', 'WY', 'CO', 'UT', 'NV', 'CA', 'OR', 'AK', 'HI'],
    color: colorWest,
  },
  ['Southwest']: {
    states: ['AZ', 'NM', 'TX', 'OK'],
    color: colorSouthWest,
  },
  ['Midwest']: {
    states: ['ND', 'SD', 'NE', 'KS', 'MN', 'IA', 'MO', 'WI', 'IL', 'IN', 'MI', 'OH'],
    color: colorMidWest,
  },
  ['Northeast']: {
    states: ['PA', 'NY', 'DE', 'MD', 'CT', 'RI', 'MA', 'VT', 'NH', 'ME', 'NJ', 'DC'],
    color: colorNorthEast,
  },
  ['Southeast']: {
    states: ['AR', 'LA', 'MS', 'KY', 'TN', 'AL', 'GA', 'FL', 'SC', 'NC', 'VA', 'WV'],
    color: colorSouthEast,
  },
};

export const getRegionByState = (state) => {
  return Object.keys(regions).find((region) =>
    regions[region].states.includes((state || '').toUpperCase())
  );
};

// { 'AR': { fill: 'red' }, 'DE': { fill: 'orange' }, ... }
//
const defaultRegionsConfig = Object.keys(regions).reduce((acc, region) => {
  const { states, color } = regions[region];
  states.forEach((state) => (acc[state] = { fill: color }));

  return acc;
}, {});

const getStateRegion = (state) =>
  Object.keys(regions).find((reg) => regions[reg].states.includes(state));

const setRegionColor = (oldConfig, region, color) => {
  const newConfig = { ...oldConfig };

  for (const state of Object.keys(oldConfig)) {
    if (regions[region].states.includes(state)) {
      newConfig[state].fill = color || regions[region].color;
    }
  }

  return newConfig;
};

const setRegionOpacity = (oldConfig, region, opacity) => {
  const newConfig = { ...oldConfig };

  for (const state of Object.keys(oldConfig)) {
    if (regions[region].states.includes(state)) {
      newConfig[state].opacity = opacity || opacityRegular;
    }
  }

  return newConfig;
};

export const UsMapView = ({
  selectedRegions = [],
  onClickRegion,
  highlightedRegion = null,
  onHighlightRegion = null,
}) => {
  const [highlighted, setHighlighted] = useState();

  const config = useMemo(() => {
    const newConfig = JSON.parse(JSON.stringify(defaultRegionsConfig));

    for (const region of Object.keys(regions)) {
      if (selectedRegions.includes(region)) {
        setRegionColor(newConfig, region, colorSelected);
      }

      if (region === highlighted) {
        setRegionOpacity(newConfig, region, opacityHighlighted);
      }
    }

    return newConfig;
  }, [highlighted, selectedRegions]);

  const onClickState = useCallback(
    (id) => {
      const region = getStateRegion(id);
      if (onClickRegion) {
        onClickRegion(region);
      }
    },
    [onClickRegion]
  );

  useEffect(() => {
    if (Object.keys(regions).includes(highlightedRegion)) {
      setHighlighted(highlightedRegion);
    } else {
      setHighlighted(null);
    }
  }, [highlightedRegion]);

  return (
    <div className={styles.usMapContainer}>
      <USAMap
        customize={config}
        onClick={(event) => {
          onClickState(event.target.dataset.name);
        }}
        onMouseEnter={(event) => {
          const state = event.target.dataset.name;
          const region = getStateRegion(state);
          if (region) {
            setHighlighted(region);
            if (onHighlightRegion) onHighlightRegion(region);
          }
        }}
        onMouseLeave={(event) => {
          setHighlighted(null);
          if (onHighlightRegion) onHighlightRegion(null);
        }}
      />
    </div>
  );
};
