import React from 'react';

import { WorkModelSelectMany } from 'components/WorkModelSelectMany';
import DropdownUsRegions from 'components/DropdownUsRegions';
import Label from 'components/Label';
import GooglePlacesAutocomplete from 'components/GooglePlacesAutocomplete';

import styles from '../ReqDetail.scss';

const Locations = ({
  handleToggleRegions,
  handleWorkModelChange,
  handleInputChange,
  requisitionOpening: {
    attributes: { location: requisitionLocation, locations = [], state, workModel = [] } = {},
  } = {},
  locationSource,
  location, // state for CreateNewRequisition (not saved yet)
  required = true,
  locationTitle = 'Location (optional)',
}) => {
  const locationOptions = locationSource.reduce((acc, curr) => {
    let subOpts = [];
    if (curr.subcategories) {
      subOpts = curr.subcategories.map((subOpt) => ({
        indent: 1,
        label: subOpt.name,
        optionType: subOpt.type,
        value: {
          type: subOpt.type,
          [subOpt.type]: subOpt.name,
        },
      }));
    }

    const allOpts = Array.prototype.concat(
      [
        {
          indent: 0,
          label: curr.name,
          optionType: curr.type,
          subCategories: subOpts,
          value: {
            type: curr.type,
            [curr.type]: curr.name,
          },
        },
      ],
      subOpts
    );

    return acc.concat(allOpts);
  }, []);

  const locationsInputProps = {
    className: styles.searchBarFilter,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    onChange: (value) =>
      handleToggleRegions({
        target: {
          label: value.label,
          name: 'locations',
          optionType: value.optionType,
          subCategories: value.subCategories,
          value,
        },
      }),
    optionsLarge: true,
    source: locationOptions.map((locOpt) => ({ ...locOpt, value: locOpt.value.region })),
    name: 'locations',
    key: 'locations',
    isLocation: true,
    selectedValues: locations.map((loc) => loc.region) || '',
    defaultLabel: 'Click to select a region',
    disabled: state === 'closed',
    pillsPlacement: 'select-many',
    closeOnSelect: true,
  };

  return (
    <div className={styles.Locations}>
      <Label standalone className={styles.label}>
        {locationTitle}
      </Label>
      <GooglePlacesAutocomplete
        name="location"
        value={location || requisitionLocation || ''}
        placeholder="Location"
        handleSelect={(place) =>
          handleInputChange({
            target: {
              value: place,
              name: 'location',
            },
          })
        }
      />
      <Label required={state !== 'closed' && required} className={styles.label}>
        Region
      </Label>
      <DropdownUsRegions {...locationsInputProps} />
      <Label required={state !== 'closed' && required} className={styles.label}>
        Work Model
      </Label>
      <WorkModelSelectMany noLabel workModel={workModel} onChange={handleWorkModelChange} />
    </div>
  );
};

export default Locations;
