import { createSelector } from 'reselect';
import { getApp } from 'shared/store/app/selectors';

const selectCandidateSettings = createSelector(getApp, (state) => state.candidateSettings);

const selectCandidateCompanyBlocksTotal = createSelector(
  selectCandidateSettings,
  (candidateSettings = {}) => candidateSettings.candidateCompanyBlocks.total
);

export default selectCandidateCompanyBlocksTotal;
