import { handleActions } from 'redux-actions';

import {
  TEMPLATES_LOAD_STARTED,
  TEMPLATES_LOAD_DONE,
  TEMPLATE_SAVE_DONE,
  TEMPLATE_DELETE_DONE,
  TEMPLATE_UPDATE_DONE,
} from '../actions';

const initialState = {
  data: [],
  isLoading: true,
};

const handleTemplatesLoadStarted = (state = initialState, action) => {
  const {
    payload: { page },
  } = action;

  if (page === 1) {
    return {
      ...state,
      isLoading: true,
    };
  }

  return state;
};

const handleTemplatesLoadDone = (state = initialState, action) => {
  const {
    payload: { templates = [], page, total },
  } = action;

  if (page === 1) {
    return {
      data: [...initialState.data, ...templates],
      page,
      total,
      isLoading: false,
    };
  }

  return {
    data: [...state.data, ...templates],
    page,
    total,
    isLoading: false,
  };
};

const handleTemplateSaveDone = (state = initialState, action) => {
  const {
    payload: { template },
  } = action;

  const { data, total } = state;

  const newData = data.slice();

  newData.unshift(template);

  return {
    ...state,
    data: newData,
    total: total + 1,
  };
};

const handleTemplateDeleteDone = (state = initialState, action) => {
  const {
    payload: { templateId },
  } = action;

  const { data, total } = state;

  const newData = data.filter((temp) => temp.id !== templateId);

  return {
    ...state,
    data: newData,
    total: total - 1,
  };
};

const handleTemplateUpdateDone = (state = initialState, action) => {
  const {
    payload: { template },
  } = action;

  const { data } = state;

  const newData = data.map((temp) => {
    if (temp.id === template.id) {
      return template;
    }

    return temp;
  });

  return {
    ...state,
    data: newData,
  };
};

const actionHandlers = {
  [TEMPLATES_LOAD_STARTED]: handleTemplatesLoadStarted,
  [TEMPLATES_LOAD_DONE]: handleTemplatesLoadDone,
  [TEMPLATE_SAVE_DONE]: handleTemplateSaveDone,
  [TEMPLATE_DELETE_DONE]: handleTemplateDeleteDone,
  [TEMPLATE_UPDATE_DONE]: handleTemplateUpdateDone,
};

const reduceTemplateComposer = handleActions(actionHandlers, initialState);

export default reduceTemplateComposer;
