import React, { Component, Fragment } from 'react';

import DialogueModal from 'components/DialogueModal';
import Button from 'components/Button';
import Content from 'components/Content';

import styles from './CohortCollisionsModal.scss';

// TODO

class CohortCollisionsModal extends Component {
  constructor(props) {
    super(props);

    const collisionsToCheck = props.collisions || [];

    const candidateIds = collisionsToCheck.map((coll) => coll.candidate_id);

    const uniqCandidateIds = candidateIds.filter((id, idx, self) => self.indexOf(id) === idx);

    const collisionsByCand = uniqCandidateIds.map((id) => {
      const cohortLinks = collisionsToCheck
        .filter((coll) => coll.candidate_id === id)
        .map((coll) => coll.cohort_links);
      return Array.prototype.concat([], cohortLinks);
    });

    if (process.env.DISABLE_FORCE_ADDS) {
      const candidatesToSkip = uniqCandidateIds;

      const collisionsAllSkipped = collisionsByCand.map((collision) => {
        collision[0].skipping = true;
        return collision;
      });

      this.state = {
        collisionsToCheck: collisionsAllSkipped,
        candidatesToRemove: [],
        candidatesToSkip,
      };
    } else {
      this.state = {
        collisionsToCheck: collisionsByCand,
        candidatesToRemove: [],
        candidatesToSkip: [],
      };
    }
  }

  handleSkip = (candidateId) => {
    const { collisionsToCheck, candidatesToSkip } = this.state;

    const newCollisionsToCheck = collisionsToCheck.map((coll) => {
      if (coll[0][0].candidate_id === candidateId) {
        coll[0][0].skipping = true;
      }
      return coll;
    });

    const newCandidatesToSkip = candidatesToSkip.slice();

    newCandidatesToSkip.push(candidateId);

    this.setState({
      candidatesToSkip: newCandidatesToSkip,
      collisionsToCheck: newCollisionsToCheck,
    });
  };

  handleForceAdd = ({ coll, candidateId }) => {
    const { cohortId } = this.props;
    const { collisionsToCheck, candidatesToRemove } = this.state;

    const newCollisionsToCheck = collisionsToCheck.map((coll) => {
      if (coll[0][0].candidate_id === candidateId) {
        coll[0][0].removing = true;
      }
      return coll;
    });

    const filteredLinks = coll[0].filter((link) => link.cohort_id !== cohortId);

    const candidatesToRemoveObjects = filteredLinks.map((link) => ({
      cohortId: link.cohort_id,
      cohortCandidateId: link.cohort_candidate_id,
      candidateId: link.candidate_id,
    }));

    const newCandidatesToRemove = Array.prototype.concat(
      candidatesToRemove,
      candidatesToRemoveObjects
    );

    this.setState({
      candidatesToRemove: newCandidatesToRemove,
      collisionsToCheck: newCollisionsToCheck,
    });
  };

  handleCancelRemove = (candidateId) => {
    const { collisionsToCheck, candidatesToRemove } = this.state;

    const newCollisionsToCheck = collisionsToCheck.map((coll) => {
      if (coll[0][0].candidate_id === candidateId) {
        coll[0][0].removing = false;
        coll[0][0].skipping = false;
      }
      return coll;
    });

    const newCandidatesToRemove = candidatesToRemove.filter(
      (candId) => candId.candidateId !== candidateId
    );

    this.setState({
      candidatesToRemove: newCandidatesToRemove,
      collisionsToCheck: newCollisionsToCheck,
    });
  };

  handleCancelSkip = (candidateId) => {
    const { collisionsToCheck, candidatesToSkip } = this.state;

    const newCollisionsToCheck = collisionsToCheck.map((coll) => {
      if (coll[0][0].candidate_id === candidateId) {
        coll[0][0].skipping = false;
        coll[0][0].removing = false;
      }
      return coll;
    });

    const newCandidatesToSkip = candidatesToSkip.filter((candId) => candId !== candidateId);

    this.setState({
      candidatesToSkip: newCandidatesToSkip,
      collisionsToCheck: newCollisionsToCheck,
    });
  };

  handleSkipAll = () => {
    const { collisionsToCheck } = this.state;

    const newCollisionsToCheck = collisionsToCheck.map((collision) => {
      collision[0].skipping = true;
      collision[0].removing = false;
      return collision;
    });

    const newCandidatesToSkip = collisionsToCheck.map((coll) => coll[0].candidate_id);

    this.setState({
      candidatesToSkip: newCandidatesToSkip,
      candidatesToRemove: [],
      collisionsToCheck: newCollisionsToCheck,
    });
  };

  handleForceAddAll = () => {
    const { cohortId } = this.props;
    const { collisionsToCheck } = this.state;

    const newCollisionsToCheck = collisionsToCheck.map((coll) => {
      coll[0][0].skipping = false;
      coll[0][0].removing = true;
      return coll;
    });

    const candidatesToRemove = collisionsToCheck.map((coll) => {
      const filteredLinks = coll[0].filter((link) => link.cohort_id !== cohortId);

      return filteredLinks.map((link) => ({
        candidateId: link.candidate_id,
        cohortId: link.cohort_id,
        cohortCandidateId: link.cohort_candidate_id,
      }));
    });

    this.setState({
      candidatesToSkip: [],
      candidatesToRemove: candidatesToRemove.reduce((a, b) => a.concat(b)),
      collisionsToCheck: newCollisionsToCheck,
    });
  };

  render() {
    const { handleCloseClick, handleSubmitClick, cohortId, cohortName = '' } = this.props;

    const { collisionsToCheck = [], candidatesToSkip, candidatesToRemove } = this.state;

    const closeButton = (
      <Button onClick={handleCloseClick} quaternary>
        Cancel
      </Button>
    );

    const skipAllButton = (
      <Button onClick={this.handleSkipAll} tertiary>
        Skip All
      </Button>
    );

    const removeAllButton = (
      <Button onClick={this.handleForceAddAll} warning>
        Force Add All
      </Button>
    );

    const continueButtonProps = {
      onClick: () => handleSubmitClick({ candidatesToSkip, candidatesToRemove }),
      primary: true,
      disabled: !collisionsToCheck.every((coll) => coll[0][0].skipping || coll[0][0].removing),
    };

    const continueButton = <Button {...continueButtonProps}>Continue</Button>;

    const collisionsContent = collisionsToCheck.map((coll, idx) => {
      const filteredLinks = coll[0].filter((link) => link.cohort_id !== cohortId);

      const {
        candidate_first_name: firstName,
        candidate_last_name: lastName,
        candidate_id: candidateId,
        skipping,
        removing,
      } = coll[0][0];

      const cohorts = filteredLinks.map((link) => link.cohort_name);
      const uniqCohorts = cohorts.filter((cohort, idx, self) => self.indexOf(cohort) === idx);
      const cohortContent = uniqCohorts.map((link) => {
        const linkProps = {
          className: styles.cohortLink,
        };

        return (
          <div key={link} {...linkProps}>
            {link}
          </div>
        );
      });

      const employers = filteredLinks.map((link) => link.employer_name);

      const uniqEmployers = employers.filter((emp, idx, self) => self.indexOf(emp) === idx);

      const employersContent = uniqEmployers.map((emp) => (
        <div key={emp} className={styles.company}>
          {emp}
        </div>
      ));

      const removeButtonProps = {
        warning: true,
        onClick: () =>
          this.handleForceAdd({
            coll,
            candidateId,
          }),
      };

      const skipButtonProps = {
        tertiary: true,
        onClick: () => this.handleSkip(candidateId),
      };

      const cancelSkipButtonProps = {
        warningTertiary: true,
        onClick: () => this.handleCancelSkip(candidateId),
      };

      const cancelRemoveButtonProps = {
        warningTertiary: true,
        onClick: () => this.handleCancelRemove(candidateId),
      };

      const cancelSkipButton = process.env.DISABLE_FORCE_ADDS ? null : (
        <Button {...cancelSkipButtonProps}>Cancel Skip</Button>
      );

      const skippingContent = skipping ? (
        <Fragment>
          {cancelSkipButton}
          <Content className={styles.statusContent}>SKIPPING</Content>
        </Fragment>
      ) : null;

      const removingContent = removing ? (
        <Fragment>
          <Button {...cancelRemoveButtonProps}>Cancel Force Add</Button>
          <Content className={styles.statusContent}>FORCE ADDING</Content>
        </Fragment>
      ) : null;

      const uncheckedContent =
        !skipping && !removing ? (
          <Fragment>
            <Button {...removeButtonProps}>Force Add</Button>,
            <Button {...skipButtonProps}>Skip</Button>,
          </Fragment>
        ) : null;

      const bottomContent = uncheckedContent || skippingContent || removingContent;

      return (
        <div key={idx} className={styles.cohortLinkContainer}>
          <div className={styles.linkContainer}>
            <div className={styles.candidate}>
              {firstName} {lastName}
            </div>
            <div className={styles.links}>{cohortContent}</div>
            <div className={styles.companies}>{employersContent}</div>
          </div>
          <div className={styles.bottomContainer}>{bottomContent}</div>
        </div>
      );
    });

    const name = cohortName ? ` for ${cohortName}` : '';

    const title = `Collisions${name}`;

    const collisionButtons = process.env.DISABLE_FORCE_ADDS ? null : (
      <div className={styles.collisionButtons}>
        {removeAllButton}
        {skipAllButton}
      </div>
    );

    const instructions = process.env.DISABLE_FORCE_ADDS ? (
      <Content className={styles.subtitle}>
        The following candidates will be <span className={styles.seafoam}>skipped</span> due to
        collisions with other cohorts.
      </Content>
    ) : (
      <Content className={styles.subtitle}>
        Select <span className={styles.red}>Force Add</span> to remove candidates from all
        conflicting cohorts and add to current cohort. Select{' '}
        <span className={styles.seafoam}>Skip</span> to omit the candidate from the current cohort.
      </Content>
    );

    return (
      <DialogueModal>
        <div className={styles.requestConfirmationModal}>
          <div className={styles.requestConfirmationTitle}>{title}</div>
          {instructions}
          <div className={styles.header}>
            <div className={styles.headerContent}>Candidate</div>
            <div className={styles.headerContent}>Cohort/s</div>
            <div className={styles.headerContent}>Employer/s</div>
          </div>
          <div className={styles.requestConfirmationContent}>{collisionsContent}</div>
          {collisionButtons}
          <div className={styles.requestConfirmationButtons}>
            {closeButton}
            {continueButton}
          </div>
        </div>
      </DialogueModal>
    );
  }
}

export default CohortCollisionsModal;
