import React, { useEffect } from 'react';
import { push } from 'connected-react-router';

import { handleError } from 'utils/common';

import ConnectContainer from 'containers/ConnectContainer';

import Block from 'components/Block';
import TableOverlay from 'components/TableOverlay';

import { employerHomePageValues } from 'utils/constants';

import { promiseGetDiscourseSSO } from './promises';

import duck from './redux';

import styles from './DiscourseSSOLogin.scss';

const DiscourseSSOLogin = (props) => {
  useEffect(() => {
    const {
      actions: { setNotification },
      dispatch,
      location: { search } = {},
      isAdmin,
      isCandidate,
      isEmployee,
      user: { currentProfile: { employer: { homepage } = {} } = {} } = {},
    } = props;

    if (isCandidate || isAdmin) {
      promiseGetDiscourseSSO(search)
        .then((url) => {
          if (window && window.location && url) {
            window.location.replace(url);
          } else {
            dispatch(push('/candidate/home/'));
          }
        })
        .catch((error) => {
          handleError(error);
          dispatch(push('/candidate/home/'));
        });
    }

    if (isEmployee) {
      const findTalentRoute =
        employerHomePageValues[homepage]?.homepage ||
        process.env.APPROVED_EMPLOYER_DEFAULT_LINK ||
        '/featured';

      const notificationText = `
        The sales community forum is currently unavailable for hiring managers. If you wish to use Rainmakers as a candidate, please logout and create a new profile as a candidate.

        Otherwise, hit continue to be taken to the Rainmakers hiring manager platform.
      `;

      setNotification({
        title: 'Sales community unavailable',
        text: notificationText,
        confirmText: 'Continue',
      });

      dispatch(push(findTalentRoute));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.LoginContainer}>
      <Block addWhiteBG={true} boxShadow={true} className={styles.block}>
        <TableOverlay />
      </Block>
    </div>
  );
};

export default ConnectContainer(duck)(DiscourseSSOLogin);
