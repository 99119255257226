import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import isEmpty from 'lodash.isempty';
import { Prompt } from 'react-router-dom';

import { handleError } from 'utils/common';

import ConnectContainer from 'containers/ConnectContainer';

import { CandidateAuthHOC } from 'containers/HOC';

import ReadyContainer from 'connectors/ReadyContainer';

import { promiseGetFullCandidate } from 'pages/EmployeePortal/CandidateProfilePages/promises';

import Block from 'components/Block';
import Button from 'components/Button';
import FontIcon from 'components/FontIcon';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import RainCam from 'components/RainCam';
import Sidebar from 'components/Sidebar';
import TableOverlay from 'components/TableOverlay';

import duck from '../redux';

import styles from './EditVideo.scss';

@CandidateAuthHOC()
class EditVideo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      unsavedData: false,
    };
  }

  componentDidMount() {
    const {
      candidate: { id: candidateId } = {},
      actions: { nestedEntityLoadStarted },
    } = this.props;

    promiseGetFullCandidate({ candidateId }).then(this.onCandidateSuccess).catch(handleError);

    nestedEntityLoadStarted({
      id: candidateId,
      nestedType: 'vimeo_video',
      slice: 'candidateEditor',
      type: 'candidates',
    });
  }

  onCandidateSuccess = (candidateProfile) => {
    const {
      actions: { candidateProfilePatchDone },
    } = this.props;

    candidateProfilePatchDone({ candidateProfile });
  };

  handleDeleteVideo = () => {
    const {
      actions: { deleteResource },
      vimeoVideos: { byId: vimeoVideosById = {}, allIds: vimeoVideosAllIds = [] },
      candidate: { id: candidateId } = {},
    } = this.props;

    const vimeoVideoId = vimeoVideosAllIds.find(
      (id) => vimeoVideosById[id].attributes.ownerId === candidateId
    );

    if (vimeoVideoId) {
      deleteResource({
        slice: 'candidateEditor',
        type: 'vimeo_videos',
        id: vimeoVideoId,
      });
    }
  };

  render() {
    const { unsavedData } = this.state;

    const {
      actions: { deleteResource, setAppModalError },
      candidate,
      candidate: { id: candidateId, profileLocked } = {},
      vimeoVideos,
      vimeoVideos: { byId: vimeoVideosById = {}, allIds: vimeoVideosAllIds = [] } = {},
      user: { isMobile, isSafari } = {},
    } = this.props;

    const vimeoVideoId = vimeoVideosAllIds.find(
      (id) => vimeoVideosById[id].attributes.ownerId === candidateId
    );

    const { [vimeoVideoId]: { attributes: { vimeoResponse = {} } = {} } = {} } = vimeoVideosById;

    const { embed: { html: __html } = {} } = vimeoResponse || {};

    const titleBlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      addPadding: true,
      className: styles.titleBlock,
    };

    const mainBlockProps = {
      ...titleBlockProps,
      className: styles.mainBlock,
    };

    const rainCamProps = {
      deleteResource,
      isMobile,
      isSafari,
      ownerId: candidateId,
      ownerType: 'Candidate',
      profileLocked,
      setUnsavedData: (state) => this.setState({ unsavedData: state }),
      setAppModalError,
      slice: 'candidateEditor',
    };

    const deleteButtonProps = {
      onClick: this.handleDeleteVideo,
      warning: true,
      className: styles.delete,
      disabled: profileLocked,
    };

    const innerHTML = { __html };

    const embedContent = <div dangerouslySetInnerHTML={innerHTML} />;

    const innerContent = __html ? (
      <Fragment>
        {embedContent}
        <Button {...deleteButtonProps}>
          <FontIcon iconName="trash" />
          Discard
        </Button>
      </Fragment>
    ) : (
      <RainCam {...rainCamProps} />
    );

    const mainContent = isEmpty(vimeoVideos) ? (
      <div className={styles.emptyVideo}>
        <TableOverlay table="inPlace" />
      </div>
    ) : (
      innerContent
    );

    const statusContent = profileLocked ? (
      <div className={styles.profileLocked}>Profile Locked</div>
    ) : null;

    const content = (
      <div className={styles.EditorIntro}>
        <Block {...titleBlockProps}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>Intro Video</div>
            <div className={styles.saveContent}>{statusContent}</div>
          </div>
        </Block>
        <Block {...mainBlockProps}>{mainContent}</Block>
        <Prompt
          when={unsavedData}
          message={
            'Are you sure you want to leave this page? You have uploaded a video without submitting and your video will be lost.'
          }
        />
      </div>
    );

    const layoutWithoutSidebarProps = {
      content,
    };

    const sidebarProps = {
      type: 'candidateEditor',
      page: 'edit-video',
      candidate,
    };

    return (
      <React.Fragment>
        <Helmet title="Candidate Editor" />
        <ReadyContainer className={styles.CandidateEditorVideo}>
          <Sidebar {...sidebarProps} />
          <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />
        </ReadyContainer>
      </React.Fragment>
    );
  }
}

export default ConnectContainer(duck)(EditVideo);
