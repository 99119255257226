import { all, fork } from 'redux-saga/effects';

import sagaPickerOptionsLoad from './sagaPickerOptionsLoad';
import sagaTagsLoad from './sagaTagsLoad';

const sagaDefaults = function* sagaDefaults() {
  yield all([fork(sagaPickerOptionsLoad), fork(sagaTagsLoad)]);
};

export default sagaDefaults;
