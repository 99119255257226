import { createStructuredSelector } from 'reselect';

import {
  selectIsLoggedIn,
  selectIsAdmin,
  selectLogoutPending,
} from 'connectors/Login/redux/selectors';

import {
  entitySelectorFactory,
  loadingSelectorFactory,
  pageSelectorFactory,
  totalSelectorFactory,
  savingSelectorFactory,
} from 'reduxStore/selectorFactories';

import { selectUser } from 'connectors/User/redux/selectors';

import { getModal } from 'shared/store/app/selectors';

import selectCohort from './selectCohort';

const selector = createStructuredSelector({
  candidates: entitySelectorFactory('toolboxCohorts', 'candidate'),
  candidatesPage: pageSelectorFactory('toolboxCohorts', 'candidate'),
  candidatesTotal: totalSelectorFactory('toolboxCohorts', 'candidate'),
  candidatesLoading: loadingSelectorFactory('toolboxCohorts', 'candidate'),
  addableCandidates: entitySelectorFactory('toolboxCohorts', 'addableCandidate'),
  addableCandidatesPage: pageSelectorFactory('toolboxCohorts', 'addableCandidate'),
  addableCandidatesTotal: totalSelectorFactory('toolboxCohorts', 'addableCandidate'),
  addableCandidatesLoading: loadingSelectorFactory('toolboxCohorts', 'addableCandidate'),
  cohort: selectCohort,
  cohorts: entitySelectorFactory('toolboxCohorts', 'cohort'),
  cohortEmployers: entitySelectorFactory('toolboxCohorts', 'cohortEmployer'),
  cohortEmployersPage: pageSelectorFactory('toolboxCohorts', 'cohortEmployer'),
  cohortEmployersTotal: totalSelectorFactory('toolboxCohorts', 'cohortEmployer'),
  cohortEmployersLoading: loadingSelectorFactory('toolboxCohorts', 'cohortEmployer'),
  cohortCandidates: entitySelectorFactory('toolboxCohorts', 'cohortCandidate'),
  cohortCandidatesPage: pageSelectorFactory('toolboxCohorts', 'cohortCandidate'),
  cohortCandidatesTotal: totalSelectorFactory('toolboxCohorts', 'cohortCandidate'),
  cohortCandidatesLoading: loadingSelectorFactory('toolboxCohorts', 'cohortCandidate'),
  employers: entitySelectorFactory('toolboxCohorts', 'employer'),
  employersPage: pageSelectorFactory('toolboxCohorts', 'employer'),
  employersTotal: totalSelectorFactory('toolboxCohorts', 'employer'),
  employersLoading: loadingSelectorFactory('toolboxCohorts', 'employer'),
  isAdmin: selectIsAdmin,
  isLoading: loadingSelectorFactory('toolboxCohorts', 'cohort'),
  isLoggedIn: selectIsLoggedIn,
  logoutPending: selectLogoutPending,
  modal: getModal,
  page: pageSelectorFactory('toolboxCohorts', 'cohort'),
  saving: savingSelectorFactory('toolboxCohorts'),
  total: totalSelectorFactory('toolboxCohorts', 'cohort'),
  user: selectUser,
});

export { selector as default };
