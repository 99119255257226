import React from 'react';

import { handleError } from 'utils/common';
import { patchEntity } from 'api/apiEntity';
import { VisibleHOC } from 'containers/HOC';

import DropdownPane from 'components/DropdownPane';
import VerificationContent from 'components/VerificationContent';

const ToolboxEducationOrgActionDropdown = ({
  unverifiedData: { id, attributes: { name, webSite, picture = {} } = {} } = {},
  actions: { resourceUpdate, resourceDelete },
  educationOrg,
  isVisible,
  refreshCallback,
  sliceToUpdate = 'toolboxEducationOrgs',
}) => {
  const verifyOrReject = async (verify) => {
    if (verify) {
      const attrs = educationOrg.attributes || educationOrg;

      resourceUpdate({
        slice: sliceToUpdate,
        type: 'educationOrganization',
        id: educationOrg.id,
        resource: {
          attributes: {
            ...attrs,
            webSite: webSite || attrs.webSite,
            picture: picture || attrs.picture,
          },
        },
      });
    }

    try {
      await patchEntity({
        id,
        type: 'unverified_education_organization_data',
        data: {
          attributes: {
            state: verify ? 'verified' : 'rejected',
          },
          id,
          type: 'unverified_education_organization_data',
        },
        config: {
          params: {
            camelizeIt: true,
          },
        },
      });
    } catch (error) {
      handleError(error);
    }

    if (refreshCallback) {
      refreshCallback();
    }

    resourceDelete({
      id,
      slice: sliceToUpdate,
      type: 'unverifiedEducationOrganizationData',
    });
  };

  return (
    <DropdownPane
      above={!isVisible}
      element={
        <VerificationContent
          name={name}
          picture={picture}
          webSite={webSite}
          editCompanyLink={`/toolbox/education-org/${educationOrg.id}/edit-profile`}
          onVerify={() => verifyOrReject(true)}
          onReject={() => verifyOrReject(false)}
        />
      }
      label="Actions"
      left={true}
    />
  );
};

export default VisibleHOC()(ToolboxEducationOrgActionDropdown);
