/* eslint react/prefer-stateless-function: "off" */
import React from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import UnauthorizedPage from 'pages/UtilPages/UnauthorizedPage';

const ProfileAuthHOC = () => (WrappedComponent) => {
  const _ProfileAuthHOC = (props) => {
    const {
      isCandidate,
      logoutPending,
      user: { currentProfileType, currentProfile: { id: currentProfileId } = {} } = {},
    } = props;

    const location = useLocation();
    const { search } = location;
    const { id: candidateId } = useParams();

    if (!currentProfileType && !logoutPending) {
      const to = {
        pathname: '/login/candidate',
        search,
        state: { originalPath: location },
      };

      return <Redirect to={to} />;
    }

    if (isCandidate && currentProfileId !== candidateId) return <UnauthorizedPage />;

    return <WrappedComponent {...props} />;
  };

  return _ProfileAuthHOC;
};

export default ProfileAuthHOC;
