import React from 'react';

import classnames from 'classnames';

import DialogueModal from 'components/DialogueModal';
import Button from 'components/Button';

import styles from './ConfirmModal.scss';

const ConfirmModal = ({
  className,
  handleCancel,
  handleConfirm,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  text,
  title,
}) => {
  const classes = classnames(styles.ConfirmModal, className);

  const confirmProps = {
    onClick: handleConfirm,
    primary: true,
  };

  const cancelProps = {
    onClick: handleCancel,
    quaternary: true,
  };

  const confirmButton = <Button {...confirmProps}>{confirmText}</Button>;

  const cancelButton = <Button {...cancelProps}>{cancelText}</Button>;

  return text ? (
    <DialogueModal className={classes}>
      <div className={styles.title}>{title}</div>
      <div className={styles.content}>{text}</div>
      <div className={styles.buttons}>
        {cancelButton}
        {confirmButton}
      </div>
    </DialogueModal>
  ) : null;
};

export default ConfirmModal;
