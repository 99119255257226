import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import dayjs from 'dayjs';

import EmptyEducationLogo from 'shared/assets/missing-education-organization-picture.png';

import Button from 'components/Button';

import ToolboxEducationOrgEditorDropdown from 'pages/AdminPortal/ToolboxEducationOrgsPage/components/ToolboxEducationOrgEditorDropdown/ToolboxEducationOrgEditorDropdown';
import { promiseGetEducationOrganization } from 'pages/AdminPortal/ToolboxCompanyEditorPage/promises';

import { selectUser } from 'connectors/User/redux/selectors';

import { selectIsAdmin } from 'connectors/Login/redux/selectors';

import { setImageUploadCompany } from 'shared/store/app/creators';

import { useRetryLoadData } from 'hooks/useRetryLoadData';
import { handleError } from 'utils/common';

import { useImageField } from 'hooks';

import styles from './EducationDisplay.scss';

const UNVERIFIED_DATA_RETRIES = 2;
const UNVERIFIED_DATA_TIMEOUT = 2000;

const EducationDisplay = ({
  education,
  idx,
  handleToggleEditingEdu,
  setEduRef,
  isProfileLocked = false,
  // actions,
  showInlineEditor = false,
}) => {
  const {
    educationOrganization: educationOrganizationProps,
    startDate,
    endDate,
    degree,
    gpa,
    moved,
  } = education;

  const { id: candidateId } = useParams();

  const educationOrganization = educationOrganizationProps.attributes || educationOrganizationProps;
  const { name: educationOrgName = '' } = educationOrganization;

  const user = useSelector(selectUser);
  const isAdmin = useSelector(selectIsAdmin);

  const dispatch = useDispatch();

  const { currentProfile: { type: profileType } = {} } = user || {};

  const [unverifiedData, setUnverifiedData] = useState();

  const loadUnverifiedData = useCallback(
    () =>
      promiseGetEducationOrganization(educationOrganization.id)
        .then(({ unverifiedEducationOrganizationData }) => unverifiedEducationOrganizationData)
        .catch(handleError),
    [educationOrganization.id]
  );

  useRetryLoadData({
    loadData: loadUnverifiedData,
    ifSuccessful: useCallback((data) => data, []),
    successCallback: setUnverifiedData,
    retries: UNVERIFIED_DATA_RETRIES,
    timeout: UNVERIFIED_DATA_TIMEOUT,
    enabled: profileType !== 'Candidate',
  });

  const refreshData = useCallback(() => {
    promiseGetEducationOrganization(educationOrganization.id)
      .then((educationOrg) => {
        const { unverifiedEducationOrganizationData } = educationOrg;

        if (!unverifiedEducationOrganizationData) {
          setUnverifiedData(undefined);

          dispatch(setImageUploadCompany(educationOrg));
        }
      })
      .catch(handleError);
  }, [dispatch, educationOrganization.id]);

  const startDateYear = startDate ? dayjs(startDate, 'YYYY-MM-DD').get('year') : '';
  const endDateYear = endDate ? dayjs(endDate, 'YYYY-MM-DD').get('year') : 'Present';

  const editButtonProps = {
    tertiary: true,
    className: styles.addButtonBorder,
    onClick: () => handleToggleEditingEdu(idx),
  };

  const dateContent = !startDate ? (
    <div className={styles.years}>{endDate ? endDateYear : null}</div>
  ) : (
    <div className={styles.years}>
      {startDateYear} - {endDateYear}
    </div>
  );

  const gpaContent = gpa ? `GPA: ${gpa}` : null;

  const basicInfoClasses = classnames({
    [styles.movedContainer]: moved,
    [styles.basicInfoContainer]: !moved,
  });

  const editEducationButton = isProfileLocked ? null : (
    <div className={styles.mainSubtitle}>
      <Button {...editButtonProps}>Edit</Button>
    </div>
  );

  const actionDropdown = (
    <div className={styles.actionsContent}>
      {showInlineEditor && (
        <ToolboxEducationOrgEditorDropdown
          educationOrg={educationOrganization}
          unverifiedData={
            unverifiedData ? { id: unverifiedData?.id, attributes: unverifiedData } : null
          }
          refreshCallback={refreshData}
          sliceToUpdate="toolboxCandidates"
          candidateId={candidateId}
        />
      )}
    </div>
  );

  const { imageComponent } = useImageField({
    model: { attributes: educationOrganization },
    avatarClass: styles.avatarWrapper,
    emptyLogo: EmptyEducationLogo,
  });

  return (
    <div ref={setEduRef} className={basicInfoClasses}>
      <div className={styles.orgLogo}>{imageComponent}</div>
      <div className={styles.basicInfoColumnInputs}>
        <div className={styles.educationName}>
          {isAdmin ? (
            <a
              href={`/toolbox/education-org/${educationOrganization.id}/edit-profile`}
              target="_blank"
              className={styles.orgTitleLink}
              rel="noreferrer"
            >
              {educationOrgName}
            </a>
          ) : (
            educationOrgName
          )}
        </div>
        <div className={styles.degree}>{degree}</div>
        {dateContent}
        <div className={styles.gpa}>{gpaContent}</div>
      </div>
      {actionDropdown}
      {editEducationButton}
    </div>
  );
};

export default EducationDisplay;
