import React from 'react';
import classNames from 'classnames';

import styles from './FontIcon.scss';

export const FontIcon = (props) => {
  const { className, iconName, inline, ...otherProps } = props;

  if (!iconName) return null;

  const iconClassName = classNames(
    styles[`icon-${iconName}`],
    styles.icon,
    { [styles.inline]: inline },
    className
  );

  return <i className={iconClassName} {...otherProps} />;
};

export default FontIcon;
