import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

/**
 * Usage: ConnectContainer(duck)(component)
 */
const ConnectContainer =
  (duck = {}) =>
  (ContainedComponent) => {
    const { creators = {}, selector = () => ({}) } = duck;

    const mapStateToProps = selector;
    const mapDispatchToProps = (dispatch) => ({
      actions: bindActionCreators(creators, dispatch),
      dispatch,
    });

    const component = (props) => <ContainedComponent {...props} />;

    const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(component);

    return connectedComponent;
  };

export default ConnectContainer;
