import React from 'react';

const FormattedPercentage = ({ className, percent }) => {
  if (Number.isNaN(parseInt(percent, 10))) {
    return '';
  }
  const formattedPercent = `${Math.floor((percent * 1000) / 10)}`;

  return <span className={className}>{formattedPercent}%</span>;
};

export default FormattedPercentage;
