import React, { Component } from 'react';
import classnames from 'classnames';

import { postEntity } from 'api/apiEntity';

import Button from 'components/Button';

import styles from '../../EmployerEditorEmployees.scss';

class EmployeesActionDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sendConfirmationDone: false,
      confirmationSentTemp: false,
    };
  }

  handleClick = () => {
    const { user, resourceUpdate, userId } = this.props;

    const args = {
      type: 'auth/confirmation',
      data: {
        attributes: {
          id: userId,
        },
        type: 'users',
      },
    };

    postEntity(args);

    this.setState({
      sendConfirmationDone: true,
      confirmationSentTemp: true,
    });

    resourceUpdate({
      slice: 'toolboxEmployers',
      type: 'users',
      id: userId,
      resource: {
        ...user,
        attributes: {
          ...user.attributes,
          confirmationSent: true,
        },
      },
    });

    setTimeout(() => this.setState({ sendConfirmationDone: false }), 4000);
  };

  render() {
    const { confirmedAt, confirmationSent } = this.props;

    const { sendConfirmationDone, confirmationSentTemp } = this.state;

    const sendConfirmationClasses = classnames(styles.sendConfirmation, {
      [styles.sendConfirmationSent]: sendConfirmationDone,
    });

    const sendConfirmationButtonProps = {
      primary: true,
      onClick: this.handleClick,
      className: sendConfirmationClasses,
    };

    const actionText =
      confirmationSent || confirmationSentTemp
        ? 'Resend Confirmation Email'
        : 'Send Confirmation Email';

    const sendConfirmationText = sendConfirmationDone ? 'Sent!' : actionText;

    return !confirmedAt ? (
      <Button {...sendConfirmationButtonProps}>{sendConfirmationText}</Button>
    ) : null;
  }
}

export default EmployeesActionDropdown;
