import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, Redirect } from 'react-router-dom';
import queryString from 'query-string';

import { redirectHomepage } from 'utils/navHelpers/index';
import ConnectContainer from 'containers/ConnectContainer';

import duck from 'shared/store/app';

import Footer from '../CandidatesHome/components/Footer';
import Hero from './components/Hero';
import WhyJoin from './components/WhyJoin';
import Candidates from './components/Candidates';
import Profiles from './components/Profiles';
import HowItWorks from './components/HowItWorks';
import BecomeRainmaker from './components/BecomeRainmaker';

const title = 'Recruit Top Sales Talent With Rainmakers';
const metaDescription =
  'Skip the hassle of recruiting and get connected with pre-screened sales talent. Build your sales dream team today with Rainmakers.';
const canonicalUrl = `https://${process.env.HOST}/employers/`;

const EmployersPage = ({
  actions: { saveUTMInfo },
  user,
  isApproved,
  hasSeenApproved,
  login: { loginPending },
}) => {
  const { search } = useLocation();

  useEffect(() => {
    if (search) {
      saveUTMInfo({ utmInfo: queryString.parse(search) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectPath = useMemo(
    () => redirectHomepage(user, isApproved, hasSeenApproved, loginPending),
    [hasSeenApproved, isApproved, loginPending, user]
  );

  const helmetProps = {
    key: 'helmet',
    title,
    meta: [
      { name: 'description', content: metaDescription },
      { property: 'og:url', content: canonicalUrl },
      { property: 'og:title', content: title },
      { property: 'og:description', content: metaDescription },
    ],
    link: [{ rel: 'canonical', href: canonicalUrl }],
  };

  return redirectPath ? (
    <Redirect to={redirectPath} />
  ) : (
    <>
      <Helmet {...helmetProps} />
      <div style={{ width: '100%' }}>
        <Hero />
        <WhyJoin />
        <Profiles />
        <Candidates />
        <HowItWorks />
        <BecomeRainmaker />
        <Footer />
      </div>
    </>
  );
};

export default ConnectContainer(duck)(EmployersPage);
