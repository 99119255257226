const prefix = 'Rainmakers/CandidateOpportunities/';

export const OPPORTUNITIES_LOAD_STARTED = `${prefix}OPPORTUNITIES_LOAD_STARTED`;
export const OPPORTUNITIES_LOAD_DONE = `${prefix}OPPORTUNITIES_LOAD_DONE`;
export const OPPORTUNITIES_LOADING_STARTED = `${prefix}OPPORTUNITIES_LOADING_STARTED`;
export const OPPORTUNITIES_LOADING_DONE = `${prefix}OPPORTUNITIES_LOADING_DONE`;
export const OPPORTUNITIES_DELETE_STARTED = `${prefix}OPPORTUNITIES_DELETE_STARTED`;
export const OPPORTUNITIES_DELETE_DONE = `${prefix}OPPORTUNITIES_DELETE_DONE`;
export const OPPORTUNITIES_PASS_STARTED = `${prefix}OPPORTUNITIES_PASS_STARTED`;
export const OPPORTUNITIES_PASS_DONE = `${prefix}OPPORTUNITIES_PASS_DONE`;
export const OPPORTUNITIES_HIRE_STARTED = `${prefix}OPPORTUNITIES_HIRE_STARTED`;
export const OPPORTUNITIES_HIRE_DONE = `${prefix}OPPORTUNITIES_HIRE_DONE`;
export const OPPORTUNITIES_CONTACT_STARTED = `${prefix}OPPORTUNITIES_CONTACT_STARTED`;
export const OPPORTUNITIES_CONTACT_DONE = `${prefix}OPPORTUNITIES_CONTACT_DONE`;

export const PATCH_REQUISITION_CANDIDATE_STARTED = `${prefix}PATCH_REQUISITION_CANDIDATE_STARTED`;
export const PATCH_REQUISITION_CANDIDATE_DONE = `${prefix}PATCH_REQUISITION_CANDIDATE_DONE`;

export const REQUISITION_CANDIDATE_LOAD_STARTED = `${prefix}REQUISITION_CANDIDATE_LOAD_STARTED`;
export const REQUISITION_CANDIDATE_LOAD_DONE = `${prefix}REQUISITION_CANDIDATE_LOAD_DONE`;

export const RAINMAKERS_ADMIN_LOAD_STARTED = `${prefix}RAINMAKERS_ADMIN_LOAD_STARTED`;
export const RAINMAKERS_ADMIN_LOAD_DONE = `${prefix}RAINMAKERS_ADMIN_LOAD_DONE`;

export const PATCH_PRIVATE_REQUISITION_CANDIDATE_FEEDBACK_STARTED = `${prefix}PATCH_PRIVATE_REQUISITION_CANDIDATE_FEEDBACK_STARTED`;
