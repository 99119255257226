import React from 'react';
import ImageUploader from 'components/ImageUploader';

const AdminImageUploader = ({ adminId, adminImageUploadStarted, pictureUrl }) => {
  return (
    <ImageUploader
      name="Admin"
      entityId={adminId}
      imageUploadStarted={adminImageUploadStarted}
      pictureUrl={pictureUrl}
    />
  );
};

export default AdminImageUploader;
