/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import locPageMetaData, { LOCATIONS_PAGE_NYC } from 'pages/LocationsPage/locationsPageMetaData';
import jobsPageMetaData, { JOB_PAGE_AE } from 'pages/JobsPage/jobsPageMetaData';

import pic1 from './assets/1.png';
import pic2 from './assets/2.png';
import pic3 from './assets/3.png';
import pic4 from './assets/4.png';
import cta from './assets/CTA.png';

import styles from './NYCAEContent.scss';

export function NYCAEContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt="" />
      <h2>Embrace the Excitement of Tech Sales in New York City</h2>
      <p>
        New York City is one of the world’s most exciting and vibrant cities, and it needs
        <a target="_blank" rel="noopener noreferrer" href={jobsPageMetaData[JOB_PAGE_AE].localUrl}>
          {' '}
          Account Executives (AEs){' '}
        </a>
        in its tech sector. As businesses grow and adapt to new technology, AEs are needed by all
        manner of technology businesses in and around New York City to expand and manage their sales
        pipeline.
      </p>
      <p>
        For many professional salespeople, New York City is one of the best places anyone could
        live. It’s not only a major cultural center, but its diversity and cosmopolitan nature mean
        exposure to fantastic food, world-famous entertainment, art, music, and much more. So when
        the day is done, and you’ve chased your leads and closed your sales, you’ll find a lot to
        enjoy in New York City.
      </p>

      <h2>Tech Sales Careers In New York City</h2>
      <p>
        In
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={locPageMetaData[LOCATIONS_PAGE_NYC].localUrl}
        >
          {' '}
          New York City
        </a>
        , tech-related jobs have become common in all business sectors—even those not often thought
        of as “tech.” While 65% of tech-related jobs are in a technology-related field, a
        significant 35% end up in non-tech industries, such as retail, real estate, entertainment,
        and the service industry.
      </p>
      <p>
        <strong>
          Experienced Account Executives looking in New York City can find employment with a wide
          range of tech-related companies, including fintech, IT, cybersecurity, communications, POS
          technology, and tech development.
        </strong>{' '}
        The abundance of options open in NYC to AEs can lead to excellent compensation and a wealth
        of experience, and a pathway to professional advancement and personal growth.
      </p>

      <img src={pic2} alt="" />
      <h2>Responsibilities Of an Account Executive</h2>
      <p>
        Account Executives who work in tech sales market products and services to both businesses
        and consumers. In tech, this can include hardware, software, cloud storage, SaaS products,
        or related services such as cybersecurity or analytics.
      </p>
      <p>
        An AE’s manager will establish sales quotas, and the compensation will typically include
        basic pay and commission.
      </p>
      <p>
        In addition to taking leads from Sales Development Representatives (SDRs) and other sales
        team members, the AE may take on clients themselves, research potential customers, and
        engage in cold calling.{' '}
        <strong>
          The AE looks for what a client needs, then proposes solutions using his or her company’s
          products or services.
        </strong>{' '}
        Of course, success means closing the deal—but the AE’s responsibilities don’t stop there.
      </p>
      <p>
        After a sale has been completed, an effective Account Executive will check in with customer
        service departments or speak with customers personally to find out if the products are
        appropriately fulfilling the customer’s needs. If problems develop, the AE should be able to
        offer assistance. In addition, an Account Executive is expected to cultivate a positive
        rapport with the clients to retain them for future sales or expansion of services.
      </p>

      <img src={pic3} alt="" />
      <h2>What Skills Allow an Account Executive To Succeed In NYC?</h2>
      <p>
        Here are the key qualities and skills necessary to have a successful career as an Account
        Executive:
      </p>
      <ul>
        <li>
          <p>
            <strong>Previous sales experience</strong> - Employers often look for candidates with at
            least five years of sales experience in their own or a related industry. This can be as
            an SDR or an AE. A successful track record is a plus.
          </p>
        </li>
        <li>
          <p>
            <strong>Excellent communication abilities</strong> - Account Executives must feel
            comfortable making sales calls, leaving voicemails, writing emails, and giving
            presentations and pitches in person.
          </p>
        </li>
        <li>
          <p>
            <strong>Excellent listening skills</strong> - Similarly, a successful salesperson should
            also be able to listen to customers, understand the issues they need to be resolved, and
            then ask probing questions to guide them toward the most appropriate solution.
          </p>
        </li>
        <li>
          <p>
            <strong>Can be convincing without being pushy</strong> - A good account executive should
            be able to educate and teach clients about the best solutions to their challenges or
            problems and direct them toward understanding how your company can supply those
            solutions. They should make pitches more about the client’s issues than just trying to
            make a hard sell.
          </p>
        </li>
        <li>
          <p>
            <strong>Practical negotiating skills</strong> - AEs should be familiar with sales
            psychology and feel confident when negotiating important contract details.
          </p>
        </li>
        <li>
          <p>
            <strong>Confidence</strong> - Because many clients tend to be older and more experienced
            than beginning Account Executives, an AE needs to feel confident and self-assured when
            speaking.
          </p>
        </li>
        <li>
          <p>
            <strong>Resilience</strong> - Salespeople frequently encounter the word “no.”
            Prospective customers can occasionally be impolite or disagreeable in other ways. A
            successful account executive must shake off rejection and be ready to move on to the
            next call.
          </p>
        </li>
        <li>
          <p>
            <strong>Organized</strong> - Account Executives are responsible for several duties, such
            as supporting the sales department, maintaining connections with current clients, and
            converting new prospects into customers.
          </p>
        </li>
        <li>
          <p>
            <strong>Self-starting</strong> - An account executive needs to be able to follow through
            on their duties without needing constant prodding from management, whether it’s
            attending meetings, sending follow-up sales emails, or getting in touch with clients.
          </p>
        </li>
        <li>
          <p>
            <strong>Sense of curiosity</strong> - A successful Account Executives continuously seeks
            to expand their knowledge. Reading sales literature, keeping up with current market
            trends, and attending frequent training sessions to hone their skills can help with
            this.
          </p>
        </li>
        <li>
          <p>
            <strong>Self-awareness</strong> - Having a high emotional intelligence makes it easier
            to connect with prospects and empathize with their needs. Account Executives must also
            be conscious of their own personality traits—both positive and negative—to make the most
            of them.
          </p>
        </li>
        <li>
          <p>
            <strong>Teachable</strong> - Last but not least, an Account Executive needs to take
            criticism well and learn from mistakes. They should always be trying to find ways to
            perform better overall.
          </p>
        </li>
      </ul>

      <img src={pic4} alt="" />
      <h2>How Much Can an Account Executive In Tech Sales Earn In New York City?</h2>
      <p>
        New York City is one of the best-paying tech sales markets in the country—easily beating out
        other East Coast and Midwest locations.
      </p>
      <p>
        <strong>
          The average base salary for an Account Executive working in New York City is around
          $100,000.
        </strong>{' '}
        Additional compensation from on-target earnings and bonuses could result in another $83,000.
      </p>
      <p>
        A skilled Account Executive could earn more as they gain more experience and knowledge of
        the local tech industry.
      </p>

      <Link to="/apply-now" title="Apply Now">
        <img src={cta} alt="" />
      </Link>
      <h2>What Is the Typical Career Path For an Account Executive In Tech Sales In NYC?</h2>
      <p>
        Account Executives can choose from several tech sales careers as they gain more experience.
        However, before doing so, they should explore their options and decide what sort of career
        interests them most. Opportunities include developing sales strategies,
        relationship-building, client-facing communication, and management.
      </p>
      <p>
        Here are some career paths that may become available to you after a few years of working as
        an AE:
      </p>
      <ul>
        <li>Customer Success Manager</li>
        <li>Account Manager</li>
        <li>Marketing Specialist</li>
        <li>Channel Sales Manager</li>
        <li>Strategic Accounts Manager</li>
        <li>Sales Training Manager</li>
        <li>Sales Operations Manager</li>
      </ul>

      <p>
        To find out more about prospects in sales,{' '}
        <a href={`https://${process.env.HOST}/apply-now/`}>connect with Rainmakers</a> today to get
        started on your path to success.
      </p>
    </div>
  );
}
