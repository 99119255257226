import { all, fork } from 'redux-saga/effects';

import sagaNewPasswordStarted from './sagaNewPasswordStarted';
import sagaConfirmPasswordStarted from './sagaConfirmPasswordStarted';

const sagaNewPassword = function* sagaNewPassword() {
  yield all([fork(sagaNewPasswordStarted), fork(sagaConfirmPasswordStarted)]);
};

export default sagaNewPassword;
