import React from 'react';

import Input from 'components/Input';

import styles from './NameScope.scss';

const NameScope = ({ fullName: value, handleSearchInputChange: handleInputChange }) => {
  const candidateNameSearchInputProps = {
    name: 'fullName',
    value,
    placeholder: 'Search by candidate name',
    size: 'full',
    handleInputChange,
    styling: 'cohortCandidate',
    isSearch: true,
  };

  return (
    <div className={styles.candidateSearch}>
      <Input {...candidateNameSearchInputProps} />
    </div>
  );
};

export default NameScope;
