import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';

import duck from 'shared/store/app';

import ConnectContainer from 'containers/ConnectContainer';

import ReadyContainer from 'connectors/ReadyContainer';

import { DEFAULT_LOGIN_LINKS, ONBOARDING_ROUTES_MAP } from 'connectors/Login/constants';

import Layout from 'components/Layout';
import TableOverlay from 'components/TableOverlay';

const LoginSAML = (props) => {
  const {
    hasSeenApproved,
    isApproved,
    location: { state: { originalPath } = {} },
    login: { loginPending },
    user: { unauthorized, currentProfileType, currentProfile: { onboardingStatus } = {} } = {},
  } = props;

  if (unauthorized) {
    return <Redirect to={'/unauthorized'} />;
  }

  if (!loginPending && currentProfileType) {
    if (currentProfileType === 'Candidate' && !isApproved && onboardingStatus) {
      const { [onboardingStatus]: route } = ONBOARDING_ROUTES_MAP;

      if (route) {
        return <Redirect to={route} />;
      }
    }

    if (currentProfileType === 'Employee' && (!isApproved || (isApproved && !hasSeenApproved))) {
      return <Redirect to="/employer-signup-status" />;
    }

    const { [currentProfileType]: defaultPath } = DEFAULT_LOGIN_LINKS;

    const to = originalPath || defaultPath;

    return <Redirect to={to} />;
  }

  const title = 'Rainmakers Login';
  const metaDescription =
    'Rainmakers Login - Sales jobs for top sales talent. Rainmakers connects top sales candidates with top companies.';
  const canonicalUrl = `https://${process.env.HOST}/login/saml`;

  const helmetProps = {
    key: 'helmet',
    title,
    meta: [
      { name: 'description', content: metaDescription },
      { property: 'og:url', content: canonicalUrl },
      { property: 'og:title', content: title },
      { property: 'og:description', content: metaDescription },
    ],
    link: [{ rel: 'canonical', href: canonicalUrl }],
  };

  return (
    <React.Fragment>
      <Helmet {...helmetProps} />
      <ReadyContainer key="readyContainer">
        <Layout>
          <TableOverlay />
        </Layout>
      </ReadyContainer>
    </React.Fragment>
  );
};

export default ConnectContainer(duck)(LoginSAML);
