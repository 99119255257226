import { createSelector } from 'reselect';
import { entitySelectorFactory } from 'reduxStore/selectorFactories';

const selectEducationOrg = createSelector(
  entitySelectorFactory('toolboxEducationOrgs', 'educationOrganization'),
  (state, props) => {
    const {
      match: { params: { id } = {} },
    } = props || {};

    return id;
  },
  (educationOrgs, id) => {
    const { byId: { [id]: educationOrg = {} } = {} } = educationOrgs;

    return educationOrg;
  }
);

export default selectEducationOrg;
