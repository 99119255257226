import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import * as Sentry from '@sentry/browser';

import SagaManager from './app/sagas/SagaManager';
import createRootReducer from './rootReducer';

export const configureStore = ({ history, initialState, middleware = [] }) => {
  const devtools =
    typeof window !== 'undefined' &&
    typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ actionsBlacklist: [] });

  const composeEnhancers = devtools || compose;

  const sagaMiddleware = createSagaMiddleware({
    onError: (error, extra) => {
      const { sagaStack } = extra || {};

      return setImmediate(() => {
        Sentry.captureException(error, { extra: { sagaStack } });
        throw error;
      });
    },
  });

  const middlewares = [...middleware, sagaMiddleware, routerMiddleware(history)];

  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  store.runSaga = (history) => SagaManager.startSagas(sagaMiddleware, history);

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./rootReducer', () =>
        store.replaceReducer(require('./rootReducer').default)
      );
      module.hot.accept('./app/sagas/SagaManager', () => {
        SagaManager.cancelSagas(store);
        require('./app/sagas/SagaManager').default.startSagas(sagaMiddleware);
      });
    }
  }

  return store;
};

export default configureStore;
