import React from 'react';

import DropdownSelectMany from 'components/DropdownSelectMany';
import Label from 'components/Label';

import styles from '../ReqRequirement.scss';

const PreviousRoles = ({
  disabled,
  handleArrayChange,
  requisitionRequirement: { attributes: { previousRoles } = {} } = {},
  previousRolesOptions,
}) => {
  const rolesOptions = previousRolesOptions.map((role) => ({
    indent: 0,
    label: role,
    optionType: 'role',
    subCategories: [],
    value: {
      type: 'role',
      role,
    },
  }));

  const selectedRoles = previousRoles ? previousRoles.map((role) => ({ role, type: 'role' })) : [];

  const rolesInputProps = {
    className: styles.searchBarFilter,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    onChange: (value) =>
      handleArrayChange({
        target: {
          subCategories: value.subCategories,
          label: value.label,
          value: value.value,
          name: 'previousRoles',
          optionType: value.optionType,
        },
      }),
    optionsLarge: true,
    source: rolesOptions,
    name: 'previousRoles',
    key: 'previousRoles',
    isLocation: true,
    selectedValues: selectedRoles || [],
    defaultLabel: 'Click to select a title',
    disabled,
  };

  return (
    <div className={styles.rangeInputs}>
      <div className={styles.column}>
        <Label className={styles.label}>What previous roles would you accept?</Label>
        <DropdownSelectMany {...rolesInputProps} />
      </div>
    </div>
  );
};

export default PreviousRoles;
