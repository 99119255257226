import { all, fork } from 'redux-saga/effects';

import getEntitySaga from './getEntitySaga';
import getNestedEntitySaga from './getNestedEntitySaga';

const entitySaga = function* entitySaga() {
  yield all([fork(getEntitySaga), fork(getNestedEntitySaga)]);
};

export default entitySaga;
