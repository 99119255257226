import React, { useState } from 'react';
import classnames from 'classnames';

import { ComponentErrorBoundaryHOC } from 'containers/HOC';

import Button from 'components/Button';
import Block from 'components/Block';
import Bolder from 'components/Bolder';
import Content from 'components/Content';
import FontIcon from 'components/FontIcon';

import WorkHistory from './components/WorkHistory';

import styles from './ProfileSales.scss';

const whNumToShow = 4;

const ProfileSales = ({ workHistories = [], isPrint = false }) => {
  const [showAll, setShowAll] = useState(isPrint);

  const worksToDisplay = showAll ? workHistories : workHistories.slice(0, 3);
  const actualWorkHistories = worksToDisplay.filter((wh) => wh.id);

  return (
    <Block
      addWhiteBG={true}
      secondaryTitle={true}
      title={
        <Content className={styles.workHistoryTitle}>
          <Bolder>WORK HISTORY</Bolder>
        </Content>
      }
      className={styles.marginBottom}
    >
      {actualWorkHistories.map((work, id) => (
        <WorkHistory key={work.id} workHistory={work} isFirst={id === 0} isPrint={isPrint} />
      ))}
      {workHistories.length >= whNumToShow && !isPrint && (
        <div className={styles.showMoreContainer}>
          <Button
            onClick={() => setShowAll((prevState) => !prevState)}
            className={classnames(styles.showMoreSales)}
            tertiary={true}
          >
            {showAll ? 'Show less Work History' : 'Show more Work History'}{' '}
            <FontIcon
              className={styles.showMoreIcon}
              iconName={showAll ? 'caret-up' : 'caret-down'}
            />
          </Button>
        </div>
      )}
    </Block>
  );
};

export default ComponentErrorBoundaryHOC()(ProfileSales);
