import { all, fork } from 'redux-saga/effects';

// SHARED
import sagaCandidateLoadDone from './sagaCandidateLoadDone';
import sagaCandidatePatchStarted from './sagaCandidatePatchStarted';
import sagaUserPatchStarted from './sagaUserPatchStarted';

// EDIT PERSONAL
import sagaCandidateImageUploadStarted from './sagaCandidateImageUploadStarted';
import sagaCandidateFileUploadStarted from './sagaCandidateFileUploadStarted';
import sagaEmailChangeStarted from './sagaEmailChangeStarted';

// EDIT WORK HISTORY
import sagaWorkHistoryPatchStarted from './sagaWorkHistoryPatchStarted';
import sagaWorkHistoryDeleteStarted from './sagaWorkHistoryDeleteStarted';
import sagaReorderWorkHistoriesStarted from './sagaReorderWorkHistoriesStarted';

// EDIT SALES HISTORY
import sagaSalesPeriodPatchStarted from './sagaSalesPeriodPatchStarted';

// EDIT EDUCATION
import sagaEducationDeleteStarted from './sagaEducationDeleteStarted';
import sagaEducationPatchStarted from './sagaEducationPatchStarted';
import sagaReorderCandidateEducationsStarted from './sagaReorderCandidateEducationsStarted';

// EDIT RECRUITING
import sagaCandReqPatchStarted from './sagaCandReqPatchStarted';

const sagaCandidateEditor = function* sagaCandidateEditor(history) {
  yield all([
    // SHARED
    fork(sagaCandidateLoadDone),
    fork(sagaCandidatePatchStarted),
    fork(sagaUserPatchStarted),

    // EDIT PERSONAL
    fork(sagaCandidateImageUploadStarted),
    fork(sagaCandidateFileUploadStarted),
    fork(sagaEmailChangeStarted, history),

    // WORK HISTORY
    fork(sagaWorkHistoryPatchStarted),
    fork(sagaWorkHistoryDeleteStarted),
    fork(sagaReorderWorkHistoriesStarted),

    // SALES HISTORY
    fork(sagaSalesPeriodPatchStarted),

    // EDUCATION
    fork(sagaEducationPatchStarted),
    fork(sagaEducationDeleteStarted),
    fork(sagaReorderCandidateEducationsStarted),

    // RECRUITING
    fork(sagaCandReqPatchStarted),
  ]);
};

export default sagaCandidateEditor;
