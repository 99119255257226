/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import locPageMetaData, {
  LOCATIONS_PAGE_SANFRANCISCO,
} from 'pages/LocationsPage/locationsPageMetaData';
import jobsPageMetaData, { JOB_PAGE_AE } from 'pages/JobsPage/jobsPageMetaData';

import pic1 from './assets/1.png';
import pic2 from './assets/2.png';
import pic3 from './assets/3.png';
import pic4 from './assets/4.png';
import cta from './assets/CTA.png';

import styles from './SFAEContent.scss';

export function SFAEContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt="" />
      <h2>Launch Your Sales Career in San Francisco</h2>
      <p>
        The San Francisco Bay Area consistently scores first in the nation for quality of life.
        Additionally, it’s considered one of the top places for ambitious, motivated people to
        launch their sales careers. Due to the city’s abundance of sales talent, established tech
        companies and numerous startups call
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={locPageMetaData[LOCATIONS_PAGE_SANFRANCISCO].localUrl}
        >
          {' '}
          San Francisco{' '}
        </a>
        home.
      </p>
      <p>
        Successful companies go to where the skilled salespeople are rather than attempting to
        attract salespeople to them.{' '}
        <strong>
          As a result, innovative tech companies and talented salespeople make San Francisco the
          healthiest tech ecosystem in the country.
        </strong>
      </p>
      <p>
        Despite how expensive San Francisco is known to be, it nonetheless consistently ranks as
        having the highest quality of life in the US. San Francisco is well-known worldwide for its
        delectable cuisine, vibrant arts scene, and stunning homes. San Francisco is also famous for
        its public transit alternatives, and its citizens are dedicated to advancing green energy
        efforts.
      </p>
      <p>
        Additionally, the San Francisco Bay Area has a very diversified population, making it a
        thriving hub for all cultures. Residents enjoy great weather all year round, and the
        unemployment rate is significantly lower than the national average.
      </p>

      <img src={pic2} alt="" />
      <h2>San Francisco As the Definitive Tech Hub</h2>
      <p>
        The very definition of tech hub, in the eyes of many, is San Francisco. It is the home of
        Google, Facebook, Apple, Twitter, and numerous other cutting-edge tech businesses currently
        transforming the globe.
      </p>
      <p>
        The most recent advances in AI and cloud computing have been, and are being, made in San
        Francisco. How we transact business in today’s world is largely thanks to the developments
        in new technologies coming out of the Bay Area. Artificial intelligence is poised to become
        a major force in numerous fields, including the arts and medicine. On the cloud, SaaS, PaaS,
        storage, and processing make collaboration easier, communication more efficient, and
        businesses more profitable.
      </p>
      <p>
        While there is a lot of technological research and development in the US, San Francisco is
        where some of the most exciting breakthroughs occur. Businesses require exceptional sales
        personnel to help get this technology into people’s hands.
      </p>

      <img src={pic3} alt="" />
      <h2>Responsibilities Of an Account Executive</h2>
      <p>
        <a target="_blank" rel="noopener noreferrer" href={jobsPageMetaData[JOB_PAGE_AE].localUrl}>
          Account Executives{' '}
        </a>
        in San Francisco’s tech sector promote products and services to businesses and companies in
        a wide variety of industries, including medicine, manufacturing, research and development,
        finance, and education. Products and services can include hardware, software, cloud
        computing, and storage, SaaS and PaaS services, analytics, and cybersecurity.
      </p>
      <p>An Account Executive is expected to meet certain sales quotas the Sales Manager sets.</p>
      <p>
        <strong>
          Their compensation is usually determined by base pay, commissions, and bonuses after
          reaching or exceeding quotas.
        </strong>
      </p>
      <p>
        The AE may take on clients directly, research potential clients, and make cold calls in
        addition to receiving leads from Sales Development Representatives (SDRs) and other sales
        team members. The AE researches client needs and determines how best to approach them with
        solutions for specific challenges using the products or services they sell. Success is
        defined as closing the deal.
      </p>
      <p>But the AE’s duties don’t end there.</p>
      <p>
        A successful Account Executive will follow up with their customer service departments or
        speak with customers directly after a sale to determine whether the products are meeting
        their demands. If issues arise, the AE needs to be able to provide support to retain clients
        for future sales or service expansion. This requires the Account Executive to know how to
        build solid customer relationships.
      </p>

      <img src={pic4} alt="" />
      <h2>What Skills Do Companies Look For In an Account Executive In San Francisco?</h2>
      <p>
        Here are some qualities and experience needed to ensure a successful career as an Account
        Executive:
      </p>
      <ul>
        <li>
          <p>
            <strong>Experience</strong> - Employers frequently seek applicants with at least five
            years of sales experience in their own or a similar field. This could be as an AE or an
            SDR. Candidates with a proven record of success have an even greater advantage.
          </p>
        </li>
        <li>
          <p>
            <strong>Outstanding communication skills</strong> - Account Executives must be at ease
            making sales calls, leaving voicemails, composing emails, and delivering presentations
            and pitches in person. They should appear confident without being arrogant, informative
            without being condescending, and influential without being pushy.
          </p>
        </li>
        <li>
          <p>
            <strong>Excellent listening skills</strong> - In a similar vein, a great salesperson
            must be able to listen to consumers, comprehend the problems they are trying to address,
            and then probe them to find the best answer. This means more time listening than
            speaking and responding more with practical solutions than cookie-cutter pitches.
          </p>
        </li>
        <li>
          <p>
            <strong>Resilience</strong> - Salespeople constantly hear the word “no,” so they must
            develop a thick skin and be resilient—even if a potential customer is rude or
            unpleasant. A successful account executive must be able to bounce back from setbacks and
            be prepared to move on to the next call.
          </p>
        </li>
        <li>
          <p>
            <strong>Self-starting</strong> - Whether attending meetings, sending follow-up sales
            emails, or getting in touch with clients, an Account Executive needs to carry out their
            responsibilities without being urged on by management. This also means having a solid
            sense of organization and how to keep on schedule.
          </p>
        </li>
        <li>
          <p>
            <strong>Emotional intelligence</strong> - Self-awareness makes it easier to relate to
            and understand prospects’ requirements and concerns. It also makes AEs better at
            overcoming objections to focused pitches. To make the most of their personality
            features, Account Executives must know their positive and negative traits and how to
            manage them to their advantage.
          </p>
        </li>
        <li>
          <p>
            <strong>Open to feedback</strong> - Last but not least, a successful Account Executive
            must be able to take criticism well and learn from errors. They should continuously look
            for methods to improve their overall performance.
          </p>
        </li>
      </ul>

      <Link to="/apply-now" title="Apply Now">
        <img src={cta} alt="" />
      </Link>
      <h2>How Much Can an Account Executive In Tech Sales Earn In San Francisco?</h2>
      <p>
        San Francisco is one of the best-paying tech sales markets in the country—easily beating out
        other US cities.
      </p>
      <p>
        <strong>
          The average base salary for an Account Executive working in San Francisco is around
          $106,000.
        </strong>
        Additional compensation from commissions and bonuses could result in another $50,000.
      </p>
      <p>
        A skilled Account Executive could earn more as they gain more experience and knowledge of
        the local tech industry.
      </p>

      <h2>
        What Is the Typical Career Path For an Account Executive In Tech Sales In San Francisco?
      </h2>
      <p>
        As they gain more experience, Account Executives have a variety of tech sales tracks to
        select from. AEs looking to expand their careers should consider their options and choose
        the most appealing path. Opportunities exist in management, relationship-building,
        client-facing communication, creating sales strategies, and more.
      </p>
      <p>
        Here are some career paths that may become available to you after a few years of working as
        an AE:
      </p>
      <ul>
        <li>Customer Success Manager</li>
        <li>Account Manager</li>
        <li>Marketing Specialist</li>
        <li>Channel Sales Manager</li>
        <li>Strategic Accounts Manager</li>
        <li>Sales Training Manager</li>
        <li>Sales Operations Manager</li>
      </ul>

      <p>
        To find out more about prospects in sales,{' '}
        <a href={`https://${process.env.HOST}/apply-now/`}>contact Rainmakers</a> today to get
        started on your path to success.
      </p>
    </div>
  );
}
