import { call, put, take, select } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import {
  promisePostCandidateCompanyBlocks,
  promiseGetCandidateCompanyBlocks,
} from 'pages/AdminPortal/ToolboxCandidateEditorPage/promises';

import { CANDIDATE_COMPANY_BLOCKS_CREATE_STARTED } from '../actions';

import { candidateCompanyBlocksLoadDone, candidateCompanyBlocksErrorSet } from '../creators';

import { selectCandidateCompanyBlocks, selectCandidateCompanyBlocksPage } from '../selectors';

const sagaCandidateCompanyBlocksCreateStarted = function* sagaCandidateCompanyBlocksCreateStarted() {
  while (true) {
    const {
      payload: { candidateId, companyId, ownerId },
    } = yield take(CANDIDATE_COMPANY_BLOCKS_CREATE_STARTED);

    try {
      const promiseParams = {
        candidateId,
        companyId,
        ownerId,
      };

      const { candidateCompanyBlock } = yield call(
        promisePostCandidateCompanyBlocks,
        promiseParams
      );

      const candidateCompanyBlocks = yield select(selectCandidateCompanyBlocks);
      const candidateCompanyBlocksPage = yield select(selectCandidateCompanyBlocksPage);

      const newCandCompBlocks = candidateCompanyBlocks.filter(
        (ccb) => ccb.companyId !== candidateCompanyBlock.companyId
      );

      newCandCompBlocks.unshift(candidateCompanyBlock);

      const lastPromiseParams = {
        candidateId,
        page: 1,
      };

      const { total: newTotal } = yield call(promiseGetCandidateCompanyBlocks, lastPromiseParams);

      yield put(
        candidateCompanyBlocksLoadDone({
          candidateCompanyBlocks: newCandCompBlocks,
          page: candidateCompanyBlocksPage,
          total: newTotal,
        })
      );
    } catch (error) {
      handleError(error);

      const { response: { data: { errors: { owner_id: ownerId = [] } = {} } = {} } = {} } =
        error || {};

      if (ownerId[0] === 'Duplicate CandidateCompanyBlock') {
        yield put(
          candidateCompanyBlocksErrorSet({
            errors: 'A block already exists for this company.',
          })
        );
      } else {
        yield put(
          candidateCompanyBlocksErrorSet({
            errors: 'Something went wrong, please try again.',
          })
        );
      }
    }
  }
};

export default sagaCandidateCompanyBlocksCreateStarted;
