import { handleActions } from 'redux-actions';

import {
  CANDIDATE_PROFILE_PATCH_DONE,
  USER_SAVE,
  USER_REMOVE,
  USER_UPDATE,
  USER_PATCH_DONE,
  SAVE_UTM_INFO,
  SET_BACK_LINK,
  RESET_BACK_LINK,
  SET_DELAY_PROFILE_CARDS,
  LOGIN_ERROR,
  LOGOUT_ERROR,
} from 'shared/store/app/actions';

// Employee Editor
import {
  EMPLOYEE_IMG_UPLOAD_DONE,
  EMPLOYEE_UPDATE,
  EMPLOYER_PATCH_DONE,
  EMPLOYER_LOAD_DONE,
  EMPLOYER_UPDATE,
} from '../../../../pages/EmployeePortal/EmployeeEditorPages/redux/actions';

// Candidate Editor
import {
  CAND_REQ_PATCH_DONE,
  CANDIDATE_IMG_UPLOAD_DONE,
  CANDIDATE_UPDATE,
  PRIVATE_CANDIDATE_UPDATE,
  EDUCATION_PATCH_DONE,
  WORK_HISTORY_PATCH_DONE,
} from '../../../../pages/CandidatePortal/CandidateEditorPages/redux/actions';

const initialState = {};

const handleSaveUTMInfo = (state = initialState, action) => {
  const {
    payload: { utmInfo },
  } = action;

  return {
    ...state,
    utmInfo: {
      ...state.utmInfo,
      ...utmInfo,
    },
  };
};

const handleUserSave = (state = initialState, action) => {
  const {
    payload: { user },
  } = action;

  return {
    ...state,
    ...user,
    loginDone: true,
  };
};

const handleUserRemove = (_state = initialState, action) => {
  const {
    payload: { unauthorized },
  } = action;

  return { unauthorized, loginDone: true };
};

const handleLoginError = (state = initialState, action) => {
  const {
    error,
    payload: { response },
  } = action;
  const result = {
    error,
    errors: response || 'error',
    loginDone: true,
  };

  return {
    ...state,
    ...result,
  };
};

const handleLogoutError = (state, action) => {
  const {
    error,
    payload: { response },
  } = action;
  const result = {
    error,
    errors: response || 'error',
  };

  return {
    ...initialState,
    ...result,
  };
};

const handleEmployerPatchDone = (state = initialState, action) => {
  const {
    payload: { employerProfile },
  } = action;

  return {
    ...state,
    currentProfile: {
      ...state.currentProfile,
      employer: {
        ...state.currentProfile.employer,
        ...employerProfile,
      },
    },
  };
};

const handleEmployerUpdate = (state = initialState, action) => {
  const {
    payload: { newValue, name },
  } = action;

  return {
    ...state,
    currentProfile: {
      ...state.currentProfile,
      employer: {
        ...state.currentProfile.employer,
        [name]: newValue,
      },
    },
  };
};

const handleCandidateProfilePatchDone = (state = initialState, action) => {
  const {
    payload: { candidateProfile },
  } = action;

  return candidateProfile.user
    ? {
        ...state,
        ...candidateProfile.user,
        currentProfile: {
          ...state.currentProfile,
          ...candidateProfile,
        },
      }
    : {
        ...state,
        currentProfile: {
          ...state.currentProfile,
          ...candidateProfile,
        },
      };
};

// Candidate Editor

const handleUserPatchDone = (state = initialState, action) => {
  const {
    payload: { name, newValue },
  } = action;

  return {
    ...state,
    [name]: newValue,
  };
};

const handleWorkHistoryPatchDone = (state = initialState, action) => {
  const {
    payload: { newWorkHistories },
  } = action;

  return {
    ...state,
    currentProfile: {
      ...state.currentProfile,
      workHistories: newWorkHistories,
    },
  };
};

const handleEducationPatchDone = (state = initialState, action) => {
  const {
    payload: { newEducations },
  } = action;

  return {
    ...state,
    currentProfile: {
      ...state.currentProfile,
      candidateEducations: newEducations,
    },
  };
};

const handleCandReqPatchDone = (state = initialState, action) => {
  const {
    payload: { newCandReq },
  } = action;

  return {
    ...state,
    currentProfile: {
      ...state.currentProfile,
      candidateRequirement: newCandReq,
    },
  };
};

const handlePrivateCandidateUpdate = (state = initialState, action) => {
  const {
    payload: { newValue, name },
  } = action;

  return {
    ...state,
    currentProfile: {
      ...state.currentProfile,
      privateCandidateProfile: {
        ...state.currentProfile.privateCandidateProfile,
        [name]: newValue,
      },
    },
  };
};

const handleCandidateUpdate = (state = initialState, action) => {
  const {
    payload: { newValue, name },
  } = action;

  if (Array.isArray(newValue)) {
    return {
      ...state,
      currentProfile: {
        ...state.currentProfile,
        [name]: newValue,
      },
    };
  }

  if (newValue && typeof newValue === 'object') {
    return {
      ...state,
      currentProfile: {
        ...state.currentProfile,
        [name]: {
          ...state.currentProfile[name],
          ...newValue,
        },
      },
    };
  }

  return {
    ...state,
    currentProfile: {
      ...state.currentProfile,
      [name]: newValue,
    },
  };
};

const handleEmployeeUpdate = (state = initialState, action) => {
  const {
    payload: { newValue, name },
  } = action;

  if (Array.isArray(newValue)) {
    return {
      ...state,
      currentProfile: {
        ...state.currentProfile,
        [name]: newValue,
      },
    };
  }

  if (newValue && typeof newValue === 'object') {
    return {
      ...state,
      currentProfile: {
        ...state.currentProfile,
        [name]: {
          ...state.currentProfile[name],
          ...newValue,
        },
      },
    };
  }

  return {
    ...state,
    currentProfile: {
      ...state.currentProfile,
      [name]: newValue,
    },
  };
};

const handleCandidateImageUploadDone = (state = initialState, action) => {
  const {
    payload: { picture },
  } = action;

  return {
    ...state,
    currentProfile: {
      ...state.currentProfile,
      picture: {
        file_data: picture,
        large: picture,
        medium: picture,
        original: picture,
        small: picture,
        thumb: picture,
        xlarge: picture,
        xxlarge: picture,
      },
    },
  };
};

const handleEmployeeImageUploadDone = (state = initialState, action) => {
  const {
    payload: { picture },
  } = action;

  return {
    ...state,
    currentProfile: {
      ...state.currentProfile,
      picture: {
        file_data: picture,
        large: picture,
        medium: picture,
        original: picture,
        small: picture,
        thumb: picture,
        xlarge: picture,
        xxlarge: picture,
      },
    },
  };
};

const handleEmployerLoadDone = (state = initialState, action) => {
  const {
    payload: { employer },
  } = action;

  return {
    ...state,
    currentProfile: {
      ...state.currentProfile,
      employer,
    },
  };
};

const handleSetBackLink = (state = initialState, action) => {
  const { payload: backLink } = action;

  return {
    ...state,
    backLink,
  };
};

const handleResetBackLink = (state = initialState) => ({
  ...state,
  backLink: {},
});

const handleSetDelayProfileCards = (state = initialState, action) => {
  const {
    payload: { delayProfileCards },
  } = action;

  return {
    ...state,
    delayProfileCards,
  };
};

const actionHandlers = {
  [USER_SAVE]: handleUserSave,
  [USER_REMOVE]: handleUserRemove,
  [USER_PATCH_DONE]: handleUserPatchDone,
  [USER_UPDATE]: handleUserPatchDone,

  [LOGIN_ERROR]: handleLoginError,
  [LOGOUT_ERROR]: handleLogoutError,

  [CANDIDATE_PROFILE_PATCH_DONE]: handleCandidateProfilePatchDone,

  [WORK_HISTORY_PATCH_DONE]: handleWorkHistoryPatchDone,
  [EDUCATION_PATCH_DONE]: handleEducationPatchDone,
  [CAND_REQ_PATCH_DONE]: handleCandReqPatchDone,
  [CANDIDATE_UPDATE]: handleCandidateUpdate,
  [PRIVATE_CANDIDATE_UPDATE]: handlePrivateCandidateUpdate,
  [CANDIDATE_IMG_UPLOAD_DONE]: handleCandidateImageUploadDone,

  [EMPLOYEE_IMG_UPLOAD_DONE]: handleEmployeeImageUploadDone,
  [EMPLOYEE_UPDATE]: handleEmployeeUpdate,

  [EMPLOYER_UPDATE]: handleEmployerUpdate,
  [EMPLOYER_LOAD_DONE]: handleEmployerLoadDone,
  [EMPLOYER_PATCH_DONE]: handleEmployerPatchDone,

  [SAVE_UTM_INFO]: handleSaveUTMInfo,

  [RESET_BACK_LINK]: handleResetBackLink,
  [SET_BACK_LINK]: handleSetBackLink,

  [SET_DELAY_PROFILE_CARDS]: handleSetDelayProfileCards,
};

const reduceUser = handleActions(actionHandlers, initialState);

export default reduceUser;
