import React from 'react';
import classnames from 'classnames';
import v4 from 'uuid';

import Bolder from 'components/Bolder';

import styles from './FormattedObjectPills.scss';

const FormattedObjectPills = ({ className, object, hideKey = false }) => {
  const keys = Object.keys(object);

  const listClasses = classnames(styles.list, className);

  const listContent = keys.map((key) => {
    if (object[key].length > 0) {
      return (
        <li key={v4()} className={styles.mainListItem}>
          {!hideKey && <Bolder className={styles.listItem}>{key}</Bolder>}
          <ul className={styles.nestedList}>
            {object[key].map((subItem) => (
              <li key={v4()} className={styles.nestedListItem}>
                {subItem}
              </li>
            ))}
          </ul>
        </li>
      );
    }
    return (
      <li key={v4()} className={styles.mainListItem}>
        <Bolder className={styles.listItem}>{key}</Bolder>
        <ul className={styles.nestedList} />
      </li>
    );
  });

  return <ul className={listClasses}>{listContent}</ul>;
};

export default FormattedObjectPills;
