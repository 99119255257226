import React, { Component } from 'react';

import ConnectContainer from 'containers/ConnectContainer';

import Block from 'components/Block';
import TableOverlay from 'components/TableOverlay';

import duck from './redux';

import styles from './DiscourseSSOLogout.scss';

class DiscourseSSOLogout extends Component {
  componentDidMount() {
    const {
      actions: { logoutStarted },
    } = this.props;

    logoutStarted();
  }

  render() {
    const blockProps = {
      addWhiteBG: true,
    };

    return (
      <div className={styles.LoginContainer}>
        <Block {...blockProps}>
          <TableOverlay table="image" />
        </Block>
      </div>
    );
  }
}

export default ConnectContainer(duck)(DiscourseSSOLogout);
