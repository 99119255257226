import React from 'react';

import InputCheckboxMany from 'components/InputCheckboxMany';

import styles from './BenefitInputs.scss';

const BenefitInputs = ({ benefits, benefitsOptions, handleToggleArrayValue }) => {
  const benefitsInputProps = {
    label: 'Benefits',
    source: benefitsOptions,
    name: 'benefits',
    handleInputChange: handleToggleArrayValue,
    selectedValues: benefits || [],
  };

  return (
    <div className={styles.BenefitInputs}>
      <InputCheckboxMany {...benefitsInputProps} />
    </div>
  );
};

export default BenefitInputs;
