import React from 'react';

import InputBullets from 'components/InputBullets';

const ReqId = ({
  requisitionOpening: {
    attributes: { externalReqIds = [], headcount, unlimitedHeadcount, state } = {},
  } = {},
  handleAddArrayInput,
  handleAddArrayValue,
  handleDeleteArrayInput,
  handleReorderArray,
}) => {
  const headcountTest = externalReqIds.length < headcount;
  const canAddReqIds = unlimitedHeadcount || headcountTest;

  const disabled = state === 'closed';

  const reqIdInputProps = {
    label: 'Req ID (optional)',
    array: externalReqIds,
    name: 'externalReqIds',
    handleAddArrayInput,
    handleAddArrayValue,
    handleDeleteArrayInput,
    handleReorderArray,
    pad: true,
    inputDisabled: disabled,
    addDisabled: !canAddReqIds,
  };

  return <InputBullets {...reqIdInputProps} />;
};

export default ReqId;
