import { getEntity } from 'api/apiEntity';

const promiseGetAdmin = ({ adminId }) => {
  const onFulfilled = (response) => {
    const { data: { rainmakersAdmin: admin = {} } = {} } = response;

    return { admin };
  };

  const args = {
    type: 'rainmakers_admins',
    config: {
      params: {
        include_set: 'user',
        camelizeIt: true,
      },
    },
    id: adminId,
  };

  return getEntity(args).then(onFulfilled);
};

export default promiseGetAdmin;
