import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import dayjs from 'dayjs';

import ReactMarkdown from 'react-markdown';
import Button from 'components/Button';
import emptyLogo from 'shared/assets/missing-company-picture.png';

import ToolboxCompanyEditorDropdown from 'pages/AdminPortal/ToolboxCompaniesPage/components/ToolboxCompanyEditorDropdown/ToolboxCompanyEditorDropdown';

import { promiseGetCompany } from 'pages/AdminPortal/ToolboxCompanyEditorPage/promises';

import { selectUser } from 'connectors/User/redux/selectors';
import { selectIsAdmin } from 'connectors/Login/redux/selectors';

import { setImageUploadCompany } from 'shared/store/app/creators';

import { useRetryLoadData } from 'hooks/useRetryLoadData';
import { handleError } from 'utils/common';

import { useImageField } from 'hooks';

import styles from './WorkDisplay.scss';

const UNVERIFIED_DATA_TIMEOUT = 2000;
const UNVERIFIED_DATA_RETRIES = 2;

const WorkDisplay = ({
  workDisplayType = 'full',
  workHistory,
  idx,
  handleToggleEditingWork,
  setWHRef,
  isProfileLocked = false,
  actions,
  candidateId,
  showInlineCompanyEditor = false,
}) => {
  const {
    company: companyFromWH = {},
    position: positionName,
    startDate,
    endDate,
    description,
    moved,
  } = workHistory;

  const company = companyFromWH.attributes || companyFromWH;
  const { name: companyName } = company;

  const user = useSelector(selectUser);
  const isAdmin = useSelector(selectIsAdmin);
  const { currentProfile: { type: profileType } = {} } = user || {};

  const [unverifiedData, setUnverifiedData] = useState();

  const dispatch = useDispatch();

  const loadUnverifiedData = useCallback(
    () =>
      promiseGetCompany({ companyId: company.id })
        .then(({ unverifiedCompanyData }) => unverifiedCompanyData)
        .catch(handleError),
    [company.id]
  );

  useRetryLoadData({
    loadData: loadUnverifiedData,
    ifSuccessful: useCallback((data) => data, []),
    successCallback: setUnverifiedData,
    retries: UNVERIFIED_DATA_RETRIES,
    timeout: UNVERIFIED_DATA_TIMEOUT,
    enabled: profileType !== 'Candidate',
  });

  const refreshData = useCallback(() => {
    promiseGetCompany({ companyId: company.id })
      .then((updatedCompany) => {
        const { unverifiedCompanyData } = updatedCompany;

        if (!unverifiedCompanyData) {
          setUnverifiedData(undefined);

          dispatch(setImageUploadCompany(updatedCompany));

          const { entityLoadStarted, nestedEntityLoadStarted } = actions;

          if (candidateId) {
            nestedEntityLoadStarted({
              type: 'candidates',
              id: candidateId,
              nestedType: 'work_histories',
              slice: 'toolboxCandidates',
              params: {
                sortBy: 'end_date_desc_nulls_first,start_date_desc',
                includeSet: 'company_sales_periods',
              },
            });

            entityLoadStarted({
              type: 'candidate_company_blocks',
              slice: 'toolboxCandidates',
              params: {
                page: 1,
                candidateId,
                sortBy: 'created_at_desc',
                includeSet: 'company_creator',
              },
            });
          }
        }
      })
      .catch(handleError);
  }, [actions, candidateId, company.id, dispatch]);

  const startDateYear = startDate ? dayjs(startDate, 'YYYY-MM-DD').get('year') : null;
  const endDateYear = endDate ? dayjs(endDate, 'YYYY-MM-DD').get('year') : 'Present';

  const basicInfoClasses = classnames({
    [styles.movedContainer]: moved,
    [styles.basicInfoContainer]: !moved && workDisplayType !== 'full',
    [styles.basicInfoContainerFull]: !moved && workDisplayType === 'full',
  });

  const editButtonProps = {
    tertiary: true,
    className: styles.addButtonBorder,
    onClick: () => handleToggleEditingWork(idx),
  };

  const dateContent = !startDate ? (
    <div className={styles.years}>{endDate ? endDateYear : null}</div>
  ) : (
    <div className={styles.years}>
      {startDateYear} - {endDateYear}
    </div>
  );

  const editWorkButton = isProfileLocked ? null : (
    <div className={styles.mainSubtitle}>
      <Button {...editButtonProps}>Edit</Button>
    </div>
  );

  const actionDropdown = (
    <div className={styles.actionsContent}>
      {showInlineCompanyEditor && (
        <ToolboxCompanyEditorDropdown
          company={company}
          unverifiedData={
            unverifiedData ? { id: unverifiedData?.id, attributes: unverifiedData } : null
          }
          refreshCallback={refreshData}
          sliceToUpdate="toolboxCandidates"
          candidateId={candidateId}
        />
      )}
    </div>
  );

  const { imageComponent } = useImageField({
    model: { attributes: company },
    avatarClass: styles.avatarWrapper,
    emptyLogo,
  });

  return (
    <div ref={setWHRef} className={basicInfoClasses}>
      <div className={styles.companyLogo}>{imageComponent}</div>
      <div className={styles.basicInfoColumnInputs}>
        <div className={styles.companyName}>
          {isAdmin ? (
            <a
              href={`/toolbox/company/${company.id}/edit-profile`}
              target="_blank"
              className={styles.companyTitleLink}
              rel="noreferrer"
            >
              {companyName}
            </a>
          ) : (
            companyName
          )}
        </div>
        <div className={styles.positionName}>{positionName}</div>
        {dateContent}
        <ReactMarkdown children={description} className={styles.description} />
      </div>
      {actionDropdown}
      {editWorkButton}
    </div>
  );
};

export default WorkDisplay;
