import { call, put, select, cancel, take, fork } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { handleError } from 'utils/common';

import { patchNestedEntity, postNestedEntity } from 'api/apiEntity';

import { selectCandidate } from '../selectors';

import { EDUCATION_PATCH_STARTED } from '../actions';

import {
  educationPatchDone,
  candidateSaveStarted,
  candidateSaveDone,
  candidateSaveError,
} from '../creators';

const patchEducation = function* patchEducation(action) {
  const {
    payload: { educationIdx, educationId, newValue, name },
  } = action;

  const { candidateEducations: educations } = yield select(selectCandidate);

  const newEducations = educationId
    ? educations.map((edu) => {
        if (edu.id === educationId) {
          return {
            ...edu,
            [name]: newValue,
          };
        }
        return edu;
      })
    : educations.map((edu, idx) => {
        if (idx === educationIdx) {
          return {
            ...edu,
            [name]: newValue,
          };
        }
        return edu;
      });

  yield put(educationPatchDone({ newEducations }));
  yield put(candidateSaveStarted());

  yield call(delay, 750);

  const { id: candidateId, candidateEducations: updatedEducations } = yield select(selectCandidate);

  const [educationObject] = updatedEducations.filter((edu) => edu.id === educationId);

  if (
    educationObject &&
    educationObject.id === undefined &&
    educationObject.educationOrganizationId !== undefined
  ) {
    try {
      delete educationObject.educationOrganization;

      const args = {
        data: {
          type: 'candidate_education',
          attributes: {
            ...educationObject,
            candidateId,
          },
        },
        id: candidateId,
        type: 'candidates',
        nestedType: 'candidate_educations',
        config: {
          params: {
            camelizeIt: true,
          },
        },
      };

      const {
        data: { candidateEducation: newEducation },
      } = yield call(postNestedEntity, args);

      const newestEducations = educations.map((edu, idx) => {
        if (idx === educationIdx) {
          // Check if current object has set finished_at
          // If has not been set, keep as blank so that
          // currently enrolled does not get checked
          return educationObject.finishedAt === ''
            ? {
                ...newEducation,
                finishedAt: '',
              }
            : newEducation;
        }
        return edu;
      });

      yield put(educationPatchDone({ newEducations: newestEducations }));
      yield put(candidateSaveDone());
    } catch (error) {
      handleError(error);
      yield put(candidateSaveError(error));
    }
  }

  if (educationObject && educationObject.id) {
    try {
      const args = {
        data: {
          type: 'candidate_education',
          id: educationObject.id,
          attributes: {
            [name]: newValue,
          },
        },
        nestedId: educationObject.id,
        nestedType: 'candidate_educations',
        type: 'candidates',
        id: candidateId,
        config: {
          params: {
            camelizeIt: true,
          },
        },
      };

      const {
        data: { candidateEducation: newEducation },
      } = yield call(patchNestedEntity, args);

      const { candidateEducations: mostRecentEducations } = yield select(selectCandidate);

      const [newestEducationObject] = mostRecentEducations.filter((edu) => edu.id === educationId);

      const newerEducations = mostRecentEducations.map((edu) => {
        if (edu.id === newEducation.id) {
          return newestEducationObject.finishedAt === ''
            ? {
                ...newestEducationObject,
                [name]: newEducation[name],
                finishedAt: '',
              }
            : {
                ...newestEducationObject,
                [name]: newEducation[name],
              };
        }
        return edu;
      });

      yield put(educationPatchDone({ newEducations: newerEducations }));
      yield put(candidateSaveDone());
    } catch (error) {
      handleError(error);
      yield put(candidateSaveError(error));
    }
  } else {
    yield put(candidateSaveDone());
  }
};

const sagaEducationPatchStarted = function* sagaEducationPatchStarted() {
  let lastTask;
  let lastAction;
  while (true) {
    const action = yield take(EDUCATION_PATCH_STARTED);
    if (lastTask && lastAction.payload.name === action.payload.name) {
      yield cancel(lastTask);
    }
    lastAction = action;
    lastTask = yield fork(patchEducation, action);
  }
};

export default sagaEducationPatchStarted;
