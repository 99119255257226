import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect, useLocation } from 'react-router-dom';

import duck from 'shared/store/app';

import { redirectHomepage } from 'utils/navHelpers/index';
import ConnectContainer from 'containers/ConnectContainer';

import ReadyContainer from 'connectors/ReadyContainer';
import Login from 'connectors/Login';

import Layout from 'components/Layout';

const SigninPage = (props) => {
  const {
    user,
    hasSeenApproved,
    isApproved,
    login: { loginPending },
    match: {
      params: { type = '' },
    },
  } = props;

  const location = useLocation();

  const redirectPath = redirectHomepage(
    user,
    isApproved,
    hasSeenApproved,
    loginPending,
    location.state?.originalPath
  );

  const title = type ? `${type[0].toUpperCase()}${type.substr(1)} Login` : 'Login';
  const metaDescription = `Rainmakers Login ${type}. Sales jobs for top sales talent. Rainmakers connects top sales candidates with top companies.`;
  const canonicalUrl = `https://${process.env.HOST}/login/${type}/`;

  const helmetProps = {
    key: 'helmet',
    title,
    meta: [
      { name: 'description', content: metaDescription },
      { property: 'og:url', content: canonicalUrl },
      { property: 'og:title', content: title },
      { property: 'og:description', content: metaDescription },
    ],
    link: [{ rel: 'canonical', href: canonicalUrl }],
  };

  return redirectPath ? (
    <Redirect to={redirectPath} />
  ) : (
    <React.Fragment>
      <Helmet {...helmetProps} />
      <ReadyContainer key="readyContainer">
        <Layout>
          <Login />
        </Layout>
      </ReadyContainer>
    </React.Fragment>
  );
};

export default ConnectContainer(duck)(SigninPage);
