import React, { useState, useRef, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useLocation } from 'react-router-dom';

import { usePrevious } from 'hooks';
import ConnectContainer from 'containers/ConnectContainer';

import Block from 'components/Block';
import TableOverlay from 'components/TableOverlay';

import TableHeader from './components/TableHeader';
import EmptyTable from './components/EmptyTable';

import duck from './redux';

import styles from './Table.scss';

/**
 * Table Component
 * @prop header
 *     type: [{ content: 'string', headerClassname: styleObject }]
 *     description: the header prop will be used to generate a header that sets right above
 *     the content of the table and is fixed
 * @prop infiniteScrollProps
 *     type: { pageStart: number, loadMore: function, hasMore, boolean, loader: node, useWindow: boolean}
 *     description: used to load more resources for the table
 * @prop tableName
 *     type: 'string'
 *     description: used to add a unique name for scrollKey for the
 *     ScrollContainer component as well
 *     as determine what size TableOverlayo
 *     to use and used to drop columns
 * @prop emptyTextString
 *     type: 'string'
 *     description: determines what content displays when there are no resources
 *     to display in the table
 * @prop emptyActionButton
 *     type: node
 *     description: optional, adds a button to the empty table display
 * @prop isEmpty
 *     type: boolean
 *     description: tells the table component to display the empty state if true
 * @prop isLoading
 *     type: boolean
 *     description: tells the table component to display the overlay loading screen
 *     over the table
 * @prop titleContent
 *     type: node
 *     description: content to display in the Block component's header section,
 *     will display above the table header content
 * @prop tableContent
 *     type: node
 *     description: content to display within the table container, built with
 *     TableRow and TableCell components
 * @prop noBlockFlex
 *     type: boolean
 *     description: removes flex styling from the Block component, generally used
 *     when displaying blocks in a modal
 * @prop blockProps
 *     type: object
 *     description: object with props to apply to the Table's Block component, see Block
 *     component for prop defs
 */

const Table = ({
  blockProps = {},
  emptyActionButton,
  emptyTextString,
  header,
  infiniteScrollProps,
  isEmpty: isTableEmpty,
  isLoading = false,
  noBlockFlex,
  noBoxShadow,
  noFade,
  sortBy,
  tableContent,
  tableName,
  titleContent,
}) => {
  const [offset, setOffset] = useState(0);
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const prevTableContent = usePrevious(tableContent);
  const scrollParentRef = useRef();

  useEffect(() => {
    const { pathname: nextPathname } = location;
    const nextTableContent = tableContent || [];
    const { pathname: currentPathname } = prevLocation;
    const currentTableContent = prevTableContent || [];

    if (currentPathname !== nextPathname) {
      if (scrollParentRef.current) {
        scrollParentRef.current.scrollTop = 0;
      }
    }

    if (nextTableContent.length >= currentTableContent.length && currentTableContent.length >= 20) {
      setOffset(currentTableContent.length);
    } else {
      setOffset(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, tableContent]);

  const tableHeaderProps = {
    header,
    sortBy,
    tableName,
  };

  const tableHeader = header ? <TableHeader {...tableHeaderProps} /> : null;

  const infScrollProps = {
    ...infiniteScrollProps,
    className: styles.Table,
    threshold: 300,
    getScrollParent: () => scrollParentRef.current,
  };

  const emptyTableProps = {
    emptyTextString,
    emptyActionButton,
  };

  const tableOverlayProps = {
    isLoading,
    table: tableName,
  };

  const tableOverlay = <TableOverlay {...tableOverlayProps} />;

  const tableRows = tableContent
    ? tableContent.map((row, index) => {
        if (index - offset >= 0) {
          return React.cloneElement(row, { index: index - offset });
        }
        return row;
      })
    : null;

  const content = isTableEmpty ? (
    <EmptyTable {...emptyTableProps} />
  ) : (
    <div ref={scrollParentRef} className={styles.TableWrapper}>
      <InfiniteScroll {...infScrollProps}>{tableRows}</InfiniteScroll>
      {tableOverlay}
    </div>
  );

  const tableHeaderContent =
    titleContent || tableHeader ? (
      <div>
        {titleContent}
        {tableHeader}
      </div>
    ) : null;

  const tableBlockProps = {
    title: tableHeaderContent,
    boxShadow: !noBoxShadow,
    addWhiteBG: true,
    addFlex: !noBlockFlex,
    addChildFlex: true,
    addBottomFade: !noFade,
    childContainerClass: styles.TableContainer,
    ...blockProps,
  };

  return <Block {...tableBlockProps}>{content}</Block>;
};

export default ConnectContainer(duck)(Table);
