import React, { Component } from 'react';
import { promisePostCompanies } from 'pages/AdminPortal/ToolboxCandidateEditorPage/promises';

import { getNestedEntity, patchEntity, postEntity } from 'api/apiEntity';

import { handleError } from 'utils/common';
import { toggleArray } from 'utils/formUtils';

import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import Block from 'components/Block';
import Button from 'components/Button';

import WorkInputs from 'pages/CandidatePortal/CandidateEditorPages/EditWork/components/WorkInputs';
import WorkDisplay from 'pages/CandidatePortal/CandidateEditorPages/EditWork/components/WorkDisplay';

import styles from './CandidateEditorWork.scss';

class CandidateEditorWork extends Component {
  constructor(props) {
    super(props);

    const workHistories = this.getWorkHistories(props);

    this.state = {
      isPostingCompany: false,
      workHistories,
      origWorkHistories: workHistories,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.workHistories === nextProps.workHistories) return;

    const workHistories = this.getWorkHistories(nextProps);

    this.setState({
      workHistories,
      origWorkHistories: workHistories,
    });

    const firstMovedWHIdx = workHistories.findIndex((wh) => wh.moved);
    if (firstMovedWHIdx >= 0) {
      if (this[`workHistory${firstMovedWHIdx}`]) {
        this[`workHistory${firstMovedWHIdx}`].scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
      }
      setTimeout(() => {
        const { workHistories } = this.state;
        const newWorkHistories = workHistories.map((wh) => ({
          ...wh,
          moved: false,
        }));

        this.setState({
          workHistories: newWorkHistories,
          origWorkHistories: newWorkHistories,
        });
      }, 500);
    }
  }

  getWorkHistories = (props) => {
    const {
      match: { params: { id: candidateId } = {} } = {},
      workHistories: { allIds: workHistoriesAllIds = [], byId: workHistoriesById = {} } = {},
      companies: { byId: companiesById = {} } = {},
    } = props;

    const workHistoryIds = workHistoriesAllIds.filter(
      (id) => workHistoriesById[id].attributes.candidateId === candidateId
    );

    return workHistoryIds.map((id) => {
      const { relationships: { company: { data: companyIds = [] } = {} } = {}, attributes = {} } =
        workHistoriesById[id] || {};

      const { attributes: companyAttributes = {} } = companiesById[companyIds[0]] || {};

      return {
        ...attributes,
        company: companyAttributes, // TODO: copying company data into a WH should be avoided, rather use a selector to get company data from Redux
      };
    });
  };

  setWHRef = (ref, idx) => {
    this[`workHistory${idx}`] = ref;
  };

  addWorkHistory = () => {
    const { workHistories } = this.state;

    const newWorkHistories = workHistories.slice();

    const newWorkHistory = {
      startMonth: '',
      startYear: '',
      endMonth: '',
      endYear: '',
      startDate: '',
      endDate: '',
      quotasHit: '',
      stackRank: '',
      soldToCompanies: [],
      soldToCompanySizes: [],
      soldToDepartments: [],
      soldToIndustries: [],
      territories: [],
      productTypes: [],
      awards: [],
      inboundOutbound: '',
      saleCycleTime: '',
      salesPeriods: [],
      description: '',
      role: '',
      company: {
        id: '',
        picture: {
          original: '',
          thumb: '',
          small: '',
          medium: '',
          large: '',
          xlarge: '',
          xxlarge: '',
        },
      },
      position: '',
      isEditing: true,
    };

    newWorkHistories.unshift(newWorkHistory);

    this.setState({
      workHistories: newWorkHistories,
      origWorkHistories: newWorkHistories,
    });
  };

  handleCancelEditingWork = (idx) => {
    const { workHistories, origWorkHistories } = this.state;

    const origWorkHistory = origWorkHistories[idx];

    const newWorkHistories = workHistories.slice();

    if (origWorkHistory && origWorkHistory.id) {
      newWorkHistories.splice(idx, 1, { ...origWorkHistory, isEditing: false });
      this.setState({
        workHistories: newWorkHistories,
      });
    } else {
      this.handleWorkHistoryDelete({ idx });
    }
  };

  handleReorderWorkHistories = async ({ idx }) => {
    const {
      actions: { entityLoadDone },
      match: { params: { id: candidateId } } = {},
    } = this.props;

    const { isPostingCompany, reorderAttempts, workHistories } = this.state;

    if (isPostingCompany && reorderAttempts < 4) {
      setTimeout(() => {
        this.handleReorderWorkHistories({ idx });
        this.setState({ reorderAttempts: reorderAttempts + 1 });
      }, 500);
    } else {
      let { id } = workHistories[idx];

      if (id) {
        const args = {
          config: {
            params: {
              normalizeIt: true,
            },
          },
          data: {
            attributes: {
              ...workHistories[idx],
              candidateId,
            },
            id,
            type: 'workHistory',
          },
          id,
          type: 'work_histories',
        };

        try {
          await patchEntity(args);
        } catch (error) {
          handleError(error);
        }
      } else {
        const args = {
          config: {
            params: {
              normalizeIt: true,
            },
          },
          data: {
            attributes: {
              ...workHistories[idx],
              candidateId,
            },
            type: 'workHistory',
          },
          type: 'work_histories',
        };

        let allIds;
        try {
          ({ data: { entities: { workHistory: { allIds = [] } = {} } = {} } = {} } =
            await postEntity(args));
        } catch (error) {
          handleError(error);
        }

        [id] = allIds;
      }

      const getArgs = {
        type: 'candidates',
        id: candidateId,
        nestedType: 'work_histories',
        config: {
          params: {
            includeSet: 'company_sales_periods',
            sortBy: 'end_date_desc_nulls_first,start_date_desc',
            normalizeIt: true,
          },
        },
      };

      let data;
      let allNestedIds;
      try {
        ({
          data,
          data: {
            entities: { workHistory: { allNestedIds = [] } = {} },
          },
        } = await getNestedEntity(getArgs));
      } catch (error) {
        handleError(error);
      }

      const moved = idx !== allNestedIds.indexOf(id);

      if (id) {
        data.entities.workHistory.byId[id].attributes.moved = moved;
        data.entities.workHistory.byId[id].attributes.isEditing = false;
      }

      entityLoadDone({
        data,
        type: 'work_histories',
        slice: 'toolboxCandidates',
      });

      this.setState({ reorderAttempts: 0 });
    }
  };

  handleToggleEditingWork = (idx) => {
    const { workHistories } = this.state;

    if (workHistories[idx].isEditing) {
      setTimeout(() => this.handleReorderWorkHistories({ idx }), 250);
    } else {
      const newWorkHistories = workHistories.map((wh, index) => {
        if (index === idx) {
          return {
            ...wh,
            isEditing: true,
          };
        }
        return wh;
      });

      const origWorkHistory = workHistories[idx];
      const newOrigWorkHistories = workHistories.slice();
      newOrigWorkHistories.splice(idx, 1, origWorkHistory);

      this.setState({
        workHistories: newWorkHistories,
        origWorkHistories: newOrigWorkHistories,
      });
    }
  };

  handleWorkHistoryCompanyChange = ({ event, idx: workHistoryIdx }) => {
    const {
      target: { value: newValue },
    } = event;

    const { workHistories } = this.state;

    const newWorkHistories = workHistories.slice();

    newWorkHistories[workHistoryIdx].company = {
      ...newWorkHistories[workHistoryIdx].company,
      name: newValue.name,
      id: newValue.id,
    };

    newWorkHistories[workHistoryIdx].companyId = newValue.id;

    this.setState({ workHistories: newWorkHistories });
  };

  handleWorkHistoryCompanyBlur = ({ event, idx: workHistoryIdx }) => {
    const {
      target: { value: newValue },
    } = event;

    const { workHistories } = this.state;

    const { company: origCompany = {} } = workHistories[workHistoryIdx];

    if (origCompany.name !== newValue) {
      this.setState({ isPostingCompany: true });

      const onFulfilled = ({ company }) => {
        const newWorkHistories = workHistories.slice();

        newWorkHistories[workHistoryIdx].company = {
          ...newWorkHistories[workHistoryIdx].company,
          name: company.name,
          id: company.id,
        };

        newWorkHistories[workHistoryIdx].companyId = company.id;

        this.setState({ workHistories: newWorkHistories, isPostingCompany: false });
      };

      const onFail = (error) => {
        handleError(error);
        this.setState({ isPostingCompany: false });
      };

      const data = {
        type: 'company',
        attributes: {
          name: newValue.trim(),
        },
      };

      promisePostCompanies({ data }).then(onFulfilled).catch(onFail);
    }
  };

  handleWorkHistoryInputChange = ({ event, idx: workHistoryIdx }) => {
    const {
      target: { name, value: newValue },
    } = event;

    const { workHistories } = this.state;

    const newWorkHistories = workHistories.slice();

    if (newValue && name === 'insideSalesPercentage') {
      newWorkHistories[workHistoryIdx][name] = newValue / 100;

      this.setState({ workHistories: newWorkHistories });
    }

    newWorkHistories[workHistoryIdx][name] = newValue;

    this.setState({ workHistories: newWorkHistories });
  };

  handleCurrentPositionInputChange = ({ event, idx: workHistoryIdx }) => {
    const {
      target: { checked },
    } = event;

    const { workHistories } = this.state;

    const newWorkHistories = workHistories.slice();

    newWorkHistories[workHistoryIdx].endDate = checked
      ? null
      : new Date().toISOString().substring(0, 10);

    this.setState({ workHistories: newWorkHistories });
  };

  handleWorkHistoryDateInputChange = ({ name, value: newValue, idx: workHistoryIdx }) => {
    const { workHistories } = this.state;

    const newWorkHistories = workHistories.map((wh, idx) => {
      if (idx === workHistoryIdx) {
        return {
          ...wh,
          [name]: newValue,
        };
      }
      return wh;
    });

    this.setState({ workHistories: newWorkHistories });
  };

  handleWorkHistoryDelete = ({ idx }) => {
    const {
      actions: { deleteResource },
    } = this.props;
    const { workHistories } = this.state;

    const newWorkHistories = workHistories.slice();
    newWorkHistories.splice(idx, 1);

    this.setState({ workHistories: newWorkHistories });
    const { id: workHistoryId = null } = workHistories[idx] || {};

    if (workHistoryId) {
      deleteResource({
        type: 'work_histories',
        slice: 'toolboxCandidates',
        id: workHistoryId,
      });
    }
  };

  handleToggleArrayValue = ({ event, idx: workHistoryIdx }) => {
    const {
      target: { name, value: newValue },
    } = event;
    const splitName = name.split(',');

    const { workHistories } = this.state;

    const newWorkHistories = workHistories.slice();

    if (splitName.length === 2) {
      const {
        [splitName[0]]: { [splitName[1]]: arrayToToggle },
      } = workHistories[workHistoryIdx];

      const newArray = toggleArray({ array: arrayToToggle || [], value: newValue });

      newWorkHistories[workHistoryIdx][splitName[0]][splitName[1]] = newArray;

      return this.setState({ workHistories: newWorkHistories });
    }

    const { [splitName[0]]: arrayToToggle } = workHistories[workHistoryIdx];

    const newArray = toggleArray({ array: arrayToToggle || [], value: newValue });
    newWorkHistories[workHistoryIdx][splitName[0]] = newArray;

    return this.setState({ workHistories: newWorkHistories });
  };

  handleToggleSoldToIndustryArrayValue = ({ event, idx: workHistoryIdx }) => {
    const {
      target: { value },
    } = event;

    const { workHistories } = this.state;

    const newWorkHistories = workHistories.slice();

    const { soldToIndustries: arrayToToggle = [] } = workHistories[workHistoryIdx];

    if (arrayToToggle && arrayToToggle.includes(value)) {
      const newValue = arrayToToggle.filter((val) => val !== value);

      newWorkHistories[workHistoryIdx].soldToIndustries = newValue;

      return this.setState({ workHistories: newWorkHistories });
    }

    if (value === 'Many' || value === 'All') {
      const newValue = [value];

      newWorkHistories[workHistoryIdx].soldToIndustries = newValue;

      return this.setState({ workHistories: newWorkHistories });
    }

    const newValue = arrayToToggle ? arrayToToggle.slice() : [];

    newValue.push(value);

    newWorkHistories[workHistoryIdx].soldToIndustries = newValue;

    return this.setState({ workHistories: newWorkHistories });
  };

  makeBasicInfoInputs = (workHistory, idx) => {
    const {
      dispatch,
      pickerOptions = {},
      actions,
      match: { params: { id: candidateId } } = {},
    } = this.props;

    const { isEditing } = workHistory;

    const workInputsProps = {
      dispatch,
      workInputsType: 'full',
      workHistory,
      idx,
      hasRoleChangeModal: false,
      handleWorkHistoryCompanyChange: this.handleWorkHistoryCompanyChange,
      handleWorkHistoryCompanyBlur: this.handleWorkHistoryCompanyBlur,
      handleCurrentPositionInputChange: this.handleCurrentPositionInputChange,
      handleWorkHistoryDateInputChange: this.handleWorkHistoryDateInputChange,
      handleWorkHistoryInputChange: this.handleWorkHistoryInputChange,
      handleWorkHistoryDelete: this.handleWorkHistoryDelete,
      handleToggleSoldToIndustryArrayValue: this.handleToggleSoldToIndustryArrayValue,
      handleToggleArrayValue: this.handleToggleArrayValue,
      handleToggleEditingWork: this.handleToggleEditingWork,
      handleCancelEditingWork: this.handleCancelEditingWork,
      pickerOptions,
    };

    const workHistoryBlockProps = {
      key: workHistory.id || idx,
      addWhiteBG: true,
      boxShadow: true,
      addPadding: false,
      className: styles.workHistoryBlock,
    };

    const workDisplayProps = {
      setWHRef: (ref) => this.setWHRef(ref, idx),
      workHistory,
      idx,
      handleToggleEditingWork: this.handleToggleEditingWork,
      workDisplayType: 'full',
      actions,
      candidateId,
      showInlineCompanyEditor: true,
    };

    return isEditing ? (
      <Block {...workHistoryBlockProps}>
        <WorkInputs {...workInputsProps} />
      </Block>
    ) : (
      <Block {...workHistoryBlockProps}>
        <WorkDisplay {...workDisplayProps} />
      </Block>
    );
  };

  render() {
    const { workHistories = [] } = this.state;

    const hasWorkHistories = workHistories.length > 0;

    const titleBlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      addPadding: true,
      className: styles.titleBlock,
    };

    const workBlockProps = {
      noTitlePadding: true,
      largeTitleFont: true,
    };

    const inputContent = hasWorkHistories ? workHistories.map(this.makeBasicInfoInputs) : null;

    const addEmploymentButtonProps = {
      primary: true,
      onClick: this.addWorkHistory,
      disabled: workHistories.some((wh) => wh.isEditing),
    };

    return (
      <LayoutWithoutSidebar
        content={
          <div className={styles.EditorWork}>
            <Block {...titleBlockProps}>
              <div className={styles.titleContainer}>
                <div className={styles.title}>Work History</div>
                <div className={styles.saveContent}>
                  <Button {...addEmploymentButtonProps}>+ Add Employment</Button>
                </div>
              </div>
            </Block>
            <div className={styles.scrollDiv}>
              <Block {...workBlockProps}>
                <div className={styles.workInputContainer}>{inputContent}</div>
              </Block>
            </div>
          </div>
        }
      />
    );
  }
}

export default CandidateEditorWork;
