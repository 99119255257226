import { call, select, put, take } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { selectUser } from 'connectors/User/redux/selectors';

import { batchRequestLoadDone } from '../creators';

import { BATCH_REQUEST_PATCH_STARTED } from '../actions';

import { promisePatchBatchRequest } from '../../promises';

const sagaBatchRequestPatchStarted = function* sagaBatchRequestPatchStarted() {
  while (true) {
    const {
      payload: { state },
    } = yield take(BATCH_REQUEST_PATCH_STARTED);

    const {
      currentProfile: { id: candidateId },
    } = yield select(selectUser);

    try {
      const promiseParams = {
        candidateId,
        state,
      };

      const batchRequest = yield call(promisePatchBatchRequest, promiseParams);

      yield put(batchRequestLoadDone({ batchRequest }));
    } catch (error) {
      handleError(error);
    }
  }
};

export default sagaBatchRequestPatchStarted;
