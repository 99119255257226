import { call, put, select, takeLatest } from 'redux-saga/effects';

import { trackEvent } from 'utils/analytics';
import { segmentSetAvatarCandidate } from 'segment/eventNames';

import { patchEntity } from 'api/apiEntity';

import { handleError } from 'utils/common';
import { selectCandidate } from '../selectors';

import { CANDIDATE_IMG_UPLOAD_STARTED } from '../actions';

import {
  candidateImageUploadDone,
  candidateSaveStarted,
  candidateSaveDone,
  candidateSaveError,
} from '../creators';

const trackSetAvatarCandidate = () => {
  const event = segmentSetAvatarCandidate;

  const properties = {
    onboarding: false,
  };

  const eventObject = { event, properties };

  trackEvent(eventObject);
};

const patchCandidateImage = function* patchCandidateImage(action) {
  const {
    payload: { picture },
  } = action;

  yield put(candidateSaveStarted());

  const { id: candidateId } = yield select(selectCandidate);

  try {
    const args = {
      type: 'candidates',
      id: candidateId,
      data: {
        type: 'candidates',
        attributes: {
          picture: {
            file_data: picture,
          },
        },
      },
      config: {
        params: {
          camelizeIt: true,
        },
      },
    };

    const {
      data: {
        candidate: { picture: newPicture },
      },
    } = yield call(patchEntity, args);

    yield call(trackSetAvatarCandidate);

    yield put(candidateImageUploadDone({ picture: newPicture }));
    yield put(candidateSaveDone());
  } catch (error) {
    handleError(error);
    const {
      response: {
        data: { errors: { full_messages: errors = ['Please try again'] } = {} } = {},
      } = {},
    } = error || {};

    yield put(candidateSaveError(errors));
  }
};

const sagaCandidateImageUpload = function* sagaCandidateImageUpload() {
  yield takeLatest(CANDIDATE_IMG_UPLOAD_STARTED, patchCandidateImage);
};

export default sagaCandidateImageUpload;
