import React from 'react';
import classnames from 'classnames';

import Button from 'components/Button';
import FormattedMoney from 'components/FormattedMoney';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import Table from 'components/Table';
import TableCell from 'components/Table/components/TableCell';
import TableRow from 'components/Table/components/TableRow';

import styles from './ConfirmationLinks.scss';

const ConfirmationLinks = ({
  confirmationSent,
  handleCreateLink,
  handleShowPrompt,
  isLoading,
  loadMoreReqs,
  requisitionOpenings: { allIds = [], byId = {} } = {},
  titleContent,
  total,
  userId,
}) => {
  const header = [
    {
      content: 'Req Name',
      headerClassName: styles.firstLabel,
    },
    {
      content: 'Status',
      headerClassName: styles.reqStatusHeader,
    },
    {
      content: 'Locations',
      headerClassName: styles.reqLocationHeader,
    },
    {
      content: 'OTE',
      headerClassName: styles.reqOteHeader,
    },
    {
      content: '',
      headerClassName: styles.actionHeader,
    },
  ];

  const makeReqCells = (id) => {
    const {
      attributes: {
        externalName,
        headcount,
        hiredRequisitionCandidates = 0,
        locations = [],
        oteMaxCents = '',
        oteMinCents = '',
        state = '',
        unlimitedHeadcount,
      } = {},
    } = byId[id] || {};

    const locationsCut = locations.slice(0, 2);

    const locationsContent = locations
      ? locationsCut.map((loc) => (
          <div key={loc[loc.type]} className={styles.location}>
            {loc[loc.type]}
          </div>
        ))
      : null;

    const moreLocations =
      locations.length > 2 ? (
        <div className={styles.locationsCell}>{`+${locations.length - 2} more...`}</div>
      ) : null;

    const locationsCell = (
      <div className={styles.locationsCell}>
        {locationsContent}
        {moreLocations}
      </div>
    );

    const oteMaxContent =
      oteMaxCents && oteMaxCents !== 0 ? (
        <div className={styles.oteMax}>
          <FormattedMoney amount={oteMaxCents} />
        </div>
      ) : null;

    const oteMinContent = oteMinCents ? (
      <div className={styles.oteMin}>
        <FormattedMoney amount={oteMinCents} />
      </div>
    ) : null;

    const dashContent = oteMaxContent ? <span> - </span> : null;

    const oteRangeCell =
      oteMinCents !== undefined ? (
        <div className={styles.oteRangeCell}>
          {oteMinContent}
          {dashContent}
          {oteMaxContent}
        </div>
      ) : (
        <div className={styles.oteRangeCell} />
      );

    const reqNameCell = <div className={styles.reqName}>{externalName}</div>;

    const notOpenHeader =
      state && state !== 'open' ? `${state.charAt(0).toUpperCase()}${state.slice(1)}` : null;
    const unlimitedHeader = unlimitedHeadcount ? 'Published' : null;
    const openHeader = hiredRequisitionCandidates === headcount ? 'Full' : 'Published';

    const statusHeader = notOpenHeader || unlimitedHeader || openHeader;

    const headerClasses = classnames(styles.headerStatus, {
      [styles.fullStatus]: statusHeader === 'Full',
      [styles.pendingStatus]: statusHeader === 'Pending',
      [styles.openStatus]: statusHeader === 'Published',
    });

    const pendingSubstatus = state === 'pending' ? 'Not Published' : null;
    const unlimitedSubstatus = unlimitedHeadcount
      ? `${hiredRequisitionCandidates} positions filled`
      : null;
    const openSubstatus = `${hiredRequisitionCandidates}/${headcount} positions filled`;

    const subStatusContent = pendingSubstatus || unlimitedSubstatus || openSubstatus;

    const headcountCell = (
      <div className={styles.statusReqCell}>
        <div className={headerClasses}>{statusHeader}</div>
        <div className={styles.subStatus}>{subStatusContent}</div>
      </div>
    );

    const getLinkButtonProps = {
      secondary: true,
      className: styles.getLink,
      onClick: confirmationSent
        ? () => handleShowPrompt({ reqId: id })
        : () => handleCreateLink({ reqId: id, userId }),
    };

    const getLinkButton = (
      <div className={styles.actionsCell}>
        <Button {...getLinkButtonProps}>Get link</Button>
      </div>
    );

    const cells = [
      <TableCell key={1}>{reqNameCell}</TableCell>,
      <TableCell key={2}>{headcountCell}</TableCell>,
      <TableCell key={3}>{locationsCell}</TableCell>,
      <TableCell key={4}>{oteRangeCell}</TableCell>,
      <TableCell noFlex key={5}>
        {getLinkButton}
      </TableCell>,
    ];

    return cells;
  };

  const makeReqRows = (id) => (
    <TableRow id={id} rowType="adminReqRow" key={id}>
      {makeReqCells(id)}
    </TableRow>
  );

  const tableContent = allIds.map(makeReqRows);

  const infiniteScrollProps = {
    pageStart: 0,
    loadMore: loadMoreReqs,
    hasMore: total > allIds.length,
    loader: isLoading ? <div /> : <InfiniteScrollLoader />,
    useWindow: false,
  };

  const tableProps = {
    titleContent,
    tableName: 'adminRequisitions',
    tableContent,
    reqTable: true,
    infiniteScrollProps,
    header,
    isEmpty: !isLoading && allIds.length === 0,
    emptyTextString: 'No Requisitions',
    isLoading,
  };

  return <Table {...tableProps} />;
};

export default ConfirmationLinks;
