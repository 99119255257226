import React from 'react';
import classnames from 'classnames';

import styles from './InputFile.scss';

const InputFile = (props) => {
  const handleChange = (event) => {
    const { onChange } = props;

    onChange && onChange(event);
  };

  const handleClick = (event) => {
    event.target.value = null;
  };

  const { name, className, containerClassName, disabled, accept, children } = props;

  return (
    <div className={classnames(containerClassName)}>
      <input
        type={'file'}
        name={name}
        onChange={handleChange}
        onClick={handleClick}
        disabled={disabled}
        accept={accept}
        className={styles.fileInput}
        id={name}
      />
      <label htmlFor={name} className={classnames(styles.fileInputLabel, className)}>
        {children}
      </label>
    </div>
  );
};

export default InputFile;
