/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import jobsPageMetaData, {
  JOB_PAGE_AE,
  JOB_PAGE_CSM,
  JOB_PAGE_EAE,
  JOB_PAGE_MMAE,
  JOB_PAGE_SD,
  JOB_PAGE_SDR,
  JOB_PAGE_SM,
  JOB_PAGE_VP,
} from 'pages/JobsPage/jobsPageMetaData';
import marketsJobsPageMetaData, {
  MK_JB_SDR_SF_PAGE,
  MK_JB_AE_SF_PAGE,
} from 'pages/MarketsJobsPage/marketsJobsPageMetaData';

import { getAltName } from 'utils/urlUtils';

import pic1 from './assets/1-San-Francisco-Tech-Recruiters.png';
import pic2 from './assets/2-San-Francisco-Sales-Recruiter.png';
import pic3 from './assets/3-San-Francisco-Recruiting-Agency.png';
import pic4 from './assets/4-Contact-Sales-Recruiters.png';

import styles from './SanFranciscoContent.scss';

export function SanFranciscoContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt={getAltName(pic1)} />

      <h2>Tech Sales Opportunities In San Francisco</h2>

      <p>
        In terms of quality of life, the San Francisco Bay Area routinely ranks first in the
        country. It’s also regarded as one of the best locations for ambitious, driven software
        salespeople to launch and advance their careers. So it’s no wonder San Francisco is
        considered one of the leading cities in the world for tech innovation.
      </p>

      <p>
        Point of fact, many of the top SaaS companies in the world and many startups make San
        Francisco their home due to the city’s wealth of sales expertise. Instead of trying to get
        salespeople to come to them, these successful businesses have gone to where talented
        salespeople already exist.
      </p>

      <p>
        Together, tech companies and sales talent have helped make San Francisco the top tech
        environment in the country.
      </p>

      <h2>Quality of Life</h2>

      <p>
        Although San Francisco is the second most expensive city to live in; it often ranks as
        having the highest quality of life in the country. This city is known worldwide for its
        excellent cuisine, lively arts, and beautiful houses. In addition, residents are committed
        to furthering green energy initiatives, and San Francisco is known for its public
        transportation options.
      </p>

      <p>
        The San Francisco Bay Area also boasts a highly diverse population, making it an essential
        cultural nexus. The unemployment rate is well below the national average, and residents
        enjoy lovely weather year-round.
      </p>

      <h2>Tech and Financial Growth In San Francisco</h2>

      <p>
        San Francisco’s economy will continue to be driven by the IT sector for some time. Tech is
        anticipated to surpass all other industries in the area in growth, increasing demand for
        SaaS sales representatives.
      </p>

      <p>
        Growth isn’t limited to the tech sector. As home to the nation’s most prominent venture
        capital industry, San Francisco has become the second-largest financial center in the US.
        While some startups have moved away from the Bay Area due to the high cost of living, San
        Francisco is still where most get their start.
      </p>

      <img src={pic2} alt={getAltName(pic2)} />

      <h2>Sales Opportunities In San Francisco</h2>

      <p>
        There are positions open at all levels of tech sales in San Francisco. Rainmakers matches
        top-quality candidates with businesses best suited for their talents and requirements.
        Everybody wins.
      </p>

      <h3>Sales Development Representative</h3>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_SDR].localUrl} target="_blank" rel="noreferrer">
          Sales Development Representatives (SDRs)
        </a>{' '}
        are valued team members of SaaS sales teams. An SDR functions mainly as an inside sales
        professional concentrating on cold calling to build sales pipelines for the rest of the
        team. Their role is usually an entry-level one that doesn’t require extensive experience and
        is an excellent jumping-off point for an extended successful sales career.
      </p>

      <h3>Account Executive</h3>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_AE].localUrl} target="_blank" rel="noreferrer">
          Account Executives (AE)
        </a>{' '}
        close deals with new clients, address current clients’ needs and attract new ones. A
        significant portion of the pay is based on commissions, with AEs striving to meet set quotas
        and revenue targets.
      </p>

      <h3>Mid-Market Account Executive</h3>

      <p>
        This is the perfect next career step for sales professionals who are enthusiastic about
        their careers and looking to take them to the next level.{' '}
        <a href={jobsPageMetaData[JOB_PAGE_MMAE].localUrl} target="_blank" rel="noreferrer">
          Mid-Market Account Executives
        </a>
        manage training, support sales growth, and supply services using SaaS/cloud technologies
        while facilitating sales, closing deals, and maintaining strong client relationships. Their
        primary market for sales expansion is middle-market companies.
      </p>

      <h3>Enterprise Account Executive</h3>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_EAE].localUrl} target="_blank" rel="noreferrer">
          Enterprise Account Executives
        </a>{' '}
        work for various industries including manufacturers, telecommunications businesses, software
        companies, and retail chains. This position offers significant earning potential due to the
        level of responsibilities and large deal sizes involved. Businesses require well-prepared,
        devoted, and capable Enterprise Account Managers to sustain good sales relationships and
        foster growth and innovation on both sides.
      </p>

      <img src={pic3} alt={getAltName(pic3)} />

      <h3>Customer Success Manager</h3>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_CSM].localUrl} target="_blank" rel="noreferrer">
          Customer Success Managers (CSMs)
        </a>{' '}
        aim to develop strong, long-lasting client relationships. After closing a deal, businesses
        must prove their products’ value. Customer Success Managers advertise products and services
        to help customers succeed and return. While considered a relatively new form of sales role,
        nearly half of high-tech companies employ Customer Success Managers to their benefit.
      </p>

      <h3>Sales Director</h3>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_SD].localUrl} target="_blank" rel="noreferrer">
          Sales Directors
        </a>{' '}
        oversee all relevant tasks completed by the sales team, strategically develop and present
        new goods and services, and ensure that the business maintains high standards for customer
        service. In addition to training, Sales Directors also lead the sales team and make sure
        they meet their objectives. Therefore, they must possess strong organizational, leadership,
        and sales experience.
      </p>

      <h3>Sales Manager</h3>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_SM].localUrl} target="_blank" rel="noreferrer">
          Sales Managers
        </a>{' '}
        oversee daily sales operations and direct and supervise sales staff. They develop sales
        strategies, set and track sales goals, and keep an eye on the bottom line. A sales manager
        may be responsible for different things depending on the business. Typical duties include
        acquiring sales talent, training, coaching, defining the sales plan, and monitoring sales
        activity.
      </p>

      <h3>Vice President of Sales</h3>

      <p>
        The{' '}
        <a href={jobsPageMetaData[JOB_PAGE_VP].localUrl} target="_blank" rel="noreferrer">
          VP of Sales's
        </a>{' '}
        responsibilities can vary. They frequently act as strategists, supervisors, and mentors, but
        a VP of Sales must also carry out responsibilities focused on meeting quotas and advancing
        the performance of the sales department. This can include creating plans and sales
        strategies, managing sales teams, supervising budgets, acquiring sales talent, and more.
      </p>

      <Link to="/apply-now" title="Apply Now">
        <img src={pic4} alt={getAltName(pic4)} />
      </Link>

      <h2>Sales Career Opportunities at Rainmakers</h2>

      <p>
        Careers in tech sales have the potential to pay well and allow for significant personal
        development. In addition, many job opportunities provide good paths for promotion for those
        prepared to put in the time and effort necessary to surpass expectations and demonstrate
        their value.
      </p>

      <p>
        Every role, from Sales Development Rep to VP in Sales, is essential to the sales funnel of
        any successful company. There is no reason why anyone couldn’t succeed if they work hard,
        possess the necessary skills, and are committed to the job at hand.
      </p>

      <p>
        To learn more about these excellent opportunities,{' '}
        <a href="/apply-now/">contact Rainmakers</a> today.
      </p>

      <h2>Sales Career Opportunities at Rainmakers</h2>
      <p>
        Careers in tech sales have the potential to pay well and allow for significant personal
        development. In addition, many job opportunities provide good paths for promotion for those
        prepared to put in the time and effort necessary to surpass expectations and demonstrate
        their value.
      </p>
      <p>
        To find out about the latest tech sales jobs that are in high demand in San Francisco, take
        a closer look at these current opportunities.
      </p>
      <ul>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={marketsJobsPageMetaData[MK_JB_AE_SF_PAGE].localUrl}
          >
            {' '}
            Account Executive Jobs
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={marketsJobsPageMetaData[MK_JB_SDR_SF_PAGE].localUrl}
          >
            {' '}
            Sales Development Representative Jobs
          </a>
        </li>
      </ul>
      <p>
        Every role, from Sales Development Rep to VP in Sales, is essential to the sales funnel of
        any successful company. There is no reason why anyone couldn’t succeed if they work hard,
        possess the necessary skills, and are committed to the job at hand.
      </p>

      <p>
        To learn more about these excellent opportunities,{' '}
        <a href={`https://${process.env.HOST}/apply-now/`}>contact Rainmakers</a> today.
      </p>
    </div>
  );
}
