import cloneDeep from 'lodash.clonedeep';
import validator from 'validator';

import { handleError } from 'utils/common';

const Validator = {
  // args is an array of strings to compare the newValue against
  // return true if newValue does not equal any of the strings to compare
  doesNotEqual({ newValue, args }) {
    if (newValue === null) return null;

    return args.every((strToCompare) => {
      const testRegex = new RegExp(strToCompare, 'g');
      return !testRegex.test(newValue);
    });
  },

  doesEqual({ newValue, args }) {
    if (newValue === null) return null;

    return newValue === args;
  },

  isNotNull({ newValue }) {
    return newValue !== null;
  },

  isNotEmpty({ newValue }) {
    if (newValue === null) return null;

    const emptyTest = /\S+/;
    return emptyTest.test(newValue);
  },

  isEmail({ newValue }) {
    if (!newValue) return null;

    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(newValue.trim());
  },

  isUrl({ newValue }) {
    if (!newValue) return null;

    // const urlRegex = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;

    return validator.isURL(newValue);
  },

  isPassword({ newValue }) {
    const passwordRegex = /^(?=.*\d)[0-9a-zA-Z`~!@#$%^&*()\-_=+,<.>\/?‘“;:\[{\]}\\|]{8,}$/;

    return passwordRegex.test(newValue);
  },

  isPhoneNumber({ newValue }) {
    const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

    return phoneRegex.test(newValue);
  },

  isNumeric({ newValue }) {
    if (newValue === null || newValue === '' || newValue === undefined) return null;

    const numericTest = /^(?:[1-9]\d*|\d)$/;
    return this.isNotEmpty({ newValue }) && numericTest.test(newValue);
  },

  isMoney({ newValue }) {
    if (newValue === null || newValue === '' || newValue === undefined) return null;

    const moneyTest = /^(0|[1-9][0-9]{0,2}(?:(,[0-9]{3})*|[0-9]*))(\.[0-9]+){0,1}$/;

    return this.isNotEmpty({ newValue }) && moneyTest.test(newValue);
  },

  minLength({ newValue, args }) {
    if (newValue === null) return null;

    if (this.isNotEmpty({ newValue }) === false) return null;

    return newValue.length >= args;
  },

  hasXWords({ newValue, args }) {
    if (newValue === null) return null;

    if (this.isNotEmpty({ newValue }) === false) return null;

    const splitValue = newValue.split(' ');
    return splitValue.length >= args && splitValue[args - 1].length > 0;
  },

  hasANumber({ newValue }) {
    if (newValue === null) return null;

    if (this.isNotEmpty({ newValue }) === false) return null;

    const numRegex = /\d+/g;
    return numRegex.test(newValue);
  },

  hasXElements({ newValue, args }) {
    if (newValue === null || newValue === undefined) return null;

    return newValue.length >= args;
  },

  isGreaterThanX({ newValue, args }) {
    if (newValue === null || newValue === undefined) return null;

    return !this.isNumeric({ newValue }) || newValue > args;
  },

  isLessThanX({ newValue, args }) {
    if (newValue === null || newValue === undefined) return null;

    return !this.isNumeric({ newValue }) || newValue < args;
  },

  isMoneyGreaterThanX({ newValue, args }) {
    if (newValue === null || newValue === undefined) return null;

    return !this.isNumeric({ newValue }) || newValue >= args;
  },

  isMoneyLessThanX({ newValue, args }) {
    if (newValue === null || newValue === undefined) return null;

    return !this.isNumeric({ newValue }) || newValue < args;
  },

  checkAllErrors({ errorObject, objectToCheck }) {
    const newErrorObject = cloneDeep(errorObject);

    let isValid = true;

    const inputsToCheck = Object.keys(objectToCheck);

    inputsToCheck.forEach((inputName) => {
      const inputHasRules = newErrorObject[inputName];

      if (inputHasRules) {
        try {
          const rulesForInput = Object.keys(newErrorObject[inputName]);

          rulesForInput.forEach((rule) => {
            let testResult = null;
            const ruleArray = newErrorObject[inputName][rule];
            const argsForRule = ruleArray.length > 1 ? ruleArray[1] : null;
            const valueToCheck = objectToCheck[inputName];
            testResult = Validator[rule]
              ? Validator[rule]({ newValue: valueToCheck, args: argsForRule })
              : ruleArray[0];

            if (testResult === false) {
              isValid = false;
            }

            newErrorObject[inputName][rule][0] = testResult;
          });
        } catch (error) {
          handleError(error);
        }
      }
    });

    return { inputErrors: newErrorObject, isValid };
  },

  checkErrors({ newValue, name: inputName, errorObject }) {
    const newErrorObject = cloneDeep(errorObject);

    let isValid = true;
    const inputHasRules = newErrorObject[inputName];

    if (inputHasRules) {
      const rulesForInput = Object.keys(newErrorObject[inputName]);

      rulesForInput.forEach((rule) => {
        const ruleArray = newErrorObject[inputName][rule];
        const argsForRule = ruleArray.length > 1 ? ruleArray[1] : null;

        ruleArray[0] = Validator[rule]
          ? Validator[rule]({ newValue, args: argsForRule })
          : ruleArray[0];

        if (ruleArray[0] === false) {
          isValid = false;
        }
      });
    }

    return { inputErrors: newErrorObject, isValid };
  },
};

export default Validator;
