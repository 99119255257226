import { getCandidates } from 'api/apiCandidates';

const promiseGetCandidate = ({
  candidateId,
  contextOrigin,
  includeSet = 'search',
  normalizeIt = false,
}) => {
  const config = {
    params: {
      includeSet,
      contextOrigin,
      normalizeIt,
    },
  };

  const onFulfilled = ({
    data: { entities: { candidate: { byId = {}, allIds = [] } = {} } = {} } = {},
  }) => byId[allIds[0]].attributes || {};

  const getCandidatesArgs = {
    candidateId,
    config,
  };

  return getCandidates(getCandidatesArgs).then(onFulfilled);
};

export default promiseGetCandidate;
