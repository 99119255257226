import React from 'react';
import classnames from 'classnames';

import Dropdown from 'components/Dropdown';
import ButtonGroup from 'components/ButtonGroup';
import DropdownUsRegions from 'components/DropdownUsRegions';

import styles from './SearchBar.scss';

const INPUT_COMPONENTS = {
  ButtonGroup,
  Dropdown,
  DropdownUsRegions,
};

const SearchBar = ({
  handleFilterUpdate,
  handleAddCheckboxPickerMoreFilter,
  handleRemove,
  className,
  candidateSearchOptions = [],
  locations,
  highlightLocations,
  disabled = false,
  ...otherProps
}) => {
  const classes = classnames(styles.SearchBar, className);

  const dropDownCommonProps = {
    className: styles.searchBarFilter,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    onChange: handleFilterUpdate,
    searchFilter: true,
    optionsLarge: true,
    handleRemove,
  };

  const searchKeys = Object.keys(candidateSearchOptions);

  const propsArray = searchKeys.map((key) => {
    const options = candidateSearchOptions[key];

    const source = options.map((option) => ({
      trackingHash: option.trackingHash,
      label: option.name,
      value: option.id,
      indent: option.indent,
      id: option.id,
    }));

    const defaultValue =
      key === 'experience' ? 'Sales Experience' : `${key.charAt(0).toUpperCase()}${key.slice(1)}`;

    const value = source
      .map((src) => `${src.value}`)
      .includes(`${otherProps[key] ? otherProps[key][0] : null}`)
      ? [otherProps[key]]
      : null;

    if (key === 'locations') {
      const locSource = options.reduce((acc, curr) => {
        let subOpts = [];
        let subValues = [];
        if (curr.subcategories) {
          subValues = curr.subcategories.map((subOpt) => ({
            id: subOpt.id,
            label: subOpt.name,
            optionType: subOpt.type,
            queries: subOpt.queries,
            title: key,
            trackingHash: subOpt.trackingHash,
            type: 'checkbox_picker',
          }));

          const parent = {
            label: curr.name,
            optionType: curr.type,
            queries: curr.queries,
            title: key,
            subcategories: subValues,
            trackingHash: curr.trackingHash,
            type: 'checkbox_picker',
          };

          subOpts = curr.subcategories.map((subOpt) => ({
            indent: 1,
            label: subOpt.name,
            id: subOpt.id,
            optionType: subOpt.type,
            parent,
            trackingHash: subOpt.trackingHash,
            value: subOpt.id,
          }));
        }

        const allOpts = Array.prototype.concat(
          [
            {
              indent: 0,
              label: curr.name,
              optionType: curr.type,
              id: curr.id,
              subcategories: subValues,
              trackingHash: curr.trackingHash,
              value: curr.id,
            },
          ],
          subOpts
        );

        return acc.concat(allOpts);
      }, []);

      return {
        className: styles.searchBarFilter,
        defaultIcon: 'caret-down',
        activeIcon: 'caret-up',
        onChange: (value) =>
          handleAddCheckboxPickerMoreFilter({
            title: key,
            type: 'checkbox_picker',
            ...value,
          }),
        optionsLarge: true,
        source: locSource,
        defaultLabel: 'Locations',
        selectedValues: locations,
        name: key,
        key,
        type: 'DropdownUsRegions',
        highlight: highlightLocations,
      };
    }

    return key === 'role'
      ? {
          ...dropDownCommonProps,
          name: key,
          value,
          source,
          type: 'ButtonGroup',
          key: value,
        }
      : {
          ...dropDownCommonProps,
          defaultValue,
          name: key,
          value,
          source,
          key,
          hasRemove: key !== 'role' && otherProps[key].length > 0,
          hasTitleInDropdown: key === 'role',
          hasTitleContent: key === 'role' ? 'Desired Role' : null,
          type: 'Dropdown',
        };
  });

  const topDropdowns = propsArray.slice(0, 1).map((props, idx) => {
    const InputComponent = INPUT_COMPONENTS[props.type];
    return <InputComponent key={idx} {...props} disabled={disabled} />;
  });

  const bottomDropdowns = propsArray.slice(1).map((props, idx) => {
    const InputComponent = INPUT_COMPONENTS[props.type];
    return <InputComponent key={idx} {...props} disabled={disabled} />;
  });

  return (
    <div className={classes}>
      <div className={styles.SearchBarRow}>{topDropdowns}</div>
      <div className={styles.SearchBarRow}>{bottomDropdowns}</div>
    </div>
  );
};

export default SearchBar;
