import React from 'react';

import Button from 'components/Button';

import ConnectContainer from 'containers/ConnectContainer';

import duck from '../../../../../redux';

import styles from './ActionDropdown.scss';

const ActionDropdown = ({
  actions: { candidateCompanyBlocksDeleteStarted },
  candidateCompanyBlockId,
}) => {
  const unblockButtonProps = {
    onClick: () => candidateCompanyBlocksDeleteStarted({ candidateCompanyBlockId }),
    secondary: true,
    className: styles.button,
  };

  return <Button {...unblockButtonProps}>Unblock</Button>;
};

export default ConnectContainer(duck)(ActionDropdown);
