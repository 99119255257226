import React from 'react';

import styles from './HowItWorks.scss';

const HowItWorks = () => {
  return (
    <div id="How-it-works" className={styles.HowItWorks}>
      <div className={styles.Container}>
        <h3 className={styles.Title}>How It Works</h3>

        <div className={styles.Column}>
          <div className={styles.Image}>
            <img src={require('../../assets/img_define_role.png')} alt="Share your sales history" />
          </div>
          <h4 className={styles.StepTitle}>1. Define the role</h4>
        </div>

        <div className={styles.Column}>
          <div className={styles.Image}>
            <img
              src={require('../../assets/img_match_with_talent.png')}
              alt="Set your requirements"
            />
          </div>
          <h4 className={styles.StepTitle}>2. Get matched with talent</h4>
        </div>

        <div className={styles.Column}>
          <div className={styles.Image}>
            <img src={require('../../assets/img_hire.png')} alt="Companies will approach you" />
          </div>
          <h4 className={styles.StepTitle}>
            3. Hire the <span className={styles.Underline}>right</span> candidate
          </h4>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
