const errors = {
  name: {
    isNotEmpty: [null],
  },
  email: {
    isEmail: [null],
  },
};

export default errors;
