import { createAction } from 'redux-actions';

import { SHOW_MODAL, NEXT_MODAL } from 'shared/store/app/actions';

import {
  EMPLOYEE_PATCH_STARTED,
  EMPLOYER_PATCH_STARTED,
} from 'pages/EmployeePortal/EmployeeEditorPages/redux/actions';

import {
  ADD_REQUESTED_CANDIDATE,
  CLEAR_REQUESTED_CANDIDATES,
  BOOKMARKS_ADD_STARTED,
  BOOKMARKS_ADD_DONE,
  BOOKMARKS_REMOVE_STARTED,
  BOOKMARKS_REMOVE_DONE,
  PROFILE_BOOKMARK_ADD,
  PROFILE_BOOKMARK_REMOVE,
  RESULTS_LOAD_STARTED,
  RESULTS_LOAD_DONE,
  RESULTS_RESET,
  RESULTS_UPDATE,
  RESULTS_LOADING_STARTED,
  RESULTS_LOADING_DONE,
  ADMIN_SEARCH_CANDIDATE_HIDE_STARTED,
  ADMIN_SEARCH_CANDIDATE_HIDE_DONE,
  SEARCH_CANDIDATE_HIDE_STARTED,
  SEARCH_CANDIDATE_HIDE_DONE,
  SEARCH_CANDIDATE_UNDO_HIDE_STARTED,
  SEARCH_CANDIDATE_UNDO_HIDE_DONE,
  REQUEST_SENT_DONE,
  REQUEST_ERROR_SET,
  REMOVE_CANDIDATES_FROM_SEARCH,
  SET_EMULATED_EMPLOYER,
  RESET_EMULATED_EMPLOYER,
  SET_SEARCH_PARAMS,
  RESET_SEARCH_PARAMS,
  SAVE_SEARCH_OPTIONS,
} from '../actions';

export const saveSearchOptions = createAction(SAVE_SEARCH_OPTIONS);

export const addRequestedCandidate = createAction(ADD_REQUESTED_CANDIDATE);
export const clearRequestedCandidates = createAction(CLEAR_REQUESTED_CANDIDATES);

export const employeePatchStarted = createAction(EMPLOYEE_PATCH_STARTED);
export const employerPatchStarted = createAction(EMPLOYER_PATCH_STARTED);

export const showModal = createAction(SHOW_MODAL);
export const nextModal = createAction(NEXT_MODAL);

export const bookmarksAddStarted = createAction(BOOKMARKS_ADD_STARTED);
export const bookmarksAddDone = createAction(BOOKMARKS_ADD_DONE);
export const bookmarksRemoveStarted = createAction(BOOKMARKS_REMOVE_STARTED);
export const bookmarksRemoveDone = createAction(BOOKMARKS_REMOVE_DONE);

export const profileBookmarkAdd = createAction(PROFILE_BOOKMARK_ADD);
export const profileBookmarkRemove = createAction(PROFILE_BOOKMARK_REMOVE);

export const resultsLoadStarted = createAction(RESULTS_LOAD_STARTED);
export const resultsLoadDone = createAction(RESULTS_LOAD_DONE);
export const resultsReset = createAction(RESULTS_RESET);
export const resultsUpdate = createAction(RESULTS_UPDATE);
export const resultsLoadingStarted = createAction(RESULTS_LOADING_STARTED);
export const resultsLoadingDone = createAction(RESULTS_LOADING_DONE);

export const searchCandidateUndoHideStarted = createAction(SEARCH_CANDIDATE_UNDO_HIDE_STARTED);
export const searchCandidateUndoHideDone = createAction(SEARCH_CANDIDATE_UNDO_HIDE_DONE);
export const searchCandidateHideStarted = createAction(SEARCH_CANDIDATE_HIDE_STARTED);
export const searchCandidateHideDone = createAction(SEARCH_CANDIDATE_HIDE_DONE);
export const adminSearchCandidateHideStarted = createAction(ADMIN_SEARCH_CANDIDATE_HIDE_STARTED);
export const adminSearchCandidateHideDone = createAction(ADMIN_SEARCH_CANDIDATE_HIDE_DONE);

export const requestSentDone = createAction(REQUEST_SENT_DONE);
export const requestErrorSet = createAction(REQUEST_ERROR_SET);

export const removeCandidatesFromSearch = createAction(REMOVE_CANDIDATES_FROM_SEARCH);

export const setEmulatedEmployer = createAction(SET_EMULATED_EMPLOYER);
export const resetEmulatedEmployer = createAction(RESET_EMULATED_EMPLOYER);

export const setSearchParams = createAction(SET_SEARCH_PARAMS);
export const resetSearchParams = createAction(RESET_SEARCH_PARAMS);
