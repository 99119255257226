import { getEntity } from 'api/apiEntity';
import { makeLikeParam } from 'utils/paramUtils';

const promiseGetTemplates = ({ page, searchName, sortBy, employeeId }) => {
  const params = {
    page,
    sort_by: sortBy,
  };

  if (searchName) {
    params.name = makeLikeParam(searchName);
  }

  if (employeeId) {
    params.employee_id = employeeId;
  }

  const onFulfilled = (response) => {
    const {
      data: {
        message_templates: templates,
        meta: {
          pagination: { current_page: page, total_count: total },
        },
      },
    } = response;

    return { templates, page, total };
  };

  const args = {
    type: 'message_templates',
    config: {
      params,
    },
  };

  return getEntity(args).then(onFulfilled);
};

export default promiseGetTemplates;
