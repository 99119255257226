/* eslint react/prefer-stateless-function: "off" */
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

const UserAuthHOC = () => (WrappedComponent) => {
  const _UserAuthHOC = (props) => {
    const { logoutPending = false, user: { currentProfileType } = {} } = props;

    const location = useLocation();
    const { search } = location;

    if (!currentProfileType && !logoutPending) {
      const to = {
        pathname: '/login/candidate',
        search,
        state: { originalPath: location },
      };

      return <Redirect to={to} />;
    }

    return <WrappedComponent {...props} />;
  };

  return _UserAuthHOC;
};

export default UserAuthHOC;
