import React, { Fragment } from 'react';

import { ComponentErrorBoundaryHOC } from 'containers/HOC';

import Button from 'components/Button';

import styles from '../DashboardButtons/DashboardButtons.scss';

export const MatchButtons = ({ candidateSuggestionId, patchResource, suggested }) => {
  const upVoteProps = {
    onClick: () =>
      patchResource({
        id: candidateSuggestionId,
        attributes: {
          suggested: true,
        },
        normalizeIt: true,
        requiredFields: [],
        slice: 'toolboxAdmins',
        type: 'candidate_suggestions',
      }),
    primary: true,
    className: styles.makeOfferButton,
    fakeDisabled: suggested === false,
  };

  const upVoteButton = <Button {...upVoteProps}>Yah</Button>;

  const downVoteProps = {
    onClick: () =>
      patchResource({
        id: candidateSuggestionId,
        attributes: {
          suggested: false,
        },
        normalizeIt: true,
        requiredFields: [],
        slice: 'toolboxAdmins',
        type: 'candidate_suggestions',
      }),
    className: styles.makeOfferButton,
    warning: true,
    fakeDisabled: suggested === true,
  };

  const downVoteButton = <Button {...downVoteProps}>Nah</Button>;

  return (
    <Fragment>
      {upVoteButton}
      {downVoteButton}
    </Fragment>
  );
};

export default ComponentErrorBoundaryHOC()(MatchButtons);
