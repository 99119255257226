import { createSelector } from 'reselect';
import { getCurrentUser } from 'shared/store/app/selectors';

const selectEmployer = createSelector(getCurrentUser, (user = {}) => {
  if (user && user.currentProfile && user.currentProfile.employer) {
    return user.currentProfile.employer;
  }
  return {};
});

export default selectEmployer;
