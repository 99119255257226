import React from 'react';
import classnames from 'classnames';

import styles from './Block.scss';

/**
 * Block Component
 * @prop className
 *     type: styleObject
 *     description: applies the provided className to the main Block element
 * @prop childContainerClass
 *     type: styleObject
 *     description: applies the provided className to the Block's child container
 * @prop titleClass
 *     type: styleObject
 *     description: applies the provided className to the Block's title container
 * @prop children
 *     type: node
 *     description: content to display in the Block's child container
 * @prop title
 *     type: node
 *     description: content to display in the Block's title container
 * @prop boxShadow
 *     type: boolean
 *     description: applies a box shadow to the main Block element
 * @prop addPadding
 *     type: boolean
 *     description: applies 16px of padding to the Block's child container
 * @prop addMarginBottom
 *     type: boolean
 *     description: applies 8px margin to bottom of main Block element
 * @prop addVertPadding
 *     type: boolean
 *     description: applies 9px padding to top and bottom of Block's child container
 * @prop addWhiteBG
 *     type: boolean
 *     description: adds a white background to the entire Block component
 * @prop addFlex
 *     type: boolean
 *     description: adds flex styling to the Block component, useful for
 *     making sure a block takes up the entire space of a parent element
 * @prop addChildFlex
 *     type: boolean
 *     description: adds flex styling to the Block's child container, useful
 *     for making sure any children content takes up the entire space of the
 *     Block child container and for organizing content within the child
 *     container, set to flex-direction: column by default
 * @prop addChildFlexRow
 *     type: boolean
 *     description: same as above but flex-direction is set to row and
 *     content is centered
 * @prop addChildMarginRight
 *     type: boolean
 *     description: applies 20px of margin to right side of Block's child container
 * @prop addTopFade
 *     type: boolean
 *     description: applies a gradient fade effect to the top of the Block's child
 *     container
 * @prop addBottomFade
 *     type: boolean
 *     description: applies a gradient fade effect to the bottom of the Block's
 *     child container
 * @prop noTitlePadding
 *     type: boolean
 *     description: removes the default 16px padding in the Block's title container
 * @prop noTitleBorder
 *     type: boolean
 *     description: removes the default bottom border of the Block's title container
 * @prop formTitle
 *     type: boolean
 *     description: used when the title content is an input
 * @prop disabled
 *     type: styleObject
 *     description: applies a semi-transparent gray overlay over the child container
 */

const Block = ({
  className,
  children,
  title,
  boxShadow,
  addPadding,
  addMarginBottom,
  addVertPadding,
  addWhiteBG,
  addFlex,
  addChildFlex,
  addChildFlexRow,
  addChildMarginRight,
  addTopFade,
  addBottomFade,
  addMargin,
  noTitlePadding,
  noTitleBorder,
  formTitle,
  secondaryTitle,
  tertiaryTitle,
  titleClass,
  disabled,
  childContainerClass,
  ...rest
}) => {
  const classes = classnames(
    styles.Block,
    {
      [styles.blockFlex]: addFlex,
      [styles.blockFadeTop]: addTopFade,
      [styles.blockFadeBottom]: addBottomFade,
      [styles.blockBoxShadow]: boxShadow,
      [styles.blockWhiteBG]: addWhiteBG,
      [styles.blockMarginBottom]: addMarginBottom,
      [styles.blockMargin]: addMargin,
    },
    className
  );

  const childContainerClasses = classnames(
    {
      [styles.blockPadding]: addPadding,
      [styles.blockVertPadding]: addVertPadding,
      [styles.blockChildFlex]: addFlex,
      [styles.blockChildFlexDisplay]: addChildFlex,
      [styles.blockChildFlexRow]: addChildFlexRow,
      [styles.blockChildRightMargin]: addChildMarginRight,
    },
    childContainerClass
  );

  const titleClasses = classnames(
    {
      [styles.blockTitle]: !secondaryTitle && !tertiaryTitle,
      [styles.blockTitleSecondary]: secondaryTitle,
      [styles.blockTitleTertiary]: tertiaryTitle,
      [styles.noPadding]: noTitlePadding,
      [styles.formTitle]: formTitle,
      [styles.noTitleBorder]: noTitleBorder,
    },
    titleClass
  );

  return (
    <div className={classes} {...rest}>
      {title ? <div className={titleClasses}>{title}</div> : null}
      <div className={childContainerClasses}>{children}</div>
      {disabled ? <div className={styles.disabledBlock} /> : null}
    </div>
  );
};

export default Block;
