import * as genericCreators from 'shared/store/app/creators';

import selector from './selectors';
import reducer from './reducers';
import * as creators from './creators';

const duck = {
  selector,
  reducer,
  creators: {
    ...genericCreators,
    ...creators,
  },
};

export default duck;
