import { call, cancel, put, select, take, fork } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { push } from 'connected-react-router';
import { handleError } from 'utils/common';

import { patchResource, postResource } from 'api/apiResource';

import sagaValidateResource from 'connectors/Validator/redux/sagas/sagaValidateResource';

import { resourceSelectorFactory, errorsSelectorFactory } from 'reduxStore/selectorFactories';

import { formatType } from 'reduxStore/utils';

import { PATCH_RESOURCE } from '../../actions';

import {
  apiErrorsUpdate,
  resourceErrorsUpdate,
  resourceUpdate,
  resourceDelete,
  savingUpdate,
} from '../../creators';

const doPatchResource = function* doPatchResource(action, browserHistory) {
  const {
    payload: {
      attributes,
      id,
      ignoreValidations,
      params,
      requiredFields = [],
      slice,
      successCallback,
      type,
    },
  } = action;

  if (id && slice && type) {
    yield put(savingUpdate({ saving: true, slice }));

    const resource = yield select(resourceSelectorFactory(slice, type, id));

    const { attributes: origAttributes, attributes: { temporary } = {} } = resource || {};

    const newResource = {
      ...resource,
      attributes: {
        ...origAttributes,
        ...attributes,
      },
      id,
    };

    yield put(
      resourceUpdate({
        resource: newResource,
        id,
        slice,
        type,
      })
    );

    yield call(delay, 750);

    const errors = yield select(errorsSelectorFactory(slice, type, id));

    const { inputErrors, isValid } = yield call(sagaValidateResource, {
      resource: newResource.attributes,
      errors,
    });

    yield put(
      resourceErrorsUpdate({
        id,
        inputErrors,
        slice,
        type,
      })
    );

    const needPost =
      requiredFields.every((field) => newResource.attributes[field]) &&
      (isValid || ignoreValidations) &&
      temporary;

    const cleanAttributes = Object.keys(attributes).reduce((acc, curr) => {
      if (Array.isArray(attributes[curr])) {
        acc[curr] = newResource.attributes[curr].filter((el) => el);

        return acc;
      }

      if (curr !== 'temporary') {
        acc[curr] = newResource.attributes[curr];
      }

      return acc;
    }, {});

    if (needPost) {
      yield put(
        resourceUpdate({
          resource: {
            ...newResource,
            attributes: {
              ...newResource.attributes,
              isPosting: true,
            },
          },
          id,
          slice,
          type,
        })
      );

      const cleanAllAttributes = Object.keys(newResource.attributes).reduce((acc, curr) => {
        if (Array.isArray(newResource.attributes[curr])) {
          acc[curr] = newResource.attributes[curr].filter((el) => el);

          return acc;
        }

        if (curr !== 'temporary') {
          acc[curr] = newResource.attributes[curr];
        }

        return acc;
      }, {});
      try {
        const args = {
          config: {
            params: {
              normalizeIt: true,
              ...params,
            },
          },
          data: {
            attributes: cleanAllAttributes,
            type,
          },
          type,
        };

        const {
          data: { entities: { [formatType(type)]: { byId = {}, allIds = [] } = {} } = {} } = {},
        } = yield call(postResource, args);

        const resourceWithId = {
          ...byId[allIds[0]],
          attributes: {
            ...byId[allIds[0]].attributes,
            isPosting: false,
          },
        };

        yield put(
          resourceUpdate({
            id: resourceWithId.id,
            resource: resourceWithId,
            slice,
            type,
          })
        );

        const { pathname, ...browserState } = browserHistory.location;

        if (pathname.includes(resource.id)) {
          const newPathname = pathname.replace(resource.id, resourceWithId.id);

          yield put(
            push({
              ...browserState,
              pathname: newPathname,
            })
          );
        }

        if (resourceWithId.id !== id) {
          yield put(
            resourceDelete({
              id,
              slice,
              type,
            })
          );
        }

        successCallback && successCallback({ resource: resourceWithId });
      } catch (error) {
        handleError(error);

        yield put(apiErrorsUpdate({ slice, error }));
      }
    }

    const needPatch = !temporary && newResource && (isValid || ignoreValidations);

    if (needPatch) {
      try {
        const args = {
          config: {
            params: {
              normalizeIt: true,
              ...params,
            },
          },
          data: {
            attributes: cleanAttributes,
            id,
            type,
          },
          id,
          type,
        };

        const {
          data: { entities: { [formatType(type)]: { byId = {}, allIds = [] } = {} } = {} } = {},
        } = yield call(patchResource, args);

        const resourceWithId = {
          ...byId[allIds[0]],
          attributes: {
            ...byId[allIds[0]].attributes,
            isPosting: false,
          },
        };

        successCallback && successCallback({ resource: resourceWithId });
      } catch (error) {
        handleError(error);

        yield put(apiErrorsUpdate({ slice, error }));
      }
    }

    yield put(savingUpdate({ saving: false, slice }));
  }

  if (id && type && !slice) {
    const cleanAttributes = Object.keys(attributes).reduce((acc, curr) => {
      if (Array.isArray(attributes[curr])) {
        acc[curr] = attributes[curr].filter((el) => el);
      } else {
        acc[curr] = attributes[curr];
      }

      return acc;
    }, {});

    try {
      const args = {
        config: {
          params: {
            normalizeIt: true,
            ...params,
          },
        },
        data: {
          attributes: cleanAttributes,
          id,
          type,
        },
        id,
        type,
      };

      const {
        data: { entities: { [formatType(type)]: { byId = {}, allIds = [] } = {} } = {} } = {},
      } = yield call(patchResource, args);

      const resourceWithId = {
        ...byId[allIds[0]],
        attributes: {
          ...byId[allIds[0]].attributes,
          isPosting: false,
        },
      };

      successCallback && successCallback({ resource: resourceWithId });
    } catch (error) {
      handleError(error);
    }
  }
};

const patchResourceSaga = function* patchResourceSaga(browserHistory) {
  let lastTask;
  let lastAction = {};

  while (true) {
    const action = yield take(PATCH_RESOURCE);

    const { payload: { attributes = {}, id, slice, type } = {} } = action;
    const { payload: { attributes: previousAttributes = {}, id: previousId } = {} } = lastAction;

    const attributesToUpdate = Object.keys(attributes);
    const previousAttributesToUpdate = Object.keys(previousAttributes);

    const sameAttributes =
      attributesToUpdate.length === previousAttributesToUpdate.length &&
      attributesToUpdate.every((attr) => previousAttributesToUpdate.includes(attr));

    if (id && slice && type) {
      const resource = yield select(resourceSelectorFactory(slice, type, id));

      const { attributes: { isPosting = false } = {} } = resource || {};

      if (!isPosting && lastTask && sameAttributes && id === previousId) {
        yield cancel(lastTask);
      }

      if (!isPosting) {
        lastAction = action;
        lastTask = yield fork(doPatchResource, action, browserHistory);
      }
    } else {
      if (lastTask && sameAttributes && id === previousId) {
        yield cancel(lastTask);
      }

      lastAction = action;
      lastTask = yield fork(doPatchResource, action, browserHistory);
    }
  }
};

export default patchResourceSaga;
