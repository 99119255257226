import React from 'react';

import Button from 'components/Button';

const CohortActionDropdown = ({ deleteResource, cohortCandidateId }) => {
  const removeButtonProps = {
    onClick: () =>
      deleteResource({
        slice: 'toolboxCandidates',
        type: 'cohort_candidates',
        id: cohortCandidateId,
      }),
    warning: true,
  };

  return <Button {...removeButtonProps}>Remove</Button>;
};

export default CohortActionDropdown;
