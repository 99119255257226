import React, { useEffect, useState, useCallback, useRef } from 'react';
import { getCandidateBullhornInfo, postCandidateBullhornInfo } from 'api/apiCandidates';

import Button from 'components/Button/Button';

import styles from './BullhornPushButton.scss';

function BullhornPushButton({ candidateId }) {
  const _mounted = useRef(false);
  const [data, setData] = useState({});
  const { bullhorn_push: { bh_url } = {} } = data || {};

  useEffect(() => {
    _mounted.current = true;

    return () => {
      _mounted.current = false;
    };
  }, []);

  const getData = useCallback(() => {
    getCandidateBullhornInfo(candidateId).then((response) => {
      const { status, data = null } = response;

      if (status === 404) setData(null);
      else setData(data);
    });
  }, [candidateId]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className={styles.container}>
      {data ? (
        <Button primary to={bh_url} fullUrl>
          Open Bullhorn
        </Button>
      ) : (
        <Button
          primary
          onClick={() => {
            setData(undefined);
            postCandidateBullhornInfo(candidateId)
              .then(() => getData())
              .catch(() => getData());
          }}
        >
          Push Bullhorn
        </Button>
      )}
    </div>
  );
}

export default BullhornPushButton;
