import React from 'react';
import classnames from 'classnames';

import InfiniteScrollLoader from 'components/InfiniteScrollLoader';

import styles from './TableOverlay.scss';

const TableOverlay = ({ className, table, isLoading = true, locked, lockedNotFixed, absolute }) => {
  const classes = classnames(
    styles.TableOverlay,
    {
      [styles.savedSearches]: table === 'savedSearches',
      [styles.image]: table === 'image',
      [styles.resume]: table === 'resume',
      [styles.inPlace]: table === 'inPlace',
      [styles.search]: table === 'search',
      [styles.candidateLoader]: table === 'candidate',
      [styles.noSidebarLoader]: table === 'noSidebar',
      [styles.locked]: locked,
      [styles.lockedNotFixed]: lockedNotFixed,
      [styles.absolute]: absolute,
    },
    className
  );

  const loader = locked || lockedNotFixed ? null : <InfiniteScrollLoader />;

  return isLoading ? <div className={classes}>{loader}</div> : null;
};

export default TableOverlay;
