import { call, select, put, take } from 'redux-saga/effects';

import { entitySelectorFactory } from 'reduxStore/selectorFactories';

import { handleError } from 'utils/common';
import { trackEvent } from 'utils/analytics';
import { segmentRemoveBookmark } from 'segment/eventNames';

import { promiseBookmarksRemove } from '../../promises';

import { BOOKMARKS_REMOVE_STARTED } from '../actions';

import { resultsUpdate } from '../creators';

const trackBookmarkRemove = (args) => {
  const event = segmentRemoveBookmark;

  const properties = { ...args };

  const eventObject = { event, properties };

  trackEvent(eventObject);
};

const sagaBookmarksRemove = function* sagaBookmarksRemove() {
  while (true) {
    const {
      payload: { bookmarkId, candidateId, candidateFirstName, candidateLastName },
    } = yield take(BOOKMARKS_REMOVE_STARTED);

    const promiseParams = {
      bookmarkId,
    };

    const { byId, allIds } = yield select(entitySelectorFactory('employerSearch', 'candidate'));

    try {
      const newById = {
        ...byId,
        [candidateId]: {
          ...byId[candidateId],
          attributes: {
            ...byId[candidateId].attributes,
            bookmark: null,
          },
        },
      };

      yield put(resultsUpdate({ type: 'candidate', entity: { allIds, byId: newById } }));

      yield call(promiseBookmarksRemove, promiseParams);

      yield call(trackBookmarkRemove, {
        bookmarkId,
        candidateId,
        candidateFirstName,
        candidateLastName,
      });
    } catch (error) {
      handleError(error);

      yield put(resultsUpdate({ type: 'candidate', entity: { allIds, byId } }));
    }
  }
};

export default sagaBookmarksRemove;
