import React from 'react';

import InputBullets from 'components/InputBullets';

import styles from '../ReqRequirement.scss';

const MustHaves = ({
  disabled,
  handleAddArrayInput,
  handleAddArrayValue,
  handleDeleteArrayInput,
  handleReorderArray,
  requisitionRequirement: { attributes: { mustHaves } = {} } = {},
}) => {
  const mustHavesProps = {
    disabled,
    label: 'Must haves',
    secondaryLabel: 'Add essential skills for the role',
    array: mustHaves,
    name: 'mustHaves',
    handleAddArrayInput,
    handleAddArrayValue,
    handleDeleteArrayInput,
    handleReorderArray,
    pad: true,
    inputClass: styles.inputBulletContainer,
  };

  return (
    <div className={styles.rangeInputs}>
      <div className={styles.column}>
        <InputBullets {...mustHavesProps} />
      </div>
    </div>
  );
};

export default MustHaves;
