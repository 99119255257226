import React, { useState, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { useOnClickOutside } from 'hooks';

import FontIcon from 'components/FontIcon';

import styles from './MenuDropdown.scss';

const MenuDropdown = ({ className, label, items, showCaret = true, target = '_self' }) => {
  const [active, setActive] = useState(false);
  const ref = useRef();

  useOnClickOutside(
    ref,
    useCallback(() => setActive(false), [])
  );

  const handleOnClick = () => setActive((active) => !active);

  const dropdown = active ? (
    <div className={styles.optionsContainer}>
      {items.map(({ title, url }) => (
        <Link key={title} className={styles.option} to={url} target={target}>
          {title}
        </Link>
      ))}
    </div>
  ) : null;

  return (
    <div onClick={handleOnClick} className={cn(className, styles.container)} ref={ref}>
      <div className={styles.internalContainer}>
        <div className={styles.title}>{label}</div>
        {showCaret && (
          <FontIcon className={styles.caret} iconName={active ? 'caret-up' : 'caret-down'} />
        )}
      </div>
      {dropdown}
    </div>
  );
};

export default MenuDropdown;
