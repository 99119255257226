import { postAddCandidates } from 'api/apiNonstandard';

const promisePostAddCandidates = ({ cohortId, candidatesToAdd }) => {
  const candidatesToAddString = candidatesToAdd
    ? candidatesToAdd.map((id) => `&id[]=${id}`).join('')
    : '';

  const onFulfilled = ({ data }) => data;

  const args = {
    id: cohortId,
    type: 'cohorts',
    nestedType: 'add_candidates',
    data: {
      add_candidates: {
        scope: `&not_in_cohort=${cohortId}${candidatesToAddString}`,
      },
    },
    config: {
      params: {
        normalizeIt: true,
      },
    },
  };

  return postAddCandidates(args).then(onFulfilled);
};

export default promisePostAddCandidates;
