import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet-async';

import { createRoute, queryStringParse } from 'utils/paramUtils';

import ConnectContainer from 'containers/ConnectContainer';

import ReadyContainer from 'connectors/ReadyContainer';

import Sidebar from 'components/Sidebar';
import Bold from 'components/Bold';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import InputCheckbox from 'components/InputCheckbox';

import EmployeesTable from 'pages/AdminPortal/ToolboxEmployerEditorPage/components/EmployerEditorEmployees/components/EmployeesTable';

import duck from '../redux';

import styles from './EditEmployees.scss';

class EditEmployees extends Component {
  constructor(props) {
    super(props);

    const { employer: { id: employerId } = {}, location: { search } = {} } = props;

    const params = {
      sortBy: {
        type: 'string',
        defaultValue: 'created_at_desc',
      },
      userAccountLocked: {
        type: 'boolean',
        defaultValue: false,
      },
    };

    const queryParams = queryStringParse({ search, params });

    const defaultParams = Object.keys(params).reduce((acc, curr) => {
      acc[curr] = params[curr].defaultValue;

      return acc;
    }, {});

    this.state = { ...queryParams };

    this.defaultLoadParams = {
      ...defaultParams,
      employerId,
      includeSet: 'employer_company',
      page: 1,
      sortBy: 'created_at_desc',
    };

    this.defaultLoadArgs = {
      type: 'employees',
      slice: 'employerEditor',
    };

    this.route = '/employee/edit-employees/';
  }

  componentDidMount() {
    const {
      actions: { entityLoadStarted },
    } = this.props;

    entityLoadStarted({
      ...this.defaultLoadArgs,
      params: {
        ...this.getLoadParams(),
        page: 1,
      },
    });
  }

  getLoadParams = () => {
    const { page = 1 } = this.props;

    const { userAccountLocked, sortBy } = this.state;

    return {
      ...this.defaultLoadParams,
      userAccountLocked,
      sortBy,
      page,
    };
  };

  loadMore = () => {
    const {
      actions: { entityLoadStarted },
      employeesPage: page,
    } = this.props;

    entityLoadStarted({
      ...this.defaultLoadArgs,
      params: {
        ...this.getLoadParams(),
        page: page + 1,
      },
    });
  };

  handleSearchCheckboxChange = (event) => {
    const {
      target: { name, checked: value },
    } = event;

    const {
      actions: { entityLoadStarted },
      location,
    } = this.props;

    const newRoute = createRoute({
      route: this.route,
      location,
      name,
      value,
    });

    this.setState({ [name]: value }, () =>
      entityLoadStarted({
        ...this.defaultLoadArgs,
        newRoute,
        params: {
          ...this.getLoadParams(),
          page: 1,
        },
      })
    );
  };

  render() {
    const {
      actions: { showModal },
      children,
      employer,
      employer: { name: companyName },
      employees,
      employeesLoading: isLoading,
      employeesTotal,
      isApproved,
      modal,
      users,
    } = this.props;

    const { userAccountLocked } = this.state;

    const attentionRequiredProps = {
      name: 'userAccountLocked',
      label: 'Show employees with locked accounts',
      checked: userAccountLocked,
      handleInputChange: this.handleSearchCheckboxChange,
      className: styles.checkbox,
      checkboxMany: true,
    };

    const filtersContent = (
      <div className={styles.employeesHeader}>
        <div className={styles.employeesHeaderText}>
          <Bold>{companyName}</Bold> employees
        </div>
        <div className={styles.employeesText}>
          Please contact <a href="mailto:support@rainmakers.co">support@rainmakers.co</a> to add
          employees.
        </div>
        <InputCheckbox {...attentionRequiredProps} />
      </div>
    );

    const employeesTableProps = {
      ...this.state,
      employees,
      isLoading,
      loadMore: this.loadMore,
      modal,
      modalKey: 'EmployeeEditorModal',
      parent: 'EmployerEmployeesPage',
      showModal,
      titleContent: filtersContent,
      total: employeesTotal,
      users,
      handleSearchCheckboxChange: this.handleSearchCheckboxChange,
    };

    const tableContent = <EmployeesTable {...employeesTableProps} />;

    const content = <div className={styles.employeesMainContent}>{tableContent}</div>;

    const layoutWithoutSidebarProps = {
      content,
    };

    const sidebarProps = {
      type: isApproved ? 'employeeStatusApproved' : 'employeeStatusUnapproved',
      page: 'edit-employees',
      employer,
    };

    return (
      <Fragment>
        <Helmet key="helmet" title="Settings" />
        <ReadyContainer key="readyContainer">
          <Sidebar {...sidebarProps} />

          <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />

          {children}
        </ReadyContainer>
      </Fragment>
    );
  }
}

export default ConnectContainer(duck)(EditEmployees);
