import React from 'react';

import InputBullets from 'components/InputBullets';

const Overview = ({
  handleAddArrayInput,
  handleAddArrayValue,
  handleDeleteArrayInput,
  handleReorderArray,
  requisitionOpening: { attributes: { overview, state } = {} } = {},
  required = true,
}) => {
  const overviewInputProps = {
    label: 'About the role',
    secondaryLabel: 'List a few of the key highlights of the role.',
    array: overview,
    name: 'overview',
    handleAddArrayInput,
    handleAddArrayValue,
    handleDeleteArrayInput,
    handleReorderArray,
    pad: true,
    required: state !== 'closed' && required,
    disabled: state === 'closed',
  };

  return <InputBullets {...overviewInputProps} />;
};

export default Overview;
