import React from 'react';

import { toCents, toDollars } from 'utils/moneyUtils';

import Button from 'components/Button';
import Input from 'components/Input';
import InputSelect from 'components/InputSelect';
import Label from 'components/Label';
import TableOverlay from 'components/TableOverlay';

import Locations from 'pages/AdminPortal/ToolboxRequisitionEditorPage/components/RequisitionOpening/components/Locations';
import Overview from 'pages/AdminPortal/ToolboxRequisitionEditorPage/components/RequisitionOpening/components/Overview';

import styles from './CreateNewRequisition.scss';

const CreateNewRequisition = ({
  creatingReq,
  oteMaxCents,
  oteMinCents,
  role,
  employees,
  enableMessaging,
  externalName,
  handleAddArrayInput,
  handleAddArrayValue,
  handleCreateReqClick,
  handleDeleteArrayInput,
  handleReorderArray,
  handleReqInputChange,
  handleToggleLocation,
  handleToggleRegions,
  handleWorkModelChange,
  isAdmin,
  location,
  locations,
  locationSource,
  overview,
  roleOptions,
  selectedEmpId,
  workModel = [],
}) => {
  const empSource = employees.map((emp) => {
    const { firstName = '', lastName = '', jobTitle = '', id: empId } = emp;

    const label = jobTitle ? `${firstName} ${lastName}, ${jobTitle}` : `${firstName} ${lastName}`;

    return {
      label,
      value: empId,
    };
  });

  const rolesSource = roleOptions.map((loc) => ({ label: loc, value: loc }));

  const selectEmpTwoInputProps = {
    name: 'selectedEmpId',
    source: empSource,
    value: selectedEmpId,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    handleInputChange: handleReqInputChange,
    defaultLabel: 'Who is the owner of this requisition?',
    bigInput: true,
  };

  const selectEmpContent = isAdmin ? <InputSelect {...selectEmpTwoInputProps} /> : null;

  const roleInputProps = {
    source: rolesSource,
    name: 'role',
    handleInputChange: handleReqInputChange,
    value: role || '',
    label: 'What type of role is this?',
    defaultLabel: 'Select a role',
    defaultIcon: 'caret-down',
    bigInput: true,
  };

  const createReqInputProps = {
    className: styles.createReqInputContainer,
    handleInputChange: handleReqInputChange,
    value: externalName,
    label: 'Req Name (this is what the candidate sees)',
    name: 'externalName',
    placeholder: 'ex: Account Executive',
    autoFocus: true,
    size: 'full',
  };

  const locationsProps = {
    locationSource,
    location,
    handleToggleLocation,
    handleToggleRegions,
    handleInputChange: handleReqInputChange,
    handleWorkModelChange,
    required: false,
    requisitionOpening: {
      attributes: {
        locations,
        workModel,
      },
    },
  };

  const overviewProps = {
    handleAddArrayInput,
    handleAddArrayValue,
    handleDeleteArrayInput,
    handleReorderArray,
    required: false,
    requisitionOpening: {
      attributes: {
        overview,
      },
    },
  };

  const commonOteInputProps = {
    className: styles.inputContainerNoPad,
    type: 'text',
    size: 'large',
    handleInputChange: (event) =>
      handleReqInputChange({
        ...event,
        target: {
          ...event.target,
          value: toCents(event.target.value),
          name: event.target.name,
        },
      }),
  };

  const oteRangeValid = oteMinCents && oteMaxCents ? oteMaxCents >= oteMinCents : true;

  const oteMinValid = oteMinCents && oteMinCents < 10000000000;
  const oteMaxValid = oteMaxCents && oteMaxCents < 10000000000;

  const oteMinIsMoneyLessThanX = !oteMinValid ? [oteMinValid, 10000000000] : undefined;
  const oteMaxIsMoneyGreaterThanX = !oteMaxValid ? [oteMinValid, 10000000000] : undefined;

  const oteMinErrors = !oteRangeValid
    ? { isMoneyLessThanX: [oteRangeValid, oteMaxCents] }
    : { isMoneyLessThanX: oteMinIsMoneyLessThanX };

  const oteMaxErrors = !oteRangeValid
    ? { isMoneyLessThanX: [oteRangeValid, oteMinCents] }
    : { isMoneyLessThanX: oteMaxIsMoneyGreaterThanX };

  const oteMinInputProps = {
    ...commonOteInputProps,
    size: 'full',
    value: oteMinCents || oteMinCents === 0 ? toDollars(oteMinCents) : '',
    name: 'oteMinCents',
    moneyInput: true,
    oteInputOne: true,
    errors: oteMinErrors,
  };

  const oteMaxInputProps = {
    ...commonOteInputProps,
    size: 'full',
    value: oteMaxCents || oteMaxCents === 0 ? toDollars(oteMaxCents) : '',
    name: 'oteMaxCents',
    moneyInput: true,
    oteInputTwo: true,
    errors: oteMaxErrors,
  };

  const validOTE =
    oteMinCents !== '' &&
    oteMinCents !== null &&
    oteMaxCents !== '' &&
    oteMaxCents !== null &&
    oteMinValid &&
    oteMaxValid &&
    oteRangeValid;

  const createAndSendIntroButtonProps = {
    onClick: handleCreateReqClick,
    primary: true,
    disabled:
      (isAdmin && selectedEmpId === '') ||
      !validOTE ||
      externalName === null ||
      externalName === '' ||
      role === null ||
      overview.length === 0 ||
      locations.length === 0 ||
      workModel.length === 0,
    className: styles.nextButton,
  };

  const createAndSendIntroButton =
    !isAdmin && enableMessaging ? (
      <Button {...createAndSendIntroButtonProps}>Next</Button>
    ) : (
      <Button {...createAndSendIntroButtonProps}>Add and send intro request</Button>
    );

  const overlay = creatingReq ? <TableOverlay table="image" /> : null;

  return (
    <div className={styles.adminRequestOptionsContainer}>
      <div className={styles.header}>Create new Requisition</div>

      <div className={styles.subheader}>
        A new requisition will be created and the candidate will be added to it. Make sure the
        information is accurate.
      </div>

      <div className={styles.createReqInput}>
        {overlay}
        <Input {...createReqInputProps} />
        <InputSelect {...roleInputProps} />
        <Locations {...locationsProps} />
        <Overview {...overviewProps} />
        {selectEmpContent}
        <div className={styles.rangeInputs}>
          <Label standalone>OTE Range (only you can see this)</Label>
          <div className={styles.rangeInputsContainer}>
            <Input {...oteMinInputProps} />
            <div className={styles.rangeSpan}>to</div>
            <Input {...oteMaxInputProps} />
          </div>
        </div>
      </div>

      <div className={styles.requestIntroButtons}>{createAndSendIntroButton}</div>
    </div>
  );
};

export default CreateNewRequisition;
