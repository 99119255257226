import { call, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import store from 'store';

import { PING_SESSION } from 'shared/store/app/actions';

import sagaCheckSessionEnd from './sagaCheckSessionEnd';

const pingSession = function* pingSession() {
  const start = store.get('sessionStart');

  const sessionStart = Date.parse(start);

  if (!sessionStart) {
    store.set('sessionStart', new Date());
  }

  store.set('sessionLastActive', new Date());

  const delayTime = 1800000;

  yield call(delay, delayTime);

  yield call(sagaCheckSessionEnd, { useLastActive: false });
};

const sagaPingSession = function* sagaPingSession() {
  yield takeLatest(PING_SESSION, pingSession);
};

export default sagaPingSession;
