import React from 'react';
import isEmpty from 'lodash.isempty';

import { printElements } from 'utils/printElements';

import UnauthorizedPage from 'pages/UtilPages/UnauthorizedPage';

import Block from 'components/Block';
import Button from 'components/Button';
import Empty from 'components/Empty';
import FormattedArrayWithLabel from 'components/FormattedArrayWithLabel';

import AboutCompany from './components/AboutCompany';
import AboutRole from './components/AboutRole';
import Extras from './components/Extras';
import Header from './components/Header';
import HiringLocations from './components/HiringLocations';
import Opportunity from './components/Opportunity';
import Sidebar from './components/Sidebar';
import Summary from './components/Summary';

import styles from './RequisitionOpeningView.scss';

const RequisitionOpeningView = ({
  employers: { byId: employersById = {} } = {},
  candidates: { byId: candidatesById = {} } = {},
  requisitionCandidates: { byId: requisitionCandidatesById = {} } = {},
  requisitionOpening = {},
  requisitionOpening: {
    attributes: { employerId, highlights = [], id, products = [], unauthorized } = {},
  } = {},
  modal,
  match: {
    params: { reqCandId },
  },
  isAdmin,
  isCandidate,
  isEmployee,
  pickerOptions,
}) => {
  const {
    [reqCandId]: { relationships: { candidate: { data: candidateIds = [] } = {} } = {} } = {},
  } = requisitionCandidatesById;

  const { [candidateIds[0]]: { attributes: { fullName } = {} } = {} } = candidatesById;

  const sidebarProps = {
    isCandidate,
    requisitionOpeningId: id,
  };

  const commonProps = {
    employer: employersById[employerId] || {},
    requisitionOpening,
    pickerOptions,
  };

  const highlightsProps = {
    label: 'Highlights',
    array: highlights,
  };

  const productsProps = {
    label: 'Product(s)',
    array: products,
  };

  const titleBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    addPadding: true,
    noTitlePadding: true,
    className: styles.titleBlock,
    largeTitleFont: false,
  };

  const nameString =
    fullName && (isEmployee || isAdmin) ? `This is the Requisition for ${fullName} only` : '';

  const subtitleContent = nameString ? <div className={styles.subTitle}>{nameString}</div> : null;

  const printButtonProps = {
    onClick: () => {
      const element = document.getElementById('printpage');
      if (element) {
        printElements.print([element]);
      }
    },
    primary: true,
  };

  const printButtonContent = isAdmin ? <Button {...printButtonProps}>Print</Button> : null;

  const header =
    isAdmin || (reqCandId && isEmployee) ? (
      <Block {...titleBlockProps}>
        <div className={styles.title}>
          Preview
          {subtitleContent}
        </div>
        {printButtonContent}
      </Block>
    ) : null;

  const isModal = !isEmpty(modal);

  const content = !isEmpty(requisitionOpening) ? (
    <React.Fragment>
      {header}
      <div className={isModal ? styles.RequisitionPreviewModal : styles.RequisitionPreview}>
        <div className={isModal ? styles.scrollDivModal : styles.scrollDiv}>
          <Sidebar {...sidebarProps} />
          <div id="printpage" className={styles.contentContainer}>
            <Header {...commonProps} />
            <Summary {...commonProps} />
            <HiringLocations {...commonProps} />
            <AboutCompany {...commonProps} />
            <FormattedArrayWithLabel {...highlightsProps} />
            <FormattedArrayWithLabel {...productsProps} />
            <AboutRole {...commonProps} />
            <Opportunity {...commonProps} />
            <Extras {...commonProps} />
          </div>
        </div>
      </div>
    </React.Fragment>
  ) : (
    <Empty />
  );

  const isUnauthorized = (isEmployee && employerId === null) || unauthorized;

  return isUnauthorized ? <UnauthorizedPage /> : content;
};

export default RequisitionOpeningView;
