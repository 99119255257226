import React, { useState } from 'react';

import { VisibleHOC } from 'containers/HOC';

import Bold from 'components/Bold';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import DialogueModal from 'components/DialogueModal';
import Input from 'components/Input';

import ConnectContainer from 'containers/ConnectContainer';

import duck from '../../redux';
import styles from './OpportunitiesActionDropdown.scss';

const source = [
  {
    label: 'Pass',
    value: 'candidate_pass_pending',
  },
  {
    label: 'Contact Talent Concierge',
    value: 'contact',
  },
];

const OpportunitiesActionDropdown = ({
  actions: { showModal, patchRequisitionCandidateStarted },
  candidate: { fullName, rainmakersAdminId } = {},
  employerName,
  rainmakersAdmin: {
    firstName: adminFirstName = 'Rainmakers Support',
    user: { email: adminEmailAddress = 'support@rainmakers.co' } = {},
  } = {},
  reqCandidateId,
  isVisible,
}) => {
  const [state, setState] = useState({
    promptType: null,
    message: null,
  });

  const { promptType, message } = state;

  const handleClick = (promptType) => {
    if (promptType === 'candidate_pass_pending') {
      return showModal({
        key: 'CandidatePassModal',
        parent: 'CandidateOpportunitiesPage',
        route: `/candidate/opportunity/${reqCandidateId}/`,
      });
    }

    if (adminEmailAddress) {
      const subject = `${fullName} inquiry regarding ${employerName}`;
      const body = `Hi ${adminFirstName},`;
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      window.open(`mailto:${adminEmailAddress}?subject=${subject}&body=${body}`, '_blank').focus();
    }
  };

  const confirmFunction = () =>
    patchRequisitionCandidateStarted({
      reqCandidateId,
      message,
      state: promptType,
    });

  const promptContent = promptType ? (
    <DialogueModal>
      <div className={styles.promptModal}>
        <Bold className={styles.promptContent}>
          <div className={styles.promptTitle}>
            <div className={styles.subtitle}>Please enter your message below:</div>
          </div>
        </Bold>

        <div className={styles.inputContent}>
          <Input
            type={'textarea'}
            size={'full'}
            handleInputChange={(event) =>
              setState((prevState) => ({ ...prevState, [event.target.name]: event.target.value }))
            }
            value={message}
            rows={3}
            name="message"
            label="Let your concierge know what is on your mind."
          />
        </div>

        <div className={styles.promptButtons}>
          <Button
            quaternary={true}
            onClick={() => setState((prevState) => ({ ...prevState, promptType: null }))}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              confirmFunction();
              setState((prevState) => ({ ...prevState, promptType: null }));
            }}
            primary={true}
            className={styles.confirm}
          >
            Confirm
          </Button>
        </div>
      </div>
    </DialogueModal>
  ) : null;

  return (
    <div className={styles.ActionDropdown}>
      <Dropdown
        source={rainmakersAdminId ? source : source.slice(0, 1)}
        onChange={handleClick}
        value={'send_message'}
        defaultValue={'Actions'}
        above={!isVisible}
        left={true}
        actionsOneOption={true}
        defaultIcon={'caret-down'}
        activeIcon={'caret-up'}
        data-testid="opportunities-action-dropdown"
      />
      {promptContent}
    </div>
  );
};

export default ConnectContainer(duck)(VisibleHOC()(OpportunitiesActionDropdown));
