/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import { VerticalDelimiter } from 'components/VerticalDelimiter';
import marketsJobsPageMetaData, {
  MK_JB_AE_CHI_PAGE,
  MK_JB_AE_NYC_PAGE,
  MK_JB_AE_SF_PAGE,
} from 'pages/MarketsJobsPage/marketsJobsPageMetaData';

import pic1 from './assets/1-account-executive-career-information.png';
import pic2 from './assets/2-contact-rainmakers-tech-sales-recruiters.png';
import pic3 from './assets/3-account-executive-job-information.png';
import pic4 from './assets/4-account-executive-ae.png';
import pic5 from './assets/5-tech-sales-recruiters.png';
import pic6 from './assets/6-create-profile-browse-account-executive-jobs.png';

import styles from './AEJobsContent.scss';

export function AEJobsContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt="" />

      <h2>Account Executive (AE) Jobs</h2>

      <p>
        An Account Executive (AE) is a salesperson who is typically in charge of closing deals with
        new prospective clients, managing the process from prospect to deal signing.. In addition,
        they are frequently required to reach a revenue goal as they receive commissions for selling
        the goods and services of the business they represent.
      </p>

      <p>
        An AE will work with a Sales Development Representative (SDR) who is responsible for
        generating initial interest and scheduling the first sales appointment. The AE and SDR
        frequently collaborate at the start of the process, with the Account Executive taking the
        most active role after the first sales appointment.
      </p>

      <h2>Is an Account Executive Position Right For You?</h2>

      <p>
        Depending on the size of the company and the maturity of the sales team, the Account
        Executive may also be in charge of prospecting new clients. Because they perform the duties
        of both the SDR and AE, this position is sometimes referred to as a “full-cycle sales rep.”
        We see this a lot in early stage startups.
      </p>

      <p>
        An Account Executive will be expected to check in regularly to ensure customers are still
        satisfied with the business, product, or service. In addition, they will look for
        opportunities to upsell and cross-sell additional products or services and work to maintain
        customer satisfaction and loyalty. In many industries, such as technology, software,
        financial services, pharmaceuticals, and healthcare, the Account Executive shares post-sale
        responsibilities with their customer service team.
      </p>

      <p>
        The Account Executive position has been around for decades, and it has grown and evolved as
        technology, demographics, consumer needs, and the economy have also developed and changed.
        One report from the US Bureau of Labor Statistics predicts a 7% increase in demand for AEs
        within the next couple of years.
      </p>

      <p>
        Most AEs begin their careers as SDRs—typically working in that position for about one to
        three years. After that, the best performers graduate on to Account Executive, which could
        lead to more significant sales or management positions.
      </p>

      <p>
        Beginning annual salary ranges from $45,000 to $70,000 depending on experience, region, and
        business. Performance bonuses and commissions could then augment this base salary.
      </p>

      <Link to="/apply-now" title="Apply Now">
        <img src={pic2} alt="" />
      </Link>

      <h2>What Are an Account Executive’s Duties?</h2>
      <p>
        Account Executives are essential to many businesses. They often serve as the company’s first
        impression to customers and are responsible for a significant portion of that business’s
        revenue.
      </p>

      <p>Their primary duties may consist of:</p>

      <ul>
        <li>Having a complete knowledge of the product or service being sold.</li>
        <li>Following up on leads submitted by SDRs.</li>
        <li>Conducting discovery and demo calls.</li>
        <li>Moving deals through the sales process.</li>
        <li>Conducting research and creating sales decks in preparation for sales meetings.</li>
        <li>Maintaining phone and electronic communication.</li>
        <li>Sending new pitches to increase business revenue.</li>
        <li>
          Managing and developing clients following business transactions to help identify upsell or
          cross-sell opportunities.
        </li>
        <li>Seeking new clients, retaining current ones.</li>
        <li>Assisting in training of new SDRs and new Account Executives.</li>
        <li>Generating sales performance reports.</li>
        <li>Participating in department and team meetings.</li>
      </ul>

      <h2>What Does a Successful Account Executive Look Like?</h2>

      <p>
        Account Executives are typically evaluated on their sales performance - specifically
        relating to their quota.
      </p>

      <p>
        The target for a sales quota is usually determined by a desired number of deals closed or
        meeting a specified revenue amount. Quotas are typically set by the month, quarter, or year.
      </p>

      <p>
        To make sure that deals are closed with desirable clients, a portion of an Account
        Executive’s performance bonus or commission may be calculated based on customer retention.
        Factors include whether or not a customer is retained for a set amount of time, how
        customers report satisfaction with their level of service, and the amount of upselling and
        cross-sell opportunities completed.
      </p>

      <img src={pic3} alt="" />

      <h2>How Does One Become an Account Executive?</h2>

      <p>
        Most Account Executives begin their careers as Sales Development Representatives or Junior
        Account Executives. This period often lasts one to three years and involves:
      </p>

      <ol>
        <li>Gaining experience and building skills in prospecting.</li>
        <li>Researching ideal decision makers to reach out to</li>
        <li>Conducting cold calls.</li>
        <li>Sending cold emails.</li>
        <li>Facilitating meetings between potential clients and an Account Executive.</li>
      </ol>

      <p>
        After a successful period of training and collaboration, SDRs and Junior Account Executives
        often advance to full Account Executive status.
      </p>

      <p>
        The vast majority of Account Executives will have experience in some form of customer
        engagement and account management. Because the position of Account Executive relies heavily
        on contact with the public, the candidate must also have experience in this area and feel
        comfortable doing so.
      </p>

      <p>
        Another way some individuals find themselves in Account Executive positions is by working in
        sales for smaller businesses. Usually, this requires the individual to handle a broader
        range of duties successfully. If they can manage this, they’re better positioned to take on
        Account Executive duties with larger companies. While rare, some individuals manage to get
        in on the ground floor by working directly with company founders. This gives them an inside
        track that proves invaluable when dealing with large accounts.
      </p>

      <p>
        In general, individuals who want to become Account Executives can most rely on the
        traditional SDR-to-AE career path that features extensive on-the-job training and
        experience.
      </p>

      <img src={pic4} alt="" />

      <h2>What Skills Allow an Account Executive To Succeed?</h2>

      <p>
        Here are the critical skills and qualities necessary to have a successful career as an
        Account Executive:
      </p>

      <ul>
        <li>
          <strong>Previous sales experience</strong> – Most companies prefer to see at least five
          years of sales experience either within their own company or a similar business. A proven
          record of success is a plus.
        </li>
        <li>
          <strong>Good listening skills</strong> – A successful salesperson knows how to listen to
          prospective clients, learn what problems they need to solve and ask insightful questions
          to help them identify the best solution.
        </li>
        <li>
          <strong>Can be informative without being pushy</strong> – Instead of attempting to make a
          hard sell, an effective Account Executive should be able to inform and educate clients on
          the best solutions and guide them toward seeing how your business can provide those
          solutions.
        </li>
        <li>
          <strong>Superior communicator skills</strong> – Account Executives must be at ease when
          speaking on sales calls, leaving voicemails, composing emails, and conducting in-person
          pitches and presentations.
        </li>
        <li>
          <strong>Effective negotiators</strong> – AEs should possess a solid understanding of sales
          psychology and feel confident when negotiating crucial contract points.
        </li>
        <li>
          <strong>Organized</strong> – Account Executives are responsible for various tasks,
          including helping internal stakeholders with initiatives, managing existing customer
          relationships, and closing prospects in the sales funnel.
        </li>
        <li>
          <strong>Confidence</strong> – There is a good chance the Account Executive will be
          considerably younger than many of their prospects—particularly company founders and upper
          management. A good Account Executive must possess a good amount of confidence and
          self-assurance to speak with them.
        </li>
        <li>
          <strong>Can be a self-starter</strong> – Whether it’s attending meetings, sending
          follow-up sales emails, or making contact with clients, an Account Executive must be able
          to follow up on their responsibilities without repeated prodding from management.
        </li>
        <li>
          <strong>Resilience</strong> – Salespeople hear the word “no” a lot. Sometimes prospective
          clients can be rude or otherwise unpleasant. Nonetheless, a good Account Executive must be
          able to brush off discouragement and move on to the next call with enthusiasm.
        </li>
        <li>
          <strong>Self-awareness</strong> – A high emotional intelligence makes it easier to
          establish a connection with a prospect and show compassion for their needs. An Account
          Executive needs to be aware of personality strengths and weaknesses to use this knowledge
          to their advantage.
        </li>
        <li>
          <strong>Possess a sense of curiosity</strong> – Successful Account Executives are always
          seeking to learn more. This can be accomplished by reading sales books, following current
          market trends, and attending regular training sessions to sharpen their skills.
        </li>
        <li>
          <strong>Coachable</strong> – Finally, an Account Executive must be able to accept
          criticism and learn from mistakes. They should always be looking for ways to improve their
          overall performance.
        </li>
      </ul>

      <img src={pic5} alt="" />

      <h2>What Comes Next For Account Executives?</h2>

      <p>
        Because there is expected growth in the number of Account Executive positions becoming
        available over the next few years, it also means there is room for advancement. As many
        started as SDRs or junior Account Executives, it’s perfectly reasonable for an AE to find
        their career trajectory can take them further.
      </p>

      <p>
        Most Account Executives are internally promoted, but many are hired from outside firms,
        particularly those operating in the same industry. Account Executives will often be promoted
        to working with larger companies and larger deal sizes. Alternatively, they may advance to
        more senior positions, such as Director or Vice President of Sales. Although they
        occasionally deal with high-profile clients in these positions, much of their time is
        devoted to managing and improving their company’s operations.
      </p>

      <p>
        Those Account Executives interested in advancement would do well to attend management
        training sessions, professional development courses and, of course, have a stellar sales and
        customer retention record.
      </p>

      <Link to="/apply-now" title="Apply Now">
        <img src={pic6} alt="" />
      </Link>

      <h2>Building a Great Career</h2>

      <p>
        Account Executives are an essential part of any successful business. It’s a position with
        the potential for high earnings and can help provide significant personal growth. There is
        also the genuine possibility of further advancement for those Account Executives who prove
        their worth above and beyond expectations.
      </p>

      <p>
        As an Account Executive, you’re a critical part of the sales funnel. Your success directly
        affects your company’s success. By developing the right skills and being dedicated to the
        tasks at hand, there’s no reason why any individual couldn’t become a successful Account
        Executive and reap all the benefits.
      </p>

      <p>
        Increase your knowledge of available career prospects and the daily routine of an Account
        Executive in the most dynamic tech hubs!
      </p>
      <ul>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={marketsJobsPageMetaData[MK_JB_AE_NYC_PAGE].localUrl}
          >
            {' '}
            AE Jobs in New York City
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={marketsJobsPageMetaData[MK_JB_AE_SF_PAGE].localUrl}
          >
            {' '}
            AE Jobs in San Francisco
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={marketsJobsPageMetaData[MK_JB_AE_CHI_PAGE].localUrl}
          >
            {' '}
            AE Jobs in Chicago
          </a>
        </li>
      </ul>
      <VerticalDelimiter />

      <p>
        Whether you are a local employer{' '}
        <a href={`https://${process.env.HOST}/employers`}>
          looking to hire a new Account Executive
        </a>{' '}
        to join your growing sales team or looking for{' '}
        <a href={`https://${process.env.HOST}/apply-now/`}>AE jobs near you</a>, Rainmakers is here
        to help! Contact us to get started.
      </p>
    </div>
  );
}
