import React, { useState } from 'react';

import { ComponentErrorBoundaryHOC } from 'containers/HOC';

import Block from 'components/Block';
import Bolder from 'components/Bolder';
import Button from 'components/Button';
import Content from 'components/Content';
import FontIcon from 'components/FontIcon';

import styles from './ProfileAwards.scss';

const numAwardsToShow = 3;
const maxAwardSize = 70;

export const ProfileAwards = ({ qAndA }) => {
  const [showAll, setShowAll] = useState(false);

  const awards = qAndA?.multiAnswer?.find((q) => q.question === 'Awards and honors')?.answer;

  if (!awards || !Array.isArray(awards) || awards.length === 0) return null;

  const awardsToShow = showAll ? awards : awards.slice(0, numAwardsToShow);

  return (
    <Block
      title={
        <Content>
          <Bolder>Awards and honors</Bolder>
        </Content>
      }
      addWhiteBG={true}
      tertiaryTitle={true}
      className={styles.marginBottom}
    >
      <div className={styles.container}>
        {awardsToShow.map((award) => (
          <Content title={award} key={award} className={styles.award}>
            {award && award.length > maxAwardSize ? `${award.substr(0, maxAwardSize)}...` : award}
          </Content>
        ))}
      </div>
      {showAll && awards.length > numAwardsToShow ? (
        <div className={styles.showMoreContainer}>
          <Button onClick={() => setShowAll(false)} className={styles.showMore} tertiary={true}>
            Show less <FontIcon className={styles.showMoreIcon} iconName="caret-up" />
          </Button>
        </div>
      ) : !showAll && awards.length > numAwardsToShow ? (
        <div className={styles.showMoreContainer}>
          <Button onClick={() => setShowAll(true)} className={styles.showMore} tertiary={true}>
            Show more <FontIcon className={styles.showMoreIcon} iconName="caret-down" />
          </Button>
        </div>
      ) : null}
    </Block>
  );
};

export default ComponentErrorBoundaryHOC()(ProfileAwards);
