import { useEffect, useState } from 'react';

import getCandidatePublicProfile from 'api/apiCandidates/getCandidatePublicProfile';

import { handleError } from 'utils/common';

export const useCandidatePublicProfile = (candidateId) => {
  const [publicProfileId, setPublicProfileId] = useState();

  useEffect(() => {
    getCandidatePublicProfile(candidateId)
      .then((resp) => {
        const { data: { publicProfile: { id } = {} } = {} } = resp;
        setPublicProfileId(id);
      })
      .catch(handleError);
  }, [candidateId]);

  return publicProfileId;
};
