import { handleActions } from 'redux-actions';

const initialState = {
  entities: {
    candidate: {
      byId: {},
      allIds: [],
    },
    user: {
      byId: {},
      allIds: [],
    },
  },
  meta: {
    candidate: {
      pagination: {},
    },
  },
};

const actionHandlers = {};

const reduceToolboxCandidates = handleActions(actionHandlers, initialState);

export default reduceToolboxCandidates;
