import { call, put, select, takeLatest } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { deleteEntity } from 'api/apiEntity';

import { candidateProfilePatchDone } from 'connectors/User/redux/creators';

import { selectUser } from 'connectors/User/redux/selectors';

import { WORK_HISTORY_DELETE_STARTED } from '../actions';

const deleteWorkHistory = function* deleteWorkHistory(action) {
  const {
    payload: { workHistoryId },
  } = action;

  if (workHistoryId) {
    try {
      const args = {
        id: workHistoryId,
        type: 'work_histories',
      };

      yield call(deleteEntity, args);

      const { currentProfile } = yield select(selectUser);

      const { workHistories } = currentProfile;

      const newWorkHistories = workHistories.filter((wh) => wh.id !== workHistoryId);

      const candidateProfile = {
        ...currentProfile,
        workHistories: newWorkHistories,
      };

      yield put(candidateProfilePatchDone({ candidateProfile }));
    } catch (error) {
      handleError(error);
    }
  }
};

const sagaWorkHistoryDeleteStarted = function* sagaWorkHistoryDeleteStarted() {
  yield takeLatest(WORK_HISTORY_DELETE_STARTED, deleteWorkHistory);
};

export default sagaWorkHistoryDeleteStarted;
