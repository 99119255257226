import React, { useState, useRef, useCallback } from 'react';
import v4 from 'uuid';
import isEmpty from 'lodash.isempty';
import classnames from 'classnames';

import { useOnClickOutside } from 'hooks';
import { VisibleHOC } from 'containers/HOC';

import FontIcon from 'components/FontIcon';
import Label from 'components/Label';

import styles from './InputSelect.scss';

const InputSelect = ({
  handleInputChange,
  name,
  autoFocus,
  className,
  internalClassName,
  optionsContainerClassName,
  defaultLabelClassName,
  source,
  label,
  value,
  required,
  defaultIcon,
  activeIcon,
  defaultLabel,
  disabled,
  fullWidth,
  noMargin,
  darkIcon,
  isVisible,
  setWidth,
  bigInput,
  highlight,
  // eslint-disable-next-line no-unused-vars
  size,
  // eslint-disable-next-line no-unused-vars
  type,
  ...restProps
}) => {
  const [active, setActive] = useState(false);
  const ref = useRef();

  const onClick = () => {
    setActive((active) => (disabled ? false : !active));
  };

  const onSelect = (newValue) => {
    if (active) {
      const event = {
        target: {
          name,
          value: newValue,
          type: 'select',
        },
      };

      handleInputChange(event);

      setActive(false);
    }
  };

  useOnClickOutside(
    ref,
    useCallback(() => setActive(false), [])
  );

  const selectedItem = source.filter((item) => {
    if (item.value?.type) {
      return item.value[item.value.type] === value[value.type];
    }
    return `${item.value}` === `${value}`;
  });

  const currentItemOne = selectedItem.length > 0 ? selectedItem[0] : defaultLabel;
  const currentItemTwo =
    value === 'Non-sales' && name === 'role' ? { label: 'Non-sales', value: 'Non-sales' } : null;

  const currentItem = currentItemTwo || currentItemOne;

  const selectClasses = classnames(
    styles.selectStyle,
    {
      [styles.noMarginTop]: noMargin,
      [styles.requiredRelative]: required && !label,
    },
    className
  );

  const inputClasses = classnames(
    styles.inputStyle,
    {
      [styles.active]: active,
      [styles.fullWidth]: fullWidth,
      [styles.big]: bigInput,
      [styles.disabled]: disabled,
      [styles.focus]: highlight,
    },
    internalClassName
  );

  const optionsContainerClasses = classnames(
    {
      [styles.OptionsContainer]: isVisible,
      [styles.bigOptionsContainer]: bigInput,
      [styles.OptionsContainerAbove]: !isVisible,
      [styles.OptionsContainerAboveBig]: !isVisible && bigInput,
      [styles.fullWidthOptions]: fullWidth,
    },
    optionsContainerClassName
  );

  const makeOptions = (option) => {
    const { disabled, indent, label, value: optionValue } = option;

    const isCurrentItem = () => {
      if (currentItem) {
        if (currentItem.value && currentItem.value.type) {
          return currentItem.value[currentItem.value.type] === optionValue[optionValue.type];
        }
        return currentItem.value === optionValue;
      }
      return false;
    };

    const optionClasses = classnames(styles.Option, {
      [styles.activeOption]: isCurrentItem(),
      [styles.disabledOption]: disabled,
    });

    return (
      <div
        className={optionClasses}
        key={v4()}
        onClick={disabled ? () => null : () => onSelect(optionValue)}
        value={optionValue}
      >
        {indent && indent > 0
          ? new Array(indent).fill(<span className={styles.indent}>&mdash;</span>)
          : null}
        {label}
      </div>
    );
  };

  const wrappedContent = active ? (
    <div
      onClick={(event) => event.stopPropagation()}
      style={{ width: setWidth }}
      className={optionsContainerClasses}
    >
      {source.map(makeOptions)}
    </div>
  ) : null;

  const defaultLabelClasses = classnames(styles.inPlaceDefaultLabel, defaultLabelClassName, {
    [styles.disabled]: disabled,
  });

  const iconClasses = classnames({
    [styles.dark]: darkIcon,
  });

  const defaultLabelContent =
    !value ||
    value === '' ||
    value.length === 0 ||
    (typeof value === 'object' && isEmpty(value)) ? (
      <div className={defaultLabelClasses} data-testid="defaultLabel">
        {defaultLabel}
      </div>
    ) : (
      <div className={styles.selectedValue} data-testid="defaultLabel">
        {currentItem && currentItem.label ? currentItem.label : defaultLabel}
      </div>
    );

  return (
    <button
      type="button"
      className={selectClasses}
      autoFocus={autoFocus}
      ref={ref}
      onClick={onClick}
      {...restProps}
    >
      {label ? (
        <Label required={required} className={classnames(styles.labelStyle)}>
          {label}
        </Label>
      ) : null}
      <div className={classnames(styles.relative)}>
        <div style={{ width: setWidth }} className={inputClasses}>
          {defaultLabelContent}
          {active && activeIcon ? (
            <FontIcon className={iconClasses} iconName={activeIcon} />
          ) : defaultIcon ? (
            <FontIcon className={iconClasses} iconName={defaultIcon} />
          ) : null}
        </div>
        {wrappedContent}
      </div>
    </button>
  );
};

export default VisibleHOC()(InputSelect);
