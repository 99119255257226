import React from 'react';
import dayjs from 'dayjs';

import EmptyEducationLogo from 'shared/assets/missing-education-organization-picture.png';

import Input from 'components/Input';
import InputCheckbox from 'components/InputCheckbox';
import InputSelect from 'components/InputSelect';
import Button from 'components/Button';
import Label from 'components/Label';
import Tag from 'components/Tag';

import AutocompleteServer from 'connectors/AutocompleteServer';
import { makeYears } from 'utils/formUtils';

import styles from './EducationInputs.scss';

const EducationInputs = ({
  education,
  idx,
  handleEducationToggleArray,
  handleEducationOrganizationChange,
  handleEducationOrganizationBlur,
  handleEducationDateInputChange,
  handleCurrentEducationInputChange,
  handleEducationInputChange,
  handleEducationDelete,
  handleToggleEditingEdu,
  handleCancelEditingEdu,
  recentGrad,
  autofocusFields = [],
}) => {
  const {
    extracurricularActivities: extraActivities,
    educationOrganization: { name: educationOrgName, picture: { medium: orgLogo } = {} } = {},
    startedAt: startDate,
    finishedAt: endDate,
    degree,
    gpa = '',
  } = education;

  const picUrl = orgLogo === '' ? EmptyEducationLogo : orgLogo;

  const startDateYear = startDate ? dayjs(startDate, 'YYYY-MM-DD').get('year') : '';
  const endDateYear = endDate ? dayjs(endDate, 'YYYY-MM-DD').get('year') : '';

  const commonTextInputProps = {
    handleInputChange: (event) => handleEducationInputChange({ event, idx }),
    type: 'text',
    size: 'xxlarge',
    topLabel: true,
    disabled: false,
  };

  const educationNameInputProps = {
    required: true,
    placeholder: 'Where did you attend?',
    size: 'full',
    value: educationOrgName || '',
    label: 'Institution',
    handleInputChange: (event) => handleEducationOrganizationChange({ event, idx }),
    handleOnBlur: (event) => handleEducationOrganizationBlur({ event, idx }),
    hasLogo: true,
    autocompleteType: 'education_organizations',
    field: 'name',
  };

  const startDateYearInputProps = {
    ...commonTextInputProps,
    handleInputChange: (event) => handleEducationDateInputChange({ event, idx }),
    name: 'startDateYear',
    source: makeYears(),
    value: startDateYear,
    defaultLabel: 'Year',
    defaultIcon: 'caret-down',
    autoFocus: autofocusFields.includes('start_date_year'),
    highlight: autofocusFields.includes('start_date_year'),
  };

  const endDateYearInputProps = {
    ...commonTextInputProps,
    handleInputChange: (event) => handleEducationDateInputChange({ event, idx }),
    name: 'endDateYear',
    source: makeYears(parseInt(startDateYear, 10)),
    value: endDateYear,
    defaultLabel: 'Year',
    defaultIcon: 'caret-down',
    autoFocus: autofocusFields.includes('end_date_year'),
    highlight: autofocusFields.includes('end_date_year'),
  };

  const endDateYearContent =
    endDate !== null ? (
      <InputSelect {...endDateYearInputProps} />
    ) : (
      <span className={styles.presentLabel}>Present</span>
    );

  const currentlyEnrolledHereInputProps = {
    name: 'currentlyEnrolled',
    label: 'I am currently enrolled',
    checked: endDate === null || false,
    handleInputChange: (event) => handleCurrentEducationInputChange({ event, idx }),
    className: styles.inputCheckbox,
    checkboxMany: true,
  };

  const degreeInputProps = {
    ...commonTextInputProps,
    name: 'degree',
    size: 'full',
    value: degree || '',
    placeholder: 'What degree did you earn?',
    label: 'Degree / Certificate',
    autoFocus: autofocusFields.includes('degree'),
    highlight: autofocusFields.includes('degree'),
  };

  const gpaInputProps = {
    ...commonTextInputProps,
    name: 'gpa',
    size: 'small',
    type: 'number',
    value: gpa || '',
    placeholder: '',
    required: recentGrad,
    label: 'GPA',
    autoFocus: autofocusFields.includes('gpa'),
    highlight: autofocusFields.includes('gpa'),
  };

  const extraActivitiesProps = {
    placeholder: '',
    value: '',
    size: 'full',
    name: 'extracurricularActivities',
    label: 'Extracurriculars',
    handleInputChange: (event) =>
      handleEducationToggleArray({
        event: {
          ...event,
          target: {
            ...event.target,
            name: event.target.name,
            value: event.target.value.name,
          },
        },
        idx,
      }),
    handleOnBlur: (event) => handleEducationToggleArray({ event, idx }),
    topLabel: true,
    autocompleteType: 'extracurricular_activities',
    field: 'name',
    needReset: true,
    required: recentGrad,
    hasAddButton: true,
    autoFocus: autofocusFields.includes('extracurricular_activities'),
    highlight: autofocusFields.includes('extracurricular_activities'),
  };

  const extraActivitiesSelected = extraActivities.map((activity) => {
    const tagProps = {
      name: activity,
      inputName: 'extracurricularActivities',
      onDeleteClick: (name, inputName) =>
        handleEducationToggleArray({
          event: {
            target: {
              value: name,
              name: inputName,
            },
          },
          idx,
        }),
    };
    return <Tag key={activity} {...tagProps} />;
  });

  const cancelButtonProps = {
    quaternary: true,
    onClick: () => handleCancelEditingEdu(idx),
  };

  const validGPA = recentGrad ? Boolean(gpa) : true;
  const validExtras = recentGrad ? extraActivities && extraActivities.length > 0 : true;

  const isValid = educationOrgName && educationOrgName !== '' && validGPA && validExtras;

  const saveButtonProps = {
    primary: true,
    onClick: isValid ? () => handleToggleEditingEdu(idx) : null,
    disabled: !isValid,
  };

  const deleteButtonProps = {
    className: styles.deleteButton,
    warningTertiary: true,
    onClick: (event) => handleEducationDelete({ event, idx }),
  };

  const deleteButton = education.id ? <Button {...deleteButtonProps}>Delete</Button> : null;

  return (
    <div key={idx} className={styles.basicInfoContainer}>
      <div className={styles.orgLogo}>
        <img className={styles.logo} src={picUrl} alt="" />
      </div>
      <div className={styles.basicInfoColumnInputs}>
        <AutocompleteServer {...educationNameInputProps} />
        <Input {...degreeInputProps} />
        <Label className={styles.label}>Years</Label>
        <div className={styles.basicInfoRowInputSelects}>
          <span className={styles.labelSubtext}>From</span>
          <InputSelect {...startDateYearInputProps} />
          <span className={styles.labelSubtext}>to</span>
          {endDateYearContent}
        </div>
        <div className={styles.currentPositionCheckbox}>
          <InputCheckbox {...currentlyEnrolledHereInputProps} />
        </div>
        <AutocompleteServer {...extraActivitiesProps} />
        <div className={styles.autocompleteContainer}>{extraActivitiesSelected}</div>
        <Input {...gpaInputProps} />
        <div className={styles.actionButtons}>
          {deleteButton}
          <div className={styles.buttonsRight}>
            <Button {...cancelButtonProps}>Cancel</Button>
            <Button {...saveButtonProps}>Save</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationInputs;
