const prefix = 'Rainmakers/Candidate/Onboarding/';

export const APPLY_STARTED = `${prefix}APPLY_STARTED`;
export const APPLY_DONE = `${prefix}APPLY_DONE`;
export const FORUM_APPLY_STARTED = `${prefix}FORUM_APPLY_STARTED`;
export const FORUM_APPLY_DONE = `${prefix}FORUM_APPLY_DONE`;
export const SET_REQUIREMENTS_STARTED = `${prefix}SET_REQUIREMENTS_STARTED`;
export const SET_REQUIREMENTS_DONE = `${prefix}SET_REQUIREMENTS_DONE`;
export const ADD_SALES_INFO_STARTED = `${prefix}ADD_SALES_INFO_STARTED`;
export const ADD_SALES_INFO_DONE = `${prefix}ADD_SALES_INFO_DONE`;
export const ADD_VIDEO_STARTED = `${prefix}ADD_VIDEO_STARTED`;
export const CREATE_LEAD_STARTED = `${prefix}CREATE_LEAD_STARTED`;

export const ONBOARDING_ERRORS_CHECK = `${prefix}ONBOARDING_ERRORS_CHECK`;
export const ONBOARDING_ERRORS_SET = `${prefix}ONBOARDING_ERRORS_SET`;

export const UPLOAD_RESUME_STARTED = `${prefix}UPLOAD_RESUME_STARTED`;
export const UPLOAD_RESUME_DONE = `${prefix}UPLOAD_RESUME_DONE`;
export const PARSE_RESUME_STARTED = `${prefix}PARSE_RESUME_STARTED`;
export const PARSE_RESUME_DONE = `${prefix}PARSE_RESUME_DONE`;

export const PATCH_CANDIDATE_STARTED = `${prefix}PATCH_CANDIDATE_STARTED`;
export const PATCH_CANDIDATE_DONE = `${prefix}PATCH_CANDIDATE_DONE`;

export const PATCH_CANDIDATE_PERSONAL_STARTED = `${prefix}PATCH_CANDIDATE_PERSONAL_STARTED`;
export const PATCH_CANDIDATE_PERSONAL_DONE = `${prefix}PATCH_CANDIDATE_PERSONAL_DONE`;

export const SERVER_ERROR_SET = `${prefix}SERVER_ERROR_SET`;

export const CANDIDATE_IMG_UPLOAD_STARTED = `${prefix}CANDIDATE_IMG_UPLOAD_STARTED`;
export const CANDIDATE_IMG_UPLOAD_DONE = `${prefix}CANDIDATE_IMG_UPLOAD_DONE`;

export const PATCH_WORK_HISTORY_STARTED = `${prefix}PATCH_WORK_HISTORY_STARTED`;
export const PATCH_WORK_HISTORY_DONE = `${prefix}PATCH_WORK_HISTORY_DONE`;

export const REORDER_WORK_STARTED = `${prefix}REORDER_WORK_STARTED`;
export const REORDER_WORK_DONE = `${prefix}REORDER_WORK_DONE`;

export const REORDER_EDUCATION_STARTED = `${prefix}REORDER_EDUCATION_STARTED`;
export const REORDER_EDUCATION_DONE = `${prefix}REORDER_EDUCATION_DONE`;

export const WORK_HISTORY_DELETE_STARTED = `${prefix}WORK_HISTORY_DELETE_STARTED`;
export const WORK_HISTORY_DELETE_DONE = `${prefix}WORK_HISTORY_DELETE_DONE`;

export const CANDIDATE_EDUCATION_DELETE_STARTED = `${prefix}CANDIDATE_EDUCATION_DELETE_STARTED`;
export const CANDIDATE_EDUCATION_DELETE_DONE = `${prefix}CANDIDATE_EDUCATION_DELETE_DONE`;

export const RECENT_ROLE_SET = `${prefix}RECENT_ROLE_SET`;
