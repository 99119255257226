import React from 'react';
import { Helmet } from 'react-helmet-async';

import ReadyContainer from 'connectors/ReadyContainer';

import Content from 'components/Content';
import Title from 'components/Title';
import Block from 'components/Block';

import styles from './Thanks.scss';

const Thanks = () => {
  const blockProps = {
    addWhiteBG: true,
    addPadding: true,
  };

  const title = 'Employer Application Success';
  const metaDescription = 'Thanks for applying to Rainmakers';
  const canonicalUrl = `https://${process.env.HOST}/thanks/`;

  const titleString = 'Thanks for Applying!';

  const helmetProps = {
    title,
    meta: [
      { name: 'description', content: metaDescription },
      { property: 'og:url', content: canonicalUrl },
      { property: 'og:title', content: title },
      { property: 'og:description', content: metaDescription },
    ],
    link: [{ rel: 'canonical', href: canonicalUrl }],
  };

  return (
    <React.Fragment>
      <Helmet {...helmetProps} />
      <ReadyContainer className={styles.Thanks}>
        <Block {...blockProps}>
          <Title string={titleString} />
          <Content className={styles.thanksContent}>
            We will contact you soon. If you have any questions, email{' '}
            <span className={styles.contact}>
              <a
                href="mailto:support@rainmakers.co"
                className={styles.mailLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                {' '}
                support@rainmakers.co
              </a>
            </span>
            .
          </Content>
        </Block>
      </ReadyContainer>
    </React.Fragment>
  );
};

export default Thanks;
