import { createAction } from 'redux-actions';

import {
  CANDIDATE_PROFILE_PATCH_DONE,
  RESET_BACK_LINK,
  SET_BACK_LINK,
  SET_DELAY_PROFILE_CARDS,
} from 'shared/store/app/actions';

import {
  CANDIDATE_LOAD_STARTED,
  CANDIDATE_PROFILE_PATCH_STARTED,
} from '../../../CandidateSettingsPages/redux/actions';

import {
  PROFILE_CARDS_LOAD_STARTED,
  PROFILE_CARDS_LOAD_DONE,
  BATCH_REQUEST_LOAD_STARTED,
  BATCH_REQUEST_LOAD_DONE,
  BATCH_REQUEST_PATCH_STARTED,
  BATCH_REQUEST_PATCH_DONE,
  BATCH_REQUEST_REREQUEST_STARTED,
  BATCH_REQUEST_REREQUEST_DONE,
} from '../actions';

export const candidateLoadStarted = createAction(CANDIDATE_LOAD_STARTED);
export const candidatePatchStarted = createAction(CANDIDATE_PROFILE_PATCH_STARTED);

export const profileCardsLoadStarted = createAction(PROFILE_CARDS_LOAD_STARTED);
export const profileCardsLoadDone = createAction(PROFILE_CARDS_LOAD_DONE);

export const batchRequestLoadStarted = createAction(BATCH_REQUEST_LOAD_STARTED);
export const batchRequestLoadDone = createAction(BATCH_REQUEST_LOAD_DONE);

export const batchRequestPatchStarted = createAction(BATCH_REQUEST_PATCH_STARTED);
export const batchRequestPatchDone = createAction(BATCH_REQUEST_PATCH_DONE);

export const batchRequestRerequestStarted = createAction(BATCH_REQUEST_REREQUEST_STARTED);
export const batchRequestRerequestDone = createAction(BATCH_REQUEST_REREQUEST_DONE);

export const setBackLink = createAction(SET_BACK_LINK);
export const resetBackLink = createAction(RESET_BACK_LINK);
export const setDelayProfileCards = createAction(SET_DELAY_PROFILE_CARDS);

export const candidateProfilePatchDone = createAction(CANDIDATE_PROFILE_PATCH_DONE);
