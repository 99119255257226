import React from 'react';

import Dropdown from 'components/Dropdown';

import RequisitionOpeningFilterOption from '../RequisitionOpeningFilterOption';

const RequisitionFilter = ({
  employees,
  handleSearchInputChange,
  loadMoreRequisitionOpenings,
  rainmakersAdmins,
  requisitionOpeningId,
  requisitionOpenings: { allIds = [], byId = {} },
  requisitionOpeningsTotal,
  user,
}) => {
  const initialSource = [
    {
      label: 'All Requisitions',
      value: 'all',
      reqLabel: 'All Requisitions',
    },
  ];

  const infiniteScrollProps = {
    loadMore: loadMoreRequisitionOpenings,
    hasMore: requisitionOpeningsTotal > allIds.length,
    loader: <div />,
    useWindow: false,
  };

  const makeItem = (id) => {
    const { [id]: { attributes: { externalName } = {} } = {} } = byId;

    const requisitionDetailOptionProps = {
      employees,
      rainmakersAdmins,
      requisitionOpening: byId[id],
      handleSearchInputChange,
      requisitionOpeningId,
      user,
    };

    const label = <RequisitionOpeningFilterOption {...requisitionDetailOptionProps} />;

    return { label, value: id, reqLabel: externalName };
  };

  const source =
    allIds.length > 0 ? Array.prototype.concat(initialSource, allIds.map(makeItem)) : initialSource;

  const dropdownProps = {
    infiniteScrollProps,
    source,
    onChange: (reqId) =>
      handleSearchInputChange({
        target: {
          value: reqId,
          name: 'requisitionOpeningId',
        },
      }),
    value: allIds.length > 0 ? requisitionOpeningId : null,
    defaultValue: 'all',
    reqFilter: true,
    defaultIcon: 'up-down-caret',
  };

  return <Dropdown {...dropdownProps} />;
};

export default RequisitionFilter;
