import React from 'react';
import classnames from 'classnames';

import FormattedName from 'components/FormattedName';
import { useImageField } from 'hooks';

import styles from './AdminData.scss';

const AdminData = ({
  admin = {},
  className,
  onClick,
  subtitle,
  boldSubtitle,
  largeAvatar,
  sideTable,
}) => {
  const { firstName, lastName, user: { lastName: userLastName } = {} } = admin.attributes;

  const classes = classnames(
    styles.AdminData,
    {
      [styles.sideTable]: sideTable,
    },
    className
  );

  const infoWrapperClasses = classnames(styles.adminDataInfoWrapper, {
    [styles.adminDataInfoWrapperShort]: sideTable,
  });

  const titleClasses = classnames(styles.adminDataTitle, {
    [styles.adminDataTitleSmall]: sideTable,
  });

  const subtitleClasses = classnames(styles.adminDataSubtitle, {
    [styles.adminDataSubtitleBold]: boldSubtitle,
  });

  const avatarClass = classnames(styles.adminDataAvatarWrapper, {
    [styles.adminDataAvatarLargeWrapper]: largeAvatar,
    [styles.adminDataAvatarSmallWrapper]: sideTable,
  });

  const { imageComponent } = useImageField({
    model: admin,
    avatarClass,
  });

  const adminProps = {
    className: classes,
    onClick,
  };

  const formattedNameProps = {
    className: styles.adminName,
    firstName,
    lastName: lastName || userLastName || '',
  };

  const subtitleString = subtitle ? <div className={subtitleClasses}>{subtitle}</div> : null;

  return (
    <div {...adminProps}>
      {imageComponent}

      <div className={infoWrapperClasses}>
        <div className={titleClasses}>
          <FormattedName {...formattedNameProps} />
        </div>

        {subtitleString}
      </div>
    </div>
  );
};

export default AdminData;
