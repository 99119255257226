import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import Block from 'components/Block';
import AutocompleteServer from 'connectors/AutocompleteServer';
import EmployerData from 'components/EmployerData';
import FormattedName from 'components/FormattedName';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';

import PermBlockActionDropdown from 'pages/AdminPortal/ToolboxEmployerEditorPage/components/EmployerEditorSettings/components/PermBlockActionDropdown';

import ContributingCohorts from '../ContributingCohorts';

import styles from './BlockedSettings.scss';

const BlockedSettings = (props) => {
  const {
    actions: { deleteResource, showModal },
    candidate: { attributes: { active = true, hiringStatus, applicationStatus } = {} } = {},
    cohortLinksTemporary: { allIds: cohortLinksAllIds = [], byId: cohortLinksById = {} } = {},
    cohortLinksTemporaryTotal,
    companies: { byId: companiesById = {} } = {},
    loadMoreCohortLinksTemporary,
    candidateCompanyBlocks: { allIds: ccBlocksAllIds = [], byId: ccBlocksById = {} } = {},
    candidateCompanyBlocksTotal,
    employers: { byId: employersById = {} } = {},
    loadMoreCandidateCompanyBlocks,
    handlePermBlockChange,
    modal,
  } = props;

  const globalBlocksBlockProps = {
    addWhiteBG: true,
    largeTitleFont: true,
    title: 'Global Blocks',
    noTitleBorder: true,
    addPadding: true,
    titleClass: styles.titlePadding,
  };

  const permBlocksBlockProps = {
    addWhiteBG: true,
    largeTitleFont: true,
    title: 'Permanent Blocks',
    noTitleBorder: true,
    addPadding: true,
    titleClass: styles.titlePadding,
  };

  const permBlocksInputProps = {
    placeholder: 'Enter company name and select to create permanent block',
    value: '',
    size: 'xlarge',
    name: 'companyId',
    handleInputChange: handlePermBlockChange,
    hasLogo: true,
    autocompleteType: 'companies',
    field: 'name',
    needReset: true,
  };

  const permBlockCompanies = ccBlocksAllIds.map((id) => {
    const {
      [id]: {
        attributes: { blockType, ownerType, creatorType },
        relationships: {
          company: { data: companyIds = [] } = {},
          creator: { data: creatorIds = [], type: creatorTypes = [] } = {},
        } = {},
      } = {},
    } = ccBlocksById || {};

    const creatorTypePlural = `${creatorTypes[0]}s`;

    const { [creatorTypePlural]: { byId: creatorsById = {} } = {} } = props;

    const { attributes: { firstName, lastName } = {} } = creatorsById[creatorIds[0]] || {};

    const { [companyIds[0]]: { attributes: employer = {} } = {} } = companiesById || {};

    const empDataProps = {
      key: id,
      employer,
    };

    const permBlockActionDropdownProps = {
      candidateCompanyBlockId: id,
      deleteResource,
      slice: 'toolboxCandidates',
    };

    const ownerContent = ownerType === 'RainmakersAdmin' ? 'Admin' : ownerType;

    const nameProps = {
      firstName,
      lastName,
    };

    return (
      <div key={id} className={styles.empContent}>
        <div className={styles.empData}>
          <EmployerData {...empDataProps} />
        </div>
        <div className={styles.blockerType}>
          {ownerContent} ({blockType})
        </div>
        <div className={styles.blockerType}>{creatorType || 'System'}</div>
        <div className={styles.blockerType}>
          <FormattedName {...nameProps} />
        </div>
        <div className={styles.actionContent}>
          <PermBlockActionDropdown {...permBlockActionDropdownProps} />
        </div>
      </div>
    );
  });

  const permBlocksInfiniteScrollProps = {
    pageStart: 0,
    loadMore: loadMoreCandidateCompanyBlocks,
    hasMore: candidateCompanyBlocksTotal > ccBlocksAllIds.length,
    loader: <InfiniteScrollLoader />,
    useWindow: false,
  };

  const tempBlocksBlockProps = {
    addWhiteBG: true,
    largeTitleFont: true,
    title: 'Temporary Blocks',
    noTitleBorder: true,
    addPadding: true,
    titleClass: styles.titlePadding,
  };

  const tempBlocksSearchInputProps = {
    placeholder: 'Search for Companies',
    value: '',
    size: 'xlarge',
    name: 'companyId',
    handleInputChange: () => console.log('feature not ready'),
    hasLogo: true,
    autocompleteType: 'companies',
    field: 'name',
    needReset: true,
  };

  // Remove perm blocks from temp blocks
  const cohortLinks = cohortLinksAllIds.map((id) => cohortLinksById[id]);
  const tempBlocksFiltered = cohortLinks.filter(
    ({ attributes: { contributingCohortIds: cohortIds } = {} }) => cohortIds && cohortIds.length > 0
  );

  const tempBlockCompanies = tempBlocksFiltered.map((block) => {
    const {
      attributes: { contributingCohortIds: cohortIds = [] } = {},
      id: blockId,
      relationships: { employer: { data: employerIds = [] } = {} } = {},
    } = block || {};

    const { [employerIds[0]]: { attributes: employer = {} } = {} } = employersById || {};

    const empDataProps = {
      key: blockId,
      employer,
    };

    const contributingCohortsProps = {
      cohortIds,
      modal,
      showModal,
    };

    return (
      <div key={blockId} className={styles.empContent}>
        <div className={styles.empData}>
          <EmployerData {...empDataProps} />
        </div>
        <ContributingCohorts {...contributingCohortsProps} />
      </div>
    );
  });

  const tempBlocksInfiniteScrollProps = {
    pageStart: 0,
    loadMore: loadMoreCohortLinksTemporary,
    hasMore: cohortLinksTemporaryTotal > cohortLinksAllIds.length,
    loader: <InfiniteScrollLoader />,
    useWindow: false,
  };

  const activeHiringStatusText = hiringStatus === 'active' ? 'Actively Interviewing' : null;
  const inactiveHiringStatusText = hiringStatus === 'inactive' ? 'Not Looking' : null;
  const passiveHiringStatusText = hiringStatus === 'passive' ? 'Passively Looking' : null;

  const hiringStatusText =
    activeHiringStatusText || inactiveHiringStatusText || passiveHiringStatusText || '';

  const blockedByAppStatus = applicationStatus === 'pending';

  const blockedByActiveStatus = !active;
  const blockedByHiringStatus = hiringStatus === 'inactive';

  const isGloballyBlocked =
    blockedByAppStatus || blockedByActiveStatus || blockedByHiringStatus || false;

  const globalBlockText = isGloballyBlocked ? (
    <span className={styles.warning}>Candidate is globally blocked</span>
  ) : (
    <span className={styles.info}>Candidate is not globally blocked</span>
  );

  return (
    <div className={styles.activeContent}>
      <Block {...globalBlocksBlockProps}>
        <div className={styles.globalBlocksContainer}>
          {globalBlockText}
          <div className={styles.blockedStatuses}>
            <span>Application Status:</span>{' '}
            <span className={styles.subtext}>{applicationStatus}</span>
          </div>
          <div className={styles.blockedStatuses}>
            <span>Active Status:</span>{' '}
            <span className={styles.subtext}>{active ? 'Active' : 'Inactive'}</span>
          </div>
          <div className={styles.blockedStatuses}>
            <span>Hiring Status:</span> <span className={styles.subtext}>{hiringStatusText}</span>
          </div>
        </div>
      </Block>
      <Block {...permBlocksBlockProps}>
        <div className={styles.permBlocksContainer}>
          <AutocompleteServer {...permBlocksInputProps} />
        </div>
        <div className={styles.tempBlocksHeader}>
          <div className={styles.companyHeader}>Company</div>
          <div className={styles.checkboxHeader}>Owner (type)</div>
          <div className={styles.checkboxHeader}>Creator</div>
          <div className={styles.checkboxHeader}>Creator Name</div>
          <div className={styles.cohortActionHeader} />
        </div>
        <div className={styles.companiesContainer}>
          <InfiniteScroll {...permBlocksInfiniteScrollProps}>{permBlockCompanies}</InfiniteScroll>
        </div>
      </Block>
      <Block {...tempBlocksBlockProps}>
        <div className={styles.tempBlocksContainer}>
          <AutocompleteServer {...tempBlocksSearchInputProps} />
        </div>
        <div className={styles.tempBlocksHeader}>
          <div className={styles.companyHeader}>Company</div>
          <div className={styles.checkboxHeader}>Cohort/s</div>
        </div>
        <div className={styles.companiesContainer}>
          <InfiniteScroll {...tempBlocksInfiniteScrollProps}>{tempBlockCompanies}</InfiniteScroll>
        </div>
      </Block>
    </div>
  );
};

export default BlockedSettings;
