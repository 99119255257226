import React from 'react';

import { trackEvent } from 'utils/analytics';
import { segmentComponentClicked } from 'segment/eventNames';

import { ComponentErrorBoundaryHOC } from 'containers/HOC';

import Bold from 'components/Bold';
import Bolder from 'components/Bolder';
import FormattedName from 'components/FormattedName';
import CandidateImage from 'components/CandidateImage';
import Content from 'components/Content';
import SocialMediaLink from 'components/SocialMediaLink';

import { extractLocation } from 'utils/locationUtils';

import styles from './ProfileHeader.scss';

export const ProfileHeader = ({
  alwaysUseHideName,
  candidate,
  currentPosition: { company: { name: companyName = '' } = {}, position: positionName = '' } = {},
  handleShowTheater,
  socialMedia,
  resume,
  videoLink,
  useHideNames,
  useBlankHideNames,
  id: candidateId,
}) => {
  const {
    firstName,
    lastName,
    picture: { xlarge: pictureUrl = '' } = {},
    vimeoVideoEnabled,
    location,
  } = candidate || {};

  const locationFormatted = extractLocation(location);

  const formattedNameProps = {
    alwaysUseHideName,
    useHideNames,
    useBlankHideNames,
    candidateId,
    firstName,
    lastName,
    placeholderClassname: styles.placeholder,
  };

  const candidateImageProps = {
    alwaysUseHideName,
    candidateId,
    pictureUrl,
    useHideNames: useHideNames || useBlankHideNames,
  };

  const resumeLinkProps = {
    label: 'Resume',
    iconName: 'resume',
    to: resume !== '/resumes/original/missing.png' ? resume : '',
    onClick: () =>
      trackEvent({
        event: segmentComponentClicked,
        properties: {
          componentName: 'Resume Link',
          page: 'Candidate Profile',
        },
      }),
    fullUrl: true,
  };

  const { twitter, linkedin } = socialMedia || {};

  const { url: twitterUrl = '' } = twitter || {};

  const { url: linkedinUrl = '' } = linkedin || {};

  const linkedInLinkProps = {
    label: 'LinkedIn',
    iconName: 'linkedin-16',
    to: linkedinUrl,
    onClick: () =>
      trackEvent({
        event: segmentComponentClicked,
        properties: {
          componentName: 'LinkedIn Link',
          page: 'Candidate Profile',
        },
      }),
    fullUrl: true,
  };

  const twitterLinkProps = {
    label: 'Twitter',
    iconName: 'twitter-16',
    onClick: () =>
      trackEvent({
        event: segmentComponentClicked,
        properties: {
          componentName: 'Twitter Link',
          page: 'Candidate Profile',
        },
      }),
    to: twitterUrl,
    fullUrl: true,
  };

  const videoLinkProps = {
    label: 'Video',
    iconName: 'video-camera',
    onClick: handleShowTheater,
    show: handleShowTheater && videoLink && vimeoVideoEnabled,
  };

  return (
    <div className={styles.profileHeaderContainer} data-testid="ProfileHeader">
      <CandidateImage {...candidateImageProps} />
      <Content className={styles.flexColumn}>
        <div className={styles.profileName}>
          <Bolder>
            <FormattedName {...formattedNameProps} />
          </Bolder>
          {locationFormatted ? (
            <div className={styles.profileStatus}>
              <Bold>{locationFormatted}</Bold>
            </div>
          ) : null}
        </div>
        <div className={styles.profileCompany}>
          {positionName || null}
          <Bold>{companyName ? `@ ${companyName}` : null}</Bold>
        </div>
        <Content className={styles.socialMedia}>
          <SocialMediaLink {...linkedInLinkProps} />
          <SocialMediaLink {...twitterLinkProps} />
          <SocialMediaLink {...resumeLinkProps} />
          <SocialMediaLink {...videoLinkProps} />
        </Content>
      </Content>
    </div>
  );
};

export default ComponentErrorBoundaryHOC()(ProfileHeader);
