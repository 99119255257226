import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import isEmpty from 'lodash.isempty';
import queryString from 'query-string';

import ConnectContainer from 'containers/ConnectContainer';
import { ModalHOC, ProfileAuthHOC } from 'containers/HOC';
import ReadyContainer from 'connectors/ReadyContainer';

import { handleError } from 'utils/common';
import { trackEvent } from 'utils/analytics';

import { segmentAddBookmark, segmentRemoveBookmark } from 'segment/eventNames';

import { promiseGetFullCandidate } from 'pages/EmployeePortal/CandidateProfilePages/promises';
import { promiseGetAdmin } from 'pages/AdminPortal/ToolboxAdminsPage/promises';

import {
  promiseBookmarksAdd,
  promiseBookmarksRemove,
} from 'pages/EmployeePortal/CandidateSearchPage/promises';

import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import NotFound from 'pages/UtilPages/NotFound';
import TableOverlay from 'components/TableOverlay';
import CandidateProfile from '../CandidateProfile';
import CandidateProfilePrint from '../CandidateProfilePrint/CandidateProfilePrint';
import CandidateEmpty from '../CandidateEmpty';

import duck from '../redux';

const PARENT_VIEWS = {
  manage: 'dashboard',
  featured: 'featured',
  matches: 'matches',
  saved: 'bookmarked',
};

const tpValues = {
  d: 'dashboard',
  f: 'featured',
  m: 'matches',
  b: 'bookmarked',
  adbr: 'adminBatchRequest',
};

const hideNameTPValues = ['f', 'm', 'b'];

@ProfileAuthHOC()
@ModalHOC({
  paginatorUrl: '/candidates/:id/profile',
  centered: true,
})
class CandidateWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCandidateLoading: true,
      candidateProfile: {},
    };
  }

  componentDidMount() {
    const {
      actions: { entityLoadStarted, requisitionDetailsLoadStarted },
      isAdmin,
      isEmployee,
      loggedInSearchEmployerId,
      match: { params: { id: candidateId } = {} } = {},
    } = this.props;

    if (isEmployee || (isAdmin && loggedInSearchEmployerId)) {
      requisitionDetailsLoadStarted({
        page: 1,
        employerId: loggedInSearchEmployerId,
      });

      entityLoadStarted({
        type: 'requisition_candidates',
        slice: 'employerCandidates',
        params: {
          candidateId,
          employerId: loggedInSearchEmployerId,
        },
        updateOnly: true,
      });
    }

    if (candidateId) {
      promiseGetFullCandidate({
        isEmployee,
        candidateId,
        contextOrigin: this.getParentView(),
        includeBookmark: isEmployee,
        noPrivate: true,
        noUser: true,
      })
        .then(this.onCandidateSuccess)
        .catch(this.onCandidateFail);
    } else {
      this.onCandidateFail(Error('CandidareWrapper error'));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      actions: { requisitionDetailsLoadStarted, resetNeedReload },
      isEmployee,
      isAdmin,
      loggedInSearchEmployerId,
      needReload,
      match: { params: { id } = {} } = {},
    } = nextProps;

    const {
      loggedInSearchEmployerId: currLoggedInSearchEmployerId,
      match: { params: { id: currId } = {} } = {},
    } = this.props;

    if (loggedInSearchEmployerId && loggedInSearchEmployerId !== currLoggedInSearchEmployerId) {
      requisitionDetailsLoadStarted({
        page: 1,
        employerId: loggedInSearchEmployerId,
      });
    }

    if ((id && id !== currId) || needReload) {
      if (needReload) {
        resetNeedReload();
      }

      this.setState({ isCandidateLoading: !needReload });
      promiseGetFullCandidate({
        isEmployee,
        candidateId: id,
        contextOrigin: this.getParentView(),
        includeBookmark: isEmployee,
        noPrivate: true,
        noUser: true,
      })
        .then(this.onCandidateSuccess)
        .catch(this.onCandidateFail);

      if (isEmployee || (isAdmin && loggedInSearchEmployerId)) {
        requisitionDetailsLoadStarted({
          page: 1,
          employerId: loggedInSearchEmployerId,
        });
      }
    }
  }

  componentWillUnmount() {
    const {
      actions: { resetCandidate },
    } = this.props;

    resetCandidate();
  }

  onCandidateSuccess = (candidateProfile) => {
    const { rainmakers_admin_id: adminId } = candidateProfile;
    this.setState({
      candidateProfile: {
        ...candidateProfile,
        rainmakers_admin: {},
      },
      isCandidateLoading: false,
    });

    if (adminId) {
      promiseGetAdmin({
        adminId,
        includeSet: 'user',
      })
        .then(({ admin }) =>
          this.setState((prevState) => ({
            candidateProfile: {
              ...prevState.candidateProfile,
              rainmakers_admin: admin,
            },
          }))
        )
        .catch(handleError);
    }
  };

  onCandidateFail = (error) => {
    handleError(error);

    this.setState({
      isCandidateLoading: false,
    });
  };

  onBookmarkSuccess = (response) => {
    const {
      data: { bookmark },
    } = response;

    const {
      match: { params: { id: candidateId } = {} } = {},
      location,
      modal,
      actions: { resourceUpdate, profileBookmarkAdd },
    } = this.props;

    resourceUpdate({
      id: bookmark.id,
      resource: {
        attributes: bookmark,
        id: bookmark.id,
        type: 'bookmark',
        relationships: {
          candidate: { data: [bookmark.candidateId] },
        },
      },
      slice: 'employerBookmarks',
      type: 'bookmarks',
    });

    const { candidateProfile } = this.state;

    profileBookmarkAdd({ bookmark, candidateId });

    const { search, pathname } = location;

    const { tp = null } = queryString.parse(search);

    const { originalUrl } = modal;

    const origPathname = originalUrl ? originalUrl.split('/')[1] : null;

    const parentViewPathname = origPathname ? PARENT_VIEWS[origPathname] : null;

    const splitPathname = pathname.split('/').slice(-2)[0];

    const lockedPathname = splitPathname === 'profile' ? 'locked_link' : null;

    const trackPage = tp ? tpValues[tp] : null;

    const parentView = trackPage || lockedPathname || parentViewPathname;

    this.trackBookmarkAdd(bookmark.id, candidateProfile, parentView);

    this.setState((prevState) => ({
      candidateProfile: {
        ...prevState.candidateProfile,
        bookmark,
      },
    }));
  };

  onBookmarkRemoveSuccess = (bookmarkId) => {
    const {
      match: { params: { id: candidateId } = {} } = {},
      actions: { resourceDelete, profileBookmarkRemove },
    } = this.props;

    const {
      candidateProfile: { first_name: candidateFirstName, last_name: candidateLastName } = {},
    } = this.state;

    resourceDelete({
      id: bookmarkId,
      type: 'bookmarks',
      slice: 'employerBookmarks',
    });

    profileBookmarkRemove({ candidateId });

    this.trackBookmarkRemove({
      bookmarkId,
      candidateId,
      candidateFirstName,
      candidateLastName,
    });

    this.setState((prevState) => ({
      oldBookmark: null,
      candidateProfile: {
        ...prevState.candidateProfile,
        bookmark: null,
      },
    }));
  };

  onBookmarkRemoveFail = (error) => {
    handleError(error);

    const { oldBookmark: bookmark } = this.state;

    const {
      match: { params: { id: candidateId } = {} } = {},
      actions: { profileBookmarkAdd },
    } = this.props;

    profileBookmarkAdd({ bookmark, candidateId });

    this.setState((prevState) => ({
      candidateProfile: {
        ...prevState.candidateProfile,
        bookmark,
      },
    }));
  };

  onBookmarkFail = (error) => {
    handleError(error);

    const {
      match: { params: { id: candidateId } = {} } = {},
      actions: { profileBookmarkRemove },
    } = this.props;

    profileBookmarkRemove({ candidateId });

    this.setState((prevState) => ({
      candidateProfile: {
        ...prevState.candidateProfile,
        bookmark: null,
      },
    }));
  };

  getParentView = () => {
    const { location: { search, pathname } = {}, modal: { originalUrl } = {} } = this.props;

    const { tp = null } = queryString.parse(search);

    const origPathname = originalUrl ? originalUrl.split('/')[1] : null;

    const parentViewPathname = origPathname ? PARENT_VIEWS[origPathname] : null;

    const splitPathname = pathname.split('/').slice(-2)[0];

    const lockedPathname = splitPathname === 'profile' ? 'locked_link' : null;

    const trackPage = tp ? tpValues[tp] : null;

    const parentView = trackPage || lockedPathname || parentViewPathname;

    return parentView === 'featured' ? 'search' : parentView;
  };

  trackBookmarkAdd = (bookmarkId, candidate, viewedFromPage) => {
    const event = segmentAddBookmark;

    const properties = {
      bookmarkId,
      candidateId: candidate.id,
      candidateFirstName: candidate.first_name,
      candidateLastName: candidate.last_name,
      candidateFullName: candidate.full_name,
      viewedFromPage,
    };

    const eventObject = { event, properties };

    trackEvent(eventObject);
  };

  trackBookmarkRemove = (args) => {
    const event = segmentRemoveBookmark;

    const properties = { ...args };

    const eventObject = { event, properties };

    trackEvent(eventObject);
  };

  handleBookmarkCandidate = () => {
    const {
      match: { params: { id: candidateId } = {} } = {},
      actions: { profileBookmarkAdd },
    } = this.props;

    const bookmark = { id: 'fakeId', created_at: new Date() };

    profileBookmarkAdd({ bookmark, candidateId });

    this.setState((prevState) => ({
      candidateProfile: {
        ...prevState.candidateProfile,
        bookmark,
      },
    }));

    promiseBookmarksAdd({ candidateId }).then(this.onBookmarkSuccess).catch(this.onBookmarkFail);
  };

  handleRemoveBookmarkCandidate = ({ bookmarkId }) => {
    const {
      match: { params: { id: candidateId } = {} } = {},
      actions: { profileBookmarkRemove },
    } = this.props;

    profileBookmarkRemove({ candidateId });

    this.setState((prevState) => ({
      oldBookmark: prevState.candidateProfile.bookmark,
      candidateProfile: {
        ...prevState.candidateProfile,
        bookmark: null,
      },
    }));

    promiseBookmarksRemove({ bookmarkId })
      .then(() => this.onBookmarkRemoveSuccess(bookmarkId))
      .catch(this.onBookmarkRemoveFail);
  };

  handleGetCandidate = ({ showLoader = true }) => {
    const { match: { params: { id: candidateId } = {} } = {}, isEmployee } = this.props;

    if (showLoader) {
      this.setState({
        isCandidateLoading: true,
      });
    }

    promiseGetFullCandidate({
      isEmployee,
      candidateId,
      includeBookmark: isEmployee,
      noPrivate: true,
      noUser: true,
    })
      .then(this.onCandidateSuccess)
      .catch(this.onCandidateFail);
  };

  handleMakeOffer = ({ candidateId, baseCents, offerSignedDate, startDate, variableCents }) => {
    const {
      actions: { patchResource, patchNestedResource },
      requisitionCandidates: { byId = {}, allIds = [] } = {},
    } = this.props;

    const id = allIds.find((id) => byId[id].attributes.candidateId === candidateId);

    if (id) {
      patchResource({
        attributes: {
          state: 'hired',
          baseCents,
          variableCents,
          offerSignedDate,
          startDate,
        },
        id,
        normalizeIt: true,
        type: 'requisition_candidates',
        slice: 'employerCandidates',
      });

      patchNestedResource({
        attributes: {
          rating: 10,
        },
        id,
        normalizeIt: true,
        slice: 'employerCandidates',
        nestedType: 'private_req_can_feedback',
        type: 'requisition_candidates',
      });
    }
  };

  handlePass = ({ candidateId, feedback, passedOn, rating }) => {
    const {
      actions: { patchResource, patchNestedResource },
      requisitionCandidates: { byId = {}, allIds = [] } = {},
    } = this.props;

    const id = allIds.find((id) => byId[id].attributes.candidateId === candidateId);

    if (id) {
      patchResource({
        attributes: {
          passedOn,
          state: 'employer_passed',
        },
        id,
        normalizeIt: true,
        slice: 'employerCandidates',
        type: 'requisition_candidates',
      });

      patchNestedResource({
        attributes: {
          rating,
          feedback,
        },
        id,
        normalizeIt: true,
        slice: 'employerCandidates',
        nestedType: 'private_req_can_feedback',
        type: 'requisition_candidates',
      });
    }
  };

  handleHideSearchCandidate = ({ firstName, lastName, candidateId, loggedInSearchEmployerId }) => {
    const {
      actions: { searchCandidateHideStarted, adminSearchCandidateHideStarted },
    } = this.props;

    if (loggedInSearchEmployerId) {
      adminSearchCandidateHideStarted({
        firstName,
        lastName,
        candidateId,
        loggedInEmployerId: loggedInSearchEmployerId,
      });
    } else {
      searchCandidateHideStarted({
        candidateId,
        firstName,
        lastName,
      });
    }
    this.setState((prevState) => ({
      candidateProfile: {
        ...prevState.candidateProfile,
        bookmark: null,
        hide: true,
      },
    }));
  };

  handleUndoHideSearchCandidate = ({ candidateId }) => {
    const {
      actions: { searchCandidateUndoHideStarted },
      results: {
        byId: { [candidateId]: { attributes: { candidateCompanyBlockId } = {} } = {} } = {},
      } = {},
    } = this.props;

    if (candidateCompanyBlockId) {
      searchCandidateUndoHideStarted({
        candidateCompanyBlockId,
        candidateId,
      });
    }
  };

  render() {
    const {
      match: { params: { id, view } = {} } = {},
      location: { search, pathname },
      modal,
      candidateProfileReqs = [],
      results: { byId = {} } = {},
      requisitionCandidates,
    } = this.props;

    const { tp = null, tableStatus = null } = queryString.parse(search);

    const { candidateProfile, isCandidateLoading, loadError } = this.state;

    const { originalUrl } = modal;

    const origPathname = originalUrl ? originalUrl.split('/')[1] : null;

    const parentViewPathname = origPathname ? PARENT_VIEWS[origPathname] : null;

    const splitPathname = pathname.split('/').slice(-2)[0];

    const lockedPathname = splitPathname === 'profile' ? 'locked_link' : null;

    const trackPage = tp ? tpValues[tp] : null;
    const useHideNames = tp && hideNameTPValues.includes(tp);
    const useBlankHideNames = !tp;

    const parentView = trackPage || lockedPathname || parentViewPathname;

    const { [id]: { attributes: { hide: hideSearch = false } = {} } = {} } = byId;

    const newProps = {
      candidateProfile: {
        ...candidateProfile,
        hideSearch,
      },
      candidateProfileReqs,
      handleGetCandidate: this.handleGetCandidate,
      handleBookmarkCandidate: this.handleBookmarkCandidate,
      handleRemoveBookmarkCandidate: this.handleRemoveBookmarkCandidate,
      handleMakeOffer: this.handleMakeOffer,
      handlePass: this.handlePass,
      handleHideSearchCandidate: this.handleHideSearchCandidate,
      handleUndoHideSearchCandidate: this.handleUndoHideSearchCandidate,
      isCandidateLoading,
      key: id,
      parentView,
      requisitionCandidates,
      tableStatus,
      useHideNames,
      useBlankHideNames,
      ...this.props,
    };

    const content = () => {
      switch (view) {
        case 'profile':
          return <CandidateProfile {...newProps} />;
        case 'print':
          return <CandidateProfilePrint {...newProps} />;
        default:
          return <NotFound />;
      }
    };

    if (loadError) {
      return <NotFound />;
    }

    const title = 'Candidate Profile';
    const metaDescription = 'Rainmakers Candidate Profile';
    const ogDescription = "Visit Rainmakers today to view this sales candidate's profile.";
    const canonicalUrl = `https://${process.env.HOST}/${id}/${view}/`;

    const helmetProps = {
      key: 'helmet',
      title,
      meta: [
        { name: 'description', content: metaDescription },
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: title },
        { property: 'og:url', content: canonicalUrl },
        { property: 'og:description', content: ogDescription },
        { property: 'og:image', content: require('shared/assets/candidate-profile-logo.jpg') },
        { property: 'og:image:width', content: '75' },
        { property: 'og:image:height', content: '75' },
      ],
      link: [{ rel: 'canonical', href: canonicalUrl }],
    };

    const hideOverlay = id && !isCandidateLoading && candidateProfileReqs.length >= 0;

    const actualContent = hideOverlay ? (
      content()
    ) : (
      <>
        <TableOverlay key="profileOverlay" table="savedSearches" />
        {content()}
      </>
    );

    const finalContent = isEmpty(candidateProfile) ? (
      <CandidateEmpty modal={modal} />
    ) : (
      actualContent
    );

    return (
      <>
        <Helmet {...helmetProps} />
        <ReadyContainer key="readyContainer">
          <LayoutWithoutSidebar content={finalContent} isPrint={view === 'print'} />
        </ReadyContainer>
      </>
    );
  }
}

export default ConnectContainer(duck)(CandidateWrapper);
