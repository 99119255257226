import React from 'react';
import isEmpty from 'lodash.isempty';

import Block from 'components/Block';
import Bold from 'components/Bold';
import Bolder from 'components/Bolder';
import Button from 'components/Button';
import Content from 'components/Content';
import ContentTitle from 'components/ContentTitle';
import Input from 'components/Input';
import InputCheckboxMany from 'components/InputCheckboxMany';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';

import styles from './PassForm.scss';

const PassForm = ({
  candidate: { id: candidateId, firstName, lastName } = {},
  feedback = '',
  companyFeedback = [],
  handleCancel,
  handleFeedbackChange,
  handleReasonChange,
  handleSubmitFeedback,
  offerFeedback = [],
  opportunities = [],
  match: {
    params: { id: requisitionCandidateId },
  },
  reason = '',
  roleFeedback = [],
  user: { currentProfileType } = {},
}) => {
  const isEmployee = currentProfileType === 'Employee';
  const isAdmin = currentProfileType === 'RainmakersAdmin';

  const requisitionCandidate = opportunities.find((opp) => opp.id === requisitionCandidateId) || {};

  if (
    (isEmpty(requisitionCandidate) ||
      (candidateId &&
        requisitionCandidate.candidate &&
        requisitionCandidate.candidate.id !== candidateId)) &&
    currentProfileType !== 'RainmakersAdmin'
  ) {
    return null;
  }

  const {
    employer: { name: employerName = '' } = {},
    requisitionOpeningCopy: { externalName = '' } = {},
  } = requisitionCandidate;

  const messageBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    largeTitleFont: true,
    className: styles.block,
    addPadding: true,
  };

  const commonProps = {
    handleInputChange: handleFeedbackChange,
    hasLabel: true,
    blockStyle: true,
    disabled: isEmployee,
  };

  const companyFeedbackSource = [
    {
      label: 'Too small',
      value: 'too_small',
    },
    {
      label: 'Too large',
      value: 'too_large',
    },
    {
      label: 'Wrong industry',
      value: 'wrong_industry',
    },
  ];

  const companyFeedbackProps = {
    ...commonProps,
    source: companyFeedbackSource,
    name: 'companyFeedback',
    selectedValues: companyFeedback,
  };

  const roleFeedbackSource = [
    {
      label: 'Comp is too low',
      value: 'low_comp',
    },
    {
      label: 'Not a good match',
      value: 'bad_match',
    },
    {
      label: 'Wrong location',
      value: 'wrong_location',
    },
  ];

  const roleFeedbackProps = {
    ...commonProps,
    source: roleFeedbackSource,
    name: 'roleFeedback',
    selectedValues: roleFeedback,
  };

  const offerFeedbackSource = [
    {
      label: 'Job search is on hold',
      value: 'job_search_hold',
    },
    {
      label: 'Took another offer',
      value: 'took_another_offer',
    },
    {
      label: 'Staying at current job',
      value: 'staying_at_current_job',
    },
  ];

  const offerFeedbackProps = {
    ...commonProps,
    source: offerFeedbackSource,
    name: 'offerFeedback',
    selectedValues: offerFeedback,
  };

  const reasonProps = {
    type: 'textarea',
    size: 'full',
    handleInputChange: handleReasonChange,
    value: reason,
    rows: 5,
    name: 'reason',
    disabled: isEmployee,
  };

  const privateProps = {
    type: 'textarea',
    size: 'full',
    handleInputChange: handleReasonChange,
    value: feedback,
    rows: 5,
    name: 'feedback',
  };

  const cancelProps = {
    onClick: handleCancel,
    quaternary: true,
  };

  const cancelButton = handleCancel ? (
    <Button {...cancelProps}>{isEmployee ? 'Close' : 'Cancel'}</Button>
  ) : null;

  const submitProps = {
    onClick: handleSubmitFeedback,
    primary: true,
    className: styles.submit,
  };

  const header =
    isEmployee || isAdmin ? (
      <Content className={styles.mainTitle}>
        <Bolder>
          {firstName} {lastName}
        </Bolder>{' '}
        passed on <Bolder>{employerName}</Bolder>
      </Content>
    ) : (
      <Content className={styles.mainTitle}>
        <Bold>Why are you turning down this opportunity?</Bold>
      </Content>
    );

  const subtitle =
    isEmployee || isAdmin ? (
      <Content className={styles.subtitle}>{firstName}&apos;s feedback:</Content>
    ) : (
      <Content className={styles.subtitle}>
        <Bold>
          {employerName} - {externalName}
        </Bold>
      </Content>
    );

  const blurb =
    isEmployee || isAdmin ? null : (
      <Content className={styles.text}>
        Tell us why you think this opportunity isn&apos;t right for you at this time. You can select
        as many options as you want, and feel free to describe in detail or add more answers in the
        text field at the bottom.
      </Content>
    );

  const privateInput = isEmployee ? null : (
    <Content>
      <ContentTitle>
        Why are you passing (this is private and we won&apos;t share with the company)?
      </ContentTitle>
      <Input {...privateProps} />
    </Content>
  );

  const submitInput = isEmployee ? (
    <Content className={styles.buttons}>{cancelButton}</Content>
  ) : (
    <Content className={styles.buttons}>
      {cancelButton}
      <Button {...submitProps}>Submit feedback</Button>
    </Content>
  );

  const reasonInput = isEmployee ? (
    <Content className={styles.input}>
      <ContentTitle>ADDITIONAL FEEDBACK</ContentTitle>
      <Content className={styles.reasonText}>{reason}</Content>
    </Content>
  ) : (
    <Content className={styles.input}>
      <ContentTitle>Feedback for the company</ContentTitle>
      <Input {...reasonProps} />
    </Content>
  );

  const content = (
    <div className={styles.ApproachMessages}>
      <div className={styles.scrollDiv}>
        <Block {...messageBlockProps}>
          {header}

          {subtitle}

          {blurb}

          <Content className={styles.input}>
            <ContentTitle>COMPANY</ContentTitle>
            <InputCheckboxMany {...companyFeedbackProps} />
          </Content>

          <Content className={styles.input}>
            <ContentTitle>ROLE</ContentTitle>
            <InputCheckboxMany {...roleFeedbackProps} />
          </Content>

          <Content className={styles.input}>
            <ContentTitle>PERSONAL</ContentTitle>
            <InputCheckboxMany {...offerFeedbackProps} />
          </Content>

          {reasonInput}

          {privateInput}

          {submitInput}
        </Block>
      </div>
    </div>
  );

  const layoutWithoutSidebarProps = {
    content,
  };

  return <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />;
};

export default PassForm;
