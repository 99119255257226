import { call, select, put, take } from 'redux-saga/effects';

import { entitySelectorFactory } from 'reduxStore/selectorFactories';

import { handleError } from 'utils/common';
import { trackEvent } from 'utils/analytics';
import { segmentAddBookmark } from 'segment/eventNames';

import { promiseBookmarksAdd } from '../../../CandidateSearchPage/promises';

import { BOOKMARKS_ADD_STARTED } from '../actions';

import { featuredCandidateUpdate } from '../creators';

const trackBookmarkAdd = (bookmarkId, candidate, viewedFromPage) => {
  const event = segmentAddBookmark;

  const properties = {
    bookmarkId,
    candidateId: candidate.id,
    firstName: candidate.firstName,
    lastName: candidate.lastName,
    fullName: candidate.fullName,
    viewedFromPage,
  };

  const eventObject = { event, properties };

  trackEvent(eventObject);
};

const sagaBookmarksAdd = function* sagaBookmarksAdd() {
  while (true) {
    const {
      payload: { candidateId, viewedFromPage },
    } = yield take(BOOKMARKS_ADD_STARTED);

    const promiseParams = {
      candidateId,
    };

    const { byId } = yield select(entitySelectorFactory('featured', 'candidate'));

    try {
      yield put(
        featuredCandidateUpdate({
          candidate: {
            ...byId[candidateId].attributes,
            bookmark: {
              id: 'fakeId',
              createdAt: new Date(),
            },
          },
        })
      );

      const {
        data: {
          bookmark: { id, createdAt },
        },
      } = yield call(promiseBookmarksAdd, promiseParams);

      const newCandidate = {
        ...byId[candidateId].attributes,
        bookmark: {
          id,
          createdAt,
        },
      };

      yield put(
        featuredCandidateUpdate({
          candidate: newCandidate,
        })
      );

      yield call(trackBookmarkAdd, id, newCandidate, viewedFromPage);
    } catch (error) {
      handleError(error);

      yield put(featuredCandidateUpdate({ candidate: byId[candidateId].attributes }));
    }
  }
};

export default sagaBookmarksAdd;
