import React from 'react';

import EmptyCompanyLogo from 'shared/assets/missing-company-picture.png';

import Block from 'components/Block';
import Input from 'components/Input';
import InputRadio from 'components/InputRadio';
import Label from 'components/Label';

import { toDollars, toCents } from 'utils/moneyUtils';

import styles from './CSMSalesPeriodInputs.scss';

const CSMSalesPeriodInputs = ({
  handleSalesPeriodInputChange,
  workHistory: {
    attributes: {
      company: { name: companyName = '', picture: { xlarge: pictureUrl = '' } = {} } = {},
      id: workHistoryId,
      position: { name: positionName = '' } = {},
      startDate = '',
      endDate = '',
      salesPeriods = [],
    } = {},
  } = {},
  isProfileLocked = false,
  autofocusFields = [],
  modelIndex = null,
}) => {
  const picUrl = pictureUrl === '' ? EmptyCompanyLogo : pictureUrl;

  const salesInputs = salesPeriods.map((salesPeriod, idx) => {
    const {
      companiesSupported,
      hadQuota,
      id: salesPeriodId,
      quotaAmountCents: quotaAmount,
      renewalRetentionPercentage,
      salaryCents: salary,
      totalCompCents: totalComp,
    } = salesPeriod;

    const isOpenIdx = idx === parseInt(modelIndex, 10);

    const commonTextInputProps = {
      handleInputChange: (event) =>
        handleSalesPeriodInputChange({
          event: {
            ...event,
            target: {
              ...event.target,
              value: toCents(event.target.value),
              name: event.target.name,
            },
          },
          salesPeriodId,
          type: 'CSMSalesPeriod',
          workHistoryId,
        }),
      type: 'text',
      size: 'full',
      moneyInput: true,
      disabled: isProfileLocked,
    };

    const salaryInputProps = {
      ...commonTextInputProps,
      name: 'salaryCents',
      value: salary || salary === 0 ? toDollars(salary) : '',
      label: 'Base Salary',
      placeholder: '',
      autoFocus: autofocusFields.includes('salary_cents') && isOpenIdx && !salary,
      highlight: autofocusFields.includes('salary_cents') && isOpenIdx && !salary,
    };

    const totalCompInputProps = {
      ...commonTextInputProps,
      name: 'totalCompCents',
      value: totalComp || totalComp === 0 ? toDollars(totalComp) : '',
      label: 'Total Comp',
      placeholder: '',
      autoFocus: autofocusFields.includes('total_comp_cents') && isOpenIdx && !totalComp,
      highlight: autofocusFields.includes('total_comp_cents') && isOpenIdx && !totalComp,
    };

    const quotaInputProps = {
      ...commonTextInputProps,
      disabled: !hadQuota,
      name: 'quotaAmountCents',
      value: (quotaAmount || quotaAmount === 0) && hadQuota ? toDollars(quotaAmount) : '',
      placeholder: '',
      autoFocus: autofocusFields.includes('quota_amount_cents') && isOpenIdx && !quotaAmount,
      highlight: autofocusFields.includes('quota_amount_cents') && isOpenIdx && !quotaAmount,
    };

    const renewalPercentInputProps = {
      ...commonTextInputProps,
      name: 'renewalRetentionPercentage',
      value:
        renewalRetentionPercentage || renewalRetentionPercentage === 0
          ? Math.floor((renewalRetentionPercentage * 1000) / 10)
          : '',
      label: 'Renewal/Retention Rate',
      placeholder: '',
      type: 'number',
      min: '0',
      max: '100',
      size: 'small',
      moneyInput: false,
      percentInput: true,
      handleInputChange: (event) =>
        handleSalesPeriodInputChange({
          event: {
            ...event,
            target: {
              ...event.target,
              value:
                event.target.value || event.target.value === 0 ? event.target.value / 100 : null,
              name: event.target.name,
            },
          },
          salesPeriodId,
          type: 'CSMSalesPeriod',
          workHistoryId,
        }),
      autoFocus:
        autofocusFields.includes('renewal_retention_percentage') &&
        isOpenIdx &&
        !renewalRetentionPercentage,
      highlight:
        autofocusFields.includes('renewal_retention_percentage') &&
        isOpenIdx &&
        !renewalRetentionPercentage,
    };

    const options = [
      {
        label: 'Yes',
        value: true,
      },
      {
        label: 'No',
        value: false,
      },
    ];

    const hadQuotaProps = {
      options,
      name: 'hadQuota',
      value: hadQuota,
      handleInputChange: (event) =>
        handleSalesPeriodInputChange({
          event,
          salesPeriodId,
          type: 'CSMSalesPeriod',
          workHistoryId,
        }),
      autoFocus: autofocusFields.includes('had_quota') && isOpenIdx && hadQuota === null,
      highlight: autofocusFields.includes('had_quota') && isOpenIdx && hadQuota === null,
    };

    const companiesSupportedInputProps = {
      ...commonTextInputProps,
      handleInputChange: (event) =>
        handleSalesPeriodInputChange({
          event,
          salesPeriodId,
          type: 'CSMSalesPeriod',
          workHistoryId,
        }),
      placeholder: '',
      size: 'small',
      type: 'number',
      label: '# of Customers Supported',
      min: '0',
      max: '10000',
      name: 'companiesSupported',
      value: companiesSupported || companiesSupported === 0 ? companiesSupported : '',
      moneyInput: false,
      autoFocus:
        autofocusFields.includes('companies_supported') && isOpenIdx && !companiesSupported,
      highlight:
        autofocusFields.includes('companies_supported') && isOpenIdx && !companiesSupported,
    };

    return (
      <div key={salesPeriodId} className={styles.salesPeriodContainer}>
        <div className={styles.rowInputs}>
          <div className={styles.columnInputs}>
            <Label standalone>Did you have a quota?</Label>
            <InputRadio {...hadQuotaProps} />
          </div>
          <div className={styles.columnInputs}>
            <Label disabled={!hadQuota}>Annual Quota ($)</Label>
            <Input {...quotaInputProps} />
          </div>
          <Input {...salaryInputProps} />
          <Input {...totalCompInputProps} />
          <Input {...companiesSupportedInputProps} />
          <Input {...renewalPercentInputProps} />
        </div>
      </div>
    );
  });

  const startYear = startDate.substring(0, 4);
  const endYear = endDate ? endDate.substring(0, 4) : 'Present';

  const salesPeriodBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    addPadding: false,
    className: styles.salesBlock,
    key: workHistoryId,
  };

  return (
    <Block {...salesPeriodBlockProps}>
      <div className={styles.inputsContainer}>
        <div className={styles.companyContainer}>
          <div className={styles.companyLogo}>
            <img src={picUrl} alt="company" />
          </div>
          <div className={styles.companyDetails}>
            <div className={styles.companyName}>{companyName}</div>
            <div className={styles.companyPosition}>{positionName}</div>
            <div className={styles.companyYears}>
              {startYear} - {endYear}
            </div>
          </div>
        </div>
        <div className={styles.columnInputs}>{salesInputs}</div>
      </div>
    </Block>
  );
};

export default CSMSalesPeriodInputs;
