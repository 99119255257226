import { handleError } from 'utils/common';
import { postEntity } from 'api/apiEntity';

const promiseEmailValid = ({ email }) => {
  const onFulfilled = (response) => response;

  const onFailure = (error) => {
    handleError(error);

    const { response: { data: { errors } = {} } = {} } = error;

    return errors;
  };

  const args = {
    type: 'email_valid',
    data: {
      type: 'users',
      attributes: {
        email,
      },
    },
  };

  const promise = postEntity(args).then(onFulfilled).catch(onFailure);

  return promise;
};

export default promiseEmailValid;
