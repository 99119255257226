import React, { Component } from 'react';

import Block from 'components/Block';

import { toggleArray } from 'utils/formUtils';

import styles from '../../ToolboxRequisitionEditor.scss';

import BaseSalary from './components/BaseSalary';
import BonusStructure from './components/BonusStructure';
import ExternalName from './components/ExternalName';
import Headcount from './components/Headcount';
import Highlights from './components/Highlights';
import HiringManager from './components/HiringManager';
import Locations from './components/Locations';
import Opportunities from './components/Opportunities';
import Ote from './components/Ote';
import Overview from './components/Overview';
import Products from './components/Products';
import ReqId from './components/ReqId';
import Role from './components/Role';

import localStyles from './RequisitionOpening.scss';

class RequisitionOpening extends Component {
  handlePatchResource = ({ attributes }) => {
    const {
      actions: { patchResource, resourceUpdate },
      changedAttributes = [],
      requisitionOpening,
      requisitionOpening: {
        attributes: { state },
        id,
      },
      handleSetState,
      slice,
    } = this.props;

    handleSetState({ changeMade: true });

    state === 'pending' &&
      patchResource({
        id,
        attributes,
        normalizeIt: true,
        params: {
          includeSet: 'employer',
        },
        requiredFields: ['externalName'],
        ignoreValidations: true,
        slice,
        type: 'requisition_openings',
      });

    if (state === 'open') {
      const newAttributes = changedAttributes.slice();

      const keys = Object.keys(attributes);

      keys.forEach((key) => {
        if (!newAttributes.includes(key)) {
          newAttributes.push(key);
        }
      });

      handleSetState({ changedAttributes: newAttributes });

      resourceUpdate({
        type: 'requisition_openings',
        slice,
        id,
        resource: {
          ...requisitionOpening,
          attributes: {
            ...requisitionOpening.attributes,
            ...attributes,
          },
        },
      });
    }
  };

  handleAddArrayInput = (arrayName) => {
    const {
      actions: { resourceUpdate },
      requisitionOpening,
      requisitionOpening: { attributes: { [arrayName]: array } = {}, id } = {},
      slice,
    } = this.props;

    const newValue = array.slice();

    newValue.push('');

    const resource = {
      ...requisitionOpening,
      attributes: {
        ...requisitionOpening.attributes,
        [arrayName]: newValue,
      },
    };

    resourceUpdate({
      type: 'requisition_openings',
      slice,
      id,
      resource,
    });
  };

  handleAddArrayValue = (event, arrayName) => {
    const {
      target: { name, value },
    } = event;

    const { requisitionOpening: { attributes: { [arrayName]: arrayToUpdate = [] } = {} } = {} } =
      this.props;

    let newValue = arrayToUpdate ? arrayToUpdate.slice() : [];

    newValue[name] = value;

    if (newValue.every((el) => !el)) {
      newValue = [];
    }

    this.handlePatchResource({ attributes: { [arrayName]: newValue } });
  };

  handleReorderArray = ({ startIndex, endIndex, name }) => {
    const {
      requisitionOpening: {
        attributes: { [name]: arrayToUpdate },
      },
    } = this.props;

    const newArray = arrayToUpdate.slice();

    const [removed] = newArray.splice(startIndex, 1);

    newArray.splice(endIndex, 0, removed);

    this.handlePatchResource({ attributes: { [name]: newArray } });
  };

  handleDeleteArrayInput = (indexToRemove, arrayName) => {
    const {
      requisitionOpening: {
        attributes: { [arrayName]: arrayToUpdate },
      },
    } = this.props;

    const newValue = arrayToUpdate.filter((id, index) => index !== indexToRemove);

    this.handlePatchResource({ attributes: { [arrayName]: newValue } });
  };

  handleToggleLocation = (event) => {
    const {
      target: { subCategories, name, value, optionType },
    } = event;

    const {
      requisitionOpening: {
        attributes: { state, [name]: arrayToUpdate = [] },
      },
    } = this.props;

    // Main option selected
    if (subCategories && optionType === 'folder') {
      let newArray = arrayToUpdate.slice();

      const subCatValues = subCategories.map((sub) => `${sub.value[sub.value.type]}`);

      const filterValues = newArray.map((val) => `${val[val.type]}`);

      const allSelected = subCatValues.every((val) => filterValues.includes(val));

      // All subcategories of main option are currently selected
      if (allSelected) {
        newArray = newArray.filter((val) => `${val[val.type]}` !== `${value[value.type]}`);
        newArray = newArray.filter(
          (val) =>
            !subCategories.map((sub) => `${sub.value[sub.value.type]}`).includes(`${val[val.type]}`)
        );
      } else {
        subCategories.forEach((subCat) => {
          if (
            !newArray
              .map((val) => `${val[val.type]}`)
              .includes(`${subCat.value[subCat.value.type]}`)
          ) {
            newArray.push(subCat.value);
          }
        });
      }

      const attributes = { [name]: newArray };

      if (state === 'pending') {
        attributes.abbreviatedLocations = allSelected ? [] : [value[value.type]];
      }

      return this.handlePatchResource({ attributes });
    }

    // Option is currently selected => deselect
    if (arrayToUpdate.map((val) => val[val.type]).includes(value[value.type])) {
      let newArray = arrayToUpdate.slice();

      newArray = arrayToUpdate.filter((val) => `${val[val.type]}` !== `${value[value.type]}`);

      const attributes = { [name]: newArray };

      if (state === 'pending') {
        attributes.abbreviatedLocations =
          newArray && newArray[0] ? [newArray[0][newArray[0].type]] : [];
      }

      return this.handlePatchResource({ attributes });
    }

    const newArray = arrayToUpdate.slice();

    newArray.push(value);

    if (subCategories && optionType === 'folder') {
      subCategories.forEach((subCat) => {
        if (!newArray.map((val) => val[val.type]).includes(subCat.label)) {
          newArray.push(subCat.value);
        }
      });
    }

    const attributes = { [name]: newArray };

    if (state === 'pending') {
      attributes.abbreviatedLocations = [value[value.type]];
    }

    this.handlePatchResource({
      attributes: {
        [name]: newArray,
        abbreviatedLocations: [value[value.type]],
      },
    });
  };

  handleToggleRegions = (event) => {
    const {
      target: { name, label: region },
    } = event;

    const {
      requisitionOpening: {
        // eslint-disable-next-line no-unused-vars
        attributes: { [name]: arrayToUpdate = [] },
      },
    } = this.props;

    if (region) {
      const regValue = region;

      // Multiselect:
      // const newArray = arrayToUpdate.map((item) => item.region).includes(regValue)
      //   ? arrayToUpdate.filter((r) => r.region !== regValue)
      //   : [...arrayToUpdate, { type: 'region', region: regValue }];

      // Single select:
      const newArray = arrayToUpdate.map((item) => item.region).includes(regValue)
        ? []
        : [{ type: 'region', region: regValue }];

      console.log('newArray', newArray);

      this.handlePatchResource({
        attributes: {
          [name]: newArray,
          abbreviatedLocations: [region],
        },
      });
    }
  };

  handleInputChange = (event) => {
    const {
      target: { name, type, value, checked },
    } = event;

    const newValue = type === 'checkbox' ? checked : value;

    console.log('patchResource', { [name]: newValue });

    this.handlePatchResource({ attributes: { [name]: newValue } });
  };

  handleWorkModelChange = (event) => {
    const {
      target: { name, value },
    } = event;

    const { requisitionOpening: { attributes: { [name]: arrayToUpdate = [] } = {} } = {} } =
      this.props;

    const { workModel } = value || {};

    const newArray = toggleArray({ array: arrayToUpdate, value: workModel });

    this.handlePatchResource({ attributes: { [name]: newArray } });
  };

  render() {
    const {
      pickerOptions: { candReqLocations: locationSource = [], role: roleOptions = [] } = {},
      requisitionOpening = {},
      requisitionOpening: { attributes: { temporary } = {} } = {},
      requisitionOpeningErrors = {},
    } = this.props;

    const blockProps = {
      addWhiteBG: true,
      boxShadow: true,
      largeTitleFont: true,
      className: styles.block,
    };

    const reqInformationBlockProps = {
      ...blockProps,
      title: 'Req Information',
      childContainerClass: localStyles.blockChildContainer,
    };

    const companyInformationBlockProps = {
      ...blockProps,
      title: 'Company Information',
      childContainerClass: localStyles.blockChildContainer,
    };

    const roleInformationBlockProps = {
      ...blockProps,
      title: 'Role Information',
      childContainerClass: localStyles.blockChildContainer,
    };

    const compensationBlockProps = {
      ...blockProps,
      title: 'Compensation',
      childContainerClass: localStyles.blockChildContainer,
    };

    const commonProps = {
      errors: requisitionOpeningErrors,
      handleInputChange: this.handleInputChange,
      handleAddArrayInput: this.handleAddArrayInput,
      handleAddArrayValue: this.handleAddArrayValue,
      handleDeleteArrayInput: this.handleDeleteArrayInput,
      handleReorderArray: this.handleReorderArray,
      handleToggleLocation: this.handleToggleLocation,
      handleToggleRegions: this.handleToggleRegions,
      handleWorkModelChange: this.handleWorkModelChange,
      locationSource,
      requisitionOpening,
      roleOptions,
      locationTitle: 'Location',
    };

    const disabledOverlay = temporary ? <div className={styles.disabledOverlay} /> : null;

    return (
      <div className={styles.ReqFormBlocks}>
        <Block {...reqInformationBlockProps}>
          <ExternalName {...commonProps} />
          {disabledOverlay}
          <Role {...commonProps} />
          <HiringManager {...commonProps} />
          <Headcount {...commonProps} />
          <ReqId {...commonProps} />
        </Block>
        <Block {...roleInformationBlockProps}>
          <Locations {...commonProps} />
          <Overview {...commonProps} />
          <Opportunities {...commonProps} />
        </Block>
        <Block {...compensationBlockProps}>
          <Ote {...commonProps} />
          <BaseSalary {...commonProps} />
          <BonusStructure {...commonProps} />
        </Block>
        <Block {...companyInformationBlockProps}>
          <Highlights {...commonProps} />
          <Products {...commonProps} />
        </Block>
      </div>
    );
  }
}

export default RequisitionOpening;
