import { createAction } from 'redux-actions';

import { SHOW_MODAL } from 'shared/store/app/actions';
import { EMPLOYER_PATCH_STARTED } from 'pages/EmployeePortal/EmployeeEditorPages/redux/actions';

import {
  SET_EMULATED_EMPLOYER,
  RESET_EMULATED_EMPLOYER,
} from 'pages/EmployeePortal/CandidateSearchPage/redux/actions';

import {
  BOOKMARKS_ADD_STARTED,
  BOOKMARKS_ADD_DONE,
  BOOKMARKS_REMOVE_STARTED,
  BOOKMARKS_REMOVE_DONE,
  FEATURED_CANDIDATES_LOAD_STARTED,
  FEATURED_CANDIDATES_LOAD_DONE,
  FEATURED_CANDIDATES_RESET,
  FEATURED_CANDIDATE_UPDATE,
  FEATURED_CANDIDATES_LOADING_STARTED,
  FEATURED_CANDIDATES_LOADING_DONE,
  ADMIN_FEATURED_CANDIDATE_PASS_STARTED,
  ADMIN_FEATURED_CANDIDATE_PASS_DONE,
  FEATURED_CANDIDATE_PASS_STARTED,
  FEATURED_CANDIDATE_PASS_DONE,
  FEATURED_CANDIDATE_UNDO_PASS_STARTED,
  FEATURED_CANDIDATE_UNDO_PASS_DONE,
} from '../actions';

export const showModal = createAction(SHOW_MODAL);

export const setEmulatedEmployer = createAction(SET_EMULATED_EMPLOYER);
export const resetEmulatedEmployer = createAction(RESET_EMULATED_EMPLOYER);

export const bookmarksAddStarted = createAction(BOOKMARKS_ADD_STARTED);
export const bookmarksAddDone = createAction(BOOKMARKS_ADD_DONE);
export const bookmarksRemoveStarted = createAction(BOOKMARKS_REMOVE_STARTED);
export const bookmarksRemoveDone = createAction(BOOKMARKS_REMOVE_DONE);

export const featuredCandidatesLoadStarted = createAction(FEATURED_CANDIDATES_LOAD_STARTED);
export const featuredCandidatesLoadDone = createAction(FEATURED_CANDIDATES_LOAD_DONE);
export const featuredCandidatesReset = createAction(FEATURED_CANDIDATES_RESET);
export const featuredCandidateUpdate = createAction(FEATURED_CANDIDATE_UPDATE);
export const featuredCandidatesLoadingStarted = createAction(FEATURED_CANDIDATES_LOADING_STARTED);
export const featuredCandidatesLoadingDone = createAction(FEATURED_CANDIDATES_LOADING_DONE);

export const adminFeaturedCandidatePassStarted = createAction(
  ADMIN_FEATURED_CANDIDATE_PASS_STARTED
);
export const adminFeaturedCandidatePassDone = createAction(ADMIN_FEATURED_CANDIDATE_PASS_DONE);

export const featuredCandidatePassStarted = createAction(FEATURED_CANDIDATE_PASS_STARTED);
export const featuredCandidatePassDone = createAction(FEATURED_CANDIDATE_PASS_DONE);

export const featuredCandidateUndoPassStarted = createAction(FEATURED_CANDIDATE_UNDO_PASS_STARTED);
export const featuredCandidateUndoPassDone = createAction(FEATURED_CANDIDATE_UNDO_PASS_DONE);

export const employerPatchStarted = createAction(EMPLOYER_PATCH_STARTED);
