import { pluralize } from 'inflection';

// This utility denormalizes jsonapi serializer data
const transformData = (normalizedData, key, singular) => {
  const mainData = normalizedData[key];

  const newData = Object.keys(mainData).map((entityId) => {
    const { relationships } = mainData[entityId];

    if (relationships) {
      const relationshipKeys = Object.keys(relationships);

      const relationshipEntities = relationshipKeys.reduce((acc, curr) => {
        const { data } = relationships[curr];

        if (Array.isArray(data) && data.length > 0) {
          return {
            ...acc,
            [pluralize(curr)]: data.map((relEnt) => {
              if (normalizedData[relEnt.type]) {
                return normalizedData[relEnt.type][relEnt.id].attributes;
              }
              return relEnt.id;
            }),
          };
        }

        if (data && data.id && data.type) {
          const relationshipGroup = normalizedData[data.type];

          if (relationshipGroup) {
            return {
              ...acc,
              [curr]: relationshipGroup[data.id].attributes,
            };
          }

          return { ...acc, [`${curr}_id`]: data.id };
        }

        return acc;
      }, {});

      return {
        ...mainData[entityId],
        attributes: {
          ...mainData[entityId].attributes,
          ...relationshipEntities,
        },
      };
    }

    return mainData[entityId];
  });

  const newKey = singular ? key : pluralize(key);

  const { attributes = {} } = newData[0] || {};

  const finalData = singular ? attributes : newData.map((item) => item.attributes);

  return { [newKey]: finalData };
};

export { transformData };
