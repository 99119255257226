import React from 'react';

import Label from 'components/Label';
import Tag from 'components/Tag';
import AutocompleteServer from 'connectors/AutocompleteServer';
import InputSelectMany from 'components/InputSelectMany';

import styles from './WhoSoldTo.scss';

const WhoSoldTo = ({
  soldToCompanies,
  soldToCompanySizes,
  soldToDepartments,
  soldToIndustries,
  deptSource,
  sizeSource,
  industrySource,
  handleToggleSoldToIndustryArrayValue,
  handleToggleArrayValue,
}) => {
  const makeSource = (item) => ({ label: item, value: item });

  const industrySourceArray = industrySource.map(makeSource);

  const soldToIndustriesInputProps = {
    source: industrySourceArray,
    size: 'full',
    name: 'soldToIndustries',
    handleInputChange: handleToggleSoldToIndustryArrayValue,
    selectedValues: soldToIndustries || [],
    defaultIcon: 'caret-down',
    defaultLabel: 'Select Industries',
    noPadding: true,
  };

  const soldToIndustriesSelected = soldToIndustries
    ? soldToIndustries.map((ind) => {
        const tagProps = {
          name: ind,
          inputName: 'soldToIndustries',
          onDeleteClick: (name, inputName) =>
            handleToggleArrayValue({
              target: {
                value: name,
                name: inputName,
              },
            }),
        };

        return <Tag key={ind} {...tagProps} />;
      })
    : null;

  const sizeSourceArray = sizeSource.map(makeSource);

  const soldToCompanySizesInputProps = {
    source: sizeSourceArray,
    size: 'full',
    name: 'soldToCompanySizes',
    handleInputChange: handleToggleArrayValue,
    selectedValues: soldToCompanySizes || [],
    defaultIcon: 'caret-down',
    defaultLabel: 'Select company sizes',
    noPadding: true,
  };

  const soldToCompanySizesSelected = soldToCompanySizes
    ? soldToCompanySizes.map((size) => {
        const tagProps = {
          name: size,
          inputName: 'soldToCompanySizes',
          onDeleteClick: (name, inputName) =>
            handleToggleArrayValue({
              target: {
                value: name,
                name: inputName,
              },
            }),
        };

        return <Tag key={size} {...tagProps} />;
      })
    : null;

  const deptSourceArray = deptSource.map(makeSource);

  const soldToDepartmentsInputProps = {
    source: deptSourceArray,
    size: 'full',
    name: 'soldToDepartments',
    handleInputChange: handleToggleArrayValue,
    selectedValues: soldToDepartments || [],
    defaultIcon: 'caret-down',
    defaultLabel: 'Select departments',
    noPadding: true,
  };

  const soldToDepartmentsSelected = soldToDepartments
    ? soldToDepartments.map((dept) => {
        const tagProps = {
          key: dept,
          name: dept,
          inputName: 'soldToDepartments',
          onDeleteClick: (name, inputName) =>
            handleToggleArrayValue({
              target: {
                value: name,
                name: inputName,
              },
            }),
        };

        return <Tag key={dept} {...tagProps} />;
      })
    : null;

  const soldToCompaniesInputProps = {
    placeholder: 'Type and choose an option or add if not found',
    value: '',
    size: 'full',
    characterLimit: 100,
    name: 'soldToCompanies',
    label: "Companies you've sold to/supported (optional)",
    handleInputChange: (event) =>
      handleToggleArrayValue({
        ...event,
        target: {
          ...event.target,
          name: event.target.name,
          value: event.target.value.name,
        },
      }),
    handleOnBlur: handleToggleArrayValue,
    topLabel: true,
    autocompleteType: 'companies',
    field: 'name',
    needReset: true,
    hasAddButton: true,
  };

  const soldToCompaniesSelected = soldToCompanies
    ? soldToCompanies.map((comp) => {
        const tagProps = {
          name: comp,
          inputName: 'soldToCompanies',
          onDeleteClick: (name, inputName) =>
            handleToggleArrayValue({
              target: {
                value: name,
                name: inputName,
              },
            }),
        };

        return <Tag key={comp} {...tagProps} />;
      })
    : null;

  return (
    <div className={styles.whoSoldToContainer} data-testid="WhoSoldTo">
      <div className={styles.containerTitle}>WHO YOU&apos;VE SOLD TO/SUPPORTED</div>

      <Label required className={styles.label}>
        Company sizes you&apos;ve sold to/supported
      </Label>
      <InputSelectMany {...soldToCompanySizesInputProps} />
      <div className={styles.autocompleteContainer}>{soldToCompanySizesSelected}</div>

      <Label required className={styles.labelMoreGap}>
        Departments you&apos;ve sold to/supported
      </Label>
      <InputSelectMany {...soldToDepartmentsInputProps} />
      <div className={styles.autocompleteContainer}>{soldToDepartmentsSelected}</div>

      <Label required className={styles.labelMoreGap}>
        Industries you&apos;ve sold to/supported
      </Label>
      <InputSelectMany {...soldToIndustriesInputProps} />
      <div className={styles.autocompleteContainer}>{soldToIndustriesSelected}</div>

      <AutocompleteServer {...soldToCompaniesInputProps} />
      <div className={styles.autocompleteContainer}>{soldToCompaniesSelected}</div>
    </div>
  );
};

export default WhoSoldTo;
