import React from 'react';
import { Helmet } from 'react-helmet-async';

import { makeLikeParam } from 'utils/paramUtils';

import { AdminAuthHOC } from 'containers/HOC';

import ConnectContainer from 'containers/ConnectContainer';

import ReadyContainer from 'connectors/ReadyContainer';
import { useTableQuery } from 'hooks';

import Sidebar from 'components/Sidebar';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';

import ToolboxEducationOrgsTable from './components/ToolboxEducationOrgsTable';

import duck from './redux';

import styles from './ToolboxEducationOrgsPage.scss';

const route = '/toolbox/education-orgs/';

const ToolboxEducationOrgsPage = ({
  actions,
  children,
  isLoading,
  unverifiedEducationOrganizationData,
  educationOrgs: { byId = {}, allIds = [] } = {},
  total,
  page = 1,
}) => {
  const { queryParams, loadMore, handleSearchInputChange, handleSearchCheckboxChange } =
    useTableQuery(
      route,
      page,
      {
        name: {
          type: 'string',
          defaultValue: null,
        },
        sortBy: {
          type: 'string',
          defaultValue: 'created_at_desc',
        },
        unverifiedDataState: {
          type: 'array',
          defaultValue: null,
        },
      },
      {
        type: 'education_organizations',
        slice: 'toolboxEducationOrgs',
      },
      { includeSet: 'unverified_data' },
      {
        name: makeLikeParam,
      }
    );

  return (
    <>
      <Helmet key="helmet" title="Toolbox - Education Orgs" />
      <ReadyContainer key="readyContainer">
        <Sidebar type="toolbox" page="toolboxEducationOrgs" />

        <LayoutWithoutSidebar
          content={
            <div className={styles.toolboxEducationOrgsMainContent}>
              <ToolboxEducationOrgsTable
                {...queryParams}
                actions={actions}
                isLoading={isLoading}
                educationOrgs={allIds.map((id) => byId[id])}
                unverifiedEducationOrganizationData={unverifiedEducationOrganizationData}
                total={total}
                loadMore={loadMore}
                handleSearchInputChange={handleSearchInputChange}
                handleSearchCheckboxChange={({ target: { name, checked } }) => {
                  handleSearchCheckboxChange({
                    target: {
                      name,
                      checked: checked ? ['unverified'] : null,
                    },
                  });
                }}
              />
            </div>
          }
        />

        {children}
      </ReadyContainer>
    </>
  );
};

export default ConnectContainer(duck)(AdminAuthHOC()(ToolboxEducationOrgsPage));
