import selector from './selectors';
import reducer from './reducers';
import * as creators from './creators';

const duck = {
  selector,
  reducer,
  creators,
};

export default duck;
