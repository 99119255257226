const prefix = 'Rainmakers/CandidateEditor/';

// SHARED
export const CANDIDATE_LOAD_DONE = `${prefix}CANDIDATE_LOAD_DONE `;

// EDIT PERSONAL

export const EMAIL_CHANGE_STARTED = `${prefix}EMAIL_CHANGE_STARTED`;
export const EMAIL_CHANGE_DONE = `${prefix}EMAIL_CHANGE_DONE`;

export const CANDIDATE_PATCH_STARTED = `${prefix}CANDIDATE_PATCH_STARTED `;
export const CANDIDATE_PATCH_ALL_STARTED = `${prefix}CANDIDATE_PATCH_ALL_STARTED `;
export const CANDIDATE_PATCH_DONE = `${prefix}CANDIDATE_PATCH_DONE`;

export const CANDIDATE_POST_STARTED = `${prefix}CANDIDATE_POST_STARTED`;
export const CANDIDATE_POST_DONE = `${prefix}CANDIDATE_POST_DONE`;

export const CANDIDATE_UPDATE = `${prefix}CANDIDATE_UPDATE`;
export const PRIVATE_CANDIDATE_UPDATE = `${prefix}PRIVATE_CANDIDATE_UPDATE`;

export const CANDIDATE_SAVE_STARTED = `${prefix}CANDIDATE_SAVE_STARTED`;
export const CANDIDATE_SAVE_DONE = `${prefix}CANDIDATE_SAVE_DONE`;
export const CANDIDATE_SAVE_ERROR = `${prefix}CANDIDATE_SAVE_ERROR`;

export const CANDIDATE_RESET = `${prefix}CANDIDATE_RESET`;

export const CANDIDATE_ERRORS_RESET = `${prefix}CANDIDATE_ERRORS_RESET`;
export const CANDIDATE_ERRORS_SET = `${prefix}CANDIDATE_ERRORS_SET`;
export const CANDIDATE_ERRORS_UPDATE = `${prefix}CANDIDATE_ERRORS_UPDATE`;

export const CANDIDATE_VALID_SET = `${prefix}CANDIDATE_VALID_SET`;
export const CANDIDATE_VALID_RESET = `${prefix}CANDIDATE_VALID_RESET`;

export const VALIDATE_ALL = `${prefix}VALIDATE_ALL`;
// export const CANDIDATE_UPDATE_DONE = `${prefix}CANDIDATE_UPDATE_DONE`;
//
export const USER_PATCH_STARTED = `${prefix}USER_PATCH_STARTED`;
export const USER_ERRORS_SET = `${prefix}USER_ERRORS_SET`;
export const USER_ERRORS_RESET = `${prefix}USER_ERRORS_RESET`;

export const CANDIDATE_IMG_UPLOAD_STARTED = `${prefix}CANDIDATE_IMG_UPLOAD_STARTED`;
export const CANDIDATE_IMG_UPLOAD_DONE = `${prefix}CANDIDATE_IMG_UPLOAD_DONE`;

export const CANDIDATE_FILE_UPLOAD_STARTED = `${prefix}CANDIDATE_FILE_UPLOAD_STARTED`;
export const CANDIDATE_FILE_UPLOAD_DONE = `${prefix}CANDIDATE_FILE_UPLOAD_DONE`;
export const RESUME_SAVE_STARTED = `${prefix}RESUME_SAVE_STARTED`;
export const RESUME_SAVE_DONE = `${prefix}RESUME_SAVE_DONE`;

// WORK HISTORY
export const REORDER_WORK_STARTED = `${prefix}REORDER_WORK_STARTED`;
export const REORDER_WORK_DONE = `${prefix}REORDER_WORK_DONE`;

export const WORK_HISTORY_PATCH_STARTED = `${prefix}WORK_HISTORY_PATCH_STARTED`;
export const WORK_HISTORY_PATCH_DONE = `${prefix}WORK_HISTORY_PATCH_DONE`;

export const WORK_HISTORY_POST_STARTED = `${prefix}WORK_HISTORY_POST_STARTED`;
export const WORK_HISTORY_POST_DONE = `${prefix}WORK_HISTORY_POST_DONE`;

export const WORK_HISTORY_DELETE_STARTED = `${prefix}WORK_HISTORY_DELETE_STARTED`;
export const WORK_HISTORY_DELETE_DONE = `${prefix}WORK_HISTORY_DELETE_DONE`;

export const SALES_PERIOD_POST_STARTED = `${prefix}SALES_PERIOD_POST_STARTED`;
export const SALES_PERIOD_POST_DONE = `${prefix}SALES_PERIOD_POST_DONE`;
export const SALES_PERIOD_PATCH_STARTED = `${prefix}SALES_PERIOD_PATCH_STARTED`;
export const SALES_PERIOD_PATCH_DONE = `${prefix}SALES_PERIOD_PATCH_DONE`;

// EDUCATION
export const REORDER_EDUCATION_STARTED = `${prefix}REORDER_EDUCATION_STARTED`;
export const REORDER_EDUCATION_DONE = `${prefix}REORDER_EDUCATION_DONE`;
export const EDUCATION_POST_STARTED = `${prefix}EDUCATION_POST_STARTED`;
export const EDUCATION_POST_DONE = `${prefix}EDUCATION_POST_DONE`;
export const EDUCATION_PATCH_STARTED = `${prefix}EDUCATION_PATCH_STARTED`;
export const EDUCATION_PATCH_DONE = `${prefix}EDUCATION_PATCH_DONE`;
export const EDUCATION_DELETE_STARTED = `${prefix}EDUCATION_DELETE_STARTED`;
export const EDUCATION_DELETE_DONE = `${prefix}EDUCATION_DELETE_DONE`;

// CANDIDATE REQUIREMENTS
export const CAND_REQ_POST_STARTED = `${prefix}CAND_REQ_POST_STARTED`;
export const CAND_REQ_POST_DONE = `${prefix}CAND_REQ_POST_DONE`;
export const CAND_REQ_PATCH_STARTED = `${prefix}CAND_REQ_PATCH_STARTED`;
export const CAND_REQ_PATCH_DONE = `${prefix}CAND_REQ_PATCH_DONE`;
