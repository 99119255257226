import { handleActions } from 'redux-actions';

import {
  SERVER_ERROR_SET,
  ONBOARDING_ERRORS_SET,
  PARSE_RESUME_STARTED,
  PARSE_RESUME_DONE,
  RECENT_ROLE_SET,
} from 'pages/CandidatePortal/OnboardingPages/redux/actions';

const initialState = {
  onboardingData: {},
  recentRole: null,
  parsingResume: false,
  errors: {
    firstName: {
      isNotEmpty: [null],
    },
    lastName: {
      isNotEmpty: [null],
    },
    email: {
      isNotEmpty: [null],
      isEmail: [null],
      isAvailable: [null, 'email'],
    },
    phone: {
      isNotEmpty: [null],
      isPhoneNumber: [null],
    },
    password: {
      isNotEmpty: [null],
      minLength: [null, 8],
      hasANumber: [null],
      isPassword: [null],
    },
    linkedinUrl: {
      isUrl: [true],
    },
    salesExperience: {
      isNotNull: [null],
    },
  },
  serverErrors: [],
};

const handleParseResumeStarted = (state = initialState) => ({
  ...state,
  parsingResume: true,
});

const handleParseResumeDone = (state = initialState) => ({
  ...state,
  parsingResume: false,
});

const handleServerErrorSet = (state = initialState, action) => {
  const {
    payload: { errors },
  } = action;

  return {
    ...state,
    serverErrors: errors,
  };
};

const handleRecentRoleSet = (state = initialState, action) => {
  const {
    payload: { recentRole },
  } = action;

  return {
    ...state,
    recentRole,
  };
};

const handleOnboardingErrorsSet = (state = initialState, action) => {
  const {
    payload: { inputErrors },
  } = action;

  return {
    ...state,
    errors: {
      ...state.errors,
      ...inputErrors,
    },
  };
};

const actionHandlers = {
  [SERVER_ERROR_SET]: handleServerErrorSet,
  [ONBOARDING_ERRORS_SET]: handleOnboardingErrorsSet,
  [PARSE_RESUME_STARTED]: handleParseResumeStarted,
  [PARSE_RESUME_DONE]: handleParseResumeDone,
  [RECENT_ROLE_SET]: handleRecentRoleSet,
};

const reduceCandidateOnboarding = handleActions(actionHandlers, initialState);

export default reduceCandidateOnboarding;
