import { createStructuredSelector } from 'reselect';

import {
  entitySelectorFactory,
  loadingSelectorFactory,
  pageSelectorFactory,
  totalSelectorFactory,
  savingSelectorFactory,
} from 'reduxStore/selectorFactories';

import {
  selectIsApproved,
  selectLogoutPending,
  selectIsLoggedIn,
} from 'connectors/Login/redux/selectors';

import { selectUser } from 'connectors/User/redux/selectors';

import { selectPickerOptions, selectTags } from 'connectors/Defaults/redux/selectors';

import { getModal } from 'shared/store/app/selectors';

import selectEmployer from './selectEmployer';
import selectEmployerErrors from './selectEmployerErrors';
import selectEmployee from './selectEmployee';
import selectEmployeeErrors from './selectEmployeeErrors';
import selectEmployeeSaving from './selectEmployeeSaving';
import selectEmployeeServerError from './selectEmployeeServerError';
import selectUserErrors from './selectUserErrors';

const selector = createStructuredSelector({
  employee: selectEmployee,
  employees: entitySelectorFactory('employerEditor', 'employee'),
  employeesPage: pageSelectorFactory('employerEditor', 'employee'),
  employeesTotal: totalSelectorFactory('employerEditor', 'employee'),
  employeesLoading: loadingSelectorFactory('employerEditor', 'employee'),
  employeeSaving: selectEmployeeSaving,
  employeeServerError: selectEmployeeServerError,
  employer: selectEmployer,
  employerErrors: selectEmployerErrors,
  userErrors: selectUserErrors,
  employeeErrors: selectEmployeeErrors,
  isApproved: selectIsApproved,
  isLoggedIn: selectIsLoggedIn,
  logoutPending: selectLogoutPending,
  modal: getModal,
  pickerOptions: selectPickerOptions,
  saving: savingSelectorFactory('employerEditor'),
  tags: selectTags,
  user: selectUser,
  users: entitySelectorFactory('employerEditor', 'user'),
});

export {
  selectEmployeeSaving,
  selectEmployeeServerError,
  selectEmployerErrors,
  selectEmployee,
  selectEmployer,
  selector as default,
};
