import React from 'react';

import Bolder from 'components/Bolder';
import Content from 'components/Content';
import ContentTitle from 'components/ContentTitle';

import styles from './InterviewRequests.scss';

const InterviewRequests = () => {
  return (
    <Content className={styles.container}>
      <ContentTitle>INTERVIEW REQUESTS</ContentTitle>

      <Content className={styles.padded}>
        Emails for interview requests from hiring managers and potential employers will always be
        allowed so long as your privacy status is set to{' '}
        <Bolder className={styles.active}>Active</Bolder>. You can change your privacy status in the{' '}
        <Bolder>Privacy tab</Bolder> in Settings.
      </Content>
    </Content>
  );
};

export default InterviewRequests;
