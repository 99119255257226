import React from 'react';

import FormattedArray from 'components/FormattedArray';

import FontIcon from 'components/FontIcon';

import styles from './CompanyBenefits.scss';

const CompanyBenefits = ({ benefits }) => {
  const filteredBenefits = benefits.filter((ben) => ben !== '');

  const icon = <FontIcon className={styles.icon} iconName="checkmark-circle" />;

  const content = (
    <div className={styles.CompanyBenefits}>
      <FormattedArray icon={icon} array={filteredBenefits} />
    </div>
  );

  return content;
};

export default CompanyBenefits;
