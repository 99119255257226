/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import locPageMetaData, { LOCATIONS_PAGE_NYC } from 'pages/LocationsPage/locationsPageMetaData';
import jobsPageMetaData, { JOB_PAGE_SDR } from 'pages/JobsPage/jobsPageMetaData';

import pic1 from './assets/1.png';
import pic2 from './assets/2.png';
import pic3 from './assets/3.png';
import pic4 from './assets/4.png';
import pic5 from './assets/5.png';
import cta from './assets/CTA.png';

import styles from './NYCSDRContent.scss';

export function NYCSDRContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt="" />
      <h2>SDR Jobs in NYC: Your Guide to Finding the Best Opportunities</h2>
      <p>
        Tech companies in and around New York City need Sales Development Representatives (SDRs) to
        'help grow and maintain their sales pipeline and improve cross-functional teamwork. As an
        SDR, you’d be sourcing, driving, and establishing connections with prospective clients under
        the direction of the Sales Development Manager and marketing teams to open new business and
        close new deals.
      </p>

      <h2>
        What Are the Best Ways To Find Job Opportunities As a Sales Development Representative In
        New York City?
      </h2>
      <p>
        Those looking for
        <a target="_blank" rel="noopener noreferrer" href={jobsPageMetaData[JOB_PAGE_SDR].localUrl}>
          {' '}
          SDR positions{' '}
        </a>
        in New York City can find work with many different types of tech-related business, from tech
        development to fintech, and, in the process, experience significant personal and
        professional growth.
      </p>
      <p>
        Rainmakers pioneers the market for hiring tech salespeople and offers a transparent,
        data-driven platform that effectively matches top salespeople with cutting-edge tech
        companies. The SDRs with the highest potential are consequently linked with the most
        suitable opportunities that meet their qualifications.
      </p>

      <img src={pic2} alt="" />
      <h2>What Are the Responsibilities of a Sales Development Representative in New York City?</h2>
      <p>
        The measure of an SDR is their ability to move prospects through the sales funnel. Instead
        of closing deals, they concentrate on nurturing great leads. SDRs are usually the first
        point of contact for new customers, so an SDR must be able to reach out and respond
        effectively to potential customers through various methods such as researching,
        cold-calling, emails, and other marketing campaigns. You’ll need to develop observational
        and listening skills to discover and understand customer needs. It’s also essential to build
        relationships with prospects to sell them on your company’s products or services and
        establish meetings with an AE.
      </p>
      <p>
        In
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={locPageMetaData[LOCATIONS_PAGE_NYC].localUrl}
        >
          {' '}
          New York City
        </a>
        , this is how a business’s sales team works from beginning to end: The SDRs receive lead
        information from the marketing department. Leads must be qualified and nurtured by SDRs
        until they are prepared to make a purchase. At that point, the account executive takes
        control and positions the company’s products or services at the ideal moment to close the
        sale.
      </p>
      <p>
        Most sales operations are built on this straightforward approach, but it doesn’t mean an SDR
        function will be simple or undemanding. Instead, you’ll require a particular set of hard and
        soft talents to succeed as an SDR.
      </p>

      <img src={pic3} alt="" />
      <h2>What Does a Typical SDR Career Path Look Like in New York City?</h2>
      <p>
        SDRs can explore numerous roles and discover their strengths and weaknesses. For example,
        SDRs research prospects, write emails, book meetings, and nurture relationships. Find out if
        you prefer to process and strategy, client-facing communication and relationship building,
        or peer leadership. Early awareness of abilities and interests will help direct your sales
        career.
      </p>
      <p>
        The following are eight possible career paths that may open to you after a couple of years
        of gaining experience as an SDR:
      </p>
      <ul>
        <li>Account Executive</li>
        <li>Customer Success</li>
        <li>Account Manager</li>
        <li>Marketing</li>
        <li>Channel Sales</li>
        <li>Strategic Accounts</li>
        <li>Sales Training</li>
        <li>Sales Operations</li>
      </ul>

      <img src={pic4} alt="" />
      <p>
        SDRs are in a great position to assist AEs in closing transactions. As SDR is usually
        entry-level, you’ll receive training when you start. Here are some additional skills that
        SDRs can use to help their sales career be even more successful.
      </p>
      <ul>
        <li>
          <p>
            <strong>Conducting Effective Outreach</strong> - It might be challenging for an SDR to
            prospect while balancing quantity and quality. On the one hand, you want to create a
            strong sales rep pipeline. On the other hand, you are aware that getting in touch with
            qualified leads can take a while. Many successful salespeople are slowing down,
            investing more time in outreach, even when it means interacting with fewer prospects.
          </p>
          <p>
            This entails learning and using efficient outreach techniques, including cold-calling,
            sending emails, and leaving voicemails. SDRs learn which approaches work best with
            different prospects. This not only takes practice but also requires SDRs to know and
            prepare for their prospects’ most frequent objections.
          </p>
        </li>
        <li>
          <p>
            <strong>Active Listening</strong> - An SDR should not be robotic or forced in their
            interactions with prospects; instead, they should come across as sincere and helpful. A
            large part of this is developing an ability to listen actively.
          </p>
          <p>
            No matter what your business sells, it would be best if you were highly tuned in to the
            words and phrases that suggest a potential customer would be a suitable fit for your
            offering. Instead of just checking the boxes for lead qualification, a flexible and
            compassionate SDR concentrates on acquiring information that will benefit a prospect
            later in the sales process.
          </p>
        </li>
      </ul>
      <Link to="/apply-now" title="Apply Now">
        <img src={cta} alt="" />
      </Link>
      <ul>
        <li>
          <p>
            <strong>Resilience</strong> - SDRs have a difficult job. They don’t enjoy the same level
            of success as AEs, whose primary objective is to close sales. Typically, they phone and
            send emails all day long. And more often than not, they get many people saying “no.”
          </p>
          <p>
            It takes work to become resilient. The trick is learning to move on after each rejection
            and not allowing minor setbacks to ruin your day.
          </p>
        </li>
        <li>
          <p>
            <strong>Building Relationships</strong> - Before handing a prospect off to your account
            executive to close, your responsibility as an SDR is to get them primed for a sale.
            Successful SDRs can develop sincere relationships with prospects and win their trust.
          </p>
        </li>
        <li>
          <p>
            <strong>Staying Organized</strong> - Although they might differ from person to person,
            processes are essential to maintaining organization. For example, SDRs can plan their
            days and prioritize the things vital to their success by managing their schedules and
            tasks, such as research, email outreach, calls, meetings, etc.
          </p>
        </li>
        <li>
          <p>
            <strong>Self-Awareness</strong> - Finally, an SDR needs to be conscious of their
            strengths and weaknesses. Self-aware SDRs will seek input from supervisors and
            coworkers, won’t take criticism personally, and will have a solid awareness of their
            skills, limitations, and potential growth areas.
          </p>
        </li>
      </ul>

      <img src={pic5} alt="" />
      <h2>How Does the SDR Job Market Compare In New York City?</h2>
      <p>
        Finding top SaaS sales talent is getting more and more difficult. As a result, internal and
        external recruiters are scouring LinkedIn, online job boards, and other resources looking
        for competent Sales Development Representatives.
      </p>
      <p>
        Regarding the number of positions available in tech and SaaS sales, New York City is
        comfortably in the lead on the East Coast and is comparable to San Francisco. As a result,
        qualified candidates should have no problem matching with a company that can use their
        talents.
      </p>

      <p>
        To find out more about prospects in sales,{' '}
        <a href={`https://${process.env.HOST}/`}>contact Rainmakers</a> today to get started on your
        path to success.
      </p>
    </div>
  );
}
