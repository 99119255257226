import { createStructuredSelector } from 'reselect';

import {
  entitySelectorFactory,
  loadingSelectorFactory,
  pageSelectorFactory,
  totalSelectorFactory,
} from 'reduxStore/selectorFactories';

import {
  selectIsApproved,
  selectIsAdmin,
  selectIsLoggedIn,
  selectIsEmployee,
  selectLogoutPending,
} from 'connectors/Login/redux/selectors';
import { selectUser } from 'connectors/User/redux/selectors';
import {
  selectRequestConfirmation,
  selectSearchEmployerId,
  selectSearchEmployer,
} from 'pages/EmployeePortal/CandidateSearchPage/redux/selectors';

const selector = createStructuredSelector({
  employer: selectSearchEmployer,
  employerId: selectSearchEmployerId,
  logoutPending: selectLogoutPending,
  user: selectUser,
  isLoggedIn: selectIsLoggedIn,
  isApproved: selectIsApproved,
  isAdmin: selectIsAdmin,
  isEmployee: selectIsEmployee,
  featuredCandidates: entitySelectorFactory('featured', 'candidate'),
  featuredCandidatesPage: pageSelectorFactory('featured', 'candidate'),
  featuredCandidatesTotal: totalSelectorFactory('featured', 'candidate'),
  featuredCandidatesLoading: loadingSelectorFactory('featured', 'candidate'),
  requestConfirmation: selectRequestConfirmation,
});

export { selector as default };
