import React, { Component, Fragment } from 'react';
import classnames from 'classnames';

import { makeLike8Param } from 'utils/paramUtils';
import { toggleArray } from 'utils/formUtils';

import Button from 'components/Button';
import EmployerData from 'components/EmployerData';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import InputCheckbox from 'components/InputCheckbox';
import Table from 'components/Table';
import TableCell from 'components/Table/components/TableCell';
import TableRow from 'components/Table/components/TableRow';

import NameScope from './components/NameScope';
import StateScope from './components/StateScope';
import MassAdd from './components/MassAdd';

import styles from './AddEmployersTable.scss';

const STATE_SOURCE = [
  {
    label: 'Approved',
    value: 'approved',
  },
  {
    label: 'Pending',
    value: 'pending',
  },
];

class AddEmployersTable extends Component {
  constructor(props) {
    super(props);

    const { cohortId } = props;

    this.state = {
      name: '',
      applicationStatus: ['approved', 'pending'],
    };

    this.defaultLoadParams = {
      page: 1,
      notInCohortId: cohortId,
      sortBy: 'name_asc',
      name: null,
      applicationStatus: ['approved', 'pending'],
    };

    this.defaultLoadArgs = {
      type: 'employers',
      slice: 'toolboxCohorts',
    };
  }

  componentDidMount() {
    const {
      actions: { entityLoadStarted },
    } = this.props;

    entityLoadStarted({
      ...this.defaultLoadArgs,
      params: {
        ...this.getLoadParams(),
        page: 1,
      },
    });
  }

  getLoadParams = () => {
    const { employersPage = 1 } = this.props;

    const { applicationStatus, name } = this.state;

    return {
      ...this.defaultLoadParams,
      applicationStatus,
      name: makeLike8Param(name),
      page: employersPage,
    };
  };

  loadMore = () => {
    const {
      actions: { entityLoadStarted },
      employersPage: page,
    } = this.props;

    entityLoadStarted({
      ...this.defaultLoadArgs,
      params: {
        ...this.getLoadParams(),
        page: page + 1,
      },
    });
  };

  handleArrayChange = (event) => {
    const {
      target: { value, name },
    } = event;
    const {
      actions: { entityLoadStarted },
    } = this.props;

    const { [name]: array } = this.state;

    const newArray = toggleArray({ array, value });

    this.setState({ [name]: newArray }, () =>
      entityLoadStarted({
        ...this.defaultLoadArgs,
        params: {
          ...this.getLoadParams(),
          page: 1,
        },
      })
    );
  };

  handleSearchInputChange = (event) => {
    const {
      target: { name, value },
    } = event;

    const {
      actions: { entityLoadStarted },
    } = this.props;

    this.setState({ [name]: value }, () =>
      entityLoadStarted({
        ...this.defaultLoadArgs,
        params: {
          ...this.getLoadParams(),
          page: 1,
        },
      })
    );
  };

  render() {
    const {
      actions: { resourceUpdate },
      cohortId,
      isReadOnly,
      cohortEmployersTotal,
      employersLoading,
      employers: { allIds = [], byId = {} } = {},
      employersTotal,
      handleAddAddableEmployers,
      handleHideAddEmployersOptions,
      handleAddAllEmployers,
    } = this.props;

    const { name, applicationStatus } = this.state;

    const massAddProps = {
      show: cohortEmployersTotal === 0,
      handleAddAllEmployers,
    };

    const cancelButtonProps = {
      warningTertiary: true,
      onClick: handleHideAddEmployersOptions,
    };

    const cancelButton = <Button {...cancelButtonProps}>Cancel</Button>;

    const selectAllButtonProps = {
      onClick: handleAddAddableEmployers,
      primary: true,
      disabled: !allIds.some((id) => byId[id].attributes.checked),
    };

    const selectAllButton = <Button {...selectAllButtonProps}>Add All Selected</Button>;

    const submitButtons =
      allIds.length > 0 ? <div className={styles.actionButtons}>{selectAllButton}</div> : null;

    const nameScopeProps = {
      name,
      handleSearchNameChange: this.handleSearchInputChange,
    };

    const stateScopeProps = {
      stateSource: STATE_SOURCE,
      applicationStatus,
      handleStateChange: this.handleArrayChange,
    };

    const searchOptions = (
      <Fragment>
        <div className={styles.info}>
          The options below will display employers in the table for a more refined selection
          process:
        </div>
        <div className={styles.row}>
          <NameScope {...nameScopeProps} />
          <StateScope {...stateScopeProps} />
        </div>
      </Fragment>
    );

    const addEmployersTitle = (
      <div className={styles.addEmployersTitle}>
        <div className={styles.addEmpTitle}>
          <div className={styles.titleText}>
            <MassAdd {...massAddProps} />
            {cancelButton}
          </div>
        </div>
        {searchOptions}
        {submitButtons}
      </div>
    );

    const addEmployersBlockProps = {
      boxShadow: true,
      addWhiteBG: true,
      addFlex: true,
      addChildFlex: true,
      addBottomFade: true,
      disabled: !cohortId,
    };

    const infiniteScrollProps = {
      loadMore: this.loadMore,
      hasMore: employersTotal > allIds.length,
      loader: employersLoading ? <div /> : <InfiniteScrollLoader />,
      useWindow: false,
    };

    const makeEmployerCells = (id) => {
      const employer = byId[id] || {};

      const { attributes, attributes: { applicationStatus, checked = false } = {} } = employer;

      const employerDataProps = {
        employer: attributes,
      };

      const employerDataContent = (
        <div className={styles.employerContent}>
          <EmployerData {...employerDataProps} />
        </div>
      );

      const statusClasses = classnames(styles.statusContent, {
        [styles.greenActive]: applicationStatus === 'approved',
        [styles.inactiveStatus]: applicationStatus === 'pending',
      });

      const statusContent = (
        <div className={statusClasses}>
          {applicationStatus === 'approved' ? 'Approved' : 'Pending'}
        </div>
      );

      const selectEmployerProps = {
        className: styles.checkbox,
        checked,
        name: 'selectEmployer',
        handleInputChange: () =>
          resourceUpdate({
            type: 'employers',
            slice: 'toolboxCohorts',
            id,
            resource: {
              ...employer,
              attributes: {
                ...employer.attributes,
                checked: !checked,
              },
            },
          }),
      };

      const selectEmployerContent = isReadOnly ? null : (
        <TableCell key={1}>
          <InputCheckbox {...selectEmployerProps} />
          {employerDataContent}
        </TableCell>
      );

      const cells = [selectEmployerContent, <TableCell key={2}>{statusContent}</TableCell>];

      return cells;
    };

    const makeEmployerRows = (id) => {
      const { attributes: { applicationStatus } = {} } = byId[id] || {};

      return (
        <TableRow id={id} disabled={applicationStatus === 'pending'} key={id}>
          {makeEmployerCells(id)}
        </TableRow>
      );
    };

    const tableContent = allIds.map(makeEmployerRows);

    const tableProps = {
      blockProps: addEmployersBlockProps,
      titleContent: addEmployersTitle,
      tableContent,
      infiniteScrollProps,
      tableName: 'employers',
      isEmpty: !employersLoading && allIds.length === 0,
      emptyTextString: 'Select options from above to see addable employers here',
      isLoading: employersLoading,
    };

    return (
      <div className={styles.content}>
        <Table {...tableProps} />
      </div>
    );
  }
}

export default AddEmployersTable;
