import { handleError } from 'utils/common';
import { getCandidateRelationship } from 'api/apiCandidates';

const promiseGetCandidateVideo = ({ candidateId, normalizeIt, fullEntity }) => {
  const onFulfilled = ({
    data: {
      entities: {
        vimeoVideo = {
          allIds: [],
          byId: {},
        },
        vimeoVideo: { allIds = [], byId = {} } = {},
      } = {},
    } = {},
  }) =>
    fullEntity
      ? {
          vimeoVideo,
        }
      : {
          vimeoVideo: byId[allIds[0]].attributes || {},
        };

  const getArgs = {
    candidateId,
    config: {
      params: {
        normalizeIt,
      },
    },
    type: 'vimeoVideo',
  };

  return getCandidateRelationship(getArgs).then(onFulfilled).catch(handleError);
};

export default promiseGetCandidateVideo;
