const prefix = 'Rainmakers/Search/';

export const PROFILE_BOOKMARK_ADD = `${prefix}PROFILE_BOOKMARK_ADD`;
export const PROFILE_BOOKMARK_REMOVE = `${prefix}PROFILE_BOOKMARK_REMOVE`;

export const BOOKMARKS_ADD_STARTED = `${prefix}BOOKMARKS_ADD_STARTED`;
export const BOOKMARKS_ADD_DONE = `${prefix}BOOKMARKS_ADD_DONE`;
export const BOOKMARKS_REMOVE_STARTED = `${prefix}BOOKMARKS_REMOVE_STARTED`;
export const BOOKMARKS_REMOVE_DONE = `${prefix}BOOKMARKS_REMOVE_DONE`;
export const RESULTS_LOAD_STARTED = `${prefix}RESULTS_LOAD_STARTED`;
export const RESULTS_LOAD_DONE = `${prefix}RESULTS_LOAD_DONE`;
export const RESULTS_RESET = `${prefix}RESULTS_RESET`;
export const RESULTS_UPDATE = `${prefix}RESULTS_UPDATE`;
export const RESULTS_LOADING_STARTED = `${prefix}RESULTS_LOADING_STARTED`;
export const RESULTS_LOADING_DONE = `${prefix}RESULTS_LOADING_DONE`;

export const REQUEST_SENT_DONE = `${prefix}REQUEST_SENT_DONE`;
export const REQUEST_ERROR_SET = `${prefix}REQUEST_ERROR_SET`;

export const REMOVE_CANDIDATES_FROM_SEARCH = `${prefix}REMOVE_CANDIDATES_FROM_SEARCH`;

export const SET_EMULATED_EMPLOYER = `${prefix}SET_EMULATED_EMPLOYER`;
export const RESET_EMULATED_EMPLOYER = `${prefix}RESET_EMULATED_EMPLOYER`;

export const SET_SEARCH_PARAMS = `${prefix}SET_SEARCH_PARAMS`;
export const RESET_SEARCH_PARAMS = `${prefix}RESET_SEARCH_PARAMS`;

export const SEARCH_CANDIDATE_HIDE_STARTED = `${prefix}SEARCH_CANDIDATE_HIDE_STARTED`;
export const SEARCH_CANDIDATE_HIDE_DONE = `${prefix}SEARCH_CANDIDATE_HIDE_DONE`;

export const ADMIN_SEARCH_CANDIDATE_HIDE_STARTED = `${prefix}ADMIN_SEARCH_CANDIDATE_HIDE_STARTED`;
export const ADMIN_SEARCH_CANDIDATE_HIDE_DONE = `${prefix}ADMIN_SEARCH_CANDIDATE_HIDE_DONE`;

export const SEARCH_CANDIDATE_UNDO_HIDE_STARTED = `${prefix}SEARCH_CANDIDATE_UNDO_HIDE_STARTED`;
export const SEARCH_CANDIDATE_UNDO_HIDE_DONE = `${prefix}SEARCH_CANDIDATE_UNDO_HIDE_DONE`;

export const ADD_REQUESTED_CANDIDATE = `${prefix}ADD_REQUESTED_CANDIDATE`;
export const CLEAR_REQUESTED_CANDIDATES = `${prefix}CLEAR_REQUESTED_CANDIDATES`;

export const SAVE_SEARCH_OPTIONS = `${prefix}SAVE_SEARCH_OPTIONS`;
