import React, { Component } from 'react';

import { VisibleHOC } from 'containers/HOC';

import Dropdown from 'components/Dropdown';

@VisibleHOC()
class ToolboxEmployersActionDropdown extends Component {
  handleClick = (value) => {
    const { patchResource, id } = this.props;

    return patchResource({
      id,
      attributes: {
        [value.field]: value.value,
      },
      requiredFields: ['name'],
      slice: 'toolboxEmployers',
      type: 'employers',
    });
  };

  render() {
    // const source = [
    //   {
    //     label: 'Approve',
    //     value: 'approve',
    //   },
    // ];

    const { employer } = this.props;
    const source = [];

    switch (employer.applicationStatus) {
      case 'pending':
        source.push({
          label: 'Approve',
          value: { field: 'applicationStatus', value: 'approved' },
        });
        break;
      case 'approved':
        source.push({
          label: 'Archive',
          value: { field: 'applicationStatus', value: 'archived' },
        });
        break;
    }

    const { isVisible } = this.props;

    const dropDownProps = {
      above: !isVisible,
      source,
      onChange: this.handleClick,
      value: 'send_message',
      defaultValue: 'Actions',
      left: true,
      actions: true,
      defaultIcon: 'caret-down',
      activeIcon: 'caret-up',
    };

    return <Dropdown {...dropDownProps} />;
  }
}

export default ToolboxEmployersActionDropdown;
