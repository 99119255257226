/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import { getAltName } from 'utils/urlUtils';

import pic1 from './assets/san-francisco-recruiting-agency-1.png';
import pic2 from './assets/san-francisco-tech-recruiters-2.png';
import pic3 from './assets/sales-recruiting-process-3.png';
import pic4 from './assets/find-top-sales-talent-4.png';
// import pic5 from './assets/find-best-sales-talent.png';

import styles from './SanFranciscoContent.scss';

export function SanFranciscoContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt={getAltName(pic1)} />
      <h2>Achieving Sales Team Excellence</h2>
      <p>
        Building an effective and efficient sales team can be challenging. Attracting and hiring the
        right people is essential for your growth and profitability, but top-performing salespeople
        can be hard to find, costing you precious time and money.
      </p>
      <p>
        <em>That's where Rainmakers can help.</em>
      </p>
      <p>
        We are a leading sales recruitment agency in San Francisco, helping sales specialists land
        solid sales team positions and assisting companies in finding their next great hire.
        Connecting high-performing salespeople with great companies is what we do best, taking the
        guesswork out of the sales recruitment process.
      </p>
      <p>
        Read on to learn more about how the expert sales recruiters at Rainmakers can help you
        streamline your sales hiring procedures.
      </p>
      <h2>Why Choose Us as Your San Francisco Sales Recruiters</h2>
      <p>
        There are a lot of sales recruitment agencies in San Francisco. To successfully recruit the
        right people, sales recruitment agencies must go above and beyond to match you with
        candidates with a proven track record of success and align with your sales team and company
        culture.
      </p>
      <p>That's the difference when you choose Rainmakers as your sales recruiting agency.</p>
      <p>
        Our process connects high-performing salespeople with awesome technology companies in San
        Francisco by matching each candidate specifically to the job and company. So how do we do
        that?
      </p>
      <p>
        It's simple.{' '}
        <strong>
          We only select the top salespeople with successful track records and showcase their
          achievements.
        </strong>{' '}
        We do this by going beyond the resume and LinkedIn profile, providing specific sales stats
        and performance measures for each candidate's unique sales profile. In addition, our
        candidates set their hiring requirements and salaries so companies know precisely what to
        expect from potential candidates.
      </p>
      <p>
        When candidates and companies are matched through Rainmakers, sales teams can focus on
        generating sales rather than a revolving door of salespeople.
      </p>
      <p>
        Just ask Account Executive Matt Garcia, who had this to say about finding his current role
        through Rainmakers: "Working with Rainmakers was an easy and seamless experience. I had an
        offer in hand over a week and am flourishing in my new role."
      </p>
      <p>
        Or ask SDR Palmer Johnson, who shared his experience: “If you take your career as a
        salesperson seriously, Rainmakers is the place to be. I am ecstatic about the company that
        hired me, and I would not have found it without the platform.”
      </p>
      <p>
        We’ve helped thousands of candidates find positions with companies uniquely suited to their
        talent and abilities.
      </p>
      <img src={pic2} alt={getAltName(pic2)} />
      <h2>Our Sales Recruitment Process in San Francisco</h2>
      <p>
        You probably know from traditional recruitment processes how much time, energy, and money
        goes into finding a new sales candidate. Once you've posted a job, it can take months to
        attract the right talent to fill your open role, and sometimes it turns out your great new
        hire could have been better after all.
      </p>
      <p>
        Not with Rainmakers.{' '}
        <strong>
          Our sales recruiters know how to find the best candidates in the San Francisco area who
          are ready to be your next top-tier salesperson.
        </strong>{' '}
        We understand what companies in San Francisco are looking for in their sales teams and have
        a unique recruitment methodology tailored to the San Francisco sales market. Plus, we
        provide advice and support throughout the hiring process.
      </p>
      <p>Here's how it works:</p>
      <h3>Define the Role</h3>
      <p>
        The first step in our process is helping you define the role. This should be a detailed and
        precise job description, including skills requirements, salary range, and expectations for
        the position. The more detailed and specific you are, the better matched you'll be with the
        right candidates. In addition, if you provide a clear definition of the role, you'll save
        yourself the time of vetting poorly matched candidates.
      </p>
      <h3>Get Matched with Talent</h3>
      <p>
        Like you define the role, Rainmakers candidates define themselves by creating unique sales
        profiles, showcasing their achievements and salary requirements. Once your position is
        posted, you will be matched with top-tier sales talent that matches your open role.
      </p>
      <h3>Hire the Right Candidate</h3>
      <p>
        Rainmakers provides a pool of candidates specifically sourced for your role and assists you
        with hiring. When you make an offer to a sales candidate, you know you've selected the right
        person for the job.
      </p>
      <h2>Sales Industries We Serve in San Francisco</h2>
      <p>
        We work with everyone from large industries to not-for-profits with a focus on the
        technology industry. The tech industry reigns supreme in San Francisco, with major tech
        companies like Apple, Google, Meta, Salesforce, and Uber calling San Francisco home for
        their headquarters.
      </p>
      <p>
        Other high-demand industries in San Francisco are Financial Services, Biotechnology, and
        Life Sciences.
      </p>
      <p>
        Successful salespeople are in high demand in the San Francisco area. Sales recruitment firms
        like Rainmakers help tech companies stay ahead of the competition when recruiting top sales
        talent.
      </p>
      <img src={pic3} alt={getAltName(pic3)} />
      <h2>San Francisco Sales Job Market Insights</h2>
      <p>
        San Francisco has a thriving job market, especially in the tech industry. More and more tech
        companies are flocking to the area because it's known for being home to cutting-edge tech
        companies and enterprises. In addition, 18 Fortune 500 companies are based in San Francisco,
        with 39 in Silicon Valley. So, it's no wonder top salespeople are swarming the area to get a
        job working for them, creating an influx of workers looking for jobs in the tech industry.
      </p>
      <p>
        Fortunately, tens of thousands of tech jobs are added yearly, despite current tech industry
        layoffs and global uncertainties. In addition, San Francisco tech companies offer a median
        salary of $87,129 for tech salespeople, helping make San Francisco a popular destination for
        top talent in the tech sales industry.
      </p>
      <Link to="/employer-signup/" title="Apply Now">
        <img src={pic4} alt={getAltName(pic4)} />
      </Link>
      <h2>How to Get Started</h2>
      <p>
        Hiring the right salespeople for your team can be the difference between growth and profit
        versus personnel overturns and losing sales. Working with a sales recruitment agency can
        help you expand your salesforce with the confidence that you have found the right candidates
        for your sales team.
      </p>
      <p>
        The sales recruiters at Rainmakers can guide your company to further growth and profit by
        finding and matching the very best sales candidates with your company.
      </p>
      <p>
        Ready to start building your dream sales team?{' '}
        <a href="https://www.rainmakers.co/employer-signup/">Create a free account</a> with
        Rainmakers and start browsing candidates!
      </p>
    </div>
  );
}
