import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Disclaimer.scss';

const Disclaimer = () => (
  <div className={styles.Disclaimer}>
    By creating an account, you agree to the{' '}
    <span className={styles.inlineLink}>
      <Link target="_blank" tabIndex={10} to="/terms">
        Terms of Service
      </Link>
    </span>{' '}
    and{' '}
    <span className={styles.inlineLink}>
      <Link target="_blank" tabIndex={10} to="/privacy">
        Privacy Policy
      </Link>
    </span>
    .
  </div>
);

export default Disclaimer;
