import { getEntity } from 'api/apiEntity';

const promiseGetEmployees = ({ employerId, page, sortBy }) => {
  const params = {
    page,
    sortBy,
    employerId,
    includeSet: 'employer_company',
    camelizeIt: true,
  };

  const onFulfilled = (response) => {
    const {
      data: {
        employees = [],
        meta: {
          pagination: { currentPage: page, totalCount: total, totalPages },
        },
      },
    } = response;

    return {
      employees,
      page,
      total,
      totalPages,
    };
  };

  const args = {
    type: 'employees',
    config: {
      params,
    },
  };

  return getEntity(args).then(onFulfilled);
};

export default promiseGetEmployees;
