import React, { useState } from 'react';

import Button from 'components/Button';
import DialogueModal from 'components/DialogueModal';
import InputSelect from 'components/InputSelect';
import TalentConcierge from 'components/TalentConcierge';

import styles from './CreateSuggestionSet.scss';

const CreateSuggestionSet = (props) => {
  const { suggestionSet: { attributes: { rainmakersAdminId: adminId } = {} } = {} } = props;

  const [rainmakersAdminId, setRainmakersAdminId] = useState(adminId);

  const {
    handleCloseClick,
    patchResource,
    rainmakersAdmins: { byId = {}, allIds = [] } = {},
    requisitionOpeningId,
    slice,
    suggestionSet: { id } = {},
    usersById = {},
  } = props;

  const {
    [rainmakersAdminId]: {
      attributes,
      relationships: { user: { data: userIds = [] } = {} } = {},
    } = {},
  } = byId || {};

  const { attributes: user = {} } = usersById[userIds[0]] || {};

  const admin = {
    ...attributes,
    user,
  };

  const adminSource = allIds.map((id) => {
    const {
      [id]: { attributes: { firstName, lastName } = {} },
    } = byId || {};

    return {
      label: `${firstName} ${lastName}`,
      value: id,
    };
  });

  const adminInputProps = {
    source: adminSource,
    name: 'rainmakersAdminId',
    handleInputChange: (event) => setRainmakersAdminId(event.target.value),
    value: rainmakersAdminId || '',
    defaultLabel: 'Update Admin',
    defaultIcon: 'caret-down',
    noMargin: true,
    setWidth: '160px',
    bigInput: true,
  };

  const talentConciergeProps = {
    admin,
    adminInput: <InputSelect {...adminInputProps} />,
  };

  const createButtonProps = {
    onClick: () => {
      patchResource({
        id,
        attributes: {
          rainmakersAdminId,
          requisitionOpeningId,
          dataSource: 'admin',
        },
        params: {
          includeSet: 'list_component',
          normalizeIt: true,
        },
        requiredFields: ['rainmakersAdminId', 'requisitionOpeningId'],
        slice,
        type: 'suggestion_sets',
      });
      handleCloseClick();
    },
    primary: true,
    disabled: !rainmakersAdminId,
  };

  const closeButtonProps = {
    onClick: handleCloseClick,
    quaternary: true,
  };

  return (
    <DialogueModal>
      <div className={styles.modal}>
        <TalentConcierge {...talentConciergeProps} />
        <div className={styles.actionButtons}>
          <Button {...closeButtonProps}>Close</Button>
          <Button {...createButtonProps}>Create</Button>
        </div>
      </div>
    </DialogueModal>
  );
};

export default CreateSuggestionSet;
