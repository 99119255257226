import React, { useState, useRef } from 'react';

import { trackEvent } from 'utils/analytics';
import { segmentComponentClicked } from 'segment/eventNames';

import { ComponentErrorBoundaryHOC } from 'containers/HOC';

import Button from 'components/Button';
import FontIcon from 'components/FontIcon';

import WorkName from '../WorkName';
import WorkDetail from '../WorkDetail';

import styles from './WorkHistory.scss';

const trackShowDetailsClick = () =>
  trackEvent({
    event: segmentComponentClicked,
    properties: {
      componentName: 'Work History Show Details',
      page: 'Candidate Profile',
    },
  });

const trackHideDetailsClick = () =>
  trackEvent({
    event: segmentComponentClicked,
    properties: {
      componentName: 'Work History Hide Details',
      page: 'Candidate Profile',
    },
  });

const WorkHistory = ({ workHistory, isFirst, isPrint }) => {
  const {
    company,
    endDate = '',
    position: positionName = '',
    startDate = '',
    description: origDescription = '',
  } = workHistory || {};

  const {
    about: companyAbout = '',
    investors: companyInvestors,
    name: companyName = '',
    picture,
    size: companySize = '',
    stage: companyStage = '',
  } = company || {};

  const { xlarge: companyLogo } = picture || {};

  const [showDetails, setShowDetails] = useState(isPrint);
  const workDetailsRef = useRef();

  const containerClasses = isFirst ? styles.workHistoryContainerFirst : styles.workHistoryContainer;

  const workDetails = (
    <div ref={workDetailsRef}>{showDetails && <WorkDetail description={origDescription} />}</div>
  );

  const workDetailButton = showDetails ? (
    <>
      {!isPrint && (
        <Button
          secondary={true}
          className={styles.showDetails}
          onClick={() => {
            trackHideDetailsClick();
            setShowDetails(false);
          }}
        >
          Hide details <FontIcon className={styles.showDetailsIcon} iconName="caret-up" />
        </Button>
      )}
    </>
  ) : (
    <Button
      secondary={true}
      className={styles.showDetails}
      onClick={() => {
        trackShowDetailsClick();
        setShowDetails(true);
        workDetailsRef.current.scrollIntoView();
      }}
    >
      Show details <FontIcon className={styles.showDetailsIcon} iconName="caret-down" />
    </Button>
  );

  const description = origDescription
    ? origDescription.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, '')
    : '';

  const workDetailButtonFinal = description ? workDetailButton : null;

  const workNameProps = {
    companyAbout,
    companyInvestors,
    companyLogo,
    companyName,
    companySize,
    companyStage,
    endDate,
    positionName,
    startDate,
    workDetailButton: workDetailButtonFinal,
    workDetails,
  };

  return (
    <div className={containerClasses}>
      <div className={styles.workNameContainer}>
        <WorkName {...workNameProps} />
      </div>
    </div>
  );
};

export default ComponentErrorBoundaryHOC()(WorkHistory);
