import { create } from 'axios';
import normalize from 'json-api-normalizer';
import camelCase from 'lodash.camelcase';
import camelCaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';
import isEmpty from 'lodash.isempty';
import compareVersions from 'compare-versions';

import { hackSortBy } from 'utils/paramUtils';

import config from '../config';

import { transformData } from './apiUtils';

const { apiBaseURL, apiBaseVersion } = config;

const timeout = 30000;

const apiConfig = {
  baseURL: apiBaseURL,
  timeout,
};

const api = create(apiConfig);

api.interceptors.request.use((config) => {
  const { data, params } = config;

  let newData = data;
  let newParams = params;

  if (data && data.data && !(data.data instanceof FormData)) {
    newData = snakeCaseKeys(data, { deep: true });
  }

  if (params) {
    newParams = snakeCaseKeys(params, {
      deep: true,
      exclude: [/^filter\[/],
    });
  }

  if (newData) {
    return newData && newData.data && newData.data instanceof FormData
      ? {
          ...config,
          data: newData.data,
          params: newParams,
        }
      : {
          ...config,
          data: newData,
          params: newParams,
        };
  }

  if (newParams && newParams.sort_by) {
    newParams.sort_by = hackSortBy({ sortBy: newParams.sort_by });
  }

  return {
    ...config,
    params: newParams,
  };
});

api.interceptors.response.use((response = {}) => {
  const { data: { meta: { api_version: apiVersion } = {} } = {} } = response;

  if (apiVersion && compareVersions(apiVersion, apiBaseVersion) > 0) {
    if (window && window.location) {
      return window.location.reload(true);
    }
  }

  return response;
});

api.interceptors.response.use((response) => {
  const {
    config: {
      params: { normalize_it: normalizeIt = false, camelize_it: camelizeIt = false } = {},
    } = {},
    data = {},
  } = response || {};

  const { meta = {} } = data;

  // The normalizeIt param is sent along with the request when
  // we want to use the data in the new normalized style from the
  // jsonapi serializer
  //
  // if the param is not present, we translate it back into the old
  // response style further down
  if (normalizeIt) {
    const normalized = normalize(data);

    const entities = Object.keys(normalized);

    if (isEmpty(entities)) {
      const camelResponse = camelCaseKeys(response, { deep: true });

      return {
        ...camelResponse,
        data: {
          ...camelResponse.data,
          meta: camelCaseKeys(meta, { deep: true }),
        },
      };
    }

    // moreNormal is the response being parsed and split into
    // byId and allIds properties for easier access
    const moreNormal = entities.reduce((acc, curr) => {
      const { [curr]: byId } = normalized;
      const allIds = Object.keys(byId);

      allIds.forEach((id) => {
        const { relationships = {} } = byId[id];

        const relationshipKeys = Object.keys(relationships);

        relationshipKeys.forEach((key) => {
          const { data } = relationships[key];

          if (data && data.id) {
            byId[id].relationships[key].data = [data.id];
            byId[id].relationships[key].type = [data.type];
            return;
          }

          if (Array.isArray(data)) {
            byId[id].relationships[key].data = data.map((d) => d.id);
            byId[id].relationships[key].type = data.map((d) => d.type);
            return;
          }

          delete byId[id].relationships[key];
        });
      });

      acc[curr] = {
        byId,
        allIds,
      };

      return acc;
    }, {});

    const newResponse = {
      ...response,
      data: {
        entities: moreNormal,
        meta: camelCaseKeys(meta, { deep: true }),
      },
    };

    return newResponse;
  }

  if (data && data.data) {
    if (Array.isArray(data.data) && data.data[0] && data.data[0].type) {
      const normalized = normalize(data, { camelizeKeys: camelizeIt });

      const key = camelizeIt ? camelCase(data.data[0].type) : data.data[0].type;

      const singular = false;

      // transform data into old response style (denormalize)
      const newData = transformData(normalized, key, singular);

      const newResponse = {
        ...response,
        data: {
          ...newData,
          meta: camelizeIt ? camelCaseKeys(meta, { deep: true }) : meta,
        },
      };

      return newResponse;
    }

    if (data.data && data.data.type) {
      const normalized = normalize(data, { camelizeKeys: camelizeIt });

      const key = camelizeIt ? camelCase(data.data.type) : data.data.type;

      const singular = true;

      // transform data into old response style (denormalize)
      const newData = transformData(normalized, key, singular);

      const newResponse = {
        ...response,
        data: {
          ...newData,
          meta: camelizeIt ? camelCaseKeys(meta, { deep: true }) : meta,
        },
        originalResponse: response,
      };

      return newResponse;
    }
  }

  return camelizeIt ? camelCaseKeys(response, { deep: true }) : response;
});

const { delete: apiDelete, get, patch, post, put } = api;

export default api;

export { apiDelete, get, patch, post, put };
