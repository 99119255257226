import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import classnames from 'classnames';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// import { ScrollContainer } from 'react-router-scroll';
import throttle from 'lodash.throttle';
import store from 'store';
import * as Sentry from '@sentry/browser';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';

import AdminHeader from 'components/AdminHeader';
import AppErrorReporter from 'components/AppErrorReporter';
import CandidateHeader from 'components/CandidateHeader';
import ConnectContainer from 'containers/ConnectContainer';
import { ComponentErrorBoundaryHOC } from 'containers/HOC';
import Content from 'components/Content';
import EmployeeHeader from 'components/EmployeeHeader';
import Header from 'components/Header';
import Title from 'components/Title';
import { CandidateSearchDisabled } from 'pages/EmployeePortal/CandidateSearchPage';
import { FeaturedPageDisabled } from 'pages/EmployeePortal/FeaturedPage';

import { usePrevious } from 'hooks';
import { employerHomePageValues } from 'utils/constants';

import duck from 'shared/store/app/';

import { trackPage } from 'utils/analytics';
import { scrubIt } from 'utils/ravenUtils';

import ErrorModal from 'connectors/DialogModals/ErrorModal';
import Notification from 'connectors/Notification';

import config from '../../src/config';

import { modalRoutes, nonModalRoutes } from './routes';

import './global.scss';
import styles from './App.module.scss';

dayjs.extend(customParseFormat);

const BLACK_BG_PATHS = ['intro-video'];

const WHITE_BG_PATHS = [
  '/',
  '/employers',
  '/login',
  '/login/candidate',
  '/login/employee',
  '/set-requirements',
  '/apply-now',
  '/community-apply',
  '/create-profile',
  '/community-profile',
  '/add-sales-info',
  '/add-video',
  '/terms',
  '/my-hiring-profile',
  '/privacy',
  '/faq',
  '/employer-faq',
  '/employer-signup',
  '/about-us',
  '/careers',
  '/sitemap',
  '/atlanta-sales-recruiter',
  '/austin-sales-recruiter',
  '/boston-sales-recruiter',
  '/chicago-sales-recruiter',
  '/los-angeles-sales-recruiter',
  '/new-york-sales-recruiter',
  '/san-diego-sales-recruiter',
  '/san-francisco-sales-recruiter',
  '/seattle-sales-recruiter',
  '/toronto-sales-recruiter',
];

const App = (props) => {
  const location = useLocation();
  const history = useHistory();
  const prevLocation = usePrevious(location);

  const mainRef = useRef();

  const [canvasScroll, setCanvasScroll] = useState(0);
  const [addStyle, setAddStyle] = useState(true);

  useEffect(() => {
    Sentry.init({
      dsn: process.env.SENTRY_DSN,
      environment: process.env.NODE_ENV,
      beforeSend: scrubIt,
    });

    const {
      isAdmin,
      user: { pingerBot = false },
    } = props;

    if (!pingerBot && !isAdmin) {
      interceptRouteChangeWithPageview();
    }

    history.listen(({ hash }) => {
      if (!hash) {
        document && document.querySelector('html').scrollTo(0, 0);
        // } else {
        //   const id = hash.replace('#', '');
        //   const element = document.getElementById(id);
        //   if (element) element.scrollIntoView();
      }
    });

    if (window) {
      window.addEventListener('beforeunload', trackSessionLastActive);
    }

    if (window && window.drift && isAdmin) {
      window.drift.on('ready', (api) => api.widget.hide());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { pathname: prevPathname = '/' } = prevLocation || {};
    const { pathname: currentPathname = '/' } = location || {};

    const {
      actions: { pingSession },
      user: { pingerBot = false },
      isAdmin,
      isLoggedIn,
    } = props;

    pingSession && pingSession();

    if (isLoggedIn) {
      document.querySelector('body').onscroll = null;
      Sentry.setTag('userId', props.user?.id);
    }
    if (!isLoggedIn) {
      document.querySelector('body').onscroll = handleScroll;
      Sentry.setTag('userId', null);
    }

    if (prevPathname !== currentPathname) {
      removeAddStyle();

      if (!pingerBot && !isAdmin) {
        interceptRouteChangeWithPageview();
      }

      setTimeout(() => setAddStyle(true), 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleScroll = throttle(() => {
    setCanvasScroll(document.querySelector('html').scrollTop);
  }, 250);

  const interceptRouteChangeWithPageview = () => {
    trackPage();

    if (window.Intercom && typeof window.Intercom === 'function') {
      window.Intercom('update');
    }
  };

  const removeAddStyle = () => setAddStyle(false);

  const trackSessionLastActive = () => {
    const sessionStart = Date.parse(store.get('sessionStart'));
    if (sessionStart) {
      const sessionLastActive = new Date();

      store.set('sessionLastActive', sessionLastActive);
    }
  };

  const {
    appError,
    appModalError,
    actions: { resetAppError },
    isApproved,
    user: { currentProfile, currentProfileType, isIE } = {},
    modal: { originalUrl, initialRender } = {},
  } = props;

  const {
    user: { currentProfile: { employer: { homepage } = {} } = {} },
  } = props;

  const { state = {} } = location || {};
  const { modal = null } = state || {};

  const isLoggedIn = Boolean(currentProfileType);
  const isCandidate = currentProfileType === 'Candidate';
  const isAdmin = currentProfileType === 'RainmakersAdmin';
  const isEmployee = currentProfileType === 'Employee';

  const disabledEmployeeUrls =
    isEmployee && homepage ? employerHomePageValues[homepage]?.disabled_pages || [] : [];

  const background =
    (modal || initialRender) && originalUrl ? new URL(`http://www.fake.com${originalUrl}`) : null;
  const { pathname = '/' } = location;

  const isPrint = pathname.endsWith('/print');

  const isTurboProfile = pathname.includes('turbo-profile');

  const isWhiteBG =
    WHITE_BG_PATHS.includes(pathname) ||
    WHITE_BG_PATHS.includes(pathname.substr(0, pathname.length - 1)) ||
    isTurboProfile ||
    isPrint ||
    !isLoggedIn;

  const firstPathSegment = pathname.split('/')[1];
  const isBlackBG = BLACK_BG_PATHS.includes(firstPathSegment);

  const canvasProps = {
    // onScroll: !isLoggedIn || isCandidate ? handleScroll : undefined,
    className: classnames(styles.canvasContent, {
      [styles.canvasContentPrint]: isPrint || isTurboProfile,
      [styles.whiteCanvas]: isWhiteBG,
      [styles.blackCanvas]: isBlackBG,
    }),
  };

  const appErrorReporterProps = {
    appError,
    appModalError,
    resetAppError,
  };

  const appErrorContent = appError ? <AppErrorReporter {...appErrorReporterProps} /> : null;

  const errorModalContent = appModalError ? <ErrorModal {...appErrorReporterProps} /> : null;

  const ieContent = isIE ? (
    <div {...canvasProps}>
      <div className={styles.unsupportedContainer}>
        <Title>Uh oh...</Title>
        <Content className={styles.unsupported}>
          Sorry, it looks like you are using a browser that we do not currently support. For the
          best experience with Rainmakers, please try a browser like
          <a href="https://www.google.com/chrome/browser/">Chrome</a> or
          <a href="https://www.mozilla.org/firefox">Firefox</a>.
        </Content>
      </div>
    </div>
  ) : null;

  const empHelmetProps = pathname.includes('employers') ? config.app.employerHead : null;

  const candHelmetProps =
    pathname.includes('profile') &&
    !pathname.includes('my-hiring') &&
    !pathname.includes('create-profile')
      ? config.app.candidateHead
      : null;

  const helmetProps = empHelmetProps || candHelmetProps || config.app.head;

  const allRoutes = [...modalRoutes, ...nonModalRoutes];

  const allRoutesFiltered = allRoutes.map((route) =>
    route.route === '/featured' && disabledEmployeeUrls.includes(route.route)
      ? { ...route, component: CandidateSearchDisabled }
      : route.route === '/matches' && disabledEmployeeUrls.includes(route.route)
      ? { ...route, component: FeaturedPageDisabled }
      : route
  );

  const routeComponents = allRoutesFiltered.map((route) => (
    <Route key={route.route} exact path={route.route} component={route.component} />
  ));

  const modalComponents = modalRoutes.map((route) => (
    <Route key={route.route} exact path={route.route} component={route.component} />
  ));

  const mainProps = {
    key: 'app',
    // onScroll: !isLoggedIn ? handleScroll : undefined,
    className: classnames(styles.Main, {
      [styles.overflowScrolling]: addStyle,
    }),
    ref: mainRef,
  };

  return (
    ieContent || (
      <main {...mainProps}>
        <Helmet {...helmetProps} />
        <Route
          render={(routeProps) => {
            const headerProps = {
              ...routeProps,
              canvasScroll,
              onboardingStatus: isCandidate ? currentProfile.onboardingStatus : null,
              isApproved,
            };

            if (isPrint || isTurboProfile) {
              return null;
            }

            const candidateHeader = isCandidate ? <CandidateHeader {...headerProps} /> : null;

            const adminHeader = isAdmin ? <AdminHeader {...headerProps} /> : null;

            const employeeHeader = isEmployee ? <EmployeeHeader {...headerProps} /> : null;

            return candidateHeader || adminHeader || employeeHeader || <Header {...headerProps} />;
          }}
        />
        {appErrorContent}
        <div {...canvasProps}>
          <Switch location={background || location}>{routeComponents}</Switch>
          {background && <Switch>{modalComponents}</Switch>}
        </div>
        {errorModalContent}
        <Notification />
      </main>
    )
  );
};

export default ComponentErrorBoundaryHOC()(ConnectContainer(duck)(App));
