import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { allRoutes } from 'shared/routes';
import jobsPageData from 'pages/JobsPage/jobsPageMetaData';
import ReadyContainer from 'connectors/ReadyContainer';

import Footer from 'pages/CandidatesHome/components/Footer';

import styles from './SitemapPage.scss';

const canininicalUrl = (url) => (url[url.length - 1] === '/' ? url : `${url}/`);

const SitemapPage = () => {
  const title = 'Sitemap';
  const metaDescription = 'Sitemap';
  const canonicalUrl = `https://${process.env.HOST}/sitemap/`;

  const [allRouteStrings, setAllRouteStrings] = useState([]);

  useEffect(() => {
    const forumRoutes =
      process.env.FORUM_TARGET && process.env.FORUM_PATHNAME ? [process.env.FORUM_PATHNAME] : [];

    const jobsPageRoutes = Object.keys(jobsPageData).map((page) => jobsPageData[page].localUrl);

    setAllRouteStrings(
      [
        ...allRoutes.filter((route) => route.route && route.sitemap).map((route) => route.route),
        ...jobsPageRoutes,
        ...forumRoutes,
      ].map(canininicalUrl)
    );
  }, []);

  const helmetProps = {
    key: 'helmet',
    title,
    meta: [
      { name: 'description', content: metaDescription },
      { property: 'og:url', content: canonicalUrl },
      { property: 'og:title', content: title },
      { property: 'og:description', content: metaDescription },
    ],
    link: [{ rel: 'canonical', href: canonicalUrl }],
  };

  return (
    <>
      <Helmet {...helmetProps} />
      <ReadyContainer key="readyContainer" className={styles.readyContainer}>
        <h1>Rainmakers Sitemap</h1>
        <table>
          <tbody>
            {allRouteStrings.map((url, index) => {
              const fullUrl =
                process.env.HOST === 'localhost'
                  ? `localhost:7777${url}`
                  : `https://${process.env.HOST}${url}`;

              return (
                <tr key={index}>
                  <td>
                    <a href={fullUrl} target="_blank" rel="noreferrer" className={styles.urlLink}>
                      {fullUrl}
                    </a>
                  </td>
                  <td />
                  <td />
                </tr>
              );
            })}
          </tbody>
        </table>
      </ReadyContainer>
      <Footer />
    </>
  );
};

export default SitemapPage;
