import { createAction } from 'redux-actions';

import {
  NEW_PASSWORD_STARTED,
  NEW_PASSWORD_DONE,
  CONFIRM_PASSWORD_STARTED,
  CONFIRM_PASSWORD_DONE,
  NEW_PASSWORD_ERROR,
  NEW_PASSWORD_ERROR_RESET,
} from '../actions';

export const newPasswordStarted = createAction(NEW_PASSWORD_STARTED);
export const newPasswordDone = createAction(NEW_PASSWORD_DONE);
export const confirmPasswordStarted = createAction(CONFIRM_PASSWORD_STARTED);
export const confirmPasswordDone = createAction(CONFIRM_PASSWORD_DONE);
export const newPasswordError = createAction(NEW_PASSWORD_ERROR);
export const newPasswordErrorReset = createAction(NEW_PASSWORD_ERROR_RESET);
