import React from 'react';
import classnames from 'classnames';

import { createEducationOrganization } from 'reduxStore/schema';

import Table from 'components/Table';
import TableRow from 'components/Table/components/TableRow';
import TableCell from 'components/Table/components/TableCell';
import EducationOrgData from 'components/EducationOrgData';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import Button from 'components/Button';
import FontIcon from 'components/FontIcon';

import ToolboxEducationOrgActionDropdown from '../ToolboxEducationOrgActionDropdown';
import ToolboxEducationOrgsSearchBar from '../ToolboxEducationOrgsSearchBar';

import styles from './ToolboxEducationOrgsTable.scss';

const ToolboxEducationOrgsTable = ({
  actions,
  actions: { resourceUpdate, showModal },
  educationOrgs = [],
  handleSearchInputChange,
  handleSearchCheckboxChange,
  isLoading,
  loadMore,
  name,
  total,
  unverifiedDataState,
  unverifiedEducationOrganizationData: { byId: unverifiedDataById = {} } = {},
}) => {
  const tableHeader = [
    {
      content: 'Name',
      headerClassName: styles.nameHeader,
    },
    {
      content: 'Verification Status',
      headerClassName: styles.statusHeader,
    },
    {
      content: '',
      headerClassName: styles.candActionHeader,
    },
  ];

  const openProfileModal = (educationOrgId) =>
    showModal({
      key: 'EducationOrgEditorModal',
      parent: 'ToolboxEducationOrgsPage',
      route: `/toolbox/education-org/${educationOrgId}/edit-profile`,
    });

  const makeEducationOrgCells = (educationOrg = {}) => {
    const {
      id: educationOrgId,
      relationships: {
        unverifiedEducationOrganizationData: { data: unverifiedDataIds = [] } = {},
      } = {},
    } = educationOrg;

    const unverifiedEducationOrganizationData = unverifiedDataById[unverifiedDataIds[0]] || {};

    const verified = unverifiedDataIds[0]
      ? !unverifiedDataById[unverifiedDataIds[0]]
      : !unverifiedDataIds[0];

    const statusClassnames = classnames({
      [styles.verifiedStatus]: verified,
      [styles.unverifiedStatus]: !verified,
    });

    const statusContent = !verified ? (
      <div className={statusClassnames}>
        <div className={styles.iconDanger}>!</div>
      </div>
    ) : (
      <div className={statusClassnames}>
        <FontIcon iconName="checkmark-circle" />
      </div>
    );

    const actionsContent = !verified ? (
      <div className={styles.actionsContent}>
        <ToolboxEducationOrgActionDropdown
          actions={actions}
          educationOrgId={educationOrgId}
          unverifiedData={unverifiedDataById[unverifiedDataIds[0]] || null}
          educationOrg={educationOrg}
        />
      </div>
    ) : (
      <div className={styles.actionsContent} />
    );

    const educationOrgDataProps = {
      educationOrg: {
        ...educationOrg,
        attributes: {
          ...educationOrg.attributes,
          unverifiedEducationOrganizationData,
        },
      },
      onClick: () => openProfileModal(educationOrgId),
    };

    const educationOrgDataContent = (
      <div className={styles.educationOrgContent}>
        <EducationOrgData {...educationOrgDataProps} />
      </div>
    );

    const cells = [
      <TableCell key={1}>{educationOrgDataContent}</TableCell>,
      <TableCell key={2}>{statusContent}</TableCell>,
      <TableCell noFlex key={3}>
        {actionsContent}
      </TableCell>,
    ];

    return cells;
  };

  const makeEducationOrgRows = (educationOrg) => (
    <TableRow id={educationOrg.id} key={educationOrg.id}>
      {makeEducationOrgCells(educationOrg)}
    </TableRow>
  );

  const tableContent = educationOrgs
    .filter((org) => !org.attributes.temporary)
    .map(makeEducationOrgRows);

  const searchBarProps = {
    handleSearchInputChange,
    handleSearchCheckboxChange,
    name,
    unverifiedDataState,
  };

  const createEducationOrgClick = () => {
    const resource = createEducationOrganization();

    resourceUpdate({
      slice: 'toolboxEducationOrgs',
      type: 'education_organizations',
      id: resource.id,
      resource,
    });

    showModal({
      key: 'EducationOrgEditorModal',
      parent: 'ToolboxEducationOrgsPage',
      route: `/toolbox/education-org/${resource.id}/edit-profile`,
    });
  };

  const createProps = {
    onClick: createEducationOrgClick,
    primary: true,
  };

  const disclaimer =
    unverifiedDataState && unverifiedDataState.includes('unverified') ? (
      <div className={styles.educationOrgBottom}>
        The logos shown on this table are suggestions and will not be displayed to users until
        verified. If the image looks correct, please select the verify option from the dropdown. If
        not, please click the company name, reject the suggestion, and manually update the logo and
        any additional data.
      </div>
    ) : null;

  const filtersContent = (
    <div className={styles.educationOrgHeader}>
      <div className={styles.candidateHeaderLeft}>
        <ToolboxEducationOrgsSearchBar {...searchBarProps} />
        {disclaimer}
      </div>
      <Button {...createProps}>+ Education Org</Button>
    </div>
  );

  const infiniteScrollProps = {
    loadMore,
    hasMore: total > educationOrgs.length,
    loader: isLoading ? (
      <div key="infiniteScrollLoader" />
    ) : (
      <InfiniteScrollLoader key="infiniteScrollLoader" />
    ),
    useWindow: false,
  };

  const tableProps = {
    titleContent: filtersContent,
    tableContent,
    header: tableHeader,
    infiniteScrollProps,
    tableName: 'adminEducationOrgs',
    isEmpty: !isLoading && educationOrgs.length === 0,
    emptyTextString: 'No Education Orgs',
    isLoading,
  };

  return <Table {...tableProps} />;
};

export default ToolboxEducationOrgsTable;
