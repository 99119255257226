import React from 'react';

import ReactMarkdown from 'react-markdown';

import styles from './WorkDetail.scss';

const WorkDetail = ({ description }) => {
  return description ? (
    <ReactMarkdown children={description} className={styles.workDetailContainer} />
  ) : null;
};

export default WorkDetail;
