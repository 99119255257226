import React from 'react';
import classnames from 'classnames';

import CandidateData from 'components/CandidateData';
import DropdownSelectMany from 'components/DropdownSelectMany';
import FormattedName from 'components/FormattedName';
import FormattedDate from 'components/FormattedDate';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import Table from 'components/Table';
import TableRow from 'components/Table/components/TableRow';
import TableCell from 'components/Table/components/TableCell';

import ToolboxApproachesActionDropdown from 'pages/AdminPortal/ToolboxApproachesPage/components/ToolboxApproachesActionDropdown';

import styles from './EmployerApproachesTable.scss';

const STATUSES = [
  { label: 'Active', value: 'active' },
  { label: 'Hired', value: 'hired' },
  { label: 'Passed', value: 'passed' },
  { label: 'Candidate Passed', value: 'candidate_passed' },
  { label: 'Candidate Pass Pending', value: 'candidate_pass_pending' },
  { label: 'Employer Passed', value: 'employer_passed' },
  { label: 'Inactive', value: 'inactive' },
];

const EmployerApproachesTable = ({
  actions,
  containerRef,
  candidates: { byId: candidatesById = {} } = {},
  requisitionCandidates: { byId = {}, allIds = [] } = {},
  loadMoreRequisitionCandidates,
  privateReqCanFeedback: { byId: privateReqCanFeedbackById = {} } = {},
  requisitionCandidatesTotal,
  state,
  handleSearchFilterChange,
  requisitionOpenings: { byId: requisitionOpeningsById = {} } = {},
  ...others
}) => {
  const makeEmployerRows = (requisitionCandidate) => {
    const {
      attributes: { attentionRequired = false, createdAt, state } = {},
      id,
      relationships: {
        candidate: { data: candidateIds = [] } = {},
        creator: { data: creatorIds = [], type: creatorTypes = [] } = {},
        requisitionOpeningCopy: { data: requisitionOpeningIds = [] } = {},
      } = {},
    } = requisitionCandidate || {};

    const creatorType = `${creatorTypes[0]}s`;

    const { [creatorType]: { byId: creatorsById = {} } = {} } = others;

    const {
      attributes: {
        firstName: creatorFirstName = '',
        lastName: creatorLastName = '',
        type = '',
      } = {},
    } = creatorsById[creatorIds[0]] || {};

    const { attributes: { externalName = '' } = {} } =
      requisitionOpeningsById[requisitionOpeningIds[0]] || {};

    const { attributes: { firstName, lastName } = {} } = candidatesById[candidateIds[0]] || {};

    const candidateDataProps = {
      candidate: candidatesById[candidateIds[0]] || {},
      subtitle: externalName,
    };

    const candidateContent = (
      <div className={styles.candidateContent}>
        <CandidateData {...candidateDataProps} />
      </div>
    );

    const statusContentClasses = classnames(styles.statusContent, {
      [styles.statusHired]: state === 'hired',
      [styles.statusPassed]: state === 'passed',
    });

    const attentionRequiredContent = attentionRequired ? (
      <div className={styles.iconDanger}>!</div>
    ) : null;

    const statusContent = (
      <div className={statusContentClasses}>
        {state}
        {attentionRequiredContent}
      </div>
    );

    const createdAtContent = (
      <div className={styles.statusContent}>
        <FormattedDate parse date={createdAt} format="MMM D YYYY" />
      </div>
    );

    const creatorNameContent =
      type === 'RainmakersAdmin' ? (
        <div className={styles.statusContent}>Admin</div>
      ) : (
        <div className={styles.statusContent}>
          <FormattedName firstName={creatorFirstName} lastName={creatorLastName} />
        </div>
      );

    const privateReqCanFeedbackIds = Object.keys(privateReqCanFeedbackById).filter(
      (privateId) =>
        privateReqCanFeedbackById[privateId] &&
        privateReqCanFeedbackById[privateId].attributes.requisitionCandidateId === id
    );

    const privateReqCanFeedbackCandidateId = privateReqCanFeedbackIds.find(
      (id) => privateReqCanFeedbackById[id].attributes.ownerType === 'Candidate'
    );

    const privateReqCanFeedbackEmployeeId = privateReqCanFeedbackIds.find(
      (id) => privateReqCanFeedbackById[id].attributes.ownerType === 'Employee'
    );

    const { [privateReqCanFeedbackCandidateId]: privateReqCanFeedbackCandidate } =
      privateReqCanFeedbackById || {};

    const { [privateReqCanFeedbackEmployeeId]: privateReqCanFeedbackEmployee } =
      privateReqCanFeedbackById || {};

    const dropdownProps = {
      actions,
      attentionRequired,
      containerRef,
      firstName,
      lastName,
      privateReqCanFeedbackCandidate,
      privateReqCanFeedbackEmployee,
      requisitionCandidate,
      slice: 'toolboxEmployers',
      state,
    };

    const actionsContent = (
      <div className={styles.actionsContent}>
        <ToolboxApproachesActionDropdown {...dropdownProps} />
      </div>
    );

    const row = (
      <TableRow id={id} key={id} className={styles.employerRow}>
        <TableCell key={1}>{candidateContent}</TableCell>
        <TableCell flex key={2}>
          {statusContent}
        </TableCell>
        <TableCell flex key={4}>
          {createdAtContent}
        </TableCell>
        <TableCell flex key={5}>
          {creatorNameContent}
        </TableCell>
        <TableCell noFlex key={6}>
          {actionsContent}
        </TableCell>
      </TableRow>
    );

    return row;
  };

  const tableContent = allIds.map((id) => byId[id]).map(makeEmployerRows);

  const tableHeader = [
    {
      content: 'Candidate',
      headerClassName: styles.companyHeader,
    },
    {
      content: 'Status',
      headerClassName: styles.otherHeader,
    },
    {
      content: 'Created At',
      headerClassName: styles.otherHeader,
    },
    {
      content: 'Creator Name',
      headerClassName: styles.otherHeader,
    },
    {
      content: '',
      headerClassName: styles.candActionHeader,
    },
  ];

  const infiniteScrollProps = {
    pageStart: 0,
    loadMore: loadMoreRequisitionCandidates,
    hasMore: requisitionCandidatesTotal > allIds.length,
    loader: <InfiniteScrollLoader key="infiniteScrollLoader" />,
    useWindow: false,
  };

  const stateSource = STATUSES.map((state) => ({
    indent: 0,
    label: state.label,
    optionType: 'role',
    subCategories: [],
    value: {
      type: 'state',
      state: state.value,
    },
  }));

  const selectedStates = state.map((state) => ({ state, type: 'state' }));

  const stateFilterProps = {
    className: styles.searchBarFilter,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    source: stateSource,
    name: 'state',
    key: 'state',
    optionsLarge: true,
    isLocation: true,
    onChange: (value) =>
      handleSearchFilterChange({
        target: {
          subCategories: value.subCategories,
          label: value.label,
          value: value.value,
          name: 'state',
          optionType: value.optionType,
        },
      }),
    selectedValues: selectedStates,
    defaultLabel: 'Click to select application status',
  };

  const filtersContent = (
    <div className={styles.candidateHeader}>
      <div className={styles.stack}>
        <DropdownSelectMany {...stateFilterProps} />
      </div>
    </div>
  );

  const tableProps = {
    noBlockFlex: true,
    titleContent: filtersContent,
    header: tableHeader,
    tableContent,
    infiniteScrollProps,
    tableName: 'employerApproaches',
    emptyTextString: 'No Approaches',
    isEmpty: allIds.length === 0,
  };

  return <Table {...tableProps} />;
};

export default EmployerApproachesTable;
