import React from 'react';

import InputBullets from 'components/InputBullets';

import styles from '../ReqRequirement.scss';

const DesiredBackground = ({
  disabled,
  handleAddArrayInput,
  handleAddArrayValue,
  handleDeleteArrayInput,
  handleReorderArray,
  requisitionRequirement: { attributes: { desiredBackground } = {} } = {},
}) => {
  const desiredBackgroundProps = {
    disabled,
    label: 'Desired background',
    secondaryLabel: 'Add ideal prerequisites for the role',
    array: desiredBackground,
    name: 'desiredBackground',
    handleAddArrayInput,
    handleAddArrayValue,
    handleDeleteArrayInput,
    handleReorderArray,
    pad: true,
    inputClass: styles.inputBulletContainer,
  };

  return (
    <div className={styles.rangeInputs}>
      <div className={styles.column}>
        <InputBullets {...desiredBackgroundProps} />
      </div>
    </div>
  );
};

export default DesiredBackground;
