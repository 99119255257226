import React from 'react';

import Content from 'components/Content';
import ContentTitle from 'components/ContentTitle';
import FormattedArray from 'components/FormattedArray';
import styles from './Extras.scss';

const Extras = ({ employer: { attributes: { links } = {} } = {} }) => {
  const mappedLinks = links
    ? links.map((link) => {
        const formattedLink = link.includes('http') ? link : `http://${link}`;

        return (
          <div title={formattedLink} key={formattedLink} className={styles.pressLink}>
            <a href={formattedLink} rel="noreferrer" target="_blank">
              {link}
            </a>
          </div>
        );
      })
    : null;

  const props = {
    array: mappedLinks,
  };

  return links && links.length > 0 ? (
    <div className={styles.Extras}>
      <ContentTitle>EXTRAS</ContentTitle>
      <Content className={styles.ExtrasContent}>
        <FormattedArray {...props} />
      </Content>
    </div>
  ) : null;
};

export default Extras;
