import { take, put, call, select } from 'redux-saga/effects';

import { patchEntity } from 'api/apiEntity';

import { handleError } from 'utils/common';
import { trackEvent } from 'utils/analytics';

import { segmentIntroRequestPassedCandidate } from 'segment/eventNames';

import { getModal } from 'shared/store/app/selectors';
import { selectCandidate } from 'pages/CandidatePortal/CandidateEditorPages/redux/selectors';

import { PATCH_REQUISITION_CANDIDATE_STARTED } from '../actions';

import { hideModal, patchRequisitionCandidateDone, showModal } from '../creators';

const trackCandidateAction = (candidate = {}, event) => {
  const {
    candidateFirstName,
    candidateLastName,
    candidateId,
    creator: {
      firstName: creatorFirstName,
      lastName: creatorLastName,
      type: creatorType,
      id: creatorId,
    } = {},
    category = 'unlisted',
    origin,
  } = candidate;

  const properties = {
    candidateFirstName,
    candidateLastName,
    creatorFirstName,
    creatorLastName,
    creatorType,
    category,
    origin,
    candidateId,
    creatorId,
  };

  const eventObject = { event, properties };

  trackEvent(eventObject);
};

const sagaPatchRequisitionCandidateStarted = function* sagaPatchRequisitionCandidateStarted() {
  while (true) {
    const {
      payload: { attributes, reqCandidateId },
    } = yield take(PATCH_REQUISITION_CANDIDATE_STARTED);

    try {
      const args = {
        id: reqCandidateId,
        type: 'requisition_candidates',
        data: {
          id: reqCandidateId,
          type: 'requisition_candidate',
          attributes,
        },
        config: {
          params: {
            includeSet: 'employer',
            camelizeIt: true,
          },
        },
      };

      const {
        data: { requisitionCandidate: newRequisitionCandidate },
      } = yield call(patchEntity, args);

      const { firstName: candidateFirstName, lastName: candidateLastName } = yield select(
        selectCandidate
      );
      const event = segmentIntroRequestPassedCandidate;

      yield call(
        trackCandidateAction,
        {
          candidateFirstName,
          candidateLastName,
          ...newRequisitionCandidate,
        },
        event
      );

      yield put(patchRequisitionCandidateDone({ requisitionCandidate: newRequisitionCandidate }));

      const { originalUrl, backKey, backPath, parent } = yield select(getModal);

      if (backKey && backPath) {
        yield put(
          showModal({
            backKey: null,
            backPath: null,
            key: backKey,
            originalUrl,
            parent,
            route: backPath,
          })
        );
      } else {
        yield put(hideModal());
      }
    } catch (error) {
      handleError(error);
    }
  }
};

export default sagaPatchRequisitionCandidateStarted;
