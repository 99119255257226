import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';

import { AdminAuthHOC } from 'containers/HOC';

import { deleteResource } from 'api/apiResource';

import { handleError } from 'utils/common';
import { createRoute, queryStringParse, getDefaultParams } from 'utils/paramUtils';
import { toggleArray } from 'utils/formUtils';

import { promisePostAddCandidates } from 'pages/AdminPortal/ToolboxBatchRequestsPage/promises';

import ConnectContainer from 'containers/ConnectContainer';

import ReadyContainer from 'connectors/ReadyContainer';

import Bold from 'components/Bold';
import Button from 'components/Button';
import DialogueModal from 'components/DialogueModal';
import Sidebar from 'components/Sidebar';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import StatusFilter from 'components/StatusFilter';

import CohortCollisionsModal from 'pages/AdminPortal/ToolboxCohortEditorPage/components/CohortEditorCandidates/components/CohortCollisionsModal';

import ToolboxBatchRequestsTable from './components/ToolboxBatchRequestsTable';
import CohortsTable from './components/CohortsTable';

import duck from './redux';

import styles from './ToolboxBatchRequestsPage.scss';

const STATUSES = [
  { label: 'All', value: 'all' },
  { label: 'Open', value: 'open' },
  { label: 'Active', value: 'active' },
  { label: 'Complete', value: 'complete' },
];

const route = '/toolbox/cohort-requests/';

const ToolboxBatchRequestsPage = ({
  actions,
  actions: { entityLoadStarted, entityLoadDone },
  page = 1,
  cohortsPage,
  batchRequests,
  batchRequests: { allIds = [], byId = {} } = {},
  candidates,
  children,
  cohorts,
  cohortsTotal,
  isLoading,
  total,
}) => {
  const location = useLocation();
  const { search } = location;

  const params = {
    sortBy: {
      type: 'string',
      defaultValue: 'updated_at_desc',
    },
    state: {
      type: 'array',
      defaultValue: ['pending'],
    },
  };

  const queryParamsParsed = queryStringParse({ search, params });

  const defaultParams = getDefaultParams(params);

  const [queryParams, setQueryParams] = useState(queryParamsParsed);
  const [cohortType, setCohortType] = useState('search');
  const [cohortStatus, setCohortStatus] = useState('active');
  const [showCohorts, setShowCohorts] = useState(false);
  const [selectedCohorts, setSelectedCohorts] = useState([]);
  const [collisionErrors, setCollisionErrors] = useState();
  const [collisionCohortId, setCollisionCohortId] = useState();

  const newRoute = useRef();

  const defaultLoadParams = {
    ...defaultParams,
    includeSet: 'candidate',
    page: 1,
  };

  const defaultLoadArgs = {
    type: 'batch_requests',
    slice: 'toolboxBatchRequests',
  };

  useEffect(() => {
    const state = cohortStatus === 'all' ? undefined : cohortStatus;

    entityLoadStarted({
      ...defaultLoadArgs,
      params: {
        ...getLoadParams(),
        page: 1,
      },
    });

    entityLoadStarted({
      type: 'cohorts',
      slice: 'toolboxBatchRequests',
      params: {
        category: cohortType,
        page: 1,
        state,
        sortBy: 'created_at_desc',
        virtual: false,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (newRoute.current) {
      entityLoadStarted({
        ...defaultLoadArgs,
        newRoute: newRoute.current,
        params: {
          ...getLoadParams(),
          page: 1,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const getLoadParams = () => {
    const { sortBy, state } = queryParams;

    return {
      ...defaultLoadParams,
      page,
      sortBy,
      state,
    };
  };

  const loadMoreCohorts = () => {
    const page = cohortsPage;
    const state = cohortStatus === 'all' ? undefined : cohortStatus;

    entityLoadStarted({
      type: 'cohorts',
      slice: 'toolboxBatchRequests',
      params: {
        page: page + 1,
        category: cohortType,
        state,
        sortBy: 'created_at_desc',
        virtual: false,
      },
    });
  };

  const handleSelectCohort = ({ cohort }) => {
    if (selectedCohorts.filter((co) => co.id === cohort.id).length > 0) {
      const filteredCohorts = selectedCohorts.filter((co) => co.id !== cohort.id);
      setSelectedCohorts(filteredCohorts);
    } else {
      const featuredSelectedCohorts = selectedCohorts.filter((co) => co.category === 'featured');
      const searchSelectedCohorts = selectedCohorts.filter((co) => co.category === 'search');

      if (featuredSelectedCohorts.length > 0 && cohort.category === 'featured') {
        const newCohorts = selectedCohorts.filter((co) => co.category !== 'featured');
        newCohorts.push(cohort);
        setSelectedCohorts(newCohorts);
      }

      if (featuredSelectedCohorts.length === 0 && cohort.category === 'featured') {
        const newCohorts = selectedCohorts.slice();
        newCohorts.push(cohort);
        setSelectedCohorts(newCohorts);
      }

      if (searchSelectedCohorts.length > 0 && cohort.category === 'search') {
        const newCohorts = selectedCohorts.filter((co) => co.category !== 'search');
        newCohorts.push(cohort);
        setSelectedCohorts(newCohorts);
      }

      if (searchSelectedCohorts.length === 0 && cohort.category === 'search') {
        const newCohorts = selectedCohorts.slice();
        newCohorts.push(cohort);
        setSelectedCohorts(newCohorts);
      }
    }
  };

  const onResolved = (data) => {
    entityLoadDone({
      slice: 'toolboxBatchRequests',
      type: 'batch_requests',
      updateOnly: true,
      data,
    });
  };

  const onFail = (error, id) => {
    handleError(error);
    const { response: { data: { errors } = {} } = {} } = error || {};

    setCollisionErrors(errors);
    setCollisionCohortId(id);
  };

  const handleAddCandidatesToSelectedCohorts = async () => {
    try {
      const promises = selectedCohorts.map(({ id }) =>
        promisePostAddCandidates({
          cohortId: id,
          candidatesToAdd: allIds
            .filter((id) => byId[id].attributes.checked)
            .map((id) => byId[id].attributes.candidateId),
        })
          .then(onResolved)
          .catch((error) => onFail(error, id))
      );

      await Promise.all(promises);

      entityLoadStarted({
        ...defaultLoadArgs,
        params: {
          ...getLoadParams(),
          page: 1,
        },
      });
    } catch (error) {
      handleError(error);
      const { response: { data: { errors } = {} } = {} } = error || {};

      setCollisionErrors(errors);
    }

    setShowCohorts(false);
    setSelectedCohorts([]);
  };

  const handleChangeCohortType = (type) => {
    if (cohortType !== type) {
      setCohortType(type);
      const state = cohortStatus === 'all' ? undefined : cohortStatus;

      entityLoadStarted({
        type: 'cohorts',
        slice: 'toolboxBatchRequests',
        params: {
          category: type,
          page: 1,
          sortBy: 'created_at_desc',
          state,
          virtual: false,
        },
      });
    }
  };

  const handleChangeCohortStatus = (status) => {
    if (cohortStatus !== status) {
      setCohortStatus(status);

      const state = status === 'all' ? undefined : status;

      entityLoadStarted({
        type: 'cohorts',
        slice: 'toolboxBatchRequests',
        params: {
          category: cohortType,
          page: 1,
          sortBy: 'created_at_desc',
          state,
          virtual: false,
        },
      });
    }
  };

  const loadMoreBatchRequests = () => {
    entityLoadStarted({
      ...defaultLoadArgs,
      params: {
        ...getLoadParams(),
        page: page + 1,
      },
    });
  };

  const handleSearchFilterChange = (event) => {
    const {
      target: { name, value },
    } = event;

    const { [name]: arrayToUpdate } = queryParams;

    const actualValue = value[value.type];

    const newArray = toggleArray({ array: arrayToUpdate, value: actualValue });

    newRoute.current = createRoute({
      route,
      location,
      name,
      value: newArray,
    });

    setQueryParams((queryParams) => ({
      ...queryParams,
      [name]: newArray,
    }));
  };

  const handleCollisionsSubmitClick = async ({ candidatesToSkip, candidatesToRemove }) => {
    const candidatesToAdd = allIds
      .filter(
        (id) =>
          byId[id].attributes.checked && !candidatesToSkip.includes(byId[id].attributes.candidateId)
      )
      .map((id) => byId[id].attributes.candidateId);

    const promises = candidatesToRemove.map(({ cohortCandidateId: id }) =>
      deleteResource({
        type: 'cohort_candidates',
        id,
      })
    );

    try {
      await Promise.all(promises);

      await promisePostAddCandidates({
        cohortId: collisionCohortId,
        candidatesToAdd,
        candidatesToSkip,
      });
    } catch (error) {
      handleError(error);
    }

    entityLoadStarted({
      ...defaultLoadArgs,
      params: {
        ...getLoadParams(),
        page: 1,
      },
    });
  };

  const tableProps = {
    actions,
    candidates,
    batchRequests,
    handleSearchFilterChange,
    handleShowCohorts: () => setShowCohorts((showCohorts) => !showCohorts),
    isLoading,
    loadMoreBatchRequests,
    state: queryParams.state,
    total,
  };

  const cancelButtonProps = {
    quaternary: true,
    onClick: () => {
      setShowCohorts(false);
      setSelectedCohorts([]);
    },
  };

  const selectButtonProps = {
    primary: true,
    onClick: handleAddCandidatesToSelectedCohorts,
    disabled: selectedCohorts.length === 0,
  };

  const selectButtonText =
    selectedCohorts.length > 1
      ? `Add to Selected Cohorts (${selectedCohorts.length})`
      : 'Add to Selected Cohort';

  const featureCohortFilterProps = {
    className: classnames(styles.cohortFilter, {
      [styles.activeFilter]: cohortType === 'featured',
    }),
    onClick: () => handleChangeCohortType('featured'),
  };

  const searchCohortFilterProps = {
    className: classnames(styles.cohortFilter, {
      [styles.activeFilter]: cohortType === 'search',
    }),
    onClick: () => handleChangeCohortType('search'),
  };

  const statusFilterProps = {
    statuses: STATUSES,
    value: cohortStatus || 'all',
    handleChangeStatus: handleChangeCohortStatus,
  };

  const cohortBlockTitle = (
    <div className={styles.cohortBlockTitle}>
      <div className={styles.cohortFilterContainer}>
        <div {...searchCohortFilterProps}>Featured</div>
        <div {...featureCohortFilterProps}>Match</div>
      </div>
      <div className={styles.cohortHeaderText}>
        <Bold>cohorts </Bold>with status:
      </div>
      <StatusFilter {...statusFilterProps} />
    </div>
  );

  const cohortsTableProps = {
    titleContent: cohortBlockTitle,
    selectedCohorts,
    cohorts,
    cohortsTotal,
    loadMoreCohorts,
    handleSelectCohort,
  };

  const cohortSelect = showCohorts ? (
    <DialogueModal className={styles.noWidth}>
      <div className={styles.cohortModal}>
        <CohortsTable {...cohortsTableProps} />
        <div className={styles.buttons}>
          <Button {...cancelButtonProps}>Cancel</Button>
          <Button {...selectButtonProps}>{selectButtonText}</Button>
        </div>
      </div>
    </DialogueModal>
  ) : null;

  const collisions =
    collisionErrors &&
    collisionErrors[0] &&
    collisionErrors[0].error &&
    collisionErrors[0].error.collisions
      ? collisionErrors[0].error.collisions
      : [];

  const collisionModalProps = {
    collisions,
    handleCloseClick: () => setCollisionErrors([]),
    handleSubmitClick: handleCollisionsSubmitClick,
  };

  const collisionModal =
    collisions.length > 0 ? <CohortCollisionsModal {...collisionModalProps} /> : null;

  const content = (
    <div className={styles.toolboxBatchRequestsMainContent}>
      <ToolboxBatchRequestsTable {...tableProps} />
      {cohortSelect}
      {collisionModal}
    </div>
  );

  const layoutWithoutSidebarProps = {
    content,
  };

  return [
    <Helmet key="helmet" title="Toolbox - Cohort Requests" />,
    <ReadyContainer key="readyContainer">
      <Sidebar type="toolbox" page="toolboxBatchRequests" />

      <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />

      {children}
    </ReadyContainer>,
  ];
};

export default ConnectContainer(duck)(AdminAuthHOC()(ToolboxBatchRequestsPage));
