import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { EmployeeAuthHOC } from 'containers/HOC';

import ReadyContainer from 'connectors/ReadyContainer';

import { handleError } from 'utils/common';

import ConnectContainer from 'containers/ConnectContainer';

import Block from 'components/Block';
import Button from 'components/Button';
import LayoutWithSidebar from 'components/LayoutWithSidebar';
import Sidebar from 'components/Sidebar';

import EmployeeDisabledPageContent from 'components/EmployeeDisabledPageContent';

import SearchBar from './components/SearchBar';
import SavedSearches from './components/SavedSearches';

import { promiseSearchOptions } from './promises';

import duck from './redux';

import styles from './CandidateSearch.scss';

const defaultRole = {
  filter: 'role',
  label: 'AE',
  value: ['filter[candidate_requirement.roles][]=Account Executive'],
};

const CandidateSearchDisabled = (props) => {
  const [state, setState] = useState({
    candidateSearchOptions,
    searchActivatedId: '',
    experience: [],
    role: [defaultRole.value],
    locations: [],
    moreFilters: [],
  });

  const {
    user: {
      currentProfile: {
        employer: { searchRequested },
      },
    },
    actions: { employerPatchStarted },
  } = props;

  const actionButtonText = searchRequested === true ? 'Recruiter Notified' : 'Notify my recruiter';
  const actionButtonDisabled = searchRequested === true ? true : false;

  useEffect(() => {
    promiseSearchOptions()
      .then((candidateSearchOptions) => {
        setState((prevState) => ({ ...prevState, candidateSearchOptions }));
      })
      .catch(handleError);
  }, []);

  const handleActionButtonClick = () => {
    employerPatchStarted({ name: 'searchRequested', newValue: true });
  };

  const { candidateSearchOptions, experience, role, locations, searchActivatedId } = state;

  const searchBarProps = {
    experience,
    role,
    locations,
    candidateSearchOptions,
    highlightLocations: locations && locations.length === 0,
    disabled: true,
  };

  const searchBar = (
    <div className={styles.searchBarContainer}>
      <SearchBar {...searchBarProps} />
      <div className={styles.buttonsContainer}>
        <Button
          key="moreFiltersButton"
          secondary={true}
          className={styles.moreFilters}
          disabled={true}
        >
          More Filters
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <Helmet key="helmet" title="Featured Candidates" />
      <ReadyContainer key="readyContainer" className={styles.CandidateSearch}>
        <Sidebar type="search" page="candidateSearch" />
        <LayoutWithSidebar
          sidebar={<SavedSearches searchActivatedId={searchActivatedId} disabled={true} />}
          content={
            <div className={styles.searchMainContent}>
              <Block
                title={searchBar}
                boxShadow={true}
                addWhiteBG={true}
                addFlex={true}
                addChildFlex={true}
                addBottomFade={true}
              >
                <EmployeeDisabledPageContent
                  actionButtonText={actionButtonText}
                  actionButtonDisabled={actionButtonDisabled}
                  handleActionButtonClick={handleActionButtonClick}
                />
              </Block>
            </div>
          }
          right={true}
        />
      </ReadyContainer>
    </>
  );
};

export default ConnectContainer(duck)(EmployeeAuthHOC()(CandidateSearchDisabled));
