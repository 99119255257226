import React, { useCallback, useEffect, useState, useMemo } from 'react';
import dayjs from 'dayjs';

import InputSelect from 'components/InputSelect';

import { makeYears, monthSource as fullMounthSource } from 'utils/formUtils';

import styles from './InputSelectMonthYear.scss';

function InputSelectMonthYear({
  onChange,
  name,
  value, // YYYY-MM-DD
  defaultIcon = 'caret-down',
  activeIcon = 'caret-up',
  autofocusFields = [],
  maxDate = dayjs(),
  autoselectLatestAvailable = false,
}) {
  const [month, setMonth] = useState(value ? dayjs(value, 'YYYY-MM-DD').month() : null);
  const [year, setYear] = useState(value ? dayjs(value, 'YYYY-MM-DD').year() : null);

  const date = useMemo(
    () => (month === null || year === null ? null : dayjs(new Date(year, month, 1))),
    [month, year]
  );

  const yearsSource = useMemo(
    () => makeYears().filter((y) => (maxDate ? y.value <= maxDate.year() : true)),
    [maxDate]
  );

  const [monthSource, setMonthSource] = useState(
    fullMounthSource.filter((m) =>
      maxDate && maxDate.year() === year ? m.value <= maxDate.month() + 1 : true
    )
  );

  useEffect(() => {
    if (date) {
      onChange({ name, value: date.format('YYYY-MM-DD') });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const handleMonthChange = useCallback((event) => {
    const {
      target: { value: newValue },
    } = event;

    setMonth(newValue - 1);
  }, []);

  const handleYearChange = useCallback(
    (event) => {
      const {
        target: { value: newValue },
      } = event;

      setYear(newValue);

      if (maxDate && Number(newValue) === maxDate.year()) {
        const newMonthSource = fullMounthSource.filter((m) => m.value <= maxDate.month() + 1);
        setMonthSource(newMonthSource);

        if (newMonthSource.every((m) => m.value !== month + 1) && autoselectLatestAvailable) {
          // if selected month does not exist in the selected year - select the latest available month for the year

          const maxAllowedMonth = newMonthSource.reduce(
            (acc, val) => (val.value > acc ? val.value : acc),
            0
          );
          setMonth(maxAllowedMonth - 1);
        }
      } else {
        setMonthSource(fullMounthSource);
      }
    },
    [autoselectLatestAvailable, maxDate, month]
  );

  return (
    <div className={styles.container}>
      <InputSelect
        handleInputChange={handleMonthChange}
        name={`${name}Month`}
        source={monthSource}
        value={month === null ? month : month + 1}
        defaultLabel={'Month'}
        defaultIcon={defaultIcon}
        activeIcon={activeIcon}
        autoFocus={autofocusFields.includes(`${name}Month`)}
        highlight={autofocusFields.includes(`${name}Month`)}
        noMargin
        data-testid="input-select-month"
      />
      <InputSelect
        handleInputChange={handleYearChange}
        name={`${name}Year`}
        source={yearsSource}
        value={year}
        defaultLabel={'Year'}
        defaultIcon={defaultIcon}
        activeIcon={activeIcon}
        autoFocus={autofocusFields.includes(`${name}Year`)}
        highlight={autofocusFields.includes(`${name}Year`)}
        noMargin
        data-testid="input-select-year"
      />
    </div>
  );
}

export default InputSelectMonthYear;
