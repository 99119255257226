import { createStructuredSelector } from 'reselect';

import {
  apiErrorsSelectorFactory,
  entitySelectorFactory,
  loadingSelectorFactory,
  pageSelectorFactory,
  totalSelectorFactory,
  savingSelectorFactory,
} from 'reduxStore/selectorFactories';

import { selectPickerOptions, selectTags } from 'connectors/Defaults/redux/selectors';

import { getModal } from 'shared/store/app/selectors';

import {
  selectIsLoggedIn,
  selectIsAdmin,
  selectLogoutPending,
} from 'connectors/Login/redux/selectors';

import { selectUser } from 'connectors/User/redux/selectors';

import selectCompany from './selectCompany';
import selectCompanyErrors from './selectCompanyErrors';

const selector = createStructuredSelector({
  apiErrors: apiErrorsSelectorFactory('toolboxCompanies'),
  companies: entitySelectorFactory('toolboxCompanies', 'company'),
  company: selectCompany,
  companyErrors: selectCompanyErrors,
  isAdmin: selectIsAdmin,
  isLoading: loadingSelectorFactory('toolboxCompanies', 'company'),
  isLoggedIn: selectIsLoggedIn,
  logoutPending: selectLogoutPending,
  modal: getModal,
  page: pageSelectorFactory('toolboxCompanies', 'company'),
  pickerOptions: selectPickerOptions,
  saving: savingSelectorFactory('toolboxCompanies'),
  total: totalSelectorFactory('toolboxCompanies', 'company'),
  tags: selectTags,
  unverifiedCompanyData: entitySelectorFactory('toolboxCompanies', 'unverifiedCompanyData'),
  user: selectUser,
});

export { selector as default };
