import React from 'react';

import Content from 'components/Content';
import ContentTitle from 'components/ContentTitle';
import Bolder from 'components/Bolder';

import styles from './Summary.scss';

const Summary = ({ employer: { attributes: { sizeMax, sizeMin, stage } = {} } = {} }) => {
  const sizeMaxString = sizeMax ? sizeMax.toLocaleString() : null;

  const sizeMinString = sizeMin ? sizeMin.toLocaleString() : null;

  const sizeMinStringFinal = sizeMinString === '10,000' ? `${sizeMinString}+` : sizeMinString;

  const sizeToShow = sizeMaxString || sizeMinStringFinal || '-';

  const sizeText =
    sizeMaxString && sizeMinString ? `${sizeMinString} - ${sizeMaxString}` : sizeToShow;

  const sizeContent = sizeMin ? (
    <div className={styles.SummaryItem}>
      <Bolder>{sizeText} employees</Bolder>
    </div>
  ) : null;

  const stageContent = stage ? (
    <div className={styles.SummaryItem}>
      <Bolder>{stage} funding</Bolder>
    </div>
  ) : null;

  return sizeContent || stageContent ? (
    <div className={styles.Summary}>
      <ContentTitle>SUMMARY</ContentTitle>
      <Content className={styles.SummaryContent}>
        {sizeContent}
        {stageContent}
      </Content>
    </div>
  ) : null;
};

export default Summary;
