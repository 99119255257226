/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import { getAltName } from 'utils/urlUtils';

import pic1 from './assets/1-new-york-sales-recruiting-agency.png';
import pic2 from './assets/2-best-sales-recruiters-rainmakers.png';
import pic3 from './assets/3-rainmakers-hiring-process.png';
import pic4 from './assets/4-sales-jobs-nyc.png';
import pic5 from './assets/5-sales-recruiter.png';

import styles from './NYCContent.scss';

export function NYCContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt={getAltName(pic1)} />
      <h2>Top Recruiting Agencies in New York City</h2>
      <h3>
        {'1) '} <a href="https://hirewell.com/">Hirewell</a>
      </h3>
      <p>
        What sets Hirewell apart from other recruiting agencies is its team of recruitment
        consultants who specialize in a variety of functions and industries. From technology and HR
        to marketing, sales, finance and accounting, real estate, supply chain, insurance, and
        executive recruiting, Hirewell has the expertise and experience to find the right talent for
        any organization. In addition, Hirewell has developed its own recruiting tech, which enables
        it to match candidates with the best opportunities and helps clients streamline their
        recruitment processes.
      </p>
      <h3>
        {'2) '} <a href="https://www.rainmakers.co/employers">Rainmakers</a>
      </h3>
      <p>
        Rainmakers offers a proven, effective process that takes the workload off of hiring managers
        while improving the quality of the candidates they interview. By first defining your needs
        and then reaching candidates, the team at Rainmakers ensures that the people you spend time
        with are highly qualified, talented candidates for your business.
      </p>
      <h3>
        {'3) '} <a href="https://nbocellistaffing.com/main/">Nadine Bocelli & Company, Inc.</a>
      </h3>
      <p>
        Nadine Bocelli & Company, Inc. and New York Legal Staffing, Inc. are legal staffing
        companies dedicated to providing direct hire, temp-to-perm, and temporary legal staffing and
        employment solutions in the Greater New York, New Jersey, and Connecticut areas. Their
        expertise, integrity, and dedication make them the top choice for legal staffing and
        recruitment in New York City.
      </p>
      <h3>
        {'4) '} <a href="https://www.redstreamtechnology.com/">RedStream Technology</a>
      </h3>
      <p>
        RedStream Technology is a recruitment agency that specializes in connecting exceptional I.T.
        professionals to extraordinary career opportunities since 2007. Their high-touch, hands-on
        approach and deep relationships with stakeholders result in a greater understanding of their
        needs. This means a more satisfying placement for candidates and the highest rate of return
        on staffing investment for companies.
      </p>
      <Link to="/employer-signup/" title="Apply Now">
        <img src={pic2} alt={getAltName(pic2)} />
      </Link>
      <h2>How to Hire Sales Talent in New York City</h2>
      <p>
        Rainmakers provides organizations, from start-ups to established corporations, with
        top-shelf sales talent and steady hiring success. With Rainmakers in their corner, hiring
        teams can rest easy in the city that never sleeps.
      </p>
      <h2>The Tech Sales Climate in New York City</h2>
      <p>
        Let’s face it – hiring has never been more challenging. The tech sales market grows more
        competitive by the day, and sifting through pile after pile of candidates eats up valuable
        resources.
      </p>
      <p>
        Even when time and budget are removed from the sales recruiting equation, it can be
        exceedingly difficult for in-house hiring teams to fully understand the nuances of hiring
        sales applicants that will thrive in their organizations over the long term.
      </p>
      <p>
        Salespeople all come equipped with unique strengths and weaknesses, which can ultimately
        make or break their performance over time. So, if you need top sales talent but aren’t up to
        speed with industry insights, assessing sales skills, or determining factors that make for a
        good fit, you may be in the market for sales recruiting services.
      </p>
      <p>
        Let’s take a closer look at how a sales recruiting agency can benefit your organization
        today.
      </p>
      <h2>What is a Sales Recruitment Agency?</h2>
      <p>
        A sales recruitment agency specializes in qualifying, sourcing, and assessing sales
        positions, unlike a traditional recruitment agency, which offers the same services for a
        broader range of roles. This narrow focus on sales gives sales recruitment agencies the edge
        when connecting employers to top sales talent because of their depth of sales knowledge and
        vital industry insights.
      </p>
      <p>
        Moving qualified candidates into the interviewing process is significant in the hiring
        puzzle.{' '}
        <strong>
          {' '}
          However, locating eligible candidates who also fit specific job requirements, company
          culture considerations, and temperamental preferences is often too much for in-house teams
          to handle independently.
        </strong>
      </p>
      <p>
        Remember that partnering with a sales recruiting agency allows in-house hiring teams to cast
        a much wider net than simply posting job openings on job boards, optimizes the hiring
        process, and significantly decreases the average time-to-hire.
      </p>
      <h2>How Does a Recruiting Agency Help the Hiring Process?</h2>
      <p>
        Recruiting agencies augment the hiring process in several impactful ways. Hiring teams
        regain their precious time, only interview highly qualified candidates, and reach talent
        they would have missed.
      </p>
      <h3>Improves Hiring Speed</h3>
      <p>
        Sales recruiting agencies, like <em>Rainmakers</em>, have access to specialized recruiting
        tools, broad hiring networks, and vast talent databases. These advantages allow sales
        recruitment agencies to hire quickly and efficiently. An in-house hiring team would be
        hard-pressed to match the lightning hiring speeds of specialized sales recruitment agencies.
      </p>
      <h3>Filters Qualified Talent</h3>
      <p>
        Sales recruitment agencies prioritize quality over quantity, though they still provide many
        qualified applicants. This qualified talent filter is one of the principal benefits hiring
        agencies offer. Unfortunately, sifting through stacks of candidates to separate the
        stand-outs from the throw-outs costs internal hiring teams precious time and money, often
        yielding mixed hiring results.
      </p>
      <h3>Expands Talent Pool</h3>
      <p>
        Sales recruitment agencies cast a significantly wider net, reaching candidates well beyond
        the range of in-house teams. Job boards and LinkedIn posts only represent a small fraction
        of entry points for job applicants, and sales recruitment agencies utilize these alternative
        avenues to full effect.
      </p>
      <h3>Offers Industry Insights</h3>
      <p>
        Tech sales recruitment agencies specialize in the entire gamut of sales positions within a
        single vertical. This narrow focus affords recruitment agencies exceptional insight into
        highly technical and complex roles and requirements.
      </p>
      <img src={pic4} alt={getAltName(pic4)} />
      <h2>How Does the Recruiting Process Work in New York City?</h2>
      <p>
        While the sales recruitment process across locations and industries is essentially the same,
        sales recruiting in places like New York City can add significant complexity to hiring the
        right sales talent.
      </p>
      <p>
        The reason? Well, like other major metropolitan hubs of industry, the sheer size of New York
        City’s talent pool makes candidates that much more challenging to hire. That’s because
        candidates living in New York have access to immense opportunities and choices.
      </p>
      <p>
        The time sales talent spend between jobs is often brief, placing pressure on hiring teams
        and sales recruitment agencies to find, vet, and hire in a New York minute.
      </p>
      <p>
        <strong>With New York City’s unique hiring challenges top of mind</strong>, the Rainmakers
        sales recruiting process provides hiring teams with streamlined sourcing and role matching
        second to none.
      </p>
      <p>Here’s a quick look under the hood of our proven sales hiring process:</p>
      <img src={pic3} alt={getAltName(pic3)} />
      <p>
        At Rainmakers, we don't just match you with any sales professional - we take the time to
        understand your business needs through a detailed initial consultation. Once we know what
        you're looking for, we optimize your job posting to appeal to top-performing sales
        professionals. Our recruitment team then scours our comprehensive network and talent
        database to source the best candidates. We provide in-depth sales profiles for each
        candidate so you know whom you're interviewing before the process begins.
      </p>
      <p>
        Our team thoroughly screens and interviews each candidate before passing along only the
        cream of the crop for your consideration. With a smaller pool of top-tier candidates, you
        can quickly identify and select the best fit for your company's needs, starting the
        onboarding process and setting your team up for success.
      </p>
      <h2>What Challenges Do Hiring Managers Face in New York City?</h2>
      <p>
        <strong>
          New York City hiring managers face the three most pressing challenges: applicant overload,
          rising sales rep attrition rates, and shifting employment standards and norms.
        </strong>
      </p>
      <p>
        New York City’s sales talent pool is enormous, leaving in-house hiring teams with mountains
        of applicants to review and vet. This profusion of applicants may seem like a blessing in
        disguise, but most applicants sourced from basic, traditional techniques are unqualified or
        ill-fitted. As a result, applicant overload can paralyze a hiring team.
      </p>
      <p>
        The rising attrition rate among New York City sales reps points to the inefficiencies and
        failures of traditional hiring processes. A candidate's success or failure in their role is
        rooted in their initial recruitment experience. If the recruitment process is slow,
        disjointed, and doesn’t effectively match talent to positions, there’s a significantly
        higher chance those hires will jump ship sooner than later.
      </p>
      <p>
        Employee expectations have undergone sweeping shifts in a short time. The younger
        generations bring new demands, standards, and norms to the workplace, and many organizations
        need help to keep up. A sales recruitment agency understands these shifting standards and
        knows how to communicate with younger candidates about their expectations.
      </p>
      <Link to="/employer-signup/" title="Apply Now">
        <img src={pic5} alt={getAltName(pic5)} />
      </Link>
      <h2>Your Sales Dream Team is Right Around the Block</h2>
      <p>
        Rainmakers puts employers on the fast track to hiring their dream sales team. So, if you’re
        looking to supercharge your hiring process for the best and brightest applications, set up
        an account and{' '}
        <a href="https://www.rainmakers.co/employer-signup/">try working with Rainmakers today!</a>
      </p>
    </div>
  );
}
