import React from 'react';

import InputBullets from 'components/InputBullets';

const Products = ({
  handleAddArrayInput,
  handleAddArrayValue,
  handleDeleteArrayInput,
  handleReorderArray,
  requisitionOpening: { attributes: { products, state } = {} } = {},
}) => {
  const productsInputProps = {
    disabled: state === 'closed',
    label: 'Products',
    secondaryLabel: 'What products do you sell?',
    array: products,
    name: 'products',
    handleAddArrayInput,
    handleAddArrayValue,
    handleDeleteArrayInput,
    handleReorderArray,
    pad: true,
    required: state !== 'closed',
  };

  return <InputBullets {...productsInputProps} />;
};

export default Products;
