import React, { Component } from 'react';
import classnames from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';

import { handleError } from 'utils/common';
import { getEntity } from 'api/apiEntity';

import Bolder from 'components/Bolder';
import Button from 'components/Button';
import DialogueModal from 'components/DialogueModal';
import FontIcon from 'components/FontIcon';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';

import styles from './SelectGreenhouseJob.scss';

export class SelectGreenhouseJob extends Component {
  constructor(props) {
    super(props);

    this.state = {
      greenhouseJobs: {
        allIds: [],
        byId: {},
      },
      selectedJobId: null,
      hasMore: true,
    };
  }

  loadMoreGreenhouseJobs = async (page) => {
    const { employerId } = this.props;
    const { greenhouseJobs } = this.state;

    const args = {
      type: 'greenhouse/list_jobs',
      config: {
        params: {
          sortBy: 'created_at_desc',
          normalizeIt: true,
          page,
          employerId,
        },
      },
    };

    let allIds;
    let byId;
    try {
      ({
        data: { entities: { greenhouseJob: { allIds = [], byId = {} } = {} } = {} } = {},
      } = await getEntity(args));
    } catch (error) {
      handleError(error);
    }

    const hasMore = allIds.length > 0;

    const newById = {
      ...greenhouseJobs.byId,
      ...byId,
    };

    const newAllIds = Array.prototype.concat(greenhouseJobs.allIds, allIds);

    this.setState({
      greenhouseJobs: {
        allIds: newAllIds,
        byId: newById,
      },
      hasMore,
    });
  };

  render() {
    const { handleAction, handleSkip, handleCancel, hasCancel, asModal } = this.props;
    const { greenhouseJobs: { byId = {}, allIds = [] } = {}, hasMore, selectedJobId } = this.state;

    const continueButtonProps = {
      onClick: () => handleAction(selectedJobId, byId[selectedJobId]),
      primary: true,
      disabled: selectedJobId === null,
    };

    const skipButtonProps = {
      onClick: handleSkip,
      quaternary: true,
      className: styles.skipButton,
    };

    const cancelButtonProps = {
      onClick: handleCancel,
      warningTertiary: true,
      className: styles.cancelButton,
    };

    const continueButton = <Button {...continueButtonProps}>Select Job &amp; Continue</Button>;

    const skipButton = <Button {...skipButtonProps}>Skip</Button>;

    const cancelButton = hasCancel ? <Button {...cancelButtonProps}>Cancel</Button> : null;

    const jobRows = allIds.map((id) => {
      const { [id]: { attributes: { name, requisitionOpeningId } = {} } = {} } = byId || {};

      const selected = selectedJobId === id;
      const disabled = requisitionOpeningId !== null;

      const rowProps = {
        onClick: disabled ? () => null : () => this.setState({ selectedJobId: id }),
        className: classnames(styles.row, styles.mainRow, {
          [styles.selected]: selected,
          [styles.disabled]: disabled,
        }),
      };

      const selectedContent = selected ? <FontIcon iconName="checkmark-circle" /> : null;

      return (
        <div key={id} {...rowProps}>
          <div className={styles.selectedCell}>{selectedContent}</div>
          <div className={styles.cell}>{name}</div>
        </div>
      );
    });

    const infiniteScrollProps = {
      pageStart: 0,
      loadMore: this.loadMoreGreenhouseJobs,
      hasMore,
      loader: <InfiniteScrollLoader />,
      useWindow: false,
    };

    const content = (
      <div className={styles.adminRequestOptionsContainer}>
        <div className={styles.header}>Create from Greenhouse Job</div>
        <div className={styles.subheader}>Select a job from the list below.</div>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.selectedCell} />
            <div className={styles.cell}>
              <Bolder>Job Name</Bolder>
            </div>
          </div>
          <div className={styles.tableMain}>
            <InfiniteScroll {...infiniteScrollProps}>{jobRows}</InfiniteScroll>
          </div>
        </div>
      </div>
    );

    return asModal ? (
      <DialogueModal>
        <div className={styles.modalContainer}>
          {content}
          <div className={styles.requestIntroButtons}>
            <div className={styles.buttonsLeft}>{cancelButton}</div>
            <div className={styles.buttonsRight}>
              {skipButton}
              {continueButton}
            </div>
          </div>
        </div>
      </DialogueModal>
    ) : (
      <div className={styles.nonModalContainer}>
        {content}
        <div className={styles.requestIntroButtons}>
          <div className={styles.buttonsLeft}>{cancelButton}</div>
          <div className={styles.buttonsRight}>
            {skipButton}
            {continueButton}
          </div>
        </div>
      </div>
    );
  }
}

export default SelectGreenhouseJob;
