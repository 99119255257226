import { call, select, put, take, cancel, fork } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import camelCase from 'lodash.camelcase';
import { push } from 'connected-react-router';

import { handleError } from 'utils/common';
import { trackEvent } from 'utils/analytics';
import { segmentCandidateAddedSalesInfo } from 'segment/eventNames';

import { patchNestedEntity, patchEntity } from 'api/apiEntity';

import { candidateProfilePatchDone } from 'connectors/User/redux/creators';

import { selectUser } from 'connectors/User/redux/selectors';

import { ADD_SALES_INFO_STARTED } from '../actions';

import { serverErrorSet } from '../creators';

const VALID_STATUSES = ['applied', 'applied_forum', 'set_requirements', 'created_profile'];

const TYPES = {
  AESalesPeriod: 'ae_sales_period',
  CSMSalesPeriod: 'csm_sales_period',
};

export const addSalesInfoStarted = function* addSalesInfoStarted(action) {
  const {
    payload: { type, salesData, autosave },
  } = action;

  const user = yield select(selectUser);

  const {
    currentProfile: { id: candidateId, workHistories: currentWorkHistories, onboardingStatus },
  } = user;

  if (autosave) {
    yield call(delay, 750);

    try {
      const { workHistory } = salesData;

      const { salesPeriods, id: workHistoryId } = workHistory;

      const sp = salesPeriods[0];

      const { type: spType } = sp;

      const { [spType]: configKey } = TYPES;
      const camelKey = camelCase(spType);

      const putSpArgs = {
        type: `${configKey}s`,
        data: {
          type: configKey,
          id: sp.id,
          attributes: {
            ...sp,
            leadsGeneratedPercentage: sp.leadsGeneratedPercentage || 0,
          },
        },
        id: sp.id,
        config: {
          params: {
            camelizeIt: true,
          },
        },
      };

      const putWhArgs = {
        type: 'candidates',
        nestedType: 'work_histories',
        data: {
          type: 'work_history',
          id: workHistoryId,
          attributes: {
            ...workHistory,
          },
        },
        id: candidateId,
        nestedId: workHistoryId,
        config: {
          params: {
            camelizeIt: true,
            includeSet: 'company_sales_periods',
          },
        },
      };

      if (type === 'salesPeriod') {
        const {
          data: { [camelKey]: newSalesPeriod },
        } = yield call(patchEntity, putSpArgs);

        const newSalesPeriods = salesPeriods.map((period) => {
          if (period.id === newSalesPeriod.id) {
            return newSalesPeriod;
          }
          return period;
        });

        const newWorkHistories = currentWorkHistories.map((wh) => {
          if (wh.id === workHistoryId) {
            return {
              ...wh,
              salesPeriods: newSalesPeriods,
            };
          }
          return wh;
        });

        const latestUser = yield select(selectUser);

        const candidateProfile = {
          ...latestUser.currentProfile,
          workHistories: newWorkHistories,
        };

        yield put(candidateProfilePatchDone({ candidateProfile }));
      }

      if (type === 'workHistory') {
        const {
          data: { workHistory: newWorkHistory },
        } = yield call(patchNestedEntity, putWhArgs);

        const newWorkHistories = currentWorkHistories.map((wh) => {
          if (wh.id === workHistoryId) {
            return newWorkHistory;
          }
          return wh;
        });

        const latestUser = yield select(selectUser);

        const candidateProfile = {
          ...latestUser.currentProfile,
          workHistories: newWorkHistories,
        };

        yield put(candidateProfilePatchDone({ candidateProfile }));
      }
    } catch (error) {
      handleError(error);

      const {
        response: {
          data: { errors: { full_messages: errors = ['Please try again'] } = {} } = {},
        } = {},
      } = error || {};

      yield put(serverErrorSet({ errors }));
    }
  } else {
    try {
      const newOnboardingStatus = VALID_STATUSES.includes(onboardingStatus)
        ? 'added_sales_info'
        : onboardingStatus;

      if (newOnboardingStatus !== onboardingStatus) {
        yield call(trackEvent, { event: segmentCandidateAddedSalesInfo });
      }

      const args = {
        id: candidateId,
        type: 'candidates',
        data: {
          type: 'candidates',
          id: candidateId,
          attributes: {
            onboardingStatus: newOnboardingStatus,
          },
        },
        config: {
          params: {
            camelizeIt: true,
          },
        },
      };

      const {
        data: { candidate },
      } = yield call(patchEntity, args);

      const candidateProfile = {
        ...user.currentProfile,
        ...candidate,
      };

      yield put(candidateProfilePatchDone({ candidateProfile }));

      yield put(push('/add-video'));
    } catch (error) {
      handleError(error);

      const {
        response: {
          data: { errors: { full_messages: errors = ['Please try again'] } = {} } = {},
        } = {},
      } = error || {};

      yield put(serverErrorSet({ errors }));
    }
  }
};

const sagaAddSalesInfoStarted = function* sagaAddSalesInfoStarted() {
  let lastTask;
  let lastAction;
  while (true) {
    const action = yield take(ADD_SALES_INFO_STARTED);
    if (lastTask && lastAction.payload.type === action.payload.type) {
      yield cancel(lastTask);
    }
    lastAction = action;
    lastTask = yield fork(addSalesInfoStarted, action);
  }
};

export default sagaAddSalesInfoStarted;
