import React, { Component, Fragment } from 'react';

import { handleError } from 'utils/common';
import { createRequisitionOpening } from 'reduxStore/schema';

import { postEntity } from 'api/apiEntity';

import SelectGreenhouseJob from 'connectors/DialogModals/SelectGreenhouseJob';

import Button from 'components/Button';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import Table from 'components/Table';
import TableRow from 'components/Table/components/TableRow';

import EmployerRequisitionsRow from '../EmployerRequisitionsRow';
import EmployerRequisitionsSearchBar from '../EmployerRequisitionsSearchBar';

import styles from './EmployerRequisitionsTable.scss';

class EmployerRequisitionsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSelectGreenhouseJob: false,
    };
  }

  createNewReq = () => {
    const {
      actions: { resourceUpdate, showModal },
      user: { currentProfile: { employer = {} } = {} } = {},
    } = this.props;

    const resource = createRequisitionOpening();

    resourceUpdate({
      slice: 'employerRequisitions',
      type: 'requisition_openings',
      id: resource.id,
      resource: {
        ...resource,
        attributes: {
          ...resource.attributes,
          employerId: employer.id,
        },
        relationships: {
          employer: {
            data: [employer.id],
          },
        },
      },
    });

    showModal({
      key: 'ReqDetailModal',
      parent: 'ManageReqsPage',
      route: `/requisitions/${resource.id}/edit`,
    });
  };

  createReqFromJob = async (selectedJobId) => {
    const {
      actions: { resourceUpdate, showModal },
    } = this.props;

    this.setState({
      serverError: null,
      showSelectGreenhouseJob: false,
    });

    const args = {
      type: 'requisition_openings',
      data: {
        type: 'requisition_opening',
        attributes: {
          greenhouseJobId: selectedJobId,
        },
      },
      config: {
        params: {
          normalizeIt: true,
        },
      },
    };

    try {
      const {
        data: { entities: { requisitionOpening: { byId = {}, allIds = [] } = {} } = {} } = {},
      } = await postEntity(args);

      resourceUpdate({
        slice: 'employerRequisitions',
        type: 'requisition_openings',
        resource: byId[allIds[0]],
        id: allIds[0],
      });

      showModal({
        parent: 'ManageReqsPage',
        route: `/requisitions/${allIds[0]}/edit`,
        key: 'ReqDetailModal',
      });
    } catch (error) {
      handleError(error);

      this.setState({ error });
    }
  };

  render() {
    const { showSelectGreenhouseJob } = this.state;

    const {
      actions: { duplicateRequisition, patchResource, resourceUpdate, showModal },
      dispatch,
      externalName,
      isLoading,
      handleSearchFilterChange,
      handleSearchInputChange,
      loadMore,
      modal,
      requisitionOpenings: { allIds = [], byId = {} } = {},
      role,
      roleOptions,
      sortBy,
      state,
      total,
      user: { currentProfile: { employer: { greenhouseReady } = {} } = {} } = {},
    } = this.props;

    const tableHeader = () => {
      switch (state) {
        case 'closed':
          return [
            {
              content: 'Req Name',
              headerClassName: styles.firstLabel,
            },
            {
              content: 'Status',
              headerClassName: styles.reqStatusHeader,
            },
            {
              content: 'Location',
              headerClassName: styles.reqLocationHeader,
            },
            {
              content: 'OTE',
              headerClassName: styles.reqOteHeader,
            },
            {
              content: 'Candidates',
              headerClassName: styles.reqCandidatesHeader,
            },
            {
              content: 'Closed On',
              headerClassName: styles.reqPublishedHeader,
            },
            {
              content: 'Creator',
              headerClassName: styles.reqPublishedHeader,
            },
            {
              content: '',
              headerClassName: styles.actionHeader,
            },
          ];
        default:
          return [
            {
              content: 'Req Name',
              headerClassName: styles.firstLabel,
            },
            {
              content: 'Status',
              headerClassName: styles.reqStatusHeader,
            },
            {
              content: 'Location',
              headerClassName: styles.reqLocationHeader,
            },
            {
              content: 'OTE',
              headerClassName: styles.reqOteHeader,
            },
            {
              content: 'Candidates',
              headerClassName: styles.reqCandidatesHeader,
            },
            {
              content: 'Published',
              headerClassName: styles.reqPublishedHeader,
            },
            {
              content: 'Creator',
              headerClassName: styles.reqPublishedHeader,
            },
            {
              content: '',
              headerClassName: styles.actionHeader,
            },
          ];
      }
    };

    const makeReqCells = (id) => {
      const rowProps = {
        dispatch,
        duplicateRequisition,
        modal,
        patchResource,
        requisitionOpening: byId[id],
        resourceUpdate,
        showModal,
      };

      return <EmployerRequisitionsRow {...rowProps} />;
    };

    const makeReqRows = (id) => (
      <TableRow id={id} rowType="reqRow" key={id}>
        {makeReqCells(id)}
      </TableRow>
    );

    const tableContent = allIds.filter((id) => !byId[id].attributes.temporary).map(makeReqRows);

    const searchBarProps = {
      externalName,
      handleSearchFilterChange,
      handleSearchInputChange,
      role,
      roleOptions,
      state,
    };

    const createReqClick = greenhouseReady
      ? () => this.setState({ showSelectGreenhouseJob: true })
      : this.createNewReq;

    const createReqProps = {
      className: styles.newReqButton,
      primary: true,
      onClick: createReqClick,
    };

    const greenhouseJobProps = {
      asModal: true,
      slice: 'employerRequisitions',
      handleAction: this.createReqFromJob,
      handleSkip: () => {
        this.setState({ showSelectGreenhouseJob: false });
        this.createNewReq();
      },
      handleCancel: () => this.setState({ showSelectGreenhouseJob: false }),
      hasCancel: true,
    };

    const selectGreenhouseJobsModal = showSelectGreenhouseJob ? (
      <SelectGreenhouseJob {...greenhouseJobProps} />
    ) : null;

    const titleContent = (
      <div className={styles.candidateHeader}>
        <div className={styles.candidateHeaderLeft}>
          <EmployerRequisitionsSearchBar {...searchBarProps} />
        </div>
        <Button {...createReqProps}>+ Requisition</Button>
      </div>
    );

    const infiniteScrollProps = {
      pageStart: 0,
      loadMore,
      hasMore: total > allIds.length,
      loader: isLoading ? (
        <div key="infiniteScrollLoader" />
      ) : (
        <InfiniteScrollLoader key="infiniteScrollLoader" />
      ),
      useWindow: false,
    };

    const tableProps = {
      titleContent,
      tableContent,
      header: tableHeader(),
      tableName: 'reqManager',
      reqTable: true,
      infiniteScrollProps,
      isEmpty: !isLoading && allIds.length === 0,
      emptyTextString: 'No Requisition Openings',
      isLoading,
      sortBy,
    };

    return (
      <Fragment>
        <Table {...tableProps} />
        {selectGreenhouseJobsModal}
      </Fragment>
    );
  }
}

export default EmployerRequisitionsTable;
