import { createSelector } from 'reselect';
import { getApp } from 'shared/store/app/selectors';

const selectDefaults = createSelector(getApp, (state) => state.defaults);

const selectPickerOptions = createSelector(
  selectDefaults,
  (defaults = {}) => defaults.pickerOptions
);

export default selectPickerOptions;
