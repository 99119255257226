import React, { useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';

import Label from 'components/Label';
import { MarginContainer } from 'components/MarginContainer';
import GooglePlacesAutocomplete from 'components/GooglePlacesAutocomplete';
import InputSelect from 'components/InputSelect';

import searchOptionsConfig from '../../searchOptionsConfig';

import { DropdownView } from './components/DropdownView';
import { MoneyRangeView } from './components/MoneyRangeView';
import { KeywordsView } from './components/KeywordsView';

import styles from './SideFiltersPane.scss';

const distanceOptions = [25, 50, 100, 200, 500]; // miles

const DEFAULT_ROLEID = 'rolesdr';

const unformatMoney = (amount) =>
  amount ? parseInt(amount.toString().replace(/[^\d.-]/g, ''), 10) : amount;

export const SideFiltersPane = ({
  roleId = DEFAULT_ROLEID,
  data,
  data: { place: savedLocation, distance },
  handleSideFilterChange,
  handleSideFilterLatLonChange,
}) => {
  const [location, setLocation] = useState(savedLocation);

  const sideFilters = useMemo(() => {
    const { sideFilters: sf } = searchOptionsConfig.find(({ id }) => id === roleId) || {};
    if (sf) {
      return sf;
    }

    const { sideFilters: sfDefault = [] } =
      searchOptionsConfig.find(({ id }) => id === DEFAULT_ROLEID) || {};

    return sfDefault;
  }, [roleId]);

  useEffect(() => {
    setLocation(savedLocation);
  }, [savedLocation]);

  const distanceSource = useMemo(
    () =>
      distanceOptions.map((option) => ({
        label: `${option} miles`,
        value: option,
      })),
    []
  );

  const handleInputChange = debounce(({ target: { name, value } }) => {
    handleSideFilterChange(name, value);
  }, 500);

  const handleMoneyFilterChange = debounce(({ target: { name, value } }) => {
    handleSideFilterChange(name, unformatMoney(value));
  }, 500);

  return (
    <section className={styles.extraFiltersContainer}>
      {sideFilters.map(
        ({
          id,
          type,
          label,
          name,
          fromName,
          toName,
          dataKey,
          fromDataKey,
          toDataKey,
          placeholder,
          filterName,
          getOptions,
        }) => {
          return (
            <div key={id} className={styles.inputGroup}>
              {type === 'location' && (
                <>
                  <MarginContainer bottom="10">
                    <Label>{label}</Label>
                  </MarginContainer>
                  <GooglePlacesAutocomplete
                    value={location || ''}
                    name="location"
                    showCloseButton
                    searchOptions={{ types: ['(regions)'] }}
                    placeholder=""
                    handleSelect={(place, lat, lon) => {
                      setLocation(place);
                      handleSideFilterChange('place', place);
                      handleSideFilterLatLonChange(lat, lon);
                    }}
                  />
                </>
              )}
              {type === 'distance' && (
                <>
                  <MarginContainer bottom="10">
                    <Label>{label}</Label>
                  </MarginContainer>
                  <InputSelect
                    className={styles.radiusSelectContainer}
                    internalClassName={styles.radiusSelect}
                    optionsContainerClassName={styles.optionsContainer}
                    defaultLabelClassName={styles.distancePlaceholder}
                    type="text"
                    size="xlarge"
                    name="distance"
                    value={distance}
                    source={distanceSource}
                    defaultLabel="100 miles"
                    defaultIcon="caret-down"
                    activeIcon="caret-up"
                    noMargin
                    handleInputChange={handleInputChange}
                  />
                </>
              )}
              {type === 'money-range' && (
                <MoneyRangeView
                  label={label}
                  fromName={fromName}
                  toName={toName}
                  handleMoneyFilterChange={handleMoneyFilterChange}
                  fromValue={data[fromDataKey]}
                  toValue={data[toDataKey]}
                />
              )}
              {type === 'dropdown' && (
                <DropdownView
                  label={label}
                  name={name}
                  selectedValues={data[dataKey] || []}
                  getOptions={getOptions}
                  handleChange={handleSideFilterChange}
                />
              )}
              {type === 'keywords' && (
                <KeywordsView
                  name={name}
                  label={label}
                  keywords={data[dataKey] || []}
                  filterName={filterName}
                  placeholder={placeholder}
                  handleInputChange={handleInputChange}
                />
              )}
            </div>
          );
        }
      )}
    </section>
  );
};
