import React, { Component } from 'react';
import { push } from 'connected-react-router';

import Block from 'components/Block';
import Button from 'components/Button';
import ContentTitle from 'components/ContentTitle';
import EmployeeImageUploader from 'components/EmployeeImageUploader';
import Input from 'components/Input';
import InputCheckboxSlider from 'components/InputCheckboxSlider';
import Label from 'components/Label';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';

import styles from './EmployerEditorEmployee.scss';

class EmployerEditorEmployee extends Component {
  goBack = () => {
    const {
      actions: { showModal },
      dispatch,
      employer: { id: employerId },
      modal,
    } = this.props;

    const route = `/toolbox/employer/${employerId}/edit-employees`;

    if (!modal.originalUrl) {
      dispatch(push(route));
    } else {
      showModal({
        parent: 'ToolboxEmployersPage',
        route,
        key: 'EmployerEditorModal',
        originalUrl: modal.originalUrl,
      });
    }
  };

  handlePatchResource = (attributes, type) => {
    const {
      actions: { patchUserResource },
      match: {
        params: { employeeId: id },
      },
      employee: { relationships: { user: { data: userIds = [] } = {} } = {} } = {},
    } = this.props;

    patchUserResource({
      attributes,
      id: type === 'users' ? userIds[0] : id,
      slice: 'toolboxEmployers',
      type,
    });
  };

  handleEmployeeInputChange = (event) => {
    const {
      target: { name, value, type, checked },
    } = event;

    const newValue = type === 'checkbox' ? checked : value;

    this.handlePatchResource({ [name]: newValue }, 'employees');
  };

  handleUserTextInputChange = (event) => {
    const {
      target: { name, value },
    } = event;

    this.handlePatchResource({ [name]: value }, 'users');
  };

  render() {
    const {
      actions: { patchUserResource },
      employee: {
        attributes = {},
        attributes: {
          jobTitle,
          phoneNumber,
          firstName,
          lastName,
          picture: { xxlarge: pictureUrl = '' } = {},
          cohortUpdates,
        } = {},
        id: employeeId,
        relationships: { user: { data: userIds = [] } = {} } = {},
      } = {},
      employeeErrors = {},
      userErrors = {},
      users: { byId: usersById = {} } = {},
    } = this.props;

    const { firstName: firstNameErrors, lastName: lastNameErrors } = employeeErrors;

    const { email: emailErrors } = userErrors;

    const { attributes: { email, accountLocked = false } = {} } = usersById[userIds[0]] || {};

    const commonBlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      largeTitleFont: true,
      addPadding: true,
    };

    const genInfoBlockProps = {
      ...commonBlockProps,
      childContainerClass: styles.blockFlex,
      title: 'General',
    };

    const commonUserInputProps = {
      type: 'text',
      size: 'xxlarge',
      disabled: false,
      required: true,
      handleInputChange: this.handleUserTextInputChange,
    };

    const commonEmployeeInputProps = {
      handleInputChange: this.handleEmployeeInputChange,
      type: 'text',
      size: 'xxlarge',
    };

    const firstNameInputProps = {
      ...commonEmployeeInputProps,
      required: true,
      name: 'firstName',
      value: firstName || '',
      label: 'First Name',
      size: 'full',
      placeholder: '',
      errors: firstName !== null ? firstNameErrors : {},
    };

    const lastNameInputProps = {
      ...commonEmployeeInputProps,
      required: true,
      name: 'lastName',
      value: lastName || '',
      label: 'Last Name',
      size: 'full',
      placeholder: '',
      errors: lastName !== null ? lastNameErrors : {},
    };

    const emailInputProps = {
      ...commonUserInputProps,
      name: 'email',
      value: email || '',
      label: 'Email',
      size: 'xlarge',
      placeholder: '',
      autoFocus: true,
      className: styles.firstInput,
      errors: email !== null ? emailErrors : {},
    };

    const phoneInputProps = {
      ...commonEmployeeInputProps,
      name: 'phoneNumber',
      value: phoneNumber || '',
      label: 'Phone #',
      placeholder: 'Area code first',
      type: 'tel',
      size: 'phone',
    };

    const jobTitleInputProps = {
      ...commonEmployeeInputProps,
      name: 'jobTitle',
      value: jobTitle || '',
      label: 'Job Title',
      placeholder: 'Recruiter, e.g.',
      size: 'xxlarge',
    };

    const imageUploaderProps = {
      employeeId,
      pictureUrl,
      employeeImageUploadStarted: ({ picture }) =>
        patchUserResource({
          attributes: {
            picture: {
              file_data: picture,
              large: picture,
              medium: picture,
              original: picture,
              small: picture,
              thumb: picture,
              xlarge: picture,
              xxlarge: picture,
            },
          },
          id: employeeId,
          slice: 'toolboxEmployers',
          type: 'employees',
        }),
    };

    const backButtonProps = {
      quaternary: true,
      onClick: this.goBack,
      className: styles.backButton,
    };

    const backButton = <Button {...backButtonProps}>&#60; All Employees</Button>;

    const permissionBooleans = [
      {
        key: 'editAdmins',
        label: 'Administrator',
      },
      {
        key: 'editGreenhouse',
        label: 'Greenhouse',
      },
    ];

    const makeInputs = ({ key, label }) => {
      const props = {
        inputLabel: label,
        key,
        name: key,
        checked: attributes[key],
        label: attributes[key] ? 'On' : 'Off',
        handleInputChange: this.handleEmployeeInputChange,
        disabled: key === 'editAdmins' && accountLocked,
      };

      return (
        <div className={styles.input}>
          <Label>{label}</Label>
          <InputCheckboxSlider {...props} />
        </div>
      );
    };

    const permissionInputs = permissionBooleans.map(makeInputs);

    const accountLockedInputProps = {
      handleInputChange: (event) =>
        this.handleUserTextInputChange({
          ...event,
          target: {
            ...event.target,
            value: event.target.checked,
            name: event.target.name,
          },
        }),
      name: 'accountLocked',
      checked: accountLocked,
      label: accountLocked ? 'Account Locked' : 'Account Unlocked',
      placeholder: '',
      disabled: attributes.editAdmins,
    };

    const emailsInputProps = {
      inputLabel: 'Email Updates',
      name: 'cohortUpdates',
      checked: cohortUpdates,
      label: cohortUpdates ? 'On' : 'Off',
      handleInputChange: this.handleEmployeeInputChange,
    };

    const content = (
      <div className={styles.EditorPersonal}>
        <div className={styles.backButtonContainer}>{backButton}</div>
        <div className={styles.scrollDiv}>
          <Block {...genInfoBlockProps}>
            <EmployeeImageUploader {...imageUploaderProps} />
            <div className={styles.inputsContainer}>
              <Input {...emailInputProps} />
              <div className={styles.nameInputs}>
                <Input {...firstNameInputProps} />
                <Input {...lastNameInputProps} />
                <Input {...phoneInputProps} />
              </div>
              <Input {...jobTitleInputProps} />
            </div>
          </Block>

          <Block {...commonBlockProps}>
            <ContentTitle>Account</ContentTitle>
            <div className={styles.inputContainer}>
              <InputCheckboxSlider {...accountLockedInputProps} />
            </div>
          </Block>

          <Block {...commonBlockProps}>
            <ContentTitle>Emails</ContentTitle>
            <div className={styles.inputContainer}>
              <Label>Cohort Updates</Label>
              <InputCheckboxSlider {...emailsInputProps} />
            </div>
          </Block>

          <Block {...commonBlockProps}>
            <ContentTitle>Permissions</ContentTitle>
            <div className={styles.inputContainer}>{permissionInputs}</div>
          </Block>
        </div>
      </div>
    );

    const layoutWithoutSidebarProps = {
      content,
    };

    return <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />;
  }
}

export default EmployerEditorEmployee;
