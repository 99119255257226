import { createMemoryHistory, createBrowserHistory } from 'history';

export const createUniversalHistory = ({ initialEntries = [] } = {}) => {
  if (__BROWSER__) {
    const history = window.browserHistory || createBrowserHistory({ initialEntries });
    // Needed browserHistory on window in order to get location (location.state) in sagas
    // if (process.env.NODE_ENV === 'development' && !window.browserHistory) {
    if (!window.browserHistory) {
      window.browserHistory = history;
    }
    return history;
  }
  return createMemoryHistory({ initialEntries });
};

export default createUniversalHistory;
