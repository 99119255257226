import { handleActions } from 'redux-actions';

import { PATCH_REQUISITION_CANDIDATE_DONE as PASS_PATCH_REQUISITION_CANDIDATE_DONE } from 'pages/CandidatePortal/CandidateOpportunitiesPage/redux/actions';

const initialState = {
  entities: {
    requisitionCandidate: {
      byId: {},
      allIds: [],
    },
  },
  meta: {
    requisitionCandidate: {
      pagination: {},
    },
  },
};

const handlePatchRequisitionCandidateDone = (state = initialState, action) => {
  const {
    payload: { requisitionCandidate },
  } = action;

  return state.entities.requisitionCandidate.byId[requisitionCandidate.id]
    ? {
        ...state,
        entities: {
          ...state.entities,
          requisitionCandidate: {
            ...state.entities.requisitionCandidate,
            byId: {
              ...state.entities.requisitionCandidate.byId,
              [requisitionCandidate.id]: {
                ...state.entities.requisitionCandidate.byId[requisitionCandidate.id],
                attributes: {
                  ...state.entities.requisitionCandidate.byId[requisitionCandidate.id].attributes,
                  ...requisitionCandidate,
                },
              },
            },
          },
        },
      }
    : {
        ...state,
      };
};

const actionHandlers = {
  [PASS_PATCH_REQUISITION_CANDIDATE_DONE]: handlePatchRequisitionCandidateDone,
};

const reduceToolboxApproaches = handleActions(actionHandlers, initialState);

export default reduceToolboxApproaches;
