import { call, select, put, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { postEmployers } from 'api/apiUsers';

import { handleError } from 'utils/common';
import { makeAnalyticsPayload, trackEvent } from 'utils/analytics';
import { segmentUserSignup } from 'segment/eventNames';

import { SIGNUP_STARTED } from 'pages/EmployeePortal/SignupPage/redux/actions';

import { signupError } from 'pages/EmployeePortal/SignupPage/redux/creators';

import { selectUser } from 'connectors/User/redux/selectors';

import {
  addRequestInterceptor,
  employeeLoginDone,
  identifySegmentUser,
  waitForJob,
} from 'shared/store/app/creators';

const sagaSignupStarted = function* sagaSignupStarted() {
  while (true) {
    const { payload: formData } = yield take(SIGNUP_STARTED);

    const { utmInfo = {}, referer } = yield select(selectUser);

    try {
      const analytics = makeAnalyticsPayload({ utmInfo, referer });

      const args = {
        data: {
          ...formData,
          user: {
            ...formData.user,
            email: formData.user.email.trim(),
          },
          analytics,
        },
        config: {
          params: {
            normalizeIt: true,
          },
        },
      };

      const {
        headers,
        data: {
          entities: {
            user: { byId: userById = {}, allIds: userAllIds = [] } = {},
            employee: { byId: employeeById = {}, allIds: employeeAllIds = [] } = {},
          } = {},
          meta: { queuedPublicJobs: jobs = [] },
        },
      } = yield call(postEmployers, args);

      const user = userById[userAllIds[0]] || {};
      const employee = employeeById[employeeAllIds[0]] || {};

      yield call(trackEvent, { event: segmentUserSignup, properties: { profileType: 'Employee' } });

      yield put(addRequestInterceptor({ headers }));

      const job = jobs.find((job) => job.className === 'Analytics::UserSignedUpWorker');

      if (job) {
        yield put(
          waitForJob({
            jobId: job.providerJobId,
            onCompleteAction: identifySegmentUser,
            onCompletePayload: { user },
          })
        );
      }

      yield put(
        employeeLoginDone({
          user: {
            ...user.attributes,
            currentProfile: employee.attributes,
          },
          headers,
          noAddInterceptor: true,
        })
      );
    } catch (error) {
      handleError(error);

      const { response: { data: { errors = [] } = {} } = {} } = error || {};

      if (errors.length > 0) {
        if (errors[0].status === 409) {
          yield put(push('/thanks'));
        } else {
          yield put(
            signupError({
              errors,
            })
          );
        }
      }
    }
  }
};

export default sagaSignupStarted;
