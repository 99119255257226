import React from 'react';
import classnames from 'classnames';

import EmptyCompanyLogo from 'shared/assets/missing-company-picture.png';

import { useImageField } from 'hooks';

import styles from './CompanyData.scss';

const CompanyData = ({ className, onClick, employer = {}, largeAvatar, sideTable }) => {
  const { attributes: { name: companyName } = {} } = employer;

  const classes = classnames(
    styles.EmployerData,
    {
      [styles.sideTable]: sideTable,
    },
    className
  );

  const infoWrapperClasses = classnames(styles.employerDataInfoWrapper, {
    [styles.employerDataInfoWrapperShort]: sideTable,
  });

  const titleClasses = classnames(styles.employerDataTitle, {
    [styles.employerDataTitleSmall]: sideTable,
  });

  const avatarClass = classnames(styles.employerDataAvatarWrapper, {
    [styles.employerDataAvatarLargeWrapper]: largeAvatar,
    [styles.employerDataAvatarSmallWrapper]: sideTable,
  });

  const { imageComponent } = useImageField({
    model: employer,
    avatarClass,
    emptyLogo: EmptyCompanyLogo,
  });

  const employerProps = {
    className: classes,
    onClick,
  };

  return (
    <div {...employerProps}>
      {imageComponent}

      <div className={infoWrapperClasses}>
        <div className={titleClasses}>{companyName}</div>
      </div>
    </div>
  );
};

export default CompanyData;
