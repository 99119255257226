import React from 'react';
import { v4 } from 'uuid';

import { ComponentErrorBoundaryHOC } from 'containers/HOC';

import Block from 'components/Block';
import Bolder from 'components/Bolder';
import Content from 'components/Content';

import styles from './ProfileQAndA.scss';

export const ProfileQAndA = ({ qAndA }) => {
  const { singleAnswer = [] } = qAndA || {};

  const filteredSingleAnswers = singleAnswer.filter(
    (qaItem) => qaItem.answer !== null && qaItem.answer !== undefined && qaItem.answer !== ''
  );

  const qAndAContent =
    filteredSingleAnswers.length > 0
      ? filteredSingleAnswers.map((qaItem) => (
          <Content key={v4()} className={styles.qaItem}>
            <Bolder className={styles.question}>{qaItem.question}</Bolder>
            <Content className={styles.answer}>{qaItem.answer}</Content>
          </Content>
        ))
      : null;

  const qAndAFinalContent = qAndAContent ? (
    <Content className={styles.profileRecruitmentContainer}>{qAndAContent}</Content>
  ) : null;

  const title = (
    <Content>
      <Bolder>Q &amp; A</Bolder>
    </Content>
  );

  const commonBlockProps = {
    title,
    addWhiteBG: true,
    secondaryTitle: true,
    className: styles.marginBottom,
  };

  return !qAndAFinalContent ? null : <Block {...commonBlockProps}>{qAndAFinalContent}</Block>;
};

export default ComponentErrorBoundaryHOC()(ProfileQAndA);
