import React, { useState } from 'react';

import Block from 'components/Block';
import ConfirmModal from 'components/ConfirmModal';
import ContentTitle from 'components/ContentTitle';
import InputCheckboxSlider from 'components/InputCheckboxSlider';
import Label from 'components/Label';

import styles from '../../EditEmployee.scss';

const Settings = ({
  employee: {
    attributes = {},
    id: employeeId,
    relationships: { user: { data: userIds = [] } = {} } = {},
  } = {},
  handleEmployeeInputChange,
  handleUserTextInputChange,
  user: { currentProfile: { editAdmins = false } = {}, currentProfileId } = {},
  users: { byId: usersById = {} } = {},
}) => {
  const [prompt, setPrompt] = useState(false);

  const { attributes: { accountLocked = false } = {} } = usersById[userIds[0]] || {};

  const commonBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    largeTitleFont: true,
    addPadding: true,
  };

  const permissionBooleans = [
    {
      key: 'editAdmins',
      label: 'Administrator',
    },
    {
      key: 'editGreenhouse',
      label: 'Greenhouse',
    },
  ];

  const isEditingSelf = currentProfileId === employeeId;

  const makeInputs = ({ key, label }) => {
    const handleInputChange =
      key === 'editAdmins' && isEditingSelf && attributes[key]
        ? () => setPrompt(true)
        : handleEmployeeInputChange;

    const props = {
      inputLabel: label,
      key,
      name: key,
      checked: attributes[key],
      label: attributes[key] ? 'On' : 'Off',
      handleInputChange,
      disabled: !editAdmins || (key === 'editAdmins' && accountLocked),
    };

    const confirmModalProps = {
      handleCancel: () => setPrompt(false),
      handleConfirm: () => {
        setPrompt(false);
        handleEmployeeInputChange({
          event: {
            target: {
              checked: !attributes[key],
              name: 'editAdmins',
              type: 'checkbox',
            },
          },
        });
      },
      title: 'Careful',
      text:
        'If you disable the Administrator setting for yourself, you will not be able to re-enable it from this account. Hit confirm to proceed with the selection or cancel to go back.',
    };

    const confirmModalContent = prompt ? <ConfirmModal {...confirmModalProps} /> : null;

    return (
      <div className={styles.input}>
        <Label>{label}</Label>
        <InputCheckboxSlider {...props} />
        {confirmModalContent}
      </div>
    );
  };

  const permissionInputs = permissionBooleans.map(makeInputs);

  const accountLockedInputProps = {
    handleInputChange: (event) =>
      handleUserTextInputChange({
        ...event,
        target: {
          ...event.target,
          value: event.target.checked,
          name: event.target.name,
        },
      }),
    name: 'accountLocked',
    checked: accountLocked,
    label: accountLocked ? 'Account Locked' : 'Account Unlocked',
    placeholder: '',
    disabled: !editAdmins || attributes.editAdmins,
  };

  return (
    <div className={styles.scrollDiv}>
      <Block {...commonBlockProps}>
        <ContentTitle>Account</ContentTitle>
        <div className={styles.inputContainer}>
          <InputCheckboxSlider {...accountLockedInputProps} />
        </div>
      </Block>

      <Block {...commonBlockProps}>
        <ContentTitle>Permissions</ContentTitle>
        <div className={styles.inputContainer}>{permissionInputs}</div>
      </Block>
    </div>
  );
};

export default Settings;
