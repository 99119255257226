// Common User Events

export const segmentSessionEndUser = 'session_end-user';

export const segmentComponentClicked = 'component_clicked';

export const segmentOpeningViewed = 'opening_viewed';

// Employee Events (keep alphabetized, group the same type)

export const segmentAddBookmark = 'add_bookmark-employee';
export const segmentRemoveBookmark = 'remove_bookmark-employee';

export const segmentAddSavedSearchEmployee = 'add_saved_search-employee';
export const segmentRemoveSavedSearchEmployee = 'remove_saved_search-employee';

export const segmentCandidateProfileViewEmployee = 'candidate_profile_view-employee';

// moved to server
// export const segmentCompleteRegistrationEmployee = 'complete_registration-employee';

export const segmentFirstApprovedLoginEmployee = 'first_approved_login-employee';

export const segmentHideCandidateEmployee = 'candidate_hide-employee';
export const segmentUnhideCandidateEmployee = 'candidate_unhide-employee';

export const segmentHireCandidateEmployee = 'hire-employee';
export const segmentPassCandidateEmployee = 'pass-employee';

export const segmentRequisitionAction = 'requisition_action-employee';
export const segmentRequisitionCreated = 'req_created-employee';

export const segmentRequirementsPageViewEmployee = 'requirements_page_view-employee';

export const segmentSearchEmployee = 'search-employee';

export const segmentUserLoginEmployee = 'login-employee';
export const segmentUserLogoutEmployee = 'logout-employee';

// Candidate Events

export const segmentProfileVideoAdded = 'profile_video_added';
export const segmentProfileVideoWatched = 'profile_video_watched';

// - Onboarding Events
// moved to server
// export const segmentLeadCandidate = 'lead-candidate';
export const segmentUserSignup = 'user_signup';
export const segmentCandidateSetRequirements = 'candidate_set_requirements';
export const segmentCandidateCreatedProfile = 'candidate_created_profile';
export const segmentCandidateAddedSalesInfo = 'candidate_added_sales_info';
export const segmentRegistrationComplete = 'candidate_registration_completed';

// -- Set Requirements Events
export const segmentSetHiringStatusCandidate = 'set_onboarding_hiring_status-candidate';
export const segmentSetDesiredCompRoleCandidate = 'set_desired_comp_role-candidate';
export const segmentSetRoleQuestionsCandidate = 'set_role_questions-candidate';
// moved to server
// export const segmentCompleteSetRequirementsCandidate = 'complete_set_requirements-candidate';

// -- Create Profile Events
export const segmentSetAvatarCandidate = 'set_avatar-candidate';
export const segmentUploadResumeCandidate = 'upload_resume-candidate';
export const segmentSkipResumeCandidate = 'skip_resume-candidate';
// moved to server
// export const segmentCompleteCreateProfileCandidate = 'complete_create_profile-candidate';

// -- Add Sales Info Events
export const segmentSetRecentCompCandidate = 'set_recent_comp-candidate';
export const segmentSetDealInfoCandidate = 'set_deal_info-candidate';
export const segmentSetWhatSoldCandidate = 'set_what_sold-candidate';
export const segmentSetWhoSoldToCandidate = 'set_who_sold_to-candidate';
// moved to server
// export const segmentCompleteRegistrationCandidate = 'complete_registration-candidate';

export const segmentUserLoginCandidate = 'login-candidate';
export const segmentUserLogoutCandidate = 'logout-candidate';

export const segmentOpportunitiesPageViewCandidate = 'opportunities_page_view-candidate';
export const segmentIntroRequestPassedCandidate = 'intro_request_passed-candidate';

export const segmentCandidateProfileViewCandidate = 'candidate_profile_view-candidate';

// Admin Events

// moved to server
// export const segmentCandidateAppStatusChangeAdmin = 'candidate_app_status_change-admin';
// export const segmentEmployeeAppStatusChangeAdmin = 'employee_app_status_change-admin';
// export const segmentEmployerAppStatusChangeAdmin = 'employer_app_status_change-admin';

// Logged Out User Events

export const segmentCandidateProfileViewLoggedOut = 'candidate_profile_view-logged-out';
