import React, { useState, useEffect, useRef, useCallback } from 'react';
import classnames from 'classnames';

import { VisibleHOC } from 'containers/HOC';
import { useOnClickOutside } from 'hooks';

import FontIcon from 'components/FontIcon';

import styles from './DropdownSelectMany.scss';

// TODO: too many duplicated code with Dropdown.js

const DropdownSelectMany = ({
  activeIcon,
  className,
  defaultIcon,
  defaultLabel,
  disabled,
  highlight,
  isLocation,
  isVisible,
  labelPrefix,
  optionsLarge,
  optionsMedium,
  optionsTall,
  selectedValues = [],
  source,
  onChange,
  name,
}) => {
  const [active, setActive] = useState(false);
  const [clientHeight, setClientHeight] = useState(39);
  const _mounted = useRef(false);
  const container = useRef();

  useOnClickOutside(
    container,
    useCallback(() => setActive(false), [])
  );

  useEffect(() => {
    _mounted.current = true;
    if (container.current) {
      setTimeout(() => {
        const { clientHeight = 39 } = container.current || {};
        if (_mounted.current) {
          setClientHeight(clientHeight + 1);
        }
      }, 1);
    }

    return () => {
      _mounted.current = false;
    };
  }, []);

  useEffect(() => {
    const { clientHeight = 39 } = container.current || {};
    const newClientHeight = clientHeight + 1;
    updateClientHeight(newClientHeight);
  }, [selectedValues.length]);

  const onClick = () => {
    setActive((active) => !active);
  };

  const onSelect = (item) => {
    if (active) {
      onChange({
        ...item,
        filter: name,
      });
    }
  };

  const onDeselect = (item) => {
    onChange({
      ...item,
      filter: name,
    });
  };

  const updateClientHeight = (clientHeight) => setClientHeight(clientHeight);

  const makePills = (item) => (
    <div key={item.label} className={styles.pill} onClick={(event) => event.stopPropagation()}>
      {item.label}
      <FontIcon
        iconName={'close'}
        className={styles.closeIcon}
        onClick={(event) => {
          event.stopPropagation();
          onDeselect(item);
        }}
      />
    </div>
  );

  const optionsContainerClasses = classnames(styles.OptionsContainer, {
    [styles.largeContainer]: optionsLarge,
    [styles.mediumContainer]: optionsMedium,
    [styles.optionsTall]: optionsTall,
  });

  const selectedItems = [];

  const makeOptions = (item, idx) => {
    const { label, key, value, optionType } = item;

    if (isLocation) {
      const isSelected = selectedValues
        .map((val) => `${val[val.type]}`)
        .includes(`${value[value.type]}`);

      const subValues =
        optionType === 'folder'
          ? item.subCategories.map((subCat) => `${subCat.value[subCat.value.type]}`)
          : [];

      const selectedSubValues = subValues
        ? subValues.filter((subVal) =>
            selectedValues.map((val) => `${val[val.type]}`).includes(subVal)
          )
        : [];

      const isFolderSelected =
        optionType === 'folder' && selectedSubValues.length === subValues.length;

      const isFolderPartialSelected =
        optionType === 'folder' &&
        selectedSubValues.length > 0 &&
        selectedSubValues.length < subValues.length;

      if (isSelected && optionType !== 'folder') selectedItems.push(item);

      const optionClasses = classnames(styles.Option, {
        [styles.activeOption]: (isSelected && optionType !== 'folder') || isFolderSelected,
        [styles.partialActiveOption]: isFolderPartialSelected,
      });

      const onClickHandler = (event) => {
        event.stopPropagation();

        onSelect(item);
      };

      const optionProps = {
        className: optionClasses,
        key: key || label,
        onClick: onClickHandler,
        value,
      };

      const indentString =
        item.indent > 0
          ? new Array(item.indent).fill(<span key={idx} className={styles.indent} />)
          : null;

      const labelClasses = classnames(styles.label, {
        [styles.activeLabel]: isSelected || isFolderSelected,
        [styles.partialActiveLabel]: isFolderPartialSelected,
      });

      const labelContent = <span className={labelClasses}>{label}</span>;

      return (
        <div {...optionProps}>
          {indentString}
          {labelContent}
        </div>
      );
    }

    const isSelected = selectedValues.map((val) => `${val}`).includes(`${value}`);

    const subValues =
      optionType === 'folder' ? item.subCategories.map((subCat) => `${subCat.queries}`) : [];

    const selectedSubValues = subValues
      ? subValues.filter((subVal) => selectedValues.map((val) => `${val}`).includes(subVal))
      : [];

    const isFolderSelected =
      optionType === 'folder' && selectedSubValues.length === subValues.length;

    const isFolderPartialSelected =
      optionType === 'folder' &&
      selectedSubValues.length > 0 &&
      selectedSubValues.length < subValues.length;

    if (isSelected && optionType !== 'folder') selectedItems.push(item);

    const optionClasses = classnames(styles.Option, {
      [styles.activeOption]: (isSelected && optionType !== 'folder') || isFolderSelected,
      [styles.partialActiveOption]: isFolderPartialSelected,
    });

    const onClickHandler = (event) => {
      event.stopPropagation();

      onSelect(item);
    };

    const optionProps = {
      className: optionClasses,
      key: key || label,
      onClick: onClickHandler,
      value,
    };

    const indentString =
      item.indent > 0 ? new Array(item.indent).fill(<span className={styles.indent} />) : null;

    const labelClasses = classnames(styles.label, {
      [styles.activeLabel]: isSelected || isFolderSelected,
      [styles.partialActiveLabel]: isFolderPartialSelected,
    });

    const labelContent = <span className={labelClasses}>{label}</span>;

    return (
      <div {...optionProps}>
        {indentString}
        {labelContent}
      </div>
    );
  };

  const selectableOptions = source && source.map(makeOptions);

  const content = active ? selectableOptions : null;

  const prefixForLabel = labelPrefix ? (
    <span className={styles.labelPrefix}>{labelPrefix}</span>
  ) : null;

  const selectedPills = selectedItems.length > 0 ? selectedItems.map(makePills) : null;

  const selectedLabelContent = selectedPills ? (
    <div className={styles.pillContainer}>{selectedPills}</div>
  ) : (
    <div className={styles.inPlaceDefaultLabel}>
      {prefixForLabel}
      {defaultLabel}
    </div>
  );

  const defaultIconContent = defaultIcon ? (
    <FontIcon className={styles.mainIcon} iconName={defaultIcon} />
  ) : null;

  const activeIconContent = activeIcon ? (
    <FontIcon className={styles.mainIcon} iconName={activeIcon} />
  ) : (
    defaultIconContent
  );

  const iconContent = active ? activeIconContent : defaultIconContent;

  const classes = classnames(
    styles.Dropdown,
    {
      [styles.active]: active,
      [styles.focus]: highlight,
      [styles.disabled]: disabled,
    },
    className
  );

  const styleKey = isVisible ? 'top' : 'bottom';

  const optionsContainerStyle = { [styleKey]: `${clientHeight}px` };

  return (
    <div className={classes} onClick={disabled ? () => null : onClick} ref={container}>
      {selectedLabelContent}
      {iconContent}
      <div style={optionsContainerStyle} className={optionsContainerClasses}>
        {content}
      </div>
    </div>
  );
};

export default VisibleHOC()(DropdownSelectMany);
