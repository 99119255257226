import { call, select, put, take } from 'redux-saga/effects';

import { entitySelectorFactory } from 'reduxStore/selectorFactories';

import { handleError } from 'utils/common';
import { trackEvent } from 'utils/analytics';
import { segmentRemoveBookmark } from 'segment/eventNames';

import { promiseBookmarksRemove } from '../../../CandidateSearchPage/promises';

import { BOOKMARKS_REMOVE_STARTED } from '../actions';

import { featuredCandidateUpdate } from '../creators';

const trackBookmarkRemove = (bookmarkId, candidateId) => {
  const event = segmentRemoveBookmark;

  const properties = { bookmarkId, candidateId };

  const eventObject = { event, properties };

  trackEvent(eventObject);
};

const sagaBookmarksRemove = function* sagaBookmarksRemove() {
  while (true) {
    const {
      payload: { bookmarkId, candidateId },
    } = yield take(BOOKMARKS_REMOVE_STARTED);

    const promiseParams = {
      bookmarkId,
    };

    const { byId } = yield select(entitySelectorFactory('featured', 'candidate'));

    try {
      yield put(
        featuredCandidateUpdate({
          candidate: {
            ...byId[candidateId].attributes,
            bookmark: null,
          },
        })
      );

      yield call(promiseBookmarksRemove, promiseParams);

      yield call(trackBookmarkRemove, bookmarkId, candidateId);
    } catch (error) {
      handleError(error);

      yield put(featuredCandidateUpdate({ candidate: byId[candidateId].attributes }));
    }
  }
};

export default sagaBookmarksRemove;
