/* eslint-disable import/prefer-default-export */
import { createSelector, createStructuredSelector } from 'reselect';

export const getApp = (state) => state.app;

export const getCurrentUser = createSelector([getApp], (app) => app.user);
export const getModal = createSelector([getApp], (app) => app.modal);
export const getLogin = createSelector([getApp], (app) => app.login);
export const getNotification = createSelector([getApp], (app) => app.notification);

export const getApprovedStatus = createSelector([getCurrentUser], (user) => {
  const { currentProfile, currentProfileType } = user || {};

  if (currentProfile) {
    return (
      (currentProfile.employer && currentProfile.employer.applicationStatus === 'approved') ||
      currentProfile.applicationStatus === 'approved' ||
      currentProfileType === 'RainmakersAdmin'
    );
  }

  return false;
});

export const getHideNames = createSelector([getCurrentUser], (user) => {
  const { currentProfile, currentProfileType } = user || {};

  if (currentProfile && currentProfileType === 'Employee') {
    return currentProfile.employer && currentProfile.employer.hideCandidateNames;
  }

  return false;
});

export const getHasSeenApproved = createSelector(
  [getCurrentUser],
  (user) =>
    (user &&
      user.currentProfile &&
      user.currentProfile.employer &&
      user.currentProfile.employer.approvedViewSeen === true) ||
    (user && user.currentProfile && user.currentProfile.approvedViewSeen === true)
);

export const getLoggedIn = createSelector([getCurrentUser], (user) => user.currentProfile);

const selector = createStructuredSelector({
  app: getApp,
  hideNames: getHideNames,
  isApproved: getApprovedStatus,
  isLoggedIn: getLoggedIn,
  hasSeenApproved: getHasSeenApproved,
  login: getLogin,
  modal: getModal,
  notification: getNotification,
  user: getCurrentUser,
});

export default selector;
