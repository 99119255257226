import { call, put, select, takeLatest } from 'redux-saga/effects';

import { handleError } from 'utils/common';
import { trackEvent } from 'utils/analytics';
import { segmentSetAvatarCandidate } from 'segment/eventNames';

import { patchEntity } from 'api/apiEntity';

import { selectUser } from 'connectors/User/redux/selectors';

import { candidateProfilePatchDone } from 'connectors/User/redux/creators';

import { CANDIDATE_IMG_UPLOAD_STARTED } from '../actions';

import { serverErrorSet } from '../creators';

export const trackSetAvatarCandidate = () => {
  const event = segmentSetAvatarCandidate;

  const properties = {
    onboarding: true,
  };

  const eventObject = { event, properties };

  trackEvent(eventObject);
};

const patchCandidateImage = function* patchCandidateImage(action) {
  const {
    payload: { picture },
  } = action;

  const { currentProfile = {} } = yield select(selectUser);

  const { id: candidateId } = currentProfile;

  try {
    const args = {
      id: candidateId,
      type: 'candidates',
      data: {
        type: 'candidates',
        id: candidateId,
        attributes: {
          picture: {
            file_data: picture,
          },
        },
      },
      config: {
        params: {
          camelizeIt: true,
        },
      },
    };

    const {
      data: { candidate },
    } = yield call(patchEntity, args);

    yield call(trackSetAvatarCandidate);

    const candidateProfile = {
      ...currentProfile,
      ...candidate,
    };

    yield put(candidateProfilePatchDone({ candidateProfile }));
  } catch (error) {
    handleError(error);

    const { response: { data: { errors: { full_messages: errors = [] } = {} } = {} } = {} } =
      error || {};

    yield put(serverErrorSet({ errors }));
  }
};

const sagaCandidateImageUpload = function* sagaCandidateImageUpload() {
  yield takeLatest(CANDIDATE_IMG_UPLOAD_STARTED, patchCandidateImage);
};

export default sagaCandidateImageUpload;
