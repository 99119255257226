/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import locationsData, {
  LOCATIONS_PAGE_NYC,
  LOCATIONS_PAGE_AUSTIN,
  LOCATIONS_PAGE_DENVER,
  LOCATIONS_PAGE_ATLANTA,
  LOCATIONS_PAGE_PHOENIX,
  LOCATIONS_PAGE_RALEIGH,
  LOCATIONS_PAGE_LA,
  LOCATIONS_PAGE_CHICAGO,
  LOCATIONS_PAGE_SANFRANCISCO,
} from 'pages/LocationsPage/locationsPageMetaData';

import jobsData, { JOB_PAGE_CSM, JOB_PAGE_SD } from 'pages/JobsPage/jobsPageMetaData';

import { getAltName } from 'utils/urlUtils';

import pic1 from './assets/top-cities-salespeople.png';
import pic2 from './assets/contact-rainmakers.png';

import styles from './MainContent.scss';

export function MainContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt={getAltName(pic1)} />

      <h2>Your Gateway to Premier Sales Jobs</h2>
      <p>
        Step into a realm where your sales talent is valued, nurtured, and matched with
        opportunities that align with your ambition. At Rainmakers, we specialize in bridging the
        gap between high-caliber sales professionals and industry-leading companies across the
        United States. Whether you're an Account Executive (AE), Customer Success Manager (CSM),
        Executive Account Executive, Mid-Market Account Executive, Sales Development Representative
        (SDR), Sales Director, Technical Sales Manager, or VP of Sales, your journey to a fulfilling
        career starts here.
      </p>

      <h2>Navigating Opportunities in America's Thriving Cities</h2>
      <p>
        Embark on a journey through diverse landscapes of opportunities in some of the most dynamic
        cities in the United States. From the innovation-driven corridors of New York City and San
        Francisco to the vibrant entrepreneurial spirit of Austin and Denver, each town offers a
        unique tapestry of possibilities.
      </p>

      <ul>
        <li>
          <Link to={locationsData[LOCATIONS_PAGE_NYC].localUrl} target="_blank">
            New York City
          </Link>
          : Immerse yourself in a global epicenter of technology, finance, and innovation, where
          startups in biotech, real estate, and healthcare thrive amidst a landscape of iconic
          skyscrapers.
        </li>
        <li>
          <Link to={locationsData[LOCATIONS_PAGE_AUSTIN].localUrl} target="_blank">
            Austin
          </Link>
          : Discover a city synonymous with innovation, where favorable tax regulations fuel a surge
          in tech startups and e-commerce ventures.
        </li>
        <li>
          <Link to={locationsData[LOCATIONS_PAGE_DENVER].localUrl} target="_blank">
            Denver
          </Link>
          : Explore a dynamic ecosystem that spans from groundbreaking healthcare innovations to a
          burgeoning cannabis industry, all set against a backdrop of majestic mountains.
        </li>
        <li>
          <Link to={locationsData[LOCATIONS_PAGE_ATLANTA].localUrl} target="_blank">
            Atlanta
          </Link>
          : Engage with a corporate scene that hosts an array of Fortune 500 companies, offering
          diverse career options in academia, research, and healthcare.
        </li>
        <li>
          <Link to={locationsData[LOCATIONS_PAGE_PHOENIX].localUrl} target="_blank">
            Phoenix
          </Link>
          : Uncover a diverse job market with a spectrum of opportunities in tourism, healthcare,
          e-commerce, and emerging technologies.
        </li>
        <li>
          <Link to={locationsData[LOCATIONS_PAGE_RALEIGH].localUrl} target="_blank">
            Raleigh
          </Link>
          : Experience a city where research, education, and IT converge, bolstered by strong ties
          with local universities and an affordable cost of living.
        </li>
        <li>
          <Link to={locationsData[LOCATIONS_PAGE_LA].localUrl} target="_blank">
            Los Angeles
          </Link>
          : Navigate a bustling economic hub with opportunities spanning international trade,
          fashion, tourism, and entertainment.
        </li>
        <li>
          <Link to={locationsData[LOCATIONS_PAGE_CHICAGO].localUrl} target="_blank">
            Chicago
          </Link>
          : Delve into a robust tech scene surrounded by diverse industries, from finance and
          engineering to healthcare and transportation.
        </li>
        <li>
          <Link to={locationsData[LOCATIONS_PAGE_SANFRANCISCO].localUrl} target="_blank">
            San Francisco
          </Link>
          : Embrace a city that offers both tech opportunities and a wealth of possibilities in
          biotechnology, international finance, and education.
        </li>
      </ul>

      <Link to="/apply-now" title="Apply Now">
        <img src={pic2} alt={getAltName(pic2)} />
      </Link>

      <h2>Advance Your Career with Rainmakers</h2>
      <p>
        Navigating the job market can often feel like navigating a maze. That's where Rainmakers
        steps in. Our expert recruiting services are meticulously designed to guide you through
        every twist and turn, connecting your unique skills and aspirations with the aligned
        opportunities.{' '}
        <strong>
          Our deep-rooted connections across various industries empower us to open doors for you,
          whether you are seeking a role as a{' '}
        </strong>
        <Link to={jobsData[JOB_PAGE_CSM].localUrl} target="_blank">
          CSM
        </Link>
        <strong>{', '}</strong>
        <Link to={jobsData[JOB_PAGE_SD].localUrl} target="_blank">
          Sales Director
        </Link>
        <strong>, or any other sales position.</strong>
      </p>

      <p>
        Joining forces with Rainmakers means gaining access to an exclusive network of top-tier
        employers in burgeoning cities, exponentially enhancing your chances of securing a role that
        matches and elevates your career goals.
      </p>

      <h2>Start Your Sales Journey Today</h2>
      <p>
        Discover your potential by partnering with Rainmakers. Together, we will navigate the
        dynamic job markets of America's most vibrant cities, connecting you with the opportunities
        that will shape your future. <Link to="/apply-now/">Sign-up now!</Link>
      </p>
    </div>
  );
}
