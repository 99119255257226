/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import pic1 from './assets/1-sales-manager-job-description.png';
import pic2 from './assets/2-contact-rainmakers-for-sales-manager-jobs.png';
import pic3 from './assets/3-sales-manager-average-salary.png';
import pic4 from './assets/4-what-does-a-sales-manager-do.png';
import pic5 from './assets/5-sales-manager-jobs.png';
import pic6 from './assets/6-create-profile-browse-sales-manager-jobs.png';

import styles from './SMJobsContent.scss';

export function SMJobsContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt="" />

      <h2>Tech Sales Manager Job Information</h2>

      <p>
        Sales Managers are the ones who direct and oversee sales representatives and manage a
        company’s daily sales operations. They provide direction for the sales team by developing
        training for the staff, creating plans for the team’s effective operation, and encouraging
        team members to surpass short- and long-term sales goals. In addition, the Sales Manager
        oversees sales strategies, establishes sales objectives, and monitors sales results.
      </p>

      <h2>Duties and Responsibilities</h2>

      <p>
        Depending on the company, a sales manager may have different duties. However, the jobs
        frequently involve finding talent, developing and coaching sales staff, establishing the
        sales plan, and tracking sales operations.
      </p>

      <h3>Recruitment</h3>

      <p>
        Sales Managers are responsible for hiring sales representatives and are expected to help
        interview potential applicants to see if they possess the right qualifications for sales rep
        positions. A sales manager must have an intimate understanding of what makes a good sales
        rep. Applying a careful, thoughtful approach to the hiring process means building a solid
        and effective sales team.
      </p>

      <h3>Team Development</h3>

      <p>
        Sales Managers must give their team members the tools necessary to close deals. They
        instruct sales reps— seasoned pros and recent hires—on the company’s products, services,
        sales procedures, and other methods, such as upselling and cross-selling.
      </p>

      <p>
        Sales Managers should offer regular training sessions, in-person workshops, conferences, and
        internal resources to maintain the sales talents of their teams. Additionally, they are
        responsible for arranging one-on-one meetings with agents to assess progress, address
        possible obstacles, and enhance professional development.
      </p>

      <h3>Develop Sales Strategy</h3>

      <p>
        The Sales Manager must set team goals and quotas and identify and assign target markets. In
        addition, a good Sales Manager is responsible for analyzing customer buying processes and
        determining the best strategies for the sales team to close deals. Therefore, critical
        analysis, sales coaching, and a deep understanding of effective sales tactics are essential.
      </p>

      <p>
        On top of developing sales strategies, a Sales Manager must be able to track sales data to
        determine what works and what doesn’t and adjust approaches as needed.
      </p>

      <h3>Collaboration</h3>

      <p>
        To keep deals moving through the sales pipeline, Sales Managers must collaborate closely
        with other customer-facing departments like marketing and customer support. Additionally, by
        relating customer feedback to product or service development teams, businesses can improve
        what they offer customers.
      </p>

      <Link to="/apply-now" title="Apply Now">
        <img src={pic2} alt="" />
      </Link>

      <h2>What Do Employers Look For When Hiring a Sales Manager?</h2>

      <p>
        Unsurprisingly, employers require potential Sales Managers to exhibit several professional
        and personal qualities. Here are some of the traits and abilities employers look for in a
        Sales Manager.
      </p>

      <ul>
        <li>
          <strong>Resilience</strong> – Although working in sales is stressful, managing a sales
          team is far more challenging. Therefore, Sales Managers must be able to exhibit and
          project resilience to help guide sales teams through difficult situations and inspire them
          every day.
        </li>
        <li>
          <strong>Motivation</strong> – Salespeople frequently need an extra push to keep closing
          deals, particularly after repeated rejections. A strong manager should be able to
          intervene and boost department morale and inspire sales reps to pursue their next lead.
        </li>
        <li>
          <strong>Problem-solving</strong> – Whether it’s a deal that has stalled or a dissatisfied
          new customer, Sales Managers must be skilled at putting out the sorts of fires that
          sometimes arise during sales cycles. They must have the ability to foresee issues and
          respond appropriately and calmly.
        </li>
        <li>
          <strong>Leadership</strong> – Sales Managers must know when to assign specific tasks to
          their sales teams to keep the entire department running efficiently and trust them to
          fulfill their responsibilities. This involves building a two-way relationship and a strong
          sense of cooperation with the sales team.
        </li>
        <li>
          <strong>Communication</strong> – Sales Managers interact with many individuals and
          departments, including marketing, customer service, tech support, product development
          senior management, and their own sales teams. The Sales Manager must know how to speak
          with clarity and compassion and match their tone with the appropriate situation, whether
          it be with requesting data from marketing or dealing with a dissatisfied customer.
        </li>
        <li>
          <strong>Public Speaking</strong> – Sales Managers should be comfortable with public
          speaking as it is invaluable when presenting sales pitches to clients or reports to other
          departments.
        </li>
        <li>
          <strong>Active Listening</strong> – It is advantageous for Sales Managers to practice
          active listening techniques. This means giving special attention to another person,
          whether they are a teammate or a potential client. This also includes not talking over
          someone, missing essential information, and waiting until they have finished speaking.
        </li>
        <li>
          <strong>Empathy</strong> – Empathy enables Sales Managers to pinpoint the root causes of
          the issues that their teams are experiencing and understand difficult circumstances from
          their perspective. Sales Managers can improve their interactions with their prospects and
          current customers by developing a strong sense of empathy. This allows the Sales Manager
          to better understand customer challenges and how their sales team can provide effective
          solutions.
        </li>
      </ul>

      <img src={pic3} alt="" />

      <h2>What Is It Like To Work As a Sales Manager?</h2>

      <p>
        The entire sales industry moves quickly and is both challenging and exciting. This is
        especially true for Sales Managers.
      </p>

      <p>
        Managing sales teams requires managers to be able to simultaneously handle numerous
        personalities and hundreds of deals while maintaining composure.
      </p>

      <p>
        On the plus side, according to various job rankings and reports, Sales Managers tend to
        report higher overall job satisfaction. There are several opportunities for advancement and
        increased compensation. And while the stress level can be high, it’s a perfect position for
        individuals who thrive when presented with challenges and opportunities to problem-solve.
      </p>

      <p>
        Even though some Sales Managers report that maintaining a work-life balance can be
        difficult, the ideal Sales Manager applicant should discover that the rewarding environment
        and compensation make the trade-off worthwhile.
      </p>

      <img src={pic4} alt="" />

      <h2>What Qualifications Does One Need To Become a Sales Manager?</h2>

      <p>
        While the pathway to becoming a Sales Manager may differ from person to person, here are
        some of the most common steps to acquire this position:
      </p>

      <ol>
        <li>
          Obtain a bachelor’s degree in business administration, marketing, public relations,
          communications, psychology, or another related field.
        </li>
        <li>
          It’s essential to have built up between two and five years of practical sales
          experience—ideally, in the same field that you intend to obtain the Sales Manager
          position. Most employers will not consider applicants who lack relevant and proven sales
          experience.
        </li>
        <li>
          Communicate your goals to your current sales leadership. A competent boss will offer
          chances to demonstrate your ability and resolve and present opportunities to help you
          develop into the role.
        </li>
        <li>
          While not an absolute necessity, a master’s degree in sales management, business
          administration, or both could put an applicant at the head of the line.
        </li>
        <li>
          Instead of other advanced degrees, the applicant may want to consider pursuing
          professional certifications to gain more knowledge and expertise. This also lets employers
          know you’re serious about advancing your sales career. Some certificates to consider
          include:
          <ul>
            <li>Certified Sales Executive certification</li>
            <li>Challenger Sales & Marketing development program</li>
            <li>Certified Professional Sales Person (CPSP) certification</li>
          </ul>
        </li>
      </ol>

      <img src={pic5} alt="" />

      <h2>How Much Does a Sales Manager Earn?</h2>

      <p>
        Based on local business size and cost of living, the base salary for Sales Managers differs
        by location and state. In recent years, the median income for sales managers in the U.S. was
        $133,000, with low and high average salaries of $90,000 and $186,000, respectively. Sales
        Manager salaries have increased steadily over the past ten years, in contrast to many other
        occupations, and have mostly kept pace with inflation.
      </p>

      <p>
        San Jose, California, witnessed $208,000 as the highest Sales Manager salary as recently as
        2020. However, in New York City, that figure came to approximately $203,000.
      </p>

      <p>
        Sales Managers make, on average, almost $80,000 more than the sales representatives they
        manage, but they also experience more significant challenges when it comes to maintaining a
        proper work-life balance. The fact that many managers aren’t actively closing deals means
        that their pay typically does not come with a sales commission. However, depending on their
        team’s sales growth, some Sales Managers may receive bonuses, and a few businesses might
        provide special manager incentives for prolonged above-average performance.
      </p>

      <Link to="/apply-now" title="Apply Now">
        <img src={pic6} alt="" />
      </Link>

      <h2>The Path From Sales Rep To Sales Manager</h2>

      <p>
        The sense of accomplishment that comes with being a Sales Manager more than makes up for the
        difficulties inherent to the position. The rush one can get from leading a sales team to
        accomplish or exceed their quotas and working with others to expand the business can’t be
        topped.
      </p>

      <p>
        That said, individuals committed to advancing a sales career should not wait for the title
        of Sales Manager before supporting their fellow sales reps. Initiative and willingness to
        assist other sales team members get noticed, which should accelerate the process of landing
        a future position as a Sales Manager.
      </p>

      <p>
        Are you ready to find a new Sales Manager to join your team? Have you decided it’s time to
        find your next opportunity? Let Rainmakers help.{' '}
        <a href={`https://${process.env.HOST}/`}>Connect now!</a>
      </p>
    </div>
  );
}
