import { combineReducers } from 'redux';
import { v4 } from 'uuid';
import union from 'lodash.union';
import merge from 'lodash.merge';

import { produce } from 'immer';

import { formatType } from 'reduxStore/utils';

import {
  app,
  defaults,
  login,
  modal,
  newPassword,
  notification,
  requisitionDetails,
  resetPassword,
  templateComposer,
  user,
} from 'connectors/reducers';

import {
  employerBookmarks,
  candidateProfile,
  employerCandidates,
  employerEditor,
  employerRequisitions,
  employerSearch,
  featured,
  signup,
} from 'pages/EmployeePortal/reducers';

import {
  toolboxAdmins,
  toolboxApproaches,
  toolboxBatchRequests,
  toolboxCandidates,
  toolboxCohorts,
  toolboxCompanies,
  toolboxEducationOrgs,
  toolboxEmployers,
  toolboxLeads,
  toolboxRequisitions,
} from 'pages/AdminPortal/reducers';

import {
  candidateEditor,
  candidateHome,
  candidateOpportunities,
  candidateSettings,
  onboarding,
} from 'pages/CandidatePortal/reducers';

import {
  API_ERRORS_UPDATE,
  API_ERRORS_DELETE,
  ENTITY_LOAD_STARTED,
  ENTITY_LOAD_DONE,
  LOGOUT_DONE,
  RESOURCE_UPDATE,
  RESOURCE_DELETE,
  RESOURCE_ERRORS_UPDATE,
  SAVING_UPDATE,
} from './actions';

export const initialState = Object.freeze({
  locale: 'en_US',
});

const appReducer = combineReducers({
  app,
  employerBookmarks,
  employerCandidates,
  employerSearch,
  candidateProfile,
  defaults,
  employerEditor,
  login,
  modal,
  newPassword,
  notification,
  requisitionDetails,
  employerRequisitions,
  resetPassword,
  templateComposer,
  featured,
  signup,
  user,

  toolboxAdmins,
  toolboxApproaches,
  toolboxBatchRequests,
  toolboxCandidates,
  toolboxCohorts,
  toolboxCompanies,
  toolboxEducationOrgs,
  toolboxEmployers,
  toolboxLeads,
  toolboxRequisitions,

  candidateEditor,
  candidateHome,
  candidateOpportunities,
  candidateSettings,
  onboarding,
});

const crossSliceReducer = (state = initialState, action) =>
  produce(state, () => {
    const { payload, type: actionType } = action;
    switch (actionType) {
      case API_ERRORS_UPDATE: {
        const { error: { data: { errors = [] } = {} } = {}, slice } = payload;

        const { [slice]: { apiErrors = {} } = {} } = state;

        if (Array.isArray(errors)) {
          const newErrors = errors.reduce((acc, curr) => {
            const errorId = v4();

            acc[errorId] = curr;

            return acc;
          }, {});

          return {
            ...state,
            [slice]: {
              ...state[slice],
              apiErrors: {
                ...apiErrors,
                ...newErrors,
              },
            },
          };
        }

        const errorKeys = Object.keys(errors);

        const newErrors = errorKeys.reduce((acc, curr) => {
          const errorId = v4();

          acc[errorId] = { [curr]: errors[curr] };

          return acc;
        }, apiErrors);

        return {
          ...state,
          [slice]: {
            ...state[slice],
            apiErrors: {
              ...newErrors,
            },
          },
        };
      }

      case API_ERRORS_DELETE: {
        const { id, slice } = payload;
        const { [slice]: { apiErrors = {} } = {} } = state;

        const newErrors = { ...apiErrors };

        delete newErrors[id];

        return {
          ...state,
          [slice]: {
            ...state[slice],
            apiErrors: newErrors,
          },
        };
      }

      case SAVING_UPDATE: {
        const { saving, slice } = payload;
        const { [slice]: currentSlice = {} } = state;

        return {
          ...state,
          [slice]: {
            ...currentSlice,
            saving,
          },
        };
      }

      case ENTITY_LOAD_DONE: {
        const {
          data: { entities, meta = {} },
          irregularType,
          slice,
          type,
          typeOverride,
          updateOnly,
        } = payload;

        let formattedType = formatType(type);

        const formattedIrregularType = irregularType ? formatType(irregularType) : null;

        const formattedOverrideType = typeOverride ? formatType(typeOverride) : null;

        if (
          formattedOverrideType &&
          entities &&
          (entities[formattedType] || entities[formattedIrregularType])
        ) {
          entities[formattedOverrideType] =
            entities[formattedType] || entities[formattedIrregularType];

          delete entities[formattedType];
          delete entities[formattedIrregularType];
        }

        if (formattedOverrideType) {
          formattedType = formattedOverrideType;
        }

        const { pagination: { currentPage } = {} } = meta;

        // Empty response for an 'updateOnly' call
        // Set loading to false
        // Don't touch entities or meta
        if (!entities && updateOnly) {
          return {
            ...state,
            [slice]: {
              ...state[slice],
              meta: {
                ...state[slice].meta,
                [formattedType]: {
                  ...state[slice].meta[formattedType],
                  isLoading: false,
                  isBackgroundLoading: false,
                },
              },
            },
          };
        }

        const {
          [slice]: currentSlice = {},
          [slice]: { meta: { [formattedType]: currentEntityMeta = {} } = {} } = {},
        } = state;

        // Empty response
        // Set loading to false
        // Set entity to empty
        if (!entities && !updateOnly) {
          return {
            ...state,
            [slice]: {
              ...currentSlice,
              entities: {
                ...currentSlice.entities,
                [formattedType]: {
                  byId: {},
                  allIds: [],
                },
              },
              meta: {
                ...currentSlice.meta,
                [formattedType]: {
                  ...meta,
                  isLoading: false,
                  isBackgroundLoading: false,
                },
              },
            },
          };
        }

        // currentPage is 1 and this is not an update call
        // Reset main entity with new data
        // Merge relational entities
        if (currentPage === 1 && !updateOnly) {
          const entityKeys = Object.keys(entities);

          const newEntities = entityKeys.reduce((acc, curr) => {
            const { [slice]: { entities: { [curr]: { byId = {}, allIds = [] } = {} } = {} } = {} } =
              state;

            if (curr === formattedType) {
              acc[curr] = {
                byId: {
                  ...byId,
                  ...entities[curr].byId,
                },
                allIds: union(entities[curr].allIds),
              };
            } else {
              acc[curr] = {
                byId: merge(entities[curr].byId, byId),
                allIds,
              };
            }

            return acc;
          }, {});

          return {
            ...state,
            [slice]: {
              ...currentSlice,
              entities: {
                ...currentSlice.entities,
                ...newEntities,
              },
              meta: {
                ...currentSlice.meta,
                [formattedType]: {
                  ...meta,
                  isLoading: false,
                  isBackgroundLoading: false,
                },
              },
            },
          };
        }

        // Add new entities to old entities
        const entityKeys = Object.keys(entities);

        const newEntities = entityKeys.reduce((acc, curr) => {
          const { [slice]: { entities: { [curr]: { byId = {}, allIds = [] } = {} } = {} } = {} } =
            state;

          acc[curr] = updateOnly
            ? {
                byId: {
                  ...byId,
                  ...entities[curr].byId,
                },
                allIds,
              }
            : {
                byId: merge(entities[curr].byId, byId),
                allIds: union(allIds, entities[curr].allIds),
              };

          return acc;
        }, {});

        // updateOnly
        // do not touch meta pagination data
        // only update existing entity data
        if (updateOnly) {
          const newState = {
            ...state,
            [slice]: {
              ...currentSlice,
              entities: merge(
                JSON.parse(JSON.stringify(currentSlice.entities)),
                JSON.parse(JSON.stringify(newEntities))
              ),
              meta: {
                ...currentSlice.meta,
                [formattedType]: {
                  ...currentEntityMeta,
                  isLoading: false,
                  isBackgroundLoading: false,
                },
              },
            },
          };

          return newState;
        }

        // not updateOnly
        // update meta pagination data and existing entity data
        return {
          ...state,
          [slice]: {
            ...currentSlice,
            entities: merge(
              JSON.parse(JSON.stringify(currentSlice.entities)),
              JSON.parse(JSON.stringify(newEntities))
            ),
            meta: {
              ...currentSlice.meta,
              [formattedType]: {
                ...meta,
                isLoading: false,
                isBackgroundLoading: false,
              },
            },
          },
        };
      }

      case ENTITY_LOAD_STARTED: {
        const { params: { page } = {}, slice, typeOverride, type } = payload;

        const { [slice]: currentSlice = {} } = state;

        let formattedType = formatType(type);

        const formattedOverrideType = typeOverride ? formatType(typeOverride) : null;

        if (formattedOverrideType) {
          formattedType = formattedOverrideType;
        }

        if (page === 1) {
          return {
            ...state,
            [slice]: {
              ...currentSlice,
              meta: {
                ...currentSlice.meta,
                [formattedType]: {
                  isLoading: true,
                },
              },
            },
          };
        }

        if (page > 1) {
          return {
            ...state,
            [slice]: {
              ...currentSlice,
              meta: {
                ...currentSlice.meta,
                [formattedType]: {
                  isBackgroundLoading: true,
                },
              },
            },
          };
        }

        return state;
      }

      case RESOURCE_UPDATE: {
        const { slice, type, id, resource } = payload;

        const formattedType = formatType(type);

        const {
          [slice]: currentSlice = {},
          [slice]: {
            entities: {
              [formattedType]: currentEntity = {},
              [formattedType]: { allIds = [], byId: { [id]: currentResource = {} } = {} } = {},
            } = {},
          } = {},
        } = state;

        const newAllIds = allIds.includes(id) ? allIds : [id].concat(allIds);

        return {
          ...state,
          [slice]: {
            ...currentSlice,
            entities: {
              ...currentSlice.entities,
              [formattedType]: {
                ...currentEntity,
                byId: {
                  ...currentEntity.byId,
                  [id]: {
                    ...currentResource,
                    ...resource,
                    previousResource: currentResource,
                  },
                },
                allIds: newAllIds,
              },
            },
          },
        };
      }

      case RESOURCE_DELETE: {
        const { id, slice, type } = payload;

        const formattedType = formatType(type);

        const {
          [slice]: currentSlice = {},
          [slice]: {
            entities: {
              [formattedType]: currentEntity = {},
              [formattedType]: { allIds, byId } = {},
            } = {},
            meta: { [formattedType]: { pagination: { totalCount } = {} } = {} } = {},
          } = {},
        } = state;

        const newAllIds = allIds.filter((allId) => allId !== id);

        const { [id]: resourceToRemove, ...newById } = byId;

        const newTotal = resourceToRemove ? totalCount - 1 : totalCount;

        const newMeta = totalCount
          ? {
              ...currentSlice.meta,
              [formattedType]: {
                pagination: {
                  ...currentEntity.pagination,
                  totalCount: newTotal,
                },
              },
            }
          : {
              ...currentSlice.meta,
            };

        return {
          ...state,
          [slice]: {
            ...currentSlice,
            entities: {
              ...currentSlice.entities,
              [formattedType]: {
                allIds: newAllIds,
                byId: newById,
              },
            },
            meta: newMeta,
          },
        };
      }

      case RESOURCE_ERRORS_UPDATE: {
        const { id, inputErrors, slice, type } = payload;

        const formattedType = formatType(type);

        const {
          [slice]: currentSlice = {},
          [slice]: {
            entities: {
              [formattedType]: currentEntity = {},
              [formattedType]: { byId: { [id]: currentResource = {} } = {} } = {},
            } = {},
          } = {},
        } = state;

        return {
          ...state,
          [slice]: {
            ...currentSlice,
            entities: {
              ...currentSlice.entities,
              [formattedType]: {
                ...currentEntity,
                byId: {
                  ...currentEntity.byId,
                  [id]: {
                    ...currentResource,
                    errors: inputErrors,
                  },
                },
              },
            },
          },
        };
      }

      case LOGOUT_DONE: {
        const { login, notification, user } = state;

        return {
          login: {
            ...login,
            logoutDone: true,
          },
          notification,
          user,
        };
      }

      default:
        return state;
    }
  });

const rootReducer = (state, action) => {
  const intermediateState = appReducer(state, action);
  const finalState = crossSliceReducer(intermediateState, action);

  return finalState;
};

export default rootReducer;
