import React from 'react';
import { push } from 'connected-react-router';

import Bold from 'components/Bold';
import FontIcon from 'components/FontIcon';
import StatusFilter from 'components/StatusFilter';
import Table from 'components/Table';
import TableRow from 'components/Table/components/TableRow';
import TableCell from 'components/Table/components/TableCell';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';

import styles from '../../AdminEditorSuggestionSets.scss';

const STATUSES = [
  { label: 'All', value: null },
  { label: 'Complete', value: true },
  { label: 'Not Complete', value: false },
];

const SuggestionSets = ({
  dispatch,
  rainmakersAdmin: { id } = {},
  complete,
  employers: { byId: employersById = {} } = {},
  handleChangeState,
  loadMoreSuggestionSets,
  modal,
  requisitionOpenings: { byId: requisitionOpeningsById = {} } = {},
  showModal,
  suggestionSets: { byId = {}, allIds = [] },
  suggestionSetsLoading,
  suggestionSetsTotal,
}) => {
  const tableHeader = [
    {
      content: 'Requisition',
      headerClassName: styles.statusHeader,
    },
    {
      content: 'Employer',
      headerClassName: styles.statusHeader,
    },
    {
      content: 'Complete?',
      headerClassName: styles.statusHeader,
    },
  ];

  const makeCells = (suggestionSet = {}) => {
    const {
      attributes: { complete } = {},
      relationships: {
        employer: { data: employerIds = [] } = {},
        requisitionOpeningCopy: { data: requisitionOpeningIds = [] } = {},
      } = {},
    } = suggestionSet;

    const { attributes: { name: employerName } = {} } = employersById[employerIds[0]] || {};

    const { attributes: { externalName } = {} } =
      requisitionOpeningsById[requisitionOpeningIds[0]] || {};

    const completeContent = complete ? <FontIcon iconName="checkmark-circle" /> : '-';

    return [
      <TableCell flex center key={1}>
        {externalName}
      </TableCell>,
      <TableCell flex center key={2}>
        {employerName}
      </TableCell>,
      <TableCell flex center key={3}>
        {completeContent}
      </TableCell>,
    ];
  };

  const makeRows = (suggestionSet) => {
    const route = `/toolbox/admin/${id}/suggestion-sets/${suggestionSet.id}`;

    const onClick = !modal.originalUrl
      ? () => dispatch(push(route))
      : () =>
          showModal({
            parent: 'ToolboxAdminsPage',
            route,
            key: 'AdminEditorModal',
            originalUrl: modal.originalUrl,
          });

    const rowProps = {
      id: suggestionSet.id,
      key: suggestionSet.id,
      onClick,
    };

    return <TableRow {...rowProps}>{makeCells(suggestionSet)}</TableRow>;
  };

  const suggestionSets = allIds
    .filter((setId) => byId[setId].attributes.rainmakersAdminId === id)
    .map((id) => byId[id]);

  const tableContent = suggestionSets.filter((set) => !set.temporary).map(makeRows);

  const statusFilterProps = {
    statuses: STATUSES,
    value: complete,
    handleChangeStatus: handleChangeState,
  };

  const filtersContent = (
    <div className={styles.header}>
      <div className={styles.top}>
        <div className={styles.left}>
          <span className={styles.text}>
            <Bold>Suggestion Sets </Bold>with status:
          </span>
          <StatusFilter {...statusFilterProps} />
        </div>
      </div>
    </div>
  );

  const infiniteScrollProps = {
    loadMore: loadMoreSuggestionSets,
    hasMore: suggestionSetsTotal > allIds.length,
    loader: suggestionSetsLoading ? (
      <div key="infiniteScrollLoader" />
    ) : (
      <InfiniteScrollLoader key="infiniteScrollLoader" />
    ),
    useWindow: false,
  };

  const tableProps = {
    titleContent: filtersContent,
    tableContent,
    status: complete,
    header: tableHeader,
    infiniteScrollProps,
    tableName: 'adminSuggestionSets',
    isEmpty: !suggestionSetsLoading && allIds.length === 0,
    emptyTextString: 'No Suggestion Sets',
    isLoading: suggestionSetsLoading,
  };

  return <Table {...tableProps} />;
};

export default SuggestionSets;
