import React from 'react';
import classnames from 'classnames';

import styles from './ButtonGroup.scss';

const ButtonGroup = (props) => {
  const onSelect = (item) => {
    const { onChange, name } = props;

    const arg = name
      ? {
          ...item,
          filter: name,
        }
      : item.value;

    onChange(arg);
  };

  const { className, defaultValue, label, source, value, disabled } = props;

  const [currentValue] = source.filter((item) => `${item.value}` === `${value}`);

  const currentItem = currentValue || { label: defaultValue, value: null };

  const makeOptions = (item, id) => {
    const { label, value } = item;
    const isFirst = id === 0;
    const isLast = id === source.length - 1;
    const buttonClasses = classnames(styles.Button, {
      [styles.first]: isFirst,
      [styles.last]: isLast,
      [styles.activeOption]: `${currentItem.value}` === `${value}`,
      [styles.disabled]: disabled,
    });

    const onClickHandler = disabled ? null : () => onSelect(item);

    const optionProps = {
      className: buttonClasses,
      key: value,
      onClick: onClickHandler,
      value,
    };

    const content = <div {...optionProps}>{label} </div>;

    return content;
  };

  const content = source.length > 0 ? source.map(makeOptions) : null;

  const labelField = label ? <span className={styles.Label}>{label}</span> : null;

  const buttonGroupProps = {
    className: classnames(styles.ButtonGroup, className),
  };

  return (
    <div {...buttonGroupProps}>
      {labelField}
      <div className={styles.ButtonsContainer}>{content}</div>
    </div>
  );
};

export default ButtonGroup;
