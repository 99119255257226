import { call, select, put, take } from 'redux-saga/effects';

import { entitySelectorFactory } from 'reduxStore/selectorFactories';

import { handleError } from 'utils/common';
import { trackEvent } from 'utils/analytics';
import { segmentUnhideCandidateEmployee } from 'segment/eventNames';

import { deleteEntity } from 'api/apiEntity';

import { SEARCH_CANDIDATE_UNDO_HIDE_STARTED } from '../actions';

import { resultsUpdate } from '../creators';

const trackUnhideCandidate = (candidateId, blockType) => {
  const event = segmentUnhideCandidateEmployee;

  const properties = {
    blockType,
    candidateId,
  };

  const eventObject = { event, properties };

  trackEvent(eventObject);
};

const sagaSearchCandidateUndoHideStarted = function* sagaSearchCandidateUndoHideStarted() {
  while (true) {
    const {
      payload: { candidateCompanyBlockId, candidateId },
    } = yield take(SEARCH_CANDIDATE_UNDO_HIDE_STARTED);

    const { byId, allIds } = yield select(entitySelectorFactory('employerSearch', 'candidate'));

    try {
      const newById = {
        ...byId,
        [candidateId]: {
          ...byId[candidateId],
          attributes: {
            ...byId[candidateId].attributes,
            hide: false,
          },
        },
      };

      yield put(resultsUpdate({ type: 'candidate', entity: { allIds, byId: newById } }));

      const args = {
        id: candidateCompanyBlockId,
        type: 'candidate_company_blocks',
      };

      yield call(deleteEntity, args);

      yield call(trackUnhideCandidate, candidateId, 'search_pass');
    } catch (error) {
      handleError(error);
      yield put(resultsUpdate({ type: 'candidate', entity: { allIds, byId } }));
    }
  }
};

export default sagaSearchCandidateUndoHideStarted;
