import React from 'react';

import Content from 'components/Content';
import FormattedMoney from 'components/FormattedMoney';

import Ote from 'pages/AdminPortal/ToolboxRequisitionEditorPage/components/RequisitionOpening/components/Ote';

import styles from './RoleEditor.scss';

const RoleEditor = ({ firstName, oteCents, oteMaxCents, oteMinCents, handleInputChange }) => {
  const oteProps = {
    requisitionOpening: {
      attributes: {
        oteMaxCents,
        oteMinCents,
      },
    },
    handleInputChange,
  };

  const desiredOTEContent = oteCents ? (
    <Content className={styles.subTitle}>
      {firstName} is looking for <FormattedMoney amount={oteCents} /> OTE.
    </Content>
  ) : null;

  return (
    <Content className={styles.Attribute}>
      {desiredOTEContent}
      <Ote {...oteProps} />
    </Content>
  );
};

export default RoleEditor;
