import { call, put, take } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { promisePostTemplate } from '../../promises';

import { TEMPLATE_SAVE_STARTED } from '../actions';

import { templateSaveDone } from '../creators';

const sagaTemplateSaveStarted = function* sagaTemplateSaveStarted() {
  while (true) {
    const { payload } = yield take(TEMPLATE_SAVE_STARTED);

    try {
      const {
        data: { message_template: template },
      } = yield call(promisePostTemplate, payload);

      yield put(templateSaveDone({ template }));
    } catch (error) {
      handleError(error);
    }
  }
};

export default sagaTemplateSaveStarted;
