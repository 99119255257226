import React from 'react';

import { ComponentErrorBoundaryHOC } from 'containers/HOC';

import Content from 'components/Content';
import Block from 'components/Block';
import Bolder from 'components/Bolder';

import EducationBlob from './components/EducationBlob';

import styles from './ProfileEducation.scss';

export const ProfileEducation = ({ candidateEducations = [], recentGrad = false }) => {
  const actualEducations = candidateEducations.filter((edu) => edu.id);

  if (actualEducations.length === 0) {
    return null;
  }

  return (
    <Block
      addWhiteBG={true}
      title={
        <Content className={styles.educationTitle}>
          <Bolder>EDUCATION</Bolder>
        </Content>
      }
      className={styles.marginBottom}
      secondaryTitle={true}
      data-testid="ProfileEducation"
    >
      <Content className={styles.profileEducationContainer}>
        {actualEducations.map((education) => (
          <EducationBlob key={education.id} education={education} recentGrad={recentGrad} />
        ))}
      </Content>
    </Block>
  );
};

export default ComponentErrorBoundaryHOC()(ProfileEducation);
