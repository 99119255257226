import { handleError } from 'utils/common';
import { patchNestedEntity } from 'api/apiEntity';

const promisePatchBatchRequest = ({ candidateId, state, dismissed }) => {
  const onFulfilled = ({ data: { batch_request: batchRequest } }) => batchRequest;

  const onFailed = (error) => {
    handleError(error);

    return [];
  };

  const args = {
    data: {
      type: 'batch_request',
      attributes: {
        state,
        dismissed,
      },
    },
    id: candidateId,
    type: 'candidates',
    nestedType: 'batch_request',
  };

  const promise = patchNestedEntity(args).then(onFulfilled).catch(onFailed);

  return promise;
};

export default promisePatchBatchRequest;
