import {
  AtlantaContent,
  ChicagoContent,
  DenverContent,
  LosAngelesContent,
  NYCContent,
  PhoenixContent,
  RaleighContent,
  SanFranciscoContent,
} from './components';

export const RECRUITER_PAGE_ATLANTA = 'atlanta';
export const RECRUITER_PAGE_CHICAGO = 'chicago';
export const RECRUITER_PAGE_DENVER = 'denver';
export const RECRUITER_PAGE_LA = 'los-angeles';
export const RECRUITER_PAGE_NYC = 'new-york-city';
export const RECRUITER_PAGE_PHOENIX = 'phoenix';
export const RECRUITER_PAGE_RALEIGH = 'raleigh';
export const RECRUITER_PAGE_SF = 'san-francisco';

export default {
  [RECRUITER_PAGE_ATLANTA]: {
    title: 'Get the Top Sales Talent Your Business Needs in Atlanta with Rainmakers',
    description:
      'The competition for the best sales talent in the tech industry is fierce in Atlanta. Rainmakers, a sales recruiting firm, can help you connect with the top-performing salespeople you need.',
    url: `https://${process.env.HOST}/recruiting/atlanta/`,
    localUrl: '/recruiting/atlanta',
    header: 'Sales Recruiting Agency in Atlanta',
    content: AtlantaContent,
  },
  [RECRUITER_PAGE_CHICAGO]: {
    title: 'Sales Recruiting Agency in Chicago, IL',
    description:
      'Rainmakers, a recruiting agency in Chicago, specializes in connecting businesses with top sales talent to help them succeed in a competitive market by streamlining the hiring process and providing access to a database of vetted sales professionals.',
    url: `https://${process.env.HOST}/recruiting/chicago/`,
    localUrl: '/recruiting/chicago',
    header: 'Unlocking Top Talent in Chicago with Rainmakers',
    content: ChicagoContent,
  },
  [RECRUITER_PAGE_DENVER]: {
    title: "Denver's Leading Sales Recruitment Agency: Rainmakers",
    description:
      'Our Denver sales recruiters specialize in connecting businesses with top sales professionals to build high-performing sales teams. With our expertise, extensive talent pool, and industry-specific knowledge, we streamline the hiring process, ensuring faster recruitment.',
    url: `https://${process.env.HOST}/recruiting/denver/`,
    localUrl: '/recruiting/denver',
    header: 'Finding Top Sales Talent in Denver',
    content: DenverContent,
  },
  [RECRUITER_PAGE_LA]: {
    title: 'Sales Recruitment Services in Los Angeles, CA',
    description:
      'Rainmakers is a specialized sales recruiting firm in Los Angeles that uses a comprehensive database of top industry talent to connect companies, particularly in the tech sector, with high-quality sales professionals.',
    url: `https://${process.env.HOST}/recruiting/los-angeles/`,
    localUrl: '/recruiting/los-angeles',
    header: 'Innovative Recruitment Agency in LA',
    content: LosAngelesContent,
  },
  [RECRUITER_PAGE_NYC]: {
    title: 'Sales Recruitment Agency for New York Businesses',
    description:
      'Hiring sales talent in NYC is challenging. Rainmakers offers recruitment services that streamline the process, filter candidates, and optimize business hiring speed.',
    url: `https://${process.env.HOST}/recruiting/new-york-city/`,
    localUrl: '/recruiting/new-york-city',
    header: 'Sales Recruitment Agency in NYC',
    content: NYCContent,
  },
  [RECRUITER_PAGE_PHOENIX]: {
    title: 'Top Sales Recruiting Agency in Phoenix, AZ',
    description:
      'Rainmakers is a sales recruitment service that connects high-performance sales staff with tech companies through our exclusive platform, offering a proven system and a unique recruitment process to save time and find quality candidates in the booming Phoenix job market.',
    url: `https://${process.env.HOST}/recruiting/phoenix/`,
    localUrl: '/recruiting/phoenix',
    header: 'Sales Recruitment Services in Phoenix',
    content: PhoenixContent,
  },
  [RECRUITER_PAGE_RALEIGH]: {
    title: 'Sales Recruitment Specialists in Raleigh, NC ',
    description:
      "As Raleigh's leading sales recruiters, we're committed to unleashing your business's sales potential with the right talent. Explore our services today and build the sales team of your dreams!",
    url: `https://${process.env.HOST}/recruiting/raleigh/`,
    localUrl: '/recruiting/raleigh',
    header: 'Leading Raleigh Sales Recruiters',
    content: RaleighContent,
  },
  [RECRUITER_PAGE_SF]: {
    title: 'Sales Hiring and Recruitment Consultancy in San Francisco',
    description:
      'Rainmakers, a premier sales recruitment agency in San Francisco, efficiently matches high-achieving sales talent with qualified businesses, expediting the hiring process and fostering business growth.',
    url: `https://${process.env.HOST}/recruiting/san-francisco/`,
    localUrl: '/recruiting/san-francisco',
    header: 'Revolutionizing Sales Recruitment in San Francisco',
    content: SanFranciscoContent,
  },
};
