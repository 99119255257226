import React, { useState, useEffect, useRef, useContext } from 'react';
import classnames from 'classnames';

import { handleError } from 'utils/common';

import { ComponentErrorBoundaryHOC } from 'containers/HOC';

import Button from 'components/Button';
import FontIcon from 'components/FontIcon';
import { NotesButton } from 'components/NotesButton';

import { NotesContext } from 'pages/AdminPortal/ToolboxCandidateEditorPage/NotesContext';

import Dashboard from './components/DashboardButtons';
import Featured from './components/FeaturedButtons';
import Match from './components/MatchButtons';
import Suggestion from './components/SuggestionButtons';

import styles from './ProfileSidebar.scss';

export const ProfileSidebar = ({
  bookmark,
  candidateId,
  candidateSuggestionId,
  doneLoading,
  firstName,
  lastName,
  employeePatchStarted,
  handleMakeOffer,
  handlePass,
  handleRequestIntroClick,
  handlePassFeaturedCandidate,
  handleHideSearchCandidate,
  handleUndoHideSearchCandidate,
  handleUndoPassFeaturedCandidate,
  handleBookmarkCandidate,
  handleRemoveBookmarkCandidate,
  hideCandidatePopupSeen,
  hide = false,
  hideSearch = false,
  hired = false,
  isAdmin,
  isCandidateLoading,
  isEmployee,
  isInsideModal,
  loggedInEmployerId,
  needIntroButton,
  needReload,
  parentView,
  passed = false,
  patchResource,
  requisitionCandidates,
  requestSent,
  suggested,
  tableStatus,
}) => {
  const _mounted = useRef(false);
  const copyThisText = useRef();
  const [linkCopied, setLinkCopied] = useState(false);
  const [showLink, setShowLink] = useState(null);

  const { enableNotes, showNotes } = useContext(NotesContext);

  useEffect(() => {
    _mounted.current = true;

    return () => {
      _mounted.current = false;
    };
  }, []);

  const handleCopy = (path) => {
    const host = window.location.origin;

    const textToCopy = `${host}${path}`;

    copyThisText.current.value = textToCopy;

    copyThisText.current.select();

    try {
      document.execCommand('copy');

      copyThisText.current.blur();

      setLinkCopied(true);

      setTimeout(() => {
        if (_mounted.current) {
          setLinkCopied(false);
        }
      }, 4000);
    } catch (error) {
      handleError(error);
      if (_mounted.current) {
        setShowLink(textToCopy);
      }
    }
  };

  const isDashboard = parentView === 'dashboard';
  const isMatch = parentView === 'matches';
  const isFeatured =
    parentView === 'featured' ||
    parentView === 'locked_link' ||
    parentView === 'bookmarked' ||
    parentView === 'archived';
  const isSuggestions = parentView === 'suggestions';

  const featuredButtonsProps = {
    bookmark,
    candidateId,
    doneLoading,
    firstName,
    lastName,
    employeePatchStarted,
    handleBookmarkCandidate,
    handleHideSearchCandidate,
    handleRemoveBookmarkCandidate,
    handleRequestIntroClick,
    handleUndoHideSearchCandidate,
    hide: hideSearch,
    hideCandidatePopupSeen,
    isAdmin,
    isCandidateLoading,
    isEmployee,
    loggedInEmployerId,
    needIntroButton,
    parentView,
    requestSent,
    needReload,
  };

  const matchButtonsProps = {
    requestSent,
    candidateId,
    firstName,
    lastName,
    handleRequestIntroClick,
    handlePassFeaturedCandidate,
    handleUndoPassFeaturedCandidate,
    isAdmin,
    isCandidateLoading,
    isEmployee,
    parentView,
    hide,
    loggedInEmployerId,
    needReload,
  };

  const dashboardButtonsProps = {
    candidateId,
    firstName,
    handleMakeOffer,
    handlePass,
    hired,
    isCandidateLoading,
    lastName,
    passed,
    requisitionCandidates,
    tableStatus,
    needReload,
  };

  const suggestionButtonsProps = {
    suggested,
    candidateSuggestionId,
    patchResource,
  };

  const featuredButtonsContent = isFeatured && <Featured {...featuredButtonsProps} />;
  const matchButtonsContent = isMatch && <Match {...matchButtonsProps} />;
  const dashboardButtonsContent = isDashboard && <Dashboard {...dashboardButtonsProps} />;
  const suggestionsButtonsContent = isSuggestions && <Suggestion {...suggestionButtonsProps} />;

  return (
    <div className={styles.profileHeaderContainer} data-testid="ProfileSidebar">
      {featuredButtonsContent ||
        matchButtonsContent ||
        dashboardButtonsContent ||
        suggestionsButtonsContent}
      <div
        className={classnames(styles.profileCopyLinkContainer, {
          [styles.adminCopyLinkContainer]: isAdmin,
        })}
      >
        <Button
          tertiary={true}
          active={linkCopied}
          className={classnames(styles.linkCopiedText, {
            [styles.linkCopiedTextActive]: linkCopied,
          })}
          onClick={() => handleCopy(`/candidates/${candidateId}/profile/`)}
        >
          {linkCopied ? (
            <div className={styles.linkCopied}>
              <FontIcon iconName="checkmark-circle" className={styles.copiedLinkIcon} />
              Link copied!
            </div>
          ) : (
            <div key="regularShare" className={styles.linkNotCopied}>
              <FontIcon iconName="share" className={styles.copyLinkIcon} />
              Share Profile
            </div>
          )}
        </Button>
        {showLink !== null && (
          <div>
            <div key="linkMessage" className={styles.showLinkError}>
              Browser not supported, please manually copy the link below:
            </div>
            <div key="linkButton" className={styles.showLink}>
              {showLink}
            </div>
          </div>
        )}
      </div>
      <input ref={copyThisText} className={styles.hidden} />
      {enableNotes && !showNotes && !isInsideModal && <NotesButton />}
    </div>
  );
};

export default ComponentErrorBoundaryHOC()(ProfileSidebar);
