import React from 'react';
import Slider from 'react-rangeslider';

import Input from 'components/Input';
import Label from 'components/Label';

import styles from '../ReqRequirement.scss';

const SalesExperienceMonths = ({
  disabled,
  handleExperienceInputChange,
  handleInputChange,
  requisitionRequirement: {
    attributes: {
      salesExperienceMonthsMin,
      salesExperienceMonthsMax,
      salesExperienceMonthsWt,
    } = {},
  } = {},
}) => {
  const validYearExp = (number) => (number !== null && number !== '') || number === 0;

  const salesExpYearsMin =
    salesExperienceMonthsMin || salesExperienceMonthsMin === 0
      ? Math.floor(salesExperienceMonthsMin / 12)
      : null;
  const salesExpYearsMax =
    salesExperienceMonthsMax || salesExperienceMonthsMax === 0
      ? Math.floor(salesExperienceMonthsMax / 12)
      : null;

  const salesExpYearsMinInputProps = {
    handleInputChange: (event) =>
      handleExperienceInputChange({
        ...event,
        target: {
          years: event.target.value,
          months: 0,
          name: 'salesExperienceMonthsMin',
        },
      }),
    name: 'salesExperienceMonthsMin',
    value: validYearExp(salesExpYearsMin) ? salesExpYearsMin : '',
    placeholder: '',
    size: 'full',
    type: 'text',
    min: '0',
    max: '99',
    className: styles.inputContainer,
    disabled,
  };

  const salesExpYearsMaxInputProps = {
    handleInputChange: (event) =>
      handleExperienceInputChange({
        ...event,
        target: {
          years: event.target.value,
          months: 0,
          name: 'salesExperienceMonthsMax',
        },
      }),
    name: 'salesExperienceMonthsMax',
    value: validYearExp(salesExpYearsMax) ? salesExpYearsMax : '',
    placeholder: '',
    size: 'full',
    type: 'text',
    min: '0',
    max: '99',
    className: styles.inputContainer,
    disabled,
  };

  const salesExpWtProps = {
    disabled,
    className: styles.rangeSlider,
    min: 0,
    max: 10,
    step: 5,
    value: salesExperienceMonthsWt || 0,
    onChange: disabled
      ? () => null
      : (value) =>
          handleInputChange({
            target: {
              value,
              name: 'salesExperienceMonthsWt',
            },
          }),
    tooltip: false,
    labels: {
      0: '0',
      5: '5',
      10: '10',
    },
  };

  return (
    <div className={styles.rangeInputs}>
      <div className={styles.column}>
        <Label className={styles.label}>How many years of sales experience?</Label>
        <div className={styles.yearMonthContainer}>
          <div className={styles.yearContainer}>
            <Input {...salesExpYearsMinInputProps} />
            <Label> - </Label>
            <Input {...salesExpYearsMaxInputProps} />
            <Label>years</Label>
          </div>
        </div>
      </div>
      <div className={styles.column}>
        <Label className={styles.labelStyle}>How much do you value this?</Label>
        <Slider {...salesExpWtProps} />
      </div>
    </div>
  );
};

export default SalesExperienceMonths;
