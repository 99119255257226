import { handleActions } from 'redux-actions';

import { SET_NOTIFICATION, RESET_NOTIFICATION } from 'shared/store/app/actions';

const initialState = {
  title: null,
  text: null,
  confirmText: null,
  actionButton: null,
};

const handleSetNotification = (state = initialState, action) => {
  const {
    payload: { title, text, confirmText, actionButton },
  } = action;

  return {
    ...state,
    title,
    text,
    confirmText,
    actionButton,
  };
};

const handleResetNotification = () => initialState;

const actionHandlers = {
  [RESET_NOTIFICATION]: handleResetNotification,
  [SET_NOTIFICATION]: handleSetNotification,
};

const reduceNotification = handleActions(actionHandlers, initialState);

export default reduceNotification;
