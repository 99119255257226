import { call, put, select, cancel, take, fork } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { handleError } from 'utils/common';

import { patchNestedEntity, postNestedEntity } from 'api/apiEntity';

import { selectCandidate } from '../selectors';

import { WORK_HISTORY_PATCH_STARTED } from '../actions';

import {
  workHistoryPatchDone,
  candidateSaveStarted,
  candidateSaveDone,
  candidateSaveError,
} from '../creators';

const patchWorkHistory = function* patchWorkHistory(action) {
  const {
    payload: { workHistoryIdx, workHistoryId, newValue, name },
  } = action;

  const { workHistories, id: candidateId } = yield select(selectCandidate);

  const newWorkHistories = workHistoryId
    ? workHistories.map((wh) => {
        if (wh.id === workHistoryId) {
          return {
            ...wh,
            [name]: newValue,
          };
        }
        return wh;
      })
    : workHistories.map((wh, idx) => {
        if (idx === workHistoryIdx) {
          return {
            ...wh,
            [name]: newValue,
          };
        }
        return wh;
      });

  yield put(workHistoryPatchDone({ newWorkHistories }));
  yield put(candidateSaveStarted());

  yield call(delay, 750);

  const { workHistories: updatedWorkHistories } = yield select(selectCandidate);

  const workHistoryObject = workHistoryId
    ? updatedWorkHistories.find((wh) => wh.id === workHistoryId)
    : updatedWorkHistories[workHistoryIdx];

  if (
    workHistoryObject.id === undefined &&
    workHistoryObject.companyId !== undefined &&
    workHistoryObject.position !== undefined &&
    workHistoryObject.startDate !== '' &&
    workHistoryObject.endDate !== ''
  ) {
    try {
      const args = {
        data: {
          type: 'work_history',
          attributes: {
            ...workHistoryObject,
            candidateId,
          },
        },
        nestedType: 'work_histories',
        type: 'candidates',
        candidateId,
        config: {
          params: {
            camelizeIt: true,
          },
        },
      };

      const {
        data: { workHistory: newWorkHistory },
      } = yield call(postNestedEntity, args);

      const newestWorkHistories = updatedWorkHistories.map((wh, idx) => {
        if (idx === workHistoryIdx) {
          return newWorkHistory;
        }
        return wh;
      });

      yield put(workHistoryPatchDone({ newWorkHistories: newestWorkHistories }));
      yield put(candidateSaveDone());
    } catch (error) {
      handleError(error);
      yield put(candidateSaveError(error));
    }
  }

  if (workHistoryObject.id) {
    try {
      const args = {
        data: {
          type: 'work_history',
          id: workHistoryObject.id,
          attributes: {
            [name]: newValue,
          },
        },
        nestedId: workHistoryObject.id,
        nestedType: 'work_histories',
        id: candidateId,
        type: 'candidates',
        config: {
          params: {
            camelizeIt: true,
          },
        },
      };

      const {
        data: { workHistory: newWorkHistory },
      } = yield call(patchNestedEntity, args);

      const newerWorkHistories = workHistories.map((wh) => {
        if (wh.id === newWorkHistory.id) {
          return {
            ...wh,
            [name]: newWorkHistory[name],
          };
        }
        return wh;
      });

      if (name !== 'soldToCompanies') {
        yield put(workHistoryPatchDone({ newWorkHistories: newerWorkHistories }));
      }
      yield put(candidateSaveDone());
    } catch (error) {
      handleError(error);
      yield put(candidateSaveError(error));
    }
  } else {
    yield put(candidateSaveDone());
  }
};

const sagaWorkHistoryPatchStarted = function* sagaWorkHistoryPatchStarted() {
  let lastTask;
  let lastAction;
  while (true) {
    const action = yield take(WORK_HISTORY_PATCH_STARTED);
    if (lastTask && lastAction.payload.name === action.payload.name) {
      yield cancel(lastTask);
    }
    lastAction = action;
    lastTask = yield fork(patchWorkHistory, action);
  }
};

export default sagaWorkHistoryPatchStarted;
