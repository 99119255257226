import { get } from '../api';

const route = '/candidates';

const getCandidatePublicProfile = (candidateId) =>
  get(`${route}/${candidateId}/public_profile`, {
    params: {
      camelizeIt: true,
    },
  });

export default getCandidatePublicProfile;
