import { createSelector } from 'reselect';
import { entitySelectorFactory } from 'reduxStore/selectorFactories';

const selectEmployee = createSelector(
  entitySelectorFactory('employerEditor', 'employee'),
  (state, props) => {
    const { match: { params: { id } = {} } = {} } = props || {};

    return id;
  },
  (employees, id) => {
    const { byId: { [id]: employee = {} } = {} } = employees;

    return employee;
  }
);

export default selectEmployee;
