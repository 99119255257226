import { handleActions } from 'redux-actions';

const initialState = {
  entities: {
    employer: {
      byId: {},
      allIds: [],
    },
  },
  meta: {
    employer: {
      pagination: {},
    },
  },
};
const reduceToolboxEmployers = handleActions({}, initialState);

export default reduceToolboxEmployers;
