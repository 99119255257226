import React, { useState, useEffect, useRef, useCallback } from 'react';
import classnames from 'classnames';
import FontIcon from 'components/FontIcon';

import { useOnClickOutside } from 'hooks';

import styles from './DropdownContent.scss';

const DropdownContent = ({
  actionButton,
  activeIcon,
  children,
  className,
  defaultIcon,
  disabled,
  dropLeft,
  dropRight,
  label,
  closeDropdown,
  onClick: onClickExternal,
}) => {
  const [active, setActive] = useState(false);
  const ref = useRef();

  useEffect(() => {
    if (closeDropdown === true) {
      setActive(false);
    }
  }, [closeDropdown]);

  useOnClickOutside(
    ref,
    useCallback(() => setActive(false), [])
  );

  const onClick = () => {
    if (!active && onClickExternal) {
      onClickExternal();
    }

    setActive((active) => !active);
  };

  const onActionClick = () => setActive(false);

  const onChildClick = (event) => event.stopPropagation();

  const classes = classnames(
    styles.Dropdown,
    {
      [styles.active]: active,
      [styles.disabled]: disabled,
    },
    className
  );

  const optionsContainerClasses = classnames(
    'ignore-react-onclickoutside',
    styles.OptionsContainer,
    {
      [styles.dropLeft]: dropLeft,
      [styles.dropRight]: dropRight,
    }
  );

  const labelClasses = classnames(styles.inPlaceDefaultLabel, {
    [styles.labelActive]: active,
  });

  const defaultLabel = <div className={labelClasses}>{label}</div>;

  const defaultIconContent = defaultIcon ? <FontIcon iconName={defaultIcon} /> : null;

  const activeIconContent = activeIcon ? <FontIcon iconName={activeIcon} /> : defaultIconContent;

  const iconContent = active ? activeIconContent : defaultIconContent;

  const actionSpan = actionButton ? (
    <span onClick={disabled ? () => null : onActionClick} className={styles.actionButton}>
      {actionButton}
    </span>
  ) : null;

  const content = active ? (
    <div>
      {children}
      {actionSpan}
    </div>
  ) : null;

  return (
    <div className={classes} onClick={disabled ? () => null : onClick} ref={ref}>
      {defaultLabel}
      {iconContent}
      <div className={optionsContainerClasses} onClick={onChildClick}>
        {content}
      </div>
    </div>
  );
};

export default DropdownContent;
