import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import queryString from 'query-string';
import dayjs from 'dayjs';

import ConnectContainer from 'containers/ConnectContainer';
import { CandidateAuthHOC } from 'containers/HOC';

import ReadyContainer from 'connectors/ReadyContainer';

import { promisePostEducationOrgs } from 'pages/AdminPortal/ToolboxCandidateEditorPage/promises';
import { promiseGetFullCandidate } from 'pages/EmployeePortal/CandidateProfilePages/promises';

import { handleError } from 'utils/common';
import { toggleArray } from 'utils/formUtils';

import Block from 'components/Block';
import Button from 'components/Button';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import Sidebar from 'components/Sidebar';
import TableOverlay from 'components/TableOverlay';

import duck from '../redux';
import EducationInputs from './components/EducationInputs';
import EducationDisplay from './components/EducationDisplay';

import styles from './EditEducation.scss';

@CandidateAuthHOC()
class EditEducation extends Component {
  constructor(props) {
    super(props);

    const {
      user: { currentProfile: { candidateEducations = [] } = {} } = {},
      location: { search },
    } = props;

    const { model = null, model_index: modelIndex = null } = queryString.parse(search);

    const newCandidateEducations = candidateEducations ? candidateEducations.slice() : [];

    if (model === 'candidate_education' && modelIndex && candidateEducations[modelIndex]) {
      newCandidateEducations[modelIndex] = {
        ...newCandidateEducations[modelIndex],
        isEditing: true,
      };
    }

    this.state = {
      isPostingEducation: false,
      candidateEducations: newCandidateEducations,
      origCandidateEducations: newCandidateEducations,
      obeyParams: true,
    };
  }

  componentDidMount() {
    const {
      actions: { pickerOptionsLoadStarted },
      candidate,
    } = this.props;

    const { id: candidateId } = candidate || {};

    promiseGetFullCandidate({ candidateId }).then(this.onCandidateSuccess).catch(handleError);

    pickerOptionsLoadStarted();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      user: { currentProfile: { candidateEducations = [] } = {} } = {},
      location: { search },
    } = nextProps;

    const { model = null, model_index: modelIndex = null } = queryString.parse(search);

    const { obeyParams } = this.state;

    const newCandidateEducations = candidateEducations ? candidateEducations.slice() : [];

    if (
      model === 'candidate_education' &&
      modelIndex &&
      candidateEducations[modelIndex] &&
      obeyParams
    ) {
      newCandidateEducations[modelIndex] = {
        ...newCandidateEducations[modelIndex],
        isEditing: true,
      };
    }

    this.setState({
      candidateEducations: newCandidateEducations,
      origCandidateEducations: newCandidateEducations,
    });

    const firstMovedEduIdx = candidateEducations.findIndex((edu) => edu.moved);
    if (firstMovedEduIdx >= 0) {
      if (this[`candidateEducation${firstMovedEduIdx}`]) {
        this[`candidateEducation${firstMovedEduIdx}`].scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
      }
      setTimeout(() => {
        const { candidateEducations } = this.state;
        const newCandidateEducations = candidateEducations.map((edu) => ({
          ...edu,
          moved: false,
        }));

        this.setState({
          candidateEducations: newCandidateEducations,
          origCandidateEducations: newCandidateEducations,
        });
      }, 500);
    }
  }

  componentWillUnmount() {
    this.setState({
      candidateEducations: [],
      origCandidateEducations: [],
    });
  }

  onCandidateSuccess = (candidateProfile) => {
    const {
      actions: { candidateProfilePatchDone },
    } = this.props;

    candidateProfilePatchDone({ candidateProfile });
  };

  setEduRef = (ref, idx) => {
    this[`candidateEducation${idx}`] = ref;
  };

  addEducation = () => {
    const { candidateEducations } = this.state;

    const newEducations = candidateEducations.slice();

    const newEducation = {
      startedAt: '',
      finishedAt: '',
      degree: '',
      description: '',
      educationOrganization: {
        id: '',
        picture: {
          original: '',
          thumb: '',
          small: '',
          medium: '',
          large: '',
          xlarge: '',
          xxlarge: '',
        },
      },
      extracurricularActivities: [],
      isEditing: true,
    };

    newEducations.unshift(newEducation);

    this.setState({
      candidateEducations: newEducations,
      origCandidateEducations: newEducations,
    });
  };

  handleEducationToggleArray = ({ event, idx: educationIdx }) => {
    const {
      target: { value, name },
    } = event;

    const { candidateEducations: educations } = this.state;

    const array = educations[educationIdx][name].slice();

    const newArray = toggleArray({ array, value });

    const newEducations = educations.slice();

    newEducations[educationIdx][name] = newArray;

    this.setState({ candidateEducations: newEducations });
  };

  handleEducationOrganizationChange = ({ event, idx: educationIdx }) => {
    const {
      target: { value: newValue },
    } = event;

    const { candidateEducations: educations } = this.state;

    const newEducations = educations.slice();

    newEducations[educationIdx].educationOrganization = {
      ...newEducations[educationIdx].educationOrganization,
      name: newValue.name,
      id: newValue.id,
    };

    newEducations[educationIdx].educationOrganizationId = newValue.id;

    this.setState({ candidateEducations: newEducations });
  };

  handleEducationOrganizationBlur = ({ event, idx: educationIdx }) => {
    const {
      target: { value: newValue },
    } = event;

    const { candidateEducations: educations } = this.state;

    const { educationOrganization: origOrg = {} } = educations[educationIdx];

    if (origOrg.name !== newValue) {
      const onFulfilled = ({ educationOrg }) => {
        const newEducations = educations.slice();

        newEducations[educationIdx].educationOrganization = {
          ...newEducations[educationIdx].educationOrganization,
          name: educationOrg.name,
          id: educationOrg.id,
        };

        newEducations[educationIdx].educationOrganizationId = educationOrg.id;

        this.setState({ candidateEducations: newEducations });
      };

      const data = {
        type: 'education_organization',
        attributes: {
          name: newValue.trim(),
        },
      };

      promisePostEducationOrgs({ data }).then(onFulfilled).catch(handleError);
    }
  };

  handleEducationDateInputChange = ({ event, idx: educationIdx }) => {
    const {
      target: { name, value: newValue },
    } = event;

    const { candidateEducations: educations } = this.state;

    const { startedAt: startDate, finishedAt: endDate } = educations[educationIdx];

    const newEducation = {
      startedAt: startDate,
      finishedAt: endDate,
    };

    const startDateObject = startDate ? dayjs(startDate, 'YYYY-MM-DD').toDate() : new Date();
    const endDateObject = endDate ? dayjs(endDate, 'YYYY-MM-DD').toDate() : new Date();

    if (name === 'startDateYear') {
      startDateObject.setFullYear(newValue);
      newEducation.startedAt = startDateObject.toISOString().substring(0, 10);
    }

    if (name === 'endDateYear') {
      endDateObject.setFullYear(newValue);
      newEducation.finishedAt = endDateObject.toISOString().substring(0, 10);
    }

    const newEducations = educations.map((edu, idx) => {
      if (idx === educationIdx) {
        return {
          ...edu,
          ...newEducation,
        };
      }
      return edu;
    });

    this.setState({ candidateEducations: newEducations });
  };

  handleEducationInputChange = ({ event, idx: educationIdx }) => {
    const {
      target: { name, value: newValue },
    } = event;

    const { candidateEducations: educations } = this.state;

    const newEducations = educations.slice();

    newEducations[educationIdx][name] = newValue;

    this.setState({ candidateEducations: newEducations });
  };

  handleCurrentEducationInputChange = ({ event, idx: educationIdx }) => {
    const {
      target: { checked },
    } = event;

    const { candidateEducations: educations } = this.state;

    const newEducations = educations.slice();

    newEducations[educationIdx].finishedAt = checked
      ? null
      : new Date().toISOString().substring(0, 10);

    this.setState({ candidateEducations: newEducations });
  };

  handleEducationDelete = ({ idx }) => {
    const {
      actions: { educationDeleteStarted },
    } = this.props;

    const { candidateEducations } = this.state;

    const newEducations = candidateEducations.slice();

    newEducations.splice(idx, 1);

    this.setState({ candidateEducations: newEducations });

    if (candidateEducations[idx].id) {
      educationDeleteStarted({ educationId: candidateEducations[idx].id, newEducations });
    }
  };

  handleCancelEditingEdu = (idx) => {
    const { candidateEducations, origCandidateEducations } = this.state;

    const origEducation = origCandidateEducations[idx];

    const newCandidateEducations = candidateEducations.slice();

    if (origEducation && origEducation.id) {
      origEducation.isEditing = false;
      newCandidateEducations.splice(idx, 1, origEducation);
      this.setState({
        candidateEducations: newCandidateEducations,
      });
    } else {
      this.handleEducationDelete({ idx });
    }
  };

  handleToggleEditingEdu = (idx) => {
    const { candidateEducations } = this.state;

    if (candidateEducations[idx].isEditing) {
      setTimeout(() => this.handleReorderCandidateEducations({ idx }), 250);
    } else {
      const newCandidateEducations = candidateEducations.map((edu, index) => {
        if (index === idx) {
          return {
            ...edu,
            isEditing: true,
          };
        }
        return edu;
      });

      const origCandidateEducation = candidateEducations[idx];
      const newOrigCandidateEducations = candidateEducations.slice();
      newOrigCandidateEducations.splice(idx, 1, origCandidateEducation);

      this.setState({
        candidateEducations: newCandidateEducations,
        origCandidateEducations: newOrigCandidateEducations,
      });
    }
  };

  handleReorderCandidateEducations = ({ idx }) => {
    const {
      actions: { reorderEducationStarted },
    } = this.props;

    const {
      isPostingEducation,
      reorderAttempts,
      candidateEducations: origCandidateEducations,
      workHistories: origWorkHistories,
    } = this.state;

    if (isPostingEducation && reorderAttempts < 4) {
      setTimeout(() => {
        this.handleReorderCandidateEducations({ idx });
        this.setState({ reorderAttempts: reorderAttempts + 1, obeyParams: false });
      }, 500);
    } else {
      this.setState({ reorderAttempts: 0, obeyParams: false });
      reorderEducationStarted({
        origCandidateEducations,
        origWorkHistories,
        idx,
      });
    }
  };

  makeEducationInputs = (education, idx) => {
    const {
      isProfileLocked,
      location: { search },
      user: { currentProfile: { recentGrad = false } = {} } = {},
    } = this.props;

    const { 'fields[]': fields = [], 'model_index': modelIndex = null } = queryString.parse(search);

    let autofocusFields = fields;

    if (fields && typeof fields === 'string') {
      autofocusFields = [fields];
    }

    if (idx !== parseInt(modelIndex, 10)) {
      autofocusFields = [];
    }

    const { isEditing } = education;

    const educationInputsProps = {
      autofocusFields,
      education,
      idx,
      handleEducationToggleArray: this.handleEducationToggleArray,
      handleEducationOrganizationChange: this.handleEducationOrganizationChange,
      handleEducationOrganizationBlur: this.handleEducationOrganizationBlur,
      handleEducationDateInputChange: this.handleEducationDateInputChange,
      handleEducationInputChange: this.handleEducationInputChange,
      handleCurrentEducationInputChange: this.handleCurrentEducationInputChange,
      handleEducationDelete: this.handleEducationDelete,
      handleToggleEditingEdu: this.handleToggleEditingEdu,
      handleCancelEditingEdu: this.handleCancelEditingEdu,
      recentGrad,
    };

    const educationBlockProps = {
      key: idx,
      addWhiteBG: true,
      boxShadow: true,
      addPadding: false,
      noTitlePadding: true,
      largeTitleFont: true,
      className: styles.educationBlock,
    };

    const displayEduProps = {
      setEduRef: (ref) => this.setEduRef(ref, idx),
      education,
      idx,
      handleToggleEditingEdu: this.handleToggleEditingEdu,
      isProfileLocked,
    };

    return isEditing ? (
      <Block {...educationBlockProps}>
        <EducationInputs {...educationInputsProps} />
      </Block>
    ) : (
      <Block {...educationBlockProps}>
        <EducationDisplay {...displayEduProps} />
      </Block>
    );
  };

  render() {
    const {
      candidate,
      isProfileLocked,
      location: { search },
    } = this.props;

    const { model_index: modelIndex = null } = queryString.parse(search);

    const { candidateEducations: educations } = this.state;

    const statusContent = isProfileLocked ? (
      <div className={styles.profileLocked}>Profile Locked</div>
    ) : null;

    const hasEducations = educations.length > 0;

    const titleBlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      addPadding: true,
      className: styles.titleBlock,
    };

    const educationBlockProps = {
      noTitlePadding: true,
      largeTitleFont: true,
    };

    const inputContent = hasEducations ? educations.map(this.makeEducationInputs) : null;

    const addEducationButtonProps = {
      primary: true,
      onClick: this.addEducation,
      disabled: isProfileLocked || educations.some((edu) => edu.isEditing),
      highlight: parseInt(modelIndex, 10) === -1,
    };

    const profileLockOverlay = isProfileLocked ? <TableOverlay lockedNotFixed /> : null;

    const content = (
      <div className={styles.EditorEducation}>
        <Block {...titleBlockProps}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>Education</div>
            <div className={styles.saveContent}>
              {statusContent}
              <Button {...addEducationButtonProps}>+ Add Education</Button>
            </div>
          </div>
        </Block>
        <div className={styles.scrollDiv}>
          {profileLockOverlay}
          <Block {...educationBlockProps}>
            <div className={styles.educationInputContainer}>{inputContent}</div>
          </Block>
        </div>
      </div>
    );

    const layoutWithoutSidebarProps = {
      content,
    };

    const sidebarProps = {
      type: 'candidateEditor',
      page: 'edit-education',
      candidate,
    };

    return (
      <React.Fragment>
        <Helmet title="Edit Education" />
        <ReadyContainer className={styles.CandidateEditorEducation}>
          <Sidebar {...sidebarProps} />
          <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />
        </ReadyContainer>
      </React.Fragment>
    );
  }
}

export default ConnectContainer(duck)(EditEducation);
