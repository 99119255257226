import React from 'react';

import Block from 'components/Block';
import Button from 'components/Button';
import EmployerData from 'components/EmployerData';
import InputCheckbox from 'components/InputCheckbox';

import ToolboxRequisitionsActionDropdown from 'pages/AdminPortal/ToolboxRequisitionsPage/components/ToolboxRequisitionsActionDropdown';
import EmployerRequisitionsActionDropdown from 'pages/EmployeePortal/ManageReqsPage/components/EmployerRequisitionsActionDropdown';

import styles from '../../ToolboxRequisitionEditor.scss';

const Header = ({
  actions: { duplicateRequisition, patchResource, resourceUpdate, showModal },
  changeMade,
  changedAttributes,
  employers: { byId: employersById = {} } = {},
  handleSetState,
  isAdmin,
  isRequirements,
  match: {
    params: { reqCandId, view },
  },
  requisitionCandidate: { candidate: { full_name: fullName = '' } = {} } = {},
  requisitionOpening,
  requisitionOpening: {
    attributes,
    attributes: { complete, externalName, state },
    id,
    relationships: { employer: { data: employerIds = [] } = {} } = {},
  } = {},
  isValid,
  saving,
  slice,
}) => {
  const { attributes: employer } = employersById[employerIds[0]] || {};

  const updateReq = () => {
    patchResource({
      id,
      attributes: changedAttributes.reduce((acc, curr) => {
        acc[curr] = attributes[curr];
        return acc;
      }, {}),
      requiredFields: ['externalName'],
      ignoreValidations: true,
      slice,
      type: 'requisition_openings',
    });
    handleSetState({
      changeMade: false,
      changedAttributes: [],
    });
  };

  const publishReq = () => {
    handleSetState({ changeMade: false });
    patchResource({
      id,
      attributes: { state: 'open' },
      ignoreValidations: true,
      requiredFields: ['externalName'],
      slice,
      type: 'requisition_openings',
    });
  };

  const isPending = state === 'pending';
  const isClosed = state === 'closed';

  const savingContent = saving ? (
    <div className={styles.autoSaving}>Saving...</div>
  ) : (
    <div className={styles.autoSaving}>Saved</div>
  );

  const saveContent =
    saving !== undefined && (isPending || view === 'requirements') ? savingContent : null;

  const actionsProps = {
    duplicateRequisition,
    className: styles.reqActionDropdown,
    isModal: true,
    patchResource,
    requisitionOpening,
    resourceUpdate,
    showModal,
  };

  const pendingButtonText = 'Publish';

  const publishedButtonText = isValid === true && changeMade ? 'Update' : 'Published';

  const buttonText = isPending || isClosed ? pendingButtonText : publishedButtonText;

  const canUseButton = isPending || isClosed ? isValid : isValid && changeMade;

  const buttonAction = isPending || isClosed ? publishReq : updateReq;

  const publishButtonProps = {
    className: styles.publishButton,
    primary: true,
    disabled: !canUseButton,
    onClick: buttonAction,
  };

  const publishButtonContent =
    (!updateReq && !publishReq) || reqCandId ? null : (
      <Button {...publishButtonProps}>{buttonText}</Button>
    );

  const ActionDropdownComponent = isAdmin
    ? ToolboxRequisitionsActionDropdown
    : EmployerRequisitionsActionDropdown;

  const dropdownContent =
    isRequirements || reqCandId ? null : <ActionDropdownComponent {...actionsProps} />;

  const blockProps = {
    addWhiteBG: true,
    boxShadow: true,
    addPadding: true,
    noTitlePadding: true,
    className: styles.titleBlock,
    largeTitleFont: false,
  };

  const nameString = fullName ? `This is the Requisition for ${fullName} only` : '';

  const subtitleContent = fullName ? <div className={styles.subTitle}>{nameString}</div> : null;

  const employerDataProps = {
    employer,
    subtitle: externalName,
  };

  const completeInputProps = {
    name: 'complete',
    label: 'Complete',
    checked: complete || false,
    handleInputChange: (event) =>
      patchResource({
        id,
        ignoreValidations: true,
        attributes: { complete: event.target.checked },
        requiredFields: ['externalName'],
        slice,
        type: 'requisition_openings',
      }),
  };

  return reqCandId ? null : (
    <Block {...blockProps}>
      <div className={styles.titleInput}>
        <div className={styles.title}>
          <EmployerData {...employerDataProps} />
          {subtitleContent}
        </div>
        <div className={styles.saveAndPublish}>
          {saveContent}
          <div className={styles.checkbox}>
            {isAdmin && <InputCheckbox {...completeInputProps} />}
            {dropdownContent}
          </div>
          {publishButtonContent}
        </div>
      </div>
    </Block>
  );
};

export default Header;
