import camelCase from 'lodash.camelcase';
import { getAutocompleteOptions } from 'api/apiAutocomplete';
import { getEntity } from 'api/apiEntity';
import { makeLikeParam, makeLike8Param } from 'utils/paramUtils';

// valid autocomplete types are
// 'companies':
//    field - 'name'
// 'companies_no_employer':
//    field - 'name'
// 'candidates':
//    field - 'name'
// 'education_organizations':
//    field - 'name'
// 'employers':
//    field - 'name'
// 'perks':
//    field - 'name'
// 'benefits':
//    field - 'name'
// 'investors':
//    field - 'name'
// 'territories':
//    field - 'name'
// 'product_types':
//    field - 'name'
// 'extracurricular_activities':
//    field - 'name'
const promiseAutocompleteOptions = ({ query, page, field, autocompleteType }) => {
  const isCandidate = autocompleteType === 'candidates';
  const isEmployer = autocompleteType === 'employers';
  const isCompaniesNoEmployer = autocompleteType === 'companies_no_employer';

  const params = {
    camelizeIt: true,
    query,
    field,
    page,
  };

  if (isCompaniesNoEmployer) {
    params.noEmployer = isCompaniesNoEmployer;
  }

  if (isCandidate) {
    params.fullName = makeLikeParam(query);
    delete params.query;
    delete params.field;
  }
  if (isEmployer || isCompaniesNoEmployer) {
    params.name = makeLike8Param(query);
    params.sortBy = 'name_asc';
    delete params.query;
    delete params.field;
  }

  const config = { params };

  const responseType = isCompaniesNoEmployer ? 'companies' : camelCase(autocompleteType);

  const onFulfilled = (response) => {
    const {
      data: { [responseType]: options },
    } = response;

    return options;
  };

  const args = { config };

  if (isCandidate || isEmployer || isCompaniesNoEmployer) {
    args.type = isCompaniesNoEmployer ? 'companies' : autocompleteType;
  } else {
    args.autocompleteType = autocompleteType;
  }

  const promise =
    isCandidate || isEmployer || isCompaniesNoEmployer
      ? getEntity(args).then(onFulfilled)
      : getAutocompleteOptions(args).then(onFulfilled);

  return promise;
};

export default promiseAutocompleteOptions;
