import React from 'react';
import { Helmet } from 'react-helmet-async';

import ReadyContainer from '../../connectors/ReadyContainer';

import Block from '../../components/Block';
import Footer from '../../pages/CandidatesHome/components/Footer';
import styles from './CandidateFAQ.scss';

const CandidateFAQ = () => {
  const blockProps = {
    addWhiteBG: true,
    addPadding: true,
  };

  const title = 'Looking For Your Next Sales Job? Rainmakers Can Help';
  const metaDescription =
    'Looking for your next sales job? Learn how Rainmakers can help. Check out our most frequently asked questions today.';
  const canonicalUrl = `https://${process.env.HOST}/faq/`;

  const helmetProps = {
    key: 'helmet',
    title,
    meta: [
      { name: 'description', content: metaDescription },
      { property: 'og:url', content: canonicalUrl },
      { property: 'og:title', content: title },
      { property: 'og:description', content: metaDescription },
    ],
    link: [{ rel: 'canonical', href: canonicalUrl }],
  };

  const emailProps = {
    href: 'mailto:support@rainmakers.co',
    className: styles.mailLink,
    rel: 'noopener noreferrer',
    target: '_blank',
  };

  return (
    <React.Fragment>
      <Helmet {...helmetProps} />
      <ReadyContainer key="readyContainer" className={styles.CandidateFAQ}>
        <Block {...blockProps}>
          <div className={styles.content}>
            <h1 className={styles.header}>Candidate FAQ</h1>

            <div className={styles.question}>
              <span className={styles.bold}>Why should I join Rainmakers?</span>
            </div>
            <div className={styles.answer}>
              Rainmakers is an exclusive platform for top salespeople. We put the sales candidate in
              control. We enable candidates to show their true value. Candidates name their
              requirements such as salary, location and industry. Employers can only reach out to a
              candidate if they can meet or exceed the candidate&apos;s requirements.
            </div>

            <div className={styles.question}>
              <span className={styles.bold}>How much does it cost to join?</span>
            </div>
            <div className={styles.answer}>
              Rainmakers is free for candidates. Candidates just need to apply and be accepted to
              the platform.
            </div>

            <div className={styles.question}>
              <span className={styles.bold}>How do I get started?</span>
            </div>
            <div className={styles.answer}>
              Give us some basic info and upload your resume. That&apos;s it.
            </div>

            <div className={styles.supportText}>
              Contact us at
              <span className={styles.contact}>
                <a {...emailProps}> support@rainmakers.co</a>
              </span>
              with any of your unanswered questions.
            </div>
          </div>
        </Block>
        <Footer />
      </ReadyContainer>
    </React.Fragment>
  );
};

export default CandidateFAQ;
