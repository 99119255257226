import { call, select, put, take } from 'redux-saga/effects';

import { handleError } from 'utils/common';
import { makeAnalyticsPayload } from 'utils/analytics';

import { postCandidates } from 'api/apiUsers';
import { patchNestedEntity } from 'api/apiEntity';

import {
  candidateLoginDone,
  identifySegmentUser,
  addRequestInterceptor,
  waitForJob,
} from 'shared/store/app/creators';

import { selectUser } from 'connectors/User/redux/selectors';

import { promiseGetDiscourseSSO } from 'connectors/DiscourseSSOLogin/promises';

import { FORUM_APPLY_STARTED } from '../actions';

import { serverErrorSet } from '../creators';

const sagaForumApplyStarted = function* sagaForumApplyStarted() {
  while (true) {
    const {
      payload: { applyData },
    } = yield take(FORUM_APPLY_STARTED);

    const { utmInfo = {}, referer } = yield select(selectUser);

    try {
      const { firstName, lastName, email, password, phone, linkedinUrl, salesExperience } =
        applyData;

      const analytics = makeAnalyticsPayload({ utmInfo, referer });

      const { sig, sso } = utmInfo || {};

      const args = {
        data: {
          user: {
            email: email.trim(),
            password,
            passwordConfirmation: password,
            referredByCode: utmInfo.referral || null,
            referredByEmail: utmInfo.referralEmail || null,
            referredBySource: utmInfo.referralSource || null,
          },
          candidate: {
            firstName,
            lastName,
            email: email.trim(),
            socialMedia: {
              linkedin: {
                url: linkedinUrl,
              },
            },
            onboardingStatus: !salesExperience && sig && sso ? 'applied_forum' : 'applied',
          },
          analytics,
        },
        config: {
          params: {
            normalizeIt: true,
          },
        },
      };

      const {
        headers,
        data: {
          entities: {
            user: { byId: userById = {}, allIds: userAllIds = [] } = {},
            candidate: { byId: candidateById = {}, allIds: candidateAllIds = [] } = {},
          } = {},
          meta: { queuedPublicJobs: jobs = [] } = {},
        },
      } = yield call(postCandidates, args);

      const user = userById[userAllIds[0]] || {};
      const candidate = candidateById[candidateAllIds[0]] || {};
      const { id: candidateId } = candidate;

      yield put(addRequestInterceptor({ headers }));

      const job = jobs.find((job) => job.className === 'Analytics::UserSignedUpWorker');

      if (job) {
        yield put(
          waitForJob({
            jobId: job.providerJobId,
            onCompleteAction: identifySegmentUser,
            onCompletePayload: { user },
          })
        );
      }

      const patchNestedArgs = {
        type: 'candidates',
        nestedType: 'private_candidate_profile',
        id: candidateId,
        data: {
          type: 'private_candidate_profile',
          attributes: {
            phoneNumber: phone,
          },
        },
        config: {
          params: {
            normalizeIt: true,
          },
        },
      };

      yield call(patchNestedEntity, patchNestedArgs);

      if (!salesExperience && sig && sso) {
        try {
          const search = `?sso=${sso}&sig=${sig}`;

          const url = yield call(promiseGetDiscourseSSO, search);

          if (url && window && window.location) {
            window.location.replace(url);
          }
        } catch (error) {
          handleError(error);

          yield put(
            candidateLoginDone({
              user: {
                ...user.attributes,
                currentProfile: candidate.attributes,
              },
              headers,
              autoLoginPath: '/community-profile',
              noAddInterceptor: true,
            })
          );
        }
      } else {
        yield put(
          candidateLoginDone({
            user: {
              ...user.attributes,
              currentProfile: candidate.attributes,
            },
            headers,
            autoLoginPath: '/community-profile',
            noAddInterceptor: true,
          })
        );
      }
    } catch (error) {
      handleError(error);

      const {
        response: {
          data: { errors: { full_messages: errors = ['Please try again'] } = {} } = {},
        } = {},
      } = error || {};

      yield put(serverErrorSet({ errors }));
    }
  }
};

export default sagaForumApplyStarted;
