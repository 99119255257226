import React, { useEffect, useState } from 'react';

import Input from 'components/Input';
import Label from 'components/Label';

import styles from '../SideFiltersPane.scss';

const formatMoney = (amount) => (amount ? Number(amount).toLocaleString() : amount);
const unformatMoney = (amount) =>
  amount ? parseInt(amount.toString().replace(/[^\d.-]/g, ''), 10) : amount;

export const MoneyRangeView = ({
  label,
  fromName,
  toName,
  fromValue,
  toValue,
  handleMoneyFilterChange,
}) => {
  const [fromLocalValue, setFromLocalValue] = useState(formatMoney(fromValue) || '');
  const [toLocalValue, setToLocalValue] = useState(formatMoney(toValue) || '');

  useEffect(() => {
    setFromLocalValue(formatMoney(fromValue) || '');
  }, [fromValue]);

  useEffect(() => {
    setToLocalValue(formatMoney(toValue) || '');
  }, [toValue]);

  const updateLocalValues = ({ target: { name, value } }) => {
    if (name === fromName) {
      setFromLocalValue(formatMoney(unformatMoney(value)));
    }

    if (name === toName) {
      setToLocalValue(formatMoney(unformatMoney(value)));
    }
  };

  const handleMoneyInputChange = (ev) => {
    updateLocalValues(ev);
    handleMoneyFilterChange(ev);
  };

  return (
    <>
      <Label>{label}</Label>
      <div className={styles.inputRow}>
        <Input
          className={styles.inputClassName}
          type="text"
          name={fromName}
          size="full"
          value={fromLocalValue}
          placeholder="$"
          handleInputChange={handleMoneyInputChange}
        />
        <div>{'-'}</div>
        <Input
          className={styles.inputClassName}
          type="text"
          name={toName}
          size="full"
          value={toLocalValue}
          placeholder="$"
          handleInputChange={handleMoneyInputChange}
        />
      </div>
    </>
  );
};
