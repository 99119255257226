import { all, fork } from 'redux-saga/effects';

import patchResourceSaga from './patchResourceSaga';
import patchNestedResourceSaga from './patchNestedResourceSaga';
import postResourceSaga from './postResourceSaga';
import deleteResourceSaga from './deleteResourceSaga';

const resourceSaga = function* resourceSaga(browserHistory) {
  yield all([
    fork(patchResourceSaga, browserHistory),
    fork(patchNestedResourceSaga, browserHistory),
    fork(postResourceSaga, browserHistory),
    fork(deleteResourceSaga, browserHistory),
  ]);
};

export default resourceSaga;
