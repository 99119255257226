import React from 'react';
import Slider from 'react-rangeslider';

import { toDollars, toCents } from 'utils/moneyUtils';

import Label from 'components/Label';
import Input from 'components/Input';

import styles from './AEDealInfo.scss';

const AEDealInfo = ({ salesPeriod, handleSalesPeriodInputChange, trackEvent }) => {
  const {
    id: salesPeriodId,
    dealsAverageCents: dealsAverage,
    saleCycleDays,
    quotaAmountCents: quotaAmount,
    leadsGeneratedPercentage: leadsGenPercentage,
  } = salesPeriod || {};

  const commonTextInputProps = {
    handleInputChange: (event) =>
      handleSalesPeriodInputChange({
        event: {
          ...event,
          target: {
            ...event.target,
            value: toCents(event.target.value),
            name: event.target.name,
          },
        },
        salesPeriodId,
      }),
    type: 'text',
    size: 'full',
  };

  const dealsAverageInputProps = {
    ...commonTextInputProps,
    name: 'dealsAverageCents',
    value: dealsAverage || dealsAverage === 0 ? toDollars(dealsAverage) : '',
    placeholder: '',
    className: styles.oteInputContainer,
    moneyInput: true,
  };

  const saleCycleDaysInputProps = {
    ...commonTextInputProps,
    size: 'small',
    type: 'number',
    handleInputChange: (event) =>
      handleSalesPeriodInputChange({
        event,
        salesPeriodId,
      }),
    name: 'saleCycleDays',
    value: saleCycleDays || saleCycleDays === 0 ? saleCycleDays : '',
    placeholder: '',
    min: '0',
    className: styles.oteInputContainer,
  };

  const quotaAmountInputProps = {
    ...commonTextInputProps,
    name: 'quotaAmountCents',
    value: quotaAmount || quotaAmount === 0 ? toDollars(quotaAmount) : '',
    placeholder: '',
    className: styles.oteInputContainer,
    moneyInput: true,
  };

  const leadsGenPercentageProps = {
    className: styles.rangeSlider,
    min: 0,
    max: 100,
    value:
      leadsGenPercentage || leadsGenPercentage === 0
        ? Math.floor((leadsGenPercentage * 1000) / 10)
        : 0,
    onChangeComplete: () => trackEvent({ name: 'leadsGeneratedPercentage' }),
    onChange: (value) => {
      const newValue = value > 100 ? 100 : value;
      return handleSalesPeriodInputChange({
        event: {
          target: {
            value: newValue / 100,
            name: 'leadsGeneratedPercentage',
          },
        },
        salesPeriodId,
      });
    },
    tooltip: false,
    labels: {
      0: '0%',
      100: '100%',
    },
  };

  return (
    <div className={styles.dealInfoContainer} data-testid="AEDealInfo">
      <div className={styles.containerTitle}>DEAL INFO</div>

      <div className={styles.formRow}>
        <div className={styles.dealsAverage}>
          <Label standalone required className={styles.label}>
            Your average deal size
          </Label>
          <Input {...dealsAverageInputProps} />
        </div>

        <div className={styles.saleCycleDays}>
          <Label standalone required className={styles.label}>
            Your average deal cycle time
          </Label>
          <div className={styles.saleCycleDaysInput}>
            <Input {...saleCycleDaysInputProps} /> <Label>days</Label>
          </div>
        </div>
      </div>

      <Label standalone required className={styles.label}>
        Your most recent yearly quota
      </Label>
      <div className={styles.formRow}>
        <Input {...quotaAmountInputProps} />
      </div>

      <Label standalone required className={styles.label}>
        Your percentage of sales that are outbound
      </Label>
      <div className={styles.slider}>
        <Slider {...leadsGenPercentageProps} />
        <div className={styles.sliderPercent}>
          {`${leadsGenPercentage ? Math.floor((leadsGenPercentage * 1000) / 10) : 0}%`}
        </div>
      </div>
    </div>
  );
};

export default AEDealInfo;
