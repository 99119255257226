/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import jobsPageMetaData, {
  JOB_PAGE_AE,
  JOB_PAGE_CSM,
  JOB_PAGE_EAE,
  JOB_PAGE_MMAE,
  JOB_PAGE_SD,
  JOB_PAGE_SDR,
  JOB_PAGE_SM,
  JOB_PAGE_VP,
} from 'pages/JobsPage/jobsPageMetaData';

import { getAltName } from 'utils/urlUtils';

import pic1 from './assets/1-Phoenix-AE-Tech-Jobs.png';
import pic2 from './assets/2-Phoenix-SDR-AE-jobs.png';
import pic3 from './assets/3-Phoenix-SDR-Jobs.png';
import pic4 from './assets/4-Tech-Talent-Scouts.png';
import pic5 from './assets/5-Contact-Sales-Recruiters.png';

import styles from './PhoenixContent.scss';

export function PhoenixContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt={getAltName(pic1)} />

      <h2>Phoenix Tech Sales Careers</h2>

      <p>
        In a nod to the infamous Silicon Valley to the west, Phoenix, Arizona, has been more
        recently dubbed the Silicon Desert in recognition of its rise as a rival technology hub.
        While Phoenix has long been recognized as a thriving business center, the growth of
        technology startups in the area has gained much attention.
      </p>

      <p>
        Arizona has become a popular location for webscale, cloud, and large enterprise
        businesses—particularly in three key industries: satellite telecommunications, electronic
        manufacturing, and aerospace. As a result, Phoenix is one of the nation’s fastest-growing
        and most active regions for tech business growth and boasts up to 36% lower business
        operational costs than those in California. This has proven to be a big draw for businesses
        looking for locations for their headquarters.
      </p>

      <p>
        Unsurprisingly, an increase in tech business in the area has also resulted in a greater need
        for tech sales personnel.
      </p>

      <h2>Best Living In Phoenix</h2>

      <p>
        Phoenix, Arizona, has a lot to offer prospective tech sales specialists who live or are
        planning to move to the area.
      </p>

      <p>
        Phoenix’s fantastic weather makes for an enjoyable and happy living, and there are many
        outdoor things to enjoy. Outdoors enthusiasts will find many scenic trails, avid golfers
        will not find a lack of links to practice their swing, and there are plenty of opportunities
        for fishing and boating.
      </p>

      <p>
        The Phoenix area offers several festivals throughout the entire year and is home to many
        excellent restaurants, shopping districts, and vibrant arts communities.
      </p>

      <p>
        Phoenix’s cost of living is about 5% less than the national average. Additionally,{' '}
        <strong>
          housing is around 5% lower, with utilities and groceries coming in at 4% and 5% less
          expensive than average.
        </strong>{' '}
        So while the cost of living may seem only marginally less than in other cities, Phoenix
        makes up for that in general affordability, convenience, and quality of life.
      </p>

      <img src={pic2} alt={getAltName(pic2)} />

      <h2>The Phoenix Tech Boom</h2>

      <p>
        Phoenix continues to rapidly expand Arizona’s tech industry and has emerged as one of the
        country’s fastest-growing tech hubs. Recent data show that software job growth has increased
        by over 30% throughout the city over the past ten years. The national average is almost 10%
        slower than this.
      </p>

      <p>
        In addition to software development, Phoenix has become one of the top data center markets
        in the US, according to CBRE’s North American Data Center Trends Report. Factors that make
        Phoenix ideal for data center growth include a low risk of natural disasters, affordable
        power, and a vibrant cloud technology environment. Data centers in and around Phoenix manage
        the processing, transmission, and storage of enormous amounts of data, including customer
        information, business files, emails, SaaS, and more.
      </p>

      <p>
        <strong>
          Another crucial tech field—network infrastructure to support operations—plays a
          significant role in Phoenix’s tech boom.
        </strong>{' '}
        To meet the increased demand for IT network capacity and infrastructure from colocated
        companies and their customers.
      </p>

      <p>
        Taken together, software development, data centers, IT infrastructure, and more all make
        Phoenix a rich market for SaaS and other tech sales.
      </p>

      <h2>Sales Positions Available In Phoenix</h2>

      <p>
        Technology sales opportunities have been opening up all around Phoenix, Arizona. Rainmakers
        builds excellent <a href="/jobs/">employment opportunities</a> by linking qualified
        candidates with their ideal companies. And when businesses find and employ skilled
        salespeople, everyone wins.
      </p>

      <p>Here are some of the most rewarding tech sales positions available in Phoenix today.</p>

      <h2>Sales Development Representative</h2>

      <p>
        The success of any tech sales team depends on the performance of the{' '}
        <a href={jobsPageMetaData[JOB_PAGE_SDR].localUrl} target="_blank" rel="noreferrer">
          Sales Development Representatives (SDRs)
        </a>{' '}
        . An SDR specializes in inside sales activities, focusing on research and cold calling, to
        build sales pipelines for the rest of the team. A long-term sales career is frequently best
        started with an entry-level SDR position. The job is ideal for those with a drive for
        success and requires little to no prerequisite knowledge.
      </p>

      <img src={pic3} alt={getAltName(pic3)} />

      <h2>Account Executive</h2>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_AE].localUrl} target="_blank" rel="noreferrer">
          Account Executives (AE)
        </a>{' '}
        are responsible for bringing in new business, managing existing accounts, closing deals with
        potential customers, and attending to the needs of existing clients. Since a substantial
        portion of an AE’s compensation comes from commissions, the job necessitates concerted
        effort on the part of the employee to meet established quotas and income targets.
      </p>

      <h2>Mid-Market Account Executive</h2>

      <p>
        For sales professionals who are enthusiastic about their careers and want to advance,
        becoming a Mid-Market Account Manager is an excellent next step.{' '}
        <a href={jobsPageMetaData[JOB_PAGE_MMAE].localUrl} target="_blank" rel="noreferrer">
          Mid-Market Account Executives
        </a>{' '}
        oversee training, promote growth, leverage SaaS/cloud technology, help close deals, and
        maintain strong client relationships. Their primary source of new sales and commissions is
        middle-market companies.
      </p>

      <h2>Enterprise Account Executive</h2>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_EAE].localUrl} target="_blank" rel="noreferrer">
          Enterprise Account Executives
        </a>{' '}
        allow manufacturers, software developers, telecommunications firms, retail chains, and other
        major businesses to aim at large companies that can bring in significant sales. Enterprise
        Account Executives maintain profitable sales, high-level business relationships, and foster
        innovation. The ideal applicant for this position should be organized, capable, and
        dedicated. Due to the level of responsibility required, this position tends to have high
        earning potential.
      </p>

      <img src={pic4} alt={getAltName(pic4)} />

      <h2>Customer Success Manager</h2>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_CSM].localUrl} target="_blank" rel="noreferrer">
          Customer Success Managers (CSMs)
        </a>{' '}
        work hard to develop dependable, long-lasting client connections. The Customer Success
        Manager’s responsibility is to sell products and services to encourage recurring business
        and client success. Even though CSMs are a relatively new sales role, nearly half of
        high-tech organizations today employ them successfully.
      </p>

      <h2>Sales Director</h2>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_SD].localUrl} target="_blank" rel="noreferrer">
          Sales Directors
        </a>{' '}
        a company’s commitment to offering top-notch customer service. Their main duties are
        ensuring the sales team completes all necessary tasks, develops services, and delivers new
        products to clients. Sales Directors also supervise the sales team, lead training sessions,
        and ensure everyone works toward their objectives. Strong leadership, sales, and
        organizational abilities are essential for this position.
      </p>

      <h2>Sales Manager</h2>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_SM].localUrl} target="_blank" rel="noreferrer">
          Sales Managers
        </a>{' '}
        oversee both sales staff and daily sales activity. They are responsible for developing
        growth and sales strategies, setting and tracking sales goals, and keeping an eye on profit
        growth. A Sales Manager may also be responsible for other tasks, such as recruiting sales
        talent, creating the sales plan, coaching, and keeping an eye on operations. The scope of a
        Sales Manager’s duties is usually determined by the size of the company and the sales
        department.
      </p>

      <Link to="/apply-now" title="Apply Now">
        <img src={pic5} alt={getAltName(pic5)} />
      </Link>

      <h2>Vice President of Sales</h2>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_VP].localUrl} target="_blank" rel="noreferrer">
          Vice Presidents (VP)
        </a>{' '}
        of Sales take on many important tasks. They frequently act as managers, coaches, and
        strategists and are in charge of enhancing the performance of the sales division and helping
        them meet quotas. VPs of Sales frequently develop goals and sales plans, manage sales teams,
        monitor profits, hire salespeople, and perform other duties. While this position carries a
        lot of responsibility, it also offers the best pay.
      </p>

      <h2>Finding Tech Sales Careers at Rainmakers</h2>

      <p>
        Careers in tech sales typically offer a wealth of options for professional and personal
        development. There are many excellent pathways for promotion for those who are prepared to
        put in the time and effort required to meet and surpass expectations and show their value to
        the team.
      </p>

      <p>
        Every team member, from the Vice President of Sales to the Sales Development
        Representatives, is essential to the success of any growing company. Candidates who put in
        the effort, have the skills, and are committed to meeting goals will help everyone gain
        success and satisfaction.
      </p>

      <p>
        <a href="/apply-now/">Get in touch</a> with Rainmakers today to learn more about these
        growing opportunities.
      </p>
    </div>
  );
}
