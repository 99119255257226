import { createStructuredSelector } from 'reselect';

import { entitySelectorFactory } from 'reduxStore/selectorFactories';

import {
  selectIsApproved,
  selectIsAdmin,
  selectIsCandidate,
  selectLogoutPending,
} from 'connectors/Login/redux/selectors';

import { selectPickerOptions } from 'connectors/Defaults/redux/selectors';

import selectCandidate from './selectCandidate';
import selectCandidateUser from './selectCandidateUser';
import selectCandidateSaving from './selectCandidateSaving';
import selectResumeSaving from './selectResumeSaving';
import selectCandidateSaveError from './selectCandidateSaveError';
import selectProfileLocked from './selectProfileLocked';
import selectCandidateErrors from './selectCandidateErrors';

const selector = createStructuredSelector({
  vimeoVideos: entitySelectorFactory('candidateEditor', 'vimeoVideo'),
  logoutPending: selectLogoutPending,
  isApproved: selectIsApproved,
  isAdmin: selectIsAdmin,
  isCandidate: selectIsCandidate,
  isProfileLocked: selectProfileLocked,
  candidate: selectCandidate,
  user: selectCandidateUser,
  candidateSaving: selectCandidateSaving,
  candidateSaveError: selectCandidateSaveError,
  candidateErrors: selectCandidateErrors,
  resumeSaving: selectResumeSaving,
  pickerOptions: selectPickerOptions,
});

export {
  selectProfileLocked,
  selectCandidateSaveError,
  selectCandidateSaving,
  selectCandidateErrors,
  selectResumeSaving,
  selectCandidate,
  selectCandidateUser,
  selector as default,
};
