import { call, take } from 'redux-saga/effects';

import { identify } from 'utils/analytics';

import { IDENTIFY_SEGMENT_USER } from 'shared/store/app/actions';

const sagaIdentifySegmentUser = function* sagaIdentifySegmentUser() {
  while (true) {
    const {
      payload: { user },
    } = yield take(IDENTIFY_SEGMENT_USER);

    const { currentProfile = {} } = user || {};

    if (user.currentProfileType === 'Candidate') {
      const traits = {
        email: user.email,
        firstName: currentProfile.firstName,
        lastName: currentProfile.lastName,
        fullName: currentProfile.fullName,
        candidateId: currentProfile.id,
        createdAt: user.createdAt,
        blocked: user.blocked,
        appStatus: currentProfile.applicationStatus,
        approvedOn: currentProfile.applicationStatusUpdatedAt,
        phone: currentProfile.phoneNumber,
        type: currentProfile.type,
        hiringStatus: currentProfile.hiringStatus,
      };

      yield call(identify, { userId: user.id, traits });
    }

    if (user.currentProfileType === 'Employee') {
      const { employer = {} } = currentProfile;
      const traits = {
        email: user.email,
        firstName: currentProfile.firstName,
        lastName: currentProfile.lastName,
        createdAt: user.createdAt,
        blocked: user.blocked,
        employerId: currentProfile.employerId,
        employeeId: currentProfile.id,
        phone: currentProfile.phoneNumber,
        jobTitle: currentProfile.jobTitle,
        type: currentProfile.type,
        company: currentProfile.employer.name,
        employerAppStatus: employer.applicationStatus,
        approvedOn: employer.applicationStatusUpdatedAt,
      };

      yield call(identify, { userId: user.id, traits });
    }
  }
};

export default sagaIdentifySegmentUser;
