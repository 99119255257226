import { call, select, put, take } from 'redux-saga/effects';

import { entitySelectorFactory } from 'reduxStore/selectorFactories';

import { getEntity, postEntity } from 'api/apiEntity';

import { ADMIN_SEARCH_CANDIDATE_HIDE_STARTED } from '../actions';

import { resultsUpdate } from '../creators';

const sagaSearchCandidateHideStarted = function* sagaSearchCandidateHideStarted() {
  while (true) {
    const {
      payload: { candidateId, loggedInEmployerId },
    } = yield take(ADMIN_SEARCH_CANDIDATE_HIDE_STARTED);

    const { byId, allIds } = yield select(entitySelectorFactory('employerSearch', 'candidate'));

    try {
      const newById = {
        ...byId,
        [candidateId]: {
          ...byId[candidateId],
          hide: true,
          bookmark: null,
        },
      };

      yield put(resultsUpdate({ type: 'candidate', entity: { allIds, byId: newById } }));

      const {
        data: {
          employer: { company_id: companyId },
        },
      } = yield call(getEntity, {
        id: loggedInEmployerId,
        type: 'employers',
      });

      const args = {
        type: 'candidate_company_blocks',
        data: {
          type: 'candidate_company_block',
          attributes: {
            candidateId,
            companyId,
            ownerId: companyId,
            ownerType: 'Company',
            blockType: 'search_pass',
          },
        },
      };

      yield call(postEntity, args);
    } catch (error) {
      yield put(resultsUpdate({ type: 'candidate', entity: { allIds, byId } }));

      console.log('Error while executing sagaFeaturedCandidatePassStarted', error);
    }
  }
};

export default sagaSearchCandidateHideStarted;
