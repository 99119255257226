import { all, fork } from 'redux-saga/effects';

import sagaOpportunitiesLoadStarted from './sagaOpportunitiesLoadStarted';
import sagaPatchRequisitionCandidateStarted from './sagaPatchRequisitionCandidateStarted';
import sagaPatchPrivateRequisitionCandidateFeedbackStarted from './sagaPatchPrivateRequisitionCandidateFeedbackStarted';
import sagaRainmakersAdminLoadStarted from './sagaRainmakersAdminLoadStarted';
import sagaRequisitionCandidateLoadStarted from './sagaRequisitionCandidateLoadStarted';

const sagaCandidateOpportunities = function* sagaCandidateOpportunities() {
  yield all([
    fork(sagaOpportunitiesLoadStarted),
    fork(sagaPatchRequisitionCandidateStarted),
    fork(sagaRainmakersAdminLoadStarted),
    fork(sagaRequisitionCandidateLoadStarted),
    fork(sagaPatchPrivateRequisitionCandidateFeedbackStarted),
  ]);
};

export default sagaCandidateOpportunities;
