import { call, put, cancel, take, fork } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { handleError } from 'utils/common';

import { promiseGetOpportunities } from 'pages/CandidatePortal/CandidateOpportunitiesPage/promises';

import { OPPORTUNITIES_LOAD_STARTED } from '../actions';

import {
  opportunitiesLoadDone,
  opportunitiesLoadingStarted,
  opportunitiesLoadingDone,
} from '../creators';

const opportunitiesLoad = function* opportunitiesLoad(action) {
  const {
    payload: { page = 1, state, newRoute, sortBy },
  } = action;

  try {
    const promiseParams = {
      page,
      state,
      sortBy,
    };

    if (page === 1) {
      yield put(opportunitiesLoadingStarted());
    }
    if (newRoute) {
      yield put(push(newRoute));
    }

    const data = yield call(promiseGetOpportunities, promiseParams);

    yield put(opportunitiesLoadDone(data));
  } catch (error) {
    handleError(error);
    yield put(opportunitiesLoadingDone());
  }
};

const sagaOpportunitiesLoadStarted = function* sagaOpportunitiesLoadStarted() {
  let lastTask;
  while (true) {
    const action = yield take(OPPORTUNITIES_LOAD_STARTED);
    if (lastTask && action.payload.page === 1) {
      yield cancel(lastTask);
    }
    lastTask = yield fork(opportunitiesLoad, action);
  }
};

export default sagaOpportunitiesLoadStarted;
