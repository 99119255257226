import { getEntity } from 'api/apiEntity';

const promiseGetCandidateCompanyBlocks = ({ page, candidateId, companyId, includeSet }) => {
  const onFulfilled = (response) => {
    const {
      data: {
        candidateCompanyBlocks = [],
        meta: {
          pagination: { currentPage: page, totalCount: total },
        },
      },
    } = response;

    return { candidateCompanyBlocks, page, total };
  };

  const args = {
    type: 'candidate_company_blocks',
    config: {
      params: {
        page,
        candidateId,
        companyId,
        includeSet,
        sortBy: 'company_name_asc',
        camelizeIt: true,
      },
    },
  };

  return getEntity(args).then(onFulfilled);
};

export default promiseGetCandidateCompanyBlocks;
