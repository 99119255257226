import React from 'react';
import classnames from 'classnames';

import { useImageField } from 'hooks';

import styles from './EducationOrgData.scss';

const EducationOrgData = ({ className, onClick, educationOrg = {}, largeAvatar }) => {
  const { attributes: { name: educationOrgName } = {} } = educationOrg;

  const classes = classnames(styles.EducationOrgData, className);

  const infoWrapperClasses = classnames(styles.educationOrgDataInfoWrapper);

  const titleClasses = classnames(styles.educationOrgDataTitle);

  const avatarClass = classnames(styles.educationOrgDataAvatarWrapper, {
    [styles.educationOrgDataAvatarLargeWrapper]: largeAvatar,
  });

  const { imageComponent } = useImageField({
    model: educationOrg,
    avatarClass,
  });

  const educationOrgProps = {
    className: classes,
    onClick,
  };

  return (
    <div {...educationOrgProps}>
      {imageComponent}

      <div className={infoWrapperClasses}>
        <div className={titleClasses}>{educationOrgName}</div>
      </div>
    </div>
  );
};

export default EducationOrgData;
