import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash.isempty';

import { handleError } from 'utils/common';
import { deleteResource } from 'api/apiResource';

import { promisePostAddCandidates } from 'pages/AdminPortal/ToolboxCohortEditorPage/promises';

import Block from 'components/Block';
import Button from 'components/Button';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';

import CohortCollisionsModal from './components/CohortCollisionsModal';
import SelectedCandidatesTable from './components/SelectedCandidatesTable';
import AddCandidatesTable from './components/AddCandidatesTable';

import styles from './CohortEditorCandidates.scss';

class CohortEditorCandidates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      from: null,
      to: null,
      month: null,
      addingCandidates: false,
      showAddCandidatesOptions: false,
    };
  }

  handleCollisionsSubmitClick = async ({ candidatesToSkip, candidatesToRemove }) => {
    const {
      actions: { nestedEntityLoadStarted },
      cohort: { id: cohortId } = {},
      candidates: { allIds = [], byId = {} } = {},
    } = this.props;

    const { from: startDate, to: endDate } = this.state;

    this.setState({
      addingCandidates: true,
      collisionErrors: [],
    });

    const candidatesToAdd = allIds.filter(
      (id) => byId[id].attributes.checked && !candidatesToSkip.includes(id)
    );

    const promises = candidatesToRemove.map(({ cohortCandidateId: id }) =>
      deleteResource({
        type: 'cohort_candidates',
        id,
      })
    );

    try {
      await Promise.all(promises);

      await promisePostAddCandidates({
        cohortId,
        candidatesToAdd,
        candidatesToSkip,
        startDate,
        endDate,
      });
    } catch (error) {
      handleError(error);
    }

    nestedEntityLoadStarted({
      slice: 'toolboxCohorts',
      type: 'cohorts',
      id: cohortId,
      nestedType: 'cohort_candidates',
      params: {
        page: 1,
        includeSet: 'candidate',
        sortBy: 'candidate_first_name_asc,candidate_last_name_asc',
      },
    });

    this.setState({
      from: null,
      to: null,
      month: null,
      addingCandidates: false,
    });
  };

  handleAddAddableCandidates = async () => {
    try {
      const {
        addableCandidates: { allIds = [], byId = {} } = {},
        cohort: { id: cohortId },
        actions: { nestedEntityLoadStarted },
      } = this.props;

      const candidatesToAdd = allIds.filter((id) => byId[id].attributes.checked);

      this.setState({
        collisionErrors: null,
        addingCandidates: true,
        showAddCandidatesOptions: false,
      });

      const promiseParams = {
        cohortId,
        candidatesToAdd,
      };

      await promisePostAddCandidates(promiseParams);

      nestedEntityLoadStarted({
        slice: 'toolboxCohorts',
        type: 'cohorts',
        id: cohortId,
        nestedType: 'cohort_candidates',
        params: {
          page: 1,
          includeSet: 'candidate',
          sortBy: 'candidate_first_name_asc,candidate_last_name_asc',
        },
      });

      this.setState({ addingCandidates: false });
    } catch (error) {
      handleError(error);
      const { response: { data: { errors } = {} } = {} } = error || {};

      this.setState({
        collisionErrors: errors,
        addingCandidates: false,
      });
    }
  };

  render() {
    const {
      actions,
      candidates,
      addableCandidates,
      addableCandidatesLoading,
      addableCandidatesTotal,
      addableCandidatesPage,
      cohort: { id: cohortId, attributes: { name: cohortName, category, state, locked } = {} } = {},
      cohortCandidatesTotal,
      cohortCandidates,
      cohortCandidatesLoading,
      loadMoreCohortCandidates,
      handleCohortCandidateAdd,
      handleCohortCandidateRemove,
      router,
      modal,
      actions: { showModal },
    } = this.props;

    const { month, addingCandidates, showAddCandidatesOptions, collisionErrors } = this.state;

    const isReadOnly = locked;

    const titleBlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      addPadding: true,
      className: styles.titleBlock,
    };

    const backToModal =
      modal && router && router.location && router.location.state
        ? () =>
            showModal({
              key: modal.backKey,
              originalUrl: modal.originalUrl,
              parent: router.location.state.parent,
              route: modal.backPath,
            })
        : null;

    const backLinkProps = isEmpty(modal)
      ? {}
      : {
          onClick: backToModal,
        };

    const backButtonProps = {
      quaternary: true,
      className: styles.backButton,
    };

    const backButton =
      modal.backPath && modal.backKey ? (
        <div className={styles.backButtonContainer}>
          <Link {...backLinkProps}>
            <Button {...backButtonProps}>&#60; Back</Button>
          </Link>
        </div>
      ) : null;

    const collisions =
      collisionErrors &&
      collisionErrors[0] &&
      collisionErrors[0].error &&
      collisionErrors[0].error.collisions
        ? collisionErrors[0].error.collisions
        : [];

    const collisionModalProps = {
      collisions,
      handleCloseClick: () => this.setState({ collisionErrors: [] }),
      handleSubmitClick: this.handleCollisionsSubmitClick,
      cohortId,
    };

    const collisionModal =
      collisions.length > 0 ? <CohortCollisionsModal {...collisionModalProps} /> : null;

    const selectedCandidatesTableProps = {
      candidates,
      cohortId,
      isReadOnly,
      addingCandidates,
      cohortCandidates,
      cohortCandidatesLoading,
      cohortCandidatesTotal,
      loadMoreCohortCandidates,
      handleCohortCandidateRemove,
      handleShowAddCandidatesOptions: () => this.setState({ showAddCandidatesOptions: true }),
    };

    const addCandidatesTableProps = {
      actions,
      cohortId,
      category,
      isReadOnly,
      addingCandidates,
      addableCandidates,
      addableCandidatesLoading,
      addableCandidatesTotal,
      addableCandidatesPage,
      handleAddAddableCandidates: this.handleAddAddableCandidates,
      handleHideAddCandidatesOptions: () => this.setState({ showAddCandidatesOptions: false }),
      handleCohortCandidateAdd,
      handleCohortCandidateRemove,
      month,
    };

    const tableContent = showAddCandidatesOptions ? (
      <AddCandidatesTable {...addCandidatesTableProps} />
    ) : (
      <SelectedCandidatesTable {...selectedCandidatesTableProps} />
    );

    const content = (
      <div className={styles.EditorCandidates}>
        {backButton}
        <Block {...titleBlockProps}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>
              {cohortName}
              <div className={styles.category}>
                {category === 'featured' ? 'Match' : 'Featured'}
              </div>
              <div className={state === 'active' ? styles.active : styles.inactive}>
                {state === 'active' ? 'Active' : 'Inactive'}
              </div>
            </div>
          </div>
        </Block>
        {tableContent}
        {collisionModal}
      </div>
    );

    const layoutWithoutSidebarProps = {
      content,
    };

    return <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />;
  }
}

export default CohortEditorCandidates;
