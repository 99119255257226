import React from 'react';

import { HIRING_STATUS_SOURCE } from 'connectors/Defaults/constants';

import Label from 'components/Label';
import InputCheckboxMany from 'components/InputCheckboxMany';

import styles from './PrimaryQuestions.scss';

const PrimaryQuestions = ({ hiringStatus, handleInputChange }) => {
  const hiringStatusInputProps = {
    required: true,
    source: HIRING_STATUS_SOURCE.slice(0, 2),
    name: 'hiringStatus',
    handleInputChange,
    selectedValues: hiringStatus !== '' ? [hiringStatus] : [],
    blockStyle: true,
    flexFit: true,
    hasLabel: true,
  };

  return (
    <div className={styles.primaryContainer} data-testid="PrimaryQuestions">
      <Label standalone required>
        Where are you in your job search?
      </Label>
      <InputCheckboxMany {...hiringStatusInputProps} />
    </div>
  );
};

export default PrimaryQuestions;
