import { call, put, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { identify, sendGAClientId, trackEvent } from 'utils/analytics';
import { segmentUserLoginCandidate } from 'segment/eventNames';
import { deviceIsMobile } from 'utils/uaParserUtils';

import { promiseGetFullCandidate } from 'pages/EmployeePortal/CandidateProfilePages/promises';

import { CANDIDATE_LOGIN_DONE } from 'shared/store/app/actions';

import { addRequestInterceptor, loginDone, setModal, userSave } from 'shared/store/app/creators';

const identifySegment = (user, candidate) => {
  const {
    firstName,
    fullName,
    lastName,
    phoneNumber: phone,
    id: candidateId,
    applicationStatus: appStatus,
    applicationStatusUpdatedAt: approvedOn,
    type,
    hiringStatus,
  } = candidate;

  const { email, createdAt, blocked } = user;

  const traits = {
    email,
    firstName,
    lastName,
    fullName,
    candidateId,
    createdAt,
    blocked,
    appStatus,
    approvedOn,
    phone,
    type,
    hiringStatus,
  };

  identify({ userId: user.id, traits });
};

const trackLogin = (isAutoLogin) => {
  const event = segmentUserLoginCandidate;

  const properties = { isAutoLogin, isAdmin: false };

  const eventObject = { event, properties };

  trackEvent(eventObject);
};

const sagaCandidateLoginDone = function* sagaCandidateLoginDone(browserHistory) {
  while (true) {
    const {
      payload: { user = {}, headers, autoLoginPath, noAddInterceptor },
    } = yield take(CANDIDATE_LOGIN_DONE);

    const {
      currentProfile,
      currentProfile: { id: candidateId },
      id: userId,
    } = user;

    if (!noAddInterceptor) {
      yield call(identifySegment, user, currentProfile);
      yield put(addRequestInterceptor({ headers }));
    }

    // Send ga cookie client id for analytics
    yield call(sendGAClientId, userId);

    yield put(userSave({ user }));

    const promiseParams = {
      candidateId,
    };

    const fullCandidate = yield call(promiseGetFullCandidate, promiseParams);

    const isMobile = deviceIsMobile({ userAgent: headers['user-agent'] });

    const fullUser = {
      ...user,
      isMobile,
      currentProfileType: 'Candidate',
      currentProfile: {
        ...currentProfile,
        ...fullCandidate,
        showProfileCards: true,
      },
    };

    yield put(userSave({ user: fullUser }));

    const { state } = browserHistory.location;

    const originalPath = state && state.originalPath ? state.originalPath : null;

    const redirectPath = autoLoginPath || originalPath;

    const isAutoLogin = !!autoLoginPath;

    yield call(trackLogin, isAutoLogin);

    yield put(loginDone());

    if (
      redirectPath &&
      redirectPath.pathname &&
      redirectPath.pathname.includes('profile') &&
      !redirectPath.pathname.includes('my-hiring') &&
      !redirectPath.pathname.includes('create-profile')
    ) {
      yield put(
        setModal({
          originalUrl: '/candidate/home',
          key: 'CandidateProfileModal',
          initialRender: true,
        })
      );
      yield put(
        push({
          ...redirectPath,
          state: {
            modal: 'CandidateProfileModal',
            parent: 'CandidateHomePage',
          },
        })
      );
    } else if (
      redirectPath &&
      redirectPath.pathname &&
      redirectPath.pathname.includes('opportunity')
    ) {
      yield put(
        setModal({
          originalUrl: '/candidate/opportunities/active',
          key: 'CandidatePassModal',
          initialRender: true,
        })
      );
      yield put(
        push({
          ...redirectPath,
          state: {
            modal: 'CandidatePassModal',
            parent: 'CandidateOpportunitiesPage',
          },
        })
      );
    }
  }
};

export default sagaCandidateLoginDone;
