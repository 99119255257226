import { call, select, put, take } from 'redux-saga/effects';

import { entitySelectorFactory } from 'reduxStore/selectorFactories';

import { handleError } from 'utils/common';
import { trackEvent } from 'utils/analytics';
import { segmentHideCandidateEmployee } from 'segment/eventNames';

import { postEntity } from 'api/apiEntity';

import { FEATURED_CANDIDATE_PASS_STARTED } from '../actions';

import { featuredCandidateUpdate } from '../creators';

const trackHideCandidate = (candidateId, blockType, firstName, lastName) => {
  const event = segmentHideCandidateEmployee;

  const properties = {
    blockType,
    candidateId,
    candidateFirstName: firstName,
    candidateLastName: lastName,
  };

  const eventObject = { event, properties };

  trackEvent(eventObject);
};

const sagaFeaturedCandidatePassStarted = function* sagaFeaturedCandidatePassStarted() {
  while (true) {
    const {
      payload: { candidateId, firstName, lastName },
    } = yield take(FEATURED_CANDIDATE_PASS_STARTED);

    const { byId } = yield select(entitySelectorFactory('featured', 'candidate'));

    try {
      yield put(
        featuredCandidateUpdate({
          candidate: {
            ...byId[candidateId].attributes,
            hide: true,
          },
        })
      );

      const args = {
        type: 'candidate_company_blocks',
        data: {
          type: 'candidate_company_block',
          attributes: {
            candidateId,
            blockType: 'featured_pass',
          },
        },
        config: {
          params: {
            camelizeIt: true,
          },
        },
      };

      const {
        data: {
          candidateCompanyBlock: { id: candidateCompanyBlockId },
        },
      } = yield call(postEntity, args);

      yield call(trackHideCandidate, candidateId, 'featured_pass', firstName, lastName);

      yield put(
        featuredCandidateUpdate({
          candidate: {
            ...byId[candidateId].attributes,
            hide: true,
            candidateCompanyBlockId,
          },
        })
      );
    } catch (error) {
      handleError(error);

      yield put(
        featuredCandidateUpdate({
          candidate: byId[candidateId].attributes,
        })
      );
    }
  }
};

export default sagaFeaturedCandidatePassStarted;
