import { createAction } from 'redux-actions';

import { SHOW_MODAL, HIDE_MODAL } from 'shared/store/app/actions';

import {
  PATCH_PRIVATE_REQUISITION_CANDIDATE_FEEDBACK_STARTED,
  PATCH_REQUISITION_CANDIDATE_STARTED,
  PATCH_REQUISITION_CANDIDATE_DONE,
  OPPORTUNITIES_LOAD_STARTED,
  OPPORTUNITIES_LOAD_DONE,
  OPPORTUNITIES_LOADING_STARTED,
  OPPORTUNITIES_LOADING_DONE,
  OPPORTUNITIES_DELETE_STARTED,
  OPPORTUNITIES_DELETE_DONE,
  OPPORTUNITIES_PASS_STARTED,
  OPPORTUNITIES_PASS_DONE,
  OPPORTUNITIES_HIRE_STARTED,
  OPPORTUNITIES_HIRE_DONE,
  OPPORTUNITIES_CONTACT_STARTED,
  OPPORTUNITIES_CONTACT_DONE,
  RAINMAKERS_ADMIN_LOAD_STARTED,
  RAINMAKERS_ADMIN_LOAD_DONE,
  REQUISITION_CANDIDATE_LOAD_STARTED,
  REQUISITION_CANDIDATE_LOAD_DONE,
} from '../actions';

export const patchPrivateRequisitionCandidateFeedbackStarted = createAction(
  PATCH_PRIVATE_REQUISITION_CANDIDATE_FEEDBACK_STARTED
);

export const showModal = createAction(SHOW_MODAL);
export const hideModal = createAction(HIDE_MODAL);

export const opportunitiesLoadStarted = createAction(OPPORTUNITIES_LOAD_STARTED);
export const opportunitiesLoadDone = createAction(OPPORTUNITIES_LOAD_DONE);
export const opportunitiesLoadingStarted = createAction(OPPORTUNITIES_LOADING_STARTED);
export const opportunitiesLoadingDone = createAction(OPPORTUNITIES_LOADING_DONE);
export const opportunitiesDeleteStarted = createAction(OPPORTUNITIES_DELETE_STARTED);
export const opportunitiesDeleteDone = createAction(OPPORTUNITIES_DELETE_DONE);
export const opportunitiesPassDone = createAction(OPPORTUNITIES_PASS_DONE);
export const opportunitiesPassStarted = createAction(OPPORTUNITIES_PASS_STARTED);
export const opportunitiesHireDone = createAction(OPPORTUNITIES_HIRE_DONE);
export const opportunitiesHireStarted = createAction(OPPORTUNITIES_HIRE_STARTED);
export const opportunitiesTouchDone = createAction(OPPORTUNITIES_CONTACT_DONE);
export const opportunitiesTouchStarted = createAction(OPPORTUNITIES_CONTACT_STARTED);

export const patchRequisitionCandidateDone = createAction(PATCH_REQUISITION_CANDIDATE_DONE);
export const patchRequisitionCandidateStarted = createAction(PATCH_REQUISITION_CANDIDATE_STARTED);

export const rainmakersAdminLoadStarted = createAction(RAINMAKERS_ADMIN_LOAD_STARTED);
export const rainmakersAdminLoadDone = createAction(RAINMAKERS_ADMIN_LOAD_DONE);

export const requisitionCandidateLoadStarted = createAction(REQUISITION_CANDIDATE_LOAD_STARTED);
export const requisitionCandidateLoadDone = createAction(REQUISITION_CANDIDATE_LOAD_DONE);
