import React, { Component } from 'react';

import styles from './WaterfallHOC.scss';

const WaterfallHOC = () => (WrappedComponent) => {
  class _WaterfallHOC extends Component {
    constructor(props) {
      super(props);
      this.state = {
        display: false,
      };
    }

    componentDidMount() {
      const { index } = this.props;

      setTimeout(() => {
        this.setState({ display: true });
      }, (index + 1) * 50);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      const { index: nextIndex, id: nextId } = nextProps;

      const { index, id } = this.props;

      if (nextIndex && nextIndex !== index && nextId && nextId !== id) {
        this.setState({ display: false });
        setTimeout(() => {
          this.setState({ display: true });
        }, (nextIndex + 1) * 50);
      }
    }

    render() {
      const { display } = this.state;

      const waterfallProps = {
        className: display ? styles.show : styles.hide,
      };

      const wrappedComponentProps = { ...this.props };

      return (
        <div {...waterfallProps}>
          <WrappedComponent {...wrappedComponentProps} />
        </div>
      );
    }
  }

  return _WaterfallHOC;
};

export default WaterfallHOC;
