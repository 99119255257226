import React from 'react';

import InputSelect from 'components/InputSelect';

const Role = ({
  handleInputChange,
  requisitionOpening: { attributes: { role, state } = {} } = {},
  errors: { role: roleErrors = {} } = {},
  roleOptions,
}) => {
  const rolesSource = roleOptions.map((loc) => ({ label: loc, value: loc }));

  const recentRoleInputProps = {
    source: rolesSource,
    name: 'role',
    handleInputChange,
    value: role || '',
    defaultLabel: 'Select a role',
    defaultIcon: 'caret-down',
    setWidth: '200px',
    bigInput: true,
    label: 'What role best describes this position?',
    required: state !== 'closed',
    errors: roleErrors || {},
    disabled: state === 'closed',
  };

  return <InputSelect {...recentRoleInputProps} />;
};

export default Role;
