import { postEntity } from 'api/apiEntity';

const promisePostMessage = ({
  creatorId,
  creatorType,
  message,
  messageHtml,
  messageGroupId,
  subject,
  template,
}) => {
  const attributes = {
    subject,
    messageGroupId,
    template,
  };

  if (creatorId && creatorType) {
    attributes.creatorId = creatorId;
    attributes.creatorType = creatorType;
  }

  if (message || messageHtml) {
    attributes.message = message;
    attributes.messageHtml = messageHtml;
  }

  const onFulfilled = (response) => response;

  const args = {
    type: 'messages',
    data: {
      type: 'messages',
      attributes,
    },
  };

  return postEntity(args).then(onFulfilled);
};

export default promisePostMessage;
