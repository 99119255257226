import { createSelector } from 'reselect';
import { getApp } from 'shared/store/app/selectors';

const selectCandidateHome = createSelector(getApp, (state) => state.candidateHome);

const selectProfileCards = createSelector(
  selectCandidateHome,
  (candidateHome = {}) => candidateHome.profileCards
);

export default selectProfileCards;
