import React from 'react';

import FontIcon from 'components/FontIcon';

import styles from './ChecklistIcon.scss';

const ChecklistIcon = ({ loading, open, complete, idx }) => {
  const completeIcon =
    complete && !loading ? (
      <FontIcon className={styles.icon} iconName="checkmark-circle-24" />
    ) : null;

  const incompleteIcon =
    open && !loading ? (
      <div className={styles.iconDanger}>!</div>
    ) : (
      <div className={styles.circle}>{idx}</div>
    );

  const icon = completeIcon || incompleteIcon;

  const line = idx === 4 ? null : <div className={styles.line} />;

  return (
    <div className={styles.checklistIconContainer}>
      {icon}
      {line}
    </div>
  );
};

export default ChecklistIcon;
