import React, { Component } from 'react';
import classnames from 'classnames';
import trimEnd from 'lodash.trimend';

const styles = require('./RainEditor.scss');

class RainEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      RichTextEditor: null,
    };
  }

  componentDidMount() {
    const RichTextEditor = require('react-rte').default;

    const { html, markdown, setValue, value: init } = this.props;

    const markdownValue = markdown
      ? RichTextEditor.createValueFromString(markdown, 'markdown')
      : null;
    const htmlValue = html ? RichTextEditor.createValueFromString(html, 'html') : null;

    const initialValue = htmlValue || markdownValue;

    const value = init || initialValue || RichTextEditor.createEmptyValue();

    this.setState({ RichTextEditor });

    setValue({
      markdown,
      html: value.toString('html'),
      value,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { html, selectedId, markdown, setValue } = nextProps;

    const { selectedId: currSelectedId } = this.props;
    const { RichTextEditor } = this.state;

    if ((selectedId || selectedId === '') && selectedId !== currSelectedId && RichTextEditor) {
      const markdownValue = markdown
        ? RichTextEditor.createValueFromString(markdown, 'markdown')
        : null;
      const htmlValue = html ? RichTextEditor.createValueFromString(html, 'html') : null;

      const newValue = htmlValue || markdownValue;
      const value = newValue || RichTextEditor.createEmptyValue();

      setValue({
        markdown,
        html: value.toString('html'),
        value,
      });
    }
  }

  render() {
    const { autoFocus, className, placeholder, setValue, value } = this.props;

    const { RichTextEditor } = this.state;

    const toolbarConfig = {
      display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'HISTORY_BUTTONS'],
      INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD', className: styles.editorButton },
        { label: 'Italic', style: 'ITALIC', className: styles.editorButton },
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item', className: styles.editorButton },
        { label: 'OL', style: 'ordered-list-item', className: styles.editorButton },
      ],
    };

    const classes = classnames(styles.editor, className);

    const inputProps = {
      autoFocus,
      className: classes,
      onChange: (value) => {
        const stringValue = value.toString('markdown');

        const cleanString = stringValue
          ? stringValue.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, '')
          : '';

        const noTrailingLines = trimEnd(cleanString, '\r\n');

        setValue({
          markdown: cleanString ? noTrailingLines : null,
          html: value.toString('html'),
          value,
        });
      },
      placeholder,
      toolbarConfig,
      value,
    };

    return (
      <div className={styles.container}>{RichTextEditor && <RichTextEditor {...inputProps} />}</div>
    );
  }
}

export default RainEditor;
