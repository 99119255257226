import { createStructuredSelector } from 'reselect';

import {
  entitySelectorFactory,
  loadingSelectorFactory,
  pageSelectorFactory,
  totalSelectorFactory,
} from 'reduxStore/selectorFactories';

import {
  selectIsApproved,
  selectIsLoggedIn,
  selectIsAdmin,
  selectIsEmployee,
  selectLogoutPending,
} from 'connectors/Login/redux/selectors';
import { selectUser } from 'connectors/User/redux/selectors';
import { getHideNames } from 'shared/store/app/selectors';

const selector = createStructuredSelector({
  candidates: entitySelectorFactory('employerBookmarks', 'candidate'),
  bookmarks: entitySelectorFactory('employerBookmarks', 'bookmark'),
  page: pageSelectorFactory('employerBookmarks', 'bookmark'),
  total: totalSelectorFactory('employerBookmarks', 'bookmark'),
  isLoading: loadingSelectorFactory('employerBookmarks', 'bookmark'),
  hideNames: getHideNames,
  logoutPending: selectLogoutPending,
  user: selectUser,
  isApproved: selectIsApproved,
  isLoggedIn: selectIsLoggedIn,
  isAdmin: selectIsAdmin,
  isEmployee: selectIsEmployee,
});

export { selector as default };
