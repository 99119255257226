const prefix = 'Rainmakers/EmployeeEditor/';

// EMPLOYEE
export const EMPLOYEE_LOAD_STARTED = `${prefix}EMPLOYEE_LOAD_STARTED`;
export const EMPLOYEE_LOAD_DONE = `${prefix}EMPLOYEE_LOAD_DONE `;

export const EMAIL_CHANGE_STARTED = `${prefix}EMAIL_CHANGE_STARTED`;
export const EMAIL_CHANGE_DONE = `${prefix}EMAIL_CHANGE_DONE`;

export const EMPLOYEE_PATCH_STARTED = `${prefix}EMPLOYEE_PATCH_STARTED `;
export const EMPLOYEE_PATCH_ALL_STARTED = `${prefix}EMPLOYEE_PATCH_ALL_STARTED `;
export const EMPLOYEE_PATCH_DONE = `${prefix}EMPLOYEE_PATCH_DONE`;

export const EMPLOYEE_POST_STARTED = `${prefix}EMPLOYEE_POST_STARTED`;
export const EMPLOYEE_POST_DONE = `${prefix}EMPLOYEE_POST_DONE`;

export const EMPLOYEE_UPDATE = `${prefix}EMPLOYEE_UPDATE`;

export const EMPLOYEE_SAVE_STARTED = `${prefix}EMPLOYEE_SAVE_STARTED`;
export const EMPLOYEE_SAVE_DONE = `${prefix}EMPLOYEE_SAVE_DONE`;
export const EMPLOYEE_SAVE_ERROR = `${prefix}EMPLOYEE_SAVE_ERROR`;

export const EMPLOYEE_RESET = `${prefix}EMPLOYEE_RESET`;

export const EMPLOYEE_ERRORS_RESET = `${prefix}EMPLOYEE_ERRORS_RESET`;
export const EMPLOYEE_ERRORS_SET = `${prefix}EMPLOYEE_ERRORS_SET`;
export const EMPLOYEE_ERRORS_UPDATE = `${prefix}EMPLOYEE_ERRORS_UPDATE`;

export const EMPLOYEE_VALID_SET = `${prefix}EMPLOYEE_VALID_SET`;
export const EMPLOYEE_VALID_RESET = `${prefix}EMPLOYEE_VALID_RESET`;

export const VALIDATE_ALL = `${prefix}VALIDATE_ALL`;

// USER
export const USER_PATCH_STARTED = `${prefix}USER_PATCH_STARTED`;
export const USER_ERRORS_SET = `${prefix}USER_ERRORS_SET`;
export const USER_ERRORS_RESET = `${prefix}USER_ERRORS_RESET`;

export const EMPLOYEE_IMG_UPLOAD_STARTED = `${prefix}EMPLOYEE_IMG_UPLOAD_STARTED`;
export const EMPLOYEE_IMG_UPLOAD_DONE = `${prefix}EMPLOYEE_IMG_UPLOAD_DONE`;

// EMPLOYER
export const EMPLOYER_LOAD_STARTED = `${prefix}EMPLOYER_LOAD_STARTED`;
export const EMPLOYER_LOAD_DONE = `${prefix}EMPLOYER_LOAD_DONE`;
export const EMPLOYER_PATCH_STARTED = `${prefix}EMPLOYER_PATCH_STARTED`;
export const EMPLOYER_PATCH_DONE = `${prefix}EMPLOYER_PATCH_DONE`;
export const EMPLOYER_UPDATE = `${prefix}EMPLOYER_UPDATE`;
export const EMPLOYER_SAVE_STARTED = `${prefix}EMPLOYER_SAVE_STARTED`;
export const EMPLOYER_SAVE_DONE = `${prefix}EMPLOYER_SAVE_DONE`;
export const EMPLOYER_SAVE_ERROR = `${prefix}EMPLOYER_SAVE_ERROR`;
export const REMOVE_EMPLOYEE_SAVE_ERROR = `${prefix}REMOVE_EMPLOYEE_SAVE_ERROR`;
export const EMPLOYER_ERRORS_SET = `${prefix}EMPLOYER_ERRORS_SET`;
export const EMPLOYER_IMG_UPLOAD_STARTED = `${prefix}EMPLOYER_IMG_UPLOAD_STARTED`;
export const EMPLOYER_IMG_UPLOAD_DONE = `${prefix}EMPLOYER_IMG_UPLOAD_STARTED`;
export const EMPLOYER_IMG_UPLOAD_ERROR = `${prefix}EMPLOYER_IMG_UPLOAD_ERROR`;
