import React from 'react';
import classnames from 'classnames';

import { toCents, toDollars } from 'utils/moneyUtils';

import Input from 'components/Input';
import Label from 'components/Label';

import styles from '../ReqDetail.scss';

const Ote = ({
  requisitionOpening: { attributes: { oteMinCents, oteMaxCents, state } = {} } = {},
  errors: { oteMinCents: oteMinCentsErrors = {}, oteMaxCents: oteMaxCentsErrorsOrig = {} } = {},
  handleInputChange,
}) => {
  const oteRangeValid = oteMinCents && oteMaxCents ? oteMaxCents >= oteMinCents : null;

  const oteMaxCentsErrors = {
    ...oteMaxCentsErrorsOrig,
    isMoneyGreaterThanX: [oteRangeValid, oteMinCents],
  };

  const commonTextInputProps = {
    className: styles.inputContainerNoPad,
    type: 'text',
    size: 'large',
    disabled: state === 'closed',
    handleInputChange: (event) =>
      handleInputChange({
        ...event,
        target: {
          ...event.target,
          value: toCents(event.target.value),
          name: event.target.name,
        },
      }),
  };

  const oteMinCentsInputProps = {
    ...commonTextInputProps,
    size: 'full',
    value: oteMinCents || oteMinCents === 0 ? toDollars(oteMinCents) : '',
    name: 'oteMinCents',
    moneyInput: true,
    oteInputOne: true,
    errors: oteMinCents ? oteMinCentsErrors : {},
  };

  const oteMaxCentsInputProps = {
    ...commonTextInputProps,
    size: 'full',
    value: oteMaxCents || oteMaxCents === 0 ? toDollars(oteMaxCents) : '',
    name: 'oteMaxCents',
    moneyInput: true,
    oteInputTwo: true,
    errors: oteMaxCents && oteMinCents ? oteMaxCentsErrors : {},
  };

  const labelClasses = classnames(styles.labelStyle);

  const labelContent = (
    <Label required={state !== 'closed'} standalone className={labelClasses}>
      OTE Range
    </Label>
  );

  return (
    <div className={styles.rangeInputs}>
      {labelContent}
      <div className={styles.rangeInputsContainer}>
        <Input {...oteMinCentsInputProps} />
        <div className={styles.rangeSpan}>to</div>
        <Input {...oteMaxCentsInputProps} />
      </div>
    </div>
  );
};

export default Ote;
