import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { AdminAuthHOC } from 'containers/HOC';

import { makeLikeParam } from 'utils/paramUtils';

import ConnectContainer from 'containers/ConnectContainer';

import ReadyContainer from 'connectors/ReadyContainer';
import { useTableQuery } from 'hooks';

import Sidebar from 'components/Sidebar';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';

import ToolboxApproachesTable from './components/ToolboxApproachesTable';

import duck from './redux';

import styles from './ToolboxApproachesPage.scss';

const route = '/toolbox/approaches/';

const ToolboxApproachesPage = ({
  actions: { entityLoadStarted, pickerOptionsLoadStarted },
  page = 1,
  actions,
  children,
  isAdmin,
  isLoading,
  pickerOptions: { role: roleOptions = [] } = {},
  requisitionCandidates: { byId = {}, allIds = [] } = {},
  total,
  user,
  employers: { byId: employersById = {} } = {},
  candidates: { byId: candidatesById = {} } = {},
  messageGroups: { byId: messageGroupsById = {} },
  privateReqCanFeedback: { byId: privateReqCanFeedbackById = {} } = {},
  requisitionOpenings: { byId: requisitionOpeningsById = {} } = {},
  rainmakersAdmins = {},
}) => {
  const defaultLoadArgs = {
    type: 'requisition_candidates',
    slice: 'toolboxApproaches',
  };

  const {
    queryParams,
    loadMore,
    handleSearchInputChange,
    handleSearchFilterChange,
    handleSortChange,
    handleSearchCheckboxChange,
  } = useTableQuery(
    route,
    page,
    {
      attentionRequired: {
        type: 'boolean',
        defaultValue: null,
      },
      candidateRainmakersAdminId: {
        type: 'array',
        defaultValue: [],
      },
      candidateFullName: {
        type: 'string',
        defaultValue: null,
      },
      candidateUserEmail: {
        type: 'string',
        defaultValue: null,
      },
      employerName: {
        type: 'string',
        defaultValue: null,
      },
      messageGroupMessagesCount: {
        type: 'array',
        defaultValue: [],
      },
      messageGroupUnread: {
        type: 'boolean',
        defaultValue: null,
      },
      requisitionOpeningRole: {
        type: 'array',
        defaultValue: [],
      },
      sortBy: {
        type: 'string',
        defaultValue: 'last_activity_at_asc',
      },
      state: {
        type: 'array',
        defaultValue: ['active', 'candidate_pass_pending'],
      },
    },
    defaultLoadArgs,
    { includeSet: 'admin_toolbox' },
    {
      candidateFullName: makeLikeParam,
      employerName: makeLikeParam,
      messageGroupMessagesCount: (value) => value[0] || null,
    }
  );

  useEffect(() => {
    entityLoadStarted({
      ...defaultLoadArgs,
      type: 'rainmakers_admins',
      params: {
        state: false,
        talentConcierge: true,
        page: 1,
      },
    });

    pickerOptionsLoadStarted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isAdmin) {
    return null;
  }

  const requisitionCandidates = allIds
    .map((id) => {
      const { [id]: requisitionCandidate } = byId;

      return requisitionCandidate;
    })
    .filter((requisitionCandidate) => !requisitionCandidate.temporary);

  return (
    <>
      <Helmet key="helmet" title="Toolbox | Approaches" />

      <ReadyContainer key="readyContainer">
        <Sidebar type="toolbox" page="toolboxApproaches" />

        <LayoutWithoutSidebar
          content={
            <div className={styles.toolboxApproachesMainContent}>
              <ToolboxApproachesTable
                {...queryParams}
                actions={actions}
                roleOptions={roleOptions}
                isLoading={isLoading}
                user={user}
                requisitionCandidates={requisitionCandidates}
                employersById={employersById}
                candidatesById={candidatesById}
                messageGroupsById={messageGroupsById}
                privateReqCanFeedbackById={privateReqCanFeedbackById}
                requisitionOpeningsById={requisitionOpeningsById}
                rainmakersAdmins={rainmakersAdmins}
                total={total}
                loadMore={loadMore}
                handleSearchCheckboxChange={handleSearchCheckboxChange}
                handleSearchInputChange={handleSearchInputChange}
                handleSearchFilterChange={handleSearchFilterChange}
                handleSortChange={handleSortChange}
              />
            </div>
          }
        />

        {children}
      </ReadyContainer>
    </>
  );
};

export default ConnectContainer(duck)(AdminAuthHOC()(ToolboxApproachesPage));
