import React from 'react';
import { Link } from 'react-router-dom';
import FontIcon from 'components/FontIcon';

import jobsMetadata, {
  JOB_PAGE_SDR,
  JOB_PAGE_AE,
  JOB_PAGE_CSM,
  JOB_PAGE_MMAE,
  JOB_PAGE_EAE,
  JOB_PAGE_SM,
  JOB_PAGE_SD,
  JOB_PAGE_VP,
} from 'pages/JobsPage/jobsPageMetaData';

import cityRecruiterPageMetaData, {
  RECRUITER_PAGE_ATLANTA,
  RECRUITER_PAGE_CHICAGO,
  RECRUITER_PAGE_DENVER,
  RECRUITER_PAGE_LA,
  RECRUITER_PAGE_NYC,
  RECRUITER_PAGE_PHOENIX,
  RECRUITER_PAGE_RALEIGH,
  RECRUITER_PAGE_SF,
} from 'pages/CityRecruiterPage/cityRecruiterPageMetaData';

import styles from './TopQuestions.scss';

const TopQuestions = () => {
  return (
    <div id="Top-questions" className={styles.TopQuestions}>
      <h3 className={styles.Title}>Top Questions</h3>

      <div className={styles.Container}>
        <div className={styles.QuestionContainer}>
          <div className={styles.Question}>
            How is Rainmakers different than a traditional sales recruiter and other recruiting
            options?
          </div>

          <ul>
            <li className={styles.Answer}>
              Rainmakers is a recruiting platform exclusively for top salespeople.
            </li>
            <li className={styles.Answer}>
              We put the salesperson in control by allowing them to showcase their sales
              achievements.
            </li>
            <li className={styles.Answer}>
              The salesperson sets their hiring requirements, like compensation.
            </li>
            <li className={styles.Answer}>Companies bid on the candidates!</li>
          </ul>
        </div>

        <div className={styles.QuestionContainer}>
          <div className={styles.Question}>How much does it cost?</div>

          <div className={styles.Answer}>Rainmakers is completely free for salespeople.</div>
        </div>

        <div className={styles.QuestionContainerNoBorder}>
          <div className={styles.Question}>Is it confidential?</div>

          <div className={styles.Answer}>
            Yes. Rainmakers does not share your information with employers until you are ready. We
            automatically hide your information from current and previous employers and allow you to
            block other companies.
          </div>
        </div>

        <div className={styles.QuestionContainerNoBorder}>
          <Link className={styles.FAQLink} to="/faq">
            Go to FAQ <FontIcon className={styles.Icon} iconName="caret-right" />
          </Link>
        </div>

        <div className={styles.QuestionContainer}>
          <div className={styles.Question}>How do I get a tech sales job?</div>

          <div className={styles.Answer}>
            You can go the traditional route - manually finding employers, tweaking your resume and
            submitting the application on your own, but we all know that is a hope and pray strategy
            in {new Date().getFullYear()}. No one ever calls you back. A more{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://${process.env.HOST}/blog/how-to-get-a-tech-sales-job-fast/`}
            >
              realistic approach
            </a>{' '}
            is to use your network, or get access to someone else’s network.
          </div>
        </div>

        <div className={styles.QuestionContainer}>
          <div className={styles.Question}>Is software sales a good career path?</div>

          <div className={styles.Answer}>
            Absolutely.{' '}
            <Link to="/jobs/" target="_blank">
              Tech sales careers
            </Link>{' '}
            are extremely lucrative in today’s fast paced world. The demand is extremely high, the
            pay is great, the career opportunities are endless, and the barrier to entry is pretty
            low. Software sales is an excellent choice if you want to advance your career and embark
            on a journey to financial freedom.
          </div>
        </div>

        <div className={styles.QuestionContainer}>
          <div className={styles.Question}>What are the different tech sales career paths?</div>

          <div className={styles.Answer}>
            There are many different sales roles and paths to consider. Most salespeople start off
            as a{' '}
            <Link to={jobsMetadata[JOB_PAGE_SDR].localUrl} target="_blank">
              {jobsMetadata[JOB_PAGE_SDR].header}
            </Link>
            , but from there you could become an{' '}
            <Link to={jobsMetadata[JOB_PAGE_AE].localUrl} target="_blank">
              {jobsMetadata[JOB_PAGE_AE].header}
            </Link>
            , Account Manager,{' '}
            <Link to={jobsMetadata[JOB_PAGE_CSM].localUrl} target="_blank">
              {jobsMetadata[JOB_PAGE_CSM].header}
            </Link>
            ,{' '}
            <Link to={jobsMetadata[JOB_PAGE_EAE].localUrl} target="_blank">
              {jobsMetadata[JOB_PAGE_EAE].header}
            </Link>
            ,{' '}
            <Link to={jobsMetadata[JOB_PAGE_MMAE].localUrl} target="_blank">
              {jobsMetadata[JOB_PAGE_MMAE].header}
            </Link>
            , Outside Sales Rep,{' '}
            <Link to={jobsMetadata[JOB_PAGE_SM].localUrl} target="_blank">
              Sales Manager
            </Link>
            ,{' '}
            <Link to={jobsMetadata[JOB_PAGE_SD].localUrl} target="_blank">
              Sales Director
            </Link>
            , Sales Operations Manager, Sales Engineer, and of course,{' '}
            <Link to={jobsMetadata[JOB_PAGE_VP].localUrl} target="_blank">
              VP of Sales
            </Link>
            .
          </div>
        </div>

        <div className={styles.QuestionContainer}>
          <div className={styles.Question}>How much money can you make in tech sales?</div>

          <div className={styles.Answer}>
            Depending on role and experience, you could make anywhere from $50k per year, to over
            $500k per year. Check out our blog to learn about all the various{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://${process.env.HOST}/blog/tech-sales-jobs-salaries/`}
            >
              sales roles and salaries
            </a>{' '}
            in tech sales.
          </div>
        </div>

        <div className={styles.QuestionContainer}>
          <div className={styles.Question}>
            Where are companies using sales recruiting agencies?
          </div>

          <div className={styles.Answer}>
            Businesses are increasingly leveraging the expertise of recruiters across various major
            cities in the United States, including the{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={cityRecruiterPageMetaData[RECRUITER_PAGE_SF].localUrl}
            >
              San Francisco Bay Area
            </a>
            ,{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={cityRecruiterPageMetaData[RECRUITER_PAGE_RALEIGH].localUrl}
            >
              Raleigh
            </a>
            ,{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={cityRecruiterPageMetaData[RECRUITER_PAGE_PHOENIX].localUrl}
            >
              Phoenix
            </a>
            ,{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={cityRecruiterPageMetaData[RECRUITER_PAGE_LA].localUrl}
            >
              Los Angeles
            </a>
            ,{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={cityRecruiterPageMetaData[RECRUITER_PAGE_DENVER].localUrl}
            >
              Denver
            </a>
            ,{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={cityRecruiterPageMetaData[RECRUITER_PAGE_CHICAGO].localUrl}
            >
              Chicago
            </a>
            , Austin,{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={cityRecruiterPageMetaData[RECRUITER_PAGE_ATLANTA].localUrl}
            >
              Atlanta
            </a>
            , and{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={cityRecruiterPageMetaData[RECRUITER_PAGE_NYC].localUrl}
            >
              New York City
            </a>{' '}
            to identify and attract top talent for their sales teams..
          </div>
        </div>

        <div className={styles.QuestionContainerNoBorder}>
          <div className={styles.Question}>
            What are the most important skills to have in tech sales?
          </div>

          <div className={styles.Answer}>
            The{' '}
            <Link to="/top-skills/" target="_blank">
              most important skills
            </Link>{' '}
            to have to be successful in tech sales are sharp business acumen, emotional
            intelligence,{' '}
            <Link to="/top-skills/prospecting" target="_blank">
              prospecting
            </Link>
            {', '}
            project management, communication, client management, drive, ambition and resilience.
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopQuestions;
