import { call, put, select, cancel, take, fork } from 'redux-saga/effects';
import { handleError } from 'utils/common';

import { deleteResource } from 'api/apiResource';

import { resourceSelectorFactory } from 'reduxStore/selectorFactories';

import { DELETE_RESOURCE } from '../../actions';

import { apiErrorsUpdate, resourceUpdate, resourceDelete, savingUpdate } from '../../creators';

const doDeleteResource = function* doDeleteResource(action) {
  const {
    payload: { id, slice, type },
  } = action;

  if (id && slice && type) {
    yield put(savingUpdate({ saving: true, slice }));

    const resource = yield select(resourceSelectorFactory(slice, type, id));

    yield put(
      resourceDelete({
        id,
        slice,
        type,
      })
    );

    try {
      const args = {
        id,
        type,
      };

      yield call(deleteResource, args);
    } catch ({ response: error }) {
      handleError(error);

      yield put(
        resourceUpdate({
          id,
          resource,
          slice,
          type,
        })
      );

      yield put(apiErrorsUpdate({ slice, error }));
    }
  }
};

const deleteResourceSaga = function* deleteResourceSaga() {
  let lastTask;
  let lastAction;
  while (true) {
    const action = yield take(DELETE_RESOURCE);
    if (
      lastTask &&
      lastAction.payload.name === action.payload.name &&
      lastAction.payload.id === action.payload.id
    ) {
      yield cancel(lastTask);
    }
    lastAction = action;
    lastTask = yield fork(doDeleteResource, action);
  }
};

export default deleteResourceSaga;
