import { handleActions } from 'redux-actions';

import {
  CANDIDATE_COMPANY_BLOCKS_ERROR_SET,
  CANDIDATE_COMPANY_BLOCKS_LOAD_DONE,
  CANDIDATE_COMPANY_BLOCKS_LOAD_STARTED,
} from '../actions';

const initialState = {
  candidateCompanyBlocks: {
    data: [],
    errors: null,
  },
};

const handleCandidateCompanyBlocksErrorSet = (state = initialState, action) => {
  const {
    payload: { errors },
  } = action;

  return {
    ...state,
    candidateCompanyBlocks: {
      ...state.candidateCompanyBlocks,
      errors,
    },
  };
};

const handleCandidateCompanyBlocksLoadStarted = (state = initialState) => ({
  ...state,
  candidateCompanyBlocks: {
    ...state.candidateCompanyBlocks,
    loading: true,
  },
});

const handleCandidateCompanyBlocksLoadDone = (state = initialState, action) => {
  const {
    payload: { candidateCompanyBlocks, page, total },
  } = action;

  if (!page) {
    return {
      ...state,
      candidateCompanyBlocks: {
        ...initialState.candidateCompanyBlocks,
        data: [...initialState.candidateCompanyBlocks.data, ...candidateCompanyBlocks],
        total,
        loading: false,
      },
    };
  }

  if (page === 1) {
    return {
      ...state,
      candidateCompanyBlocks: {
        ...initialState.candidateCompanyBlocks,
        data: [...initialState.candidateCompanyBlocks.data, ...candidateCompanyBlocks],
        page,
        total,
        loading: false,
      },
    };
  }

  return {
    ...state,
    candidateCompanyBlocks: {
      ...state.candidateCompanyBlocks,
      data: [...state.candidateCompanyBlocks.data, ...candidateCompanyBlocks],
      page,
      total,
      loading: false,
    },
  };
};

const actionHandlers = {
  [CANDIDATE_COMPANY_BLOCKS_LOAD_DONE]: handleCandidateCompanyBlocksLoadDone,
  [CANDIDATE_COMPANY_BLOCKS_LOAD_STARTED]: handleCandidateCompanyBlocksLoadStarted,
  [CANDIDATE_COMPANY_BLOCKS_ERROR_SET]: handleCandidateCompanyBlocksErrorSet,
};

const reduceCandidateSettings = handleActions(actionHandlers, initialState);

export default reduceCandidateSettings;
