import { select, take, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { handleError } from 'utils/common';

import { HIDE_MODAL } from 'shared/store/app/actions';

import { setModal } from 'shared/store/app/creators';

import { getModal } from 'shared/store/app/selectors';

const sagaHideModal = function* sagaHideModal() {
  while (true) {
    yield take(HIDE_MODAL);

    try {
      const modal = yield select(getModal);
      const { originalUrl, initialRender } = modal;
      const { state: currentState = {} } = window.browserHistory.location;

      const split = originalUrl.split('?');
      const pathname = split[0];
      const search = split[1] ? `?${split[1]}` : '';

      const locationState = {
        pathname,
        search,
        state: { modal: false },
      };

      if (originalUrl && (currentState.modal || initialRender)) {
        yield put(push(locationState));
        yield put(setModal({ ...modal, originalUrl: '', initialRender: false }));
      }
    } catch (error) {
      handleError(error);
    }
  }
};

export default sagaHideModal;
