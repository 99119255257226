import { call, put, select, cancel, take, fork } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { handleError } from 'utils/common';

import { patchEntity, patchNestedEntity } from 'api/apiEntity';

import { selectCandidate } from '../selectors';

import { CANDIDATE_PATCH_STARTED } from '../actions';

import {
  candidateUpdate,
  privateCandidateUpdate,
  candidateSaveStarted,
  candidateSaveDone,
  candidateSaveError,
} from '../creators';

const patchCandidate = function* patchCandidate(action) {
  const {
    payload: { newValue, name, privateInfo },
  } = action;

  if (privateInfo) {
    yield put(privateCandidateUpdate({ newValue, name }));
  } else {
    yield put(candidateUpdate({ newValue, name }));
  }
  yield put(candidateSaveStarted());

  yield call(delay, 750);

  const { id: candidateId } = yield select(selectCandidate);

  try {
    const args = {
      type: 'candidates',
      data: {
        type: 'candidates',
        id: candidateId,
        attributes: {
          [name]: newValue,
        },
      },
      id: candidateId,
      config: {
        params: {
          camelizeIt: true,
        },
      },
    };

    const privateArgs = {
      type: 'candidates',
      nestedType: 'private_candidate_profile',
      data: {
        type: 'private_candidate_profile',
        attributes: {
          [name]: newValue,
        },
      },
      id: candidateId,
      config: {
        params: {
          camelizeIt: true,
        },
      },
    };

    if (privateInfo) {
      const {
        data: { privateCandidateProfile },
      } = yield call(patchNestedEntity, privateArgs);

      yield put(privateCandidateUpdate({ name, newValue: privateCandidateProfile[name] }));
    } else {
      const {
        data: { candidate },
      } = yield call(patchEntity, args);

      yield put(candidateUpdate({ name, newValue: candidate[name] }));
    }
    yield put(candidateSaveDone());
  } catch (error) {
    handleError(error);
    yield put(candidateSaveError(error));
  }
};

const sagaCandidatePatchStarted = function* sagaCandidatePatchStarted() {
  let lastTask;
  let lastAction;
  while (true) {
    const action = yield take(CANDIDATE_PATCH_STARTED);
    if (lastTask && lastAction.payload.name === action.payload.name) {
      yield cancel(lastTask);
    }
    lastAction = action;
    lastTask = yield fork(patchCandidate, action);
  }
};

export default sagaCandidatePatchStarted;
