import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import isEmpty from 'lodash.isempty';

import { handleError } from 'utils/common';

import ConnectContainer from 'containers/ConnectContainer';

import ReadyContainer from 'connectors/ReadyContainer';

import { promiseEmailValid } from 'pages/CandidatePortal/CandidateEditorPages/promises';

import duck from '../redux';

import Graphic from '../Apply/components/Graphic';
import Form from './components/Form';

import styles from './ForumApply.scss';

class ForumApply extends Component {
  constructor(props) {
    super(props);

    const {
      onboardingData: {
        email = '',
        firstName = '',
        lastName = '',
        linkedinUrl = '',
        phone = '',
        salesExperience = null,
      } = {},
    } = props;

    this.state = {
      autoCapFirstName: false,
      autoCapLastName: false,
      email,
      firstName,
      lastName,
      linkedinUrl,
      password: '',
      phone,
      salesExperience,
    };
  }

  componentDidMount() {
    const {
      location,
      actions: { saveUTMInfo },
    } = this.props;

    if (!isEmpty(location.query)) {
      saveUTMInfo({ utmInfo: location.query });
    }

    this.handleOnBlur({ target: { name: 'password', value: '' } });
  }

  handleOnBlur = (event) => {
    const {
      actions: { onboardingErrorsCheck, createLeadStarted },
    } = this.props;

    const { firstName, lastName } = this.state;

    const {
      target: { name, value: newValue },
    } = event;

    if (name === 'email') {
      const leadsData = {
        config: {
          lead: {
            email: newValue,
            firstName,
            lastName,
          },
        },
      };

      createLeadStarted({ leadsData });
    } else {
      onboardingErrorsCheck({ newValue, name });
    }

    this.setState({
      [name]: newValue,
    });
  };

  autoCap = (value) => value.charAt(0).toUpperCase() + value.slice(1);

  handleAutoCap = ({ newValue, name }) => {
    const { autoCapFirstName, autoCapLastName } = this.state;

    if (name === 'firstName') {
      if (newValue === '') {
        return this.setState({
          autoCapFirstName: false,
          firstName: newValue,
        });
      }
      if (!autoCapFirstName) {
        return this.setState({
          autoCapFirstName: true,
          firstName: this.autoCap(newValue),
        });
      }
      return this.setState({
        firstName: newValue,
      });
    }
    if (name === 'lastName') {
      if (newValue === '') {
        return this.setState({
          autoCapLastName: false,
          lastName: newValue,
        });
      }
      if (!autoCapLastName) {
        return this.setState({
          autoCapLastName: true,
          lastName: this.autoCap(newValue),
        });
      }
      return this.setState({
        lastName: newValue,
      });
    }
  };

  handleInputChange = (event) => {
    const {
      target: { name, value, checked, type },
    } = event;

    const newValue = type === 'checkbox' ? checked : value;

    const {
      actions: { onboardingErrorsCheck, onboardingErrorsSet },
      onboardingErrors,
    } = this.props;

    onboardingErrorsCheck({ newValue, name });

    if (name === 'firstName' || name === 'lastName') {
      return this.handleAutoCap({ newValue, name });
    }

    if (name === 'email') {
      onboardingErrorsSet({
        inputErrors: {
          ...onboardingErrors,
          email: {
            ...onboardingErrors.email,
            isAvailable: [true],
          },
        },
      });
    }

    this.setState({
      [name]: newValue,
    });
  };

  handleSubmitForm = (event) => {
    event.preventDefault();

    const {
      actions: { forumApplyStarted, onboardingErrorsSet },
      onboardingErrors,
    } = this.props;

    const { firstName, lastName, email, linkedinUrl, password, phone, salesExperience } =
      this.state;

    const applyData = {
      firstName,
      lastName,
      email,
      linkedinUrl,
      password,
      phone,
      salesExperience,
    };

    promiseEmailValid({ email })
      .then((response) => {
        if (response.email) {
          onboardingErrorsSet({
            inputErrors: {
              ...onboardingErrors,
              email: {
                ...onboardingErrors.email,
                isAvailable: [false, 'email'],
              },
            },
          });
        } else {
          forumApplyStarted({ applyData });
        }
      })
      .catch(handleError);
  };

  render() {
    const { firstName, lastName, email, linkedinUrl, password, phone, salesExperience } =
      this.state;

    const { onboardingErrors, serverErrors = [] } = this.props;

    const serverErrorMessage = serverErrors.length > 0 ? serverErrors[0] : null;

    const serverErrorContent = serverErrorMessage ? (
      <div className={styles.errorMessage}>{serverErrorMessage}</div>
    ) : null;

    const formProps = {
      email,
      firstName,
      handleInputChange: this.handleInputChange,
      handleOnBlur: this.handleOnBlur,
      handleSubmitForm: this.handleSubmitForm,
      lastName,
      linkedinUrl,
      password,
      phone,
      onboardingErrors,
      salesExperience,
      serverErrorContent,
    };

    const title = 'Sales Jobs in Tech | Rainmakers';
    const metaDescription =
      'Sign up with Rainmakers to tap into a premium career network and connect with top tech companies in search of dynamic sales professionals. Various sales positions available!';
    const canonicalUrl = `https://${process.env.HOST}/apply-now/`;

    const helmetProps = {
      key: 'helmet',
      title,
      meta: [
        { name: 'description', content: metaDescription },
        { property: 'og:url', content: canonicalUrl },
        { property: 'og:title', content: title },
        { property: 'og:description', content: metaDescription },
      ],
      link: [{ rel: 'canonical', href: canonicalUrl }],
    };

    return (
      <React.Fragment>
        <Helmet {...helmetProps} />
        <ReadyContainer key="Apply" className={styles.Container}>
          <div key="title" className={styles.Title}>
            RAINMAKERS SALES COMMUNITY
          </div>
          <div key="form" className={styles.CandidateRegistration}>
            <Graphic />
            <Form {...formProps} />
          </div>
        </ReadyContainer>
      </React.Fragment>
    );
  }
}

export default ConnectContainer(duck)(ForumApply);
