import { createStructuredSelector } from 'reselect';

import { entitySelectorFactory } from 'reduxStore/selectorFactories';

import { selectUser, selectUTMInfo } from 'connectors/User/redux/selectors';
import { getApprovedStatus } from 'shared/store/app/selectors';

import {
  selectLogoutPending,
  selectIsCandidate,
  selectIsAdmin,
} from 'connectors/Login/redux/selectors';

import selectOnboardingErrors from './selectOnboardingErrors';
import selectServerErrors from './selectServerErrors';
import selectOnboardingData from './selectOnboardingData';
import selectParsingResume from './selectParsingResume';
import selectRecentRole from './selectRecentRole';

const selector = createStructuredSelector({
  isAdmin: selectIsAdmin,
  isApproved: getApprovedStatus,
  isCandidate: selectIsCandidate,
  logoutPending: selectLogoutPending,
  onboardingData: selectOnboardingData,
  onboardingErrors: selectOnboardingErrors,
  parsingResume: selectParsingResume,
  serverErrors: selectServerErrors,
  recentRole: selectRecentRole,
  user: selectUser,
  utmInfo: selectUTMInfo,
  vimeoVideos: entitySelectorFactory('candidateEditor', 'vimeoVideo'),
});

export {
  selectParsingResume,
  selectOnboardingData,
  selectOnboardingErrors,
  selectServerErrors,
  selectRecentRole,
  selector as default,
};
