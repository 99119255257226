import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash.isempty';
import { Helmet } from 'react-helmet-async';
import { Route, Switch, useParams } from 'react-router-dom';

import { handleError } from 'utils/common';
import { getEntity } from 'api/apiEntity';

import ReadyContainer from 'connectors/ReadyContainer';

import Block from 'components/Block';
import Sidebar from 'components/Sidebar';

import { AdminAuthHOC, ModalHOC } from 'containers/HOC';
import ConnectContainer from 'containers/ConnectContainer';

import RequisitionOpening from 'pages/AdminPortal/ToolboxRequisitionEditorPage/components/RequisitionOpening';
import RequisitionOpeningView from 'pages/AdminPortal/ToolboxRequisitionEditorPage/components/RequisitionOpeningView';
import CandidateNonModalWrapper from 'pages/EmployeePortal/CandidateProfilePages/CandidateNonModalWrapper';

import { usePrevious } from 'hooks';

import duck from 'pages/AdminPortal/ToolboxApproachesPage/redux';

import ApproachEditorAdmin from './components/ApproachEditorAdmin';
import ApproachEditorEmployee from './components/ApproachEditorEmployee';

import styles from './ToolboxApproachEditor.scss';

const ToolboxApproachEditor = (props) => {
  const {
    actions: { pickerOptionsLoadStarted, entityLoadStarted, entityLoadDone },
    isAdmin,
    candidates: { byId: candidatesById = {} } = {},
    requisitionOpenings: { byId: requisitionOpeningsById = {} } = {},
    requisitionCandidate: {
      relationships: {
        candidate: { data: candidateIds = [] } = {},
        requisitionOpeningCopy: { data: requisitionOpeningIds = [] } = {},
      } = {},
    } = {},
    saving,
  } = props;

  const params = useParams();
  const { view, id } = params;
  const prevId = usePrevious(id);
  const [state, setState] = useState({
    showFeedback: false,
    changedAttributes: [],
  });

  useEffect(() => {
    doReqCandLoad(id);
    doMessagesLoad(id);

    pickerOptionsLoadStarted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const nextId = id;
    const currId = prevId;

    if (nextId !== currId) {
      doReqCandLoad(nextId);
      doMessagesLoad(nextId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const doMessagesLoad = async (id) => {
    const args = {
      type: 'message_groups',
      config: {
        params: {
          linkedObjectId: id,
          linkedObjectType: 'RequisitionCandidate',
          normalizeIt: true,
          page: 1,
        },
      },
    };

    let messageGroupData;
    try {
      messageGroupData = await getEntity(args);
    } catch (error) {
      handleError(error);
    }

    entityLoadDone({
      slice: 'toolboxApproaches',
      type: 'message_groups',
      data: messageGroupData,
      updateOnly: true,
    });

    const { data: { entities: { messageGroup: { allIds = [] } = {} } = {} } = {} } =
      messageGroupData || {};

    const messageGroupId = allIds && allIds[0];

    if (messageGroupId) {
      entityLoadStarted({
        type: 'messages',
        slice: 'toolboxApproaches',
        params: {
          page: 1,
          messageGroupId,
          sortBy: 'created_at_desc',
        },
      });

      entityLoadStarted({
        type: 'message_group_members',
        slice: 'toolboxApproaches',
        params: {
          page: 1,
          messageGroupId,
          sortBy: 'profile_type_asc,created_at_asc',
          includeSet: 'profile',
        },
      });
    }
  };

  const doReqCandLoad = async (id) => {
    const args = {
      config: {
        params: {
          includeSet: 'admin_toolbox',
          normalizeIt: true,
        },
      },
      type: 'requisition_candidates',
      id,
    };

    const nestedArgs = {
      config: {
        params: {
          normalizeIt: true,
          requisitionCandidateId: id,
        },
      },
      type: 'private_req_can_feedbacks',
    };

    let reqCandData;
    let byId;
    let allIds;
    let candidatesById;
    let privateReqCanData;
    try {
      [
        {
          data: reqCandData = {},
          data: {
            entities: {
              requisitionCandidate: { byId = {}, allIds = [] } = {},
              candidate: { byId: candidatesById = {} } = {},
            } = {},
          } = {},
        },
        { data: privateReqCanData = {} },
      ] = await Promise.all([getEntity(args), getEntity(nestedArgs)]);
    } catch (error) {
      handleError(error);
    }

    const {
      relationships: {
        candidate: { data: candidateIds = [] } = {},
        employer: { data: employerIds = [] } = {},
      } = {},
    } = byId[allIds[0]] || {};

    const { attributes: { rainmakersAdminId } = {} } = candidatesById[candidateIds[0]] || {};

    if (employerIds[0]) {
      entityLoadStarted({
        params: {
          page: 1,
          sortBy: 'last_name_asc',
          employerId: employerIds[0],
        },
        slice: 'toolboxApproaches',
        type: 'employees',
      });
    }
    if (rainmakersAdminId) {
      entityLoadStarted({
        slice: 'toolboxApproaches',
        type: 'rainmakers_admins',
        id: rainmakersAdminId,
        params: {
          includeSet: 'user',
          normalizeIt: true,
        },
      });
    }

    entityLoadDone({
      slice: 'toolboxApproaches',
      type: 'requisition_candidates',
      data: reqCandData,
      updateOnly: true,
    });

    entityLoadDone({
      slice: 'toolboxApproaches',
      type: 'private_req_can_feedback',
      data: privateReqCanData,
      updateOnly: true,
    });
  };

  const handleSetState = (state) =>
    setState((prevState) => ({
      ...prevState,
      ...state,
    }));

  const { [candidateIds[0]]: { attributes: candidate = {}, attributes: { fullName } = {} } = {} } =
    candidatesById || {};

  const requisitionOpening = requisitionOpeningsById[requisitionOpeningIds[0]] || {};

  const newProps = {
    ...props,
    ...state,
    candidateLoading: isEmpty(candidate),
    candidate,
    candidateProfileReqs: [],
    handleSetState,
    match: {
      params: {
        ...params,
        requisitionCandidateId: id,
        reqCandId: id,
      },
    },
    requisitionOpening: {
      ...requisitionOpening,
      attributes: {
        ...requisitionOpening.attributes,
        state: 'pending',
      },
    },
    requisitionOpeningErrors: {},
    slice: 'toolboxApproaches',
  };

  const candProps = {
    ...props,
    ...state,
    match: {
      params: {
        ...params,
        id: candidateIds[0],
        view: 'profile',
      },
    },
  };

  const sidebarProps = {
    type: 'toolboxApproachEditor',
    page: view,
    id,
  };

  const savingContent = saving ? (
    <div className={styles.autoSaving}>Saving...</div>
  ) : (
    <div className={styles.autoSaved}>Saved</div>
  );

  const saveContent = saving === undefined ? null : savingContent;

  const titleBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    addPadding: true,
    largeTitleFont: false,
    className: styles.titleBlock,
  };

  return (
    <React.Fragment>
      <Helmet key="helmet" title="Approach Editor" />
      <ReadyContainer key="readyContainer">
        <Sidebar {...sidebarProps} />

        <Switch>
          <Route exact path={'/toolbox/approach/:id/candidate'}>
            <div className={styles.Profile}>
              <CandidateNonModalWrapper {...candProps} />
            </div>
          </Route>
          <Route exact path={'/toolbox/approach/:id/messages'}>
            {isAdmin ? (
              <ApproachEditorAdmin {...newProps} />
            ) : (
              <ApproachEditorEmployee {...newProps} />
            )}
          </Route>
          <Route exact path={'/toolbox/approach/:id/details'}>
            <div className={styles.editReq}>
              <Block {...titleBlockProps}>
                <div className={styles.titleContainer}>
                  <div className={styles.title}>This is the Requisition for {fullName} only</div>
                  <div className={styles.saveContent}>{saveContent}</div>
                </div>
              </Block>
              <div className={styles.scrollDiv}>
                <RequisitionOpening {...newProps} />
              </div>
            </div>
          </Route>
          <Route exact path={'/toolbox/approach/:id/preview'}>
            <div className={styles.viewReq}>
              <RequisitionOpeningView {...newProps} />
            </div>
          </Route>
        </Switch>
      </ReadyContainer>
    </React.Fragment>
  );
};

export default ConnectContainer(duck)(
  AdminAuthHOC()(
    ModalHOC({ paginatorUrl: '/toolbox/approach/:id/messages' })(ToolboxApproachEditor)
  )
);
