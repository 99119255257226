import React from 'react';

import ProgressItem from 'components/ProgressItem';
import styles from './ProgressNav.scss';

const ProgressNav = ({ segments, numFilled }) => {
  const bars = segments.map((label, order) => {
    const progItemProps = {
      label,
      order,
      filled: order < numFilled,
      current: order === numFilled,
      last: order === segments.length - 1,
    };

    return <ProgressItem key={label} {...progItemProps} />;
  });

  return <div className={styles.barsContainer}>{bars}</div>;
};

export default ProgressNav;
