// Code copied & modified from https://github.com/gabidavila/react-usa-map
// MIT Licence

import React from 'react';

const USAState = (props) => {
  return (
    <path
      d={props.dimensions}
      fill={props.fill}
      opacity={props.opacity}
      data-name={props.state}
      className={`${props.state} state`}
      onClick={props.onClickState}
      onMouseOver={props.onMouseOverState}
      onMouseEnter={props.onMouseEnterState}
      onMouseLeave={props.onMouseLeaveState}
    >
      <title>{props.stateName}</title>
    </path>
  );
};
export default USAState;
