import { all, fork } from 'redux-saga/effects';

import sagaShowModal from './sagaShowModal';
import sagaHideModal from './sagaHideModal';

const sagaModal = function* sagaModal() {
  yield all([fork(sagaShowModal), fork(sagaHideModal)]);
};

export default sagaModal;
