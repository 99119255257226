import React, { useState } from 'react';
import classnames from 'classnames';

import { toggleArray } from 'utils/formUtils';

import Block from 'components/Block';
import Content from 'components/Content';
import InputCheckboxSlider from 'components/InputCheckboxSlider';
import Label from 'components/Label';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import InputSelect from 'components/InputSelect';
import TalentConcierge from 'components/TalentConcierge';

import { employerHomePageValues } from 'utils/constants';

import CohortEmployersTable from './components/CohortEmployersTable';
import CohortLinksTable from './components/CohortLinksTable';

import BlockedSettings from './components/BlockedSettings';
import CandReqBlocksSettings from './components/CandReqBlocksSettings';

import styles from './EmployerEditorSettings.scss';

const EmployerEditorSettings = (props) => {
  const {
    actions,
    candidates,
    candidateCompanyBlocks,
    candidateCompanyBlocksLoading,
    candidateCompanyBlocksTotal,
    cohorts,
    cohorts: { byId: cohortsById = {} } = {},
    cohortLinks,
    cohortLinksLoading,
    cohortLinksTotal,
    cohortLinksTemporary,
    cohortLinksTemporaryLoading,
    cohortLinksTemporaryTotal,
    creators,
    employer,
    employer: {
      attributes: {
        enableMessaging,
        atsRequested,
        enableGreenhouse,
        blindHiringRequested,
        hideCandidateNames,
        ssoRequested,
        ssoEnabled,
        rainmakersAdminId,
        homepage,
        saas,
      } = {},
      id: employerId,
    } = {},
    employees = {},
    cohortEmployers = {},
    cohortEmployers: { allIds = [], byId = {} } = {},
    cohortEmployersTotal,
    cohortEmployersLoading,
    modal,
    rainmakersAdmins = {},
    requisitionCandidates,
    requisitionCandidatesTotal,
    requisitionCandidatesLoading,
    accountManagers: { allIds: accountManagersAllIds = [], byId: accountManagersById = {} } = {},
    users: { byId: usersById = {} } = {},
  } = props;

  const { relationships: { cohort: { data: cohortIds = [] } = {} } = {} } = byId[allIds[0]] || {};

  const cohort = cohortsById[cohortIds[0]] || {};

  const [state, setState] = useState({
    selectedCohort: cohort || {},
    cohortCategory: ['search', 'featured'],
    cohortState: ['open', 'active'],
    showAssigned: true,
  });

  const {
    selectedCohort,
    selectedCohort: { attributes: { name: cohortName = '' } = {} } = {},
    cohortState,
    cohortCategory,
    showAssigned,
  } = state;

  const handleEmployerInputChange = ({ target: { value, name, checked, type } }) => {
    const {
      actions: { patchResource },
      employer: { id: employerId },
    } = props;

    const newValue = type === 'checkbox' ? checked : value;

    patchResource({
      type: 'employers',
      id: employerId,
      attributes: { [name]: newValue },
      slice: 'toolboxEmployers',
    });
  };

  const handleSearchFilterChange = ({ target: { name, value } }) => {
    const {
      actions: { entityLoadStarted },
      employer: { id: employerId },
    } = props;

    const { [name]: arrayToUpdate } = state;

    const actualValue = value[value.type];

    const newArray = toggleArray({ array: arrayToUpdate, value: actualValue });

    const { cohortState, cohortCategory } = state;

    setState((prevState) => ({ ...prevState, [name]: newArray, selectedCohort: {} }));

    entityLoadStarted({
      type: 'cohort_employers',
      slice: 'toolboxEmployers',
      params: {
        page: 1,
        employerId,
        cohortState,
        cohortCategory,
        includeSet: 'cohort',
        sortBy: 'created_at',
        [name]: newArray,
      },
    });
  };

  const handlePermBlockChange = ({ target: { value } }) => {
    const {
      actions: { postResource },
      employer: { attributes: { companyId } = {} } = {},
      user: { currentProfile: { id: ownerId } = {} } = {},
    } = props;

    postResource({
      type: 'candidate_company_blocks',
      slice: 'toolboxEmployers',
      attributes: {
        candidateId: value.id,
        companyId,
        ownerId,
        ownerType: 'RainmakersAdmin',
        blockType: 'rainmakers_admin',
      },
      params: {
        includeSet: 'candidate_creator',
      },
      normalizeIt: true,
      requiredFields: [],
    });
  };

  const handleSelectCohort = (cohort) => {
    const {
      actions: { nestedEntityLoadStarted },
      employer: { id: employerId },
    } = props;

    setState((prevState) => ({ ...prevState, selectedCohort: cohort }));

    nestedEntityLoadStarted({
      type: 'cohorts',
      nestedType: 'cohort_links',
      slice: 'toolboxEmployers',
      id: cohort.id,
      params: {
        page: 1,
        employerId,
        includeSet: 'candidate',
        sortBy: 'candidate_first_name_asc,candidate_last_name_asc',
      },
    });
  };

  const loadMoreRequisitionCandidates = () => {
    const {
      actions: { entityLoadStarted },
      requisitionCandidatesPage: page,
      employer: { id: employerId },
    } = props;

    entityLoadStarted({
      type: 'requisition_candidates',
      slice: 'toolboxEmployers',
      params: {
        page: page + 1,
        employerId,
        sortBy: 'created_at_desc',
        includeSet: 'admin_toolbox',
        state: ['active', 'candidate_pass_pending'],
      },
    });
  };

  const loadMoreCohortEmployers = () => {
    const {
      actions: { entityLoadStarted },
      cohortEmployersPage: page,
      employer: { id: employerId },
    } = props;

    const { cohortState, cohortCategory } = state;

    entityLoadStarted({
      type: 'cohort_employers',
      slice: 'toolboxEmployers',
      params: {
        page: page + 1,
        employerId,
        cohortState,
        cohortCategory,
        includeSet: 'cohort',
        sortBy: 'created_at',
      },
    });
  };

  const loadMoreCohortLinks = () => {
    const {
      actions: { nestedEntityLoadStarted },
      cohortLinksPage: page,
      employer: { id: employerId },
    } = props;

    const { selectedCohort: { id } = {} } = state;

    nestedEntityLoadStarted({
      nestedType: 'cohort_links',
      type: 'cohorts',
      id,
      slice: 'toolboxEmployers',
      params: {
        employerId,
        includeSet: 'candidate',
        sortBy: 'candidate_first_name_asc,candidate_last_name_asc',
        page: page + 1,
      },
    });
  };

  const loadMoreCandidateCompanyBlocks = () => {
    const {
      actions: { entityLoadStarted },
      candidateCompanyBlocksPage: page,
      employer: { attributes: { companyId } = {} } = {},
    } = props;

    entityLoadStarted({
      type: 'candidate_company_blocks',
      slice: 'toolboxEmployers',
      params: {
        page: page + 1,
        companyId,
        sortBy: 'created_at_desc',
        includeSet: 'candidate_creator',
      },
    });
  };

  const loadMoreCohortLinksTemporary = () => {
    const {
      actions: { entityLoadStarted },
      cohortLinksTemporaryPage: page,
      employer: { id: employerId } = {},
    } = props;

    entityLoadStarted({
      type: 'cohort_links',
      typeOverride: 'cohort_links_temporary',
      slice: 'toolboxEmployers',
      params: {
        page: page + 1,
        employerId,
        includeSet: 'candidate',
        cohortVirtual: true,
        state: 'blocked',
        sortBy: 'candidate_first_name_asc',
      },
    });
  };

  const handleChangeAdmin = ({ target: { value, name } }) => {
    const {
      actions: { patchResource },
      employer: { id },
    } = props;

    patchResource({
      type: 'employers',
      id,
      attributes: { [name]: value },
      slice: 'toolboxEmployers',
      normalizeIt: true,
    });
  };

  const cohortLinkTitleString = cohortName
    ? `Candidate Assignment for ${cohortName} Cohort`
    : 'Select a cohort to show candidates';

  const cohortLinksTitle = <div className={styles.cohortBlockTitle}>{cohortLinkTitleString}</div>;

  const cohortsTableProps = {
    actions,
    cohorts,
    cohortCategory,
    cohortEmployers,
    cohortEmployersLoading,
    cohortEmployersTotal,
    cohortState,
    employerId,
    handleSearchFilterChange,
    handleSelectCohort,
    loadMoreCohortEmployers,
    selectedCohort,
  };

  const cohortLinksTableProps = {
    candidates,
    cohortLinks: cohortName ? cohortLinks : {},
    cohortLinksLoading,
    cohortLinksTotal,
    loadMoreCohortLinks,
    titleContent: cohortLinksTitle,
  };

  const blockedSettingsProps = {
    actions,
    candidates,
    candidateCompanyBlocks,
    candidateCompanyBlocksTotal,
    candidateCompanyBlocksLoading,
    employees,
    creators,
    modal,
    employer,
    cohortLinksTemporary,
    cohortLinksTemporaryTotal,
    cohortLinksTemporaryLoading,
    loadMoreCohortLinksTemporary,
    loadMoreCandidateCompanyBlocks,
    handlePermBlockChange,
    rainmakersAdmins,
  };

  const employerApproachesProps = {
    candidates,
    requisitionCandidates,
    requisitionCandidatesLoading,
    requisitionCandidatesTotal,
    loadMoreRequisitionCandidates,
  };

  const activeContent = showAssigned ? (
    <>
      <CohortEmployersTable {...cohortsTableProps} />
      <CohortLinksTable {...cohortLinksTableProps} />
    </>
  ) : (
    <>
      <BlockedSettings {...blockedSettingsProps} />
      <CandReqBlocksSettings {...employerApproachesProps} />
    </>
  );

  const assignedTabClasses = classnames(styles.mainTab, {
    [styles.mainTabActive]: showAssigned,
  });

  const blockedTabClasses = classnames(styles.mainTab, {
    [styles.mainTabActive]: !showAssigned,
  });

  const assignedTabProps = {
    onClick: () => setState({ showAssigned: true }),
    className: assignedTabClasses,
  };

  const blockedTabProps = {
    onClick: () => setState({ showAssigned: false }),
    className: blockedTabClasses,
  };

  const mainTabsTitleContent = (
    <div className={styles.tabContainer}>
      <div {...assignedTabProps}>Assigned</div>
      <div {...blockedTabProps}>Blocked</div>
    </div>
  );

  const contentBlockProps = {
    boxShadow: true,
    addChildFlex: true,
    noTitlePadding: true,
    title: mainTabsTitleContent,
    addWhiteBG: true,
    className: styles.firstBlock,
  };

  const featuresBlockProps = {
    ...contentBlockProps,
    title: 'Features',
    noTitlePadding: false,
  };

  const enableMessagingProps = {
    name: 'enableMessaging',
    checked: enableMessaging,
    handleInputChange: handleEmployerInputChange,
    label: enableMessaging ? 'On' : 'Off',
  };

  const enableGreenhouseProps = {
    name: 'enableGreenhouse',
    checked: enableGreenhouse,
    handleInputChange: handleEmployerInputChange,
    label: enableGreenhouse ? 'On' : 'Off',
  };

  const hideCandidateNamesProps = {
    name: 'hideCandidateNames',
    checked: hideCandidateNames,
    handleInputChange: handleEmployerInputChange,
    label: hideCandidateNames ? 'On' : 'Off',
  };

  const { attributes, relationships: { user: { data: adminUserIds = [] } = {} } = {} } =
    accountManagersById[rainmakersAdminId] || {};

  const { attributes: user = {} } = usersById[adminUserIds[0]] || {};

  const adminSource = accountManagersAllIds.map((id) => {
    const { attributes: { firstName, lastName } = {} } = accountManagersById[id] || {};

    return {
      label: `${firstName} ${lastName}`,
      value: id,
    };
  });

  const adminInputProps = {
    source: adminSource,
    name: 'rainmakersAdminId',
    handleInputChange: handleChangeAdmin,
    value: rainmakersAdminId || '',
    defaultLabel: 'Update Admin',
    defaultIcon: 'caret-down',
    noMargin: true,
    setWidth: '160px',
    bigInput: true,
  };

  const talentConciergeProps = {
    admin: {
      ...attributes,
      user,
    },
    adminInput: <InputSelect {...adminInputProps} />,
    adminTitle: 'Account Manager',
    emailSubject: 'Account Manager Inquiry',
    candidateName: 'Admin',
  };

  const homepageProps = {
    handleInputChange: handleEmployerInputChange,
    type: 'text',
    size: 'full',
    topLabel: true,
    name: 'homepage',
    label: 'Employer Homepage',
    value: homepage,
    source: Object.entries(employerHomePageValues).map(([value, { label }]) => ({
      label,
      value,
    })),
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    defaultLabel: 'Stage',
    bigInput: true,
    noMargin: true,
  };

  const atsRequestedCont = atsRequested ? '(Requested)' : null;
  const blindHiringRequestedCont = blindHiringRequested ? '(Requested)' : null;
  const ssoRequestStatusCont = ssoRequested ? '(Requested)' : null;
  const ssoEnabledCont = ssoEnabled ? 'is Enabled' : 'is Disabled';

  return (
    <LayoutWithoutSidebar
      content={
        <div className={styles.EditorSettings}>
          <div className={styles.scrollDiv}>
            <Block {...featuresBlockProps}>
              <div className={styles.featuresContainer}>
                <TalentConcierge {...talentConciergeProps} />
                <Content className={styles.checkboxContainer}>
                  <InputSelect {...homepageProps} />
                  <Label standalone>Messaging</Label>
                  <InputCheckboxSlider {...enableMessagingProps} />
                  <Label standalone>Greenhouse Job Linking {atsRequestedCont}</Label>
                  <InputCheckboxSlider {...enableGreenhouseProps} />
                  <Label standalone>Blind Hiring {blindHiringRequestedCont}</Label>
                  <InputCheckboxSlider {...hideCandidateNamesProps} />
                  <Label standalone>Saas Customer</Label>
                  <InputCheckboxSlider
                    name={'saas'}
                    checked={saas}
                    handleInputChange={handleEmployerInputChange}
                    label={saas ? 'On' : 'Off'}
                  />
                  <Label standalone>
                    SSO {ssoEnabledCont} {ssoRequestStatusCont}
                  </Label>
                </Content>
              </div>
            </Block>
            <Block {...contentBlockProps}>{activeContent}</Block>
          </div>
        </div>
      }
    />
  );
};

export default EmployerEditorSettings;
