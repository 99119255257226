import React, { useState, useRef, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import cn from 'classnames';

import { useOnClickOutside } from 'hooks';
import FontIcon from 'components/FontIcon';

import styles from './Dropdown.scss';

const Dropdown = ({
  actions,
  actionsOneOption,
  searchFilter,
  className,
  defaultValue,
  infiniteScrollProps,
  label,
  large,
  left,
  above,
  source,
  value,
  labelPrefix,
  reqFilter,
  locFilter,
  defaultIcon,
  activeIcon,
  closedReq,
  optionsLarge,
  optionsMedium,
  disabled,
  hasRemove,
  handleRemove,
  name,
  hasTitleInDropdown,
  hasTitleContent,
  onChange,
  ...restProps
}) => {
  const [active, setActive] = useState(false);
  const ref = useRef();
  const scrollParentRef = useRef();

  useOnClickOutside(
    ref,
    useCallback(() => setActive(false), [])
  );

  const onClick = () => setActive((active) => !active);

  const onSelect = (item) => {
    if (active) {
      const arg = name
        ? {
            ...item,
            filter: name,
          }
        : item.value;

      onChange(arg);
    }
  };

  const [currentValue] = source.filter((item) => `${item.value}` === `${value}`);

  const currentItem = currentValue || { label: defaultValue, value: null };

  const classes = cn(
    styles.Dropdown,
    {
      [styles.actions]: !closedReq && actions,
      [styles.actionsOneOption]: actionsOneOption,
      [styles.closedActions]: closedReq,
      [styles.active]: active,
      [styles.searchFilterActive]: active && searchFilter,
      [styles.searchFilter]: searchFilter,
      [styles.activeInPlace]: searchFilter && currentValue,
      [styles.large]: large,
      [styles.left]: left,
      [styles.above]: above,
      [styles.disabled]: disabled,
    },
    className
  );

  const optionsContainerClasses = cn(styles.OptionsContainer, {
    [styles.ReqOptionsContainer]: reqFilter,
    [styles.largeContainer]: optionsLarge,
    [styles.mediumContainer]: optionsMedium,
    [styles.searchContainer]: searchFilter,
  });

  const makeOptions = (item, id) => {
    const { label, value } = item;
    const addReqBorder = reqFilter && id === 1;
    const addActionBorder = actions && id === 3;
    const isReq = reqFilter && id > 1;
    const optionClasses = cn(styles.Option, {
      [styles.reqs]: isReq,
      [styles.topBorderAction]: addActionBorder,
      [styles.topBorderReq]: addReqBorder,
      [styles.activeOption]: `${currentItem.value}` === `${value}` && !actions && !actionsOneOption,
    });

    const onClick = disabled ? () => null : () => onSelect(item);

    const optionProps = {
      className: optionClasses,
      onClick,
      value,
    };

    const indentString =
      locFilter && item.indent > 0
        ? new Array(item.indent).fill(<span className={styles.indent}>&mdash;</span>)
        : null;

    const content = (
      <div key={value} {...optionProps}>
        {indentString}
        {label}
      </div>
    );

    return content;
  };

  const content = active && source.length > 0 ? source.map(makeOptions) : null;

  if (hasRemove && content) {
    const removeProps = {
      className: cn(styles.Option, styles.removeFilter),
      key: 'remove',
      onClick: () => handleRemove(name),
      value,
    };

    const removeContent = <div {...removeProps}>Remove filter</div>;

    content.unshift(removeContent);
  }

  if (hasTitleInDropdown && content) {
    const titleProps = {
      className: cn(styles.Option, styles.titleFilter),
      key: 'title',
    };

    const titleContent = <div {...titleProps}>{hasTitleContent}</div>;

    content.unshift(titleContent);
  }

  const prefixForLabel = labelPrefix ? (
    <span className={styles.labelPrefix}>{labelPrefix}</span>
  ) : null;

  const defaultLabelContent = reqFilter ? currentItem.reqLabel : currentItem.label;

  const inPlaceDefaultLabelClasses = cn(styles.inPlaceDefaultLabel);

  const defaultLabel =
    actions || actionsOneOption ? (
      <div className={styles.DefaultLabel} data-testid="defaultLabel">
        {defaultValue}
      </div>
    ) : (
      <div className={inPlaceDefaultLabelClasses} data-testid="defaultLabel">
        {prefixForLabel}
        {defaultLabelContent}
      </div>
    );

  const labelField = label ? (
    <span className={styles.Label} data-testid="labelField">
      {label}
    </span>
  ) : null;

  const defaultIconContent = defaultIcon ? <FontIcon iconName={defaultIcon} /> : null;

  const activeIconContent = activeIcon ? <FontIcon iconName={activeIcon} /> : defaultIconContent;

  const iconContent = active ? activeIconContent : defaultIconContent;

  const mainClickHandler = disabled ? null : onClick;

  const infScrollProps = infiniteScrollProps
    ? {
        ...infiniteScrollProps,
        getScrollParent: () => scrollParentRef.current,
      }
    : null;

  const infScrollContent = infScrollProps && (
    <InfiniteScroll {...infiniteScrollProps}>{content}</InfiniteScroll>
  );

  const finalContent = infScrollContent || content;

  return (
    <div className={classes} onClick={mainClickHandler} ref={ref} {...restProps}>
      {labelField}
      {defaultLabel}
      {iconContent}
      <div className={optionsContainerClasses} ref={scrollParentRef}>
        {finalContent}
      </div>
    </div>
  );
};

export default Dropdown;
