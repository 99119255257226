/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import { getAltName } from 'utils/urlUtils';

import pic1 from './assets/phoenix-sales-recruiters-1.png';
import pic2 from './assets/best-sales-recruiters-phoenix-2.png';
import pic3 from './assets/find-sales-people-3.png';
import pic4 from './assets/phoenix-sales-recruiting-process-4.png';
import pic5 from './assets/sales-recruiters-near-me-5.png';
import pic6 from './assets/sales-recruiters-phoenix-6.png';

import styles from './PhoenixContent.scss';

export function PhoenixContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt={getAltName(pic1)} />
      <h2>Connect with Top Sales Talent</h2>
      <p>
        In the beautiful city of Phoenix, Arizona, the average annual rainfall is about nine inches.
        Now imagine this rainfall is the average company salesperson who isn't meeting your needs.
      </p>
      <p>
        That's why you need a <em>rainmaker.</em>
      </p>
      <h3>
        <strong>RAINMAKER - Definition</strong>
      </h3>
      <p>
        <blockquote>
          Someone who generates massive sales and profits for a company and{' '}
          <strong>makes it rain</strong>
        </blockquote>
      </p>
      <p>
        So, how do you find a salesperson in Phoenix, Arizona, who can earn you money? Short answer:
        you use Rainmakers.
      </p>
      <h2>What is Rainmakers?</h2>
      <p>
        Rainmakers is a sales recruitment service operating in Phoenix, Arizona. Our team focuses on
        connecting high-performance sales staff with amazing companies.
      </p>
      <p>
        Our platform is exclusive to top tech salespeople, letting them showcase their
        accomplishments, sales, and hiring requirements. As a company, you get access to this list
        of leading providers, ensuring you find the right fit.
      </p>
      <img src={pic2} alt={getAltName(pic2)} />
      <h2>Why Choose Rainmakers as Your Phoenix Sales Recruiters</h2>
      <p>
        Our proven system connects top sales talent with companies in Phoenix, Arizona. So by hiring
        a rainmaker, you know you are getting someone with a proven history of sales talent.
      </p>
      <p>
        Our platform works for both job seekers and employers. So both parties are encouraged to
        remain active on the platform, creating dynamic conversations and leading to quick matches
        of tech industry talent and the companies that deserve them.
      </p>
      <p>
        Here are the <a href="https://www.rainmakers.co/jobs/">sales positions</a> we support in
        Phoenix:
      </p>
      <ul>
        <li>
          <a href="https://www.rainmakers.co/jobs/sales-development-representative">
            Sales development representatives
          </a>
          . Seek effective professionals who know how to build sales pipelines through cold
          outreach.
        </li>
        <li>
          <strong>
            Account executives (including{' '}
            <a href="https://www.rainmakers.co/jobs/mid-market-account-executive">mid-market</a>,{' '}
            <a href="https://www.rainmakers.co/jobs/enterprise-account-executive">enterprise</a>,
            and <a href="https://www.rainmakers.co/jobs/account-executive">entry-level</a>)
          </strong>
          . Find someone who can work with new and current clients with amazing results.
        </li>
        <li>
          <a href="https://www.rainmakers.co/jobs/customer-success-manager">
            Customer success manager
          </a>
          . Find CSMs who know how to develop and strengthen customer relationships.
        </li>
        <li>
          <a href="https://www.rainmakers.co/jobs/sales-director">Sales directors</a>. Find someone
          to develop strategic plans for new products and services and maintain high customer
          service standards.
        </li>
        <li>
          <a href="https://www.rainmakers.co/jobs/sales-manager">Sales manager</a>. Get someone who
          can manage your sales team's daily operations and strategy.
        </li>
        <li>
          <a href="https://www.rainmakers.co/jobs/vp-sales">VP of sales</a>. Find someone with the
          experience to manage all aspects of your sales department.
        </li>
      </ul>
      <p>
        If you want the top sales talent for your company, Rainmakers has you covered. It all starts
        with our proven recruitment process.
      </p>
      <Link to="/employer-signup/" title="Apply Now">
        <img src={pic3} alt={getAltName(pic3)} />
      </Link>
      <h2>Our Sales Recruitment Process in Phoenix</h2>
      <p>
        Rainmakers is unique because it doesn't just list all salespeople in Phoenix. Instead, it
        focuses solely on quality applicants, so you know you're hiring someone who makes sales.
      </p>
      <p>
        By letting Rainmakers handle the filtering, you can save time during the hiring process—no
        more interviews with people who might be a right fit. Instead,{' '}
        <strong>you can focus on people who meet your performance needs.</strong>
      </p>
      <p>
        We do this by providing applicant listings that show a client's experience and proven sales
        statistics. In addition, our employee listings go deeper than a LinkedIn profile,{' '}
        <strong>showing you where they sold and how much they sold.</strong>
      </p>
      <p>
        No more confirming whether the applicant has true sales experience. Instead, you can find
        everything you need in a quality candidate <em>without leaving the page.</em>
      </p>
      <p>
        Phoenix is a burgeoning market full of many talented salespeople. Knowing this, it isn't
        enough to find someone good at general sales.{' '}
        <strong>
          It would be best if you had someone with experience in selling what you offer.
        </strong>
      </p>
      <p>
        Rainmakers lets you find this out through skill lists that you can easily confirm through
        experience. Search-specific tags, like "SaaS" or "C-Level Experience," will help you find
        someone skilled in exactly what you need.
      </p>
      <p>
        No more wasted time on research, pointless interviews, and job seekers that might help you.
        With Rainmakers, you know the person fits your needs <em>before</em> you start any
        employment conversation.
      </p>
      <img src={pic4} alt={getAltName(pic4)} />
      <h2>Sales Industries We Serve in Phoenix</h2>
      <p>
        Rainmakers specializes in the tech industry in Phoenix. If you're a tech company in the
        Phoenix, AZ, area, Rainmakers can connect you with the right people.
      </p>
      <p>Here are some more specific industries we work with:</p>
      <ul>
        <li>AI Companies (like RadiusAI)</li>
        <li>B2B tech companies (like Avnet)</li>
        <li>Food & beverage tech companies (like AFS Technologies)</li>
        <li>Home tech companies (like Dealty and HomeKey Systems)</li>
        <li>Medical technology companies (like nodMD and Solera Health)</li>
        <li>Payment processing companies (like Replay and Skooki)</li>
        <li>Tech security companies (like Axon)</li>
        <li>Software companies (like Cvent and Chassi)</li>
        <li>Web hosting companies (like GoDaddy)</li>
        <li>And any tech niche you can think of</li>
      </ul>
      <p>
        Rainmakers cover all kinds of tech sales jobs. Our system is built to match high-performing
        salespeople with any niche in the tech industry.
      </p>
      <img src={pic5} alt={getAltName(pic5)} />
      <h2>Phoenix Sales Job Market Insights</h2>
      <p>
        Phoenix's pro-business environment makes it an attractive market for tech companies. As a
        result, the list of industries we have above barely scratches the surface.
      </p>
      <p>
        Phoenix, Arizona, is well on its way to becoming a tier 1 market. This puts it on par with
        cities like <a href="https://www.rainmakers.co/markets/new-york-city">New York</a> and{' '}
        <a href="https://www.rainmakers.co/markets/los-angeles-ca">Los Angeles</a>.
      </p>
      <p>
        During the third quarter of 2022, Phoenix saw a 32% increase in job postings. Many of these
        postings were in the tech industry.
      </p>
      <p>
        Those salaries are also growing at the fastest rate in the nation. At 7.6%, Phoenix heavily
        outpaces the national trend when looking at other cities.
      </p>
      <p>
        Over the past six years, this massive growth has put Arizona into a supercycle (sustained
        growth rate). The work of the city leaders, state officials, and local groups has created a
        friendly environment for new tech companies.
      </p>
      <p>
        Better yet, Arizona creates incentives for companies to invest in the Phoenix area. So,
        companies go to Arizona, stay there, and grow there, creating a continuous flow of new tech
        jobs for hungry salespeople.
      </p>
      <p>
        Phoenix is doing amazing work to make it rain. So, we stay busy in Phoenix and like it that
        way.
      </p>
      <p>
        The Phoenix job market is booming for the tech industry. This boom is expected to continue
        for years to come.
      </p>
      <p>But how do you get started finding your next high-performance salesperson?</p>
      <Link to="/employer-signup/" title="Apply Now">
        <img src={pic6} alt={getAltName(pic6)} />
      </Link>
      <h2>How to Get Started with Our Phoenix Sales Recruiters</h2>
      <p>
        Getting started with our Phoenix Sales Recruiters takes you a few seconds. All you have to
        do is fill out our{' '}
        <a href="https://www.rainmakers.co/employer-signup/">employer signup form</a>.
      </p>
      <p>
        Rainmakers isn't just another recruitment company in Arizona. Our team and platform focus on
        finding top performers in the industry and listing them on our database so you don't have to
        find them.
      </p>
      <p>
        That's less time you spend sifting through applications and more time watching your new
        salesperson get results.
      </p>
      <h2>Ready To Find Your Rainmaker?</h2>
      <p>
        If you're a tech company in Phoenix seeking exceptional sales talent, Rainmakers is your
        go-to solution.
      </p>
      <p>
        With our proven system, unique recruitment process, and focus on quality candidates, we save
        you time and ensure a perfect fit for your sales team. So don’t settle for average when you
        can find your rainmaker with Rainmakers.
      </p>
      <p>
        <a href="https://www.rainmakers.co/employer-signup/">Get started today</a> and unlock the
        full potential of your sales in Phoenix.
      </p>
    </div>
  );
}
