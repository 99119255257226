import { take, call } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { getEntity, patchEntity, patchNestedEntity } from 'api/apiEntity';

import { PATCH_PRIVATE_REQUISITION_CANDIDATE_FEEDBACK_STARTED } from '../actions';

const sagaPatchPrivateRequisitionCandidateFeedbackStarted =
  function* sagaPatchPrivateRequisitionCandidateFeedbackStarted() {
    while (true) {
      const {
        payload: { attributes, isAdmin, reqCandidateId },
      } = yield take(PATCH_PRIVATE_REQUISITION_CANDIDATE_FEEDBACK_STARTED);

      if (isAdmin) {
        try {
          const getArgs = {
            type: 'private_req_can_feedbacks',
            config: {
              params: {
                requisitionCandidateId: reqCandidateId,
                normalizeIt: true,
              },
            },
          };

          const {
            data: {
              entities: { privateReqCanFeedback: { allIds = [], byId = {} } = {} } = {},
            } = {},
          } = yield getEntity(getArgs);

          const id = allIds.find((id) => byId[id].attributes.ownerType === 'Candidate');

          if (id) {
            const args = {
              id,
              type: 'private_req_can_feedbacks',
              data: {
                attributes,
              },
              config: {
                params: {
                  camelizeIt: true,
                },
              },
            };

            yield call(patchEntity, args);
          }
        } catch (error) {
          handleError(error);
        }
      } else {
        try {
          const args = {
            id: reqCandidateId,
            type: 'requisition_candidates',
            nestedType: 'private_req_can_feedback',
            data: {
              attributes,
            },
            config: {
              params: {
                camelizeIt: true,
              },
            },
          };

          yield call(patchNestedEntity, args);
        } catch (error) {
          handleError(error);
        }
      }
    }
  };

export default sagaPatchPrivateRequisitionCandidateFeedbackStarted;
