import { get } from '../api';

const route = '/candidates';

const getCandidateBullhornInfo = (candidateId) =>
  get(`${route}/${candidateId}/bullhorn_push`).catch((response) => {
    const {
      response: { status },
    } = response;
    return status === 404 ? Promise.resolve(response) : Promise.reject(response);
  });

export default getCandidateBullhornInfo;
