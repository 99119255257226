import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';

import { AdminAuthHOC } from 'containers/HOC';

import ConnectContainer from 'containers/ConnectContainer';

import ReadyContainer from 'connectors/ReadyContainer';

import Sidebar from 'components/Sidebar';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';

import ToolboxLeadsTable from './components/ToolboxLeadsTable';

import duck from './redux';

import styles from './ToolboxLeadsPage.scss';

@AdminAuthHOC()
class ToolboxLeadsPage extends Component {
  constructor(props) {
    super(props);

    this.defaultLoadParams = {
      page: 1,
      sortBy: 'created_at_desc',
    };

    this.defaultLoadArgs = {
      type: 'leads',
      slice: 'toolboxLeads',
    };
  }

  componentDidMount() {
    const {
      actions: { entityLoadStarted },
    } = this.props;

    entityLoadStarted({
      ...this.defaultLoadArgs,
      params: {
        ...this.getLoadParams(),
        page: 1,
      },
    });
  }

  getLoadParams = () => {
    const { page = 1 } = this.props;

    return {
      ...this.defaultLoadParams,
      page,
    };
  };

  loadMoreLeads = () => {
    const {
      actions: { entityLoadStarted },
      page,
    } = this.props;

    entityLoadStarted({
      ...this.defaultLoadArgs,
      params: {
        ...this.getLoadParams(),
        page: page + 1,
      },
    });
  };

  render() {
    const {
      children,
      isLoading,
      leads: { byId = {}, allIds = [] },
      total,
      user,
    } = this.props;

    const leads = allIds.map((id) => {
      const { [id]: lead } = byId;

      return lead;
    });

    const tableProps = {
      isLoading,
      user,
      leads,
      total,
      loadMoreLeads: this.loadMoreLeads,
    };

    const content = (
      <div className={styles.toolboxLeadsMainContent}>
        <ToolboxLeadsTable {...tableProps} />
      </div>
    );

    const layoutWithoutSidebarProps = {
      content,
    };

    return (
      <React.Fragment>
        <Helmet key="helmet" title="Toolbox - Leads" />
        <ReadyContainer key="readyContainer">
          <Sidebar type="toolbox" page="toolboxLeads" />

          <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />

          {children}
        </ReadyContainer>
      </React.Fragment>
    );
  }
}

export default ConnectContainer(duck)(ToolboxLeadsPage);
