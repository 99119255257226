/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import { getAltName } from 'utils/urlUtils';

import pic1 from './assets/raleigh-sales-recruiters-1.png';
import pic2 from './assets/raleigh-north-carolina-tech-companies-2.png';
import pic3 from './assets/raleigh-recruiters-process-rainmakers-3.png';
import pic4 from './assets/best-sales-recruiters-raleigh-4.png';
import pic5 from './assets/raleigh-recruitment-agency-5.png';

import styles from './RaleighContent.scss';

export function RaleighContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt={getAltName(pic1)} />
      <h2>Tailored Recruitment for Your Carolina Business</h2>
      <p>
        Rainmakers is a unique recruiting system designed specifically for companies needing
        specific sales talent. Professionals can receive bids from companies interested in hiring by
        creating a detailed sales profile, including sales history and job requirements. In
        addition, our service offers complete confidentiality and control, only sharing information
        with employers when the salesperson is ready.
      </p>
      <p>
        Our platform is praised for its seamless user experience and is completely free. As a
        result, Rainmakers has helped numerous salespeople land their ideal roles in companies like
        Dialpad, Envoy, Freshworks, and many others.
      </p>
      <p>
        Rainmakers stands out from traditional sales recruiters by giving the power back to the
        salesperson, showcasing their achievements, and allowing them to set their own hiring
        requirements. This benefits companies as it helps narrow the screening process, allowing you
        to be matched with the most appropriate candidates.
      </p>
      <p>
        The demand for tech sales professionals is high, and with the right skillset - including
        business acumen, emotional intelligence, project management, and resilience - one can thrive
        in this field. Tech sales jobs are available across the United States, with Raleigh, North
        Carolina, quickly becoming one of the most popular markets.
      </p>
      <img src={pic2} alt={getAltName(pic2)} />
      <h2>Why We’re Different</h2>
      <p>
        Assemble your perfect sales team in Raleigh, NC, with Rainmakers. Our unique service is
        designed to align your company's needs with top-performing salespeople. Rainmakers ensures
        you're always interviewing the finest sales talent by only accepting high-quality
        applicants. In addition, the platform offers in-depth sales profiles that go beyond
        traditional resumes or LinkedIn profiles, providing comprehensive sales statistics such as
        performance metrics, products sold, and past clientele. This depth of information makes it
        simple to identify candidates with the skills and experience that effortlessly match your
        requirements.
      </p>
      <p>
        Define the role, get matched with talent, and hire the right candidate. It’s really that
        simple!
      </p>
      <h2>Our Approach to Recruiting</h2>
      <p>
        At Rainmakers, our sales recruitment approach in Raleigh is uniquely tailored to meet the
        specific needs of the local market. We start by understanding your company's particular
        requirements and goals. We then tap into our pool of high-quality sales professionals, each
        of whom has been thoroughly vetted and assessed based on their skills, experience, and
        proven sales performance.
      </p>
      <p>
        Our platform provides in-depth sales profiles, allowing you to view comprehensive sales
        statistics and information beyond the usual resume data. The result is an efficient and
        effective recruitment process that identifies and secures top-tier sales talent that aligns
        with your business objectives and contributes significantly to your revenue growth.
      </p>
      <img src={pic3} alt={getAltName(pic3)} />
      <h2>Raleigh’s Booming Tech Scene</h2>
      <p>
        Our latest market research indicates that Raleigh and its surrounding Triangle area are
        recognized as the second-fastest expanding tech ecosystem in the United States. This
        esteemed reputation began in 1965 when IBM established its presence in the Research Triangle
        Park, marking the region as an emerging hotspot for technological innovation. Half a century
        later, the tech giant Apple announced its plans to launch a 1 million square foot campus in
        RTP, with the capacity to accommodate 3,000 employees.
      </p>
      <p>
        Rainmakers is proud to be a part of this thriving tech community.{' '}
        <strong>
          With over 4,000 tech companies operating in the area, the Triangle is home to over 60,000
          professionals.
        </strong>{' '}
        The tech firms here are known for their groundbreaking work in various sectors, such as
        interactive software and games, open source, hardware manufacturing, defense technology,
        telecommunications, and nanotechnology.
      </p>
      <p>
        Raleigh has been recognized as the 10th Top Tech Talent Market in North America, according
        to CBRE's "Scoring Tech Talent" report of 2020.
      </p>
      <p>
        Raleigh is at the forefront of the software industry, with industry behemoths like SAS and
        Allscripts choosing to establish their headquarters in the Triangle. This region has become
        a magnet for technical talent, attracting companies specializing in diverse areas like
        mobile applications, healthcare IT, cloud computing, and cyber security. The Triangle's
        strong higher education programs continually replenish the talent pool, ensuring a steady
        stream of intelligent and motivated professionals ready to contribute to the tech sector.
      </p>
      <Link to="/employer-signup/" title="Apply Now">
        <img src={pic4} alt={getAltName(pic4)} />
      </Link>
      <h2>Raleigh Job Market & Insights</h2>
      <p>
        Raleigh, North Carolina, acclaimed as the fifth-best job market in the United States, is
        experiencing significant growth, particularly in the technology sector. Following an
        exceptional year of job creation and company recruitment in 2022, Raleigh continues to
        attract substantial investments. Wake County Economic Development leaders report that
        despite economic challenges such as inflation, labor shortages, and rising interest rates,
        the economic development pipeline remains robust, primarily in advanced technology
        industries.
      </p>
      <p>
        Raleigh's economic success reflects its quality of life, cost of living, amenities, and
        diverse industries.
      </p>
      <p>
        Despite concerns about tech-sector layoffs, fewer job openings, and tempered expectations
        for 2023, Raleigh's market shows promising strength and dynamism, particularly in the tech
        sector. Moreover, with nearly 47% of the population holding a college degree or more,
        Raleigh offers a wealth of educated and skilled talent, making it an attractive location for
        businesses and economic growth.
      </p>
      <Link to="/employer-signup/" title="Apply Now">
        <img src={pic5} alt={getAltName(pic5)} />
      </Link>
      <h2>Rainmakers + Raleigh</h2>
      <p>
        We understand the unique needs of businesses in Raleigh and the surrounding Triangle area.
        Our innovative recruitment approach and platform provide access to top-tier sales
        professionals, ensuring companies can leverage the wealth of talent in this booming region.
      </p>
      <p>
        If you're ready to tap into this potential and assemble your perfect sales team in Raleigh,
        North Carolina, Rainmakers is here to help. Explore our services today and take the first
        step towards unleashing your business's sales potential.{' '}
        <a href="https://www.rainmakers.co/employer-signup/">Get started now!</a>
      </p>
    </div>
  );
}
