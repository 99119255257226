import { CancelToken } from 'axios';
import { push } from 'connected-react-router';
import { delay } from 'redux-saga';
import { call, cancelled, fork, put, take } from 'redux-saga/effects';

import { handleError } from 'utils/common';
import { getNestedEntity } from 'api/apiEntity';

import { NESTED_ENTITY_LOAD_STARTED } from '../../actions';

import { entityLoadDone } from '../../creators';

const loadNestedEntity = function* loadNestedEntity(action) {
  const {
    payload: {
      id,
      nestedId,
      nestedType,
      newRoute,
      params,
      slice,
      type,
      typeOverride,
      updateOnly,
      irregularType,
    },
  } = action;

  const source = CancelToken.source();

  try {
    const args = {
      id,
      type,
      typeOverride,
      nestedId,
      nestedType,
      config: {
        cancelToken: source.token,
        params: {
          sortBy: 'created_at_desc',
          normalizeIt: true,
          ...params,
        },
      },
    };

    yield call(delay, 250);

    if (newRoute) {
      yield put(push(newRoute));
    }

    const { data } = yield call(getNestedEntity, args);

    yield put(
      entityLoadDone({
        irregularType,
        slice,
        type: nestedType,
        typeOverride,
        data,
        updateOnly,
      })
    );
  } catch (error) {
    handleError(error);

    yield put(
      entityLoadDone({
        irregularType,
        slice,
        type: nestedType,
        typeOverride,
        data: {},
      })
    );
  } finally {
    if (yield cancelled()) {
      yield call(source.cancel);
    }
  }
};

// const sagaGetNestedEntity = function* sagaGetNestedEntity() {
//   yield takeEvery(NESTED_ENTITY_LOAD_STARTED, loadNestedEntity);
// };

const sagaGetNestedEntity = function* sagaGetNestedEntity() {
  const pendingTasks = {};

  const handleCancel = (taskId, slice, type) => {
    const { [taskId]: pendingTask = {} } = pendingTasks;

    const {
      task,
      payload: { slice: taskSlice, nestedType: taskType },
    } = pendingTask;

    if (slice === taskSlice && type === taskType) {
      task.cancel();

      delete pendingTasks[taskId];
    }

    if (!task.isRunning()) {
      delete pendingTasks[taskId];
    }

    return task;
  };

  while (true) {
    const action = yield take(NESTED_ENTITY_LOAD_STARTED);

    const { payload, payload: { slice, nestedType: type, params: { page } = {} } = {} } = action;

    const newTask = yield fork(loadNestedEntity, action);

    if (page === 1) {
      yield Object.keys(pendingTasks).map((taskId) => handleCancel(taskId, slice, type));
    }

    pendingTasks[newTask.id] = {
      task: newTask,
      payload,
    };
  }
};

export default sagaGetNestedEntity;
