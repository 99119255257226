import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useParams } from 'react-router';

import ReadyContainer from 'connectors/ReadyContainer';

import Layout from 'components/Layout';
import DiscourseSSOLogin from 'connectors/DiscourseSSOLogin';
import DiscourseSSOLogout from 'connectors/DiscourseSSOLogout';

const DiscourseSSOPage = (props) => {
  const location = useLocation();
  const params = useParams();

  const title = 'Rainmakers Sales Community Login';
  const metaDescription =
    'Rainmakers Sales Community Login. Sales jobs for top sales talent. Rainmakers connects top sales candidates with top companies.';
  const canonicalUrl = `https://${process.env.HOST}/discourse-sso/login`;

  const helmetProps = {
    key: 'helmet',
    title,
    meta: [
      { name: 'description', content: metaDescription },
      { property: 'og:url', content: canonicalUrl },
      { property: 'og:title', content: title },
      { property: 'og:description', content: metaDescription },
    ],
    link: [{ rel: 'canonical', href: canonicalUrl }],
  };

  const { type } = params;

  const loginContent =
    type === 'login' ? <DiscourseSSOLogin {...props} location={location} /> : null;
  const logoutContent = type === 'logout' ? <DiscourseSSOLogout {...props} /> : null;
  const content = loginContent || logoutContent;

  return (
    <React.Fragment>
      <Helmet {...helmetProps} />
      <ReadyContainer key="readyContainer">
        <Layout>{content}</Layout>
      </ReadyContainer>
    </React.Fragment>
  );
};

export default DiscourseSSOPage;
