const prefix = 'Rainmakers/Featured/';

export const BOOKMARKS_ADD_STARTED = `${prefix}BOOKMARKS_ADD_STARTED`;
export const BOOKMARKS_ADD_DONE = `${prefix}BOOKMARKS_ADD_DONE`;
export const BOOKMARKS_REMOVE_STARTED = `${prefix}BOOKMARKS_REMOVE_STARTED`;
export const BOOKMARKS_REMOVE_DONE = `${prefix}BOOKMARKS_REMOVE_DONE`;
export const FEATURED_CANDIDATES_LOAD_STARTED = `${prefix}FEATURED_CANDIDATES_LOAD_STARTED`;
export const FEATURED_CANDIDATES_LOAD_DONE = `${prefix}FEATURED_CANDIDATES_LOAD_DONE`;
export const FEATURED_CANDIDATES_LOADING_STARTED = `${prefix}FEATURED_CANDIDATES_LOADING_STARTED`;
export const FEATURED_CANDIDATES_LOADING_DONE = `${prefix}FEATURED_CANDIDATES_LOADING_DONE`;
export const FEATURED_CANDIDATES_RESET = `${prefix}FEATURED_CANDIDATES_RESET`;
export const FEATURED_CANDIDATE_UPDATE = `${prefix}FEATURED_CANDIDATE_UPDATE`;

export const SET_EMPLOYER_ID = `${prefix}SET_EMPLOYER_ID`;
export const RESET_EMPLOYER_ID = `${prefix}RESET_EMPLOYER_ID`;

export const ADMIN_FEATURED_CANDIDATE_PASS_STARTED = `${prefix}ADMIN_FEATURED_CANDIDATE_PASS_STARTED`;
export const ADMIN_FEATURED_CANDIDATE_PASS_DONE = `${prefix}ADMIN_FEATURED_CANDIDATE_PASS_DONE`;

export const FEATURED_CANDIDATE_PASS_STARTED = `${prefix}FEATURED_CANDIDATE_PASS_STARTED`;
export const FEATURED_CANDIDATE_PASS_DONE = `${prefix}FEATURED_CANDIDATE_PASS_DONE`;

export const FEATURED_CANDIDATE_UNDO_PASS_STARTED = `${prefix}FEATURED_CANDIDATE_UNDO_PASS_STARTED`;
export const FEATURED_CANDIDATE_UNDO_PASS_DONE = `${prefix}FEATURED_CANDIDATE_UNDO_PASS_DONE`;
