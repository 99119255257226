import { handleActions } from 'redux-actions';

import { PICKER_OPTIONS_LOAD_DONE, TAGS_LOAD_DONE } from 'shared/store/app/actions';

const initialState = {
  pickerOptions: {},
  tags: {},
};

const handlePickerOptionsLoadDone = (state = initialState, action) => {
  const {
    payload: { pickerOptions },
  } = action;

  return {
    ...state,
    pickerOptions,
  };
};

const handleTagsLoadDone = (state = initialState, action) => {
  const {
    payload: { tags },
  } = action;

  return {
    ...state,
    tags,
  };
};

const actionHandlers = {
  [PICKER_OPTIONS_LOAD_DONE]: handlePickerOptionsLoadDone,
  [TAGS_LOAD_DONE]: handleTagsLoadDone,
};

const reduceDefaults = handleActions(actionHandlers, initialState);

export default reduceDefaults;
