import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import ConnectContainer from 'containers/ConnectContainer';

import Block from 'components/Block';
import Button from 'components/Button';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';

import duck from '../../redux';
import SearchSaveModal from '../SearchSaveModal';

import SavedSearch from './components/SavedSearch';

import styles from './SavedSearches.scss';

const SavedSearches = ({
  actions: { entityLoadStarted, patchResource, savedSearchesLoadStarted, deleteResource },
  isAdmin,
  savedSearchesPage,
  savedSearches: { byId = {}, allIds = [] } = {},
  savedSearchesTotal,
  savedSearchesLoading,
  handleLoadSavedSearch,
  handleSaveSearch,
  searchActivatedId,
  disabled = false,
}) => {
  const [modalType, setModalType] = useState(null);
  const [title, setTitle] = useState('');
  const [savedSearchId, setSavedSearchId] = useState('');

  useEffect(() => {
    if (!isAdmin) {
      entityLoadStarted({
        slice: 'employerSearch',
        type: 'saved_searches',
        params: {
          page: 1,
        },
      });
    }
  }, [entityLoadStarted, isAdmin]);

  const loadMore = () => {
    if (!isAdmin) {
      savedSearchesLoadStarted({ page: savedSearchesPage + 1 });
    }
  };

  const handleEditSearch = () => {
    patchResource({
      slice: 'employerSearch',
      id: savedSearchId,
      type: 'saved_searches',
      attributes: { title },
    });

    setTitle('');
    setModalType(null);
  };

  return (
    <div className={styles.searchSidebarContent}>
      <Block
        title={
          <div className={styles.savedSearchesTitle}>
            <div className={styles.title}>Saved Searches</div>
            <Button
              className={styles.saveSearchButton}
              tertiary={true}
              onClick={() => setModalType('save')}
            >
              + Add
            </Button>
          </div>
        }
        boxShadow={true}
        addWhiteBG={true}
        addFlex={true}
        addChildFlex={true}
        addBottomFade={true}
        disabled={disabled}
      >
        <div className={styles.savedSearches}>
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            hasMore={savedSearchesTotal > allIds.length}
            loader={savedSearchesLoading ? <div /> : <InfiniteScrollLoader smallTable />}
            useWindow={false}
          >
            {allIds.map((id) => (
              <SavedSearch
                key={id}
                savedSearch={byId[id]}
                handleEditSearchClick={({ id, title }) => {
                  setModalType('edit');
                  setTitle(title);
                  setSavedSearchId(id);
                }}
                handleDeleteSearchClick={() =>
                  deleteResource({
                    id,
                    slice: 'employerSearch',
                    type: 'saved_searches',
                  })
                }
                handleLoadSavedSearch={handleLoadSavedSearch}
                searchActivatedId={searchActivatedId}
              />
            ))}
          </InfiniteScroll>
        </div>
      </Block>
      {modalType && (
        <SearchSaveModal
          handleInputChange={({ target: { value } }) => setTitle(value)}
          handleSaveSearch={() => {
            handleSaveSearch({ title });
            setTitle('');
            setModalType(null);
          }}
          handleEditSearch={handleEditSearch}
          handleCloseClick={() => {
            setTitle('');
            setModalType(null);
          }}
          title={title}
          type={modalType}
        />
      )}
    </div>
  );
};

export default ConnectContainer(duck)(SavedSearches);
