import UAParser from 'ua-parser-js';
import { handleError } from 'utils/common';

/**
 *
 * Browser types enum
 *
 */

const BROWSER_TYPES = {
  IE: ['IE', 'IEMobile', 'IE Mobile', 'Edge', 'Edge Mobile', 'EdgeMobile'],
  SAFARI: ['Safari'],
};

/**
 *
 * Device types enum
 *
 */

const DEVICE_TYPES = {
  mobile: 'mobile',
  tablet: 'tablet',
};

/**
 *
 * Browser test predicates
 *
 */

const isIE = (name) => BROWSER_TYPES.IE.includes(name);
const isSafari = (name) => BROWSER_TYPES.SAFARI.includes(name);

/**
 *
 * Device test predicates
 *
 */

const isDesktop = (type) => ![DEVICE_TYPES.mobile, DEVICE_TYPES.tablet].includes(type);

const isMobile = (type) => type === DEVICE_TYPES.mobile;

/**
 *
 * Browser type comparison method
 *
 */

const checkUserAgentBrowser = ({ predicate, userAgent }) => {
  try {
    const parser = new UAParser(userAgent);

    const { name } = parser.getBrowser();

    return predicate(name);
  } catch (error) {
    handleError(error);
    return false;
  }
};

/**
 *
 * Device type comparison method
 *
 */

const checkUserAgentDevice = ({ predicate, userAgent }) => {
  try {
    const parser = new UAParser(userAgent);

    const { type } = parser.getDevice();

    return predicate(type);
  } catch (error) {
    handleError(error);
    return false;
  }
};

/**
 *
 * Browser type methods
 *
 */

export const browserIsIE = ({ userAgent }) => checkUserAgentBrowser({ predicate: isIE, userAgent });
export const browserIsSafari = ({ userAgent }) =>
  checkUserAgentBrowser({ predicate: isSafari, userAgent });

/**
 *
 * Device type methods
 *
 */

export const deviceIsDesktop = ({ userAgent }) =>
  checkUserAgentDevice({ predicate: isDesktop, userAgent });
export const deviceIsMobile = ({ userAgent }) =>
  checkUserAgentDevice({ predicate: isMobile, userAgent });
