import { getEntity } from 'api/apiEntity';

const promiseCandidateSources = () => {
  const onFulfilled = ({ data: sources = [] }) => sources;

  const args = { type: 'data_store/candidate_sources' };

  return getEntity(args).then(onFulfilled);
};

export default promiseCandidateSources;
