import { call, select, put, take } from 'redux-saga/effects';

import { entitySelectorFactory } from 'reduxStore/selectorFactories';

import { handleError } from 'utils/common';
import { trackEvent } from 'utils/analytics';
import { segmentUnhideCandidateEmployee } from 'segment/eventNames';

import { deleteEntity } from 'api/apiEntity';

import { FEATURED_CANDIDATE_UNDO_PASS_STARTED } from '../actions';

import { featuredCandidateUpdate } from '../creators';

const trackUnhideCandidate = (candidateId, blockType) => {
  const event = segmentUnhideCandidateEmployee;

  const properties = {
    blockType,
    candidateId,
  };

  const eventObject = { event, properties };

  trackEvent(eventObject);
};

const sagaFeaturedCandidateUndoPassStarted = function* sagaFeaturedCandidateUndoPassStarted() {
  while (true) {
    const {
      payload: { candidateCompanyBlockId, candidateId },
    } = yield take(FEATURED_CANDIDATE_UNDO_PASS_STARTED);

    const { byId } = yield select(entitySelectorFactory('featured', 'candidate'));

    try {
      yield put(
        featuredCandidateUpdate({
          candidate: {
            ...byId[candidateId].attributes,
            id: candidateId,
            hide: false,
          },
        })
      );

      const args = {
        id: candidateCompanyBlockId,
        type: 'candidate_company_blocks',
      };

      yield call(deleteEntity, args);

      yield call(trackUnhideCandidate, candidateId, 'featured_pass');
    } catch (error) {
      handleError(error);
      yield put(
        featuredCandidateUpdate({
          candidate: byId[candidateId].attributes,
        })
      );
    }
  }
};

export default sagaFeaturedCandidateUndoPassStarted;
