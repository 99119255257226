import React from 'react';
import Dropdown from 'components/Dropdown';

const StatusFilter = ({ statuses, handleChangeStatus, value: currentValue }) => {
  const dropdownProps = {
    source: statuses || [],
    value: currentValue,
    onChange: handleChangeStatus,
    defaultIcon: 'up-down-caret',
  };

  return <Dropdown {...dropdownProps} />;
};

export default StatusFilter;
