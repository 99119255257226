import { getEntity } from 'api/apiEntity';

const promiseGetEmployer = ({ employerId, camelizeIt = false }) => {
  const onFulfilled = (response) => {
    const {
      data: { employer = {} },
    } = response;

    return { employer };
  };

  const args = {
    type: 'employers',
    id: employerId,
    config: {
      params: {
        camelizeIt,
      },
    },
  };

  return getEntity(args).then(onFulfilled);
};

export default promiseGetEmployer;
