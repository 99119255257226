import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import camelCase from 'lodash.camelcase';
import queryString from 'query-string';

import ConnectContainer from 'containers/ConnectContainer';
import { CandidateAuthHOC } from 'containers/HOC';

import ReadyContainer from 'connectors/ReadyContainer';

import { promisePostCompanies } from 'pages/AdminPortal/ToolboxCandidateEditorPage/promises';
import { promiseGetFullCandidate } from 'pages/EmployeePortal/CandidateProfilePages/promises';

import { handleError } from 'utils/common';
import { toggleArray } from 'utils/formUtils';

import Block from 'components/Block';
import Button from 'components/Button';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import Sidebar from 'components/Sidebar';
import TableOverlay from 'components/TableOverlay';

import duck from '../redux';
import WorkInputs from './components/WorkInputs';
import WorkDisplay from './components/WorkDisplay';

import styles from './EditWork.scss';

class EditWork extends Component {
  constructor(props) {
    super(props);

    const {
      user: { currentProfile: { workHistories = [] } = {} } = {},
      location: { search },
    } = props;

    const { model = null, model_index: modelIndex = null } = queryString.parse(search);

    const newWorkHistories = workHistories ? workHistories.slice() : [];

    if (
      model === 'work_history' &&
      modelIndex &&
      parseInt(modelIndex, 10) >= 0 &&
      workHistories[modelIndex]
    ) {
      newWorkHistories[modelIndex] = {
        ...newWorkHistories[modelIndex],
        isEditing: true,
      };
    }

    if (parseInt(modelIndex, 10) === -1 && workHistories[0] && !workHistories[0].isEditing) {
      newWorkHistories.unshift(this.newWorkHistory());
    }

    this.state = {
      isPostingCompany: false,
      workHistories: newWorkHistories,
      origWorkHistories: newWorkHistories,
      obeyParams: true,
    };
  }

  componentDidMount() {
    const {
      actions: { pickerOptionsLoadStarted },
      candidate,
    } = this.props;

    const { id: candidateId } = candidate || {};

    promiseGetFullCandidate({ candidateId }).then(this.onCandidateSuccess).catch(handleError);
    pickerOptionsLoadStarted();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      user: { currentProfile: { workHistories = [] } = {} } = {},
      location: { search },
    } = nextProps;

    const { model = null, model_index: modelIndex = null } = queryString.parse(search);
    const { obeyParams } = this.state;

    const newWorkHistories = workHistories ? workHistories.slice() : [];

    if (
      model === 'work_history' &&
      modelIndex &&
      parseInt(modelIndex, 10) >= 0 &&
      workHistories[modelIndex] &&
      obeyParams
    ) {
      newWorkHistories[modelIndex] = {
        ...newWorkHistories[modelIndex],
        isEditing: true,
      };
    }

    if (
      parseInt(modelIndex, 10) === -1 &&
      workHistories[0] &&
      !workHistories[0].isEditing &&
      obeyParams
    ) {
      newWorkHistories.unshift(this.newWorkHistory());
    }

    this.setState({
      workHistories: newWorkHistories,
      origWorkHistories: newWorkHistories,
    });

    const firstMovedWHIdx = workHistories.findIndex((wh) => wh.moved);
    if (firstMovedWHIdx >= 0) {
      if (this[`workHistory${firstMovedWHIdx}`]) {
        this[`workHistory${firstMovedWHIdx}`].scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
      }
      setTimeout(() => {
        const { workHistories } = this.state;
        const renewWorkHistories = workHistories.map((wh) => ({
          ...wh,
          moved: false,
        }));

        this.setState({
          workHistories: renewWorkHistories,
          origWorkHistories: renewWorkHistories,
        });
      }, 500);
    }
  }

  componentWillUnmount() {
    this.setState({
      workHistories: [],
      origWorkHistories: [],
    });
  }

  onCandidateSuccess = (candidateProfile) => {
    const {
      actions: { candidateProfilePatchDone },
    } = this.props;

    candidateProfilePatchDone({ candidateProfile });
  };

  onCandidateFail = (error) => console.log(error);

  setWHRef = (ref, idx) => {
    this[`workHistory${idx}`] = ref;
  };

  newWorkHistory = () => {
    const newWorkHistory = {
      startMonth: '',
      startYear: '',
      endMonth: '',
      endYear: '',
      startDate: '',
      endDate: '',
      quotasHit: '',
      stackRank: '',
      soldToCompanies: [],
      soldToCompanySizes: [],
      soldToDepartments: [],
      soldToIndustries: [],
      territories: [],
      productTypes: [],
      awards: [],
      inboundOutbound: '',
      saleCycleTime: '',
      salesPeriods: [],
      description: '',
      role: '',
      company: {
        id: '',
        picture: {
          original: '',
          thumb: '',
          small: '',
          medium: '',
          large: '',
          xlarge: '',
          xxlarge: '',
        },
      },
      position: '',
      isEditing: true,
    };

    return newWorkHistory;
  };

  addWorkHistory = () => {
    const { workHistories } = this.state;

    const newWorkHistories = workHistories.slice();

    const newWorkHistory = this.newWorkHistory();

    newWorkHistories.unshift(newWorkHistory);

    this.setState({
      workHistories: newWorkHistories,
      origWorkHistories: newWorkHistories,
    });
  };

  handleCancelEditingWork = (idx) => {
    const { workHistories, origWorkHistories } = this.state;

    const origWorkHistory = origWorkHistories[idx];

    const newWorkHistories = workHistories.slice();

    if (origWorkHistory && origWorkHistory.id) {
      newWorkHistories.splice(idx, 1, { ...origWorkHistory, isEditing: false });
      this.setState({
        workHistories: newWorkHistories,
      });
    } else {
      this.handleWorkHistoryDelete({ idx });
    }
  };

  handleReorderWorkHistories = ({ idx }) => {
    const {
      actions: { reorderWorkStarted },
    } = this.props;

    const { isPostingCompany, reorderAttempts, workHistories: origWorkHistories } = this.state;

    if (isPostingCompany && reorderAttempts < 4) {
      setTimeout(() => {
        this.handleReorderWorkHistories({ idx });
        this.setState({ reorderAttempts: reorderAttempts + 1, obeyParams: false });
      }, 500);
    } else {
      this.setState({ reorderAttempts: 0, obeyParams: false });
      reorderWorkStarted({
        origWorkHistories,
        idx,
      });
    }
  };

  handleToggleEditingWork = (idx) => {
    const { workHistories, origWorkHistories } = this.state;

    if (workHistories[idx].isEditing) {
      setTimeout(() => this.handleReorderWorkHistories({ idx }), 250);
    } else {
      const newWorkHistories = workHistories.map((wh, index) => {
        if (index === idx) {
          return {
            ...wh,
            isEditing: true,
          };
        }
        return wh;
      });

      const origWorkHistory = workHistories[idx];

      const newOrigWorkHistories = origWorkHistories.slice();

      newOrigWorkHistories.splice(idx, 1, origWorkHistory);

      this.setState({
        workHistories: newWorkHistories,
        origWorkHistories: newOrigWorkHistories,
      });
    }
  };

  handleWorkHistoryCompanyChange = ({ event, idx: workHistoryIdx }) => {
    const {
      target: { value: newValue },
    } = event;

    const { workHistories } = this.state;

    const newWorkHistories = workHistories.slice();

    newWorkHistories[workHistoryIdx].company = {
      ...newWorkHistories[workHistoryIdx].company,
      name: newValue.name,
      id: newValue.id,
    };

    newWorkHistories[workHistoryIdx].companyId = newValue.id;

    this.setState({ workHistories: newWorkHistories });
  };

  handleWorkHistoryCompanyBlur = ({ event, idx: workHistoryIdx }) => {
    const {
      target: { value: newValue },
    } = event;

    const { workHistories } = this.state;

    const { company: origCompany = {} } = workHistories[workHistoryIdx];

    if (origCompany.name !== newValue) {
      this.setState({ isPostingCompany: true });

      const onFulfilled = ({ company }) => {
        const newWorkHistories = workHistories.slice();

        newWorkHistories[workHistoryIdx].company = {
          ...newWorkHistories[workHistoryIdx].company,
          name: company.name,
          id: company.id,
        };

        newWorkHistories[workHistoryIdx].companyId = company.id;

        this.setState({ workHistories: newWorkHistories, isPostingCompany: false });
      };

      const onFail = (error) => {
        handleError(error);
        this.setState({ isPostingCompany: false });
      };

      const data = {
        type: 'company',
        attributes: {
          name: newValue,
        },
      };

      promisePostCompanies({ data }).then(onFulfilled).catch(onFail);
    }
  };

  handleWorkHistoryInputChange = ({ event, idx: workHistoryIdx }) => {
    const {
      target: { name, value: newValue },
    } = event;

    const { workHistories } = this.state;

    const newWorkHistories = workHistories.slice();

    if (newValue && name === 'insideSalesPercentage') {
      newWorkHistories[workHistoryIdx][name] = newValue / 100;

      this.setState({ workHistories: newWorkHistories });
    }

    newWorkHistories[workHistoryIdx][name] = newValue;

    this.setState({ workHistories: newWorkHistories });
  };

  handleCurrentPositionInputChange = ({ event, idx: workHistoryIdx }) => {
    const {
      target: { checked },
    } = event;

    const { workHistories } = this.state;

    const newWorkHistories = workHistories.slice();

    newWorkHistories[workHistoryIdx].endDate = checked
      ? null
      : new Date().toISOString().substring(0, 10);

    this.setState({ workHistories: newWorkHistories });
  };

  handleWorkHistoryDateInputChange = ({ name, value: newValue, idx: workHistoryIdx }) => {
    const { workHistories } = this.state;

    const newWorkHistories = workHistories.map((wh, idx) => {
      if (idx === workHistoryIdx) {
        return {
          ...wh,
          [name]: newValue,
        };
      }
      return wh;
    });

    this.setState({ workHistories: newWorkHistories });
  };

  handleWorkHistoryDelete = ({ idx }) => {
    const {
      actions: { workHistoryDeleteStarted },
    } = this.props;

    const { workHistories } = this.state;

    const newWorkHistories = workHistories.slice();
    newWorkHistories.splice(idx, 1);

    this.setState({ workHistories: newWorkHistories });
    if (workHistories[idx].id) {
      const workHistoryId = workHistories[idx].id;

      workHistoryDeleteStarted({
        workHistoryId,
        newWorkHistories,
      });
    }
  };

  handleToggleArrayValue = ({ event, idx: workHistoryIdx }) => {
    const {
      target: { name, value: newValue },
    } = event;
    const splitName = name.split(',');

    const { workHistories } = this.state;

    const newWorkHistories = workHistories.slice();

    if (splitName.length === 2) {
      const {
        [splitName[0]]: { [splitName[1]]: arrayToToggle },
      } = workHistories[workHistoryIdx];

      const newArray = toggleArray({ array: arrayToToggle || [], value: newValue });

      newWorkHistories[workHistoryIdx][splitName[0]][splitName[1]] = newArray;

      return this.setState({ workHistories: newWorkHistories });
    }

    const { [splitName[0]]: arrayToToggle } = workHistories[workHistoryIdx];

    const newArray = toggleArray({ array: arrayToToggle || [], value: newValue });
    newWorkHistories[workHistoryIdx][splitName[0]] = newArray;

    return this.setState({ workHistories: newWorkHistories });
  };

  handleToggleSoldToIndustryArrayValue = ({ event, idx: workHistoryIdx }) => {
    const {
      target: { value },
    } = event;

    const { workHistories } = this.state;

    const newWorkHistories = workHistories.slice();

    const { soldToIndustries: arrayToToggle = [] } = workHistories[workHistoryIdx];

    if (arrayToToggle && arrayToToggle.includes(value)) {
      const newValue = arrayToToggle.filter((val) => val !== value);

      newWorkHistories[workHistoryIdx].soldToIndustries = newValue;

      return this.setState({ workHistories: newWorkHistories });
    }

    if (value === 'Many' || value === 'All') {
      const newValue = [value];

      newWorkHistories[workHistoryIdx].soldToIndustries = newValue;

      return this.setState({ workHistories: newWorkHistories });
    }

    const newValue = arrayToToggle ? arrayToToggle.slice() : [];

    newValue.push(value);

    newWorkHistories[workHistoryIdx].soldToIndustries = newValue;

    return this.setState({ workHistories: newWorkHistories });
  };

  makeBasicInfoInputs = (workHistory, idx) => {
    const {
      dispatch,
      isProfileLocked,
      location: { search },
      pickerOptions = {},
    } = this.props;

    const { 'fields[]': fields = [], 'model_index': modelIndex = null } = queryString.parse(search);

    let autofocusFields = fields;

    if (fields && typeof fields === 'string') {
      autofocusFields = [camelCase(fields)];
    }
    if (fields && Array.isArray(fields)) {
      autofocusFields = fields.map((field) => camelCase(field));
    }

    if (idx !== parseInt(modelIndex, 10)) {
      autofocusFields = [];
    }

    const { isEditing } = workHistory;

    const workInputsProps = {
      autofocusFields,
      dispatch,
      workInputsType: 'full',
      workHistory,
      idx,
      addWorkHistory: this.addWorkHistory,
      hasRoleChangeModal: true,
      handleWorkHistoryCompanyChange: this.handleWorkHistoryCompanyChange,
      handleWorkHistoryCompanyBlur: this.handleWorkHistoryCompanyBlur,
      handleCurrentPositionInputChange: this.handleCurrentPositionInputChange,
      handleWorkHistoryDateInputChange: this.handleWorkHistoryDateInputChange,
      handleWorkHistoryInputChange: this.handleWorkHistoryInputChange,
      handleWorkHistoryDelete: this.handleWorkHistoryDelete,
      handleToggleSoldToIndustryArrayValue: this.handleToggleSoldToIndustryArrayValue,
      handleToggleArrayValue: this.handleToggleArrayValue,
      handleToggleEditingWork: this.handleToggleEditingWork,
      handleCancelEditingWork: this.handleCancelEditingWork,
      pickerOptions,
    };

    const workHistoryBlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      addPadding: false,
      className: styles.workHistoryBlock,
    };

    const workDisplayProps = {
      setWHRef: (ref) => this.setWHRef(ref, idx),
      workHistory,
      idx,
      handleToggleEditingWork: this.handleToggleEditingWork,
      workDisplayType: 'full',
      isProfileLocked,
    };

    return isEditing ? (
      <Block key={workHistory.id || idx} {...workHistoryBlockProps}>
        <WorkInputs {...workInputsProps} />
      </Block>
    ) : (
      <Block key={workHistory.id || idx} {...workHistoryBlockProps}>
        <WorkDisplay {...workDisplayProps} />
      </Block>
    );
  };

  render() {
    const {
      candidate,
      isProfileLocked,
      location: { search },
    } = this.props;

    const { model_index: modelIndex = null } = queryString.parse(search);

    const { workHistories = [] } = this.state;

    const statusContent = isProfileLocked ? (
      <div className={styles.profileLocked}>Profile Locked</div>
    ) : null;

    const hasWorkHistories = workHistories.length > 0;

    const titleBlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      addPadding: true,
      className: styles.titleBlock,
    };

    const workBlockProps = {
      noTitlePadding: true,
      largeTitleFont: true,
    };

    const inputContent = hasWorkHistories ? workHistories.map(this.makeBasicInfoInputs) : null;

    const addEmploymentButtonProps = {
      primary: true,
      onClick: this.addWorkHistory,
      disabled: isProfileLocked || workHistories.some((wh) => wh.isEditing),
      highlight: parseInt(modelIndex, 10) === -2,
    };

    const profileLockOverlay = isProfileLocked ? <TableOverlay lockedNotFixed /> : null;

    const content = (
      <div className={styles.EditorWork}>
        <Block {...titleBlockProps}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>Work History</div>
            <div className={styles.saveContent}>
              {statusContent}
              <Button {...addEmploymentButtonProps}>+ Add Employment</Button>
            </div>
          </div>
        </Block>
        <div className={styles.scrollDiv}>
          {profileLockOverlay}
          <Block {...workBlockProps}>
            <div className={styles.workInputContainer}>{inputContent}</div>
          </Block>
        </div>
      </div>
    );

    const layoutWithoutSidebarProps = {
      content,
    };

    const sidebarProps = {
      type: 'candidateEditor',
      page: 'edit-work',
      candidate,
    };

    return (
      <React.Fragment>
        <Helmet title="Candidate Editor" />
        <ReadyContainer className={styles.CandidateEditorWork}>
          <Sidebar {...sidebarProps} />
          <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />
        </ReadyContainer>
      </React.Fragment>
    );
  }
}

export default ConnectContainer(duck)(CandidateAuthHOC()(EditWork));
