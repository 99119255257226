import React from 'react';

import Input from 'components/Input';

import styles from './NameScope.scss';

const NameScope = ({ name, handleSearchNameChange: handleInputChange }) => {
  const employerNameSearchInputProps = {
    value: name,
    name: 'name',
    placeholder: 'Search by employer name',
    size: 'full',
    handleInputChange,
    styling: 'cohortCandidate',
    isSearch: true,
  };

  return (
    <div className={styles.employerSearch}>
      <Input {...employerNameSearchInputProps} />
    </div>
  );
};

export default NameScope;
