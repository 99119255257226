import React from 'react';

import { useImageField } from 'hooks';

import EmptyCompanyLogo from 'shared/assets/missing-company-picture.png';

import { handleWebUrl } from 'utils/urlUtils';

import styles from './CompanyHeader.scss';

const CompanyHeader = ({
  employer,
  name,
  tagline,
  location,
  foundedOn,
  webSite = '',
  stage,
  sizeMin,
  sizeMax,
  industry,
}) => {
  const { imageComponent } = useImageField({
    model: employer,
    avatarClass: styles.headerLogoContainer,
    emptyLogo: EmptyCompanyLogo,
  });

  const isValid = (value) => value !== '' && value !== null && value !== undefined;

  const sizeMaxString = sizeMax ? sizeMax.toLocaleString() : null;

  const sizeMinString = sizeMin ? sizeMin.toLocaleString() : null;

  const sizeMinStringFinal = sizeMinString === '10,000' ? `${sizeMinString}+` : sizeMinString;

  const sizeToShow = sizeMaxString || sizeMinStringFinal || '-';

  const sizeText =
    sizeMaxString && sizeMinString ? `${sizeMinString} - ${sizeMaxString}` : sizeToShow;

  const employeesContent = isValid(sizeMin) ? (
    <div className={styles.headerDetail}>{sizeText} Employees</div>
  ) : null;

  const siteFinal = handleWebUrl(webSite);

  const siteContent =
    siteFinal !== null ? (
      <div className={styles.headerDetailContainer}>
        <div className={styles.headerDetailBull}>&bull;</div>
        <div className={styles.headerLink}>
          <a href={siteFinal} rel="noreferrer" target="_blank">
            {webSite}
          </a>
        </div>
      </div>
    ) : null;

  const foundedOnContent = isValid(foundedOn) ? (
    <div className={styles.headerDetailContainer}>
      <div className={styles.headerDetailBull}>&bull;</div>
      <div className={styles.headerDetail}>Founded in {foundedOn}</div>
    </div>
  ) : null;

  const stageContent = isValid(stage) ? (
    <div className={styles.headerDetailContainer}>
      <div className={styles.headerDetailBull}>&bull;</div>
      <div className={styles.headerDetail}>{stage}</div>
    </div>
  ) : null;

  const locationContent = isValid(location) ? (
    <div className={styles.headerDetailContainer}>
      <div className={styles.headerDetailBull}>&bull;</div>
      <div className={styles.headerDetail}>{location}</div>
    </div>
  ) : null;

  return (
    <div className={styles.CompanyHeader}>
      {imageComponent}
      <div className={styles.headerDetailsContainer}>
        <div className={styles.headerName}>{name}</div>
        <div className={styles.headerIndustry}>{industry}</div>
        <div className={styles.headerTagline}>{tagline}</div>
        <div className={styles.headerDeets}>
          {employeesContent}
          {foundedOnContent}
          {stageContent}
          {locationContent}
          {siteContent}
        </div>
      </div>
    </div>
  );
};

export default CompanyHeader;
