import React from 'react';
import classnames from 'classnames';

import FontIcon from 'components/FontIcon';

import styles from './Tag.scss';

const Tag = ({ className, name, inputName, onDeleteClick, viewOnly, value, grayTag, disabled }) => {
  const classes = classnames(
    styles.Tag,
    {
      [styles.hasDelete]: !viewOnly,
      [styles.grayTag]: grayTag,
    },
    className
  );

  const deleteButtonProps = {
    onClick: disabled ? () => null : () => onDeleteClick(name, inputName, value),
    className: disabled ? styles.disabledButton : styles.deleteButton,
  };

  const deleteButton = viewOnly ? null : (
    <div {...deleteButtonProps}>
      <FontIcon iconName="close" />
    </div>
  );

  const truncateName = name && name.length > 70;

  const nameContent = truncateName ? `${name.substr(0, 70)}...` : name;

  return (
    <div title={name} className={classes}>
      {nameContent}
      {deleteButton}
    </div>
  );
};

export default Tag;
