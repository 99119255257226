import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ReadyContainer from 'connectors/ReadyContainer';

import Block from 'components/Block';

import SuggestionSet from './components/SuggestionSet';
import SuggestionSets from './components/SuggestionSets';

import styles from './AdminEditorSuggestionSets.scss';

const AdminEditorSuggestionSets = ({
  actions: { showModal, entityLoadStarted, nestedEntityLoadStarted, patchResource },
  dispatch,
  rainmakersAdmin,
  rainmakersAdmin: { id: rainmakersAdminId },
  candidateSuggestions = {},
  candidateSuggestionsPage,
  candidateSuggestionsTotal,
  candidateSuggestionsLoading,
  employers = {},
  modal,
  pickerOptions = {},
  requisitionOpenings = {},
  requisitionRequirements = {},
  suggestionSetsPage: page = 1,
  suggestionSets,
  suggestionSets: { byId = {} } = {},
  suggestionSetsLoading,
  suggestionSetsTotal,
}) => {
  const { nestedId } = useParams();
  const [complete, setComplete] = useState(null);
  const suggestionSetsLoadParams = useRef({
    includeSet: 'list_component',
    page: 1,
    rainmakersAdminId,
    sortBy: 'created_at_desc',
    complete: null,
  });

  useEffect(() => {
    entityLoadStarted({
      ...suggestionSetsLoadArgs,
      params: {
        ...getSuggestionSetsLoadParams(),
        page: 1,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complete]);

  const suggestionSetsLoadArgs = {
    type: 'suggestion_sets',
    slice: 'toolboxAdmins',
  };

  const getSuggestionSetsLoadParams = () => {
    return {
      ...suggestionSetsLoadParams.current,
      complete,
      page,
    };
  };

  const loadMoreSuggestionSets = () => {
    entityLoadStarted({
      ...suggestionSetsLoadArgs,
      params: {
        ...getSuggestionSetsLoadParams(),
        page: page + 1,
      },
    });
  };

  const handleChangeState = (newState) => {
    if (newState !== complete) {
      setComplete(newState);
    }
  };

  const blockProps = {
    addWhiteBG: true,
    boxShadow: true,
    largeTitleFont: true,
    className: styles.block,
  };

  const suggestionSetsProps = {
    complete,
    dispatch,
    employers,
    handleChangeState,
    loadMoreSuggestionSets,
    modal,
    patchResource,
    rainmakersAdmin,
    requisitionOpenings,
    showModal,
    suggestionSets,
    suggestionSetsLoading,
    suggestionSetsTotal,
  };

  const { [nestedId]: suggestionSet } = byId || {};

  const suggestionSetProps = {
    rainmakersAdmin,
    candidateSuggestions,
    candidateSuggestionsPage,
    candidateSuggestionsTotal,
    candidateSuggestionsLoading,
    employers,
    entityLoadStarted,
    modal,
    nestedEntityLoadStarted,
    patchResource,
    pickerOptions,
    requisitionOpenings,
    requisitionRequirements,
    showModal,
    suggestionSet,
    suggestionSetId: nestedId,
  };

  const content = suggestionSet ? (
    <SuggestionSet {...suggestionSetProps} />
  ) : (
    <SuggestionSets {...suggestionSetsProps} />
  );

  return (
    <ReadyContainer key="readyContainer" className={styles.Admin}>
      <Block {...blockProps}>{content}</Block>
    </ReadyContainer>
  );
};

export default AdminEditorSuggestionSets;
