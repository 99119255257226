import React, { useContext, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';
import { useLocation, useRouteMatch } from 'react-router-dom';

import {
  setModal,
  hideModal,
  showModal as showModalExternal,
  setCurrentIdx,
} from 'shared/store/app/creators';

import { NotesContext } from 'pages/AdminPortal/ToolboxCandidateEditorPage/NotesContext';

import Overlay from 'components/Overlay';
import FontIcon from 'components/FontIcon';
import { NotesButton } from 'components/NotesButton';

import { usePrevious } from 'hooks';

import regularStyles from './Modal.scss';
import centeredStyles from './ModalCentered.scss';

function getNextRoute(match, nextId, paginatorUrl) {
  const { params, path } = match;
  let url = path.replace(/:id/, nextId);

  // exception for internal list of employees in toolbex-employer
  if (params.view === 'edit-employee' && params.employeeId) {
    return paginatorUrl.replace(/:id/, nextId);
  }

  // eslint-disable-next-line no-unused-vars
  for (const param of Object.keys(params)) {
    if (param !== 'id') {
      if (params[param] !== undefined) {
        url = url.replace(`:${param}?`, params[param]);
        url = url.replace(`:${param}`, params[param]);
      } else {
        url = url.replace(`/:${param}?`, '');
      }
    }
  }

  return url;
}

const Modal = ({
  dispatch,
  currentId = null,
  paginator: { byId = {}, allIds: allIdsExternal = [] } = {},
  paginatorUrl,
  paginatorField,
  modal: { backPath, backKey, parent, originalUrl, closeHook, silentClose },
  modal,
  actions: { showModal },
  children,
  className,
  large,
  centered = false,
  ...others
}) => {
  const location = useLocation();
  const match = useRouteMatch();
  const prevCurrentIdx = usePrevious(modal.currentIdx);

  const styles = centered ? centeredStyles : regularStyles;

  useEffect(() => {
    const allIds = getAllIds();
    const currentIdx = allIds && currentId ? allIds.findIndex((id) => id === currentId) : '';
    dispatch(setCurrentIdx({ currentIdx }));

    return () => {
      if (location.action !== 'POP' && location.state && !location.state.modal) {
        dispatch(hideModal());
      }

      if (!modal.silentClose) {
        dispatch(
          setModal({
            ...modal,
            initialRender: false,
          })
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { currentIdx: nextCurrentIdx } = modal || {};

    if (prevCurrentIdx === nextCurrentIdx) return;

    const allIds = getAllIds();

    const { originalUrl } = modal;

    if (nextCurrentIdx === undefined) {
      const currentIdx = allIds && currentId ? allIds.findIndex((id) => id === currentId) : '';
      const nextId = allIds[currentIdx + 1] || null;
      const prevId = allIds[currentIdx - 1] || null;

      if (nextId) {
        dispatch(setCurrentIdx({ currentIdx: currentIdx + 1 }));
      }
      if (prevId && !nextId) {
        dispatch(setCurrentIdx({ currentIdx: currentIdx - 1 }));
      }

      const newId = nextId || prevId;

      if (newId) {
        showModal({
          key: location.state.modal,
          parent: location.state.parent,
          route: getNextRoute(match, nextId, paginatorUrl),
          originalUrl,
        });
      } else {
        // dispatch(hideModal());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.currentIdx]);

  const getAllIds = () => {
    const irregularAllIds =
      paginatorField !== 'id'
        ? allIdsExternal.map((id) => byId[id].attributes[paginatorField])
        : null;

    const realAllIds = irregularAllIds || allIdsExternal;

    return realAllIds.filter((id) => byId[id] && !byId[id].attributes.temporary);
  };

  const handleCloseClick = (e) => {
    e.stopPropagation();

    closeHook && closeHook();

    if (silentClose) {
      return dispatch(
        showModalExternal({
          backKey: null,
          backPath: null,
          key: backKey,
          originalUrl,
          parent,
          silentClose: false,
          closeHook: null,
        })
      );
    }

    if (backKey && backPath) {
      dispatch(
        showModalExternal({
          backKey: null,
          backPath: null,
          key: backKey,
          originalUrl,
          parent,
          route: backPath,
          silentClose: false,
          closeHook: null,
        })
      );
    } else {
      dispatch(hideModal());
    }
  };

  const handleNextClick = () => {
    const allIds = getAllIds();

    const { closeHook, currentIdx, originalUrl, loadMore } = modal;

    const oneIdLeftButNotCurrentId = allIds.length === 1 && allIds[0] !== currentId;

    const nextId = oneIdLeftButNotCurrentId ? allIds[0] : allIds[currentIdx + 1];

    const nearEnd = allIds.length - currentIdx <= 3;

    nearEnd && loadMore && loadMore();

    const {
      search,
      state: { modal: modalState, parent },
    } = location;

    if (nextId) {
      const nextCurrentIdx = oneIdLeftButNotCurrentId ? 0 : currentIdx + 1;
      dispatch(setCurrentIdx({ currentIdx: nextCurrentIdx }));

      showModal({
        closeHook,
        key: modalState,
        parent,
        route: getNextRoute(match, nextId, paginatorUrl),
        originalUrl,
        search,
        loadMore,
      });
    }
  };

  const handlePrevClick = () => {
    const allIds = getAllIds();

    const { closeHook, loadMore, currentIdx, originalUrl } = modal;

    const nextId = allIds[currentIdx - 1];

    const {
      search,
      state: { modal: modalState, parent },
    } = location;

    if (nextId) {
      dispatch(setCurrentIdx({ currentIdx: currentIdx - 1 }));

      showModal({
        closeHook,
        key: modalState,
        parent,
        route: getNextRoute(match, nextId, paginatorUrl),
        originalUrl,
        search,
        loadMore,
      });
    }
  };

  const allIds = getAllIds();

  const { currentIdx, initialRender } = modal;

  const classes = classnames(
    styles.Modal,
    {
      [styles.large]: large,
    },
    className
  );

  const oneIdLeftButNotCurrentId = allIds.length === 1 && allIds[0] !== currentId;

  const prevButtonDisabled = currentIdx === 0;

  const prevButtonClasses = classnames(styles.prevButton, {
    [styles.disabledNavButton]: prevButtonDisabled || oneIdLeftButNotCurrentId,
  });

  const nextButtonDisabled = currentIdx === allIds.length - 1;

  const nextButtonClasses = classnames(styles.nextButton, {
    [styles.disabledNavButton]: nextButtonDisabled && !oneIdLeftButNotCurrentId,
  });

  const paginationElements =
    !initialRender && allIds.length > 0 ? (
      <div className={styles.pageButtons} data-testid="pageButtons">
        <div
          className={nextButtonClasses}
          onClick={nextButtonDisabled && !oneIdLeftButNotCurrentId ? null : handleNextClick}
        >
          <FontIcon iconName="caret-right" />
        </div>
        <div
          className={prevButtonClasses}
          onClick={prevButtonDisabled || oneIdLeftButNotCurrentId ? null : handlePrevClick}
        >
          <FontIcon iconName="caret-left" />
        </div>
      </div>
    ) : null;

  const { enableNotes, showNotes } = useContext(NotesContext);

  const navElements = (
    <div className={styles.navElementContainer}>
      <div className={styles.closeButton} onClick={handleCloseClick}>
        <FontIcon iconName="close" />
      </div>
      {enableNotes && !showNotes && <NotesButton />}
      {paginationElements}
    </div>
  );

  const timeout = initialRender ? 0 : 1000;

  const modalContent = initialRender ? (
    <Overlay isOpened {...others}>
      <div className={classes}>{children}</div>
      {navElements}
    </Overlay>
  ) : (
    <Overlay isOpened {...others}>
      <CSSTransition
        appear
        timeout={{ enter: timeout }}
        enter={false}
        exit={false}
        classNames={styles}
      >
        <>
          <div className={classes}>{children}</div>
          {navElements}
        </>
      </CSSTransition>
    </Overlay>
  );

  const modalCenteredContent = initialRender ? (
    <Overlay isOpened {...others}>
      <div className={styles.ModalContainer}>
        <div className={classes}>
          {children}
          {navElements}
        </div>
      </div>
    </Overlay>
  ) : (
    <Overlay isOpened {...others}>
      <CSSTransition
        appear
        timeout={{ enter: 1000 }}
        enter={false}
        exit={false}
        classNames={styles}
      >
        <div className={styles.ModalContainer}>
          <div className={classes}>
            {children}
            {navElements}
          </div>
        </div>
      </CSSTransition>
    </Overlay>
  );

  return centered ? modalCenteredContent : modalContent;
};

export default Modal;
