import React, { Component } from 'react';
import classnames from 'classnames';

import { handleError } from 'utils/common';

import { ComponentErrorBoundaryHOC } from 'containers/HOC';

import Button from 'components/Button';
import FontIcon from 'components/FontIcon';

import styles from './Sidebar.scss';

export class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      linkCopied: false,
      showLink: null,
    };
  }

  handleCopy = (path) => {
    const host = window.location.origin;

    const textToCopy = `${host}${path}`;

    this.copyThisText.value = textToCopy;

    this.copyThisText.select();

    try {
      document.execCommand('copy');

      this.copyThisText.blur();

      this.setState({
        linkCopied: true,
      });

      setTimeout(() => {
        this.setState({
          linkCopied: false,
        });
      }, 4000);
    } catch (error) {
      handleError(error);
      this.setState({ showLink: textToCopy });
    }
  };

  render() {
    const { isCandidate, requisitionOpeningId } = this.props;

    const { linkCopied, showLink } = this.state;

    const linkCopiedClasses = classnames(styles.linkCopiedText, {
      [styles.linkCopiedTextActive]: linkCopied,
    });

    const shareButton = (
      <div key="regularShare" className={styles.linkNotCopied}>
        <FontIcon iconName="share" className={styles.copyLinkIcon} />
        Share Opening
      </div>
    );

    const linkCopiedText = linkCopied ? (
      <div className={styles.linkCopied}>
        <FontIcon iconName="checkmark-circle" className={styles.copiedLinkIcon} />
        Link copied!
      </div>
    ) : (
      shareButton
    );

    const linkCopyButtonProps = {
      tertiary: true,
      active: linkCopied,
      className: linkCopiedClasses,
      onClick: () => this.handleCopy(`/openings/${requisitionOpeningId}/`),
    };

    const showLinkContent =
      showLink !== null
        ? [
            <div key="linkMessage" className={styles.showLinkError}>
              Browser not supported, please manually copy the link below:
            </div>,
            <div key="linkButton" className={styles.showLink}>
              {showLink}
            </div>,
          ]
        : null;

    const profileCopyLinkContainerClasses = classnames(styles.profileCopyLinkContainer);

    return isCandidate ? null : (
      <div className={styles.profileHeaderContainer}>
        <div className={profileCopyLinkContainerClasses}>
          <Button {...linkCopyButtonProps}>{linkCopiedText}</Button>
          {showLinkContent}
        </div>
        <input
          ref={(ref) => {
            this.copyThisText = ref;
          }}
          className={styles.hidden}
        />
      </div>
    );
  }
}

export default ComponentErrorBoundaryHOC()(Sidebar);
