import { call, select, put, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { push } from 'connected-react-router';

import { handleError } from 'utils/common';
import { trackEvent } from 'utils/analytics';
import { segmentCandidateSetRequirements } from 'segment/eventNames';

import { patchEntity, patchNestedEntity, postNestedEntity } from 'api/apiEntity';

import { candidateProfilePatchDone } from 'connectors/User/redux/creators';

import { selectUser } from 'connectors/User/redux/selectors';

import { SET_REQUIREMENTS_STARTED } from '../actions';

import { serverErrorSet } from '../creators';

export const doCandReq = function* doCandReq(candidateRequirement, candidateId) {
  const { id: candidateRequirementId = '', salaryCents = '', oteCents = '' } = candidateRequirement;

  const args = {
    data: {
      attributes: {
        candidateId,
        ...candidateRequirement,
      },
      id: candidateRequirementId,
      type: 'candidate_requirement',
    },
    config: {
      params: {
        normalizeIt: true,
      },
    },
    id: candidateId,
    nestedType: 'candidate_requirement',
    type: 'candidates',
  };

  if (!candidateRequirementId && salaryCents !== '' && oteCents !== '') {
    const {
      data: { entities: { candidateRequirement: { byId = {}, allIds = [] } = {} } = {} },
    } = yield call(postNestedEntity, args);

    return byId[allIds[0]].attributes || {};
  }

  if (candidateRequirementId) {
    const {
      data: { entities: { candidateRequirement: { byId = {}, allIds = [] } = {} } = {} },
    } = yield call(patchNestedEntity, args);

    return byId[allIds[0]].attributes || {};
  }

  return candidateRequirement;
};

export const setRequirementsStarted = function* setRequirementsStarted(action) {
  const {
    payload: { requirementsData, autosave },
  } = action;

  const {
    candidateRequirement,
    closingExperience,
    hiringStatus,
    managementExperience,
    outsideSalesExperience,
    recentGrad,
    salesExperience,
    soldCLevel,
    soldSaas,
    startupExperience,
  } = requirementsData;

  const user = yield select(selectUser);

  const {
    currentProfile: { id: candidateId, onboardingStatus },
  } = user;

  if (autosave) {
    const candidateProfile = {
      ...user.currentProfile,
      ...requirementsData,
    };

    yield put(candidateProfilePatchDone({ candidateProfile }));

    yield call(delay, 750);
  } else {
    yield put(push('/create-profile'));
  }

  try {
    const candReq = yield call(doCandReq, candidateRequirement, candidateId);

    const newOnboardingStatus =
      (onboardingStatus === 'applied' || onboardingStatus === 'applied_forum') && !autosave
        ? 'set_requirements'
        : onboardingStatus;

    if (newOnboardingStatus !== onboardingStatus) {
      yield call(trackEvent, { event: segmentCandidateSetRequirements });
    }

    const args = {
      id: candidateId,
      data: {
        type: 'candidates',
        id: candidateId,
        attributes: {
          closingExperienceMonths: closingExperience,
          hiringStatus,
          managementExperience,
          onboardingStatus: newOnboardingStatus,
          outsideSalesExperience,
          recentGrad,
          salesExperienceMonths: salesExperience,
          soldCLevel,
          soldSaas,
          startupExperience,
        },
      },
      type: 'candidates',
      config: {
        params: {
          normalizeIt: true,
        },
      },
    };

    const { data: { entities: { candidate: { byId = {}, allIds = [] } = {} } = {} } = {} } =
      yield call(patchEntity, args);

    const newCandidate = byId[allIds[0]].attributes;

    const candidateProfile = {
      ...user.currentProfile,
      ...newCandidate,
      candidateRequirement: candReq,
    };

    yield put(candidateProfilePatchDone({ candidateProfile }));
  } catch (error) {
    handleError(error);

    const {
      response: {
        data: { errors: { full_messages: errors = ['Please try again'] } = {} } = {},
      } = {},
    } = error || {};

    yield put(serverErrorSet({ errors }));
  }
};

const sagaSetRequirementsStarted = function* sagaSetRequirementsStarted() {
  yield takeLatest(SET_REQUIREMENTS_STARTED, setRequirementsStarted);
};

export default sagaSetRequirementsStarted;
