import React from 'react';
import Slider from 'react-rangeslider';

import DropdownSelectMany from 'components/DropdownSelectMany';
import Label from 'components/Label';

import styles from '../ReqRequirement.scss';

const SoldToCompanySizes = ({
  disabled,
  handleArrayChange,
  handleInputChange,
  requisitionRequirement: { attributes: { soldToCompanySizes, soldToCompanySizesWt } = {} } = {},
  sizeOptions,
}) => {
  const companySizeOptions = sizeOptions.map((size) => ({
    indent: 0,
    label: size,
    optionType: 'size',
    subCategories: [],
    value: {
      type: 'size',
      size,
    },
  }));

  const selectedSizes = soldToCompanySizes
    ? soldToCompanySizes.map((size) => ({ size, type: 'size' }))
    : [];

  const sizeInputProps = {
    className: styles.searchBarFilter,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    onChange: (value) =>
      handleArrayChange({
        target: {
          subCategories: value.subCategories,
          label: value.label,
          value: value.value,
          name: 'soldToCompanySizes',
          optionType: value.optionType,
        },
      }),
    optionsLarge: true,
    source: companySizeOptions,
    name: 'soldToCompanySizes',
    key: 'soldToCompanySizes',
    isLocation: true,
    selectedValues: selectedSizes || [],
    defaultLabel: 'Click to select a company size',
    disabled,
  };

  const sizeWtProps = {
    disabled,
    className: styles.rangeSlider,
    min: 0,
    max: 10,
    step: 5,
    value: soldToCompanySizesWt || 0,
    onChange: disabled
      ? () => null
      : (value) =>
          handleInputChange({
            target: {
              value,
              name: 'soldToCompanySizesWt',
            },
          }),
    tooltip: false,
    labels: {
      0: '0',
      5: '5',
      10: '10',
    },
  };

  return (
    <div className={styles.rangeInputs}>
      <div className={styles.column}>
        <Label className={styles.label}>Company sizes sold to?</Label>
        <DropdownSelectMany {...sizeInputProps} />
      </div>
      <div className={styles.column}>
        <Label className={styles.labelStyle}>How much do you value this?</Label>
        <Slider {...sizeWtProps} />
      </div>
    </div>
  );
};

export default SoldToCompanySizes;
