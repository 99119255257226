import React from 'react';
import { Helmet } from 'react-helmet-async';

import { AdminAuthHOC } from 'containers/HOC';

import { makeLikeParam } from 'utils/paramUtils';

import ConnectContainer from 'containers/ConnectContainer';

import ReadyContainer from 'connectors/ReadyContainer';

import { useTableQuery } from 'hooks';

import Sidebar from 'components/Sidebar';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';

import ToolboxCohortsTable from './components/ToolboxCohortsTable';

import duck from './redux';

import styles from './ToolboxCohortsPage.scss';

const route = '/toolbox/cohorts/';

const ToolboxCohortsPage = ({ actions, cohorts, children, isLoading, total, user, page = 1 }) => {
  const {
    queryParams,
    loadMore,
    handleSearchInputChange,
    handleSearchFilterChange,
    handleSortChange,
  } = useTableQuery(
    route,
    page,
    {
      category: {
        type: 'array',
        defaultValue: ['search', 'featured'],
      },
      name: {
        type: 'string',
        defaultValue: null,
      },
      sortBy: {
        type: 'string',
        defaultValue: 'created_at_desc',
      },
      state: {
        type: 'array',
        defaultValue: ['active', 'open'],
      },
    },
    {
      type: 'cohorts',
      slice: 'toolboxCohorts',
    },
    {},
    {
      name: makeLikeParam,
    }
  );

  return (
    <>
      <Helmet key="helmet" title="Toolbox - Cohorts" />

      <ReadyContainer key="readyContainer">
        <Sidebar type="toolbox" page="toolboxCohorts" />

        <LayoutWithoutSidebar
          content={
            <div className={styles.toolboxCohortsMainContent}>
              <ToolboxCohortsTable
                {...queryParams}
                actions={actions}
                cohorts={cohorts}
                handleSearchInputChange={handleSearchInputChange}
                handleSearchFilterChange={handleSearchFilterChange}
                handleSortChange={handleSortChange}
                loadMore={loadMore}
                isLoading={isLoading}
                total={total}
                user={user}
              />
            </div>
          }
        />

        {children}
      </ReadyContainer>
    </>
  );
};

export default ConnectContainer(duck)(AdminAuthHOC()(ToolboxCohortsPage));
