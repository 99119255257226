import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';

import { createRoute, makeLikeParam, queryStringParse } from 'utils/paramUtils';

import { toggleArray } from 'utils/formUtils';

import { EmployeeAuthHOC } from 'containers/HOC';
import ConnectContainer from 'containers/ConnectContainer';

import ReadyContainer from 'connectors/ReadyContainer';
import ErrorModal from 'connectors/DialogModals/ErrorModal';

import Sidebar from 'components/Sidebar';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';

import EmployerRequisitionsTable from './components/EmployerRequisitionsTable';

import duck from './redux';

import styles from './ManageReqs.scss';

@EmployeeAuthHOC()
class ManageReqs extends Component {
  constructor(props) {
    super(props);
    const {
      location: { search },
    } = props;

    const queryParams = queryStringParse({
      search,
      params: {
        externalName: {
          type: 'string',
          defaultValue: null,
        },
        role: {
          type: 'array',
          defaultValue: [],
        },
        sortBy: {
          type: 'string',
          defaultValue: 'state_asc,published_on_desc,created_at_desc',
        },
        state: {
          type: 'array',
          defaultValue: ['open', 'pending'],
        },
      },
    });

    this.state = {
      ...queryParams,
      serverError: null,
    };

    this.defaultLoadParams = {
      externalName: null,
      includeSet: 'employer',
      page: 1,
      requisitionCandidateId: 'null',
      role: [],
      sortBy: 'state_asc,published_on_desc,created_at_desc',
      state: ['open', 'pending'],
    };

    this.defaultLoadArgs = {
      type: 'requisition_openings',
      slice: 'employerRequisitions',
    };

    this.route = '/manage/reqs/';
  }

  componentDidMount() {
    const {
      actions: { entityLoadStarted, pickerOptionsLoadStarted },
    } = this.props;

    entityLoadStarted({
      ...this.defaultLoadArgs,
      params: {
        ...this.getLoadParams(),
        page: 1,
      },
    });

    pickerOptionsLoadStarted();
  }

  getLoadParams = () => {
    const { page = 1 } = this.props;
    const { externalName, role, sortBy, state } = this.state;

    return {
      ...this.defaultLoadParams,
      externalName: makeLikeParam(externalName),
      role,
      page,
      sortBy,
      state,
    };
  };

  loadMore = () => {
    const {
      actions: { entityLoadStarted },
      page,
    } = this.props;

    entityLoadStarted({
      ...this.defaultLoadArgs,
      params: {
        ...this.getLoadParams(),
        page: page + 1,
      },
    });
  };

  handleSortChange = (value) => {
    const {
      actions: { entityLoadStarted },
      location,
    } = this.props;

    const { sortBy } = this.state;

    if (sortBy && sortBy.includes(value)) {
      const newDirection = sortBy.includes('asc') ? '_desc' : '_asc';

      const newSort = `${value}${newDirection}`;

      const newRoute = createRoute({
        route: this.route,
        location,
        name: 'sortBy',
        value: newSort,
      });

      this.setState({ sortBy: newSort }, () =>
        entityLoadStarted({
          ...this.defaultLoadArgs,
          newRoute,
          params: {
            ...this.getLoadParams(),
            page: 1,
          },
        })
      );
    } else {
      const newSort = `${value}_asc`;

      const newRoute = createRoute({
        route: this.route,
        location,
        name: 'sortBy',
        value: newSort,
      });

      this.setState({ sortBy: `${value}_asc` }, () =>
        entityLoadStarted({
          ...this.defaultLoadArgs,
          newRoute,
          params: {
            ...this.getLoadParams(),
            page: 1,
          },
        })
      );
    }
  };

  handleSearchFilterChange = (event) => {
    const {
      target: { name, value },
    } = event;
    const {
      actions: { entityLoadStarted },
      location,
    } = this.props;

    const { [name]: arrayToUpdate } = this.state;

    const actualValue = value[value.type];

    const newArray = toggleArray({ array: arrayToUpdate, value: actualValue });

    const newRoute = createRoute({
      route: this.route,
      location,
      name,
      value: newArray,
    });

    this.setState({ [name]: newArray }, () =>
      entityLoadStarted({
        ...this.defaultLoadArgs,
        newRoute,
        params: {
          ...this.getLoadParams(),
          page: 1,
        },
      })
    );
  };

  handleSearchInputChange = ({ target: { name, value } }) => {
    const {
      actions: { entityLoadStarted },
      location,
    } = this.props;

    const newRoute = createRoute({
      route: this.route,
      location,
      name,
      value,
    });

    this.setState({ [name]: value }, () =>
      entityLoadStarted({
        ...this.defaultLoadArgs,
        newRoute,
        params: {
          ...this.getLoadParams(),
          page: 1,
        },
      })
    );
  };

  render() {
    const {
      actions,
      children,
      isLoading,
      modal,
      pickerOptions: { role: roleOptions = [] } = {},
      requisitionOpenings = {},
      total,
      user,
    } = this.props;

    const { serverError } = this.state;

    const tableProps = {
      ...this.state,
      actions,
      handleSearchFilterChange: this.handleSearchFilterChange,
      handleSearchInputChange: this.handleSearchInputChange,
      handleSortChange: this.handleSortChange,
      isLoading,
      loadMore: this.loadMore,
      modal,
      roleOptions,
      requisitionOpenings,
      total,
      user,
    };

    const serverErrorContent = serverError ? (
      <ErrorModal resetAppError={() => this.setState({ serverError: null })}>
        Sorry, something has gone wrong. Please try again or contact{' '}
        <a href="mailto:support@rainmakers.co">support@rainmakers.co</a> if the problem persists.
      </ErrorModal>
    ) : null;

    const content = (
      <div className={styles.manageReqsMainContent}>
        <EmployerRequisitionsTable {...tableProps} />
        {serverErrorContent}
      </div>
    );

    const layoutWithoutSidebarProps = { content };

    return (
      <React.Fragment>
        <Helmet title="Manage Reqs" />

        <ReadyContainer className={styles.ManageReqs}>
          <Sidebar type="manage" page="manageReqs" />
          <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />
          {children}
        </ReadyContainer>
      </React.Fragment>
    );
  }
}

export default ConnectContainer(duck)(ManageReqs);
