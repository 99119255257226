import fecha from 'fecha';

export const timeAgoInHours = (date) => {
  if (date) {
    const parsedDate = fecha.parse(`${date}`, 'YYYY-MM-DD HH:mm:ss ZZ');
    if (parsedDate) {
      const timeAgoInMs = Date.now() - parsedDate;

      return timeAgoInMs / 3600000;
    }
    return null;
  }
  return null;
};

export const timeAgoInDays = (date) => {
  const hours = timeAgoInHours(date);

  if (hours) return hours / 24;

  return null;
};

export const timeAgoInMonths = (date) => {
  const days = timeAgoInDays(date);

  if (days) return days / 30;

  return null;
};
