import React from 'react';

import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import Block from 'components/Block';

import YourReferralCode from 'components/YourReferralCode';
import YourReferrals from 'components/YourReferrals';
import YourDefaultReferralSource from 'components/YourDefaultReferralSource';

import styles from './AdminEditorReferrals.scss';

const AdminEditorReferrals = ({
  user: { referralCode = 'No Code', referredHires = 0 } = {},
  ...restProps
}) => {
  const {
    users: { byId: usersById = {} } = {},
    rainmakersAdmin: { relationships: { user: { data: userIds = [] } = {} } = {} },
  } = restProps;

  const { attributes: { email, referredUserCount } = {} } = usersById[userIds[0]] || {};

  const content = (
    <div className={styles.EditorReferrals}>
      <Block addWhiteBG={true} boxShadow={true} addPadding={true} className={styles.titleBlock}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            <div>Referrals</div>
          </div>
        </div>
      </Block>

      <Block addWhiteBG={true} boxShadow={true} addPadding={false} className={styles.contentBlock}>
        <YourReferralCode
          referralCode={referralCode}
          referralEmail={encodeURIComponent(email)}
          extraPath="apply-now"
        />
        <YourReferrals referredHires={referredHires} referredSignUps={referredUserCount} />
        <YourDefaultReferralSource {...restProps} />
      </Block>
    </div>
  );

  return <LayoutWithoutSidebar content={content} />;
};

export default AdminEditorReferrals;
