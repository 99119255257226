/* eslint-disable security/detect-object-injection */

// CANDIDATE
// -- PARENTS
import CandidateHomePage from 'pages/CandidatePortal/CandidateHomePage/CandidateHome';
import CandidateOpportunitiesPage from 'pages/CandidatePortal/CandidateOpportunitiesPage';
// -- NORMAL
import EditEducation from 'pages/CandidatePortal/CandidateEditorPages/EditEducation';
import EditPersonal from 'pages/CandidatePortal/CandidateEditorPages/EditPersonal';
import EditPreview from 'pages/CandidatePortal/CandidateEditorPages/EditPreview';
import EditRecruiting from 'pages/CandidatePortal/CandidateEditorPages/EditRecruiting';
import EditSales from 'pages/CandidatePortal/CandidateEditorPages/EditSales';
import EditVideo from 'pages/CandidatePortal/CandidateEditorPages/EditVideo';
import EditWork from 'pages/CandidatePortal/CandidateEditorPages/EditWork';
import AddSalesInfo from 'pages/CandidatePortal/OnboardingPages/AddSalesInfo';
import AddVideo from 'pages/CandidatePortal/OnboardingPages/AddVideo';
import Apply from 'pages/CandidatePortal/OnboardingPages/Apply';
import CommunityProfile from 'pages/CandidatePortal/OnboardingPages/CommunityProfile';
import CreateProfile from 'pages/CandidatePortal/OnboardingPages/CreateProfile';
import CommunityApply from 'pages/CandidatePortal/OnboardingPages/ForumApply';
import SetRequirements from 'pages/CandidatePortal/OnboardingPages/SetRequirements';
import CandidateSignupStatus from 'pages/CandidatePortal/CandidateSettingsPages/CandidateSignupStatus';
import EmailSettings from 'pages/CandidatePortal/CandidateSettingsPages/EmailSettings';
import PrivacySettings from 'pages/CandidatePortal/CandidateSettingsPages/PrivacySettings';
import Referrals from 'pages/CandidatePortal/CandidateSettingsPages/Referrals';
// -- MODAL
import CandidatePassModal from 'pages/CandidatePortal/CandidatePassPage';

// EMPLOYEE
// -- PARENTS
import DashboardPage from 'pages/EmployeePortal/DashboardPage';
import CandidateSearchPage from 'pages/EmployeePortal/CandidateSearchPage';
import CandidateBookmarksPage from 'pages/EmployeePortal/CandidateBookmarksPage';
import ManageReqsPage from 'pages/EmployeePortal/ManageReqsPage';
import EmployerEmployeesPage from 'pages/EmployeePortal/EmployeeEditorPages/EditEmployees';
// -- NORMAL
import EmployerEditCompany from 'pages/EmployeePortal/EmployeeEditorPages/EditCompany';
import EmployerEditIntegrations from 'pages/EmployeePortal/EmployeeEditorPages/EditIntegrations';
import EmployerEditPersonal from 'pages/EmployeePortal/EmployeeEditorPages/EditPersonal';
import EmployerEditPreview from 'pages/EmployeePortal/EmployeeEditorPages/EditPreview';
import EmployerSignup from 'pages/EmployeePortal/SignupPage';
import EmployerSignupStatus from 'pages/EmployeePortal/SignupStatusPage';
import EmployerThanks from 'pages/EmployeePortal/Thanks';
import FeaturedPage from 'pages/EmployeePortal/FeaturedPage';
import CandidateTurboProfile from 'pages/EmployeePortal/CandidateTurboProfile';
// -- MODAL
import CandidateProfileModal from 'pages/EmployeePortal/CandidateProfilePages/CandidateWrapper';
import ReqDetailModal from 'pages/EmployeePortal/RequisitionOpeningPages';
import EmployeeEditorModal from 'pages/EmployeePortal/EmployeeEditorPages/EditEmployee';

// ADMIN
// -- PARENTS
import ToolboxAdminsPage from 'pages/AdminPortal/ToolboxAdminsPage';
import ToolboxApproachesPage from 'pages/AdminPortal/ToolboxApproachesPage';
import ToolboxRequisitionsPage from 'pages/AdminPortal/ToolboxRequisitionsPage';
import ToolboxCandidatesPage from 'pages/AdminPortal/ToolboxCandidatesPage';
import ToolboxReferralsPage from 'pages/AdminPortal/ToolboxReferralsPage';
import ToolboxEmployersPage from 'pages/AdminPortal/ToolboxEmployersPage';
import ToolboxLeadsPage from 'pages/AdminPortal/ToolboxLeadsPage';
// import ToolboxEmployerEditorPage from 'pages/AdminPortal/ToolboxEmployerEditorPage';
import ToolboxCompaniesPage from 'pages/AdminPortal/ToolboxCompaniesPage';
import ToolboxEducationOrgsPage from 'pages/AdminPortal/ToolboxEducationOrgsPage';
import ToolboxCohortsPage from 'pages/AdminPortal/ToolboxCohortsPage';
import ToolboxBatchRequestsPage from 'pages/AdminPortal/ToolboxBatchRequestsPage';
// -- MODAL
import AdminEditorModal from 'pages/AdminPortal/ToolboxAdminEditorPage';
import ApproachEditorModal from 'pages/AdminPortal/ToolboxApproachEditorPage';
import RequisitionEditorModal from 'pages/AdminPortal/ToolboxRequisitionEditorPage';
import CandidateEditorModal from 'pages/AdminPortal/ToolboxCandidateEditorPage';
import CohortEditorModal from 'pages/AdminPortal/ToolboxCohortEditorPage';
import EmployerEditorModal from 'pages/AdminPortal/ToolboxEmployerEditorPage';
import CompanyEditorModal from 'pages/AdminPortal/ToolboxCompanyEditorPage';
import EducationOrgEditorModal from 'pages/AdminPortal/ToolboxEducationOrgEditorPage';

// MAIN PAGES
import AboutUs from 'pages/AboutUs';
import ActivateAccount from 'pages/NewPasswordPage';
import CandidatesFaq from 'pages/CandidateFAQPage';
import CandidatesHome from 'pages/CandidatesHome';
import Careers from 'pages/Careers';
import DiscourseSSO from 'pages/DiscourseSSOPage';
import EmployerFaq from 'pages/EmployerFAQPage';
import EmployersHome from 'pages/EmployersHome';
import HiringForm from 'pages/HiringFormPage';
import Login from 'pages/SigninPage';
import LoginSAML from 'pages/LoginSAMLPage';
import NotFound from 'pages/UtilPages/NotFound';
import PrivacyPolicy from 'pages/PrivacyPolicyPage';
import ResetPassword from 'pages/ResetPasswordPage';
import Terms from 'pages/TermsPage';
import Unauthorized from 'pages/UtilPages/UnauthorizedPage';
import VideoPlayer from 'pages/VideoPlayerPage';
import JobsPage from 'pages/JobsPage';
import CityRecruiterPage from 'pages/CityRecruiterPage';
import LocationsPage from 'pages/LocationsPage';
import SkillsPage from 'pages/SkillsPage';
import MarketsJobsPage from 'pages/MarketsJobsPage';
import SitemapPage from 'pages/SitemapPage';

const allRoutes = [
  // modal routes
  {
    route: '/requisition_candidates/:reqCandId/requisition_opening/:view',
    component: ReqDetailModal,
    modal: true,
  },
  {
    route: '/employee/edit-employees/:id/:view',
    component: EmployeeEditorModal,
    modal: true,
  },
  {
    route: '/requisitions/:id/:view',
    component: ReqDetailModal,
    modal: true,
  },
  {
    route: '/candidate/opportunity/:id',
    component: CandidatePassModal,
    modal: true,
  },
  {
    route: '/candidates/:id/:view',
    component: CandidateProfileModal,
    modal: true,
  },
  {
    route: '/toolbox/admin/:id/:view/:nestedId?',
    component: AdminEditorModal,
    modal: true,
  },
  {
    route: '/toolbox/approach/:id/:view',
    component: ApproachEditorModal,
    modal: true,
  },
  {
    route: '/turbo-profile/:id/',
    component: CandidateTurboProfile,
    modal: false,
  },
  {
    route: '/toolbox/candidate/:id/:view',
    component: CandidateEditorModal,
    modal: true,
  },
  {
    route: '/toolbox/cohort/:id/:view',
    component: CohortEditorModal,
    modal: true,
  },
  {
    route: '/toolbox/company/:id/:view',
    component: CompanyEditorModal,
    modal: true,
  },
  {
    route: '/toolbox/education-org/:id/:view',
    component: EducationOrgEditorModal,
    modal: true,
  },
  {
    route: '/toolbox/employer/:id/:view/:employeeId?/:reqView?',
    component: EmployerEditorModal,
    modal: true,
  },
  {
    route: '/toolbox/requisition/:id/:view',
    component: RequisitionEditorModal,
    modal: true,
  },
  // non-modal routes
  {
    route: '/',
    component: CandidatesHome,
    sitemap: true,
  },
  {
    route: '/about-us',
    component: AboutUs,
    sitemap: true,
  },
  {
    route: '/activate-account',
    component: ActivateAccount,
  },
  {
    route: '/blog',
    sitemap: true,
  },
  {
    route: '/careers',
    component: Careers,
    sitemap: true,
  },
  {
    route: '/discourse-sso/:type?',
    component: DiscourseSSO,
  },
  {
    route: '/faq',
    component: CandidatesFaq,
    sitemap: true,
  },
  {
    route: '/my-hiring-profile',
    component: HiringForm,
    sitemap: true,
  },
  {
    route: '/new-password',
    component: ActivateAccount,
  },
  {
    route: '/reset-password',
    component: ResetPassword,
  },
  {
    route: '/privacy',
    component: PrivacyPolicy,
  },
  {
    route: '/sitemap/',
    component: null,
    sitemap: true,
  },
  {
    route: '/terms',
    component: Terms,
  },
  {
    route: '/saml_unauthorized',
    component: Unauthorized,
  },
  {
    route: '/unauthorized',
    component: Unauthorized,
  },
  {
    route: '/intro-video/:id',
    component: VideoPlayer,
  },
  {
    route: '/employers',
    component: EmployersHome,
    sitemap: true,
  },
  {
    route: '/employer-faq',
    component: EmployerFaq,
    sitemap: true,
  },
  {
    route: '/login/saml',
    component: LoginSAML,
  },
  {
    route: '/login/:type?',
    component: Login,
  },
  {
    route: '/toolbox/admins',
    component: ToolboxAdminsPage,
  },
  {
    route: '/toolbox/approaches',
    component: ToolboxApproachesPage,
  },
  {
    route: '/toolbox/cohort-requests',
    component: ToolboxBatchRequestsPage,
  },
  {
    route: '/toolbox/candidates',
    component: ToolboxCandidatesPage,
  },
  {
    route: '/toolbox/referrals',
    component: ToolboxReferralsPage,
  },
  {
    route: '/toolbox/cohorts',
    component: ToolboxCohortsPage,
  },
  {
    route: '/toolbox/companies',
    component: ToolboxCompaniesPage,
  },
  {
    route: '/toolbox/education-orgs',
    component: ToolboxEducationOrgsPage,
  },
  {
    route: '/toolbox/employers',
    component: ToolboxEmployersPage,
  },
  {
    route: '/toolbox/leads',
    component: ToolboxLeadsPage,
  },
  {
    route: '/toolbox/requisitions',
    component: ToolboxRequisitionsPage,
  },
  {
    route: '/candidate/home',
    component: CandidateHomePage,
  },
  {
    route: '/candidate/opportunities/:state',
    component: CandidateOpportunitiesPage,
  },
  {
    route: '/manage/dashboard/:state?/:reqId?',
    component: DashboardPage,
  },
  {
    route: '/featured',
    component: CandidateSearchPage,
  },
  {
    route: '/matches',
    component: FeaturedPage,
  },
  {
    route: '/saved',
    component: CandidateBookmarksPage,
  },
  {
    route: '/manage/reqs/:deprecated_state?',
    component: ManageReqsPage,
  },
  {
    route: '/employee/edit-employees',
    component: EmployerEmployeesPage,
  },
  {
    route: '/candidate/edit-education',
    component: EditEducation,
  },
  {
    route: '/candidate/edit-personal',
    component: EditPersonal,
  },
  {
    route: '/candidate/edit-preview',
    component: EditPreview,
  },
  {
    route: '/candidate/edit-recruiting',
    component: EditRecruiting,
  },
  {
    route: '/candidate/edit-sales',
    component: EditSales,
  },
  {
    route: '/candidate/intro-video',
    component: EditVideo,
  },
  {
    route: '/candidate/edit-work',
    component: EditWork,
  },
  {
    route: '/add-sales-info',
    component: AddSalesInfo,
  },
  {
    route: '/add-video',
    component: AddVideo,
  },
  {
    route: '/apply-now',
    component: Apply,
    sitemap: true,
  },
  {
    route: '/community-profile',
    component: CommunityProfile,
  },
  {
    route: '/create-profile',
    component: CreateProfile,
  },
  {
    route: '/community-apply',
    component: CommunityApply,
  },
  {
    route: '/set-requirements',
    component: SetRequirements,
  },
  {
    route: '/candidate/status',
    component: CandidateSignupStatus,
  },
  {
    route: '/candidate/email-settings',
    component: EmailSettings,
  },
  {
    route: '/candidate/privacy-settings',
    component: PrivacySettings,
  },
  {
    route: '/candidate/referrals',
    component: Referrals,
  },
  {
    route: '/employee/edit-company',
    component: EmployerEditCompany,
  },
  {
    route: '/employee/edit-integrations',
    component: EmployerEditIntegrations,
  },
  {
    route: '/employee/edit-personal',
    component: EmployerEditPersonal,
  },
  {
    route: '/employee/edit-preview',
    component: EmployerEditPreview,
  },
  {
    route: '/openings/:id',
    component: ReqDetailModal,
  },
  {
    route: '/employer-signup/',
    component: EmployerSignup,
    sitemap: true,
  },
  {
    route: '/employer-signup-status',
    component: EmployerSignupStatus,
  },
  {
    route: '/thanks/',
    component: EmployerThanks,
  },
  {
    route: '/jobs/:page?',
    component: JobsPage,
  },
  {
    route: '/recruiting/:page',
    component: CityRecruiterPage,
  },
  {
    route: '/markets/:page?',
    component: LocationsPage,
  },
  {
    route: '/top-skills/:page?',
    component: SkillsPage,
  },
  {
    route: '/markets/:market?/:role?',
    component: MarketsJobsPage,
  },
  {
    route: '/sitemap/',
    component: SitemapPage,
  },
  {
    route: '*',
    component: NotFound,
  },
];

const modalRoutes = allRoutes.filter((route) => route.component && route.modal);
const nonModalRoutes = allRoutes.filter((route) => route.component && !route.modal);

export { allRoutes, modalRoutes, nonModalRoutes };
