import { call, select, put, take } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { patchNestedEntity } from 'api/apiEntity';

import { candidateProfilePatchDone } from 'connectors/User/redux/creators';

import { selectUser } from 'connectors/User/redux/selectors';

import { RECENT_ROLE_SET } from '../actions';

import { serverErrorSet } from '../creators';

const sagaRecentRoleSet = function* sagaRecentRoleSet() {
  while (true) {
    const {
      payload: { recentRole },
    } = yield take(RECENT_ROLE_SET);

    const { currentProfile } = yield select(selectUser);

    const { workHistories, id: candidateId } = currentProfile;

    const latestWorkHistory = workHistories ? workHistories[0] : null;

    if (latestWorkHistory) {
      try {
        const args = {
          data: {
            type: 'work_history',
            id: latestWorkHistory.id || '',
            attributes: {
              role: recentRole,
            },
          },
          id: candidateId,
          type: 'candidates',
          nestedType: 'work_histories',
          nestedId: latestWorkHistory.id || '',
        };

        yield call(patchNestedEntity, args);

        const newWorkHistories = workHistories.slice();

        newWorkHistories[0].role = recentRole;

        const candidateProfile = {
          ...currentProfile,
          workHistories: newWorkHistories,
        };

        yield put(candidateProfilePatchDone({ candidateProfile }));
      } catch (error) {
        handleError(error);

        yield put(
          serverErrorSet({
            errors: ['Please try again'],
          })
        );
      }
    }
  }
};

export default sagaRecentRoleSet;
