const prefix = 'RAINMAKERS_';

export const SET_NOTIFICATION = `${prefix}SET_NOTIFICATION`;
export const RESET_NOTIFICATION = `${prefix}RESET_NOTIFICATION`;

export const API_ERRORS_UPDATE = `${prefix}API_ERRORS_UPDATE`;
export const API_ERRORS_DELETE = `${prefix}API_ERRORS_DELETE`;
export const SAVING_UPDATE = `${prefix}SAVING_UPDATE`;

export const PATCH_USER_RESOURCE = `${prefix}PATCH_USER_RESOURCE`;
export const PATCH_USER_EMAIL = `${prefix}PATCH_USER_EMAIL`;

export const PATCH_RESOURCE = `${prefix}PATCH_RESOURCE`;
export const PATCH_NESTED_RESOURCE = `${prefix}PATCH_NESTED_RESOURCE`;
export const POST_RESOURCE = `${prefix}POST_RESOURCE`;
export const DELETE_RESOURCE = `${prefix}DELETE_RESOURCE`;

export const RESOURCE_UPDATE = `${prefix}RESOURCE_UPDATE`;
export const RESOURCE_DELETE = `${prefix}RESOURCE_DELETE`;

export const RESOURCE_ERRORS_UPDATE = `${prefix}RESOURCE_ERRORS_UPDATE`;

export const NESTED_ENTITY_LOAD_STARTED = `${prefix}NESTED_ENTITY_LOAD_STARTED`;
export const ENTITY_LOAD_STARTED = `${prefix}ENTITY_LOAD_STARTED`;
export const ENTITY_LOAD_DONE = `${prefix}ENTITY_LOAD_DONE`;

// APP
export const SET_APP_ERROR = `${prefix}SET_APP_ERROR`;
export const SET_APP_MODAL_ERROR = `${prefix}SET_APP_MODAL_ERROR`;
export const RESET_APP_ERROR = `${prefix}RESET_APP_ERROR`;

export const ADD_REQUEST_INTERCEPTOR = `${prefix}ADD_REQUEST_INTERCEPTOR`;
export const SAVE_REQUEST_INTERCEPTOR = `${prefix}SAVE_REQUEST_INTERCEPTOR`;
export const REMOVE_REQUEST_INTERCEPTOR = `${prefix}REMOVE_REQUEST_INTERCEPTOR`;

export const WAIT_FOR_JOB = `${prefix}WAIT_FOR_JOB`;

export const PING_SESSION = `${prefix}PING_SESSION`;

// LOGIN
export const DIRECT_LOGIN_STARTED = `${prefix}DIRECT_LOGIN_STARTED`;
export const LOGIN_STARTED = `${prefix}LOGIN_STARTED`;
export const LOGIN_DONE = `${prefix}LOGIN_DONE`;
export const CANDIDATE_LOGIN_DONE = `${prefix}CANDIDATE_LOGIN_DONE`;
export const ADMIN_LOGIN_DONE = `${prefix}ADMIN_LOGIN_DONE`;
export const EMPLOYEE_LOGIN_DONE = `${prefix}EMPLOYEE_LOGIN_DONE`;
export const LOGIN_ERROR = `${prefix}LOGIN_ERROR`;
export const LOGIN_ERROR_RESET = `${prefix}LOGIN_ERROR_RESET`;
export const LOGIN_NOTIFICATION = `${prefix}LOGIN_NOTIFICATION`;

export const LOGOUT_STARTED = `${prefix}LOGOUT_STARTED`;
export const LOGOUT_DONE = `${prefix}LOGOUT_DONE`;
export const LOGOUT_ERROR = `${prefix}LOGOUT_ERROR`;

export const SET_LOGGED_OUT_PATH = `${prefix}SET_LOGGED_OUT_PATH`;
export const RESET_LOGGED_OUT_PATH = `${prefix}RESET_LOGGED_OUT_PATH`;

export const IDENTIFY_SEGMENT_USER = `${prefix}IDENTIFY_SEGMENT_USER`;

// USER
export const USER_SAVE = `${prefix}USER_SAVE`;
export const USER_REMOVE = `${prefix}USER_REMOVE`;
export const USER_PATCH_DONE = `${prefix}USER_PATCH_DONE`;
export const USER_UPDATE = `${prefix}USER_UPDATE`;

export const CANDIDATE_PROFILE_PATCH_DONE = `${prefix}CANDIDATE_PROFILE_PATCH_DONE`;

export const SAVE_UTM_INFO = `${prefix}SAVE_UTM_INFO`;

export const SET_BACK_LINK = `${prefix}SET_BACK_LINK`;
export const RESET_BACK_LINK = `${prefix}RESET_BACK_LINK`;

export const SET_DELAY_PROFILE_CARDS = `${prefix}SET_DELAY_PROFILE_CARDS`;

// MODAL
export const SHOW_MODAL = `${prefix}SHOW_MODAL`;
export const HIDE_MODAL = `${prefix}HIDE_MODAL`;
export const NEXT_MODAL = `${prefix}NEXT_MODAL`;

export const SET_MODAL = `${prefix}SET_MODAL`;
export const RESET_MODAL = `${prefix}RESET_MODAL`;

export const SET_CURRENT_IDX = `${prefix}SET_CURRENT_IDX`;
export const RESET_CURRENT_IDX = `${prefix}RESET_CURRENT_IDX`;

// DEFAULTS
export const PICKER_OPTIONS_LOAD_STARTED = `${prefix}PICKER_OPTIONS_LOAD_STARTED`;
export const PICKER_OPTIONS_LOAD_DONE = `${prefix}PICKER_OPTIONS_LOAD_DONE`;

export const TAGS_LOAD_STARTED = `${prefix}TAGS_LOAD_STARTED`;
export const TAGS_LOAD_DONE = `${prefix}TAGS_LOAD_DONE`;

// Image processing
export const SET_IMAGE_UPLOAD_COMPANY = `${prefix}SET_IMAGE_UPLOAD_COMPANY`;
