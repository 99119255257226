import * as creators from 'shared/store/app/creators';

import selector from './selectors';
import reducer from './reducers';

const duck = {
  creators,
  reducer,
  selector,
};

export default duck;
