import { postEntity } from 'api/apiEntity';

const promisePostVimeoVideo = (attributes) => {
  const onFulfilled = (response) => {
    const { data: { entities: { vimeoVideo: { byId, allIds } = {} } = {} } = {} } = response;

    return allIds && allIds.length > 0 ? byId[allIds[0]] : {};
  };

  const args = {
    type: 'vimeo_videos',
    data: {
      type: 'vimeo_videos',
      attributes,
    },
    config: {
      params: {
        normalizeIt: true,
      },
    },
  };

  return postEntity(args).then(onFulfilled);
};

export default promisePostVimeoVideo;
