import React, { Component } from 'react';
import classnames from 'classnames';

import { handleError } from 'utils/common';
import { postEntity } from 'api/apiEntity';

import { makeLikeParam } from 'utils/paramUtils';
import { toggleArray } from 'utils/formUtils';

import AutocompleteServer from 'connectors/AutocompleteServer';
import Block from 'components/Block';
import Bolder from 'components/Bolder';
import Button from 'components/Button';
import ConfirmModal from 'components/ConfirmModal';
import DialogueModal from 'components/DialogueModal';
import FontIcon from 'components/FontIcon';
import Input from 'components/Input';
import InputCheckboxSlider from 'components/InputCheckboxSlider';
import InputSelect from 'components/InputSelect';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import TalentConcierge from 'components/TalentConcierge';

import { promisePostConfirmation } from '../../promises';

import BlockedSettings from './components/BlockedSettings';
import CandReqBlocksSettings from './components/CandReqBlocksSettings';
import ConfirmationLinks from './components/ConfirmationLinks';
import CohortCandidatesTable from './components/CohortCandidatesTable';
import CohortLinksTable from './components/CohortLinksTable';

import styles from './CandidateEditorSettings.scss';

class CandidateEditorSettings extends Component {
  constructor(props) {
    super(props);
    const {
      cohortCandidates: { allIds = [], byId = {} } = {},
      cohorts: { byId: cohortsById = {} } = {},
    } = props;

    const { relationships: { cohort: { data: cohortIds = [] } = {} } = {} } = byId[allIds[0]] || {};

    const cohort = cohortsById[cohortIds[0]] || {};

    this.state = {
      employerId: null,
      employerName: '',
      grayArchive: false,
      linkCopied: false,
      requisitionName: '',
      reqId: null,
      selectedCohort: cohort || {},
      cohortCategory: ['search', 'featured'],
      cohortState: ['open', 'active'],
      showAssigned: true,
      showPrompt: null,
    };
  }

  archiveCandidate = () => {
    const {
      candidate: {
        attributes: { id },
        relationships: { user: { data: userIds = [] } = {} } = {},
      } = {},
      actions: { patchResource },
    } = this.props;

    patchResource({
      type: 'candidates',
      slice: 'toolboxCandidates',
      attributes: {
        applicationStatus: 'archived',
      },
      id,
    });

    patchResource({
      type: 'users',
      slice: 'toolboxCandidates',
      attributes: {
        accountLocked: true,
      },
      id: userIds[0],
    });

    this.setState({
      showPrompt: null,
      grayArchive: true,
    });
  };

  handleCopy = () => {
    const { confirmationLink: textToCopy } = this.state;

    this.copyText.value = textToCopy;

    this.copyText.select();

    try {
      document.execCommand('copy');
      this.copyText.blur();
    } catch (error) {
      handleError(error);
    }

    this.setState({ linkCopied: true }, () => {
      setTimeout(() => this.setState({ linkCopied: false }), 4000);
    });
  };

  handleShowPrompt = ({ reqId }) => this.setState({ showPrompt: 'confirmReq', reqId });

  handleCreateLink = ({ reqId, userId }) => {
    promisePostConfirmation({
      userId,
      reqId,
    })
      .then((response) => {
        const { data: { meta: { confirmation_link: confirmationLink } = {} } = {} } = response;

        this.setState({ confirmationLink });
      })
      .catch(handleError);
  };

  handleSendConfirmation = async () => {
    this.setState({ sendingConfirmation: true }, () => {
      setTimeout(() => this.setState({ sendingConfirmation: false }), 4000);
    });

    const {
      actions: { resourceUpdate },
      candidate: { relationships: { user: { data: userIds = [] } = {} } = {} } = {},
      users: { byId: usersById = {} } = {},
    } = this.props;

    const args = {
      type: 'auth/confirmation',
      data: {
        type: 'users',
        attributes: {
          id: userIds[0],
        },
      },
    };

    try {
      await postEntity(args);
    } catch (error) {
      handleError(error);
    }

    resourceUpdate({
      resource: {
        ...usersById[userIds[0]],
        attributes: {
          ...usersById[userIds[0]].attributes,
          confirmationSent: true,
        },
      },
      slice: 'toolboxCandidates',
      type: 'users',
      id: userIds[0],
    });
  };

  handleRequisitionNameChange = (event) => {
    const {
      target: { value },
    } = event;

    const {
      actions: { entityLoadStarted },
    } = this.props;

    const { employerId } = this.state;

    this.setState({
      requisitionName: value,
    });

    entityLoadStarted({
      type: 'requisition_openings',
      slice: 'toolboxCandidates',
      params: {
        state: 'open',
        sortBy: 'published_on_desc,created_at_desc',
        page: 1,
        employerId,
        externalName: makeLikeParam(value),
        includeSet: 'requirement_creator',
        requisitionCandidateId: 'null',
        suggestionSetId: 'null',
      },
    });
  };

  handleEmployerChange = (event) => {
    const { target: { value: { id: employerId, name: employerName } = {} } = {} } = event;

    const {
      actions: { entityLoadStarted },
    } = this.props;

    this.setState({
      employerName,
      employerId,
      requisitionName: '',
    });

    entityLoadStarted({
      type: 'requisition_openings',
      slice: 'toolboxCandidates',
      params: {
        page: 1,
        employerId,
        state: 'open',
        sortBy: 'published_on_desc,created_at_desc',
        includeSet: 'requirement_creator',
        requisitionCandidateId: 'null',
        suggestionSetId: 'null',
      },
    });
  };

  handleChangeAdmin = (event) => {
    const {
      actions: { patchResource },
      candidate: { id },
    } = this.props;

    const {
      target: { value, name },
    } = event;

    patchResource({
      type: 'candidates',
      id,
      attributes: { [name]: value },
      slice: 'toolboxCandidates',
      normalizeIt: true,
    });
  };

  handleSearchFilterChange = (event) => {
    const {
      target: { name, value },
    } = event;

    const {
      actions: { entityLoadStarted },
      candidate: { id: candidateId },
    } = this.props;

    const { [name]: arrayToUpdate } = this.state;

    const actualValue = value[value.type];

    const newArray = toggleArray({ array: arrayToUpdate, value: actualValue });

    const { cohortState, cohortCategory } = this.state;

    this.setState({
      [name]: newArray,
      selectedCohort: {},
    });

    entityLoadStarted({
      type: 'cohort_candidates',
      slice: 'toolboxCandidates',
      params: {
        page: 1,
        candidateId,
        cohortState,
        cohortCategory,
        includeSet: 'cohort',
        sortBy: 'created_at',
        [name]: newArray,
      },
    });
  };

  handlePermBlockChange = (event) => {
    const {
      actions: { postResource },
      candidate: { id: candidateId },
      user: { currentProfile: { id: ownerId } = {} } = {},
    } = this.props;

    const {
      target: { value },
    } = event;

    postResource({
      type: 'candidate_company_blocks',
      slice: 'toolboxCandidates',
      attributes: {
        candidateId,
        companyId: value.id,
        ownerId,
        ownerType: 'RainmakersAdmin',
        blockType: 'rainmakers_admin',
      },
      params: {
        includeSet: 'company_creator',
      },
      normalizeIt: true,
      requiredFields: [],
    });
  };

  handleSelectCohort = (cohort) => {
    const {
      actions: { nestedEntityLoadStarted },
      candidate: { id: candidateId },
    } = this.props;

    this.setState({ selectedCohort: cohort });

    nestedEntityLoadStarted({
      type: 'cohorts',
      nestedType: 'cohort_links',
      slice: 'toolboxCandidates',
      id: cohort.id,
      params: {
        page: 1,
        candidateId,
        includeSet: 'employer',
      },
    });
  };

  loadMoreRequisitionOpenings = () => {
    const {
      actions: { entityLoadStarted },
      requisitionOpeningsPage: page,
    } = this.props;

    const { employerId, requisitionName } = this.state;

    entityLoadStarted({
      type: 'requisition_openings',
      slice: 'toolboxCandidates',
      params: {
        state: 'open',
        sortBy: 'published_on_desc,created_at_desc',
        page: page + 1,
        employerId,
        externalName: makeLikeParam(requisitionName),
        includeSet: 'requirement_creator',
        requisitionCandidateId: 'null',
        suggestionSetId: 'null',
      },
    });
  };

  loadMoreRequisitionCandidates = () => {
    const {
      actions: { entityLoadStarted },
      requisitionCandidatesPage: page,
      match: {
        params: { id: candidateId },
      },
    } = this.props;

    entityLoadStarted({
      type: 'requisition_candidates',
      slice: 'toolboxCandidates',
      params: {
        page: page + 1,
        candidateId,
        sortBy: 'created_at_desc',
        includeSet: 'admin_toolbox',
        state: ['active'],
      },
    });
  };

  loadMoreCohortCandidates = () => {
    const {
      actions: { entityLoadStarted },
      cohortCandidatesPage: page,
      candidate: { id: candidateId },
    } = this.props;

    const { cohortState, cohortCategory } = this.state;

    entityLoadStarted({
      type: 'cohort_candidates',
      slice: 'toolboxCandidates',
      params: {
        page: page + 1,
        candidateId,
        cohortState,
        cohortCategory,
        includeSet: 'cohort',
        sortBy: 'created_at',
      },
    });
  };

  loadMoreCohortLinks = () => {
    const {
      actions: { nestedEntityLoadStarted },
      cohortLinksPage: page,
      candidate: { id: candidateId },
    } = this.props;

    const { selectedCohort: { id } = {} } = this.state;

    nestedEntityLoadStarted({
      nestedType: 'cohort_links',
      type: 'cohorts',
      id,
      slice: 'toolboxCandidates',
      params: {
        candidateId,
        includeSet: 'employer',
        page: page + 1,
      },
    });
  };

  loadMoreCandidateCompanyBlocks = () => {
    const {
      actions: { entityLoadStarted },
      candidateCompanyBlocksPage: page,
      candidate: { id: candidateId },
    } = this.props;

    entityLoadStarted({
      type: 'candidate_company_blocks',
      slice: 'toolboxCandidates',
      params: {
        page: page + 1,
        candidateId,
        sortBy: 'created_at_desc',
        includeSet: 'company_creator',
      },
    });
  };

  loadMoreCohortLinksTemporary = () => {
    const {
      actions: { entityLoadStarted },
      cohortLinksTemporaryPage: page,
      candidate: { id: candidateId } = {},
    } = this.props;

    entityLoadStarted({
      type: 'cohort_links',
      typeOverride: 'cohort_links_temporary',
      slice: 'toolboxCandidates',
      params: {
        page: page + 1,
        candidateId,
        includeSet: 'employer',
        cohortVirtual: true,
        state: 'blocked',
        sortBy: 'employer_name_asc',
      },
    });
  };

  render() {
    const {
      actions,
      candidate,
      candidate: {
        attributes: {
          active = false,
          applicationStatus,
          firstName = '',
          lastName = '',
          profileLocked = false,
          rainmakersAdminId,
          vimeoVideoEnabled,
          bullhornPushEnabled,
        } = {},
        id: candidateId,
        relationships: { user: { data: userIds = [] } = {} } = {},
      } = {},
      handleCandidateInputChange,
      handleUserInputChange,
      cohorts = {},
      cohortCandidates = {},
      cohortCandidatesTotal,
      cohortCandidatesLoading,
      cohortLinks,
      cohortLinksTotal,
      cohortLinksLoading,
      candidateCompanyBlocks,
      candidateCompanyBlocksTotal,
      candidateCompanyBlocksLoading,
      cohortLinksTemporary,
      cohortLinksTemporaryLoading,
      cohortLinksTemporaryTotal,
      companies,
      creators,
      employers,
      employees,
      modal,
      actions: { entityLoadDone, resourceUpdate, showModal },
      rainmakersAdmins,
      requisitionCandidates,
      requisitionCandidatesTotal,
      requisitionCandidatesLoading,
      requisitionOpenings,
      requisitionOpeningsTotal,
      requisitionOpeningsLoading,
      talentConcierges: {
        allIds: talentConciergesAllIds = [],
        byId: talentConciergesById = {},
      } = {},
      users: { byId: usersById = {} } = {},
    } = this.props;

    const isApproved = applicationStatus === 'approved';

    const {
      attributes: { accountLocked = false, confirmedAt, confirmationSent, doNotTrack = false } = {},
    } = usersById[userIds[0]] || {};

    const {
      cohortState,
      cohortCategory,
      confirmationLink,
      employerId,
      employerName,
      grayArchive,
      linkCopied,
      requisitionName,
      reqId,
      selectedCohort,
      selectedCohort: { attributes: { name: cohortName = '' } = {} } = {},
      showAssigned,
      showPrompt,
      sendingConfirmation,
    } = this.state;

    const titleBlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      addPadding: true,
      className: styles.titleBlock,
    };

    const settingsBlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      largeTitleFont: true,
    };

    const activeInputProps = {
      handleInputChange: (event) =>
        handleCandidateInputChange({
          ...event,
          target: {
            ...event.target,
            value: event.target.checked,
            name: event.target.name,
          },
        }),
      name: 'active',
      checked: active,
      label: active ? 'Cohort Open' : 'Cohort Blocked',
      placeholder: '',
    };

    const accountLockedInputProps = {
      handleInputChange: (event) =>
        handleUserInputChange({
          ...event,
          target: {
            ...event.target,
            value: event.target.checked,
            name: event.target.name,
          },
        }),
      name: 'accountLocked',
      checked: accountLocked,
      label: accountLocked ? 'Account Locked' : 'Account Unlocked',
      placeholder: '',
    };

    const lockedInputProps = {
      handleInputChange: (event) =>
        handleCandidateInputChange({
          ...event,
          target: {
            ...event.target,
            value: event.target.checked,
            name: event.target.name,
          },
        }),
      name: 'profileLocked',
      checked: profileLocked,
      label: profileLocked ? 'Profile Locked' : 'Profile Unlocked',
      placeholder: '',
    };

    const vimeoVideoEnabledInputProps = {
      handleInputChange: (event) =>
        handleCandidateInputChange({
          ...event,
          target: {
            ...event.target,
            value: event.target.checked,
            name: event.target.name,
          },
        }),
      name: 'vimeoVideoEnabled',
      checked: vimeoVideoEnabled,
      label: vimeoVideoEnabled ? 'Video Enabled' : 'Video Disabled',
      placeholder: '',
    };

    const doNotTrackInputProps = {
      handleInputChange: (event) =>
        handleUserInputChange({
          ...event,
          target: {
            ...event.target,
            value: !event.target.checked,
            name: event.target.name,
          },
        }),
      name: 'doNotTrack',
      checked: !doNotTrack,
      label: doNotTrack ? 'Tracking Disabled' : 'Tracking Enabled',
      placeholder: '',
    };

    const blockedSettingsProps = {
      actions,
      modal,
      candidate,
      cohortLinksTemporary,
      cohortLinksTemporaryTotal,
      cohortLinksTemporaryLoading,
      loadMoreCohortLinksTemporary: this.loadMoreCohortLinksTemporary,
      candidateCompanyBlocks,
      candidateCompanyBlocksTotal,
      candidateCompanyBlocksLoading,
      companies,
      loadMoreCandidateCompanyBlocks: this.loadMoreCandidateCompanyBlocks,
      handlePermBlockChange: this.handlePermBlockChange,
      employees,
      creators,
      rainmakersAdmins,
    };

    const assignedTabClasses = classnames(styles.mainTab, {
      [styles.mainTabActive]: showAssigned,
    });

    const blockedTabClasses = classnames(styles.mainTab, {
      [styles.mainTabActive]: !showAssigned,
    });

    const assignedTabProps = {
      onClick: () => this.setState({ showAssigned: true }),
      className: assignedTabClasses,
    };

    const blockedTabProps = {
      onClick: () => this.setState({ showAssigned: false }),
      className: blockedTabClasses,
    };

    const mainTabsTitleContent = (
      <div className={styles.tabContainer}>
        <div {...assignedTabProps}>Assigned</div>
        <div {...blockedTabProps}>Blocked</div>
      </div>
    );

    const contentBlockProps = {
      boxShadow: true,
      addChildFlex: true,
      noTitlePadding: true,
      className: styles.firstBlock,
      title: mainTabsTitleContent,
    };

    const requisitionsBlockProps = {
      boxShadow: true,
      addChildFlex: true,
      noTitlePadding: true,
      className: styles.firstBlock,
      title: <div className={styles.blockTitle}>Requisition Confirmation Links</div>,
    };

    const candReqSettingsProps = {
      modal,
      showModal,
      employers,
      requisitionCandidates,
      requisitionCandidatesTotal,
      requisitionCandidatesLoading,
      loadMoreRequisitionCandidates: this.loadMoreRequisitionCandidates,
    };

    const cohortLinkTitleString = cohortName
      ? `Employer Assignment for ${cohortName} Cohort`
      : 'Select a cohort to show employers';

    const cohortLinksTitle = <div className={styles.cohortBlockTitle}>{cohortLinkTitleString}</div>;

    const cohortsTableProps = {
      actions,
      cohorts,
      cohortCategory,
      cohortCandidates,
      cohortCandidatesLoading,
      cohortCandidatesTotal,
      cohortState,
      candidateId,
      handleSearchFilterChange: this.handleSearchFilterChange,
      handleSelectCohort: this.handleSelectCohort,
      loadMoreCohortEmployers: this.loadMoreCohortEmployers,
      selectedCohort,
    };

    const cohortLinksTableProps = {
      employers,
      cohortLinks: cohortName ? cohortLinks : {},
      cohortLinksLoading,
      cohortLinksTotal,
      loadMoreCohortLinks: this.loadMoreCohortLinks,
      titleContent: cohortLinksTitle,
    };

    const activeContent = showAssigned ? (
      <React.Fragment>
        <CohortCandidatesTable {...cohortsTableProps} />
        <CohortLinksTable {...cohortLinksTableProps} />
      </React.Fragment>
    ) : (
      <div>
        <BlockedSettings {...blockedSettingsProps} />
        <CandReqBlocksSettings {...candReqSettingsProps} />
      </div>
    );

    const archiveButtonProps = {
      warning: true,
      onClick: () => this.setState({ showPrompt: 'archive' }),
      disabled: grayArchive,
    };

    const sendConfirmationClasses = classnames(styles.sendConfirmation, {
      [styles.sendConfirmationSent]: sendingConfirmation,
    });

    const sendConfirmationButtonProps = {
      primary: true,
      onClick: confirmationSent
        ? () => this.setState({ showPrompt: 'confirmation' })
        : this.handleSendConfirmation,
      className: sendConfirmationClasses,
    };

    const actionText = confirmationSent ? 'Resend Confirmation Email' : 'Send Confirmation Email';

    const sendConfirmationText = sendingConfirmation ? 'Sent!' : actionText;

    const sendConfirmationButton =
      confirmedAt === null ? (
        <Button {...sendConfirmationButtonProps}>{sendConfirmationText}</Button>
      ) : null;

    const closeButtonProps = {
      quaternary: true,
      onClick: () => this.setState({ showPrompt: null }),
    };

    const closeButton = <Button {...closeButtonProps}>Cancel</Button>;

    const confirmButtonProps = {
      onClick: () => {
        if (showPrompt === 'confirmation') {
          this.handleSendConfirmation();
        }

        if (showPrompt === 'confirmReq') {
          this.handleCreateLink({
            reqId,
            userId: userIds[0],
          });
        }

        this.setState({ showPrompt: null, reqId: null });
      },
      primary: true,
    };

    const confirmButton = <Button {...confirmButtonProps}>Got it</Button>;

    const confirmPromptContent =
      showPrompt === 'confirmation' || showPrompt === 'confirmReq' ? (
        <DialogueModal>
          <div className={styles.promptModal}>
            <div className={styles.promptContent}>
              Creating a new confirmation link will disable the old one.
            </div>
            <div className={styles.promptButtons}>
              {closeButton}
              {confirmButton}
            </div>
          </div>
        </DialogueModal>
      ) : null;

    const archiveConfirmModalProps = {
      handleCancel: () => this.setState({ showPrompt: null }),
      handleConfirm: this.archiveCandidate,
      confirmText: 'Confirm',
      cancelText: 'Cancel',
      text: `Are you sure you want to archive ${firstName} ${lastName}?`,
      title: 'Confirm Archive',
    };

    const archivePromptContent =
      showPrompt === 'archive' ? <ConfirmModal {...archiveConfirmModalProps} /> : null;

    const { attributes, relationships: { user: { data: adminUserIds = [] } = {} } = {} } =
      talentConciergesById[rainmakersAdminId] || {};

    const { attributes: user = {} } = usersById[adminUserIds[0]] || {};

    const adminSource = talentConciergesAllIds.map((id) => {
      const { attributes: { firstName, lastName } = {} } = talentConciergesById[id] || {};

      return {
        label: `${firstName} ${lastName}`,
        value: id,
      };
    });

    const adminInputProps = {
      source: adminSource,
      name: 'rainmakersAdminId',
      handleInputChange: this.handleChangeAdmin,
      value: rainmakersAdminId || '',
      defaultLabel: 'Update Admin',
      defaultIcon: 'caret-down',
      noMargin: true,
      setWidth: '160px',
      bigInput: true,
    };

    const talentConciergeProps = {
      admin: {
        ...attributes,
        user,
      },
      adminInput: <InputSelect {...adminInputProps} />,
    };

    const setEmployerInputProps = {
      placeholder: 'Search for an employer',
      size: 'full',
      name: 'employer_id',
      value: employerName || '',
      handleInputChange: this.handleEmployerChange,
      autocompleteType: 'employers',
      field: 'name',
      needReset: true,
    };

    const searchRequisitionsInputProps = {
      placeholder: 'Search for a requisition',
      handleInputChange: this.handleRequisitionNameChange,
      type: 'text',
      size: 'full',
      value: requisitionName || '',
    };

    const input = employerId ? (
      <Input {...searchRequisitionsInputProps} />
    ) : (
      <AutocompleteServer {...setEmployerInputProps} />
    );

    const clearEmployerButtonProps = {
      quaternary: true,
      onClick: () => {
        entityLoadDone({
          data: {
            entities: {},
            meta: {},
          },
          slice: 'toolboxCandidates',
          type: 'requisition_openings',
        });
        this.setState({
          employerName: '',
          employerId: null,
          requisitionName: '',
        });
      },
    };

    const clearEmployerButton = employerId ? (
      <Button {...clearEmployerButtonProps}>Clear</Button>
    ) : (
      ''
    );

    const filtersContent = (
      <div className={styles.requisitionHeader}>
        {input}
        <div className={styles.reqHeaderText}>
          <span className={styles.reqHeaderBold}>
            {employerName ? `${employerName} requisitions` : ''}
          </span>
          {clearEmployerButton}
        </div>
      </div>
    );

    const confirmationLinksProps = {
      confirmationSent,
      handleShowPrompt: this.handleShowPrompt,
      handleCreateLink: this.handleCreateLink,
      total: requisitionOpeningsTotal,
      requisitionOpenings,
      loadMoreReqs: this.loadMoreReqs,
      isLoading: requisitionOpeningsLoading,
      titleContent: filtersContent,
      userId: userIds[0],
    };

    const closeConfirmationButtonProps = {
      quaternary: true,
      onClick: () => {
        resourceUpdate({
          type: 'users',
          slice: 'toolboxCandidates',
          id: userIds[0],
          resource: {
            ...usersById[userIds[0]],
            attributes: {
              ...usersById[userIds[0]].attributes,
              confirmationSent: true,
            },
          },
        });
        this.setState({ confirmationLink: '' });
      },
    };

    const closeConfirmationButton = <Button {...closeConfirmationButtonProps}>Close</Button>;

    const linkCopiedClasses = classnames(styles.linkCopiedText, {
      [styles.linkCopiedActive]: linkCopied,
    });

    const linkCopiedText = linkCopied ? (
      <div className={styles.linkCopied}>
        <FontIcon iconName="checkmark" className={styles.copiedLinkIcon} />
        Link copied!
      </div>
    ) : (
      <div className={styles.linkNotCopied}>
        <FontIcon iconName="share" className={styles.copyLinkIcon} />
        Copy link
      </div>
    );

    const linkCopyButtonProps = {
      primary: true,
      active: linkCopied,
      className: linkCopiedClasses,
      onClick: this.handleCopy,
    };

    const confirmationLinkContent = confirmationLink ? (
      <DialogueModal>
        <div className={styles.promptModal}>
          <div className={styles.confirmationContent}>
            <div className={styles.promptTitle}>
              <Bolder>Confirmation Link</Bolder>
            </div>
            <div>{confirmationLink}</div>
          </div>
          <div className={styles.promptButtons}>
            <Button {...linkCopyButtonProps}>{linkCopiedText}</Button>
            {closeConfirmationButton}
          </div>
        </div>
        <input
          ref={(ref) => {
            this.copyText = ref;
          }}
          value=""
          className={styles.hidden}
        />
      </DialogueModal>
    ) : null;

    const confirmationLinksTable =
      confirmedAt === null ? (
        <Block {...requisitionsBlockProps}>
          <ConfirmationLinks {...confirmationLinksProps} />
        </Block>
      ) : null;

    const archiveButtonContent =
      isApproved || grayArchive ? <Button {...archiveButtonProps}>Archive</Button> : null;

    const content = (
      <div className={styles.EditorSettings}>
        <Block {...titleBlockProps}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>Settings</div>
            <div className={styles.saveContent}>
              {sendConfirmationButton}
              {archiveButtonContent}
            </div>
          </div>
        </Block>

        <div className={styles.scrollDiv}>
          <Block {...settingsBlockProps}>
            <div className={styles.topContainer}>
              <div className={styles.activeInputContainer}>
                <InputCheckboxSlider {...accountLockedInputProps} />
                <InputCheckboxSlider {...activeInputProps} />
                <InputCheckboxSlider {...lockedInputProps} />
                <InputCheckboxSlider {...doNotTrackInputProps} />
                <InputCheckboxSlider {...vimeoVideoEnabledInputProps} />
                <InputCheckboxSlider
                  handleInputChange={(event) =>
                    handleCandidateInputChange({
                      ...event,
                      target: {
                        ...event.target,
                        value: event.target.checked,
                        name: event.target.name,
                      },
                    })
                  }
                  name="bullhornPushEnabled"
                  checked={bullhornPushEnabled}
                  label={bullhornPushEnabled ? 'Bullhorn Push Enabled' : 'Bullhorn Push Disabled'}
                  placeholder=""
                />
              </div>
              <TalentConcierge {...talentConciergeProps} />
            </div>
          </Block>

          {confirmationLinksTable}

          <Block {...contentBlockProps}>{activeContent}</Block>
        </div>
        {archivePromptContent}
        {confirmPromptContent}
        {confirmationLinkContent}
      </div>
    );

    const layoutWithoutSidebarProps = {
      content,
    };

    return <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />;
  }
}

export default CandidateEditorSettings;
