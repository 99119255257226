import React from 'react';
import ImageUploader from 'components/ImageUploader';

import EmptyCompanyLogo from 'shared/assets/missing-company-picture.png';

const EmployerImageUploader = ({ employerId, employerImageUploadStarted, pictureUrl }) => {
  return (
    <ImageUploader
      name="Employer"
      entityId={employerId}
      imageUploadStarted={employerImageUploadStarted}
      pictureUrl={pictureUrl}
      emptyLogo={EmptyCompanyLogo}
      rounded={false}
    />
  );
};

export default EmployerImageUploader;
