import React from 'react';

import styles from './WhyJoin.scss';

const WhyJoin = () => {
  return (
    <div id="Why-join" className={styles.WhyJoin}>
      <div className={styles.Container}>
        <h3 className={styles.Title}>Why Join?</h3>

        <div className={styles.Column1}>
          <h3 className={styles.SecondaryTitle}>Top sales talent</h3>
          <div className={styles.Description}>
            {
              "Rainmakers accepts only quality applicants, so you can be confident that you're interviewing the top sales talent around."
            }
          </div>
        </div>

        <div className={styles.Column2}>
          <img
            src={require('../../assets/img_prescreened.png')}
            alt="Top sales talent"
            className={styles.Image}
          />
        </div>
      </div>
    </div>
  );
};

export default WhyJoin;
