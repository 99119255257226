import React from 'react';

import EmployerData from 'components/EmployerData';
import Table from 'components/Table';
import TableRow from 'components/Table/components/TableRow';
import TableCell from 'components/Table/components/TableCell';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import InputCheckbox from 'components/InputCheckbox';

import styles from './CohortLinksTable.scss';

const CohortLinksTable = ({
  employers: { byId: employersById = {} } = {},
  cohortLinks: { allIds = [], byId = {} } = {},
  cohortLinksLoading,
  cohortLinksTotal,
  loadMoreCohortLinks,
  titleContent,
}) => {
  const tableHeader = [
    {
      content: 'Company',
      headerClassName: styles.companyHeader,
    },
    {
      content: 'Enabled',
      headerClassName: styles.checkboxHeader,
    },
    {
      content: 'Disabled',
      headerClassName: styles.checkboxHeader,
    },
  ];

  const makeEmployerRows = (id) => {
    const {
      attributes: { state } = {},
      relationships: { employer: { data: employerIds = [] } = {} } = {},
    } = byId[id] || {};

    const { attributes: employer = {} } = employersById[employerIds[0]] || {};

    const employerDataProps = {
      employer,
    };

    const employerContent = (
      <div className={styles.employerContent}>
        <EmployerData {...employerDataProps} />
      </div>
    );

    const enableCheckboxProps = {
      name: 'toggle_enable',
      checked: state === 'enabled',
      disabled: true,
    };

    const disableCheckboxProps = {
      name: 'toggle_disable',
      checked: state === 'blocked',
      disabled: true,
    };

    const row = (
      <TableRow id={id} key={id} className={styles.employerRow}>
        <TableCell key={1}>{employerContent}</TableCell>
        <TableCell flex key={2}>
          <div className={styles.employerCheckbox}>
            <InputCheckbox {...enableCheckboxProps} />
          </div>
        </TableCell>
        <TableCell flex key={3}>
          <div className={styles.employerCheckbox}>
            <InputCheckbox {...disableCheckboxProps} />
          </div>
        </TableCell>
      </TableRow>
    );

    return row;
  };

  const tableContent = allIds.map(makeEmployerRows);

  const infiniteScrollProps = {
    pageStart: 0,
    loadMore: loadMoreCohortLinks,
    hasMore: !cohortLinksLoading && cohortLinksTotal > allIds.length,
    loader: <InfiniteScrollLoader key="infiniteScrollLoader" />,
    useWindow: false,
  };

  const tableProps = {
    noBlockFlex: true,
    titleContent,
    header: tableHeader,
    infiniteScrollProps,
    tableContent,
    tableName: 'employers',
    isEmpty: !cohortLinksLoading && allIds.length === 0,
    isLoading: cohortLinksLoading,
    emptyTextString: 'No Employers',
  };

  return <Table {...tableProps} />;
};

export default CohortLinksTable;
