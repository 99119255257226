import React from 'react';

import Button from 'components/Button';
import CandidateData from 'components/CandidateData';
import FormattedDate from 'components/FormattedDate';
import FormattedMoney from 'components/FormattedMoney';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import Table from 'components/Table';
import TableCell from 'components/Table/components/TableCell';
import TableRow from 'components/Table/components/TableRow';

import ActionDropdown from '../ActionDropdown';
import FeedbackActionDropdown from '../FeedbackActionDropdown';
import SearchBar from '../SearchBar';

import styles from './CandidatesTable.scss';

const STATE_MAP = {
  hired: 'Hired',
  employer_passed: 'Employer Passed',
  candidate_passed: 'Candidate Passed',
  passed: 'Passed',
};

const CandidatesTable = ({
  actions,
  actions: { showModal },
  candidates: { byId: candidatesById = {} },
  candidateCompanyBlocksLoading,
  candidateCompanyBlocksTotal,
  candidateCompanyBlocks: {
    allIds: candidateCompanyBlocksAllIds = [],
    byId: candidateCompanyBlocksById = {},
  } = {},
  candidateFullName,
  isLoading,
  employees,
  rainmakersAdmins,
  requisitionOpeningId,
  state,
  total,
  user,
  user: { currentProfile: { employer: { name: employerName } = {} } = {} } = {},
  handleSearchInputChange,
  handleSearchFilterChange,
  handleSetState,
  loadMore,
  loadMoreRequisitionOpenings,
  privateReqCanFeedbacks: { byId: privateReqCanFeedbacksById = {} } = {},
  requisitionCandidates: { byId = {}, allIds = [] } = {},
  requisitionOpenings = {},
  requisitionOpenings: { byId: requisitionOpeningsById = {} } = {},
  requisitionOpeningsTotal,
  requisitionOpeningsLoading,
}) => {
  const isStatusUnscheduled = state === 'active';
  const isStatusArchived = state === 'archived';
  const isStatusHired = state === 'hired';
  const isStatusPassed = state === 'passed';

  const tableHeader = (currentStatus) => {
    switch (currentStatus) {
      case 'archived':
        return [
          {
            content: 'Name',
            headerClassName: styles.nameHeader,
          },
          {
            content: 'Archived On',
            headerClassName: styles.hiredHeader,
          },
          {
            content: 'From',
            headerClassName: styles.typeHeader,
          },
          {
            content: '',
            headerClassName: styles.candActionHeader,
          },
        ];
      case 'hired':
        return [
          {
            content: 'Name',
            headerClassName: styles.nameHeader,
          },
          {
            content: 'OTE Range',
            headerClassName: styles.statusHeader,
          },
          {
            content: 'Job Title',
            headerClassName: styles.statusHeader,
          },
          {
            content: 'Date Hired',
            headerClassName: styles.hiredHeader,
          },
          {
            content: 'Who Hired',
            headerClassName: styles.hiredEndHeader,
          },
        ];
      case 'passed':
        return [
          {
            content: 'Name',
            headerClassName: styles.nameHeader,
          },
          {
            content: 'Status',
            headerClassName: styles.statusHeader,
          },
          {
            content: 'OTE Range',
            headerClassName: styles.statusHeader,
          },
          {
            content: 'Job Title',
            headerClassName: styles.statusHeader,
          },
          {
            content: '',
            headerClassName: styles.candActionHeader,
          },
        ];
      default:
        return [
          {
            content: 'Name',
            headerClassName: styles.nameHeader,
          },
          {
            content: 'Status',
            headerClassName: styles.statusHeader,
          },
          {
            content: 'Job Title',
            headerClassName: styles.statusHeader,
          },
          {
            content: 'OTE Range',
            headerClassName: styles.statusHeader,
          },
          {
            content: 'Time Active',
            headerClassName: styles.centered,
          },
          {
            content: '',
            headerClassName: styles.candActionHeader,
          },
        ];
    }
  };

  const openProfileModal = (candidateId) =>
    showModal({
      key: 'CandidateProfileModal',
      parent: 'DashboardPage',
      route: `/candidates/${candidateId}/profile`,
      search: `?tp=d&tableStatus=${state}`,
    });

  const makeUnscheduledCells = (id) => {
    const {
      [id]: {
        attributes: {
          companyFeedback,
          createdAt,
          offerFeedback,
          reason,
          roleFeedback,
          passedOn,
          state: requisitionCandidateState,
        } = {},
        relationships: {
          candidate: { data: candidateIds = [] } = {},
          requisitionOpeningCopy: { data: requisitionOpeningIds = [] } = {},
        } = {},
      } = {},
    } = byId;

    const {
      [requisitionOpeningIds[0]]: {
        attributes: { oteMinCents, oteMaxCents, externalName, role } = {},
      } = {},
    } = requisitionOpeningsById;

    const { [candidateIds[0]]: { attributes: { firstName, lastName } = {} } = {} } = candidatesById;

    const firstContactContent = (
      <div className={styles.tableSmallTextCenteredTimeActive}>
        <FormattedDate date={createdAt} timeAgoFormat="relativeDays" timeAgo parse />
      </div>
    );

    const statusString = STATE_MAP[requisitionCandidateState] || 'Interview Requested';

    const statusContent = <div className={styles.statusContent}>{statusString}</div>;

    const jobTitleContent = <div className={styles.statusContent}>{externalName}</div>;

    const oteContent = oteMinCents ? (
      <div className={styles.typeContent}>
        <FormattedMoney amount={oteMinCents} /> <span className={styles.span}>-</span>{' '}
        <FormattedMoney amount={oteMaxCents} />
      </div>
    ) : (
      <div className={styles.statusContent} />
    );

    const candidateDataProps = {
      onClick: () => openProfileModal(candidateIds[0], id),
      candidate: candidatesById[candidateIds[0]],
      subtitle: role,
    };

    const candidateDataContent = (
      <div className={styles.candidateContent}>
        <CandidateData {...candidateDataProps} />
      </div>
    );

    const privateReqCanFeedbackId = Object.keys(privateReqCanFeedbacksById).find(
      (el) => privateReqCanFeedbacksById[el].attributes.requisitionCandidateId === id
    );

    const { [privateReqCanFeedbackId]: privateReqCanFeedback } = privateReqCanFeedbacksById;

    const dropdownProps = {
      actions,
      id,
      privateReqCanFeedback,
      firstName,
      lastName,
    };

    const feedbackActionDropdownProps = {
      companyFeedback,
      employerName,
      externalName,
      offerFeedback,
      passedOn,
      privateReqCanFeedback,
      roleFeedback,
      reason,
      state: requisitionCandidateState,
      firstName,
      lastName,
    };

    const feedbackContent =
      (requisitionCandidateState === 'candidate_passed' &&
        (companyFeedback.length > 0 || offerFeedback.length > 0 || roleFeedback.length > 0)) ||
      (requisitionCandidateState === 'employer_passed' && privateReqCanFeedback) ? (
        <div className={styles.actionsContent}>
          <FeedbackActionDropdown {...feedbackActionDropdownProps} />
        </div>
      ) : null;

    const actionsContent =
      requisitionCandidateState === 'active' ? (
        <div className={styles.actionsContent}>
          <ActionDropdown {...dropdownProps} />
        </div>
      ) : (
        <div className={styles.actionsContent} />
      );

    const actionsCell = feedbackContent || actionsContent;

    const cells = [
      <TableCell key={1}>{candidateDataContent}</TableCell>,
      <TableCell key={2}>{statusContent}</TableCell>,
      <TableCell key={3}>{jobTitleContent}</TableCell>,
      <TableCell key={4}>{oteContent}</TableCell>,
      <TableCell key={5}>{firstContactContent}</TableCell>,
      <TableCell noFlex key={6}>
        {actionsCell}
      </TableCell>,
    ];

    return cells;
  };

  const makeDecidedCells = (id) => {
    const {
      [id]: {
        attributes: {
          companyFeedback,
          decidedByName,
          offerFeedback,
          offerSignedDate,
          passedOn,
          reason,
          roleFeedback,
          state: requisitionCandidateState,
        } = {},
        relationships: {
          candidate: { data: candidateIds = [] } = {},
          requisitionOpeningCopy: { data: requisitionOpeningIds = [] } = {},
        } = {},
      } = {},
    } = byId;

    const privateReqCanFeedbackId = Object.keys(privateReqCanFeedbacksById).find(
      (el) => privateReqCanFeedbacksById[el].attributes.requisitionCandidateId === id
    );

    const { [privateReqCanFeedbackId]: privateReqCanFeedback } = privateReqCanFeedbacksById;

    const { [candidateIds[0]]: { attributes: { firstName, lastName } = {} } = {} } = candidatesById;

    const {
      [requisitionOpeningIds[0]]: {
        attributes: { oteMinCents, oteMaxCents, externalName, role } = {},
      } = {},
    } = requisitionOpeningsById;

    const jobTitleContent = <div className={styles.statusContent}>{externalName}</div>;

    const oteContent = oteMinCents ? (
      <div className={styles.statusContent}>
        <FormattedMoney amount={oteMinCents} /> - <FormattedMoney amount={oteMaxCents} />
      </div>
    ) : (
      <div className={styles.statusContent} />
    );

    const dateDecidedContent = (
      <div className={styles.tableSmallTextCenteredDate}>
        <FormattedDate date={passedOn || offerSignedDate} parse />
      </div>
    );

    const candidateDataProps = {
      onClick: () => openProfileModal(candidateIds[0], id),
      candidate: candidatesById[candidateIds[0]],
      subtitle: role,
    };

    const candidateDataContent = (
      <div className={styles.candidateContent}>
        <CandidateData {...candidateDataProps} />
      </div>
    );

    const whoDecidedContent = (
      <div className={styles.tableSmallTextCenteredEnd}>{decidedByName}</div>
    );

    const statusString = STATE_MAP[requisitionCandidateState] || '';

    const passedDate = passedOn ? (
      <div className={styles.tableSmallText}>
        <FormattedDate date={passedOn || offerSignedDate} parse />
      </div>
    ) : null;

    const statusContent = (
      <div className={passedDate ? styles.statusPassedContent : styles.statusContent}>
        <div className={styles.tableText}>{statusString}</div>
        {passedDate}
      </div>
    );

    const feedbackActionDropdownProps = {
      companyFeedback,
      employerName,
      externalName,
      offerFeedback,
      passedOn,
      privateReqCanFeedback,
      roleFeedback,
      reason,
      state: requisitionCandidateState,
      firstName,
      lastName,
    };

    const feedbackContent =
      (requisitionCandidateState === 'candidate_passed' &&
        (companyFeedback.length > 0 || offerFeedback.length > 0 || roleFeedback.length > 0)) ||
      (requisitionCandidateState === 'employer_passed' && privateReqCanFeedback) ? (
        <div className={styles.actionsContent}>
          <FeedbackActionDropdown {...feedbackActionDropdownProps} />
        </div>
      ) : (
        <div className={styles.actionsContent} />
      );

    return isStatusHired
      ? [
          <TableCell key={1}>{candidateDataContent}</TableCell>,
          <TableCell key={2}>{oteContent}</TableCell>,
          <TableCell key={3}>{jobTitleContent}</TableCell>,
          <TableCell key={4}>{dateDecidedContent}</TableCell>,
          <TableCell key={5}>{whoDecidedContent}</TableCell>,
        ]
      : [
          <TableCell key={1}>{candidateDataContent}</TableCell>,
          <TableCell key={2}>{statusContent}</TableCell>,
          <TableCell key={3}>{oteContent}</TableCell>,
          <TableCell key={4}>{jobTitleContent}</TableCell>,
          <TableCell key={6}>{feedbackContent}</TableCell>,
        ];
  };

  const makeArchivedCells = (id) => {
    const {
      [id]: {
        attributes: { createdAt, blockType } = {},
        relationships: { candidate: { data: candidateIds = [] } } = {},
      } = {},
    } = candidateCompanyBlocksById || {};

    const { [candidateIds[0]]: { attributes: { firstName } = {} } = {} } = candidatesById;

    const candidateDataProps = {
      onClick: () => openProfileModal(candidateIds[0]),
      candidate: candidatesById[candidateIds[0]],
    };

    const candidateDataContent = (
      <div className={styles.candidateContent}>
        <CandidateData {...candidateDataProps} />
      </div>
    );

    const typeString = blockType === 'search_pass' ? 'Featured' : 'Matches';

    const typeContent = <div className={styles.typeContent}>{typeString}</div>;

    const createdAtContent = (
      <div className={styles.tableSmallTextCenteredDate}>
        <FormattedDate date={createdAt} parse />
      </div>
    );

    const unarchiveProps = {
      tertiaryThin: true,
      onClick: () =>
        handleSetState({
          unarchiveFirstName: firstName,
          unarchiveType: typeString,
          unarchiveBlockId: id,
        }),
    };

    const unarchiveContent = (
      <div className={styles.trashContent}>
        <Button {...unarchiveProps}>Unarchive</Button>
      </div>
    );

    const cells = [
      <TableCell key={1}>{candidateDataContent}</TableCell>,
      <TableCell key={2}>{createdAtContent}</TableCell>,
      <TableCell key={3}>{typeContent}</TableCell>,
      <TableCell noFlex key={4}>
        {unarchiveContent}
      </TableCell>,
    ];

    return cells;
  };

  const makeRows = (id) => {
    const rowType =
      isStatusHired || isStatusPassed || isStatusArchived ? 'decidedRow' : 'dashboardRow';

    const rowProps = {
      rowType,
      key: id,
      id,
    };

    const decidedTableFunction = isStatusHired || isStatusPassed ? makeDecidedCells : null;
    const unscheduledTableFunction = isStatusUnscheduled ? makeUnscheduledCells : null;
    const archivedTableFunction = isStatusArchived ? makeArchivedCells : null;
    const makeCellsFunction =
      decidedTableFunction || unscheduledTableFunction || archivedTableFunction;

    return <TableRow {...rowProps}>{makeCellsFunction(id)}</TableRow>;
  };

  const tableContent = isStatusArchived
    ? candidateCompanyBlocksAllIds.map(makeRows)
    : allIds.map(makeRows);

  const searchBarProps = {
    state,
    candidateFullName,
    employees,
    handleSearchInputChange,
    handleSearchFilterChange,
    loadMoreRequisitionOpenings,
    rainmakersAdmins,
    requisitionOpeningId,
    requisitionOpenings,
    requisitionOpeningsTotal,
    requisitionOpeningsLoading,
    user,
  };

  const titleContent = (
    <div className={styles.candidateHeader}>
      <SearchBar {...searchBarProps} />
    </div>
  );

  const infiniteScrollProps = {
    loadMore,
    hasMore:
      state === 'archived'
        ? candidateCompanyBlocksTotal > candidateCompanyBlocksAllIds.length
        : total > allIds.length,
    loader:
      isLoading || candidateCompanyBlocksLoading ? (
        <div key="infiniteScrollLoader" />
      ) : (
        <InfiniteScrollLoader key="infiniteScrollLoader" />
      ),
    useWindow: false,
    threshold: 300,
  };

  const findTalentRoute = process.env.APPROVED_EMPLOYER_DEFAULT_LINK || '/featured';

  const emptyActionButtonProps = {
    primary: true,
    to: findTalentRoute,
  };

  const emptyActionButton = <Button {...emptyActionButtonProps}>Browse Candidates</Button>;

  const tableProps = {
    titleContent,
    tableContent,
    status: state,
    header: tableHeader(state),
    infiniteScrollProps,
    tableName: isStatusUnscheduled ? 'candidateDashboard' : 'decidedDashboard',
    isEmpty:
      state === 'archived'
        ? candidateCompanyBlocksLoading === false && candidateCompanyBlocksAllIds === 0
        : isLoading === false && allIds.length === 0,
    emptyTextString: 'No Candidates',
    emptyActionButton,
    isLoading: isLoading || candidateCompanyBlocksLoading,
  };

  return <Table {...tableProps} />;
};

export default CandidatesTable;
