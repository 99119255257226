import React, { useState, useRef, useEffect, useCallback } from 'react';
import classnames from 'classnames';

import { useOnClickOutside } from 'hooks';

import EmptyCompanyLogo from 'shared/assets/missing-company-picture.png';
import EmptyEducationLogo from 'shared/assets/missing-education-organization-picture.png';
import EmptyAvatar from 'shared/assets/missing-user-picture.png';

import { handleError } from 'utils/common';

import Input from 'components/Input';
import Button from 'components/Button';

import { promiseAutocompleteOptions } from './promises';

import styles from './AutocompleteServer.scss';

const AutocompleteServer = ({
  value,
  autocompleteType,
  handleGetValue,
  needReset,
  handleInputChange,
  name,
  characterLimit,
  hasAddButton,
  className,
  disabled,
  errors = {},
  isSearch,
  label,
  placeholder,
  required,
  size,
  styling,
  type,
  wide,
  autoFocus,
  highlight,
  handleOnBlur,
  field,
  ...rest
}) => {
  const [active, setActive] = useState(false);
  const [search, setSearch] = useState(value);
  const [origValue] = useState(value);
  const [options, setOptions] = useState([]);
  const [selectedItemIdx, setSelectedItemIdx] = useState(undefined);
  const [loadError, setLoadError] = useState();
  const [lastEventKey, setLastEventKey] = useState();
  const ref = useRef();

  useEffect(() => {
    if (value !== search && value !== origValue && !needReset) {
      setSearch(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onOptionSuccess = (options) => {
    setOptions(options);
  };

  const onOptionFail = (error) => {
    handleError(error);
    setLoadError(error);
  };

  const onSelect = (option) => {
    const isCandidate = autocompleteType === 'candidates';
    const nameString = isCandidate ? option.fullName : option.name;
    if (active) {
      const event = {
        target: {
          name,
          value: {
            ...option,
            name: nameString,
          },
          type: 'select',
        },
      };

      handleInputChange(event);

      handleGetValue && handleGetValue(nameString);

      setSearch(needReset ? '' : nameString);
      setActive(false);
      setSelectedItemIdx(undefined);
    }
  };

  const handleKeyDown = (event) => {
    const selectedItemIdxInternal = selectedItemIdx ?? -1;

    const isCandidate = autocompleteType === 'candidates';

    setLastEventKey(event.key === ',');

    if (
      event.key === ' ' &&
      lastEventKey &&
      autocompleteType !== 'companies' &&
      autocompleteType !== 'employers' &&
      needReset
    ) {
      handleClickOutside({ stripCommas: true });
    }

    if (event.key === 'ArrowDown') {
      const newIdx =
        selectedItemIdxInternal + 1 > options.length - 1
          ? options.length - 1
          : selectedItemIdxInternal + 1;

      const option = options[newIdx];

      if (option) {
        setSelectedItemIdx(newIdx);
        return setSearch(isCandidate ? option.fullName : option.name);
      }

      return null;
    }

    if (event.key === 'ArrowUp') {
      const newIdx = selectedItemIdxInternal - 1 < 0 ? 0 : selectedItemIdxInternal - 1;
      const option = options[newIdx];
      if (option) {
        setSelectedItemIdx(newIdx);
        setSearch(isCandidate ? option.fullName : option.name);
      }
      return null;
    }

    if (event.key === 'Enter') {
      if (selectedItemIdxInternal >= 0) {
        const option = options[selectedItemIdxInternal];
        if (option) {
          return onSelect(option);
        }
      }
      if (event.target.value[event.target.value.length - 1] === ',') {
        const newValue = event.target.value.substring(0, event.target.value.length - 1);
        handleOnBlur &&
          handleOnBlur({
            ...event,
            target: {
              ...event.target,
              name: event.target.name,
              value: newValue,
            },
          });
      } else {
        handleOnBlur && handleOnBlur(event);
      }
      setActive(false);
      if (needReset) {
        setSearch('');
      }
    }
  };

  const handleTextInputChange = (event) => {
    const {
      target: { value },
    } = event;

    if (!disabled) {
      if (handleGetValue) handleGetValue(value);
      setSearch(value);
      setActive(value !== '');
    }

    promiseAutocompleteOptions({
      query: value,
      page: 1,
      field,
      autocompleteType,
    })
      .then(onOptionSuccess)
      .catch(onOptionFail);
  };

  const handleClickOutside = useCallback(
    ({ stripCommas = false } = {}) => {
      const searchTerm = search;

      const searchExt = stripCommas ? searchTerm.replace(/,/g, '') : searchTerm;

      if (active) {
        promiseAutocompleteOptions({
          query: searchExt,
          page: 1,
          field,
          autocompleteType,
        })
          .then((options) => {
            if (
              options[0] &&
              ((options[0].name &&
                options[0].name.toLowerCase() === searchExt.trim().toLowerCase()) ||
                (options[0].fullName &&
                  options[0].fullName.toLowerCase() === searchExt.trim().toLowerCase()))
            ) {
              const option = options[0];
              const event = {
                target: {
                  name,
                  value: {
                    id: option.id,
                    name: option.name,
                    picture: option.picture,
                  },
                  type: 'select',
                },
              };

              handleInputChange(event);
              setSelectedItemIdx(undefined);
            } else {
              handleOnBlur &&
                handleOnBlur({
                  target: {
                    name,
                    value: searchExt.trim(),
                  },
                });
            }

            setActive(false);

            if (needReset) {
              setSearch('');
            }
          })
          .catch((error) => {
            handleError(error);

            handleOnBlur &&
              handleOnBlur({
                target: {
                  name,
                  value: searchExt.trim(),
                },
              });
            if (needReset) {
              setSearch('');
              setActive(false);
              setSelectedItemIdx(undefined);
            }
          });
      }
    },
    [active, autocompleteType, field, handleInputChange, handleOnBlur, name, needReset, search]
  );

  useOnClickOutside(ref, handleClickOutside);

  const selectClasses = classnames({
    [styles.relative]: true,
    [styles.wide]: wide,
    [styles.full]: size === 'full',
  });

  const inputClasses = classnames(className, {
    [styles.active]: active,
  });

  const optionsContainerClasses = classnames(styles.OptionsContainer, {
    [styles.optionsContainerFull]: styling === 'cohortCandidate',
  });

  const makeOptions = (option, idx) => {
    const isCandidate = autocompleteType === 'candidates';

    const optionClasses = classnames(styles.Option, {
      [styles.optionActive]: idx === selectedItemIdx,
    });

    const onClickHandler = () => {
      onSelect(option);
      if (needReset) {
        setSearch('');
        return setActive(false);
      }
      return setSearch(isCandidate ? option.fullName : option.name);
    };

    let pic = null;

    if (autocompleteType.includes('companies')) {
      pic =
        option.picture && option.picture.medium === '' ? (
          <div className={styles.logoWrapper}>
            <img src={EmptyCompanyLogo} alt="" />
          </div>
        ) : (
          <div className={styles.logoWrapper}>
            <img src={option.picture.medium} alt="" />
          </div>
        );
    }
    if (autocompleteType === 'employers') {
      pic =
        option.picture && option.picture.medium === '' ? (
          <div className={styles.logoWrapper}>
            <img src={EmptyCompanyLogo} alt="" />
          </div>
        ) : (
          <div className={styles.logoWrapper}>
            <img src={option.picture.medium} alt="" />
          </div>
        );
    }
    if (autocompleteType === 'education_organizations') {
      pic =
        option.picture && option.picture.medium === '' ? (
          <div className={styles.logoWrapper}>
            <img src={EmptyEducationLogo} alt="" />
          </div>
        ) : (
          <div className={styles.logoWrapper}>
            <img src={option.picture.medium} alt="" />
          </div>
        );
    }
    if (isCandidate) {
      pic =
        option.picture && option.picture.medium === '' ? (
          <div className={styles.logoWrapperRound}>
            <img src={EmptyAvatar} alt="" />
          </div>
        ) : (
          <div className={styles.logoWrapperRound}>
            <img src={option.picture.medium} alt="" />
          </div>
        );
    }

    const picContent = pic || null;

    const optionProps = {
      className: optionClasses,
      key: option.id,
      onClick: onClickHandler,
      value: option.name,
    };

    const nameContent = isCandidate ? option.fullName : option.name;

    return (
      <div {...optionProps}>
        {picContent}
        {nameContent}
      </div>
    );
  };

  const content = options && options.length > 0 ? options.slice(0, 5).map(makeOptions) : null;

  const wrappedContent =
    active && content ? <div className={optionsContainerClasses}>{content}</div> : null;

  if (loadError) {
    errors.autocompleteError = [true];
  }

  const inputProps = {
    autoComplete: 'off',
    autoFocus,
    characterLimit,
    className: inputClasses,
    disabled,
    errors,
    handleInputChange: handleTextInputChange,
    handleInputKeyDown: handleKeyDown,
    hasFlex: true,
    highlight,
    isSearch,
    label,
    name,
    placeholder,
    required,
    size,
    type,
    value: search,
  };

  const buttonProps = {
    onClick: () => handleClickOutside({ stripCommas: false }),
    primary: true,
    className: styles.addButton,
  };

  return hasAddButton ? (
    <div className={selectClasses} ref={ref} {...rest}>
      <div className={styles.addButtonContainer}>
        <Input {...inputProps} />
        <Button {...buttonProps}>Add</Button>
      </div>
      {wrappedContent}
    </div>
  ) : (
    <div className={selectClasses} ref={ref} {...rest}>
      <Input {...inputProps} />
      {wrappedContent}
    </div>
  );
};

export default AutocompleteServer;
