import { all, fork } from 'redux-saga/effects';

import sagaEmployeePatchStarted from './sagaEmployeePatchStarted';
import sagaEmployeeImageUploadStarted from './sagaEmployeeImageUploadStarted';
import sagaEmployerImageUpload from './sagaEmployerImageUpload';
import sagaEmployerPatchStarted from './sagaEmployerPatchStarted';
import sagaUserPatchStarted from './sagaUserPatchStarted';
import sagaEmailChangeStarted from './sagaEmailChangeStarted';

const sagaEmployer = function* sagaEmployer(history) {
  yield all([
    fork(sagaEmailChangeStarted, history),
    fork(sagaEmployeePatchStarted, history),
    fork(sagaEmployeeImageUploadStarted, history),
    fork(sagaEmployerPatchStarted, history),
    fork(sagaEmployerImageUpload, history),
    fork(sagaUserPatchStarted, history),
  ]);
};

export default sagaEmployer;
