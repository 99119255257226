import React, { Component, Fragment } from 'react';

import Button from 'components/Button';
import Content from 'components/Content';
import FontIcon from 'components/FontIcon';
import FormattedDate from 'components/FormattedDate';
import Label from 'components/Label';

import styles from '../Message/Message.scss';

class SendgridEmailEvents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventsPage: 0,
    };
  }

  render() {
    const { eventsPage } = this.state;

    const { sendgridEmailEvents = [] } = this.props;

    const boolToString = (bool, highlight) =>
      bool ? (
        <span className={highlight ? styles.redYes : styles.yes}>Yes</span>
      ) : (
        <span className={styles.no}>No</span>
      );

    const {
      attributes: {
        bounce = '',
        click = '',
        createdAt,
        dropped = '',
        deferred = '',
        delivered = '',
        groupUnsubscribe = '',
        groupResubscribe = '',
        open = '',
        processed = '',
        spamreport = '',
        unsubscribe = '',
      } = {},
    } = sendgridEmailEvents[eventsPage] || {};

    const details = sendgridEmailEvents[eventsPage] ? (
      <div className={styles.messageDetails}>
        <Content className={styles.itemDetail}>
          <Label>Sent At:</Label> <FormattedDate format="M/D/YY h:ma" parse date={createdAt} />
        </Content>
        <Content className={styles.itemDetail}>
          <Label>Processed:</Label> {boolToString(processed)}
        </Content>
        <Content className={styles.itemDetail}>
          <Label>Dropped:</Label> {boolToString(dropped, true)}
        </Content>
        <Content className={styles.itemDetail}>
          <Label>Deferred:</Label> {boolToString(deferred)}
        </Content>
        <Content className={styles.itemDetail}>
          <Label>Delivered:</Label> {boolToString(delivered)}
        </Content>
        <Content className={styles.itemDetail}>
          <Label>Bounce:</Label> {boolToString(bounce, true)}
        </Content>
        <Content className={styles.itemDetail}>
          <Label>Open:</Label> {boolToString(open)}
        </Content>
        <Content className={styles.itemDetail}>
          <Label>Click:</Label> {boolToString(click)}
        </Content>
        <Content className={styles.itemDetail}>
          <Label>Unsubscribe:</Label> {boolToString(unsubscribe)}
        </Content>
        <Content className={styles.itemDetail}>
          <Label>Spam Report:</Label> {boolToString(spamreport)}
        </Content>
        <Content className={styles.itemDetail}>
          <Label>Group Unsubscribe:</Label> {boolToString(groupUnsubscribe)}
        </Content>
        <Content className={styles.itemDetail}>
          <Label>Group Resubscribe:</Label> {boolToString(groupResubscribe)}
        </Content>
      </div>
    ) : null;

    const totalEvents = sendgridEmailEvents.length;

    const prevButtonProps = {
      smallCircle: true,
      onClick: () => this.setState((prevState) => ({ eventsPage: prevState.eventsPage - 1 })),
      disabled: eventsPage === 0,
    };

    const nextButtonProps = {
      smallCircle: true,
      onClick: () => this.setState((prevState) => ({ eventsPage: prevState.eventsPage + 1 })),
      disabled: eventsPage === totalEvents - 1,
    };

    const navButtons = totalEvents ? (
      <div className={styles.navDetails}>
        <Button {...prevButtonProps}>
          <FontIcon iconName="caret-left" />
        </Button>
        {totalEvents - eventsPage}
        <Button {...nextButtonProps}>
          <FontIcon iconName="caret-right" />
        </Button>
      </div>
    ) : (
      ''
    );

    return (
      <Fragment>
        <div className={styles.eventDetails}>{details}</div>
        {navButtons}
      </Fragment>
    );
  }
}

export default SendgridEmailEvents;
