import React from 'react';

import Button from 'components/Button';

import styles from './Welcome.scss';

const Welcome = () => {
  const createAccountButtonProps = {
    className: styles.publishButton,
    primary: true,
    disabled: false,
    to: '/candidate/edit-personal',
  };

  return (
    <div className={styles.welcomeTop}>
      <div className={styles.welcomeContainer}>
        <h2 className={styles.welcomeHeader}>Thanks for Applying!</h2>
        <div className={styles.WelcomeMessage}>Your application has been received!</div>
        <Button {...createAccountButtonProps}>Update Profile</Button>
      </div>
    </div>
  );
};

export default Welcome;
