import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { handleError } from 'utils/common';
import { patchEntity } from 'api/apiEntity';
import { VisibleHOC } from 'containers/HOC';

import DropdownPane from 'components/DropdownPane';
import InlineCompanyEditor from 'components/InlineCompanyEditor';

import { useCandidatePublicProfile } from 'hooks/useCandidatePublicProfile';

import patchCandidateRefreshTriggers from 'api/apiCandidates/patchCandidateRefreshTriggers';

import {
  resourceUpdate,
  resourceDelete,
  entityLoadStarted,
  setImageUploadCompany,
} from 'shared/store/app/creators';

const ToolboxCompanyEditorDropdown = ({
  unverifiedData,
  company,
  isVisible,
  refreshCallback,
  sliceToUpdate = 'toolboxCompanies',
  candidateId,
}) => {
  const attrs = company.attributes || company;
  const { id, attributes: { webSite, picture = {} } = {} } = unverifiedData || {};

  const dispatch = useDispatch();

  const publicProfileId = useCandidatePublicProfile(candidateId);

  useEffect(() => {
    dispatch(
      entityLoadStarted({
        type: 'companies',
        slice: 'toolboxCompanies',
        id: attrs.id,
        params: {
          includeSet: 'unverified_data',
          sortBy: 'created_at_desc',
        },
        updateOnly: true,
      })
    );
  }, [attrs.id, dispatch]);

  const verifyOrRejectCompany = async (verify) => {
    if (verify) {
      dispatch(
        resourceUpdate({
          slice: sliceToUpdate,
          type: 'companies',
          id: company.id,
          resource: {
            attributes: {
              ...attrs,
              webSite: webSite || company.attributes.webSite,
              picture: picture || company.attributes.picture,
            },
          },
        })
      );
    }

    try {
      await patchEntity({
        id,
        type: 'unverified_company_data',
        data: {
          attributes: {
            state: verify ? 'verified' : 'rejected',
          },
          id,
          type: 'unverified_company_data',
        },
        config: {
          params: {
            camelizeIt: true,
          },
        },
      });

      if (publicProfileId) {
        patchCandidateRefreshTriggers(publicProfileId).catch(handleError);
      }
    } catch (error) {
      handleError(error);
    }

    if (refreshCallback) {
      refreshCallback();
    }

    dispatch(
      resourceDelete({
        id,
        slice: sliceToUpdate,
        type: 'unverifiedCompanyData',
      })
    );
  };

  return (
    <DropdownPane
      above={!isVisible}
      element={
        <InlineCompanyEditor
          unverifiedData={unverifiedData}
          onVerify={() => verifyOrRejectCompany(true)}
          onReject={() => verifyOrRejectCompany(false)}
          publicProfileId={publicProfileId}
        />
      }
      label="Company Editor"
      left={true}
      onOpenCallback={() => {
        dispatch(setImageUploadCompany(company));
      }}
    />
  );
};

export default VisibleHOC()(ToolboxCompanyEditorDropdown);
