/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import jobsPageMetaData, {
  JOB_PAGE_AE,
  JOB_PAGE_CSM,
  JOB_PAGE_EAE,
  JOB_PAGE_MMAE,
  JOB_PAGE_SD,
  JOB_PAGE_SDR,
  JOB_PAGE_SM,
  JOB_PAGE_VP,
} from 'pages/JobsPage/jobsPageMetaData';

import { getAltName } from 'utils/urlUtils';

import pic1 from './assets/1-Los-Angeles-Tech-Sales-Jobs.png';
import pic2 from './assets/2-Los-Angeles-SDR-careers.png';
import pic3 from './assets/3-Los-Angeles-SDR-jobs.png';
import pic4 from './assets/4-Tech-Talent-Scouts.png';
import pic5 from './assets/5-Contact-Sales-Recruiters.png';

import styles from './LAContent.scss';

export function LAContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt={getAltName(pic1)} />

      <h2>Tech Sales Careers In Los Angeles</h2>

      <p>
        Los Angeles is known for many things, not the least of which is being the unrivaled capital
        of the entertainment industry. Some might find it surprising that it’s also emerging as one
        of the top tech hubs in the United States.
      </p>

      <p>
        As tech centers, startups, and enterprise IT industries shuffle about the country looking
        for the most advantageous location to settle, many are finding Los Angeles not only amenable
        to the tech industry but actively encouraging its growth.
      </p>

      <p>
        With that growth comes a wealth of new business opportunities and many career pathways
        opening up for tech sales.
      </p>

      <h2>Los Angeles As a Major Tech Center</h2>

      <p>
        Los Angeles is now home to around 3,800 venture-backed tech-based companies, making it the
        third-largest tech environment in the country. In recent years, it’s seen as much as 20%
        growth annually in technical industries and an 83% increase in venture capital investments
        into startups.
      </p>

      <p>
        Among the top tech industries experiencing rich growth in Los Angeles are blockchain,
        enterprise software, fintech, and health technology. Also growing in the LA area are tech
        businesses related to mobile communications, smart technology, logistical software,
        education tech, and high-end consumer products.
      </p>

      <p>
        With an established manufacturing base and a business-friendly environment, Los Angeles has
        a solid foundation to build itself up as a world-recognized technology hub covering various
        industries, from SaaS to medicine to finances.
      </p>

      <img src={pic2} alt={getAltName(pic2)} />

      <h2>Living the Best Life In Los Angeles</h2>

      <p>
        Los Angeles has attracted people from all over with its beautiful weather and beaches. What
        keeps people in Los Angeles is its amazing quality of life.
      </p>

      <p>
        Art is everywhere in Los Angeles, as is some of the finest food in the world. One thing that
        helps bring that all together is its diversity. It seems like the whole world comes together
        in Los Angeles to deliver the best in music, food, dance, art, theater, comedy, and more.
      </p>

      <p>
        While the cost of living in Los Angeles may be higher than the national average,
        compensation tends to keep pace. Salespeople who move to Los Angeles to pursue profitable
        sales careers will not only be able to make a good living but also take advantage of the
        cultural center of the United States and all the excitement and glam that comes with it.
      </p>

      <h2>Sales Positions Available In Los Angeles</h2>

      <p>
        Opportunities for tech sales careers have begun appearing throughout Los Angeles. By
        connecting skilled people with top-of-the-line employers, Rainmakers creates outstanding
        career prospects.
      </p>

      <p>Here are some of the most rewarding tech sales jobs becoming available in Los Angeles.</p>

      <h3>Sales Development Representative</h3>

      <p>
        A tech sales team’s success is based on how well its{' '}
        <a href={jobsPageMetaData[JOB_PAGE_SDR].localUrl} target="_blank" rel="noreferrer">
          Sales Development Representatives (SDRs)
        </a>{' '}
        perform. To create sales pipelines for the rest of the team, an SDR specializes in inside
        sales activities, concentrating on research and cold calling. An entry-level SDR role is
        typically the ideal place to begin a long-term sales career. The position best suits those
        with a strong desire for success and requires little to no prior knowledge.
      </p>

      <img src={pic3} alt={getAltName(pic3)} />

      <h3>Account Executive</h3>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_AE].localUrl} target="_blank" rel="noreferrer">
          Account Executives (AE)
        </a>{' '}
        generate new business, manage client accounts, negotiate deals with prospective clients, and
        attend to current customers’ needs. Since commissions make up a sizable chunk of an AE’s
        pay, the position requires a concentrated effort on the part of the AE to reach
        predetermined quotas and earning targets.
      </p>

      <h3>Mid-Market Account Executive</h3>

      <p>
        A significant next step for sales professionals who are passionate about their careers and
        want to grow is becoming a{' '}
        <a href={jobsPageMetaData[JOB_PAGE_MMAE].localUrl} target="_blank" rel="noreferrer">
          Mid-Market Account Executive
        </a>
        . Mid-Market Account Executives oversee training, foster development, apply SaaS/cloud
        technologies, aid in deal closing, and uphold solid client connections. Middle-market
        enterprises are their main source of new sales and commissions.
      </p>

      <h3>Enterprise Account Executive</h3>

      <p>
        With the aid of{' '}
        <a href={jobsPageMetaData[JOB_PAGE_EAE].localUrl} target="_blank" rel="noreferrer">
          Enterprise Account Executives
        </a>
        , manufacturers, software developers, telecommunications companies, retail chains, and other
        significant organizations can target big corporations that have the potential to generate
        huge revenues. Enterprise Account Executives build lucrative business relationships,
        encourage innovation, and maintain profitable sales. The ideal candidate for this role will
        be capable, organized, and committed. This position typically has high earning potential
        because of the level of responsibility required.
      </p>

      <img src={pic4} alt={getAltName(pic4)} />

      <h3>Customer Success Manager</h3>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_CSM].localUrl} target="_blank" rel="noreferrer">
          Customer Success Managers (CSMs)
        </a>{' '}
        put a lot of effort into creating solid, enduring client relationships. They are responsible
        for selling goods and services to businesses to encourage repeat business and nurture client
        success. Despite being a relatively new sales position, CSMs are now successfully used by
        almost half of high-tech companies.
      </p>

      <h3>Sales Director</h3>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_SD].localUrl} target="_blank" rel="noreferrer">
          Sales Directors
        </a>{' '}
        uphold a business’s dedication to providing excellent customer service. Their primary
        responsibilities include ensuring the sales team completes all required tasks, creating
        services, and delivering new products to customers. Additionally, Sales Directors oversee
        the sales team, conduct training sessions, and make sure everyone is pursuing their goals. A
        candidate must have excellent organizational, sales, and leadership skills for this sales
        position.
      </p>

      <h3>Sales Manager</h3>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_SM].localUrl} target="_blank" rel="noreferrer">
          Sales Managers
        </a>{' '}
        are responsible for the sales force and everyday sales activity. They are in charge of
        creating growth and sales strategies, establishing and monitoring sales goals, and
        monitoring profit growth. In addition to recruiting salespeople, developing sales plans,
        providing coaching, and monitoring operations, a Sales Manager may also have other related
        duties. The size of the business and the sales department typically dictate the range of a
        Sales Manager’s responsibilities.
      </p>

      <Link to="/apply-now" title="Apply Now">
        <img src={pic5} alt={getAltName(pic5)} />
      </Link>

      <h3>Vice President of Sales</h3>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_VP].localUrl} target="_blank" rel="noreferrer">
          Vice Presidents (VPs)
        </a>{' '}
        of Sales are responsible for many essential tasks. They are in charge of improving the
        performance of the sales division and assisting them in exceeding quotas. They frequently
        take on the roles of managers, coaches, and strategists. Vice Presidents of Sales frequently
        set objectives and sales plans, oversee sales teams, track profits, employ salespeople, and
        carry out additional related tasks. Although it comes with a lot of responsibility, this
        position also tends to command the highest pay.
      </p>

      <h2>More On Tech Sales Careers at Rainmakers</h2>

      <p>
        Tech sales careers provide a wide range of professional and personal growth opportunities.
        There are numerous profitable career paths for those willing to invest the time and effort
        necessary to meet and exceed expectations and demonstrate their worth to the team.
      </p>

      <p>
        Expanding businesses need every team member—from the Vice President of Sales to the Sales
        Development Representatives—to succeed. All parties benefit from candidates who apply
        themselves, possess the necessary skills, and are dedicated to achieving their goals.
      </p>

      <p>
        <a href="/apply-now/">Contact Rainmakers today</a> to find out more about these growing
        opportunities.
      </p>
    </div>
  );
}
