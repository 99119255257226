import React, { Component, Fragment } from 'react';

import { handleError } from 'utils/common';

import { promiseGetRequisitionCandidates } from 'pages/EmployeePortal/CandidateProfilePages/promises';

import { ComponentErrorBoundaryHOC } from 'containers/HOC';

import Button from 'components/Button';
import FontIcon from 'components/FontIcon';

import styles from './MatchButtons.scss';

// needIntroButton boolean controls the display of
// the request intro button.
// True means we need to show the Request Intro button with active onClick handler
// False means we need to show Contact Requested
// Undefined means show the Request Intro button but disable onClick handler
// Null means do not show anything

export class MatchButtons extends Component {
  constructor(props) {
    super(props);
    const { isEmployee, isAdmin, hide = false, loggedInEmployerId } = props;

    const needIntroButton = isEmployee || (isAdmin && loggedInEmployerId) ? true : undefined;

    if (hide) {
      this.state = {
        needIntroButton: null,
        doneLoading: false,
      };
    } else {
      this.state = {
        needIntroButton,
        doneLoading: false,
      };
    }
  }

  componentDidMount() {
    const { candidateId, isEmployee, loggedInEmployerId, isAdmin, hide = false } = this.props;

    if (!hide) {
      if (isEmployee) {
        promiseGetRequisitionCandidates({ candidateId })
          .then((reqCandidates) =>
            this.onRequisitionCandidatesFulfilled({ reqCandidates, candidateId })
          )
          .catch((error) => this.onRequisitionCandidatesFailed({ error, candidateId }));
      } else if (isAdmin && loggedInEmployerId) {
        promiseGetRequisitionCandidates({
          candidateId,
          employerId: loggedInEmployerId,
        })
          .then((reqCandidates) =>
            this.onRequisitionCandidatesFulfilled({ reqCandidates, candidateId })
          )
          .catch((error) => this.onRequisitionCandidatesFailed({ error, candidateId }));
      } else {
        this.setState({ doneLoading: true });
      }
    } else {
      this.setState({ doneLoading: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { candidateId: currCandidateId } = this.props;
    const { candidateId, isEmployee, isAdmin, loggedInEmployerId, hide, needReload } = nextProps;

    if (currCandidateId !== candidateId) {
      this.setState({ doneLoading: false, needIntroButton: null });
    }

    if (!hide || needReload) {
      if (isEmployee) {
        promiseGetRequisitionCandidates({ candidateId })
          .then((reqCandidates) =>
            this.onRequisitionCandidatesFulfilled({ reqCandidates, candidateId })
          )
          .catch((error) => this.onRequisitionCandidatesFailed({ error, candidateId }));
      }

      if (isAdmin && loggedInEmployerId) {
        promiseGetRequisitionCandidates({
          candidateId,
          employerId: loggedInEmployerId,
        })
          .then((reqCandidates) =>
            this.onRequisitionCandidatesFulfilled({ reqCandidates, candidateId })
          )
          .catch((error) => this.onRequisitionCandidatesFailed({ error, candidateId }));
      } else {
        this.setState({ doneLoading: true });
      }
    } else {
      this.setState({ needIntroButton: null, doneLoading: true });
    }
  }

  onRequisitionCandidatesFulfilled = ({ reqCandidates, candidateId }) => {
    const { candidateId: currCandidateId } = this.props;

    if (currCandidateId === candidateId) {
      this.setState({
        needIntroButton: reqCandidates.length === 0,
        doneLoading: true,
      });
    }
  };

  onRequisitionCandidatesFailed = ({ error, candidateId }) => {
    handleError(error);

    const { candidateId: currCandidateId } = this.props;

    if (currCandidateId === candidateId) {
      this.setState({
        needIntroButton: true,
        doneLoading: true,
      });
    }
  };

  render() {
    const {
      candidateId,
      firstName,
      lastName,
      handleRequestIntroClick,
      handlePassFeaturedCandidate,
      handleUndoPassFeaturedCandidate,
      isAdmin,
      isCandidateLoading,
      isEmployee,
      hide = false,
      requestSent,
      loggedInEmployerId,
    } = this.props;

    const { needIntroButton, doneLoading } = this.state;

    const contactButtonProps = {
      key: 'contactButton',
      className: styles.profileContactButton,
      primary: true,
      onClick:
        needIntroButton === undefined ? () => null : () => handleRequestIntroClick({ isAdmin }),
    };

    const actionButton =
      requestSent || needIntroButton === false ? (
        <div key="contactedButton" className={styles.contactedNote}>
          <FontIcon className={styles.seafoam} iconName="checkmark-circle" />
          Contact Request Sent!
        </div>
      ) : (
        <Button {...contactButtonProps}>
          <span>Request Intro</span>
        </Button>
      );

    const fakeActionButton = <div key="fakeButton" className={styles.fakeContact} />;

    const actionButtonContent = needIntroButton !== null ? actionButton : fakeActionButton;

    const finalActionButtonContent =
      isEmployee || (isAdmin && loggedInEmployerId) ? actionButtonContent : null;

    const adminPassOnClick =
      isAdmin && loggedInEmployerId
        ? () => {
            this.setState({ needIntroButton: null });
            handlePassFeaturedCandidate({
              firstName,
              lastName,
              candidateId,
              loggedInEmployerId,
            });
          }
        : () => null;

    const employeePassOnClick = isEmployee
      ? () => {
          this.setState({ needIntroButton: null });
          handlePassFeaturedCandidate({
            candidateId,
            firstName,
            lastName,
          });
        }
      : null;

    const passOnClick = employeePassOnClick || adminPassOnClick;

    const passButtonProps = {
      key: 'passButton',
      warningTertiary: true,
      className: styles.passButton,
      onClick: needIntroButton === undefined ? () => null : passOnClick,
    };

    const passedButtonProps = {
      key: 'passedButton',
      secondary: true,
      className: styles.passedButton,
      onClick: () => {
        this.setState({ needIntroButton: true });
        handleUndoPassFeaturedCandidate({ candidateId });
      },
    };

    const showPassButton =
      (isEmployee || (isAdmin && loggedInEmployerId)) && needIntroButton !== false;

    const passButton = <Button {...passButtonProps}>Not Interested</Button>;

    const fakePassButton = <div key="fakeButton2" className={styles.fakeContact2} />;

    const passButtonContent = needIntroButton !== null ? passButton : fakePassButton;

    const passedButton = hide ? <Button {...passedButtonProps}>Undo</Button> : null;

    const passActionButton = passedButton || passButtonContent;

    const passActionButtonFinal = showPassButton ? passActionButton : null;

    return !isCandidateLoading && doneLoading ? (
      <Fragment>
        {finalActionButtonContent}
        {passActionButtonFinal}
      </Fragment>
    ) : (
      <Fragment>
        <div key="fakeButton1" className={styles.fakeContact} />
        <div key="fakeButton2" className={styles.fakeContact2} />
      </Fragment>
    );
  }
}

export default ComponentErrorBoundaryHOC()(MatchButtons);
