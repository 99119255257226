import { createSelector } from 'reselect';
import { entitySelectorFactory } from 'reduxStore/selectorFactories';

const selectRequisitionCandidate = createSelector(
  entitySelectorFactory('toolboxApproaches', 'requisitionCandidate'),
  (state, props) => {
    const { match: { params: { id } = {} } = {} } = props || {};

    return id;
  },
  (requisitionCandidates, id) => {
    const { byId: { [id]: requisitionCandidate = {} } = {} } = requisitionCandidates;

    return requisitionCandidate;
  }
);

export default selectRequisitionCandidate;
