import { createStructuredSelector } from 'reselect';

import {
  entitySelectorFactory,
  loadingSelectorFactory,
  pageSelectorFactory,
  totalSelectorFactory,
  savingSelectorFactory,
} from 'reduxStore/selectorFactories';

import {
  selectIsLoggedIn,
  selectIsAdmin,
  selectIsEmployee,
  selectLogoutPending,
} from 'connectors/Login/redux/selectors';

import { selectUser } from 'connectors/User/redux/selectors';

import { getModal } from 'shared/store/app/selectors';

import { selectPickerOptions } from 'connectors/Defaults/redux/selectors';

import selectRequisitionCandidate from './selectRequisitionCandidate';

const selector = createStructuredSelector({
  logoutPending: selectLogoutPending,
  user: selectUser,
  isLoggedIn: selectIsLoggedIn,
  isAdmin: selectIsAdmin,
  isEmployee: selectIsEmployee,
  messages: entitySelectorFactory('toolboxApproaches', 'message'),
  messagesPage: pageSelectorFactory('toolboxApproaches', 'message'),
  messagesTotal: totalSelectorFactory('toolboxApproaches', 'message'),
  messagesLoading: loadingSelectorFactory('toolboxApproaches', 'message'),
  messageGroupMembers: entitySelectorFactory('toolboxApproaches', 'messageGroupMember'),
  messageGroups: entitySelectorFactory('toolboxApproaches', 'messageGroup'),
  messageRecipients: entitySelectorFactory('toolboxApproaches', 'messageRecipient'),
  rainmakersAdmins: entitySelectorFactory('toolboxApproaches', 'rainmakersAdmin'),
  requisitionCandidate: selectRequisitionCandidate,
  requisitionCandidates: entitySelectorFactory('toolboxApproaches', 'requisitionCandidate'),
  sendgridEmailEvents: entitySelectorFactory('toolboxApproaches', 'sendgridEmailEvent'),
  candidates: entitySelectorFactory('toolboxApproaches', 'candidate'),
  employees: entitySelectorFactory('toolboxApproaches', 'employees'),
  employers: entitySelectorFactory('toolboxApproaches', 'employer'),
  privateReqCanFeedback: entitySelectorFactory('toolboxApproaches', 'privateReqCanFeedback'),
  requisitionOpenings: entitySelectorFactory('toolboxApproaches', 'requisitionOpening'),
  isLoading: loadingSelectorFactory('toolboxApproaches', 'requisitionCandidate'),
  saving: savingSelectorFactory('toolboxApproaches'),
  page: pageSelectorFactory('toolboxApproaches', 'requisitionCandidate'),
  total: totalSelectorFactory('toolboxApproaches', 'requisitionCandidate'),
  modal: getModal,
  pickerOptions: selectPickerOptions,
});

export { selector as default };
