import { promiseGetCandidate, promiseGetCandidateRelationships, promiseGetBookmark } from '.';

const promiseGetFullCandidate = ({
  candidateId,
  contextOrigin,
  includeBookmark,
  isEmployee,
  noPrivate,
  noUser,
}) => {
  const onFulfilled = (responses = []) => {
    const candidate = responses[0] || {};
    const relationships = responses[1] || {};
    const bookmark = responses[2] || {};

    const fullCandidate = {
      ...candidate,
      ...relationships,
      bookmark,
    };

    return fullCandidate;
  };

  const mockPromise = new Promise((resolve) => setTimeout(resolve, 0));

  const bookmarkPromise = includeBookmark ? promiseGetBookmark({ candidateId }) : mockPromise;

  const promises = [
    promiseGetCandidate({
      candidateId,
      contextOrigin,
      normalizeIt: true,
    }),
    promiseGetCandidateRelationships({
      candidateId,
      isEmployee,
      normalizeIt: true,
      noPrivate,
      noUser,
    }),
    bookmarkPromise,
  ];

  return Promise.all(promises).then(onFulfilled);
};

export default promiseGetFullCandidate;
