import React from 'react';
import classnames from 'classnames';

import styles from './Label.scss';

const Label = ({ children, required, standalone, className, htmlFor, disabled }) => {
  const classes = classnames(
    styles.Label,
    {
      [styles.required]: required,
      [styles.standalone]: standalone,
      [styles.disabled]: disabled,
    },
    className
  );

  return (
    <label htmlFor={htmlFor} className={classes}>
      {children}
    </label>
  );
};

export default Label;
