import { handleActions } from 'redux-actions';

import {
  RESET_PASSWORD_STARTED,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_DONE,
  RESET_PASSWORD_ERROR_RESET,
} from '../actions';

const initialState = {
  resetPasswordErrors: '',
  resetPasswordDone: false,
};

const handleResetPasswordStarted = (state = initialState) => ({
  ...state,
});

const handleResetPasswordDone = (state = initialState) => ({
  ...state,
  resetPasswordDone: true,
});

const handleResetPasswordError = (state = initialState, action) => {
  const {
    payload: { errors },
  } = action;

  return {
    ...state,
    resetPasswordErrors: errors,
    resetPasswordDone: false,
  };
};

const handleResetPasswordErrorReset = () => initialState;

const actionHandlers = {
  [RESET_PASSWORD_STARTED]: handleResetPasswordStarted,
  [RESET_PASSWORD_DONE]: handleResetPasswordDone,
  [RESET_PASSWORD_ERROR]: handleResetPasswordError,
  [RESET_PASSWORD_ERROR_RESET]: handleResetPasswordErrorReset,
};

const reduceResetPassword = handleActions(actionHandlers, initialState);

export default reduceResetPassword;
