import React, { useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import InfiniteScroll from 'react-infinite-scroller';

import { EmployeeAuthHOC } from 'containers/HOC';
import { employerHomePageValues } from 'utils/constants';

import ConnectContainer from 'containers/ConnectContainer';

import ReadyContainer from 'connectors/ReadyContainer';

import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import Sidebar from 'components/Sidebar';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import Block from 'components/Block';
import TableOverlay from 'components/TableOverlay';

import Bookmark from './components/Bookmark';

import duck from './redux';

import styles from './CandidateBookmarks.scss';

const defaultLoadParams = {
  includeSet: 'candidate',
  page: 1,
};

const defaultLoadArgs = {
  type: 'bookmarks',
  slice: 'employerBookmarks',
};

const CandidateBookmarks = (props) => {
  const {
    actions: { deleteResource, entityLoadStarted, showModal },
    bookmarks: { byId = {}, allIds = [] } = {},
    candidates: { byId: candidatesById = {} } = {},
    children,
    isAdmin,
    isEmployee,
    user: { currentProfile: { employer: { homepage } = {} } = {} } = {},
    isLoading,
    total,
    page = 1,
  } = props;

  useEffect(() => {
    entityLoadStarted({
      ...defaultLoadArgs,
      params: {
        ...getLoadParams(),
        page: 1,
      },
    });
  }, [entityLoadStarted, getLoadParams]);

  const getLoadParams = useCallback(() => {
    return {
      ...defaultLoadParams,
      page,
    };
  }, [page]);

  const excludedLinks =
    isEmployee && homepage && Array.isArray(employerHomePageValues[homepage]?.excluded_pages)
      ? employerHomePageValues[homepage].excluded_pages
      : [];

  const loadMore = () => {
    entityLoadStarted({
      ...defaultLoadArgs,
      params: {
        ...getLoadParams(),
        page: page + 1,
      },
    });
  };

  const openProfileModal = (candidateId) => {
    showModal({
      key: 'CandidateProfileModal',
      parent: 'CandidateBookmarksPage',
      route: `/candidates/${candidateId}/profile`,
      search: '?tp=b',
    });
  };

  const contentBlockProps = {
    title: 'Saved Candidates',
    boxShadow: true,
    addWhiteBG: true,
    addFlex: true,
    addChildFlex: true,
    addBottomFade: true,
  };

  const bookmarksContent =
    allIds.length === 0 && !isLoading ? (
      <div className={styles.emptyContainer}>No Saved Candidates</div>
    ) : (
      allIds.map((id) => {
        const {
          [id]: { relationships: { candidate: { data: candidateIds = [] } = {} } = {} } = {},
        } = byId;

        const { [candidateIds[0]]: { attributes: candidate = {} } = {} } = candidatesById;

        const bookmarkProps = {
          candidate,
          bookmarkId: id,
          deleteResource,
          handleOnClick: openProfileModal,
        };

        return <Bookmark key={id} {...bookmarkProps} />;
      })
    );

  const infiniteScrollProps = {
    pageStart: 0,
    loadMore: loadMore,
    hasMore: total > bookmarksContent.length,
    loader: isLoading ? (
      <div key="infiniteScrollLoader" />
    ) : (
      <InfiniteScrollLoader key="infiniteScrollLoader" />
    ),
    useWindow: false,
  };

  return (
    <>
      <Helmet key="helmet" title="Saved Candidates" />
      <ReadyContainer key="readyContainer" className={styles.CandidateBookmarks}>
        <Sidebar type="search" page="candidateBookmarks" excludedLinks={excludedLinks} />
        <LayoutWithoutSidebar
          content={
            !isAdmin ? (
              <div className={styles.searchMainContent}>
                <Block {...contentBlockProps}>
                  <div className={styles.searchResultsContainer}>
                    {isLoading && <TableOverlay />}
                    <InfiniteScroll {...infiniteScrollProps}>{bookmarksContent}</InfiniteScroll>
                  </div>
                </Block>
              </div>
            ) : (
              <div className={styles.searchMainContent}>
                <Block {...contentBlockProps}>
                  <div className={styles.emptyContainer}>
                    Why are you here? Admins can&apos;t have bookmarks!
                  </div>
                </Block>
              </div>
            )
          }
        />
        {children}
      </ReadyContainer>
    </>
  );
};

export default ConnectContainer(duck)(EmployeeAuthHOC()(CandidateBookmarks));
