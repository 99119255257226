import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Hero.scss';

const Hero = () => (
  <div className={styles.heroContainer}>
    <div className={styles.hero}>
      <div className={styles.Text}>
        <h3 className={styles.row1}>
          LAND YOUR DREAM <br />
          SALES JOB
        </h3>

        <h1 className={styles.row3}>
          Rainmakers connects high-performing salespeople with awesome companies
        </h1>

        <Link className={styles.Request} to="/apply-now/">
          Apply Now
        </Link>

        <div className={styles.mobileHero}>
          <img src={require('../../assets/mobile-billy.png')} alt="" />
          <img src={require('../../assets/mobile-tina.png')} alt="" />
        </div>
      </div>
    </div>
  </div>
);

export default Hero;
