/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import { getAltName } from 'utils/urlUtils';

import pic1 from './assets/atlanta-sales-recruiting-agency-1.png';
import pic2 from './assets/best-sales-recruiters-rainmakers-2.png';
import pic3 from './assets/rainmakers-hiring-process-3.png';
import pic4 from './assets/work-with-a-sales-recruiting-agency-4.png';

import styles from './AtlantaContent.scss';

export function AtlantaContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt={getAltName(pic1)} />
      <h2>Top Recruiting Firms in Atlanta</h2>
      <p>
        Hirewell and Rainmakers are two top recruiting agencies serving Atlanta, each specializing
        in different areas. Hirewell is a talent acquisition partner for the modern world and offers
        a range of recruiting services in technology, HR, marketing, sales, finance and accounting,
        real estate, supply chain, insurance, and executive recruiting. On the other hand,
        Rainmakers is a sales recruiting agency that connects top-performing salespeople with the
        companies that need their services the most. Both agencies help businesses find the right
        talent quickly, save time, and reduce risk in the hiring process.
      </p>
      <p>Learn more about the top recruiting agencies serving Atlanta-area companies.</p>
      <p>
        {'1) '} <a href="https://hirewell.com/">Hirewell</a>
      </p>
      <p>
        Hirewell is not your average recruiting agency - it was built on the idea of challenging the
        status quo and doing talent acquisition differently and better. Since its inception in 2001,
        Hirewell has been committed to pushing the boundaries of traditional recruiting methods and
        approaches. With a team of experienced recruitment consultants and its innovative recruiting
        technology, Hirewell constantly strives to find new and more effective ways to connect top
        talent with businesses across a wide range of industries.
      </p>
      <p>
        {'2) '} <a href="https://www.rainmakers.co/">Rainmakers</a>
      </p>
      <p>
        Rainmakers is a recruiting platform exclusively designed for top salespeople in thriving
        markets such as Atlanta, and offers a unique approach to talent acquisition. With a focus on
        showcasing sales achievements, salespeople set their hiring requirements, and companies bid
        on the candidates that best meet their needs.
      </p>
      <p>
        {'3) '} <a href="https://www.atlgastaffing.com/">ATL GA Staffing</a>
      </p>
      <p>
        ATL GA Staffing is a staffing agency that specializes in hospitality staffing for leading
        hotels in the Atlanta area. They provide tailored services to each property, including
        building and retaining high-quality teams in housekeeping, laundry, and food & beverage.
        Their team members are carefully screened and selected, ensuring that clients receive the
        best staff that the market has to offer. Located in the heart of Atlanta, they offer a range
        of positions to job seekers in the hospitality industry.
      </p>
      <p>
        {'4) '} <a href="https://www.pridestaff.com/atlantacentral/">PrideStaff</a>
      </p>
      <p>
        PrideStaff is a staffing agency that provides temporary, temporary-to-hire, and direct hire
        staff for various industries, including light industrial, administrative, clerical,
        accounting & finance, and legal administrative. They create employment solutions that
        benefit clients, candidates, and the company. With 25+ years of experience in the Atlanta
        area, they use their knowledge and PrideStaff's national resources to connect employers with
        the qualified and productive staff they need.
      </p>
      <Link to="/employer-signup/" title="Apply Now">
        <img src={pic2} alt={getAltName(pic2)} />
      </Link>
      <h2>Find Top Sales Talent in Atlanta?</h2>
      <p>
        Finding the right sales professionals on your own can feel daunting. The best sales
        professionals are usually already employed, and the demand for the available talent is
        fierce, especially in an economic hub like Atlanta. Yet, hiring the wrong sales professional
        costs your company valuable time and money. You need the right person, but you need to
        reduce expenses and risks for your company.
      </p>
      <p>
        You’re looking for an all-star employee who will generate significant revenue for your
        company. That’s precisely where Rainmakers steps in.
      </p>
      <p>
        At Rainmakers, we offer the services of a sales recruiting agency that connects
        top-performing salespeople with the companies that need their services most. Because we
        accept only the best candidates, you can be confident that you will interview only the top
        talent in the industry. As a result, our process predictably finds effective sales
        professionals, saving you time and reducing the risk to your business.
      </p>
      <p>
        <strong>
          With the help of Rainmakers, Atlanta area businesses connect more quickly with the people
          that will drive their success.
        </strong>{' '}
        We match talent with the specific skills and experience you’re looking for in your company,
        assisting your hiring managers with their job so you can get the right people in place as
        quickly as possible.
      </p>
      <h2>What Is a Sales Recruiting Agency?</h2>
      <p>
        Sales recruiting agencies partner with businesses that need to hire salespeople. These firms
        have sales recruiters who understand the personality and skill set necessary for successful
        sales professionals, and they also have intimate knowledge of the hiring process.
      </p>
      <p>
        By combing through the talent for your company, sales recruiting firms like Rainmakers free
        up your time to focus on getting your new people in position and working hard for you more
        quickly, so you can start seeing the benefits of your additional sales help.
      </p>
      <h2>What Challenges Do Hiring Managers Face in Atlanta?</h2>
      <p>
        The sales industry is facing a talent crisis. Sales rep attrition rates are at all-time
        highs, so companies are scrambling to retain talent. Unfortunately, companies often need
        more information on candidates through resumes and LinkedIn profiles, making it difficult to
        compare them.
      </p>
      <p>
        Another challenge in the sales industry is the need for more soft skills for new hires. Not
        only are these harder to measure, but hiring managers are finding that many candidates need
        more skills, like communication and teamwork, which are essential in the life of a
        salesperson.
      </p>
      <p>
        Sales recruiting companies like Rainmakers step in to help businesses overcome these
        challenges. By doing a deeper dive into available talent and looking at more than just basic
        knowledge and education, Rainmakers can help you find people with the right skills as
        quickly as possible.
      </p>
      <Link to="/employer-signup/" title="Apply Now">
        <img src={pic4} alt={getAltName(pic4)} />
      </Link>
      <h2>What Types of Companies Work with Sales Recruiters?</h2>
      <p>
        Any company that uses a team of sales professionals to grow its customer base can benefit
        from working with sales recruiting firms. Finance, retail, medical, pharmaceutical, and even
        service businesses can benefit from improved sales teams.
      </p>
      <p>
        Yet it is in the tech industry where sales recruiters are in the highest demand. To best
        connect talent with hiring companies, sales recruiters need to understand both the tech and
        sales worlds. In addition, sales employees need a deep knowledge of topics like big data,
        SaaS, and cybersecurity to be effective in this market.
      </p>
      <p>
        The problem for tech businesses is fierce competition for the best talent, especially in
        Atlanta. So, when you find a candidate that fits your needs, chances are high that they
        already have a job offer, if not more than one, making it difficult to get someone on
        board. 
      </p>
      <p>
        Working with sales recruiters helps you connect more quickly with these professionals while
        also helping you build a job offer that is more likely to lead to acceptance.
      </p>
      <h2>How Does the Recruiting Process Work in Atlanta?</h2>
      <p>
        When Atlanta companies partner with sales recruiting agencies, they benefit from a proven,
        effective process that takes the workload off of hiring managers while improving the quality
        of the candidates they interview. For Atlanta area businesses, this process involves:
      </p>
      <ol>
        <li>
          <strong>Connect with Rainmakers:</strong> First, you come to an agreement with the
          Rainmakers team to work together.
        </li>
        <li>
          <strong>Defining your need:</strong> Next, our team will meet with your business to
          determine what you need in a sales professional. We will help you clarify the job posting
          so it appeals to the exact type of candidate you need.
        </li>
        <li>
          <strong>Reaching candidates:</strong> Next, we will dig into your deep database of
          available talent while also mobilizing our professional network to reach qualified
          candidates. Because our candidates build in-depth sales profiles including compensation
          requirements, you’ll know exactly what you’re getting.
        </li>
        <li>
          <strong>Interviewing:</strong> Interviewing is done first by the Rainmakers team and then
          by your company once we have thoroughly vetted and screened the talent. This process means
          the people you spend time with are highly qualified, talented candidates for your
          business.
        </li>
        <li>
          <strong>Hiring:</strong> With a smaller pool of candidates, you can quickly find the one
          that fits your company’s needs, hire them, and start the onboarding process.
        </li>
      </ol>
      <img src={pic3} alt={getAltName(pic3)} />
      <h2>How Does a Recruiting Agency Help the Hiring Process?</h2>
      <p>
        By working with an Atlanta sales recruiting firm, your company benefits from the following:
      </p>
      <ul>
        <li>
          Working with a team of experienced recruiters who understand the sales industry and what
          it takes to succeed.
        </li>
        <li>
          Saving time looking at only a hand-selected group to choose from who are capable and
          talented.
        </li>
        <li>Partnering with a specialist who has specific recruitment knowledge.</li>
        <li>
          Extending your reach to candidates who aren’t actively looking but would fit your job
          perfectly.
        </li>
        <li>Substantially speeding up the time from job posting to hiring.</li>
      </ul>
      <h2>How Does a Sales Recruiting Agency Find Candidates?</h2>
      <p>
        At Rainmakers, we don’t have to find candidates. We already have a pool of talent who are
        the best in their field, ready and waiting for job offers. Each has an in-depth sales
        profile showing sales stats and performance data. So when you contact us for help with
        recruiting, we can quickly identify candidates with the right skills and experience to fit
        your needs.
      </p>
      <p>
        Save time, reduce risk, and improve hiring success.{' '}
        <a href="https://www.rainmakers.co/employer-signup/">
          Create a free profile on Rainmakers now
        </a>{' '}
        and start browsing candidates.
      </p>
    </div>
  );
}
