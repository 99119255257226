import { all, fork } from 'redux-saga/effects';

import sagaRequisitionDetailsLoadStarted from './sagaRequisitionDetailsLoadStarted';
import sagaRequisitionCandidatesCreateStarted from './sagaRequisitionCandidatesCreateStarted';
import sagaMessageCreateStarted from './sagaMessageCreateStarted';

const sagaCandidate = function* sagaCandidate() {
  yield all([
    fork(sagaMessageCreateStarted),
    fork(sagaRequisitionDetailsLoadStarted),
    fork(sagaRequisitionCandidatesCreateStarted),
  ]);
};

export default sagaCandidate;
