import React from 'react';

import ReactMarkdown from 'react-markdown';

import styles from './CompanyAbout.scss';

const CompanyAbout = ({ about: origAbout }) => {
  const about = origAbout ? origAbout.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, '') : '';

  return about && about !== '' ? (
    <div className={styles.CompanyAbout}>
      <ReactMarkdown children={about} className={styles.markdown} />
    </div>
  ) : null;
};

export default CompanyAbout;
