import { all, fork } from 'redux-saga/effects';

import sagaBookmarksAdd from './sagaBookmarksAdd';
import sagaBookmarksRemove from './sagaBookmarksRemove';
import sagaSearch from './sagaSearch';
import sagaRemoveCandidatesFromSearch from './sagaRemoveCandidatesFromSearch';
import sagaAdminSearchCandidateHideStarted from './sagaAdminSearchCandidateHideStarted';
import sagaSearchCandidateHideStarted from './sagaSearchCandidateHideStarted';
import sagaSearchCandidateUndoHideStarted from './sagaSearchCandidateUndoHideStarted';

const sagaCandidateSearch = function* sagaCandidateSearch() {
  yield all([
    fork(sagaBookmarksAdd),
    fork(sagaBookmarksRemove),
    fork(sagaSearch),
    fork(sagaRemoveCandidatesFromSearch),
    fork(sagaAdminSearchCandidateHideStarted),
    fork(sagaSearchCandidateHideStarted),
    fork(sagaSearchCandidateUndoHideStarted),
  ]);
};

export default sagaCandidateSearch;
