import { createStructuredSelector } from 'reselect';

import {
  entitySelectorFactory,
  loadingSelectorFactory,
  pageSelectorFactory,
  totalSelectorFactory,
} from 'reduxStore/selectorFactories';

import {
  selectIsLoggedIn,
  selectIsAdmin,
  selectLogoutPending,
} from 'connectors/Login/redux/selectors';

import { selectUser } from 'connectors/User/redux/selectors';

const selector = createStructuredSelector({
  logoutPending: selectLogoutPending,
  user: selectUser,
  isLoggedIn: selectIsLoggedIn,
  isAdmin: selectIsAdmin,
  leads: entitySelectorFactory('toolboxLeads', 'lead'),
  page: pageSelectorFactory('toolboxLeads', 'lead'),
  total: totalSelectorFactory('toolboxLeads', 'lead'),
  isLoading: loadingSelectorFactory('toolboxLeads', 'lead'),
});

export { selector as default };
