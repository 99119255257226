/* eslint-disable no-unused-vars */
import camelCase from 'lodash.camelcase';
import hash from 'object-hash';

import { trackEvent } from 'utils/analytics';
import { segmentSearchEmployee, segmentAddSavedSearchEmployee } from 'segment/eventNames';
import { handleError } from 'utils/common';

export function flattenLocations(locOptions) {
  if (!locOptions) return [];

  let locOptionsFlat = [];

  for (const loc of locOptions) {
    if (loc.id) {
      locOptionsFlat.push(loc);
    }

    if (Array.isArray(loc.subcategories) && loc.subcategories.length > 0) {
      locOptionsFlat = [...locOptionsFlat, ...loc.subcategories.filter((sub) => sub.id)];
    }
  }

  return locOptionsFlat;
}

export function flattenAdditionalFilters(roleObj) {
  return roleObj?.additionalFilters?.reduce((acc, curr) => [...acc, ...curr.filters], []) || [];
}

export function makeKeywordsQueries(filter, keywordsArr) {
  if (!Array.isArray(filter?.queries) || !Array.isArray(keywordsArr)) return [];

  const value = encodeURIComponent(keywordsArr.toString());
  return [filter.queries.toString().replace('<value>', value)];
}

export function composeQueryString({
  isAdmin,
  allSearchParams,
  moreFilters,
  sideFilters,
  employerId,
}) {
  const allSearchParamsQueries = allSearchParams?.map((param) => param.queries.join('&')).join('&');

  const moreFiltersQueries = moreFilters?.map((filter) => filter.queries.join('&')).join('&');

  const sideFiltersQueries = sideFilters?.map((filter) => filter.queries.join('&')).join('&');

  const employerIdParam = isAdmin && employerId && `filter[simulated_employer_id]=${employerId}`;
  const categoryString = 'filter[category]=search';

  const output = [
    allSearchParamsQueries,
    employerIdParam,
    moreFiltersQueries,
    sideFiltersQueries,
    categoryString,
  ]
    .filter(Boolean)
    .join('&');

  return output;
}

export function composeQueryStringEx({
  isAdmin,
  allSearchParams,
  employerId,
  moreFilters,
  sideFilters,
  place,
}) {
  const queryObj = {};

  for (const filter of moreFilters) {
    queryObj[filter.id] = {
      value: filter.value,
    };
  }

  for (const filter of sideFilters) {
    queryObj[filter.id] = {
      value: filter.value,
    };
  }

  for (const filter of allSearchParams) {
    queryObj[filter.id] = {
      value: filter.value,
    };
  }

  if (isAdmin && employerId) {
    queryObj.simulated_employer_id = {
      value: employerId,
    };
  }

  if (place) {
    queryObj.place = {
      value: place,
    };
  }

  return queryObj;
}

export function deconstructQueryStringOld(querystring, candidateSearchOptions, defaultRoleId) {
  const { role: roleOptions, locations: locOptions } = candidateSearchOptions;

  const locOptionsFlat = flattenLocations(locOptions);

  // look for the role in querystring:

  const roleId =
    roleOptions.find((option) => option.queries.every((query) => querystring.includes(query)))
      ?.id || defaultRoleId;

  // look for locations in querystring:

  const locations = [];
  for (const param of locOptionsFlat) {
    if (param.queries.every((query) => querystring.includes(query))) {
      locations.push(param.id);
    }
  }

  // look for more filters

  const moreFilterOptions = flattenAdditionalFilters(
    candidateSearchOptions.role.find((item) => item.id === roleId)
  );

  const moreFilters = [];
  for (const param of moreFilterOptions) {
    if (param.queries.every((query) => querystring.includes(query))) {
      moreFilters.push({ ...param, value: true });
    }
  }

  // keywords are saved with just `filter[keywords]=blabla&filter[keywords]=blabla2` so need to treat it specially

  // find keywords values in the query string

  const keywordsQuery = querystring.split('&').find((str) => str.startsWith('filter[keywords]='));

  if (keywordsQuery) {
    const keywordsValueArr = decodeURIComponent(keywordsQuery.split('=')[1]).split(',');

    // find the first keywords filter under this role
    // (if there are two keywords filters under the role then we just take the first one since we cannot distinguish it):

    const keywordsFilter = moreFilterOptions.find((option) => option.type === 'keywords');

    // add keywords to the more filters array:

    moreFilters.push({
      ...keywordsFilter,
      value: keywordsValueArr,
      queries: makeKeywordsQueries(keywordsFilter, keywordsValueArr),
      trackingHash: {
        keywords: keywordsValueArr,
      },
    });
  }

  const allSearchParams = [
    ...roleOptions
      .filter((option) => option.id === roleId)
      .map((item) => ({ ...item, filter: 'role', label: item.name, value: true })),
    ...locOptionsFlat
      .filter((option) => locations.includes(option.id))
      .map((item) => ({ ...item, filter: 'locations', label: item.name, value: true })),
  ];

  return {
    allSearchParams, // full filters data
    role: roleId, // id
    locations, // ids only
    moreFilters, // full filters data
    sideFilters: [],
    sideFiltersData: getDefaultSideFiltersData(),
  };
}

export function deconstructQueryStringEx(
  querystring,
  candidateSearchOptions,
  candidateSearchOptionsProps,
  defaultRoleParams
) {
  const { role: roleOptionsProps, locations: locOptionsProps } = candidateSearchOptions;

  const { role: roleOptionsPassed, locations: locOptionsPassed } = candidateSearchOptionsProps;

  const roleOptions = roleOptionsProps || roleOptionsPassed;
  const locOptions = locOptionsProps || locOptionsPassed;

  const locOptionsFlat = flattenLocations(locOptions);

  const output = {
    allSearchParams: [defaultRoleParams],
    moreFilters: [],
    sideFilters: [],
    sideFiltersData: {},
    locations: [],
    role: defaultRoleParams.id,
  };

  let queryObj = {};

  try {
    queryObj = typeof querystring === 'string' ? JSON.parse(querystring) : querystring;
  } catch (err) {
    console.error('Cannot parse JSON query string', err);
    handleError('Cannot parse JSON query string');

    return output;
  }

  if (!queryObj) {
    return output;
  }

  const queryFilterIds = Object.keys(queryObj);

  for (const id of queryFilterIds) {
    if (roleOptions.find((option) => option.id === id)) {
      output.role = id;
    } else if (locOptionsFlat.find((option) => option.id === id)) {
      output.locations.push(id);
    }
  }

  const additionalFilters = flattenAdditionalFilters(
    roleOptions.find((option) => option.id === output.role)
  );

  output.moreFilters = additionalFilters
    .filter(({ filter, id }) => queryFilterIds.includes(id))
    .map((filter) => ({ ...filter, value: queryObj[filter.id].value }))
    .map((filter) =>
      filter.type === 'keywords'
        ? {
            ...filter,
            queries: makeKeywordsQueries(filter, queryObj[filter.id].value),
            trackingHash: {
              keywords: queryObj[filter.id].value,
            },
          }
        : filter
    );

  output.sideFiltersData = restoreSideFiltersData(queryObj);
  output.sideFilters = buildSideFilters(output.sideFiltersData);

  output.allSearchParams = [
    ...roleOptions
      .filter((option) => output.role === option.id)
      .map((item) => ({ ...item, filter: 'role', label: item.name, value: true })),
    ...locOptionsFlat
      .filter((option) => output.locations.includes(option.id))
      .map((item) => ({ ...item, filter: 'locations', label: item.name, value: true })),
  ];

  return output;
}

function getTrackProperties({ moreFilters, sideFilters, allSearchParams }) {
  const moreFilterTrackProperties = moreFilters.map((filt) => filt.trackingHash);

  const sideFilterTrackProperties = sideFilters.map((filt) => filt.trackingHash);

  const mainFilterTrackProperties = allSearchParams.map((filt) => filt.trackingHash);

  const allFilterTrackProperties = Array.prototype.concat(
    moreFilterTrackProperties,
    sideFilterTrackProperties,
    mainFilterTrackProperties
  );

  const trackProperties = {};

  allFilterTrackProperties.forEach((obj) => {
    if (obj) {
      Object.keys(obj).forEach((key) => {
        if (Array.isArray(obj[key]) && trackProperties[camelCase(key)]) {
          trackProperties[camelCase(key)] = Array.prototype.concat(
            trackProperties[camelCase(key)],
            obj[key]
          );
        } else {
          trackProperties[camelCase(key)] = obj[key];
        }
      });
    }
  });

  return trackProperties;
}

export function trackSearchViewEvent({
  total,
  allSearchParams,
  moreFilters,
  sideFilters,
  searchActivatedId,
}) {
  const trackProperties = getTrackProperties({ moreFilters, sideFilters, allSearchParams });

  trackEvent({
    event: segmentSearchEmployee,
    properties: {
      ...trackProperties,
      hashId: hash(trackProperties),
      resultsCount: total,
      savedSearchId: searchActivatedId,
    },
  });
}

export function trackSaveSearchEvent({
  saveSearchTitle: title,
  allSearchParams,
  moreFilters,
  sideFilters,
}) {
  const trackProperties = getTrackProperties({ moreFilters, sideFilters, allSearchParams });

  trackEvent({
    event: segmentAddSavedSearchEmployee,
    properties: {
      ...trackProperties,
      hashId: hash(trackProperties),
      title,
    },
  });
}

export function loadNewResults({
  isAdmin,
  actions: { resultsLoadDone, resultsLoadStarted, setSearchParams },
  noTrack = false,
  moreFilters = [],
  sideFilters = [],
  allSearchParams,
  employerId,
  searchResultsContainerRef,
}) {
  const allParamsForSearch = composeQueryString({
    isAdmin,
    allSearchParams,
    employerId,
    moreFilters,
    sideFilters,
  });

  handleScrollToTop(searchResultsContainerRef);

  const trackProperties = getTrackProperties({
    moreFilters,
    sideFilters,
    allSearchParams,
  });

  const searchParams = {
    trackProperties,
  };

  setSearchParams({
    searchParams,
    searchString: allParamsForSearch,
  });

  resultsLoadStarted({
    allSearchParams: allParamsForSearch,
    noTrack,
    searchProperties: searchParams,
    employerId,
  });
}

export function loadMoreResults({
  isAdmin,
  actions: { resultsLoadStarted },
  candidatesAllIds: allIds,
  employerId,
  total,
  allSearchParams,
  moreFilters,
  sideFilters,
}) {
  const allParamsForSearch = composeQueryString({
    isAdmin,
    allSearchParams,
    employerId,
    moreFilters,
    sideFilters,
  });

  const hasMore = allIds.length > 0 && total > allIds.length;

  const latestCandidateId = allIds.length > 0 ? allIds[allIds.length - 1] : undefined;

  if (hasMore) {
    resultsLoadStarted({
      noTrack: true,
      allSearchParams: allParamsForSearch,
      latestCandidateId,
    });
  }
}

export function handleScrollToTop(searchResultsContainerRef) {
  if (searchResultsContainerRef.current) {
    searchResultsContainerRef.current.scrollTop = 0;
  }
}

export function getDefaultMoreFilters({ roleName, candidateSearchOptions, currentFilters }) {
  const roleItem = candidateSearchOptions.role.find((roleOption) => roleOption.name === roleName);

  if (
    !roleItem ||
    !Array.isArray(roleItem.additionalFilters) ||
    roleItem.additionalFilters.length === 0
  ) {
    return [];
  }

  const { additionalFilters = [] } = roleItem;

  // for whatever default filter we are setting, we need to check if there are
  // additional filters nested in it that also have defaults that need to be set
  // this was added when location filter became a deeply nested additional filter
  const defaultableFilters = additionalFilters
    .map((opt) => opt.filters.filter((nestedOpt) => nestedOpt.values))
    .filter((array) => array.length > 0);

  const flatDefaultableFilters = Array.prototype.concat(...defaultableFilters);

  const revisedFlatFilters = flatDefaultableFilters.filter((filt) => filt.title !== 'Location');

  const addMoreFilters = revisedFlatFilters
    .map((item) => {
      const defaultableItem = item.values.find((val) => val.default);
      if (defaultableItem) {
        let subValues = [];

        if (defaultableItem.subcategories) {
          subValues = defaultableItem.subcategories.map((subOpt) => ({
            label: subOpt.name,
            optionType: subOpt.type,
            id: subOpt.id,
            queries: subOpt.queries,
            title: item.title,
            tracking_hash: subOpt.tracking_hash,
            type: item.type,
          }));
        }
        return {
          ...defaultableItem,
          label: defaultableItem.name,
          optionType: defaultableItem.type,
          title: item.title,
          subcategories: subValues,
          type: item.type,
          id: item.id,
        };
      }

      return {};
    })
    .filter((obj) => obj);

  const defaultsWithSubs = addMoreFilters.reduce((acc, curr) => {
    if (curr.subcategories) {
      return Array.prototype.concat(acc, curr.subcategories, curr);
    }
    return acc.concat(curr);
  }, []);

  const defaultableCheckboxes = additionalFilters.map((opt) =>
    opt.filters.filter((nestedOpt) => nestedOpt.default)
  );
  const flatDefaultableCheckboxes = Array.prototype.concat(...defaultableCheckboxes);

  return [...currentFilters, ...defaultsWithSubs, ...flatDefaultableCheckboxes].filter(
    ({ type }) => type !== 'keywords'
  );
}

export function getDefaultSearchOptions(candidateSearchOptions, allSearchParams) {
  const state = {};
  let newMoreFilters = [];

  let newAllSearchParams = [...allSearchParams];

  Object.keys(candidateSearchOptions).forEach((key) => {
    const defaultOption = candidateSearchOptions[key].find((option) => option.default);

    newAllSearchParams = newAllSearchParams.filter((param) => param.filter !== key);

    if (defaultOption) {
      newAllSearchParams.push({
        label: defaultOption.name,
        filter: key,
        value: defaultOption.queries, // update this!
        id: defaultOption.id,
      });

      if (defaultOption.additionalFilters) {
        newMoreFilters = getDefaultMoreFilters({
          role: defaultOption.name,
          candidateSearchOptions,
          currentFilters: newMoreFilters,
        });
      }

      state[key] = key === 'locations' ? defaultOption.id : defaultOption.queries;
    } else {
      state[key] = [];
    }

    state.filtersLoaded = true;
  });

  state.moreFilters = newMoreFilters;
  state.allSearchParams = newAllSearchParams;

  return state;
}

export const getDefaultSideFiltersData = () => ({
  oteCentsFrom: null,
  oteCentsTo: null,
  salaryCentsFrom: null,
  salaryCentsTo: null,
  soldToIndustries: [],
  soldToDepartments: [],
  dealsAverageCentsFrom: null,
  dealsAverageCentsTo: null,
  quotaAmountCentsFrom: null,
  quotaAmountCentsTo: null,
  lat: null,
  lon: null,
  distance: null,
  place: null,
  keywords: [],
});

export function validateFilters(
  candidateSearchOptions,
  allSearchParams,
  moreFilters,
  sideFilters,
  sideFiltersData
) {
  if (!candidateSearchOptions)
    return {
      allSearchParams,
      moreFilters,
      sideFilters,
    };

  let validatedParams = [...allSearchParams];
  let validatedMoreFilters = [...moreFilters];
  const validatedSideFilters = [...sideFilters];
  const validatedSideFiltersData = { ...sideFiltersData };

  const currentLocations = validatedParams.filter((filt) => filt.filter === 'locations');

  if (currentLocations.length > 0) {
    const validLocationValues = flattenLocations(candidateSearchOptions.locations);
    const validLocationIds = validLocationValues.map(({ id }) => id);

    // remove all locations with unknown ids
    validatedParams = validatedParams.filter(
      ({ id, filter }) => filter !== 'locations' || validLocationIds.includes(id)
    );
  }

  const { id: roleId } = validatedParams.find(({ filter }) => filter === 'role') || {};

  if (roleId) {
    const { additionalFilters } = candidateSearchOptions.role.find(({ id }) => id === roleId);

    if (additionalFilters) {
      const validMoreFilters = additionalFilters.reduce(
        (acc, curr) => (Array.isArray(curr.filters) ? [...acc, ...curr.filters] : acc),
        []
      );

      const validMoreFiltersIds = validMoreFilters.map(({ id }) => id);

      // remove all more filters with unknown ids
      validatedMoreFilters = validatedMoreFilters.filter(({ id }) =>
        validMoreFiltersIds.includes(id)
      );
    }
  }

  return {
    allSearchParams: validatedParams,
    moreFilters: validatedMoreFilters,
    sideFilters: validatedSideFilters,
    sideFiltersData: validatedSideFiltersData,
  };
}

const toCents = (val) => (val || 0) * 100;

export function buildSideFilters({
  oteCentsFrom,
  oteCentsTo,
  salaryCentsFrom,
  salaryCentsTo,
  soldToIndustries = [],
  soldToDepartments = [],
  dealsAverageCentsFrom,
  dealsAverageCentsTo,
  quotaAmountCentsFrom,
  quotaAmountCentsTo,
  lat,
  lon,
  distance,
  keywords = [],
}) {
  const output = [];

  // OTE
  // ?filter[candidate_requirement.ote_cents][]=gte:15000000

  if (oteCentsFrom) {
    output.push({
      id: 'candidate_requirement.ote_cents_from',
      queries: [`filter[candidate_requirement.ote_cents][]=gte:${toCents(oteCentsFrom)}`],
      title: 'OTE Cents',
      trackingChange: {
        oteCents: true,
      },
      type: 'string',
      value: oteCentsFrom,
    });
  }

  if (oteCentsTo) {
    output.push({
      id: 'candidate_requirement.ote_cents_to',
      queries: [`filter[candidate_requirement.ote_cents][]=lte:${toCents(oteCentsTo)}`],
      title: 'OTE Cents',
      trackingChange: {
        oteCents: true,
      },
      type: 'string',
      value: oteCentsTo,
    });
  }

  // Salary
  // ?filter[candidate_requirement.salary_cents][]=gte:15000000

  if (salaryCentsFrom) {
    output.push({
      id: 'candidate_requirement.salary_cents_from',
      queries: [`filter[candidate_requirement.salary_cents][]=gte:${toCents(salaryCentsFrom)}`],
      title: 'Salary Cents',
      trackingChange: {
        salaryCents: true,
      },
      type: 'string',
      value: salaryCentsFrom,
    });
  }

  if (salaryCentsTo) {
    output.push({
      id: 'candidate_requirement.salary_cents_to',
      queries: [`filter[candidate_requirement.salary_cents][]=lte:${toCents(salaryCentsTo)}`],
      title: 'Salary Cents',
      trackingChange: {
        salaryCents: true,
      },
      type: 'string',
      value: salaryCentsTo,
    });
  }

  // Departments Sold To ("C-Level,Finance" example)
  // &filter[work_histories.sold_to_departments][]=C-Level&filter[work_histories.sold_to_departments][]=Finance

  if (soldToDepartments.length > 0) {
    output.push({
      id: 'work_histories.sold_to_departments',
      queries: soldToDepartments.map(
        (dept) => `filter[work_histories.sold_to_departments][]=${dept}`
      ),
      title: 'Sold To Departments',
      trackingChange: {
        soldToDepartments: true,
      },
      type: 'multiselect',
      value: soldToDepartments,
    });
  }

  // Industries Sold To (Education, Entertainment example)
  // &filter[work_histories.sold_to_industries][]=Education&filter[work_histories.sold_to_industries][]=Entertainment

  if (soldToIndustries.length > 0) {
    output.push({
      id: 'work_histories.sold_to_industries',
      queries: soldToIndustries.map(
        (indst) => `filter[work_histories.sold_to_industries][]=${indst}`
      ),
      title: 'Sold To Industries',
      trackingChange: {
        soldToIndustries: true,
      },
      type: 'multiselect',
      value: soldToIndustries,
    });
  }

  // Deal Size
  // filter[work_histories.sales_periods.deals_average_cents][]=gte:500005500

  if (dealsAverageCentsFrom) {
    output.push({
      id: 'work_histories.sales_periods.deals_average_cents_from',
      queries: [
        `filter[work_histories.sales_periods.deals_average_cents][]=gte:${toCents(
          dealsAverageCentsFrom
        )}`,
      ],
      title: 'Deals Average Cents',
      trackingChange: {
        dealsAverageCents: true,
      },
      type: 'string',
      value: dealsAverageCentsFrom,
    });
  }

  if (dealsAverageCentsTo) {
    output.push({
      id: 'work_histories.sales_periods.deals_average_cents_to',
      queries: [
        `filter[work_histories.sales_periods.deals_average_cents][]=lte:${toCents(
          dealsAverageCentsTo
        )}`,
      ],
      title: 'Deals Average Cents',
      trackingChange: {
        dealsAverageCents: true,
      },
      type: 'string',
      value: dealsAverageCentsTo,
    });
  }

  // Quota
  // filter[work_histories.sales_periods.quota_amount_cents][]=gte:500005500

  if (quotaAmountCentsFrom) {
    output.push({
      id: 'work_histories.sales_periods.quota_amount_cents_from',
      queries: [
        `filter[work_histories.sales_periods.quota_amount_cents][]=gte:${toCents(
          quotaAmountCentsFrom
        )}`,
      ],
      title: 'Quota Amount Cents',
      trackingChange: {
        quotaAmountCents: true,
      },
      type: 'string',
      value: quotaAmountCentsFrom,
    });
  }

  if (quotaAmountCentsTo) {
    output.push({
      id: 'work_histories.sales_periods.quota_amount_cents_to',
      queries: [
        `filter[work_histories.sales_periods.quota_amount_cents][]=lte:${toCents(
          quotaAmountCentsTo
        )}`,
      ],
      title: 'Quota Amount Cents',
      trackingChange: {
        quotaAmountCents: true,
      },
      type: 'string',
      value: quotaAmountCentsTo,
    });
  }

  // Latitude
  // filter[locations.lat][]=40.7128

  if (lat) {
    output.push({
      id: 'locations.lat',
      queries: [`filter[locations.lat][]=${lat}`],
      title: 'Latitude',
      trackingChange: {
        lat: true,
      },
      type: 'string',
      value: lat,
    });
  }

  // Longitude
  // filter[locations.lon][]=-74.0060

  if (lon) {
    output.push({
      id: 'locations.lon',
      queries: [`filter[locations.lon][]=${lon}`],
      title: 'Longitude',
      trackingChange: {
        lon: true,
      },
      type: 'string',
      value: lon,
    });
  }

  // Distance
  // filter[locations.distance][]=50mi

  if (!distance) {
    distance = 100;
  }

  if (lon && lat) {
    output.push({
      id: 'locations.distance',
      queries: [`filter[locations.distance][]=${distance}mi`],
      title: 'Distance',
      trackingChange: {
        distance: true,
      },
      type: 'string',
      value: distance,
    });
  }

  // keywords

  if (keywords.length > 0) {
    output.push({
      id: 'sdrfilterkeywords',
      queries: keywords.map((keyword) => `filter[keywords]=${keyword}`),
      title: 'Keywords(s)',
      trackingChange: {
        sdrfilterkeywords: true,
      },
      type: 'keywords',
      value: keywords,
    });
  }

  return output;
}

function restoreSideFiltersData(data) {
  return {
    oteCentsFrom: data['candidate_requirement.ote_cents_from']?.value || null,
    oteCentsTo: data['candidate_requirement.ote_cents_to']?.value || null,
    salaryCentsFrom: data['candidate_requirement.salary_cents_from']?.value || null,
    salaryCentsTo: data['candidate_requirement.salary_cents_to']?.value || null,
    soldToIndustries: data['work_histories.sold_to_industries']?.value || [],
    soldToDepartments: data['work_histories.sold_to_departments']?.value || [],
    dealsAverageCentsFrom:
      data['work_histories.sales_periods.deals_average_cents_from']?.value || null,
    dealsAverageCentsTo: data['work_histories.sales_periods.deals_average_cents_to']?.value || null,
    quotaAmountCentsFrom:
      data['work_histories.sales_periods.quota_amount_cents_from']?.value || null,
    quotaAmountCentsTo: data['work_histories.sales_periods.quota_amount_cents_to']?.value || null,
    lat: data['locations.lat']?.value || null,
    lon: data['locations.lon']?.value || null,
    distance: data['locations.distance']?.value || null,
    place: data.place?.value || null,
    // eslint-disable-next-line dot-notation
    keywords: data['sdrfilterkeywords']?.value || [],
  };
}
