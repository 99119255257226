import { put, take } from 'redux-saga/effects';

import { userSave } from 'connectors/User/redux/creators';

import { CANDIDATE_LOAD_DONE } from '../actions';

const sagaCandidateLoadDone = function* sagaCandidateLoadDone() {
  while (true) {
    const { payload: user } = yield take(CANDIDATE_LOAD_DONE);

    yield put(userSave({ user }));
  }
};

export default sagaCandidateLoadDone;
