import React from 'react';

import MikeImage from 'shared/assets/rm-logo-short.png';
import styles from './Greeting.scss';

const Greeting = () => (
  <div className={styles.welcomeBottom}>
    <div className={styles.greetingContainer}>
      <div className={styles.mikeImage}>
        <img src={MikeImage} alt="Michael Ferguson" />
      </div>
      <div className={styles.mikeGreeting}>Thank you for joining Rainmakers</div>
      <div className={styles.mikeWelcome}>
        Let us know if you have any questions about your account or the Rainmakers process
        <span className={styles.mikeEmail}>
          <a href="mailto:support@rainmakers.co?subject=Rainmakers Support">
            support@rainmakers.co
          </a>
        </span>
      </div>
    </div>
  </div>
);

export default Greeting;
