import { postEntity } from 'api/apiEntity';

const promiseBookmarksAdd = ({ candidateId }) => {
  const onFulfilled = (response) => response;

  const args = {
    type: 'bookmarks',
    data: {
      type: 'bookmark',
      attributes: {
        candidate_id: candidateId,
      },
    },
    config: {
      params: {
        camelizeIt: true,
      },
    },
  };

  return postEntity(args).then(onFulfilled);
};

export default promiseBookmarksAdd;
