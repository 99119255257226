import React, { useEffect, useMemo, useState } from 'react';

import DropdownSelectMany from 'components/DropdownSelectMany';
import { MarginContainer } from 'components/MarginContainer';
import Label from 'components/Label';

export const DropdownView = ({ label, name, selectedValues, getOptions, handleChange }) => {
  const [optionValues, setOptionValues] = useState([]);

  useEffect(() => {
    getOptions().then(setOptionValues);
  }, [getOptions]);

  const options = useMemo(
    () =>
      optionValues.map((value) => ({
        indent: 0,
        label: value,
        optionType: 'value',
        subCategories: [],
        value: {
          type: 'value',
          value,
        },
      })),
    [optionValues]
  );

  const selectedOptions = selectedValues.map((value) => ({ value, type: 'value' }));

  return (
    <>
      <MarginContainer bottom="10">
        <Label>{label}</Label>
      </MarginContainer>
      <DropdownSelectMany
        defaultIcon={'caret-down'}
        activeIcon={'caret-up'}
        onChange={(data) => {
          const newSelect = data?.value?.value;

          const value = selectedValues.includes(newSelect)
            ? selectedValues.filter((val) => val !== newSelect)
            : [...selectedValues, newSelect];

          handleChange(name, value);
        }}
        optionsLarge={false}
        source={options}
        name={name}
        isLocation={true}
        selectedValues={selectedOptions || []}
        defaultLabel="Click to select"
      />
    </>
  );
};
