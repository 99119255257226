import { createStructuredSelector } from 'reselect';

import { selectUser } from 'connectors/User/redux/selectors';
import {
  selectIsAdmin,
  selectIsCandidate,
  selectIsEmployee,
} from 'connectors/Login/redux/selectors';

const selector = createStructuredSelector({
  user: selectUser,
  isAdmin: selectIsAdmin,
  isCandidate: selectIsCandidate,
  isEmployee: selectIsEmployee,
});

export { selector as default };
