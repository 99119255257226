import React from 'react';

import FormattedArray from 'components/FormattedArray';
import styles from './CompanyPress.scss';

const CompanyPress = ({ links }) => {
  const mappedLinks = links
    ? links.map((link) => {
        const formattedLink = link.includes('http') ? link : `http://${link}`;

        return (
          <div title={formattedLink} key={formattedLink} className={styles.pressLink}>
            <a href={formattedLink} rel="noreferrer" target="_blank">
              {link}
            </a>
          </div>
        );
      })
    : null;

  const props = {
    array: mappedLinks,
  };

  const content =
    links && links.length > 0 ? (
      <div className={styles.CompanyPress}>
        <FormattedArray {...props} />
      </div>
    ) : null;

  return content;
};

export default CompanyPress;
