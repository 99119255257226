import React from 'react';

import Table from 'components/Table';
import TableRow from 'components/Table/components/TableRow';
import TableCell from 'components/Table/components/TableCell';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import FormattedDate from 'components/FormattedDate';

import styles from './ToolboxLeadsTable.scss';

const ToolboxLeadsTable = ({ isLoading, leads, total, loadMoreLeads }) => {
  const tableHeader = [
    {
      content: 'Email',
      headerClassName: styles.emailHeader,
    },
    {
      content: 'First Name',
      headerClassName: styles.nameHeader,
    },
    {
      content: 'Last Name',
      headerClassName: styles.nameHeader,
    },
    {
      content: 'Created At',
      headerClassName: styles.createdAtHeader,
    },
  ];

  const makeLeadsCells = (lead) => {
    const {
      attributes: { email, firstName, lastName, createdAt },
    } = lead || {};

    const emailContent = <div className={styles.emailContent}>{email}</div>;

    const firstNameContent = <div className={styles.nameContent}>{firstName}</div>;

    const lastNameContent = <div className={styles.nameContent}>{lastName}</div>;

    const createdAtContent = (
      <div className={styles.createdAtContent}>
        <FormattedDate parse date={createdAt} />
      </div>
    );

    const cells = [
      <TableCell key={1}>{emailContent}</TableCell>,
      <TableCell key={2}>{firstNameContent}</TableCell>,
      <TableCell key={3}>{lastNameContent}</TableCell>,
      <TableCell key={4}>{createdAtContent}</TableCell>,
    ];

    return cells;
  };

  const makeLeadsRows = (lead) => (
    <TableRow id={lead.id} key={lead.id}>
      {makeLeadsCells(lead)}
    </TableRow>
  );

  const tableContent = leads.map(makeLeadsRows);

  const infiniteScrollProps = {
    loadMore: loadMoreLeads,
    hasMore: total > leads.length,
    loader: isLoading ? (
      <div key="infiniteScrollLoader" />
    ) : (
      <InfiniteScrollLoader key="infiniteScrollLoader" />
    ),
    useWindow: false,
  };

  const tableProps = {
    tableContent,
    header: tableHeader,
    infiniteScrollProps,
    tableName: 'adminLeads',
    isEmpty: !isLoading && leads.length === 0,
    emptyTextString: 'No Leads',
    isLoading,
  };

  return <Table {...tableProps} />;
};

export default ToolboxLeadsTable;
