import { call, select, put, take } from 'redux-saga/effects';

import { entitySelectorFactory } from 'reduxStore/selectorFactories';

import { handleError } from 'utils/common';
import { trackEvent } from 'utils/analytics';
import { segmentHideCandidateEmployee } from 'segment/eventNames';

import { postEntity } from 'api/apiEntity';

import { setNeedReload } from 'pages/EmployeePortal/CandidateProfilePages/redux/creators';

import { SEARCH_CANDIDATE_HIDE_STARTED } from '../actions';

import { resultsUpdate } from '../creators';

const trackHideCandidate = (candidateId, blockType, firstName, lastName) => {
  const event = segmentHideCandidateEmployee;

  const properties = {
    blockType,
    candidateId,
    candidateFirstName: firstName,
    candidateLastName: lastName,
  };

  const eventObject = { event, properties };

  trackEvent(eventObject);
};

const sagaSearchCandidateHideStarted = function* sagaSearchCandidateHideStarted() {
  while (true) {
    const {
      payload: { candidateId, firstName, lastName },
    } = yield take(SEARCH_CANDIDATE_HIDE_STARTED);

    const { byId, allIds } = yield select(entitySelectorFactory('employerSearch', 'candidate'));

    try {
      const newById = {
        ...byId,
        [candidateId]: {
          ...byId[candidateId],
          attributes: {
            ...byId[candidateId].attributes,
            hide: true,
            bookmark: null,
          },
        },
      };

      yield put(resultsUpdate({ type: 'candidate', entity: { allIds, byId: newById } }));

      const args = {
        type: 'candidate_company_blocks',
        data: {
          type: 'candidate_company_block',
          attributes: {
            candidateId,
            blockType: 'search_pass',
          },
        },
        config: {
          params: {
            camelizeIt: true,
          },
        },
      };

      const {
        data: { candidateCompanyBlock: { id: candidateCompanyBlockId } = {} } = {},
      } = yield call(postEntity, args);

      yield call(trackHideCandidate, candidateId, 'search_pass', firstName, lastName);

      const { byId: latestById, allIds: latestAllIds } = yield select(
        entitySelectorFactory('employerSearch', 'candidate')
      );

      const newestById = {
        ...latestById,
        [candidateId]: {
          ...latestById[candidateId],
          attributes: {
            ...latestById[candidateId].attributes,
            hide: true,
            bookmark: null,
            candidateCompanyBlockId,
          },
        },
      };

      yield put(
        resultsUpdate({ type: 'candidate', entity: { allIds: latestAllIds, byId: newestById } })
      );
    } catch (error) {
      handleError(error);
      yield put(resultsUpdate({ type: 'candidate', entity: { allIds, byId } }));

      yield put(setNeedReload());
    }
  }
};

export default sagaSearchCandidateHideStarted;
