import { patchEntity } from 'api/apiEntity';

const promisePatchRequisitionOpening = ({ reqId, newValue, name }) => {
  const onFulfilled = (response) => {
    const {
      data: { requisition_opening: reqDetail },
    } = response;

    return reqDetail;
  };

  const args = {
    id: reqId,
    type: 'requisition_openings',
    data: {
      type: 'requisition_opening',
      id: reqId,
      attributes: {
        [name]: newValue,
      },
    },
  };

  return patchEntity(args).then(onFulfilled);
};

export default promisePatchRequisitionOpening;
