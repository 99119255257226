import React from 'react';
import classnames from 'classnames';

import { toCents, toDollars } from 'utils/moneyUtils';

import Input from 'components/Input';
import Label from 'components/Label';

import styles from '../ReqDetail.scss';

const BaseSalary = ({
  requisitionOpening: { attributes: { baseSalaryMinCents, baseSalaryMaxCents, state } = {} } = {},
  errors: {
    baseSalaryMinCents: baseSalaryMinCentsErrors = {},
    baseSalaryMaxCents: baseSalaryMaxCentsErrorsOrig = {},
  } = {},
  handleInputChange,
}) => {
  const baseRangeValid =
    baseSalaryMinCents && baseSalaryMaxCents ? baseSalaryMaxCents >= baseSalaryMinCents : null;

  const baseSalaryMaxCentsErrors = {
    ...baseSalaryMaxCentsErrorsOrig,
    isMoneyGreaterThanX: [baseRangeValid, baseSalaryMinCents],
  };

  const commonTextInputProps = {
    className: styles.inputContainerNoPad,
    type: 'text',
    size: 'full',
    moneyInput: true,
    disabled: state === 'closed',
    handleInputChange: (event) =>
      handleInputChange({
        ...event,
        target: {
          ...event.target,
          value: toCents(event.target.value),
          name: event.target.name,
        },
      }),
  };

  const baseSalaryMinCentsInputProps = {
    ...commonTextInputProps,
    value: baseSalaryMinCents || baseSalaryMinCents === 0 ? toDollars(baseSalaryMinCents) : '',
    name: 'baseSalaryMinCents',
    salaryInputOne: true,
    errors: baseSalaryMinCents ? baseSalaryMinCentsErrors : {},
  };

  const baseSalaryMaxCentsInputProps = {
    ...commonTextInputProps,
    value: baseSalaryMaxCents || baseSalaryMaxCents === 0 ? toDollars(baseSalaryMaxCents) : '',
    name: 'baseSalaryMaxCents',
    salaryInputTwo: true,
    errors: baseSalaryMaxCents && baseSalaryMinCents ? baseSalaryMaxCentsErrors : {},
  };

  const labelClasses = classnames(styles.labelStyle);

  const labelContent = (
    <Label required={state !== 'closed'} standalone className={labelClasses}>
      Base Salary Range
    </Label>
  );

  return (
    <div className={styles.rangeInputs}>
      {labelContent}
      <div className={styles.rangeInputsContainer}>
        <Input {...baseSalaryMinCentsInputProps} />
        <div className={styles.rangeSpan}>to</div>
        <Input {...baseSalaryMaxCentsInputProps} />
      </div>
    </div>
  );
};

export default BaseSalary;
