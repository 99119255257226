import { getEntity } from 'api/apiEntity';

const promiseGetCohort = ({ cohortId }) => {
  const onFulfilled = (response) => {
    const {
      data: { cohort = {} },
    } = response;

    return cohort;
  };

  const args = {
    type: 'cohorts',
    id: cohortId,
  };

  return getEntity(args).then(onFulfilled);
};

export default promiseGetCohort;
