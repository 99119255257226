import React, { useCallback, useEffect } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import queryString from 'query-string';

import { applyStarted, saveUTMInfo } from 'pages/CandidatePortal/OnboardingPages/redux/creators';
import duck from 'pages/CandidatePortal/OnboardingPages/redux';

import { redirectHomepage } from 'utils/navHelpers/index';

import ConnectContainer from 'containers/ConnectContainer';
import { VerticalDelimiter } from 'components/VerticalDelimiter';
import ApplyForm from 'components/ApplyForm';

import NotFound from 'pages/UtilPages/NotFound';
import Footer from 'pages/CandidatesHome/components/Footer';

import { TopMarketsLinks } from 'pages/LocationsPage/components';
import { JobsLinks } from 'pages/JobsPage/components';

import pageMetaData from './marketsJobsPageMetaData';

import styles from './MarketsJobsPage.scss';

const onSubmit = async (values, dispatch) => {
  const { firstName, lastName, email, linkedin, password, phone } = values;

  const applyData = {
    firstName,
    lastName,
    email,
    linkedinUrl: linkedin,
    password,
    phone,
  };

  dispatch(applyStarted({ applyData }));
};

const MarketsJobsPage = (props) => {
  const location = useLocation();
  const { search } = location;
  const dispatch = useDispatch();
  const { market = 'main', role = 'main' } = useParams();
  const page = `${market}/${role}`;

  const { user, hasSeenApproved, isApproved } = props;
  const redirectPath = redirectHomepage(user, isApproved, hasSeenApproved, false);

  useEffect(() => {
    if (search) {
      const utmInfo = queryString.parse(search);
      dispatch(saveUTMInfo({ utmInfo }));
    }
  }, [search, dispatch]);

  const handleSubmit = useCallback(
    async (values) => {
      onSubmit(values, dispatch);
    },
    [dispatch]
  );

  if (!Object.keys(pageMetaData).includes(page)) {
    return <NotFound />;
  }

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  return (
    <>
      <Helmet
        title={pageMetaData[page].title}
        meta={[
          { name: 'description', content: pageMetaData[page].description },
          { property: 'og:url', content: pageMetaData[page].url },
          { property: 'og:title', content: pageMetaData[page].title },
          { property: 'og:description', content: pageMetaData[page].description },
        ]}
        link={[{ rel: 'canonical', href: pageMetaData[page].url }]}
      />
      <div className={styles.outerWrapper}>
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <div className={styles.content}>
              <div className={styles.topHeader}>
                <h1>{pageMetaData[page].header}</h1>
                <VerticalDelimiter />
              </div>
              {React.createElement(pageMetaData[page].content)}
            </div>
            <div className={styles.applyForm}>
              <ApplyForm handleSubmit={handleSubmit} />
              <div className={styles.topMarketsLinksWrapper}>
                <TopMarketsLinks />
              </div>
              <div className={styles.topMarketsLinksWrapper}>
                <JobsLinks />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ConnectContainer(duck)(MarketsJobsPage);
