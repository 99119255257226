import { call, put, select, take } from 'redux-saga/effects';
import camelCase from 'lodash.camelcase';
import isEmpty from 'lodash.isempty';

import { handleError } from 'utils/common';

import { postSignIn } from 'api/apiAuth';

import { patchEntity } from 'api/apiEntity';

import { employeeLoginDone, removeRequestInterceptor } from 'shared/store/app/creators';

import { selectUser } from 'connectors/User/redux/selectors';

import { EMAIL_CHANGE_STARTED } from '../actions';

import {
  userUpdate,
  userPatchDone,
  employeeSaveStarted,
  employeeSaveDone,
  employeeSaveError,
} from '../creators';

const sagaEmailChangeStarted = function* sagaEmailChangeStarted(browserHistory) {
  while (true) {
    const {
      payload: { newEmail, password },
    } = yield take(EMAIL_CHANGE_STARTED);

    const { email: originalEmail } = yield select(selectUser);

    const name = 'email';
    const cleanEmail = newEmail.trim();

    yield put(userUpdate({ newValue: cleanEmail, name }));
    yield put(employeeSaveStarted());

    const userObject = yield select(selectUser);

    if (userObject.id) {
      try {
        const loginArgs = {
          data: {
            password,
            email: originalEmail.trim(),
          },
        };

        yield call(postSignIn, loginArgs);

        try {
          const args = {
            data: {
              attributes: {
                [name]: cleanEmail,
              },
              id: userObject.id,
              type: 'users',
            },
            config: {
              params: {
                camelizeIt: true,
              },
            },
            type: 'users',
            id: userObject.id,
          };

          const {
            data: {
              user: { [name]: newValue },
            },
          } = yield call(patchEntity, args);

          yield put(userPatchDone({ name, newValue }));

          const loginArgs = {
            data: {
              password,
              email: newEmail.trim(),
            },
            config: {
              params: {
                includeSet: 'login',
                normalizeIt: true,
              },
            },
          };

          const {
            data: { entities = {}, entities: { user: { byId = {}, allIds = [] } = {} } = {} } = {},
            headers,
          } = yield call(postSignIn, loginArgs);

          const {
            [allIds[0]]: { attributes: userAttributes },
          } = byId;

          const { currentProfileType } = userAttributes;

          const type = camelCase(currentProfileType);

          const { [type]: { byId: profileById = {}, allIds: profileAllIds = [] } = {} } = entities;

          const { [profileAllIds[0]]: { attributes: currentProfile } = {} } = profileById || {};

          const fullUser = {
            ...userAttributes,
            currentProfile,
          };

          if (!isEmpty(currentProfile)) {
            yield put(removeRequestInterceptor());

            const { pathname, search } = browserHistory.location;

            const autoLoginPath = { pathname, search };

            const loginDoneArgs = {
              user: fullUser,
              headers,
              autoLoginPath,
            };

            yield put(employeeLoginDone(loginDoneArgs));

            yield put(employeeSaveDone());
          }
        } catch (error) {
          handleError(error);

          const {
            response: {
              data: { errors: { full_messages: errors = ['Please try again'] } = {} } = {},
            } = {},
          } = error || {};

          yield put(userUpdate({ newValue: originalEmail, name }));
          yield put(employeeSaveError(errors[0]));
        }
      } catch (error) {
        handleError(error);

        const {
          response: {
            data: { errors: { full_messages: errors = ['Please try again'] } = {} } = {},
          } = {},
        } = error || {};

        yield put(userUpdate({ newValue: originalEmail, name }));
        yield put(employeeSaveError(errors[0]));
      }
    } else {
      yield put(employeeSaveDone());
    }
  }
};

export default sagaEmailChangeStarted;
