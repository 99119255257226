import { getEntity } from 'api/apiEntity';

const promiseGetEducationOrganization = (id) => {
  const onFulfilled = (response) => {
    const {
      data: { educationOrganization = {} },
    } = response;

    return educationOrganization;
  };

  const args = {
    type: 'education_organizations',
    id,
    config: {
      params: {
        includeSet: 'unverified_data',
        camelizeIt: true,
      },
    },
  };

  return getEntity(args).then(onFulfilled);
};

export default promiseGetEducationOrganization;
