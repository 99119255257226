import { createStructuredSelector } from 'reselect';

import {
  entitySelectorFactory,
  loadingSelectorFactory,
  pageSelectorFactory,
  totalSelectorFactory,
} from 'reduxStore/selectorFactories';

import {
  selectIsApproved,
  selectIsLoggedIn,
  selectIsEmployee,
  selectIsAdmin,
  selectLogoutPending,
} from 'connectors/Login/redux/selectors';
import { selectUser } from 'connectors/User/redux/selectors';

import selectRainmakersAdmin from './selectRainmakersAdmin';

const selector = createStructuredSelector({
  candidates: entitySelectorFactory('employerCandidates', 'candidate'),
  candidateCompanyBlocks: entitySelectorFactory('employerCandidates', 'candidateCompanyBlock'),
  candidateCompanyBlocksPage: pageSelectorFactory('employerCandidates', 'candidateCompanyBlock'),
  candidateCompanyBlocksTotal: totalSelectorFactory('employerCandidates', 'candidateCompanyBlock'),
  candidateCompanyBlocksLoading: loadingSelectorFactory(
    'employerCandidates',
    'candidateCompanyBlock'
  ),
  employees: entitySelectorFactory('employerCandidates', 'employee'),
  isAdmin: selectIsAdmin,
  isApproved: selectIsApproved,
  isEmployee: selectIsEmployee,
  isLoading: loadingSelectorFactory('employerCandidates', 'requisitionCandidate'),
  isLoggedIn: selectIsLoggedIn,
  logoutPending: selectLogoutPending,
  page: pageSelectorFactory('employerCandidates', 'requisitionCandidate'),
  privateReqCanFeedbacks: entitySelectorFactory('employerCandidates', 'privateReqCanFeedback'),
  rainmakersAdmins: entitySelectorFactory('employerCandidates', 'rainmakersAdmin'),
  requisitionOpenings: entitySelectorFactory('employerCandidates', 'requisitionOpening'),
  requisitionOpeningsPage: pageSelectorFactory('employerCandidates', 'requisitionOpening'),
  requisitionOpeningsTotal: totalSelectorFactory('employerCandidates', 'requisitionOpening'),
  requisitionOpeningsLoading: loadingSelectorFactory('employerCandidates', 'requisitionOpening'),
  requisitionCandidates: entitySelectorFactory('employerCandidates', 'requisitionCandidate'),
  total: totalSelectorFactory('employerCandidates', 'requisitionCandidate'),
  user: selectUser,
  users: entitySelectorFactory('employerCandidates', 'user'),
  rainmakersAdmin: selectRainmakersAdmin,
});

export { selector as default };
