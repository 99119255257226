import { createAction } from 'redux-actions';

import {
  USER_PATCH_DONE,
  USER_UPDATE,
  PICKER_OPTIONS_LOAD_STARTED,
  TAGS_LOAD_STARTED,
} from 'shared/store/app/actions';

import {
  EMPLOYER_LOAD_STARTED,
  EMPLOYER_LOAD_DONE,
  EMPLOYER_PATCH_STARTED,
  EMPLOYER_PATCH_DONE,
  EMPLOYER_SAVE_STARTED,
  EMPLOYER_SAVE_DONE,
  EMPLOYER_SAVE_ERROR,
  EMPLOYER_ERRORS_SET,
  EMPLOYER_UPDATE,
  EMPLOYER_IMG_UPLOAD_STARTED,
  EMPLOYER_IMG_UPLOAD_DONE,
  EMPLOYER_IMG_UPLOAD_ERROR,
  EMPLOYEE_ERRORS_SET,
  EMPLOYEE_ERRORS_RESET,
  EMPLOYEE_ERRORS_UPDATE,
  EMPLOYEE_IMG_UPLOAD_STARTED,
  EMPLOYEE_IMG_UPLOAD_DONE,
  EMPLOYEE_LOAD_STARTED,
  EMPLOYEE_LOAD_DONE,
  EMPLOYEE_PATCH_ALL_STARTED,
  EMPLOYEE_PATCH_DONE,
  EMPLOYEE_PATCH_STARTED,
  EMPLOYEE_POST_STARTED,
  EMPLOYEE_POST_DONE,
  EMPLOYEE_RESET,
  EMPLOYEE_SAVE_STARTED,
  EMPLOYEE_SAVE_DONE,
  EMPLOYEE_SAVE_ERROR,
  REMOVE_EMPLOYEE_SAVE_ERROR,
  EMPLOYEE_UPDATE,
  EMPLOYEE_VALID_SET,
  EMPLOYEE_VALID_RESET,
  EMAIL_CHANGE_STARTED,
  EMAIL_CHANGE_DONE,
  USER_PATCH_STARTED,
  USER_ERRORS_SET,
  USER_ERRORS_RESET,
  VALIDATE_ALL,
} from '../actions';

export const employeeLoadStarted = createAction(EMPLOYEE_LOAD_STARTED);
export const employeeLoadDone = createAction(EMPLOYEE_LOAD_DONE);

export const employeePatchStarted = createAction(EMPLOYEE_PATCH_STARTED);
export const employeePatchAllStarted = createAction(EMPLOYEE_PATCH_ALL_STARTED);
export const employeePatchDone = createAction(EMPLOYEE_PATCH_DONE);

export const employeePostStarted = createAction(EMPLOYEE_POST_STARTED);
export const employeePostDone = createAction(EMPLOYEE_POST_DONE);
export const employeeUpdate = createAction(EMPLOYEE_UPDATE);
export const employeeSaveStarted = createAction(EMPLOYEE_SAVE_STARTED);
export const employeeSaveDone = createAction(EMPLOYEE_SAVE_DONE);
export const employeeSaveError = createAction(EMPLOYEE_SAVE_ERROR);
export const employeeReset = createAction(EMPLOYEE_RESET);
export const employeeErrorsReset = createAction(EMPLOYEE_ERRORS_RESET);
export const employeeErrorsSet = createAction(EMPLOYEE_ERRORS_SET);
export const employeeErrorsUpdate = createAction(EMPLOYEE_ERRORS_UPDATE);
export const employeeValidSet = createAction(EMPLOYEE_VALID_SET);
export const employeeValidReset = createAction(EMPLOYEE_VALID_RESET);

export const emailChangeStarted = createAction(EMAIL_CHANGE_STARTED);
export const emailChangeDone = createAction(EMAIL_CHANGE_DONE);

export const validateAll = createAction(VALIDATE_ALL);

export const userPatchStarted = createAction(USER_PATCH_STARTED);
export const userPatchDone = createAction(USER_PATCH_DONE);
export const userUpdate = createAction(USER_UPDATE);
export const userErrorsSet = createAction(USER_ERRORS_SET);
export const userErrorsReset = createAction(USER_ERRORS_RESET);

export const employeeImageUploadStarted = createAction(EMPLOYEE_IMG_UPLOAD_STARTED);
export const employeeImageUploadDone = createAction(EMPLOYEE_IMG_UPLOAD_DONE);

export const employerLoadStarted = createAction(EMPLOYER_LOAD_STARTED);
export const employerLoadDone = createAction(EMPLOYER_LOAD_DONE);
export const employerPatchStarted = createAction(EMPLOYER_PATCH_STARTED);
export const employerPatchDone = createAction(EMPLOYER_PATCH_DONE);
export const employerSaveStarted = createAction(EMPLOYER_SAVE_STARTED);
export const employerSaveDone = createAction(EMPLOYER_SAVE_DONE);
export const employerSaveError = createAction(EMPLOYER_SAVE_ERROR);
export const removeEmployeeSaveError = createAction(REMOVE_EMPLOYEE_SAVE_ERROR);
export const employerErrorsSet = createAction(EMPLOYER_ERRORS_SET);
export const employerUpdate = createAction(EMPLOYER_UPDATE);
export const employerImageUploadStarted = createAction(EMPLOYER_IMG_UPLOAD_STARTED);
export const employerImageUploadDOne = createAction(EMPLOYER_IMG_UPLOAD_DONE);
export const employerImageUploadError = createAction(EMPLOYER_IMG_UPLOAD_ERROR);

export const pickerOptionsLoadStarted = createAction(PICKER_OPTIONS_LOAD_STARTED);
export const tagsLoadStarted = createAction(TAGS_LOAD_STARTED);
