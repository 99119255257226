import React from 'react';
import classnames from 'classnames';
import Layout from 'components/Layout';

import styles from './LayoutWithSidebar.scss';

const LayoutWithSidebar = ({ sidebar, content, right, className }) => {
  const classes = classnames(styles.Layout, className, {
    [styles.right]: right,
  });

  return (
    <Layout className={classes}>
      <div className={styles.Content}>{content}</div>
      <div className={styles.Sidebar}>{sidebar}</div>
    </Layout>
  );
};

export default LayoutWithSidebar;
