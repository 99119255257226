import React from 'react';

import Bolder from 'components/Bolder';
import FormattedDate from 'components/FormattedDate';

import styles from './ScopeContent.scss';

const ScopeContent = ({ scopeSource, scope, toDate, fromDate }) => {
  const scopeStrings = scope.map(
    (value) => scopeSource.filter((scopeObj) => scopeObj.value === value)[0].label
  );

  const scopeArrayWithDivider = [];

  scopeStrings.forEach((str) => {
    scopeArrayWithDivider.push(<Bolder>{str}</Bolder>);
    scopeArrayWithDivider.push(' and ');
  });

  scopeArrayWithDivider.pop();

  const formattedFromDate = <FormattedDate date={fromDate} format="MMM D, YYYY" />;
  const formattedToDate = <FormattedDate date={toDate} format="MMM D, YYYY" />;

  const scopeContent = scope.length ? [' that are ', scopeArrayWithDivider] : null;

  const toDateContent = toDate
    ? [' and before ', <Bolder key={1}>{formattedToDate}</Bolder>]
    : null;

  const fromDateContent = fromDate
    ? [' approved after ', <Bolder key={2}>{formattedFromDate}</Bolder>]
    : null;

  return (
    <div className={styles.currentSort}>
      Showing candidates{fromDateContent}
      {toDateContent}
      {scopeContent}.
    </div>
  );
};

export default ScopeContent;
