import React from 'react';
import classnames from 'classnames';

import EmptyCompanyLogo from 'shared/assets/missing-company-picture.png';

import { useImageField } from 'hooks';

import FontIcon from 'components/FontIcon';

import styles from './EmployerData.scss';

const EmployerData = ({
  boldSubtitle,
  className,
  onClick,
  largeAvatar,
  sideTable,
  subtitle,
  applicationStatus,
  employer = {},
}) => {
  const classes = classnames(
    styles.EmployerData,
    {
      [styles.sideTable]: sideTable,
    },
    className
  );

  const infoWrapperClasses = classnames(styles.employerDataInfoWrapper, {
    [styles.employerDataInfoWrapperShort]: sideTable,
  });

  const titleClasses = classnames(styles.employerDataTitle, {
    [styles.employerDataTitleSmall]: sideTable,
  });

  const employerProps = {
    className: classes,
    onClick,
  };

  const avatarClass = classnames(styles.employerDataAvatarWrapper, {
    [styles.employerDataAvatarLargeWrapper]: largeAvatar,
    [styles.employerDataAvatarSmallWrapper]: sideTable,
  });

  const { name: companyName } = employer || {};

  const { imageComponent } = useImageField({
    model: { attributes: employer },
    avatarClass,
    extraOverlay: verifiedOverlay,
    emptyLogo: EmptyCompanyLogo,
  });

  let appStatusOverlayStyle = null;
  switch (applicationStatus) {
    case 'approved':
      appStatusOverlayStyle = styles.verifiedOverlay;
      break;
    case 'archived':
      appStatusOverlayStyle = styles.archivedOverlay;
  }

  const verifiedOverlay = appStatusOverlayStyle ? (
    <div className={appStatusOverlayStyle}>
      <FontIcon iconName="checkmark" />
    </div>
  ) : null;

  const subtitleClasses = classnames(styles.candidateDataSubtitle, {
    [styles.candidateDataSubtitleBold]: boldSubtitle,
  });

  const subtitleString = subtitle ? <div className={subtitleClasses}>{subtitle}</div> : null;

  return (
    <div {...employerProps}>
      {imageComponent}

      <div className={infoWrapperClasses}>
        <div className={titleClasses}>{companyName}</div>

        {subtitleString}
      </div>
    </div>
  );
};

export default EmployerData;
