import { all, fork } from 'redux-saga/effects';

import sagaBookmarksAdd from './sagaBookmarksAdd';
import sagaBookmarksRemove from './sagaBookmarksRemove';
import sagaFeaturedCandidatesLoadStarted from './sagaFeaturedCandidatesLoadStarted';
import sagaFeaturedCandidatePassStarted from './sagaFeaturedCandidatePassStarted';
import sagaAdminFeaturedCandidatePassStarted from './sagaAdminFeaturedCandidatePassStarted';
import sagaFeaturedCandidateUndoPassStarted from './sagaFeaturedCandidateUndoPassStarted';

const sagaCandidateSearch = function* sagaCandidateSearch() {
  yield all([
    fork(sagaBookmarksAdd),
    fork(sagaBookmarksRemove),
    fork(sagaFeaturedCandidatesLoadStarted),
    fork(sagaFeaturedCandidatePassStarted),
    fork(sagaAdminFeaturedCandidatePassStarted),
    fork(sagaFeaturedCandidateUndoPassStarted),
  ]);
};

export default sagaCandidateSearch;
