import { createStructuredSelector } from 'reselect';

import {
  entitySelectorFactory,
  loadingSelectorFactory,
  pageSelectorFactory,
  totalSelectorFactory,
  savingSelectorFactory,
} from 'reduxStore/selectorFactories';

import {
  selectIsApproved,
  selectIsLoggedIn,
  selectIsEmployee,
  selectIsCandidate,
  selectIsAdmin,
  selectLogoutPending,
} from 'connectors/Login/redux/selectors';

import { selectPickerOptions } from 'connectors/Defaults/redux/selectors';

import { selectUser } from 'connectors/User/redux/selectors';
import { getModal } from 'shared/store/app/selectors';

import selectRequisitionOpening from './selectRequisitionOpening';
import selectRequisitionOpeningErrors from './selectRequisitionOpeningErrors';

const selector = createStructuredSelector({
  candidates: entitySelectorFactory('employerRequisitions', 'candidate'),
  employers: entitySelectorFactory('employerRequisitions', 'employer'),
  greenhouseJobs: entitySelectorFactory('employerRequisitions', 'greenhouseJob'),
  isAdmin: selectIsAdmin,
  isApproved: selectIsApproved,
  isEmployee: selectIsEmployee,
  isCandidate: selectIsCandidate,
  isLoading: loadingSelectorFactory('employerRequisitions', 'requisitionOpening'),
  isLoggedIn: selectIsLoggedIn,
  logoutPending: selectLogoutPending,
  modal: getModal,
  page: pageSelectorFactory('employerRequisitions', 'requisitionOpening'),
  pickerOptions: selectPickerOptions,
  requisitionCandidates: entitySelectorFactory('employerRequisitions', 'requisitionCandidate'),
  requisitionOpening: selectRequisitionOpening,
  requisitionOpeningErrors: selectRequisitionOpeningErrors,
  requisitionOpenings: entitySelectorFactory('employerRequisitions', 'requisitionOpening'),
  requisitionRequirements: entitySelectorFactory('employerRequisitions', 'requisitionRequirement'),
  saving: savingSelectorFactory('employerRequisitions'),
  total: totalSelectorFactory('employerRequisitions', 'requisitionOpening'),
  user: selectUser,
});

export { selector as default };
