import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { EmployeeAuthHOC } from 'containers/HOC';
import ConnectContainer from 'containers/ConnectContainer';
import ReadyContainer from 'connectors/ReadyContainer';

import { promiseGetEmployer } from 'pages/EmployeePortal/EmployeeEditorPages/promises';

import { handleError } from 'utils/common';
import { employerInputChange } from 'utils/formHelpers/EmployerHelpers';
import { toggleArray } from 'utils/formUtils';

import { COMPANY_SIZE_SOURCE } from 'connectors/Defaults/constants';

import Block from 'components/Block';
import Content from 'components/Content';
import EmployerImageUploader from 'components/EmployerImageUploader';
import GooglePlacesAutocomplete from 'components/GooglePlacesAutocomplete';
import Input from 'components/Input';
import InputRichText from 'components/InputRichText';
import InputBullets from 'components/InputBullets';
import InputCheckbox from 'components/InputCheckbox';
import InputSelect from 'components/InputSelect';
import Label from 'components/Label';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import Sidebar from 'components/Sidebar';
import Tag from 'components/Tag';

import Validator from 'connectors/Validator';

import duck from '../redux';

import PerkInputs from './components/PerkInputs';
import InvestorInputs from './components/InvestorInputs';
import LinkInputs from './components/LinkInputs';
import BenefitInputs from './components/BenefitInputs';

import styles from './EditCompany.scss';

const EditCompany = (props) => {
  const [companyWebsiteError, setCompanyWebsiteError] = useState('');

  useEffect(() => {
    const {
      actions: { employerLoadDone, pickerOptionsLoadStarted, tagsLoadStarted },
      user,
    } = props;

    const onFulfilled = ({ employer }) => employerLoadDone({ employer });

    const currentProfile = user.loginDone ? user.currentProfile : {};

    const employerId =
      currentProfile && currentProfile.type === 'Employee' ? currentProfile.employerId : null;

    pickerOptionsLoadStarted();

    tagsLoadStarted();

    const promiseParams = {
      employerId,
      camelizeIt: true,
    };

    promiseGetEmployer(promiseParams).then(onFulfilled).catch(handleError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEmployerInputChange = (event) => {
    const {
      actions: { employerPatchStarted },
      employer,
    } = props;

    const patchArgs = employerInputChange(event, employer);

    const { newValue, name } = patchArgs;

    if (name === 'webSite') {
      if (Validator.isUrl({ newValue })) {
        setCompanyWebsiteError('');
      } else {
        setCompanyWebsiteError('Illegal website url');
        return;
      }
    }

    employerPatchStarted(patchArgs);
  };

  const handleAddArrayInput = (arrayName) => {
    const {
      actions: { employerLoadDone },
      employer,
    } = props;

    const { [arrayName]: arrayToUpdate = [] } = employer;

    const newValue = arrayToUpdate.slice();

    newValue.push('');

    const newCompanyProfile = {
      ...employer,
      [arrayName]: newValue,
    };

    employerLoadDone({ employer: newCompanyProfile });
  };

  const handleAddArrayValue = (event, arrayName) => {
    const {
      target: { name, value },
    } = event;
    const {
      employer: { [arrayName]: arrayToUpdate = [] } = {},
      actions: { employerPatchStarted },
    } = props;

    let newValue = arrayToUpdate ? arrayToUpdate.slice() : [];

    newValue[name] = value;

    if (newValue.every((el) => !el)) {
      newValue = [];
    }

    employerPatchStarted({ newValue, name: arrayName });
  };

  const handleReorderArray = ({ startIndex, endIndex, name }) => {
    const {
      employer: { [name]: arrayToUpdate },
      actions: { employerPatchStarted },
    } = props;

    const newArray = arrayToUpdate.slice();

    const [removed] = newArray.splice(startIndex, 1);

    newArray.splice(endIndex, 0, removed);

    employerPatchStarted({ newValue: newArray, name });
  };

  const handleToggleArrayValue = (event) => {
    const {
      target: { name, value },
    } = event;
    const {
      employer: { [name]: arrayToUpdate },
      actions: { employerPatchStarted },
    } = props;

    const newValue = toggleArray({
      array: arrayToUpdate,
      value,
    });

    employerPatchStarted({ newValue, name });
  };

  const handleDeleteArrayInput = (indexToRemove, arrayName) => {
    const {
      actions: { employerPatchStarted },
      employer: { [arrayName]: arrayToUpdate },
    } = props;

    const newValue = arrayToUpdate.filter((id, index) => index !== indexToRemove);

    employerPatchStarted({ newValue, name: arrayName });
  };

  const handleHiringLocationSelect = (place) => {
    const {
      actions: { employerPatchStarted },
      employer: { hiringLocations },
    } = props;

    const newLocations = hiringLocations.slice();

    newLocations.push(place);

    employerPatchStarted({ newValue: newLocations, name: 'hiringLocations' });
  };

  const handleDeleteHiringLocation = ({ value }) => {
    const {
      actions: { employerPatchStarted },
      employer: { hiringLocations },
    } = props;
    const newLocations = hiringLocations.filter((loc) => loc !== value);

    employerPatchStarted({ newValue: newLocations, name: 'hiringLocations' });
  };

  const {
    employer,
    employer: {
      about = '',
      benefits = [],
      email = '',
      foundedOn = '',
      highlights = [],
      hiringLocations = [],
      id: employerId,
      industry,
      investors,
      links = [],
      location,
      name,
      opportunities = [],
      perks,
      picture: { xxlarge: pictureUrl } = {},
      products = [],
      sizeMin,
      socialMedia: {
        linkedin: { url: linkedinUrl = '' } = {},
        facebook: { url: facebookUrl = '' } = {},
        twitter: { url: twitterUrl = '' } = {},
      } = {},
      stage,
      tagline,
      webSite,
      workRemote,
    },
    isApproved,
    pickerOptions: { stage: stageOptions = [], industry: industryOptionsRaw = [] } = {},
    tags: { perks: perksOptions = [], benefits: benefitsOptions = [] } = {},
    employeeSaving,
    actions: { employerImageUploadStarted },
  } = props;

  const industryOptions = industryOptionsRaw.filter((opt) => opt !== 'Many' && opt !== 'Other');

  const savingContent = employeeSaving ? (
    <div className={styles.autoSaving}>Saving...</div>
  ) : (
    <div className={styles.autoSaved}>Profile Saved</div>
  );

  const saveContent = employeeSaving === undefined ? null : savingContent;

  const commonBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    addPadding: true,
  };

  const titleBlockProps = {
    ...commonBlockProps,
    className: styles.titleBlock,
  };

  const basicInfoBlockProps = {
    ...commonBlockProps,
    addChildFlex: true,
    title: 'Basic Info',
  };
  const socialMediaBlockProps = {
    ...commonBlockProps,
    title: 'Social Media',
    className: styles.firstBlock,
  };
  const perksBenefitsBlockProps = {
    ...commonBlockProps,
    title: 'Perks and Benefits',
    className: styles.firstBlock,
  };
  const investmentBlockProps = {
    ...commonBlockProps,
    title: 'Investment',
    className: styles.firstBlock,
  };
  const pressBlockProps = {
    ...commonBlockProps,
    title: 'Press & Media Exposure',
    className: styles.firstBlock,
  };

  const commonTextInputProps = {
    handleInputChange: handleEmployerInputChange,
    type: 'text',
    size: 'full',
    topLabel: true,
  };

  const companyNameInputProps = {
    ...commonTextInputProps,
    name: 'companyName',
    label: 'Company Name',
    value: name || '',
    placeholder: 'Company Name',
    autoFocus: true,
    className: styles.noPadding,
    required: true,
  };

  const companyEmailInputProps = {
    ...commonTextInputProps,
    name: 'email',
    label: 'Company Email',
    value: email || '',
    placeholder: 'Company Email',
  };

  const companyLocationInputProps = {
    value: location,
    name: 'location',
    placeholder: 'Where is the company headquartered?',
    handleSelect: (place) =>
      handleEmployerInputChange({
        target: {
          value: place,
          name: 'location',
        },
      }),
  };

  const industrySource = industryOptions.map((ind) => ({ label: ind, value: ind }));

  const [industryValue] = industrySource.filter(({ label }) => label === industry);

  const companyIndustryInputProps = {
    ...commonTextInputProps,
    name: 'industry',
    label: 'Industry Type',
    value: industryValue ? industryValue.label : '',
    source: industrySource,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    defaultLabel: 'Industry',
    bigInput: true,
    required: true,
  };

  const [sizeValue] = COMPANY_SIZE_SOURCE.filter(
    ({ min }) => parseInt(min, 10) === parseInt(sizeMin, 10)
  );

  const companySizeInputProps = {
    ...commonTextInputProps,
    name: 'size',
    label: 'Company Size',
    value: sizeValue ? sizeValue.value : '',
    source: COMPANY_SIZE_SOURCE,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    defaultLabel: 'Size',
    bigInput: true,
    required: true,
  };
  const companyLinkedInInputProps = {
    ...commonTextInputProps,
    name: 'socialMedia,linkedin,url',
    label: 'Company LinkedIn',
    type: 'url',
    value: linkedinUrl || '',
    placeholder: 'https://www.linkedin.com/in/awesome-company',
    className: styles.noPadding,
    required: true,
  };

  const companyTwitterInputProps = {
    ...commonTextInputProps,
    name: 'socialMedia,twitter,url',
    autoCapitalize: 'none',
    label: 'Company Twitter',
    value: twitterUrl || '',
    placeholder: 'www.twitter.com/awesome-company',
    required: true,
  };

  const companyFacebookInputProps = {
    ...commonTextInputProps,
    name: 'socialMedia,facebook,url',
    label: 'Company Facebook',
    value: facebookUrl || '',
    placeholder: 'www.facebook.com/awesome-company',
    required: true,
  };

  const companyWebSiteInputProps = {
    ...commonTextInputProps,
    name: 'webSite',
    label: 'Company Website',
    value: webSite || '',
    placeholder: 'www.awesome-company.com',
    required: true,
    errors: {
      isUrl: [companyWebsiteError.length === 0],
    },
    showErrorsImmediately: true,
  };

  const currentDate = new Date();

  const companyFoundedOnInputProps = {
    ...commonTextInputProps,
    size: 'small',
    name: 'foundedOn',
    label: 'Founding Year',
    value: foundedOn || '',
    type: 'number',
    max: `${currentDate.getFullYear()}`,
    centered: true,
    required: true,
  };

  const companyTaglineInputProps = {
    ...commonTextInputProps,
    name: 'tagline',
    label: 'Company Tagline',
    value: tagline || '',
    placeholder: 'The essence of who you are',
  };

  const aboutInputProps = {
    value: about,
    name: 'about',
    handleInputChange: handleEmployerInputChange,
  };

  const companyPerksInputProps = {
    handleToggleArrayValue: handleToggleArrayValue,
    perks,
    perksOptions,
  };

  const companyLinksInputProps = {
    handleAddArrayInput: handleAddArrayInput,
    handleAddArrayValue: handleAddArrayValue,
    handleDeleteArrayInput: handleDeleteArrayInput,
    links: links || [],
  };

  const companyInvestorsInputProps = {
    handleToggleArrayValue: handleToggleArrayValue,
    investors,
  };

  const companyBenefitsInputProps = {
    handleToggleArrayValue: handleToggleArrayValue,
    benefits,
    benefitsOptions,
  };

  const stageSource = stageOptions.map((stage) => ({ label: stage, value: stage }));

  const [stageValue] = stageSource.filter(({ label }) => label === stage);

  const companyStageInputProps = {
    ...commonTextInputProps,
    name: 'stage',
    label: 'Funding',
    value: stageValue ? stageValue.label : '',
    source: stageSource,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    defaultLabel: 'Stage',
    bigInput: true,
    noMargin: true,
    required: true,
  };

  const hiringLocationsInputProps = {
    name: 'hiringLocations',
    placeholder: 'San Francisco, CA',
    handleSelect: handleHiringLocationSelect,
    needReset: true,
  };

  const hiringLocationsSelected = hiringLocations
    ? hiringLocations.map((loc) => {
        const tagProps = {
          name: loc,
          inputName: 'hiringLocations',
          onDeleteClick: (name, inputName) =>
            handleDeleteHiringLocation({
              value: name,
              name: inputName,
            }),
        };
        return <Tag key={loc} {...tagProps} />;
      })
    : null;

  const imageUploaderProps = {
    employerId,
    pictureUrl,
    employerImageUploadStarted,
  };

  const highlightsInputProps = {
    label: 'Highlights',
    secondaryLabel: 'What are the key features or highlights of the company that you want to show?',
    array: highlights,
    name: 'highlights',
    handleAddArrayInput: handleAddArrayInput,
    handleAddArrayValue: handleAddArrayValue,
    handleDeleteArrayInput: handleDeleteArrayInput,
    handleReorderArray: handleReorderArray,
    pad: true,
    required: true,
  };

  const productsInputProps = {
    label: 'Products',
    secondaryLabel: 'What products do you sell?',
    array: products,
    name: 'products',
    handleAddArrayInput: handleAddArrayInput,
    handleAddArrayValue: handleAddArrayValue,
    handleDeleteArrayInput: handleDeleteArrayInput,
    handleReorderArray: handleReorderArray,
    pad: true,
    required: true,
  };

  const opportunitiesInputProps = {
    label: 'Why is this a great opportunity?',
    array: opportunities,
    name: 'opportunities',
    handleAddArrayInput: handleAddArrayInput,
    handleAddArrayValue: handleAddArrayValue,
    handleDeleteArrayInput: handleDeleteArrayInput,
    handleReorderArray: handleReorderArray,
    pad: true,
    required: true,
  };

  const workRemoteProps = {
    name: 'workRemote',
    label: 'Remote positions',
    checked: workRemote,
    handleInputChange: handleEmployerInputChange,
    className: styles.checkbox,
    checkboxMany: true,
  };

  const hiringLocationsContent =
    hiringLocations.length > 0 ? (
      <div className={styles.autocompleteContainer}>{hiringLocationsSelected}</div>
    ) : null;

  const content = (
    <div className={styles.CompanyForm}>
      <Block {...titleBlockProps}>
        <div className={styles.companyProfileHeader}>
          <div className={styles.companyProfileTitle}>Editing Company Profile</div>
          {saveContent}
        </div>
      </Block>
      <div className={styles.scrollDiv}>
        <Block {...basicInfoBlockProps}>
          <div className={styles.containerFlexRow}>
            <EmployerImageUploader {...imageUploaderProps} />
            <div className={styles.containerFlexColumn}>
              <div className={styles.basicInfoTop}>
                <Input {...companyNameInputProps} />
                <Input {...companyEmailInputProps} />
                <Label required standalone className={styles.label}>
                  Company HQ Location
                </Label>
                <GooglePlacesAutocomplete {...companyLocationInputProps} />
                <div className={styles.basicInfoRowApart}>
                  <InputSelect {...companySizeInputProps} />
                  <InputSelect {...companyIndustryInputProps} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.basicInfoTop}>
            {/* <ErrorMessage className={styles.companyWebsiteError}>
              {companyWebsiteError}
            </ErrorMessage> */}
            <Input {...companyWebSiteInputProps} />
            <Label className={styles.label}>Hiring Locations</Label>
            <GooglePlacesAutocomplete {...hiringLocationsInputProps} />
            {hiringLocationsContent}
            <InputCheckbox {...workRemoteProps} />
          </div>
          <div className={styles.basicInfoRow}>
            <div className={styles.taglineContainer}>
              <Input {...companyTaglineInputProps} />
            </div>
            <div className={styles.foundedContainer}>
              <Input {...companyFoundedOnInputProps} />
            </div>
          </div>
          <Label required className={styles.aboutLabel}>
            Company Description
          </Label>
          <Content className={styles.aboutSublabel}>
            Let candidates know why they should work for a cool company like yours.
          </Content>
          <InputRichText {...aboutInputProps} />
          <InputBullets {...highlightsInputProps} />
          <InputBullets {...productsInputProps} />
          <InputBullets {...opportunitiesInputProps} />
        </Block>
        <Block {...socialMediaBlockProps}>
          <Input {...companyLinkedInInputProps} />
          <Input {...companyFacebookInputProps} />
          <Input {...companyTwitterInputProps} />
        </Block>
        <Block {...perksBenefitsBlockProps}>
          <PerkInputs {...companyPerksInputProps} />
          <BenefitInputs {...companyBenefitsInputProps} />
        </Block>
        <Block {...investmentBlockProps}>
          <InputSelect {...companyStageInputProps} />
          <InvestorInputs {...companyInvestorsInputProps} />
        </Block>
        <Block {...pressBlockProps}>
          <LinkInputs {...companyLinksInputProps} />
        </Block>
      </div>
    </div>
  );

  return (
    <>
      <Helmet key="helmet" title="Settings" />
      <ReadyContainer key="readyContainer">
        <Sidebar
          type={isApproved ? 'employeeStatusApproved' : 'employeeStatusUnapproved'}
          page={'edit-company'}
          employer={employer}
        />
        <LayoutWithoutSidebar content={content} />
      </ReadyContainer>
    </>
  );
};

export default ConnectContainer(duck)(EmployeeAuthHOC()(EditCompany));
