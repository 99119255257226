import { createSelector } from 'reselect';
import { entitySelectorFactory } from 'reduxStore/selectorFactories';

const selectCohort = createSelector(
  entitySelectorFactory('toolboxCohorts', 'cohort'),
  (state, props) => {
    const {
      match: { params: { id } = {} },
    } = props || {};

    return id;
  },
  (cohorts, id) => {
    const { byId: { [id]: cohort = {} } = {} } = cohorts;

    return cohort;
  }
);

export default selectCohort;
