import { createAction } from 'redux-actions';

import {
  CANDIDATE_PROFILE_PATCH_DONE,
  LOGOUT_STARTED,
  RESET_BACK_LINK,
  SAVE_UTM_INFO,
  SET_BACK_LINK,
  SET_DELAY_PROFILE_CARDS,
  USER_SAVE,
  USER_REMOVE,
  USER_PATCH_DONE,
  USER_UPDATE,
} from 'shared/store/app/actions';

export const logoutStarted = createAction(LOGOUT_STARTED);

export const userSave = createAction(USER_SAVE);
export const userRemove = createAction(USER_REMOVE);
export const userUpdate = createAction(USER_UPDATE);
export const userPatchDone = createAction(USER_PATCH_DONE);

export const candidateProfilePatchDone = createAction(CANDIDATE_PROFILE_PATCH_DONE);

export const saveUTMInfo = createAction(SAVE_UTM_INFO);

export const resetBackLink = createAction(RESET_BACK_LINK);
export const setBackLink = createAction(SET_BACK_LINK);

export const setDelayProfileCards = createAction(SET_DELAY_PROFILE_CARDS);
