import React from 'react';
import fecha from 'fecha';

// Formatting

// MONTHS
// M => 1 2 ... 11 12
// MM => 01 02 ... 11 12
// MMM => Jan Feb ... Nov Dec
// MMMM => January February ... November December
// DAYS OF MONTH
// D => 1 2 ... 30 31
// Do => 1st 2nd ... 30th 31st
// DD => 01 02 ... 30 31
// DAYS OF WEEK
// d => 0 1 ... 5 6
// ddd => Sun Mon ... Fri Sat
// dddd => Sunday Monday ... Friday Saturday
// YEARS
// YY => 70 71 ... 29 30
// YYYY => 1970 1971 ... 2029 2030
// AM PM
// A => AM PM
// a => am pm
// HOURS
// H => 0 1 ... 22 23
// HH => 00 01 ... 22 23
// h => 1 2 ... 11 12
// hh => 01 02 ... 11 12
// MINUTES
// m => 0 1 ... 58 59
// mm => 00 01 ... 58 59
// SECONDS
// s => 0 1 ... 58 59
// ss => 00 01 ... 58 59
// FRACTIONAL SECONDS
// S => 0 1 ... 8 9
// SS => 0 1 ... 98 99
// SSS => 0 1 ... 998 999
// TIMEZONE
// ZZ => -0700 -0600 ... +0600 +0700

const renderTimeAgo = (date, timeAgoFormat) => {
  if (date) {
    const timeAgoInMs = Date.now() - date;
    const timeAgoInHours = timeAgoInMs / 3600000;
    const timeAgoInDays = timeAgoInHours / 24;
    const timeAgoInMonths = timeAgoInDays / 30;

    if (timeAgoInDays >= 365) {
      switch (timeAgoFormat) {
        case 'relativeDays':
          return '> 1 year';
        default:
          break;
      }
    }

    if (timeAgoInDays >= 40) {
      switch (timeAgoFormat) {
        case 'stringDays':
          return '> 1 month';
        case 'relativeDays':
          return `${Math.round(timeAgoInMonths)} months`;
        default:
          return '> 1 month';
      }
    }

    if (timeAgoInDays >= 30) {
      switch (timeAgoFormat) {
        case 'stringDays':
          return '> 1 month';
        case 'relativeDays':
          return '1 month';
        default:
          return '> 1 month';
      }
    }

    if (timeAgoInDays >= 2) {
      switch (timeAgoFormat) {
        case 'relativeDays':
          return `${Math.floor(timeAgoInDays)} days`;
        case 'integerDays':
          return Math.floor(timeAgoInDays);
        case 'stringDays':
          return `${Math.floor(timeAgoInDays)} days`;
        default:
          break;
      }
    }

    if (timeAgoInDays >= 1) {
      switch (timeAgoFormat) {
        case 'relativeDays':
          return '1 day';
        case 'integerDays':
          return 1;
        case 'stringDays':
          return 'Yesterday';
        default:
          break;
      }
    }
    if (timeAgoInDays < 1) {
      switch (timeAgoFormat) {
        case 'relativeDays':
          break;
        case 'integerDays':
          return 1;
        case 'stringDays':
          return 'Today';
        default:
          break;
      }
    }
    if (timeAgoInHours >= 2) {
      switch (timeAgoFormat) {
        case 'relativeDays':
          return `${Math.floor(timeAgoInHours)} hours`;
        default:
          break;
      }
    }

    if (timeAgoInHours >= 1) {
      switch (timeAgoFormat) {
        case 'relativeDays':
          return `${Math.floor(timeAgoInHours)} hour`;
        default:
          break;
      }
    }

    if (timeAgoInHours < 1) {
      switch (timeAgoFormat) {
        case 'relativeDays':
          return '< 1 hour';
        default:
          break;
      }
    }
  } else {
    return '-';
  }
};

const FormattedDate = ({
  className,
  date,
  format = 'MMMM D, YYYY',
  timeAgo,
  timeAgoFormat,
  parse,
}) => {
  if (!date) return <span className={className} />;

  const parsedDate =
    parse && typeof date === 'string' ? fecha.parse(`${date}`, 'YYYY-MM-DD HH:mm:ss ZZ') : date;

  const fechaDate = parsedDate ? fecha.format(parsedDate, format) : '';

  const content = timeAgo ? renderTimeAgo(parsedDate, timeAgoFormat) : fechaDate;

  return <span className={className}>{content}</span>;
};

export default FormattedDate;
