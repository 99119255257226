const errors = {
  externalName: {
    isNotEmpty: [null],
    minLength: [null, 2],
    doesNotEqual: [null, ['Untitled Req', 'Copy of Untitled Req']],
  },
  role: {
    isNotEmpty: [null],
  },
  locations: {
    hasXElements: [null, 1],
  },
  headcount: {
    isNumeric: [null],
    isGreaterThanX: [null, 0],
  },
  oteMinCents: {
    isNotNull: [null],
    isNotEmpty: [null],
    isMoney: [null],
    isMoneyLessThanX: [null, 10000000000],
  },
  oteMaxCents: {
    isNotNull: [null],
    isNotEmpty: [null],
    isMoney: [null],
    isMoneyLessThanX: [null, 10000000000],
  },
  overview: {
    hasXElements: [null, 1],
  },
  baseSalaryMinCents: {
    isMoney: [null],
    isMoneyLessThanX: [null, 10000000000],
  },
  baseSalaryMaxCents: {
    isMoney: [null],
    isMoneyLessThanX: [null, 10000000000],
  },
};

export default errors;
