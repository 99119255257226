import React from 'react';
import classnames from 'classnames';

import Button from 'components/Button';
import EmployerData from 'components/EmployerData';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import InputCheckbox from 'components/InputCheckbox';
import Table from 'components/Table';
import TableRow from 'components/Table/components/TableRow';
import TableCell from 'components/Table/components/TableCell';

import styles from './SelectedEmployersTable.scss';

const SelectedEmployersTable = ({
  cohortId,
  isReadOnly,
  addingEmployers,
  cohortEmployersLoading,
  cohortEmployers: { allIds = [], byId = {} } = {},
  cohortEmployersTotal,
  employers: { byId: employersById = {} } = {},
  loadMoreCohortEmployers,
  handleCohortEmployerRemove,
  handleCohortEmployerToggle,
  handleSetAllEmployersToggle,
  handleShowAddEmployersOptions,
}) => {
  const addEmployersButtonProps = {
    onClick: handleShowAddEmployersOptions,
    primary: true,
  };

  const addEmployersButton = <Button {...addEmployersButtonProps}>+ Add Employers</Button>;

  const removeHeader = isReadOnly ? null : <div className={styles.removeHeader}>Remove</div>;

  const setAllEnabledProps = {
    checked: allIds.every((id) => byId[id].attributes.state === 'enabled'),
    handleInputChange: () => handleSetAllEmployersToggle({ type: 'enabled' }),
    disabled: isReadOnly || allIds.length === 0,
  };

  const setAllDisabledProps = {
    checked: allIds.every((id) => byId[id].attributes.state === 'blocked'),
    handleInputChange: () => handleSetAllEmployersToggle({ type: 'blocked' }),
    disabled: isReadOnly || allIds.length === 0,
  };

  const addEmployersTitle = [
    <div key="empTitle" className={styles.addEmpTitle}>
      <div>Selected Employers</div>
      {addEmployersButton}
    </div>,
    <div key="empHeader" className={styles.tableHeader}>
      <div className={styles.companyHeader}>Employer</div>
      <div className={styles.statusHeader}>Status</div>
      <div className={styles.checkboxHeader}>
        <span className={styles.headerText}>Enable</span>
        <InputCheckbox {...setAllEnabledProps} />
      </div>
      <div className={styles.checkboxHeader}>
        <span className={styles.headerText}>Disable</span>
        <InputCheckbox {...setAllDisabledProps} />
      </div>
      {removeHeader}
    </div>,
  ];

  const addEmployersBlockProps = {
    boxShadow: true,
    addWhiteBG: true,
    addFlex: true,
    addChildFlex: true,
    addBottomFade: true,
    noTitlePadding: true,
    disabled: !cohortId,
  };

  const infiniteScrollProps = {
    loadMore: loadMoreCohortEmployers,
    hasMore: cohortEmployersTotal > allIds.length,
    loader: cohortEmployersLoading ? (
      <div key="doneLoad" />
    ) : (
      <InfiniteScrollLoader key="isLoading" />
    ),
    useWindow: false,
  };

  const makeEmployerCells = (id) => {
    const {
      attributes: { state } = {},
      relationships: { employer: { data: employerIds = [] } = {} } = {},
    } = byId[id] || {};

    const { attributes = {}, attributes: { applicationStatus } = {} } =
      employersById[employerIds[0]] || {};

    const employerDataProps = {
      employer: attributes,
    };

    const employerDataContent = (
      <div className={styles.employerContent}>
        <EmployerData {...employerDataProps} />
      </div>
    );

    const lookingStatusClasses = classnames(styles.statusContent);

    const activeText = applicationStatus === 'approved' ? 'Approved' : null;
    const inactiveText = applicationStatus === 'pending' ? 'Pending' : null;

    const statusText = activeText || inactiveText || '-';

    const statusContent = <div className={lookingStatusClasses}>{statusText}</div>;

    const removeEmployerProps = {
      onClick: () => handleCohortEmployerRemove({ cohortEmployerId: id }),
      className: styles.removeEmpContent,
    };

    const removeEmployerContent = isReadOnly ? null : (
      <TableCell flex key={5}>
        <div {...removeEmployerProps}>&times;</div>
      </TableCell>
    );

    const enableCheckboxProps = {
      name: 'toggle_enable',
      checked: state === 'enabled',
      handleInputChange: (event) =>
        handleCohortEmployerToggle({
          event,
          cohortEmployerId: id,
        }),
      disabled: isReadOnly,
    };

    const disableCheckboxProps = {
      name: 'toggle_disable',
      checked: state === 'blocked',
      handleInputChange: (event) =>
        handleCohortEmployerToggle({
          event,
          cohortEmployerId: id,
        }),
      disabled: isReadOnly,
    };

    const cells = [
      <TableCell key={1}>{employerDataContent}</TableCell>,
      <TableCell key={2}>{statusContent}</TableCell>,
      <TableCell key={3}>
        <div className={styles.employerCheckbox}>
          <InputCheckbox {...enableCheckboxProps} />
        </div>
      </TableCell>,
      <TableCell key={4}>
        <div className={styles.employerCheckbox}>
          <InputCheckbox {...disableCheckboxProps} />
        </div>
      </TableCell>,
      removeEmployerContent,
    ];

    return cells;
  };

  const makeEmployerRows = (id) => {
    const { relationships: { employer: { data: employerIds = [] } = {} } = {} } = byId[id] || {};

    const { attributes: { applicationStatus } = {} } = employersById[employerIds[0]] || {};

    return (
      <TableRow id={id} disabled={applicationStatus !== 'approved'} key={id}>
        {makeEmployerCells(id)}
      </TableRow>
    );
  };

  const tableContent = allIds.map(makeEmployerRows);

  const tableProps = {
    blockProps: addEmployersBlockProps,
    titleContent: addEmployersTitle,
    tableContent,
    infiniteScrollProps,
    tableName: 'adminCohortEmployers',
    isEmpty: !cohortEmployersLoading && allIds.length === 0,
    emptyTextString: 'No Employers Added',
    isLoading: cohortEmployersLoading || addingEmployers,
  };

  return (
    <div className={styles.content}>
      <Table {...tableProps} />
    </div>
  );
};

export default SelectedEmployersTable;
