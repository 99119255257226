/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import jobsData, { JOB_PAGE_SDR, JOB_PAGE_AE } from 'pages/JobsPage/jobsPageMetaData';
import pic1 from './assets/1.jpg';
import pic2 from './assets/2.jpg';
import pic3 from './assets/3.jpg';
import pic4 from './assets/4.jpg';
import pic5 from './assets/CTA1.png';

import styles from './ProspectingContent.scss';

export function ProspectingContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt="" />
      <h2>Sales Skills: Prospecting</h2>
      <p>What is prospecting?</p>
      <p>
        Finding and communicating with potential clients is known as prospecting and is the first
        stage of the sales cycle. To create a sales pipeline, a salesperson must regularly engage
        with potential clients, learn from them, build rapport, and close sales.
      </p>
      <p>
        Prospecting is usually done by a sales department’s
        <a target="_blank" rel="noopener noreferrer" href={jobsData[JOB_PAGE_SDR].localUrl}>
          {` ${jobsData[JOB_PAGE_SDR].header} `}
        </a>
        or
        <a target="_blank" rel="noopener noreferrer" href={jobsData[JOB_PAGE_AE].localUrl}>
          {` ${jobsData[JOB_PAGE_AE].header} `}
        </a>
        to establish and cultivate connections with selected prospects to nurture new business.
      </p>

      <h2>Why Is Prospecting So Crucial to a Company’s Success?</h2>
      <p>
        For one thing, it provides a lot of value. Gaining new prospects helps fill in any gaps in
        the sales pipeline that may have appeared due to gradual attrition. Furthermore, it provides
        forward momentum, which leads to increased sales. A salesperson can use prospecting to
        determine whether a potential customer can find value in their company’s products or
        services and then build an effective selling strategy.
      </p>
      <p>
        Prospecting is also an opportunity to gather information and do market research. Even though
        it might not necessarily result in an immediate sale, prospecting can answer concerns such
        as why individuals purchase your goods, their objectives, and what they think of your sales
        methods.
      </p>
      <p>It can help determine how salespeople can best market their company’s goods.</p>

      <h2>How Does Prospecting Help Generate Leads?</h2>
      <p>
        Mainly, prospecting opens up conversations and allows you to create those leads that are
        most likely to result in sales. You do this by screening potential clients to see how they
        could benefit from your company’s products. This is done partially through research but also
        through outreach.
      </p>
      <p>
        You save time and effort by screening the proper people before you begin actively selling to
        them and streamline your whole pipeline. The better the prospecting, the more positive leads
        you generate.
      </p>

      <img src={pic2} alt="" />
      <h2>What Are the Different Types of Prospecting?</h2>
      <p>
        Cold calling is nearly as old as phones themselves. It was common for sales professionals in
        the late 19th century to initiate contact with their potential clients in this manner.
        Today’s sales prospecting, however, employs various strategies to locate and entice
        potential clients—including the phone.
      </p>
      <p>Here are the different ways you might engage in prospecting:</p>

      <h3>Phone</h3>
      <p>
        Prospecting over the phone involves getting in touch and speaking with a business’s
        decision-makers one-on-one. Note that around 85% of cold calls from unknown numbers go
        directly to voicemail, so If someone on the other end does pick up, this could be an
        excellent opportunity to advance your company’s products or services.
      </p>

      <h3>Email</h3>
      <p>
        Creating well-written emails, building a list, and monitoring open rates, click-through
        rates, or the effectiveness of email subject lines are all part of email prospecting.
        Metrics provide a clear picture of how your prospects interact with your material and are
        easily accessible through email prospecting.
      </p>

      <h3>In-Person</h3>
      <p>
        This involves speaking to potential customers directly at trade fairs, conferences, and
        industry events in order to encourage sales. This can be a lot of fun and gets you out and
        recognized in the industry.
      </p>

      <h3>Video</h3>
      <p>
        Many salespeople find increased open rates and deal closings through video prospecting via
        emails, LinkedIn messages, and other social media or communication platforms. Sending a
        pre-recorded video presentation is an excellent way to save time since sales representatives
        often spend most of their day writing emails. In addition, a forward-thinking salesperson
        can potentially increase their chances by sending prospects personalized greetings.
      </p>

      <h3>Social Media</h3>
      <p>
        You can increase the visibility of your business by creating a profile on social media
        platforms such as LinkedIn, Instagram, Facebook, Twitter, and even TikTok. The keys are to
        provide helpful information and produce sales-focused material to draw leads who will become
        prospects.
      </p>

      <img src={pic3} alt="" />
      <h2>What Are the Main Benefits of Prospecting in the Tech Industry?</h2>
      <p>
        Prospecting is essential for salespeople and businesses looking to retain and increase their
        customer base and revenue. Revenue. Here are four direct benefits of prospecting:
      </p>
      <ul>
        <li>
          <strong>Qualifying Prospects –</strong> Salespeople can improve their selling
          effectiveness and target the best prospects who are ready to buy by determining the degree
          of need, the buying motive of prospects, and the prospects’ financial capacity.
        </li>
        <li>
          <strong>Increasing Customers –</strong> When prospecting is successful, you draw in more
          prospective clients who eventually become purchasers. As a result, the average salesperson
          can expect their client base to dwindle gradually without prospecting. Conversely, with
          prospecting, the buyer pool will grow due to adding new clients to existing ones.
        </li>
        <li>
          <strong>Increasing Revenue –</strong> The more prospects a salesperson helps convert to
          customers, the more revenue increases. Additionally, the salesperson typically could
          receive a higher commission, and the company typically has the opportunity to boost
          earnings and expand.
        </li>
        <li>
          <strong>Collecting Data –</strong> Locating a potential customer does not necessarily
          result in a sale. That said, salespeople can use their interactions with prospects to
          acquire information and conduct market research. For example, salespeople can utilize
          direct mail or online surveys to find out why customers shop for particular products,
          their main priorities, how to best sell, and how to create a long-term sales strategy.
        </li>
      </ul>

      <img src={pic4} alt="" />
      <h2>What Are Some Common Challenges When Prospecting?</h2>
      <p>
        It’s clear that prospecting brings a lot to the table in terms of increased sales, revenue,
        customers, and data. But, at the same time, it can be a profoundly frustrating task.
      </p>
      <p>Here are some common issues salespeople run into and how to address them.</p>

      <h3>Finding Quality Prospects</h3>
      <p>
        This can be tricky. An effective marketing department should have all the data they’d need
        to create a promising pool of potential clients. But sometimes, there’s a shortage of data
        on hand.
      </p>
      <p>
        To address this, align the sales and marketing strategies so everyone is on the same page.
        Determine your addressable market first, then create profiles of your ideal customers. This
        should help you develop a list of pre-qualified leads you can work from.
      </p>

      <h3>Failure To Get a Response</h3>
      <p>
        You’re in competition with a 24/7 barrage of information coming at customers via phone, the
        Internet, and media. So to stand out, you need to provide something extra to your
        communication.
      </p>
      <p>
        One way is through personalization. This helps customers feel more connected. The brevity of
        messaging also allows as many customers tune out over long pitches. Most of all, try to
        provide value. Demonstrate how your products or services can solve client issues through
        infographics, short videos, and tight writing.
      </p>

      <h3>Dealing With Customer Concerns</h3>
      <p>
        Prospects often voice reservations, and sellers must be prepared to address them
        effectively. Consider this an opportunity to strengthen trust with the client.
      </p>
      <p>
        Don’t shut customer concerns down. Instead, listen to their concerns and be prepared to
        alleviate them. Know the common objections that will likely come up and have responses
        ready. Be ready to explain how your company’s products can solve problems or streamline
        procedures. Stay on top of industry trends, reviews, and competitors so you can speak with
        authority when addressing concerns.
      </p>

      <Link to="/apply-now" title="Apply Now">
        <img src={pic5} alt="" />
      </Link>
      <h2>How Can Technology Companies Identify and Target Potential Prospects?</h2>
      <p>
        Understanding your customer and what makes them buy from you is the cornerstone of every
        sales targeting approach. These are some ways to accomplish that.
      </p>

      <h3>Define Your Ideal Customer</h3>
      <p>Buyer personas are helpful for sales teams as well as marketing departments.</p>
      <p>
        Businesses that have created personas or profiles of ideal customers tend to find more
        success. Try to define your target clients in terms of background, demographics, goals,
        interests, and challenges.
      </p>
      <p>To help do this, take advantage of surveys, analytics, and customer feedback.</p>

      <h3>Go For Quality Over Quantity</h3>
      <p>
        We assume that when you generate more prospects, your sales will rise. However, the truth is
        that a more extensive list implies a more diluted message when you consider that just a
        third of your sales professionals’ days are spent conducting sales.
      </p>
      <p>
        You’ll be able to build deeper relationships with each prospect on your list and encourage
        more in-depth, one-on-one discussions if you only include top-tier candidates. Because so
        many transactions require follow-up calls, you want to focus on a select group and make
        those calls before your competitors do.
      </p>

      <h3>Leverage Technology</h3>
      <p>
        To assist their sales strategy, sales representatives have access to various tools and
        technologies today. You can make your prospect management more streamlined by taking
        advantage of tools such as email automation, web conferencing, scheduling software, lead
        scoring, and team networking.
      </p>
      <p>
        The advantages of technology go beyond increased productivity. It can significantly improve
        how you find, interact with, and cultivate connections with prospects. The digital age
        allows you to access a wealth of customer data to gain insightful knowledge. Utilizing this
        information will enable you to customize each prospect’s experience and interact with them
        via the best channels.
      </p>
      <p>
        To find out more about how prospecting and other sales techniques help technology sales,{' '}
        <a href={`https://${process.env.HOST}/apply-now/`}>get started with Rainmakers today</a> to
        get started.
      </p>
    </div>
  );
}
