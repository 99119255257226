import React from 'react';

import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import Block from 'components/Block';
import Button from 'components/Button';
import TableOverlay from 'components/TableOverlay';

import styles from './CandidateEditorEmpty.scss';

const CandidateEditorEmpty = () => {
  const commonBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    largeTitleFont: true,
    addPadding: true,
  };

  const titleBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    noTitlePadding: true,
    className: styles.titleBlock,
  };

  const resumeUploadBlockProps = {
    ...commonBlockProps,
  };

  const genInfoBlockProps = {
    ...commonBlockProps,
    className: styles.firstBlock,
    addFlex: true,
    addPadding: false,
  };

  const previewButtonProps = {
    disabled: true,
    className: styles.previewButton,
  };

  const resumeUploadButtonProps = {
    disabled: true,
    className: styles.previewButton,
  };

  const content = (
    <div className={styles.EditorEmpty}>
      <Block {...titleBlockProps}>
        <div className={styles.emptyTitleContainer}>
          <div className={styles.emptyTitle} />
          <Button {...previewButtonProps} />
        </div>
      </Block>
      <div className={styles.scrollDiv}>
        <Block {...resumeUploadBlockProps}>
          <div className={styles.emptyResumeDetails}>
            <Button {...resumeUploadButtonProps} />
          </div>
        </Block>
        <Block {...genInfoBlockProps}>
          <TableOverlay table="savedSearches" />
        </Block>
      </div>
    </div>
  );

  const layoutWithoutSidebarProps = {
    content,
  };

  return <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />;
};

export default CandidateEditorEmpty;
