/* eslint react/prefer-stateless-function: "off" */
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import UnauthorizedPage from 'pages/UtilPages/UnauthorizedPage';

const EmployeeAuthHOC = () => (WrappedComponent) => {
  const _EmployeeAuthHOC = (props) => {
    const {
      isEmployee = true,
      isAdmin,
      logoutPending = false,
      user: { currentProfileType } = {},
    } = props;

    const location = useLocation();
    const { search } = location;

    if (!currentProfileType && !logoutPending) {
      const to = {
        pathname: '/login/candidate',
        search,
        state: { originalPath: location },
      };

      return <Redirect to={to} />;
    }

    if (!isAdmin && !isEmployee && !logoutPending) return <UnauthorizedPage />;

    return <WrappedComponent {...props} />;
  };

  return _EmployeeAuthHOC;
};

export default EmployeeAuthHOC;
