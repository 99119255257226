/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import jobsPageMetaData, {
  JOB_PAGE_AE,
  JOB_PAGE_CSM,
  JOB_PAGE_EAE,
  JOB_PAGE_MMAE,
  JOB_PAGE_SD,
  JOB_PAGE_SDR,
  JOB_PAGE_SM,
  JOB_PAGE_VP,
} from 'pages/JobsPage/jobsPageMetaData';

import { getAltName } from 'utils/urlUtils';

import pic1 from './assets/1-Raleigh-Tech-Sales-Manager-Jobs.png';
import pic2 from './assets/2-Raleigh-Tech-Sales.png';
import pic3 from './assets/3-Raleigh-Tech-Sales-Management.png';
import pic4 from './assets/4-Contact-Sales-Recruiters.png';

import styles from './RaleighContent.scss';

export function RaleighContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt={getAltName(pic1)} />

      <h2>Tech Sales Opportunities In Raleigh</h2>

      <p>
        Raleigh is the second fastest-expanding metro area in the country, with an average of 70
        people settling in each day. Raleigh’s booming population and number of nationally ranked
        universities have helped it attract educated and skilled tech workers and a wealth of
        well-known tech companies. Businesses such as Lenovo, IBM, Cisco Systems, SAS Institute,
        IQVA, and more have all chosen to establish a footing in Raleigh, further cementing its
        reputation as a growing tech hub.
      </p>

      <p>
        In addition to established companies, an impressive number of tech startups also set up shop
        in the area. Nearly a third of the top businesses in Raleigh are tech startups. This
        demonstrates how the city supports growth and innovation, leading to a wealth of tech sales
        opportunities at all levels.
      </p>

      <h2>Raleigh As a Respected Tech Center</h2>

      <p>
        Thanks to the Research Triangle Park (RTP), established in 1959 and regarded as the greatest
        research facility in the nation, the Raleigh-Durham region has long been regarded as a hub
        for innovation.
      </p>

      <p>
        RTP is home to hundreds of businesses, governmental organizations, and nonprofits, including
        Analytics8, Q2, Sierra Space, and Fidelity Investments. There are also some top universities
        in the area, such as Duke University, North Carolina State University, and the University of
        North Carolina at Chapel Hill.
      </p>

      <p>
        With its reputation for having a talented pool of tech workers close at hand, Raleigh
        continues to draw even more tech businesses and startups, enhancing regional economic
        growth.
      </p>

      <p>
        Raleigh’s plans feature initiatives for fostering innovation and entrepreneurship, forging
        alliances, and generating fair employment opportunities. It’s taking an active hand in
        keeping Raleigh’s reputation as a tech hub by working to attract people with the knowledge,
        training, and experience necessary to prosper in and compete in the regional economy.
      </p>

      <p>
        With all the tech businesses already in place and more coming in, the tech sales landscape
        for the coming years appears to be ever-expanding.
      </p>

      <img src={pic2} alt={getAltName(pic2)} />

      <h2>Living Life Well In Raleigh</h2>

      <p>
        While the cost of living is just a hair above the national average, Raleigh is still much
        cheaper than many other US cities. For those coming to Raleigh and purchasing a home, the
        appreciation rate will grow over time, making property a sound investment.
      </p>

      <p>
        Raleigh has a lot to offer new residents: excellent shops and boutiques, fine dining,
        world-famous entertainment venues, bustling nightlife, music festivals, world-class art
        museums, and diverse culture. It’s also located within easy reach of excellent hiking and
        horse trails, beaches, mountains, recreational parks, and more.
      </p>

      <p>
        Summers and winters are relatively mild, making it perfect for outdoor enthusiasts or anyone
        who wants to stay away from extreme heat or cold.
      </p>

      <h2>Sales Positions Opening In Raleigh</h2>

      <p>
        <a href="/jobs/">Tech sales job openings</a> have started to materialize all across Raleigh.
        Rainmakers delivers exceptional employment opportunities by matching qualified individuals
        with top-tier organizations.
      </p>

      <p>Here are some of the most lucrative IT sales positions that are opening up in Raleigh.</p>

      <h2>Sales Development Representative</h2>

      <p>
        The performance of a tech sales team’s{' '}
        <a href={jobsPageMetaData[JOB_PAGE_SDR].localUrl} target="_blank" rel="noreferrer">
          Sales Development Representatives (SDRs)
        </a>{' '}
        determines how successful it is. An SDR specializes in inside sales activities, focusing on
        research and cold calling, to build sales pipelines for the rest of the team. Typically, the
        best position to start a long-term sales career is in an entry-level SDR position. The job
        requires little to no prior experience and is best suited to people with a strong drive for
        achievement.
      </p>

      <h2>Account Executive</h2>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_AE].localUrl} target="_blank" rel="noreferrer">
          Account Executives (AE)
        </a>{' '}
        are in charge of bringing in new business, handling client accounts, striking deals with
        potential customers, and attending to the requirements of current clients. Since a sizable
        portion of an AE’s compensation comes from commissions, the position necessitates a focused
        effort on the part of the AE to meet established quotas and earning targets.
      </p>

      <img src={pic3} alt={getAltName(pic3)} />

      <h2>Mid-Market Account Executive</h2>

      <p>
        Being a{' '}
        <a href={jobsPageMetaData[JOB_PAGE_MMAE].localUrl} target="_blank" rel="noreferrer">
          Mid-Market Account Executive
        </a>{' '}
        is a significant next step for sales professionals who are passionate about their jobs and
        want to advance. Mid-Market Account Executives manage client relationships, use SaaS/cloud
        technology, oversee training, nurture development, and close deals. Their primary source of
        new sales and commissions comes from middle-market businesses.
      </p>

      <h2>Enterprise Account Executive</h2>

      <p>
        Manufacturers, software developers, telecommunications businesses, retail chains, and other
        key organizations can target large corporations that have the potential to create enormous
        profits with the help of an{' '}
        <a href={jobsPageMetaData[JOB_PAGE_EAE].localUrl} target="_blank" rel="noreferrer">
          Enterprise Account Executive
        </a>
        . Enterprise Account Executives foster innovation, cultivate profitable business
        connections, and uphold profit-oriented sales strategies. The right person for this position
        should be competent, well-organized, and dedicated. Due to the level of responsibility, this
        profession often comes with high-income potential.
      </p>

      <h2>Customer Success Manager</h2>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_CSM].localUrl} target="_blank" rel="noreferrer">
          Customer Success Managers (CSMs)
        </a>{' '}
        focus on developing strong, long-lasting client relationships. Their primary
        responsibilities feature selling products and services to companies in order to foster
        customer success and repeat business. Even though CSMs are a relatively new position in the
        sales landscape, nearly half of high-tech organizations are currently successfully using
        them.
      </p>

      <Link to="/apply-now" title="Apply Now">
        <img src={pic4} alt={getAltName(pic4)} />
      </Link>

      <h2>Sales Director</h2>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_SD].localUrl} target="_blank" rel="noreferrer">
          Sales Directors
        </a>{' '}
        uphold a company’s commitment to offering top-notch customer service. Their main duties are
        to make sure the sales team completes all necessary tasks, develop services, and introduce
        new products to customers. Sales Directors also supervise the sales force, lead training
        sessions, and guarantee that everyone works toward their goals. For this position, a
        candidate must possess exceptional organizational, sales, and leadership skills.
      </p>

      <h2>Sales Manager</h2>

      <p>
        Sales teams and activities fall under the control of{' '}
        <a href={jobsPageMetaData[JOB_PAGE_SM].localUrl} target="_blank" rel="noreferrer">
          Sales Managers
        </a>
        . They are in charge of developing growth and sales strategies, setting and maintaining
        sales objectives, and keeping track of profit growth. A Sales Manager may also be
        responsible for various related tasks such as hiring salespeople, writing sales plans,
        offering coaching, and overseeing operations. The size of the company and the sales
        department often determines the scope of a Sales Manager’s duties.
      </p>

      <h2>Vice President of Sales</h2>

      <p>
        <a href={jobsPageMetaData[JOB_PAGE_VP].localUrl} target="_blank" rel="noreferrer">
          Vice Presidents (VPs) of Sales
        </a>{' '}
        are in charge of a variety of crucial duties within a company. They are in charge of
        enhancing the sales division’s performance and helping them to surpass quotas. They fulfill
        duties often associated with managers, coaches, and business strategists. Vice Presidents of
        Sales oversee sales teams, track earnings, hire salespeople, and perform other related
        duties. They also commonly develop goals and sales plans. As the position carries a lot of
        responsibility, it typically pays rather well.
      </p>

      <h2>Discover Tech Sales Careers at Rainmakers</h2>

      <p>
        Careers in Tech sales offer a variety of chances for professional and personal development.
        For those prepared to put in the time and effort necessary to meet and exceed expectations
        and prove their value to the team, there are many lucrative career paths available.
      </p>

      <p>
        Every team member—from the Vice President of Sales to the Sales Development
        Representatives—is essential for the success of growing businesses. Sales team members with
        the necessary skills and are committed to reaching their objectives benefit all parties.
      </p>

      <p>
        <a href="/apply-now/">Get in touch with Rainmakers</a> today to learn more about these
        exciting opportunities.
      </p>
    </div>
  );
}
