import { call, put, select, take } from 'redux-saga/effects';

import sagaValidateInput from 'connectors/Validator/redux/sagas/sagaValidateInput';

import { SIGNUP_ERRORS_CHECK } from 'pages/EmployeePortal/SignupPage/redux/actions';

import { signupErrorsSet } from '../creators';

import { selectSignupErrors } from '../selectors';

const sagaSignupErrorsCheck = function* sagaSignupErrorsCheck() {
  while (true) {
    const {
      payload: { newValue, name },
    } = yield take(SIGNUP_ERRORS_CHECK);

    const errors = yield select(selectSignupErrors);

    const { inputErrors } = yield call(sagaValidateInput, { newValue, name, errors });

    yield put(signupErrorsSet({ inputErrors }));
  }
};

export default sagaSignupErrorsCheck;
