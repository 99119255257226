import { call, put, select, take } from 'redux-saga/effects';

import sagaValidateInput from 'connectors/Validator/redux/sagas/sagaValidateInput';

import { ONBOARDING_ERRORS_CHECK } from 'pages/CandidatePortal/OnboardingPages/redux/actions';

import { onboardingErrorsSet } from '../creators';

import { selectOnboardingErrors } from '../selectors';

const sagaErrorsCheck = function* sagaErrorsCheck() {
  while (true) {
    const {
      payload: { newValue, name },
    } = yield take(ONBOARDING_ERRORS_CHECK);

    const errors = yield select(selectOnboardingErrors);

    const { inputErrors } = yield call(sagaValidateInput, { newValue, name, errors });

    yield put(onboardingErrorsSet({ inputErrors }));
  }
};

export default sagaErrorsCheck;
