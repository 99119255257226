import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';

import { handleError } from 'utils/common';
import { trackEvent } from 'utils/analytics';
import { segmentFirstApprovedLoginEmployee } from 'segment/eventNames';

import { promiseSignupStatus } from 'pages/EmployeePortal/SignupStatusPage/promises';

import Sidebar from 'components/Sidebar';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import Block from 'components/Block';

import ReadyContainer from 'connectors/ReadyContainer';

import { EmployeeAuthHOC } from 'containers/HOC';
import ConnectContainer from 'containers/ConnectContainer';

import duck from 'shared/store/app';

import Approved from './components/Approved';
import Welcome from './components/Welcome';
import Greeting from './components/Greeting';

import styles from './SignupStatusPage.scss';

@EmployeeAuthHOC()
class SignupStatusPage extends Component {
  componentDidMount() {
    const {
      actions: { userSave },
    } = this.props;

    const onFulfilled = ({ user }) => userSave({ user });

    promiseSignupStatus().then(onFulfilled).catch(handleError);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      actions: { employerPatchStarted },
      isApproved,
      login: { loginPending },
      hasSeenApproved,
    } = nextProps;

    if (!loginPending && isApproved && !hasSeenApproved) {
      employerPatchStarted({ name: 'approvedViewSeen', newValue: true });
      this.trackFirstApprovedLogin();
    }
  }

  trackFirstApprovedLogin = () => {
    const event = segmentFirstApprovedLoginEmployee;

    const properties = {};

    const eventObject = { event, properties };

    trackEvent(eventObject);
  };

  render() {
    const {
      user,
      isApproved,
      login: { loginPending },
      hasSeenApproved,
    } = this.props;

    const approvedProps = { user, isApproved, loginPending, hasSeenApproved };
    const topContent = isApproved ? <Approved {...approvedProps} /> : <Welcome />;

    const blockProps = {
      addWhiteBG: true,
      boxShadow: true,
      className: styles.statusPageBlock,
      addFlex: true,
      addChildFlex: true,
    };

    const content = (
      <div className={styles.WelcomePage}>
        <Block {...blockProps}>
          <div className={styles.welcome}>
            {topContent}
            <Greeting />
          </div>
        </Block>
      </div>
    );

    const layoutWithoutSidebarProps = {
      content,
    };

    const sidebarProps = {
      className: styles.sidebarClass,
      type: isApproved ? 'employeeStatusApproved' : 'employeeStatusUnapproved',
      page: 'employerSignupStatus',
    };

    return !loginPending ? (
      <React.Fragment>
        <Helmet title="Application Status" />
        <ReadyContainer>
          <Sidebar {...sidebarProps} />
          <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />
        </ReadyContainer>
      </React.Fragment>
    ) : null;
  }
}

export default ConnectContainer(duck)(SignupStatusPage);
