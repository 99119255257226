import React from 'react';

import Content from 'components/Content';
import ContentTitle from 'components/ContentTitle';
import ErrorMessage from 'components/ErrorMessage';
import InputCheckboxSlider from 'components/InputCheckboxSlider';
import TableOverlay from 'components/TableOverlay';

import styles from './EmailsFromRainmakers.scss';

const EmailsFromRainmakers = ({
  profileLocked,
  handleCandidateInputChange,
  blogUpdates,
  productUpdates,
}) => {
  const productUpdatesProps = {
    name: 'productUpdates',
    checked: productUpdates,
    handleInputChange: handleCandidateInputChange,
    label: productUpdates ? 'On' : 'Off',
    disabled: profileLocked,
  };

  const blogUpdatesProps = {
    name: 'blogUpdates',
    checked: blogUpdates,
    handleInputChange: handleCandidateInputChange,
    label: blogUpdates ? 'On' : 'Off',
    disabled: profileLocked,
  };

  const profileLockedContent = profileLocked ? (
    <ErrorMessage className={styles.profileLocked}>Profile Locked</ErrorMessage>
  ) : null;

  const profileLockOverlay = profileLocked ? <TableOverlay lockedNotFixed /> : null;

  return (
    <Content className={styles.container}>
      <ContentTitle>EMAILS FROM RAINMAKERS {profileLockedContent}</ContentTitle>

      <div className={styles.contentContainer}>
        {profileLockOverlay}
        <Content className={styles.inputContainer}>
          <Content className={styles.checkboxContainer}>
            <span className={styles.checkboxLabel}>Product updates</span>
            <InputCheckboxSlider {...productUpdatesProps} />
          </Content>
          <Content className={styles.inputDescription}>
            We won&apos;t annoy you with our usual product updates (which happen almost every day),
            but every once in a while we&apos;ll have a cool new feature we&apos;ll want to tell you
            about.
          </Content>
        </Content>

        <Content className={styles.inputContainer}>
          <Content className={styles.checkboxContainer}>
            <span className={styles.checkboxLabel}>Blog and other cool stuff</span>
            <InputCheckboxSlider {...blogUpdatesProps} />
          </Content>
          <Content className={styles.inputDescription}>
            We also consistently publish articles about sales, best practices and general knowledge
            about the industry.
          </Content>
        </Content>
      </div>
    </Content>
  );
};

export default EmailsFromRainmakers;
