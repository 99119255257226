import React from 'react';
import EmptyAvatar from 'shared/assets/missing-user-picture.png';

import { usePictureUrl } from './usePictureUrl';

export function useImageField({
  model = {},
  avatarClass,
  emptyLogo = EmptyAvatar,
  extraOverlay = null,
}) {
  const pictureUrl = usePictureUrl(model);

  const imageComponent = (
    <div className={avatarClass}>
      <img alt="image info" src={pictureUrl || emptyLogo} />
      {extraOverlay}
    </div>
  );

  return {
    imageComponent,
  };
}
