import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, Redirect } from 'react-router-dom';
import queryString from 'query-string';

import { redirectHomepage } from 'utils/navHelpers/index';
import ConnectContainer from 'containers/ConnectContainer';

import duck from 'shared/store/app';

import Hero from './components/Hero';
import HowItWorks from './components/HowItWorks';
import Partners from './components/Partners';
import SocialProof from './components/SocialProof';
import TopQuestions from './components/TopQuestions';
import BlogRoll from './components/BlogRoll';
import Footer from './components/Footer';

const title = 'Sales Recruiting Platform | Rainmakers';
const metaDescription =
  'Looking to hire top sales talent? Rainmakers is here to help. As an elite career marketplace, we connect high-performing salespeople with growing companies recruiting top talent.';
const canonicalUrl = `https://${process.env.HOST}/`;

const Home = (props) => {
  const location = useLocation();
  const { search } = location;

  useEffect(() => {
    const { saveUTMInfo } = props.actions;

    if (search) {
      saveUTMInfo({ utmInfo: queryString.parse(search) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    user,
    isApproved,
    hasSeenApproved,
    login: { loginPending },
  } = props;

  const redirectPath = redirectHomepage(user, isApproved, hasSeenApproved, loginPending);

  const helmetProps = {
    key: 'helmet',
    title,
    meta: [
      { name: 'description', content: metaDescription },
      { property: 'og:url', content: canonicalUrl },
      { property: 'og:title', content: title },
      { property: 'og:description', content: metaDescription },
    ],
    link: [{ rel: 'canonical', href: canonicalUrl }],
  };

  return redirectPath ? (
    <Redirect to={redirectPath} />
  ) : (
    <React.Fragment>
      <Helmet {...helmetProps} />

      <div style={{ width: '100%' }}>
        <Hero />

        <HowItWorks />

        <Partners />

        <SocialProof />

        <TopQuestions />

        <BlogRoll />

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default ConnectContainer(duck)(Home);
