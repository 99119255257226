import React, { Component, Fragment } from 'react';

import { handleError } from 'utils/common';
import { getEntity } from 'api/apiEntity';

import Block from 'components/Block';
import Button from 'components/Button';
import Content from 'components/Content';
import InputSelectInfiniteLoad from 'components/InputSelectInfiniteLoad';

import styles from '../../ToolboxRequisitionEditor.scss';

class RequisitionIntegrations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasMore: true,
      greenhouseJobs: {
        allIds: [],
        byId: {},
      },
    };
  }

  componentDidMount() {
    this.loadMoreGreenhouseJobs(1);
  }

  loadMoreGreenhouseJobs = async (page) => {
    const { requisitionOpening: { attributes: { employerId } = {} } = {} } = this.props;

    const { greenhouseJobs } = this.state;

    const args = {
      type: 'greenhouse/list_jobs',
      config: {
        params: {
          normalizeIt: true,
          page,
          employerId,
        },
      },
    };

    let allIds;
    let byId;
    try {
      ({
        data: { entities: { greenhouseJob: { allIds = [], byId = {} } = {} } = {} } = {},
      } = await getEntity(args));
    } catch (error) {
      handleError(error);
    }

    const hasMore = allIds.length > 0;

    const newById = {
      ...greenhouseJobs.byId,
      ...byId,
    };

    const newAllIds = Array.prototype.concat(greenhouseJobs.allIds, allIds);

    this.setState({
      greenhouseJobs: {
        allIds: newAllIds,
        byId: newById,
      },
      hasMore,
    });
  };

  handleLinkJob = (event) => {
    const {
      actions: { patchResource },
      requisitionOpening: { id },
      slice,
    } = this.props;

    const {
      target: { value: { attributes: { greenhouseJobName } = {}, id: greenhouseJobId } = {} } = {},
    } = event;

    patchResource({
      attributes: {
        greenhouseJobName,
        greenhouseJobId,
      },
      id,
      normalizeIt: true,
      params: {
        includeSet: 'employer',
      },
      requiredFields: ['externalName'],
      ignoreValidations: true,
      slice,
      type: 'requisition_openings',
    });
  };

  handlePatchResource = ({ attributes }) => {
    const {
      actions: { patchResource, resourceUpdate },
      changedAttributes = [],
      requisitionOpening,
      requisitionOpening: {
        attributes: { state },
        id,
      },
      handleSetState,
      slice,
    } = this.props;

    handleSetState({ changeMade: true });

    state === 'pending' &&
      patchResource({
        id,
        attributes,
        normalizeIt: true,
        params: {
          includeSet: 'employer',
        },
        requiredFields: ['externalName'],
        ignoreValidations: true,
        slice,
        type: 'requisition_openings',
      });

    if (state === 'open') {
      const newAttributes = changedAttributes.slice();

      const keys = Object.keys(attributes);

      keys.forEach((key) => {
        if (!newAttributes.includes(key)) {
          newAttributes.push(key);
        }
      });

      handleSetState({ changedAttributes: newAttributes });

      resourceUpdate({
        type: 'requisition_openings',
        slice,
        id,
        resource: {
          ...requisitionOpening,
          attributes: {
            ...requisitionOpening.attributes,
            ...attributes,
          },
        },
      });
    }
  };

  handleInputChange = (event) => {
    const {
      target: { name, type, value, checked },
    } = event;

    const newValue = type === 'checkbox' ? checked : value;

    this.handlePatchResource({ attributes: { [name]: newValue } });
  };

  render() {
    const {
      actions: { patchResource },
      requisitionOpening: {
        attributes: { greenhouseJobId, greenhouseJobName, state } = {},
        id,
      } = {},
      slice,
    } = this.props;

    const {
      greenhouseJobs: { byId = {}, allIds = [] },
      hasMore,
    } = this.state;

    const blockProps = {
      addWhiteBG: true,
      boxShadow: true,
      largeTitleFont: true,
      className: styles.block,
    };

    const reqInformationBlockProps = {
      ...blockProps,
      title: 'Greenhouse',
    };

    const unlinkButtonProps = {
      warning: true,
      onClick: () =>
        patchResource({
          attributes: {
            greenhouseJobId: null,
          },
          id,
          normalizeIt: true,
          params: {
            includeSet: 'employer',
          },
          requiredFields: ['externalName'],
          ignoreValidations: true,
          slice,
          type: 'requisition_openings',
        }),
      className: styles.unlinkButton,
    };

    const unlinkButton = greenhouseJobId ? <Button {...unlinkButtonProps}>Unlink</Button> : null;

    const linkProps = greenhouseJobId
      ? {
          href: `https://app.greenhouse.io/sdash/${greenhouseJobId}`,
          rel: 'noopener noreferrer',
          target: '_blank',
        }
      : {};

    const greenhouseJobsSource = allIds.map((id) => {
      const { attributes: { name: label, requisitionOpeningId } = {} } = byId[id] || {};

      return { label, value: byId[id], disabled: requisitionOpeningId !== null };
    });

    const greenhouseJobProps = {
      source: greenhouseJobsSource,
      name: 'greenhouseJobId',
      handleInputChange: this.handleLinkJob,
      hasMore,
      pageStart: 1,
      value: { type: 'thing', thing: 'nomatch' },
      defaultLabel: 'Select a Greenhouse Job',
      defaultIcon: 'caret-down',
      noMargin: true,
      bigInput: true,
      loadMoreOptions: this.loadMoreGreenhouseJobs,
      disabled: state === 'closed',
    };

    const name =
      greenhouseJobId && byId[greenhouseJobId] && !greenhouseJobName
        ? byId[greenhouseJobId].attributes.name
        : greenhouseJobName;

    const greenhouseJobContent = greenhouseJobId ? (
      <Fragment>
        <a {...linkProps}>{name}</a>
        {unlinkButton}
      </Fragment>
    ) : (
      <InputSelectInfiniteLoad {...greenhouseJobProps} />
    );

    return (
      <div className={styles.ReqFormBlocks}>
        <Block {...reqInformationBlockProps}>
          <Content className={styles.greenhouseJobName}>{greenhouseJobContent}</Content>
        </Block>
      </div>
    );
  }
}

export default RequisitionIntegrations;
