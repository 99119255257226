import { call, cancel, put, select, take, fork } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import snakeCase from 'lodash.snakecase';

import { handleError } from 'utils/common';

import { patchResource } from 'api/apiResource';

import { postUserResource } from 'api/apiUsers';

import sagaValidateResource from 'connectors/Validator/redux/sagas/sagaValidateResource';

import { resourceSelectorFactory, errorsSelectorFactory } from 'reduxStore/selectorFactories';

import { formatType } from 'reduxStore/utils';

import { PATCH_USER_RESOURCE } from '../../actions';

import {
  apiErrorsUpdate,
  resourceErrorsUpdate,
  resourceUpdate,
  resourceDelete,
  savingUpdate,
} from '../../creators';

const doPatchUserResource = function* doPatchUserResource(action) {
  const {
    payload: { attributes, id, params, requiredFields = [], slice, type },
  } = action;

  if (id && slice && type) {
    yield put(savingUpdate({ saving: true, slice }));

    const resource = yield select(resourceSelectorFactory(slice, type, id));

    const {
      attributes: origAttributes,
      attributes: { currentProfileType, currentProfileId, temporary } = {},
      relationships: { user: { data: userIds = [] } = {} } = {},
    } = resource || {};

    const companionResourceId = type === 'users' ? currentProfileId : userIds[0];
    const companionResourceType = type === 'users' ? `${snakeCase(currentProfileType)}` : 'users';

    const companionResource = yield select(
      resourceSelectorFactory(slice, companionResourceType, companionResourceId)
    );

    const newResource = {
      ...resource,
      attributes: {
        ...origAttributes,
        ...attributes,
      },
      id,
    };

    yield put(
      resourceUpdate({
        resource: newResource,
        id,
        slice,
        type,
      })
    );

    const errors = yield select(errorsSelectorFactory(slice, type, id));

    const companionErrors = yield select(
      errorsSelectorFactory(slice, `${companionResource.type}s`, companionResourceId)
    );

    const { inputErrors, isValid } = yield call(sagaValidateResource, {
      resource: newResource.attributes,
      errors,
    });

    const { inputErrors: companionResourceErrors, isValid: companionIsValid } = yield call(
      sagaValidateResource,
      {
        resource: companionResource.attributes,
        errors: companionErrors,
      }
    );

    yield put(
      resourceErrorsUpdate({
        id,
        inputErrors,
        slice,
        type,
      })
    );

    yield put(
      resourceErrorsUpdate({
        id: companionResourceId,
        inputErrors: companionResourceErrors,
        slice,
        type: companionResourceType,
      })
    );

    yield call(delay, 750);

    const needPost =
      requiredFields.every((field) => newResource.attributes[field]) &&
      isValid &&
      companionIsValid &&
      temporary;

    if (needPost) {
      const data =
        type === 'users'
          ? {
              user: {
                email: newResource.attributes.email.trim(),
              },
              [companionResource.type]: {
                ...companionResource.attributes,
              },
            }
          : {
              user: {
                email: companionResource.attributes.email.trim(),
              },
              [resource.type]: {
                ...newResource.attributes,
              },
            };

      try {
        const args = {
          config: {
            params: {
              normalizeIt: true,
              ...params,
            },
          },
          data,
          type: type === 'users' ? `${companionResource.type}s` : type,
        };

        const {
          data: {
            entities: {
              [companionResource.type]: {
                byId: companionById = {},
                allIds: companionAllIds = [],
              } = {},
              [formatType(type)]: { byId = {}, allIds = [] } = {},
            } = {},
          } = {},
        } = yield call(postUserResource, args);

        const resourceWithId = {
          ...byId[allIds[0]],
        };

        const companionResourceWithId = {
          ...companionById[companionAllIds[0]],
        };

        yield put(
          resourceUpdate({
            id: resourceWithId.id,
            resource: {
              ...resourceWithId,
              relationships: {
                [companionResource.type]: {
                  data: [companionResourceWithId.id],
                },
              },
            },
            slice,
            type,
          })
        );

        yield put(
          resourceUpdate({
            id: companionResourceWithId.id,
            resource: {
              ...companionResourceWithId,
              relationships: {
                [formatType(type)]: {
                  data: [resourceWithId.id],
                },
              },
            },
            slice,
            type: `${companionResource.type}s`,
          })
        );

        const idToReplaceWith = type === 'users' ? companionResourceWithId.id : resourceWithId.id;

        const idToReplace = type === 'users' ? companionResource.id : resource.id;

        const { pathname, ...browserState } = window.browserHistory.location;

        if (pathname.includes(idToReplace)) {
          const newPathname = pathname.replace(idToReplace, idToReplaceWith);

          yield call(window.browserHistory.replace, {
            ...browserState,
            pathname: newPathname,
          });
        }

        if (idToReplaceWith !== idToReplace) {
          yield put(
            resourceDelete({
              id: idToReplace,
              slice,
              type,
            })
          );
        }
      } catch (error) {
        handleError(error);

        const { response } = error;

        yield put(
          resourceUpdate({
            id,
            resource,
            slice,
            type,
          })
        );

        yield put(
          resourceUpdate({
            id: companionResource.id,
            resource: companionResource,
            slice,
            type: `${companionResource.type}s`,
          })
        );

        yield put(apiErrorsUpdate({ slice, error: response }));
      }
    }

    const needPatch = !temporary && newResource && isValid;

    if (needPatch) {
      try {
        const args = {
          config: {
            params: {
              normalizeIt: true,
              ...params,
            },
          },
          data: {
            attributes,
            id,
            type,
          },
          id,
          type,
        };

        yield call(patchResource, args);
      } catch (error) {
        handleError(error);

        const { response } = error;

        yield put(
          resourceUpdate({
            id,
            resource,
            slice,
            type,
          })
        );

        yield put(apiErrorsUpdate({ slice, error: response }));
      }
    }

    yield put(savingUpdate({ saving: false, slice }));
  }

  if (id && type && !slice) {
    try {
      const args = {
        config: {
          params: {
            normalizeIt: true,
            ...params,
          },
        },
        data: {
          attributes,
          id,
          type,
        },
        id,
        type,
      };

      yield call(patchResource, args);
    } catch (error) {
      handleError(error);
    }
  }
};

const patchUserResourceSaga = function* patchUserResourceSaga() {
  let lastTask;
  let lastAction = {};
  while (true) {
    const action = yield take(PATCH_USER_RESOURCE);
    const { payload: { attributes = {}, id } = {} } = action;
    const { payload: { attributes: previousAttributes = {}, id: previousId } = {} } = lastAction;

    const attributesToUpdate = Object.keys(attributes);
    const previousAttributesToUpdate = Object.keys(previousAttributes);

    const sameAttributes =
      attributesToUpdate.length === previousAttributesToUpdate.length &&
      attributesToUpdate.every((attr) => previousAttributesToUpdate.includes(attr));

    if (lastTask && sameAttributes && id === previousId) {
      yield cancel(lastTask);
    }

    lastAction = action;
    lastTask = yield fork(doPatchUserResource, action);
  }
};

export default patchUserResourceSaga;
