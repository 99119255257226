import React, { Component } from 'react';

import { employerInputChange } from 'utils/formHelpers/EmployerHelpers';

import Block from 'components/Block';
import Input from 'components/Input';
import InputCheckboxSlider from 'components/InputCheckboxSlider';
import Label from 'components/Label';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';

import styles from './EmployerEditorIntegrations.scss';

const ERROR_NAMES = {
  greenhouseApiKey: 'APIExceptions::Greenhouse::InvalidAPIKey',
  greenhouseUserEmail: 'APIExceptions::Greenhouse::InvalidUserEmail',
};

class EmployerEditorIntegrations extends Component {
  constructor(props) {
    super(props);

    const {
      employer: {
        attributes: {
          greenhouseApiKey: originalGreenhouseApiKey,
          greenhouseUserEmail: originalGreenhouseUserEmail,
        } = {},
      } = {},
    } = props;

    this.state = {
      originalGreenhouseApiKey,
      originalGreenhouseUserEmail,
    };
  }

  handleEmployerInputChange = (event) => {
    const {
      actions: { patchResource, apiErrorsDelete },
      employer: { attributes = {}, id } = {},
      apiErrors = {},
    } = this.props;

    const {
      target: { name },
    } = event;

    this.setState({ [`${name}IsValid`]: false });

    const errorNameToRemove = ERROR_NAMES[name];
    const idToRemove = Object.keys(apiErrors).find(
      (id) => apiErrors[id].name === errorNameToRemove
    );

    if (idToRemove) {
      apiErrorsDelete({ slice: 'toolboxEmployers', id: idToRemove });
    }

    const { newValue } = employerInputChange(event, attributes);

    const revisedValue = newValue === '' ? null : newValue;

    patchResource({
      id,
      attributes: { [name]: revisedValue },
      normalizeIt: true,
      requiredFields: ['name'],
      slice: 'toolboxEmployers',
      type: 'employers',
      successCallback: () => this.setState({ [`${name}IsValid`]: true }),
    });
  };

  render() {
    const {
      employer: { attributes: { greenhouseApiKey, greenhouseUserEmail, pushProspects } = {} } = {},
      saving: employerSaving = undefined,
      apiErrors = {},
    } = this.props;

    const {
      originalGreenhouseApiKey,
      originalGreenhouseUserEmail,
      greenhouseApiKeyIsValid,
      greenhouseUserEmailIsValid,
    } = this.state;

    const apiErrorsIds = Object.keys(apiErrors);

    const apiKeyError = apiErrorsIds.some(
      (id) => apiErrors[id].name === 'APIExceptions::Greenhouse::InvalidAPIKey'
    );
    const emailError = apiErrorsIds.some(
      (id) => apiErrors[id].name === 'APIExceptions::Greenhouse::InvalidUserEmail'
    );

    const apiValidationStatusPending =
      !originalGreenhouseApiKey ||
      !greenhouseApiKey ||
      originalGreenhouseApiKey !== greenhouseApiKey
        ? 'Pending'
        : null;

    const apiValidationStatusVerified =
      (greenhouseApiKey && greenhouseApiKeyIsValid) ||
      (originalGreenhouseApiKey && originalGreenhouseApiKey === greenhouseApiKey)
        ? 'Valid'
        : null;

    const apiValidationStatusInvalid = apiKeyError ? 'Invalid' : null;

    const apiValidationStatus =
      apiValidationStatusInvalid ||
      apiValidationStatusVerified ||
      apiValidationStatusPending ||
      'Pending';

    const emailValidationStatusPending =
      !originalGreenhouseUserEmail ||
      !greenhouseUserEmail ||
      originalGreenhouseUserEmail !== greenhouseUserEmail
        ? 'Pending'
        : null;

    const emailValidationStatusVerified =
      (greenhouseUserEmail && greenhouseUserEmailIsValid) ||
      (originalGreenhouseUserEmail && originalGreenhouseUserEmail === greenhouseUserEmail)
        ? 'Valid'
        : null;

    const emailValidationStatusInvalid = emailError ? 'Invalid' : null;

    const emailValidationStatus =
      emailValidationStatusInvalid ||
      emailValidationStatusVerified ||
      emailValidationStatusPending ||
      'Pending';

    const savingContent = employerSaving ? (
      <div className={styles.autoSaving}>Saving...</div>
    ) : (
      <div className={styles.autoSaved}>Profile Saved</div>
    );

    const saveContent = employerSaving === undefined ? null : savingContent;

    const titleBlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      addPadding: true,
      className: styles.titleBlock,
    };

    const basicInfoBlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      addPadding: true,
      addChildFlex: true,
    };

    const greenhouseApiKeyProps = {
      handleInputChange: this.handleEmployerInputChange,
      type: 'text',
      size: 'xlarge',
      name: 'greenhouseApiKey',
      value: greenhouseApiKey || '',
      placeholder: '',
      autoFocus: true,
      className: styles.noPadding,
    };

    const canEnterEmail = apiValidationStatus === 'Valid';

    const greenhouseEmailProps = {
      handleInputChange: this.handleEmployerInputChange,
      type: 'text',
      size: 'xlarge',
      name: 'greenhouseUserEmail',
      value: greenhouseUserEmail || '',
      placeholder: '',
      disabled: !canEnterEmail,
    };

    const pushProspectsProps = {
      name: 'pushProspects',
      checked: pushProspects,
      handleInputChange: this.handleEmployerInputChange,
      label: pushProspects ? 'On' : 'Off',
    };

    const getValidationColor = (status) => {
      switch (status) {
        case 'Valid':
          return styles.valid;
        case 'Invalid':
          return styles.invalid;
        case 'Pending':
          return styles.pending;
        default:
          return styles.pending;
      }
    };

    const content = (
      <div className={styles.EditorProfile}>
        <Block {...titleBlockProps}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>Integrations</div>
            <div className={styles.saveContent}>{saveContent}</div>
          </div>
        </Block>
        <div className={styles.scrollDiv}>
          <form method="post" autoComplete="off" role="presentation">
            <Block {...basicInfoBlockProps}>
              <div className={styles.basicInfoTop}>
                <Label className={styles.labelFlex} standalone>
                  <div>Greenhouse Harvest API Key</div>
                  <div>
                    Status:{' '}
                    <span className={getValidationColor(apiValidationStatus)}>
                      {apiValidationStatus}
                    </span>
                  </div>
                </Label>
                <Input {...greenhouseApiKeyProps} />
                <Label className={styles.label} disabled={!canEnterEmail}>
                  <div>Greenhouse User Email</div>
                  <div>
                    Status:{' '}
                    <span className={getValidationColor(emailValidationStatus)}>
                      {emailValidationStatus}
                    </span>
                  </div>
                </Label>
                <Input {...greenhouseEmailProps} />
                <div className={styles.hairline} />
                <Label className={styles.label}>
                  <div>
                    <div>Push Prospects</div>
                    <div className={styles.sublabel}>
                      Candidates requested to Reqs not linked to Greenhouse Jobs will be pushed as
                      prospects
                    </div>
                  </div>
                </Label>
                <InputCheckboxSlider {...pushProspectsProps} />
              </div>
            </Block>
          </form>
        </div>
      </div>
    );

    return <LayoutWithoutSidebar content={content} />;
  }
}

export default EmployerEditorIntegrations;
