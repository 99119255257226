import React, { Fragment } from 'react';

import Input from 'components/Input';
import InputCheckbox from 'components/InputCheckbox';

import styles from './ToolboxEducationOrgsSearchBar.scss';

const ToolboxEducationOrgsSearchBar = ({
  handleSearchInputChange,
  handleSearchCheckboxChange,
  name,
  unverifiedDataState,
}) => {
  const autoCompleteProps = {
    name: 'name',
    placeholder: 'Search by institution name',
    size: 'full',
    handleInputChange: handleSearchInputChange,
    isSearch: true,
    value: name,
  };

  const unverifiedDataStateProps = {
    name: 'unverifiedDataState',
    label: 'Unverified Only',
    checked: unverifiedDataState,
    handleInputChange: handleSearchCheckboxChange,
    className: styles.checkbox,
    checkboxMany: true,
  };

  return (
    <Fragment>
      <div className={styles.SearchBarOne}>
        <Input {...autoCompleteProps} />
      </div>
      <div className={styles.SearchBarTwo}>
        <InputCheckbox {...unverifiedDataStateProps} />
      </div>
    </Fragment>
  );
};

export default ToolboxEducationOrgsSearchBar;
