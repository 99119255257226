import React from 'react';

import Block from 'components/Block';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import TableOverlay from 'components/TableOverlay';

import styles from './Empty.scss';

const Empty = () => {
  const titleBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    addPadding: true,
    addFlex: true,
    className: styles.titleBlock,
  };

  const content = (
    <div className={styles.EditorEmpty}>
      <Block {...titleBlockProps}>
        <TableOverlay table="inPlace" />
      </Block>
    </div>
  );

  const layoutWithoutSidebarProps = {
    content,
  };

  return <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />;
};

export default Empty;
