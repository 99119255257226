import React from 'react';

import Input from 'components/Input';

const ExternalName = ({
  requisitionOpening: { attributes: { externalName, state } = {} } = {},
  errors: { externalName: externalNameErrors } = {},
  handleInputChange,
}) => {
  const requisitionNameInputProps = {
    errors: externalName !== null ? externalNameErrors : {},
    handleInputChange,
    label: 'Requisition Name (this is what the Candidate sees)',
    name: 'externalName',
    placeholder: 'Untitled Req',
    required: state !== 'closed',
    size: 'full',
    type: 'text',
    value: externalName,
  };

  return <Input {...requisitionNameInputProps} />;
};

export default ExternalName;
