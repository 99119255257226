import React, { Component } from 'react';

import { VisibleHOC } from 'containers/HOC';

import Dropdown from 'components/Dropdown';

import Bold from 'components/Bold';
import Bolder from 'components/Bolder';
import Button from 'components/Button';
import Content from 'components/Content';
import DialogueModal from 'components/DialogueModal';
import Oblique from 'components/Oblique';

import { trackEvent } from 'utils/analytics';
import { segmentRequisitionAction } from 'segment/eventNames';

import styles from './EmployerRequisitionsActionDropdown.scss';

const MODAL_ACTIONS_OPEN = [
  { label: 'Duplicate', value: 'duplicate' },
  { label: 'Close Req', value: 'close' },
];

const MODAL_ACTIONS_CLOSED = [{ label: 'Duplicate', value: 'duplicate' }];

const MODAL_ACTIONS_PENDING = [
  { label: 'Duplicate', value: 'duplicate' },
  { label: 'Close Req', value: 'delete' },
];
const MANAGER_ACTIONS_OPEN = [
  { label: 'Edit', value: 'edit' },
  { label: 'Duplicate', value: 'duplicate' },
  { label: 'Close Req', value: 'close' },
];
const MANAGER_ACTIONS_CLOSED = [
  { label: 'View', value: 'view' },
  { label: 'Publish', value: 'publish' },
  { label: 'Duplicate', value: 'duplicate' },
];
const MANAGER_ACTIONS_PENDING = [
  { label: 'Edit', value: 'edit' },
  { label: 'Duplicate', value: 'duplicate' },
  { label: 'Close Req', value: 'delete' },
];

@VisibleHOC()
class EmployerRequisitionsActionDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = { promptType: '' };
  }

  trackRequisitionAction = ({ type, reqId }) => {
    const event = segmentRequisitionAction;

    const properties = { type, reqId };

    const eventObject = { event, properties };

    trackEvent(eventObject);
  };

  handleClick = (value) => {
    const {
      requisitionOpening: { id },
      showModal,
    } = this.props;

    if (value) {
      this.trackRequisitionAction({
        type: value,
        reqId: id,
      });
    }

    let route = `/requisitions/${id}/edit`;

    switch (value) {
      case 'edit':
        return showModal({
          parent: 'ManageReqsPage',
          route,
          key: 'ReqDetailModal',
        });
      case 'view':
        route = `/requisitions/${id}/view`;
        return showModal({
          parent: 'ManageReqsPage',
          route,
          key: 'ReqDetailModal',
        });
      case 'duplicate':
        return this.duplicateRequisition();
      case 'close':
        return this.setState({ promptType: 'closeReq' });
      case 'publish':
        return this.updateReq({
          attributes: { state: 'open' },
        });
      case 'delete':
        return this.updateReq({
          attributes: { state: 'closed' },
        });
      default:
        return console.log('whoops');
    }
  };

  duplicateRequisition = () => {
    const { duplicateRequisition, requisitionOpening = {} } = this.props;

    duplicateRequisition({
      requisitionOpening,
      slice: 'employerRequisitions',
      modal: {
        key: 'ReqDetailModal',
        parent: 'ManageReqsPage',
        route: '/requisitions/:id/edit',
      },
    });
  };

  updateReq = ({ attributes }) => {
    const { patchResource, requisitionOpening: { id } = {} } = this.props;

    patchResource({
      id,
      attributes,
      ignoreValidations: true,
      requiredFields: ['externalName'],
      slice: 'employerRequisitions',
      type: 'requisition_openings',
    });
  };

  render() {
    const {
      className,
      isVisible,
      isModal,
      requisitionOpening: { attributes: { state, externalName } = {} } = {},
    } = this.props;

    const { promptType } = this.state;

    const getModalSource = (status) => {
      switch (status) {
        case 'open':
          return MODAL_ACTIONS_OPEN;
        case 'closed':
          return MODAL_ACTIONS_CLOSED;
        case 'pending':
          return MODAL_ACTIONS_PENDING;
        default:
          return [];
      }
    };

    const getManagerSource = (status) => {
      switch (status) {
        case 'open':
          return MANAGER_ACTIONS_OPEN;
        case 'closed':
          return MANAGER_ACTIONS_CLOSED;
        case 'pending':
          return MANAGER_ACTIONS_PENDING;
        default:
          return [];
      }
    };

    const source = isModal ? getModalSource(state) : getManagerSource(state);

    const dropDownProps = {
      above: !isVisible && !isModal,
      className,
      source,
      onChange: this.handleClick,
      value: 'edit',
      defaultValue: isModal ? '...' : 'Actions',
      left: true,
      actions: true,
      defaultIcon: 'caret-down',
      activeIcon: 'caret-up',
      closedReq: state === 'closed',
    };

    const confirmButtonProps = {
      onClick: () => {
        this.updateReq({
          attributes: { state: 'closed' },
        });
        this.setState({ promptType: '' });
      },
      primary: true,
      className: styles.confirm,
    };

    const confirmButton = <Button {...confirmButtonProps}>Confirm</Button>;

    const closeButtonProps = {
      quaternary: true,
      onClick: () => this.setState({ promptType: '' }),
    };

    const closeButton = <Button {...closeButtonProps}>Cancel</Button>;

    const promptContent = promptType ? (
      <DialogueModal>
        <div className={styles.promptModal}>
          <Bolder className={styles.promptTitle}>
            Are you sure you want to close this requisition?
          </Bolder>
          <Bold className={styles.promptSubtitle}>{externalName}</Bold>
          <Content className={styles.promptContent}>
            All active <Bold>Candidates</Bold> assigned to this requisition will be marked as
            <Oblique>passed</Oblique>.
          </Content>

          <div className={styles.promptButtons}>
            {closeButton}
            {confirmButton}
          </div>
        </div>
      </DialogueModal>
    ) : null;

    return (
      <div className={styles.ActionDropdown}>
        <Dropdown {...dropDownProps} />
        {promptContent}
      </div>
    );
  }
}

export default EmployerRequisitionsActionDropdown;
