import React from 'react';

import SearchKeyword from '../../SearchKeyword';
import SearchChips from '../../SearchChips';

import styles from '../SideFiltersPane.scss';

export const KeywordsView = ({
  name = 'keywords',
  label,
  keywords,
  filterName,
  placeholder,
  handleInputChange,
}) => {
  return (
    <>
      <SearchKeyword
        placeholder={placeholder}
        className={styles.keywordContainer}
        label={label}
        noAddButton
        handleKeywordAdd={({ target: { value } }) => {
          if (!keywords.includes(value.trim())) {
            handleInputChange({
              target: { name, value: [...keywords, value] },
            });
          }
        }}
      />
      <SearchChips
        className={styles.noMargin}
        tagClassName={styles.tagClassName}
        handleKeywordDelete={(value) => {
          const newValueArr = keywords.filter((item) => item !== value);
          handleInputChange({ target: { name, value: newValueArr } });
        }}
        keywordsFilter={{ filter: filterName, value: keywords }}
      />
    </>
  );
};
