import * as Sentry from '@sentry/browser';

// set user context
export const setUserContext = (user) => {
  Sentry.configureScope((scope) => {
    scope.setTag('userType', user.currentProfileType);

    scope.setUser({
      id: user.id,
      profileId: user.currentProfileId,
      type: user.currentProfileType,
    });
  });
};

// unset user context
// export const unsetUserContext = () => {
//   if (typeof window === 'undefined' || !window.Raven) return;

//   window.Raven.setUserContext();
// };
//

const SENSITIVE_KEYS = [
  'password',
  'password_confirmation',
  'reset_password_token',
  'confirmation_token',
  'picture',
  'email',
  'passw',
  'secret',
  'token',
  'key',
  'crypt',
  'salt',
  'certificate',
  'otp',
  'ssn',
];

// scrub personal and sensitive data
export const scrubIt = (event, hint) => {
  const error = hint.originalException;
  const codesExcluded = [404, 401];

  if (error.response && codesExcluded.includes(error.response.status)) {
    return null;
  }

  const eventKeys = Object.keys(event);

  eventKeys.forEach((key) => {
    if (key === 'extra') {
      if (event[key].__serialized__) {
        SENSITIVE_KEYS.forEach((sensitiveKey) => {
          if (event[key].__serialized__[sensitiveKey]) {
            delete event[key].__serialized__[sensitiveKey];
          }
        });
      }
    }

    if (key === 'request') {
      if (event[key].headers) {
        SENSITIVE_KEYS.forEach((sensitiveKey) => {
          if (event[key].headers[sensitiveKey]) {
            delete event[key].headers[sensitiveKey];
          }
        });
      }
    }

    SENSITIVE_KEYS.forEach((sensitiveKey) => {
      if (event[key] && event[key][sensitiveKey]) {
        delete event[key][sensitiveKey];
      }
    });
  });

  return event;
};
