import React from 'react';
import ImageUploader from 'components/ImageUploader';

const EmployeeImageUploader = ({ employeeId, employeeImageUploadStarted, pictureUrl }) => {
  return (
    <ImageUploader
      name="Employee"
      entityId={employeeId}
      imageUploadStarted={employeeImageUploadStarted}
      pictureUrl={pictureUrl}
    />
  );
};

export default EmployeeImageUploader;
