import React from 'react';
import { Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

import Bolder from 'components/Bolder';
import styles from './Footer.scss';

const Footer = () => (
  <div className={styles.Footer}>
    <div className={styles.Container}>
      <div className={styles.Column}>
        <img src={require('../../assets/rm-logo.svg')} alt="Rainmakers" className={styles.Image} />
      </div>

      <div className={styles.Column}>
        <Bolder className={styles.SecondaryTitle}>Candidates</Bolder>
        <Link to="/">Candidate Home</Link>
        <NavHashLink to="/#How-it-works">How it works</NavHashLink>
        <NavHashLink to="/#Top-questions">Why join?</NavHashLink>
        <Link to="/faq/">Candidate FAQ</Link>
      </div>

      <div className={styles.Column}>
        <Bolder className={styles.SecondaryTitle}>Employers</Bolder>
        <Link to="/employers/">Employer Home</Link>
        <NavHashLink to="/employers/#Why-join">Why join?</NavHashLink>
        <NavHashLink to="/employers/#How-it-works">How it works</NavHashLink>
        <Link to="/employer-faq/">Employer FAQ</Link>
        <Link to="/my-hiring-profile/">AE Hiring Profile</Link>
      </div>

      <div className={styles.Column}>
        <Bolder className={styles.SecondaryTitle}>Rainmakers</Bolder>
        <Link to="/about-us/">About Us</Link>
        <a href="/blog/" target="_blank">
          News / Articles
        </a>
        <Link to="/careers/">Careers at Rainmakers</Link>
        <Link to="/terms/">Terms & Conditions</Link>
        <Link to="/privacy/">Privacy Policy</Link>
        <Link to="/sitemap/">Sitemap</Link>
      </div>

      <div className={styles.Legal}>
        <div className={styles.leftLegal}>© Rainmaker Labs, Inc.</div>
        <div className={styles.rightLegal}>
          Supported by:{' '}
          <img
            src={require('../../assets/sovren-logo.svg')}
            alt="Sovren"
            className={styles.sovrenLogo}
          />
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
