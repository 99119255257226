import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { postNestedEntity, getNestedEntity, patchEntity } from 'api/apiEntity';

import { selectCandidate } from '../selectors';

import { CANDIDATE_FILE_UPLOAD_STARTED } from '../actions';

import {
  candidateUpdate,
  candidateSaveStarted,
  candidateSaveDone,
  candidateSaveError,
  resumeSaveStarted,
  resumeSaveDone,
} from '../creators';

const patchCandidateFile = function* patchCandidateFile(action) {
  const {
    payload: { file, type },
  } = action;

  yield put(candidateSaveStarted());
  yield put(resumeSaveStarted());

  const { id: candidateId } = yield select(selectCandidate);

  try {
    const formData = new FormData();
    formData.append(`data[attributes][${type}]`, file);
    formData.append('data[type]', 'candidate');
    formData.append('data[id]', candidateId);

    const args = {
      data: formData,
      id: candidateId,
      type: 'candidates',
    };

    const {
      data: { candidate: origCandidate },
    } = yield call(patchEntity, args);

    const postArgs = {
      id: candidateId,
      type: 'candidates',
      nestedType: 'parse_resume',
    };

    const {
      data: { candidate },
    } = yield call(postNestedEntity, postArgs);

    yield put(candidateUpdate({ name: type, newValue: candidate[type] || origCandidate[type] }));

    const candEduArgs = {
      id: candidateId,
      type: 'candidates',
      nestedType: 'candidate_educations',
      config: {
        params: {
          includeSet: 'education_organization',
          sortBy: 'finished_at_desc_nulls_first,started_at_desc',
          camelizeIt: true,
        },
      },
    };

    const workHistArgs = {
      id: candidateId,
      type: 'candidates',
      nestedType: 'work_histories',
      config: {
        params: {
          includeSet: 'company_sales_periods',
          sortBy: 'end_date_desc_nulls_first,start_date_desc',
          camelizeIt: true,
        },
      },
    };

    const [
      {
        data: { workHistories = [] },
      },
      {
        data: { candidateEducations = [] },
      },
    ] = yield all([call(getNestedEntity, workHistArgs), call(getNestedEntity, candEduArgs)]);

    yield put(candidateUpdate({ name: 'workHistories', newValue: workHistories }));

    yield put(candidateUpdate({ name: 'candidateEducations', newValue: candidateEducations }));

    yield put(candidateSaveDone());
    yield put(resumeSaveDone());
  } catch (error) {
    handleError(error);
    yield put(candidateSaveError(error));
    yield put(resumeSaveDone());
  }
};

const sagaCandidateFileUpload = function* sagaCandidateFileUpload() {
  yield takeLatest(CANDIDATE_FILE_UPLOAD_STARTED, patchCandidateFile);
};

export default sagaCandidateFileUpload;
