import {
  MainContent,
  SDRContent,
  AEJobsContent,
  EAEJobsContent,
  CSMJobsContent,
  MMAEJobsContent,
  VPJobsContent,
  SMJobsContent,
  SDJobsContent,
} from './components';

export const JOB_PAGE_MAIN = 'main';
export const JOB_PAGE_SDR = 'sales-development-representative';
export const JOB_PAGE_EAE = 'enterprise-account-executive';
export const JOB_PAGE_AE = 'account-executive';
export const JOB_PAGE_CSM = 'customer-success-manager';
export const JOB_PAGE_MMAE = 'mid-market-account-executive';
export const JOB_PAGE_VP = 'vp-sales';
export const JOB_PAGE_SM = 'sales-manager';
export const JOB_PAGE_SD = 'sales-director';

export default {
  [JOB_PAGE_MAIN]: {
    title: 'Best Sales Jobs In Tech',
    sideNavName: 'Roles Overview',
    h1: 'Best Sales Jobs In Tech',
    description:
      'If you are looking for a career as a technology sales professional, consider the various roles and paths you can take. Learn more about which positions are in high',
    url: `https://${process.env.HOST}/jobs/`,
    localUrl: '/jobs/',
    header: 'All Tech Sales Roles',
    content: MainContent,
    sideNav: false,
  },
  [JOB_PAGE_AE]: {
    title: 'Account Executive (AE): Salary, Job Description & Desired Skills',
    sideNavName: 'Account Executive (AE)',
    description:
      'An Account Executive is a type of salesperson who serves the needs of existing clients and assists in closing sales with new customers. Salary can range depending on location.',
    url: `https://${process.env.HOST}/jobs/account-executive/`,
    localUrl: '/jobs/account-executive',
    header: 'Account Executive (AE)',
    content: AEJobsContent,
    sideNav: true,
  },
  [JOB_PAGE_CSM]: {
    title: 'Customer Success Manager: Job Description & Salary Information',
    sideNavName: 'Customer Success Manager (CSM)',
    description:
      'Customer Success Managers (CSMs) help your company’s customers as they evolve from prospects into engaged users of your services or products.',
    url: `https://${process.env.HOST}/jobs/customer-success-manager/`,
    localUrl: '/jobs/customer-success-manager',
    header: 'Customer Success Manager (CSM)',
    content: CSMJobsContent,
    sideNav: true,
  },
  [JOB_PAGE_EAE]: {
    title: 'Enterprise Account Executive: Salary & Job Description',
    sideNavName: 'Executive Account Executive',
    description:
      'Enterprise Account Executive’s primary responsibilities include growing new earnings through strategic, relationship-based selling. Their salary can range based on location and company.',
    url: `https://${process.env.HOST}/jobs/enterprise-account-executive/`,
    localUrl: '/jobs/enterprise-account-executive',
    header: 'Enterprise Account Executive',
    content: EAEJobsContent,
    sideNav: true,
  },
  [JOB_PAGE_MMAE]: {
    title: 'Mid-Market Account Executive: Job Description & Salary Information',
    sideNavName: 'Mid-Market Account Executive',
    description:
      'A Mid-Market Account Executive’s salary can range depending on experience and location. Their primary role is to provide training and facilitate sales enablement around SaaS and technology.',
    url: `https://${process.env.HOST}/jobs/mid-market-account-executive/`,
    localUrl: '/jobs/mid-market-account-executive',
    header: 'Mid-Market Account Executive',
    content: MMAEJobsContent,
    sideNav: true,
  },
  [JOB_PAGE_SDR]: {
    title: 'Sales Development Representative (SDR) Jobs',
    sideNavName: 'Sales Development Representative (SDR)',
    description:
      'Rainmakers connects salespeople with companies looking to fill Sales Development Representative (SDR) jobs. Learn more about this role and connect with companies that are now hiring!',
    url: `https://${process.env.HOST}/jobs/sales-development-representative/`,
    localUrl: '/jobs/sales-development-representative',
    header: 'Sales Development Representative (SDR)',
    content: SDRContent,
    sideNav: true,
  },
  [JOB_PAGE_SD]: {
    title: 'Find A Sales Director Job | Salary & Job Description Information',
    sideNavName: 'Sales Director',
    description:
      'Rainmakers helps technology companies find ideal employees, including Sales Directors. Find a job near you, view a general job description',
    url: `https://${process.env.HOST}/jobs/sales-director/`,
    localUrl: '/jobs/sales-director',
    header: 'Sales Director',
    content: SDJobsContent,
    sideNav: true,
  },
  [JOB_PAGE_SM]: {
    title: 'Technical Sales Manager Jobs: Description, Salary, & Qualifications',
    sideNavName: 'Technical Sales Manager',
    description:
      'As a Sales Manager in the technology industry, your primary responsibility is to manage & progress your sales team and identify opportunities. In 2020, research indicated the average salary to be near $132,000.',
    url: `https://${process.env.HOST}/jobs/sales-manager/`,
    localUrl: '/jobs/sales-manager',
    header: 'Sales Manager',
    content: SMJobsContent,
    sideNav: true,
  },
  [JOB_PAGE_VP]: {
    title: 'VP of Sales Jobs In The Technology Industry: Job Description & Salary Information',
    sideNavName: 'VP of Sales',
    description:
      'A technical Vice President (VP) of Sales is in charge of training a business’s sales team to attain and outperform targets. Salary ranges typically between $222,000 and $304,000.',
    url: `https://${process.env.HOST}/jobs/vp-sales/`,
    localUrl: '/jobs/vp-sales',
    header: 'VP of Sales',
    content: VPJobsContent,
    sideNav: true,
  },
};
