import React from 'react';

import Button from 'components/Button';

import banner from './assets/banner.jpeg';

import styles from './EmployeeDisabledPageContent.scss';

function EmployeeDisabledPageContent({
  actionButtonText,
  actionButtonDisabled,
  handleActionButtonClick,
}) {
  return (
    <div className={styles.disabledContentContainer}>
      <div className={styles.bannerContainer}>
        <img src={banner} alt="banner" />
      </div>
      <div className={styles.bannerText}>
        Want access to more top sales candidates? Get access to the full Rainmakers database.
      </div>
      <div>
        <Button primary={true} disabled={actionButtonDisabled} onClick={handleActionButtonClick}>
          {actionButtonText}
        </Button>
      </div>
    </div>
  );
}

export default EmployeeDisabledPageContent;
