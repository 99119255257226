import React, { Fragment } from 'react';

import Input from 'components/Input';
import DropdownSelectMany from 'components/DropdownSelectMany';

import styles from './ToolboxEmployersSearchBar.scss';

const SearchBar = ({
  applicationStatus,
  name,
  handleSearchInputChange,
  handleSearchFilterChange,
}) => {
  const employerSearchProps = {
    name: 'name',
    placeholder: 'Search by employer name',
    size: 'full',
    handleInputChange: handleSearchInputChange,
    isSearch: true,
    value: name,
  };

  const applicationStatusOptions = [
    {
      label: 'Approved',
      value: 'approved',
    },
    {
      label: 'Pending',
      value: 'pending',
    },
    {
      label: 'Archived',
      value: 'archived',
    },
  ];

  const applicationStatusSource = applicationStatusOptions.map((state) => ({
    indent: 0,
    label: state.label,
    optionType: 'role',
    subCategories: [],
    value: {
      type: 'state',
      state: state.value,
    },
  }));

  const selectedStates = applicationStatus.map((state) => ({ state, type: 'state' }));

  const stateFilterProps = {
    className: styles.searchBarFilter,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    source: applicationStatusSource,
    name: 'applicationStatus',
    key: 'applicationStatus',
    optionsLarge: true,
    isLocation: true,
    onChange: (value) =>
      handleSearchFilterChange({
        target: {
          subCategories: value.subCategories,
          label: value.label,
          value: value.value,
          name: 'applicationStatus',
          optionType: value.optionType,
        },
      }),
    selectedValues: selectedStates,
    defaultLabel: 'Click to select application status',
  };

  return (
    <Fragment>
      <div className={styles.SearchBarOne}>
        <div className={styles.stack}>
          <DropdownSelectMany {...stateFilterProps} />
        </div>
        <div className={styles.stack}>
          <Input {...employerSearchProps} />
        </div>
      </div>
    </Fragment>
  );
};

export default SearchBar;
