import React, { Fragment } from 'react';

import Dropdown from 'components/Dropdown';
import DropdownSelectMany from 'components/DropdownSelectMany';
import Label from 'components/Label';
import InputCheckbox from 'components/InputCheckbox';

import SearchKeyword from '../SearchKeyword';
import SearchChips from '../SearchChips';

import styles from './SearchMoreFilters.scss';

const SearchMoreFilters = ({
  candidateSearchOptions,
  currentlySelectedRole,
  handleAddMoreFilter,
  handleAddCheckboxPickerMoreFilter,
  handleKeywordDelete,
  handleKeywordAdd,
  moreFilters,
}) => {
  const { role } = candidateSearchOptions;

  const { additionalFilters: moreFilterItems = [] } =
    role.find((r) => r.name === currentlySelectedRole) || {};

  const filtersContent = moreFilterItems.map((filter) => {
    const items = filter.filters.map((item) => {
      if (item.type === 'checkbox') {
        const props = {
          key: item.title,
          name: item.title,
          label: item.title,
          id: item.id,
          checked: moreFilters.some((filter) => filter.title === item.title),
          className: styles.filterItem,
          handleInputChange: () => handleAddMoreFilter(item),
        };

        return <InputCheckbox {...props} />;
      }

      if (item.type === 'checkbox_picker') {
        const source = item.values.reduce((acc, curr) => {
          let subOpts = [];
          let subValues = [];
          if (curr.subcategories) {
            subValues = curr.subcategories.map((subOpt) => ({
              label: subOpt.name,
              optionType: subOpt.type,
              queries: subOpt.queries,
              title: item.title,
              trackingHash: subOpt.trackingHash,
              type: item.type,
              id: item.id,
            }));

            const parent = {
              label: curr.name,
              optionType: curr.type,
              queries: curr.queries,
              title: item.title,
              subcategories: subValues,
              trackingHash: curr.trackingHash,
              type: item.type,
            };

            subOpts = curr.subcategories.map((subOpt) => ({
              indent: 1,
              label: subOpt.name,
              optionType: subOpt.type,
              id: subOpt.id,
              parent,
              trackingHash: subOpt.trackingHash,
              value: subOpt.queries,
            }));
          }

          const allOpts = Array.prototype.concat(
            [
              {
                indent: 0,
                label: curr.name,
                optionType: curr.type,
                subcategories: subValues,
                trackingHash: curr.trackingHash,
                value: curr.queries,
                id: curr.id,
              },
            ],
            subOpts
          );

          return acc.concat(allOpts);
        }, []);

        const selectedValues = moreFilters
          .filter((filter) => filter.title === item.title)
          .map((filt) => filt.queries);

        const props = {
          className: styles.searchBarFilter,
          defaultIcon: 'caret-down',
          activeIcon: 'caret-up',
          onChange: (value) =>
            handleAddCheckboxPickerMoreFilter({
              label: value.label,
              optionType: value.optionType,
              parent: value.parent,
              queries: value.value,
              title: item.title,
              subcategories: value.subcategories,
              trackingHash: value.trackingHash,
              id: value.id,
              type: item.type,
            }),
          searchFilter: true,
          optionsLarge: true,
          source,
          defaultLabel: item.title === 'Location' ? 'Click to select a location' : item.title,
          selectedValues,
          name: item.title,
          key: item.title,
          id: item.id,
        };

        return (
          <>
            <Label className={styles.secondaryLabel}>{item.title}</Label>
            <DropdownSelectMany {...props} />
          </>
        );
      }

      if (item.type === 'picker') {
        const source = item.values.map((opt) => ({
          label: opt.name,
          value: opt.queries,
          indent: opt.indent,
        }));

        const { queries = [] } = moreFilters.find((filter) => filter.title === item.title) || {};

        const value =
          moreFilters.some((filter) => filter.title === item.title) &&
          source.map((opt) => opt.value[0]).includes(`${queries[0]}`)
            ? [queries]
            : null;

        const props = {
          className: styles.searchBarFilter,
          defaultIcon: 'caret-down',
          activeIcon: 'caret-up',
          onChange: (value) =>
            handleAddMoreFilter({
              title: item.title,
              queries: value.value,
              id: item.id,
            }),
          searchFilter: true,
          optionsLarge: true,
          source,
          defaultValue: item.title,
          value,
          name: item.title,
          id: item.id,
          key: item.title,
        };

        return (
          <>
            <Label className={styles.secondaryLabel}>{item.title}</Label>
            <Dropdown {...props} />
          </>
        );
      }

      if (item.type === 'keywords') {
        return (
          <div className={styles.keywordContainer}>
            <SearchKeyword
              placeholder={item.placeholder || "Try 'Saas' or 'CRM'"}
              handleKeywordAdd={(data) => handleKeywordAdd(item.id, data)}
            />
            <SearchChips
              handleKeywordDelete={(...data) => handleKeywordDelete(item.id, ...data)}
              keywordsFilter={moreFilters.find(({ id }) => id === item.id)}
            />
          </div>
        );
      }

      return null;
    });

    return (
      items &&
      items.length > 0 && (
        <div className={styles.filterGroup}>
          {filter.header && items && items.length > 0 && (
            <Label className={styles.label} key="roleHeader">
              {filter.header}
            </Label>
          )}
          {items}
        </div>
      )
    );
  });

  return <div className={styles.SearchMoreFilters}>{filtersContent}</div>;
};

export default SearchMoreFilters;
