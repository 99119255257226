import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { AdminAuthHOC } from 'containers/HOC';

import { makeLikeParam } from 'utils/paramUtils';

import ConnectContainer from 'containers/ConnectContainer';
import { useTableQuery } from 'hooks';

import ReadyContainer from 'connectors/ReadyContainer';

import Sidebar from 'components/Sidebar';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';

import ToolboxRequisitionsTable from './components/ToolboxRequisitionsTable';

import duck from './redux';

import styles from './ToolboxRequisitionsPage.scss';

const route = '/toolbox/requisitions/';

const ToolboxRequisitionsPage = (props) => {
  const {
    actions: {
      duplicateRequisition,
      entityLoadStarted,
      patchResource,
      resourceDelete,
      resourceUpdate,
      showModal,
      pickerOptionsLoadStarted,
    },
    children,
    isLoading,
    modal,
    pickerOptions: { role: roleOptions = [] } = {},
    requisitionOpenings: { byId = {}, allIds = [] } = {},
    total,
    user,
    employers: { byId: employersById = {} } = {},
    requisitionRequirements: { byId: requisitionRequirementsById = {} } = {},
    page = 1,
  } = props;

  const {
    queryParams,
    loadMore,
    handleSearchInputChange,
    handleSearchFilterChange,
    handleSortChange,
    handleSearchCheckboxChange,
  } = useTableQuery(
    route,
    page,
    {
      complete: {
        type: 'boolean',
        defaultValue: null,
      },
      employerName: {
        type: 'string',
        defaultValue: null,
      },
      externalName: {
        type: 'string',
        defaultValue: null,
      },
      role: {
        type: 'array',
        defaultValue: [],
      },
      sortBy: {
        type: 'string',
        defaultValue: 'created_at_desc',
      },
      state: {
        type: 'array',
        defaultValue: ['open', 'pending'],
      },
    },
    {
      type: 'requisition_openings',
      slice: 'toolboxRequisitions',
    },
    {
      includeSet: 'employer',
      requisitionCandidateId: 'null',
      suggestionSetId: 'null',
    },
    {
      employerName: makeLikeParam,
      externalName: makeLikeParam,
    }
  );

  useEffect(() => {
    pickerOptionsLoadStarted();
  }, [pickerOptionsLoadStarted]);

  return [
    <Helmet key="helmet" title="Toolbox | Requisitions" />,

    <ReadyContainer key="readyContainer">
      <Sidebar type="toolbox" page="toolboxRequisitions" />

      <LayoutWithoutSidebar
        content={
          <div className={styles.toolboxMainContent}>
            <ToolboxRequisitionsTable
              {...queryParams}
              duplicateRequisition={duplicateRequisition}
              entityLoadStarted={entityLoadStarted}
              isLoading={isLoading}
              user={user}
              requisitionOpenings={allIds.map((id) => byId[id])}
              employersById={employersById}
              modal={modal}
              patchResource={patchResource}
              requisitionRequirementsById={requisitionRequirementsById}
              resourceDelete={resourceDelete}
              resourceUpdate={resourceUpdate}
              roleOptions={roleOptions}
              showModal={showModal}
              total={total}
              loadMoreRequisitions={loadMore}
              handleSearchCheckboxChange={handleSearchCheckboxChange}
              handleSearchInputChange={handleSearchInputChange}
              handleSearchFilterChange={handleSearchFilterChange}
              handleSortChange={handleSortChange}
            />
          </div>
        }
      />

      {children}
    </ReadyContainer>,
  ];
};

export default ConnectContainer(duck)(AdminAuthHOC()(ToolboxRequisitionsPage));
