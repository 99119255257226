import React from 'react';

import Table from 'components/Table';
import TableRow from 'components/Table/components/TableRow';
import TableCell from 'components/Table/components/TableCell';
import EmployerData from 'components/EmployerData';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';

import styles from './CandReqBlocksSettings.scss';

const CandReqBlocksSettings = ({
  employers: { byId: employersById } = {},
  requisitionCandidates: { allIds = [], byId = {} } = {},
  loadMoreRequisitionCandidates: loadMore,
  requisitionCandidatesTotal: total,
  requisitionCandidatesLoading: isLoading,
}) => {
  const makeRows = (id) => {
    const {
      attributes: { state } = {},
      relationships: { employer: { data: employerIds = [] } = {} } = {},
    } = byId[id] || {};

    const { attributes: employer = {} } = employersById[employerIds[0]] || {};
    const employerDataProps = {
      employer,
      employerId: employerIds[0],
    };

    const employerContent = (
      <div className={styles.employerContent}>
        <EmployerData {...employerDataProps} />
      </div>
    );

    const statusContent = <div className={styles.statusContent}>{state}</div>;

    const row = (
      <TableRow id={id} key={id} className={styles.employerRow}>
        <TableCell key={1}>{employerContent}</TableCell>
        <TableCell flex key={2}>
          {statusContent}
        </TableCell>
      </TableRow>
    );

    return row;
  };

  const tableContent = allIds.map(makeRows);

  const tableHeader = [
    {
      content: 'Company',
      headerClassName: styles.companyHeader,
    },
    {
      content: 'Status',
      headerClassName: styles.otherHeader,
    },
  ];

  const infiniteScrollProps = {
    pageStart: 0,
    loadMore,
    hasMore: total > allIds.length,
    loader: <InfiniteScrollLoader />,
    useWindow: false,
  };

  const titleString = 'Active Requisition Blocks';

  const titleContent = <div className={styles.blockTitle}>{titleString}</div>;

  const tableProps = {
    blockProps: {
      titleClass: styles.mainBlockTitle,
      boxShadow: false,
    },
    titleContent,
    noBlockFlex: true,
    header: tableHeader,
    tableContent,
    infiniteScrollProps,
    tableName: 'candReqEmployers',
    isEmpty: !isLoading && allIds.length === 0,
    emptyTextString: 'No Active Requisition Candidates',
  };

  return <Table {...tableProps} />;
};

export default CandReqBlocksSettings;
