import { handleActions } from 'redux-actions';

import {
  CANDIDATE_ERRORS_RESET,
  CANDIDATE_ERRORS_SET,
  CANDIDATE_SAVE_STARTED,
  CANDIDATE_SAVE_DONE,
  CANDIDATE_SAVE_ERROR,
  RESUME_SAVE_STARTED,
  RESUME_SAVE_DONE,
} from '../actions';

const initialState = {
  resumeSaving: false,
  candidateSaving: false,
  candidateServerError: null,
  errors: {
    firstName: {
      isNotEmpty: [null],
    },
    lastName: {
      isNotEmpty: [null],
    },
  },
};

const handleCandidateSaveStarted = (state = initialState) => ({
  ...state,
  candidateSaving: true,
  candidateServerError: null,
});

const handleCandidateSaveDone = (state = initialState) => ({
  ...state,
  candidateSaving: false,
  candidateServerError: null,
});

const handleCandidateSaveError = (state = initialState, action) => ({
  ...state,
  candidateSaving: false,
  candidateServerError: action.payload,
});

const handleResumeSaveStarted = (state = initialState) => ({
  ...state,
  resumeSaving: true,
});

const handleResumeSaveDone = (state = initialState) => ({
  ...state,
  resumeSaving: false,
});

const handleCandidateErrorsSet = (state = initialState, action) => {
  const {
    payload: { inputErrors },
  } = action;

  return {
    ...state,
    errors: {
      ...state.errors,
      ...inputErrors,
    },
  };
};

const handleCandidateErrorsReset = (state = initialState) => ({
  ...state,
  errors: initialState.errors,
});

const actionHandlers = {
  [CANDIDATE_ERRORS_RESET]: handleCandidateErrorsReset,
  [CANDIDATE_ERRORS_SET]: handleCandidateErrorsSet,
  [CANDIDATE_SAVE_STARTED]: handleCandidateSaveStarted,
  [CANDIDATE_SAVE_DONE]: handleCandidateSaveDone,
  [CANDIDATE_SAVE_ERROR]: handleCandidateSaveError,
  [RESUME_SAVE_STARTED]: handleResumeSaveStarted,
  [RESUME_SAVE_DONE]: handleResumeSaveDone,
};

const reduceCandidate = handleActions(actionHandlers, initialState);

export default reduceCandidate;
