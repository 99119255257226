import { call, put, take } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { promisePostCompanies } from 'pages/AdminPortal/ToolboxCandidateEditorPage/promises';

import { CREATE_COMPANY_AND_BLOCK_STARTED } from '../actions';

import { candidateCompanyBlocksCreateStarted } from '../creators';

const sagaCreateCompanyAndBlockStarted = function* sagaCreateCompanyAndBlockStarted() {
  while (true) {
    const {
      payload: { candidateId, companyName },
    } = yield take(CREATE_COMPANY_AND_BLOCK_STARTED);

    try {
      const { company } = yield call(promisePostCompanies, {
        data: {
          name: companyName.trim(),
        },
      });

      const payload = {
        companyId: company.id,
        candidateId,
        ownerId: candidateId,
        ownerType: 'Candidate',
      };

      yield put(candidateCompanyBlocksCreateStarted(payload));
    } catch (error) {
      handleError(error);
    }
  }
};

export default sagaCreateCompanyAndBlockStarted;
