import React from 'react';

import EmployerData from 'components/EmployerData';
import Table from 'components/Table';
import TableRow from 'components/Table/components/TableRow';
import TableCell from 'components/Table/components/TableCell';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';

import styles from './CandidateBookmarksTable.scss';

const CandidateBookmarksTable = ({
  bookmarks: { byId = {}, allIds = [] } = {},
  loadMoreBookmarks,
  bookmarksTotal,
}) => {
  const makeEmployerRows = (id) => {
    const { attributes: employer = {} } = byId[id] || {};

    const employerDataProps = {
      employer,
    };

    const employerContent = (
      <div className={styles.employerContent}>
        <EmployerData {...employerDataProps} />
      </div>
    );

    const row = (
      <TableRow id={id} key={id} className={styles.employerRow}>
        <TableCell key={1}>{employerContent}</TableCell>
      </TableRow>
    );

    return row;
  };

  const tableContent = allIds.map(makeEmployerRows);

  const infiniteScrollProps = {
    pageStart: 0,
    loadMore: loadMoreBookmarks,
    hasMore: bookmarksTotal > allIds.length,
    loader: <InfiniteScrollLoader key="infiniteScrollLoader" />,
    useWindow: false,
  };

  const titleString = 'Saved';

  const titleContent = <div className={styles.blockTitle}>{titleString}</div>;

  const tableProps = {
    titleContent,
    noBlockFlex: true,
    tableContent,
    infiniteScrollProps,
    isEmpty: allIds.length === 0,
    emptyTextString: 'No Bookmarks',
  };

  return <Table {...tableProps} />;
};

export default CandidateBookmarksTable;
