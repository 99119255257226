import React from 'react';

import Button from 'components/Button';
import InputFile from 'components/InputFile';
import TableOverlay from 'components/TableOverlay';

import styles from './MainContent.scss';

const MainContent = ({
  lastName,
  resume,
  reverseOrder,
  handleSubmitResume,
  workHistories,
  candidateEducations,
  handleAddEducation,
  handleAddWorkHistory,
  makeWorkHistoryInputs,
  makeEducationInputs,
  parsingResume,
  resumeTypeError,
  recentGrad,
}) => {
  const resumeNameIdxStart = resume.search(/\/original\//) + 10;
  const resumeNameIdxEnd = resume.search(/pdf\?/) + 3;
  const resumePathToParse = resume.substring(resumeNameIdxStart, resumeNameIdxEnd);
  const resumeName = resumePathToParse.split('/')[1] || `${lastName}_resume.pdf`;

  const resumeUploadNewButtonProps = {
    tertiary: true,
    className: styles.resumeUploadNewButton,
    fakeButton: true,
  };

  const resumeInputFileProps = {
    placeholder: '',
    containerClassName: styles.candidateResumeInputContainer,
    onChange: handleSubmitResume,
    isButton: true,
    name: 'resume',
    accept: 'application/pdf',
  };

  const workHistoryInputs = workHistories.map(makeWorkHistoryInputs);

  const educationInputs = candidateEducations.map(makeEducationInputs);

  const addWorkHistoryProps = {
    tertiary: true,
    onClick: handleAddWorkHistory,
    className: styles.addButton,
    disabled: workHistories.some((wh) => wh.isEditing),
  };

  const addEducationProps = {
    tertiary: true,
    onClick: handleAddEducation,
    className: styles.addButton,
    disabled: candidateEducations.some((edu) => edu.isEditing),
  };

  const addWorkHistoryButton = <Button {...addWorkHistoryProps}>+ Add work history</Button>;

  const addEducationButton = <Button {...addEducationProps}>+ Add education</Button>;

  const hasResume = resume !== '/resumes/original/missing.png';

  const uploadResumeButtonProps = {
    secondary: true,
    className: styles.uploadButton,
    fakeButton: true,
  };

  const newResumeInputFileProps = {
    placeholder: '',
    className: styles.candidateResumeInput,
    containerClassName: styles.candidateResumeInputContainer,
    onChange: handleSubmitResume,
    isButton: true,
    name: 'resume',
    accept: 'application/pdf',
  };

  const resumeStuff = hasResume ? (
    <div className={styles.resumeContainer}>
      <div className={styles.resumeImage}>
        <div className={styles.resumeRow1} />
        <div className={styles.resumeRow2} />
        <div className={styles.resumeRow2} />
      </div>
      <div className={styles.resumeNameContainer}>
        <div className={styles.resumeName}>{resumeName}</div>
        <InputFile {...resumeInputFileProps}>
          <Button {...resumeUploadNewButtonProps}>Replace Resume</Button>
        </InputFile>
        <div className={styles.gray}>*PDF required</div>
        <div className={styles.errorMessage}>{resumeTypeError}</div>
      </div>
    </div>
  ) : (
    <div className={styles.noResumeContainer}>
      <InputFile {...newResumeInputFileProps}>
        <Button {...uploadResumeButtonProps}>Upload Resume</Button>
      </InputFile>
      <div className={styles.gray}>*PDF required</div>
    </div>
  );

  const inputsContent = recentGrad ? (
    <div className={styles.contentContainer} data-testid="MainContent">
      {resumeStuff}
      <div className={styles.container}>
        <div className={styles.containerTitle}>EDUCATION</div>
        {educationInputs}
        {addEducationButton}
      </div>
      <div className={styles.container}>
        <div className={styles.containerTitle}>WORK HISTORY</div>
        {workHistoryInputs}
        {addWorkHistoryButton}
      </div>
    </div>
  ) : (
    <div className={styles.contentContainer} data-testid="MainContent">
      {resumeStuff}
      <div className={styles.container}>
        <div className={styles.containerTitle}>WORK HISTORY</div>
        {workHistoryInputs}
        {addWorkHistoryButton}
      </div>
      <div className={styles.container}>
        <div className={styles.containerTitle}>EDUCATION</div>
        {educationInputs}
        {addEducationButton}
      </div>
    </div>
  );

  const parsingResumeEducation = (
    <div className={styles.container}>
      <div className={styles.containerTitle}>EDUCATION</div>
      <div className={styles.loadingContent}>
        <div className={styles.imagePlaceholder} />
        <div className={styles.contentPlaceholder}>
          <div className={styles.fakeContent1} />
          <div className={styles.fakeContent1} />
          <div className={styles.fakeContent2} />
        </div>
      </div>
    </div>
  );

  const parsingResumeWork = (
    <div className={styles.container}>
      <div className={styles.containerTitle}>WORK HISTORY</div>
      <div className={styles.loadingContent}>
        <div className={styles.imagePlaceholder} />
        <div className={styles.contentPlaceholder}>
          <div className={styles.fakeContent1} />
          <div className={styles.fakeContent1} />
          <div className={styles.fakeContent2} />
        </div>
      </div>
    </div>
  );

  const parsingResumeResume = (
    <div className={styles.resumeContainer}>
      <div className={styles.resumeImage}>
        <div className={styles.resumeRow1} />
        <div className={styles.resumeRow2} />
        <div className={styles.resumeRow2} />
      </div>
    </div>
  );

  const parsingResumeContent = reverseOrder ? (
    <div className={styles.contentContainer} data-testid="MainContent">
      {parsingResumeResume}
      {parsingResumeEducation}
      {parsingResumeWork}
      <TableOverlay table="image" />
    </div>
  ) : (
    <div className={styles.contentContainer} data-testid="MainContent">
      {parsingResumeResume}
      {parsingResumeWork}
      {parsingResumeEducation}
      <TableOverlay table="image" />
    </div>
  );

  return parsingResume ? parsingResumeContent : inputsContent;
};

export default MainContent;
