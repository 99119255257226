export const toggleArray = ({ array, value }) => {
  if (array.includes(value)) {
    return array.filter((item) => item !== value);
  }

  return [...array, value];
};

export const toggleLocationsArray = ({ array, value }) => {
  if (array.map((val) => val[val.type]).includes(value[value.type])) {
    return array.filter((val) => val[val.type] !== value[value.type]);
  }

  return [...array, value];
};

export const makeYears = (startYear) => {
  let currentYear = new Date().getFullYear() + 1;
  const years = [];
  const startYearToUse = startYear + 1 || 1901;

  while (currentYear >= startYearToUse) {
    currentYear -= 1;
    years.push({ label: `${currentYear}`, value: `${currentYear}` });
  }

  return years;
};

export const monthSource = [
  {
    label: 'Jan',
    value: 1,
  },
  {
    label: 'Feb',
    value: 2,
  },
  {
    label: 'Mar',
    value: 3,
  },
  {
    label: 'Apr',
    value: 4,
  },
  {
    label: 'May',
    value: 5,
  },
  {
    label: 'Jun',
    value: 6,
  },
  {
    label: 'Jul',
    value: 7,
  },
  {
    label: 'Aug',
    value: 8,
  },
  {
    label: 'Sep',
    value: 9,
  },
  {
    label: 'Oct',
    value: 10,
  },
  {
    label: 'Nov',
    value: 11,
  },
  {
    label: 'Dec',
    value: 12,
  },
];

export const roundYears = (number, precision) => {
  const factor = 10 ** precision;
  const tempNum = number * factor;
  const roundedTempNum = Math.round(tempNum);
  return roundedTempNum / factor;
};
