import { call, select, put, take } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { getNestedEntity, postNestedEntity, patchNestedEntity } from 'api/apiEntity';

import { candidateProfilePatchDone } from 'connectors/User/redux/creators';

import { selectUser } from 'connectors/User/redux/selectors';

import { REORDER_WORK_STARTED } from '../actions';

import { serverErrorSet } from '../creators';

const sagaReorderWorkHistoriesStarted = function* sagaReorderWorkHistoriesStarted() {
  while (true) {
    const {
      payload: { origWorkHistories, origCandidateEducations, idx: currentIndex },
    } = yield take(REORDER_WORK_STARTED);

    try {
      const workHistory = origWorkHistories[currentIndex];

      const origEditingWorkHistories = origWorkHistories.filter(
        (wh, idx) => wh.isEditing && !wh.id && idx !== currentIndex
      );

      const {
        currentProfile,
        currentProfile: { id: candidateId },
      } = yield select(selectUser);

      const whArgs = {
        data: {
          id: workHistory.id || '',
          type: 'work_history',
          attributes: {
            ...workHistory,
            candidateId,
          },
        },
        id: candidateId,
        type: 'candidates',
        nestedType: 'work_histories',
        nestedId: workHistory.id || '',
        config: {
          params: {
            camelizeIt: true,
          },
        },
      };

      const {
        data: { workHistory: newWorkHistory },
      } = yield workHistory.id ? call(patchNestedEntity, whArgs) : call(postNestedEntity, whArgs);

      const args = {
        id: candidateId,
        type: 'candidates',
        nestedType: 'work_histories',
        config: {
          params: {
            includeSet: 'company_sales_periods',
            sortBy: 'end_date_desc_nulls_first,start_date_desc',
            normalizeIt: true,
          },
        },
      };

      const {
        data: {
          entities,
          entities: {
            company: { byId: companyById = {} } = {},
            workHistory: { allIds: workHistoryAllIds = [], byId: workHistoryById = {} } = {},
          } = {},
        } = {},
      } = yield call(getNestedEntity, args);

      const didMove = currentIndex !== workHistoryAllIds.indexOf(newWorkHistory.id);

      const orderedWorkHistories = workHistoryAllIds.map((id) => {
        const {
          [id]: {
            relationships: {
              company: { data: companyIds = [] } = {},
              salesPeriods: { data: salesPeriodsIds = [], type: salesPeriodsTypes = [] } = {},
            } = {},
          } = {},
        } = workHistoryById || {};

        const salesPeriods = salesPeriodsIds.map((spId, idx) => {
          const {
            [salesPeriodsTypes[idx]]: { byId: { [spId]: { attributes = {} } = {} } = {} } = {},
          } = entities;

          return attributes;
        });

        return {
          ...workHistoryById[id].attributes,
          company: companyById[companyIds[0]].attributes,
          salesPeriods,
        };
      });

      const newWorkHistories = orderedWorkHistories.map((wh) => {
        const wasEditing = origWorkHistories.findIndex(
          (work) => work.id && work.id === wh.id && wh.isEditing
        );
        if (didMove && wh.id === newWorkHistory.id) {
          return {
            ...wh,
            moved: true,
            isEditing: false,
          };
        }
        return {
          ...wh,
          isEditing: wasEditing >= 0,
        };
      });

      const candidateProfile = {
        ...currentProfile,
        workHistories: [...newWorkHistories, ...origEditingWorkHistories],
        candidateEducations: origCandidateEducations,
      };

      yield put(candidateProfilePatchDone({ candidateProfile }));
    } catch (error) {
      handleError(error);

      const {
        response: {
          data: { errors: { full_messages: errors = ['Please try again'] } = {} } = {},
        } = {},
      } = error || {};

      yield put(serverErrorSet({ errors }));
    }
  }
};

export default sagaReorderWorkHistoriesStarted;
