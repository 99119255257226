const prefix = 'Rainmakers/Candidate/Home/';

export const PROFILE_CARDS_LOAD_STARTED = `${prefix}PROFILE_CARDS_LOAD_STARTED`;
export const PROFILE_CARDS_LOAD_DONE = `${prefix}PROFILE_CARDS_LOAD_DONE`;

export const BATCH_REQUEST_LOAD_STARTED = `${prefix}BATCH_REQUEST_LOAD_STARTED`;
export const BATCH_REQUEST_LOAD_DONE = `${prefix}BATCH_REQUEST_LOAD_DONE`;

export const BATCH_REQUEST_PATCH_STARTED = `${prefix}BATCH_REQUEST_PATCH_STARTED`;
export const BATCH_REQUEST_PATCH_DONE = `${prefix}BATCH_REQUEST_PATCH_DONE`;

export const BATCH_REQUEST_REREQUEST_STARTED = `${prefix}BATCH_REQUEST_REREQUEST_STARTED`;
export const BATCH_REQUEST_REREQUEST_DONE = `${prefix}BATCH_REQUEST_REREQUEST_DONE`;
