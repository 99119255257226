import { handleActions } from 'redux-actions';

const initialState = {
  entities: {
    candidate: {
      byId: {},
      allIds: [],
    },
    user: {
      byId: {},
      allIds: [],
    },
  },
  meta: {
    candidate: {
      pagination: {},
    },
  },
};

export default handleActions({}, initialState);
