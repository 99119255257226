import React from 'react';

import Content from 'components/Content';
import ContentTitle from 'components/ContentTitle';
import FormattedArray from 'components/FormattedArray';

import styles from './AboutRole.scss';

const AboutRole = ({ requisitionOpening: { attributes: { overview } = {} } = {} }) => {
  const overviewProps = { array: overview };

  const overviewContent =
    overview && overview.length > 0 && overview.every((view) => view !== '') ? (
      <FormattedArray {...overviewProps} />
    ) : null;

  return overviewContent ? (
    <div className={styles.AboutRole}>
      <ContentTitle>ABOUT THE ROLE</ContentTitle>
      <Content className={styles.AboutContent}>{overviewContent}</Content>
    </div>
  ) : null;
};

export default AboutRole;
