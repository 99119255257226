import { createSelector } from 'reselect';
import { entitySelectorFactory } from 'reduxStore/selectorFactories';

const selectCompany = createSelector(
  entitySelectorFactory('toolboxCompanies', 'company'),
  (state, props) => {
    const {
      match: { params: { id } = {} },
    } = props || {};

    return id;
  },
  (companies, id) => {
    const { byId: { [id]: company = {} } = {} } = companies;

    return company;
  }
);

export default selectCompany;
