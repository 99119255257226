import React from 'react';
import { useLocation, useParams } from 'react-router';

import Modal from 'connectors/Modal';

const MODAL_PAGES = {
  CandidateOpportunitiesPage: {
    source: 'candidateOpportunities',
    field: 'id',
  },
  DashboardPage: {
    source: 'requisitionCandidates',
    field: 'candidateId',
  },
  CandidateSearchPage: {
    source: 'results',
    field: 'id',
    loader: 'resultsLoadStarted',
  },
  CandidateBookmarksPage: {
    source: 'bookmarkCandidates',
    field: 'id',
  },
  FeaturedPage: {
    source: 'featuredCandidates',
    field: 'id',
  },
  ToolboxApproachesPage: {
    source: 'requisitionCandidates',
    field: 'id',
  },
  ToolboxAdminsPage: {
    source: 'rainmakersAdmins',
    field: 'id',
  },
  ToolboxCandidatesPage: {
    source: 'candidates',
    field: 'id',
  },
  ToolboxEmployersPage: {
    source: 'employers',
    field: 'id',
  },
  ToolboxCompaniesPage: {
    source: 'companies',
    field: 'id',
  },
  ToolboxEducationOrgsPage: {
    source: 'educationOrganizations',
    field: 'id',
  },
  ToolboxCohortsPage: {
    source: 'cohorts',
    field: 'id',
  },
  ToolboxRequisitionsPage: {
    source: 'requisitionOpenings',
    field: 'id',
  },
  EmployerEmployeesPage: {
    source: 'employees',
    field: 'id',
  },
  ManageReqsPage: {
    source: 'requisitionOpenings',
    field: 'id',
  },
};

const ModalHOC = (args) => (WrappedComponent) => {
  const _ModalHOC = (props) => {
    const currentLocation = useLocation();
    const { id: currentId } = useParams();

    const { state: { parent: currentParent = null } = {} } = currentLocation || {};

    const { [currentParent]: { source: paginatorArray, field: paginatorField } = {} } = MODAL_PAGES;

    const {
      modal: { key } = {},
      isEmployee,
      isCandidate,
      isAdmin,
      isLoggedIn,
      [paginatorArray]: paginator,
    } = props;

    const { paginatorUrl = null, centered = false, noOverlay = false } = args || {};

    const modalProps =
      paginatorArray && paginatorUrl
        ? {
            paginatorField,
            paginator,
            currentId,
            paginatorUrl,
            noOverlay,
            ...props,
          }
        : {
            noOverlay,
            ...props,
          };

    const modalContent = (
      <Modal {...modalProps} centered={centered}>
        <WrappedComponent {...props} />
      </Modal>
    );

    const pureContent = <WrappedComponent {...props} />;

    const loggedIn = isLoggedIn || isEmployee || isCandidate || isAdmin;

    const content = key && loggedIn ? modalContent : pureContent;

    return content;
  };

  return _ModalHOC;
};

export default ModalHOC;
