import React from 'react';

import Label from 'components/Label';
import { toDollars, toCents } from 'utils/moneyUtils';
import Input from 'components/Input';

import styles from './RecentCompensation.scss';

const RecentCompensation = ({ salesPeriod, handleSalesPeriodInputChange, trackEvent }) => {
  const {
    id: salesPeriodId,
    oteCents: currentOTE,
    salaryCents: currentSalary,
    totalCompCents: totalComp,
    type,
  } = salesPeriod || {};

  const currentSalaryInputProps = {
    size: 'full',
    name: 'salaryCents',
    oteInputOne: true,
    value: currentSalary ? toDollars(currentSalary) : '',
    placeholder: '',
    moneyInput: true,
    className: styles.oteInputContainer,
    handleInputChange: (event) =>
      handleSalesPeriodInputChange({
        event: {
          ...event,
          target: {
            ...event.target,
            value: toCents(event.target.value),
            name: event.target.name,
          },
        },
        salesPeriodId,
      }),
  };

  const currentOTEInputProps = {
    size: 'full',
    name: 'oteCents',
    oteInputOne: true,
    value: currentOTE ? toDollars(currentOTE) : '',
    placeholder: '',
    moneyInput: true,
    className: styles.oteInputContainer,
    handleOnBlur: () => trackEvent({ name: 'oteCents' }),
    handleInputChange: (event) =>
      handleSalesPeriodInputChange({
        event: {
          ...event,
          target: {
            ...event.target,
            value: toCents(event.target.value),
            name: event.target.name,
          },
        },
        salesPeriodId,
      }),
  };

  const totalCompInputProps = {
    size: 'full',
    name: 'totalCompCents',
    oteInputOne: true,
    value: totalComp ? toDollars(totalComp) : '',
    placeholder: '',
    moneyInput: true,
    className: styles.oteInputContainer,
    handleOnBlur: () => trackEvent({ name: 'totalCompCents' }),
    handleInputChange: (event) =>
      handleSalesPeriodInputChange({
        event: {
          ...event,
          target: {
            ...event.target,
            value: toCents(event.target.value),
            name: event.target.name,
          },
        },
        salesPeriodId,
      }),
  };

  const oteLabel = type === 'CSMSalesPeriod' ? 'Total Comp' : 'OTE';

  const oteProps = type === 'CSMSalesPeriod' ? totalCompInputProps : currentOTEInputProps;

  return (
    <div className={styles.whatSoldContainer} data-testid="RecentCompensation">
      <div className={styles.containerTitle}>RECENT COMPENSATION</div>

      <div className={styles.formRow}>
        <Label required className={styles.label}>
          Base Salary
        </Label>
        <Label required className={styles.label}>
          {oteLabel}
        </Label>
      </div>

      <div className={styles.formRow}>
        <Input {...currentSalaryInputProps} />
        <Input {...oteProps} />
      </div>
    </div>
  );
};

export default RecentCompensation;
