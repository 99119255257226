import React, { useState } from 'react';

import { handleError } from 'utils/common';
import { toggleArray } from 'utils/formUtils';

import { getEntity } from 'api/apiEntity';

import { VisibleHOC } from 'containers/HOC';

import PrivateReqCanFeedbackEmployerPass from 'connectors/DialogModals/PrivateReqCanFeedbackEmployerPass';
import PrivateReqCanFeedbackEmployerHire from 'connectors/DialogModals/PrivateReqCanFeedbackEmployerHire';

import Bold from 'components/Bold';
import Bolder from 'components/Bolder';
import Button from 'components/Button';
import DialogueModal from 'components/DialogueModal';
import Dropdown from 'components/Dropdown';

import PassForm from 'pages/CandidatePortal/CandidatePassPage/components/PassForm';

import styles from './ToolboxApproachesActionDropdown.scss';

const LOAD_PRIVATE_PROMPT_TYPES = [
  'candidate_passed',
  'candidate_pass_pending',
  'employer_passed',
  'hired',
];

const ToolboxApproachesActionDropdown = ({
  actions: { entityLoadDone, patchResource, resourceUpdate } = {},
  firstName,
  isVisible,
  lastName,
  privateReqCanFeedbackEmployee,
  privateReqCanFeedbackCandidate,
  privateReqCanFeedbackCandidate: { attributes: { feedback = '' } = {} } = {},
  requisitionCandidate,
  requisitionCandidate: {
    attributes: {
      attentionRequired = false,
      companyFeedback = [],
      offerFeedback = [],
      reason = '',
      roleFeedback = [],
    } = {},
    id,
  } = {},
  slice,
  state,
}) => {
  const [promptType, setPromptType] = useState(null);
  const [lastActivityType, setLastActivityType] = useState('');

  const onChange = async (promptType) => {
    if (promptType === 'attention_required') {
      return patchResource({
        attributes: {
          attentionRequired: false,
        },
        id,
        normalizeIt: true,
        slice,
        type: 'requisition_candidates',
      });
    }

    if (LOAD_PRIVATE_PROMPT_TYPES.includes(promptType)) {
      const args = {
        config: {
          params: {
            normalizeIt: true,
            requisitionCandidateId: id,
          },
        },
        type: 'private_req_can_feedbacks',
      };

      let data;
      try {
        ({ data = {} } = await getEntity(args));
      } catch (error) {
        handleError(error);
      }

      entityLoadDone({
        slice,
        type: 'private_req_can_feedback',
        data,
        updateOnly: true,
      });
    }

    setPromptType(promptType);
  };

  const inactiveStates = ['hired', 'inactive', 'passed', 'employer_passed', 'candidate_passed'];

  const source = inactiveStates.includes(state)
    ? [
        {
          label: 'Activate',
          value: 'active',
        },
      ]
    : [
        {
          label: 'Candidate Pass Pending',
          value: 'candidate_pass_pending',
        },
        {
          label: 'Candidate Pass',
          value: 'candidate_passed',
        },
        {
          label: 'Employer Pass',
          value: 'employer_passed',
        },
        {
          label: 'Hired',
          value: 'hired',
        },
        {
          label: 'TC Action',
          value: 'touch',
        },
        {
          label: 'Deactivate',
          value: 'inactive',
        },
      ];

  if (attentionRequired) {
    source.unshift({ label: 'Clear Attention Required', value: 'attention_required' });
  }

  if (state === 'candidate_pass_pending') {
    source.unshift({ label: 'Activate', value: 'active' });
  }

  const dropDownProps = {
    source,
    onChange,
    value: 'send_message',
    defaultValue: 'Actions',
    above: !isVisible,
    left: true,
    actionsOneOption: true,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
  };

  const handleCancel = () => setPromptType(null);

  const hireSubmit = async ({ baseCents, offerSignedDate, startDate, variableCents }) => {
    setPromptType(null);

    patchResource({
      attributes: {
        baseCents,
        offerSignedDate,
        startDate,
        variableCents,
        state: 'hired',
      },
      id,
      normalizeIt: true,
      slice,
      type: 'requisition_candidates',
    });

    if (privateReqCanFeedbackEmployee.id) {
      patchResource({
        attributes: {
          rating: 10,
        },
        id: privateReqCanFeedbackEmployee.id,
        normalizeIt: true,
        slice,
        type: 'private_req_can_feedbacks',
      });
    }
  };

  const passSubmit = async ({ feedback, passedOn, rating }) => {
    setPromptType(null);

    patchResource({
      attributes: {
        passedOn,
        state: 'employer_passed',
      },
      id,
      normalizeIt: true,
      slice,
      type: 'requisition_candidates',
    });

    if (privateReqCanFeedbackEmployee.id) {
      patchResource({
        attributes: {
          rating,
          feedback,
        },
        id: privateReqCanFeedbackEmployee.id,
        normalizeIt: true,
        slice,
        type: 'private_req_can_feedbacks',
      });
    }
  };

  const commonModalProps = {
    firstName,
    lastName,
    handleCancel,
  };

  const hireModalProps = {
    ...commonModalProps,
    handleSubmit: hireSubmit,
  };

  const passModalProps = {
    ...commonModalProps,
    handleSubmit: passSubmit,
  };

  const confirmTouch =
    promptType === 'touch'
      ? () =>
          patchResource({
            attributes: {
              lastActivityType,
              lastActivityAt: new Date().toISOString(),
            },
            id,
            normalizeIt: true,
            slice,
            type: 'requisition_candidates',
          })
      : null;

  const confirmActivate =
    promptType === 'active'
      ? () =>
          patchResource({
            attributes: {
              state: 'active',
            },
            id,
            normalizeIt: true,
            slice,
            type: 'requisition_candidates',
          })
      : null;

  const confirmDeactivate =
    promptType === 'inactive'
      ? () =>
          patchResource({
            attributes: {
              state: 'inactive',
            },
            id,
            normalizeIt: true,
            slice,
            type: 'requisition_candidates',
          })
      : null;

  const confirmFunction = confirmTouch || confirmActivate || confirmDeactivate;

  const touchDisabled = promptType === 'touch' && !lastActivityType;

  const confirmButtonProps = {
    onClick: () => {
      confirmFunction();
      setPromptType(null);
    },
    primary: true,
    className: styles.confirm,
    disabled: touchDisabled,
  };

  const confirmButton = <Button {...confirmButtonProps}>Confirm</Button>;

  const closeButtonProps = {
    quaternary: true,
    onClick: () => setPromptType(null),
  };

  const closeButton = <Button {...closeButtonProps}>Cancel</Button>;

  const activateString =
    promptType === 'active' ? (
      <span>
        Activate{' '}
        <Bolder>
          {firstName} {lastName}
        </Bolder>
        ?
      </span>
    ) : null;

  const deactivateString =
    promptType === 'inactive' ? (
      <span>
        Deactivate{' '}
        <Bolder>
          {firstName} {lastName}
        </Bolder>
        ?
      </span>
    ) : null;

  const touchString =
    promptType === 'touch' ? (
      <div className={styles.promptTitle}>
        <Bolder>Action Type</Bolder>
        <div className={styles.subtitle}>Please choose one of the following types:</div>
      </div>
    ) : null;

  const promptString = touchString || activateString || deactivateString;

  const touchTypes = ['Candidate', 'Employer', 'Rainmakers_Admin', 'Anonymous'];

  const touchButtons = touchTypes.map((type) => {
    const props = {
      secondary: true,
      active: lastActivityType && lastActivityType.toLowerCase() === type.toLowerCase(),
      onClick: () => setLastActivityType(type.toLowerCase()),
    };

    return (
      <Button key={type} {...props}>
        {type}
      </Button>
    );
  });

  const inputs =
    promptType === 'touch' ? <div className={styles.inputsTouchColumn}>{touchButtons}</div> : null;

  const passProps = {
    offerFeedback,
    companyFeedback,
    roleFeedback,
    reason,
    feedback,
    user: {
      currentProfileType: 'RainmakersAdmin',
    },
    candidate: {
      firstName,
      lastName,
    },
    opportunities: [
      {
        id,
      },
    ],
    handleCancel: () => setPromptType(null),
    handleFeedbackChange: (event) => {
      const {
        target: { name, value },
      } = event;

      const { attributes: { [name]: array = [] } = {} } = requisitionCandidate;

      resourceUpdate({
        id,
        slice,
        type: 'requisition_candidates',
        resource: {
          ...requisitionCandidate,
          attributes: {
            ...requisitionCandidate.attributes,
            [name]: toggleArray({ array, value }),
          },
        },
      });
    },
    handleReasonChange: (event) => {
      const {
        target: { name, value },
      } = event;

      if (name === 'feedback') {
        resourceUpdate({
          id: privateReqCanFeedbackCandidate.id,
          slice,
          type: 'private_req_can_feedbacks',
          resource: {
            ...privateReqCanFeedbackCandidate,
            attributes: {
              ...privateReqCanFeedbackCandidate.attributes,
              [name]: value,
            },
          },
        });
      } else {
        resourceUpdate({
          id,
          slice,
          type: 'requisition_candidates',
          resource: {
            ...requisitionCandidate,
            attributes: {
              ...requisitionCandidate.attributes,
              [name]: value,
            },
          },
        });
      }
    },
    handleSubmitFeedback: async () => {
      const pending = promptType === 'candidate_pass_pending';

      patchResource({
        id,
        attributes: {
          state: pending ? 'candidate_pass_pending' : 'candidate_passed',
          companyFeedback,
          offerFeedback,
          reason,
          roleFeedback,
        },
        type: 'requisition_candidates',
        slice,
        params: {
          includeSet: 'candidate',
        },
        normalizeIt: true,
        requiredFields: [],
      });

      if (privateReqCanFeedbackCandidate.id) {
        patchResource({
          attributes: {
            feedback,
          },
          id: privateReqCanFeedbackCandidate.id,
          normalizeIt: true,
          slice,
          type: 'private_req_can_feedbacks',
        });
      }

      setPromptType(null);
    },
    match: {
      params: {
        id,
      },
    },
  };

  const candidatePassModal =
    promptType === 'candidate_passed' || promptType === 'candidate_pass_pending' ? (
      <DialogueModal className={styles.bigModal}>
        <PassForm {...passProps} />
      </DialogueModal>
    ) : null;

  const hireModal =
    promptType === 'hired' ? <PrivateReqCanFeedbackEmployerHire {...hireModalProps} /> : null;

  const passModal =
    promptType === 'employer_passed' ? (
      <PrivateReqCanFeedbackEmployerPass {...passModalProps} />
    ) : null;

  const touchModal =
    promptType === 'touch' || promptType === 'active' || promptType === 'inactive' ? (
      <DialogueModal>
        <div className={styles.promptModal}>
          <Bold className={styles.promptContent}>{promptString}</Bold>

          <div className={styles.inputContent}>{inputs}</div>

          <div className={styles.promptButtons}>
            {closeButton}
            {confirmButton}
          </div>
        </div>
      </DialogueModal>
    ) : null;

  const promptContent = hireModal || passModal || touchModal || candidatePassModal;

  return (
    <div className={styles.ActionDropdown}>
      <Dropdown {...dropDownProps} />
      {promptContent}
    </div>
  );
};

export default VisibleHOC()(ToolboxApproachesActionDropdown);
