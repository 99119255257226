import React, { Component } from 'react';
import isEmpty from 'lodash.isempty';
import { Helmet } from 'react-helmet-async';

import ConnectContainer from 'containers/ConnectContainer';
import { ModalHOC } from 'containers/HOC';

import ReadyContainer from 'connectors/ReadyContainer';

import Sidebar from 'components/Sidebar';
import Empty from 'components/Empty';

import duck from '../redux';
import Personal from './components/Personal';
import Settings from './components/Settings';

import styles from './EditEmployee.scss';

@ModalHOC({
  paginatorUrl: '/employee/edit-employees/:id/profile',
})
class EditEmployee extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      employee: { attributes: { temporary = false } = {} } = {},
    } = this.props;

    if (!temporary) {
      this.doLoad(id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      match: { params: { id: currId } = {} },
    } = this.props;

    const {
      match: { params: { id: nextId } = {} },
    } = nextProps;

    if (nextId !== currId) {
      this.doLoad(nextId);
    }
  }

  doLoad = (id) => {
    const {
      actions: { entityLoadStarted },
    } = this.props;

    entityLoadStarted({
      type: 'employees',
      slice: 'employerEditor',
      id,
      params: {
        includeSet: 'employer_company',
        sortBy: 'created_at_desc',
      },
      updateOnly: true,
    });
  };

  handlePatchResource = (attributes, type) => {
    const {
      actions: { patchUserResource },
      match: {
        params: { id },
      },
      employee: { relationships: { user: { data: userIds = [] } = {} } = {} } = {},
    } = this.props;

    patchUserResource({
      attributes,
      id: type === 'users' ? userIds[0] : id,
      slice: 'employerEditor',
      type,
    });
  };

  handleEmployeeInputChange = (event) => {
    const {
      target: { name, value, type, checked },
    } = event;

    const newValue = type === 'checkbox' ? checked : value;

    this.handlePatchResource({ [name]: newValue }, 'employees');
  };

  handleUserTextInputChange = (event) => {
    const {
      target: { name, value },
    } = event;

    this.handlePatchResource({ [name]: value }, 'users');
  };

  render() {
    const {
      employee,
      match: {
        params: { id, view },
      },
    } = this.props;

    const newProps = {
      ...this.props,
      handleEmployeeInputChange: this.handleEmployeeInputChange,
      handleUserTextInputChange: this.handleUserTextInputChange,
    };

    const content = () => {
      switch (view) {
        case 'profile':
          return <Personal {...newProps} />;
        case 'settings':
          return <Settings {...newProps} />;
        default:
          return '404';
      }
    };

    const sidebarProps = {
      type: 'employerEditor',
      page: view,
      id,
    };

    return (
      <React.Fragment>
        <Helmet title="Settings" />
        <ReadyContainer className={styles.CandidateEditorSkills}>
          <Sidebar {...sidebarProps} />
          {isEmpty(employee) ? <Empty {...this.props} /> : content()}
        </ReadyContainer>
      </React.Fragment>
    );
  }
}

export default ConnectContainer(duck)(EditEmployee);
