import { postAddCandidates } from 'api/apiNonstandard';

const promisePostAddCandidates = ({
  cohortId,
  candidatesToSkip,
  candidatesToAdd,
  startDate,
  endDate,
}) => {
  const candidatesToSkipString = candidatesToSkip
    ? candidatesToSkip.map((id) => `&id[]=ne:${id}`).join('')
    : '';

  const candidatesToAddString = candidatesToAdd
    ? candidatesToAdd.map((id) => `&id[]=${id}`).join('')
    : '';

  const startString = startDate
    ? `&application_status_updated_at[]=gte:${startDate.toISOString()}`
    : '';

  const endString = endDate ? `&application_status_updated_at[]=lte:${endDate.toISOString()}` : '';

  const excludeCurrentCohort = `&not_in_cohort_id=${cohortId}`;

  const onFulfilled = (response) => {
    const {
      data: { cohort_candidates: cohortCandidates },
    } = response;

    return { cohortCandidates };
  };

  const args = {
    type: 'cohorts',
    nestedType: 'add_candidates',
    id: cohortId,
    data: {
      add_candidates: {
        scope: `${excludeCurrentCohort}${candidatesToSkipString}${candidatesToAddString}${startString}${endString}`,
      },
    },
  };

  return postAddCandidates(args).then(onFulfilled);
};

export default promisePostAddCandidates;
