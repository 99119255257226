import React, { useState, useRef, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';

import { useOnClickOutside } from 'hooks';

import HeaderLogo from 'components/HeaderLogo';
import FontIcon from 'components/FontIcon';
import MenuDropdown from 'components/MenuDropdown/MenuDropdown';
import MenuAccordion from 'components/MenuAccordion';

import salesRolesData from 'pages/JobsPage/jobsPageMetaData';
import locationsPageMetaData from 'pages/LocationsPage/locationsPageMetaData';

import Navigation from '../Navigation';

import styles from './Header.scss';

const WELCOME_LINKS = [
  {
    to: '/',
    title: 'Candidates',
    active: ['/'],
  },
  {
    to: '/employers',
    title: 'Employers',
    active: ['/employers', '/my-hiring-profile/'],
  },
  {
    element: (
      <MenuDropdown
        showCaret={false}
        label="Sales Roles"
        items={Object.keys(salesRolesData).map((role) => ({
          title: salesRolesData[role].header,
          url: salesRolesData[role].localUrl,
        }))}
        target="_blank"
      />
    ),
    active: Object.keys(salesRolesData).map((role) => salesRolesData[role].localUrl),
    hideOnMobile: true,
  },
  {
    element: (
      <MenuAccordion
        label="Sales Roles"
        items={Object.keys(salesRolesData).map((role) => ({
          title: salesRolesData[role].header,
          url: salesRolesData[role].localUrl,
        }))}
        target="_blank"
      />
    ),
    active: Object.keys(salesRolesData).map((role) => salesRolesData[role].localUrl),
    hideOnDesktop: true,
  },
  {
    element: (
      <MenuDropdown
        showCaret={false}
        label="Markets"
        items={Object.keys(locationsPageMetaData).map((market) => ({
          title: locationsPageMetaData[market].header,
          url: locationsPageMetaData[market].localUrl,
        }))}
        target="_blank"
      />
    ),
    active: Object.keys(locationsPageMetaData).map(
      (market) => locationsPageMetaData[market].localUrl
    ),
    hideOnMobile: true,
  },
  {
    element: (
      <MenuAccordion
        label="Markets"
        items={Object.keys(locationsPageMetaData).map((market) => ({
          title: locationsPageMetaData[market].header,
          url: locationsPageMetaData[market].localUrl,
        }))}
        target="_blank"
      />
    ),
    active: Object.keys(locationsPageMetaData).map(
      (market) => locationsPageMetaData[market].localUrl
    ),
    hideOnDesktop: true,
  },
  {
    to: '/blog/',
    title: 'Blog',
    target: '_blank',
    active: [],
  },
  {
    to: `https://${process.env.FORUM_LINK}`,
    title: 'Community',
    target: '_self',
    active: [],
    hide: !process.env.FORUM_LINK,
  },
];

const LOGGED_OUT_EMPLOYER_LINKS = [
  {
    to: '/employer-signup/',
    title: 'Join Now',
    button: true,
  },
  {
    to: '/login/employee/',
    title: 'Sign In',
  },
];

const LOGGED_OUT_CANDIDATE_LINKS = [
  {
    to: '/apply-now/',
    title: 'Apply Now',
    button: true,
  },
  {
    to: '/login/candidate/',
    title: 'Sign In',
  },
];

const logoLink = '/';

const Header = ({ currentRequisitionId = 'all', canvasScroll }) => {
  const { pathname = '' } = useLocation();
  const [showNav, setShowNav] = useState(false);
  const ref = useRef();

  useOnClickOutside(
    ref,
    useCallback(() => setShowNav(false), [])
  );

  const makeWelcomeLinks = (link) => {
    const { active, title, to, target, element, hideOnMobile, hideOnDesktop } = link;

    const linkClasses = classnames(styles.navLink, {
      [styles.activeWelcomeNavLink]: active.includes(pathname),
      active: active.includes(pathname),
    });

    if (element) {
      return (
        <span
          key="jobsMenuDropdown"
          className={classnames(
            linkClasses,
            hideOnMobile && styles.hideOnMobile,
            hideOnDesktop && styles.hideOnDesktop
          )}
        >
          {element}
        </span>
      );
    }

    const linkProps = target
      ? {
          target,
          href: to,
          key: title,
          className: linkClasses,
        }
      : {
          to,
          key: title,
          className: linkClasses,
        };

    return target ? <a {...linkProps}>{title}</a> : <Link {...linkProps}>{title}</Link>;
  };

  const makeLinks = (link) => {
    const { title, to, button = false, activePaths } = link;

    const isActive = activePaths && activePaths.includes(pathname.split('/')[1]);

    const linkClasses = classnames(styles.navLink, {
      [styles.activeNavLink]: isActive,
      [styles.button]: button,
    });

    const linkProps = {
      to: to.replace(':id', currentRequisitionId),
      key: title,
      className: linkClasses,
    };

    return <Link {...linkProps}>{title}</Link>;
  };

  const welcomeLinks = WELCOME_LINKS.filter((link) => !link.hide).map(makeWelcomeLinks);

  const currentSegment = pathname.split('/')[1];

  const showInvite = canvasScroll > 500;

  const loggedOutLinks = [
    'employers',
    'employer-signup',
    'my-hiring-profile',
    'recruiting',
  ].includes(currentSegment)
    ? LOGGED_OUT_EMPLOYER_LINKS
    : LOGGED_OUT_CANDIDATE_LINKS;

  const loggedOutLinksFinal = loggedOutLinks.slice(1, 2);

  return (
    <header
      className={classnames(styles.HeaderContainer, {
        [styles.HeaderTransparent]: true,
        [styles.headerBoxShadow]: canvasScroll !== 0,
      })}
    >
      <div
        className={classnames(styles.Header, {
          [styles.HeaderFull]: true,
        })}
      >
        <div className={styles.LogoBlock}>
          <Link to={logoLink}>
            <HeaderLogo />
          </Link>
          <div className={styles.welcomeLinksContainer} ref={ref}>
            <Navigation className={styles.WelcomeNavBlock}>{welcomeLinks}</Navigation>
            {showInvite ? makeLinks(loggedOutLinks[0]) : null}
            <div
              onClick={() => setShowNav((showNav) => !showNav)}
              className={classnames(styles.NavHamburger, {
                [styles.seafoam]: showNav,
              })}
            >
              <FontIcon iconName="menu" />
            </div>
            {showNav ? (
              <div className={styles.NavDropdown} onClick={() => setShowNav(false)}>
                {welcomeLinks}
              </div>
            ) : null}
          </div>
        </div>
        <div onClick={() => setShowNav(false)} className={styles.navLinksContainer}>
          <Navigation className={styles.NavBlock}>{loggedOutLinksFinal.map(makeLinks)}</Navigation>
        </div>
      </div>
    </header>
  );
};

export default Header;
