import React from 'react';

import AutocompleteServer from 'connectors/AutocompleteServer';
import Tag from 'components/Tag';

import styles from './PerkInputs.scss';

const PerkInputs = ({ perks, handleToggleArrayValue }) => {
  const perksInputProps = {
    placeholder: 'Bountiful supplies of LaCroix, e.g.',
    value: '',
    size: 'xxlarge',
    name: 'perks',
    label: 'Perks',
    handleInputChange: (event) =>
      handleToggleArrayValue({
        ...event,
        target: {
          ...event.target,
          name: event.target.name,
          value: event.target.value.name,
        },
      }),
    handleOnBlur: handleToggleArrayValue,
    topLabel: true,
    autocompleteType: 'perks',
    field: 'name',
    needReset: true,
  };

  const perksSelected = perks
    ? perks.map((perk) => {
        const tagProps = {
          key: perk,
          name: perk,
          inputName: 'perks',
          onDeleteClick: (name, inputName) =>
            handleToggleArrayValue({
              target: {
                value: name,
                name: inputName,
              },
            }),
        };
        return <Tag key={perk} {...tagProps} />;
      })
    : null;

  return (
    <div className={styles.PerkInputs}>
      <AutocompleteServer {...perksInputProps} />
      <div className={styles.autocompleteContainer}>{perksSelected}</div>
    </div>
  );
};

export default PerkInputs;
