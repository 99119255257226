import React, { Component } from 'react';

import { handleError } from 'utils/common';
import { postEntity } from 'api/apiEntity';
import { toggleArray } from 'utils/formUtils';

import Button from 'components/Button';
import Content from 'components/Content';
import ContentTitle from 'components/ContentTitle';
import DialogueModal from 'components/DialogueModal';
import EmployeeData from 'components/EmployeeData';
import InputCheckbox from 'components/InputCheckbox';
import TableRow from 'components/Table/components/TableRow';
import TableOverlay from 'components/TableOverlay';

import GroupMember from '../GroupMember';

import styles from '../../ApproachEditorEmployee.scss';

class GroupMembers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addingMembers: false,
      showAddMembers: false,
      selectedEmployeeIds: [],
    };
  }

  handleAddMembers = async () => {
    this.setState({ addingMembers: true });
    const { selectedEmployeeIds } = this.state;
    const {
      actions: { entityLoadStarted },
      messageGroupId,
    } = this.props;

    const promises = selectedEmployeeIds.map((id) =>
      postEntity({
        type: 'message_group_members',
        data: {
          type: 'message_group_members',
          attributes: {
            profileType: 'Employee',
            profileId: id,
            messageGroupId,
          },
        },
        config: {
          params: {
            includeSet: 'profile',
            normalizeIt: true,
          },
        },
      })
    );

    try {
      await Promise.all(promises);

      entityLoadStarted({
        type: 'message_group_members',
        slice: 'toolboxApproaches',
        params: {
          page: 1,
          messageGroupId,
          sortBy: 'profile_type_asc,created_at_asc',
          includeSet: 'profile',
        },
      });
    } catch (error) {
      handleError(error);
    }

    this.setState({
      showAddMembers: false,
      selectedEmployeeIds: [],
      addingMembers: false,
    });
  };

  handleToggleEmployeeId = (employeeId) => {
    const { selectedEmployeeIds } = this.state;

    const newIds = toggleArray({ array: selectedEmployeeIds, value: employeeId });

    this.setState({ selectedEmployeeIds: newIds });
  };

  render() {
    const {
      actions: { deleteResource },
      candidates,
      employees,
      employees: { byId = {}, allIds = [] } = {},
      messageGroupId,
      messageGroupMembers: {
        byId: messageGroupMembersById = {},
        allIds: messageGroupMembersAllIds = [],
      } = {},
      rainmakersAdmins,
      user,
    } = this.props;

    const { selectedEmployeeIds, showAddMembers, addingMembers } = this.state;

    const currentGroupMemberIds = messageGroupMembersAllIds.filter(
      (id) => messageGroupMembersById[id].attributes.messageGroupId === messageGroupId
    );

    const groupMembers = currentGroupMemberIds.map((id) => {
      const props = {
        employees,
        rainmakersAdmins,
        candidates,
        deleteResource,
        member: messageGroupMembersById[id],
        user,
      };

      return <GroupMember key={id} {...props} />;
    });

    const closeButton = (
      <Button
        onClick={() => this.setState({ selectedEmployeeIds: [], showAddMembers: false })}
        quaternary
      >
        Cancel
      </Button>
    );

    const actionButton = (
      <Button onClick={this.handleAddMembers} primary>
        Add
      </Button>
    );

    const memberProfileIds = messageGroupMembersAllIds.map((id) => {
      const { relationships: { profile: { data: profileIds = [] } = {} } = {} } =
        messageGroupMembersById[id] || {};

      return profileIds[0];
    });

    const selectableEmployeeIds = allIds.filter((id) => !memberProfileIds.includes(id));

    const selectableEmployeesUI = selectableEmployeeIds.map((id) => {
      const employee = byId[id] || {};
      const { attributes: { jobTitle } = {} } = employee;

      const empDataProps = {
        employee,
        subtitle: jobTitle,
      };

      const checkboxProps = {
        checked: selectedEmployeeIds.includes(id),
        handleInputChange: () => null,
        disabled: true,
      };

      const props = {
        onClick: () => this.handleToggleEmployeeId(id),
        className: styles.empRow,
      };

      return (
        <TableRow key={id} {...props}>
          <EmployeeData {...empDataProps} />
          <InputCheckbox {...checkboxProps} />
        </TableRow>
      );
    });

    const tableOverlay = addingMembers ? <TableOverlay /> : null;

    const addMemberModal = showAddMembers ? (
      <DialogueModal>
        {tableOverlay}
        <div className={styles.modal}>
          <div className={styles.modalTitle}>Add New Message Recipients</div>

          <div className={styles.employeeTable}>{selectableEmployeesUI}</div>

          <div className={styles.modalButtons}>
            {closeButton}
            {actionButton}
          </div>
        </div>
      </DialogueModal>
    ) : null;

    const addMemberButtonProps = {
      tertiary: true,
      onClick: () => this.setState({ showAddMembers: true }),
      disabled: selectableEmployeeIds.length === 0,
    };

    return (
      <Content>
        <ContentTitle>MESSAGE RECIPIENTS</ContentTitle>

        <Content className={styles.tierRowFlexStart}>
          {groupMembers}

          <Button {...addMemberButtonProps}>+ Add</Button>
        </Content>

        {addMemberModal}
      </Content>
    );
  }
}

export default GroupMembers;
