import { getEntity } from 'api/apiEntity';

const promiseGetEmployer = ({ employerId }) => {
  const onFulfilled = (response) => {
    const {
      data: { employer = {} },
    } = response;

    return employer;
  };

  const args = {
    id: employerId,
    type: 'employers',
  };

  return getEntity(args).then(onFulfilled);
};

export default promiseGetEmployer;
