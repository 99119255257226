import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import EmptyAvatar from 'shared/assets/missing-user-picture.png';
import selectImageUploadCompany from 'connectors/App/redux/selectors/selectImageUploadCompany';

import InputFile from 'components/InputFile';
import Button from 'components/Button';
import ImageCropModal from 'components/ImageCropModal';

import styles from './ImageUploader.scss';

const DEFAULT_MAX_IMAGE_SIZE_MB = 4;

const ImageUploader = ({
  emptyLogo = EmptyAvatar,
  rounded = true,
  entityId: entityIdProp,
  imageUploadStarted,
  pictureUrl,
  name,
  maxImageSizeMb = DEFAULT_MAX_IMAGE_SIZE_MB,
  highlight,
  ['data-testid']: dataTestId,
}) => {
  const reduxCompany = useSelector(selectImageUploadCompany) || {};

  const { id: reduxEntityId } = reduxCompany;
  const entityId = entityIdProp || reduxEntityId;

  const [isHover, setIsHover] = useState(false);
  const [imagePreview, setImagePreview] = useState('');
  const [tempImage, setTempImage] = useState('');
  const [showImageCropModal, setShowImageCropModal] = useState(false);
  const [imageTypeError, setImageTypeError] = useState(null);

  const handleImageCropModalShow = useCallback(
    (event) => {
      const reader = new FileReader();
      const image = event.target.files[0];

      if (image.size > maxImageSizeMb * 1000000) {
        setImageTypeError(`File must be less than ${maxImageSizeMb} MB`);
        return;
      }

      reader.onloadend = () => {
        setTempImage(reader.result);
        setShowImageCropModal(true);
      };

      reader.readAsDataURL(image);
    },
    [maxImageSizeMb]
  );

  const handleImageCropCloseClick = useCallback(() => {
    setShowImageCropModal(false);
    setImagePreview('');
    setTempImage('');
  }, []);

  const handleImageCropSaveClick = useCallback(
    (image) => {
      setImagePreview(image);
      setShowImageCropModal(false);
      setImageTypeError(null);

      imageUploadStarted({ picture: image });
    },
    [imageUploadStarted]
  );

  const picUrl =
    imagePreview ||
    tempImage ||
    (pictureUrl && typeof pictureUrl === 'object' && pictureUrl.xlarge) ||
    (pictureUrl && typeof pictureUrl === 'string' && pictureUrl) ||
    emptyLogo;

  const sharedInputFileProps = {
    placeholder: '',
    onChange: handleImageCropModalShow,
    name: 'logo',
    disabled: !entityId,
    accept: '.jpg,.png',
  };

  const hasImageContent = (
    <div
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={isHover ? styles.uploadImageWrapperHover : styles.uploadImageWrapper}
    >
      <img src={picUrl} alt={name} />
      <div
        className={cn(styles.hoverOverlay, {
          [styles.hoverOverlayHide]: !isHover,
        })}
      >
        <InputFile
          {...sharedInputFileProps}
          className={styles.replaceImageInput}
          containerClassName={styles.replaceImageInputContainer}
        >
          <div className={styles.replaceButton}>Replace photo</div>
        </InputFile>
      </div>
    </div>
  );

  const imageContent =
    picUrl === '' ? (
      <div className={cn(styles.noPhotoPlaceholder, highlight && styles.highlight)}>
        <InputFile
          {...sharedInputFileProps}
          className={styles.imageInput}
          containerClassName={styles.imageInputContainer}
        >
          <Button
            tertiary={true}
            className={styles.resumeUploadNewButton}
            disabled={!entityId}
            fakeButton={true}
          >
            Add a photo
          </Button>
        </InputFile>
      </div>
    ) : (
      hasImageContent
    );

  return (
    <div
      className={cn(styles.uploadImageContainer, rounded && styles.rounded)}
      data-testid={dataTestId}
    >
      {imageContent}
      <div className={styles.errorText}>{imageTypeError}</div>
      {showImageCropModal && (
        <ImageCropModal
          tempImage={tempImage}
          handleImageCropCloseClick={handleImageCropCloseClick}
          handleImageCropSaveClick={handleImageCropSaveClick}
        />
      )}
    </div>
  );
};

export default ImageUploader;
