import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import ReadyContainer from 'connectors/ReadyContainer';

import Bolder from 'components/Bolder';
import Block from 'components/Block';
import Content from 'components/Content';
import Footer from 'pages/CandidatesHome/components/Footer';

import styles from './Careers.scss';

const Careers = () => {
  const blockProps = {
    addWhiteBG: true,
    addPadding: true,
  };

  const title = 'Careers at Rainmakers';
  const metaDescription =
    'Join Rainmakers and help us connect thousands to sales representatives with great companies. Rainmakers connects Top Sales Candidates with Top Sales Jobs.';
  const canonicalUrl = `https://${process.env.HOST}/careers/`;

  const helmetProps = {
    key: 'helmet',
    title,
    meta: [
      { name: 'description', content: metaDescription },
      { property: 'og:url', content: canonicalUrl },
      { property: 'og:title', content: title },
      { property: 'og:description', content: metaDescription },
    ],
    link: [{ rel: 'canonical', href: canonicalUrl }],
  };

  const applyLinkProps = {
    to: '/apply-now/',
    className: styles.button,
  };

  return (
    <React.Fragment>
      <Helmet {...helmetProps} />
      <ReadyContainer key="readyContainer" className={styles.Careers}>
        <Block {...blockProps}>
          <h2 className={styles.smallHeader}>
            <Bolder>CAREERS</Bolder>
          </h2>
          <h1 className={styles.header}>
            <Bolder>Join the Rainmakers team</Bolder>
          </h1>

          <Content className={styles.Content}>
            We&apos;re hiring! If you like working in a fast-paced startup environment, Rainmakers
            is the company for you. We are hiring for all positions. Apply by filling out a
            Rainmakers Profile.
          </Content>

          <div className={styles.headerTwo}>
            <Link {...applyLinkProps}>Apply now</Link>
          </div>
        </Block>

        <Footer />
      </ReadyContainer>
    </React.Fragment>
  );
};

export default Careers;
