import { put, select, take, call } from 'redux-saga/effects';

import { handleError } from 'utils/common';
import { postEntity } from 'api/apiEntity';
import { promiseEmailValid } from 'pages/CandidatePortal/CandidateEditorPages/promises';
import sagaValidateInput from 'connectors/Validator/redux/sagas/sagaValidateInput';

import { selectUser } from 'connectors/User/redux/selectors';

import { CREATE_LEAD_STARTED } from '../actions';

import { serverErrorSet, onboardingErrorsSet } from '../creators';

import { selectOnboardingErrors } from '../selectors';

const sagaCreateLeadStarted = function* sagaCreateLeadStarted() {
  while (true) {
    const {
      payload: { leadsData },
    } = yield take(CREATE_LEAD_STARTED);

    const { utmInfo = {}, referer } = yield select(selectUser);

    const {
      config: {
        lead: { email },
      },
    } = leadsData;

    const utmInfoMore = {
      ...utmInfo,
      referredByCode: utmInfo.referral || null,
      referredByEmail: utmInfo.referralEmail || null,
      referredBySource: utmInfo.referralSource || null,
    };

    const args = {
      type: 'leads',
      data: {
        type: 'lead',
        attributes: {
          referer,
          ...leadsData.config.lead,
          ...utmInfoMore,
          email: email.trim(),
        },
      },
    };

    const errors = yield select(selectOnboardingErrors);

    try {
      const { isValid } = yield call(sagaValidateInput, { newValue: email, name: 'email', errors });

      if (isValid) {
        const result = yield call(promiseEmailValid, { email: email.trim() });
        if (result.email) {
          yield put(
            onboardingErrorsSet({
              inputErrors: {
                ...errors,
                email: {
                  ...errors.email,
                  isAvailable: [false, 'email'],
                },
              },
            })
          );
        } else {
          yield call(postEntity, args);
        }
      }
    } catch (error) {
      handleError(error);

      const { response: { data: { errors: { full_messages: errors = [] } = {} } = {} } = {} } =
        error || {};

      yield put(serverErrorSet({ errors }));
    }
  }
};

export default sagaCreateLeadStarted;
