export const COMPANY_SIZE_SOURCE = [
  {
    label: '1 - 100',
    min: 1,
    value: '1,100',
  },
  {
    label: '100 - 500',
    min: 100,
    value: '100,500',
  },
  {
    label: '500 - 1,000',
    min: 500,
    value: '500,1000',
  },
  {
    label: '1,000 - 10,000',
    min: 1000,
    value: '1000,10000',
  },
  {
    label: '10,000+',
    min: 10000,
    value: '10000,',
  },
];

export const COMPANY_SIZE_SOURCE_NEW = [
  {
    label: '1 - 100',
    min: 1,
    value: {
      sizeMin: '1',
      sizeMax: '100',
    },
  },
  {
    label: '100 - 500',
    min: 100,
    value: {
      sizeMin: '100',
      sizeMax: '500',
    },
  },
  {
    label: '500 - 1,000',
    min: 500,
    value: {
      sizeMin: '500',
      sizeMax: '1000',
    },
  },
  {
    label: '1,000 - 10,000',
    min: 1000,
    value: {
      sizeMin: '1000',
      sizeMax: '10000',
    },
  },
  {
    label: '10,000+',
    min: 10000,
    value: {
      sizeMin: '10000',
      sizeMax: '',
    },
  },
];

export const MILITARY_STATUS_SOURCE = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Veteran',
    value: 'veteran',
  },
  {
    label: 'None',
    value: 'none',
  },
];

export const GENDER_SOURCE = [
  {
    label: 'Female',
    value: 'female',
  },
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'X',
    value: 'other',
  },
  {
    label: 'Rather not say',
    value: 'unspecified',
  },
];

export const HIRING_STATUS_SOURCE = [
  {
    label: "I'm actively interviewing",
    value: 'active',
  },
  {
    label: "I'm just browsing",
    value: 'passive',
  },
  {
    label: "I'm not looking",
    value: 'inactive',
  },
];
