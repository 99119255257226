import { call, put, take } from 'redux-saga/effects';
import { handleError } from 'utils/common';

import { TAGS_LOAD_STARTED } from 'shared/store/app/actions';

import { tagsLoadDone } from 'shared/store/app/creators';

import { promiseTags } from '../../promises';

const sagaTagsLoad = function* sagaTagsLoad() {
  while (true) {
    yield take(TAGS_LOAD_STARTED);

    try {
      const tags = yield call(promiseTags);

      yield put(tagsLoadDone({ tags }));
    } catch (error) {
      handleError(error);
    }
  }
};

export default sagaTagsLoad;
