import React, { Fragment } from 'react';

import Input from 'components/Input';
import InputCheckbox from 'components/InputCheckbox';
import DropdownSelectMany from 'components/DropdownSelectMany';
import DropdownSelectOne from 'components/DropdownSelectOne';

import styles from './ToolboxApproachesSearchBar.scss';

const ToolboxApproachesSearchBar = ({
  rainmakersAdmins: { byId: rainmakersAdminsById = {}, allIds: rainmakersAdminsAllIds = [] } = {},
  attentionRequired,
  handleSearchInputChange,
  handleSearchFilterChange,
  handleSearchCheckboxChange,
  roleOptions,
  candidateRainmakersAdminId = [],
  candidateFullName,
  candidateUserEmail,
  employerName,
  messageGroupMessagesCount,
  messageGroupUnread,
  requisitionOpeningRole,
  state,
}) => {
  const nameSearchProps = {
    name: 'candidateFullName',
    placeholder: 'Search by candidate name',
    size: 'full',
    handleInputChange: handleSearchInputChange,
    isSearch: true,
    value: candidateFullName,
  };

  const emailSearchProps = {
    name: 'candidateUserEmail',
    placeholder: 'Search by candidate email',
    size: 'full',
    handleInputChange: handleSearchInputChange,
    isSearch: true,
    value: candidateUserEmail,
  };

  const employerSearchProps = {
    name: 'employerName',
    placeholder: 'Search by employer name',
    size: 'full',
    handleInputChange: handleSearchInputChange,
    isSearch: true,
    value: employerName,
  };

  const adminSource = rainmakersAdminsAllIds.map((id) => {
    const { attributes: { firstName, lastName } = {} } = rainmakersAdminsById[id] || {};

    return {
      indent: 0,
      label: `${firstName} ${lastName}`,
      optionType: 'admin',
      subCategories: [],
      value: {
        type: 'candidateRainmakersAdminId',
        candidateRainmakersAdminId: id,
      },
    };
  });

  const selectedAdmins = candidateRainmakersAdminId.map((id) => ({
    candidateRainmakersAdminId: id,
    type: 'candidateRainmakersAdminId',
  }));

  const adminFilterProps = {
    className: styles.searchBarFilter,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    source: adminSource,
    name: 'candidateRainmakersAdminId',
    key: 'candidateRainmakersAdminId',
    optionsLarge: true,
    isLocation: true,
    onChange: (value) =>
      handleSearchFilterChange({
        target: {
          subCategories: value.subCategories,
          label: value.label,
          value: value.value,
          name: 'candidateRainmakersAdminId',
          optionType: value.optionType,
        },
      }),
    selectedValues: selectedAdmins,
    defaultLabel: 'Click to filter by concierges',
  };

  const roleSource = roleOptions.map((role) => ({
    indent: 0,
    label: role,
    optionType: 'role',
    subCategories: [],
    value: {
      type: 'role',
      role,
    },
  }));

  const selectedRoles = requisitionOpeningRole.map((role) => ({ role, type: 'role' }));

  const roleFilterProps = {
    className: styles.searchBarFilter,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    source: roleSource,
    name: 'requisitionOpeningRole',
    key: 'requisitionOpeningRole',
    optionsLarge: true,
    isLocation: true,
    onChange: (value) =>
      handleSearchFilterChange({
        target: {
          subCategories: value.subCategories,
          label: value.label,
          value: value.value,
          name: 'requisitionOpeningRole',
          optionType: value.optionType,
        },
      }),
    selectedValues: selectedRoles,
    defaultLabel: 'Click to select roles',
  };

  const stateOptions = [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Hired',
      value: 'hired',
    },
    {
      label: 'Passed',
      value: 'passed',
    },
    {
      label: 'Candidate Pass Pending',
      value: 'candidate_pass_pending',
    },
    {
      label: 'Candidate Passed',
      value: 'candidate_passed',
    },
    {
      label: 'Employer Passed',
      value: 'employer_passed',
    },
    {
      label: 'Inactive',
      value: 'inactive',
    },
  ];

  const stateSource = stateOptions.map((state) => ({
    indent: 0,
    label: state.label,
    optionType: 'role',
    subCategories: [],
    value: {
      type: 'state',
      state: state.value,
    },
  }));

  const selectedStates = state.map((state) => ({ state, type: 'state' }));

  const stateFilterProps = {
    className: styles.searchBarFilter,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    source: stateSource,
    name: 'state',
    key: 'state',
    optionsLarge: true,
    isLocation: true,
    onChange: (value) =>
      handleSearchFilterChange({
        target: {
          subCategories: value.subCategories,
          label: value.label,
          value: value.value,
          name: 'state',
          optionType: value.optionType,
        },
      }),
    selectedValues: selectedStates,
    defaultLabel: 'Click to select states',
  };

  const messageCountOptions = [
    {
      label: '1 message',
      value: '1',
    },
    {
      label: '2 messages',
      value: '2',
    },
    {
      label: '2+ messages',
      value: 'gte:2',
    },
    {
      label: '3+ messages',
      value: 'gte:3',
    },
  ];

  const messageCountSource = messageCountOptions.map((option) => ({
    indent: 0,
    label: option.label,
    optionType: 'role',
    subCategories: [],
    value: {
      type: 'count',
      count: option.value,
    },
  }));

  const selectedMessageCounts = messageGroupMessagesCount.map((count) => ({
    count,
    type: 'count',
  }));

  const messageCountFilterProps = {
    className: styles.searchBarFilter,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    source: messageCountSource,
    name: 'messageGroupMessagesCount',
    key: 'messageGroupMessagesCount',
    optionsLarge: true,
    isLocation: true,
    onChange: (value) =>
      handleSearchFilterChange({
        target: {
          subCategories: value.subCategories,
          label: value.label,
          value: value.value,
          name: 'messageGroupMessagesCount',
          optionType: value.optionType,
        },
      }),
    selectedValues: selectedMessageCounts,
    defaultLabel: 'Click to filter by # of messages',
  };

  const attentionRequiredProps = {
    name: 'attentionRequired',
    label: 'Attention Required',
    checked: attentionRequired,
    handleInputChange: handleSearchCheckboxChange,
    className: styles.checkbox,
    checkboxMany: true,
  };

  const messageGroupUnreadProps = {
    name: 'messageGroupUnread',
    label: 'Unread',
    checked: messageGroupUnread,
    handleInputChange: handleSearchCheckboxChange,
    className: styles.checkbox,
    checkboxMany: true,
  };

  return (
    <Fragment>
      <div className={styles.SearchBarOne}>
        <DropdownSelectMany {...stateFilterProps} />
        <DropdownSelectMany {...adminFilterProps} />
        <DropdownSelectMany {...roleFilterProps} />
        <InputCheckbox {...attentionRequiredProps} />
        <InputCheckbox {...messageGroupUnreadProps} />
      </div>
      <div className={styles.SearchBarTwo}>
        <Input {...nameSearchProps} />
        <Input {...emailSearchProps} />
        <Input {...employerSearchProps} />
        <DropdownSelectOne {...messageCountFilterProps} />
      </div>
    </Fragment>
  );
};

export default ToolboxApproachesSearchBar;
