import { call, put, take } from 'redux-saga/effects';

import Cookies from 'js-cookie';

import config from 'config';

import api from 'api/api';

import { ADD_REQUEST_INTERCEPTOR } from 'shared/store/app/actions';

import { saveRequestInterceptor } from 'shared/store/app/creators';

const saveCredentials = ({ 'access-token': accessToken, client, uid }) => {
  const {
    cookies: { maxAge, secure },
  } = config;

  const sharedConfig = {
    expires: new Date(Date.now() + maxAge),
    secure,
  };

  Cookies.set('accessToken', accessToken, sharedConfig);
  Cookies.set('client', client, sharedConfig);
  Cookies.set('uid', uid, sharedConfig);
};

const sagaAddRequestInterceptor = function* sagaAddRequestInterceptor() {
  while (true) {
    const {
      payload: { headers },
    } = yield take(ADD_REQUEST_INTERCEPTOR);

    if (headers.client && headers.uid && headers['access-token']) {
      yield call(saveCredentials, headers);
    }

    const authInterceptor = api.interceptors.request.use(
      (config) => {
        return {
          ...config,
          headers,
        };
      },
      (error) => Promise.reject(error)
    );

    yield put(saveRequestInterceptor({ authInterceptor }));
  }
};

export default sagaAddRequestInterceptor;
