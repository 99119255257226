import { all, fork } from 'redux-saga/effects';

import sagaLoginStarted from './sagaLoginStarted';
import sagaDirectLoginStarted from './sagaDirectLoginStarted';
import sagaCandidateLoginDone from './sagaCandidateLoginDone';
import sagaEmployeeLoginDone from './sagaEmployeeLoginDone';
import sagaAdminLoginDone from './sagaAdminLoginDone';
import sagaLogoutStarted from './sagaLogoutStarted';
import sagaIdentifySegmentUser from './sagaIdentifySegmentUser';

const sagaLogin = function* sagaLogin(browserHistory) {
  yield all([
    fork(sagaCandidateLoginDone, browserHistory),
    fork(sagaDirectLoginStarted, browserHistory),
    fork(sagaAdminLoginDone, browserHistory),
    fork(sagaEmployeeLoginDone, browserHistory),
    fork(sagaLoginStarted, browserHistory),
    fork(sagaLogoutStarted, browserHistory),
    fork(sagaIdentifySegmentUser, browserHistory),
  ]);
};

export default sagaLogin;
