import { all, fork } from 'redux-saga/effects';

import sagaTemplatesLoadStarted from './sagaTemplatesLoadStarted';
import sagaTemplateSaveStarted from './sagaTemplateSaveStarted';
import sagaTemplateDeleteStarted from './sagaTemplateDeleteStarted';
import sagaTemplateUpdateStarted from './sagaTemplateUpdateStarted';

const sagaTemplateComposer = function* sagaTemplateComposer() {
  yield all([
    fork(sagaTemplatesLoadStarted),
    fork(sagaTemplateSaveStarted),
    fork(sagaTemplateDeleteStarted),
    fork(sagaTemplateUpdateStarted),
  ]);
};

export default sagaTemplateComposer;
