import { call, put, take, select, spawn } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { promiseGetFullCandidate } from 'pages/EmployeePortal/CandidateProfilePages/promises';

import { selectIsEmployee } from 'connectors/Login/redux/selectors';

import { promiseBookmarks } from '../../../CandidateBookmarksPage/promises';
import { promiseSearch } from '../../promises';

import { FEATURED_CANDIDATES_LOAD_STARTED } from '../actions';

import { featuredCandidatesLoadDone, featuredCandidateUpdate } from '../creators';

const eagerLoadMoreData = function* eagerLoadMoreData(candidates, page) {
  const isEmployee = yield select(selectIsEmployee);
  /*eslint-disable-next-line no-unused-vars*/
  for (const cand of candidates) {
    try {
      const needContextOrigin = page === 1 && cand.id === candidates[0].id;

      const fullCand = yield call(promiseGetFullCandidate, {
        isEmployee,
        contextOrigin: needContextOrigin ? 'featured' : null,
        candidateId: cand.id,
        includeBookmark: isEmployee,
        noPrivate: true,
        noUser: true,
      });

      yield put(featuredCandidateUpdate({ candidate: { ...fullCand, loaded: true } }));
    } catch (error) {
      handleError(error);
    }
  }
};

const sagaFeaturedCandidatesLoadStarted = function* sagaFeaturedCandidatesLoadStarted() {
  while (true) {
    const {
      payload: { page = 1, employerId },
    } = yield take(FEATURED_CANDIDATES_LOAD_STARTED);

    const promiseParams = {
      category: 'featured',
      page,
      contextOrigin: 'featured_view',
      perPage: 30,
    };

    if (employerId) {
      promiseParams.allSearchParams = `&filter[simulated_employer_id]=${employerId}`;
    }

    try {
      const response = yield call(promiseSearch, promiseParams);

      const {
        data: {
          entities: { candidate: { allIds: candIds = [], byId: candById = {} } = {} } = {},
        } = {},
      } = response;

      const bookmarksParams = { candidateIds: candIds };

      const { bookmarks } = yield call(promiseBookmarks, bookmarksParams);

      const bookmarkCandIds = bookmarks.map((bm) => bm.candidate_id);

      candIds.forEach((candId, index) => {
        if (bookmarkCandIds.includes(candId)) {
          response.data.entities.candidate.byId[candId] = {
            ...response.data.entities.candidate.byId[candId],
            attributes: {
              ...response.data.entities.candidate.byId[candId].attributes,
              indexPosition: index,
              bookmark: bookmarks.find((bm) => bm.candidate_id === candId),
            },
          };
        }
      });

      yield put(featuredCandidatesLoadDone(response));

      const candidates = candIds.map((id) => candById[id]);

      yield spawn(eagerLoadMoreData, candidates, page);
    } catch (error) {
      handleError(error);
    }
  }
};

export default sagaFeaturedCandidatesLoadStarted;
