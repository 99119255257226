import { all, fork } from 'redux-saga/effects';

import sagaErrorsCheck from './sagaErrorsCheck';
import sagaUploadResumeStarted from './sagaUploadResumeStarted';
import sagaPatchCandidateStarted from './sagaPatchCandidateStarted';
import sagaCandidateImageUpload from './sagaCandidateImageUpload';
import sagaReorderWorkHistoriesStarted from './sagaReorderWorkHistoriesStarted';
import sagaReorderCandidateEducationsStarted from './sagaReorderCandidateEducationsStarted';
import sagaWorkHistoryDeleteStarted from './sagaWorkHistoryDeleteStarted';
import sagaCandidateEducationDeleteStarted from './sagaCandidateEducationDeleteStarted';
import sagaRecentRoleSet from './sagaRecentRoleSet';

import sagaApplyStarted from './sagaApplyStarted';
import sagaForumApplyStarted from './sagaForumApplyStarted';
import sagaSetRequirementsStarted from './sagaSetRequirementsStarted';
import sagaAddSalesInfoStarted from './sagaAddSalesInfoStarted';
import sagaCreateLeadStarted from './sagaCreateLeadStarted';
import sagaAddVideoStarted from './sagaAddVideoStarted';

const sagaCandidateOnboarding = function* sagaCandidateOnboarding() {
  yield all([
    fork(sagaCreateLeadStarted),
    fork(sagaApplyStarted),
    fork(sagaForumApplyStarted),
    fork(sagaSetRequirementsStarted),
    fork(sagaAddSalesInfoStarted),
    fork(sagaAddVideoStarted),
    fork(sagaWorkHistoryDeleteStarted),
    fork(sagaCandidateEducationDeleteStarted),
    fork(sagaReorderWorkHistoriesStarted),
    fork(sagaReorderCandidateEducationsStarted),
    fork(sagaErrorsCheck),
    fork(sagaUploadResumeStarted),
    fork(sagaPatchCandidateStarted),
    fork(sagaCandidateImageUpload),
    fork(sagaRecentRoleSet),
  ]);
};

export default sagaCandidateOnboarding;
