import React from 'react';

import Button from 'components/Button';
import Input from 'components/Input';
import InputRadio from 'components/InputRadio';
import Label from 'components/Label';

import styles from './Form.scss';

const Form = ({
  email,
  emailErrorContent,
  handleInputChange,
  handleSubmitForm,
  handleOnBlur,
  firstName,
  lastName,
  linkedinUrl,
  onboardingErrors,
  password,
  phone,
  salesExperience,
  serverErrorContent,
}) => {
  const valid = (onboardingErrors) => {
    const inputs = ['firstName', 'lastName', 'email', 'password', 'salesExperience'];

    if (linkedinUrl) inputs.push('linkedinUrl');

    const invalidInputs = inputs.filter((input) => {
      const invalidRules = Object.keys(onboardingErrors[input]).filter(
        (rule) =>
          onboardingErrors[input][rule][0] === false || onboardingErrors[input][rule][0] === null
      );

      return invalidRules.length > 0;
    });
    return invalidInputs.length === 0;
  };

  const {
    firstName: firstNameErrors,
    lastName: lastNameErrors,
    email: emailErrors,
    password: passwordErrors,
    linkedinUrl: linkedinUrlErrors,
    phone: phoneErrors,
  } = onboardingErrors;

  const commonTextInputProps = {
    handleOnBlur,
    handleInputChange,
    type: 'text',
    required: true,
    size: 'full',
  };

  const firstNameInputProps = {
    ...commonTextInputProps,
    name: 'firstName',
    value: firstName,
    label: 'First Name',
    placeholder: '',
    errors: firstNameErrors,
    autoFocus: true,
    autoCapitalize: 'words',
  };

  const lastNameInputProps = {
    ...commonTextInputProps,
    name: 'lastName',
    value: lastName,
    label: 'Last Name',
    placeholder: '',
    errors: lastNameErrors,
    autoCapitalize: 'words',
  };

  const emailInputProps = {
    ...commonTextInputProps,
    autoComplete: 'username',
    name: 'email',
    value: email,
    label: 'Email',
    placeholder: '',
    errors: emailErrors,
  };

  const phoneInputProps = {
    ...commonTextInputProps,
    name: 'phone',
    value: phone || '',
    label: 'Your Phone #',
    placeholder: 'Area Code First',
    errors: phoneErrors,
    type: 'tel',
  };

  const linkedinUrlInputProps = {
    ...commonTextInputProps,
    required: false,
    type: 'url',
    name: 'linkedinUrl',
    value: linkedinUrl,
    label: 'Your LinkedIn Profile URL',
    placeholder: 'www.linkedin.com/in/myurl',
    errors: linkedinUrlErrors,
  };

  const passwordInputProps = {
    handleInputChange,
    handleOnBlur,
    required: true,
    size: 'full',
    topLabel: true,
    autoComplete: 'new-password',
    type: 'password',
    name: 'password',
    value: password,
    errors: passwordErrors,
    label: 'Create Password',
  };

  const disabled = !valid(onboardingErrors);

  const actionButtonProps = {
    primary: true,
    onClick: handleSubmitForm,
    disabled,
    type: 'submit',
  };

  const options = [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ];

  const salesExperienceInputProps = {
    name: 'salesExperience',
    handleInputChange,
    value: salesExperience,
    options,
  };

  const formProps = {
    method: 'post',
    className: styles.Form,
    onSubmit: disabled ? () => null : handleSubmitForm,
  };

  return (
    <form {...formProps}>
      {serverErrorContent}
      {emailErrorContent}
      <div className={styles.formRow}>
        <Input {...firstNameInputProps} />
        <Input {...lastNameInputProps} />
      </div>
      <Input {...emailInputProps} />
      <Input {...phoneInputProps} />
      <Input {...linkedinUrlInputProps} />
      <Input {...passwordInputProps} />

      <div className={styles.salesExperience}>
        <Label standalone required>
          Have you ever worked in sales?
        </Label>
        <InputRadio {...salesExperienceInputProps} />
      </div>

      <Button {...actionButtonProps}>Apply</Button>
    </form>
  );
};

export default Form;
