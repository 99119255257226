import React from 'react';

import InputCheckboxMany from 'components/InputCheckboxMany';

const CohortScope = ({ scopeSource, handleScopeChange: handleInputChange, ...others }) => {
  const selectedValues = scopeSource.filter(({ value }) => others[value]).map(({ value }) => value);

  const scopeInputProps = {
    source: scopeSource,
    name: 'scope',
    handleInputChange,
    selectedValues,
    blockStyle: true,
    hasLabel: true,
    smallBlock: true,
  };

  return <InputCheckboxMany {...scopeInputProps} />;
};

export default CohortScope;
