import React from 'react';
import classnames from 'classnames';

import styles from './Bold.scss';

const Bold = ({ children, className }) => {
  const classes = classnames(styles.Bold, className);

  return <span className={classes}>{children}</span>;
};

export default Bold;
