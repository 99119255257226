const prefix = 'Rainmakers/CandidateSettings/';

export const CANDIDATE_PROFILE_PATCH_STARTED = `${prefix}CANDIDATE_PROFILE_PATCH_STARTED`;
export const CANDIDATE_LOAD_STARTED = `${prefix}CANDIDATE_LOAD_STARTED`;

export const CANDIDATE_COMPANY_BLOCKS_ERROR_SET = `${prefix}CANDIDATE_COMPANY_BLOCKS_ERROR_SET`;
export const CANDIDATE_COMPANY_BLOCKS_LOAD_STARTED = `${prefix}CANDIDATE_COMPANY_BLOCKS_LOAD_STARTED`;
export const CANDIDATE_COMPANY_BLOCKS_LOAD_DONE = `${prefix}CANDIDATE_COMPANY_BLOCKS_LOAD_DONE`;

export const CANDIDATE_COMPANY_BLOCKS_DELETE_STARTED = `${prefix}CANDIDATE_COMPANY_BLOCKS_DELETE_STARTED`;
export const CANDIDATE_COMPANY_BLOCKS_DELETE_DONE = `${prefix}CANDIDATE_COMPANY_BLOCKS_DELETE_DONE`;

export const CANDIDATE_COMPANY_BLOCKS_CREATE_STARTED = `${prefix}CANDIDATE_COMPANY_BLOCKS_CREATE_STARTED`;
export const CANDIDATE_COMPANY_BLOCKS_CREATE_DONE = `${prefix}CANDIDATE_COMPANY_BLOCKS_CREATE_DONE`;

export const CREATE_COMPANY_AND_BLOCK_STARTED = `${prefix}CREATE_COMPANY_AND_BLOCK_STARTED`;
export const CREATE_COMPANY_AND_BLOCK_DONE = `${prefix}CREATE_COMPANY_AND_BLOCK_DONE`;
