/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import pic1 from './assets/1-customer-success-manager-job-information.png';
import pic2 from './assets/2-contact-rainmakers-browse-jobs.png';
import pic3 from './assets/3-customer-success-manager-job-description.png';
import pic4 from './assets/4-day-in-life-customer-success-manager.png';
import pic5 from './assets/5-customer-success-manager.png';
import pic6 from './assets/6-create-profile-to-browse-customer-success-manager-jobs.png';

import styles from './CSMJobsContent.scss';

export function CSMJobsContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt="" />

      <h2>Customer Success Manager Jobs</h2>

      <p>
        Customer success managers (CSMs) aid your business’s customers as they mature from sales
        prospects into engaged users of your products or services. They are committed to creating
        strong, enduring client connections and are frequently assigned to the same clients for the
        duration of their involvement with your business.
      </p>

      <p>
        Building a solid relationship with a customer is more than just landing a sale. In fact,
        landing the deal is just the beginning of the relationship. Your business must continue
        demonstrating to the customer that your product or service is worthwhile even after the
        sale. A Customer Success Manager is a subject matter expert on the company’s products and
        services and helps cultivate relationships with customers to help them succeed and keep
        coming back.
      </p>

      <p>
        The implementation of Customer Success Managers is relatively recent, but the idea has
        gained some serious traction as businesses see the value in investing deeply into customer
        relations. Nearly half of all high-tech companies employ CSMs these days, and the trend is
        growing.
      </p>

      <h2>What Does a Customer Success Manager Do?</h2>

      <p>
        While customer service representatives respond to problems as they happen, CSMs seek to find
        solutions in advance. They take a proactive interest in their client’s business and offer
        fresh, creative ideas for how to make the most of your products or services.
      </p>

      <p>
        They fill an interesting space in the sales team as they are involved with aspects of a
        business’s relationship with the customer. Here’s how.
      </p>

      <Link to="/apply-now" title="Apply Now">
        <img src={pic2} alt="" />
      </Link>

      <h2>CSMs Link Sales and Customer Service</h2>
      <p>
        Customer Success Managers are skilled in both sales and customer service. They differ from
        Account Managers in that they are less involved in acquiring the next client but more on
        continued focus on current ones. In addition, they prioritize developing client
        relationships, improving retention, addressing client problems, and promoting overall
        customer satisfaction.
      </p>

      <p>
        One of the easiest times to lose customers to a competitor is when they purchase a product
        or service and start seeing tangible, positive results. How these results manifest will vary
        from customer to customer. Some will see increased revenue as a result of the product or
        service as a success milestone. Others will come to see how your product or service saves
        them time or makes their operations more efficient.
      </p>

      <p>
        It’s between initial purchase and when that product delivers results that a CSM is
        especially critical to customer relations. They help the client get the best results from
        your product or service and guide them to success while keeping a close eye on their
        satisfaction.
      </p>

      <h2>CSMs Add Value and Boost Retention</h2>

      <p>
        The chances of retention are significantly increased by having a Customer Success Manager on
        hand for the onboarding procedure, but their usefulness extends beyond that.
      </p>

      <p>
        To ensure that customers are using the products regularly, CSMs conduct regular follow-ups -
        often in the form of Quarterly Business Reviews, or QBRs. It may seem counter-intuitive, but
        research indicates that following the purchase of a product or service, frequent usage of
        said product or service often does not occur organically following the purchase of a product
        or service. For example, approximately half of the customers who sign on for a SaaS product
        use it for only once a month or less.
      </p>

      <p>
        If customers do not perceive the value of your products or services, it might be challenging
        to maintain continued enthusiasm for your business. Customer Success Managers work to keep
        the advantages of your products in the forefront of customer’s minds.
      </p>

      <img src={pic3} alt="" />

      <h2>CSMs Have a “Big Picture” View of the Customer Sales Relationship</h2>

      <p>
        While regular customer service representatives see individual problems as they happen, CSMs
        identify issues that can affect several clients and predict how these trends will impact
        future churn.
      </p>

      <p>
        Customer Success Managers are also well-positioned to know about upcoming products and
        updates, so they can help businesses connect with existing clients as part of a larger sales
        strategy.
      </p>

      <p>
        Because they can see things from the perspective of both the business and the customer, they
        can identify potential issues and opportunities and turn those into better revenue for both
        the business they represent and the customers whose success they help manage.
      </p>

      <h2>What Does a Successful CSM Look Like?</h2>

      <p>
        Customer Success Managers must be skilled in organization and presentation. But, perhaps,
        more importantly, they need to be proficient at building trust.
      </p>

      <p>
        Customers need to trust that the CSM has an intimate knowledge of the product or service and
        the industry overall. In addition, they need to trust in the CSM’s ability to understand how
        the customer uses the product and that the CSM can offer intelligent recommendations in the
        customer’s best interest.
      </p>

      <p>
        On top of all that, the CSM must be able to show sincerity—that they care. Pretending to
        connect with the customer is not enough. A customer that sees through attempts to care about
        their company will quickly lose trust in the CSM, the business, and the product.
      </p>

      <p>
        Here are some of the most critical skills and attributes a Customer Success Manager needs to
        succeed:
      </p>

      <ul>
        <li>Ability to quickly learn new products and technologies</li>
        <li>A talent for forming relationships</li>
        <li>Industry knowledge</li>
        <li>Efficient and effective planning skills</li>
        <li>Personal and professional presentation skills</li>
        <li>Empathy and understanding of client needs</li>
      </ul>

      <img src={pic4} alt="" />

      <h2>CSM Primary Responsibilities</h2>

      <p>
        Customer Success Managers are tasked with identifying avenues of growth for both business
        and client and coming up with solutions for customer pain points and business growth.
      </p>

      <h3>Creative Problem-Solving</h3>

      <p>
        Customer Success Managers take care of their client's businesses and collaborate with them
        to address challenges. Ideally, their mission is to isolate and stop minor problems before
        they become larger ones. While doing so, they pay attention to their client's satisfaction
        levels to better come up with solutions before problems arise.
      </p>

      <p>
        CSMs follow up with clients monthly, if not weekly, and ask specific questions to determine
        how satisfied they are. They can gauge the general satisfaction of their customers by
        contrasting these conversations with their usage and behavior data, such as how frequently
        they log into a company’s software or how often they use a physical product.
      </p>

      <p>
        An effective CSM notices early warning signs and takes prompt action to address problems
        before they can turn into complaints. They might accomplish this by first contacting the
        client to check in or hosting a lunch or other meeting to learn how the product is working
        out for them.
      </p>

      <p>
        Because Customer Success Managers already keep a frequent, open line of communication
        available to the customer, this sort of engagement should feel natural.
      </p>

      <img src={pic5} alt="" />

      <h3>Promoting Products and Brands</h3>

      <p>
        CSMs generate interest in new or developing products by keeping clients updated. As new
        products or updates become available, a CSM takes the lead in conducting demos and training.
        Should customers show interest in new or updated products or services, the CSM can help make
        the proper connection between them and the sales staff.
      </p>

      <p>
        Because CSMs understand their client's needs, they’re in an excellent position to identify
        expansion possibilities and upsell opportunities.
      </p>

      <p>
        In promoting a business’s brand, a CSM should be able to facilitate technical support and
        training as needed. This is particularly important during onboarding when CSMs directly
        collaborate with customers to ensure that their product adoption proceeds without a hitch.
      </p>

      <h3>Long-Term Client Management</h3>

      <p>
        Sales and service representatives concentrate on the immediate satisfaction of consumers,
        and CSMs concentrate on providing value over the long term. So when a customer signs up,
        their commitment doesn’t finish; instead, it just starts.
      </p>

      <p>
        Customer Success Managers are, ultimately, responsible for relationship marketing.
        Therefore, they communicate openly with clients frequently to ensure that complaints are
        understood and swiftly resolved.
      </p>

      <Link to="/apply-now" title="Apply Now">
        <img src={pic6} alt="" />
      </Link>

      <h2>How Much Does a Customer Success Manager Earn?</h2>

      <p>
        In the US, a Customer Success Manager has an average salary of approximately $69,000. In
        addition, over a third of all Customer Success Managers surveyed report annual bonuses, with
        the average being about 10% of their base pay. The highest average salary for Customer
        Success Managers in the US is a little over $113,000 in New York, followed by about $100,000
        in San Francisco.
      </p>

      <p>
        Talented and experienced Customer Success Managers working in certain businesses have been
        known to earn as much as $150,000 annually.
      </p>

      <h2>Building a Career As a Customer Success Manager</h2>

      <p>
        Customers today demand more from businesses than just goods that satisfy their needs.
        Instead, they expect attentive service from experts concerned about their needs and goals.
      </p>

      <p>
        This expectation enhances the value of Customer Success Managers. Businesses need an
        individual in this position who can thoroughly understand a client’s needs. A good CSM
        communicates and internalizes feedback so their business can produce the products and
        services their customers demand.
      </p>

      <p>
        <a href={`https://${process.env.HOST}/employers`}>Start browsing candidates now</a> if you
        are looking for a new all-star Customer Success Manager to join your team. Or, if you’re
        looking for a new Customer Success Manager job opportunity,{' '}
        <a href={`https://${process.env.HOST}`}>sign-up</a> and start connecting with companies!
      </p>
    </div>
  );
}
