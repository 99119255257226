import React from 'react';
import { Helmet } from 'react-helmet-async';

import { AdminAuthHOC } from 'containers/HOC';

import { makeLikeParam } from 'utils/paramUtils';

import ConnectContainer from 'containers/ConnectContainer';

import ReadyContainer from 'connectors/ReadyContainer';

import Sidebar from 'components/Sidebar';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';

import { useTableQuery } from 'hooks';
import ToolboxEmployersTable from './components/ToolboxEmployersTable';

import duck from './redux';

import styles from './ToolboxEmployersPage.scss';

const route = '/toolbox/employers/';

const ToolboxEmployersPage = ({
  actions: { entityLoadStarted, patchResource, resourceUpdate, showModal },
  children,
  isLoading,
  total,
  employers: { byId = {}, allIds = [] },
  user,
  page = 1,
}) => {
  const {
    queryParams,
    loadMore,
    handleSearchInputChange,
    handleSearchFilterChange,
    handleSortChange,
  } = useTableQuery(
    route,
    page,
    {
      name: {
        type: 'string',
        defaultValue: null,
      },
      applicationStatus: {
        type: 'array',
        defaultValue: ['approved', 'pending'],
      },
      sortBy: {
        type: 'string',
        defaultValue: 'created_at_desc',
      },
    },
    {
      type: 'employers',
      slice: 'toolboxEmployers',
    },
    {},
    {
      name: makeLikeParam,
    }
  );

  return (
    <>
      <Helmet key="helmet" title="Toolbox - Employers" />
      <ReadyContainer key="readyContainer">
        <Sidebar type="toolbox" page="toolboxEmployers" />

        <LayoutWithoutSidebar
          content={
            <div className={styles.toolboxEmployersMainContent}>
              <ToolboxEmployersTable
                {...queryParams}
                entityLoadStarted={entityLoadStarted}
                isLoading={isLoading}
                user={user}
                employers={allIds.map((id) => byId[id])}
                patchResource={patchResource}
                resourceUpdate={resourceUpdate}
                showModal={showModal}
                total={total}
                loadMore={loadMore}
                handleSearchInputChange={handleSearchInputChange}
                handleSearchFilterChange={handleSearchFilterChange}
                handleSortChange={handleSortChange}
              />
            </div>
          }
        />

        {children}
      </ReadyContainer>
    </>
  );
};

export default ConnectContainer(duck)(AdminAuthHOC()(ToolboxEmployersPage));
