import React, { Component } from 'react';
import classnames from 'classnames';

import ReactMarkdown from 'react-markdown';
import { WaterfallHOC } from 'containers/HOC';
import EmptyAvatar from 'shared/assets/missing-user-picture.png';

import Bold from 'components/Bold';
import Content from 'components/Content';
import FormattedMoney from 'components/FormattedMoney';
import FormattedName from 'components/FormattedName';
import FontIcon from 'components/FontIcon';

import { extractLocation } from 'utils/locationUtils';

import ConnectContainer from 'containers/ConnectContainer';

import duck from 'shared/store/app';

import SearchAnalysis from '../SearchAnalysis';

import styles from './SearchResult.scss';

@WaterfallHOC()
class SearchResult extends Component {
  render() {
    const {
      className,
      candidate,
      handleOnClick,
      handleOnContextMenu,
      handleAddBookmark,
      handleRemoveBookmark,
      hideNames,
      isAdmin,
      requisitionOpeningId,
      searchString,
    } = this.props;

    const {
      summary,
      latestWorkHistoryJsonapi: latestWorkHistory = {},
      location,
      id: candidateId,
      isNew,
      bookmark,
      firstName,
      lastName,
      picture: { xlarge } = {},
      hide = false,
    } = candidate;

    const {
      data: {
        attributes: { position, soldToCompanySizes = [], role: recentRole = null } = {},
        relationships: {
          company: { data: { id: companyId } = {} } = {},
          salesPeriods: { data: salesPeriodObjects = [] } = {},
        } = {},
      } = {},
      included = [],
    } = latestWorkHistory || {};

    const company = included.filter((item) => [companyId].includes(item.id))[0];
    const companyName = company?.attributes.name;

    const locationFormatted = extractLocation(location);

    const salesPeriodsIds = salesPeriodObjects.map((sp) => sp.id);

    const salesPeriods = included.filter((item) => salesPeriodsIds.includes(item.id));

    const { attributes: { quotaAmountCents, dealsAverageCents, type } = {} } =
      salesPeriods[0] || {};

    const picUrl = !xlarge || xlarge === '' ? EmptyAvatar : xlarge;

    const classes = classnames(
      styles.SearchResult,
      {
        [styles.hidden]: hide,
      },
      className
    );

    const searchResultProps = {
      className: classes,
      onClick: () => handleOnClick(candidate),
    };

    const locationContent = locationFormatted && (
      <span className={styles.hiringStatus}>{locationFormatted}</span>
    );

    const hasBookmark = !isAdmin && bookmark;

    const bookmarkId = hasBookmark ? bookmark.id : null;

    const bookmarkOnClick = hasBookmark
      ? (event) => {
          event.stopPropagation();

          handleRemoveBookmark({
            bookmarkId,
            candidateId,
            candidateFirstName: firstName,
            candidateLastName: lastName,
          });
        }
      : (event) => {
          event.stopPropagation();

          handleAddBookmark(candidateId);
        };

    const bookmarkClassName = hasBookmark ? styles.resultBookmarked : styles.resultUnbookmarked;

    const bookmarkTitle = hasBookmark ? 'Remove Bookmark' : 'Add Bookmark';

    const bookmarkIconProps = {
      className: classnames(styles.resultBookmark, bookmarkClassName),
      onClick: bookmarkOnClick,
      title: bookmarkTitle,
    };

    const bookmarkContent = !isAdmin ? (
      <div {...bookmarkIconProps}>
        {hasBookmark ? (
          <FontIcon iconName="bookmark-24" />
        ) : (
          <FontIcon iconName="empty-bookmark-24" />
        )}
      </div>
    ) : null;

    const quotaContent = quotaAmountCents ? (
      <div className={styles.resultNumberDetails}>
        <FormattedMoney amount={quotaAmountCents} />
        <span className={styles.numberLabel}>(Quota)</span>
      </div>
    ) : null;

    const dealAverageContent = dealsAverageCents ? (
      <div className={styles.resultNumberDetails}>
        <FormattedMoney amount={dealsAverageCents} />
        <span className={styles.numberLabel}>(Avg. Deal Size)</span>
      </div>
    ) : null;

    const supportsOrSellsTo = type === 'CSMSalesPeriod' ? 'Supports' : 'Sells To';

    const soldToCompanySizesContent =
      soldToCompanySizes && soldToCompanySizes[0] ? (
        <div className={styles.resultNumberDetails}>
          {soldToCompanySizes[0]}
          <span className={styles.numberLabel}>({supportsOrSellsTo})</span>
        </div>
      ) : null;

    const formattedNameProps = { useHideNames: true, candidateId, firstName, lastName };

    const nameContent = <FormattedName {...formattedNameProps} />;

    const nameProps = { className: styles.resultName };

    const numbersContent =
      recentRole === 'Non-sales' ? null : (
        <div className={styles.resultNumbers}>
          {quotaContent}
          {dealAverageContent}
          {soldToCompanySizesContent}
        </div>
      );

    const handleNameOnClick = (event) => {
      if (event.ctrlKey || event.metaKey) {
        event.stopPropagation();
      } else {
        event.preventDefault();
      }
    };

    const truncatedSummary =
      summary && summary.length > 275 ? `${summary.substr(0, 275)}...` : summary;

    const hideOverlay = hide ? <div className={styles.hideContainer} /> : null;

    const isNewContent = isNew ? (
      <div className={styles.newContent}>
        <FontIcon iconName="rocket" />
      </div>
    ) : null;

    const analysisProps = {
      candidateId,
      requisitionOpeningId,
      searchString,
    };

    const analysisContent = isAdmin ? <SearchAnalysis {...analysisProps} /> : null;

    // const useBlur = xlarge && hideNames && (noReqCand || noReqCand === null);
    const useBlur = xlarge && hideNames;

    const picContainerClasses = classnames(styles.resultImage, {
      [styles.blur]: useBlur,
    });

    return (
      <div className={styles.mainContainer}>
        <div {...searchResultProps}>
          <div className={picContainerClasses}>
            <img src={picUrl} alt="user" />
            {hideOverlay}
          </div>
          <div className={styles.resultDetails}>
            <div className={styles.resultHeader}>
              <div {...nameProps}>
                <a
                  onContextMenu={() => handleOnContextMenu(candidate)}
                  onClick={handleNameOnClick}
                  href={`/candidates/${candidateId}/profile/?tp=f`}
                >
                  {nameContent}
                </a>
              </div>
              <div className={styles.resultStatus}>{locationContent}</div>
              {isNewContent}
            </div>
            <div className={styles.profileCompany}>
              {position || null}
              <Bold>{companyName ? `@ ${companyName}` : null}</Bold>
            </div>
            <Content className={styles.resultSummary}>
              <ReactMarkdown children={truncatedSummary} className={styles.markdownSummary} />
            </Content>
            {numbersContent}
            {analysisContent}
          </div>
          <div className={styles.resultMatch}>{bookmarkContent}</div>
        </div>
      </div>
    );
  }
}

export default ConnectContainer(duck)(SearchResult);
