import React from 'react';
import classnames from 'classnames';

import styles from './Title.scss';

const Title = ({ string, children, className }) => {
  const classes = classnames(styles.Title, className);

  return <h1 className={classes}>{string || children}</h1>;
};

export default Title;
