import React from 'react';
import classnames from 'classnames';

import styles from './Content.scss';

const Content = ({ children, className, ...otherProps }) => (
  <div className={classnames(styles.Content, className)} {...otherProps}>
    {children}
  </div>
);

export default Content;
