import React from 'react';
import InputSelectMonthYear from 'components/InputSelectMonthYear';
import InputCheckbox from 'components/InputCheckbox';
import Label from 'components/Label';

import styles from './FromToYearMonth.scss';

function FromToYearMonth({
  idx,
  startDate,
  endDate,
  handleWorkHistoryDateInputChange,
  handleCurrentPositionInputChange,
  autofocusFields,
  yearsLabal = 'Years',
  presentLabel = 'Present',
  currentlyWorkHearLabel = 'I currently work here',
  ...rest
}) {
  return (
    <div {...rest}>
      <Label required standalone className={styles.label}>
        {yearsLabal}
      </Label>
      <div className={styles.basicInfoRowInputSelects}>
        <div className={styles.inputSelectsRow}>
          <span className={styles.labelSubtext}>From</span>
          <InputSelectMonthYear
            onChange={({ name, value }) =>
              handleWorkHistoryDateInputChange({ name: `${name}Date`, value, idx })
            }
            name={'start'}
            value={startDate}
            autofocusFields={autofocusFields}
          />
        </div>
        <div className={styles.inputSelectsRow}>
          <span className={styles.labelSubtextTo}>to</span>
          {endDate !== null ? (
            <InputSelectMonthYear
              onChange={({ name, value }) =>
                handleWorkHistoryDateInputChange({ name: `${name}Date`, value, idx })
              }
              name={'end'}
              value={endDate}
              autofocusFields={autofocusFields}
            />
          ) : (
            <span className={styles.presentLabel}>{presentLabel}</span>
          )}
        </div>
      </div>

      <div>
        <InputCheckbox
          name={'currentlyWork'}
          label={currentlyWorkHearLabel}
          checked={endDate === null || false}
          handleInputChange={(event) => handleCurrentPositionInputChange({ event, idx })}
          className={styles.inputCheckbox}
          checkboxMany={true}
        />
      </div>
    </div>
  );
}

export default FromToYearMonth;
