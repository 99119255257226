export const toDollars = (amount) => Math.floor(amount / 100).toLocaleString();

export const toCents = (amount) => {
  const cleanAmount = amount.toString().replace(/[^\d.-]/g, '');
  const parsedAmount = parseInt(cleanAmount, 10);
  return parsedAmount === '' ? null : Math.round(parsedAmount * 100);
};

export const toAbbr = (amount) => {
  if (amount >= 100000000) {
    return `${(Math.round(amount / 10000000) / 10).toFixed(1)}M`;
  }
  if (amount >= 100000) {
    return `${Math.round(amount / 100000)}K`;
  }
  if (amount >= 100) {
    return `${Math.round(amount / 100)}`;
  }
  return `0.${amount}`;
};
