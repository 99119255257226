import { createSelector } from 'reselect';
import { getApp } from 'shared/store/app/selectors';

const selectCandidateHome = createSelector(getApp, (state) => state.candidateHome);

const selectProfileCardsLoading = createSelector(
  selectCandidateHome,
  (candidateHome = {}) => candidateHome.profileCardsLoading
);

export default selectProfileCardsLoading;
