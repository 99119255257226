import React from 'react';
import classnames from 'classnames';

import ConnectContainer from 'containers/ConnectContainer';

import Button from 'components/Button';
import Overlay from 'components/Overlay';

import duck from 'shared/store/app/';

import styles from './Notification.scss';

const Notification = ({
  className,
  notification: { actionButton, confirmText, text, title } = {},
  actions: { resetNotification },
}) => {
  return text ? (
    <Overlay isOpened>
      <div className={classnames(styles.Notification, className)}>
        <div className={styles.title}>{title}</div>
        <div className={styles.text}>{text}</div>
        <div className={styles.actionButtons}>
          {actionButton}
          <Button onClick={resetNotification} tertiary={true}>
            {confirmText}
          </Button>
        </div>
      </div>
    </Overlay>
  ) : null;
};

export default ConnectContainer(duck)(Notification);
