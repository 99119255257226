import React from 'react';
import dayjs from 'dayjs';

import EmptyCompanyLogo from 'shared/assets/missing-company-picture.png';

import FormattedDate from 'components/FormattedDate';

import styles from './WorkName.scss';

const WorkName = ({
  companyLogo,
  companyName = null,
  positionName,
  startDate,
  endDate,
  workDetailButton,
  workDetails,
}) => {
  const startYear = dayjs(startDate, 'YYYY-MM-DD').get('year');
  const startMonth = dayjs(startDate, 'YYYY-MM-DD').get('month');
  const endYear = endDate ? dayjs(endDate, 'YYYY-MM-DD').get('year') : 'Present';
  const endMonth = endDate ? dayjs(endDate, 'YYYY-MM-DD').get('month') : null;
  const startDateFecha = new Date(startYear, startMonth, 3);
  const endDateFecha = endDate ? new Date(endYear, endMonth, 3) : null;
  const startMonthFormatted = <FormattedDate date={startDateFecha} format="MMM" />;
  const endMonthFormatted = endDate ? <FormattedDate date={endDateFecha} format="MMM" /> : null;

  const picUrl = companyLogo === '' ? EmptyCompanyLogo : companyLogo;

  return (
    <div className={styles.workNameContainer}>
      <div className={styles.workLogoContainer}>
        <img className={styles.workLogo} src={picUrl} alt="" />
      </div>
      <div className={styles.workNameDetailContainer}>
        <div className={styles.workNameDetailTop}>
          <div className={styles.workNameDetail}>
            <div className={styles.workPosition}>{positionName}</div>
            <div className={styles.workName}>{companyName}</div>
            <div className={styles.workDate}>
              {startMonthFormatted} {startYear} - {endMonthFormatted} {endYear}
            </div>
          </div>
          {workDetailButton}
        </div>
        {workDetails}
      </div>
    </div>
  );
};

export default WorkName;
