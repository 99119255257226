import React from 'react';

import styles from './ProfileSummarySection.scss';

export const formatMoney = (amount) => {
  if (!amount) return 'no information';

  if (amount >= 100000000) {
    return `${(Math.round(amount / 10000000) / 10).toFixed(1)}M`;
  }
  if (amount >= 100000) {
    return `${Math.round(amount / 100000)}K`;
  }

  return amount;
};

export const ProfileSummarySection = ({ candidateData }) => {
  const { candidateRequirement: { salaryCents, oteCents, locationNotes } = {} } =
    candidateData || {};

  const salary = salaryCents ? `$${formatMoney(salaryCents)} Base` : '';
  const ote = oteCents ? `$${formatMoney(oteCents)} OTE` : '';
  const reasonForLooking = locationNotes ? locationNotes.split('\n') : [];

  console.log('reason', reasonForLooking);

  return (
    <div className={styles.profileSummarySectionContainer}>
      <div>
        {(salary || ote) && (
          <>
            <h2>Compensation target</h2>
            {salary && ote && <div>{`${salary}, ${ote}`}</div>}
            {salary && !ote && <div>{salary}</div>}
            {!salary && ote && <div>{ote}</div>}
          </>
        )}
      </div>
      <div className={styles.recruiterInsights}>
        {reasonForLooking.length > 0 && (
          <>
            <h2>Recruiter Insights</h2>
            <>
              {reasonForLooking.map((line, idx) => (
                <div key={idx}>{line}</div>
              ))}
            </>
          </>
        )}
      </div>
    </div>
  );
};
