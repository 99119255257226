import React from 'react';
import ImageUploader from 'components/ImageUploader';

const CandidateImageUploader = ({
  candidateId,
  candidateImageUploadStarted,
  pictureUrl,
  highlight,
}) => {
  return (
    <ImageUploader
      name="Candidate"
      entityId={candidateId}
      imageUploadStarted={candidateImageUploadStarted}
      pictureUrl={pictureUrl}
      maxImageSizeMb={10}
      highlight={highlight}
      data-testid="CandidateImageUploader"
    />
  );
};

export default CandidateImageUploader;
