import React from 'react';
import classnames from 'classnames';

import styles from './Oblique.scss';

const Oblique = ({ children, className }) => {
  const classes = classnames(styles.Oblique, className);

  return <span className={classes}>{children}</span>;
};

export default Oblique;
