import React, { useState, useRef } from 'react';

import Slider from 'react-rangeslider';
import AvatarEditor from 'react-avatar-editor';

import Button from 'components/Button';
import DialogueModal from 'components/DialogueModal';
import Label from 'components/Label';

import styles from './ImageCropModal.scss';

const ImageCropModal = ({ handleImageCropSaveClick, tempImage, handleImageCropCloseClick }) => {
  const [state, setState] = useState({
    zoom: 1,
    rotation: 0,
  });

  const editor = useRef();

  const onClickSave = () => {
    // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
    // drawn on another canvas, or added to the DOM.
    const origCanvas = editor.current.getImage();
    const canvas = document.createElement('canvas');

    // Save image at 2048 x 2048
    canvas.width = 2048;
    canvas.height = 2048;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(origCanvas, 0, 0, 2048, 2048);

    const dataUrl = canvas.toDataURL('image/jpeg', 0.92);

    handleImageCropSaveClick(dataUrl);
  };

  const changeZoom = (value) => {
    setState((prevState) => ({ ...prevState, zoom: value }));
  };

  const changeRotationRight = () => {
    const { rotation } = state;

    const newRotation = rotation === 270 ? 0 : rotation + 90;

    setState((prevState) => ({ ...prevState, rotation: newRotation }));
  };

  const changeRotationLeft = () => {
    const { rotation } = state;

    const newRotation = rotation === 0 ? 270 : rotation - 90;

    setState((prevState) => ({ ...prevState, rotation: newRotation }));
  };

  const { zoom, rotation } = state;

  const title = 'Crop Image';

  const avatarEditorProps = {
    ref: editor,
    image: tempImage,
    border: 50,
    color: [0, 0, 0, 0.4], // RGBA
    scale: zoom,
    rotate: rotation,
    style: {
      minWidth: '256px',
      minHeight: '256px',
      maxWidth: '384px',
      maxHeight: '384px',
      width: '30vmax',
      height: '30vmax',
      margin: '0 auto',
    },
  };

  const zoomPercentageInputProps = {
    className: styles.rangeSlider,
    min: 1,
    max: 3,
    step: 0.05,
    value: zoom,
    orientation: 'horizontal',
    tooltip: false,
    onChange: changeZoom,
  };

  return (
    <DialogueModal large>
      <div className={styles.imageCropModal}>
        <div className={styles.imageCropTitle}>{title}</div>

        <AvatarEditor {...avatarEditorProps} />

        <div className={styles.slider}>
          <Label standalone>Zoom</Label>
          <Slider {...zoomPercentageInputProps} />
        </div>

        <Label standalone>Orientation</Label>
        <div className={styles.rotators}>
          <Button onClick={changeRotationLeft} secondary>
            Rotate Left
          </Button>
          <Button onClick={changeRotationRight} secondary>
            Rotate Right
          </Button>
        </div>

        <div className={styles.imageCropButtons}>
          <Button onClick={handleImageCropCloseClick} quaternary>
            Cancel
          </Button>
          <Button onClick={onClickSave} primary>
            Save
          </Button>
        </div>
      </div>
    </DialogueModal>
  );
};

export default ImageCropModal;
