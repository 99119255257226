import React from 'react';
import Slider from 'react-rangeslider';

import AutocompleteServer from 'connectors/AutocompleteServer';
import Label from 'components/Label';
import Tag from 'components/Tag';

import styles from '../ReqRequirement.scss';

const ProductTypes = ({
  disabled,
  handleToggleArrayValue,
  handleInputChange,
  requisitionRequirement: { attributes: { productTypes, productTypesWt } = {} } = {},
}) => {
  const productTypesInputProps = {
    placeholder: 'Start typing and select a product type or add if not found',
    value: '',
    size: 'full',
    name: 'productTypes',
    label: 'Products previously sold',
    handleInputChange: (event) =>
      handleToggleArrayValue({
        ...event,
        target: {
          ...event.target,
          name: event.target.name,
          value: event.target.value.name,
        },
      }),
    handleOnBlur: handleToggleArrayValue,
    autocompleteType: 'product_types',
    field: 'name',
    needReset: true,
    hasAddButton: true,
    disabled,
  };

  const productTypesSelected =
    productTypes && productTypes.length > 0
      ? productTypes.map((type) => {
          const tagProps = {
            name: type,
            inputName: 'productTypes',
            onDeleteClick: (name, inputName) =>
              handleToggleArrayValue({
                target: {
                  value: name,
                  name: inputName,
                },
              }),
          };
          return <Tag key={type} {...tagProps} />;
        })
      : null;

  const productTypesWtProps = {
    disabled,
    className: styles.rangeSlider,
    min: 0,
    max: 10,
    step: 5,
    value: productTypesWt || 0,
    onChange: disabled
      ? () => null
      : (value) =>
          handleInputChange({
            target: {
              value,
              name: 'productTypesWt',
            },
          }),
    tooltip: false,
    labels: {
      0: '0',
      5: '5',
      10: '10',
    },
  };

  return (
    <div className={styles.rangeInputs}>
      <div className={styles.column}>
        <AutocompleteServer {...productTypesInputProps} />
        <div className={styles.autocompleteContainer}>{productTypesSelected}</div>
      </div>
      <div className={styles.column}>
        <Label className={styles.labelStyle}>How much do you value this?</Label>
        <Slider {...productTypesWtProps} />
      </div>
    </div>
  );
};

export default ProductTypes;
