import React from 'react';
import ImageUploader from 'components/ImageUploader';

import EmptyEducationLogo from 'shared/assets/missing-education-organization-picture.png';

const EducationOrgImageUploader = ({
  educationOrgId,
  educationOrgImageUploadStarted,
  pictureUrl,
}) => {
  return (
    <ImageUploader
      name="Company"
      entityId={educationOrgId}
      imageUploadStarted={educationOrgImageUploadStarted}
      pictureUrl={pictureUrl}
      emptyLogo={EmptyEducationLogo}
      maxImageSizeMb={4}
    />
  );
};

export default EducationOrgImageUploader;
