import React, { useState } from 'react';

import { toCents, toDollars } from 'utils/moneyUtils';

import Bold from 'components/Bold';
import Bolder from 'components/Bolder';
import Button from 'components/Button';
import DatePicker from 'components/DatePicker';
import DialogueModal from 'components/DialogueModal';
import DropdownContent from 'components/DropdownContent';
import FormattedDate from 'components/FormattedDate';
import Input from 'components/Input';
import Label from 'components/Label';

import styles from '../DialogModal.scss';

const PrivateReqCanFeedbackEmployerHire = ({ firstName, lastName, handleSubmit, handleCancel }) => {
  const [baseCents, setBaseCents] = useState(null);
  const [offerSignedDate, setOfferSignedDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [variableCents, setVariableCents] = useState(null);

  const isValid = (value) => value !== null && value !== undefined && value !== '';

  const disabled = !isValid(baseCents) || !isValid(variableCents) || !isValid(offerSignedDate);

  const confirmButtonProps = {
    onClick: () =>
      handleSubmit({
        baseCents,
        offerSignedDate,
        rating: 10,
        startDate,
        variableCents,
      }),
    primary: true,
    className: styles.confirm,
    disabled,
  };

  const confirmButton = <Button {...confirmButtonProps}>Confirm</Button>;

  const closeButtonProps = {
    quaternary: true,
    onClick: handleCancel,
  };

  const closeButton = <Button {...closeButtonProps}>Cancel</Button>;

  const promptString = (
    <div className={styles.promptTitle}>
      <div>
        Congratulations on hiring{' '}
        <Bolder>
          {firstName} {lastName}
        </Bolder>
        !
      </div>
      <div className={styles.subtitle}>Please let us know the following:</div>
    </div>
  );

  const commonTextInputProps = {
    type: 'text',
    required: true,
    className: styles.inputContainerNoPad,
    moneyInput: true,
    size: 'full',
  };

  const baseCentsProps = {
    ...commonTextInputProps,
    handleInputChange: (event) => setBaseCents(toCents(event.target.value)),
    value: baseCents || baseCents === 0 ? toDollars(baseCents) : '',
    name: 'baseCents',
    label: 'Base',
  };

  const variableProps = {
    ...commonTextInputProps,
    value: variableCents || variableCents === 0 ? toDollars(variableCents) : '',
    handleInputChange: (event) => setVariableCents(toCents(event.target.value)),
    name: 'variableCents',
    label: 'Variable',
  };

  const offerSignedProps = {
    onDayClick: (day) => setOfferSignedDate(day),
    selectedDays: [offerSignedDate],
  };

  const offerSignedContent = (
    <div className={styles.addCandidatesOptionsContainer}>
      <DatePicker {...offerSignedProps} />
    </div>
  );

  const offerSignedDropdownProps = {
    label: offerSignedDate ? <FormattedDate date={offerSignedDate} /> : 'Select date',
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    dropRight: true,
  };

  const startDateProps = {
    onDayClick: (day) => setStartDate(day),
    selectedDays: [startDate],
  };

  const startDateContent = (
    <div className={styles.addCandidatesOptionsContainer}>
      <DatePicker {...startDateProps} />
    </div>
  );

  const startDateDropdownProps = {
    label: startDate ? <FormattedDate date={startDate} /> : 'Select date',
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    dropRight: true,
  };

  const inputs = (
    <div className={styles.inputsMain}>
      <div className={styles.inputsRow}>
        <Input {...baseCentsProps} />
        <Input {...variableProps} />
      </div>
      <div className={styles.inputsRow}>
        <div className={styles.inputsColumn}>
          <Label standalone required>
            Offer Signed Date
          </Label>
          <DropdownContent {...offerSignedDropdownProps}>{offerSignedContent}</DropdownContent>
        </div>
        <div className={styles.inputsColumn}>
          <Label standalone>Start Date (optional)</Label>
          <DropdownContent {...startDateDropdownProps}>{startDateContent}</DropdownContent>
        </div>
      </div>
    </div>
  );

  return (
    <DialogueModal>
      <div className={styles.promptModal}>
        <Bold className={styles.promptContent}>{promptString}</Bold>

        <div className={styles.inputContent}>{inputs}</div>

        <div className={styles.promptButtons}>
          {closeButton}
          {confirmButton}
        </div>
      </div>
    </DialogueModal>
  );
};

export default PrivateReqCanFeedbackEmployerHire;
