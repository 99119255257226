import { getCurrentUser } from '../../../src/api/apiAuth';
import { getEntity } from '../../../src/api/apiEntity';

const promiseGetCurrentUser = async (headers) => {
  const config = {
    headers,
    params: {
      normalizeIt: true,
      includeSet: 'login',
    },
  };

  const { data: { entities = {} } = {} } = await getCurrentUser(config);

  const { user: { byId = {}, allIds = [] } = {} } = entities;

  const userId = allIds[0];

  if (!userId) {
    return null;
  }

  const { [userId]: { attributes, attributes: { currentProfileType } } = {} } = byId || {};

  const type =
    currentProfileType === 'RainmakersAdmin' ? 'rainmakersAdmin' : currentProfileType.toLowerCase();

  const { [type]: { byId: profileById = {}, allIds: profileAllIds = [] } = {} } = entities || {};

  const profileId = profileAllIds[0];

  const { [profileId]: { attributes: currentProfile = {} } = {} } = profileById || {};

  if (type === 'employee') {
    const { employerId } = currentProfile;

    const args = {
      id: employerId,
      type: 'employers',
      config: {
        headers,
        params: {
          normalizeIt: true,
        },
      },
    };

    const { data: { entities: { employer: { byId = {}, allIds = [] } = {} } = {} } = {} } =
      await getEntity(args);

    const { attributes: employer = {} } = byId[allIds[0]] || {};
    const fullUser = {
      ...attributes,
      currentProfile: {
        ...currentProfile,
        id: profileId,
        employer,
      },
    };

    return fullUser;
  }

  const fullUser = {
    ...attributes,
    currentProfile: {
      ...currentProfile,
      id: profileId,
    },
  };

  return fullUser;
};

export default promiseGetCurrentUser;
