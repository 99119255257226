import { handleActions } from 'redux-actions';

import { ADMIN_LOAD_DONE } from '../actions';

const initialState = {
  rainmakersAdmin: {},
  entities: {
    requisitionCandidate: {
      byId: {},
      allIds: [],
    },
    requisitionOpening: {
      byId: {},
      allIds: [],
    },
  },
  meta: {
    requisitionCandidate: {
      pagination: {},
    },
    requisitionOpening: {
      pagination: {},
    },
  },
};

const handleAdminLoadDone = (state = initialState, action) => {
  const {
    payload: { rainmakersAdmin },
  } = action;

  return {
    ...state,
    rainmakersAdmin,
  };
};

const actionHandlers = {
  [ADMIN_LOAD_DONE]: handleAdminLoadDone,
};

const reduceCandidates = handleActions(actionHandlers, initialState);

export default reduceCandidates;
