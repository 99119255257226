/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import pic1 from './assets/1-sales-director-job-information.png';
import pic2 from './assets/2-contact-rainmakers-for-sales-director-jobs.png';
import pic3 from './assets/3-sales-director-jobs-near-me.png';
import pic4 from './assets/4-sales-director-salary-information.png';
import pic5 from './assets/5-sales-director-jobs.png';
import pic6 from './assets/6-create-profile-to-browse-sales-director-jobs.png';

import styles from './SDJobsContent.scss';

export function SDJobsContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt="" />

      <h2>Sales Director Job Information</h2>

      <p>
        Sales Directors oversee all sales activities carried out by the sales team, strategically
        plan and introduce new products and services, and ensure the business maintains top-notch
        customer service. They inspire the sales team and make sure they achieve anticipated sales
        targets.
      </p>

      <h2>What Does a Sales Director Do?</h2>

      <p>
        Depending on the company and the sector the sales director works in, specific duties may
        vary but often include:
      </p>

      <ul>
        <li>Energize and inspire the sales team</li>
        <li>Push their own deals over the finish line</li>
        <li>Prepare sales reports, verify their accuracy, and submit them.</li>
        <li>Set monthly, quarterly, and annual sales targets and ensure they are being met.</li>
        <li>Create strategic plans to meet sales targets and increase the consumer base.</li>
        <li>Report targets and strategic plans to upper management,</li>
        <li>
          Evaluate industry trends and the competitive environment to help improve the sales
          program.
        </li>
        <li>Implement a sales orientation and training program to educate new hires.</li>
        <li>
          Create a referral network with local or regional companies and look for ways to take
          advantage of those connections.
        </li>
      </ul>

      <Link to="/apply-now" title="Apply Now">
        <img src={pic2} alt="" />
      </Link>

      <h2>What Skills Does a Sales Director Need?</h2>

      <p>
        To be successful in their position, Sales Directors must possess both hard and soft talents
        to varying degrees. The following are some of the expected abilities needed for a sales
        director position:
      </p>

      <p>
        <strong>Leadership</strong> – Leadership is the ability to inspire, support, and guide teams
        and employees to carry out their duties effectively and efficiently. Sales managers must
        successfully and positively guide their personnel toward success.
      </p>

      <p>
        <strong>Communication</strong> – Sales Directors must possess outstanding verbal, nonverbal,
        and written communication skills. They must be able to listen actively, observe, and
        empathize with both employees and management, and be able to convey expectations, receive
        input, and provide appropriate feedback.
      </p>

      <p>
        <strong>Business Development Expertise</strong> – Business development expertise entails the
        capacity to assess the performance and policies of business entities and spot opportunities
        for improvement. To continue to increase sales and build a broader customer base, Sales
        Directors must continually assess the performance and goals of the business, staff, and
        themselves.
      </p>

      <p>
        <strong>Problem-Solving and Critical Thinking</strong> – A Sales Director needs to have the
        ability to apply knowledge, facts, statistics, and reasoning to offer solutions to business
        and sales issues. Sales directors frequently find themselves in high-stress, time-sensitive
        situations, so they must be able to think quickly and react appropriately.
      </p>

      <p>
        <strong>Technology Proficiency</strong> – Technology proficiency includes understanding both
        software and network systems. The duties of a Sales Director will often require knowledge of
        the complete Microsoft Office suite, SaaS software, cloud computing, business-specific ERP
        software, and Salesforce technology.
      </p>

      <p>
        <strong>Time Management Skills</strong> – This is the ability to balance and prioritize
        tasks to allow you to finish your work quickly and efficiently while maintaining a healthy
        work-life balance. Because much of a Sales Director’s take requires a certain amount of
        multitasking, they must be able to prioritize their duties and distribute work as
        appropriate.
      </p>

      <img src={pic3} alt="" />

      <h2>What Is a Sales Director’s Work Environment Like?</h2>

      <p>
        Unsurprisingly, Sales Directors spend most of their days in a full-time office environment.
        However, they may also spend a part of the day on the sales floor with their teams or
        visiting other departments to help stay abreast of new products, upgrades, and other
        business developments. Occasionally, they may even operate in the field, meeting key
        customers in person to close deals.
      </p>

      <p>
        A Sales Director tends to work a standard Monday to Friday business hour schedule but may
        occasionally work on some weekends or evenings depending on the business or if they are
        attending off-site functions such as conferences or conventions.
      </p>

      <p>
        Sales Directors are almost always salaried employees, and many work more than 40 hours per
        week—not including possible travel.
      </p>

      <h2>What Is the Average Salary of a Sales Director</h2>

      <p>
        As a salaried employee who may be expected to work over 40 hours a week plus possible
        travel, the average Sales Director’s salary in the United States is approximately $187,000
        as of mid-2022. Still, the range typically falls between about $164,000 and $214,000
        annually.
      </p>

      <p>
        Salary ranges can vary widely depending on many important factors, including education,
        certifications, sales region, company location, industry type, skill set, and years spent in
        the profession.
      </p>

      <img src={pic4} alt="" />

      <h2>Requirements To Become a Sales Director</h2>

      <p>
        Depending on the industry and size of the organization, there may be particular
        prerequisites needed to become a sales director, such as:
      </p>

      <p>
        <strong>Education</strong> – It’s highly recommended that a Sales Director candidate possess
        a bachelor’s degree in business administration, marketing, communications, or a closely
        related discipline. While it’s possible to get this position based solely on extensive
        experience and proven success, most recruiters will require a degree from an accredited
        school. A Masters in Business Administration (MBA) will tend to put the candidate in an even
        more favorable position.
      </p>

      <p>
        <strong>Training</strong> – Sales Directors frequently require a proven track record of at
        least seven to ten years in a relevant position, such as an Account Executive, Account
        Manager, or Director of Sales and Marketing. In addition to prior experience, Sales
        Directors frequently undergo further company training to hone their management and
        leadership abilities, learn crucial systems and technologies, enhance their communication
        skills, prioritize and delegate tasks, and inspire, encourage, and give feedback to their
        teams. This additional training is often provided to Sales Directors as part of the
        onboarding process for their new position.
      </p>

      <p>
        <strong>Certifications</strong> – There are several certification programs available to
        Sales Directors. While they are not absolutely required to acquire a job or develop your
        career, they are, nonetheless, useful to have. Certifications allow you to document your
        talents and credentials to current and future employers. The following are common
        certifications obtained by current and potential Sales Directors:
        <ul>
          <li>
            <strong>National Association of Sales Professionals (NASP)</strong> – The Certified
            Professional Sales Person (CPSP) and Certified Professional Sales Leader (CPSL)
            credentials are provided by the National Association of Sales Professionals (NASP). The
            CPSP and CPSL certificates are based on The 45-Day Challenge series, which combines
            practical behavioral training with tried-and-true daily conditioning to help you become
            an exceptional sales professional or sales leader.
          </li>

          <li>
            <strong>Sales Management Association</strong> – The Certified Sales Leadership
            Professional (CSLP) and Certified Sales Operations Professional (CSOP) qualifications
            are available from the Sales Management Association.
          </li>
          <li>
            <strong>Salesforce</strong> – One of the top customer relationship management (CRM)
            platforms utilized by businesses across various sectors is Salesforce. The Salesforce
            certification demonstrates that you have the expertise and understanding of the CRM
            skills required for an executive-level position.
          </li>
          <li>
            <strong>AIPMM Certified Brand Manager</strong> – The Certified Brand Manager
            certification is provided by the Association of International Product Marketing and
            Management (AIPMM). Candidates for this certification learn how to maximize brand equity
            and value as well as customer relationship management. This certification is excellent
            for demonstrating to employers that you are knowledgeable about brand management.
          </li>
          <li>
            <strong>Certificate in Analytical Skills</strong> – Hiring managers are always looking
            for executives with good analytical abilities. The American Management Association (AMA)
            offers three levels of analytical skills certificates.
          </li>
          <li>
            <strong>Google Certifications</strong> – Google offers several certification programs,
            including Google Analytics and Google AdWords. When doing demographic analysis and
            enhancing search engine optimization and digital advertising, a firm grasp of Google
            Analytics and AdWords is essential.
          </li>
        </ul>
      </p>

      <img src={pic5} alt="" />

      <h2>Becoming a Sales Director</h2>

      <p>
        A mix of education and appropriate professional experience is essential to acquiring the
        position of Sales Director. Many Sales Directors advance to their present employers’
        executive-level positions through promotions. Just the same, consider the following a
        checklist for achieving the goal of becoming a Sales Director:
      </p>

      <ol>
        <li>
          <strong>Obtain degree</strong> – Sales Directors should have a bachelor’s degree in
          business, marketing, communications, or a related profession. Many companies prefer
          MBA-holders.
        </li>
        <li>
          <strong>Gain experience</strong> – If your school offers internship opportunities, you
          should try to take part. Internships help you get crucial sales and management experience.
          Many businesses prefer applicants with 3-5 years of managerial experience and 10+ years of
          sales experience.
        </li>
        <li>
          <strong>Demonstrate proven skills</strong> – Many sales directors are promoted from inside
          the company, so it’s crucial to show your leadership and management qualities early on.
          Use your current position to show you are organized, can accomplish your work, demonstrate
          good attendance, be a problem solver, and make proactive decisions.
        </li>
        <li>
          <strong>Make your intentions clear to management</strong> – Express your desire to become
          a Sales Director. Discuss your function and contributions to the company with senior
          management and ask them how you can improve your Sales Director skills.
        </li>
        <li>
          <strong>Have positive relationships with colleagues</strong>– Sales directors must
          maintain good relationships with the people they hope to one day motivate, lead, and
          provide feedback to. Use your current role to show your employer your communication skills
          and professional demeanor.
        </li>
        <li>
          <strong>Be certified</strong> – Certifications aren’t essential, but they can help you
          stand out to hiring managers.
        </li>
      </ol>

      <Link to="/apply-now" title="Apply Now">
        <img src={pic6} alt="" />
      </Link>

      <h2>Being a Sales Director Is Within Your Reach</h2>

      <p>
        Sales Directors supervise every sales activity the sales team engages in. They serve as the
        head and nervous system of any business’s sales department. They need to be able to
        strategically develop and launch new products and services, ensure that the company
        continues to provide excellent customer service, motivate the sales team, and ensure that
        the sales team meets its predetermined sales goals.
      </p>

      <p>
        This takes knowledge, experience, and confidence in one’s own abilities. With proper
        preparation and experience, becoming a Sales Director is a worthy and rewarding goal.
      </p>

      <p>
        If you are a technology company looking for a new Sales Director to join your team or are
        looking for a new sales career opportunity, let Rainmakers help!{' '}
        <a href={`https://${process.env.HOST}/`}>Sign-up now</a> to get started.
      </p>
    </div>
  );
}
