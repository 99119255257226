import React from 'react';
import classnames from 'classnames';

import Tag from 'components/Tag';

import styles from './SearchChips.scss';

const SearchChips = ({
  handleKeywordDelete,
  className,
  keywordsFilter,
  tagClassName,
  ...otherProps
}) => {
  if (!keywordsFilter) return null;

  const { filter, value: valArray } = keywordsFilter;

  if (!Array.isArray(valArray)) {
    return null;
  }

  return (
    <div className={classnames(styles.SearchChips, className)} {...otherProps}>
      {valArray.map((value) => (
        <Tag
          className={tagClassName}
          name={value}
          key={value}
          value={value}
          inputName={filter}
          onDeleteClick={handleKeywordDelete}
        />
      ))}
    </div>
  );
};

export default SearchChips;
