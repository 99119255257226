import React from 'react';
import { Helmet } from 'react-helmet-async';

import ReadyContainer from 'connectors/ReadyContainer';
import FourOhFourImage from 'shared/assets/404.png';

import styles from './NotFound.scss';

const NotFound = () => (
  <React.Fragment>
    <Helmet key="helmet" title="404 - Page Not Found" />
    <ReadyContainer key="readyContainer" className={styles.container}>
      <div className={styles.imageWrapper}>
        <img src={FourOhFourImage} alt="404" />
      </div>
      <div className={styles.errorText}>Page not found. Only this bear.</div>
      <div className={styles.subtext}>
        <div>Contact support at</div>
        <div className={styles.contact}>
          <a
            href="mailto:support@rainmakers.co"
            className={styles.mailLink}
            rel="noopener noreferrer"
            target="_blank"
          >
            support@rainmakers.co
          </a>
        </div>
      </div>
    </ReadyContainer>
  </React.Fragment>
);

export default NotFound;
