import { createStructuredSelector } from 'reselect';

import {
  entitySelectorFactory,
  loadingSelectorFactory,
  pageSelectorFactory,
  totalSelectorFactory,
  savingSelectorFactory,
} from 'reduxStore/selectorFactories';

import {
  selectIsLoggedIn,
  selectIsAdmin,
  selectLogoutPending,
} from 'connectors/Login/redux/selectors';

import { selectUser } from 'connectors/User/redux/selectors';

import { getModal } from 'shared/store/app/selectors';

import { selectPickerOptions } from 'connectors/Defaults/redux/selectors';

import selectRequisitionOpening from './selectRequisitionOpening';
import selectRequisitionOpeningErrors from './selectRequisitionOpeningErrors';

const selector = createStructuredSelector({
  requisitionCandidates: entitySelectorFactory('toolboxRequisitions', 'requisitionCandidate'),
  requisitionCandidatesPage: pageSelectorFactory('toolboxRequisitions', 'requisitionCandidate'),
  requisitionCandidatesTotal: totalSelectorFactory('toolboxRequisitions', 'requisitionCandidate'),
  requisitionCandidatesLoading: loadingSelectorFactory(
    'toolboxRequisitions',
    'requisitionCandidate'
  ),
  candidates: entitySelectorFactory('toolboxRequisitions', 'candidate'),
  cohorts: entitySelectorFactory('toolboxRequisitions', 'cohort'),
  employers: entitySelectorFactory('toolboxRequisitions', 'employer'),
  isAdmin: selectIsAdmin,
  isLoading: loadingSelectorFactory('toolboxRequisitions', 'requisitionOpening'),
  isLoggedIn: selectIsLoggedIn,
  logoutPending: selectLogoutPending,
  modal: getModal,
  query: entitySelectorFactory('toolboxRequisitions', 'query'),
  page: pageSelectorFactory('toolboxRequisitions', 'requisitionOpening'),
  pickerOptions: selectPickerOptions,
  rainmakersAdmins: entitySelectorFactory('toolboxRequisitions', 'rainmakersAdmin'),
  requisitionOpening: selectRequisitionOpening,
  requisitionOpeningErrors: selectRequisitionOpeningErrors,
  requisitionOpenings: entitySelectorFactory('toolboxRequisitions', 'requisitionOpening'),
  requisitionRequirements: entitySelectorFactory('toolboxRequisitions', 'requisitionRequirement'),
  saving: savingSelectorFactory('toolboxRequisitions'),
  suggestions: entitySelectorFactory('toolboxRequisitions', 'suggestion'),
  suggestionsPage: pageSelectorFactory('toolboxRequisitions', 'suggestion'),
  suggestionsTotal: totalSelectorFactory('toolboxRequisitions', 'suggestion'),
  suggestionsLoading: loadingSelectorFactory('toolboxRequisitions', 'suggestion'),
  suggestionSets: entitySelectorFactory('toolboxRequisitions', 'suggestionSet'),
  suggestionSetsPage: pageSelectorFactory('toolboxRequisitions', 'suggestionSet'),
  suggestionSetsTotal: totalSelectorFactory('toolboxRequisitions', 'suggestionSet'),
  suggestionSetsLoading: loadingSelectorFactory('toolboxRequisitions', 'suggestionSet'),
  total: totalSelectorFactory('toolboxRequisitions', 'requisitionOpening'),
  user: selectUser,
});

export { selector as default };
