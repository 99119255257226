/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import { getAltName } from 'utils/urlUtils';

import pic1 from './assets/1-denver-sales-recruiters.png';
import pic2 from './assets/2-denver-sales-recruiting-agency.png';
import pic3 from './assets/3-best-denver-sales-recruiters.png';
import pic4 from './assets/4-denver-sales-recruiting-process.png';
import pic5 from './assets/5-connect-denver-sales-recruiter.png';

import styles from './DenverContent.scss';

export function DenverContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt={getAltName(pic1)} />
      <h2>Our Premier Sales Recruitment Services</h2>
      <p>
        An elite sales team is crucial for a business's success, but finding and recruiting
        candidates can be challenging and time-consuming. Unfortunately, many small and medium-sized
        companies often don’t have the time, resources, and know-how to bring top talent to their
        team.
      </p>
      <p>
        Thankfully, a knowledgeable sales recruitment agency can help. If you want to recruit sales
        professionals in the Denver area, read on to see how sales recruitment agencies can help you
        build your dream sales team.
      </p>
      <h2>What's Denver Job Market Like?</h2>
      <p>
        There has never been a better time to work in Denver. The local job economy has thrived
        amidst younger workers moving to the area, and the Denver area has steadily added jobs since
        2010.
      </p>
      <p>
        The tech industry, in particular, has exploded in Denver recently, attracting some of the
        most influential technology companies and startups to the Denver business scene. As a
        result, over 21,000 people are employed in the tech industry in Denver at 130 companies.
      </p>
      <p>
        Other notable industries in the area are aerospace and aviation, energy, financial services,
        and healthcare and biosciences.
      </p>
      <Link to="/employer-signup/" title="Apply Now">
        <img src={pic2} alt={getAltName(pic2)} />
      </Link>
      <h2>What Is a Sales Recruiting Agency?</h2>
      <p>
        Sales recruiting agencies specialize in finding the best candidates for sales positions.
        They are experts at recruiting top-quality sales candidates, both those seeking roles and
        those currently employed with other companies. As a result, they often have a pool of
        prequalified people ready to join your sales team, saving you from finding, vetting,
        interviewing, and hiring new sales team members.
      </p>
      <p>
        The main goal of a sales recruiting firm is locating the best candidates for your business’s
        needs. Sales recruitment agencies have better access to sales talent, will help you hire
        better sales candidates, and will save you time and money recruiting your sales team.
      </p>
      <h2>What Types of Companies Work with Sales Recruiters?</h2>
      <p>
        Companies in most industries work with sales recruiters, especially those in the
        fastest-growing sectors like technology, finance, retail, healthcare, and media.
      </p>
      <p>
        Given the recent boom in tech in Denver, there is a growing demand at sales recruiting firms
        to find the best tech sales talent for businesses in the Denver area. The competition in the
        technology industry is fierce, so tech companies need to have the best sales team available
        and get that team working as fast as possible.
      </p>
      <img src={pic3} alt={getAltName(pic3)} />
      <h2>How Does the Recruiting Process Work in Denver?</h2>
      <p>
        While the same services a sales recruitment agency offers will vary from one firm to the
        next, here’s the process you can expect most of the time when working with sales recruiting
        firms in Denver:
      </p>
      <ol>
        <li>
          <strong>Agreement:</strong> The first step is agreeing on what services will be provided
          and how much it will cost. You’ll also set expectations for the work, such as a guarantee
          period for your agency to find you successful sales team members.
        </li>
        <li>
          <strong>Building Your Profile:</strong> After you’ve reached an agreement, you’ll meet
          with your sales recruitment firm to discuss your specific needs. You must provide all the
          information the recruitment firm needs to find you the best candidates in Denver. This
          should include the job description, position requirements, desired skills, and other
          criteria.
        </li>
        <li>
          <strong>Sourcing:</strong> Here’s where you get to focus on other parts of your business
          for a while. The sales recruiters you’ve hired will probe their databases and networks for
          sales candidates that meet your criteria.
        </li>
        <li>
          <strong>Interviewing:</strong> Generally speaking, this is a two-step process. Most sales
          recruitment agencies will create a pool of candidates by interviewing them to ensure they
          are the right fit for your business. They will then discuss each candidate with you and
          help you arrange their final interviews.
        </li>
        <li>
          <strong>Offer Extension and Negotiation:</strong> Sales recruiters can assist you with the
          offer and negotiation process after you’ve decided on the candidate(s) you will hire.
        </li>
        <li>
          <strong>Onboarding:</strong> After a candidate accepts an offer, sales recruitment
          agencies can help with your company’s onboarding process.
        </li>
      </ol>
      <img src={pic4} alt={getAltName(pic4)} />
      <h2>How Does a Recruiting Agency Help the Hiring Process?</h2>
      <p>There are several ways sales recruitment agencies are helpful in the hiring process:</p>
      <ul>
        <li>
          <strong>Faster Hiring Process:</strong> When you work with a sales recruitment agency,
          filling your open positions will be much shorter. This is simply because finding great
          candidates for jobs is what sales recruiters do!
        </li>
        <li>
          <strong>Advanced Industry Knowledge:</strong> Effective sales recruitment agencies have
          advanced industry-specific knowledge that makes them experts at understanding your
          business needs, what the job market demands, and what skills great candidates need to
          fulfill your open positions.
        </li>
        <li>
          <strong>Access to a Large Talent Pool and Network:</strong> There’s no better way to get
          the highest-quality candidates interested in working for you than with sales recruiting
          firms. They not only have a large talent pool of candidates they’ve already pre-screened
          for open positions, but they also have a vast network they tap into to find candidates who
          will meet their client’s needs, some of which may not be actively looking for a job.
        </li>
      </ul>
      <h2>What Challenges Do Hiring Managers Face in Denver?</h2>
      <p>
        In a thriving jobs economy like Denver is experiencing, hiring managers may face challenges
        recruiting and retaining their sales teams. Various economic and demographic trends factor
        into this, including a boom in job growth and younger workers moving jobs more frequently.
      </p>
      <p>
        Another part of the issue is over job dissatisfaction, with sales reps feeling overworked,
        undervalued, and having less desire for traditional employment.
      </p>
      <p>
        Many of the issues hiring managers in Denver face can be solved by improving their
        recruitment process. First, businesses must attract the right candidates for the right
        positions at the right time. Then, they must offer them a supportive work environment. When
        your sales team are the highest-quality workers who feel valued and supported with
        exceptional talent management, you’ll retain more sales employees and recruit better
        candidates.
      </p>
      <Link to="/employer-signup/" title="Apply Now">
        <img src={pic5} alt={getAltName(pic5)} />
      </Link>
      <h2>How Does a Sales Recruiting Agency Find Candidates?</h2>
      <p>
        Choosing the talent with the right skills and fit for your company culture is critical to
        increasing sales and improving your bottom line.
      </p>
      <p>
        At Rainmakers, go beyond resumes and LinkedIn profiles. We only accept the highest quality
        candidates, so you’ll be confident you’re interviewing the best sales talent. In addition,
        because we foster relationships with hundreds of individuals every month, Rainmakers
        develops a pool of great candidates just waiting for the right role.
      </p>
      <p>
        Our sales recruiters can help you find talent that matches the role you are hiring for.{' '}
        <a href="https://www.rainmakers.co/employer-signup/">Sign-up now</a>, for free, to get
        started!
      </p>
    </div>
  );
}
