import React, { Component } from 'react';
import classnames from 'classnames';

import FontIcon from 'components/FontIcon';
import FormattedMoney from 'components/FormattedMoney';

import styles from '../../Dashboard.scss';

class RequisitionDetailOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }

  onClick = (e) => {
    e.stopPropagation();
    this.setState((prevState) => ({ active: !prevState.active }));
  };

  onReqChange = (value) => {
    const { currentValue, handleSearchInputChange } = this.props;

    if (currentValue !== value) {
      handleSearchInputChange({
        target: {
          value,
          name: 'requisitionOpeningId',
        },
      });
    }
  };

  render() {
    const { active } = this.state;

    const { requisitionOpening, user } = this.props;

    const reqDetailClickStyles = classnames(styles.reqDetailClick, {
      [styles.reqDetailClickActive]: active,
    });

    const {
      attributes: {
        externalName,
        externalReqIds = [],
        headcount,
        hiringManager,
        id: reqId,
        abbreviatedLocations = [],
        oteMinCents,
        oteMaxCents,
      } = {},
      relationships: { creator: { data: creatorIds = [], type: creatorTypes = [] } = {} } = {},
    } = requisitionOpening;

    const { [`${creatorTypes[0]}s`]: { byId: creatorsById = {} } = {} } = this.props;

    const {
      [creatorIds[0]]: {
        attributes: { firstName: creatorFirstName, lastName: creatorLastName = '' } = {},
      } = {},
    } = creatorsById || {};

    const locationsLength = abbreviatedLocations ? abbreviatedLocations.length : null;

    const multiLocationsContent = locationsLength > 1 ? abbreviatedLocations.join(', ') : null;

    const singleLocationsContent = locationsLength === 1 ? abbreviatedLocations[0] : null;
    const locationsContent = multiLocationsContent || singleLocationsContent || null;
    const locationsLabel = locationsLength > 1 ? 'Locations:' : 'Location:';
    const locationsFinal = locationsContent ? (
      <div className={styles.reqLineItem}>
        <span className={styles.reqDetailBold}>{locationsLabel}</span>
        {locationsContent}
      </div>
    ) : null;

    const reqIdsLength = externalReqIds ? externalReqIds.length : null;
    const multiReqIdsContent = reqIdsLength > 1 ? externalReqIds.join(', ') : null;
    const singleReqIdsContent = reqIdsLength === 1 ? externalReqIds[0] : null;
    const reqIdsContent = multiReqIdsContent || singleReqIdsContent || null;
    const reqIdsLabel = reqIdsLength > 1 ? 'Req IDs:' : 'Req ID:';
    const reqIdsFinal = reqIdsContent ? (
      <div className={styles.reqLineItem}>
        <span className={styles.reqDetailBold}>{reqIdsLabel}</span>
        {reqIdsContent}
      </div>
    ) : null;

    const reqCreator = creatorFirstName ? `${creatorFirstName} ${creatorLastName}` : null;

    const reqOwner = hiringManager || reqCreator || null;

    const ownerContent = user.currentProfile.id === creatorIds[0] ? 'You' : reqOwner;

    const owner = ownerContent ? (
      <div className={styles.reqLineItem}>
        <span className={styles.reqDetailBold}>Req Owner:</span>
        {ownerContent}
      </div>
    ) : null;

    const oteMaxContent = oteMaxCents ? <FormattedMoney amount={oteMaxCents} /> : null;

    const oteMinContent =
      oteMinCents !== undefined ? (
        <span>
          <FormattedMoney amount={oteMinCents} />
          {oteMaxContent ? ' - ' : null}
        </span>
      ) : null;

    const oteRangeCell =
      oteMinCents !== undefined ? (
        <div className={styles.reqLineItem}>
          <span className={styles.reqDetailBold}>OTE Range:</span>
          <span>
            {oteMinContent}
            {oteMaxContent}
          </span>
        </div>
      ) : null;

    const detailsContent = (
      <div className={styles.reqDetails}>
        <div className={styles.reqLineItem}>
          <span className={styles.reqDetailBold}>Headcount:</span>
          {headcount}
        </div>
        {reqIdsFinal}
        {locationsFinal}
        {oteRangeCell}
        {owner}
      </div>
    );

    const iconContent = active ? 'caret-up' : 'caret-down';

    const detailContent = active ? detailsContent : null;

    const nameClickProps = {
      className: styles.reqDetailMainClick,
      onClick: () => this.onReqChange(reqId),
    };

    const detailClickProps = {
      className: reqDetailClickStyles,
      onClick: this.onClick,
    };

    const labelContent = (
      <div className={styles.reqLabel}>
        <span {...nameClickProps}>{externalName}</span>
        <span {...detailClickProps}>
          <span className={styles.buttonContent}>Details</span>
          <FontIcon iconName={iconContent} />
        </span>
        {detailContent}
      </div>
    );

    return labelContent;
  }
}

export default RequisitionDetailOption;
