import { createSelector } from 'reselect';
import { getApp } from 'shared/store/app/selectors';

const selectSearchEmployerId = createSelector(getApp, (state) => {
  if (state.employerSearch && state.employerSearch.employer) {
    return state.employerSearch.employer.id;
  }

  return '';
});

export default selectSearchEmployerId;
