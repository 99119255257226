const lifecycleMap = {
  inbound: 'Inbound',
  enrichment_required: 'Enrichment Required',
  enrichment_complete: 'Enrichment Complete',
  enrichment_error: 'Enrichment Error',
  ready_to_host: 'Ready to Host',
};

export const getLifecycleStatusTitle = (lifecycleStatus) => lifecycleMap[lifecycleStatus];

export const candidateLifecycleOptions = Object.keys(lifecycleMap).map((value) => ({
  value,
  label: getLifecycleStatusTitle(value),
}));
