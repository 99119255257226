import { getEntity } from 'api/apiEntity';

const promiseGetRequisitionCandidates = ({ state = ['active'], candidateId, employerId }) => {
  const params = {
    candidateId,
    state,
  };

  if (employerId) {
    params.employerId = employerId;
  }

  const onFulfilled = (response) => {
    const {
      data: { requisition_candidates: reqCandidates = [] },
    } = response;

    return reqCandidates;
  };

  const args = {
    type: 'requisition_candidates',
    config: {
      params,
    },
  };

  return getEntity(args).then(onFulfilled);
};

export default promiseGetRequisitionCandidates;
