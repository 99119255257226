/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import { VerticalDelimiter } from 'components/VerticalDelimiter';
import marketsJobsPageMetaData, {
  MK_JB_SDR_CHI_PAGE,
  MK_JB_SDR_NYC_PAGE,
  MK_JB_SDR_SF_PAGE,
} from 'pages/MarketsJobsPage/marketsJobsPageMetaData';

import pic0 from './assets/1-sales-development-rep-job-information.png';
import pic1 from './assets/2-contact-rainmakers-for-sales-development-rep-jobs.png';
import pic2 from './assets/3-sales-development-representative-salary.png';
import pic3 from './assets/4-sdr-job-details.png';
import pic4 from './assets/5-sales-development-rep-jobs.png';
import pic5 from './assets/6-create-profile-browse-sdr-jobs.png';

import styles from './SDRContent.scss';

export function SDRContent() {
  return (
    <div className={styles.container}>
      <img src={pic0} alt="" />
      <h2>Sales Development Representative (SDR) Jobs</h2>

      <p>
        The last couple of decades have seen a significant evolution in sales development. Formerly,
        sales representatives began their careers cold calling and answering phones in the hopes of
        landing potential customers.
      </p>
      <p>
        These days, sales development representatives (SDRs) take an even more active role by
        qualifying leads, prospecting, and conducting sales outreach. Their primary job is to make
        as many contacts with potential clients as possible to determine whether to advance them in
        the sales funnel promptly.
      </p>
      <p>
        While cold calling and making direct contact with prospects using lists supplied by the
        marketing department is still a part of an SDR's responsibilities, that task is not only
        done via phone but also through direct messaging on platforms such as LinkedIn, Twitter, and
        Instagram. In addition, an SDR must also perform customer research and gather critical sales
        data. The most successful SDRs build sales opportunities, educate prospects about solutions,
        and facilitate closing deals.
      </p>

      <h2>What Are Sales Development Representatives' Duties?</h2>

      <p>
        The primary purpose of sales development representatives is to increase the company's
        customer base. As an entry-level position, there's usually no need for a college degree, but
        it's a good idea to have some sales experience in your background.
      </p>
      <p>A typical list of an SDR's responsibilities includes:</p>
      <ol>
        <li>
          Make cold calls, send emails, engage in social outreach, network, and conduct independent
          research to schedule outbound meetings.
        </li>
        <li>Engage potential customers and communicate the benefits of products or services.</li>
        <li>Assess prospects' pain points through sales discovery.</li>
        <li>Provide prospects with relevant marketing content.</li>
        <li>Follow up with existing clients to stay current with their needs.</li>
        <li>Manage sales pipelines using customer relationship management (CRM) systems.</li>
        <li>Work with sales representatives to support and close sales.</li>
      </ol>

      <Link to="/apply-now" title="Apply Now">
        <img src={pic1} alt="" />
      </Link>

      <h2>Successful Qualities For SDRs</h2>
      <p>
        You need to study prospects, analyze data, and stay up to date on everything in your
        business to succeed in an SDR role and hit targets.
      </p>

      <p>
        This doesn't happen overnight. It requires time. You'll get rejected a lot, but don't let
        that deter you. You'll experience highs and lows each day. The trick is to remain
        optimistic, determined, and laser-focused.
      </p>

      <p>
        You also need to have a strong understanding of your product. At the same time, it's
        essential to remember that, as a sales development representative, you're not just selling a
        product. You're selling a solution and your expertise.
      </p>

      <h2>Advancement In Sales Development</h2>

      <p>
        Many enthusiastic SDRs who join the profession soon advance to management and account
        executive positions. That said, the career path is not always easy.
      </p>

      <p>
        To advance to an account executive position, you must reliably demonstrate your ability to
        perform your duties without repeating the same mistakes. From there, you may find yourself
        acting as an SDR stand-in. This is when you'd fill in for an account executive and take on
        their responsibilities. Sometimes this is just for a single call or two. Sometimes it's for
        an entire day. This allows your supervisors to evaluate your sales potential and exposes you
        to that portion of the sales process.
      </p>

      <p>
        Another way to advance quicker is involve yourself in as much of the sales process as
        possible. Senior SDRs often join in on discovery and demo calls which is a great way to
        prepare them for what’s to come in an Account Executive position.
      </p>

      <p>
        If you perform well, you could possibly see yourself advancing to a new position with
        greater responsibilities and opportunities. On the other hand, if there are any issues with
        your performance, use this as a learning experience to perform better in the future.
      </p>

      <img src={pic2} alt="" />

      <h2>What Are Some Positions An SDR Can Advance To?</h2>

      <p>
        Here is a list of some of the possible ways you can step up your career in sales following a
        successful stint as an SDR:
      </p>

      <ul>
        <li>
          <strong>Account Executive</strong> – Responsible for closing sales, supporting existing
          clients, developing sales strategies, and communicating product value to clients.
        </li>
        <li>
          <strong>Customer Success Manager</strong> - Guides new clients in finding solutions and
          meeting goals and works to retain existing customers
        </li>
        <li>
          <strong>SDR Management</strong> - Responsible for managing junior SDRs and training SDRs
          just starting.
        </li>
      </ul>

      <h2>Locating SDR opportunities</h2>

      <p>
        SDR positions exist across the country with exceptionally high demands in cities such as San
        Francisco, Los Angeles, Phoenix, Austin, Denver, Chicago, NYC, and Raleigh.
      </p>

      <p>
        Many businesses have work-from-home and hybrid office options, providing employees with
        increased flexibility and autonomy.
      </p>

      <h2>How Much Do Sales Development Reps Earn?</h2>

      <p>It can vary.</p>

      <p>
        The salary of a sales development professional depends mainly on how well you accomplish
        your goals. Some businesses choose to provide a base salary with occasional performance
        bonuses.
      </p>

      <p>
        Comparably.com, a website that tracks industries, salaries, and brands, lists the average US
        sales development representative's compensation at around $74,000, including bonuses and
        commissions.
      </p>

      <p>
        By averaging the reports from the job tracking sites Indeed, Builtin, and Payscale, we find
        an average of $55,200 as a yearly base salary with bonuses and commissions averaging
        approximately $17,500. This adds up to $72,700 yearly.
      </p>

      <p>Not bad for an entry-level position.</p>

      <p>
        These figures, of course, are not guarantees but more a reflection of averages across
        numerous businesses that employ SDRs. New employees with little to no work experience will
        understandably earn less than more senior SDRs who can negotiate better base salaries.
      </p>

      <p>
        The takeaway is that an SDR who starts strong and stays strong has excellent earning
        potential.
      </p>

      <img src={pic3} alt="" />

      <h2>Succeeding in Sales Development</h2>

      <p>
        It goes without saying that in outbound sales, SDRs experience a lot of rejection. Don't
        take it personally. SDRs need to have great mental fortitude to succeed in sales and deal
        with the frustrations of not being able to connect with people they need to.
      </p>

      <p>
        The good news is that if you have the right skills, being a sales development rep can be
        very rewarding, watching your prospected leads turn into closed-won opportunities.
      </p>

      <p>Here are some of the top skills to work on.</p>

      <h2>Research</h2>

      <p>
        Do your research and—at the very least—confirm the prospect's name, job title, industry, and
        location. Verify the accuracy of the B2B marketing data you've been given. Demonstrating
        that you know who you're talking to and why will help you make a more favorable impression.
      </p>

      <p>
        Look up information on various buyers, industry trends, and competition. Use this
        information to help isolate sales triggers to tailor your pitch and provide a more helpful
        sales experience.
      </p>

      <p>
        Say a company has recently increased its workforce. Perhaps in doing so, they're dealing
        with some growing pains. <i>How</i> can your company help? <i>Who</i> is the best person to
        talk to? <i>Why</i> should they go with your solution?
      </p>

      <p>Answer these questions, and you're off to an encouraging start.</p>

      <h2>Active Listening</h2>

      <p>
        One of the most crucial SDR abilities is active listening since it enables you to interact
        with prospects on a personal level and allows you to establish yourself as a consultant.
      </p>

      <p>
        In addition, you can pin a voice to the data profile you developed during your per-call
        investigation and don't need to rely on cold calling scripts.
      </p>

      <p>
        Finally, active listening assists SDRs in assessing a prospect's suitability and lets you
        engage them more effectively. This can save you time and reveal when a candidate may be a
        poor fit before you get too deep into the sales funnel.
      </p>

      <img src={pic4} alt="" />

      <h2>Being Direct</h2>

      <p>
        You should keep in mind that, as an SDR, you are likely disrupting someone's workday.
        Getting right to the point will make you appear confident—a quality your clients will find
        value in.
      </p>

      <h2>Creativity</h2>

      <p>
        You'll gain more traction the more inventive your outreach is. Due to the intense
        competition in sales, SDRs must constantly develop strategies to differentiate their
        message.
      </p>

      <p>
        Immerse yourself in activities that will advance your knowledge, abilities, and creativity.
        Whatever you do, you must be able to inject some imagination and personality into it and
        distinguish yourself from the deluge of other messages that your prospects are receiving.
      </p>

      <p>
        Consider trying alternative message formats. For example, video prospecting, LinkedIn voice
        notes, and even TikTok-style reels can inject some needed creativity into your call process.
      </p>

      <h2>Curiosity</h2>

      <p>
        Sales development representatives need to be inquisitive in addition to having tangible SDR
        skills. Reps that come across as genuinely invested in their prospects' problems deliver
        themessage that they want to do more than just seal a deal. Additionally, they benefit by
        learning more about the market and the product, which can facilitate future sales.
      </p>

      <Link to="/apply-now" title="Apply Now">
        <img src={pic5} alt="" />
      </Link>

      <h2>The Start of a Great Career</h2>

      <p>
        Sales development is an essential step in the sales process and a terrific path for entering
        the field. While being an SDR on a sales team can be challenging, it's a crucial role that
        can also be quite rewarding.
      </p>

      <p>
        You are the first point of contact and the one who qualifies leads for sales representatives
        farther down the funnel. Your job paves the way for the success of the company's sales team.
      </p>

      <p>
        By cultivating the right skills and putting in the hard work required, you can stand out
        from the crowd and build a strong foundation for a lucrative future sales career.
      </p>

      <VerticalDelimiter />
      <p>
        Learn more about existing career opportunities and the day in the life of a Sales
        Development Representative in the tech industry’s most thriving hubs!
      </p>
      <ul>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={marketsJobsPageMetaData[MK_JB_SDR_NYC_PAGE].localUrl}
          >
            {' '}
            SDR Jobs in New York City
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={marketsJobsPageMetaData[MK_JB_SDR_SF_PAGE].localUrl}
          >
            {' '}
            SDR Jobs in San Francisco
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={marketsJobsPageMetaData[MK_JB_SDR_CHI_PAGE].localUrl}
          >
            {' '}
            SDR Jobs in Chicago
          </a>
        </li>
      </ul>

      <p>
        Whether you are an employer looking to{' '}
        <a href={`https://${process.env.HOST}/employers`}>hire a new SDR</a> to join your growing
        team or looking for{' '}
        <a href={`https://${process.env.HOST}/apply-now/`}>
          Sales Development Representative jobs near you
        </a>
        , Rainmakers is here to help! Contact us to get started.
      </p>
    </div>
  );
}
