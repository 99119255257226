import { call, put, select, takeLatest } from 'redux-saga/effects';

import { delay } from 'redux-saga';

import { handleError } from 'utils/common';

import Validator from 'connectors/Validator';

import { patchEntity } from 'api/apiEntity';

import { selectUser } from 'connectors/User/redux/selectors';

import sagaValidateInput from 'connectors/Validator/redux/sagas/sagaValidateInput';

import { selectEmployerErrors } from '../selectors';

import { EMPLOYER_PATCH_STARTED } from '../actions';

import {
  employerErrorsSet,
  employerUpdate,
  employeeSaveStarted,
  employeeSaveDone,
  employeeSaveError,
} from '../creators';

const patchEmployer = function* patchEmployer(action) {
  const {
    payload: { newValue, name },
  } = action;

  if (name === 'size') {
    yield put(employerUpdate({ newValue: newValue.split(',')[1], name: 'sizeMax' }));
    yield put(employerUpdate({ newValue: newValue.split(',')[0], name: 'sizeMin' }));
  } else {
    yield put(employerUpdate({ newValue, name }));
  }

  if (name === 'greenhouseUserEmail' || name === 'greenhouseApiKey') {
    yield put(employerUpdate({ name: `${name}IsValid`, newValue: false }));
  }

  yield put(employeeSaveStarted());

  const errors = yield select(selectEmployerErrors);

  const { isValid, inputErrors } = yield call(sagaValidateInput, { newValue, name, errors });

  yield put(employerErrorsSet({ inputErrors }));

  yield call(delay, 750);

  if (isValid) {
    const user = yield select(selectUser);

    const { currentProfile: { employer: { id: employerId } = {} } = {} } = user;

    let attributes = {};

    if (name === 'size') {
      attributes = {
        sizeMax: newValue.split(',')[1],
        sizeMin: newValue.split(',')[0],
      };
    } else if (name === 'links') {
      attributes = {
        links: newValue.filter((val) => val !== '' && Validator.isUrl({ newValue: val })),
      };
    } else if (name === 'email') {
      attributes = {
        [name]: newValue.trim(),
      };
    } else {
      attributes = {
        [name]: newValue,
      };
    }

    if (Array.isArray(newValue) && newValue.every((el) => el === '')) {
      attributes = { [name]: [] };
    }

    if (employerId) {
      try {
        const args = {
          type: 'employers',
          id: employerId,
          data: {
            id: employerId,
            type: 'employers',
            attributes,
          },
          config: {
            params: {
              camelizeIt: true,
            },
          },
        };

        const {
          data: { employer },
        } = yield call(patchEntity, args);

        if ((name === 'greenhouseUserEmail' || name === 'greenhouseApiKey') && newValue !== null) {
          yield put(employerUpdate({ name: [`${name}IsValid`], newValue: true }));
        }

        if (name === 'searchRequested') {
          yield put(employerUpdate({ name, newValue }));
        }

        if (name === 'links') {
          const restoreIndices = newValue.map((val, idx) =>
            val === '' || !Validator.isUrl({ newValue: val }) ? idx : null
          );

          const { links: newLinks } = employer;

          restoreIndices.forEach((idx) => {
            if (idx !== null) {
              newLinks.splice(idx, 0, newValue[idx]);
            }
          });
          yield put(employerUpdate({ [name]: newLinks }));
        }
        yield put(employeeSaveDone());
      } catch (error) {
        handleError(error);

        yield put(employeeSaveError(error.response));
      }
    }
  }
};

const sagaEmployerPatchStarted = function* sagaEmployerPatchStarted() {
  yield takeLatest(EMPLOYER_PATCH_STARTED, patchEmployer);
};

export default sagaEmployerPatchStarted;
