import React, { useState, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { GO_TO_HOME_STATUSES } from 'connectors/Login/constants';

import User from 'connectors/User';
import Navigation from 'components/Navigation';
import { useOnClickOutside } from 'hooks';

import HeaderLogo from '../HeaderLogo';
import FontIcon from '../FontIcon';

import styles from './CandidateHeader.scss';

const HEADER_SHADOW_ON_SCROLL_PATHS = [
  '/apply-now',
  '/community-apply',
  '/set-requirements',
  '/create-profile',
  '/community-profile',
  '/add-sales-info',
  '/add-video',
];

const CANDIDATE_LINKS = [
  {
    to: `https://${process.env.FORUM_LINK}`,
    title: 'Community',
    target: '_self',
    activePaths: [],
    hide: !process.env.FORUM_LINK,
  },
  {
    to: '/candidate/home',
    title: 'Home',
    activePaths: ['home'],
  },
  {
    to: '/candidate/opportunities/active',
    title: 'Opportunities',
    activePaths: ['opportunities'],
  },
  {
    to: '/candidate/edit-preview',
    title: 'Profile',
    activePaths: [
      'edit-personal',
      'edit-work',
      'edit-education',
      'edit-sales',
      'edit-recruiting',
      'edit-preview',
      'intro-video',
    ],
  },
  {
    to: '/candidate/privacy-settings',
    title: 'Settings',
    activePaths: ['privacy-settings', 'email-settings', 'referrals'],
  },
];

const CandidateHeader = ({ isApproved, location, onboardingStatus, canvasScroll }) => {
  const [showNav, setShowNav] = useState(false);
  const ref = useRef();

  useOnClickOutside(
    ref,
    useCallback(() => setShowNav(false), [])
  );

  const { pathname } = location;

  const makeLinks = (link) => {
    const { title, to, button = false, activePaths, width, target } = link;

    const isActive = activePaths && activePaths.includes(location.pathname.split('/')[2]);

    const linkClasses = classnames(styles.navLink, {
      [styles.activeNavLink]: isActive,
      [styles.button]: button,
    });

    const linkProps = {
      target,
      href: to,
      to,
      key: title,
      className: linkClasses,
      style: { width },
      ['data-title']: title,
    };

    return target ? <a {...linkProps}>{title}</a> : <Link {...linkProps}>{title}</Link>;
  };

  const linksToUse = CANDIDATE_LINKS.filter((link) => !link.hide);

  const links = linksToUse.map(makeLinks);

  const hasNavAccess = GO_TO_HOME_STATUSES.includes(onboardingStatus) || isApproved;

  const nav = hasNavAccess ? <Navigation className={styles.NavBlock}>{links}</Navigation> : null;

  const navDropdownProps = {
    className: styles.NavDropdown,
    onClick: () => setShowNav(false),
  };

  const navDropdown = showNav ? <div {...navDropdownProps}>{links}</div> : null;

  const hamburgerProps = {
    onClick: () => setShowNav((showNav) => !showNav),
    className: classnames(styles.NavHamburger, {
      [styles.seafoam]: showNav,
    }),
  };

  const navHamburger = hasNavAccess ? (
    <div {...hamburgerProps}>
      <FontIcon iconName="menu" />
    </div>
  ) : null;

  const logoLink = '/candidate/home';

  const useScroll =
    HEADER_SHADOW_ON_SCROLL_PATHS.includes(pathname) ||
    HEADER_SHADOW_ON_SCROLL_PATHS.includes(pathname.substr(0, pathname.length - 1));

  const headerContainerClasses = classnames(styles.HeaderContainer, styles.HeaderTransparent, {
    [styles.headerBoxShadow]: useScroll ? canvasScroll !== 0 : true,
  });

  const headerClasses = classnames(styles.Header);

  const userWrapperProps = {
    onClick: () => setShowNav(false),
  };

  const logoLinkContent = hasNavAccess ? (
    <Link to={logoLink}>
      <HeaderLogo />
    </Link>
  ) : (
    <HeaderLogo />
  );

  return (
    <header className={headerContainerClasses} ref={ref}>
      <div className={headerClasses}>
        <div className={styles.LogoBlock}>{logoLinkContent}</div>

        <div className={styles.navLinksContainer}>
          {navHamburger}
          {navDropdown}
          {nav}
          <div {...userWrapperProps}>
            <User />
          </div>
        </div>
      </div>
    </header>
  );
};

export default CandidateHeader;
