import React, { useCallback, useEffect, useRef, useState } from 'react';

import Button from 'components/Button';

import patchCandidateRefreshTriggers from 'api/apiCandidates/patchCandidateRefreshTriggers';

import { handleError } from 'utils/common';

import styles from './RefreshProfileButton.scss';

const labelBase = 'Refresh Profile';
const labelRefreshing = 'Refreshing...';
const labelDone = 'Done!';

const timeoutRefreshing = 7000;
const timeoutDone = 1000;

export const RefreshProfileButton = ({ publicProfileId }) => {
  const [label, setLabel] = useState(labelBase);
  const _mounted = useRef();

  useEffect(() => {
    _mounted.current = true;

    return () => {
      _mounted.current = false;
    };
  }, []);

  const onClick = useCallback(() => {
    if (!publicProfileId) return;

    setLabel(labelRefreshing);

    patchCandidateRefreshTriggers(publicProfileId)
      .then(() => {
        setTimeout(() => {
          if (_mounted.current) {
            setLabel(labelDone);

            setTimeout(() => {
              if (_mounted.current) {
                setLabel(labelBase);
              }
            }, timeoutDone);
          }
        }, timeoutRefreshing);
      })
      .catch(handleError);
  }, [publicProfileId]);

  return (
    <div className={styles.refreshProfileContainer}>
      <Button primary onClick={onClick}>
        {label}
      </Button>
    </div>
  );
};
