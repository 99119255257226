import React from 'react';

import styles from './BlogRoll.scss';

const BlogRoll = () => (
  <div id="Blog-roll" className={styles.BlogRoll}>
    <h3 className={styles.Title}>Check out our blog</h3>

    <div className={styles.Container}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://${process.env.HOST}/blog/how-to-get-tech-sales-job/`}
      >
        <div className={styles.Column}>
          <div className={styles.Image}>
            <img src={require('../../assets/blog1.jpg')} alt="how-to-get-tech-sales-job" />
          </div>
          <div className={styles.BlogTitle}>How to Get a Tech Sales Job</div>
        </div>
      </a>

      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://${process.env.HOST}/blog/sales-roles/`}
      >
        <div className={styles.Column}>
          <div className={styles.Image}>
            <img src={require('../../assets/blog2.jpg')} alt="sales-roles" />
          </div>
          <div className={styles.BlogTitle}>8 Primary Sales Roles (and Salaries)</div>
        </div>
      </a>

      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://${process.env.HOST}/blog/tech-sales-job-description/`}
      >
        <div className={styles.Column}>
          <div className={styles.Image}>
            <img src={require('../../assets/blog3.jpg')} alt="tech-sales-job-description" />
          </div>
          <div className={styles.BlogTitle}>What Tech Sales Job Descriptions Really Tell You</div>
        </div>
      </a>
    </div>
    <a target="_blank" rel="noopener noreferrer" href={`https://${process.env.HOST}/blog/`}>
      <div className={styles.SeeMore}>See more</div>
    </a>
  </div>
);

export default BlogRoll;
