import React from 'react';
import AutocompleteServer from 'connectors/AutocompleteServer';
import Tag from 'components/Tag';

import styles from './AutocompleteValueRow.scss';

function AutocompleteValueRow({
  placeholder,
  value,
  size,
  name,
  required,
  characterLimit,
  autocompleteType,
  field,
  needReset,
  hasAddButton,
  label,
  handleInputChange,
  handleOnBlur,
  autoFocus,
  highlight,
  selected,
  onDeleteClick,
  ...rest
}) {
  const tags = selected
    ? selected.map((comp) => (
        <Tag key={comp} name={comp} inputName={name} onDeleteClick={onDeleteClick} />
      ))
    : null;

  return (
    <div {...rest}>
      <AutocompleteServer
        placeholder={placeholder}
        value={value}
        size={size}
        name={name}
        required={required}
        characterLimit={characterLimit}
        autocompleteType={autocompleteType}
        field={field}
        needReset={needReset}
        hasAddButton={hasAddButton}
        label={label}
        handleInputChange={handleInputChange}
        handleOnBlur={handleOnBlur}
        autoFocus={autoFocus}
        highlight={highlight}
      />
      <div className={styles.autocompleteContainer}>{tags}</div>
    </div>
  );
}

export default AutocompleteValueRow;
