import { call, put, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { handleError } from 'utils/common';
import { patchEntity } from 'api/apiEntity';
import { DEFAULT_LOGIN_LINKS, ONBOARDING_ROUTES_MAP } from 'connectors/Login/constants';
import { NEW_PASSWORD_STARTED } from 'connectors/NewPassword/redux/actions';
import { newPasswordErrorReset } from 'connectors/NewPassword/redux/creators';
import {
  addRequestInterceptor,
  adminLoginDone,
  candidateLoginDone,
  employeeLoginDone,
  loginNotification,
  removeRequestInterceptor,
} from 'shared/store/app/creators';
import { promiseGetEmployer } from 'pages/EmployeePortal/EmployeeEditorPages/promises/';
import {
  resetPasswordError,
  resetPasswordErrorReset,
} from 'connectors/ResetPassword/redux/creators';

const sagaNewPasswordStarted = function* sagaNewPasswordStarted() {
  while (true) {
    const {
      payload: { token, password, passwordConfirmation },
    } = yield take(NEW_PASSWORD_STARTED);

    yield put(newPasswordErrorReset());
    yield put(resetPasswordErrorReset());

    try {
      const args = {
        type: 'auth/password',
        data: {
          type: 'users',
          attributes: {
            password,
            passwordConfirmation,
            resetPasswordToken: token,
          },
        },
        config: {
          params: {
            includeSet: 'login',
            camelizeIt: true,
          },
        },
      };

      const {
        data: {
          user,
          user: { currentProfileType: type, currentProfile: { onboardingStatus } = {} } = {},
        },
        headers,
      } = yield call(patchEntity, args);

      yield put(removeRequestInterceptor());

      if (user && headers) {
        const { [type]: defaultRoute } = DEFAULT_LOGIN_LINKS;

        if (type === 'Candidate') {
          const { [onboardingStatus]: route } = ONBOARDING_ROUTES_MAP;

          if (route) {
            yield put(push(route));
          } else {
            yield put(push(defaultRoute));
          }

          yield put(candidateLoginDone({ user, headers }));
        }

        if (type === 'Employee') {
          yield put(push(defaultRoute));

          // Employer needed for dynamic homepage funcitonality
          const promiseParams = {
            employerId: user.currentProfile.employerId,
            camelizeIt: true,
          };

          yield put(addRequestInterceptor({ headers }));

          const { employer } = yield call(promiseGetEmployer, promiseParams);

          const fullUser = {
            ...user,
            currentProfile: {
              ...user.currentProfile,
              employer,
            },
          };

          yield put(employeeLoginDone({ user: fullUser, headers }));
        }

        if (type === 'RainmakersAdmin') {
          yield put(push(defaultRoute));

          yield put(adminLoginDone({ user, headers }));
        }
      } else {
        yield put(
          loginNotification({
            notification: 'Your password has been successfully updated. Please login',
          })
        );
        yield put(push('/login/candidate'));
      }
    } catch (error) {
      handleError(error);

      const {
        response: {
          data: { errors: { full_messages: errors = ['Please try again'] } = {} } = {},
        } = {},
      } = error || {};

      yield put(resetPasswordError({ errors }));

      window.browserHistory.push('/reset-password');
    }
  }
};

export default sagaNewPasswordStarted;
