import { postEntity } from 'api/apiEntity';

const promisePostEducationOrgs = ({ data }) => {
  const onFulfilled = (response) => {
    const {
      data: { entities: { educationOrganization: { byId = {}, allIds = [] } = {} } = {} },
    } = response;

    const { attributes: educationOrg = {} } = byId[allIds[0]] || {};

    return { educationOrg };
  };

  const postEducationOrgsArgs = {
    type: 'education_organizations',
    data,
    config: {
      params: {
        normalizeIt: true,
      },
    },
  };

  return postEntity(postEducationOrgsArgs).then(onFulfilled);
};

export default promisePostEducationOrgs;
