/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import locPageMetaData, {
  LOCATIONS_PAGE_SANFRANCISCO,
} from 'pages/LocationsPage/locationsPageMetaData';
import jobsPageMetaData, { JOB_PAGE_SDR } from 'pages/JobsPage/jobsPageMetaData';

import pic1 from './assets/1.png';
import pic2 from './assets/2.png';
import pic3 from './assets/3.png';
import pic4 from './assets/4.png';
import pic5 from './assets/5.png';
import cta from './assets/CTA.png';

import styles from './SFSDRContent.scss';

export function SFSDRContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt="" />
      <h2>Become a Part of San Francisco's Tech Hub as an SDR</h2>
      <p>
        <a target="_blank" rel="noopener noreferrer" href={jobsPageMetaData[JOB_PAGE_SDR].localUrl}>
          {' '}
          Sales Development Representatives (SDRs)
        </a>{' '}
        are indispensable to the expansion and growth of tech innovation in San Francisco and its
        surrounding areas. Whether in cloud computing, AI, social media, hardware development,
        communications, or more, talented sales reps are a significant part of San Francisco’s tech
        paradigm.
      </p>
      <p>
        And San Francisco is one of the most beautiful places to live in the United States. It
        enjoys exceptional weather all year round, is full of excellent opportunities for outdoor
        recreation, and is home to fine cuisine, powerhouse entertainment venues, art museums, and
        world-famous sports teams.
      </p>
      <p>
        To be a Sales Development Representative in San Francisco is to be part of one of the
        world's most exciting, cutting-edge cities.
      </p>

      <h2>Is San Francisco a Tech Hub?</h2>
      <p>
        To many, San Francisco is the tech hub. It’s home to Google, Facebook, Apple, Twitter, and
        countless other innovative tech companies changing the world daily. In addition, it’s close
        to the infamous Silicon Valley and enjoys many tax breaks for tech companies to flourish.
      </p>
      <p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={locPageMetaData[LOCATIONS_PAGE_SANFRANCISCO].localUrl}
        >
          {' '}
          San Francisco
        </a>{' '}
        is also where the latest developments in cloud computing and AI are happening. Cloud
        computing has already changed how we do business with one another, and fresh innovations
        always come up. In addition, AI is on the cusp of being a major force in everything from
        medicine to the arts.
      </p>
      <p>
        While a lot of tech research and development is happening in the United States, San
        Francisco is where some of the most exciting advancements in technology are occurring, and
        companies need talented sales staff to help put that tech into people’s hands.
      </p>

      <img src={pic2} alt="" />
      <h2>What Are a Sales Development Representative’s Responsibilities In San Francisco?</h2>
      <p>
        The standard for a good SDR is the capacity of the salesperson to move prospects through the
        sales funnel. Instead of closing deals, they focus on creating qualified leads. SDRs must be
        able to connect with and engage prospects through various channels, including research, cold
        calling, emails, and other marketing campaigns, as they are frequently the first point of
        contact for new clients.
      </p>
      <p>
        An SDR needs to develop their listening and observing skills to identify and understand the
        needs of their customers. Effective SDRs must arrange meetings with account executives (AE)
        and build relationships with prospects to sell them on your company’s products and services.
      </p>

      <h2>What An SDR’s Work Day Is Like</h2>
      <p>
        The general process of a company’s sales funnel tends to follow a particular routine. First,
        the marketing division provides lead information to the SDRs. SDRs then research and qualify
        those leads and nurture them until they reach the stage when they are ready to purchase.
        This will involve phone calls, video calls, voice messaging, and emails. It can sometimes be
        intense as the SDR searches for clients most likely receptive to sales calls.
      </p>
      <p>
        The leads are then passed on to an Account Executive (AE), who assumes control and works on
        selling the business’s goods or services to close deals.
      </p>

      <img src={pic3} alt="" />
      <h2>What Skills Are Needed To Be a Good SDR?</h2>
      <p>
        A sales development representative’s position is typically entry-level with on-the-job
        training. However, here are some useful abilities that SDRs should develop to advance their
        sales careers.
      </p>

      <ul>
        <li>
          <p>
            <strong>Outreach</strong> - SDRs must learn how to effectively cold-call, email, and
            leave voicemails. In doing so, they should discover which approaches work best with
            different prospects. SDRs should also learn to be prepared for the most typical
            objections clients may have to a sales pitch.
          </p>
        </li>
        <li>
          <p>
            <strong>Organization</strong> - Having a process is crucial to keeping things organized.
            SDRs must keep close track of their calendars and duties, such as research, email
            outreach, calls, and meetings.
          </p>
        </li>
        <li>
          <p>
            <strong>Resiliency</strong> - An SDR’s day is full of frequent phone and email
            communication with prospects—many of whom will say “no.” An SDR needs to develop a thick
            skin in these cases, learn how to overcome each rejection, and avoid letting tiny
            failures spoil their day.
          </p>
        </li>
        <li>
          <p>
            <strong>Building Relationships</strong> - An SDR’s job includes getting a prospect ready
            for a sale before handing them over to the account executive to close. Successful SDRs
            can establish a sincere relationship with prospects and win their trust.
          </p>
        </li>
        <li>
          <p>
            <strong>Active Listening</strong> - An effective SDR focuses on listening to clients and
            gathering information about how their product or service can help solve a client’s
            problems.
          </p>
        </li>
        <li>
          <p>
            <strong>Self-Awareness</strong> - An SDR should be aware of their strengths and
            weaknesses, accept feedback, and recognize areas where they may need to grow.
          </p>
        </li>
      </ul>

      <img src={pic4} alt="" />
      <h2>What Are the Advancement Opportunities for SDRs In San Francisco?</h2>
      <p>
        SDRs might want to explore a range of roles and discover the benefits and drawbacks of each.
        They can decide if they will look into jobs that include sales strategy, developing
        relationships, client-facing communication, or peer leadership. Early awareness of their
        existing abilities and developing interests frequently serves as a guide for their future
        sales careers.
      </p>
      <p>
        After a few years of working as an SDR, the following career possibilities might be worth
        checking out:
      </p>
      <ul>
        <li>Account Executive</li>
        <li>Customer Success</li>
        <li>Account Manager</li>
        <li>Marketing</li>
        <li>Channel Sales</li>
        <li>Strategic Accounts</li>
        <li>Sales Training</li>
        <li>Sales Operations</li>
      </ul>

      <img src={pic5} alt="" />
      <h2>What An SDR’s Work Day Is Like</h2>
      <p>
        The general process of a company’s sales funnel tends to follow a particular routine. First,
        the marketing division provides lead information to the SDRs. SDRs then research and qualify
        those leads and nurture them until they reach the stage when they are ready to purchase.
        This will involve phone calls, video calls, voice messaging, and emails. It can sometimes be
        intense as the SDR searches for clients most likely receptive to sales calls.
      </p>
      <p>
        The leads are then passed on to an Account Executive (AE), who assumes control and works on
        selling the business’s goods or services to close deals.
      </p>

      <Link to="/apply-now" title="Apply Now">
        <img src={cta} alt="" />
      </Link>
      <h2>How Much Does the Average Tech SDR Make?</h2>
      <p>
        Estimating average pay for Sales Development Representative positions can be difficult
        because most candidates start with little to no sales experience. However, beginning base
        pay will be higher in high-demand cities like San Francisco, closer to $64k, with on-target
        earnings (OTEs) reaching $86k.
      </p>
      <p>
        As one develops experience, salary can increase, although Rainmakers’ research shows this
        levels off after five to seven years of experience. Growth returns after seven years,
        although this is usually due to many sales reps moving up to management and lead positions.
      </p>

      <p>
        To find out more about prospects in sales,{' '}
        <a href={`https://${process.env.HOST}/apply-now/`}>contact Rainmakers</a> today to get
        started on a guaranteed path to success.
      </p>
    </div>
  );
}
