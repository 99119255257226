import { createSelector } from 'reselect';
import isEmpty from 'lodash.isempty';
import camelCase from 'lodash.camelcase';

import { formatType } from '../utils';
import defaultErrors from '../errors';

export const getApp = (state) => state.app;

const sliceSelectorFactory = (slice) =>
  createSelector(getApp, (state) => {
    const { [slice]: chosenSlice = {} } = state || {};
    return chosenSlice;
  });

const entitySelectorFactory = (slice, entity) =>
  createSelector(
    sliceSelectorFactory(slice),
    ({ entities = {} }) => entities[formatType(entity)] || {}
  );

const resourceSelectorFactory = (slice, entity, id) =>
  createSelector(
    entitySelectorFactory(slice, formatType(entity)),
    ({ byId: { [id]: resource } = {} }) => resource || {}
  );

const errorsSelectorFactory = (slice, type, id) =>
  createSelector(
    resourceSelectorFactory(slice, formatType(type), id),
    ({ errors }) => errors || defaultErrors[camelCase(type)] || {}
  );

const metaSelectorFactory = (slice) =>
  createSelector(sliceSelectorFactory(slice), ({ meta = {} }) => meta);

const pageSelectorFactory = (slice, entity) =>
  createSelector(metaSelectorFactory(slice), (meta) => {
    const { [formatType(entity)]: { pagination: { currentPage } = {} } = {} } = meta;

    return currentPage;
  });

const totalSelectorFactory = (slice, entity) =>
  createSelector(metaSelectorFactory(slice), (meta) => {
    const { [formatType(entity)]: { pagination: { totalCount } = {} } = {} } = meta;

    return totalCount;
  });

const loadingSelectorFactory = (slice, entity) =>
  createSelector(
    sliceSelectorFactory(slice),
    ({ entities = {}, meta = {} }) =>
      (entities[formatType(entity)] &&
        entities[formatType(entity)].byId &&
        isEmpty(entities[formatType(entity)].byId) &&
        meta &&
        meta[formatType(entity)] &&
        meta[formatType(entity)].pagination &&
        isEmpty(meta[formatType(entity)].pagination)) ||
      (meta && meta[formatType(entity)] && meta[formatType(entity)].isLoading)
  );

const backgroundLoadingSelectorFactory = (slice, entity) =>
  createSelector(
    sliceSelectorFactory(slice),
    ({ meta = {} }) =>
      meta && meta[formatType(entity)] && meta[formatType(entity)].isBackgroundLoading
  );

const apiErrorsSelectorFactory = (slice) =>
  createSelector(sliceSelectorFactory(slice), ({ apiErrors = {} }) => apiErrors);

const savingSelectorFactory = (slice) =>
  createSelector(sliceSelectorFactory(slice), ({ saving }) => saving);

export {
  apiErrorsSelectorFactory,
  savingSelectorFactory,
  sliceSelectorFactory,
  entitySelectorFactory,
  resourceSelectorFactory,
  errorsSelectorFactory,
  loadingSelectorFactory,
  backgroundLoadingSelectorFactory,
  pageSelectorFactory,
  totalSelectorFactory,
};
