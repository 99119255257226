import React, { Component } from 'react';

import { handleError } from 'utils/common';
import { postNestedEntity } from 'api/apiEntity';

import { VisibleHOC } from 'containers/HOC';

import Dropdown from 'components/Dropdown';

@VisibleHOC()
class ToolboxCohortsActionDropdown extends Component {
  handleClick = (value) => {
    switch (value) {
      case 'duplicateEmployers':
        return this.duplicateCohort('duplicate_with_employers');
      case 'duplicateCandidates':
        return this.duplicateCohort('duplicate_with_candidates');
      case 'delete':
        return this.deleteCohort();
      default:
        return console.log('cannot perform this action');
    }
  };

  duplicateCohort = async (nestedType) => {
    const {
      actions: { resourceUpdate, showModal },
      cohortId,
    } = this.props;

    const args = {
      id: cohortId,
      type: 'cohorts',
      nestedType,
      config: {
        params: {
          normalizeIt: true,
        },
      },
    };

    let byId;
    let allIds;
    try {
      ({ data: { entities: { cohort: { byId, allIds } = {} } = {} } = {} } = await postNestedEntity(
        args
      ));
    } catch (error) {
      handleError(error);
    }

    const resource = byId[allIds[0]];

    resourceUpdate({
      id: resource.id,
      resource,
      slice: 'toolboxCohorts',
      type: 'cohorts',
    });

    showModal({
      key: 'CohortEditorModal',
      parent: 'ToolboxCohortsPage',
      route: `/toolbox/cohort/${resource.id}/edit-main`,
    });
  };

  deleteCohort = () => {
    const {
      actions: { deleteResource },
      cohortId,
    } = this.props;

    deleteResource({
      type: 'cohorts',
      slice: 'toolboxCohorts',
      id: cohortId,
    });
  };

  render() {
    const source = [
      {
        label: 'Duplicate w/ Employers',
        value: 'duplicateEmployers',
      },
      {
        label: 'Duplicate w/ Candidates',
        value: 'duplicateCandidates',
      },
      {
        label: 'Delete',
        value: 'delete',
      },
    ];

    const { isVisible } = this.props;

    const dropDownProps = {
      above: !isVisible,
      source,
      onChange: this.handleClick,
      value: 'send_message',
      defaultValue: 'Actions',
      left: true,
      actions: true,
      defaultIcon: 'caret-down',
      activeIcon: 'caret-up',
    };

    return <Dropdown {...dropDownProps} />;
  }
}

export default ToolboxCohortsActionDropdown;
