import React, { useState } from 'react';

import { handleError } from 'utils/common';
import { promiseGetCompany } from 'pages/AdminPortal/ToolboxCompanyEditorPage/promises';
import { postEntity } from 'api/apiEntity';

import AutocompleteServer from 'connectors/AutocompleteServer';

import DialogueModal from 'components/DialogueModal';
import ErrorMessage from 'components/ErrorMessage';
import Table from 'components/Table';
import TableRow from 'components/Table/components/TableRow';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import Button from 'components/Button';

import ToolboxEmployersRow from '../ToolboxEmployersRow';
import ToolboxEmployersSearchBar from '../ToolboxEmployersSearchBar';

import styles from './ToolboxEmployersTable.scss';

const ToolboxEmployersTable = (props) => {
  const [state, setState] = useState({
    showPrompt: false,
    companyName: '',
    serverError: null,
  });

  const { companyId, companyName, serverError, showPrompt } = state;

  const {
    applicationStatus,
    isLoading,
    employers,
    handleSearchInputChange,
    handleSearchFilterChange,
    handleSortChange,
    name,
    patchResource,
    resourceUpdate,
    sortBy,
    loadMore,
    showModal,
    total,
  } = props;

  const header = [
    {
      content: 'Name',
      headerClassName: styles.nameHeader,
      value: 'name',
      onClick: handleSortChange,
    },
    {
      content: 'Location',
      headerClassName: styles.locationHeader,
    },
    {
      content: 'SaaS',
      headerClassName: styles.saasHeader,
    },
    {
      content: 'Created At',
      headerClassName: styles.lastActivityHeader,
      value: 'created_at',
      onClick: handleSortChange,
    },
    {
      content: 'Approved On',
      headerClassName: styles.updatedAtHeader,
    },
    {
      content: 'Creator',
      headerClassName: styles.creatorHeader,
    },
    {
      content: '',
      headerClassName: styles.candActionHeader,
    },
  ];

  const tableContent = employers
    .filter((emp) => !emp.attributes.temporary)
    .map((employer) => (
      <TableRow id={employer.id} key={employer.id}>
        <ToolboxEmployersRow
          employer={employer}
          patchResource={patchResource}
          showModal={showModal}
        />
      </TableRow>
    ));

  const titleContent = (
    <div className={styles.employerHeader}>
      <div className={styles.employerHeaderLeft}>
        <ToolboxEmployersSearchBar
          applicationStatus={applicationStatus}
          name={name}
          handleSearchInputChange={handleSearchInputChange}
          handleSearchFilterChange={handleSearchFilterChange}
        />
      </div>
      <Button
        onClick={() => setState((prevState) => ({ ...prevState, showPrompt: true }))}
        primary={true}
        className={styles.newCandButton}
      >
        + Employer
      </Button>
    </div>
  );

  const infiniteScrollProps = {
    loadMore,
    hasMore: !isLoading && total > employers.length,
    loader: isLoading ? (
      <div key="infiniteScrollLoader" />
    ) : (
      <InfiniteScrollLoader key="infiniteScrollLoader" />
    ),
    useWindow: false,
  };

  const tableProps = {
    blockProps: {
      titleClass: styles.dashboardTitleContainer,
    },
    titleContent,
    tableContent,
    status: applicationStatus,
    header,
    sortBy,
    infiniteScrollProps,
    tableName: 'adminEmployers',
    isEmpty: !isLoading && employers.length === 0,
    emptyTextString: 'No Employers',
    isLoading,
  };

  const createEmployerProps = {
    placeholder: 'Start typing and select an company',
    value: '',
    size: 'full',
    name: '',
    autocompleteType: 'companies_no_employer',
    field: 'name',
    handleGetValue: (value) =>
      setState((prevState) => ({ ...prevState, companyName: value, serverError: null })),
    handleInputChange: (event) => {
      const {
        target: {
          value: { id, name },
        },
      } = event;

      setState((prevState) => ({ ...prevState, companyId: id, companyName: name }));
    },
  };

  const handleCreate = async () => {
    if (companyId) {
      promiseGetCompany({ companyId })
        .then(async (company) => {
          try {
            const args = {
              type: 'employers',
              data: {
                type: 'employer',
                attributes: {
                  ...company,
                  companyId,
                  name: companyName,
                },
              },
              config: {
                params: {
                  normalizeIt: true,
                },
              },
            };

            const { data: { entities: { employer: { byId = {}, allIds = [] } = {} } = {} } = {} } =
              await postEntity(args);

            const resourceWithId = {
              ...byId[allIds[0]],
            };

            resourceUpdate({
              id: resourceWithId.id,
              resource: resourceWithId,
              slice: 'toolboxEmployers',
              type: 'employers',
            });

            setState((prevState) => ({ ...prevState, showPrompt: false }));

            showModal({
              key: 'EmployerEditorModal',
              parent: 'ToolboxEmployersPage',
              route: `/toolbox/employer/${resourceWithId.id}/edit-profile`,
            });
          } catch (error) {
            handleError(error);
            const { response: { data: { errors = {} } = {} } = {} } = error || {};

            const errorKeys = Object.keys(errors);

            if (errorKeys[0] === 'name') {
              setState((prevState) => ({ ...prevState, serverError: 'Employer already exists' }));
            }
          }
        })
        .catch(handleError);
    } else {
      const args = {
        type: 'companies',
        data: {
          type: 'company',
          attributes: {
            name: companyName,
          },
        },
        config: {
          params: {
            normalizeIt: true,
          },
        },
      };

      try {
        const { data: { entities: { company: { allIds: companyAllIds = [] } = {} } = {} } = {} } =
          await postEntity(args);

        const employerArgs = {
          type: 'employers',
          data: {
            type: 'employer',
            attributes: {
              companyId: companyAllIds[0],
              name: companyName,
            },
          },
          config: {
            params: {
              normalizeIt: true,
            },
          },
        };

        const { data: { entities: { employer: { allIds = [], byId = {} } = {} } = {} } = {} } =
          await postEntity(employerArgs);

        setState((prevState) => ({ ...prevState, showPrompt: false }));

        const resource = {
          ...byId[allIds[0]],
        };

        resourceUpdate({
          slice: 'toolboxEmployers',
          type: 'employers',
          id: resource.id,
          resource,
        });

        showModal({
          key: 'EmployerEditorModal',
          parent: 'ToolboxEmployersPage',
          route: `/toolbox/employer/${resource.id}/edit-profile`,
        });
      } catch (error) {
        handleError(error);
        const { response: { data: { errors = {} } = {} } = {} } = error || {};

        const errorKeys = Object.keys(errors);

        if (errorKeys[0] === 'name') {
          setState((prevState) => ({ ...prevState, serverError: 'Employer already exists' }));
        }
      }
    }
  };

  return (
    <>
      <Table {...tableProps} />
      {showPrompt ? (
        <DialogueModal>
          <div className={styles.requestConfirmationModal}>
            <div className={styles.requestConfirmationTitle}>Create New Employer</div>

            <div className={styles.requestConfirmationContent}>
              Create from an existing company by typing and selecting a name from the list or type
              the name of the new company and click create.
            </div>

            {serverError ? (
              <ErrorMessage className={styles.error}>{serverError}</ErrorMessage>
            ) : null}

            <div className={styles.requestConfirmationContent}>
              <AutocompleteServer {...createEmployerProps} />
            </div>
            <div className={styles.requestConfirmationButtons}>
              <Button
                onClick={() => setState((prevState) => ({ ...prevState, showPrompt: false }))}
                quaternary
              >
                Cancel
              </Button>
              <Button onClick={handleCreate} primary={true} disabled={!companyName}>
                Create
              </Button>
            </div>
          </div>
        </DialogueModal>
      ) : null}
    </>
  );
};

export default ToolboxEmployersTable;
