import React, { Component } from 'react';

// import Bold from 'components/Bold';
import Bolder from 'components/Bolder';
import Button from 'components/Button';
import Content from 'components/Content';
import ContentTitle from 'components/ContentTitle';
import DialogueModal from 'components/DialogueModal';
import FormattedDate from 'components/FormattedDate';
import PassForm from 'pages/CandidatePortal/CandidatePassPage/components/PassForm';

import styles from './FeedbackActionDropdown.scss';

class FeedbackActionDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbackType: null,
    };
  }

  render() {
    const {
      companyFeedback,
      employerName,
      externalName,
      firstName,
      lastName,
      offerFeedback,
      passedOn,
      privateReqCanFeedback: { attributes: { feedback } = {} } = {},
      reason,
      roleFeedback,
      state,
    } = this.props;

    const { feedbackType } = this.state;

    const buttonProps = {
      onClick: () => this.setState({ feedbackType: state }),
      secondary: true,
      className: styles.button,
    };

    const closeButtonProps = {
      quaternary: true,
      onClick: () => this.setState({ feedbackType: null }),
    };

    const closeButton = <Button {...closeButtonProps}>Close</Button>;

    const feedbackContent = feedback ? (
      <Content className={styles.input}>
        <ContentTitle>ADDITIONAL FEEDBACK</ContentTitle>
        <Content className={styles.passedOnDate}>{feedback}</Content>
      </Content>
    ) : null;

    const passedOnContent = passedOn ? (
      <Content className={styles.input}>
        <ContentTitle>PASSED ON</ContentTitle>
        <Content className={styles.passedOnDate}>
          <FormattedDate date={passedOn} parse />
        </Content>
      </Content>
    ) : null;

    const passFormProps = {
      candidate: {
        firstName,
        lastName,
        id: 'candId',
      },
      opportunities: [
        {
          id: 'reqCandId',
          candidate: {
            id: 'candId',
          },
          employer: {
            name: employerName,
          },
          requisition_opening_copy: {
            external_name: externalName,
          },
        },
      ],
      companyFeedback,
      handleCancel: () => this.setState({ feedbackType: null }),
      offerFeedback,
      match: {
        params: {
          id: 'reqCandId',
        },
      },
      reason,
      roleFeedback,
      user: {
        currentProfileType: 'Employee',
      },
    };

    const candFeedbackContent =
      feedbackType === 'candidate_passed' ? (
        <DialogueModal className={styles.candidatePassedModal}>
          <PassForm {...passFormProps} />
        </DialogueModal>
      ) : null;

    const empFeedbackContent =
      feedbackType === 'employer_passed' ? (
        <DialogueModal className={styles.candidatePassedModal}>
          <div className={styles.promptModal}>
            <Content className={styles.mainTitle}>
              <Bolder>{employerName}</Bolder> passed on{' '}
              <Bolder>
                {firstName} {lastName}
              </Bolder>
            </Content>

            {passedOnContent}

            {feedbackContent}

            <div className={styles.promptButtons}>{closeButton}</div>
          </div>
        </DialogueModal>
      ) : null;

    const prompt = candFeedbackContent || empFeedbackContent || '';

    return (
      <div className={styles.ActionDropdown}>
        <Button {...buttonProps}>Feedback</Button>
        {prompt}
      </div>
    );
  }
}

export default FeedbackActionDropdown;
