import React from 'react';

import { ComponentErrorBoundaryHOC } from 'containers/HOC';

import Button from 'components/Button';
import FontIcon from 'components/FontIcon';

import styles from './SocialMediaLink.scss';

const SocialMediaLink = ({
  onClick,
  to,
  fullUrl,
  iconName,
  label,
  show,
  directUrl = false,
  newTab = true,
}) => {
  const formattedUrl = directUrl ? to : to && to.includes('http') ? to : `https://${to}`;

  const buttonProps = {
    className: styles.profileSocialButton,
    onClick,
    fullUrl,
    sameTab: !newTab,
  };

  if (to) {
    buttonProps.to = formattedUrl;
  }

  return to || show ? (
    <Button {...buttonProps}>
      <FontIcon iconName={iconName} />
      <div className={styles.socialMediaButton}>{label}</div>
    </Button>
  ) : null;
};

export default ComponentErrorBoundaryHOC()(SocialMediaLink);
