import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { printElements } from 'utils/printElements';

import ReadyContainer from 'connectors/ReadyContainer';

import { toDollars, toCents } from 'utils/moneyUtils';

import Block from 'components/Block';
import Bold from 'components/Bold';
import Bolder from 'components/Bolder';
import Button from 'components/Button';
import Content from 'components/Content';
import Input from 'components/Input';
import InputCheckbox from 'components/InputCheckbox';

import Footer from 'pages/CandidatesHome/components/Footer';

import styles from './HiringForm.scss';

class HiringForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quotaMin: null,
      quotaMax: null,
      dealSizeMin: null,
      dealSizeMax: null,
      salesCycleMin: null,
      salesCycleMax: null,
      outbound: null,
      inbound: null,
      productsSold: null,
      industriesSold: null,
      decisionMakerSold: null,
      departmentsSold: null,
      closingExperience: null,
      outsideSalesExperience: null,
      soldSaas: null,
      soldCLevel: null,
      startupExperience: null,
      managementExperience: null,
    };
  }

  handleInputChange = (event) => {
    const {
      target: { name, value, checked, type },
    } = event;

    const newValue = type === 'checkbox' ? checked : value;

    this.setState({ [name]: newValue });
  };

  render() {
    const {
      quotaMin,
      quotaMax,
      dealSizeMin,
      dealSizeMax,
      salesCycleMin,
      salesCycleMax,
      outbound,
      inbound,
      productsSold,
      industriesSold,
      decisionMakerSold,
      departmentsSold,
      closingExperience,
      outsideSalesExperience,
      soldSaas,
      soldCLevel,
      startupExperience,
      managementExperience,
    } = this.state;

    const moneyTextInputProps = {
      className: styles.inputContainerNoPad,
      type: 'text',
      size: 'full',
      handleInputChange: (event) =>
        this.handleInputChange({
          ...event,
          target: {
            ...event.target,
            value: toCents(event.target.value),
            name: event.target.name,
          },
        }),
      moneyInput: true,
    };

    const quotaMinProps = {
      ...moneyTextInputProps,
      value: quotaMin || quotaMin === 0 ? toDollars(quotaMin) : '',
      label: 'Quota (range)',
      name: 'quotaMin',
      oteInputOne: true,
      placeholder: 'Low',
      smallMargin: true,
    };

    const quotaMaxProps = {
      ...moneyTextInputProps,
      value: quotaMax || quotaMax === 0 ? toDollars(quotaMax) : '',
      name: 'quotaMax',
      oteInputTwo: true,
      placeholder: 'High',
    };

    const dealSizeMinProps = {
      ...moneyTextInputProps,
      label: 'Deal size (range)',
      value: dealSizeMin || dealSizeMin === 0 ? toDollars(dealSizeMin) : '',
      name: 'dealSizeMin',
      oteInputOne: true,
      placeholder: 'Low',
      smallMargin: true,
    };

    const dealSizeMaxProps = {
      ...moneyTextInputProps,
      value: dealSizeMax || dealSizeMax === 0 ? toDollars(dealSizeMax) : '',
      name: 'dealSizeMax',
      oteInputTwo: true,
      placeholder: 'High',
    };

    const salesCycleMinProps = {
      size: 'full',
      type: 'number',
      secondaryType: 'days',
      handleInputChange: this.handleInputChange,
      label: 'Sales cycle (range)',
      name: 'salesCycleMin',
      value: salesCycleMin || salesCycleMin === 0 ? salesCycleMin : '',
      min: '0',
      oteInputOne: true,
      smallMargin: true,
    };

    const salesCycleMaxProps = {
      size: 'full',
      type: 'number',
      secondaryType: 'days',
      handleInputChange: this.handleInputChange,
      name: 'salesCycleMax',
      value: salesCycleMax || salesCycleMax === 0 ? salesCycleMax : '',
      oteInputTwo: true,
      min: '0',
    };

    const roundOutbound = outbound || outbound === 0 ? outbound : '';
    const roundInbound = inbound || inbound === 0 ? inbound : '';

    const outboundProps = {
      size: 'full',
      name: 'outbound',
      value: roundOutbound,
      label: '% Outbound',
      type: 'number',
      min: '0',
      max: '100',
      percentInput: true,
      oteInputOne: true,
      smallMargin: true,
      handleInputChange: (event) => {
        const newValue = event.target.value && event.target.value > 100 ? 100 : event.target.value;
        const partnerValue = 100 - newValue;
        this.setState({ inbound: partnerValue });
        this.handleInputChange({
          ...event,
          target: {
            ...event.target,
            value: newValue || newValue === 0 ? newValue : null,
            name: event.target.name,
          },
        });
      },
    };

    const inboundProps = {
      size: 'full',
      name: 'inbound',
      value: roundInbound,
      label: '% Inbound',
      type: 'number',
      min: '0',
      max: '100',
      percentInput: true,
      oteInputTwo: true,
      smallMargin: true,
      handleInputChange: (event) => {
        const newValue = event.target.value && event.target.value > 100 ? 100 : event.target.value;
        const partnerValue = 100 - newValue;
        this.setState({ outbound: partnerValue });
        return this.handleInputChange({
          ...event,
          target: {
            ...event.target,
            value: newValue || newValue === 0 ? newValue : null,
            name: event.target.name,
          },
        });
      },
    };

    const title = 'Hiring Profile';
    const metaDescription =
      'Account Executive Hiring Profile: this sheet is a great way to get your AE hiring requirements down on paper.';
    const canonicalUrl = `https://${process.env.HOST}/my-hiring-profile/`;
    const helmetProps = {
      key: 'helmet',
      title,
      meta: [
        { name: 'description', content: metaDescription },
        { property: 'og:url', content: canonicalUrl },
        { property: 'og:title', content: title },
        { property: 'og:description', content: metaDescription },
      ],
      link: [{ rel: 'canonical', href: canonicalUrl }],
    };

    const commonTextAreaProps = {
      type: 'textarea',
      size: 'full',
      rows: '5',
      handleInputChange: this.handleInputChange,
      smallMargin: true,
    };

    const productsSoldProps = {
      ...commonTextAreaProps,
      name: 'productsSold',
      label: 'Products sold',
      value: productsSold || '',
      placeholder: '',
    };

    const industriesSoldProps = {
      ...commonTextAreaProps,
      name: 'industriesSold',
      label: 'Industries sold to',
      value: industriesSold || '',
      placeholder: '',
    };

    const decisionMakerSoldProps = {
      ...commonTextAreaProps,
      name: 'decisionMakerSold',
      label: 'Decision-maker sold to',
      value: decisionMakerSold || '',
      placeholder: '',
    };

    const departmentsSoldProps = {
      ...commonTextAreaProps,
      name: 'departmentsSold',
      label: 'Departments sold to',
      value: departmentsSold || '',
      placeholder: '',
    };

    const printButtonProps = {
      onClick: () => {
        const element = document.getElementById('printform');

        if (element) {
          printElements.print([element]);
        }
      },
      primary: true,
      className: styles.printButton,
    };

    const blockProps = {
      addWhiteBG: true,
    };

    const closingExperienceProps = {
      name: 'closingExperience',
      label: 'Closing experience',
      checked: closingExperience || false,
      handleInputChange: this.handleInputChange,
      checkboxMany: true,
    };

    const outsideSalesExperienceProps = {
      name: 'outsideSalesExperience',
      label: 'Outside sales experience',
      checked: outsideSalesExperience || false,
      handleInputChange: this.handleInputChange,
      checkboxMany: true,
    };
    const soldSaasProps = {
      name: 'soldSaas',
      label: 'Sold SaaS',
      checked: soldSaas || false,
      handleInputChange: this.handleInputChange,
      checkboxMany: true,
    };
    const soldCLevelProps = {
      name: 'soldCLevel',
      label: 'Sold to C-level',
      checked: soldCLevel || false,
      handleInputChange: this.handleInputChange,
      checkboxMany: true,
    };
    const startupExperienceProps = {
      name: 'startupExperience',
      label: 'Startup experience',
      checked: startupExperience || false,
      handleInputChange: this.handleInputChange,
      checkboxMany: true,
    };
    const managementExperienceProps = {
      name: 'managementExperience',
      label: 'Management experience',
      checked: managementExperience || false,
      handleInputChange: this.handleInputChange,
      checkboxMany: true,
    };

    return (
      <React.Fragment>
        <Helmet {...helmetProps} />
        <ReadyContainer key="readyContainer" className={styles.HiringForm}>
          <Block {...blockProps}>
            <div className={styles.formContainer}>
              <Bolder className={styles.topHeader}>HIRING WORKSHEETS</Bolder>
              <h1>
                <Bolder className={styles.middleHeader}>Printable hiring profile</Bolder>
              </h1>
              <Content className={styles.titleContentOne}>
                Here is a printable Account Executive Hiring Profile. Fill out your hiring
                requirements to ensure that your team is aligned on your hiring specs, then print
                and share!
              </Content>

              <Button {...printButtonProps}>Print form</Button>

              <form id="printform" className={styles.form}>
                <div className={styles.header}>
                  <img alt="" src={require('shared/assets/rm-long-logo-white.svg')} />
                </div>

                <div className={styles.title}>
                  <div className={styles.headerOne}>
                    <Bolder className={styles.titleHeader}>Account Executive Hiring Profile</Bolder>
                  </div>
                  <Content className={styles.titleContent}>
                    This sheet is a great way to get your AE hiring requirements down on paper. Feel
                    free to share with your sourcing or recruiting team to make sure everyone is
                    aligned on the job spec.
                  </Content>
                </div>

                <div className={styles.mainForm}>
                  <div className={styles.leftColumn}>
                    <div className={styles.rangeInputsContainer}>
                      <Input {...quotaMinProps} />
                      <Input {...quotaMaxProps} />
                    </div>

                    <div className={styles.rangeInputsContainer}>
                      <Input {...dealSizeMinProps} />
                      <Input {...dealSizeMaxProps} />
                    </div>

                    <div className={styles.rangeInputsContainer}>
                      <Input {...salesCycleMinProps} />
                      <Input {...salesCycleMaxProps} />
                    </div>

                    <div className={styles.rangeInputsContainer}>
                      <Input {...outboundProps} />
                      <Input {...inboundProps} />
                    </div>

                    <div className={styles.booleanInputsContainer}>
                      <div className={styles.label}>
                        <Bold>Sales experience (check all that apply)</Bold>
                      </div>
                      <InputCheckbox {...closingExperienceProps} />
                      <InputCheckbox {...outsideSalesExperienceProps} />
                      <InputCheckbox {...soldSaasProps} />
                      <InputCheckbox {...soldCLevelProps} />
                      <InputCheckbox {...startupExperienceProps} />
                      <InputCheckbox {...managementExperienceProps} />
                    </div>
                  </div>
                  <div className={styles.rightColumn}>
                    <Input {...productsSoldProps} />
                    <Input {...industriesSoldProps} />
                    <Input {...decisionMakerSoldProps} />
                    <Input {...departmentsSoldProps} />
                  </div>
                </div>

                <div className={styles.footer}>
                  <Bold>
                    Rainmakers is the leading recruiting platform for top salespeople. We provide
                    the top 6% of sales talent along with all the sales data you see on this sheet!
                    Head to {`https://${process.env.HOST}`} to learn more.
                  </Bold>
                </div>
              </form>

              <Button {...printButtonProps}>Print form</Button>
            </div>
          </Block>

          <div className={styles.footerContainer}>
            <Footer />
          </div>
        </ReadyContainer>
      </React.Fragment>
    );
  }
}

export default HiringForm;
