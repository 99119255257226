import React from 'react';

import Block from 'components/Block';
import Bold from 'components/Bold';
import Content from 'components/Content';
import FormattedDate from 'components/FormattedDate';
import Label from 'components/Label';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';

import GroupMembers from './components/GroupMembers';
import Feedback from './components/Feedback';
import Message from './components/Message';

import styles from './ApproachEditorEmployee.scss';

const ApproachEditorMessages = ({
  actions,
  actions: { patchResource },
  candidates,
  candidates: { byId: candidatesById = {} } = {},
  employers: { byId: employersById = {} } = {},
  employees,
  handleSetState,
  messages: { byId: messagesById = {}, allIds: messagesAllIds = [] } = {},
  messageGroups: { allIds: messageGroupsAllIds = [], byId: messageGroupsById = {} } = {},
  messageGroupMembers,
  rainmakersAdmins,
  requisitionCandidate: {
    attributes: {
      companyFeedback = [],
      createdAt,
      passedOn,
      offerFeedback = [],
      offerSignedDate,
      reason,
      roleFeedback = [],
      startDate,
      state = '',
    } = {},
    id: requisitionCandidateId,
    relationships: {
      candidate: { data: candidateIds = [] } = {},
      employer: { data: employerIds = [] } = {},
      requisitionOpeningCopy: { data: requisitionOpeningIds = [] } = {},
    } = {},
  } = {},
  requisitionOpenings: { byId: requisitionOpeningsById = {} } = {},
  showFeedback,
  user,
}) => {
  const messageGroupId = messageGroupsAllIds.find(
    (id) => messageGroupsById[id].attributes.linkedObjectId === requisitionCandidateId
  );

  const { attributes: { fullName = '' } = {} } = candidatesById[candidateIds[0]];

  const { attributes: { name = '' } = {} } = employersById[employerIds[0]];

  const { attributes: { role = 'N/A' } = {} } = requisitionOpeningsById[requisitionOpeningIds[0]];

  const messageBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    largeTitleFont: true,
    className: styles.block,
    addPadding: true,
  };

  const currentMessageIds = messagesAllIds.filter(
    (id) => messagesById[id].attributes.messageGroupId === messageGroupId
  );

  const messagesContent =
    currentMessageIds.length > 0 ? (
      currentMessageIds.map((id, idx) => {
        const message = messagesById[id].attributes;
        const messageProps = {
          isAdmin: false,
          message,
          idx,
        };

        return <Message key={id} {...messageProps} />;
      })
    ) : (
      <Block {...messageBlockProps}>
        <div className={styles.empty}>
          <Bold>No messages available.</Bold>
        </div>
      </Block>
    );

  const titleBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    addPadding: true,
    noTitlePadding: true,
    className: styles.titleBlock,
    largeTitleFont: false,
  };

  const titleTwoBlockProps = {
    ...titleBlockProps,
    className: styles.titleTwoBlock,
  };

  const groupMembersProps = {
    actions,
    candidates,
    employees,
    messageGroupId,
    messageGroupMembers,
    rainmakersAdmins,
    user,
  };

  const feedbackProps = {
    companyFeedback,
    handleSetState,
    offerFeedback,
    offerSignedDate,
    passedOn,
    patchResource,
    reason,
    roleFeedback,
    showFeedback,
    startDate,
    state,
  };

  const content = (
    <div className={styles.ApproachMessages}>
      <Block {...titleBlockProps}>
        <Content className={styles.tier}>
          <Content className={styles.tierRowOne}>
            <Content className={styles.attribute}>
              <Label>Name</Label>
              <Content className={styles.value}>{fullName}</Content>
            </Content>

            <Content className={styles.attribute}>
              <Label>Employer</Label>
              <Content className={styles.value}>{name}</Content>
            </Content>

            <Content className={styles.attribute}>
              <Label>Role</Label>
              <Content className={styles.value}>{role}</Content>
            </Content>

            <Content className={styles.attribute}>
              <Label>Contacted On</Label>
              <Content className={styles.value}>
                <FormattedDate format="M/D/YY" parse date={createdAt} />
              </Content>
            </Content>
          </Content>
        </Content>

        <Feedback {...feedbackProps} />
      </Block>

      <Block {...titleTwoBlockProps}>
        <GroupMembers {...groupMembersProps} />
      </Block>

      <div className={styles.scrollDiv}>{messagesContent}</div>
    </div>
  );

  const layoutWithoutSidebarProps = {
    content,
  };

  return <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />;
};

export default ApproachEditorMessages;
