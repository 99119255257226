import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

import Bold from 'components/Bold';
import Button from 'components/Button';
import Content from 'components/Content';
import FormattedDate from 'components/FormattedDate';

import styles from './GetListed.scss';

const GetListed = ({ batchRequest, handleBatchRequest, handleBatchRerequest }) => {
  const { state = null, startDate, endDate } = batchRequest || {};

  const requestButtonProps = {
    primary: true,
    onClick: handleBatchRequest,
    className: styles.requestButton,
  };

  const rerequestButtonProps = {
    primary: true,
    onClick: handleBatchRerequest,
    className: styles.requestButton,
  };

  const momentStartDate = startDate ? moment.tz(startDate, 'America/Los_Angeles') : null;
  const momentEndDate = endDate ? moment.tz(endDate, 'America/Los_Angeles') : null;

  const initializedRequestContent =
    state === 'initialized' ? (
      <Content className={styles.secondary}>
        You&apos;re ready to be shown to awesome companies! If you want to be listed, click the
        &ldquo;List Me&rdquo; button.
        <Button {...requestButtonProps}>List me!</Button>
      </Content>
    ) : null;

  const pendingRequestContent =
    state === 'pending' ? (
      <Content className={styles.secondary}>
        Your listing request has been received. Rainmakers will review your profile and get back to
        you as soon as possible. In the meantime, make sure your{' '}
        <Bold>
          <Link to="/candidate/edit-preview">info</Link>
        </Bold>{' '}
        is up-to-date. Once you are accepted, your profile will be locked.
      </Content>
    ) : null;

  const acceptedRequestContent =
    state === 'accepted' ? (
      <Content className={styles.secondary}>
        Congrats, you&apos;ve been accepted to be listed on Rainmakers! You will be listed from{' '}
        <Bold>
          <FormattedDate
            format="MMMM Do"
            date={momentStartDate ? momentStartDate.format() : ''}
            parse
          />
        </Bold>{' '}
        through{' '}
        <Bold>
          <FormattedDate
            format="MMMM Do"
            date={momentEndDate ? momentEndDate.format() : ''}
            parse
          />
        </Bold>
        . Make sure you are available during those dates to connect with employers. In the meantime
        your profile will be locked until your listing is complete. If you would like to cancel your
        listing or need to update any information, please contact support at{' '}
        <a href="mailto:support@rainmakers.co">support@rainmakers.co</a>.
      </Content>
    ) : null;

  const activeRequestContent =
    state === 'active' ? (
      <Content className={styles.secondary}>
        Awesome! You are currently being listed. You will be visible to employers through{' '}
        <Bold>
          <FormattedDate format="MMMM Do" date={momentEndDate.format()} parse />
        </Bold>
        .
      </Content>
    ) : null;

  const declinedRequestContent =
    state === 'declined' ? (
      <Content className={styles.secondary}>
        You have not been accepted to the current listing. Review your{' '}
        <Bold>
          <Link to="/candidate/edit-preview">profile</Link>
        </Bold>{' '}
        and check back soon for your chance to get listed in the next group.
      </Content>
    ) : null;

  const completeRequestContent =
    state === 'complete' ? (
      <Content className={styles.secondary}>
        Your listing has ended. We hope it went well, but if you would like to be listed again, just
        click &apos;List me!&apos; below.
        <Button {...rerequestButtonProps}>List me!</Button>
      </Content>
    ) : null;

  const content =
    initializedRequestContent ||
    pendingRequestContent ||
    acceptedRequestContent ||
    activeRequestContent ||
    completeRequestContent ||
    declinedRequestContent ||
    null;

  return (
    <div className={styles.GetListed} data-testid="GetListed">
      {content}
    </div>
  );
};

export default GetListed;
