import React from 'react';

import { ComponentErrorBoundaryHOC } from 'containers/HOC';

import { SALES_EXPERIENCE_QUESTIONS } from 'pages/CandidatePortal/OnboardingPages/constants';

import Block from 'components/Block';
import Bolder from 'components/Bolder';
import Content from 'components/Content';

import styles from './ProfileAttributes.scss';

export const ProfileAttributes = ({
  recentRole,
  recentGrad,
  closingExperienceMonths,
  ...otherProps
}) => {
  const isCSM = recentRole === 'CSM/AM';

  const attributes = recentRole
    ? SALES_EXPERIENCE_QUESTIONS[recentRole] || SALES_EXPERIENCE_QUESTIONS.default
    : SALES_EXPERIENCE_QUESTIONS['Non-sales'];

  const closingExpContent =
    closingExperienceMonths !== null && closingExperienceMonths !== 0 && !isCSM ? (
      <div className={styles.summaryItem}>
        <img
          className={styles.summaryIcon}
          src={require('../../../assets/closingExperience.svg')}
          alt=""
        />
        Closing experience
      </div>
    ) : null;

  const attributesContent = attributes.map((attr) => {
    const { camelKey, label } = attr;

    return otherProps[camelKey] ? (
      <div key={camelKey} className={styles.summaryItem}>
        <img
          className={styles.summaryIcon}
          src={require(`../../../assets/${camelKey}.svg`)}
          alt=""
        />
        {label}
      </div>
    ) : null;
  });

  const title = (
    <Content>
      <Bolder>Experience</Bolder>
    </Content>
  );

  const commonBlockProps = {
    addWhiteBG: true,
    title,
    tertiaryTitle: true,
    className: styles.marginTop,
  };

  return !recentGrad &&
    (attributes.some((attr) => otherProps[attr.camelKey]) || closingExpContent) ? (
    <Block {...commonBlockProps} data-testid="ProfileAttributes">
      <Content className={styles.flexRow}>
        {closingExpContent}
        {attributesContent}
      </Content>
    </Block>
  ) : null;
};

export default ComponentErrorBoundaryHOC()(ProfileAttributes);
