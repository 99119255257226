import React from 'react';
import { Helmet } from 'react-helmet-async';

import ReadyContainer from 'connectors/ReadyContainer';

import Block from 'components/Block';
import Footer from 'pages/CandidatesHome/components/Footer';

import styles from './PrivacyPolicy.scss';

const PrivacyPolicy = () => {
  const blockProps = {
    addWhiteBG: true,
  };

  const title = 'Rainmakers Privacy';
  const metaDescription = 'Rainmakers privacy policy for users';
  const canonicalUrl = `https://${process.env.HOST}/privacy/`;

  const helmetProps = {
    key: 'helmet',
    title,
    meta: [
      { name: 'description', content: metaDescription },
      { property: 'og:url', content: canonicalUrl },
      { property: 'og:title', content: title },
      { property: 'og:description', content: metaDescription },
    ],
    link: [{ rel: 'canonical', href: canonicalUrl }],
  };

  return (
    <div className={styles.PrivacyPolicy}>
      <Helmet {...helmetProps} />
      <ReadyContainer key="readyContainer" className={styles.PrivacyPolicy}>
        <Block {...blockProps}>
          <div className={styles.privacyText}>
            <h1 className={styles.privacyHeader}>Rainmakers Privacy Policy</h1>
            <div className={styles.privacyContent}>Last Updated: April 1, 2017</div>
            <div className={styles.firstPrivacyContent}>
              Rainmaker Labs, Inc. (&ldquo;<span className={styles.privacyBold}>Company</span>
              &rdquo;) is committed to protecting your privacy. We have prepared this Privacy Policy
              (the &ldquo;<span className={styles.privacyBold}>Policy</span>&rdquo;) to describe to
              you our practices regarding the Personal Data (as defined below) we collect from users
              of our website located at {`https://${process.env.HOST}`} (&ldquo;
              <span className={styles.privacyBold}>Site</span>&rdquo;) and online services (&ldquo;
              <span className={styles.privacyBold}>Services</span>&rdquo;).
            </div>
            <div className={styles.privacyContent}>
              <span className={styles.privacyBold}>
                1. Questions; Contacting Company; Reporting Violations.
              </span>
              If you have any questions or concerns or complaints about our Policy or our data
              collection or processing practices, or if you want to report any security violations
              to us, please contact us at the following address or phone number:
            </div>
            <div className={styles.privacyContentAddress}>
              Rainmaker Labs, Inc.
              <br />
              Attn: Michael Ferguson
              <br />
              1110 Montgomery St, San Francisco, CA 94133
            </div>
            <div className={styles.privacyContent}>
              <span className={styles.privacyBold}>2. User Consent.</span>
              By submitting Personal Data (as defined below) through our Site or Services, you agree
              to the terms of this Policy and you expressly consent to the collection, use and
              disclosure of your Personal Data in accordance with this Policy.
            </div>
            <div className={styles.privacyContent}>
              <span className={styles.privacyBold}>3. A Note About Children.</span>
              We do not intentionally gather Personal Data from visitors who are under the age of
              13. If a child under 13 submits Personal Data to Company and we learn that the
              Personal Data is the information of a child under 13, we will attempt to delete the
              information as soon as possible. If you believe that we might have any Personal Data
              from a child under 13, please contact us at{' '}
              <span className={styles.emailLink}>
                <a href="mailto:support@rainmakers.co">support@rainmakers.co</a>
              </span>
              .
            </div>
            <div className={styles.privacyContent}>
              <span className={styles.privacyBold}>
                4. A Note to Users Outside of the United States.
              </span>
              If you are a non U.S. user of the Site or Services, by providing us with data, you
              acknowledge and agree that your Personal Data may be processed for the purposes
              identified in the Policy. In addition, your Personal Data may be processed in the
              country in which it was collected and in other countries, including the United States,
              where laws regarding processing of Personal Data may be less stringent than the laws
              in your country. By providing your data, you consent to such transfer.
            </div>
            <div className={styles.privacyContent}>
              <span className={styles.privacyBold}>5. Types of Data We Collect.</span>
              <span className={styles.privacyBold}>&ldquo;Personal Data&rdquo;</span>
              means data that allows someone to identify or contact you, including, for example,
              your name, address, telephone number, e-mail address, as well as any other non-public
              information about you that is associated with or linked to any of the foregoing data.
              <span className={styles.privacyBold}>&ldquo;Anonymous Data&rdquo;</span>
              means data that is not associated with or linked to your Personal Data; Anonymous Data
              does not, by itself, permit the identification of individual persons. We collect
              Personal Data and Anonymous Data, as described below.
              <div className={styles.privacyContentIndented}>
                <span className={styles.privacyBold}>(a) Information You Provide to Us.</span>
                <ul>
                  <li>
                    We may collect Personal Data from you, such as your first and last name, e-mail
                    and mailing addresses, professional title, company name, and password when you
                    create an account to access the Services (“
                    <span className={styles.privacyBold}>Account</span>”).
                  </li>
                  <li>
                    For users of our Services who are Candidates (as defined in Rainmaker’s Terms of
                    Use, available at{' '}
                    <span className={styles.emailLink}>
                      <a
                        href={`https://${process.env.HOST}/terms`}
                      >{`https://${process.env.HOST}/terms`}</a>
                    </span>
                    ) we collect name, contact information, current resume, and such other
                    information as we may reasonably request, including employment history, work
                    experience, professional skills and certifications, compensation expectations,
                    and educational background.
                  </li>
                  <li>
                    If you use our Services on your mobile device, we may collect your phone number
                    and the unique device id number.
                  </li>
                  <li>
                    When you connect to our Services via a service provider that uniquely identifies
                    your mobile device, we may receive this identification and use it to offer
                    extended services and/or functionality.
                  </li>
                  <li>
                    Certain Services, such as two-factor authentication, may require our collection
                    of your phone number. We may associate that phone number to your mobile device
                    identification information.
                  </li>
                  <li>
                    We retain information on your behalf, such as files and messages that you store
                    using your Account.
                  </li>
                  <li>
                    If you provide us feedback or contact us via e-mail, we will collect your name
                    and e-mail address, as well as any other content included in the e-mail, in
                    order to send you a reply.
                  </li>
                  <li>
                    When you post content (text, images, photographs, messages, comments or any
                    other kind of content that is not your e-mail address) on our Site, the
                    information contained in your posting will be stored in our servers and other
                    users will be able to see it, along with your profile photo and any other
                    information that you choose to make public on your public profile page (“
                    <span className={styles.privacyBold}>Profile</span>”). The information that you
                    provide in your Profile will be visible to others, including anonymous visitors
                    to the Site.
                  </li>
                  <li>
                    When you post messages on the message boards of our Site, the information
                    contained in your posting will be stored on our servers and other users will be
                    able to see it.
                  </li>
                  <li>
                    When you participate in one of our surveys, we may collect additional profile
                    information.
                  </li>
                  <li>
                    We also collect other types of Personal Data that you provide to us voluntarily,
                    such as your operating system and version, product registration number, and
                    other requested information if you contact us via e-mail regarding support for
                    the Services.
                  </li>
                  <li>
                    If you participate in a sweepstakes, contest or giveaway on our Site, we may ask
                    you for your e-mail address and/or phone number (to notify you if you win or
                    not). We may also ask for first and last names, and sometimes post office
                    addresses to verify your identity. In some situations we may need additional
                    information as a part of the entry process, such as a prize selection choice.
                    These sweepstakes and contests are voluntary. We recommend that you read the
                    rules for each sweepstakes and contest that you enter.
                  </li>
                  <li>
                    We may also collect Personal Data at other points in our Site that state that
                    Personal Data is being collected.
                  </li>
                </ul>
              </div>
              <div className={styles.privacyContentIndented}>
                <span className={styles.privacyBold}>
                  (b) Information You Provide to LinkedIn and other Social Networking Sites.
                </span>
                The Services allow users to access LinkedIn so that Employers can contact Candidates
                directly. If a user is logged into the Services via LinkedIn or another Social
                Networking Service (“SNS”), when you click on “Connect with LinkedIn,” or a similar
                connection on another SNS, you will be prompted to merge your profiles. If you are
                already logged into the Services but not logged into LinkedIn or another SNS that we
                support, when you click on “Connect with LinkedIn,” or a similar connection on
                another SNS, you will be prompted to enter your SNS credentials or to “Sign Up” for
                the SNS. By proceeding, you are allowing the Services to access your information and
                you are agreeing to the LinkedIn or other SNS’ Terms of Use in your use of the
                Services. Conversely, if you are not currently registered as a user of the Services,
                and you click on “Sign in” using LinkedIn or another SNS that we support, you will
                first be asked to enter your LinkedIn or SNS credentials and then be given the
                option to register for the Services. In this case, we may receive information from
                LinkedIn or another SNS to make it easier for you to create an Account. Any
                information that we collect from your LinkedIn or other SNS account may depend on
                the privacy settings you have with that SNS, so please consult the SNS’s privacy and
                data practices.
              </div>
              <div className={styles.privacyContentIndented}>
                <span className={styles.privacyBold}>
                  (c) Information Collected via Technology.
                </span>
                <ul>
                  <li>
                    <span className={styles.privacyUnderline}>
                      Information Collected by Our Servers
                    </span>
                    . To make our Site and Services more useful to you, our servers (which may be
                    hosted by a third party service provider) collect information from you,
                    including your browser type, operating system, Internet Protocol (“IP”) address
                    (a number that is automatically assigned to your computer when you use the
                    Internet, which may vary from session to session), domain name, and/or a
                    date/time stamp for your visit.
                  </li>
                  <li>
                    <span className={styles.privacyUnderline}>Log Files</span>. As is true of most
                    websites, we gather certain information automatically and store it in log files.
                    This information includes IP addresses, browser type, Internet service provider
                    (“<span className={styles.privacyBold}>ISP</span>”), referring/exit pages,
                    operating system, date/time stamp, and clickstream data. We use this information
                    to analyze trends, administer the Site, track users’ movements around the Site,
                    gather demographic information about our user base as a whole, and better tailor
                    our Services to our users’ needs. For example, some of the information may be
                    collected so that when you visit the Site or the Services again, it will
                    recognize you and the information could then be used to serve advertisements and
                    other information appropriate to your interests. Except as noted in this Policy,
                    we do not link this automatically-collected data to Personal Data.
                  </li>
                  <li>
                    <span className={styles.privacyUnderline}>Cookies</span>. Like many online
                    services, we use cookies to collect information. “
                    <span className={styles.privacyBold}>Cookies</span>” are small pieces of
                    information that a website sends to your computer’s hard drive while you are
                    viewing the website. We may use both session Cookies (which expire once you
                    close your web browser) and persistent Cookies (which stay on your computer
                    until you delete them) to provide you with a more personal and interactive
                    experience on our Site. This type of information is collected to make the Site
                    more useful to you and to tailor the experience with us to meet your special
                    interests and needs.
                  </li>
                  <li>
                    <span className={styles.privacyUnderline}>Pixel Tags</span>. In addition, we use
                    “<span className={styles.privacyBold}>Pixel Tags</span>” (also referred to as
                    clear Gifs, Web beacons, or Web bugs). Pixel Tags are tiny graphic images with a
                    unique identifier, similar in function to Cookies, that are used to track online
                    movements of Web users. In contrast to Cookies, which are stored on a user’s
                    computer hard drive, Pixel Tags are embedded invisibly in Web pages. Pixel Tags
                    also allow us to send e-mail messages in a format users can read, and they tell
                    us whether e-mails have been opened to ensure that we are sending only messages
                    that are of interest to our users. We may use this information to reduce or
                    eliminate messages sent to a user. We do not tie the information gathered by
                    Pixel Tags to our users’ Personal Data.
                  </li>
                  <li>
                    <span className={styles.privacyUnderline}>
                      Collection of Data by Advertisers
                    </span>
                    . We may also use third parties to serve ads or promotions on the Site. Certain
                    third parties may automatically collect information about your visits to this
                    and other websites, your IP address, your ISP, the browser you use to visit our
                    Site (but not your name, address, e-mail address or telephone number). They do
                    this by using Cookies, Pixel Tags, or other technologies. Information collected
                    may be used, among other things, to deliver advertising targeted to your
                    interests and to better understand the usage and visitation of our Site and the
                    other sites tracked by these third parties. This policy does not apply to, and
                    we are not responsible for, Cookies or Pixel Tags in third party ads, and we
                    encourage you to check the privacy policies of advertisers and/or ad services to
                    learn about their use of Cookies and other technologies. If you would like more
                    information about this practice and to know your choices about not having this
                    information used by these companies, click here:{' '}
                    <span className={styles.emailLink}>
                      <a href="http://www.aboutads.info/choices">
                        http://www.aboutads.info/choices
                      </a>
                    </span>{' '}
                    or{' '}
                    <span className={styles.emailLink}>
                      <a href="http://www.networkadvertising.org">
                        http://www.networkadvertising.org
                      </a>
                    </span>
                    .
                  </li>
                  <li>
                    <span className={styles.privacyUnderline}>
                      How We Respond to Do Not Track Signals
                    </span>
                    . We do not currently respond to “do not track” signals or other mechanisms that
                    might enable Users to opt out of tracking on our site.
                  </li>
                  <li>
                    <span className={styles.privacyUnderline}>Flash LSOs</span>. When we post
                    videos, third parties may use local shared objects, known as “
                    <span className={styles.privacyBold}>Flash Cookies</span>,” to store your
                    preferences for volume control or to personalize certain video features. Flash
                    Cookies are different from browser Cookies because of the amount and type of
                    data and how the data is stored. Cookie management tools provided by your
                    browser will not remove Flash Cookies. To learn how to manage privacy and
                    storage settings for Flash Cookies, click here:{' '}
                    <span className={styles.emailLink}>
                      <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html">
                        http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html
                      </a>
                    </span>
                    .
                  </li>
                  <li>
                    <span className={styles.privacyUnderline}>
                      Analytics Services and Interest-Based Advertising
                    </span>
                    . In addition to the tracking technologies we place, other companies may set
                    their own cookies or similar tools when you visit our Site. This includes third
                    party analytics services (“
                    <span className={styles.privacyBold}>Analytics Services</span>”) that we engage
                    to help analyze how users use the Site, as well as third parties that deliver
                    content or offers. We may receive reports based on these parties’ use of these
                    tools on an individual or aggregate basis. We use the information we get from
                    Analytics Services only to improve our Site and Services. The information
                    generated by the Cookies or other technologies about your use of our Site and
                    Services (the “<span className={styles.privacyBold}>Analytics Information</span>
                    ”) is transmitted to the Analytics Services. The Analytics Services use
                    Analytics Information to compile reports on user activity. The Analytics
                    Services may also transfer information to third parties where required to do so
                    by law, or where such third parties process Analytics Information on their
                    behalf. Each Analytics Services’ ability to use and share Analytics Information
                    is restricted by such Analytics Services’ Terms of Use and Policy. By using our
                    Site and Services, you consent to the processing of data about you by Analytics
                    Services in the manner and for the purposes set out above. For a full list of
                    Analytics Services, please contact us at{' '}
                    <span className={styles.emailLink}>
                      <a href="mailto:support@rainmakers.co">support@rainmakers.co</a>
                    </span>
                    . We may also partner with ad companies to support our marketing efforts,
                    including by serving you ads better tailored to your likely interests. If you
                    don’t want to take advantage of these services, you may be able to opt-out by
                    visiting{' '}
                    <span className={styles.emailLink}>
                      <a href="http://www.aboutads.info/">http://www.aboutads.info/</a>
                    </span>{' '}
                    or{' '}
                    <span className={styles.emailLink}>
                      <a href="http://networkadvertising.org/choices/">
                        http://networkadvertising.org/choices/
                      </a>
                    </span>{' '}
                    , or if you are located in the EU,{' '}
                    <span className={styles.emailLink}>
                      <a href="http://www.youronlinechoices.eu/">
                        http://www.youronlinechoices.eu/
                      </a>
                    </span>
                    .
                  </li>
                </ul>
              </div>
              <div className={styles.privacyContentIndented}>
                <span className={styles.privacyBold}>
                  (d) Information Collected from You About Others.
                </span>
                If you decide to invite a third party to create an Account, we will collect your and
                the third party’s names and e-mail addresses in order to send an e-mail and follow
                up with the third party. We rely upon you to obtain whatever consents from the third
                party that may be required by law to allow us to access and upload the third party’s
                names and e-mail addresses as required above. You or the third party may contact us
                at{' '}
                <span className={styles.emailLink}>
                  <a href="mailto:support@rainmakers.co">support@rainmakers.co</a>
                </span>{' '}
                to request the removal of this information from our database. As part of your use of
                the Services, we provide you the opportunity to connect automatically with others
                you may know, including by offering contact importer tools to facilitate adding to
                your contacts so that you can more readily ask your contacts to join and communicate
                with you through the Services. With your permission, we may access your address
                book, call log and SMS log, and import your contacts’ names, e-mail addresses, phone
                numbers, image, geographic location and Facebook IDs to facilitate automatic
                connection with your friends. Also, when you invite users to the Services, we will
                access your Address Book and we will import your contacts names and phone numbers in
                order to facilitate the invitation. The e-mail that is sent to these users may from
                your e-mail address so that they know that you want to invite them to visit the
                Site. We do not store any passwords you provide as part of the contact import
                process. We also collect some settings that help us to interpret your data, such as
                the language and keyboard settings that you have established, and the phone’s
                carrier, mobile network code and mobile country code.
              </div>
              <div className={styles.privacyContentIndented}>
                <span className={styles.privacyBold}>
                  (e) Information Collected from Third Party Companies.
                </span>
                We may receive Personal and/or Anonymous Data about you from companies that provide
                our Services by way of a co-branded or private-labeled website or companies that
                offer their products and/or services on our Site. These third party companies may
                supply us with Personal Data. We may add this information to the information we have
                already collected from you via our Site in order to improve the Services we provide.
              </div>
            </div>
            <div className={styles.privacyContent}>
              <span className={styles.privacyBold}>6. Use of Your Personal Data</span>
              <div className={styles.privacyContentIndented}>
                <span className={styles.privacyBold}>(a) General Use.</span>
                In general, Personal Data you submit to us is used either to respond to requests
                that you make, or to aid us in serving you better. We use your Personal Data to:
                <ul>
                  <li>facilitate the creation of and secure your Account;</li>
                  <li>identify you as a user of the Services;</li>
                  <li>provide improved administration of our Site and Services;</li>
                  <li>provide the Services you request;</li>
                  <li>
                    improve the quality of experience when you interact with our Site and Services;
                  </li>
                  <li>
                    send you a welcome e-mail to verify ownership of the e-mail address provided
                    when your Account was created;
                  </li>
                  <li>
                    send you administrative e-mail notifications, such as security or support and
                    maintenance advisories;
                  </li>
                  <li>
                    respond to your inquiries related to employment opportunities or other requests;
                  </li>
                  <li>
                    make telephone calls to you, from time to time, as a part of secondary fraud
                    protection or to solicit your feedback; and
                  </li>
                  <li>
                    send newsletters, surveys, offers, and other promotional materials related to
                    our Services and for other marketing purposes.
                  </li>
                </ul>
              </div>
              <div className={styles.privacyContentIndented}>
                <span className={styles.privacyBold}>(b) User Testimonials and Feedback.</span>
                We often receive testimonials and comments from users who have had positive
                experiences with our Services. We occasionally publish such content. When we publish
                this content, we may identify our users by their first and last name and may also
                indicate their home city. We obtain the user’s consent prior to posting his or her
                name along with the testimonial. We may post user feedback on the Site from time to
                time. We will share your feedback with your first name and last initial only. If we
                choose to post your first and last name along with your feedback, we will obtain
                your consent prior to posting you name with your feedback. If you make any comments
                on a blog or forum associated with your Site, you should be aware that any Personal
                Data you submit there can be read, collected, or used by other users of these
                forums, and could be used to send you unsolicited messages. We are not responsible
                for the Personal Data you choose to include in these blogs and forums.
              </div>
              <div className={styles.privacyContentIndented}>
                <span className={styles.privacyBold}>(c) Creation of Anonymous Data.</span>
                We may create Anonymous Data records from Personal Data by excluding information
                (such as your name) that makes the data personally identifiable to you. We use this
                Anonymous Data to analyze request and usage patterns so that we may enhance the
                content of our Services and improve Site navigation. We reserve the right to use
                Anonymous Data and aggregated and other de-identified information for any purpose
                and disclose Anonymous Data to third parties in our sole discretion.
              </div>
            </div>
            <div className={styles.privacyContent}>
              <span className={styles.privacyBold}>7. Disclosure of Your Personal Data.</span>
              We disclose your Personal Data as described below and as described elsewhere in this
              Policy.
              <div className={styles.privacyContentIndented}>
                <span className={styles.privacyBold}>(a) Third Parties Designated by You.</span>
                When you use the Services, the Personal Data you provide will be shared with the
                third parties that you designate to receive such information, including other
                websites, your friends, relatives and business associates. Depending on the type of
                access you grant to such third parties, they may also be permitted to edit the
                information you have provided to us and to designate others to access and edit such
                information. You may change your settings at any time as to who has access to your
                information by going to your account settings and changing your publishing options.
              </div>
              <div className={styles.privacyContentIndented}>
                <span className={styles.privacyBold}>(b) Users.</span>
                We will share your Personal Data with other Users solely for the purpose of
                providing the Services.
              </div>
              <div className={styles.privacyContentIndented}>
                <span className={styles.privacyBold}>(c) Third Party Service Providers.</span>
                We may share your Personal Data with third party service providers to: provide you
                with the Services; to conduct quality assurance testing; to facilitate creation of
                accounts; to provide technical support; and/or to provide other services to the
                Company. These third party service providers are required not to use your Personal
                Data other than to provide the services requested by Company.
              </div>
              <div className={styles.privacyContentIndented}>
                <span className={styles.privacyBold}>(d) Affiliates.</span>
                We may share some or all of your Personal Data with our parent company,
                subsidiaries, joint ventures, or other companies under a common control (“
                <span className={styles.privacyBold}>Affiliates</span>”), in which case we will
                require our Affiliates to honor this Policy.
              </div>
              <div className={styles.privacyContentIndented}>
                <span className={styles.privacyBold}>(e) Corporate Restructuring.</span>
                We may share some or all of your Personal Data in connection with or during
                negotiation of any merger, financing, acquisition or dissolution transaction or
                proceeding involving sale, transfer, divestiture, or disclosure of all or a portion
                of our business or assets. In the event of an insolvency, bankruptcy, or
                receivership, Personal Data may also be transferred as a business asset. If another
                company acquires our company, business, or assets, that company will possess the
                Personal Data collected by us and will assume the rights and obligations regarding
                your Personal Data as described in this Policy.
              </div>
              <div className={styles.privacyContentIndented}>
                <span className={styles.privacyBold}>(f) Social Networking Sites.</span>
                Some of our Applications and Services may enable you to post content to SNSs (e.g.,
                LinkedIn). If you choose to do this, we will provide information to such SNSs in
                accordance with your elections. You acknowledge and agree that you are solely
                responsible for your use of SNSs and that it is your responsibility to review the
                terms of use and privacy policy of the third party provider of such SNSs. We will
                not be responsible or liable for: (i) the availability or accuracy of such SNSs;
                (ii) the content, products or services on or availability of such SNSs; or (iii)
                your use of any such SNSs.
              </div>
              <div className={styles.privacyContentIndented}>
                <span className={styles.privacyBold}>(g) Disclosure to Third Party Companies.</span>
                We may enter into agreements with companies that provide our Services by way of a
                co-branded or private-labeled website or companies that offer their products and/or
                services on our website (“
                <span className={styles.privacyBold}>Third Party Companies</span>”). A Third Party
                Company may want access to Personal Data that we collect from its customers. As a
                result, we may disclose your Personal Data to a Third Party Company; however, we
                will not disclose your Personal Data to any Third Party Company for the Third Party
                Company’s own direct marketing purposes, unless you have “opted-in” by following the
                instructions we provide to allow such disclosure. If you have opted-in to receive
                e-mail communications from a Third Party Company and later wish to discontinue
                receipt of these e-mails, please contact the Third Party Company directly to update
                your preferences. The privacy policies of these Third Party Companies may apply to
                the use and disclosure of your Personal Data that we collect and disclose to such
                Third Party Companies. Because we do not control the privacy practices of Third
                Party Companies, you should read and understand their privacy policies.
              </div>
              <div className={styles.privacyContentIndented}>
                <span className={styles.privacyBold}>(h) Public Profile.</span>
                Certain portions of the information you provide to us may also be displayed in your
                Profile. As an essential element of the Services, most of the Personal Data you
                explicitly provide to us when you register or update your Profile is displayed on
                your Profile. In order for your Profile to be made public, you must go to your
                profile settings and then to profile visibility. By default, your Profile is not for
                public viewing. Any other content you post to the Site is also meant for public
                consumption. We may display this content on the Site and further distribute it to a
                wider audience through third party sites and services. Once displayed on publicly
                viewable web pages, that information can be collected and used by others. We cannot
                control who reads your postings or what other users may do with the information that
                you voluntarily post, so it is very important that you do not put Personal Data in
                your posts. Once you have posted information publicly, while you will still be able
                to edit and delete it on the Site, you will not be able to edit or delete such
                information cached, collected, and stored elsewhere by others (e.g., search
                engines).
              </div>
              <div className={styles.privacyContentIndented}>
                <span className={styles.privacyBold}>(i) Other Disclosures.</span>
                Regardless of any choices you make regarding your Personal Data (as described
                below), Company may disclose Personal Data if it believes in good faith that such
                disclosure is necessary (a) in connection with any legal investigation; (b) to
                comply with relevant laws or to respond to subpoenas or warrants served on Company;
                (c) to protect or defend the rights or property of Company or users of the Site or
                Services; and/or (d) to investigate or assist in preventing any violation or
                potential violation of the law, this Policy, or our Terms of Use.
              </div>
            </div>
            <div className={styles.privacyContent}>
              <span className={styles.privacyBold}>8. Third Party Websites.</span>
              Our Site may contain links to third party websites. When you click on a link to any
              other website or location, you will leave our Site and go to another site, and another
              entity may collect Personal Data or Anonymous Data from you. We have no control over,
              do not review, and cannot be responsible for, these outside websites or their content.
              This Policy does not apply to these outside websites or content, or to any collection
              of your Personal Data after you click on links to such outside websites. We encourage
              you to read the privacy policies of every website you visit. The links to third party
              websites or locations are for your convenience and do not signify our endorsement of
              such third parties or their products, content or websites.
            </div>
            <div className={styles.privacyContent}>
              <span className={styles.privacyBold}>9. Your Choices Regarding Information.</span>
              You have several choices regarding the use of information on our Service:
              <div className={styles.privacyContentIndented}>
                <span className={styles.privacyBold}>(a) Email Communications.</span>
                We will periodically send you free newsletters and e-mails that directly promote the
                use of our Site or Services. When you receive newsletters or promotional
                communications from us, you may indicate a preference to stop receiving further
                communications from us and you will have the opportunity to “opt-out” by following
                the unsubscribe instructions provided in the e-mail you receive or by contacting us
                directly (please see contact information below). Despite your indicated e-mail
                preferences, we may send you service related communications, including notices of
                any updates to our Terms of Use or Policy.
              </div>
              <div className={styles.privacyContentIndented}>
                <span className={styles.privacyBold}>(b) Cookies.</span>
                If you decide at any time that you no longer wish to accept Cookies from our Service
                for any of the purposes described above, then you can instruct your browser, by
                changing its settings, to stop accepting Cookies or to prompt you before accepting a
                Cookie from the websites you visit. Consult your browser’s technical information. If
                you do not accept Cookies, however, you may not be able to use all portions of the
                Service or all functionality of the Service. If you have any questions about how to
                disable or modify Cookies, please let us know at the contact information provided
                below.
              </div>
              <div className={styles.privacyContentIndented}>
                <span className={styles.privacyBold}>(c) De-Linking SNS.</span>
                If you decide at any time that you no longer wish to have your SNS account (e.g.,
                LinkedIn) linked to your Account, then you may de-link the SNS account in the
                “preferences” section in your account settings.
              </div>
              <div className={styles.privacyContentIndented}>
                <span className={styles.privacyBold}>
                  (d) Changing or Deleting Your Personal Data.
                </span>
                All users may review, update, correct or delete the Personal Data in their Account
                (including any imported contacts) by editing their profile via the Services. If you
                completely delete all of your Personal Data, then your Account may become
                deactivated. We will use commercially reasonable efforts to honor your request. We
                may retain an archived copy of your records as required by law or for legitimate
                business purposes.
              </div>
            </div>
            <div className={styles.privacyContent}>
              <span className={styles.privacyBold}>10. Changes to This Privacy Policy.</span>
              This Policy is subject to occasional revision, and if we make any material changes in
              the way we use your Personal Data, we will notify you by sending you an e-mail to the
              last e-mail address you provided to us and/or by prominently posting notice of the
              changes on our Site. Any changes to this Policy will be effective upon the earlier of
              thirty (30) calendar days following our dispatch of an e-mail notice to you or thirty
              (30) calendar days following our posting of notice of the changes on our Site. These
              changes will be effective immediately for new users of our Service. Please note that
              at all times you are responsible for updating your Personal Data to provide us with
              your most current e-mail address. In the event that the last e-mail address that you
              have provided us is not valid, or for any reason is not capable of delivering to you
              the notice described above, our dispatch of the e-mail containing such notice will
              nonetheless constitute effective notice of the changes described in the notice. If you
              do not wish to permit changes in our use of your Personal Data, you must notify us
              prior to the effective date of the changes that you wish to deactivate your Account
              with us. Continued use of our Site or Service, following notice of such changes shall
              indicate your acknowledgement of such changes and agreement to be bound by the terms
              and conditions of such change.
            </div>
            <div className={styles.firstPrivacyContent}>
              If you have any questions about this Policy or our practices regarding Personal Data,
              please contact us at{' '}
              <span className={styles.emailLink}>
                <a href="mailto:support@rainmakers.co">support@rainmakers.co</a>
              </span>
              .
            </div>
          </div>
        </Block>
        <Footer />
      </ReadyContainer>
    </div>
  );
};

export default PrivacyPolicy;
