import React, { Component } from 'react';

import CandidateWrapper from 'pages/EmployeePortal/CandidateProfilePages/CandidateWrapper';

import styles from '../../ToolboxRequisitionEditor.scss';
import SuggestionsTable from './components/SuggestionsTable';

class EmployerEditorReqSuggestions extends Component {
  constructor(props) {
    super(props);

    const {
      match: {
        params: { id },
      },
    } = props;

    this.state = {
      'selectedCandidateId': '',
      'filter[simulated_employer_id]': null,
      'filter[requisition_opening_id]': id,
      'filter[category]': null,
      'filter[cohort_id]': null,
      'filter[application_status_updated_at]': null,
    };

    this.defaultLoadParams = {
      'includeSet': 'search',
      'page': 1,
      'filter[simulated_employer_id]': null,
      'filter[requisition_opening_id]': id,
      'filter[category]': null,
      'filter[cohort_id]': null,
    };

    this.defaultLoadArgs = {
      slice: 'toolboxRequisitions',
    };
  }

  componentDidMount() {
    const {
      actions: { entityLoadStarted },
    } = this.props;

    entityLoadStarted({
      ...this.defaultLoadArgs,
      type: 'search',
      irregularType: 'candidates',
      typeOverride: 'suggestions',
      params: this.getLoadParams(),
    });

    const date = new Date();

    date.setMonth(date.getMonth() - 3);

    entityLoadStarted({
      ...this.defaultLoadArgs,
      type: 'cohorts',
      params: {
        category: 'search',
        sortBy: 'start_date_desc',
        startDate: `gte:${date.toISOString().substr(0, 10)}`,
        state: 'complete',
        virtual: false,
      },
    });
  }

  componentWillUnmount() {
    const {
      actions: { entityLoadDone },
    } = this.props;

    entityLoadDone({
      data: {
        entities: null,
        meta: {},
      },
      slice: 'toolboxRequisitions',
      type: 'suggestions',
    });
  }

  getLoadParams = () => {
    const { suggestionsPage: page = 1 } = this.props;
    const {
      'filter[category]': category,
      'filter[simulated_employer_id]': simulatedEmployerId,
      'filter[requisition_opening_id]': requisitionOpeningId,
      'filter[cohort_id]': cohortId,
      'filter[application_status_updated_at]': applicationStatusUpdatedAt,
    } = this.state;

    return {
      ...this.defaultLoadParams,
      page,
      'filter[category]': category,
      'filter[simulated_employer_id]': simulatedEmployerId,
      'filter[requisition_opening_id]': requisitionOpeningId,
      'filter[cohort_id]': cohortId,
      'filter[application_status_updated_at]': applicationStatusUpdatedAt
        ? [`gte:${applicationStatusUpdatedAt.toISOString().substr(0, 10)}`]
        : null,
    };
  };

  handleToggleArray = ({ name, value: { cohortId: value } }) => {
    const { [name]: currentValue } = this.state;
    const {
      actions: { entityLoadStarted },
    } = this.props;

    this.setState({ [name]: value === currentValue ? null : value }, () =>
      entityLoadStarted({
        ...this.defaultLoadArgs,
        type: 'search',
        irregularType: 'candidates',
        typeOverride: 'suggestions',
        params: {
          ...this.getLoadParams(),
          page: 1,
        },
      })
    );
  };

  handleSetDate = (day) => {
    const {
      actions: { entityLoadStarted },
    } = this.props;

    this.setState({ 'filter[application_status_updated_at]': day }, () =>
      entityLoadStarted({
        ...this.defaultLoadArgs,
        type: 'search',
        irregularType: 'candidates',
        typeOverride: 'suggestions',
        params: {
          ...this.getLoadParams(),
          page: 1,
        },
      })
    );
  };

  handleSetSimulatedEmployerId = (event) => {
    const {
      target: { checked },
    } = event;

    const {
      actions: { entityLoadStarted },
      requisitionOpening: { attributes: { employerId } = {} } = {},
    } = this.props;

    this.setState(
      {
        'filter[simulated_employer_id]': checked ? employerId : null,
        'filter[category]': checked ? 'search' : null,
      },
      () =>
        entityLoadStarted({
          ...this.defaultLoadArgs,
          type: 'search',
          irregularType: 'candidates',
          typeOverride: 'suggestions',
          params: {
            ...this.getLoadParams(),
            page: 1,
          },
        })
    );
  };

  loadMoreSuggestions = () => {
    const {
      actions: { entityLoadStarted },
      suggestionsPage: page,
    } = this.props;

    entityLoadStarted({
      ...this.defaultLoadArgs,
      type: 'search',
      irregularType: 'candidates',
      typeOverride: 'suggestions',
      params: {
        ...this.getLoadParams(),
        page: page + 1,
      },
    });
  };

  openProfileModal = (candidate) => {
    const {
      actions: { showModal },
      location: { pathname },
      modal: { originalUrl },
    } = this.props;

    this.setState({ selectedCandidateId: candidate.id });

    showModal({
      key: 'CandidateProfileModal',
      parent: 'ToolboxRequisitionEditorPage',
      originalUrl,
      backPath: pathname,
      backKey: 'RequisitionEditorModal',
      closeHook: () => this.setState({ selectedCandidateId: null }),
      silentClose: true,
    });
  };

  render() {
    const {
      selectedCandidateId,
      'filter[simulated_employer_id]': simulatedEmployerId,
      'filter[cohort_id]': cohortId,
      'filter[application_status_updated_at]': applicationStatusUpdatedAt,
    } = this.state;

    const {
      cohorts: { byId: cohortsById = {}, allIds: cohortsAllIds = [] } = {},
      location,
      modal,
      match: {
        params: { id },
      },
      suggestionsLoading: isLoading,
      suggestionsPage: currentPage,
      suggestionsTotal: totalCount,
      suggestions: { byId = {}, allIds = [] } = {},
    } = this.props;

    const candidates = allIds.map((id) => {
      const { [id]: candidate } = byId;

      return candidate;
    });

    const cohorts = cohortsAllIds.map((id) => {
      const { [id]: { attributes } = {} } = cohortsById;

      return attributes;
    });

    const suggestionsTableProps = {
      applicationStatusUpdatedAt,
      candidates,
      cohorts,
      cohortId,
      currentPage,
      handleSetDate: this.handleSetDate,
      handleSetSimulatedEmployerId: this.handleSetSimulatedEmployerId,
      handleToggleArray: this.handleToggleArray,
      isLoading,
      loadMoreSuggestions: this.loadMoreSuggestions,
      openProfileModal: this.openProfileModal,
      requisitionOpeningId: id,
      simulatedEmployerId,
      totalCount,
    };

    const candidateProfileProps = {
      location,
      modal,
      candidateProfileReqs: [],
      candidate: {},
      key: 'candidateProfile',
      match: {
        params: {
          id: selectedCandidateId,
          view: 'profile',
        },
      },
    };

    const candidateModal = selectedCandidateId ? (
      <CandidateWrapper {...candidateProfileProps} />
    ) : null;

    return (
      <div className={styles.ReqForm}>
        <SuggestionsTable {...suggestionsTableProps} />
        {candidateModal}
      </div>
    );
  }
}

export default EmployerEditorReqSuggestions;
