import React from 'react';

import DropdownSelectMany from 'components/DropdownSelectMany';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import Table from 'components/Table';

import SearchResult from 'pages/EmployeePortal/CandidateSearchPage/components/SearchResult';

import styles from '../../../../ToolboxRequisitionEditor.scss';

const RequisitionCandidatesTable = ({
  candidates,
  currentPage,
  handleSearchFilterChange,
  isLoading,
  loadMore,
  openProfileModal,
  requisitionOpeningId,
  state,
  totalCount,
}) => {
  const tableContent = candidates.map((candidate, idx) => {
    const props = {
      searchString: `filter[requisition_opening_id]=${requisitionOpeningId}`,
      candidate: {
        ...candidate.attributes,
      },
      handleOnClick: openProfileModal,
      isAdmin: true,
      index: idx - (currentPage - 1) * 20,
    };

    return <SearchResult key={candidate.id} {...props} />;
  });

  const infiniteScrollProps = {
    pageStart: 0,
    loadMore,
    hasMore: candidates.length > 0 && totalCount > candidates.length,
    loader: <InfiniteScrollLoader key={0} />,
    useWindow: false,
    className: styles.Table,
  };

  const stateOptions = [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Hired',
      value: 'hired',
    },
    {
      label: 'Passed',
      value: 'passed',
    },
    {
      label: 'Candidate Pass Pending',
      value: 'candidate_pass_pending',
    },
    {
      label: 'Candidate Passed',
      value: 'candidate_passed',
    },
    {
      label: 'Employer Passed',
      value: 'employer_passed',
    },
    {
      label: 'Inactive',
      value: 'inactive',
    },
  ];

  const stateSource = stateOptions.map((state) => ({
    indent: 0,
    label: state.label,
    optionType: 'role',
    subCategories: [],
    value: {
      type: 'state',
      state: state.value,
    },
  }));

  const selectedStates = state.map((state) => ({ state, type: 'state' }));

  const stateFilterProps = {
    className: styles.searchBarFilter,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    source: stateSource,
    name: 'state',
    key: 'state',
    optionsLarge: true,
    isLocation: true,
    onChange: (value) =>
      handleSearchFilterChange({
        target: {
          subCategories: value.subCategories,
          label: value.label,
          value: value.value,
          name: 'state',
          optionType: value.optionType,
        },
      }),
    selectedValues: selectedStates,
    defaultLabel: 'Click to select states',
  };

  const titleContent = (
    <div className={styles.searchBar}>
      <DropdownSelectMany {...stateFilterProps} />
    </div>
  );

  const tableProps = {
    titleContent,
    tableContent,
    header: null,
    infiniteScrollProps,
    tableName: 'adminRequisitionOpeningRequisitionCandidates',
    isEmpty: !isLoading && candidates.length === 0,
    emptyTextString: 'No Candidates',
    isLoading,
  };

  return <Table {...tableProps} />;
};

export default RequisitionCandidatesTable;
