import { getResource } from 'api/apiResource';

const promiseGetVimeoVideo = ({ id }) => {
  const onFulfilled = (response) => {
    const { data: { entities: { vimeoVideo: { byId = {}, allIds = [] } = {} } = {} } = {} } =
      response;

    return byId[allIds[0]] || {};
  };

  const args = {
    type: 'vimeo_videos',
    id,
    config: {
      params: {
        normalizeIt: true,
      },
    },
  };

  return getResource(args).then(onFulfilled);
};

export default promiseGetVimeoVideo;
