import React from 'react';
import classnames from 'classnames';

import FontIcon from 'components/FontIcon';

import styles from './ProgressItem.scss';

const ProgressItem = ({ last, order, filled, label, current }) => {
  const iconClasses = classnames({
    [styles.openIcon]: !filled && !current,
    [styles.currentIcon]: current,
  });

  const icon = filled ? (
    <FontIcon iconName="checkmark-circle" />
  ) : (
    <div className={iconClasses}>{order + 1}</div>
  );

  const progItemClasses = classnames({
    [styles.regularItem]: !last,
    [styles.filledItem]: filled,
    [styles.openItem]: !filled && !current,
    [styles.currentItem]: current,
    [styles.lastItem]: last,
  });

  const labelContent = <span className={styles.label}>{label}</span>;

  return (
    <div className={progItemClasses}>
      {icon}
      {labelContent}
    </div>
  );
};

export default ProgressItem;
