import React from 'react';
import classnames from 'classnames';

import { createCompany } from 'reduxStore/schema';

import Table from 'components/Table';
import TableRow from 'components/Table/components/TableRow';
import TableCell from 'components/Table/components/TableCell';
import CompanyData from 'components/CompanyData';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import Button from 'components/Button';
import FontIcon from 'components/FontIcon';

import ToolboxCompanyActionDropdown from '../ToolboxCompanyActionDropdown';
import ToolboxCompaniesSearchBar from '../ToolboxCompaniesSearchBar';

import styles from './ToolboxCompaniesTable.scss';

const ToolboxCompaniesTable = ({
  actions,
  actions: { resourceUpdate, showModal },
  companies = [],
  handleSearchInputChange,
  handleSearchCheckboxChange,
  isLoading,
  loadMore,
  name,
  total,
  unverifiedDataState,
  unverifiedCompanyData: { byId: unverifiedDataById = {} } = {},
}) => {
  const tableHeader = [
    {
      content: 'Name',
      headerClassName: styles.nameHeader,
    },
    {
      content: 'Verification Status',
      headerClassName: styles.statusHeader,
    },
    {
      content: '',
      headerClassName: styles.candActionHeader,
    },
  ];

  const openProfileModal = (id) =>
    showModal({
      key: 'CompanyEditorModal',
      parent: 'ToolboxCompaniesPage',
      route: `/toolbox/company/${id}/edit-profile`,
    });

  const makeCompanyCells = (company = {}) => {
    const {
      id,
      relationships: { unverifiedCompanyData: { data: unverifiedDataIds = [] } = {} } = {},
    } = company;

    const unverifiedCompanyData = unverifiedDataById[unverifiedDataIds[0]] || {};

    const verified = unverifiedDataIds[0]
      ? !unverifiedDataById[unverifiedDataIds[0]]
      : !unverifiedDataIds[0];

    const { attributes: { state } = {} } = unverifiedCompanyData || {};

    const statusClassnames = classnames({
      [styles.verifiedStatus]: verified,
      [styles.unverifiedStatus]: !verified,
    });

    const verifiedStatusContent = verified ? (
      <div className={statusClassnames}>
        <FontIcon iconName="checkmark-circle" />
      </div>
    ) : null;

    const unverifiedStatusContent =
      unverifiedDataIds[0] && state === 'unverified_employer' ? (
        <div className={statusClassnames}>
          <div className={styles.iconExplode}>!</div>
        </div>
      ) : (
        <div className={statusClassnames}>
          <div className={styles.iconDanger}>!</div>
        </div>
      );

    const statusContent = verifiedStatusContent || unverifiedStatusContent;

    const actionsProps = {
      actions,
      company,
      unverifiedData: unverifiedCompanyData,
    };

    const actionsContent = !verified ? (
      <div className={styles.actionsContent}>
        <ToolboxCompanyActionDropdown {...actionsProps} />
      </div>
    ) : (
      <div className={styles.actionsContent} />
    );

    const companyDataProps = {
      employer: {
        ...company,
        attributes: {
          ...company.attributes,
          unverifiedCompanyData,
        },
      },
      onClick: () => openProfileModal(id),
    };

    const companyDataContent = (
      <div className={styles.companyContent}>
        <CompanyData {...companyDataProps} />
      </div>
    );

    const cells = [
      <TableCell key={1}>{companyDataContent}</TableCell>,
      <TableCell key={2}>{statusContent}</TableCell>,
      <TableCell noFlex key={3}>
        {actionsContent}
      </TableCell>,
    ];

    return cells;
  };

  const makeCompanyRows = (company) => (
    <TableRow id={company.id} key={company.id}>
      {makeCompanyCells(company)}
    </TableRow>
  );

  const tableContent = companies.filter((comp) => !comp.attributes.temporary).map(makeCompanyRows);

  const searchBarProps = {
    handleSearchInputChange,
    handleSearchCheckboxChange,
    name,
    unverifiedDataState,
  };

  const createCompanyClick = () => {
    const resource = createCompany();

    resourceUpdate({
      slice: 'toolboxCompanies',
      type: 'companies',
      id: resource.id,
      resource,
    });

    showModal({
      key: 'CompanyEditorModal',
      parent: 'ToolboxCompaniesPage',
      route: `/toolbox/company/${resource.id}/edit-profile`,
    });
  };

  const createProps = {
    onClick: createCompanyClick,
    primary: true,
  };

  const disclaimer =
    unverifiedDataState &&
    (unverifiedDataState.includes('unverified') ||
      unverifiedDataState.includes('unverified_employer')) ? (
      <div className={styles.companyBottom}>
        The logos shown on this table are suggestions and will not be displayed to users until
        verified. If the image looks correct, please select the verify option from the dropdown. If
        not, please click the company name, reject the suggestion, and manually update the logo and
        any additional data.
      </div>
    ) : null;

  const filtersContent = (
    <div className={styles.companyHeader}>
      <div className={styles.candidateHeaderLeft}>
        <ToolboxCompaniesSearchBar {...searchBarProps} />
        {disclaimer}
      </div>
      <Button {...createProps}>+ Company</Button>
    </div>
  );

  const infiniteScrollProps = {
    loadMore,
    hasMore: total > companies.length,
    loader: isLoading ? (
      <div key="infiniteScrollLoader" />
    ) : (
      <InfiniteScrollLoader key="infiniteScrollLoader" />
    ),
    useWindow: false,
  };

  const tableProps = {
    titleContent: filtersContent,
    tableContent,
    header: tableHeader,
    infiniteScrollProps,
    tableName: 'adminCompanies',
    isEmpty: !isLoading && companies.length === 0,
    emptyTextString: 'No Companies',
    isLoading,
  };

  return <Table {...tableProps} />;
};

export default ToolboxCompaniesTable;
