/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import jobsData, {
  JOB_PAGE_SDR,
  JOB_PAGE_AE,
  JOB_PAGE_EAE,
  JOB_PAGE_CSM,
  JOB_PAGE_MMAE,
  JOB_PAGE_VP,
  JOB_PAGE_SM,
  JOB_PAGE_SD,
} from 'pages/JobsPage/jobsPageMetaData';

import pic1 from './assets/1-tech-sales-jobs.png';
import pic2 from './assets/2-contact-rainmakers-to-find-tech-sales-jobs.png';
import pic3 from './assets/3-tech-sales-career.png';
import pic4 from './assets/4-create-profile-to-browse-jobs.png';

import styles from './MainContent.scss';

export function MainContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt="" />

      <h2>Amazing Sales Career Opportunities Are Waiting For You</h2>

      <p>You could land your dream sales job today!</p>
      <p>
        Rainmakers connects high-performing salespeople with awesome technology companies. If you
        take your career as a salesperson seriously, Rainmakers is the place to be.
      </p>
      <p>
        How does Rainmakers stand apart from typical recruiters of sales positions or other
        traditional recruiting options?
      </p>

      <p>
        For one thing, our platform is exclusively for top salespeople with a proven record of
        success. We put the salesperson up front to let them showcase their achievements.
      </p>

      <p>
        Rainmakers also insists that candidates set their own hiring requirements and compensation
        range. You don’t apply to companies. The companies apply to you!
      </p>

      <p>Here are some of the most in-demand sales positions we can help you with.</p>

      <Link to="/apply-now" title="Apply Now">
        <img src={pic2} alt="" />
      </Link>

      <h3>Sales Development Representative</h3>

      <p>
        <Link to={jobsData[JOB_PAGE_SDR].localUrl} target="_blank">
          Sales Development Representatives (SDRs)
        </Link>{' '}
        are valued team members of sales teams in various industries.
      </p>

      <p>
        An SDR is primarily an inside sales professional who concentrates on cold outreach to build
        sales pipelines for the rest of the sales team. The role is frequently viewed as an
        entry-level one that doesn’t require years of expertise and is a great starting point for a
        successful sales career.
      </p>

      <h3>Account Executive</h3>

      <p>
        <Link to={jobsData[JOB_PAGE_AE].localUrl} target="_blank">
          An Account Executive (AE)
        </Link>{' '}
        is responsible for concluding deals with new clients, attending to the needs of current
        clients, and attracting new ones. A significant portion of the pay is commissioned base, and
        Account Executives work to meet specific quotas and revenue targets.
      </p>

      <p>
        Despite working in an occasionally high-pressure environment, AEs can derive great
        satisfaction from closing deals and managing clients.
      </p>

      <h3>Mid-Market Account Executive</h3>

      <p>
        For sales professionals who are enthusiastic about their career and looking to take it to
        the next level, becoming a{' '}
        <Link to={jobsData[JOB_PAGE_MMAE].localUrl} target="_blank">
          Mid-Market Account Executive
        </Link>{' '}
        is a great choice.
      </p>

      <p>
        Mid-Market Account Executives are expected to train the complete sales department and
        promote sales growth via SaaS/cloud technology and service delivery, facilitating sales,
        completing deals, and sustaining positive-growth client connections. Middle-market
        businesses are their primary market for sales expansion.
      </p>

      <img src={pic3} alt="" />

      <h3>Enterprise Account Executive</h3>

      <p>
        <Link to={jobsData[JOB_PAGE_EAE].localUrl} target="_blank">
          Enterprise Account Executives
        </Link>{' '}
        work for various companies, including some of the biggest manufacturers, telecommunications
        corporations, software developers, and retail chains in the world. Because the position
        involves some significant responsibilities, Enterprise Account Executives have a high
        earning potential.
      </p>

      <p>
        To meet high-end customers where they are, businesses need well-prepared, dedicated, and
        capable Enterprise Account Managers to maintain healthy sales relationships and nurture
        growth and innovation on both sides.
      </p>

      <h3>Customer Success Manager</h3>

      <p>
        <Link to={jobsData[JOB_PAGE_CSM].localUrl} target="_blank">
          Customer Success Managers (CSMs)
        </Link>{' '}
        are committed to building strong, lasting client relationships that go beyond simply making
        a sale. After closing a deal, businesses must continue to prove the value of their products
        or services. Customer Success Managers are tasked with marketing products and services while
        cultivating customer relationships to help them succeed and keep coming back.
      </p>

      <p>
        The Customer Success Manager position is a relatively new concept, but businesses today
        invest heavily in customer relations, with nearly half of all high-tech companies employing
        CSMs.
      </p>

      <h3>Sales Director</h3>

      <p>
        <Link to={jobsData[JOB_PAGE_SD].localUrl} target="_blank">
          Sales Directors
        </Link>{' '}
        supervise all relevant activities carried out by the sales team, strategically plan and
        introduce new products and services, and ensure the company continues providing excellent
        customer service. In addition, they motivate the sales team and ensure they hit their
        predetermined goals.
      </p>

      <p>
        Sales Directors are also involved in training their sales teams and must have significant
        organizational and leadership qualities and proven experience in sales.
      </p>

      <Link to="/apply-now" title="Apply Now">
        <img src={pic4} alt="" />
      </Link>

      <h3>Sales Manager</h3>

      <p>
        <Link to={jobsData[JOB_PAGE_SM].localUrl} target="_blank">
          A Sales Manager
        </Link>{' '}
        is a person who directs and oversees sales representatives and manages a company’s daily
        sales operations. They manage the sales strategy, establish sales objectives, and monitor
        sales results.
      </p>

      <p>
        Depending on the company, a sales manager may have different duties. However, the tasks
        frequently involve finding talent, developing and coaching agents, establishing the sales
        strategy, and tracking sales activity.
      </p>

      <h3>Vice President of Sales</h3>

      <p>
        <Link to={jobsData[JOB_PAGE_VP].localUrl} target="_blank">
          The Vice President of Sales
        </Link>{' '}
        has many different responsibilities. They frequently serve as managers, mentors, teachers,
        and strategists. To achieve quotas and promote business success, a VP of Sales must carry
        out several standard tasks, including developing plans and strategies, managing sales teams,
        overseeing budgets, recruiting and hiring sales staff, motivating teams toward goals, and
        more.
      </p>

      <h2>Sales Career Opportunities at Rainmakers</h2>

      <p>
        Careers in sales offer the potential for high earnings and significant personal growth. Many
        positions also offer excellent tracks for advancement for those willing to put in the work
        and prove their worth above and beyond expectations.
      </p>

      <p>
        Every position, from Sales Development Rep to VP in Sales, is integral to any successful
        business’s sales funnel. By developing the right skills and being dedicated to the tasks at
        hand, there’s no reason why any individual couldn’t find success.
      </p>

      <p>
        <Link to="/apply-now/">Sign up now</Link> to learn more about these fantastic opportunities
        today.
      </p>
    </div>
  );
}
