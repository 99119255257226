import { createSelector } from 'reselect';

const selectUser = createSelector(
  (state) => state,
  (state) => state.user
);

const selectIsReady = createSelector(selectUser, (user = {}) => user.loginDone === true);

export default selectIsReady;
