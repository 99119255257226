import React from 'react';

import Layout from 'components/Layout';
import ResetPassword from 'connectors/ResetPassword';

const ResetPasswordPage = (props) => (
  <Layout>
    <ResetPassword {...props} />
  </Layout>
);

export default ResetPasswordPage;
