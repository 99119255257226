import { call, put, take } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { PICKER_OPTIONS_LOAD_STARTED } from 'shared/store/app/actions';

import { pickerOptionsLoadDone } from 'shared/store/app/creators';

import { promisePickerOptions } from '../../promises';

const sagaPickerOptionsLoadStarted = function* sagaPickerOptionsLoadStarted() {
  while (true) {
    yield take(PICKER_OPTIONS_LOAD_STARTED);

    try {
      const pickerOptions = yield call(promisePickerOptions);

      yield put(pickerOptionsLoadDone({ pickerOptions }));
    } catch (error) {
      handleError(error);
    }
  }
};

export default sagaPickerOptionsLoadStarted;
