import React from 'react';

import Button from 'components/Button';
import FontIcon from 'components/FontIcon';

import styles from './VerificationContent.scss';

const VerificationContent = ({
  name,
  picture,
  webSite,
  editCompanyLink,
  onVerify = () => {},
  onReject = () => {},
}) => {
  const { xlarge: url } = picture;

  return (
    <div className={styles.unverifiedContentContainer}>
      <div className={styles.imageLinkContainer}>
        <img className={styles.unverifiedImage} src={url} alt="unverified pic" />
        {editCompanyLink && (
          <a href={editCompanyLink} target="__blank" title="Edit profile in new tab">
            <FontIcon iconName="share" className={styles.linkIcon} />
          </a>
        )}
      </div>
      <div>{name}</div>
      <div>{webSite}</div>
      <div className={styles.actions}>
        <Button primary onClick={onVerify}>
          Verify
        </Button>
        <Button warning onClick={onReject}>
          Reject
        </Button>
      </div>
    </div>
  );
};

export default VerificationContent;
