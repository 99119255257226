export default [
  {
    label: 'Hi <first_name>',
    string: 'Hi <first_name>',
    replacer: 'first_name',
  },
  {
    label: 'Hello <first_name>',
    string: 'Hello <first_name>',
    replacer: 'first_name',
  },
  {
    label: 'Hey <first_name>',
    string: 'Hey <first_name>',
    replacer: 'first_name',
  },
  {
    label: 'No salutation',
    string: '<None>',
    replacer: null,
  },
];
