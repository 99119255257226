import React from 'react';

import Input from 'components/Input';

const HiringManager = ({
  requisitionOpening: { attributes: { hiringManager, state } = {} } = {},
  errors: { hiringManager: hiringManagerErrors = {} } = {},
  handleInputChange,
}) => {
  const hiringManagerInputProps = {
    handleInputChange,
    type: 'text',
    size: 'full',
    disabled: state === 'closed',
    name: 'hiringManager',
    label: 'Hiring Manager',
    placeholder: "Who's running the show?",
    value: hiringManager || '',
    errors: hiringManager !== null ? hiringManagerErrors : {},
  };
  return <Input {...hiringManagerInputProps} />;
};

export default HiringManager;
