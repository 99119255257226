import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { handleError } from 'utils/common';

import EmptyCompanyLogo from 'shared/assets/missing-company-picture.png';

import { UserAuthHOC } from 'containers/HOC';
import ConnectContainer from 'containers/ConnectContainer';

import ReadyContainer from 'connectors/ReadyContainer';

import { promiseGetEmployer } from 'pages/EmployeePortal/EmployeeEditorPages/promises';

import Sidebar from 'components/Sidebar';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import Block from 'components/Block';

import duck from '../redux';

import CompanyHeader from './components/CompanyHeader';
import CompanyAbout from './components/CompanyAbout';
import CompanyBenefits from './components/CompanyBenefits';
import CompanyPerks from './components/CompanyPerks';
import CompanyPress from './components/CompanyPress';

import styles from './EditPreview.scss';

const EditPreview = (props) => {
  useEffect(() => {
    const {
      actions: { employerLoadDone },
      user,
    } = props;

    const onFulfilled = ({ employer }) => employerLoadDone({ employer });

    const currentProfile = user.loginDone ? user.currentProfile : {};

    const employerId = currentProfile.type === 'Employee' ? currentProfile.employerId : null;

    const promiseParams = {
      employerId,
      camelizeIt: true,
    };

    promiseGetEmployer(promiseParams).then(onFulfilled).catch(handleError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { employer, isApproved } = props;

  const {
    name,
    location,
    about,
    stage,
    benefits = [],
    perks = [],
    links = [],
    sizeMin,
    sizeMax,
    industry,
    foundedOn = '',
    webSite,
    tagline,
    picture = {},
  } = employer;

  const { xlarge: pictureUrl = EmptyCompanyLogo } = picture || {};

  const commonBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    addPadding: true,
    addChildFlex: true,
  };

  return (
    <>
      <Helmet title="Profile" />,
      <ReadyContainer>
        <Sidebar
          type={isApproved ? 'employeeStatusApproved' : 'employeeStatusUnapproved'}
          page={'edit-preview'}
          employer={employer}
        />
        <LayoutWithoutSidebar
          content={
            <div className={styles.CompanyPreviewContainer}>
              <div className={styles.previewHeaderContainer}>
                <Block {...commonBlockProps}>
                  <CompanyHeader
                    employer={{ attributes: employer }}
                    pictureUrl={pictureUrl}
                    name={name}
                    tagline={tagline}
                    location={location}
                    foundedOn={foundedOn}
                    webSite={webSite}
                    stage={stage}
                    industry={industry}
                    sizeMin={sizeMin}
                    sizeMax={sizeMax}
                  />
                </Block>
              </div>
              <div className={styles.previewMainContainer}>
                <div className={styles.previewAboutContainer}>
                  <Block {...commonBlockProps} title={`About ${name}`} addFlex={true}>
                    <CompanyAbout about={about} />
                  </Block>
                </div>
                <div className={styles.previewSideContainer}>
                  <div className={styles.previewSideSmallContainer}>
                    <Block {...commonBlockProps} title={'Perks'}>
                      <CompanyPerks perks={perks} />
                    </Block>
                  </div>
                  <div className={styles.previewSideSmallContainer}>
                    <Block {...commonBlockProps} title={'Benefits'}>
                      <CompanyBenefits benefits={benefits} />
                    </Block>
                  </div>
                  <div className={styles.previewSideSmallContainer}>
                    <Block {...commonBlockProps} title={'Press'}>
                      <CompanyPress links={links || []} />
                    </Block>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </ReadyContainer>
      ,
    </>
  );
};

export default ConnectContainer(duck)(UserAuthHOC()(EditPreview));
