import { take, fork, cancel } from 'redux-saga/effects';

import rootSaga from './rootSaga';

const sagas = [rootSaga];

export const CANCEL_SAGAS_HMR = 'CANCEL_SAGAS_HMR';

function createAbortableSaga(saga, history) {
  if (process.env.NODE_ENV === 'development') {
    return function* main() {
      const sagaTask = yield fork(saga, history);

      yield take(CANCEL_SAGAS_HMR);
      yield cancel(sagaTask);
    };
  }

  return saga;
}

const SagaManager = {
  startSagas(sagaMiddleware, history) {
    sagas
      .map((saga) => createAbortableSaga(saga, history))
      .forEach((saga) => sagaMiddleware.run(saga, history));
  },
  cancelSagas(store) {
    store.dispatch({
      type: CANCEL_SAGAS_HMR,
    });
  },
};

export default SagaManager;
