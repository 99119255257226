import React, { useRef, useState, useEffect } from 'react';

import InputSelect from 'components/InputSelect';
import { promiseCandidateSources } from 'connectors/Defaults/promises';
import { handleError } from 'utils/common';

export function ReferralSourceSelect({ defaultReferralSource, onChange, ...restProps }) {
  const _mounted = useRef(false);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    _mounted.current = true;

    return () => {
      _mounted.current = false;
    };
  }, []);

  useEffect(() => {
    promiseCandidateSources()
      .then((arr) =>
        arr.map((item) => (item ? { label: item, value: item } : { label: 'None', value: 'null' }))
      )
      .then((data) => {
        if (_mounted.current) {
          setSelectedOption(defaultReferralSource || 'null');
        }
        return data;
      })
      .then((data) => _mounted.current && setOptions(data))
      .catch(handleError);
  }, [defaultReferralSource]);

  const handleOnChange = (value) => {
    setSelectedOption(value);
    onChange(value === 'null' ? null : value);
  };

  return (
    <InputSelect
      name="referralSourceDropdown"
      label={null}
      value={selectedOption}
      source={options}
      defaultIcon="caret-down"
      activeIcon="caret-up"
      bigInput={true}
      handleInputChange={({ target: { value } }) => handleOnChange(value)}
      type="text"
      size="xxlarge"
      data-testid="referralSourceDropdown"
      {...restProps}
    />
  );
}
