import React, { useState } from 'react';
import isEmpty from 'lodash.isempty';

import { handleError } from 'utils/common';
import EmptyCompanyLogo from 'shared/assets/missing-company-picture.png';

import Validator from 'connectors/Validator';

import { patchEntity } from 'api/apiEntity';
import { employerInputChange } from 'utils/formHelpers/EmployerHelpers';
import { toggleArray } from 'utils/formUtils';

import { COMPANY_SIZE_SOURCE } from 'connectors/Defaults/constants';

import Block from 'components/Block';
import Button from 'components/Button';
import CompanyImageUploader from 'components/CompanyImageUploader';
import Content from 'components/Content';
import GooglePlacesAutocomplete from 'components/GooglePlacesAutocomplete';
import Input from 'components/Input';
import InputRichText from 'components/InputRichText';
import InputSelect from 'components/InputSelect';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import Label from 'components/Label';
import TableOverlay from 'components/TableOverlay';

import PerkInputs from 'pages/EmployeePortal/EmployeeEditorPages/EditCompany/components/PerkInputs';
import InvestorInputs from 'pages/EmployeePortal/EmployeeEditorPages/EditCompany/components/InvestorInputs';
import LinkInputs from 'pages/EmployeePortal/EmployeeEditorPages/EditCompany/components/LinkInputs';
import BenefitInputs from 'pages/EmployeePortal/EmployeeEditorPages/EditCompany/components/BenefitInputs';

import styles from './CompanyEditorProfile.scss';

const CompanyEditorProfile = (props) => {
  const [companyWebsiteError, setCompanyWebsiteError] = useState('');

  const verifyCompany = async () => {
    const {
      actions: { resourceUpdate, resourceDelete },
      company,
      company: {
        relationships: { unverifiedCompanyData: { data: unverifiedDataIds = [] } = {} } = {},
      } = {},
      unverifiedCompanyData: { byId: unverifiedDataById = {} } = {},
    } = props;

    const { [unverifiedDataIds[0]]: { attributes: { webSite, picture = {} } = {}, id } = {} } =
      unverifiedDataById || {};

    const resource = {
      ...company,
      attributes: {
        ...company.attributes,
        webSite: webSite || company.attributes.webSite,
        picture: picture || company.attributes.picture,
      },
    };

    resourceUpdate({
      slice: 'toolboxCompanies',
      type: 'company',
      id: company.id,
      resource,
    });

    const args = {
      id,
      type: 'unverified_company_data',
      data: {
        attributes: {
          state: 'verified',
        },
        id,
        type: 'unverified_company_data',
      },
      config: {
        params: {
          camelizeIt: true,
        },
      },
    };

    try {
      await patchEntity(args);
    } catch (error) {
      handleError(error);
    }

    resourceDelete({
      id,
      slice: 'toolboxCompanies',
      type: 'unverifiedCompanyData',
    });
  };

  const rejectCompany = async () => {
    const {
      actions: { resourceDelete },
      company: {
        relationships: { unverifiedCompanyData: { data: unverifiedDataIds = [] } = {} } = {},
      } = {},
    } = props;

    const args = {
      id: unverifiedDataIds[0],
      type: 'unverified_company_data',
      data: {
        attributes: {
          state: 'rejected',
        },
        id: unverifiedDataIds[0],
        type: 'unverified_company_data',
      },
      config: {
        params: {
          camelizeIt: true,
        },
      },
    };

    try {
      await patchEntity(args);
    } catch (error) {
      handleError(error);
    }

    resourceDelete({
      id: unverifiedDataIds[0],
      slice: 'toolboxCompanies',
      type: 'unverifiedCompanyData',
    });
  };

  const handleCompanyInputChange = (event) => {
    const {
      actions: { patchResource },
      company: { attributes: company, id },
    } = props;

    const { newValue, name } = employerInputChange(event, company);

    if (name === 'webSite') {
      if (Validator.isUrl({ newValue })) {
        setCompanyWebsiteError('');
      } else {
        setCompanyWebsiteError('Illegal website url');
        return;
      }
    }

    patchResource({
      id,
      attributes: {
        [name]: newValue,
      },
      normalizeIt: true,
      requiredFields: ['name'],
      slice: 'toolboxCompanies',
      type: 'companies',
    });
  };

  const handleAddArrayInput = (arrayName) => {
    const {
      actions: { resourceUpdate },
      company,
    } = props;

    const {
      attributes: { [arrayName]: arrayToUpdate },
      id,
    } = company;

    const newValue = arrayToUpdate;
    newValue.push('');

    resourceUpdate({
      resource: {
        ...company,
        attributes: {
          ...company.attributes,
          [arrayName]: newValue,
        },
      },
      id,
      slice: 'toolboxCompanies',
      type: 'companies',
    });
  };

  const handleAddArrayValue = (event, arrayName) => {
    const {
      target: { name, value },
    } = event;
    const {
      company: {
        attributes: { [arrayName]: arrayToUpdate },
        id,
      },
      actions: { patchResource },
    } = props;

    let newValue = arrayToUpdate ? arrayToUpdate.slice() : [];

    newValue[name] = value;

    if (newValue.every((el) => !el)) {
      newValue = [];
    }

    patchResource({
      id,
      attributes: {
        [arrayName]: newValue,
      },
      normalizeIt: true,
      requiredFields: ['name'],
      slice: 'toolboxCompanies',
      type: 'companies',
    });
  };

  const handleToggleArrayValue = (event) => {
    const {
      target: { name, value },
    } = event;
    const {
      company: {
        attributes: { [name]: arrayToUpdate },
        id,
      },
      actions: { patchResource },
    } = props;

    const newValue = toggleArray({
      array: arrayToUpdate,
      value,
    });

    patchResource({
      id,
      attributes: {
        [name]: newValue,
      },
      normalizeIt: true,
      requiredFields: ['name'],
      slice: 'toolboxCompanies',
      type: 'companies',
    });
  };

  const handleDeleteArrayInput = (indexToRemove, arrayName) => {
    const {
      actions: { patchResource },
      company: {
        attributes: { [arrayName]: arrayToUpdate },
        id,
      },
    } = props;

    const newValue = arrayToUpdate.filter((id, index) => index !== indexToRemove);

    patchResource({
      id,
      attributes: {
        [arrayName]: newValue,
      },
      normalizeIt: true,
      requiredFields: ['name'],
      slice: 'toolboxCompanies',
      type: 'companies',
    });
  };

  const {
    actions: { patchResource },
    apiErrors = {},
    company: {
      attributes: {
        about = '',
        name: companyName,
        location,
        email,
        sizeMin,
        stage,
        industry,
        investors,
        benefits,
        perks,
        links,
        foundedOn = '',
        webSite,
        socialMedia: {
          linkedin: { url: linkedinUrl = '' } = {},
          facebook: { url: facebookUrl = '' } = {},
          twitter: { url: twitterUrl = '' } = {},
        } = {},
        tagline,
        picture: { xlarge: pictureUrl } = {},
      } = {},
      id: companyId,
      relationships: { unverifiedCompanyData: { data: unverifiedDataIds = [] } = {} } = {},
    } = {},
    pickerOptions,
    saving,
    tags,
    companyErrors: companyErrorsOrig,
    companyLoading,
    unverifiedCompanyData: { byId: unverifiedDataById = {} } = {},
  } = props;

  const companyErrors = {
    ...companyErrorsOrig,
    name: !isEmpty(apiErrors) ? [false, 'name'] : [true, 'name'],
  };

  const {
    attributes: {
      name: unverifiedName,
      picture: { xlarge: unverifiedPictureUrl = EmptyCompanyLogo } = {},
      webSite: unverifiedWebsite = 'No website available',
    } = {},
  } = unverifiedDataById[unverifiedDataIds[0]] || {};

  const verified = unverifiedDataIds[0]
    ? !unverifiedDataById[unverifiedDataIds[0]]
    : !unverifiedDataIds[0];

  const {
    'name': nameErrors,
    'email': emailErrors,
    // 'webSite': webSiteErrors,
    'socialMedia,linkedin,url': linkedinErrors,
    'socialMedia,twitter,url': twitterErrors,
    'socialMedia,facebook,url': facebookErrors,
  } = companyErrors || {};

  const { stage: stageOptions = [], industry: industryOptionsRaw = [] } = pickerOptions || {};

  const industryOptions = industryOptionsRaw.filter((opt) => opt !== 'Many' && opt !== 'Other');

  const { perks: perksOptions = [], benefits: benefitsOptions = [] } = tags || {};

  const savingContent = saving ? (
    <div className={styles.autoSaving}>Saving...</div>
  ) : (
    <div className={styles.autoSaved}>Saved</div>
  );

  const saveContent = saving === undefined ? null : savingContent;

  const unverifiedBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    addPadding: true,
    className: styles.unverifiedBlock,
  };

  const titleBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    addPadding: true,
    className: styles.titleBlock,
  };

  const commonBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    addPadding: true,
  };

  const basicInfoBlockProps = {
    ...commonBlockProps,
    title: 'Basic Info',
    addChildFlex: true,
  };
  const socialMediaBlockProps = {
    ...commonBlockProps,
    title: 'Social Media',
    className: styles.firstBlock,
  };
  const perksBenefitsBlockProps = {
    ...commonBlockProps,
    title: 'Perks and Benefits',
    className: styles.firstBlock,
  };
  const investmentBlockProps = {
    ...commonBlockProps,
    title: 'Investment',
    className: styles.firstBlock,
  };
  const pressBlockProps = {
    ...commonBlockProps,
    title: 'Press & Media Exposure',
    className: styles.firstBlock,
  };

  const commonTextInputProps = {
    handleInputChange: handleCompanyInputChange,
    type: 'text',
    size: 'full',
    topLabel: true,
  };

  const companyNameInputProps = {
    placeholder: 'Company Name',
    size: 'full',
    name: 'name',
    value: companyName || '',
    label: 'Company Name',
    handleInputChange: handleCompanyInputChange,
    handleOnBlur: handleCompanyInputChange,
    topLabel: true,
    required: true,
    className: styles.noPadding,
    errors: nameErrors,
  };

  const companyEmailInputProps = {
    ...commonTextInputProps,
    required: true,
    name: 'email',
    label: 'Company Email',
    value: email || '',
    placeholder: 'Company Email',
    errors: emailErrors,
  };

  const companyLocationInputProps = {
    name: 'location',
    value: location || '',
    placeholder: 'Where is the company headquartered?',
    handleSelect: (place) =>
      handleCompanyInputChange({
        target: {
          value: place,
          name: 'location',
        },
      }),
  };

  const industrySource = industryOptions.map((ind) => ({ label: ind, value: ind }));

  const [industryValue] = industrySource.filter(({ label }) => label === industry);

  const companyIndustryInputProps = {
    ...commonTextInputProps,
    name: 'industry',
    label: 'Industry Type',
    value: industryValue ? industryValue.label : '',
    source: industrySource,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    defaultLabel: 'Industry',
    bigInput: true,
  };

  const [sizeValue] = COMPANY_SIZE_SOURCE.filter(
    ({ min }) => parseInt(min, 10) === parseInt(sizeMin, 10)
  );

  const companySizeInputProps = {
    ...commonTextInputProps,
    handleInputChange: (event) => {
      const {
        target: { value },
      } = event;

      const min = value.split(',')[0];
      const max = value.split(',')[1];

      handleCompanyInputChange({
        ...event,
        target: {
          ...event.target,
          name: 'sizeMax',
          value: max,
        },
      });

      handleCompanyInputChange({
        ...event,
        target: {
          ...event.target,
          name: 'sizeMin',
          value: min,
        },
      });
    },
    name: 'size',
    label: 'Company Size',
    value: sizeValue ? sizeValue.value : '',
    source: COMPANY_SIZE_SOURCE,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    defaultLabel: 'Size',
    bigInput: true,
  };

  const companyLinkedInInputProps = {
    ...commonTextInputProps,
    name: 'socialMedia,linkedin,url',
    size: 'xlarge',
    type: 'url',
    label: 'Company LinkedIn',
    value: linkedinUrl || '',
    placeholder: 'www.linkedin.com/in/awesome-company',
    className: styles.noPadding,
    errors: linkedinErrors,
  };

  const companyTwitterInputProps = {
    ...commonTextInputProps,
    size: 'xlarge',
    name: 'socialMedia,twitter,url',
    autoCapitalize: 'none',
    label: 'Company Twitter',
    value: twitterUrl || '',
    placeholder: 'www.twitter.com/awesome-company',
    errors: twitterErrors,
  };

  const companyFacebookInputProps = {
    ...commonTextInputProps,
    name: 'socialMedia,facebook,url',
    size: 'xlarge',
    label: 'Company Facebook',
    value: facebookUrl || '',
    placeholder: 'www.facebook.com/awesome-company',
    errors: facebookErrors,
  };

  const companyWebSiteInputProps = {
    ...commonTextInputProps,
    required: true,
    name: 'webSite',
    label: 'Company Website',
    value: webSite || '',
    placeholder: 'www.awesome-company.com',
    errors: {
      isUrl: [companyWebsiteError.length === 0],
    },
    showErrorsImmediately: true,
  };

  const currentDate = new Date();

  const companyFoundedOnInputProps = {
    ...commonTextInputProps,
    size: 'full',
    name: 'foundedOn',
    label: 'Founding Year',
    value: foundedOn || '',
    centered: true,
    topLabel: true,
    type: 'number',
    max: `${currentDate.getFullYear()}`,
  };

  const companyTaglineInputProps = {
    ...commonTextInputProps,
    name: 'tagline',
    label: 'Company Tagline',
    value: tagline || '',
    placeholder: 'The essence of who you are',
  };

  const companyPerksInputProps = {
    handleToggleArrayValue: handleToggleArrayValue,
    perks,
    perksOptions,
  };
  const companyLinksInputProps = {
    handleAddArrayInput: handleAddArrayInput,
    handleAddArrayValue: handleAddArrayValue,
    handleDeleteArrayInput: handleDeleteArrayInput,
    links,
  };
  const companyInvestorsInputProps = {
    handleToggleArrayValue: handleToggleArrayValue,
    investors,
  };

  const companyBenefitsInputProps = {
    handleToggleArrayValue: handleToggleArrayValue,
    benefits,
    benefitsOptions,
  };

  const aboutInputProps = {
    value: about,
    handleInputChange: handleCompanyInputChange,
    name: 'about',
  };

  const stageSource = stageOptions.map((stage) => ({ label: stage, value: stage }));

  const [stageValue] = stageSource.filter(({ label }) => label === stage);

  const companyStageInputProps = {
    ...commonTextInputProps,
    name: 'stage',
    label: 'Funding',
    value: stageValue ? stageValue.label : '',
    source: stageSource,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    defaultLabel: 'Stage',
    bigInput: true,
    noMargin: true,
  };

  const imageUploaderProps = {
    companyId,
    pictureUrl,
    companyImageUploadStarted: ({ picture }) =>
      patchResource({
        attributes: {
          picture: {
            file_data: picture,
            large: picture,
            medium: picture,
            original: picture,
            small: picture,
            thumb: picture,
            xlarge: picture,
            xxlarge: picture,
          },
        },
        id: companyId,
        normalizeIt: true,
        requiredFields: ['name'],
        slice: 'toolboxCompanies',
        type: 'companies',
      }),
  };

  const companyVerifiedInputProps = {
    onClick: verifyCompany,
    primary: true,
    disabled: verified,
  };

  const companyRejectedInputProps = {
    onClick: rejectCompany,
    warning: true,
    disabled: verified,
  };

  const containerProps = {
    className: styles.profileAvatarWrapper,
  };

  const logoProps = {
    className: styles.profileAvatar,
    src: unverifiedPictureUrl,
  };

  const unverifiedContent = !verified ? (
    <Block {...unverifiedBlockProps}>
      <Content className={styles.info}>
        Does this information look correct? If so, click verify. If not, click reject and manually
        input the proper info.
        <Button {...companyVerifiedInputProps}>Verify</Button>
        <Button {...companyRejectedInputProps}>Reject</Button>
      </Content>
      <Content className={styles.unverifiedContainer}>
        <div {...containerProps}>
          <Label standalone>Suggested logo</Label>
          <img {...logoProps} alt="" />
        </div>
        <div className={styles.basicInfoTopUnverified}>
          <Label standalone>Suggested name</Label>
          <Content className={styles.unverifiedData}>{unverifiedName}</Content>
        </div>
        <div className={styles.basicInfoTop}>
          <Label standalone>Suggested website</Label>
          <Content className={styles.unverifiedData}>{unverifiedWebsite}</Content>
        </div>
      </Content>
    </Block>
  ) : null;

  const content = (
    <div className={styles.EditorProfile}>
      {unverifiedContent}
      <Block {...titleBlockProps}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Company Profile</div>
          <div className={styles.saveContent}>{saveContent}</div>
        </div>
      </Block>
      <div className={styles.scrollDiv}>
        <Block {...basicInfoBlockProps}>
          <div className={styles.containerFlexRow}>
            <CompanyImageUploader {...imageUploaderProps} />
            <div className={styles.containerFlexColumn}>
              <div className={styles.basicInfoTop}>
                <Input {...companyNameInputProps} />
                <Input {...companyEmailInputProps} />
                <Label standalone className={styles.label}>
                  Company HQ Location
                </Label>
                <GooglePlacesAutocomplete {...companyLocationInputProps} />
                <div className={styles.basicInfoRowApart}>
                  <InputSelect {...companySizeInputProps} />
                  <InputSelect {...companyIndustryInputProps} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.basicInfoTop}>
            <Input {...companyWebSiteInputProps} />
          </div>
          <div className={styles.basicInfoRow}>
            <div className={styles.taglineContainer}>
              <Input {...companyTaglineInputProps} />
            </div>
            <div className={styles.foundedContainer}>
              <Input {...companyFoundedOnInputProps} />
            </div>
          </div>
          <Label required className={styles.aboutLabel}>
            Company Description
          </Label>
          <Content className={styles.aboutSublabel}>
            Let candidates know why they should work for a cool company like yours.
          </Content>
          <InputRichText {...aboutInputProps} />
        </Block>
        <Block {...socialMediaBlockProps}>
          <Input {...companyLinkedInInputProps} />
          <Input {...companyFacebookInputProps} />
          <Input {...companyTwitterInputProps} />
        </Block>
        <Block {...perksBenefitsBlockProps}>
          <PerkInputs {...companyPerksInputProps} />
          <BenefitInputs {...companyBenefitsInputProps} />
        </Block>
        <Block {...investmentBlockProps}>
          <InputSelect {...companyStageInputProps} />
          <InvestorInputs {...companyInvestorsInputProps} />
        </Block>
        <Block {...pressBlockProps}>
          <LinkInputs {...companyLinksInputProps} />
        </Block>
      </div>
    </div>
  );

  return (
    <LayoutWithoutSidebar
      content={
        companyLoading ? (
          <div>
            <TableOverlay table="noSidebar" />
            {content}
          </div>
        ) : (
          content
        )
      }
    />
  );
};

export default CompanyEditorProfile;
