import React, { Component } from 'react';

import { handleError } from 'utils/common';

import { ComponentErrorBoundaryHOC } from 'containers/HOC';

import {
  promiseGetSearchAnalysis,
  promiseGetRainScore,
} from 'pages/EmployeePortal/CandidateProfilePages/promises';

import Block from 'components/Block';
import Bolder from 'components/Bolder';
import Button from 'components/Button';
import Content from 'components/Content';
import FontIcon from 'components/FontIcon';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import Label from 'components/Label';

import styles from './SearchAnalysis.scss';

export class SearchAnalysis extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      loading: false,
      showMore: false,
      rainScore: null,
      ReactJson: null,
    };
  }

  componentDidMount() {
    const ReactJson = require('react-json-view').default;

    this.setState({ ReactJson });
  }

  loadAnalysis = () => {
    const { candidateId, requisitionOpeningId, searchString } = this.props;

    const category = '&filter[category]=search';

    if (requisitionOpeningId && candidateId) {
      promiseGetRainScore({ candidateId, requisitionOpeningId })
        .then(({ rainScore }) => this.setState({ rainScore }))
        .catch((error) => {
          handleError(error);
          this.setState({ rainScore: '' });
        });
    }

    promiseGetSearchAnalysis({
      candidateId,
      searchString: searchString + category,
    })
      .then((data) =>
        this.setState({
          data,
          loading: false,
          showMore: true,
        })
      )
      .catch((error) => {
        handleError(error);

        this.setState({
          data: {},
          loading: false,
          showMore: false,
        });
      });
  };

  render() {
    const {
      data = {},
      data: { explanation: { value } = {} } = {},
      loading,
      rainScore,
      ReactJson,
      showMore,
    } = this.state;

    const { candidateId, searchString } = this.props;

    if (!searchString || !candidateId) {
      return null;
    }

    const showMoreButtonProps = {
      onClick: (event) => {
        event.stopPropagation();
        this.loadAnalysis();
        this.setState({ loading: true });
      },
      className: styles.showMore,
      tertiary: true,
    };

    const showLessButtonProps = {
      onClick: (event) => {
        event.stopPropagation();
        this.setState({ showMore: false });
      },
      className: styles.showMore,
      tertiary: true,
    };

    const showMoreContent = showMore ? null : (
      <Button {...showMoreButtonProps}>
        Analysis <FontIcon className={styles.showMoreIcon} iconName="caret-down" />
      </Button>
    );

    const showLessContent = showMore ? (
      <Button {...showLessButtonProps}>
        Analysis <FontIcon className={styles.showMoreIcon} iconName="caret-up" />
      </Button>
    ) : null;

    const showContent = showMoreContent || showLessContent;

    const title = (
      <Content>
        <Bolder>{showContent}</Bolder>
      </Content>
    );

    const commonBlockProps = {
      title,
      addWhiteBG: true,
      tertiaryTitle: true,
      className: styles.marginBottom,
    };

    const loadingContent = loading ? <InfiniteScrollLoader /> : null;

    const reactJsonProps = {
      src: data,
      theme: 'apathy:inverted',
      iconStyle: 'triangle',
      displayObjectSize: false,
      displayDataTypes: false,
    };

    const jsonContent = ReactJson ? <ReactJson {...reactJsonProps} /> : null;

    const rainScoreContent = rainScore ? (
      <Content>
        <Label>rAIn Score:</Label> {rainScore}
      </Content>
    ) : null;

    const moreContent = showMore ? (
      <Content className={styles.container}>
        <Content className={styles.row}>
          <Content>
            <Label>ES Score:</Label> {value}
          </Content>
          {rainScoreContent}
        </Content>
        <Content className={styles.jsonContainer}>{jsonContent}</Content>
      </Content>
    ) : (
      loadingContent
    );

    return <Block {...commonBlockProps}>{moreContent}</Block>;
  }
}

export default ComponentErrorBoundaryHOC()(SearchAnalysis);
