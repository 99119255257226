import React, { Component } from 'react';
import dayjs from 'dayjs';

import { promisePostEducationOrgs } from 'pages/AdminPortal/ToolboxCandidateEditorPage/promises';

import { getNestedEntity, patchEntity, postEntity } from 'api/apiEntity';

import { handleError } from 'utils/common';
import { toggleArray } from 'utils/formUtils';

import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import Block from 'components/Block';
import Button from 'components/Button';

import EducationInputs from 'pages/CandidatePortal/CandidateEditorPages/EditEducation/components/EducationInputs';
import EducationDisplay from 'pages/CandidatePortal/CandidateEditorPages/EditEducation/components/EducationDisplay';

import styles from './CandidateEditorEducation.scss';

class CandidateEditorEducation extends Component {
  constructor(props) {
    super(props);

    const candidateEducations = this.getCandidateEducations(props);

    this.state = {
      isPostingEducation: false,
      candidateEducations,
      origCandidateEducations: candidateEducations,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.educationOrganizations === nextProps.educationOrganizations) return;

    const candidateEducations = this.getCandidateEducations(nextProps);

    this.setState({
      candidateEducations,
      origCandidateEducations: candidateEducations,
    });

    const firstMovedEduIdx = candidateEducations.findIndex((edu) => edu.moved);
    if (firstMovedEduIdx >= 0) {
      if (this[`candidateEducation${firstMovedEduIdx}`]) {
        this[`candidateEducation${firstMovedEduIdx}`].scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
      }
      setTimeout(() => {
        const { candidateEducations } = this.state;
        const newCandidateEducations = candidateEducations.map((edu) => ({
          ...edu,
          moved: false,
        }));

        this.setState({
          candidateEducations: newCandidateEducations,
          origCandidateEducations: newCandidateEducations,
        });
      }, 500);
    }
  }

  componentWillUnmount() {
    this.setState({
      candidateEducations: [],
      origCandidateEducations: [],
    });
  }

  getCandidateEducations = (props) => {
    const {
      match: { params: { id: candidateId } = {} } = {},
      candidateEducations: {
        allIds: candidateEducationsAllIds = [],
        byId: candidateEducationsById = {},
      } = {},
      educationOrganizations: { byId: educationOrganizationsById = {} } = {},
    } = props;

    const candidateEducationIds = candidateEducationsAllIds.filter(
      (id) => candidateEducationsById[id].attributes.candidateId === candidateId
    );

    return candidateEducationIds.map((id) => {
      const {
        relationships: { educationOrganization: { data: educationOrganizationIds = [] } = {} } = {},
        attributes = {},
      } = candidateEducationsById[id];

      const { attributes: educationOrganizationAttributes = {} } =
        educationOrganizationsById[educationOrganizationIds[0]] || {};

      return {
        ...attributes,
        educationOrganization: educationOrganizationAttributes,
      };
    });
  };

  setEduRef = (ref, idx) => {
    this[`candidateEducation${idx}`] = ref;
  };

  addEducation = () => {
    const { candidateEducations } = this.state;

    const newEducations = candidateEducations.slice();

    const newEducation = {
      startedAt: '',
      finishedAt: '',
      degree: '',
      description: '',
      educationOrganization: {
        id: '',
        picture: {
          original: '',
          thumb: '',
          small: '',
          medium: '',
          large: '',
          xlarge: '',
          xxlarge: '',
        },
      },
      extracurricularActivities: [],
      isEditing: true,
    };

    newEducations.unshift(newEducation);

    this.setState({
      candidateEducations: newEducations,
      origCandidateEducations: newEducations,
    });
  };

  handleEducationToggleArray = ({ event, idx: educationIdx }) => {
    const {
      target: { value, name },
    } = event;

    const { candidateEducations: educations } = this.state;

    const array = educations[educationIdx][name].slice();

    const newArray = toggleArray({ array, value });

    const newEducations = educations.slice();

    newEducations[educationIdx][name] = newArray;

    this.setState({ candidateEducations: newEducations });
  };

  handleEducationOrganizationChange = ({ event, idx: educationIdx }) => {
    const {
      target: { value: newValue },
    } = event;

    const { candidateEducations: educations } = this.state;

    const newEducations = educations.slice();

    newEducations[educationIdx].educationOrganization = {
      ...newEducations[educationIdx].educationOrganization,
      name: newValue.name,
      id: newValue.id,
    };

    newEducations[educationIdx].educationOrganizationId = newValue.id;

    this.setState({ candidateEducations: newEducations });
  };

  handleEducationOrganizationBlur = ({ event, idx: educationIdx }) => {
    const {
      target: { value: newValue },
    } = event;

    const { candidateEducations: educations } = this.state;

    const { educationOrganization: origOrg = {} } = educations[educationIdx];

    if (origOrg.name !== newValue) {
      const onFulfilled = ({ educationOrg }) => {
        const newEducations = educations.slice();

        newEducations[educationIdx].educationOrganization = {
          ...newEducations[educationIdx].educationOrganization,
          name: educationOrg.name,
          id: educationOrg.id,
        };

        newEducations[educationIdx].educationOrganizationId = educationOrg.id;

        this.setState({ candidateEducations: newEducations });
      };

      const data = {
        type: 'education_organization',
        attributes: {
          name: newValue.trim(),
        },
      };

      promisePostEducationOrgs({ data }).then(onFulfilled).catch(handleError);
    }
  };

  handleEducationDateInputChange = ({ event, idx: educationIdx }) => {
    const {
      target: { name, value: newValue },
    } = event;

    const { candidateEducations: educations } = this.state;

    const { startedAt: startDate, finishedAt: endDate } = educations[educationIdx];

    const newEducation = {
      startedAt: startDate,
      finishedAt: endDate,
    };

    const startDateObject = startDate ? dayjs(startDate, 'YYYY-MM-DD').toDate() : new Date();
    const endDateObject = endDate ? dayjs(endDate, 'YYYY-MM-DD').toDate() : new Date();

    if (name === 'startDateYear') {
      startDateObject.setFullYear(newValue);
      newEducation.startedAt = startDateObject.toISOString().substring(0, 10);
    }

    if (name === 'endDateYear') {
      endDateObject.setFullYear(newValue);
      newEducation.finishedAt = endDateObject.toISOString().substring(0, 10);
    }

    const newEducations = educations.map((edu, idx) => {
      if (idx === educationIdx) {
        return {
          ...edu,
          ...newEducation,
        };
      }
      return edu;
    });

    this.setState({ candidateEducations: newEducations });
  };

  handleEducationInputChange = ({ event, idx: educationIdx }) => {
    const {
      target: { name, value: newValue },
    } = event;

    const { candidateEducations: educations } = this.state;

    const newEducations = educations.slice();

    newEducations[educationIdx][name] = newValue;

    this.setState({ candidateEducations: newEducations });
  };

  handleCurrentEducationInputChange = ({ event, idx: educationIdx }) => {
    const {
      target: { checked },
    } = event;

    const { candidateEducations: educations } = this.state;

    const newEducations = educations.slice();

    newEducations[educationIdx].finishedAt = checked
      ? null
      : new Date().toISOString().substring(0, 10);

    this.setState({ candidateEducations: newEducations });
  };

  handleEducationDelete = ({ idx }) => {
    const {
      actions: { deleteResource },
    } = this.props;
    const { candidateEducations } = this.state;

    const newEducations = candidateEducations.slice();

    newEducations.splice(idx, 1);

    this.setState({ candidateEducations: newEducations });

    const { id = null } = candidateEducations[idx] || {};
    if (id) {
      deleteResource({
        type: 'candidate_educations',
        slice: 'toolboxCandidates',
        id,
      });
    }
  };

  handleCancelEditingEdu = (idx) => {
    const { candidateEducations, origCandidateEducations } = this.state;

    const origEducation = origCandidateEducations[idx];

    const newCandidateEducations = candidateEducations.slice();

    if (origEducation && origEducation.id) {
      origEducation.isEditing = false;
      newCandidateEducations.splice(idx, 1, origEducation);
      this.setState({
        candidateEducations: newCandidateEducations,
      });
    } else {
      this.handleEducationDelete({ idx });
    }
  };

  handleToggleEditingEdu = (idx) => {
    const { candidateEducations } = this.state;

    if (candidateEducations[idx].isEditing) {
      setTimeout(() => this.handleReorderCandidateEducations({ idx }), 250);
    } else {
      const newCandidateEducations = candidateEducations.map((edu, index) => {
        if (index === idx) {
          return {
            ...edu,
            isEditing: true,
          };
        }
        return edu;
      });

      const origCandidateEducation = candidateEducations[idx];
      const newOrigCandidateEducations = candidateEducations.slice();
      newOrigCandidateEducations.splice(idx, 1, origCandidateEducation);

      this.setState({
        candidateEducations: newCandidateEducations,
        origCandidateEducations: newOrigCandidateEducations,
      });
    }
  };

  handleReorderCandidateEducations = async ({ idx }) => {
    const {
      actions: { entityLoadDone },
      match: {
        params: { id: candidateId },
      },
    } = this.props;

    const { isPostingEducation, reorderAttempts, candidateEducations } = this.state;

    if (isPostingEducation && reorderAttempts < 4) {
      setTimeout(() => {
        this.handleReorderCandidateEducations({ idx });
        this.setState({ reorderAttempts: reorderAttempts + 1 });
      }, 500);
    } else {
      let { id } = candidateEducations[idx];

      if (id) {
        const args = {
          config: {
            params: {
              normalizeIt: true,
            },
          },
          data: {
            attributes: {
              ...candidateEducations[idx],
              candidateId,
            },
            id,
            type: 'candidateEducation',
          },
          id,
          type: 'candidate_educations',
        };

        try {
          await patchEntity(args);
        } catch (error) {
          handleError(error);
        }
      } else {
        const args = {
          config: {
            params: {
              normalizeIt: true,
            },
          },
          data: {
            attributes: {
              ...candidateEducations[idx],
              candidateId,
            },
            type: 'candidateEducation',
          },
          type: 'candidate_educations',
        };

        let allIds;
        try {
          ({ data: { entities: { candidateEducation: { allIds = [] } = {} } = {} } = {} } =
            await postEntity(args));
        } catch (error) {
          handleError(error);
        }

        [id] = allIds;
      }

      const getArgs = {
        type: 'candidates',
        id: candidateId,
        nestedType: 'candidate_educations',
        config: {
          params: {
            includeSet: 'education_organization',
            sortBy: 'finished_at_desc_nulls_first,started_at_desc',
            normalizeIt: true,
          },
        },
      };

      let data;
      let allNestedIds;
      try {
        ({
          data,
          data: {
            entities: { candidateEducation: { allNestedIds = [] } = {} },
          },
        } = await getNestedEntity(getArgs));
      } catch (error) {
        handleError(error);
      }

      const moved = idx !== allNestedIds.indexOf(id);

      if (id) {
        data.entities.candidateEducation.byId[id].attributes.moved = moved;
        data.entities.candidateEducation.byId[id].attributes.isEditing = false;
      }

      entityLoadDone({
        data,
        type: 'candidate_educations',
        slice: 'toolboxCandidates',
      });

      this.setState({ reorderAttempts: 0 });
    }
  };

  makeEducationInputs = (education, idx) => {
    const { isEditing } = education;

    const {
      candidate: { id: candidateId, attributes: { recentGrad = false } = {} } = {},
      actions,
    } = this.props;

    const educationInputsProps = {
      education,
      idx,
      handleEducationToggleArray: this.handleEducationToggleArray,
      handleEducationOrganizationBlur: this.handleEducationOrganizationBlur,
      handleEducationOrganizationChange: this.handleEducationOrganizationChange,
      handleEducationDateInputChange: this.handleEducationDateInputChange,
      handleEducationInputChange: this.handleEducationInputChange,
      handleCurrentEducationInputChange: this.handleCurrentEducationInputChange,
      handleEducationDelete: this.handleEducationDelete,
      handleToggleEditingEdu: this.handleToggleEditingEdu,
      handleCancelEditingEdu: this.handleCancelEditingEdu,
      recentGrad,
    };

    const educationBlockProps = {
      key: idx,
      addWhiteBG: true,
      boxShadow: true,
      addPadding: false,
      noTitlePadding: true,
      largeTitleFont: true,
      className: styles.educationBlock,
    };

    const displayEduProps = {
      setEduRef: (ref) => this.setEduRef(ref, idx),
      education,
      idx,
      handleToggleEditingEdu: this.handleToggleEditingEdu,
      actions,
      candidateId,
      showInlineEditor: true,
    };

    return isEditing ? (
      <Block {...educationBlockProps}>
        <EducationInputs {...educationInputsProps} />
      </Block>
    ) : (
      <Block {...educationBlockProps}>
        <EducationDisplay {...displayEduProps} />
      </Block>
    );
  };

  render() {
    const { candidateEducations: educations } = this.state;

    const hasEducations = educations.length > 0;

    const titleBlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      addPadding: true,
      className: styles.titleBlock,
    };

    const educationBlockProps = {
      noTitlePadding: true,
      largeTitleFont: true,
    };

    const inputContent = hasEducations ? educations.map(this.makeEducationInputs) : null;

    const addEducationButtonProps = {
      primary: true,
      onClick: this.addEducation,
      disabled: educations.some((edu) => edu.isEditing),
    };

    const content = (
      <div className={styles.EditorEducation}>
        <Block {...titleBlockProps}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>Education</div>
            <div className={styles.saveContent}>
              <Button {...addEducationButtonProps}>+ Add Education</Button>
            </div>
          </div>
        </Block>
        <div className={styles.scrollDiv}>
          <Block {...educationBlockProps}>
            <div className={styles.educationInputContainer}>{inputContent}</div>
          </Block>
        </div>
      </div>
    );

    const layoutWithoutSidebarProps = {
      content,
    };

    return <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />;
  }
}

export default CandidateEditorEducation;
