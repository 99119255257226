import { getEntity } from 'api/apiEntity';

const promiseBookmarks = ({ page, includeSet, candidateIds }) => {
  const params = {
    page,
    includeSet,
    candidateId: candidateIds,
    camelizeIt: true,
  };

  const onFulfilled = (response) => {
    const {
      data: {
        bookmarks = [],
        meta: {
          pagination: { currentPage: page, totalCount: total },
        },
      },
    } = response;

    return { bookmarks, page, total };
  };

  const args = {
    type: 'bookmarks',
    config: {
      params,
    },
  };

  return getEntity(args).then(onFulfilled);
};

export default promiseBookmarks;
