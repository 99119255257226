import * as creators from './creators';
import reducer from './reducers';
import selector from './selectors';

const duck = {
  reducer,
  creators,
  selector,
};

export default duck;
