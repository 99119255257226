import React, { Component } from 'react';
import { push } from 'connected-react-router';

import { queryStringParse } from 'utils/paramUtils';

import { createEmployee, createUser } from 'reduxStore/schema';

import Button from 'components/Button';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import InputCheckbox from 'components/InputCheckbox';

import EmployeesTable from './components/EmployeesTable';

import styles from './EmployerEditorEmployees.scss';

class EmployerEditorEmployees extends Component {
  constructor(props) {
    super(props);

    const { location: { search } = {}, employer: { id } = {} } = props;

    const params = {
      sortBy: {
        type: 'string',
        defaultValue: 'created_at_desc',
      },
      userAccountLocked: {
        type: 'boolean',
        defaultValue: false,
      },
    };

    const queryParams = queryStringParse({ search, params });

    const defaultParams = Object.keys(params).reduce((acc, curr) => {
      acc[curr] = params[curr].defaultValue;

      return acc;
    }, {});

    this.state = { ...queryParams };

    this.defaultLoadParams = {
      ...defaultParams,
      employerId: id,
      includeSet: 'employer_company',
      page: 1,
      sortBy: 'created_at_desc',
    };

    this.defaultLoadArgs = {
      type: 'employees',
      slice: 'toolboxEmployers',
    };
  }

  loadMore = () => {
    const {
      actions: { entityLoadStarted },
      employeesPage: page,
    } = this.props;

    entityLoadStarted({
      ...this.defaultLoadArgs,
      params: {
        ...this.getLoadParams(),
        page: page + 1,
      },
    });
  };

  getLoadParams = () => {
    const { page = 1 } = this.props;

    const { userAccountLocked, sortBy } = this.state;

    return {
      ...this.defaultLoadParams,
      userAccountLocked: userAccountLocked || null,
      sortBy,
      page,
    };
  };

  handleSearchCheckboxChange = (event) => {
    const {
      target: { name, checked: value },
    } = event;

    const {
      actions: { entityLoadStarted },
    } = this.props;

    this.setState({ [name]: value }, () =>
      entityLoadStarted({
        ...this.defaultLoadArgs,
        params: {
          ...this.getLoadParams(),
          page: 1,
        },
      })
    );
  };

  render() {
    const {
      actions: { resourceUpdate, showModal },
      dispatch,
      employer: { id } = {},
      employeesTotal: total,
      employees,
      employeesLoading: isLoading,
      modal,
      modal: { originalUrl } = {},
      users,
    } = this.props;

    const { userAccountLocked } = this.state;

    const createEmployeeClick = () => {
      const resource = createEmployee();
      const userResource = createUser();

      resourceUpdate({
        slice: 'toolboxEmployers',
        type: 'employees',
        id: resource.id,
        resource: {
          ...resource,
          attributes: {
            ...resource.attributes,
            employerId: id,
          },
          relationships: {
            user: {
              data: [userResource.id],
            },
          },
        },
      });

      resourceUpdate({
        slice: 'toolboxEmployers',
        type: 'users',
        id: userResource.id,
        resource: {
          ...userResource,
          attributes: {
            ...userResource.attributes,
            currentProfileType: 'Employee',
            currentProfileId: resource.id,
          },
        },
      });

      const route = `/toolbox/employer/${id}/edit-employee/${resource.id}`;

      if (!originalUrl) {
        dispatch(push(route));
      } else {
        showModal({
          key: 'EmployerEditorModal',
          parent: 'ToolboxEmployersPage',
          route,
          originalUrl,
        });
      }
    };

    const createEmployeeProps = {
      className: styles.newEmployeeButton,
      primary: true,
      onClick: createEmployeeClick,
    };

    const attentionRequiredProps = {
      name: 'userAccountLocked',
      label: 'Account Locked',
      checked: userAccountLocked,
      handleInputChange: this.handleSearchCheckboxChange,
      className: styles.checkbox,
      checkboxMany: true,
    };

    const titleContent = (
      <div className={styles.employeesHeader}>
        <InputCheckbox {...attentionRequiredProps} />
        <Button {...createEmployeeProps}>+ Employee</Button>
      </div>
    );

    const employeesTableProps = {
      ...this.state,
      dispatch,
      employees,
      employerId: id,
      isAdmin: true,
      isLoading,
      loadMore: this.loadMore,
      handleSearchCheckboxChange: this.handleSearchCheckboxChange,
      modal,
      modalKey: 'EmployerEditorModal',
      parent: 'ToolboxEmployersPage',
      resourceUpdate,
      showModal,
      titleContent,
      total,
      users,
    };

    const tableContent = <EmployeesTable {...employeesTableProps} />;

    const content = <div className={styles.employeesMainContent}>{tableContent}</div>;

    const layoutWithoutSidebarProps = {
      content,
    };

    return <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />;
  }
}

export default EmployerEditorEmployees;
