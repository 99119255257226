import { createSelector } from 'reselect';
import { getApp } from 'shared/store/app/selectors';

const selectCandidateSettings = createSelector(getApp, (state) => state.candidateSettings);

const selectCandidateCompanyBlocksPage = createSelector(
  selectCandidateSettings,
  (candidateSettings = {}) => candidateSettings.candidateCompanyBlocks.page
);

export default selectCandidateCompanyBlocksPage;
