import { call, put, select, takeLatest } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { patchEntity } from 'api/apiEntity';

import { selectUser } from 'connectors/User/redux/selectors';

import { EMPLOYER_IMG_UPLOAD_STARTED } from '../actions';

import {
  employerLoadDone,
  employerSaveStarted,
  employerSaveDone,
  employerSaveError,
} from '../creators';

const patchEmployerImage = function* patchEmployerImage(action) {
  const {
    payload: { picture },
  } = action;

  yield put(employerSaveStarted());

  const { currentProfile: { employer: { id: employerId } = {} } = {} } = yield select(selectUser);

  try {
    const args = {
      config: {
        params: {
          camelizeIt: true,
        },
      },
      data: {
        type: 'employers',
        id: employerId,
        attributes: {
          picture: {
            file_data: picture,
          },
        },
      },
      id: employerId,
      type: 'employers',
    };

    const {
      data: { employer },
    } = yield call(patchEntity, args);

    yield put(employerLoadDone({ employer }));
    yield put(employerSaveDone());
  } catch (error) {
    handleError(error);

    yield put(employerSaveError(error));
  }
};

const sagaEmployerImageUpload = function* sagaEmployerImageUpload() {
  yield takeLatest(EMPLOYER_IMG_UPLOAD_STARTED, patchEmployerImage);
};

export default sagaEmployerImageUpload;
