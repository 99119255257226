import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import isEmpty from 'lodash.isempty';

import { ComponentErrorBoundaryHOC } from 'containers/HOC';

import Bold from 'components/Bold';
import Button from 'components/Button';
import DialogueModal from 'components/DialogueModal';
import FontIcon from 'components/FontIcon';

import styles from './FeaturedButtons.scss';

// needIntroButton boolean controls the display of
// the request intro button.
// True means we need to show the Request Intro button with active onClick handler
// False means we need to show Contact Requested
// Undefined means show the Request Intro button but disable onClick handler
// Null means do not show anything

const VIEWS_WITH_BOOKMARK = ['featured', 'bookmarked', 'locked_link'];

export class FeaturedButtons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  render() {
    const {
      bookmark,
      candidateId,
      doneLoading,
      firstName,
      lastName,
      employeePatchStarted,
      handleBookmarkCandidate,
      handleHideSearchCandidate,
      handleRemoveBookmarkCandidate,
      handleRequestIntroClick,
      handleUndoHideSearchCandidate,
      hide = false,
      hideCandidatePopupSeen,
      isAdmin,
      isCandidateLoading,
      isEmployee,
      loggedInEmployerId,
      needIntroButton,
      parentView,
      requestSent,
    } = this.props;

    const { showModal } = this.state;

    const employerPresent = isEmployee || (isAdmin && loggedInEmployerId);

    const contactButtonProps = {
      key: 'contactButton',
      className: styles.profileContactButton,
      primary: true,
      onClick:
        needIntroButton === undefined ? () => null : () => handleRequestIntroClick({ isAdmin }),
    };

    const actionButton =
      requestSent || needIntroButton === false ? (
        <div key="contactedButton" className={styles.contactedNote}>
          <FontIcon className={styles.seafoam} iconName="checkmark-circle" />
          Contact Request Sent!
        </div>
      ) : (
        <Button {...contactButtonProps}>
          <span>Request Intro</span>
        </Button>
      );

    const fakeActionButton = <div key="fakeButton" className={styles.fakeContactOne} />;

    const actionButtonContent = needIntroButton !== null ? actionButton : fakeActionButton;

    const finalActionButtonContent = employerPresent ? actionButtonContent : null;

    const adminHideOnClick =
      isAdmin && loggedInEmployerId
        ? () => {
            handleHideSearchCandidate({
              firstName,
              lastName,
              candidateId,
              loggedInEmployerId,
            });
            this.setState({ needIntroButton: null });
          }
        : () => null;

    const employeeHideOnClick = isEmployee
      ? () => {
          handleHideSearchCandidate({
            candidateId,
            firstName,
            lastName,
          });
          this.setState({ needIntroButton: null });
        }
      : null;

    const hideOnClick =
      isEmployee && !hideCandidatePopupSeen
        ? () => this.setState({ showModal: true })
        : employeeHideOnClick || adminHideOnClick;

    const undoHideOnClick = hide
      ? () => {
          this.setState({ needIntroButton: true });
          handleUndoHideSearchCandidate({ candidateId });
        }
      : null;

    const hideButtonOnClick =
      needIntroButton === undefined ? () => null : undoHideOnClick || hideOnClick;

    const hideClasses = classnames({
      [styles.hideButton]: !hide,
      [styles.undoButton]: hide || isAdmin,
    });

    const hideButtonProps = {
      key: 'hide',
      quinary: true,
      className: hideClasses,
      onClick: hideButtonOnClick,
    };

    const showHideButton =
      employerPresent && needIntroButton !== false && parentView === 'featured';

    const hideText = hide ? 'Undo' : 'Hide';

    const hideButton = <Button {...hideButtonProps}>{hideText}</Button>;

    const hideActionButton = showHideButton ? hideButton : null;

    const hasBookmark = !isEmpty(bookmark);

    const bookmarkClasses = classnames({
      [styles.notBookmarked]: !hasBookmark,
      [styles.bookmarked]: hasBookmark,
    });

    const bookmarkOnClick = hasBookmark
      ? () =>
          handleRemoveBookmarkCandidate({
            candidateId,
            bookmarkId: bookmark.id,
          })
      : () => handleBookmarkCandidate({ candidateId });

    const bookmarkButtonProps = {
      tertiary: true,
      active: hasBookmark,
      className: bookmarkClasses,
      onClick: bookmarkOnClick,
      key: 'bookmark',
    };

    const bookmarkText = hasBookmark ? 'Saved' : 'Save';

    const bookmarkContent =
      isEmployee && VIEWS_WITH_BOOKMARK.includes(parentView) && !hide ? (
        <Button {...bookmarkButtonProps}>{bookmarkText}</Button>
      ) : null;

    const bottomButtonsContent =
      doneLoading && (requestSent || needIntroButton === false) ? null : (
        <div key="bottomButtons" className={styles.row}>
          {hideActionButton}
          {bookmarkContent}
        </div>
      );

    const cancelButtonProps = {
      onClick: () => this.setState({ showModal: false }),
      quaternary: true,
    };

    const cancelButton = <Button {...cancelButtonProps}>Cancel</Button>;

    const modalHideButtonProps = {
      onClick: () => {
        this.setState({ showModal: false });
        employeePatchStarted({
          name: 'hideCandidatePopupSeen',
          newValue: true,
        });
        employeeHideOnClick();
      },
      primary: true,
    };

    const modalHideButton = <Button {...modalHideButtonProps}>Hide</Button>;

    const hideCandidateModal = showModal ? (
      <DialogueModal>
        <div className={styles.requestIntroModal}>
          <div className={styles.requestIntroContent}>
            Hidden candidates can be found on the <Bold>Manage Candidates</Bold> page with the{' '}
            <Bold>&ldquo;Archived&rdquo;</Bold> status selected.
          </div>
          <div className={styles.requestIntroButtons}>
            {cancelButton}
            {modalHideButton}
          </div>
        </div>
      </DialogueModal>
    ) : null;

    const secondFakeButton =
      parentView === 'featured' ? (
        <div key="fakeButton2" className={styles.fakeContactTwo} />
      ) : null;

    return !isCandidateLoading && doneLoading ? (
      <Fragment>
        {finalActionButtonContent}
        {bottomButtonsContent}
        {hideCandidateModal}
      </Fragment>
    ) : (
      <Fragment>
        <div key="fakeButton1" className={styles.fakeContact} />
        {secondFakeButton}
      </Fragment>
    );
  }
}

export default ComponentErrorBoundaryHOC()(FeaturedButtons);
