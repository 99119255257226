/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import pic1 from './assets/1-enterprise-account-executive-job-information.png';
import pic2 from './assets/2-contact-rainmakers-enterprise-executive-jobs.png';
import pic3 from './assets/3-enterprise-account-exec.png';
import pic4 from './assets/4-find-enterprise-account-executive-job.png';
import pic5 from './assets/5-recruiters-for-enterprise-account-executive-jobs.png';
import pic6 from './assets/6-create-profile-with-rainmakers-browse-jobs.png';

import styles from './EAEJobsContent.scss';

export function EAEJobsContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt="" />

      <h2>Enterprise Account Executive Jobs</h2>

      <p>
        Enterprise Account Executives work for businesses across various sectors, including some of
        the biggest manufacturers, telecommunications corporations, software companies, and retail
        chains worldwide. Because the position carries a significant amount of responsibility and
        can be a sometimes high-pressure environment, Enterprise Account Executives have the
        potential to earn considerable money. Selling to enterprise accounts requires more than
        charm and clever closing tricks. Customers at all levels are becoming better informed, more
        receptive to your rivals’ pitches, and are continuously looking to avoid “being sold to.” As
        a result, the connection between the customer and seller has changed somewhat. To meet
        customers where they are in this new buying environment, a business needs a well-prepared,
        dedicated, and capable Enterprise Account Executive to help nurture and maintain a healthy
        sales relationship with enterprise accounts.
      </p>

      <h2>What is an Enterprise Account?</h2>

      <p>
        It’s natural to assume that enterprise accounts refer to those customers who bring in the
        largest amounts of revenue. While that definition isn’t necessarily incorrect, it is
        incomplete.
      </p>

      <p>
        A significant sale to a single customer may bring in considerable revenue, but they might
        also be a one-time customer. That would not be considered an enterprise account. Instead, an
        enterprise account would include a revenue-generating customer that is reliable, repeating,
        and well-established in their industry. Enterprise accounts can provide significant revenue
        over time for little additional work once the initial business relationship is established.
      </p>

      <p>
        On top of that, an enterprise account has some additional characteristics. For example,
        they’re usually influential entities in their industry, have a complex internal
        organization, and possess a budget to readily adopt enhanced features and services.
      </p>

      <p>
        Landing such accounts is not always easy. When a business manages to land such an account,
        the enterprise account must be given special care and attention by a qualified Enterprise
        Account Executive armed with excellent management skills.
      </p>

      <Link to="/apply-now" title="Apply Now">
        <img src={pic2} alt="" />
      </Link>

      <h2>What Does an Enterprise Account Executive Do?</h2>
      <p>
        As the job title suggests, an Enterprise Account Executive prospects and manages the sales
        process with the select enterprise accounts.
      </p>

      <p>
        While Account Executives are salespeople who work to close business with new and existing
        customers, Enterprise Account Executives typically have significantly more responsibilities,
        such as selling to a company’s largest and most key accounts.
      </p>

      <p>
        Duties often include meeting with prospective clients offering support and solutions, making
        presentations, arranging training where applicable, assisting with advertising and PR
        campaigns, and interacting with the client’s various departments such as finance,
        management, production, and sales.
      </p>

      <p>
        An effective Enterprise Account Executive must be skilled at planning and organizing, have
        excellent verbal and written communication skills, and possess experience and polish that
        gives them the attention of some of the largest companies in the world.
      </p>

      <p>
        Those are the essentials. Individual businesses will have varying expectations. For
        instance:
      </p>

      <ul>
        <li>
          <i>Business #1</i> expects its Enterprise Account Executives to generate new leads,
          establish and manage relationships with company executives and clients, help build sales
          process management procedures, take on marketing responsibilities, and develop and present
          sales strategies and proposals.
        </li>
        <li>
          <i>Business #2</i> wants its Enterprise Account Executives to develop territory plans for
          enhancing sales, maintain sales pipelines, and manage contract negotiations. They’re also
          expected to be able to build long-term, strategic relationships with the business’s key
          accounts.
        </li>
      </ul>

      <img src={pic3} alt="" />

      <h2>Key Responsibilities</h2>

      <p>
        Enterprise Account Executives play a significant role in achieving a business’s sales
        targets and objectives. Here are some of the most critical responsibilities that need to be
        performed by them:
      </p>

      <ul>
        <li>Attract and cultivate new Enterprise customers to enhance sales.</li>
        <li>Increase sales by developing innovative strategies.</li>
        <li>
          Assist in generating new leads by effectively managing current connections and networking
          with contacts.
        </li>
        <li>Negotiate prices in sales-related contracts.</li>
        <li>Maintain robust relationships with existing clients.</li>
        <li>Develop and present product or service presentations to potential new accounts.</li>
        <li>Maintain accurate sales records, reports, and other relevant customer information</li>
        <li>
          Have an intimate understanding of their business’s products and services. Keep current
          with the latest industry trends and news.
        </li>
        <li>Attend sales meetings and training programs.</li>
      </ul>

      <h2>Essential Skills For an Enterprise Account Executive</h2>

      <p>
        Because Enterprise Account Executives are primarily involved in sales and maintaining
        customer relations, strong communication skills are a must. Skills in creating and
        presenting product or service presentations are also essential.
      </p>

      <p>
        A good Enterprise Account Executive should possess excellent analytical skills and be able
        to think creatively. This will help them innovate strategies to improve the sales figures.
        These same skills, along with a good dose of empathy, will assist an Enterprise Account
        Executive in identifying and attracting new clients and understanding their needs.
      </p>

      <p>In addition, an Enterprise Account Executive should possess the following qualities:</p>

      <ol>
        <li>Have a strong background in closing new business</li>
        <li>Be experienced in sales and contract negotiation, especially with deals over $100k</li>
        <li>Be self-motivated</li>
        <li>Be able to handle high-pressure situations</li>
        <li>Be able to work in a target-oriented environment</li>
      </ol>

      <img src={pic4} alt="" />

      <h2>Prerequisites For Enterprise Account Executives</h2>

      <p>
        While not much is said about degrees of higher learning being necessary for general sales
        positions, it’s highly recommended in the case of Enterprise Account Executives.
      </p>

      <p>
        Although there are different educational requirements for Enterprise Account Executives,
        most openings call for at least a bachelor’s degree in business, communications, or
        marketing. A master’s degree isn’t often necessary, but it can help make you stand out among
        others.
      </p>

      <p>In addition, candidates should have experience in:</p>

      <ul>
        <li>Customer service</li>
        <li>B2B selling</li>
        <li>Complex sales cycles</li>
        <li>Computer networking</li>
      </ul>

      <p>They should also already possess:</p>

      <ul>
        <li>Superb analytical skills</li>
        <li>Technical aptitude</li>
        <li>Strong organizational skills</li>
        <li>Excellent interpersonal skills</li>
      </ul>

      <p>
        The most effective Enterprise Account Executives are self-driven and have an entrepreneurial
        mindset. They must feel at ease when working in sometimes hectic settings. Sales positions
        typically involve a lot of pressure, mainly when dealing with large clients or territories.
        The best strategy for acquiring an Enterprise Account Executive position is to first build
        experience as a standard Account Executive with an excellent track record for success.
      </p>

      <p>
        Experience is probably the most essential thing to have. No one starts their sales career at
        the enterprise level.
      </p>

      <img src={pic5} alt="" />

      <h2>How Much Do Enterprise Account Executives Earn?</h2>

      <p>
        On average, in the US, Enterprise Account Executives earn a yearly base salary of
        approximately $110,000. The range itself can be anywhere from $74,000 to $160,000 annually.
        Factors determining where you might fall in this range include experience, effectiveness,
        and the individual business. Additional earnings could come from commissions, bonuses, and
        profit sharing. On average, extra earnings for this position come to about $100,000 per
        year.
      </p>

      <h2>Are There Positions Available for Enterprise Account Executives?</h2>

      <p>
        According to the US Bureau of Labor Statistics, Enterprise Account Executive positions are
        expected to grow by about 5% over the next few years. This is considered average. It is
        believed that by 2028, the number of available Enterprise Account Executive opportunities in
        the US will be about 20,600.
      </p>

      <p>
        That’s a good number, but you must keep the competition in mind. More people are seeking
        those positions than are available.
      </p>

      <p>
        As with many desirable jobs that feature significant amounts of responsibility, becoming an
        Enterprise Account Executive requires effort and determination. Sometimes, while pursuing
        the position, individuals decide they want to fine-tune their ambitions to better match
        their skill sets and temperament. Or, they may wish to obtain some further advanced
        experience before tackling something as substantial as an Enterprise Account Executive
        position.
      </p>

      <p>
        Possible alternate career tracks could include sales positions such as territory sales
        representatives, senior sales representatives, and area sales representatives.
      </p>

      <p>
        If you’ve decided that becoming an Enterprise Account Executive is the goal you aspire to,
        you may wish to consider the following cities as places where Enterprise Account Executives
        tend to earn the most.
      </p>

      <ul>
        <li>San Francisco, CA</li>
        <li>Arlington, VA</li>
        <li>Chicago, IL</li>
        <li>New York City, NY</li>
        <li>Santa Cruz, CA</li>
        <li>Santa Rosa, CA</li>
        <li>Sunnyvale, CA</li>
      </ul>

      <Link to="/apply-now" title="Apply Now">
        <img src={pic6} alt="" />
      </Link>

      <h2>Taking On a Top Sales Position</h2>

      <p>
        Taking on the position of Enterprise Account Executive means taking on relationships with
        some of the country's biggest, most influential companies—maybe in the world. It’s a
        position that presents many challenges, but it can deliver substantial financial and
        personal rewards for the right candidate.
      </p>

      <p>
        If you have the right skills, experience, and attitude, you can become a significant player
        in your industry, help major companies find solutions and spur growth, and celebrate being
        at the top of the sales game.
      </p>

      <p>
        If you are an employer{' '}
        <a href={`https://${process.env.HOST}/employers`}>
          looking for Enterprise Account Executives
        </a>{' '}
        to join your company, or if you are currently seeking{' '}
        <a href={`https://${process.env.HOST}/apply-now/`}>new career opportunities</a>, reach out
        to Rainmakers!
      </p>
    </div>
  );
}
