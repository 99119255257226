import { all, fork } from 'redux-saga/effects';

import patchUserResourceSaga from './patchUserResourceSaga';
import patchUserEmailSaga from './patchUserEmailSaga';

const userResourceSaga = function* resourceSaga() {
  yield all([fork(patchUserResourceSaga), fork(patchUserEmailSaga)]);
};

export default userResourceSaga;
