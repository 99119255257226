import React, { Fragment } from 'react';

import { createSuggestionSet } from 'reduxStore/schema';

import AdminData from 'components/AdminData';
import Bold from 'components/Bold';
import Button from 'components/Button';
import FontIcon from 'components/FontIcon';
import StatusFilter from 'components/StatusFilter';
import Table from 'components/Table';
import TableRow from 'components/Table/components/TableRow';
import TableCell from 'components/Table/components/TableCell';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';

import styles from '../../../../ToolboxRequisitionEditor.scss';

const STATUSES = [
  { label: 'All', value: null },
  { label: 'Complete', value: true },
  { label: 'Not Complete', value: false },
];

const SuggestionSets = ({
  complete,
  employersById,
  handleChangeState,
  handleSetState,
  loadMoreSuggestionSets,
  rainmakersAdmins: { byId: rainmakersAdminsById = {} } = {},
  resourceUpdate,
  suggestionSets: { byId = {}, allIds = [] },
  suggestionSetsLoading,
  suggestionSetsTotal,
}) => {
  const tableHeader = [
    {
      content: 'Rainmakers Admin',
      headerClassName: styles.statusHeader,
    },
    {
      content: 'Employer',
      headerClassName: styles.statusHeader,
    },
    {
      content: 'Complete?',
      headerClassName: styles.statusHeader,
    },
  ];

  const makeCells = (suggestionSet = {}) => {
    const {
      attributes: { complete } = {},
      relationships: {
        employer: { data: employerIds = [] } = {},
        rainmakersAdmin: { data: rainmakersAdminIds = [] } = {},
      } = {},
    } = suggestionSet;

    const { attributes: { name: employerName } = {} } = employersById[employerIds[0]] || {};

    const { attributes: admin = {} } = rainmakersAdminsById[rainmakersAdminIds[0]] || {};

    const adminDataProps = { admin };

    const adminDataContent = <AdminData {...adminDataProps} />;

    const completeContent = complete ? <FontIcon iconName="checkmark-circle" /> : '-';

    return (
      <Fragment>
        <TableCell flex key={1}>
          {adminDataContent}
        </TableCell>
        <TableCell flex center key={2}>
          {employerName}
        </TableCell>
        <TableCell flex center key={3}>
          {completeContent}
        </TableCell>
      </Fragment>
    );
  };

  const makeRows = (suggestionSet) => (
    <TableRow id={suggestionSet.id} key={suggestionSet.id}>
      {makeCells(suggestionSet)}
    </TableRow>
  );

  const suggestionSets = allIds.map((id) => byId[id]);

  const tableContent = suggestionSets.filter((set) => !set.attributes.temporary).map(makeRows);

  const statusFilterProps = {
    statuses: STATUSES,
    value: complete,
    handleChangeStatus: handleChangeState,
  };

  const createSuggestionSetClick = () => {
    const resource = createSuggestionSet();

    resourceUpdate({
      slice: 'toolboxRequisitions',
      type: 'suggestion_sets',
      id: resource.id,
      resource,
    });

    handleSetState({ selectedSuggestionSet: resource });
  };

  const createProps = {
    onClick: createSuggestionSetClick,
    primary: true,
    className: styles.newCandButton,
  };

  const filtersContent = (
    <div className={styles.header}>
      <div className={styles.top}>
        <div className={styles.left}>
          <span className={styles.text}>
            <Bold>Suggestion Sets </Bold>with status:
          </span>
          <StatusFilter {...statusFilterProps} />
        </div>
        <Button {...createProps}>+ Create Suggestion Set</Button>
      </div>
    </div>
  );

  const infiniteScrollProps = {
    loadMore: loadMoreSuggestionSets,
    hasMore: suggestionSetsTotal > allIds.length,
    loader: suggestionSetsLoading ? (
      <div key="infiniteScrollLoader" />
    ) : (
      <InfiniteScrollLoader key="infiniteScrollLoader" />
    ),
    useWindow: false,
  };

  const tableProps = {
    titleContent: filtersContent,
    tableContent,
    status: complete,
    header: tableHeader,
    infiniteScrollProps,
    tableName: 'adminSuggestionSets',
    isEmpty: !suggestionSetsLoading && allIds.length === 0,
    emptyTextString: 'No Suggestion Sets',
    isLoading: suggestionSetsLoading,
  };

  return <Table {...tableProps} />;
};

export default SuggestionSets;
