import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import Button from 'components/Button';
import FontIcon from 'components/FontIcon';
import ConnectContainer from 'containers/ConnectContainer';

import duck from './redux';

import styles from './ReadyContainer.scss';

const ReadyContainer = ({
  actions: { resetBackLink },
  backLink = {},
  children,
  className,
  id,
  scroll,
  ['data-testid']: dataTestId,
}) => {
  const backLinkName = backLink.title || 'Back';

  const linkProps = backLink.link
    ? {
        to: backLink.link,
        onClick: resetBackLink,
        className: styles.backButton,
        key: 'backButton',
      }
    : {
        onClick: resetBackLink,
        className: styles.backButton,
        key: 'backButton',
      };

  const readyContainerProps = {
    id,
    className: classnames(
      styles.ReadyContainer,
      {
        [styles.backLinkPresent]: backLink.link,
        [styles.scroll]: scroll,
      },
      className
    ),
    key: 'readyContainer',
    ['data-testid']: dataTestId,
  };

  return (
    <>
      {backLink.link && (
        <Link {...linkProps}>
          <Button primary className={styles.backButton}>
            <FontIcon iconName="caret-left" /> {backLinkName}
          </Button>
        </Link>
      )}
      <div {...readyContainerProps}>{children}</div>
    </>
  );
};

export default ConnectContainer(duck)(ReadyContainer);
