import React from 'react';
import classnames from 'classnames';

import Bolder from 'components/Bolder';

import styles from './ContentTitle.scss';

const ContentTitle = ({ children, className }) => {
  const classes = classnames(styles.ContentTitle, className);

  return (
    <div className={classes}>
      <Bolder>{children}</Bolder>
    </div>
  );
};

export default ContentTitle;
