import React, { Component } from 'react';

import { toggleArray } from 'utils/formUtils';

import Block from 'components/Block';

import styles from '../../ToolboxRequisitionEditor.scss';

import DealsAverage from './components/DealsAverage';
import ClosingExperienceMonths from './components/ClosingExperienceMonths';
import DesiredBackground from './components/DesiredBackground';
import DontHireFromCompanies from './components/DontHireFromCompanies';
import StartupExperience from './components/StartupExperience';
import HireFromCompanies from './components/HireFromCompanies';
import MustHaves from './components/MustHaves';
import PreviousRoles from './components/PreviousRoles';
import ProductTypes from './components/ProductTypes';
import QuotaAmount from './components/QuotaAmount';
import RecentGrad from './components/RecentGrad';
import SalesExperienceMonths from './components/SalesExperienceMonths';
import SoldToCompanies from './components/SoldToCompanies';
import SoldToCompanySizes from './components/SoldToCompanySizes';
import SoldToDepartments from './components/SoldToDepartments';
import SoldToIndustries from './components/SoldToIndustries';

class RequisitionRequirement extends Component {
  handlePatchResource = ({ attributes }) => {
    const {
      actions: { patchResource },
      requisitionRequirement: { id },
      slice,
    } = this.props;

    patchResource({
      id,
      attributes,
      normalizeIt: true,
      slice,
      type: 'requisition_requirements',
    });
  };

  handleAddArrayInput = (arrayName) => {
    const {
      actions: { resourceUpdate },
      requisitionRequirement = {},
      requisitionRequirement: { attributes: { [arrayName]: arrayToUpdate = [] } = {}, id } = {},
      slice,
    } = this.props;

    const newValue = arrayToUpdate || [];
    newValue.push('');

    const resource = {
      ...requisitionRequirement,
      attributes: {
        ...requisitionRequirement.attributes,
        [arrayName]: newValue,
      },
    };

    resourceUpdate({
      type: 'requisition_requirement',
      slice,
      id,
      resource,
    });
  };

  handleAddArrayValue = (event, arrayName) => {
    const {
      target: { name, value },
    } = event;
    const {
      requisitionRequirement: { attributes: { [arrayName]: arrayToUpdate = [] } = {} } = {},
    } = this.props;

    let newValue = arrayToUpdate ? arrayToUpdate.slice() : [];

    newValue[name] = value;

    if (newValue.every((el) => !el)) {
      newValue = [];
    }

    this.handlePatchResource({ attributes: { [arrayName]: newValue } });
  };

  handleDeleteArrayInput = (indexToRemove, arrayName) => {
    const {
      requisitionRequirement: { attributes: { [arrayName]: arrayToUpdate } = {} } = {},
    } = this.props;

    const newValue = arrayToUpdate
      ? arrayToUpdate.filter((id, index) => index !== indexToRemove)
      : [];

    this.handlePatchResource({ attributes: { [arrayName]: newValue } });
  };

  handleReorderArray = ({ startIndex, endIndex, name }) => {
    const {
      requisitionRequirement: { attributes: { [name]: arrayToUpdate } = {} } = {},
    } = this.props;

    const newArray = arrayToUpdate ? arrayToUpdate.slice() : [];

    const [removed] = newArray.splice(startIndex, 1);

    newArray.splice(endIndex, 0, removed);

    this.handlePatchResource({ attributes: { [name]: newArray } });
  };

  handleToggleArrayValue = (event) => {
    const {
      target: { name, value },
    } = event;

    const {
      requisitionRequirement: { attributes: { [name]: arrayToUpdate } = {} } = {},
    } = this.props;

    const newArray = toggleArray({ array: arrayToUpdate, value });

    this.handlePatchResource({ attributes: { [name]: newArray } });
  };

  handleArrayChange = (event) => {
    const {
      target: { value, value: { type } = {}, name },
    } = event;

    const { [type]: newValue } = value;

    const { requisitionRequirement: { attributes: { [name]: array } = {} } = {} } = this.props;

    const newArray = toggleArray({ array: array || [], value: newValue });

    this.handlePatchResource({ attributes: { [name]: newArray } });
  };

  handleExperienceInputChange = (event) => {
    const {
      target: { years, months, name },
    } = event;

    const realYears = years || years === 0 ? parseInt(years, 10) : null;

    const realMonths = months || months === 0 ? parseInt(months, 10) : null;

    const expInMonths =
      realYears === null || realMonths === null ? null : realYears * 12 + realMonths;

    this.handlePatchResource({ attributes: { [name]: expInMonths } });
  };

  handleInputChange = (event) => {
    const {
      target: { name, type, value, checked },
    } = event;

    const newValue = type === 'checkbox' ? checked : value;

    this.handlePatchResource({ attributes: { [name]: newValue } });
  };

  render() {
    const {
      requisitionOpening: { id, attributes: { role, state } = {} } = {},
      requisitionRequirement = {},
      pickerOptions: {
        industry: industryOptions = [],
        department: departmentOptions = [],
        size: sizeOptions = [],
        previousRoles: previousRolesOptions = [],
      } = {},
    } = this.props;

    const isSdr = role === 'SDR';

    const blockProps = {
      addWhiteBG: true,
      boxShadow: true,
      largeTitleFont: true,
      className: styles.requirementBlock,
    };

    const candidateBackgroundBlockProps = {
      ...blockProps,
      title: 'Candidate Background',
    };

    const salesBackgroundBlockProps = {
      ...blockProps,
      title: 'Sales Background',
    };

    const commonProps = {
      departmentOptions,
      disabled: state === 'closed' || !id,
      handleInputChange: this.handleInputChange,
      handleExperienceInputChange: this.handleExperienceInputChange,
      handleArrayChange: this.handleArrayChange,
      handleToggleArrayValue: this.handleToggleArrayValue,
      handleAddArrayInput: this.handleAddArrayInput,
      handleAddArrayValue: this.handleAddArrayValue,
      handleDeleteArrayInput: this.handleDeleteArrayInput,
      handleReorderArray: this.handleReorderArray,
      industryOptions,
      previousRolesOptions,
      requisitionRequirement,
      state,
      sizeOptions,
    };

    const previousRolesInput = isSdr ? (
      <RecentGrad {...commonProps} />
    ) : (
      <PreviousRoles {...commonProps} />
    );

    const backgroundContent = (
      <Block {...candidateBackgroundBlockProps}>
        <DesiredBackground {...commonProps} />
        <MustHaves {...commonProps} />
        <StartupExperience {...commonProps} />
        <SalesExperienceMonths {...commonProps} />
        <ClosingExperienceMonths {...commonProps} />
        {previousRolesInput}
      </Block>
    );

    const salesContent = (
      <Block {...salesBackgroundBlockProps}>
        <DealsAverage {...commonProps} />
        <QuotaAmount {...commonProps} />
        <ProductTypes {...commonProps} />
        <SoldToIndustries {...commonProps} />
        <SoldToDepartments {...commonProps} />
        <SoldToCompanySizes {...commonProps} />
        <SoldToCompanies {...commonProps} />
        <HireFromCompanies {...commonProps} />
        <DontHireFromCompanies {...commonProps} />
      </Block>
    );

    return (
      <div className={styles.ReqFormBlocks}>
        {backgroundContent}
        {salesContent}
      </div>
    );
  }
}

export default RequisitionRequirement;
