import { handleActions } from 'redux-actions';

const initialState = {
  entities: {
    batchRequest: {
      allIds: [],
      byId: {},
    },
  },
  meta: {
    batchRequest: {
      pagination: {},
    },
  },
};

const reduceToolboxBatchRequests = handleActions({}, initialState);

export default reduceToolboxBatchRequests;
