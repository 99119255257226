import React from 'react';

import { getInitials } from 'utils/generalUtils';

import Button from 'components/Button';
import DialogueModal from 'components/DialogueModal';
import ErrorMessage from 'components/ErrorMessage';

import styles from './RequestConfirmationModal.scss';

const RequestConfirmationModal = ({
  errorMessage,
  firstName,
  lastName,
  handleRequestConfirmationCloseClick,
  hideNames,
}) => {
  const actionButton = errorMessage ? (
    <Button onClick={() => handleRequestConfirmationCloseClick({ hasError: true })} primary>
      Continue
    </Button>
  ) : (
    <Button onClick={() => handleRequestConfirmationCloseClick({ hasError: false })} primary>
      Got it
    </Button>
  );

  const title = 'Request Sent';

  const name = hideNames ? getInitials({ firstName, lastName }) : firstName;

  return errorMessage ? (
    <DialogueModal>
      <div className={styles.requestConfirmationModal}>
        <div className={styles.requestConfirmationTitle}>Error</div>

        <div className={styles.requestConfirmationContent}>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </div>
        <div className={styles.requestConfirmationButtons}>{actionButton}</div>
      </div>
    </DialogueModal>
  ) : (
    <DialogueModal>
      <div className={styles.requestConfirmationModal}>
        <div className={styles.requestConfirmationTitle}>{title}</div>

        <div className={styles.requestConfirmationContent}>
          <span className={styles.candName}>{name}</span> will be contacted and you&apos;ll be
          updated with a response soon.
        </div>
        <div className={styles.requestConfirmationButtons}>{actionButton}</div>
      </div>
    </DialogueModal>
  );
};

export default RequestConfirmationModal;
