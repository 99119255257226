import React, { Fragment } from 'react';
import Slider from 'react-rangeslider';

import Input from 'components/Input';
import InputCheckbox from 'components/InputCheckbox';
import Label from 'components/Label';

import styles from '../ReqRequirement.scss';

const ClosingExperienceMonths = ({
  requisitionRequirement: {
    attributes: {
      closingExperienceMonthsMin,
      closingExperienceMonthsMax,
      closingExperienceMonthsWt,
      closingExperienceRequired,
    } = {},
  } = {},
  disabled,
  handleExperienceInputChange,
  handleInputChange,
}) => {
  const validYearExp = (number) => (number !== null && number !== '') || number === 0;

  const closingExpYearsMin =
    closingExperienceMonthsMin || closingExperienceMonthsMin === 0
      ? Math.floor(closingExperienceMonthsMin / 12)
      : null;
  const closingExpYearsMax =
    closingExperienceMonthsMax || closingExperienceMonthsMax === 0
      ? Math.floor(closingExperienceMonthsMax / 12)
      : null;

  const closingExpYearsMinInputProps = {
    handleInputChange: (event) =>
      handleExperienceInputChange({
        ...event,
        target: {
          years: event.target.value,
          months: 0,
          name: 'closingExperienceMonthsMin',
        },
      }),
    name: 'closingExperienceMonthsMin',
    value: validYearExp(closingExpYearsMin) ? closingExpYearsMin : '',
    placeholder: '',
    size: 'full',
    type: 'text',
    min: '0',
    max: '99',
    className: styles.inputContainer,
    disabled: disabled || !closingExperienceRequired,
    ultraDisabled: !closingExperienceRequired,
  };

  const closingExpYearsMaxInputProps = {
    handleInputChange: (event) =>
      handleExperienceInputChange({
        ...event,
        target: {
          years: event.target.value,
          months: 0,
          name: 'closingExperienceMonthsMax',
        },
      }),
    name: 'closingExperienceMonthsMax',
    value: validYearExp(closingExpYearsMax) ? closingExpYearsMax : '',
    placeholder: '',
    size: 'full',
    type: 'text',
    min: '0',
    max: '99',
    className: styles.inputContainer,
    disabled: disabled || !closingExperienceRequired,
    ultraDisabled: !closingExperienceRequired,
  };

  const closingExperienceRequiredProps = {
    name: 'closingExperienceRequired',
    label: 'Does this role require closing experience?',
    checked: closingExperienceRequired,
    handleInputChange,
    checkboxMany: true,
    disabled,
  };

  const closingExperienceMonthsWtProps = {
    disabled: disabled || !closingExperienceRequired,
    className: styles.rangeSlider,
    min: 0,
    max: 10,
    step: 5,
    value: closingExperienceMonthsWt || 0,
    onChange:
      disabled || !closingExperienceRequired
        ? () => null
        : (value) =>
            handleInputChange({
              target: {
                value,
                name: 'closingExperienceMonthsWt',
              },
            }),
    tooltip: false,
    labels: {
      0: '0',
      5: '5',
      10: '10',
    },
  };

  const closingExperienceMonthsContent = (
    <div className={styles.rangeInputs}>
      <div className={styles.column}>
        <Label className={closingExperienceRequired ? styles.labelStyle : styles.disabled}>
          How many years of closing experience?
        </Label>
        <div className={styles.yearMonthContainer}>
          <div className={styles.yearContainer}>
            <Input {...closingExpYearsMinInputProps} />
            <Label> - </Label>
            <Input {...closingExpYearsMaxInputProps} />
            <Label className={closingExperienceRequired ? styles.labelStyle : styles.disabled}>
              years
            </Label>
          </div>
        </div>
      </div>
      <div className={styles.column}>
        <Label className={closingExperienceRequired ? styles.labelStyle : styles.disabled}>
          How much do you value this?
        </Label>
        <Slider {...closingExperienceMonthsWtProps} />
      </div>
    </div>
  );

  return (
    <Fragment>
      <div className={styles.checkbox}>
        <InputCheckbox {...closingExperienceRequiredProps} />
      </div>
      {closingExperienceMonthsContent}
    </Fragment>
  );
};

export default ClosingExperienceMonths;
