import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import styles from './Button.scss';

const Button = ({
  children,
  className,
  disabled,
  fakeDisabled,
  primary,
  warning,
  warningTertiary,
  fullWidth,
  right,
  left,
  secondary,
  tertiary,
  tertiaryThin,
  quaternary,
  quinary,
  to,
  fullUrl,
  sameTab,
  centered,
  circle,
  smallCircle,
  active,
  onClick,
  fakeButton,
  highlight,
  ...otherProps
}) => {
  const classes = classnames(
    styles.Button,
    {
      [styles.primary]: primary,
      [styles.secondary]: secondary,
      [styles.centered]: centered,
      [styles.tertiary]: tertiary,
      [styles.tertiaryThin]: tertiaryThin,
      [styles.quaternary]: quaternary,
      [styles.quinary]: quinary,
      [styles.circle]: circle,
      [styles.smallCircle]: smallCircle,
      [styles.fullWidth]: fullWidth,
      [styles.right]: right,
      [styles.left]: left,
      [styles.warning]: warning,
      [styles.warningTertiary]: warningTertiary,
      [styles.active]: active,
      [styles.highlight]: highlight,
      [styles.disabled]: disabled,
      [styles.fakeDisabled]: fakeDisabled,
    },
    className
  );

  const buttonProps = {
    className: classes,
    onClick: disabled ? null : onClick,
    ['aria-selected']: active,
    ...otherProps,
  };

  const buttonContent = fakeButton ? (
    <div {...buttonProps}>{children}</div>
  ) : (
    <button type="button" {...buttonProps}>
      {children}
    </button>
  );

  if (to) {
    const target = sameTab ? '_self' : '_blank';

    return fullUrl ? (
      <a href={to} rel="noreferrer" target={target}>
        {buttonContent}
      </a>
    ) : (
      <Link onClick={onClick} to={to}>
        {buttonContent}
      </Link>
    );
  }

  return buttonContent;
};

export default Button;
