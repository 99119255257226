import { v4 } from 'uuid';

export const createCandidateCompanyBlock = () => {
  const id = v4();

  return {
    attributes: {
      id,
      temporary: true,
    },
    id,
    type: 'candidateCompanyBlock',
  };
};

export const createSavedSearch = () => {
  const id = v4();

  return {
    attributes: {
      id,
      title: '',
      temporary: true,
    },
    id,
    type: 'savedSearch',
  };
};

export const createRequisitionOpening = () => {
  const id = v4();

  return {
    attributes: {
      id,
      headcount: 1,
      state: 'pending',
      employerId: '',
      externalName: null,
      temporary: true,
    },
    id,
    type: 'requisitionOpening',
  };
};

export const createSuggestionSet = () => {
  const id = v4();

  return {
    attributes: {
      id,
      complete: null,
      rainmakersAdminId: null,
      requisitionOpeningId: null,
      temporary: true,
    },
    id,
    type: 'suggestionSet',
  };
};

export const createEducationOrganization = () => {
  const id = v4();

  return {
    attributes: {
      id,
      name: '',
      temporary: true,
    },
    id,
    type: 'educationOrganization',
  };
};

export const createCompany = () => {
  const id = v4();

  return {
    attributes: {
      id,
      name: '',
      email: '',
      temporary: true,
    },
    id,
    type: 'company',
  };
};

export const createEmployer = () => {
  const id = v4();

  return {
    attributes: {
      id,
      name: '',
      email: '',
      temporary: true,
    },
    id,
    type: 'employer',
  };
};

export const createEmployee = () => {
  const id = v4();

  return {
    attributes: {
      id,
      firstName: '',
      lastName: '',
      temporary: true,
    },
    id,
    type: 'employee',
  };
};

export const createRainmakersAdmin = () => {
  const id = v4();

  return {
    attributes: {
      id,
      firstName: '',
      lastName: '',
      temporary: true,
    },
    id,
    type: 'rainmakersAdmin',
  };
};

export const createCandidate = () => {
  const id = v4();

  return {
    attributes: {
      id,
      firstName: '',
      lastName: '',
      temporary: true,
    },
    id,
    type: 'candidate',
  };
};

export const createUser = () => {
  const id = v4();

  return {
    attributes: {
      id,
      email: '',
      temporary: true,
    },
    id,
    type: 'user',
  };
};

export const createCohort = () => {
  const id = v4();

  return {
    attributes: {
      id,
      defaultCohortEmployerState: 'enabled',
      temporary: true,
    },
    id,
    type: 'cohort',
  };
};
