import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import { handleError } from 'utils/common';

import { promiseGetRequisitionCandidates } from 'pages/EmployeePortal/CandidateProfilePages/promises';

import EmptyAvatar from 'shared/assets/missing-user-picture.png';

import ConnectContainer from 'containers/ConnectContainer';

import duck from 'shared/store/app';

import styles from './FeaturedCandidate.scss';

const FeaturedCandidate = ({
  hideNames,
  candidate: { id: candidateId } = {},
  candidate,
  selected,
  handleOnClick,
  handleOnLoad,
}) => {
  const [noReqCand, setNoReqCand] = useState(null);

  useEffect(() => {
    if (hideNames) {
      promiseGetRequisitionCandidates({ candidateId })
        .then((reqCandidates) => setNoReqCand(reqCandidates.length === 0))
        .catch((error) => {
          handleError(error);
          setNoReqCand(true);
        });
    }
  }, [candidateId, hideNames]);

  const { picture: { xlarge } = {}, hide = false } = candidate || {};

  const picUrl = !xlarge || xlarge === '' ? EmptyAvatar : xlarge;

  const useBlur = xlarge && hideNames && (noReqCand || noReqCand === null);

  const classes = classnames(styles.resultImage, {
    [styles.selected]: selected,
    [styles.blur]: useBlur,
  });

  return selected ? (
    <div className={styles.container}>
      <div className={classes} onClick={handleOnClick}>
        <img src={picUrl} onLoad={handleOnLoad} alt="" />
        {hide && <div className={styles.hideContainer} />}
      </div>
      <div className={styles.selectedDiv} />
    </div>
  ) : (
    <div className={classes} onClick={handleOnClick}>
      <img src={picUrl} onLoad={handleOnLoad} alt="" />
      {hide && <div className={styles.hideContainer} />}
    </div>
  );
};

export default ConnectContainer(duck)(FeaturedCandidate);
