import { call, select, put, take, cancel, fork } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { handleError } from 'utils/common';
import { trackEvent } from 'utils/analytics';
import { segmentRegistrationComplete } from 'segment/eventNames';

import { candidateProfilePatchDone, setDelayProfileCards } from 'connectors/User/redux/creators';

import { setNotification } from 'shared/store/app/creators';

import { selectUser } from 'connectors/User/redux/selectors';

import { patchEntity } from 'api/apiEntity';

import { ADD_VIDEO_STARTED } from '../actions';

import { serverErrorSet } from '../creators';

const VALID_STATUSES = [
  'applied',
  'applied_forum',
  'set_requirements',
  'created_profile',
  'added_sales_info',
];

export const addVideoStarted = function* addVideoStarted(action) {
  const {
    payload: { skippedVideo },
  } = action;

  const user = yield select(selectUser);

  const {
    currentProfile: { id: candidateId, onboardingStatus },
  } = user;

  const notificationText = `
    Top candidates will be contacted directly by their personal Rainmakers concierge within 1-2 business days. Be on the lookout for a call or email and make sure to reply quickly for your approval.

    In the meantime, please review your profile to ensure everything looks accurate.
  `;

  yield put(setDelayProfileCards({ delayProfileCards: true }));

  yield put(
    setNotification({
      title: 'Thanks for applying',
      text: notificationText,
      confirmText: 'Got it',
    })
  );

  try {
    const newVideoStatus = skippedVideo ? 'skipped_video' : 'added_video';
    const newOnboardingStatus = VALID_STATUSES.includes(onboardingStatus)
      ? newVideoStatus
      : onboardingStatus;

    if (newOnboardingStatus !== onboardingStatus) {
      yield call(trackEvent, { event: segmentRegistrationComplete });
    }

    const args = {
      id: candidateId,
      type: 'candidates',
      data: {
        type: 'candidates',
        id: candidateId,
        attributes: {
          onboardingStatus: newOnboardingStatus,
        },
      },
      config: {
        params: {
          camelizeIt: true,
        },
      },
    };

    const {
      data: { candidate },
    } = yield call(patchEntity, args);

    const candidateProfile = {
      ...user.currentProfile,
      ...candidate,
    };

    yield put(candidateProfilePatchDone({ candidateProfile }));

    yield put(push('/candidate/home'));
  } catch (error) {
    handleError(error);

    const {
      response: {
        data: { errors: { full_messages: errors = ['Please try again'] } = {} } = {},
      } = {},
    } = error || {};

    yield put(serverErrorSet({ errors }));
  }
};

const sagaAddVideoStarted = function* sagaAddVideoStarted() {
  let lastTask;
  let lastAction;
  while (true) {
    const action = yield take(ADD_VIDEO_STARTED);
    if (lastTask && lastAction.payload.type === action.payload.type) {
      yield cancel(lastTask);
    }
    lastAction = action;
    lastTask = yield fork(addVideoStarted, action);
  }
};

export default sagaAddVideoStarted;
