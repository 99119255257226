import { getEntity } from 'api/apiEntity';

const promiseSearchOptions = () => {
  const params = {
    normalizeIt: true,
  };

  const onFulfilled = ({ data: { candidateSearch = {} } }) => candidateSearch;

  const args = {
    type: 'data_store/search',
    config: {
      params,
    },
  };

  return getEntity(args).then(onFulfilled);
};

export default promiseSearchOptions;
