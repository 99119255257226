import React from 'react';

import Content from 'components/Content';
import ContentTitle from 'components/ContentTitle';
import { ReferralSourceSelect } from 'components/ReferralSourceSelect';

import styles from './YourDefaultReferralSource.scss';

const YourDefaultReferralSource = (props) => {
  const {
    actions: { patchUserResource },
  } = props;
  const {
    rainmakersAdmin: {
      id,
      attributes: { defaultReferralSource },
    },
  } = props;

  const handleOnChange = (value) => {
    patchUserResource({
      attributes: {
        defaultReferralSource: value,
      },
      id,
      slice: 'toolboxAdmins',
      type: 'rainmakers_admins',
    });
  };

  return (
    <Content className={styles.container}>
      <ContentTitle>Default Referral Source</ContentTitle>
      <Content className={styles.detailContainer}>
        <ReferralSourceSelect
          defaultReferralSource={defaultReferralSource}
          onChange={handleOnChange}
        />
      </Content>
    </Content>
  );
};

export default YourDefaultReferralSource;
