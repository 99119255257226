import React, { Component } from 'react';

import { toggleArray } from 'utils/formUtils';

import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import Block from 'components/Block';

import EmployerApproachesTable from './components/EmployerApproachesTable';

import styles from './EmployerEditorStats.scss';

class EmployerEditorStats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      state: ['active', 'candidate_pass_pending'],
    };
  }

  handleSearchFilterChange = (event) => {
    const {
      target: { name, value },
    } = event;
    const {
      actions: { entityLoadStarted },
      match: {
        params: { id: employerId },
      },
    } = this.props;

    const { [name]: arrayToUpdate } = this.state;

    const actualValue = value[value.type];

    const newArray = toggleArray({ array: arrayToUpdate, value: actualValue });

    this.setState({ [name]: newArray }, () =>
      entityLoadStarted({
        type: 'requisition_candidates',
        slice: 'toolboxEmployers',
        params: {
          page: 1,
          employerId,
          sortBy: 'created_at_desc',
          includeSet: 'admin_toolbox',
          state: newArray,
        },
      })
    );
  };

  loadMoreRequisitionCandidates = () => {
    const {
      actions: { entityLoadStarted },
      requisitionCandidatesPage: page,
      match: {
        params: { id: employerId },
      },
    } = this.props;

    const { state } = this.state;

    entityLoadStarted({
      type: 'requisition_candidates',
      slice: 'toolboxEmployers',
      params: {
        page: page + 1,
        employerId,
        sortBy: 'created_at_desc',
        includeSet: 'admin_toolbox',
        state,
      },
    });
  };

  render() {
    const {
      actions,
      candidates,
      employees,
      match: {
        params: { id: employerId },
      },
      privateReqCanFeedback,
      requisitionOpenings,
      requisitionCandidates,
      requisitionCandidatesTotal,
    } = this.props;

    const { state } = this.state;

    const idBlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      largeTitleFont: true,
      title: 'Employer ID',
      addPadding: true,
    };

    const employerApproachesProps = {
      actions,
      containerRef: this.tableContainer || null,
      candidates,
      employees,
      privateReqCanFeedback,
      requisitionCandidates,
      requisitionCandidatesTotal,
      requisitionOpenings,
      handleSearchFilterChange: this.handleSearchFilterChange,
      loadMoreRequisitionCandidates: this.loadMoreRequisitionCandidates,
      state,
    };

    const content = (
      <div className={styles.EditorStats}>
        <Block {...idBlockProps}>{employerId}</Block>

        <div
          ref={(ref) => {
            this.tableContainer = ref;
          }}
          className={styles.content}
        >
          <EmployerApproachesTable {...employerApproachesProps} />
        </div>
      </div>
    );

    const layoutWithoutSidebarProps = {
      content,
    };

    return <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />;
  }
}

export default EmployerEditorStats;
