import { createAction } from 'redux-actions';

import { USER_PATCH_DONE, SAVE_UTM_INFO } from 'shared/store/app/actions';

import { CANDIDATE_PATCH_STARTED } from 'pages/CandidatePortal/CandidateEditorPages/redux/actions';

import {
  APPLY_STARTED,
  APPLY_DONE,
  FORUM_APPLY_STARTED,
  FORUM_APPLY_DONE,
  ADD_VIDEO_STARTED,
  SET_REQUIREMENTS_STARTED,
  SET_REQUIREMENTS_DONE,
  ADD_SALES_INFO_STARTED,
  ADD_SALES_INFO_DONE,
  CREATE_LEAD_STARTED,
  REORDER_WORK_STARTED,
  REORDER_WORK_DONE,
  REORDER_EDUCATION_STARTED,
  REORDER_EDUCATION_DONE,
  WORK_HISTORY_DELETE_STARTED,
  WORK_HISTORY_DELETE_DONE,
  CANDIDATE_EDUCATION_DELETE_STARTED,
  CANDIDATE_EDUCATION_DELETE_DONE,
  ONBOARDING_ERRORS_CHECK,
  ONBOARDING_ERRORS_SET,
  UPLOAD_RESUME_STARTED,
  UPLOAD_RESUME_DONE,
  PARSE_RESUME_STARTED,
  PARSE_RESUME_DONE,
  SERVER_ERROR_SET,
  CANDIDATE_IMG_UPLOAD_STARTED,
  CANDIDATE_IMG_UPLOAD_DONE,
  PATCH_CANDIDATE_STARTED,
  PATCH_CANDIDATE_DONE,
  PATCH_CANDIDATE_PERSONAL_STARTED,
  PATCH_CANDIDATE_PERSONAL_DONE,
  PATCH_WORK_HISTORY_STARTED,
  PATCH_WORK_HISTORY_DONE,
  RECENT_ROLE_SET,
} from '../actions';

export const applyStarted = createAction(APPLY_STARTED);
export const applyDone = createAction(APPLY_DONE);

export const forumApplyStarted = createAction(FORUM_APPLY_STARTED);
export const forumApplyDone = createAction(FORUM_APPLY_DONE);

export const setRequirementsStarted = createAction(SET_REQUIREMENTS_STARTED);
export const setRequirementsDone = createAction(SET_REQUIREMENTS_DONE);

export const addSalesInfoStarted = createAction(ADD_SALES_INFO_STARTED);
export const addSalesInfoDone = createAction(ADD_SALES_INFO_DONE);

export const addVideoStarted = createAction(ADD_VIDEO_STARTED);

export const createLeadStarted = createAction(CREATE_LEAD_STARTED);

export const userPatchDone = createAction(USER_PATCH_DONE);

export const workHistoryDeleteStarted = createAction(WORK_HISTORY_DELETE_STARTED);
export const workHistoryDeleteDone = createAction(WORK_HISTORY_DELETE_DONE);

export const candidateEducationDeleteStarted = createAction(CANDIDATE_EDUCATION_DELETE_STARTED);
export const candidateEducationDeleteDone = createAction(CANDIDATE_EDUCATION_DELETE_DONE);

export const reorderWorkStarted = createAction(REORDER_WORK_STARTED);
export const reorderWorkDone = createAction(REORDER_WORK_DONE);

export const reorderEducationStarted = createAction(REORDER_EDUCATION_STARTED);
export const reorderEducationDone = createAction(REORDER_EDUCATION_DONE);

export const patchWorkHistoryStarted = createAction(PATCH_WORK_HISTORY_STARTED);
export const patchWorkHistoryDone = createAction(PATCH_WORK_HISTORY_DONE);

export const serverErrorSet = createAction(SERVER_ERROR_SET);
export const onboardingErrorsCheck = createAction(ONBOARDING_ERRORS_CHECK);
export const onboardingErrorsSet = createAction(ONBOARDING_ERRORS_SET);

export const candidateImageUploadStarted = createAction(CANDIDATE_IMG_UPLOAD_STARTED);
export const candidateImageUploadDone = createAction(CANDIDATE_IMG_UPLOAD_DONE);

export const uploadResumeStarted = createAction(UPLOAD_RESUME_STARTED);
export const uploadResumeDone = createAction(UPLOAD_RESUME_DONE);

export const parseResumeStarted = createAction(PARSE_RESUME_STARTED);
export const parseResumeDone = createAction(PARSE_RESUME_DONE);

export const patchCandidateStarted = createAction(PATCH_CANDIDATE_STARTED);
export const patchCandidateDone = createAction(PATCH_CANDIDATE_DONE);

export const candidatePatchStarted = createAction(CANDIDATE_PATCH_STARTED);

export const patchCandidatePersonalStarted = createAction(PATCH_CANDIDATE_PERSONAL_STARTED);
export const patchCandidatePersonalDone = createAction(PATCH_CANDIDATE_PERSONAL_DONE);

export const saveUTMInfo = createAction(SAVE_UTM_INFO);

export const recentRoleSet = createAction(RECENT_ROLE_SET);
