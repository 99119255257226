import React, { useState } from 'react';

import { VisibleHOC } from 'containers/HOC';

import PrivateReqCanFeedbackEmployerPass from 'connectors/DialogModals/PrivateReqCanFeedbackEmployerPass';
import PrivateReqCanFeedbackEmployerHire from 'connectors/DialogModals/PrivateReqCanFeedbackEmployerHire';

import Dropdown from 'components/Dropdown';

const ActionDropdown = ({
  actions: { showModal, patchResource },
  isVisible,
  id,
  privateReqCanFeedback,
  firstName,
  lastName,
}) => {
  const [promptType, setPromptType] = useState(null);

  const onChange = (value) => {
    switch (value) {
      case 'manage':
        return showModal({
          parent: 'DashboardPage',
          route: `/requisition_candidates/${id}/requisition_opening/preview`,
          key: 'ReqDetailModal',
        });
      default:
        return setPromptType(value);
    }
  };

  const source = [
    {
      label: 'View Req',
      value: 'manage',
    },
    {
      label: 'Hired',
      value: 'hire',
    },
    {
      label: 'Pass',
      value: 'pass',
    },
  ];

  const dropDownProps = {
    above: !isVisible,
    source,
    onChange,
    value: 'send_message',
    defaultValue: 'Actions',
    left: true,
    actions: true,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
  };

  const handleCancel = () => setPromptType(null);

  const passModalProps = {
    firstName,
    lastName,
    handleSubmit: (attributes) => {
      setPromptType(null);

      const { passedOn, rating, feedback } = attributes;

      patchResource({
        attributes: {
          passedOn,
          state: 'employer_passed',
        },
        id,
        slice: 'employerCandidates',
        type: 'requisition_candidates',
      });

      if (privateReqCanFeedback.id) {
        patchResource({
          attributes: {
            rating,
            feedback,
          },
          id: privateReqCanFeedback.id,
          slice: 'employerCandidates',
          type: 'private_req_can_feedbacks',
        });
      }
    },
    handleCancel,
  };

  const hireModalProps = {
    firstName,
    lastName,
    handleSubmit: (attributes) => {
      setPromptType(null);

      patchResource({
        attributes: {
          ...attributes,
          state: 'hired',
        },
        id,
        type: 'requisition_candidates',
        slice: 'employerCandidates',
      });

      if (privateReqCanFeedback.id) {
        patchResource({
          attributes: {
            rating: 10,
          },
          id: privateReqCanFeedback.id,
          slice: 'employerCandidates',
          type: 'private_req_can_feedbacks',
        });
      }
    },
    handleCancel,
  };

  const hireModal =
    promptType === 'hire' ? <PrivateReqCanFeedbackEmployerHire {...hireModalProps} /> : null;
  const passModal =
    promptType === 'pass' ? <PrivateReqCanFeedbackEmployerPass {...passModalProps} /> : null;

  const promptContent = hireModal || passModal;

  return (
    <div style={{ width: '100%' }}>
      <Dropdown {...dropDownProps} />
      {promptContent}
    </div>
  );
};

export default VisibleHOC()(ActionDropdown);
