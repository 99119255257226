import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Helmet } from 'react-helmet-async';
import classnames from 'classnames';
import isEmpty from 'lodash.isempty';

import { EmployeeAuthHOC } from 'containers/HOC';

import ConnectContainer from 'containers/ConnectContainer';

import ReadyContainer from 'connectors/ReadyContainer';

import { employerHomePageValues } from 'utils/constants';

import AutocompleteServer from 'connectors/AutocompleteServer';
import Block from 'components/Block';
import Bolder from 'components/Bolder';
import Button from 'components/Button';
import FontIcon from 'components/FontIcon';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import Sidebar from 'components/Sidebar';

import CandidateNonModalWrapper from 'pages/EmployeePortal/CandidateProfilePages/CandidateNonModalWrapper';

import FeaturedCandidate from './components/FeaturedCandidate';

import duck from './redux';

import styles from './FeaturedPage.scss';

class FeaturedPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      employerName: '',
      selectedCandidateId: '',
      initialized: false,
      numCandidatesToDisplay: 1,
      displayStart: 0,
      scrollDirection: 'right',
      disableArrows: false,
      showArrows: false,
      topLoaded: false,
    };

    this.candidateWidth = 96;
  }

  componentDidMount() {
    const {
      actions: { featuredCandidatesLoadStarted },
      employerId,
    } = this.props;

    window.addEventListener('resize', this.handleUpdateSize.bind(this));

    featuredCandidatesLoadStarted({
      page: 1,
      employerId,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { initialized } = this.state;
    const { featuredCandidates: { allIds = [] } = {}, featuredCandidatesLoading } = nextProps;

    if (
      !initialized &&
      allIds.length > 0 &&
      this.featuredCandidatesContainer &&
      !featuredCandidatesLoading
    ) {
      const containerWidth = this.featuredCandidatesContainer.offsetWidth;

      const origCandidatesToDisplay = Math.floor(containerWidth / this.candidateWidth);

      const showArrows = allIds.length > origCandidatesToDisplay;

      const newContainerWidth = showArrows ? containerWidth - 192 : containerWidth;

      const numCandidatesToDisplay = Math.floor(newContainerWidth / this.candidateWidth);

      this.setState({
        selectedCandidateId: allIds[0],
        initialized: true,
        numCandidatesToDisplay,
        showArrows,
      });
    }
  }

  handleUpdateSize = () => {
    const { featuredCandidates: { allIds = [] } = {} } = this.props;
    const { showArrows: prevShowArrows } = this.state;

    if (allIds.length > 0 && this.featuredCandidatesContainer) {
      const containerWidth = this.featuredCandidatesContainer.offsetWidth;

      const origCandidatesToDisplay = Math.floor(containerWidth / this.candidateWidth);

      const showArrows = allIds.length > origCandidatesToDisplay;

      let newContainerWidth = containerWidth;

      if (showArrows && !prevShowArrows) {
        newContainerWidth -= 192;
      }

      if (!showArrows && prevShowArrows) {
        newContainerWidth += 192;
      }

      const numCandidatesToDisplay = Math.floor(newContainerWidth / this.candidateWidth);

      this.setState({
        numCandidatesToDisplay,
        showArrows,
      });
    }
  };

  handleBookmarkCandidate = ({ candidateId }) => {
    const {
      actions: { bookmarksAddStarted },
    } = this.props;

    bookmarksAddStarted({ candidateId, viewedFromPage: 'matches' });
  };

  handleRemoveBookmarkCandidate = ({ bookmarkId, candidateId }) => {
    const {
      actions: { bookmarksRemoveStarted },
    } = this.props;

    bookmarksRemoveStarted({ bookmarkId, candidateId });
  };

  loadMoreFeaturedCandidates = () => {
    const {
      actions: { featuredCandidatesLoadStarted },
      featuredCandidatesPage,
      featuredCandidatesTotal,
      featuredCandidates: { allIds = [] },
      employerId,
    } = this.props;

    const { numCandidatesToDisplay, displayStart } = this.state;

    if (allIds.length < featuredCandidatesTotal) {
      const newPage = featuredCandidatesPage + 1;

      featuredCandidatesLoadStarted({
        page: newPage,
        employerId,
      });
    }

    this.setState({
      scrollDirection: 'right',
      disableArrows: true,
      displayStart: displayStart + numCandidatesToDisplay,
    });

    setTimeout(() => {
      this.setState({
        disableArrows: false,
      });
    }, 750);
  };

  handlePassFeaturedCandidate = ({ firstName, lastName, candidateId, loggedInEmployerId }) => {
    const {
      actions: { featuredCandidatePassStarted, adminFeaturedCandidatePassStarted },
    } = this.props;

    if (loggedInEmployerId) {
      adminFeaturedCandidatePassStarted({
        firstName,
        lastName,
        candidateId,
        loggedInEmployerId,
      });
    } else {
      featuredCandidatePassStarted({
        candidateId,
        firstName,
        lastName,
      });
    }
  };

  handleUndoPassFeaturedCandidate = ({ candidateId }) => {
    const {
      actions: { featuredCandidateUndoPassStarted },
      featuredCandidates: {
        byId: { [candidateId]: { attributes: { candidateCompanyBlockId } = {} } = {} } = {},
      } = {},
    } = this.props;

    if (candidateCompanyBlockId) {
      featuredCandidateUndoPassStarted({
        candidateCompanyBlockId,
        candidateId,
      });
    }
  };

  handleEmployerChange = (event) => {
    const {
      actions: { featuredCandidatesLoadStarted, setEmulatedEmployer },
    } = this.props;

    const {
      target: { value },
    } = event;

    const { id: employerId, name: employerName } = value;

    setEmulatedEmployer({ employer: value });

    this.setState({
      employerName,
      selectedCandidateId: '',
      initialized: false,
      displayStart: 0,
      scrollDirection: 'right',
    });

    featuredCandidatesLoadStarted({
      page: 1,
      employerId,
    });
  };

  handleClearEmployer = () => {
    const {
      actions: { featuredCandidatesLoadStarted, resetEmulatedEmployer },
    } = this.props;

    resetEmulatedEmployer();

    this.setState({
      employerName: null,
      selectedCandidateId: '',
      initialized: false,
      displayStart: 0,
      scrollDirection: 'right',
    });

    featuredCandidatesLoadStarted({ page: 1 });
  };

  render() {
    const {
      employerName,
      selectedCandidateId,
      numCandidatesToDisplay,
      displayStart,
      scrollDirection,
      disableArrows,
      showArrows,
      topLoaded,
    } = this.state;

    const {
      featuredCandidates: { allIds = [], byId = {} },
      featuredCandidatesLoading,
      isAdmin,
      isEmployee,
      employer: { name: currEmployerName = '' } = {},
      user: { currentProfile: { employer: { homepage } = {} } = {} } = {},
    } = this.props;

    const hideBrowseFeaturedCandidates =
      isEmployee &&
      Array.isArray(employerHomePageValues[homepage]?.disabled_pages) &&
      employerHomePageValues[homepage].disabled_pages.includes('/featured');

    const excludedLinks =
      isEmployee && homepage && Array.isArray(employerHomePageValues[homepage]?.excluded_pages)
        ? employerHomePageValues[homepage].excluded_pages
        : [];

    const candidates = allIds.map((id) => byId[id].attributes);

    const candidatesToDisplay = candidates.slice(
      displayStart,
      displayStart + numCandidatesToDisplay
    );

    const results = candidatesToDisplay.map((cand, idx) => {
      const featuredCandidateProps = {
        selected: cand.id === selectedCandidateId,
        candidate: cand,
        handleOnClick: () => this.setState({ selectedCandidateId: cand.id }),
        handleOnLoad:
          idx === 0 ? () => setTimeout(() => this.setState({ topLoaded: true }), 700) : () => null,
      };

      return <FeaturedCandidate key={cand.id} {...featuredCandidateProps} />;
    });

    const moreCandidatesProps = {
      onClick: disableArrows ? () => null : this.loadMoreFeaturedCandidates,
      disabled: displayStart + numCandidatesToDisplay >= candidates.length,
      circle: true,
      className: styles.buttonRight,
    };

    const moreCandidatesButton = showArrows ? (
      <Button {...moreCandidatesProps}>
        <FontIcon iconName="caret-right" />
      </Button>
    ) : null;

    const lessCandidatesProps = {
      onClick: disableArrows
        ? () => null
        : () => {
            const newStart =
              displayStart - numCandidatesToDisplay <= 0
                ? 0
                : displayStart - numCandidatesToDisplay;
            this.setState({
              scrollDirection: 'left',
              displayStart: newStart,
              disableArrows: true,
            });

            setTimeout(() => {
              this.setState({
                disableArrows: false,
              });
            }, 750);
          },
      disabled: displayStart === 0,
      circle: true,
      className: styles.buttonRight,
    };

    const lessCandidatesButton = showArrows ? (
      <Button {...lessCandidatesProps}>
        <FontIcon iconName="caret-left" />
      </Button>
    ) : null;

    const transitionName =
      scrollDirection === 'right'
        ? {
            enter: styles.enterRight,
            enterActive: styles.enterActiveRight,
            leave: styles.leaveRight,
            leaveActive: styles.leaveActiveRight,
          }
        : {
            enter: styles.enterLeft,
            enterActive: styles.enterActiveLeft,
            leave: styles.leaveLeft,
            leaveActive: styles.leaveActiveLeft,
          };

    const groupKey = candidatesToDisplay[0] ? candidatesToDisplay[0].id : 'init';

    const groupClasses = classnames(styles.candidatesGroup);

    const finalResults =
      results.length === 0 && !featuredCandidatesLoading ? null : (
        <React.Fragment>
          {lessCandidatesButton}
          <div
            className={styles.featuredCandidatesContainer}
            ref={(container) => {
              this.featuredCandidatesContainer = container;
            }}
          >
            <CSSTransition timeout={{ enter: 750, exit: 750 }} classNames={transitionName}>
              <div key={groupKey} className={groupClasses}>
                {results}
              </div>
            </CSSTransition>
          </div>
          {moreCandidatesButton}
        </React.Fragment>
      );

    const setEmployerInputProps = {
      placeholder: 'Select an employer to emulate',
      size: 'full',
      name: 'employer_id',
      value: employerName || '',
      handleInputChange: this.handleEmployerChange,
      autocompleteType: 'employers',
      field: 'name',
      needReset: true,
    };

    const clearEmployerProps = {
      quaternary: true,
      onClick: this.handleClearEmployer,
      className: styles.clearButton,
    };

    const clearEmployerButton = <Button {...clearEmployerProps}>Clear</Button>;

    const employerResultsContent = currEmployerName ? (
      <div className={styles.employerResultsContainer}>
        <div className={styles.employerResults}>
          Showing candidates for <Bolder>{currEmployerName}</Bolder>
        </div>
        {clearEmployerButton}
      </div>
    ) : null;

    const setEmployerInput = isAdmin ? (
      <div key="setEmployerInput" className={styles.autocomplete}>
        <AutocompleteServer {...setEmployerInputProps} />
        {employerResultsContent}
      </div>
    ) : null;

    const selectedCandidate = candidates.find((cand) => cand.id === selectedCandidateId) || {};

    const candidateProfileProps = {
      handleBookmarkCandidate: this.handleBookmarkCandidate,
      handleRemoveBookmarkCandidate: this.handleRemoveBookmarkCandidate,
      parentView: 'matches',
      handlePassFeaturedCandidate: this.handlePassFeaturedCandidate,
      handleUndoPassFeaturedCandidate: this.handleUndoPassFeaturedCandidate,
      candidate: selectedCandidate.loaded ? selectedCandidate : {},
      isMatchLoading: !topLoaded,
      key: 'candidateProfile',
      match: {
        params: {
          id: selectedCandidateId,
          view: 'profile',
        },
      },
    };

    const contentBlockProps = {
      title: finalResults,
      boxShadow: true,
      addWhiteBG: true,
      addFlex: true,
      addChildFlex: true,
      addBottomFade: true,
    };

    const candidateContent = !isEmpty(selectedCandidate) ? (
      <CandidateNonModalWrapper {...candidateProfileProps} />
    ) : null;

    const featuredProfile =
      !featuredCandidatesLoading && candidates.length === 0 ? (
        <div key="emptyProfile" className={styles.emptyContainer}>
          <div className={styles.emptyTableString}>No Matched Candidates</div>
          {!hideBrowseFeaturedCandidates && (
            <Button primary={true} to={'/featured'}>
              Browse Featured Candidates
            </Button>
          )}
        </div>
      ) : (
        candidateContent
      );

    const content = !isAdmin ? (
      <div className={styles.featuredMainContent}>
        <Block {...contentBlockProps}>{featuredProfile}</Block>
      </div>
    ) : (
      <div className={styles.featuredMainContent}>
        <Block {...contentBlockProps}>
          {setEmployerInput}
          {featuredProfile}
        </Block>
      </div>
    );

    return (
      <>
        <Helmet key="helmet" title="Matched Candidates" />
        <ReadyContainer key="readyContainer" className={styles.FeaturedPage}>
          <Sidebar type="search" page="featuredCandidates" excludedLinks={excludedLinks} />

          <LayoutWithoutSidebar content={content} />
        </ReadyContainer>
      </>
    );
  }
}

export default ConnectContainer(duck)(EmployeeAuthHOC()(FeaturedPage));
