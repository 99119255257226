import { handleActions } from 'redux-actions';

import { REQUISITION_DETAILS_LOAD_DONE } from 'connectors/RequisitionFilter/redux/actions';

const initialState = {
  data: [],
};

const handleRequisitionDetailsLoadDone = (state = initialState, action) => {
  const {
    payload: { requisitionDetails, page, total },
  } = action;

  if (page === 1) {
    return {
      data: [...initialState.data, ...requisitionDetails],
      page,
      total,
    };
  }

  return {
    data: [...state.data, ...requisitionDetails],
    page,
    total,
  };
};

const actionHandlers = {
  [REQUISITION_DETAILS_LOAD_DONE]: handleRequisitionDetailsLoadDone,
};

const reduceRequisitionDetails = handleActions(actionHandlers, initialState);

export default reduceRequisitionDetails;
