import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';

import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import Sidebar from 'components/Sidebar';
import Title from 'components/Title';

import { CandidateAuthHOC } from 'containers/HOC';
import ConnectContainer from 'containers/ConnectContainer';

import ReadyContainer from 'connectors/ReadyContainer';

import duck from '../redux';
import styles from '../Settings.scss';

import InterviewRequests from './components/InterviewRequests';
import EmailsFromRainmakers from './components/EmailsFromRainmakers';

@CandidateAuthHOC()
class EmailSettings extends Component {
  componentDidMount() {
    const {
      actions: { candidateLoadStarted },
    } = this.props;

    candidateLoadStarted();
  }

  handleCandidateInputChange = (event) => {
    const {
      target: { value, name, checked, type },
    } = event;

    const newValue = type === 'checkbox' ? checked : value;

    const {
      actions: { candidateProfilePatchStarted },
    } = this.props;

    candidateProfilePatchStarted({
      name,
      newValue,
    });
  };

  render() {
    const {
      candidate: { productUpdates = false, blogUpdates = false, profileLocked = false } = {},
    } = this.props;

    const emailsFromRainmakersProps = {
      profileLocked,
      productUpdates,
      blogUpdates,
      handleCandidateInputChange: this.handleCandidateInputChange,
    };

    const content = (
      <div className={styles.WelcomePage}>
        <Title className={styles.title}>Manage Email Notifications</Title>

        <InterviewRequests />

        <EmailsFromRainmakers {...emailsFromRainmakersProps} />
      </div>
    );

    const layoutWithoutSidebarProps = {
      content,
    };

    const sidebarProps = {
      type: 'candidateSettings',
      page: 'email-settings',
    };

    return (
      <React.Fragment>
        <Helmet title="Email Settings" />
        <ReadyContainer className={styles.EmployerStatusPage}>
          <Sidebar {...sidebarProps} />
          <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />
        </ReadyContainer>
      </React.Fragment>
    );
  }
}

export default ConnectContainer(duck)(EmailSettings);
