import React, { Fragment } from 'react';

import { camelToHuman } from 'utils/generalUtils';

import Button from 'components/Button';
import Content from 'components/Content';
import ContentTitle from 'components/ContentTitle';
import DatePicker from 'components/DatePicker';
import DropdownContent from 'components/DropdownContent';
import FormattedArray from 'components/FormattedArray';
import FormattedDate from 'components/FormattedDate';
import Label from 'components/Label';

import styles from '../../ApproachEditorEmployee.scss';

const Feedback = ({
  employeeFeedback,
  employeeRating,
  candidateFeedback,
  companyFeedback,
  handleSetState,
  isAdmin,
  offerFeedback,
  offerSignedDate,
  passedOn,
  patchResource,
  reason,
  reqCandidateId,
  reqId,
  roleFeedback,
  showFeedback,
  startDate,
  state,
}) => {
  const buttonProps = {
    tertiary: true,
    onClick: () => handleSetState({ showFeedback: !showFeedback }),
  };

  const passedOnDropdownProps = {
    label: passedOn ? <FormattedDate parse date={passedOn} /> : 'Select date',
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    dropRight: true,
    disabled:
      !isAdmin ||
      (state !== 'candidate_passed' && state !== 'employer_passed' && state !== 'passed'),
  };

  const passedOnProps = {
    onDayClick: (day) =>
      patchResource({
        attributes: {
          passedOn: day,
        },
        id: reqCandidateId,
        normalizeIt: true,
        slice: 'toolboxApproaches',
        type: 'requisition_candidates',
      }),
    selectedDays: [new Date(passedOn)],
  };

  const passedOnContent = (
    <div className={styles.addCandidatesOptionsContainer}>
      <DatePicker {...passedOnProps} />
    </div>
  );

  const offerSignedProps = {
    onDayClick: (day) =>
      patchResource({
        attributes: {
          offerSignedDate: day,
        },
        id: reqCandidateId,
        normalizeIt: true,
        slice: 'toolboxApproaches',
        type: 'requisition_candidates',
      }),
    selectedDays: [new Date(offerSignedDate)],
    disabled: state !== 'hired',
  };

  const offerSignedContent = (
    <div className={styles.addCandidatesOptionsContainer}>
      <DatePicker {...offerSignedProps} />
    </div>
  );

  const offerSignedDropdownProps = {
    label: offerSignedDate ? <FormattedDate parse date={offerSignedDate} /> : 'Select date',
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    dropRight: true,
    disabled: !isAdmin || state !== 'hired',
  };

  const startDateProps = {
    onDayClick: (day) =>
      patchResource({
        attributes: {
          startDate: day,
        },
        id: reqCandidateId,
        normalizeIt: true,
        slice: 'toolboxApproaches',
        type: 'requisition_candidates',
      }),
    selectedDays: [new Date(startDate)],
    disabled: state !== 'hired',
  };

  const startDateContent = (
    <div className={styles.addCandidatesOptionsContainer}>
      <DatePicker {...startDateProps} />
    </div>
  );

  const startDateDropdownProps = {
    label: startDate ? <FormattedDate parse date={startDate} /> : 'Select date',
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    dropLeft: true,
    disabled: !isAdmin || state !== 'hired',
  };

  const idsContent =
    reqId || reqCandidateId ? (
      <Content className={styles.tier}>
        <ContentTitle>IDs</ContentTitle>

        <Content className={styles.tierRow}>
          <Content className={styles.attribute}>
            <Label>Requisition Candidate ID</Label>
            <Content className={styles.idValue}>{reqCandidateId}</Content>
          </Content>

          <Content className={styles.attribute}>
            <Label>Requisition Opening ID</Label>
            <Content className={styles.idValue}>{reqId}</Content>
          </Content>
        </Content>
      </Content>
    ) : null;

  const privateCandidateFeedback = candidateFeedback ? (
    <Content className={styles.attribute}>
      <Label>Private Candidate Feedback</Label>
      <Content className={styles.value}>{candidateFeedback}</Content>
    </Content>
  ) : null;

  const privateEmployeeFeedback = employeeFeedback ? (
    <Fragment>
      <Content className={styles.attribute}>
        <Label>Employee Rating</Label>
        <Content className={styles.value}>{employeeRating}</Content>
      </Content>
      <Content className={styles.attribute}>
        <Label>Employee Feedback</Label>
        <Content className={styles.value}>{employeeFeedback}</Content>
      </Content>
    </Fragment>
  ) : null;

  const lessButton = isAdmin ? <Button {...buttonProps}>Less</Button> : null;

  const candidatePassedFeedback =
    state === 'candidate_passed' || state === 'candidate_pass_pending' ? (
      <Fragment>
        <Content className={styles.attribute}>
          <Label>Company Feedback</Label>
          <Content className={styles.value}>
            <FormattedArray array={companyFeedback.map((str) => camelToHuman(str))} />
          </Content>
        </Content>

        <Content className={styles.attribute}>
          <Label>Offer Feedback</Label>
          <Content className={styles.value}>
            <FormattedArray array={offerFeedback.map((str) => camelToHuman(str))} />
          </Content>
        </Content>

        <Content className={styles.attribute}>
          <Label>Role Feedback</Label>
          <Content className={styles.value}>
            <FormattedArray array={roleFeedback.map((str) => camelToHuman(str))} />
          </Content>
        </Content>

        <Content className={styles.attribute}>
          <Label>Extra Feedback</Label>
          <Content className={styles.value}>{reason}</Content>
        </Content>

        {privateCandidateFeedback}
      </Fragment>
    ) : null;

  const employeePassedFeedback = state === 'employer_passed' ? privateEmployeeFeedback : null;

  return showFeedback ? (
    <Fragment>
      {lessButton}
      <Content className={styles.tier}>
        <ContentTitle>FEEDBACK</ContentTitle>

        <Content className={styles.tierRow}>
          {candidatePassedFeedback}
          {employeePassedFeedback}
        </Content>
      </Content>

      <Content className={styles.tier}>
        <ContentTitle>DATES</ContentTitle>

        <Content className={styles.tierRow}>
          <Content className={styles.attribute}>
            <Label>Passed On</Label>
            <DropdownContent {...passedOnDropdownProps}>{passedOnContent}</DropdownContent>
          </Content>

          <Content className={styles.attribute}>
            <Label>Offer Signed</Label>
            <DropdownContent {...offerSignedDropdownProps}>{offerSignedContent}</DropdownContent>
          </Content>

          <Content className={styles.attribute}>
            <Label>Start Date</Label>
            <DropdownContent {...startDateDropdownProps}>{startDateContent}</DropdownContent>
          </Content>
        </Content>
      </Content>

      {idsContent}
    </Fragment>
  ) : (
    <Button {...buttonProps}>More</Button>
  );
};

export default Feedback;
