import React from 'react';
import ImageUploader from 'components/ImageUploader';

import EmptyCompanyLogo from 'shared/assets/missing-company-picture.png';

const CompanyImageUploader = ({ companyId, companyImageUploadStarted, pictureUrl }) => {
  return (
    <ImageUploader
      name="Company"
      entityId={companyId}
      imageUploadStarted={companyImageUploadStarted}
      pictureUrl={pictureUrl}
      emptyLogo={EmptyCompanyLogo}
      maxImageSizeMb={10}
    />
  );
};

export default CompanyImageUploader;
