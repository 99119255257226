import React from 'react';

import { createRainmakersAdmin, createUser } from 'reduxStore/schema';

import AdminData from 'components/AdminData';
import Button from 'components/Button';
import Content from 'components/Content';
import FormattedDate from 'components/FormattedDate';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import InputCheckbox from 'components/InputCheckbox';
import Table from 'components/Table';
import TableCell from 'components/Table/components/TableCell';
import TableRow from 'components/Table/components/TableRow';

import styles from './ToolboxAdminsTable.scss';

const ToolboxAdminsTable = ({
  actions: { resourceUpdate, showModal },
  rainmakersAdmins: { allIds = [], byId = {} } = {},
  handleSearchCheckboxChange,
  handleSortChange,
  isLoading,
  loadMoreAdmins,
  total,
  userAccountLocked,
  users: { byId: usersById = {} } = {},
}) => {
  const tableHeader = [
    {
      content: 'Name',
      headerClassName: styles.nameHeader,
    },
    {
      content: 'Email',
      headerClassName: styles.lastActivityHeader,
    },
    {
      content: 'Created At',
      headerClassName: styles.lastActivityHeader,
      value: 'created_at',
      onClick: handleSortChange,
    },
    {
      content: 'Phone',
      headerClassName: styles.updatedAtHeader,
    },
  ];

  const makeAdminCells = (id) => {
    const admin = byId[id] || {};
    const {
      attributes: { phoneNumber } = {},
      relationships: { user: { data: userIds = [] } = {} } = {},
    } = admin;

    const { attributes: { createdAt, email } = {} } = usersById[userIds[0]] || {};

    const adminDataProps = {
      onClick: () =>
        showModal({
          key: 'AdminEditorModal',
          parent: 'ToolboxAdminsPage',
          route: `/toolbox/admin/${id}/edit-profile`,
        }),
      admin,
    };

    const adminDataContent = (
      <div className={styles.adminContent}>
        <AdminData {...adminDataProps} />
      </div>
    );

    const phoneContent = (
      <div className={styles.updatedAtContent}>
        {phoneNumber ? <Content className={styles.updatedAtContent}>{phoneNumber}</Content> : '-'}
      </div>
    );

    const createdAtContent = (
      <div className={styles.lastActivityContent}>
        <FormattedDate format="M/D/YY" date={createdAt} parse />
      </div>
    );

    const emailContent = <div className={styles.lastActivityContent}>{email}</div>;

    const cells = [
      <TableCell key={1}>{adminDataContent}</TableCell>,
      <TableCell key={2}>{emailContent}</TableCell>,
      <TableCell key={3}>{createdAtContent}</TableCell>,
      <TableCell key={4}>{phoneContent}</TableCell>,
    ];

    return cells;
  };

  const makeAdminRows = (id) => (
    <TableRow id={id} key={id}>
      {makeAdminCells(id)}
    </TableRow>
  );

  const tableContent = allIds.filter((id) => !byId[id].attributes.temporary).map(makeAdminRows);

  const createAdminClick = () => {
    const resource = createRainmakersAdmin();
    const userResource = createUser();

    resourceUpdate({
      slice: 'toolboxAdmins',
      type: 'rainmakersAdmins',
      id: resource.id,
      resource: {
        ...resource,
        attributes: {
          ...resource.attributes,
        },
        relationships: {
          user: {
            data: [userResource.id],
          },
        },
      },
    });

    resourceUpdate({
      slice: 'toolboxAdmins',
      type: 'users',
      id: userResource.id,
      resource: {
        ...userResource,
        attributes: {
          ...userResource.attributes,
          currentProfileType: 'RainmakersAdmin',
          currentProfileId: resource.id,
        },
      },
    });

    const route = `/toolbox/admin/${resource.id}/edit-profile`;

    showModal({
      key: 'AdminEditorModal',
      parent: 'ToolboxAdminsPage',
      route,
    });
  };

  const attentionRequiredProps = {
    name: 'userAccountLocked',
    label: 'Account Locked',
    checked: userAccountLocked,
    handleInputChange: handleSearchCheckboxChange,
    className: styles.checkbox,
    checkboxMany: true,
  };

  const createAdminProps = {
    onClick: createAdminClick,
    primary: true,
  };

  const filtersContent = (
    <div className={styles.adminHeader}>
      <InputCheckbox {...attentionRequiredProps} />
      <Button {...createAdminProps}>+ Admin</Button>
    </div>
  );

  const infiniteScrollProps = {
    loadMore: loadMoreAdmins,
    hasMore: total > allIds.length,
    loader: isLoading ? (
      <div key="infiniteScrollLoader" />
    ) : (
      <InfiniteScrollLoader key="infiniteScrollLoader" />
    ),
    useWindow: false,
  };

  const tableProps = {
    blockProps: {
      titleClass: styles.dashboardTitleContainer,
    },
    titleContent: filtersContent,
    tableContent,
    header: tableHeader,
    infiniteScrollProps,
    tableName: 'adminAdmins',
    isEmpty: !isLoading && allIds.length === 0,
    emptyTextString: 'No Admins',
    isLoading,
  };

  return <Table {...tableProps} />;
};

export default ToolboxAdminsTable;
