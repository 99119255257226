import React from 'react';
import { Helmet } from 'react-helmet-async';

import Title from 'components/Title';
import Content from 'components/Content';
import ReadyContainer from 'connectors/ReadyContainer';

import styles from './UnauthorizedPage.scss';

const UnauthorizedPage = () => (
  <>
    <Helmet key="helmet" title="401 - Not Authorized" />

    <ReadyContainer key="container" className={styles.UnauthorizedPage}>
      <Title>Access Restricted</Title>
      <Content>
        Looks like you are in the wrong place!
        <br /> Please contact{' '}
        <span className={styles.emailLink}>
          <a href="mailto:support@rainmakers.co">support@rainmakers.co</a>
        </span>{' '}
        with any questions.
      </Content>
    </ReadyContainer>
  </>
);

export default UnauthorizedPage;
