import { createStructuredSelector } from 'reselect';

import { getModal } from 'shared/store/app/selectors';
import {
  selectIsApproved,
  selectIsAdmin,
  selectIsCandidate,
  selectLogoutPending,
} from 'connectors/Login/redux/selectors';

import {
  selectCandidate,
  selectCandidateUser,
} from '../../../CandidateEditorPages/redux/selectors';

import selectOpportunities from './selectOpportunities';
import selectPage from './selectPage';
import selectTotal from './selectTotal';
import selectLoading from './selectLoading';
import selectRainmakersAdmin from './selectRainmakersAdmin';

const selector = createStructuredSelector({
  candidate: selectCandidate,
  isAdmin: selectIsAdmin,
  isApproved: selectIsApproved,
  isCandidate: selectIsCandidate,
  isLoading: selectLoading,
  logoutPending: selectLogoutPending,
  modal: getModal,
  opportunities: selectOpportunities,
  page: selectPage,
  rainmakersAdmin: selectRainmakersAdmin,
  total: selectTotal,
  user: selectCandidateUser,
});

export {
  selectOpportunities,
  selectPage,
  selectTotal,
  selectLoading,
  selectRainmakersAdmin,
  selector as default,
};
