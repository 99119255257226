import React from 'react';

import Block from 'components/Block';
import ContentTitle from 'components/ContentTitle';
import InputCheckboxSlider from 'components/InputCheckboxSlider';
import Label from 'components/Label';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';

import styles from './AdminEditorSettings.scss';

const AdminEditorSettings = ({
  rainmakersAdmin: {
    attributes = {},
    relationships: { user: { data: userIds = [] } = {} } = {},
  } = {},
  saving,
  handleAdminInputChange,
  handleUserInputChange,
  user: { currentProfile: { editAdmins = false } = {} } = {},
  users: { byId: usersById = {} } = {},
}) => {
  const { [userIds[0]]: { attributes: { accountLocked = false } = {} } = {} } = usersById || {};

  const savingContent = saving ? (
    <div className={styles.autoSaving}>Saving...</div>
  ) : (
    <div className={styles.autoSaved}>Profile Saved</div>
  );

  const saveContent = saving === undefined ? null : savingContent;

  const commonBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    largeTitleFont: true,
    addPadding: true,
  };

  const titleBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    addPadding: true,
    className: styles.titleBlock,
  };

  const permissionBooleans = [
    {
      key: 'editCandidates',
      label: 'Edit Candidates',
    },
    {
      key: 'editEmployers',
      label: 'Edit Employers',
    },
    {
      key: 'editRequisitions',
      label: 'Edit Requisitions',
    },
    {
      key: 'editCohorts',
      label: 'Edit Cohorts',
    },
    {
      key: 'editInstitutions',
      label: 'Edit Institutions',
    },
    {
      key: 'editBlocks',
      label: 'Edit Blocks',
    },
  ];

  const globalEmailBooleans = [
    {
      key: 'batchRequests',
      label: 'Cohort Requests',
    },
    {
      key: 'employeeCreatedBlock',
      label: 'Match Passes',
    },
    {
      key: 'candidateApplications',
      label: 'Candidate Applications',
    },
    {
      key: 'employerApplications',
      label: 'Employer Applications',
    },
    {
      key: 'leadCreated',
      label: 'Leads',
    },
    {
      key: 'reqCanCreated',
      label: 'Approach Created',
    },
    {
      key: 'reqCanHired',
      label: 'Approach Hired',
    },
    {
      key: 'reqCanPassed',
      label: 'Approach Passed',
    },
    {
      key: 'integrations',
      label: 'Integration Requests',
    },
  ];

  const employerAdminEmailBooleans = [
    {
      key: 'empAdminEmployeeCreatedBlock',
      label: 'Match Passes',
    },
    {
      key: 'empAdminReqCanCreated',
      label: 'Approach Created',
    },
    {
      key: 'empAdminReqCanHired',
      label: 'Approach Hired',
    },
    {
      key: 'empAdminReqCanPassed',
      label: 'Approach Passed',
    },
  ];

  const talentConciergeEmailBooleans = [
    {
      key: 'conciergeEmployeeCreatedBlock',
      label: 'Match Passes',
    },
    {
      key: 'conciergeReqCanCreated',
      label: 'Approach Created',
    },
    {
      key: 'conciergeReqCanHired',
      label: 'Approach Hired',
    },
    {
      key: 'conciergeReqCanPassed',
      label: 'Approach Passed',
    },
  ];

  const roleBooleans = [
    {
      key: 'talentConcierge',
      label: 'Talent Concierge',
    },
    {
      key: 'accountManager',
      label: 'Account Manager',
    },
    {
      key: 'recruiter',
      label: 'Recruiter',
    },
  ];

  const makeInputs = ({ key, label }) => {
    const props = {
      inputLabel: label,
      key,
      name: key,
      checked: attributes[key],
      label: attributes[key] ? 'On' : 'Off',
      handleInputChange: handleAdminInputChange,
      disabled: !editAdmins,
    };

    return (
      <div className={styles.input}>
        <Label>{label}</Label>
        <InputCheckboxSlider {...props} />
      </div>
    );
  };

  const globalEmailInputs = globalEmailBooleans.map(makeInputs);
  const employerAdminEmailInputs = employerAdminEmailBooleans.map(makeInputs);
  const talentConciergeEmailInputs = talentConciergeEmailBooleans.map(makeInputs);

  const permissionInputs = permissionBooleans.map(makeInputs);

  const roleInputs = roleBooleans.map(makeInputs);

  const accountLockedInputProps = {
    handleInputChange: (event) =>
      handleUserInputChange({
        ...event,
        target: {
          ...event.target,
          value: event.target.checked,
          name: event.target.name,
        },
      }),
    name: 'accountLocked',
    checked: accountLocked,
    label: accountLocked ? 'Account Locked' : 'Account Unlocked',
    placeholder: '',
  };

  const content = (
    <div className={styles.EditorSettings}>
      <Block {...titleBlockProps}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            <div>Settings</div>
          </div>
          <div className={styles.saveContent}>{saveContent}</div>
        </div>
      </Block>
      <div className={styles.scrollDiv}>
        <Block {...commonBlockProps}>
          <ContentTitle>Account Settings</ContentTitle>
          <div className={styles.inputContainer}>
            <InputCheckboxSlider {...accountLockedInputProps} />
          </div>
        </Block>

        <Block {...commonBlockProps}>
          <ContentTitle>Permissions</ContentTitle>
          <div className={styles.inputContainer}>{permissionInputs}</div>
        </Block>

        <Block {...commonBlockProps}>
          <ContentTitle>Roles</ContentTitle>
          <div className={styles.inputContainer}>{roleInputs}</div>
        </Block>

        <Block {...commonBlockProps}>
          <ContentTitle>Global Email Subscriptions</ContentTitle>
          <div className={styles.inputContainer}>{globalEmailInputs}</div>
        </Block>

        <Block {...commonBlockProps}>
          <ContentTitle>Employer Admin Email Subscriptions</ContentTitle>
          <div className={styles.inputContainer}>{employerAdminEmailInputs}</div>
        </Block>

        <Block {...commonBlockProps}>
          <ContentTitle>Talent Concierge Email Subscriptions</ContentTitle>
          <div className={styles.inputContainer}>{talentConciergeEmailInputs}</div>
        </Block>
      </div>
    </div>
  );

  const layoutWithoutSidebarProps = {
    content,
  };

  return <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />;
};

export default AdminEditorSettings;
