import { call, put, take } from 'redux-saga/effects';
import { postEntity } from 'api/apiEntity';

import { handleError } from 'utils/common';

import { RESET_PASSWORD_STARTED } from 'connectors/ResetPassword/redux/actions';

import {
  resetPasswordDone,
  resetPasswordErrorReset,
} from 'connectors/ResetPassword/redux/creators';

const sagaResetPasswordStarted = function* sagaResetPasswordStarted() {
  while (true) {
    const {
      payload: { email },
    } = yield take(RESET_PASSWORD_STARTED);

    yield put(resetPasswordErrorReset());

    try {
      const args = {
        type: 'auth/password',
        data: {
          type: 'users',
          attributes: {
            email,
          },
        },
      };

      yield call(postEntity, args);

      yield put(resetPasswordDone());
    } catch (error) {
      handleError(error);
      yield put(resetPasswordDone());
    }
  }
};

export default sagaResetPasswordStarted;
