import { get } from 'api/api';

const promiseGetDiscourseSSO = (search) => {
  const onFulfilled = ({ data: { data: { url = '' } = {} } = {} }) => url;

  const route = `/discourse/sso${search}`;

  return get(route).then(onFulfilled);
};

export default promiseGetDiscourseSSO;
