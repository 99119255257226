import React from 'react';
import { Helmet } from 'react-helmet-async';

import { makeLikeParam } from 'utils/paramUtils';
import { toggleArray } from 'utils/formUtils';

import { AdminAuthHOC } from 'containers/HOC';

import ConnectContainer from 'containers/ConnectContainer';

import ReadyContainer from 'connectors/ReadyContainer';

import { useTableQuery } from 'hooks';

import Sidebar from 'components/Sidebar';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';

import ToolboxCompaniesTable from './components/ToolboxCompaniesTable';

import duck from './redux';

import styles from './ToolboxCompaniesPage.scss';

const route = '/toolbox/companies/';

const ToolboxCompaniesPage = ({
  actions,
  children,
  isLoading,
  unverifiedCompanyData,
  companies: { byId = {}, allIds = [] } = {},
  total,
  page = 1,
}) => {
  const { queryParams, loadMore, handleSearchInputChange, handleSearchCheckboxChange } =
    useTableQuery(
      route,
      page,
      {
        name: {
          type: 'string',
          defaultValue: null,
        },
        sortBy: {
          type: 'string',
          defaultValue: 'created_at_desc',
        },
        unverifiedDataState: {
          type: 'array',
          defaultValue: null,
        },
      },
      {
        type: 'companies',
        slice: 'toolboxCompanies',
      },
      {
        includeSet: 'unverified_data',
      },
      {
        name: makeLikeParam,
      }
    );

  return (
    <>
      <Helmet key="helmet" title="Toolbox - Companies" />
      <ReadyContainer key="readyContainer">
        <Sidebar type="toolbox" page="toolboxCompanies" />
        <LayoutWithoutSidebar
          content={
            <div className={styles.toolboxCompaniesMainContent}>
              <ToolboxCompaniesTable
                {...queryParams}
                actions={actions}
                companies={allIds.map((id) => byId[id])}
                handleSearchCheckboxChange={({ target: { name, value: eventValue } }) => {
                  const { [name]: array } = queryParams;
                  const value = toggleArray({ array: array || [], value: eventValue });

                  handleSearchCheckboxChange({
                    target: { name, checked: value },
                  });
                }}
                handleSearchInputChange={handleSearchInputChange}
                isLoading={isLoading}
                loadMore={loadMore}
                total={total}
                unverifiedCompanyData={unverifiedCompanyData}
              />
            </div>
          }
        />
        {children}
      </ReadyContainer>
    </>
  );
};

export default ConnectContainer(duck)(AdminAuthHOC()(ToolboxCompaniesPage));
