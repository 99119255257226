import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';

import { handleError } from 'utils/common';

import Bolder from 'components/Bolder';
import Button from 'components/Button';
import Content from 'components/Content';
import ContentTitle from 'components/ContentTitle';
import FontIcon from 'components/FontIcon';

import styles from './YourReferralCode.scss';

const YourReferralCode = ({
  referralCode,
  referralEmail,
  extraPath = '',
  showEmailLink = true,
}) => {
  const [host, setHost] = useState('');
  const [linkCopied, setLinkCopied] = useState(false);
  const [emailLinkCopied, setEmailLinkCopied] = useState(false);
  const copyText = useRef();
  const copyEmailText = useRef();
  const _mounted = useRef(false);

  const linkTemplate = `${host}/${extraPath}?referral=${referralCode}`;
  const emailLinkTemplate = `${host}/${extraPath}?referralEmail=${referralEmail}`;

  const referralLink = referralCode !== 'No Code' ? linkTemplate : 'No Link';
  const referralEmailLink = referralEmail !== 'No Email' ? emailLinkTemplate : 'No Link';

  useEffect(() => {
    _mounted.current = true;
    setHost(window.location.origin);

    return () => {
      _mounted.current = false;
    };
  }, []);

  const handleCopy = () => {
    copyText.current.value = referralLink;

    copyText.current.select();

    try {
      document.execCommand('copy');
      copyText.current.blur();
    } catch (error) {
      handleError(error);
    }

    setLinkCopied(true);

    setTimeout(() => {
      if (_mounted.current) {
        setLinkCopied(false);
      }
    }, 4000);
  };

  const handleEmailCopy = () => {
    copyEmailText.current.value = referralEmailLink;

    copyEmailText.current.select();

    try {
      document.execCommand('copy');
      copyEmailText.current.blur();
    } catch (error) {
      handleError(error);
    }

    setEmailLinkCopied(true);

    setTimeout(() => {
      if (_mounted.current) {
        setEmailLinkCopied(false);
      }
    }, 4000);
  };

  const linkCopiedClasses = classnames(styles.linkCopiedText, {
    [styles.linkCopiedActive]: linkCopied,
  });

  const emailLinkCopiedClasses = classnames(styles.linkCopiedText, {
    [styles.linkCopiedActive]: emailLinkCopied,
  });

  const linkCopiedText = linkCopied ? (
    <div className={styles.linkCopied}>
      <FontIcon iconName="checkmark" className={styles.copiedLinkIcon} />
      Link copied!
    </div>
  ) : (
    <div className={styles.linkNotCopied}>
      <FontIcon iconName="share" className={styles.copyLinkIcon} />
      Copy link
    </div>
  );

  const emailLinkCopiedText = emailLinkCopied ? (
    <div className={styles.linkCopied}>
      <FontIcon iconName="checkmark" className={styles.copiedLinkIcon} />
      Link copied!
    </div>
  ) : (
    <div className={styles.linkNotCopied}>
      <FontIcon iconName="share" className={styles.copyLinkIcon} />
      Copy link
    </div>
  );

  return (
    <Content className={styles.container}>
      <ContentTitle>YOUR REFERRAL CODE</ContentTitle>

      <Content className={styles.detailContainer}>
        <Content className={styles.column}>
          <Bolder>Your Code #</Bolder>
          <Content>{referralCode}</Content>
        </Content>

        <Content className={styles.column}>
          <Content className={styles.row}>
            <Content className={styles.column}>
              <Bolder>Your Links</Bolder>
            </Content>
          </Content>

          <Content className={styles.rowCentered}>
            <Content className={styles.column}>
              <Content>{referralLink}</Content>
            </Content>

            <Content className={styles.column}>
              <Button
                primary={true}
                active={linkCopied}
                className={linkCopiedClasses}
                disabled={referralCode === 'No Code'}
                onClick={handleCopy}
              >
                {linkCopiedText}
              </Button>
            </Content>
          </Content>

          {showEmailLink && (
            <Content className={styles.rowCentered}>
              <Content className={styles.column}>
                <Content>{referralEmailLink}</Content>
              </Content>

              <Content className={styles.column}>
                <Button
                  primary={true}
                  active={emailLinkCopied}
                  className={emailLinkCopiedClasses}
                  disabled={referralEmail === 'No Email'}
                  onClick={handleEmailCopy}
                >
                  {emailLinkCopiedText}
                </Button>
              </Content>
            </Content>
          )}
        </Content>
      </Content>

      <input ref={copyText} value="" className={styles.hidden} />
      <input ref={copyEmailText} value="" className={styles.hidden} />
    </Content>
  );
};

export default YourReferralCode;
