import { createAction } from 'redux-actions';

import {
  RESET_PASSWORD_STARTED,
  RESET_PASSWORD_DONE,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_ERROR_RESET,
} from 'connectors/ResetPassword/redux/actions';

export const resetPasswordStarted = createAction(RESET_PASSWORD_STARTED);
export const resetPasswordDone = createAction(RESET_PASSWORD_DONE);
export const resetPasswordError = createAction(RESET_PASSWORD_ERROR);
export const resetPasswordErrorReset = createAction(RESET_PASSWORD_ERROR_RESET);
