import { postEntity } from 'api/apiEntity';

const promisePostCandidateCompanyBlocks = ({ candidateId, companyId, ownerId, ownerType }) => {
  const onFulfilled = (response) => {
    const {
      data: { candidateCompanyBlock = {} },
    } = response;

    return { candidateCompanyBlock };
  };

  const args = {
    config: {
      params: {
        camelizeIt: true,
        includeSet: 'company',
      },
    },
    data: {
      type: 'candidate_company_block',
      attributes: {
        candidateId,
        companyId,
        ownerId,
        ownerType,
        blockType: 'rainmakers_admin',
      },
    },
    type: 'candidate_company_blocks',
  };

  return postEntity(args).then(onFulfilled);
};

export default promisePostCandidateCompanyBlocks;
