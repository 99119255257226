import { handleError } from 'utils/common';
import { patchNestedEntity } from 'api/apiEntity';

const promisePatchRequisitionOpeningCopy = ({ reqCandId, data }) => {
  const onFulfilled = (response) => response;

  const args = {
    data: {
      id: reqCandId,
      type: 'requisition_opening',
      attributes: {
        ...data,
      },
    },
    id: reqCandId,
    nestedType: 'requisition_opening_copy',
    type: 'requisition_candidates',
  };

  return patchNestedEntity(args).then(onFulfilled).catch(handleError);
};

export default promisePatchRequisitionOpeningCopy;
