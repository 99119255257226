import { createStructuredSelector } from 'reselect';

import { getCurrentUser, getApprovedStatus } from 'shared/store/app/selectors';

import selectIsLoggedIn from './selectIsLoggedIn';
import selectIsPingerBot from './selectIsPingerBot';
import selectIsAdmin from './selectIsAdmin';
import selectIsCandidate from './selectIsCandidate';
import selectIsEmployee from './selectIsEmployee';
import selectLoginErrors from './selectLoginErrors';
import selectLoginPending from './selectLoginPending';
import selectLoginNotification from './selectLoginNotification';
import selectLogoutPending from './selectLogoutPending';
import selectOnboardingStatus from './selectOnboardingStatus';

const selector = createStructuredSelector({
  isLoggedIn: selectIsLoggedIn,
  isPingerBot: selectIsPingerBot,
  isAdmin: selectIsAdmin,
  isCandidate: selectIsCandidate,
  isEmployee: selectIsEmployee,
  isApproved: getApprovedStatus,
  loginErrors: selectLoginErrors,
  loginNotification: selectLoginNotification,
  loginPending: selectLoginPending,
  logoutPending: selectLogoutPending,
  user: getCurrentUser,
});

export {
  selectOnboardingStatus,
  selectLoginPending,
  selectLogoutPending,
  selectLoginErrors,
  selectLoginNotification,
  selectIsLoggedIn,
  selectIsPingerBot,
  selectIsAdmin,
  selectIsCandidate,
  selectIsEmployee,
  getApprovedStatus as selectIsApproved,
  selector as default,
};
