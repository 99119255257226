import { ONBOARDING_ROUTES_MAP, DEFAULT_LOGIN_LINKS } from 'connectors/Login/constants';
import { employerHomePageValues } from 'utils/constants';

function redirectHomepage(user, isApproved, hasSeenApproved, loginPending, originalPath) {
  if (user.currentProfileType && originalPath) {
    return originalPath;
  }

  if (
    !loginPending &&
    user.currentProfileType === 'Employee' &&
    (!isApproved || (isApproved && !hasSeenApproved))
  ) {
    return '/employer-signup-status';
  }

  if (user.currentProfileType === 'Employee') {
    return employerHomePageValues[user.currentProfile.employer.homepage].homepage;
  }

  if (
    user.currentProfileType === 'Candidate' &&
    !isApproved &&
    user.currentProfile.onboardingStatus
  ) {
    const { [user.currentProfile.onboardingStatus]: to } = ONBOARDING_ROUTES_MAP;

    // onboarding_status == (skipped_video || added_video) don't return "to". Onboarding complere
    // use DEFAULT_LOGIN_LINKS value
    if (to) {
      return to;
    }
  }

  if (user.currentProfileType) {
    const { [user.currentProfileType]: to } = DEFAULT_LOGIN_LINKS;

    return to;
  }

  return null;
}

export default redirectHomepage;
