import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash.isempty';
import { Prompt, useParams } from 'react-router-dom';

import Block from 'components/Block';
import Button from 'components/Button';
import FontIcon from 'components/FontIcon';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import RainCam from 'components/RainCam';
import TableOverlay from 'components/TableOverlay';

import styles from './CandidateEditorIntro.scss';

const CandidateEditorIntro = ({
  actions: { setAppModalError, nestedEntityLoadStarted, deleteResource },
  candidate: { id: candidateId, attributes: { id: attrCandidateId, profileLocked } = {} } = {},
  isAdmin,
  vimeoVideos,
  vimeoVideos: { byId: vimeoVideosById = {}, allIds: vimeoVideosAllIds = [] } = {},
  user: { isMobile, isSafari } = {},
}) => {
  const [unsavedData, setUnsavedData] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    nestedEntityLoadStarted({
      id,
      nestedType: 'vimeo_video',
      slice: 'toolboxCandidates',
      type: 'candidates',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteVideo = () => {
    const vimeoVideoId = vimeoVideosAllIds.find(
      (id) => vimeoVideosById[id].attributes.ownerId === candidateId
    );

    if (vimeoVideoId) {
      deleteResource({
        slice: 'toolboxCandidates',
        type: 'vimeo_videos',
        id: vimeoVideoId,
      });
    }
  };

  const vimeoVideoId = vimeoVideosAllIds.find(
    (id) => vimeoVideosById[id].attributes.ownerId === attrCandidateId
  );

  const { [vimeoVideoId]: { attributes: { vimeoResponse = {} } = {} } = {} } = vimeoVideosById;

  const { embed: { html: __html } = {} } = vimeoResponse || {};

  const titleBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    addPadding: true,
    className: styles.titleBlock,
  };

  const mainBlockProps = {
    ...titleBlockProps,
    className: styles.mainBlock,
  };

  const rainCamProps = {
    deleteResource,
    isAdmin,
    isMobile,
    isSafari,
    ownerId: attrCandidateId,
    ownerType: 'Candidate',
    profileLocked,
    setAppModalError,
    setUnsavedData: (state) => setUnsavedData(state),
    slice: 'toolboxCandidates',
  };

  const deleteButtonProps = {
    onClick: handleDeleteVideo,
    warning: true,
    className: styles.delete,
    disabled: profileLocked,
  };

  const innerHTML = { __html };

  const embedContent = <div dangerouslySetInnerHTML={innerHTML} />;

  const playerContent = embedContent;

  const innerContent = __html ? (
    <>
      {playerContent}
      <Button {...deleteButtonProps}>
        <FontIcon iconName="trash" />
        Discard
      </Button>
    </>
  ) : (
    <RainCam {...rainCamProps} />
  );

  const mainContent = isEmpty(vimeoVideos) ? (
    <div className={styles.emptyVideo}>
      <TableOverlay table="inPlace" />
    </div>
  ) : (
    innerContent
  );

  const content = (
    <div className={styles.EditorIntro}>
      <Block {...titleBlockProps}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Intro Video</div>
        </div>
      </Block>
      <Block {...mainBlockProps}>{mainContent}</Block>
      <Prompt
        when={unsavedData}
        message={
          'Are you sure you want to leave this page? You have uploaded a video without submitting and your video will be lost.'
        }
      />
    </div>
  );

  const layoutWithoutSidebarProps = {
    content,
  };

  return <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />;
};

export default CandidateEditorIntro;
