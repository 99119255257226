import { createStructuredSelector } from 'reselect';

import {
  selectIsLoggedIn,
  selectIsAdmin,
  selectLogoutPending,
} from 'connectors/Login/redux/selectors';

import { selectUser } from 'connectors/User/redux/selectors';

import {
  entitySelectorFactory,
  pageSelectorFactory,
  totalSelectorFactory,
  loadingSelectorFactory,
} from 'reduxStore/selectorFactories';

import { getModal } from 'shared/store/app/selectors';

const selector = createStructuredSelector({
  logoutPending: selectLogoutPending,
  user: selectUser,
  isLoggedIn: selectIsLoggedIn,
  isAdmin: selectIsAdmin,
  batchRequests: entitySelectorFactory('toolboxBatchRequests', 'batchRequest'),
  candidates: entitySelectorFactory('toolboxBatchRequests', 'candidate'),
  page: pageSelectorFactory('toolboxBatchRequests', 'batchRequest'),
  total: totalSelectorFactory('toolboxBatchRequests', 'batchRequest'),
  isLoading: loadingSelectorFactory('toolboxBatchRequests', 'batchRequest'),
  cohorts: entitySelectorFactory('toolboxBatchRequests', 'cohort'),
  cohortsPage: pageSelectorFactory('toolboxBatchRequests', 'cohort'),
  cohortsTotal: totalSelectorFactory('toolboxBatchRequests', 'cohort'),
  modal: getModal,
});

export { selector as default };
