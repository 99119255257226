import { postEntity } from 'api/apiEntity';

const promisePostRequisitions = ({ requisition, employerId, greenhouseJobId }) => {
  const onFulfilled = (response) => {
    const {
      data: { requisition_opening: requisitionOpening },
    } = response;

    return { reqDetail: requisitionOpening };
  };

  const args = {
    type: 'requisition_openings',
    data: {
      type: 'requisition_opening',
      attributes: {
        headcount: 1,
        ...requisition,
      },
    },
  };

  if (employerId) {
    args.data.attributes.employerId = employerId;
  }

  if (greenhouseJobId) {
    args.data.attributes.greenhouseJobId = greenhouseJobId;
  }

  return postEntity(args).then(onFulfilled);
};

export default promisePostRequisitions;
