import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';

import { redirectHomepage } from 'utils/navHelpers/index';

import ReadyContainer from 'connectors/ReadyContainer';

import ConnectContainer from 'containers/ConnectContainer';

import EmployerSignupForm from 'components/EmployerSignupForm';

import Copy from './components/Copy';

import duck from './redux';

import styles from './SignupPage.scss';

class Signup extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {
      location: { search },
      actions: { saveUTMInfo },
    } = this.props;

    if (search) {
      saveUTMInfo({ utmInfo: queryString.parse(search) });
    }
  }

  render() {
    const { location, user, isApproved, hasSeenApproved } = this.props;

    const redirectPath = redirectHomepage(
      user,
      isApproved,
      hasSeenApproved,
      false,
      location.state?.originalPath
    );

    const title = 'Find & Hire Top Sales Talent With Rainmakers';
    const metaDescription =
      'Seeking to hire top sales talent for your company? Rainmakers has a rich database of elite sales reps. Signup and get connected today!';
    const canonicalUrl = `https://${process.env.HOST}/employer-signup/`;

    const helmetProps = {
      key: 'helmet',
      title,
      meta: [
        { name: 'description', content: metaDescription },
        { property: 'og:url', content: canonicalUrl },
        { property: 'og:title', content: title },
        { property: 'og:description', content: metaDescription },
      ],
      link: [{ rel: 'canonical', href: canonicalUrl }],
    };

    if (redirectPath) return <Redirect to={redirectPath} />;

    return (
      <>
        <Helmet {...helmetProps} />
        <ReadyContainer key="readyContainer" className={styles.EmployerRegistration}>
          <div className={styles.layout}>
            <div className={styles.employerSignup}>
              <h1 className={styles.title}>BUILD YOUR SALES DREAM TEAM</h1>
              <div className={styles.regWrapper}>
                <Copy {...this.props} />
                <EmployerSignupForm />
              </div>
            </div>
          </div>
        </ReadyContainer>
      </>
    );
  }
}

export default ConnectContainer(duck)(Signup);
