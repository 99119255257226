/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import { getAltName } from 'utils/urlUtils';

import pic1 from './assets/1-chicago-recruiting-firm.png';
import pic2 from './assets/2-chicago-sales-recruiting-agency.png';
import pic3 from './assets/3-sales-recruiters-chicago.png';
import pic4 from './assets/4-why-work-sales-recruiter.png';

import styles from './ChicagoContent.scss';

export function ChicagoContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt={getAltName(pic1)} />
      <h2>Finding Top Sales Talent Made Easy</h2>
      <p>
        Finding the right people when your Chicago area business needs new sales talent takes time
        and effort. Hiring managers often find that the pest people already have a position or have
        multiple companies wanting them. Yet you can’t succeed without an effective sales team.
      </p>
      <p>
        Rainmakers knows how to help. We will help you find a rainmaker or an employee who will
        bring in revenue for your company.
      </p>
      <p>
        Rainmakers is a sales recruiting firm that will connect you with the salespeople you need.
        We have a database of the best salespeople and the Chicago companies who need their talent.
        We help you connect more quickly with the people who will help you land more sales so that
        you can find success in a competitive market.
      </p>
      <h2>What’s Chicago’s Job Market Like?</h2>
      <p>
        Chicago’s job market attracts top talent, especially in the tech industry. It is a thriving
        market for startups in data science and general tech fields, which also means Chicago’s tech
        industry is a top place for sales talent looking for work. This creates a competitive hiring
        process, and recruiting agencies can be an invaluable tool for those companies looking to
        make a new hire.
      </p>
      <Link to="/employer-signup/" title="Apply Now">
        <img src={pic2} alt={getAltName(pic2)} />
      </Link>
      <h2>What Is a Sales Recruiting Agency?</h2>
      <p>
        A sales recruiting agency is an agency that specifically helps with hiring qualified sales
        professionals. Sales recruiters from these agencies understand the unique nature of skilled
        sales professionals while also understanding the hiring process. They put this expertise to
        work for a company to help them find the best salespeople and streamline the hiring process.
      </p>
      <p>
        Rainmakers and other sales recruiting agencies have a finger on the available talent. They
        take the time to find top people and put them into position quickly, so companies can focus
        on continuing to run their effective businesses.
      </p>
      <h2>What Challenges Do Hiring Managers Face in Chicago?</h2>
      <p>
        In the sales industry, businesses are facing a talent crisis. Historically, Chicago area
        businesses had high rep turnover rates, which have increased even more recently. Companies
        in the tech field, in particular, find they can’t locate the sales professionals they need
        for success, and when they do find people, those people lack the soft skills they need for
        success. Sales recruiting firms help stand in this gap so companies can quickly overcome
        these challenges and find the right people with the right skills.
      </p>
      <Link to="/employer-signup/" title="Apply Now">
        <img src={pic3} alt={getAltName(pic3)} />
      </Link>
      <h2>What Types of Companies Work with Sales Recruiters?</h2>
      <p>
        Most companies require sales professionals to help them sell their products or services.
        However, some companies rely more heavily on these professionals than others. These include:
      </p>
      <ul>
        <li>
          Medical and Pharmaceutical: In the medical world, the sales of medications, devices, and
          services are all important and require a sales team.
        </li>
        <li>
          Retail: Sales are the hallmark of the retail industry, so, logically, retail companies
          will need sales professionals.
        </li>
        <li>
          Technology: Whether creating software, cybersecurity programming, SaaS products, or
          something else, technology companies must have sales professionals to help them sell their
          products and services.
        </li>
      </ul>
      <p>
        While all of these types of companies need sales professionals, the tech industry has the
        highest demand for them in the Chicago area. The combination of the large number of tech
        companies in the city combined with the need that these companies have for effective sales
        creates the demand.
      </p>
      <h2>How Does the Recruiting Process Work in Chicago?</h2>
      <p>
        When companies in Chicago partner with Rainmakers to help them with their talent search,
        they benefit from working with a team that understands how to find people and onboard them
        effectively. With a proven process that works, we take the pressure off of the HR team and
        hiring managers and help you find better talent to interview.
      </p>
      <p>For our Chicago area clients, you can expect the process to work like this:</p>
      <ul>
        <li>
          <strong>Contracting with the sales recruiter:</strong> The first step is officially hiring
          the Rainmakers team to help you with your talent search.
        </li>
        <li>
          <strong>Establishing your goals:</strong> Once you contract with our team, we will learn
          more about what you need in a new hire. By understanding your goals, we can help you
          create more effective job postings to draw the right candidates. We can also dip into our
          existing database of candidates to find one who fits your needs.
        </li>
        <li>
          <strong>Finding potential candidates:</strong> Besides posting job ads, the Rainmakers
          team will dig into our database of available talent. Our professional network will move to
          find qualified candidates that meet your exact specification. Each candidate in our
          database submits an in-depth profile so that you can screen them more effectively.
        </li>
        <li>
          <strong>Interview potential candidates:</strong> Before any candidates reach your team,
          Rainmakers will screen them with an interview and vetting process. Because we’ve spent
          time with you and know what you want, we can effectively select only the best, most
          qualified people for you.
        </li>
        <li>
          <strong>Hire from a small pool:</strong> Once you get potential candidates, you will have
          a small, pre-screened pool to select from. As a result, you can interview, hire, and bring
          your new recruit onto your team more quickly.
        </li>
      </ul>
      <img src={pic4} alt={getAltName(pic4)} />
      <h2>How Does a Recruiting Agency Help the Hiring Process?</h2>
      <p>
        Working with a recruiter does several things when you are in the midst of the hiring
        process. These include:
      </p>
      <ol>
        <li>
          <strong>Hire more quickly:</strong> The time from listing a job to filling it shortens
          significantly when someone else is doing the recruiting and screening for you.
        </li>
        <li>
          <strong>Improve the quality of candidates:</strong> Recruitment agencies, like Rainmakers,
          have pre-screened, vetted professionals ready to serve you.
        </li>
        <li>
          <strong>Benefit from specialist knowledge:</strong> Our sales recruiters have insider
          knowledge of what it takes to screen people and find quality people. This recruiting
          expertise takes the guesswork out of finding talent.
        </li>
        <li>
          <strong>Gain market knowledge:</strong> Whether you need someone who knows Chicago or who
          knows your particular market well, Rainmakers can do both.
        </li>
        <li>
          <strong>Extend your reach:</strong> Finally, when you work with a recruiting agency, you
          can extend your reach beyond just candidates actively looking for a new job. Sometimes,
          those who aren’t looking fit your needs best, and we know how to find these people and
          encourage them to take the plunge into a new position.
        </li>
      </ol>
      <h2>How Does a Sales Recruiting Agency Find Candidates?</h2>
      <p>
        At Rainmakers, we maintain a database of vetted, talented sales professionals who are
        already waiting to find a job. We collect in-depth sales profiles of each one that shows
        their performance data and overall skills. This lets us quickly find a pool of people when
        you contact us for recruiting help. Because we already have the people, we help you save
        time and reduce risk when hiring someone new.
      </p>
      <p>
        Are you ready to expand your Chicago sales team? Then,{' '}
        <a href="https://www.rainmakers.co/employer-signup/">
          create your profile on Rainmakers today
        </a>{' '}
        to get started.
      </p>
    </div>
  );
}
