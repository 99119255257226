import { createSelector } from 'reselect';
import { entitySelectorFactory } from 'reduxStore/selectorFactories';
import defaultErrors from 'reduxStore/errors';

const selectCompanyErrors = createSelector(
  entitySelectorFactory('toolboxCompanies', 'company'),
  (state, props) => {
    const {
      match: { params: { id } = {} },
    } = props || {};

    return id;
  },
  (companies, id) => {
    const { byId: { [id]: { errors } = {} } = {} } = companies;

    return errors || defaultErrors.companies;
  }
);

export default selectCompanyErrors;
