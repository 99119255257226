import React from 'react';
import Slider from 'react-rangeslider';

import AutocompleteServer from 'connectors/AutocompleteServer';
import Bolder from 'components/Bolder';
import Label from 'components/Label';
import Tag from 'components/Tag';

import styles from '../ReqRequirement.scss';

const DontHireFromCompanies = ({
  disabled,
  handleToggleArrayValue,
  handleInputChange,
  requisitionRequirement: {
    attributes: { dontHireFromCompanies, dontHireFromCompaniesWt } = {},
  } = {},
}) => {
  const dontHireFromCompaniesInputProps = {
    placeholder: 'Start typing and select a company or add if not found',
    value: '',
    size: 'full',
    name: 'dontHireFromCompanies',
    label: (
      <span>
        Enter companies you would <Bolder>NOT</Bolder> like to hire from
      </span>
    ),
    handleInputChange: (event) =>
      handleToggleArrayValue({
        ...event,
        target: {
          ...event.target,
          name: event.target.name,
          value: event.target.value.name,
        },
      }),
    handleOnBlur: handleToggleArrayValue,
    topLabel: true,
    autocompleteType: 'companies',
    field: 'name',
    needReset: true,
    hasAddButton: true,
    disabled,
  };

  const dontHireFromCompaniesSelected =
    dontHireFromCompanies && dontHireFromCompanies.length > 0
      ? dontHireFromCompanies.map((type) => {
          const tagProps = {
            name: type,
            inputName: 'dontHireFromCompanies',
            onDeleteClick: (name, inputName) =>
              handleToggleArrayValue({
                target: {
                  value: name,
                  name: inputName,
                },
              }),
          };
          return <Tag key={type} {...tagProps} />;
        })
      : null;

  const dontHireFromCompaniesWtProps = {
    className: styles.rangeSlider,
    disabled,
    min: 0,
    max: 10,
    step: 5,
    value: dontHireFromCompaniesWt || 0,
    onChange: disabled
      ? () => null
      : (value) =>
          handleInputChange({
            target: {
              value,
              name: 'dontHireFromCompaniesWt',
            },
          }),
    tooltip: false,
    labels: {
      0: '0',
      5: '5',
      10: '10',
    },
  };
  return (
    <div className={styles.rangeInputs}>
      <div className={styles.column}>
        <AutocompleteServer {...dontHireFromCompaniesInputProps} />
        <div className={styles.autocompleteContainer}>{dontHireFromCompaniesSelected}</div>
      </div>
      <div className={styles.column}>
        <Label className={styles.labelStyle}>How much do you value this?</Label>
        <Slider {...dontHireFromCompaniesWtProps} />
      </div>
    </div>
  );
};

export default DontHireFromCompanies;
