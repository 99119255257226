import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useParams, Link } from 'react-router-dom';
import queryString from 'query-string';

import duck from 'shared/store/app';

import ConnectContainer from '../../containers/ConnectContainer';
import Validator from '../../connectors/Validator';
import Block from '../../components/Block';
import Input from '../../components/Input';
import TableOverlay from '../../components/TableOverlay';

import styles from './Login.scss';

const Login = ({
  actions,
  actions: { loginStarted },
  login: { loginPending, loginErrors, loginNotification } = {},
  isLoggedIn,
  user: { referer } = {},
}) => {
  const { type } = useParams();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const initialErrors = useRef({
    email: {
      isNotEmpty: [null],
      isEmail: [null],
    },
    password: {
      isNotEmpty: [null],
    },
  });

  const [errors, setErrors] = useState(initialErrors.current);

  useEffect(() => {
    const { search } = location;
    const { saveUTMInfo } = actions;

    const utmInfo = queryString.parse(search);

    saveUTMInfo({ utmInfo });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnBlur = (event) => {
    const {
      target: { name, value: newValue },
    } = event;

    const { inputErrors } = Validator.checkErrors({
      errorObject: errors,
      newValue,
      name,
    });

    setErrors(inputErrors);
  };

  const handleEmailChange = (event) => {
    const {
      target: { value: newValue },
    } = event;

    setEmail(newValue);
    setErrors((errors) => ({
      ...errors,
      email: initialErrors.email,
    }));
  };

  const handlePasswordChange = (event) => {
    const {
      target: { value: newValue },
    } = event;

    setPassword(newValue);
    setErrors((errors) => ({
      ...errors,
      email: initialErrors.password,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    loginStarted({ email, password });
  };

  const makeErrorText = (error) => {
    switch (error) {
      case 'Invalid login credentials. Please try again.':
        return 'The email or password does not match our records.';
      case 'Archived':
        return (
          <div className={styles.errorContainer}>
            <div className={styles.errorMessage}>Account Disabled</div>
            <div className={styles.errorContent}>
              Please contact <a href="mailto:support@rainmakers.co">support@rainmakers.co</a>
            </div>
          </div>
        );
      default:
        return 'Login failed';
    }
  };

  const emailErrors = errors.email;
  const passwordErrors = errors.password;

  const errorText = loginErrors ? makeErrorText(loginErrors[0]) : null;

  const notificationText =
    loginNotification !== '' ? (
      <span className={styles.loginNotification}>{loginNotification}</span>
    ) : null;

  const title = <div className={styles.loginTitle}>Sign In</div>;

  const blockProps = {
    addWhiteBG: true,
  };

  const emailInputProps = {
    autoComplete: 'email',
    debounce: 0,
    label: 'Email',
    placeholder: 'name@me.com',
    value: email,
    handleInputChange: handleEmailChange,
    handleOnBlur: handleOnBlur,
    name: 'email',
    className: styles.loginInput,
    errors: emailErrors,
    size: 'full',
    autoFocus: true,
  };

  const passwordInputProps = {
    autoComplete: 'current-password',
    debounce: 0,
    label: 'Password',
    placeholder: '********',
    value: password,
    handleInputChange: handlePasswordChange,
    handleOnBlur: handleOnBlur,
    type: 'password',
    name: 'password',
    size: 'full',
    className: styles.loginInput,
    errors: passwordErrors,
  };

  if (loginPending) {
    return (
      <div className={styles.LoginContainer}>
        <Block {...blockProps}>
          <TableOverlay table="image" />
        </Block>
      </div>
    );
  }

  const isForum =
    referer && referer.includes(process.env.HOST) && referer.includes(process.env.FORUM_PATHNAME);
  const candidateSignUpLink = isForum ? '/community-apply' : '/apply-now';

  const applyNowLinkProps = {
    to: type === 'candidate' ? candidateSignUpLink : '/employer-signup',
    className: styles.signUpLink,
  };

  const formProps = {
    method: 'post',
    className: styles.loginForm,
    onSubmit: handleSubmit,
  };

  return isLoggedIn ? null : (
    <div className={styles.LoginContainer}>
      <Block {...blockProps}>
        <div className={styles.innerLoginContainer}>
          {title}
          {notificationText}
          <form {...formProps}>
            <div className={styles.loginError}>{errorText}</div>
            <div className={styles.loginInput}>
              <Input {...emailInputProps} />
            </div>

            <div className={styles.loginInput}>
              <Input {...passwordInputProps} />
            </div>

            <div className={styles.loginOptions}>
              <Link tabIndex={8} to="/reset-password" className={styles.forgotPasswordLink}>
                Forgot password?
              </Link>
              <input className={styles.submitInput} type="submit" value="Sign In" />
            </div>
          </form>
        </div>
      </Block>
      <div className={styles.signUpContainer}>
        <div>Don&apos;t have an account?</div>
        <Link {...applyNowLinkProps}>Apply now!</Link>
      </div>
    </div>
  );
};

export default ConnectContainer(duck)(Login);
