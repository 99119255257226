import React, { Component } from 'react';

import CreateSuggestionSet from 'connectors/DialogModals/CreateSuggestionSet';

import styles from '../../ToolboxRequisitionEditor.scss';

import SuggestionSetsTable from './components/SuggestionSetsTable';

class SuggestionSets extends Component {
  constructor(props) {
    super(props);

    const {
      match: {
        params: { id: requisitionOpeningId },
      },
    } = props;

    this.state = {
      complete: null,
      selectedSuggestionSet: null,
    };

    this.suggestionSetsLoadParams = {
      includeSet: 'list_component',
      page: 1,
      requisitionOpeningId,
      sortBy: 'created_at_desc',
      complete: null,
    };

    this.suggestionSetsLoadArgs = {
      type: 'suggestion_sets',
      slice: 'toolboxRequisitions',
    };
  }

  componentDidMount() {
    const {
      actions: { entityLoadStarted },
    } = this.props;

    entityLoadStarted({
      ...this.suggestionSetsLoadArgs,
      params: this.getSuggestionSetsLoadParams(),
    });

    entityLoadStarted({
      type: 'rainmakers_admins',
      slice: 'toolboxRequisitions',
      params: {
        sortBy: 'created_at_desc',
        userAccountLocked: false,
        includeSet: 'user',
      },
    });
  }

  getSuggestionSetsLoadParams = () => {
    const { suggestionSetsPage: page = 1 } = this.props;
    const { complete } = this.state;

    return {
      ...this.suggestionSetsLoadParams,
      complete,
      page,
    };
  };

  loadMoreSuggestionSets = () => {
    const {
      actions: { entityLoadStarted },
      suggestionSetsPage: page,
    } = this.props;

    entityLoadStarted({
      ...this.suggestionSetsLoadArgs,
      params: {
        ...this.getSuggestionSetsLoadParams(),
        page: page + 1,
      },
    });
  };

  handleChangeState = (newState) => {
    const {
      actions: { entityLoadStarted },
    } = this.props;

    const { complete } = this.state;

    if (newState !== complete) {
      this.setState({ complete: newState }, () =>
        entityLoadStarted({
          ...this.suggestionSetsLoadArgs,
          params: {
            ...this.getSuggestionSetsLoadParams(),
            page: 1,
          },
        })
      );
    }
  };

  handleSetState = (args) => this.setState({ ...args });

  render() {
    const { complete, selectedSuggestionSet } = this.state;

    const {
      actions: { resourceUpdate, patchResource },
      employers: { byId: employersById = {} } = {},
      match: {
        params: { id },
      },
      rainmakersAdmins = {},
      suggestionSets = {},
      suggestionSetsLoading,
      suggestionSetsTotal,
      users: { byId: usersById = {} } = {},
    } = this.props;

    const suggestionSetsProps = {
      complete,
      employersById,
      handleChangeState: this.handleChangeState,
      handleSetState: this.handleSetState,
      loadMoreSuggestionSets: this.loadMoreSuggestionSets,
      rainmakersAdmins,
      resourceUpdate,
      suggestionSets,
      suggestionSetsLoading,
      suggestionSetsTotal,
    };

    const createSuggestionSetProps = {
      handleCloseClick: () =>
        this.setState({
          selectedSuggestionSet: null,
        }),
      patchResource,
      rainmakersAdmins,
      requisitionOpeningId: id,
      slice: 'toolboxRequisitions',
      suggestionSet: selectedSuggestionSet,
      usersById,
    };

    const createSuggestionSetModal = selectedSuggestionSet ? (
      <CreateSuggestionSet {...createSuggestionSetProps} />
    ) : null;

    return (
      <div className={styles.ReqForm}>
        <SuggestionSetsTable {...suggestionSetsProps} />
        {createSuggestionSetModal}
      </div>
    );
  }
}

export default SuggestionSets;
