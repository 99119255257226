import { call } from 'redux-saga/effects';
import store from 'store';
import { trackEvent } from 'utils/analytics';
import { segmentSessionEndUser } from 'segment/eventNames';

const sagaCheckSessionEnd = function* sagaCheckSessionEnd({ useLastActive = false }) {
  const start = store.get('sessionStart');

  const sessionStart = Date.parse(start);

  const lastActive = store.get('sessionLastActive');

  const sessionLastActive = Date.parse(lastActive);

  if (sessionLastActive && sessionStart) {
    const currentTime = new Date();

    const elapsedTimeSeconds = (currentTime - sessionLastActive) / 1000;

    if (elapsedTimeSeconds >= 1800) {
      const sessionEnd = new Date();

      const sessionDurationTotal = (sessionEnd - sessionStart) / 1000;

      const sessionDuration = useLastActive
        ? sessionDurationTotal - elapsedTimeSeconds
        : sessionDurationTotal;

      const properties = {
        sessionDuration,
      };

      const event = segmentSessionEndUser;

      const eventObject = { event, properties };

      yield call(trackEvent, eventObject);

      store.set('sessionStart', null);
      store.set('sessionLastActive', null);
    }
  }
};

export default sagaCheckSessionEnd;
