export const camelToHuman = (string) => string.replace(/_/g, ' ');

export const indexOfRegex = (string, regex, start) => {
  const position = start || 0;
  const indexOf = string.substring(position).search(regex);
  return indexOf >= 0 ? indexOf + position : indexOf;
};

export const getInitials = ({ firstName, fullName, lastName }) => {
  if (fullName) {
    const arrayOfNames = fullName.split(' ');
    return `${arrayOfNames[0][0]}.${arrayOfNames[arrayOfNames.length - 1][0]}.`;
  }

  const firstInitial = firstName && firstName[0];
  const splitLastName = lastName && lastName.split(' ');
  const lastInitial = lastName && splitLastName[splitLastName.length - 1][0];

  if (firstInitial && lastInitial) {
    return `${firstInitial}.${lastInitial}.`;
  }

  return undefined;
};
