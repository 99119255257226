import { patchEntity } from 'api/apiEntity';

// Identify Logged in user
export const identify = ({ userId = '', traits = {} }) => {
  if (typeof window === 'undefined' || !window.analytics) return;

  window.analytics.identify(userId, traits);
};

// Connect anonymous visitors to identified users
export const alias = ({ userId = '' }) => {
  if (typeof window === 'undefined' || !window.analytics) return;

  window.analytics.alias(userId);
};

// Calling reset will reset the id, including anonymousId, and clear traits for the currently identified user and group.
export const reset = () => {
  if (typeof window === 'undefined' || !window.analytics) return;

  window.analytics.reset();
  window.mixpanel.reset();
};

// Track Page view Segment and GA
export const trackPage = () => {
  if (typeof window === 'undefined' || !window.analytics) return;

  window.analytics.page();
};

// Update mixpanel last seen
export const updateLastSeen = () => {
  if (typeof window === 'undefined' || !window.mixpanel) return;

  if (window.mixpanel.people && window.mixpanel.people.set) {
    return window.mixpanel.people.set({ lastActivity: new Date() });
  }

  return null;
};

// Track Event
export const trackEvent = ({ event = '', properties = {} }) => {
  trackSegmentEvent({ event: event, properties: properties });
  trackGA4Event({ event: event, properties: properties });
  updateLastSeen();
};

// Track Segment Event
export const trackSegmentEvent = ({ event = '', properties = {} }) => {
  if (typeof window === 'undefined' || !window.analytics) return;

  window.analytics.track(event, properties);
};

// Track GA4 Event
export const trackGA4Event = ({ event = '', properties = {} }) => {
  if (typeof window === 'undefined' || typeof window.dataLayer === 'undefined' || !window.dataLayer)
    return;

  window.dataLayer.push({ event: event, ...properties });
};

// Get mixpanel distinct_id
export const getDistinctId = () => {
  if (typeof window === 'undefined' || !window.mixpanel) return;

  if (window.mixpanel.get_distinct_id) {
    return window.mixpanel.get_distinct_id();
  }

  return null;
};

// Set mixpanel time_event
export const timeEvent = (eventName) => {
  if (typeof window === 'undefined' || !window.mixpanel) return;

  if (window.mixpanel.time_event) {
    return window.mixpanel.time_event(eventName);
  }

  return null;
};

// Get ga cookie value and send to API
export const sendGAClientId = (userId) => {
  if (typeof window === 'undefined' || !window.ga) return;

  if (window.ga) {
    return window.ga((tracker) => {
      const clientId = tracker.get('clientId');

      if (clientId) {
        const args = {
          type: 'users',
          id: userId,
          data: {
            type: 'user',
            id: userId,
            attributes: {
              ga_client_id: clientId,
            },
          },
        };

        return patchEntity(args);
      }
    });
  }

  return null;
};

// Make analytics payload
const UTM_KEYS = {
  utm_source: 'source',
  utm_medium: 'medium',
  utm_term: 'term',
  utm_content: 'content',
  utm_campaign: 'name',
};

export const makeAnalyticsPayload = ({ utmInfo, referer }) => {
  const analytics = {
    context: {},
  };

  const distinctId = getDistinctId();

  if (distinctId) {
    analytics.distinct_id = distinctId;
  }

  if (Object.keys(utmInfo).length) {
    analytics.context.campaign = {};

    Object.keys(utmInfo).forEach((key) => {
      if (UTM_KEYS[key]) analytics.context.campaign[UTM_KEYS[key]] = utmInfo[key];
    });
  }

  if (referer) {
    analytics.context.page = {
      referrer: referer,
    };
  }

  return analytics;
};
