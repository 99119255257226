import { getEntity } from 'api/apiEntity';

const promisePickerOptions = () => {
  const onFulfilled = ({
    data: {
      candidate_requirements: { locations_v2: locations = [], work_models = [] } = {},
      candidate_options: candOptions = {},
      candidate_search: candSearch = {},
      onboarding: { roles = [] } = {},
      requisition_requirements: { previous_roles: previousRoles = [] } = {},
    } = {},
  }) => {
    const pickerOptions = {
      ...candOptions,
      ...candSearch,
      role: roles,
      candReqLocations: locations,
      previousRoles,
      workModels: work_models,
    };
    return pickerOptions;
  };

  const args = {
    type: 'data_store/pickers',
  };

  return getEntity(args).then(onFulfilled);
};

export default promisePickerOptions;
