import { createStructuredSelector } from 'reselect';

import {
  entitySelectorFactory,
  loadingSelectorFactory,
  pageSelectorFactory,
  totalSelectorFactory,
  savingSelectorFactory,
} from 'reduxStore/selectorFactories';

import {
  selectIsLoggedIn,
  selectIsAdmin,
  selectLogoutPending,
} from 'connectors/Login/redux/selectors';

import { getModal } from 'shared/store/app/selectors';
import { selectUser } from 'connectors/User/redux/selectors';

import { selectPickerOptions } from 'connectors/Defaults/redux/selectors';

import selectRainmakersAdmin from './selectRainmakersAdmin';
import selectRainmakersAdminErrors from './selectRainmakersAdminErrors';
import selectUserErrors from './selectUserErrors';

const selector = createStructuredSelector({
  candidateSuggestions: entitySelectorFactory('toolboxAdmins', 'candidateSuggestion'),
  candidateSuggestionsLoading: loadingSelectorFactory('toolboxAdmins', 'candidateSuggestion'),
  candidateSuggestionsPage: pageSelectorFactory('toolboxAdmins', 'candidateSuggestion'),
  candidateSuggestionsTotal: totalSelectorFactory('toolboxAdmins', 'candidateSuggestion'),
  employers: entitySelectorFactory('toolboxAdmins', 'employer'),
  isLoading: loadingSelectorFactory('toolboxAdmins', 'rainmakersAdmin'),
  isLoggedIn: selectIsLoggedIn,
  isAdmin: selectIsAdmin,
  logoutPending: selectLogoutPending,
  modal: getModal,
  page: pageSelectorFactory('toolboxAdmins', 'rainmakersAdmin'),
  pickerOptions: selectPickerOptions,
  rainmakersAdmin: selectRainmakersAdmin,
  rainmakersAdmins: entitySelectorFactory('toolboxAdmins', 'rainmakersAdmin'),
  rainmakersAdminErrors: selectRainmakersAdminErrors,
  requisitionOpenings: entitySelectorFactory('toolboxAdmins', 'requisitionOpening'),
  requisitionRequirements: entitySelectorFactory('toolboxAdmins', 'requisitionRequirement'),
  saving: savingSelectorFactory('toolboxAdmins'),
  suggestionSets: entitySelectorFactory('toolboxAdmins', 'suggestionSet'),
  suggestionSetsLoading: loadingSelectorFactory('toolboxAdmins', 'suggestionSet'),
  suggestionSetsPage: pageSelectorFactory('toolboxAdmins', 'suggestionSet'),
  suggestionSetsTotal: totalSelectorFactory('toolboxAdmins', 'suggestionSet'),
  total: totalSelectorFactory('toolboxAdmins', 'rainmakersAdmin'),
  user: selectUser,
  userErrors: selectUserErrors,
  users: entitySelectorFactory('toolboxAdmins', 'user'),
});

export { selector as default };
