import React from 'react';

import GooglePlacesAutocomplete from 'components/GooglePlacesAutocomplete';
import Input from 'components/Input';
import InputCheckbox from 'components/InputCheckbox';
import Label from 'components/Label';
import Tag from 'components/Tag';

import Disclaimer from '../Disclaimer';
import ActionBlock from '../ActionBlock';

import styles from './RegistrationForm.scss';

const RegistrationForm = ({
  serverErrorContent,
  handleRequirementInputChange,
  handleHiringLocationSelect,
  handleHiringLocationBlur,
  handleDeleteHiringLocation,
  handleSubmitForm,
  handleOnBlur,
  signupErrors,
  firstName,
  lastName,
  email,
  name,
  hiringLocations,
  phoneNumber,
  password,
  workRemote,
}) => {
  const signupValid = () => {
    const invalidInputs = Object.keys(signupErrors).filter((input) => {
      const invalidRules = Object.keys(signupErrors[input]).filter((rule) => {
        if (input === 'hiringLocations' && workRemote === true) {
          return false;
        }

        return signupErrors[input][rule][0] === false || signupErrors[input][rule][0] === null;
      });
      return invalidRules.length > 0;
    });
    return invalidInputs.length === 0;
  };

  const {
    firstName: firstNameErrors,
    lastName: lastNameErrors,
    email: emailErrors,
    name: nameErrors,
    phoneNumber: phoneNumberErrors,
    password: passwordErrors,
  } = signupErrors;

  const commonTextInputProps = {
    handleOnBlur,
    handleInputChange: handleRequirementInputChange,
    type: 'text',
    required: true,
    size: 'full',
    topLabel: true,
  };

  const commonPasswordInputProps = {
    handleInputChange: handleRequirementInputChange,
    type: 'password',
    required: true,
    size: 'full',
    topLabel: true,
    autoComplete: 'new-password',
  };

  const firstNameInputProps = {
    ...commonTextInputProps,
    autoComplete: 'given-name',
    name: 'firstName',
    value: firstName,
    label: 'First Name',
    placeholder: 'First name',
    errors: firstNameErrors,
    autoFocus: true,
  };

  const lastNameInputProps = {
    ...commonTextInputProps,
    autoComplete: 'family-name',
    name: 'lastName',
    value: lastName,
    label: 'Last Name',
    placeholder: 'Last name',
    errors: lastNameErrors,
  };

  const emailInputProps = {
    ...commonTextInputProps,
    autoComplete: 'email',
    errors: emailErrors,
    label: 'Work Email',
    name: 'email',
    placeholder: 'Your business email',
    value: email,
  };

  const businessNameInputProps = {
    ...commonTextInputProps,
    autoComplete: 'organization',
    name: 'name',
    value: name,
    label: 'Business Name',
    placeholder: 'Business name',
    errors: nameErrors,
  };

  const phoneNumberInputProps = {
    ...commonTextInputProps,
    autoComplete: 'tel-national',
    name: 'phoneNumber',
    value: phoneNumber || '',
    label: 'Phone',
    placeholder: 'Area code first',
    errors: phoneNumberErrors,
    type: 'tel',
  };

  const passwordInputProps = {
    ...commonPasswordInputProps,
    autoComplete: 'new-password',
    name: 'password',
    value: password,
    label: 'Create a Password',
    placeholder: '********',
    errors: passwordErrors,
  };

  const hiringLocationsInputProps = {
    handleBlur: handleHiringLocationBlur,
    name: 'hiringLocations',
    placeholder: 'San Francisco, CA',
    handleSelect: handleHiringLocationSelect,
    needReset: true,
  };

  const hiringLocationsSelected = hiringLocations
    ? hiringLocations.map((loc) => {
        const tagProps = {
          name: loc,
          inputName: 'hiringLocations',
          onDeleteClick: (name, inputName) =>
            handleDeleteHiringLocation({
              value: name,
              name: inputName,
            }),
        };
        return <Tag key={loc} {...tagProps} />;
      })
    : null;

  const hiringLocationsContent =
    hiringLocations.length > 0 ? (
      <div className={styles.autocompleteContainer}>{hiringLocationsSelected}</div>
    ) : null;

  const workRemoteProps = {
    name: 'workRemote',
    label: 'Remote positions',
    checked: workRemote,
    handleInputChange: handleRequirementInputChange,
    className: styles.checkbox,
    checkboxMany: true,
  };

  const formProps = {
    method: 'post',
    className: styles.Form,
    onSubmit: signupValid ? handleSubmitForm : () => null,
  };

  return (
    <div className={styles.registrationFormContainer}>
      <div className={styles.applyHeader}>
        Start browsing the profiles of top sales candidates now.
      </div>

      <form {...formProps}>
        {serverErrorContent}
        <div className={styles.rowInputs}>
          <Input {...firstNameInputProps} />
          <Input {...lastNameInputProps} />
        </div>
        <Input {...emailInputProps} />
        <Input {...businessNameInputProps} />
        <Input {...phoneNumberInputProps} />
        <Label required standalone className={styles.label}>
          Hiring Locations
        </Label>
        <GooglePlacesAutocomplete {...hiringLocationsInputProps} />
        {hiringLocationsContent}
        <InputCheckbox {...workRemoteProps} />
        <Input {...passwordInputProps} />

        <Disclaimer />

        <ActionBlock signupValid={signupValid()} handleSubmitForm={handleSubmitForm} />
      </form>
    </div>
  );
};

export default RegistrationForm;
