import React from 'react';

import styles from './Graphic.scss';

const Graphic = () => (
  <div className={styles.Graphic}>
    <div className={styles.QuoteContainerOne}>
      <div className={styles.QuoteOne}>
        &ldquo;Rainmakers is awesome! They helped me land a role that is absolutely perfect for
        me.&rdquo;
      </div>
      <div className={styles.Person}>
        <div className={styles.Avatar}>
          <img src={require('../../assets/quote_avatar_1.png')} alt="" />
        </div>
        <div className={styles.Info}>
          <div className={styles.Name}>Sarah Draxton</div>
          <div className={styles.Role}>SDR</div>
        </div>
      </div>
    </div>

    <div className={styles.QuoteContainer}>
      <div className={styles.QuoteOne}>
        &ldquo;Working with Rainmakers was an easy and seamless experience. I had an offer in hand
        in a little over a week and am flourishing in my new role.&rdquo;
      </div>
      <div className={styles.Person}>
        <div className={styles.Avatar}>
          <img src={require('../../assets/quote_avatar_2.png')} alt="" />
        </div>
        <div className={styles.Info}>
          <div className={styles.Name}>Matt Garcia</div>
          <div className={styles.Role}>Account Executive</div>
        </div>
      </div>
    </div>
  </div>
);

export default Graphic;
