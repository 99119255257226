import React from 'react';

import Bolder from 'components/Bolder';
import FormattedArray from 'components/FormattedArray';

import styles from './FormattedArrayWithLabel.scss';

const FormattedArrayWithLabel = ({ array, label }) => {
  const props = {
    array,
    cutOff: 4,
  };

  return array && array.length > 0 && array.every((el) => el !== '') ? (
    <div className={styles.Array}>
      <Bolder>{label}</Bolder>
      <FormattedArray {...props} />
    </div>
  ) : null;
};

export default FormattedArrayWithLabel;
