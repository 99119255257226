import React from 'react';

import Button from 'components/Button';
import CandidateData from 'components/CandidateData';
import DropdownSelectMany from 'components/DropdownSelectMany';
import FormattedDate from 'components/FormattedDate';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import InputCheckbox from 'components/InputCheckbox';
import Table from 'components/Table';
import TableCell from 'components/Table/components/TableCell';
import TableRow from 'components/Table/components/TableRow';

import styles from './ToolboxBatchRequestsTable.scss';

const STATUSES = [
  { label: 'Pending', value: 'pending' },
  { label: 'Declined', value: 'declined' },
  { label: 'Accepted', value: 'accepted' },
  { label: 'Active', value: 'active' },
  { label: 'Complete', value: 'complete' },
];

const ToolboxBatchRequestsTable = ({
  actions: { showModal, resourceUpdate, patchResource },
  isLoading,
  batchRequests: { allIds = [], byId = {} } = {},
  candidates: { byId: candidatesById = {} } = {},
  handleSearchFilterChange,
  state,
  total,
  loadMoreBatchRequests,
  handleShowCohorts,
}) => {
  const tableHeader = [
    {
      content: 'Name',
      headerClassName: styles.nameHeader,
    },
    {
      content: 'Updated At',
      headerClassName: styles.dateHeader,
    },
    {
      content: 'Email Off',
      headerClassName: styles.dateHeader,
    },
    {
      content: '',
      headerClassName: styles.declineHeader,
    },
  ];

  const makeBatchRequestCells = (id) => {
    const {
      attributes: { candidateId, checked = false, updatedAt, sendEmail, state: batchRequestState },
    } = byId[id] || {};

    const candProps = {
      onClick: () =>
        showModal({
          key: 'CandidateProfileModal',
          parent: 'ToolboxBatchRequestsPage',
          route: `/candidates/${candidateId}/profile/`,
          search: '?tp=adbr',
        }),
      candidate: candidatesById[candidateId],
    };

    const idContainerProps = {
      className: styles.batchRequestContent,
    };

    const inputCheckboxProps = {
      checked,
      handleInputChange: (event) => {
        const {
          target: { checked },
        } = event;

        resourceUpdate({
          slice: 'toolboxBatchRequests',
          type: 'batch_requests',
          id,
          resource: {
            ...byId[id],
            attributes: {
              ...byId[id].attributes,
              checked,
            },
          },
        });
      },
      name: 'selectCandidate',
    };

    const emailCheckboxProps = {
      checked: !sendEmail,
      handleInputChange: (event) => {
        const {
          target: { checked },
        } = event;

        patchResource({
          type: 'batch_requests',
          slice: 'toolboxBatchRequests',
          id,
          attributes: {
            sendEmail: !checked,
          },
        });
      },
      name: 'emailCandidate',
    };

    const batchRequestCheckboxContent =
      batchRequestState === 'pending' ? <InputCheckbox {...inputCheckboxProps} /> : null;

    const updatedAtProps = {
      className: styles.dateContent,
    };

    const updatedAtContent = (
      <div {...updatedAtProps}>
        <FormattedDate parse date={updatedAt} format="MMM D YYYY" />
      </div>
    );

    const batchRequestDataContent = (
      <div {...idContainerProps}>
        {batchRequestCheckboxContent}
        <CandidateData {...candProps} />
      </div>
    );

    const sendEmailContent = (
      <div className={styles.sendEmailContent}>
        <InputCheckbox {...emailCheckboxProps} />
      </div>
    );

    const declineButtonProps = {
      warning: true,
      onClick: () => {
        patchResource({
          type: 'batch_requests',
          slice: 'toolboxBatchRequests',
          id,
          attributes: {
            state: 'declined',
          },
        });
      },
      disabled: checked,
    };

    const declineButtonContent =
      batchRequestState === 'pending' ? (
        <div className={styles.declineContent}>
          <Button {...declineButtonProps}>Decline Request</Button>
        </div>
      ) : (
        <div className={styles.declineContent} />
      );

    const cells = [
      <TableCell key={1}>{batchRequestDataContent}</TableCell>,
      <TableCell key={2}>{updatedAtContent}</TableCell>,
      <TableCell key={3}>{sendEmailContent}</TableCell>,
      <TableCell noFlex key={4}>
        {declineButtonContent}
      </TableCell>,
    ];

    return cells;
  };

  const makeBatchRequestRows = (id) => (
    <TableRow id={id} key={id}>
      {makeBatchRequestCells(id)}
    </TableRow>
  );

  const tableContent = allIds.map(makeBatchRequestRows);

  const stateSource = STATUSES.map((state) => ({
    indent: 0,
    label: state.label,
    optionType: 'role',
    subCategories: [],
    value: {
      type: 'state',
      state: state.value,
    },
  }));

  const selectedStates = state.map((state) => ({ state, type: 'state' }));

  const stateFilterProps = {
    className: styles.searchBarFilter,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    source: stateSource,
    name: 'state',
    key: 'state',
    optionsLarge: true,
    isLocation: true,
    onChange: (value) =>
      handleSearchFilterChange({
        target: {
          subCategories: value.subCategories,
          label: value.label,
          value: value.value,
          name: 'state',
          optionType: value.optionType,
        },
      }),
    selectedValues: selectedStates,
    defaultLabel: 'Click to select states',
  };

  const addToCohortProps = {
    onClick: handleShowCohorts,
    primary: true,
    className: styles.newCandButton,
    disabled: !allIds.some((id) => byId[id].attributes.checked),
  };

  const filtersContent = (
    <div className={styles.batchRequestHeader}>
      <div className={styles.candidateHeaderLeft}>
        <DropdownSelectMany {...stateFilterProps} />
      </div>
      <Button {...addToCohortProps}>+ Add to Cohort</Button>
    </div>
  );

  const infiniteScrollProps = {
    loadMore: loadMoreBatchRequests,
    hasMore: total > allIds.length,
    loader: isLoading ? (
      <div key="infiniteScrollLoader" />
    ) : (
      <InfiniteScrollLoader key="infiniteScrollLoader" />
    ),
    useWindow: false,
  };

  const tableProps = {
    titleContent: filtersContent,
    tableContent,
    status: state,
    header: tableHeader,
    infiniteScrollProps,
    tableName: 'adminBatchRequests',
    isEmpty: !isLoading && allIds.length === 0,
    emptyTextString: 'No Cohort Requests',
    isLoading,
  };

  return <Table {...tableProps} />;
};

export default ToolboxBatchRequestsTable;
