import { createStructuredSelector } from 'reselect';

import { getModal } from 'shared/store/app/selectors';

import {
  selectIsLoggedIn,
  selectIsAdmin,
  selectLogoutPending,
} from 'connectors/Login/redux/selectors';
import { selectUser } from 'connectors/User/redux/selectors';

import selectTemplates from './selectTemplates';
import selectPage from './selectPage';
import selectTotal from './selectTotal';
import selectLoading from './selectLoading';

const selector = createStructuredSelector({
  logoutPending: selectLogoutPending,
  user: selectUser,
  isLoggedIn: selectIsLoggedIn,
  isAdmin: selectIsAdmin,
  modal: getModal,
  templates: selectTemplates,
  page: selectPage,
  total: selectTotal,
  isLoading: selectLoading,
});

export { selectLoading, selectTemplates, selectPage, selectTotal, selector as default };
