import { call, put, select, cancel, take, fork } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { handleError } from 'utils/common';

import { patchEntity } from 'api/apiEntity';

import sagaValidateInput from 'connectors/Validator/redux/sagas/sagaValidateInput';

import { selectCandidateErrors, selectCandidateUser } from '../selectors';

import { USER_PATCH_STARTED } from '../actions';

import {
  userUpdate,
  userPatchDone,
  candidateSaveStarted,
  candidateSaveDone,
  candidateSaveError,
  candidateErrorsSet,
} from '../creators';

const patchUser = function* patchUser(action) {
  const {
    payload: { newValue, name },
  } = action;

  yield put(userUpdate({ newValue, name }));
  yield put(candidateSaveStarted());

  const errors = yield select(selectCandidateErrors);

  const { isValid, inputErrors } = yield call(sagaValidateInput, {
    newValue,
    name,
    errors,
  });

  yield put(candidateErrorsSet({ inputErrors }));

  yield call(delay, 750);

  const userObject = yield select(selectCandidateUser);

  if (userObject.id && isValid) {
    try {
      const args = {
        data: {
          type: 'users',
          id: userObject.id,
          attributes: {
            [name]: newValue,
          },
        },
        id: userObject.id,
        type: 'users',
        config: {
          params: {
            camelizeIt: true,
          },
        },
      };

      const {
        data: { user },
      } = yield call(patchEntity, args);

      yield put(userPatchDone({ name, newValue: user[name] }));
      yield put(candidateSaveDone());
    } catch (error) {
      handleError(error);
      yield put(candidateSaveError("We're sorry, something went wrong."));
    }
  } else {
    yield put(candidateSaveDone());
  }
};

const sagaUserPatchStarted = function* sagaUserPatchStarted() {
  let lastTask;
  let lastAction;
  while (true) {
    const action = yield take(USER_PATCH_STARTED);
    if (lastTask && lastAction.payload.name === action.payload.name) {
      yield cancel(lastTask);
    }
    lastAction = action;
    lastTask = yield fork(patchUser, action);
  }
};

export default sagaUserPatchStarted;
