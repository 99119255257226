import { call, put, take } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { promiseGetAdmin } from 'pages/AdminPortal/ToolboxAdminsPage/promises';

import { RAINMAKERS_ADMIN_LOAD_STARTED } from '../actions';

import { rainmakersAdminLoadDone } from '../creators';

const sagaRainmakersAdminLoadStarted = function* sagaRainmakersAdminLoadStarted() {
  while (true) {
    const {
      payload: { adminId },
    } = yield take(RAINMAKERS_ADMIN_LOAD_STARTED);

    try {
      const params = {
        adminId,
        includeSet: 'user',
        camelizeIt: true,
      };

      const { admin = {} } = yield call(promiseGetAdmin, params);

      yield put(rainmakersAdminLoadDone({ rainmakersAdmin: admin }));
    } catch (error) {
      handleError(error);
    }
  }
};

export default sagaRainmakersAdminLoadStarted;
