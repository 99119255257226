import React from 'react';
import classnames from 'classnames';

import styles from './Navigation.scss';

const Navigation = ({ children, className, style }) => (
  <nav className={classnames(className, styles.Navigation)} style={style}>
    {children}
  </nav>
);

export default Navigation;
