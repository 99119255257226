import React from 'react';

import InputBullets from 'components/InputBullets';

const Opportunities = ({
  requisitionOpening: { attributes: { opportunities, state } = {} } = {},
  handleAddArrayInput,
  handleAddArrayValue,
  handleDeleteArrayInput,
  handleReorderArray,
}) => {
  const opportunitiesInputProps = {
    label: 'Why is this a great opportunity?',
    array: opportunities,
    name: 'opportunities',
    handleAddArrayInput,
    handleAddArrayValue,
    handleDeleteArrayInput,
    handleReorderArray,
    pad: true,
    required: state !== 'closed',
    disabled: state === 'closed',
  };

  return <InputBullets {...opportunitiesInputProps} />;
};

export default Opportunities;
