import { createStructuredSelector } from 'reselect';

import selectNewPasswordErrors from './selectNewPasswordErrors';
import selectNewPasswordDone from './selectNewPasswordDone';

const selector = createStructuredSelector({
  newPasswordErrors: selectNewPasswordErrors,
  newPasswordDone: selectNewPasswordDone,
});

export { selectNewPasswordErrors, selectNewPasswordDone, selector as default };
