import React, { Fragment } from 'react';
import classnames from 'classnames';

import FormattedDate from 'components/FormattedDate';
import FormattedMoney from 'components/FormattedMoney';
import TableCell from 'components/Table/components/TableCell';

import EmployerRequisitionsActionDropdown from '../EmployerRequisitionsActionDropdown';

import styles from '../EmployerRequisitionsTable/EmployerRequisitionsTable.scss';

const EmployerRequisitionsRow = ({
  dispatch,
  duplicateRequisition,
  requisitionOpening,
  requisitionOpening: {
    attributes: {
      abbreviatedLocations = [],
      activeRequisitionCandidates = 0,
      closedOn,
      creator: { firstName = '', lastName = '' } = {},
      creatorType,
      externalName = '',
      headcount,
      hiredRequisitionCandidates,
      oteMaxCents = '',
      oteMinCents = '',
      publishedOn,
      state,
      unlimitedHeadcount,
    } = {},
    id,
  } = {},
  patchResource,
  resourceUpdate,
  showModal,
}) => {
  const locationsContent = abbreviatedLocations.map((loc) => (
    <div key={loc} className={styles.location}>
      {loc}
    </div>
  ));

  const locationsCell = <div className={styles.locationsCell}>{locationsContent}</div>;

  const oteMaxContent =
    oteMaxCents && oteMaxCents !== 0 ? (
      <div className={styles.oteMax}>
        <FormattedMoney amount={oteMaxCents} />
      </div>
    ) : null;

  const oteMinContent = oteMinCents ? (
    <div className={styles.oteMin}>
      <FormattedMoney amount={oteMinCents} />
    </div>
  ) : null;

  const dashContent = oteMinContent && oteMaxContent ? <span> - </span> : null;

  const oteRangeCell =
    oteMinCents !== undefined ? (
      <div className={styles.oteRangeCell}>
        {oteMinContent}
        {dashContent}
        {oteMaxContent}
      </div>
    ) : (
      <div className={styles.oteRangeCell} />
    );

  const reqNameClick = () => {
    showModal({
      key: 'ReqDetailModal',
      parent: 'ManageReqsPage',
      route: `/requisitions/${id}/preview`,
    });
  };

  const reqNameCellProps = {
    className: styles.reqName,
    onClick: reqNameClick,
  };

  const reqNameCell = <div {...reqNameCellProps}>{externalName}</div>;

  const notOpenHeader =
    state !== 'open' ? `${state.charAt(0).toUpperCase()}${state.slice(1)}` : null;
  const unlimitedHeader = unlimitedHeadcount ? 'Published' : null;
  const openHeader = hiredRequisitionCandidates >= headcount ? 'Full' : 'Published';

  const statusHeader = notOpenHeader || unlimitedHeader || openHeader;

  const headerClasses = classnames(styles.headerStatus, {
    [styles.fullStatus]: statusHeader === 'Full',
    [styles.pendingStatus]: statusHeader === 'Pending',
    [styles.openStatus]: statusHeader === 'Published',
  });

  const pendingSubstatus = state === 'pending' ? 'Not published' : null;
  const unlimitedSubstatus = unlimitedHeadcount
    ? `${hiredRequisitionCandidates} positions filled`
    : null;
  const openSubstatus = `${hiredRequisitionCandidates}/${headcount} positions filled`;

  const subStatusContent = pendingSubstatus || unlimitedSubstatus || openSubstatus;

  const headcountCell = (
    <div className={styles.statusReqCell}>
      <div className={headerClasses}>{statusHeader}</div>
      <div className={styles.subStatus}>{subStatusContent}</div>
    </div>
  );

  const reqActionDropdownProps = {
    dispatch,
    duplicateRequisition,
    patchResource,
    requisitionOpening,
    resourceUpdate,
    showModal,
    icon: true,
  };

  const actionsCell = (
    <div className={styles.actionsCell}>
      <EmployerRequisitionsActionDropdown {...reqActionDropdownProps} />
    </div>
  );

  const candidatesCell =
    activeRequisitionCandidates === 0 ? (
      <div className={styles.candidateReqCell}>-</div>
    ) : (
      <div className={styles.candidateReqCell}>{activeRequisitionCandidates} active</div>
    );

  const publishedContent =
    state === 'open' ? <FormattedDate date={publishedOn} format="MMM D, YYYY" parse /> : null;

  const closedContent =
    state === 'closed' ? <FormattedDate date={closedOn} format="MMM D, YYYY" parse /> : null;

  const pendingContent = 'Pending';

  const dateContent = publishedContent || closedContent || pendingContent;

  const dateCell = <div className={styles.regularReqCell}>{dateContent}</div>;

  const creatorContent =
    creatorType === 'RainmakersAdmin' ? 'RM Admin' : `${firstName} ${lastName}`;

  const creatorCell = <div className={styles.regularReqCell}>{creatorContent}</div>;

  return (
    <Fragment>
      <TableCell key={1}>{reqNameCell}</TableCell>
      <TableCell key={2}>{headcountCell}</TableCell>
      <TableCell key={3}>{locationsCell}</TableCell>
      <TableCell key={4}>{oteRangeCell}</TableCell>
      <TableCell key={5}>{candidatesCell}</TableCell>
      <TableCell key={6}>{dateCell}</TableCell>
      <TableCell key={7}>{creatorCell}</TableCell>
      <TableCell noFlex key={8}>
        {actionsCell}
      </TableCell>
    </Fragment>
  );
};

export default EmployerRequisitionsRow;
