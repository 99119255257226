import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';

import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';

import { CandidateAuthHOC } from 'containers/HOC';
import ConnectContainer from 'containers/ConnectContainer';

import ReadyContainer from 'connectors/ReadyContainer';

import duck from '../redux';
import styles from '../Settings.scss';

import Approved from './components/Approved';
import Welcome from './components/Welcome';
import Greeting from './components/Greeting';

@CandidateAuthHOC()
class CandidateSignupStatus extends Component {
  componentDidMount() {
    const {
      actions: { candidateLoadStarted },
    } = this.props;

    candidateLoadStarted();
  }

  componentWillUnmount() {
    const {
      isApproved,
      hasSeenApproved,
      actions: { candidateProfilePatchStarted },
    } = this.props;

    if (isApproved && !hasSeenApproved) {
      candidateProfilePatchStarted({ name: 'approvedViewSeen', newValue: true });
    }
  }

  render() {
    const { isApproved } = this.props;

    const topContent = isApproved ? <Approved /> : <Welcome />;

    const content = (
      <div className={styles.WelcomePage}>
        {topContent}
        <Greeting />
      </div>
    );

    const layoutWithoutSidebarProps = {
      content,
    };

    return (
      <React.Fragment>
        <Helmet title="Application Status" />
        <ReadyContainer className={styles.EmployerStatusPage}>
          <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />
        </ReadyContainer>
      </React.Fragment>
    );
  }
}

export default ConnectContainer(duck)(CandidateSignupStatus);
