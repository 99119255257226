import React, { Component } from 'react';

import { toggleArray } from 'utils/formUtils';

import CandidateWrapper from 'pages/EmployeePortal/CandidateProfilePages/CandidateWrapper';

import styles from '../../ToolboxRequisitionEditor.scss';

import RequisitionCandidatesTable from './components/RequisitionCandidatesTable';

class EmployerEditorRequisitionCandidates extends Component {
  constructor(props) {
    super(props);

    const {
      match: {
        params: { id },
      },
    } = props;

    this.state = {
      requisitionOpeningId: id,
      selectedCandidateId: '',
      state: ['active', 'candidate_pass_pending'],
    };

    this.defaultLoadParams = {
      includeSet: 'candidate',
      page: 1,
      state: ['active', 'candidate_pass_pending'],
    };

    this.defaultLoadArgs = { slice: 'toolboxRequisitions' };
  }

  componentDidMount() {
    const {
      actions: { entityLoadStarted },
    } = this.props;

    entityLoadStarted({
      ...this.defaultLoadArgs,
      type: 'requisition_candidates',
      params: this.getLoadParams(),
    });
  }

  componentWillUnmount() {
    const {
      actions: { entityLoadDone },
    } = this.props;

    entityLoadDone({
      data: { entities: null, meta: {} },
      slice: 'toolboxRequisitions',
      type: 'requisition_candidates',
    });
  }

  getLoadParams = () => {
    const { requisitionCandidatesPage: page = 1 } = this.props;
    const { state, requisitionOpeningId } = this.state;

    return {
      ...this.defaultLoadParams,
      page,
      state,
      requisitionOpeningId,
    };
  };

  handleSearchFilterChange = (event) => {
    const {
      target: { name, value },
    } = event;
    const {
      actions: { entityLoadStarted },
    } = this.props;

    const { [name]: arrayToUpdate } = this.state;

    const actualValue = value[value.type];

    const newArray = toggleArray({ array: arrayToUpdate, value: actualValue });

    this.setState({ [name]: newArray }, () =>
      entityLoadStarted({
        ...this.defaultLoadArgs,
        type: 'requisition_candidates',
        params: {
          ...this.getLoadParams(),
          page: 1,
        },
      })
    );
  };

  loadMore = () => {
    const {
      actions: { entityLoadStarted },
      requisitionCandidatesPage: page,
    } = this.props;

    entityLoadStarted({
      ...this.defaultLoadArgs,
      type: 'requisition_candidates',
      params: {
        ...this.getLoadParams(),
        page: page + 1,
      },
    });
  };

  openProfileModal = (candidate) => {
    const {
      actions: { showModal },
      location: { pathname },
      modal: { originalUrl },
    } = this.props;

    this.setState({ selectedCandidateId: candidate.id });
    showModal({
      key: 'CandidateProfileModal',
      parent: 'ToolboxRequisitionEditorPage',
      originalUrl,
      backPath: pathname,
      backKey: 'RequisitionEditorModal',
      closeHook: () => this.setState({ selectedCandidateId: null }),
      silentClose: true,
    });
  };

  render() {
    const { selectedCandidateId, state } = this.state;

    const {
      location,
      modal,
      match: {
        params: { id },
      },
      requisitionCandidates: { allIds = [], byId = {} } = {},
      requisitionCandidatesLoading: isLoading,
      requisitionCandidatesPage: currentPage,
      requisitionCandidatesTotal: totalCount,
      candidates: { byId: candidatesById = {} } = {},
    } = this.props;

    const candidates = allIds.map((id) => {
      const { relationships: { candidate: { data: candidateIds = [] } = {} } = {} } = byId[id];
      const { [candidateIds[0]]: candidate = {} } = candidatesById || {};

      return candidate;
    });

    const requisitionCandidatesTableProps = {
      candidates,
      currentPage,
      handleSearchFilterChange: this.handleSearchFilterChange,
      isLoading,
      loadMore: this.loadMore,
      openProfileModal: this.openProfileModal,
      requisitionOpeningId: id,
      state,
      totalCount,
    };

    const candidateProfileProps = {
      location,
      modal,
      candidateProfileReqs: [],
      candidate: {},
      key: 'candidateProfile',
      match: {
        params: {
          id: selectedCandidateId,
          view: 'profile',
        },
      },
    };

    const candidateModal = selectedCandidateId ? (
      <CandidateWrapper {...candidateProfileProps} />
    ) : null;

    return (
      <div className={styles.ReqForm}>
        <RequisitionCandidatesTable {...requisitionCandidatesTableProps} />
        {candidateModal}
      </div>
    );
  }
}

export default EmployerEditorRequisitionCandidates;
