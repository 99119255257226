import React from 'react';
import classnames from 'classnames';
import moment from 'moment-timezone';

import { createCohort } from 'reduxStore/schema';

import Table from 'components/Table';
import TableRow from 'components/Table/components/TableRow';
import TableCell from 'components/Table/components/TableCell';
import FormattedDate from 'components/FormattedDate';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import Button from 'components/Button';

import ToolboxCohortsActionDropdown from '../ToolboxCohortsActionDropdown';

import ToolboxCohortsSearchBar from '../ToolboxCohortsSearchBar';

import styles from './ToolboxCohortsTable.scss';

const ToolboxCohortsTable = ({
  actions,
  actions: { resourceUpdate, showModal },
  category,
  cohorts: { allIds = [], byId = {} } = {},
  isLoading,
  handleSearchInputChange,
  handleSearchFilterChange,
  loadMore,
  name,
  state,
  total,
}) => {
  const tableHeader = [
    {
      content: 'Name',
      headerClassName: styles.nameHeader,
    },
    {
      content: 'Status',
      headerClassName: styles.statusHeader,
    },
    {
      content: 'Type',
      headerClassName: styles.typeHeader,
    },
    {
      content: 'Candidates',
      headerClassName: styles.candidatesHeader,
    },
    {
      content: 'Start Date',
      headerClassName: styles.dateHeader,
    },
    {
      content: 'End Date (EOD)',
      headerClassName: styles.endDateHeader,
    },
    {
      content: '',
      headerClassName: styles.cohortActionHeader,
    },
  ];

  const makeCohortCells = (id) => {
    const {
      attributes: { state, category, cohortCandidatesCount, endDate, name, startDate, locked } = {},
    } = byId[id] || {};

    const statusClassnames = classnames({
      [styles.activeStatus]: state === 'open' || state === 'active',
      [styles.inactiveStatus]: state === 'complete',
    });

    const statusText = `${state.charAt(0).toUpperCase()}${state.slice(1)}`;

    const statusContent = <div className={statusClassnames}>{statusText}</div>;

    const cohortNameProps = {
      onClick: () =>
        showModal({
          key: 'CohortEditorModal',
          parent: 'ToolboxCohortsPage',
          route: `/toolbox/cohort/${id}/edit-main`,
        }),
      className: styles.cohortContent,
    };

    const cohortNameContent = <div {...cohortNameProps}>{name}</div>;

    const actionsProps = {
      actions,
      cohortId: id,
    };

    const actionsContent = locked ? (
      <div className={styles.actionsContent} />
    ) : (
      <div className={styles.actionsContent}>
        <ToolboxCohortsActionDropdown {...actionsProps} />
      </div>
    );

    const candidatesContent = (
      <div className={styles.candidatesContent}>{cohortCandidatesCount}</div>
    );

    const momentStartDate = startDate ? moment.tz(startDate, 'America/Los_Angeles') : null;
    const momentEndDate = endDate ? moment.tz(endDate, 'America/Los_Angeles') : null;

    const startDateContent = startDate ? (
      <div className={styles.dateContent}>
        <FormattedDate parse format="MMM D YYYY" date={momentStartDate.format()} />
      </div>
    ) : (
      <div className={styles.dateContent}>-</div>
    );

    const endDateContent = endDate ? (
      <div className={styles.endDateContent}>
        <FormattedDate parse format="MMM D YYYY" date={momentEndDate.format()} />
      </div>
    ) : (
      <div className={styles.endDateContent}>-</div>
    );

    const typeContent = (
      <div className={styles.typeContent}>{category === 'featured' ? 'Match' : 'Featured'}</div>
    );

    const cells = [
      <TableCell key={1}>{cohortNameContent}</TableCell>,
      <TableCell key={2}>{statusContent}</TableCell>,
      <TableCell key={3}>{typeContent}</TableCell>,
      <TableCell key={4}>{candidatesContent}</TableCell>,
      <TableCell key={5}>{startDateContent}</TableCell>,
      <TableCell key={6}>{endDateContent}</TableCell>,
      <TableCell noFlex key={7}>
        {actionsContent}
      </TableCell>,
    ];

    return cells;
  };

  const makeCohortRows = (id) => (
    <TableRow id={id} key={id}>
      {makeCohortCells(id)}
    </TableRow>
  );

  const tableContent = allIds.filter((id) => !byId[id].attributes.temporary).map(makeCohortRows);

  const createSearchCohortClick = () => {
    const resource = createCohort();

    resourceUpdate({
      slice: 'toolboxCohorts',
      type: 'cohorts',
      id: resource.id,
      resource: {
        ...resource,
        attributes: {
          ...resource.attributes,
          category: 'search',
        },
      },
    });

    showModal({
      key: 'CohortEditorModal',
      parent: 'ToolboxCohortsPage',
      route: `/toolbox/cohort/${resource.id}/edit-main`,
    });
  };

  const createFeatureCohortClick = () => {
    const resource = createCohort();

    resourceUpdate({
      slice: 'toolboxCohorts',
      type: 'cohorts',
      id: resource.id,
      resource: {
        ...resource,
        attributes: {
          ...resource.attributes,
          category: 'featured',
        },
      },
    });

    showModal({
      key: 'CohortEditorModal',
      parent: 'ToolboxCohortsPage',
      route: `/toolbox/cohort/${resource.id}/edit-main`,
    });
  };

  const createSearchCohortProps = {
    onClick: createSearchCohortClick,
    primary: true,
    className: styles.newCohortButton,
  };

  const createFeatureCohortProps = {
    onClick: createFeatureCohortClick,
    primary: true,
    className: styles.newCohortButton,
  };

  const searchBarProps = {
    name,
    category,
    state,
    handleSearchInputChange,
    handleSearchFilterChange,
  };

  const filtersContent = (
    <div className={styles.cohortHeader}>
      <ToolboxCohortsSearchBar {...searchBarProps} />

      <div className={styles.cohortButtons}>
        <Button {...createSearchCohortProps}>+ Featured Cohort</Button>
        <Button {...createFeatureCohortProps}>+ Match Cohort</Button>
      </div>
    </div>
  );

  const infiniteScrollProps = {
    loadMore,
    hasMore: total > allIds.length,
    loader: isLoading ? (
      <div key="infiniteScrollLoader" />
    ) : (
      <InfiniteScrollLoader key="infiniteScrollLoader" />
    ),
    useWindow: false,
  };

  const tableProps = {
    titleContent: filtersContent,
    tableContent,
    status: state,
    header: tableHeader,
    infiniteScrollProps,
    tableName: 'adminCohorts',
    isEmpty: !isLoading && allIds.length === 0,
    emptyTextString: 'No Cohorts',
    isLoading,
  };

  return <Table {...tableProps} />;
};

export default ToolboxCohortsTable;
