import React, { useCallback, useEffect, useState } from 'react';

import Input from 'components/Input';
import FontIcon from 'components/FontIcon';
import InputRichText from 'components/InputRichText';
import Button from 'components/Button';

import { getCandidateNotes } from 'api/apiCandidates';
import { patchEntity } from 'api/apiEntity';

import { handleError } from 'utils/common';

import styles from './CandidateEditorNotes.scss';

const saveTitle = 'Save';
const savingTitle = 'Saving...';

export const CandidateEditorNotes = ({ candidateId, fullName, showEditor, setShowNotes }) => {
  const [noteId, setNoteId] = useState();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const [runAutosave, setRunAutosave] = useState(false);

  const [dataChanged, setDataChanged] = useState(false);

  const [saveButtonTitle, setSaveButtonTitle] = useState(saveTitle);

  const saveDisabled = !title;

  useEffect(() => {
    getCandidateNotes(candidateId)
      .then((response) => {
        const { data: { notes = [] } = {} } = response;

        const { id, title, content } = notes[0] || {};

        setTitle(title);
        setContent(content);
        setNoteId(id);
      })
      .catch(handleError);
  }, [candidateId]);

  const handleClickSave = useCallback(() => {
    setSaveButtonTitle(savingTitle);
    patchEntity({
      type: 'notes',
      id: noteId,
      data: {
        attributes: {
          title,
          content,
        },
      },
    })
      .then(() => {
        setDataChanged(false);
      })
      .catch(handleError);

    setTimeout(() => {
      setSaveButtonTitle(saveTitle);
    }, 1000);
  }, [noteId, content, title]);

  const doAutoSave = useCallback(() => {
    if (showEditor && dataChanged) {
      handleClickSave();
    }
  }, [dataChanged, handleClickSave, showEditor]);

  useEffect(() => {
    if (runAutosave) {
      doAutoSave();
      setRunAutosave(false);
    }
  }, [runAutosave, doAutoSave]);

  useEffect(() => {
    let interval;

    if (showEditor) {
      interval = setInterval(() => {
        setRunAutosave(true);
      }, 10_000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [showEditor]);

  if (!showEditor) return null;

  return (
    <div className={styles.notesBoxContainer}>
      <div className={styles.notesBoxHeader}>
        <div
          className={styles.notesButton}
          onClick={() => {
            doAutoSave();
            setShowNotes(false);
          }}
        >
          <FontIcon iconName="caret-right" />
        </div>
        <div>{`${fullName} Notes`}</div>
      </div>
      <div className={styles.notesArea}>
        <div className={styles.editorWrapper}>
          <Input
            className={styles.titleInput}
            size="full"
            name="note-title"
            placeholder="Note title"
            value={title}
            handleInputChange={({ target: { value } }) => {
              setTitle(value);
              setDataChanged(true);
            }}
          />
          <div className={styles.richTextEditor}>
            <InputRichText
              key={noteId}
              containerClassName={styles.myInputRichTextContainer}
              rteClassName={styles.height100}
              value={content}
              handleInputChange={({ target: { value } }) => {
                if (value !== content) {
                  setContent(value);
                  setDataChanged(true);
                }
              }}
              name="note1"
              placeholder=""
            />
          </div>
          <Button primary disabled={saveDisabled} onClick={handleClickSave}>
            {saveButtonTitle}
          </Button>
        </div>
      </div>
    </div>
  );
};
