import { call, put, take } from 'redux-saga/effects';
import camelCase from 'lodash.camelcase';
import { postSignIn } from 'api/apiAuth';
import { handleError } from 'utils/common';
import { setUserContext } from 'utils/ravenUtils';
import {
  candidateLoginDone,
  adminLoginDone,
  employeeLoginDone,
  loginError,
  loginErrorReset,
} from 'shared/store/app/creators';
import { LOGIN_STARTED } from 'shared/store/app/actions';

const sagaLoginStarted = function* sagaLoginStarted() {
  while (true) {
    const { payload: credentials } = yield take(LOGIN_STARTED);

    yield put(loginErrorReset());

    const args = {
      data: {
        ...credentials,
      },
      config: {
        params: {
          includeSet: 'login',
          normalizeIt: true,
        },
      },
    };

    try {
      const {
        data: { entities = {}, entities: { user: { byId = {}, allIds = [] } = {} } = {} } = {},
        headers,
      } = yield call(postSignIn, args);

      const { [allIds[0]]: { attributes: userAttributes = {} } = {} } = byId || {};

      const { currentProfileType } = userAttributes || {};

      const type = camelCase(currentProfileType);
      const { [type]: { byId: profileById = {}, allIds: profileAllIds = [] } = {} } = entities;

      const profileId = profileAllIds[0];

      const { [profileId]: { attributes: currentProfile = {} } = {} } = profileById || {};

      const fullUser = {
        ...userAttributes,
        currentProfile,
      };

      yield call(setUserContext, fullUser);

      if (type === 'candidate') {
        yield put(candidateLoginDone({ user: fullUser, headers }));
      }
      if (type === 'employee') {
        yield put(employeeLoginDone({ user: fullUser, headers }));
      }
      if (type === 'rainmakersAdmin') {
        yield put(adminLoginDone({ user: fullUser, headers }));
      }
      if (!type) {
        yield put(loginError({ errors: ['Archived'] }));
      }
    } catch (error) {
      handleError(error);

      const {
        response: {
          data: { errors: { full_messages: errors = ['Please try again'] } = {} } = {},
        } = {},
      } = error || {};

      yield put(loginError({ errors }));
    }
  }
};

export default sagaLoginStarted;
