import React, { useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { selectUser } from 'connectors/User/redux/selectors';

import { useOnClickOutside } from 'hooks';

import User from 'connectors/User';
import Navigation from 'components/Navigation';

import { employerHomePageValues } from 'utils/constants';

import HeaderLogo from '../HeaderLogo';
import FontIcon from '../FontIcon';

import styles from './EmployeeHeader.scss';

const EMPLOYER_LINKS = [
  {
    to: '/featured',
    title: 'Find Talent',
    activePaths: ['matches', 'featured', 'saved'],
  },
  {
    to: '/manage/dashboard',
    title: 'Manage Candidates',
    activePaths: ['manage'],
  },
  {
    to: '/employee/edit-personal',
    title: 'Settings',
    activePaths: ['employee'],
  },
];

const EmployeeHeader = ({ currentRequisitionId = 'all', isApproved, location }) => {
  const [showNav, setShowNav] = useState(false);
  const ref = useRef();

  const user = useSelector(selectUser);
  const { currentProfile: { employer: { homepage } = {} } = {} } = user || {};

  useOnClickOutside(
    ref,
    useCallback(() => setShowNav(false), [])
  );

  const makeLinks = (link) => {
    const { title, to, button = false, activePaths, width, target, hide } = link;

    if (!isApproved || hide) {
      return null;
    }

    const isActive = activePaths && activePaths.includes(location.pathname.split('/')[1]);

    const linkClasses = classnames(styles.navLink, {
      [styles.activeNavLink]: isActive,
      [styles.button]: button,
    });

    const linkProps = {
      target,
      href: to,
      to: to.replace(':id', currentRequisitionId),
      key: title,
      className: linkClasses,
      style: { width },
      ['data-title']: title,
    };

    return target ? <a {...linkProps}>{title}</a> : <Link {...linkProps}>{title}</Link>;
  };

  const findTalentRoute =
    employerHomePageValues[homepage]?.homepage ||
    process.env.APPROVED_EMPLOYER_DEFAULT_LINK ||
    '/featured';

  EMPLOYER_LINKS[0].to = findTalentRoute;

  const linksToUse = EMPLOYER_LINKS.filter((link) => !link.hide);

  const links = linksToUse.map(makeLinks);

  const nav = <Navigation className={styles.NavBlock}>{links}</Navigation>;

  const navDropdown = showNav ? (
    <div className={styles.NavDropdown} onClick={() => setShowNav(false)}>
      {links}
    </div>
  ) : null;

  const navHamburger = (
    <div
      onClick={() => setShowNav((showNav) => !showNav)}
      className={classnames(styles.NavHamburger, {
        [styles.seafoam]: showNav,
      })}
    >
      <FontIcon iconName="menu" />
    </div>
  );

  const logoLink = isApproved ? findTalentRoute : '/employer-signup-status';

  return (
    <header
      className={classnames(styles.HeaderContainer, {
        [styles.headerBoxShadow]: true,
      })}
    >
      <div className={styles.Header}>
        <div className={styles.LogoBlock}>
          <Link to={logoLink}>
            <HeaderLogo />
          </Link>
        </div>

        <div className={styles.navLinksContainer}>
          {navHamburger}
          {navDropdown}
          {nav}
          <div onClick={() => setShowNav(false)}>
            <User />
          </div>
        </div>
      </div>
    </header>
  );
};

export default EmployeeHeader;
