import { handleActions } from 'redux-actions';

import {
  REQUISITION_DETAILS_LOAD_DONE,
  RESET_CANDIDATE,
  SET_NEED_RELOAD,
  RESET_NEED_RELOAD,
  MESSAGE_ERROR_SET,
} from '../actions';

const initialState = {
  candidateProfileReqs: [],
  needReload: false,
  messageGroup: {},
};

const handleSetNeedReload = (state = initialState) => ({
  ...state,
  needReload: true,
});

const handleResetNeedReload = (state = initialState) => ({
  ...state,
  needReload: false,
});

const handleRequisitionDetailsLoadDone = (state = initialState, action) => {
  const {
    payload: { requisitionDetails: candidateProfileReqs, page },
  } = action;

  if (page === 1) {
    return {
      ...state,
      candidateProfileReqs,
    };
  }

  return {
    ...state,
    candidateProfileReqs: [...state.candidateProfileReqs, ...candidateProfileReqs],
  };
};

const handleMessageErrorSet = () => (state = initialState, action) => {
  const {
    payload: { errorMessage },
  } = action;

  return {
    ...state,
    errorMessage,
  };
};

const handleResetCandidate = () => initialState;

const actionHandlers = {
  [MESSAGE_ERROR_SET]: handleMessageErrorSet,
  [REQUISITION_DETAILS_LOAD_DONE]: handleRequisitionDetailsLoadDone,
  [RESET_CANDIDATE]: handleResetCandidate,
  [SET_NEED_RELOAD]: handleSetNeedReload,
  [RESET_NEED_RELOAD]: handleResetNeedReload,
};

const reduceCandidate = handleActions(actionHandlers, initialState);

export default reduceCandidate;
