import { CancelToken } from 'axios';
import { push } from 'connected-react-router';

import { delay } from 'redux-saga';
import { call, cancelled, fork, put, take } from 'redux-saga/effects';

import { handleError } from 'utils/common';
import { getEntity } from 'api/apiEntity';

import { ENTITY_LOAD_STARTED } from '../../actions';

import { entityLoadDone } from '../../creators';

const loadEntity = function* loadEntity(action) {
  const {
    payload: {
      id,
      newRoute,
      params,
      slice,
      type,
      typeOverride,
      updateOnly,
      irregularType,
      successCallback,
    },
  } = action;

  const source = CancelToken.source();

  try {
    const args = {
      id,
      type,
      typeOverride,
      config: {
        cancelToken: source.token,
        params: {
          sortBy: 'created_at_desc',
          normalizeIt: true,
          ...params,
        },
      },
    };

    yield call(delay, 250);

    if (newRoute) {
      yield put(push(newRoute));
    }

    const { data } = yield call(getEntity, args);

    if (successCallback) {
      yield call(successCallback, { data });
    }

    yield put(
      entityLoadDone({
        irregularType,
        slice,
        type,
        typeOverride,
        data,
        updateOnly,
      })
    );
  } catch (error) {
    handleError(error);

    yield put(
      entityLoadDone({
        irregularType,
        slice,
        type,
        typeOverride,
        data: {},
      })
    );
  } finally {
    if (yield cancelled()) {
      yield call(source.cancel);
    }
  }
};

const sagaGetEntity = function* sagaGetEntity() {
  const pendingTasks = {};

  const handleCancel = (taskId, slice, type) => {
    const {
      task,
      payload: { slice: taskSlice, type: taskType },
    } = pendingTasks[taskId];

    if (slice === taskSlice && type === taskType) {
      task.cancel();

      delete pendingTasks[taskId];
    }

    if (!task.isRunning()) {
      delete pendingTasks[taskId];
    }

    return task;
  };

  while (true) {
    const action = yield take(ENTITY_LOAD_STARTED);

    const { payload, payload: { slice, type, params = {}, params: { page } = {} } = {} } = action;

    const sameTaskIsRunning = Object.keys(pendingTasks).find((id) => {
      const {
        [id]: {
          task = {},
          payload: { params: taskParams = {}, slice: taskSlice, type: taskType } = {},
        } = {},
      } = pendingTasks;

      const sameParams =
        taskParams &&
        params &&
        Object.keys(taskParams).length === Object.keys(params).length &&
        Object.keys(taskParams).every((paramKey) => params[paramKey] === taskParams[paramKey]);

      const sameType = taskType === type;

      const sameSlice = taskSlice === slice;

      return task.isRunning && task.isRunning() && sameParams && sameType && sameSlice;
    });

    if (!sameTaskIsRunning) {
      const newTask = yield fork(loadEntity, action);

      if (page === 1) {
        yield Object.keys(pendingTasks).map((taskId) => handleCancel(taskId, slice, type));
      }

      pendingTasks[newTask.id] = {
        task: newTask,
        payload,
      };
    }
  }
};

export default sagaGetEntity;
