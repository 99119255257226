import React, { Component } from 'react';

import { VisibleHOC } from 'containers/HOC';

import Dropdown from 'components/Dropdown';

import Bold from 'components/Bold';
import Bolder from 'components/Bolder';
import Button from 'components/Button';
import Content from 'components/Content';
import DialogueModal from 'components/DialogueModal';
import Oblique from 'components/Oblique';

import styles from './ToolboxRequisitionsActionDropdown.scss';

const ACTIONS_OPEN = [
  { label: 'Duplicate', value: 'duplicate' },
  { label: 'Close Req', value: 'close' },
];
const ACTIONS_CLOSED = [
  { label: 'Publish', value: 'publish' },
  { label: 'Duplicate', value: 'duplicate' },
];
const ACTIONS_PENDING = [
  { label: 'Duplicate', value: 'duplicate' },
  { label: 'Close Req', value: 'delete' },
];

const ACTIONS = {
  open: ACTIONS_OPEN,
  closed: ACTIONS_CLOSED,
  pending: ACTIONS_PENDING,
};

@VisibleHOC()
class ToolboxRequisitionsActionDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = { promptType: '' };
  }

  handleClick = (value) => {
    switch (value) {
      case 'duplicate':
        return this.duplicateRequisition();
      case 'close':
        return this.setState({ promptType: 'closeReq' });
      case 'publish':
        return this.updateReq({
          attributes: { state: 'open' },
        });
      case 'delete':
        return this.updateReq({
          attributes: { state: 'closed' },
        });
      default:
        return console.log('action not implemented');
    }
  };

  duplicateRequisition = () => {
    const { duplicateRequisition, requisitionOpening = {} } = this.props;

    duplicateRequisition({
      requisitionOpening,
      slice: 'toolboxRequisitions',
      modal: {
        key: 'RequisitionEditorModal',
        parent: 'ToolboxRequisitionsPage',
        route: '/toolbox/requisition/:id/requisition-opening',
      },
    });
  };

  updateReq = ({ attributes }) => {
    const { patchResource, requisitionOpening: { id } = {} } = this.props;

    patchResource({
      id,
      attributes,
      ignoreValidations: true,
      requiredFields: ['externalName'],
      slice: 'toolboxRequisitions',
      type: 'requisition_openings',
    });
  };

  render() {
    const {
      className,
      isVisible,
      isModal,
      requisitionOpening: { attributes: { state, externalName } = {} } = {},
    } = this.props;

    const { promptType } = this.state;

    const dropDownProps = {
      above: isModal ? false : !isVisible,
      className,
      source: ACTIONS[state],
      onChange: this.handleClick,
      value: 'edit',
      defaultValue: 'Actions',
      left: true,
      actions: true,
      defaultIcon: 'caret-down',
      activeIcon: 'caret-up',
      closedReq: state === 'closed',
    };

    const confirmButtonProps = {
      onClick: () => {
        this.updateReq({
          attributes: { state: 'closed' },
        });
        this.setState({ promptType: '' });
      },
      primary: true,
      className: styles.confirm,
    };

    const confirmButton = <Button {...confirmButtonProps}>Confirm</Button>;

    const closeButtonProps = {
      quaternary: true,
      onClick: () => this.setState({ promptType: '' }),
    };

    const closeButton = <Button {...closeButtonProps}>Cancel</Button>;

    const promptContent = promptType ? (
      <DialogueModal>
        <div className={styles.promptModal}>
          <Bolder className={styles.promptTitle}>
            Are you sure you want to close this requisition?
          </Bolder>
          <Bold className={styles.promptSubtitle}>{externalName}</Bold>
          <Content className={styles.promptContent}>
            All active <Bold>Candidates</Bold> assigned to this requisition will be marked as{' '}
            <Oblique>passed</Oblique>.
          </Content>

          <div className={styles.promptButtons}>
            {closeButton}
            {confirmButton}
          </div>
        </div>
      </DialogueModal>
    ) : null;

    return (
      <div className={styles.ActionDropdown}>
        <Dropdown {...dropDownProps} />
        {promptContent}
      </div>
    );
  }
}

export default ToolboxRequisitionsActionDropdown;
