export const employerInputChange = (event, employer) => {
  const {
    target: { name, type, value, checked },
  } = event;

  const newValue = type === 'checkbox' ? checked : value;

  const splitName = name.split(',');
  const newName = splitName[0];

  if (splitName.length === 3) {
    if (splitName[0] === 'social_media' || splitName[0] === 'socialMedia') {
      if (splitName[1] === 'linkedin') {
        const start = newValue.search(/\/in\//);
        if (start >= 0) {
          const linkedinUsernameIdxStart = start + 4;
          const linkedinUsernameIdxEnd = newValue.length;
          let linkedinUsername = newValue.substring(
            linkedinUsernameIdxStart,
            linkedinUsernameIdxEnd
          );
          if (linkedinUsername[linkedinUsername.length - 1] === '/') {
            linkedinUsername = linkedinUsername.substring(0, linkedinUsername.length - 1);
          }

          const composedValue = {
            ...employer[splitName[0]],
            [splitName[1]]: {
              [splitName[2]]: newValue,
              username: linkedinUsername,
            },
          };

          return {
            name: newName,
            newValue: composedValue,
          };
        }

        // No username found in linkedin url or bad format
        const composedValue = {
          ...employer[splitName[0]],
          [splitName[1]]: {
            [splitName[2]]: newValue,
          },
        };

        return {
          name: newName,
          newValue: composedValue,
        };
      }

      if (splitName[1] === 'twitter') {
        const twitterUsernameIdxStart = newValue.search(/\.com\//) + 5;
        const twitterUsernameIdxEnd = newValue.length;
        let twitterUsername = newValue.substring(twitterUsernameIdxStart, twitterUsernameIdxEnd);
        if (twitterUsername[twitterUsername.length - 1] === '/') {
          twitterUsername = twitterUsername.substring(0, twitterUsername.length - 1);
        }

        const composedValue = {
          ...employer[splitName[0]],
          [splitName[1]]: {
            [splitName[2]]: newValue,
            username: twitterUsername,
          },
        };

        return {
          name: newName,
          newValue: composedValue,
        };
      }

      if (splitName[1] === 'facebook') {
        const facebookUsernameIdxStart = newValue.search(/\.com\//) + 5;
        const facebookUsernameIdxEnd = newValue.length;
        let facebookUsername = newValue.substring(facebookUsernameIdxStart, facebookUsernameIdxEnd);
        if (facebookUsername[facebookUsername.length - 1] === '/') {
          facebookUsername = facebookUsername.substring(0, facebookUsername.length - 1);
        }

        const composedValue = {
          ...employer[splitName[0]],
          [splitName[1]]: {
            [splitName[2]]: newValue,
            username: facebookUsername,
          },
        };

        return {
          name: newName,
          newValue: composedValue,
        };
      }
    }
  }

  if (newName === 'companyName') {
    return { newValue, name: 'name' };
  }

  return { newValue, name: newName };
};
