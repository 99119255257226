import React from 'react';
import { Helmet } from 'react-helmet-async';
import isEmpty from 'lodash.isempty';

import ReadyContainer from 'connectors/ReadyContainer';

import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import Button from 'components/Button';
import TableOverlay from 'components/TableOverlay';

import styles from './CandidateEmpty.scss';

const CandidateEmpty = ({ modal }) => {
  const content = (
    <div className={styles.viewCandidate}>
      <div className={styles.scrollDiv}>
        <div className={styles.resumeDetails}>
          <div className={styles.profileHeaderDetails}>
            <div className={styles.profileImagePlaceholder} />
            <div className={styles.profileNameContainer}>
              <div className={styles.profileName} />
              <div className={styles.profileNameSubtitle} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.profileHeaderContainer}>
        <div className={styles.profileLinks}>
          <Button className={styles.emptyButton} disabled />
          <Button className={styles.emptyButton} disabled />
        </div>
      </div>
      <TableOverlay table="savedSearches" />
    </div>
  );

  const layoutWithoutSidebarProps = {
    content,
  };

  return (
    <div className={isEmpty(modal) ? styles.CandidateResume : styles.CandidateResumeModal}>
      <Helmet title="Candidate" />
      <ReadyContainer
        className={isEmpty(modal) ? styles.CandidateResume : styles.CandidateResumeModal}
      >
        <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />
      </ReadyContainer>
    </div>
  );
};

export default CandidateEmpty;
