import React from 'react';

import { Link } from 'react-router-dom';

import Button from 'components/Button';

import styles from './ActionBlock.scss';

const ActionBlock = ({ handleSubmitForm, signupValid }) => {
  const createAccountButtonProps = {
    className: styles.publishButton,
    primary: true,
    onClick: signupValid ? handleSubmitForm : null,
    disabled: !signupValid,
  };

  return (
    <div className={styles.ActionBlock}>
      <div className={styles.CreateAccount}>
        <Button {...createAccountButtonProps}>Apply</Button>
      </div>
      <div className={styles.SignIn}>
        <span className={styles.signInText}>Already have an account?</span>
        <Link className={styles.signInLink} to="/login">
          Sign In
        </Link>
      </div>
    </div>
  );
};

export default ActionBlock;
