import { createSelector } from 'reselect';
import { entitySelectorFactory } from 'reduxStore/selectorFactories';
import defaultErrors from 'reduxStore/errors';

const selectRequisitionOpeningErrors = createSelector(
  entitySelectorFactory('employerRequisitions', 'requisitionOpening'),
  (state, props) => {
    const {
      match: { params: { id } = {} },
    } = props || {};

    return id;
  },
  (requisitionOpenings, id) => {
    const { byId: { [id]: { errors } = {} } = {} } = requisitionOpenings;

    return errors || defaultErrors.requisitionOpenings;
  }
);

export default selectRequisitionOpeningErrors;
