import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { push } from 'connected-react-router';
import dayjs from 'dayjs';

import { handleError } from 'utils/common';
import { trackEvent } from 'utils/analytics';
import { segmentSkipResumeCandidate } from 'segment/eventNames';

import ConnectContainer from 'containers/ConnectContainer';
import { CandidateAuthHOC } from 'containers/HOC';

import ReadyContainer from 'connectors/ReadyContainer';

import { promisePickerOptions } from 'connectors/Defaults/promises';

import {
  promisePostCompanies,
  promisePostEducationOrgs,
} from 'pages/AdminPortal/ToolboxCandidateEditorPage/promises';

import { toggleArray } from 'utils/formUtils';

import Button from 'components/Button';
import CandidateImageUploader from 'components/CandidateImageUploader';
import Content from 'components/Content';
import FontIcon from 'components/FontIcon';
import Title from 'components/Title';

import WorkInputs from '../../CandidateEditorPages/EditWork/components/WorkInputs';
import WorkDisplay from '../../CandidateEditorPages/EditWork/components/WorkDisplay';
import EducationDisplay from '../../CandidateEditorPages/EditEducation/components/EducationDisplay';
import EducationInputs from '../../CandidateEditorPages/EditEducation/components/EducationInputs';

import duck from '../redux';

import styles from '../Onboarding.scss';

import ProgressNavSection from '../components/ProgressNavSection';

import InitialContent from './components/InitialContent';
import MainContent from './components/MainContent';

@CandidateAuthHOC()
class CreateProfile extends Component {
  constructor(props) {
    super(props);

    const {
      user: {
        currentProfile: { workHistories = [], candidateEducations = [], resume, recentGrad } = {},
      } = {},
    } = props;

    const { role: recentRole = null } = workHistories[0] || {};

    const reverseOrder = recentGrad && (!recentRole || recentRole === 'Non-sales');

    const showMainContent = resume !== '/resumes/original/missing.png' || workHistories.length > 0;

    this.state = {
      workHistories,
      candidateEducations,
      origWorkHistories: workHistories,
      origCandidateEducations: candidateEducations,
      showMainContent,
      resumeTypeError: null,
      reorderAttempts: 0,
      pickerOptions: {},
      reverseOrder,
    };
  }

  componentDidMount() {
    const {
      actions: { serverErrorSet },
    } = this.props;

    serverErrorSet({ errors: [] });
    promisePickerOptions()
      .then((pickerOptions) =>
        this.setState({
          pickerOptions,
        })
      )
      .catch(handleError);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { user: { currentProfile: { workHistories = [], candidateEducations = [] } = {} } = {} } =
      nextProps;

    this.setState({
      workHistories,
      candidateEducations,
      origWorkHistories: workHistories,
      origCandidateEducations: candidateEducations,
    });

    const firstMovedEduIdx = candidateEducations.findIndex((edu) => edu.moved);
    if (firstMovedEduIdx >= 0) {
      if (this[`candidateEducation${firstMovedEduIdx}`]) {
        this[`candidateEducation${firstMovedEduIdx}`].scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
      }
      setTimeout(() => {
        const { candidateEducations } = this.state;
        const newCandidateEducations = candidateEducations.map((edu) => ({
          ...edu,
          moved: false,
        }));

        this.setState({
          candidateEducations: newCandidateEducations,
          origCandidateEducations: newCandidateEducations,
        });
      }, 500);
    }

    const firstMovedWHIdx = workHistories.findIndex((wh) => wh.moved);
    if (firstMovedWHIdx >= 0) {
      if (this[`workHistory${firstMovedWHIdx}`]) {
        this[`workHistory${firstMovedWHIdx}`].scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
      }
      setTimeout(() => {
        const { workHistories } = this.state;
        const newWorkHistories = workHistories.map((wh) => ({
          ...wh,
          moved: false,
        }));

        this.setState({
          workHistories: newWorkHistories,
          origWorkHistories: newWorkHistories,
        });
      }, 500);
    }
  }

  componentWillUnmount() {
    this.setState({
      workHistories: [],
      candidateEducations: [],
      origWorkHistories: [],
      origCandidateEducations: [],
    });
  }

  setWHRef = (ref, idx) => {
    this[`workHistory${idx}`] = ref;
  };

  setEduRef = (ref, idx) => {
    this[`candidateEducation${idx}`] = ref;
  };

  trackSkipResume = () => {
    const event = segmentSkipResumeCandidate;

    const properties = {};

    const eventObject = { event, properties };

    trackEvent(eventObject);
  };

  handleAddWorkHistory = () => {
    const {
      user: { currentProfile: { candidateRequirement: { roles = [] } = {} } = {} } = {},
      recentRole,
    } = this.props;

    const desiredRole = roles && roles[0] ? roles[0] : 'Account Executive';

    const { workHistories } = this.state;

    const newWorkHistories = workHistories.slice();

    const newWorkHistory = {
      startMonth: '',
      startYear: '',
      endMonth: '',
      endYear: '',
      startDate: '',
      endDate: '',
      quotasHit: '',
      stackRank: '',
      soldToCompanySizes: [],
      soldToCompanies: [],
      soldToDepartments: [],
      soldToIndustries: [],
      territories: [],
      productTypes: [],
      awards: [],
      inboundOutbound: '',
      saleCycleTime: '',
      salesPeriods: [],
      description: '',
      company: {
        id: '',
        picture: {
          original: '',
          thumb: '',
          small: '',
          medium: '',
          large: '',
          xlarge: '',
          xxlarge: '',
        },
      },
      role: null,
      position: '',
      isEditing: true,
    };

    if (workHistories.length === 0) {
      newWorkHistory.role = recentRole || desiredRole;
    }

    newWorkHistories.push(newWorkHistory);

    this.setState({
      workHistories: newWorkHistories,
    });
  };

  handleAddEducation = () => {
    const newEducation = {
      startedAt: '',
      finishedAt: '',
      degree: '',
      description: '',
      extracurricularActivities: [],
      educationOrganization: {
        id: '',
        picture: {
          original: '',
          thumb: '',
          small: '',
          medium: '',
          large: '',
          xlarge: '',
          xxlarge: '',
        },
      },
      isEditing: true,
    };

    const { candidateEducations } = this.state;

    const newEducations = candidateEducations.slice();

    newEducations.push(newEducation);

    this.setState({
      candidateEducations: newEducations,
    });
  };

  handleCancelEditingWork = (idx) => {
    const { workHistories, origWorkHistories } = this.state;

    const origWorkHistory = origWorkHistories[idx];

    const newWorkHistories = workHistories.slice();

    if (origWorkHistory && origWorkHistory.id) {
      newWorkHistories.splice(idx, 1, { ...origWorkHistory, isEditing: false });
      this.setState({
        workHistories: newWorkHistories,
      });
    } else {
      this.handleWorkHistoryDelete({ idx });
    }
  };

  handleCancelEditingEdu = (idx) => {
    const { candidateEducations, origCandidateEducations } = this.state;

    const origEducation = origCandidateEducations[idx];

    const newCandidateEducations = candidateEducations.slice();

    if (origEducation && origEducation.id) {
      origEducation.isEditing = false;
      newCandidateEducations.splice(idx, 1, origEducation);
      this.setState({
        candidateEducations: newCandidateEducations,
      });
    } else {
      this.handleEducationDelete({ idx });
    }
  };

  handleReorderWorkHistories = ({ idx }) => {
    const {
      actions: { reorderWorkStarted },
    } = this.props;

    const {
      isPostingCompany,
      reorderAttempts,
      workHistories: origWorkHistories,
      candidateEducations: origCandidateEducations,
    } = this.state;

    if (isPostingCompany && reorderAttempts < 4) {
      setTimeout(() => {
        this.handleReorderWorkHistories({ idx });
        this.setState({ reorderAttempts: reorderAttempts + 1 });
      }, 500);
    } else {
      reorderWorkStarted({
        origWorkHistories,
        origCandidateEducations,
        idx,
      });
      this.setState({ reorderAttempts: 0 });
    }
  };

  handleReorderCandidateEducations = ({ idx }) => {
    const {
      actions: { reorderEducationStarted },
    } = this.props;

    const {
      isPostingEducation,
      reorderAttempts,
      candidateEducations: origCandidateEducations,
      workHistories: origWorkHistories,
    } = this.state;

    if (isPostingEducation && reorderAttempts < 4) {
      setTimeout(() => {
        this.handleReorderCandidateEducations({ idx });
        this.setState({ reorderAttempts: reorderAttempts + 1 });
      }, 500);
    } else {
      reorderEducationStarted({
        origCandidateEducations,
        origWorkHistories,
        idx,
      });
      this.setState({ reorderAttempts: 0 });
    }
  };

  handleToggleEditingWork = (idx) => {
    const { workHistories } = this.state;

    if (workHistories[idx].isEditing) {
      setTimeout(() => this.handleReorderWorkHistories({ idx }), 250);
    } else {
      const newWorkHistories = workHistories.map((wh, index) => {
        if (index === idx) {
          return {
            ...wh,
            isEditing: true,
          };
        }
        return wh;
      });

      const origWorkHistory = workHistories[idx];
      const newOrigWorkHistories = workHistories.slice();
      newOrigWorkHistories.splice(idx, 1, origWorkHistory);

      this.setState({
        workHistories: newWorkHistories,
        origWorkHistories: newOrigWorkHistories,
      });
    }
  };

  handleToggleEditingEdu = (idx) => {
    const { candidateEducations } = this.state;

    if (candidateEducations[idx].isEditing) {
      setTimeout(() => this.handleReorderCandidateEducations({ idx }), 250);
    } else {
      const newCandidateEducations = candidateEducations.map((edu, index) => {
        if (index === idx) {
          return {
            ...edu,
            isEditing: true,
          };
        }
        return edu;
      });

      const origCandidateEducation = candidateEducations[idx];
      const newOrigCandidateEducations = candidateEducations.slice();
      newOrigCandidateEducations.splice(idx, 1, origCandidateEducation);

      this.setState({
        candidateEducations: newCandidateEducations,
        origCandidateEducations: newOrigCandidateEducations,
      });
    }
  };

  makeWorkHistoryInputs = (workHistory, idx) => {
    const { pickerOptions = {} } = this.state;
    const { dispatch } = this.props;

    const { isEditing } = workHistory;

    const workInputsProps = {
      dispatch,
      workInputsType: 'limited',
      workHistory,
      idx,
      hasRoleChangeModal: false,
      handleWorkHistoryCompanyChange: this.handleWorkHistoryCompanyChange,
      handleWorkHistoryCompanyBlur: this.handleWorkHistoryCompanyBlur,
      handleWorkHistoryPositionChange: this.handleWorkHistoryPositionChange,
      handleCurrentPositionInputChange: this.handleCurrentPositionInputChange,
      handleWorkHistoryDateInputChange: this.handleWorkHistoryDateInputChange,
      handleWorkHistoryInputChange: this.handleWorkHistoryInputChange,
      handleWorkHistoryDelete: this.handleWorkHistoryDelete,
      handleToggleEditingWork: this.handleToggleEditingWork,
      handleCancelEditingWork: this.handleCancelEditingWork,
      pickerOptions,
    };

    const displayWorkProps = {
      setWHRef: (ref) => this.setWHRef(ref, idx),
      workHistory,
      idx,
      handleToggleEditingWork: this.handleToggleEditingWork,
      workDisplayType: 'limited',
    };

    return isEditing ? (
      <WorkInputs key={workHistory.id || idx} {...workInputsProps} />
    ) : (
      <WorkDisplay key={workHistory.id || idx} {...displayWorkProps} />
    );
  };

  makeEducationInputs = (education, idx) => {
    const { isEditing } = education;
    const { user: { currentProfile: { recentGrad = false } = {} } = {} } = this.props;

    const educationInputsProps = {
      education,
      idx,
      handleEducationToggleArray: this.handleEducationToggleArray,
      handleEducationOrganizationChange: this.handleEducationOrganizationChange,
      handleEducationOrganizationBlur: this.handleEducationOrganizationBlur,
      handleEducationDateInputChange: this.handleEducationDateInputChange,
      handleEducationInputChange: this.handleEducationInputChange,
      handleCurrentEducationInputChange: this.handleCurrentEducationInputChange,
      handleEducationDelete: this.handleEducationDelete,
      handleToggleEditingEdu: this.handleToggleEditingEdu,
      handleCancelEditingEdu: this.handleCancelEditingEdu,
      recentGrad,
    };

    const displayEduProps = {
      setEduRef: (ref) => this.setEduRef(ref, idx),
      education,
      idx,
      handleToggleEditingEdu: this.handleToggleEditingEdu,
    };

    return isEditing ? (
      <EducationInputs key={idx} {...educationInputsProps} />
    ) : (
      <EducationDisplay key={idx} {...displayEduProps} />
    );
  };

  handleWorkHistoryCompanyChange = ({ event, idx: workHistoryIdx }) => {
    const {
      target: { value: newValue },
    } = event;

    const { workHistories } = this.state;

    const newWorkHistories = workHistories.slice();

    newWorkHistories[workHistoryIdx].company = {
      ...newWorkHistories[workHistoryIdx].company,
      name: newValue.name,
      id: newValue.id,
    };

    newWorkHistories[workHistoryIdx].companyId = newValue.id;

    this.setState({ workHistories: newWorkHistories });
  };

  handleEducationOrganizationBlur = ({ event, idx: educationIdx }) => {
    const {
      target: { value: newValue },
    } = event;

    const { candidateEducations: educations } = this.state;

    const { educationOrganization: origOrg = {} } = educations[educationIdx];

    if (origOrg.name !== newValue) {
      const onFulfilled = ({ educationOrg }) => {
        const newEducations = educations.slice();

        newEducations[educationIdx].educationOrganization = {
          ...newEducations[educationIdx].educationOrganization,
          name: educationOrg.name,
          id: educationOrg.id,
        };

        newEducations[educationIdx].educationOrganizationId = educationOrg.id;

        this.setState({ candidateEducations: newEducations });
      };

      const data = {
        type: 'education_organization',
        attributes: {
          name: newValue.trim(),
        },
      };

      promisePostEducationOrgs({ data }).then(onFulfilled).catch(handleError);
    }
  };

  handleWorkHistoryCompanyBlur = ({ event, idx: workHistoryIdx }) => {
    const {
      target: { value: newValue },
    } = event;

    const { workHistories } = this.state;

    const { company: origCompany = {} } = workHistories[workHistoryIdx];

    if (origCompany.name !== newValue) {
      this.setState({ isPostingCompany: true });

      const onFulfilled = ({ company }) => {
        const newWorkHistories = workHistories.slice();

        newWorkHistories[workHistoryIdx].company = {
          ...newWorkHistories[workHistoryIdx].company,
          name: company.name,
          id: company.id,
        };

        newWorkHistories[workHistoryIdx].companyId = company.id;

        this.setState({ workHistories: newWorkHistories, isPostingCompany: false });
      };

      const onFail = (error) => {
        handleError(error);
        this.setState({ isPostingCompany: false });
      };

      const data = {
        type: 'company',
        attributes: {
          name: newValue.trim(),
        },
      };

      promisePostCompanies({ data }).then(onFulfilled).catch(onFail);
    }
  };

  handleCurrentPositionInputChange = ({ event, idx: workHistoryIdx }) => {
    const {
      target: { checked },
    } = event;

    const { workHistories } = this.state;

    const newWorkHistories = workHistories.map((wh, idx) => {
      if (idx === workHistoryIdx) {
        return {
          ...wh,
          endDate: checked ? null : new Date().toISOString().substring(0, 10),
        };
      }
      return wh;
    });

    this.setState({ workHistories: newWorkHistories });
  };

  handleWorkHistoryDateInputChange = ({ name, value: newValue, idx: workHistoryIdx }) => {
    const { workHistories } = this.state;

    const newWorkHistories = workHistories.map((wh, idx) => {
      if (idx === workHistoryIdx) {
        return {
          ...wh,
          [name]: newValue,
        };
      }
      return wh;
    });

    this.setState({ workHistories: newWorkHistories });
  };

  handleWorkHistoryInputChange = ({ event, idx: workHistoryIdx }) => {
    const {
      target: { name, value: newValue },
    } = event;

    const { workHistories } = this.state;

    const newWorkHistories = workHistories.map((wh, idx) => {
      if (idx === workHistoryIdx) {
        return {
          ...wh,
          [name]: newValue,
        };
      }
      return wh;
    });

    this.setState({ workHistories: newWorkHistories });
  };

  handleWorkHistoryDelete = ({ idx }) => {
    const {
      actions: { workHistoryDeleteStarted },
    } = this.props;

    const { workHistories } = this.state;

    const newWorkHistories = workHistories.slice();
    newWorkHistories.splice(idx, 1);

    this.setState({ workHistories: newWorkHistories });
    if (workHistories[idx].id) {
      workHistoryDeleteStarted({ workHistoryId: workHistories[idx].id });
    }
  };

  handleEducationToggleArray = ({ event, idx: educationIdx }) => {
    const {
      target: { value, name },
    } = event;

    const { candidateEducations: educations } = this.state;

    const array = educations[educationIdx][name].slice();

    const newArray = toggleArray({ array, value });

    const newEducations = educations.slice();

    newEducations[educationIdx][name] = newArray;

    this.setState({ candidateEducations: newEducations });
  };

  handleEducationOrganizationChange = ({ event, idx: educationIdx }) => {
    const {
      target: { value: newValue },
    } = event;

    const { candidateEducations: educations } = this.state;

    const newEducations = educations.slice();

    newEducations[educationIdx].educationOrganization = {
      ...newEducations[educationIdx].educationOrganization,
      name: newValue.name,
      id: newValue.id,
    };

    newEducations[educationIdx].educationOrganizationId = newValue.id;

    this.setState({ candidateEducations: newEducations });
  };

  handleEducationDateInputChange = ({ event, idx: educationIdx }) => {
    const {
      target: { name, value: newValue },
    } = event;

    const { candidateEducations: educations } = this.state;

    const { startedAt: startDate, finishedAt: endDate } = educations[educationIdx];

    const newEducation = {
      startedAt: startDate,
      finishedAt: endDate,
    };

    const startDateObject = startDate ? dayjs(startDate, 'YYYY-MM-DD').toDate() : new Date();
    const endDateObject = endDate ? dayjs(endDate, 'YYYY-MM-DD').toDate() : new Date();

    if (name === 'startDateYear') {
      startDateObject.setFullYear(newValue);
      newEducation.startedAt = startDateObject.toISOString().substring(0, 10);
    }

    if (name === 'endDateYear') {
      endDateObject.setFullYear(newValue);
      newEducation.finishedAt = endDateObject.toISOString().substring(0, 10);
    }

    const newEducations = educations.map((edu, idx) => {
      if (idx === educationIdx) {
        return {
          ...edu,
          ...newEducation,
        };
      }
      return edu;
    });

    this.setState({ candidateEducations: newEducations });
  };

  handleEducationInputChange = ({ event, idx: educationIdx }) => {
    const {
      target: { name, value: newValue },
    } = event;

    const { candidateEducations: educations } = this.state;

    const newEducations = educations.slice();

    newEducations[educationIdx][name] = newValue;

    this.setState({ candidateEducations: newEducations });
  };

  handleCurrentEducationInputChange = ({ event, idx: educationIdx }) => {
    const {
      target: { checked },
    } = event;

    const { candidateEducations: educations } = this.state;

    const newEducations = educations.slice();

    newEducations[educationIdx].finishedAt = checked
      ? null
      : new Date().toISOString().substring(0, 10);

    this.setState({ candidateEducations: newEducations });
  };

  handleEducationDelete = ({ idx }) => {
    const {
      actions: { candidateEducationDeleteStarted },
    } = this.props;

    const { candidateEducations } = this.state;

    const newEducations = candidateEducations.slice();
    newEducations.splice(idx, 1);

    this.setState({ candidateEducations: newEducations });

    if (candidateEducations[idx].id) {
      candidateEducationDeleteStarted({ educationId: candidateEducations[idx].id });
    }
  };

  handleSubmitResume = (event) => {
    event.preventDefault();
    const {
      actions: { uploadResumeStarted, serverErrorSet },
      user: { currentProfile: { recentGrad } = {} } = {},
    } = this.props;

    serverErrorSet({ errors: [] });

    this.setState({ resumeTypeError: null });

    const resume = event.target.files[0];

    if (resume.size > 10000000) {
      return this.setState({
        resumeTypeError: 'File size must be less than 10 MB',
      });
    }

    if (resume.type === 'application/pdf') {
      this.setState({ showMainContent: true });

      return uploadResumeStarted({
        resume,
        redirect: false,
        recentGrad,
      });
    }

    this.setState({ resumeTypeError: 'Resume must be a PDF' });
  };

  handleSkipResume = () => {
    this.setState({ showMainContent: true });

    this.trackSkipResume();
  };

  render() {
    const { workHistories, candidateEducations, showMainContent, resumeTypeError, reverseOrder } =
      this.state;

    const {
      actions: { candidateImageUploadStarted, patchCandidateStarted },
      dispatch,
      parsingResume,
      serverErrors = [],
      user: { currentProfile } = {},
    } = this.props;

    const {
      id: candidateId,
      lastName,
      picture: { xxlarge: pictureUrl = '' } = {},
      recentGrad,
      resume = '/resumes/original/missing.png',
    } = currentProfile || {};

    const serverErrorMessage = serverErrors.length > 0 ? serverErrors[0] : null;

    const serverErrorContent = serverErrorMessage ? (
      <div className={styles.errorMessage}>{serverErrorMessage}</div>
    ) : null;

    const titleProps = {
      string: "Let's create your profile",
    };

    const hasWorkHistory = workHistories.length > 0;
    const { role: recentRole = null } = hasWorkHistory ? workHistories[0] : {};
    const hasWorkHistoryId = hasWorkHistory && workHistories[0].id;

    const hasValidWorkHistory =
      (recentGrad && (!hasWorkHistory || recentRole === 'Non-sales')) ||
      (hasWorkHistory && hasWorkHistoryId && !workHistories.some((wh) => wh.isEditing === true));

    const hasEducation = candidateEducations.length > 0;
    const hasEducationId = hasEducation && candidateEducations[0].id;

    const hasValidEducation =
      hasEducation && hasEducationId && !candidateEducations.some((edu) => edu.isEditing === true);

    const isValid =
      hasEducation || (recentGrad && (!hasWorkHistory || recentRole === 'Non-sales'))
        ? hasValidWorkHistory && hasValidEducation
        : hasValidWorkHistory;

    const nextButtonProps = {
      circle: true,
      active: isValid,
      onClick: patchCandidateStarted,
      className: styles.actionButton,
      disabled: !isValid,
    };

    const prevButtonProps = {
      circle: true,
      onClick: () => dispatch(push('/set-requirements')),
      className: styles.actionButton,
    };

    const initialContentProps = {
      handleSubmitResume: this.handleSubmitResume,
      handleSkipResume: this.handleSkipResume,
      resumeTypeError,
    };

    const mainContentProps = {
      recentGrad,
      reverseOrder,
      resumeTypeError,
      handleSubmitResume: this.handleSubmitResume,
      lastName,
      parsingResume,
      resume,
      workHistories,
      candidateEducations,
      handleAddEducation: this.handleAddEducation,
      handleAddWorkHistory: this.handleAddWorkHistory,
      makeEducationInputs: this.makeEducationInputs,
      makeWorkHistoryInputs: this.makeWorkHistoryInputs,
    };

    const content = showMainContent ? (
      <MainContent {...mainContentProps} />
    ) : (
      <InitialContent {...initialContentProps} />
    );

    const imageUploaderProps = {
      candidateId,
      pictureUrl,
      candidateImageUploadStarted,
    };

    return [
      <Helmet key="helmet" title="Create Profile" />,

      <ReadyContainer
        key="CreateProfile"
        className={styles.CandidateRegistration}
        data-testid="CreateProfile"
      >
        <div className={styles.prevButtonContainer}>
          <Button {...prevButtonProps}>
            <FontIcon iconName="caret-left" />
          </Button>
        </div>

        <div className={styles.mainContainer}>
          <ProgressNavSection numFilled={2} />

          <Title {...titleProps} />

          {serverErrorContent}

          <div className={styles.imageContainer}>
            <Content className={styles.contentContainer}>
              Just the basics: a profile picture and your resume.
            </Content>
          </div>

          <div className={styles.imageContainer}>
            <CandidateImageUploader {...imageUploaderProps} />
          </div>

          {content}
        </div>

        <div className={styles.nextButtonContainer}>
          <Button {...nextButtonProps}>
            <FontIcon iconName="caret-right" />
          </Button>
        </div>
      </ReadyContainer>,
    ];
  }
}

export default ConnectContainer(duck)(CreateProfile);
