import { call, put, select } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import isEmpty from 'lodash.isempty';
import queryString from 'query-string';

import { setUserContext } from 'utils/ravenUtils';

import {
  adminLoginDone,
  candidateLoginDone,
  employeeLoginDone,
  logoutStarted,
  pingSession,
} from 'shared/store/app/creators';

import { selectUser } from 'connectors/User/redux/selectors';

import sagaCheckSessionEnd from './sagaCheckSessionEnd';

const getHeadersFromCookies = () => {
  const accessToken = Cookies.get('accessToken');
  const client = Cookies.get('client');
  const uid = Cookies.get('uid');

  if (accessToken && client && uid) {
    return {
      'Accept': '*/*',
      'access-token': accessToken,
      client,
      uid,
    };
  }

  return {};
};

const sagaAutoLogin = function* sagaAutoLogin(browserHistory) {
  const user = yield select(selectUser);

  yield call(sagaCheckSessionEnd, { useLastActive: true });

  yield put(pingSession());

  const { search, pathname } = browserHistory.location;
  const {
    'access-token': accessToken,
    client,
    uid,
    'x-api-key': xApiKey,
  } = queryString.parse(search);

  const useQueryHeaders = (accessToken && client && uid) || xApiKey;

  if (pathname === '/saml_unauthorized') {
    yield put(logoutStarted({ noRedirect: true }));
  }

  if (!isEmpty(user) && !isEmpty(user.currentProfile)) {
    const cookieHeaders = getHeadersFromCookies();

    const headers = useQueryHeaders
      ? {
          'access-token': accessToken,
          client,
          uid,
          ['x-api-key']: xApiKey,
        }
      : cookieHeaders;

    const autoLoginPath = { pathname, search };

    const { currentProfileType: type } = user;

    yield call(setUserContext, user);

    if (type === 'Candidate') {
      yield put(candidateLoginDone({ user, headers, autoLoginPath }));
    }
    if (type === 'Employee') {
      yield put(employeeLoginDone({ user, headers, autoLoginPath }));
    }
    if (type === 'RainmakersAdmin') {
      yield put(adminLoginDone({ user, headers, autoLoginPath }));
    }
  }

  if (useQueryHeaders && isEmpty(user.currentProfile)) {
    yield put(logoutStarted({ noRedirect: true }));
  }
};

export default sagaAutoLogin;
