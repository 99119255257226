import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';

import { handleError } from 'utils/common';

import ConnectContainer from 'containers/ConnectContainer';
import { EmployeeAuthHOC } from 'containers/HOC';

import ReadyContainer from 'connectors/ReadyContainer';

import Sidebar from 'components/Sidebar';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import Block from 'components/Block';
import Button from 'components/Button';
import Input from 'components/Input';
import Label from 'components/Label';
import TableOverlay from 'components/TableOverlay';
import EmployeeImageUploader from 'components/EmployeeImageUploader';

import { promiseEmailValid } from 'pages/CandidatePortal/CandidateEditorPages/promises';

import duck from '../redux';
import EmailChangeModal from './components/EmailChangeModal';

import styles from './EditPersonal.scss';

@EmployeeAuthHOC()
class EditPersonal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEmailChangeModal: false,
      emailError: null,
    };
  }

  updateEmailStart = ({ password, newEmail }) => {
    const {
      actions: { emailChangeStarted },
    } = this.props;

    this.setState({ emailError: null });

    promiseEmailValid({ email: newEmail })
      .then((response) => {
        if (response.email) {
          this.setState({ emailError: 'Email has been taken' });
        } else {
          emailChangeStarted({ password, newEmail });
          this.setState({ showEmailChangeModal: false });
        }
      })
      .catch(handleError);
  };

  handleEmployeeTextInputChange = (event) => {
    const {
      target: { name, value: newValue },
    } = event;
    const {
      actions: { employeePatchStarted },
    } = this.props;

    employeePatchStarted({ name, newValue });
  };

  handleUserTextInputChange = (event) => {
    const {
      target: { name, value: newValue },
    } = event;
    const {
      actions: { userPatchStarted },
    } = this.props;

    userPatchStarted({ name, newValue });
  };

  render() {
    const {
      isApproved,
      employeeLoading,
      employeeSaving,
      user: { currentProfile: employee } = {},
      employer,
      user,
      actions: { employeeImageUploadStarted },
    } = this.props;

    const { id: employeeId, jobTitle, phoneNumber, firstName, lastName, picture } = employee || {};

    const { email } = user || {};

    const { showEmailChangeModal, emailError } = this.state;

    const savingContent = employeeSaving ? (
      <div className={styles.autoSaving}>Saving...</div>
    ) : (
      <div className={styles.autoSaved}>Profile Saved</div>
    );

    const saveContent = employeeSaving === undefined ? null : savingContent;

    const { xxlarge: pictureUrl = '' } = picture || {};

    const commonBlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      largeTitleFont: true,
      addPadding: true,
    };

    const titleBlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      addPadding: true,
      className: styles.titleBlock,
    };

    const genInfoBlockProps = {
      ...commonBlockProps,
      addFlex: true,
      addChildFlex: true,
    };

    const commonEmployeeInputProps = {
      handleInputChange: this.handleEmployeeTextInputChange,
      type: 'text',
      size: 'xxlarge',
      topLabel: true,
    };

    const firstNameInputProps = {
      ...commonEmployeeInputProps,
      required: true,
      name: 'firstName',
      value: firstName || '',
      label: 'First Name',
      size: 'full',
      placeholder: '',
    };

    const lastNameInputProps = {
      ...commonEmployeeInputProps,
      required: true,
      name: 'lastName',
      value: lastName || '',
      label: 'Last Name',
      size: 'full',
      placeholder: '',
    };

    const phoneInputProps = {
      ...commonEmployeeInputProps,
      name: 'phoneNumber',
      value: phoneNumber || '',
      label: 'Phone #',
      placeholder: 'Area code first',
      type: 'tel',
      size: 'phone',
    };

    const jobTitleInputProps = {
      ...commonEmployeeInputProps,
      name: 'jobTitle',
      value: jobTitle || '',
      label: 'Job Title',
      placeholder: 'Recruiter, e.g.',
      size: 'xxlarge',
    };

    const emailChangeModalProps = {
      updateEmailStart: this.updateEmailStart,
      handleCloseDialogue: () => this.setState({ showEmailChangeModal: false }),
      emailError,
    };

    const emailChangeModalContent = showEmailChangeModal ? (
      <EmailChangeModal {...emailChangeModalProps} />
    ) : null;

    const updateEmailButtonProps = {
      tertiaryThin: true,
      onClick: () => this.setState({ showEmailChangeModal: true }),
    };

    const imageUploaderProps = {
      employeeId,
      pictureUrl,
      employeeImageUploadStarted,
    };

    const content = (
      <div className={styles.EditorPersonal}>
        <Block {...titleBlockProps}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>Personal Info</div>
            <div className={styles.saveContent}>{saveContent}</div>
          </div>
        </Block>
        <div className={styles.scrollDiv}>
          <Block {...genInfoBlockProps}>
            <EmployeeImageUploader {...imageUploaderProps} />
            <div className={styles.flexColumn}>
              <Label standalone className={styles.label}>
                Email Address
              </Label>
              <div className={styles.emailContent}>
                {email}
                <Button {...updateEmailButtonProps}>Update</Button>
              </div>
              <div className={styles.nameInputs}>
                <Input {...firstNameInputProps} />
                <Input {...lastNameInputProps} />
                <Input {...phoneInputProps} />
              </div>
              <Input {...jobTitleInputProps} />
            </div>
          </Block>
          {emailChangeModalContent}
        </div>
      </div>
    );

    const finalContent = employeeLoading ? (
      <div>
        <TableOverlay table="candidate" />
        {content}
      </div>
    ) : (
      content
    );

    const layoutWithoutSidebarProps = {
      content: finalContent,
    };

    const sidebarProps = {
      type: isApproved ? 'employeeStatusApproved' : 'employeeStatusUnapproved',
      page: 'edit-personal',
      employer,
    };

    return (
      <React.Fragment>
        <Helmet title="Settings" />
        <ReadyContainer>
          <Sidebar {...sidebarProps} />
          <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />
        </ReadyContainer>
      </React.Fragment>
    );
  }
}

export default ConnectContainer(duck)(EditPersonal);
