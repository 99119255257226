import React from 'react';

import { ComponentErrorBoundaryHOC } from 'containers/HOC';

import FormattedArray from 'components/FormattedArray';
import FormattedMoney from 'components/FormattedMoney';
import Block from 'components/Block';
import Bolder from 'components/Bolder';
import Content from 'components/Content';
import FontIcon from 'components/FontIcon';

import styles from './ProfileRecruitment.scss';

export const ProfileRecruitment = ({ candidateRequirement, pickerOptions }) => {
  const { candReqLocations = [] } = pickerOptions || {};

  const {
    roles = [],
    locations = [],
    oteCents: ote = '',
    locationNotes = '',
    workModel = [],
  } = candidateRequirement || {};

  const rolesTitle = roles.length > 1 ? 'Desired roles' : 'Desired role';

  const locationNotesStrings = locationNotes?.split('\n');

  const rolesContent =
    roles.length > 0 ? (
      <Content className={styles.requirement}>
        <Bolder className={styles.question}>{rolesTitle}</Bolder>
        <FormattedArray array={roles} cutOff={4} />
      </Content>
    ) : null;

  const locationNotesContent = locationNotes ? (
    <Content className={styles.locNotesHead}>
      Notes:
      <Content className={styles.locNotes}>
        <FormattedArray flat array={locationNotesStrings} />
      </Content>
    </Content>
  ) : null;

  const locationNames = locations.map((loc) => loc[loc.type]);

  const subLocations = {};

  if (locationNames.length > 0) {
    subLocations['Location Names'] = locationNames;
  }

  const icon = <FontIcon className={styles.icon} iconName="checkmark" />;

  const locationContent =
    candReqLocations && candReqLocations.length > 0 && locations && locations.length > 0 ? (
      <Content className={styles.requirement}>
        <Bolder className={styles.question}>{`Desired Work Model${
          workModel.length > 1 ? 's' : ''
        }`}</Bolder>
        <Content className={styles.locAnswer}>
          <FormattedArray array={workModel} icon={icon} />
        </Content>
        {locationNotesContent}
      </Content>
    ) : null;

  const oteContent =
    ote && ote !== '' ? (
      <Content className={styles.requirement}>
        <Bolder className={styles.question}>Desired compensation</Bolder>
        <Content className={styles.answer}>
          <FormattedMoney amount={ote} /> OTE
        </Content>
      </Content>
    ) : null;

  return (
    <Block
      title={
        <Content>
          <Bolder>REQUIREMENTS</Bolder>
        </Content>
      }
      addWhiteBG={true}
      secondaryTitle={true}
      data-testid="ProfileRecruitment"
    >
      <Content className={styles.candReqContainer}>
        <Content className={styles.requirementContainer}>
          {rolesContent}
          {oteContent}
        </Content>
        <Content className={styles.requirementContainer}>{locationContent}</Content>
      </Content>
    </Block>
  );
};

export default ComponentErrorBoundaryHOC()(ProfileRecruitment);
