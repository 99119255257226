import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Hero.scss';

const Hero = () => {
  return (
    <div className={styles.Hero}>
      <div className={styles.Text}>
        <h3 className={styles.row1}>BUILD A SALES DREAM TEAM</h3>

        <h1 className={styles.row2}>
          {"Rainmakers matches your company's needs with top performing salespeople."}
        </h1>

        <Link className={styles.Request} to="/employer-signup/">
          Join Now
        </Link>
      </div>
    </div>
  );
};

export default Hero;
