import React, { useState, useRef, Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { EmployeeAuthHOC } from 'containers/HOC';

import { createRoute, makeLikeParam, queryStringParse } from 'utils/paramUtils';

import ConnectContainer from 'containers/ConnectContainer';
import ReadyContainer from 'connectors/ReadyContainer';

import { toggleArray } from 'utils/formUtils';

import Bold from 'components/Bold';
import Button from 'components/Button';
import DialogueModal from 'components/DialogueModal';
import Sidebar from 'components/Sidebar';
import TalentConcierge from 'components/TalentConcierge';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';

import CandidatesTable from './components/CandidatesTable';

import duck from './redux';

import styles from './Dashboard.scss';

const STATE_MAP = {
  archived: ['archived'],
  hired: ['hired'],
  active: ['active', 'candidate_pass_pending'],
  passed: ['passed', 'employer_passed', 'candidate_passed'],
};

const route = '/manage/dashboard/';

const Dashboard = (props) => {
  const location = useLocation();
  const { search } = location;

  const params = {
    state: {
      type: 'string',
      defaultValue: 'active',
    },
    candidateFullName: {
      type: 'string',
      defaultValue: null,
    },
    requisitionOpeningId: {
      type: 'string',
      defaultValue: 'all',
    },
    sortBy: {
      type: 'string',
      defaultValue: 'created_at_desc',
    },
  };

  const queryParamsParsed = queryStringParse({ search, params });

  const defaultParams = Object.keys(params).reduce((acc, curr) => {
    const {
      [curr]: { defaultValue },
    } = params;

    acc[curr] = defaultValue;

    return acc;
  }, {});

  const [queryParams, setQueryParams] = useState({ ...queryParamsParsed, unarchiveBlockId: null });

  const newRoute = useRef();

  const defaultLoadParams = {
    ...defaultParams,
    includeSet: 'dashboard',
    page: 1,
  };

  const defaultLoadArgs = {
    type: 'requisition_candidates',
    slice: 'employerCandidates',
  };

  useEffect(() => {
    const {
      actions: { entityLoadStarted, adminLoadStarted },
      user: {
        currentProfile: { employerId, employer: { rainmakersAdminId: adminId } = {} } = {},
      } = {},
    } = props;

    entityLoadStarted({
      ...getLoadArgs(),
      params: {
        ...getLoadParams(),
        page: 1,
      },
    });

    entityLoadStarted({
      ...defaultLoadArgs,
      type: 'requisition_openings',
      params: {
        state: 'open',
        employerId,
        page: 1,
        includeSet: 'requirement_creator',
        requisitionCandidateId: 'null',
      },
    });

    if (adminId) {
      adminLoadStarted({ adminId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const {
      actions: { entityLoadStarted },
    } = props;

    if (newRoute.current) {
      entityLoadStarted({
        ...getLoadArgs(),
        newRoute: newRoute.current,
        params: {
          ...getLoadParams(),
          page: 1,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const getLoadArgs = () => {
    const {
      actions: { entityLoadStarted },
    } = props;
    const { state } = queryParams;

    return {
      ...defaultLoadArgs,
      type: state === 'archived' ? 'candidate_company_blocks' : defaultLoadArgs.type,
      successCallback:
        state !== 'archived'
          ? ({ data }) => {
              const { entities: { requisitionCandidate: { allIds = [] } = {} } = {} } = data;

              entityLoadStarted({
                type: 'private_req_can_feedbacks',
                slice: 'employerCandidates',
                params: {
                  requisitionCandidateId: allIds,
                },
                updateOnly: true,
              });
            }
          : null,
    };
  };

  const getLoadParams = () => {
    const {
      user: { currentProfile: { employer: { companyId } = {}, id: employeeId } = {} } = {},
      page = 1,
      candidateCompanyBlocksPage = 1,
    } = props;

    const { state, candidateFullName, sortBy, requisitionOpeningId } = queryParams;

    return state === 'archived'
      ? {
          page: candidateCompanyBlocksPage,
          sortBy: 'created_at_desc',
          blockType: ['featured_pass', 'search_pass'],
          includeSet: 'candidate',
          companyId,
        }
      : {
          ...defaultLoadParams,
          state: STATE_MAP[state],
          candidateFullName: makeLikeParam(candidateFullName),
          page,
          companyId,
          employeeId: requisitionOpeningId === 'mine' ? employeeId : null,
          requisitionOpeningId: ['all', 'mine'].includes(requisitionOpeningId)
            ? null
            : requisitionOpeningId,
          sortBy: ['hired', 'passed'].includes(state) ? 'decided_on_desc' : sortBy,
        };
  };

  const loadMoreRequisitionOpenings = () => {
    const {
      actions: { entityLoadStarted },
      requisitionOpeningsPage: page,
      user: { currentProfile: { employerId } = {} } = {},
    } = props;

    entityLoadStarted({
      ...defaultLoadArgs,
      type: 'requisition_openings',
      params: {
        state: 'open',
        employerId,
        page: page + 1,
        includeSet: 'requirement_creator',
        requisitionCandidateId: 'null',
      },
    });
  };

  const loadMore = () => {
    const {
      actions: { entityLoadStarted },
      page,
    } = props;

    entityLoadStarted({
      ...getLoadArgs(),
      params: {
        ...getLoadParams(),
        page: page + 1,
      },
    });
  };

  const handleSortChange = (value) => {
    const { sortBy } = queryParams;

    if (sortBy && sortBy.includes(value)) {
      const newDirection = sortBy.includes('asc') ? '_desc' : '_asc';

      const newSort = `${value}${newDirection}`;

      newRoute.current = createRoute({
        route,
        location,
        name: 'sortBy',
        value: newSort,
      });

      setQueryParams((queryParams) => ({
        ...queryParams,
        sortBy: newSort,
      }));
    } else {
      const newSort = `${value}_asc`;

      newRoute.current = createRoute({
        route,
        location,
        name: 'sortBy',
        value: newSort,
      });

      setQueryParams((queryParams) => ({
        ...queryParams,
        sortBy: `${value}_asc`,
      }));
    }
  };

  const handleSearchFilterChange = (event) => {
    const {
      target: { name, value },
    } = event;

    const { [name]: arrayToUpdate } = queryParams;

    const actualValue = value[value.type];

    const newArray = toggleArray({ array: arrayToUpdate, value: actualValue });

    newRoute.current = createRoute({
      route,
      location,
      name,
      value: newArray,
    });

    setQueryParams((queryParams) => ({
      ...queryParams,
      [name]: newArray,
    }));
  };

  const handleSearchInputChange = ({ target: { name, value } }) => {
    newRoute.current = createRoute({
      route,
      location,
      name,
      value,
    });

    setQueryParams((queryParams) => ({
      ...queryParams,
      [name]: value,
    }));
  };

  const handleSetState = (args) =>
    setQueryParams((queryParams) => ({
      ...queryParams,
      ...args,
    }));

  const handleUnarchiveCandidate = () => {
    const {
      actions: { deleteResource },
    } = props;

    const { unarchiveBlockId } = queryParams;

    deleteResource({
      slice: 'employerCandidates',
      type: 'candidate_company_blocks',
      id: unarchiveBlockId,
    });

    setQueryParams((queryParams) => ({ ...queryParams, unarchiveBlockId: null }));
  };

  const {
    actions,
    candidates,
    candidateCompanyBlocks,
    candidateCompanyBlocksPage,
    candidateCompanyBlocksLoading,
    candidateCompanyBlocksTotal,
    children,
    employees,
    isLoading,
    privateReqCanFeedbacks,
    rainmakersAdmin,
    rainmakersAdmins,
    requisitionCandidates,
    requisitionOpenings,
    requisitionOpeningsTotal,
    total,
    user,
    user: { currentProfile: { firstName, lastName, employer } = { employer: {} } },
  } = props;

  const { unarchiveBlockId } = queryParams;

  const candidatesTableProps = {
    ...queryParams,
    actions,
    candidates,
    candidateCompanyBlocks,
    candidateCompanyBlocksPage,
    candidateCompanyBlocksLoading,
    candidateCompanyBlocksTotal,
    employees,
    handleSearchInputChange,
    handleSearchFilterChange,
    handleSortChange,
    handleSetState,
    isLoading,
    loadMore,
    loadMoreRequisitionOpenings,
    privateReqCanFeedbacks,
    rainmakersAdmins,
    requisitionCandidates,
    requisitionOpenings,
    requisitionOpeningsTotal,
    total,
    user,
  };

  const modalUnarchiveButtonProps = {
    onClick: handleUnarchiveCandidate,
    primary: true,
  };

  const modalUnarchiveButton = <Button {...modalUnarchiveButtonProps}>Continue</Button>;

  const cancelButtonProps = {
    // onClick: handleResetUnarchiveModal,
    onClick: null,
    quaternary: true,
  };

  const cancelButton = <Button {...cancelButtonProps}>Cancel</Button>;

  const unarchiveCandidateModal = unarchiveBlockId ? (
    <DialogueModal>
      <div className={styles.requestIntroModal}>
        <div className={styles.requestIntroContent}>
          <p>
            You are removing the block for this candidate. You may see this candidate again in{' '}
            <Bold>Featured</Bold> or <Bold>Matches</Bold>.
          </p>
          <p>
            If you want to request an intro to this candidate, cancel this request and select the
            candidate&apos;s name from the current list and then select <Bold>Request Intro</Bold>.
          </p>
        </div>
        <div className={styles.requestIntroButtons}>
          {cancelButton}
          {modalUnarchiveButton}
        </div>
      </div>
    </DialogueModal>
  ) : null;

  const talentConciergeProps = {
    admin: rainmakersAdmin,
    candidateName: `${employer.name} - ${firstName} ${lastName}`,
    adminTitle: 'Account Manager',
    emailSubject: 'Account Manager Inquiry',
  };

  const candidatesTable = (
    <Fragment>
      <div className={styles.talentConciergeContent}>
        <TalentConcierge {...talentConciergeProps} />
      </div>
      <div className={styles.dashboardMainContent}>
        <CandidatesTable {...candidatesTableProps} />
        {unarchiveCandidateModal}
      </div>
    </Fragment>
  );

  const layoutWithoutSidebarProps = {
    content: candidatesTable,
  };

  return [
    <Helmet key="helmet" title="Dashboard" />,
    <ReadyContainer key="readyContainer" className={styles.Dashboard}>
      <Sidebar type="manage" page="dashboard" />
      <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />
      {children}
    </ReadyContainer>,
  ];
};

export default ConnectContainer(duck)(EmployeeAuthHOC()(Dashboard));
