import React from 'react';

import InputBullets from 'components/InputBullets';

const Highlights = ({
  requisitionOpening: { attributes: { highlights, state } = {} } = {},
  handleAddArrayInput,
  handleAddArrayValue,
  handleDeleteArrayInput,
  handleReorderArray,
}) => {
  const highlightsInputProps = {
    disabled: state === 'closed',
    label: 'Company highlights',
    secondaryLabel: 'What are the key features or highlights of the company that you want to show?',
    array: highlights,
    name: 'highlights',
    handleAddArrayInput,
    handleAddArrayValue,
    handleDeleteArrayInput,
    handleReorderArray,
    pad: true,
    required: state !== 'closed',
  };

  return <InputBullets {...highlightsInputProps} />;
};

export default Highlights;
