import React, { Fragment } from 'react';

import Bolder from 'components/Bolder';
import Button from 'components/Button';
import CandidateImage from 'components/CandidateImage';
import ContentTitle from 'components/ContentTitle';
import FontIcon from 'components/FontIcon';
import FormattedArray from 'components/FormattedArray';
import FormattedMoney from 'components/FormattedMoney';
import FormattedName from 'components/FormattedName';

import { getInitials } from 'utils/generalUtils';

import styles from './ReviewRequest.scss';

const ReviewRequest = ({
  candidateProfile: {
    id: candidateId,
    firstName,
    lastName,
    picture: { xlarge: pictureUrl } = {},
  } = {},
  employerFunding,
  employerSizeMax,
  employerSizeMin,
  handleSendMessageClick,
  hideNames,
  message,
  oteMaxCents,
  oteMinCents,
  requisition: { highlights: employerHighlights, external_name: externalName, overview = [] } = {},
  salutation,
}) => {
  const tinyCandidateImageProps = {
    candidateId,
    pictureUrl,
    className: styles.avatarTiny,
    containerClassname: styles.avatarContainerTiny,
    useHideNames: true,
  };

  const sendIntroButtonProps = {
    onClick: handleSendMessageClick,
    primary: true,
    className: styles.sendRequest,
  };

  const sendIntroButton = <Button {...sendIntroButtonProps}>Send Request</Button>;

  const __html = message.toString('html');
  const innerHTML = { __html };

  const nameContent = hideNames ? getInitials({ firstName, lastName }) : firstName;
  const salutationName =
    salutation && salutation !== '<None>' ? salutation.replace(firstName, nameContent) : null;
  const salutationContent = salutationName ? (
    <div className={styles.salutation}>{salutationName},</div>
  ) : null;

  const messageContent =
    __html !== '<p><br></p>' ? (
      <div className={styles.offerItem}>
        <FontIcon iconName="rocket" />
        <div>
          {salutationContent}
          <div dangerouslySetInnerHTML={innerHTML} />
        </div>
      </div>
    ) : null;

  // const reqLink = `/requisitions/${reqId}/edit`;

  const roleDetailsContent =
    overview && overview.length > 0 && overview.every((el) => el) ? (
      <Fragment>
        <ContentTitle>ROLE DETAILS</ContentTitle>

        <div className={styles.container}>
          <FormattedArray array={overview} />
        </div>
      </Fragment>
    ) : null;

  const highlightsContent =
    employerHighlights && employerHighlights.length > 0 && employerHighlights.every((el) => el) ? (
      <div className={styles.companyOfferItem}>
        <div className={styles.label}>
          <Bolder>Highlights</Bolder>
        </div>
        <div className={styles.value}>
          <FormattedArray array={employerHighlights} />
        </div>
      </div>
    ) : null;

  const fundingContent = employerFunding ? (
    <div className={styles.companyOfferItem}>
      <div className={styles.label}>
        <Bolder>Funding</Bolder>
      </div>
      <div className={styles.value}>{employerFunding}</div>
    </div>
  ) : null;

  const employeesContent =
    employerSizeMin || employerSizeMax ? (
      <div className={styles.companyOfferItem}>
        <div className={styles.label}>
          <Bolder>Employees</Bolder>
        </div>
        <div className={styles.value}>
          {employerSizeMin} - {employerSizeMax}
        </div>
      </div>
    ) : null;

  const formattedNameProps = { candidateId, useHideNames: true, firstName, lastName };

  return (
    <div className={styles.adminRequestOptionsContainer}>
      <div className={styles.header}>Review and Send Intro Request</div>
      <div className={styles.subheader}>
        Make sure everything looks right and you&apos;re all set to get started with this candidate.
      </div>
      <div className={styles.requestIntroButtons}>{sendIntroButton}</div>

      <ContentTitle>OFFER DETAILS</ContentTitle>

      <div className={styles.container}>
        <div className={styles.offerItem}>
          <CandidateImage {...tinyCandidateImageProps} />
          <FormattedName {...formattedNameProps} />
        </div>

        <div className={styles.offerItem}>
          <FontIcon iconName="company" />
          {externalName}
        </div>

        <div className={styles.offerItem}>
          <FontIcon iconName="cash" />
          <FormattedMoney amount={oteMinCents} /> - <FormattedMoney amount={oteMaxCents} />
        </div>

        {messageContent}
      </div>

      {roleDetailsContent}

      <ContentTitle>COMPANY DETAILS</ContentTitle>

      <div className={styles.container}>
        {employeesContent}

        {fundingContent}

        {highlightsContent}
      </div>
    </div>
  );
};

export default ReviewRequest;
