import * as creators from 'shared/store/app/creators';

import reducer from './reducers';
import selector from './selectors';

const duck = {
  creators,
  reducer,
  selector,
};

export default duck;
