import React from 'react';
import dayjs from 'dayjs';

import EmptyCompanyLogo from 'shared/assets/missing-company-picture.png';

import commonStyles from '../../CandidateTurboProfile.scss';
import styles from './ProfessionalSummary.scss';

const dateToYear = (date) => (date === null ? 'Present' : dayjs(date).get('year'));

const getTotalYears = (workHistoryData) => {
  const { minYear, maxYear } = workHistoryData
    .map(({ startDate, endDate }) => [
      Number(dateToYear(startDate)),
      endDate === null ? null : Number(dateToYear(endDate)),
    ])
    .reduce(
      (acc, [startYear, endYear]) => {
        const { minYear, maxYear } = acc;

        return {
          minYear: minYear < startYear ? minYear : startYear,
          maxYear:
            maxYear === null || endYear === null ? null : maxYear > endYear ? maxYear : endYear,
        };
      },
      { minYear: 100000, maxYear: 0 }
    );

  return `${minYear} - ${maxYear === null ? 'Present' : maxYear}`;
};

export const ProfessionalSummary = ({ data }) => {
  return (
    <div className={styles.ProfessionalSummaryContainer}>
      {data.length > 0 && (
        <>
          <h2>Professional summary</h2>
          <div className={styles.professionalSummaryData}>
            {data.map(({ companyName, workHistoryData = [], companyPics = {} }) => {
              const { file_data, ['xlarge']: pictureUrl = '' } = companyPics;
              const picUrl =
                pictureUrl ||
                (typeof file_data === 'string' && file_data.length > 0 ? file_data : null) ||
                EmptyCompanyLogo;

              return (
                <React.Fragment key={companyName}>
                  <div className={styles.centered}>
                    <img alt="image info" src={picUrl} />
                  </div>
                  <div className={styles.centered}>
                    <strong>{companyName}</strong>
                  </div>
                  <div className={styles.centered}>
                    <div className={commonStyles.bodyBold}>{getTotalYears(workHistoryData)}</div>
                  </div>
                  <>
                    {workHistoryData.map(({ position, startDate, endDate }, idx) => (
                      <React.Fragment key={idx}>
                        <div />
                        <div>{position}</div>
                        <div>{`${dateToYear(startDate)} - ${dateToYear(endDate)}`}</div>
                      </React.Fragment>
                    ))}
                  </>
                  <div className={styles.companyDelimiter} />
                  <div />
                  <div />
                </React.Fragment>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
