import React from 'react';
import { Link } from 'react-router-dom';

import Bold from 'components/Bold';
import FontIcon from 'components/FontIcon';

import styles from './ProfileCard.scss';

const ProfileCard = ({ card: { link, header, subheader }, setBackLinkToHome }) => {
  const optionalText = subheader ? <div className={styles.optional}>{subheader}</div> : null;

  const profileCardProps = {
    className: styles.profileCardContent,
  };

  const linkProps = {
    to: link,
    onClick: setBackLinkToHome,
    className: styles.profileCard,
  };

  return (
    <Link {...linkProps}>
      <div {...profileCardProps}>
        <Bold>{header}</Bold>
        {optionalText}
      </div>
      <div className={styles.linkContent}>
        <FontIcon iconName="caret-right" />
      </div>
    </Link>
  );
};

export default ProfileCard;
