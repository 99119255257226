import React, { useState, useRef, useCallback } from 'react';
import classnames from 'classnames';
import v4 from 'uuid';
import isEmpty from 'lodash.isempty';
import InfiniteScroll from 'react-infinite-scroller';

import { useOnClickOutside } from 'hooks';
import { VisibleHOC } from 'containers/HOC';

import FontIcon from 'components/FontIcon';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import Label from 'components/Label';

import styles from './InputSelectInfiniteLoad.scss';

const InputSelectInfiniteLoad = ({
  disabled,
  handleInputChange,
  name,
  activeIcon,
  autoFocus,
  bigInput,
  className,
  darkIcon,
  defaultIcon,
  defaultLabel,
  fullWidth,
  hasMore,
  highlight,
  isVisible,
  label,
  loadMoreOptions,
  noMargin,
  pageStart,
  required,
  setWidth,
  source,
  value,
}) => {
  const [active, setActive] = useState(false);
  const ref = useRef();

  useOnClickOutside(
    ref,
    useCallback(() => setActive(false), [])
  );

  const onClick = () => {
    setActive((active) => (disabled ? false : !active));
  };

  const onSelect = (newValue) => {
    if (active) {
      const event = {
        target: {
          name,
          value: newValue,
          type: 'select',
        },
      };

      handleInputChange(event);

      setActive(false);
    }
  };

  const selectedItem = source.filter((item) => {
    if (item.value.type) {
      return item.value[item.value.type] === value[value.type];
    }
    return `${item.value}` === `${value}`;
  });

  const currentItemOne = selectedItem.length > 0 ? selectedItem[0] : defaultLabel;
  const currentItemTwo =
    value === 'Non-sales' && name === 'role' ? { label: 'Non-sales', value: 'Non-sales' } : null;

  const currentItem = currentItemTwo || currentItemOne;

  const selectClasses = classnames(
    styles.selectStyle,
    {
      [styles.noMarginTop]: noMargin,
      [styles.requiredRelative]: required && !label,
    },
    className
  );

  const inputClasses = classnames(styles.inputStyle, {
    [styles.active]: active,
    [styles.fullWidth]: fullWidth,
    [styles.big]: bigInput,
    [styles.disabled]: disabled,
    [styles.focus]: highlight,
  });

  const optionsContainerClasses = classnames({
    [styles.OptionsContainer]: isVisible,
    [styles.bigOptionsContainer]: bigInput,
    [styles.OptionsContainerAbove]: !isVisible,
    [styles.OptionsContainerAboveBig]: !isVisible && bigInput,
    [styles.fullWidthOptions]: fullWidth,
  });

  const labelClasses = classnames(styles.labelStyle);

  const makeOptions = (option) => {
    const { disabled, indent, label, value: optionValue } = option;
    const isCurrentItem = () => {
      if (currentItem) {
        if (currentItem.value && currentItem.value.type) {
          return currentItem.value[currentItem.value.type] === optionValue[optionValue.type];
        }
        return currentItem.value === optionValue;
      }
      return false;
    };

    const optionClasses = classnames(styles.Option, {
      [styles.activeOption]: isCurrentItem(),
      [styles.disabledOption]: disabled,
    });

    const optionProps = {
      className: optionClasses,
      key: v4(),
      onClick: disabled ? () => null : () => onSelect(optionValue),
      value: optionValue,
    };

    const indentString =
      indent && indent > 0
        ? new Array(indent).fill(<span className={styles.indent}>&mdash;</span>)
        : null;

    return (
      <div {...optionProps}>
        {indentString}
        {label}
      </div>
    );
  };

  const content = source.map(makeOptions);

  const style = {
    width: setWidth,
  };

  const wrappedContentProps = {
    onClick: (event) => event.stopPropagation(),
    style,
    className: optionsContainerClasses,
  };

  const infiniteScrollProps = {
    pageStart,
    loadMore: loadMoreOptions,
    hasMore,
    loader: <InfiniteScrollLoader />,
    useWindow: false,
  };

  const wrappedContent = active ? (
    <div {...wrappedContentProps}>
      <InfiniteScroll {...infiniteScrollProps}>{content}</InfiniteScroll>
    </div>
  ) : null;

  const defaultLabelClasses = classnames(styles.inPlaceDefaultLabel, {
    [styles.disabled]: disabled,
  });

  const iconClasses = classnames({
    [styles.dark]: darkIcon,
  });

  const defaultLabelContent =
    !value ||
    value === '' ||
    value.length === 0 ||
    (typeof value === 'object' && isEmpty(value)) ? (
      <div className={defaultLabelClasses}>{defaultLabel}</div>
    ) : (
      <div className={styles.selectedValue}>
        {currentItem && currentItem.label ? currentItem.label : defaultLabel}
      </div>
    );

  const defaultIconContent = defaultIcon ? (
    <FontIcon className={iconClasses} iconName={defaultIcon} />
  ) : null;

  const iconContent =
    active && activeIcon ? (
      <FontIcon className={iconClasses} iconName={activeIcon} />
    ) : (
      defaultIconContent
    );

  const labelContent = label ? (
    <Label required={required} className={labelClasses}>
      {label}
    </Label>
  ) : null;

  const inputSelectProps = {
    className: selectClasses,
    autoFocus,
  };

  const inputMainProps = {
    style: { width: setWidth },
    className: inputClasses,
    onClick,
  };

  const relativeDivProps = {
    className: classnames(styles.relative),
  };

  return (
    <button type="button" {...inputSelectProps} ref={ref}>
      {labelContent}
      <div {...relativeDivProps}>
        <div {...inputMainProps}>
          {defaultLabelContent}
          {iconContent}
        </div>
        {wrappedContent}
      </div>
    </button>
  );
};

export default VisibleHOC()(InputSelectInfiniteLoad);
