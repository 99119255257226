import React, { Component, Fragment } from 'react';

import { handleError } from 'utils/common';
import { indexOfRegex } from 'utils/generalUtils';

import { patchAction } from 'api/apiNonstandard';

import Block from 'components/Block';
import Bolder from 'components/Bolder';
import Button from 'components/Button';
import FormattedDate from 'components/FormattedDate';

import MessageDetails from '../MessageDetails';

import styles from './Message.scss';

class Message extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showCollapsed: false,
    };
  }

  handleAttemptResendRequest = async () => {
    const {
      message: {
        id: messageId,
        attributes: { messageGroupId },
      },
      actions: { entityLoadStarted } = {},
    } = this.props;

    const resend_args = {
      type: 'messages',
      id: messageId,
      action: 'attempt_resend',
    };

    try {
      await patchAction(resend_args);
    } catch (error) {
      handleError(error);
    }

    entityLoadStarted({
      type: 'messages',
      slice: 'toolboxApproaches',
      params: {
        page: 1,
        messageGroupId,
        sortBy: 'created_at_desc',
      },
    });
  };

  render() {
    const {
      actions,
      candidates,
      employees,
      idx,
      isAdmin,
      message: {
        attributes: { ccEmails, createdAt, fromEmail, messageHtml = '', subject, toEmail } = {},
        id: messageId,
      } = {},
      messageGroupMembers,
      messageRecipients,
      rainmakersAdmins,
      sendgridEmailEvents,
    } = this.props;

    const { showCollapsed } = this.state;

    const toContent = (
      <Fragment>
        <span className={styles.label}>To:</span>
        <span>{toEmail}</span>
      </Fragment>
    );
    const fromContent = (
      <Fragment>
        <span className={styles.label}>From:</span>
        <span>{fromEmail}</span>
      </Fragment>
    );
    const ccContent = (
      <Fragment>
        <span className={styles.label}>CC:</span>
        <span>{ccEmails}</span>
      </Fragment>
    );
    const sentAtContent = (
      <Fragment>
        <span className={styles.label}>Sent at:</span>
        <FormattedDate format="h:mma M/D/YY" parse date={createdAt} />
      </Fragment>
    );

    const emailContent =
      ccEmails || toEmail || fromEmail ? (
        <div className={styles.emailContainer}>
          <div className={styles.emailLine}>{toEmail ? toContent : ''}</div>
          <div className={styles.emailLine}>{fromEmail ? fromContent : ''}</div>
          <div className={styles.emailLine}>{ccEmails ? ccContent : ''}</div>
          <div className={styles.emailLine}>{createdAt ? sentAtContent : ''}</div>
        </div>
      ) : null;

    const detailsProps = {
      actions,
      candidates,
      employees,
      isAdmin,
      messageId,
      messageGroupMembers,
      messageRecipients,
      rainmakersAdmins,
      sendgridEmailEvents,
    };

    const attemptResendProps = {
      primary: true,
      onClick: this.handleAttemptResendRequest,
      // className: styles.integrationButton,
      name: 'attemptResend',
    };

    const attemptResendContent = !this.props.message.attributes.creatorId ? (
      <Button {...attemptResendProps}>Attempt Resend</Button>
    ) : null;

    const title = (
      <div className={styles.title}>
        <div>
          <Bolder>{subject}</Bolder>
          {emailContent}
        </div>
        {attemptResendContent}
        <MessageDetails {...detailsProps} />
      </div>
    );

    const blockProps = {
      addWhiteBG: true,
      boxShadow: true,
      largeTitleFont: true,
      addPadding: true,
      className: idx === 0 ? styles.firstBlock : styles.block,
      title,
    };

    const quotedSectionRegex = /(<div class="gmail_quote">)|(<div id="appendonsend">)|(<div id="yahoo_quoted)|(<div class="msg-quote">)/;

    const startQuotedText = messageHtml ? indexOfRegex(messageHtml, quotedSectionRegex) : -1;

    const currentMessage =
      startQuotedText >= 0 ? messageHtml.substring(0, startQuotedText) : messageHtml;

    const collapsedMessages = startQuotedText >= 0 ? messageHtml.substring(startQuotedText) : '';

    const currentMessageContent = (
      <div
        className={styles.currentMessage}
        dangerouslySetInnerHTML={{ __html: currentMessage || 'No message added' }}
      />
    );

    const collapsedMessageContent = showCollapsed ? (
      <div dangerouslySetInnerHTML={{ __html: collapsedMessages }} />
    ) : null;

    const showMoreButtonProps = {
      tertiary: true,
      onClick: () => this.setState((prevState) => ({ showCollapsed: !prevState.showCollapsed })),
      className: styles.collapseButton,
    };

    const showButton = showCollapsed ? (
      <Button {...showMoreButtonProps}>- Hide older messages</Button>
    ) : (
      <Button {...showMoreButtonProps}>+ Show older messages</Button>
    );

    const showButtonFinal = startQuotedText >= 0 ? showButton : null;

    return (
      <Block {...blockProps}>
        {currentMessageContent}
        {showButtonFinal}
        {collapsedMessageContent}
      </Block>
    );
  }
}

export default Message;
