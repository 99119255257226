import React from 'react';
import classnames from 'classnames';

import styles from './InfiniteScrollLoader.scss';

const InfiniteScrollLoader = ({ className, smallTable }) => {
  const loaderClasses = classnames(
    styles.InfiniteScrollLoader,
    {
      [styles.smallTable]: smallTable,
    },
    className
  );

  return (
    <div className={loaderClasses}>
      <div className={styles.loadingPulse} />
    </div>
  );
};

export default InfiniteScrollLoader;
