import React, { useCallback } from 'react';

import { toCents, toDollars } from 'utils/moneyUtils';

import Input from 'components/Input';
import InputSelect from 'components/InputSelect';
import DropdownSelectMany from 'components/DropdownSelectMany';
import Label from 'components/Label';
import DropdownUsRegions from 'components/DropdownUsRegions';
import { WorkModelSelectMany } from 'components/WorkModelSelectMany';
import GooglePlacesAutocomplete from 'components/GooglePlacesAutocomplete';

import { getRegionByState } from 'components/UsMapView';

import styles from './SecondaryQuestions.scss';

const SecondaryQuestions = ({
  hasHiringStatus,
  locationSource,
  roleOptions,
  roles,
  desiredOTE,
  desiredSalary,
  workModel,
  recentRole,
  handleCandReqInputChange,
  handleCandidateTextInputChange,
  handleRoleChange,
  handleWorkModelChange,
  handleRecentRoleChange,
  handleToggleArray,
  locations = [],
  location,
  trackEvent,
}) => {
  const rolesSource = roleOptions.map((loc) => ({ label: loc, value: loc }));

  const recentRolesSource = rolesSource.slice();

  recentRolesSource.push({ label: 'Recent Grad', value: 'recentGrad' });

  const rolesOptions = roleOptions.map((role) => ({
    indent: 0,
    label: role,
    optionType: 'role',
    subCategories: [],
    value: {
      type: 'role',
      role,
    },
  }));

  const selectedRoles = roles.map((role) => ({ role, type: 'role' }));

  const rolesInputProps = {
    className: styles.searchBarFilter,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    onChange: (value) =>
      handleRoleChange({
        target: {
          subCategories: value.subCategories,
          label: value.label,
          value: value.value,
          name: 'roles',
          optionType: value.optionType,
        },
      }),
    optionsLarge: true,
    source: rolesOptions,
    name: 'roles',
    key: 'roles',
    isLocation: true,
    selectedValues: selectedRoles || '',
    defaultLabel: 'Click to select a role',
  };

  const recentRoleInputProps = {
    source: recentRolesSource,
    name: 'recentRole',
    handleInputChange: handleRecentRoleChange,
    value: recentRole || '',
    defaultLabel: 'Select a role',
    defaultIcon: 'caret-down',
    noMargin: true,
    setWidth: '200px',
    bigInput: true,
  };

  const desiredSalaryInputProps = {
    size: 'full',
    name: 'salaryCents',
    oteInputOne: true,
    value: desiredSalary || desiredSalary === 0 ? toDollars(desiredSalary) : '',
    placeholder: '',
    moneyInput: true,
    className: styles.oteInputContainer,
    handleInputChange: (event) =>
      handleCandReqInputChange({
        ...event,
        target: {
          ...event.target,
          value: toCents(event.target.value),
          name: event.target.name,
        },
      }),
  };

  const desiredOTEInputProps = {
    size: 'full',
    name: 'oteCents',
    oteInputOne: true,
    value: desiredOTE || desiredOTE === 0 ? toDollars(desiredOTE) : '',
    placeholder: '',
    moneyInput: true,
    className: styles.oteInputContainer,
    handleOnBlur: () => trackEvent({ name: 'oteCents' }),
    handleInputChange: (event) =>
      handleCandReqInputChange({
        ...event,
        target: {
          ...event.target,
          value: toCents(event.target.value),
          name: event.target.name,
        },
      }),
  };

  const locationOptions = locationSource.reduce((acc, curr) => {
    let subOpts = [];
    if (curr.subcategories) {
      subOpts = curr.subcategories.map((subOpt) => ({
        indent: 1,
        label: subOpt.name,
        optionType: subOpt.type,
        value: {
          type: subOpt.type,
          [subOpt.type]: subOpt.name,
        },
      }));
    }

    const allOpts = Array.prototype.concat(
      [
        {
          indent: 0,
          label: curr.name,
          optionType: curr.type,
          subCategories: subOpts,
          value: {
            type: curr.type,
            [curr.type]: curr.name,
          },
        },
      ],
      subOpts
    );

    return acc.concat(allOpts);
  }, []);

  const selectedLocations = locations.map((loc) => loc.region);

  const setRegion = useCallback(
    (location) => {
      if (location && location.endsWith(', USA')) {
        const parts = location.split(',');
        const state = parts[parts.length - 2].trim();
        const region = getRegionByState(state);

        if (!selectedLocations.includes(region)) {
          handleToggleArray({
            target: {
              label: region,
              value: region,
              name: 'locations',
            },
          });
        }
      }
    },
    [handleToggleArray, selectedLocations]
  );

  const regionsInputProps = {
    className: styles.searchBarFilter,
    containerClassName: styles.regionsContainer,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    onChange: (value) => {
      handleToggleArray({
        target: {
          subCategories: value.subCategories,
          label: value.label,
          value,
          name: 'locations',
          optionType: value.optionType,
        },
      });
    },
    optionsLarge: true,
    source: locationOptions.map((locOpt) => ({ ...locOpt, value: locOpt.value.region })),
    name: 'locations',
    key: 'locations',
    isLocation: true,
    selectedValues: selectedLocations,
    defaultLabel: 'Click to select a region',
    pillsPlacement: 'select-many',
    closeOnSelect: true,
  };

  const locationInputProps = {
    className: styles.searchBarFilter,
    value: location || '',
    name: 'location',
    placeholder: '',
    handleSelect: (place) => {
      handleCandidateTextInputChange({
        target: {
          value: place,
          name: 'location',
        },
      });

      setRegion(place);
    },
  };

  return hasHiringStatus ? (
    <div className={styles.secondaryContainer} data-testid="SecondaryQuestions">
      <div className={styles.containerTitle}>DESIRED COMPENSATION</div>

      <div className={styles.formRow}>
        <Label required className={styles.label}>
          Desired Base Salary
        </Label>
        <Label required className={styles.label}>
          Desired OTE
        </Label>
      </div>

      <div className={styles.formRow}>
        <Input {...desiredSalaryInputProps} />
        <Input {...desiredOTEInputProps} />
      </div>

      <div className={styles.containerTitleRole}>DESIRED ROLE</div>

      <Label required className={styles.label}>
        Location
      </Label>
      <GooglePlacesAutocomplete {...locationInputProps} />

      <Label required className={styles.label}>
        What region are you located in?
      </Label>
      <DropdownUsRegions {...regionsInputProps} />

      <div className={styles.workModelContainer}>
        <Label required className={styles.labelWorkModel}>
          Work Model
        </Label>
        <WorkModelSelectMany workModel={workModel} onChange={handleWorkModelChange} noLabel />
      </div>

      <Label required className={styles.label}>
        What kind of roles are you looking for?
      </Label>
      <DropdownSelectMany {...rolesInputProps} />

      <Label required className={styles.label}>
        What role best describes your most recent position?
      </Label>
      <InputSelect {...recentRoleInputProps} />
    </div>
  ) : null;
};

export default SecondaryQuestions;
