import { call, cancel, put, take, fork } from 'redux-saga/effects';
import { handleError } from 'utils/common';

import { postResource } from 'api/apiResource';

import { POST_RESOURCE } from '../../actions';

import { apiErrorsUpdate, entityLoadDone, savingUpdate } from '../../creators';

const doPostResource = function* doPostResource(action) {
  const {
    payload: {
      attributes: origAttributes,
      normalizeIt,
      params,
      requiredFields = [],
      slice,
      type,
      typeOverride,
    },
  } = action;

  const attributes = Object.keys(origAttributes).reduce((acc, curr) => {
    if (curr !== 'temporary') {
      acc[curr] = origAttributes[curr];
    }

    return acc;
  }, {});

  if (slice && type) {
    yield put(savingUpdate({ saving: true, slice }));

    const needPost = requiredFields.every((field) => attributes[field]);

    if (needPost) {
      try {
        const args = {
          config: {
            params: {
              normalizeIt,
              ...params,
            },
          },
          data: {
            attributes,
            type,
          },
          type,
        };

        const { data } = yield call(postResource, args);

        yield put(
          entityLoadDone({
            slice,
            type,
            typeOverride,
            data,
          })
        );
      } catch (error) {
        handleError(error);

        yield put(apiErrorsUpdate({ slice, error }));
      }
    }
  }

  if (type && !slice) {
    try {
      const args = {
        config: {
          params: {
            normalizeIt,
            ...params,
          },
        },
        data: {
          attributes,
          type,
        },
        type,
      };

      yield call(postResource, args);
    } catch (error) {
      handleError(error);
    }
  }
};

const postResourceSaga = function* postResourceSaga() {
  let lastTask;
  let lastAction = {};
  while (true) {
    const action = yield take(POST_RESOURCE);
    const { payload: { attributes = {} } = {} } = action;
    const { payload: { attributes: previousAttributes = {} } = {} } = lastAction;

    const attributesToUpdate = Object.keys(attributes);
    const previousAttributesToUpdate = Object.keys(previousAttributes);

    const sameAttributes =
      attributesToUpdate.length === previousAttributesToUpdate.length &&
      attributesToUpdate.every((attr) => previousAttributesToUpdate.includes(attr));

    if (lastTask && sameAttributes) {
      yield cancel(lastTask);
    }

    lastAction = action;
    lastTask = yield fork(doPostResource, action);
  }
};

export default postResourceSaga;
