import { handleError } from 'utils/common';
import { deleteEntity } from 'api/apiEntity';

const promiseBookmarksRemove = ({ bookmarkId }) => {
  const onFulfilled = (response) => response;

  const args = {
    type: 'bookmarks',
    id: bookmarkId,
  };

  return deleteEntity(args).then(onFulfilled).catch(handleError);
};

export default promiseBookmarksRemove;
