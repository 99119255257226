import React, { useState } from 'react';
import uniqBy from 'lodash.uniqby';
import classnames from 'classnames';

import RM_BANNER from 'shared/assets/rm_banner.jpeg';
import InputSelect from 'components/InputSelect';

import styles from './RainTheater.scss';

const RainTheater = ({ files }) => {
  const [state, setState] = useState({
    playing: false,
    mousePresent: undefined,
    src: null,
  });

  const { playing, mousePresent, src } = state;

  const sortedFiles = files.sort((a, b) => b.size - a.size);

  const filteredFiles = uniqBy(sortedFiles, 'height')
    .filter((file) => file.height)
    .slice(0, 3);

  const { link: hdLink } = filteredFiles.find((vid) => vid.quality === 'hd') || {};

  const { link: sdLink } = filteredFiles.find((vid) => vid.quality === 'sd') || {};

  const filesSelectorSource = filteredFiles.map((file) => ({
    label: file.height > 700 ? `HD (${file.height}p)` : `SD (${file.height}p)`,
    value: file.link,
  }));

  const selectClasses = classnames(styles.selector, {
    [styles.active]: mousePresent || !playing,
  });

  const selectProps = {
    className: selectClasses,
    source: filesSelectorSource,
    value: src || hdLink || sdLink,
    handleInputChange: (event) =>
      setState((prevState) => ({ ...prevState, src: event.target.value, playing: false })),
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
  };

  const videoProps = {
    autoplay: false,
    className: styles.player,
    controls: true,
    height: '100%',
    muted: false,
    poster: RM_BANNER,
    src: src || hdLink || sdLink,
    width: '100%',
    onMouseEnter: () => setState((prevState) => ({ ...prevState, mousePresent: true })),
    onMouseLeave: () => setState((prevState) => ({ ...prevState, mousePresent: false })),
    onPlay: () => setState((prevState) => ({ ...prevState, playing: true })),
    onPause: () => setState((prevState) => ({ ...prevState, playing: false })),
  };

  const inputWrapperProps = {
    onMouseEnter: () => setState((prevState) => ({ ...prevState, mousePresent: true })),
  };

  return (
    <div className={styles.videoContainer}>
      <video {...videoProps} />
      <div {...inputWrapperProps}>
        <InputSelect {...selectProps} />
      </div>
    </div>
  );
};

export default RainTheater;
