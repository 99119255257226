import { createStructuredSelector } from 'reselect';

import { getModal } from 'shared/store/app/selectors';
import { selectIsLoggedIn, selectIsCandidate } from 'connectors/Login/redux/selectors';

const selector = createStructuredSelector({
  isCandidate: selectIsCandidate,
  isLoggedIn: selectIsLoggedIn,
  modal: getModal,
});

export { selector as default };
