import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';
import { push } from 'connected-react-router';

import Block from 'components/Block';
import Button from 'components/Button';
import FontIcon from 'components/FontIcon';

import CandidateNonModalWrapper from 'pages/EmployeePortal/CandidateProfilePages/CandidateNonModalWrapper';
import RequisitionOpening from 'pages/AdminPortal/ToolboxRequisitionEditorPage/components/RequisitionOpening';
import RequisitionRequirement from 'pages/AdminPortal/ToolboxRequisitionEditorPage/components/RequisitionRequirement';

import styles from 'pages/EmployeePortal/FeaturedPage/FeaturedPage.scss';

import CandidateSuggestion from '../CandidateSuggestion';

class SuggestionSet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCandidateId: '',
      initialized: false,
      numCandidatesToDisplay: 1,
      displayStart: 0,
      scrollDirection: 'right',
      disableArrows: false,
      showArrows: false,
      topLoaded: false,
      view: 'candidates',
    };

    this.candidateWidth = 118;
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleUpdateSize.bind(this));

    const {
      entityLoadStarted,
      nestedEntityLoadStarted,
      suggestionSet: {
        relationships: { requisitionOpeningCopy: { data: requisitionOpeningIds = [] } = {} } = {},
      } = {},
      suggestionSetId,
    } = this.props;

    entityLoadStarted({
      type: 'candidate_suggestions',
      slice: 'toolboxAdmins',
      params: {
        page: 1,
        sortBy: 'created_at_desc',
        suggestionSetId,
      },
    });
    nestedEntityLoadStarted({
      type: 'requisition_openings',
      slice: 'toolboxAdmins',
      id: requisitionOpeningIds[0],
      nestedType: 'requisition_requirement',
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { initialized } = this.state;
    const { candidateSuggestions: { byId = {}, allIds = [] } = {}, candidateSuggestionsLoading } =
      nextProps;

    if (
      !initialized &&
      allIds.length > 0 &&
      this.featuredCandidatesContainer &&
      !candidateSuggestionsLoading
    ) {
      const containerWidth = this.featuredCandidatesContainer.offsetWidth;

      const origCandidatesToDisplay = Math.floor(containerWidth / this.candidateWidth);

      const showArrows = allIds.length > origCandidatesToDisplay;

      const newContainerWidth = showArrows ? containerWidth - 192 : containerWidth;

      const numCandidatesToDisplay = Math.floor(newContainerWidth / this.candidateWidth);

      const {
        attributes: {
          candidateJson: { data: { attributes: { id: selectedCandidateId } = {} } = {} } = {},
        } = {},
      } = byId[allIds[0]] || {};

      this.setState({
        selectedCandidateId,
        initialized: true,
        numCandidatesToDisplay,
        showArrows,
      });
    }
  }

  handleUpdateSize = () => {
    const { candidateSuggestions: { allIds = [] } = {} } = this.props;
    const { showArrows: prevShowArrows } = this.state;

    if (allIds.length > 0 && this.featuredCandidatesContainer) {
      const containerWidth = this.featuredCandidatesContainer.offsetWidth;

      const origCandidatesToDisplay = Math.floor(containerWidth / this.candidateWidth);

      const showArrows = allIds.length > origCandidatesToDisplay;

      let newContainerWidth = containerWidth;

      if (showArrows && !prevShowArrows) {
        newContainerWidth -= 192;
      }

      if (!showArrows && prevShowArrows) {
        newContainerWidth += 192;
      }

      const numCandidatesToDisplay = Math.floor(newContainerWidth / this.candidateWidth);

      this.setState({
        numCandidatesToDisplay,
        showArrows,
      });
    }
  };

  goBack = () => {
    const { dispatch, rainmakersAdmin: { id } = {}, showModal, modal } = this.props;

    const route = `/toolbox/admin/${id}/suggestion-sets`;

    if (!modal.originalUrl) {
      dispatch(push(route));
    } else {
      showModal({
        parent: 'ToolboxAdminsPage',
        route,
        key: 'AdminEditorModal',
        originalUrl: modal.originalUrl,
      });
    }
  };

  loadMoreFeaturedCandidates = () => {
    const {
      entityLoadStarted,
      candidateSuggestionsPage: page,
      candidateSuggestionsTotal: total,
      candidateSuggestions: { allIds = [] },
      suggestionSetId,
    } = this.props;

    const { numCandidatesToDisplay, displayStart } = this.state;

    if (allIds.length < total) {
      const newPage = page + 1;

      entityLoadStarted({
        type: 'candidate_suggestions',
        slice: 'toolboxAdmins',
        params: {
          page: newPage,
          sortBy: 'created_at_desc',
          suggestionSetId,
        },
      });
    }

    this.setState({
      scrollDirection: 'right',
      disableArrows: true,
      displayStart: displayStart + numCandidatesToDisplay,
    });

    setTimeout(() => {
      this.setState({
        disableArrows: false,
      });
    }, 750);
  };

  render() {
    const {
      selectedCandidateId,
      numCandidatesToDisplay,
      displayStart,
      scrollDirection,
      disableArrows,
      showArrows,
      topLoaded,
      view,
    } = this.state;

    const {
      candidateSuggestions: { allIds = [], byId = {} },
      candidateSuggestionsLoading,
      patchResource,
      pickerOptions,
      requisitionOpenings: { byId: requisitionOpeningsById = {} },
      requisitionRequirements: {
        allIds: requisitionRequirementsAllIds = [],
        byId: requisitionRequirementsById = {},
      } = {},
      suggestionSet: {
        relationships: { requisitionOpeningCopy: { data: requisitionOpeningIds = [] } = {} } = {},
      } = {},
    } = this.props;

    const { [requisitionOpeningIds[0]]: requisitionOpening = {} } = requisitionOpeningsById;

    const requisitionRequirementId = requisitionRequirementsAllIds.find(
      (id) =>
        requisitionRequirementsById[id].attributes.requisitionOpeningId === requisitionOpeningIds[0]
    );

    const { [requisitionRequirementId]: requisitionRequirement = {} } = requisitionRequirementsById;

    const candidateSuggestions = allIds.map((id) => {
      const {
        attributes: {
          candidateEducationsJson: {
            data: candidateEducationsData = [],
            included: educationOrganizations = [],
          } = {},
          candidateJson: { data: { attributes = {} } = {} } = {},
          candidateRequirementJson: { data: candidateRequirementsData = [] } = {},
          suggested,
          workHistoriesJson: { data: workHistoriesData = [], included: companies = [] } = {},
        } = {},
        id: candidateSuggestionId,
      } = byId[id] || {};

      const candidateEducations = candidateEducationsData.map((edu) => {
        const {
          attributes = {},
          relationships: { educationOrganization: { data: { id: educationOrgId } = {} } = {} } = {},
        } = edu;

        const { attributes: educationOrganization = {} } =
          educationOrganizations.find((org) => org.id === educationOrgId) || {};

        return {
          ...attributes,
          educationOrganization,
        };
      });

      const candidateRequirement = candidateRequirementsData.attributes;

      const workHistories = workHistoriesData.map((work) => {
        const {
          attributes = {},
          relationships: { company: { data: { id: companyId } = {} } = {} } = {},
        } = work;

        const { attributes: company = {} } = companies.find((comp) => comp.id === companyId) || {};

        return {
          ...attributes,
          company,
        };
      });

      return {
        ...attributes,
        candidateEducations,
        candidateRequirement,
        candidateSuggestionId,
        suggested,
        workHistories,
      };
    });

    const candidatesToDisplay = candidateSuggestions.slice(
      displayStart,
      displayStart + numCandidatesToDisplay
    );

    const results = candidatesToDisplay.map((cand, idx) => {
      const number = candidateSuggestions.findIndex((el) => el.id === cand.id);

      const candidateSuggestionProps = {
        candidate: cand,
        handleOnClick: () => this.setState({ selectedCandidateId: cand.id }),
        handleOnLoad:
          idx === 0 ? () => setTimeout(() => this.setState({ topLoaded: true }), 700) : () => null,
        number: number + 1,
        patchResource,
        selected: cand.id === selectedCandidateId,
      };

      return <CandidateSuggestion key={cand.id} {...candidateSuggestionProps} />;
    });

    const moreCandidatesProps = {
      onClick: disableArrows ? () => null : this.loadMoreFeaturedCandidates,
      disabled: displayStart + numCandidatesToDisplay >= candidateSuggestions.length,
      circle: true,
      className: styles.buttonRight,
    };

    const moreCandidatesButton = showArrows ? (
      <Button {...moreCandidatesProps}>
        <FontIcon iconName="caret-right" />
      </Button>
    ) : null;

    const lessCandidatesProps = {
      onClick: disableArrows
        ? () => null
        : () => {
            const newStart =
              displayStart - numCandidatesToDisplay <= 0
                ? 0
                : displayStart - numCandidatesToDisplay;
            this.setState({
              scrollDirection: 'left',
              displayStart: newStart,
              disableArrows: true,
            });

            setTimeout(() => {
              this.setState({
                disableArrows: false,
              });
            }, 750);
          },
      disabled: displayStart === 0,
      circle: true,
      className: styles.buttonRight,
    };

    const lessCandidatesButton = showArrows ? (
      <Button {...lessCandidatesProps}>
        <FontIcon iconName="caret-left" />
      </Button>
    ) : null;

    const transitionName =
      scrollDirection === 'right'
        ? {
            enter: styles.enterRight,
            enterActive: styles.enterActiveRight,
            leave: styles.leaveRight,
            leaveActive: styles.leaveActiveRight,
          }
        : {
            enter: styles.enterLeft,
            enterActive: styles.enterActiveLeft,
            leave: styles.leaveLeft,
            leaveActive: styles.leaveActiveLeft,
          };

    const groupKey = candidatesToDisplay[0] ? candidatesToDisplay[0].id : 'init';

    const groupClasses = classnames(styles.candidatesGroup);

    const emptyActionButtonProps = {
      primary: true,
      to: '/featured',
    };

    const emptyActionButton = (
      <Button {...emptyActionButtonProps}>Browse Featured Candidates</Button>
    );

    const finalResults =
      results.length === 0 && !candidateSuggestionsLoading ? null : (
        <React.Fragment>
          {lessCandidatesButton}
          <div
            className={styles.featuredCandidatesContainer}
            ref={(container) => {
              this.featuredCandidatesContainer = container;
            }}
          >
            <CSSTransition timeout={{ enter: 750, exit: 750 }} classNames={transitionName}>
              <div key={groupKey} className={groupClasses}>
                {results}
              </div>
            </CSSTransition>
          </div>
          {moreCandidatesButton}
        </React.Fragment>
      );

    const [selectedCandidate] = candidateSuggestions.filter(
      (cand) => cand.id === selectedCandidateId
    );

    const number = candidateSuggestions.findIndex((el) => el.id === selectedCandidateId);

    const candidate = selectedCandidate
      ? {
          ...selectedCandidate,
          picture: {},
          firstName: 'Candidate',
          lastName: number + 1,
        }
      : {};

    const candidateProfileProps = {
      parentView: 'suggestions',
      candidate,
      isMatchLoading: !topLoaded,
      key: 'candidateProfile',
      patchResource,
      match: {
        params: {
          id: selectedCandidateId,
          view: 'profile',
        },
      },
    };

    const contentBlockProps = {
      title: finalResults,
      boxShadow: true,
      addWhiteBG: true,
      addFlex: true,
      addChildFlex: true,
      addBottomFade: true,
    };

    const normalBlockProps = {
      ...contentBlockProps,
      title: null,
    };

    const backButtonProps = {
      quaternary: true,
      onClick: this.goBack,
      className: styles.backButton,
    };

    const candidatesButtonProps = {
      onClick: () => this.setState({ view: 'candidates' }),
      className: view === 'candidates' ? styles.highlightTitleLeft : styles.titleLeft,
      secondary: true,
    };

    const reqOpeningButtonProps = {
      onClick: () => this.setState({ view: 'requisitionOpening' }),
      className: view === 'requisitionOpening' ? styles.highlightTitleMiddle : styles.titleMiddle,
      disabled: !requisitionOpening,
      secondary: true,
    };

    const reqRequirementsButtonProps = {
      onClick: () => this.setState({ view: 'requisitionRequirement' }),
      className: view === 'requisitionRequirement' ? styles.highlightTitleRight : styles.titleRight,
      disabled: !requisitionRequirement,
      secondary: true,
    };

    const backButton = (
      <div className={styles.backButtonContainer}>
        <Button {...backButtonProps}>&#60; All Suggestion Sets</Button>

        <div className={styles.titleContainer}>
          <Button {...candidatesButtonProps}>Candidates</Button>
          <Button {...reqOpeningButtonProps}>Requisition Opening</Button>
          <Button {...reqRequirementsButtonProps}>Requisition Requirement</Button>
        </div>
      </div>
    );

    const featuredProfile =
      !candidateSuggestionsLoading && candidateSuggestions.length === 0 ? (
        <div key="emptyProfile" className={styles.emptyContainer}>
          <div className={styles.emptyTableString}>No Matched Candidates</div>
          {emptyActionButton}
        </div>
      ) : (
        <CandidateNonModalWrapper {...candidateProfileProps} />
      );

    const candidatesContent =
      view === 'candidates' ? <Block {...contentBlockProps}>{featuredProfile}</Block> : null;

    const reqOpeningProps = {
      pickerOptions,
      requisitionOpening,
    };

    const reqOpeningContent =
      view === 'requisitionOpening' ? (
        <Block {...normalBlockProps}>
          <RequisitionOpening {...reqOpeningProps} />
        </Block>
      ) : null;

    const reqRequirementProps = {
      requisitionOpening,
      requisitionRequirement,
      pickerOptions,
    };

    const reqRequirementContent =
      view === 'requisitionRequirement' ? (
        <Block {...normalBlockProps}>
          <RequisitionRequirement {...reqRequirementProps} />
        </Block>
      ) : null;

    const content = candidatesContent || reqOpeningContent || reqRequirementContent;

    return (
      <React.Fragment>
        {backButton}
        {content}
      </React.Fragment>
    );
  }
}

export default SuggestionSet;
