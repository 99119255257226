import React from 'react';

import Table from 'components/Table';
import TableRow from 'components/Table/components/TableRow';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';

import ToolboxApproachesRow from '../ToolboxApproachesRow';

import ToolboxApproachesSearchBar from '../ToolboxApproachesSearchBar';

import styles from './ToolboxApproachesTable.scss';

const ToolboxApproachesTable = ({
  actions,
  actions: { showModal },
  requisitionCandidates,
  attentionRequired,
  candidateFullName,
  candidateRainmakersAdminId,
  candidateUserEmail,
  candidatesById,
  employerName,
  employersById,
  handleSearchCheckboxChange,
  handleSearchInputChange,
  handleSearchFilterChange,
  handleSortChange,
  isLoading,
  loadMore,
  messageGroupsById,
  messageGroupMessagesCount,
  messageGroupUnread,
  privateReqCanFeedbackById,
  rainmakersAdmins,
  requisitionOpeningRole,
  requisitionOpeningsById,
  roleOptions,
  state,
  sortBy,
  total,
}) => {
  const tableHeader = (currentStatus) => {
    switch (currentStatus) {
      default:
        return [
          {
            content: 'Name',
            headerClassName: styles.nameHeader,
            value: 'candidate.full_name',
            onClick: handleSortChange,
          },
          {
            content: 'Job Title',
            headerClassName: styles.lastActivityHeader,
          },
          {
            content: 'Employer',
            headerClassName: styles.lastActivityHeader,
            value: 'employer.name',
            onClick: handleSortChange,
          },
          {
            content: 'Created At',
            headerClassName: styles.lastActivityHeader,
            value: 'created_at',
            onClick: handleSortChange,
          },
          {
            content: 'State',
            headerClassName: styles.lastActivityHeader,
            value: 'state',
            onClick: handleSortChange,
          },
          {
            content: 'Last Activity',
            headerClassName: styles.lastActivityHeader,
            value: 'last_activity_at',
            onClick: handleSortChange,
          },
          {
            content: 'Activity Type',
            headerClassName: styles.lastActivityHeader,
          },
          {
            content: '',
            headerClassName: styles.candActionHeader,
          },
        ];
    }
  };

  const openApproachEditor = (approachId) =>
    showModal({
      key: 'ApproachEditorModal',
      parent: 'ToolboxApproachesPage',
      route: `/toolbox/approach/${approachId}/messages/`,
    });

  const makeApproachCells = (approach) => {
    const {
      id: requisitionCandidateId,
      relationships: {
        candidate: { data: candidateIds = [] } = {},
        employer: { data: employerIds = [] } = {},
        requisitionOpeningCopy: { data: requisitionOpeningIds = [] } = {},
        messageGroups: { data: messageGroupsIds = [] } = {},
      } = {},
    } = approach;

    const privateReqCanFeedbackIds = Object.keys(privateReqCanFeedbackById).filter(
      (id) =>
        privateReqCanFeedbackById[id] &&
        privateReqCanFeedbackById[id].attributes.requisitionCandidateId === requisitionCandidateId
    );

    const privateReqCanFeedbackCandidateId = privateReqCanFeedbackIds.find(
      (id) => privateReqCanFeedbackById[id].attributes.ownerType === 'Candidate'
    );

    const privateReqCanFeedbackEmployeeId = privateReqCanFeedbackIds.find(
      (id) => privateReqCanFeedbackById[id].attributes.ownerType === 'Employee'
    );

    const privateReqCanFeedbackCandidate =
      privateReqCanFeedbackById[privateReqCanFeedbackCandidateId] || {};
    const privateReqCanFeedbackEmployee =
      privateReqCanFeedbackById[privateReqCanFeedbackEmployeeId] || {};

    const rowProps = {
      actions,
      approach,
      candidate: candidatesById[candidateIds[0]],
      employer: employersById[employerIds[0]],
      messageGroup: messageGroupsById[messageGroupsIds[0]],
      requisitionOpening: requisitionOpeningsById[requisitionOpeningIds[0]],
      showModal,
      openApproachEditor,
      privateReqCanFeedbackCandidate,
      privateReqCanFeedbackEmployee,
    };

    return <ToolboxApproachesRow {...rowProps} />;
  };

  const makeApproachRows = (approach) => (
    <TableRow id={approach.id} key={approach.id}>
      {makeApproachCells(approach)}
    </TableRow>
  );

  const tableContent = requisitionCandidates.map(makeApproachRows);

  const searchBarProps = {
    attentionRequired,
    candidateRainmakersAdminId,
    candidateFullName,
    candidateUserEmail,
    employerName,
    handleSearchCheckboxChange,
    handleSearchInputChange,
    handleSearchFilterChange,
    messageGroupMessagesCount,
    messageGroupUnread,
    rainmakersAdmins,
    requisitionOpeningRole,
    roleOptions,
    state,
  };

  const filtersContent = (
    <div className={styles.candidateHeader}>
      <div className={styles.candidateHeaderLeft}>
        <ToolboxApproachesSearchBar {...searchBarProps} />
      </div>
    </div>
  );

  const infiniteScrollProps = {
    loadMore,
    hasMore: !isLoading && total > requisitionCandidates.length,
    loader: isLoading ? (
      <div key="infiniteScrollLoader" />
    ) : (
      <InfiniteScrollLoader key="infiniteScrollLoader" />
    ),
    useWindow: false,
  };

  const tableProps = {
    blockProps: {
      titleClass: styles.dashboardTitleContainer,
    },
    titleContent: filtersContent,
    tableContent,
    status: state,
    header: tableHeader(state),
    sortBy,
    infiniteScrollProps,
    tableName: 'adminApproaches',
    isEmpty: !isLoading && requisitionCandidates.length === 0,
    emptyTextString: 'No Approaches',
    isLoading,
  };

  return <Table {...tableProps} />;
};

export default ToolboxApproachesTable;
