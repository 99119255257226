import { createSelector } from 'reselect';
import { entitySelectorFactory } from 'reduxStore/selectorFactories';
import defaultErrors from 'reduxStore/errors';

const selectCandidateErrors = createSelector(
  entitySelectorFactory('toolboxCandidates', 'candidate'),
  (state, props) => {
    const { match: { params: { id } = {} } = {} } = props || {};

    return id;
  },
  (candidates, id) => {
    const { byId: { [id]: { errors } = {} } = {} } = candidates;

    return errors || defaultErrors.candidates;
  }
);

export default selectCandidateErrors;
