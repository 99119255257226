import { call, select, put, take } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { getNestedEntity, patchNestedEntity, postNestedEntity } from 'api/apiEntity';

import { candidateProfilePatchDone } from 'connectors/User/redux/creators';

import { selectUser } from 'connectors/User/redux/selectors';

import { REORDER_WORK_STARTED } from '../actions';

const sagaReorderWorkHistoriesStarted = function* sagaReorderWorkHistoriesStarted() {
  while (true) {
    const {
      payload: { origWorkHistories, idx: currentIndex },
    } = yield take(REORDER_WORK_STARTED);

    const workHistory = origWorkHistories[currentIndex];

    const origEditingWorkHistories = origWorkHistories.filter(
      (wh, idx) => wh.isEditing && !wh.id && idx !== currentIndex
    );

    const user = yield select(selectUser);

    const {
      currentProfile: { id: candidateId },
    } = user;

    try {
      const whArgs = {
        data: {
          type: 'work_history',
          id: workHistory.id || '',
          attributes: {
            ...workHistory,
            candidateId,
          },
        },
        id: candidateId,
        type: 'candidates',
        nestedType: 'work_histories',
        nestedId: workHistory.id || '',
        config: {
          params: {
            camelizeIt: true,
          },
        },
      };

      const {
        data: { workHistory: newWorkHistory },
      } = yield workHistory.id ? call(patchNestedEntity, whArgs) : call(postNestedEntity, whArgs);

      const args = {
        id: candidateId,
        type: 'candidates',
        nestedType: 'work_histories',
        config: {
          params: {
            includeSet: 'company_sales_periods',
            sortBy: 'end_date_desc_nulls_first,start_date_desc',
            camelizeIt: true,
          },
        },
      };

      const {
        data: { workHistories: orderedWorkHistories },
      } = yield call(getNestedEntity, args);

      const orderedWorkIds = orderedWorkHistories.map((wh) => wh.id);

      const didMove = currentIndex !== orderedWorkIds.indexOf(newWorkHistory.id);

      const newWorkHistories = orderedWorkHistories.map((wh) => {
        const wasEditing = origWorkHistories.findIndex(
          (work) => work.id && work.id === wh.id && wh.isEditing
        );
        if (didMove && wh.id === newWorkHistory.id) {
          return {
            ...wh,
            moved: true,
            isEditing: false,
          };
        }
        return {
          ...wh,
          isEditing: wasEditing >= 0,
        };
      });

      const candidateProfile = {
        ...user.currentProfile,
        workHistories: [...newWorkHistories, ...origEditingWorkHistories],
      };

      yield put(candidateProfilePatchDone({ candidateProfile }));
    } catch (error) {
      handleError(error);
    }
  }
};

export default sagaReorderWorkHistoriesStarted;
