import { getSearch } from 'api/apiSearch';

const promiseSearch = ({ allSearchParams, category, contextOrigin, page, perPage }) => {
  const config = {
    params: {
      contextOrigin,
      'filter[category]': category,
      'includeSet': 'search',
      'normalizeIt': true,
      page,
      perPage,
    },
  };

  const onFulfilled = (response) => response;

  const getSearchArgs = {
    allSearchParams,
    config,
  };

  return getSearch(getSearchArgs).then(onFulfilled);
};

export default promiseSearch;
