import { put, take, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { handleError } from 'utils/common';

import { SHOW_MODAL } from 'shared/store/app/actions';

import { setModal } from 'shared/store/app/creators';

import { getModal } from 'shared/store/app/selectors';

const sagaShowModal = function* sagaShowModal() {
  while (true) {
    const {
      payload: {
        backPath,
        backKey,
        key,
        parent,
        originalUrl,
        route,
        search: newSearch,
        closeHook,
        silentClose,
        useSameOriginalUrl,
        loadMore,
      },
    } = yield take(SHOW_MODAL);

    try {
      const { pathname, search } = window.browserHistory.location;

      const { originalUrl: modalOriginalUrl } = yield select(getModal);

      const sameOriginalUrl = useSameOriginalUrl && modalOriginalUrl;

      const url = originalUrl || sameOriginalUrl || pathname + search;

      const payload = {
        backPath,
        backKey,
        key,
        originalUrl: url,
        closeHook,
        silentClose,
        loadMore,
      };

      const locationState = {
        pathname: route,
        search: newSearch,
        state: { modal: key, parent },
      };

      yield put(setModal(payload));

      if (route) {
        yield put(push(locationState));
      }
    } catch (error) {
      handleError(error);
    }
  }
};

export default sagaShowModal;
