import { all, fork } from 'redux-saga/effects';

import candidateProfileSaga from '../CandidateProfilePages/redux/sagas';
import candidateSearchSaga from '../CandidateSearchPage/redux/sagas';
import companyProfileSaga from '../EmployeeEditorPages/redux/sagas';
import featuredCandidatesSaga from '../FeaturedPage/redux/sagas';
import signupSaga from '../SignupPage/redux/sagas';
import sagaDashboard from '../DashboardPage/redux/sagas';

const sagaEmployers = function* sagaEmployers(history) {
  yield all([
    fork(candidateProfileSaga, history),
    fork(candidateSearchSaga, history),
    fork(companyProfileSaga, history),
    fork(featuredCandidatesSaga, history),
    fork(signupSaga, history),
    fork(sagaDashboard, history),
  ]);
};

export default sagaEmployers;
