export const candidateInputChange = (event, candidate) => {
  const {
    target: { type, name, value, checked },
  } = event;

  const newValue = type === 'checkbox' ? checked : value;

  const splitName = name.split(',');

  const newName = splitName[0];

  if (splitName.length === 3) {
    if (splitName[0] === 'socialMedia') {
      if (splitName[1] === 'linkedin') {
        const start = newValue.search(/\/in\//);
        if (start >= 0) {
          const linkedinUsernameIdxStart = start + 4;
          const linkedinUsernameIdxEnd = newValue.length;
          let linkedinUsername = newValue.substring(
            linkedinUsernameIdxStart,
            linkedinUsernameIdxEnd
          );

          if (linkedinUsername[linkedinUsername.length - 1] === '/') {
            linkedinUsername = linkedinUsername.substring(0, linkedinUsername.length - 1);
          }

          const composedValue = {
            ...candidate[splitName[0]],
            [splitName[1]]: {
              [splitName[2]]: newValue,
              username: linkedinUsername,
            },
          };

          return { name: newName, newValue: composedValue };
        }

        // No username found in linkedin url or bad format
        const composedValue = {
          ...candidate[splitName[0]],
          [splitName[1]]: {
            [splitName[2]]: newValue,
          },
        };

        return { name: newName, newValue: composedValue };
      }
      const twitterUrl =
        splitName[1] === 'twitter' && newValue !== '' ? `https://twitter.com/${newValue}` : '';
      const facebookUrl =
        splitName[1] === 'facebook' && newValue !== ''
          ? `https://www.facebook.com/${newValue}`
          : '';
      const composedValue = {
        ...candidate[splitName[0]],
        [splitName[1]]: {
          [splitName[2]]: newValue,
          url: twitterUrl || facebookUrl,
        },
      };
      return { name: newName, newValue: composedValue };
    }
    const composedValue = {
      ...candidate[splitName[0]],
      [splitName[1]]: {
        [splitName[2]]: newValue,
      },
    };
    return { name: newName, newValue: composedValue };
  }

  return { name: newName, newValue };
};

export const qAndAToggleArrayValue = (event, qAndA, idx) => {
  const {
    target: { value: newValue },
  } = event;

  const { multiAnswer } = qAndA;

  const { answer: answerArray } = multiAnswer[idx];

  const arrayToToggle = answerArray || [];

  if (arrayToToggle.includes(newValue)) {
    const newArray = arrayToToggle.filter((item) => item !== newValue);

    const newMultiAnswer = multiAnswer.map((obj, currIdx) => {
      if (idx === currIdx) {
        return {
          question: obj.question,
          answer: newArray,
        };
      }
      return obj;
    });

    const newQAndA = {
      ...qAndA,
      multiAnswer: newMultiAnswer,
    };

    return {
      newValue: newQAndA,
      name: 'qAndA',
    };
  }

  const newArray = arrayToToggle.slice();
  newArray.push(newValue);

  const newMultiAnswer = multiAnswer.map((obj, currIdx) => {
    if (idx === currIdx) {
      return {
        question: obj.question,
        answer: newArray,
      };
    }
    return obj;
  });

  const newQAndA = {
    ...qAndA,
    multiAnswer: newMultiAnswer,
  };

  return {
    newValue: newQAndA,
    name: 'qAndA',
  };
};

export const qAndATextInputChange = (event, qAndA, idx) => {
  const {
    target: { value: newValue },
  } = event;

  const { singleAnswer } = qAndA;

  const newSingleAnswer = singleAnswer.map((obj, currIdx) => {
    if (idx === currIdx) {
      return {
        question: obj.question,
        answer: newValue,
      };
    }
    return obj;
  });

  const newQAndA = {
    ...qAndA,
    singleAnswer: newSingleAnswer,
  };

  return {
    newValue: newQAndA,
    name: 'qAndA',
  };
};
