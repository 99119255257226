import React from 'react';

import { toDollars, toCents } from 'utils/moneyUtils';

import Label from 'components/Label';
import Input from 'components/Input';
import InputRadio from 'components/InputRadio';

import styles from './CSMDealInfo.scss';

const CSMDealInfo = ({ salesPeriod, handleSalesPeriodInputChange, trackEvent }) => {
  const {
    quotaAmountCents: quotaAmount,
    hadQuota,
    renewalRetentionPercentage,
    companiesSupported,
    id: salesPeriodId,
  } = salesPeriod || {};

  const commonTextInputProps = {
    handleInputChange: (event) =>
      handleSalesPeriodInputChange({
        event: {
          ...event,
          target: {
            ...event.target,
            value: toCents(event.target.value),
            name: event.target.name,
          },
        },
        salesPeriodId,
      }),
    type: 'text',
    size: 'full',
  };

  const quotaInputProps = {
    ...commonTextInputProps,
    disabled: !hadQuota,
    name: 'quotaAmountCents',
    value: (quotaAmount || quotaAmount === 0) && hadQuota ? toDollars(quotaAmount) : '',
    placeholder: '',
    moneyInput: true,
  };

  const renewalPercentInputProps = {
    ...commonTextInputProps,
    name: 'renewalRetentionPercentage',
    value:
      renewalRetentionPercentage || renewalRetentionPercentage === 0
        ? Math.floor((renewalRetentionPercentage * 1000) / 10)
        : '',
    placeholder: '',
    type: 'number',
    min: '0',
    max: '100',
    percentInput: true,
    handleOnBlur: () => trackEvent({ name: 'renewalRetentionPercentage' }),
    handleInputChange: (event) =>
      handleSalesPeriodInputChange({
        event: {
          ...event,
          target: {
            ...event.target,
            value: event.target.value || event.target.value === 0 ? event.target.value / 100 : null,
            name: event.target.name,
          },
        },
        salesPeriodId,
      }),
  };

  const options = [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ];

  const hadQuotaProps = {
    options,
    name: 'hadQuota',
    value: hadQuota,
    handleInputChange: (event) =>
      handleSalesPeriodInputChange({
        event,
        salesPeriodId,
      }),
  };

  const companiesSupportedInputProps = {
    ...commonTextInputProps,
    handleInputChange: (event) =>
      handleSalesPeriodInputChange({
        event,
        salesPeriodId,
      }),
    placeholder: '',
    size: 'small',
    type: 'number',
    min: '0',
    max: '10000',
    name: 'companiesSupported',
    value: companiesSupported || companiesSupported === 0 ? companiesSupported : '',
  };

  return (
    <div className={styles.dealInfoContainer}>
      <div className={styles.containerTitle}>DEAL INFO</div>

      <div className={styles.rowInputs}>
        <div className={styles.columnInputs}>
          <Label standalone required className={styles.label}>
            Did you have a quota?
          </Label>
          <InputRadio {...hadQuotaProps} />
        </div>
        <div className={styles.columnInputs}>
          <Label className={styles.label} disabled={!hadQuota}>
            Annual Quota ($)
          </Label>
          <Input {...quotaInputProps} />
        </div>
        <div className={styles.columnInputs}>
          <Label required className={styles.label}>
            Renewal/Retention Rate
          </Label>
          <Input {...renewalPercentInputProps} />
        </div>
        <div className={styles.columnInputs}>
          <Label required className={styles.label}>
            # of Customers Supported
          </Label>
          <Input {...companiesSupportedInputProps} />
        </div>
      </div>
    </div>
  );
};

export default CSMDealInfo;
