import { createSelector } from 'reselect';
import { entitySelectorFactory } from 'reduxStore/selectorFactories';

const selectCandidate = createSelector(
  entitySelectorFactory('toolboxCandidates', 'candidate'),
  (state, props) => {
    const { match: { params: { id } = {} } = {} } = props || {};

    return id;
  },
  (candidates, id) => {
    const { byId: { [id]: candidate = {} } = {} } = candidates;

    return candidate;
  }
);

export default selectCandidate;
