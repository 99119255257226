import { useState, useEffect, useRef, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { entityLoadStarted } from 'shared/store/app/creators';

import { createRoute, queryStringParse } from 'utils/paramUtils';
import { toggleArray } from 'utils/formUtils';

export function useTableQuery(
  route,
  page,
  params,
  defaultLoadArgs,
  extraParams = {},
  queryBuilders = {}
) {
  const location = useLocation();
  const { search } = location;

  const dispatch = useDispatch();
  const newRoute = useRef(null);
  const [queryParams, setQueryParams] = useState(queryStringParse({ search, params }));

  const defaultParams = useMemo(
    () =>
      Object.keys(params).reduce((acc, curr) => {
        const {
          [curr]: { defaultValue },
        } = params;

        acc[curr] = defaultValue;

        return acc;
      }, {}),
    [params]
  );

  const builtQueryParams = Object.fromEntries(
    Object.entries(queryParams).map(([param, value]) => [
      param,
      queryBuilders[param] ? queryBuilders[param](value) : value,
    ])
  );

  const loadParams = {
    ...defaultParams,
    ...builtQueryParams,
    ...extraParams,
    page,
  };

  useEffect(() => {
    dispatch(
      entityLoadStarted({
        ...defaultLoadArgs,
        params: {
          ...loadParams,
          page: 1,
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (newRoute.current) {
      dispatch(
        entityLoadStarted({
          ...defaultLoadArgs,
          newRoute: newRoute.current,
          params: {
            ...loadParams,
            page: 1,
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const loadMore = () => {
    dispatch(
      entityLoadStarted({
        ...defaultLoadArgs,
        params: {
          ...loadParams,
          page: page + 1,
        },
      })
    );
  };

  const handleSortChange = (value) => {
    const { sortBy } = queryParams;

    if (sortBy && sortBy.includes(value)) {
      const newDirection = sortBy.includes('asc') ? '_desc' : '_asc';

      const newSort = `${value}${newDirection}`;

      newRoute.current = createRoute({
        route,
        location,
        name: 'sortBy',
        value: newSort,
      });

      setQueryParams((queryParams) => ({
        ...queryParams,
        sortBy: newSort,
      }));
    } else {
      const newSort = `${value}_asc`;

      newRoute.current = createRoute({
        route,
        location,
        name: 'sortBy',
        value: newSort,
      });

      setQueryParams((queryParams) => ({
        ...queryParams,
        sortBy: `${value}_asc`,
      }));
    }
  };

  const handleSearchFilterChange = ({ target: { name, value } }) => {
    const { [name]: arrayToUpdate } = queryParams;

    const actualValue = value[value.type];

    const newArray = toggleArray({ array: arrayToUpdate, value: actualValue });

    newRoute.current = createRoute({
      route,
      location,
      name,
      value: newArray,
    });

    setQueryParams((queryParams) => ({
      ...queryParams,
      [name]: newArray,
    }));
  };

  const handleSearchInputChange = ({ target: { name, value: origValue } }) => {
    const value = origValue || params[name].defaultValue || null;

    newRoute.current = createRoute({
      route,
      location,
      name,
      value,
    });

    setQueryParams((queryParams) => ({
      ...queryParams,
      [name]: value,
    }));
  };

  const handleSearchCheckboxChange = ({ target: { name, checked } }) => {
    const value = checked || null;

    newRoute.current = createRoute({
      route,
      location,
      name,
      value,
    });

    setQueryParams((queryParams) => ({
      ...queryParams,
      [name]: value,
    }));
  };

  return {
    queryParams,
    loadMore,
    handleSortChange,
    handleSearchFilterChange,
    handleSearchInputChange,
    handleSearchCheckboxChange,
  };
}
