import { createSelector } from 'reselect';
import { entitySelectorFactory } from 'reduxStore/selectorFactories';
import defaultErrors from 'reduxStore/errors';

const selectUserErrors = createSelector(
  entitySelectorFactory('toolboxEmployers', 'employee'),
  entitySelectorFactory('toolboxEmployers', 'user'),
  (state, props) => {
    const { match: { params: { employeeId: id } = {} } = {} } = props || {};

    return id;
  },
  (employees, users, id) => {
    const {
      byId: { [id]: { relationships: { user: { data: userIds = [] } = {} } = {} } = {} } = {},
    } = employees;

    const { byId: { [userIds[0]]: { errors } = {} } = {} } = users;

    return errors || defaultErrors.users;
  }
);

export default selectUserErrors;
