import { call, put, takeLatest } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { deleteEntity } from 'api/apiEntity';

import { WORK_HISTORY_DELETE_STARTED } from '../actions';

import {
  candidateUpdate,
  candidateSaveStarted,
  candidateSaveDone,
  candidateSaveError,
} from '../creators';

const deleteWorkHistory = function* deleteWorkHistory(action) {
  const {
    payload: { workHistoryId, newWorkHistories },
  } = action;

  yield put(candidateSaveStarted());

  if (newWorkHistories) {
    yield put(candidateUpdate({ newValue: newWorkHistories, name: 'workHistories' }));
    yield put(candidateSaveDone());
  }

  if (workHistoryId) {
    try {
      const args = {
        id: workHistoryId,
        type: 'work_histories',
      };

      yield call(deleteEntity, args);
      yield put(candidateSaveDone());
    } catch (error) {
      handleError(error);
      yield put(candidateSaveError(error));
    }
  }
};

const sagaWorkHistoryDeleteStarted = function* sagaWorkHistoryDeleteStarted() {
  yield takeLatest(WORK_HISTORY_DELETE_STARTED, deleteWorkHistory);
};

export default sagaWorkHistoryDeleteStarted;
