import { call, put, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { handleError } from 'utils/common';
import { patchEntity } from 'api/apiEntity';

import { DEFAULT_LOGIN_LINKS, ONBOARDING_ROUTES_MAP } from 'connectors/Login/constants';

import { CONFIRM_PASSWORD_STARTED } from 'connectors/NewPassword/redux/actions';

import { newPasswordErrorReset } from 'connectors/NewPassword/redux/creators';

import {
  resetPasswordError,
  resetPasswordErrorReset,
} from 'connectors/ResetPassword/redux/creators';

import {
  adminLoginDone,
  candidateLoginDone,
  employeeLoginDone,
  loginNotification,
  removeRequestInterceptor,
} from 'shared/store/app/creators';

const sagaConfirmPasswordStarted = function* sagaConfirmPasswordStarted() {
  while (true) {
    const {
      payload: { token, password, passwordConfirmation, requisitionOpeningId },
    } = yield take(CONFIRM_PASSWORD_STARTED);

    yield put(newPasswordErrorReset());
    yield put(resetPasswordErrorReset());

    try {
      const args = {
        type: 'auth/confirmation',
        data: {
          type: 'users',
          attributes: {
            password,
            passwordConfirmation,
            confirmationToken: token,
          },
        },
        config: {
          params: {
            includeSet: 'login',
            camelizeIt: true,
          },
        },
      };

      const {
        data: {
          user,
          user: { currentProfileType: type, currentProfile: { onboardingStatus } = {} } = {},
        },
        headers,
      } = yield call(patchEntity, args);

      yield put(removeRequestInterceptor());

      if (user && headers) {
        const { [type]: defaultRoute } = DEFAULT_LOGIN_LINKS;

        if (type === 'Candidate') {
          if (requisitionOpeningId) {
            const autoLoginPath = {
              pathname: `/openings/${requisitionOpeningId}`,
            };

            yield put(
              candidateLoginDone({
                user,
                headers,
                autoLoginPath,
              })
            );
          } else {
            const { [onboardingStatus]: route } = ONBOARDING_ROUTES_MAP;

            if (route) {
              yield put(push(route));
            } else {
              yield put(push(defaultRoute));
            }
            yield put(candidateLoginDone({ user, headers }));
          }
        }

        if (type === 'Employee') {
          yield put(push(defaultRoute));

          yield put(employeeLoginDone({ user, headers }));
        }

        if (type === 'RainmakersAdmin') {
          yield put(push(defaultRoute));

          yield put(adminLoginDone({ user, headers }));
        }
      } else {
        yield put(
          loginNotification({
            notification: 'Your password has been successfully updated. Please login',
          })
        );
        yield put(push('/login/candidate'));
      }
    } catch (error) {
      handleError(error);
      const {
        response: {
          data: { errors: { full_messages: errors = ['Please try again'] } = {} } = {},
        } = {},
      } = error || {};

      yield put(resetPasswordError({ errors }));

      yield put(push('/reset-password'));
    }
  }
};

export default sagaConfirmPasswordStarted;
