import { handleError } from 'utils/common';
import { getSearch } from 'api/apiSearch';

const promiseSearch = ({ allSearchParams, contextOrigin }) => {
  const params = {
    contextOrigin,
    includeSet: 'search',
    normalizeIt: true,
  };

  const config = { params };

  const onFulfilled = (response) => response;

  const getSearchArgs = {
    allSearchParams,
    config,
  };

  return getSearch(getSearchArgs).then(onFulfilled).catch(handleError);
};

export default promiseSearch;
