import { call, select, put, take } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { handleError } from 'utils/common';

import { selectUser } from 'connectors/User/redux/selectors';

import { batchRequestLoadDone } from '../creators';

import { BATCH_REQUEST_REREQUEST_STARTED } from '../actions';

import { promisePatchBatchRequest } from '../../promises';

const sagaBatchRequestRerequestStarted = function* sagaBatchRequestRerequestStarted() {
  while (true) {
    yield take(BATCH_REQUEST_REREQUEST_STARTED);

    const {
      currentProfile: { id: candidateId },
    } = yield select(selectUser);

    try {
      const promiseParams = {
        candidateId,
        dismissed: true,
      };

      yield call(promisePatchBatchRequest, promiseParams);

      yield call(delay, 200);

      const newPromiseParams = {
        candidateId,
        state: 'pending',
      };

      const newBatchRequest = yield call(promisePatchBatchRequest, newPromiseParams);

      yield put(batchRequestLoadDone({ batchRequest: newBatchRequest }));
    } catch (error) {
      handleError(error);
    }
  }
};

export default sagaBatchRequestRerequestStarted;
