import camelCase from 'lodash.camelcase';
import { singularize } from 'inflection';

const IGNORE_SINGULARIZE_TYPES = [
  'unverifiedEducationOrganizationData',
  'unverifiedCompanyData',
  'privateCanReqFeedback',
  'search',
];

export const formatType = (type) => {
  if (!IGNORE_SINGULARIZE_TYPES.includes(type)) {
    return camelCase(singularize(type));
  }
  return type;
};
