import React from 'react';
import { v4 } from 'uuid';
import classnames from 'classnames';

import styles from './InputErrors.scss';

const InputErrors = ({ errors, className }) => {
  const inputErrorsClasses = classnames(styles.InputErrors, className);

  const errorStrings = errors
    ? Object.keys(errors).map((errorName) => {
        if (errors[errorName][0] === false) {
          switch (errorName) {
            case 'isNotEmpty':
              return 'must not be blank';
            case 'isNumeric':
              return 'must be a number';
            case 'isMoney':
              return 'must be valid amount';
            case 'minLength':
              return `must have at least ${errors[errorName][1]} characters`;
            case 'hasXWords':
              return `must have at least ${errors[errorName][1]} words`;
            case 'hasXElements':
              return `must have at least ${errors[errorName][1]} selection`;
            case 'doesNotEqual':
              return `must not be ${errors[errorName][1].join(' or ')}`;
            case 'doesEqual':
              return 'Passwords must match';
            case 'isGreaterThanX':
              return `must be greater than ${errors[errorName][1]}`;
            case 'isLessThanX':
              return `must be less than ${errors[errorName][1]}`;
            case 'isMoneyLessThanX':
              return `must be less than $${(errors[errorName][1] / 100).toLocaleString()}`;
            case 'isMoneyGreaterThanX':
              return `must be greater than $${(errors[errorName][1] / 100).toLocaleString()}`;
            case 'isEmail':
              return 'must be a valid email address';
            case 'isUrl':
              return 'must be a valid URL';
            case 'isPhoneNumber':
              return 'must be a valid phone number';
            case 'hasANumber':
              return 'must include one numeric character';
            case 'isAvailable':
              return `${errors[errorName][1]} is already taken`;
            case 'autocompleteError':
              return 'Problem getting suggestions';
            case 'isPassword':
              return 'Password is invalid';
            case 'isValid':
              return `${errors[errorName][1]} is invalid`;
            default:
              return null;
          }
        }
        return null;
      })
    : null;

  const errorsContent = errorStrings
    ? errorStrings
        .map((error) =>
          error ? (
            <div key={v4()} className={styles.errorMessage}>
              {error}
            </div>
          ) : null
        )
        .filter((error) => error !== null)[0]
    : null;

  return <div className={inputErrorsClasses}>{errorsContent}</div>;
};

export default InputErrors;
