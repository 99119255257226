import React from 'react';

import { toCents, toDollars } from 'utils/moneyUtils';

import Input from 'components/Input';
import Label from 'components/Label';
import DropdownSelectMany from 'components/DropdownSelectMany';
import DropdownUsRegions from 'components/DropdownUsRegions';
import { WorkModelSelectMany } from 'components/WorkModelSelectMany';

import styles from './CandReqInputs.scss';

const CandReqInputs = ({
  candReq,
  handleToggleRegions,
  handleCandReqInputChange,
  handleCandReqRoleChange,
  handleCandReqWorkModelChange,
  pickerOptions = {},
}) => {
  const {
    oteCents = '',
    salaryCents = '',
    locations = [],
    roles = [],
    locationNotes = '',
    workModel = null,
  } = candReq;

  const { role: roleOptions = [], candReqLocations: locationSource = [] } = pickerOptions || {};

  const rolesOptions = roleOptions.map((role) => ({
    indent: 0,
    label: role,
    optionType: 'role',
    subCategories: [],
    value: {
      type: 'role',
      role,
    },
  }));

  const selectedRoles = roles.map((role) => ({ role, type: 'role' }));

  const rolesInputProps = {
    className: styles.searchBarFilter,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    onChange: (value) =>
      handleCandReqRoleChange({
        target: {
          subCategories: value.subCategories,
          label: value.label,
          value: value.value,
          name: 'roles',
          optionType: value.optionType,
        },
      }),
    optionsLarge: true,
    source: rolesOptions,
    name: 'roles',
    key: 'roles',
    isLocation: true,
    selectedValues: selectedRoles || '',
    defaultLabel: 'Click to select a role',
  };

  const locationOptions = locationSource.reduce((acc, curr) => {
    let subOpts = [];
    if (curr.subcategories) {
      subOpts = curr.subcategories.map((subOpt) => ({
        indent: 1,
        label: subOpt.name,
        optionType: subOpt.type,
        value: {
          type: subOpt.type,
          [subOpt.type]: subOpt.name,
        },
      }));
    }

    const allOpts = Array.prototype.concat(
      [
        {
          indent: 0,
          label: curr.name,
          subCategories: subOpts,
          optionType: curr.type,
          value: {
            type: curr.type,
            [curr.type]: curr.name,
          },
        },
      ],
      subOpts
    );

    return Array.prototype.concat(acc, allOpts);
  }, []);

  const locationInputProps = {
    className: styles.searchBarFilter,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    onChange: (value) =>
      handleToggleRegions({
        target: {
          label: value.label,
          name: 'locations',
          optionType: value.optionType,
          subCategories: value.subCategories,
          value,
        },
      }),
    optionsLarge: true,
    source: locationOptions.map((locOpt) => ({ ...locOpt, value: locOpt.value.region })),
    name: 'locations',
    key: 'locations',
    isLocation: true,
    selectedValues: locations.map((loc) => loc.region) || '',
    defaultLabel: 'Click to select a region',
    pillsPlacement: 'select-many',
    closeOnSelect: true,
  };

  const salaryInputProps = {
    size: 'full',
    name: 'salaryCents',
    oteInputOne: true,
    value: salaryCents || salaryCents === 0 ? toDollars(salaryCents) : '',
    placeholder: '',
    moneyInput: true,
    className: styles.oteInputContainer,
    handleInputChange: (event) =>
      handleCandReqInputChange({
        ...event,
        target: {
          ...event.target,
          value: toCents(event.target.value),
          name: event.target.name,
        },
      }),
  };

  const oteInputProps = {
    size: 'full',
    name: 'oteCents',
    oteInputOne: true,
    value: oteCents || oteCents === 0 ? toDollars(oteCents) : '',
    placeholder: '',
    moneyInput: true,
    className: styles.oteInputContainer,
    handleInputChange: (event) =>
      handleCandReqInputChange({
        ...event,
        target: {
          ...event.target,
          value: toCents(event.target.value),
          name: event.target.name,
        },
      }),
  };

  const locationNotesInputProps = {
    type: 'textarea',
    size: 'full',
    rows: '3',
    handleInputChange: handleCandReqInputChange,
    name: 'locationNotes',
    value: locationNotes,
    className: styles.oteInputContainer,
    placeholder: '"I am open to anywhere in the Bay Area" e.g.',
  };

  return (
    <div className={styles.basicInfoContainer}>
      <div className={styles.basicInfoColumnInputs}>
        <div className={styles.formRow}>
          <Label required className={styles.label}>
            Desired Base Salary
          </Label>
          <Label required className={styles.label}>
            Desired OTE
          </Label>
        </div>
        <div className={styles.formRow}>
          <Input {...salaryInputProps} />
          <Input {...oteInputProps} />
        </div>

        <div className={styles.formRow}>
          <div className={styles.bottomLeft}>
            <Label required className={styles.label}>
              What region are you located in?
            </Label>
            <DropdownUsRegions {...locationInputProps} />

            <Label required className={styles.label}>
              What kind of roles are you looking for?
            </Label>
            <DropdownSelectMany {...rolesInputProps} />
          </div>
          <div className={styles.bottomLeft}>
            <WorkModelSelectMany workModel={workModel} onChange={handleCandReqWorkModelChange} />

            <Label className={styles.label}>Notes</Label>
            <Input {...locationNotesInputProps} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandReqInputs;
