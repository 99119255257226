import React, { useEffect } from 'react';
import { Portal } from 'react-portal';
import classnames from 'classnames';

import styles from './Overlay.scss';

const Overlay = ({ children, isOpened, noOverlay }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  const backdropClasses = classnames(styles.backdrop, {
    [styles.backdropActive]: isOpened && !noOverlay,
  });

  return (
    <Portal isOpened={isOpened}>
      <div className={styles.overlay}>
        <div className={backdropClasses}>{children}</div>
      </div>
    </Portal>
  );
};

export default Overlay;
