import { createStructuredSelector } from 'reselect';

import {
  selectIsLoggedIn,
  selectIsCandidate,
  selectIsAdmin,
  selectLogoutPending,
} from 'connectors/Login/redux/selectors';

import { getApprovedStatus, getHasSeenApproved } from 'shared/store/app/selectors';

import {
  selectCandidate,
  selectCandidateUser,
} from '../../../CandidateEditorPages/redux/selectors';

import selectCandidateCompanyBlocks from './selectCandidateCompanyBlocks';
import selectCandidateCompanyBlocksPage from './selectCandidateCompanyBlocksPage';
import selectCandidateCompanyBlocksTotal from './selectCandidateCompanyBlocksTotal';
import selectCandidateCompanyBlocksLoading from './selectCandidateCompanyBlocksLoading';
import selectCandidateCompanyBlocksErrors from './selectCandidateCompanyBlocksErrors';

const selector = createStructuredSelector({
  candidate: selectCandidate,
  user: selectCandidateUser,
  logoutPending: selectLogoutPending,
  isApproved: getApprovedStatus,
  isLoggedIn: selectIsLoggedIn,
  isAdmin: selectIsAdmin,
  isCandidate: selectIsCandidate,
  hasSeenApproved: getHasSeenApproved,
  candidateCompanyBlocks: selectCandidateCompanyBlocks,
  candidateCompanyBlocksPage: selectCandidateCompanyBlocksPage,
  candidateCompanyBlocksTotal: selectCandidateCompanyBlocksTotal,
  candidateCompanyBlocksLoading: selectCandidateCompanyBlocksLoading,
  candidateCompanyBlocksErrors: selectCandidateCompanyBlocksErrors,
});

export {
  selectCandidateCompanyBlocks,
  selectCandidateCompanyBlocksPage,
  selectCandidateCompanyBlocksTotal,
  selectCandidateCompanyBlocksLoading,
  selectCandidateCompanyBlocksErrors,
  selector as default,
};
