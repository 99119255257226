import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { handleError } from 'utils/common';

import ConnectContainer from 'containers/ConnectContainer';
import { CandidateAuthHOC } from 'containers/HOC';

import { promiseGetFullCandidate } from 'pages/EmployeePortal/CandidateProfilePages/promises';

import ReadyContainer from 'connectors/ReadyContainer';

import Sidebar from 'components/Sidebar';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';

import CandidateProfile from 'pages/EmployeePortal/CandidateProfilePages/CandidateProfile';

import duck from '../redux';

import styles from './EditPreview.scss';

const EditPreview = (props) => {
  useEffect(() => {
    const {
      actions: { pickerOptionsLoadStarted },
      candidate: { id: candidateId } = {},
    } = props;

    promiseGetFullCandidate({ candidateId }).then(onCandidateSuccess).catch(handleError);

    pickerOptionsLoadStarted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCandidateSuccess = (candidateProfile) => {
    const {
      actions: { candidateProfilePatchDone },
    } = props;

    candidateProfilePatchDone({ candidateProfile });
  };

  const {
    candidate: candidateProfile = {},
    router,
    modal,
    actions: { showModal },
    isLoggedIn,
    isEmployee,
  } = props;

  return (
    <>
      <Helmet title="Candidate Editor" />
      <ReadyContainer className={styles.CandidateEditorPreview}>
        <Sidebar type={'candidateEditor'} page={'edit-preview'} candidate={candidateProfile} />
        <LayoutWithoutSidebar
          content={
            <CandidateProfile
              candidateProfile={candidateProfile}
              candidateProfileReqs={[]}
              actions={{
                showModal,
              }}
              router={router}
              modal={modal}
              isLoggedIn={isLoggedIn}
              isEmployee={isEmployee}
            />
          }
        />
      </ReadyContainer>
    </>
  );
};

export default ConnectContainer(duck)(CandidateAuthHOC()(EditPreview));
