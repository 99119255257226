import React from 'react';
import cn from 'classnames';

import EmptyCompanyLogo from 'shared/assets/missing-company-picture.png';

import commonStyles from '../../CandidateTurboProfile.scss';
import styles from './EducationSection.scss';

export const EducationSection = ({ data }) => {
  const { candidateEducations = [] } = data || {};

  const educationsCombined = candidateEducations.reduce((acc, education) => {
    const {
      educationOrganization: { id },
    } = education;
    const existing = acc.find(({ educationOrganization: { id: existingId } }) => existingId === id);

    if (existing) {
      const { degree } = education;
      existing.degree = [...existing.degree, degree];
    } else {
      acc.push({
        ...education,
        degree: [education.degree], // degree is now always an array
      });
    }

    return acc;
  }, []);

  return (
    <div className={styles.educationSectionContainer}>
      {educationsCombined.length > 0 && (
        <>
          <h2>Education</h2>
          <div className={styles.educationData}>
            {educationsCombined.map(({ degree, educationOrganization: { id, name, picture } }) => {
              const { file_data, ['xlarge']: pictureUrl = '' } = picture;
              const picUrl =
                pictureUrl ||
                (typeof file_data === 'string' && file_data.length > 0 ? file_data : null) ||
                EmptyCompanyLogo;

              return (
                <React.Fragment key={id}>
                  <div className={styles.centered}>
                    <img alt="image info" src={picUrl} />
                  </div>
                  <div className={cn(styles.centered, commonStyles.bodyBold)}>{name}</div>
                  {degree.map((degreeStr, index) => (
                    <React.Fragment key={index}>
                      <div />
                      <div>{degreeStr}</div>
                    </React.Fragment>
                  ))}
                  <div className={styles.companyDelimiter} />
                  <div />
                </React.Fragment>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
