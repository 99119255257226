import { call, put, select, cancel, take, fork } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { handleError } from 'utils/common';

import { patchEntity } from 'api/apiEntity';

import { selectUser } from 'connectors/User/redux/selectors';

import { EMPLOYEE_PATCH_STARTED } from '../actions';

import {
  employeeUpdate,
  employeeSaveStarted,
  employeeSaveDone,
  employeeSaveError,
} from '../creators';

const patchEmployee = function* patchEmployee(action) {
  const {
    payload: { newValue, name, noUpdate = false },
  } = action;

  if (!noUpdate) {
    yield put(employeeUpdate({ newValue, name }));
  }

  yield put(employeeSaveStarted());

  yield call(delay, 750);

  const { currentProfile: { id: employeeId } = {} } = yield select(selectUser);

  const attributes = {
    [name]: newValue,
  };

  try {
    const args = {
      data: {
        id: employeeId,
        type: 'employees',
        attributes,
      },
      id: employeeId,
      type: 'employees',
      config: {
        params: {
          camelizeIt: true,
        },
      },
    };

    const {
      data: { employee },
    } = yield call(patchEntity, args);

    if (!noUpdate) {
      yield put(employeeUpdate({ name, newValue: employee[name] }));
    }
    yield put(employeeSaveDone());
  } catch (error) {
    handleError(error);

    yield put(employeeSaveError(error));
  }
};

const sagaEmployeePatchStarted = function* sagaEmployeePatchStarted() {
  let lastTask;
  let lastAction;
  while (true) {
    const action = yield take(EMPLOYEE_PATCH_STARTED);
    if (lastTask && lastAction.payload.name === action.payload.name) {
      yield cancel(lastTask);
    }
    lastAction = action;
    lastTask = yield fork(patchEmployee, action);
  }
};

export default sagaEmployeePatchStarted;
