import { useEffect, useState } from 'react';

// scheduleData:
// [
//   {
//     timeout: <ms>,
//     data: <any kind of data>,
//   },
//   ...
//   {
//     timeout: <ms>,
//     data: <any kind of data>,
//   },
// ]

// fnToCall:
// args:
// - step number
// - step data
// return val:
// - true - keep processing
// - false - stop processing

export const usePeriodicAction = (scheduleData, fnToCall, enableProcessing) => {
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    setCurrentStep(0);
  }, [enableProcessing]);

  useEffect(() => {
    if (!enableProcessing) return;

    const { timeout, data } = scheduleData[currentStep] || {};

    const res = fnToCall(currentStep, data);

    if (res && timeout) {
      setTimeout(() => {
        setCurrentStep((step) => step + 1);
      }, timeout);
    }
  }, [enableProcessing, fnToCall, scheduleData, currentStep]);
};
