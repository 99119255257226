import React, { Component } from 'react';

import DialogueModal from 'components/DialogueModal';
import Button from 'components/Button';
import Content from 'components/Content';

import styles from './CohortCollisionsEmployerModal.scss';

class CohortCollisionsEmployerModal extends Component {
  constructor(props) {
    super(props);

    const collisionsToCheck = props.collisions || [];

    const employerIds = collisionsToCheck.map((coll) => coll.employer_id);

    const uniqEmployerIds = employerIds.filter((id, idx, self) => self.indexOf(id) === idx);

    const collisionsByEmp = uniqEmployerIds.map((id) => {
      const cohortLinks = collisionsToCheck
        .filter((coll) => coll.employer_id === id)
        .map((coll) => coll.cohort_links);
      return Array.prototype.concat.apply([], cohortLinks);
    });

    this.state = {
      collisionsToCheck: collisionsByEmp,
    };
  }

  render() {
    const { handleCloseClick, cohortId, cohortName = '' } = this.props;

    const { collisionsToCheck = [] } = this.state;

    const continueButtonProps = {
      onClick: handleCloseClick,
      primary: true,
    };

    const continueButton = <Button {...continueButtonProps}>Continue</Button>;

    const collisionsContent = collisionsToCheck.map((coll, idx) => {
      const filteredLinks = coll.filter((link) => link.cohort_id !== cohortId);

      const { employer_name: employerName } = coll[0];

      const cohorts = filteredLinks.map((link) => link.cohort_name);
      const uniqCohorts = cohorts.filter((cohort, idx, self) => self.indexOf(cohort) === idx);
      const cohortContent = uniqCohorts.map((link) => {
        const linkProps = {
          className: styles.cohortLink,
        };

        return (
          <div key={link} {...linkProps}>
            {link}
          </div>
        );
      });

      return (
        <div key={idx} className={styles.cohortLinkContainer}>
          <div className={styles.linkContainer}>
            <div className={styles.candidate}>{employerName}</div>
            <div className={styles.links}>{cohortContent}</div>
          </div>
        </div>
      );
    });

    const name = cohortName ? ` for ${cohortName}` : '';

    const title = `Collisions${name}`;

    const instructions = (
      <Content className={styles.subtitle}>
        The following employers could not be added due to collisions in the cohorts listed. Please
        review and try again once you have removed the employer from the other cohort or otherwise
        fixed the collisions.
      </Content>
    );

    return (
      <DialogueModal>
        <div className={styles.requestConfirmationModal}>
          <div className={styles.requestConfirmationTitle}>{title}</div>
          {instructions}
          <div className={styles.header}>
            <div className={styles.headerContent}>Employer</div>
            <div className={styles.headerContent}>Cohort/s</div>
          </div>
          <div className={styles.requestConfirmationContent}>{collisionsContent}</div>
          <div className={styles.requestConfirmationButtons}>{continueButton}</div>
        </div>
      </DialogueModal>
    );
  }
}

export default CohortCollisionsEmployerModal;
