import { call, put, take, fork } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';

import { handleError } from 'utils/common';

import {
  promisePostMessage,
  promisePostRequisitionCandidates,
  promisePatchRequisitionOpening,
  promisePatchRequisitionOpeningCopy,
} from 'pages/EmployeePortal/CandidateProfilePages/promises';

import {
  requestSentDone,
  requestErrorSet,
  addRequestedCandidate,
} from 'pages/EmployeePortal/CandidateSearchPage/redux/creators';

import { MESSAGE_CREATE_STARTED } from '../actions';

const sagaIntroMessageInfo = function* sagaSentryReport(data) {
  yield call(Sentry.captureMessage, 'Intro Message Info', { extra: data });
};

const messageCompose = ({ message, messageHtml, salutation }) => {
  const salutationMarkdown = !salutation || salutation === '<None>' ? '' : `_${salutation},_`;
  const salutationHtml = !salutation || salutation === '<None>' ? '' : `${salutation},`;

  const messageContentMarkdown = message
    ? `
  ${salutationMarkdown} \n
  ${message.trim()}
  `
    : '';

  const messageContentHtml =
    messageHtml && messageHtml !== '<p><br></p>'
      ? `
  <p>${salutationHtml}</p>
  <p>${messageHtml}</p>
  `
      : '';

  const markdown = messageContentHtml ? messageContentMarkdown : '';

  return { html: messageContentHtml, markdown };
};

const sagaSendMessage = function* sagaSendMessage({
  payload: {
    candidateId,
    employerName = '',
    firstName = '',
    lastName = '',
    message = '',
    messageHtml = '',
    salutation = '',
  } = {},
  messageGroups,
}) {
  try {
    yield put(addRequestedCandidate({ candidateId }));

    const { html = '', markdown = '' } = yield call(messageCompose, {
      message,
      messageHtml,
      salutation,
    });

    const promiseMessageParams = {
      subject: `<Rainmakers> ${employerName} / ${firstName} ${lastName} Interview Request`,
      message: markdown,
      messageHtml: html,
      messageGroupId: messageGroups[0].id,
      template: 'linked_obj_req_can',
    };

    yield call(promisePostMessage, promiseMessageParams);

    yield put(
      requestSentDone({
        show: true,
        firstName,
        lastName,
      })
    );
  } catch (error) {
    handleError(error);

    const errorMessage = `Your request for introduction to ${firstName} has been processed but we could not send your custom message at this time. Please contact support@rainmakers.co to send a message to ${firstName}`;

    yield put(
      requestErrorSet({
        show: true,
        firstName,
        lastName,
        errorMessage,
      })
    );
  }
};

const sagaMessageCreateStarted = function* sagaMessageCreateStarted() {
  while (true) {
    const {
      payload,
      payload: {
        successCallback,
        candidateId,
        category,
        firstName = '',
        lastName = '',
        oteMaxCents,
        oteMinCents,
        requisition: { state } = {},
        reqId = '',
      },
    } = yield take(MESSAGE_CREATE_STARTED);

    yield fork(sagaIntroMessageInfo, payload);

    try {
      const newReq = state === 'pending';

      const promiseParams = {
        origin: category,
        candidateId,
        reqId,
      };

      if (newReq) {
        const patchParams = {
          newValue: 'open',
          name: 'state',
          reqId,
        };

        yield call(promisePatchRequisitionOpening, patchParams);
      }

      const { data: { requisitionCandidate: { id: reqCandId, messageGroups = [] } = {} } = {} } =
        yield call(promisePostRequisitionCandidates, promiseParams);

      const copyParams = {
        data: {
          ote_min_cents: oteMinCents,
          ote_max_cents: oteMaxCents,
        },
        reqCandId,
      };

      yield call(promisePatchRequisitionOpeningCopy, copyParams);

      yield call(sagaSendMessage, {
        payload: {
          ...payload,
          reqCandId,
        },
        messageGroups,
      });

      yield successCallback && call(successCallback);
    } catch (error) {
      handleError(error);

      const errorMessage =
        'We could not process your request for introduction at this time. If the issue persists, please contact support@rainmakers.co';

      yield put(
        requestErrorSet({
          show: true,
          firstName,
          lastName,
          errorMessage,
        })
      );
    }
  }
};

export default sagaMessageCreateStarted;
