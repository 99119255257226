import React from 'react';
import { CSSTransition } from 'react-transition-group';

import Bold from 'components/Bold';
import Button from 'components/Button';

import styles from './AppErrorReporter.scss';

const AppErrorReporter = ({ resetAppError }) => {
  const reloadButtonProps = {
    primary: true,
    onClick: () => {
      resetAppError();
      return window.location.reload(true);
    },
    className: styles.buttonClass,
  };

  const dismissButtonProps = {
    warning: true,
    onClick: resetAppError,
    className: styles.buttonClass,
  };

  return (
    <CSSTransition appear={true} timeout={{ enter: 500, exit: 500 }} classNames={styles}>
      <div className={styles.errorMessage}>
        <div>
          <Bold>We&apos;re sorry — something&apos;s gone wrong.</Bold>
        </div>
        <div className={styles.buttons}>
          <Button {...dismissButtonProps}>Dismiss</Button>
          <Button {...reloadButtonProps}>Reload</Button>
        </div>
      </div>
    </CSSTransition>
  );
};

export default AppErrorReporter;
