/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import locPageMetaData, { LOCATIONS_PAGE_CHICAGO } from 'pages/LocationsPage/locationsPageMetaData';
import jobsPageMetaData, { JOB_PAGE_SDR } from 'pages/JobsPage/jobsPageMetaData';

import pic1 from './assets/1.png';
import pic2 from './assets/2.png';
import pic3 from './assets/3.png';
import pic4 from './assets/4.png';
import cta from './assets/CTA.png';

import styles from './ChicagoSDRContent.scss';

export function ChicagoSDRContent() {
  return (
    <div className={styles.container}>
      <img src={pic1} alt="" />
      <h2>Why Chicago is the Place to Be for SDRs</h2>
      <p>
        Sales Development Representatives (SDRs) are essential to the growth and maintenance of
        Chicago-area technology, communications, and fintech companies’ sales pipelines. They’re
        also contributing to new technology being developed in the region every day.
      </p>
      <p>
        The best, though, is that Chicago is a vibrant, lively city with a lot to offer anyone
        moving there to participate in their thriving tech industry. Chicago is home to world-famous
        sports teams, museums, and parks. Its cuisine is much more varied than pizza and hot dogs,
        and it has some of the best nightlife in the country. Chicago is also not far from some
        excellent opportunities for outdoor activities, from sailing and swimming to hiking and
        camping.
      </p>
      <p>
        To be a Sales Development Representative in Chicago is to embark on a profitable sales
        career in one of America’s premier historic cities.
      </p>

      <h2>Is Chicago a Good City for Tech Sales Jobs?</h2>
      <p>
        SDR job seekers in Chicago have access to employment with many tech-related companies,
        including fintech, IT, communications, and tech development, and can achieve considerable
        personal and professional growth.
      </p>
      <p>
        Rainmakers leads the market for hiring tech sales professionals with a transparent,
        data-driven platform that matches talented salespeople with the very best tech companies.
        <a target="_blank" rel="noopener noreferrer" href={jobsPageMetaData[JOB_PAGE_SDR].localUrl}>
          {' '}
          Sales development representatives{' '}
        </a>
        who show talent and a history of success are consequently matched with the opportunities
        that best meet their qualifications and experience.
      </p>

      <img src={pic2} alt="" />
      <h2>What Are a Sales Development Representative’s Responsibilities In Chicago?</h2>
      <p>
        The capability with which a salesperson can advance prospects through the sales funnel is
        the benchmark for a quality SDR. Therefore, they prioritize developing solid leads rather
        than conducting closings. In addition, SDRs are frequently the initial point of contact for
        new customers, so they must be able to effectively connect with and engage prospects via
        various channels, including research, cold calling, emails, and other marketing initiatives.
      </p>
      <p>
        In
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={locPageMetaData[LOCATIONS_PAGE_CHICAGO].localUrl}
        >
          {' '}
          Chicago
        </a>
        , to find and comprehend the demands of your customers, an SDR must strengthen their
        observational and listening abilities. It’s also essential for effective SDRs to organize
        meetings with account executives (AE) and cultivate connections with prospects to pitch them
        your business’s goods and services.
      </p>

      <h2>The Role of the SDR In the Sales Process</h2>
      <p>The process used by most sales teams follows this simple format:</p>
      <ol>
        <li>
          SDRs prospect and reach out to a list of leads, or the marketing division provides lead
          information to the SDRs.
        </li>
        <li>SDRs qualify leads and nurture them until they are ready to purchase.</li>
        <li>
          The AE then assumes control and places the business’s goods or services at the perfect
          time to complete the deal.
        </li>
      </ol>
      <p>
        Although most sales operations are based on this simple strategy, this does not imply that
        an SDR role will be easy or low-demanding. On the contrary, to be successful as an SDR,
        you’ll need a specific set of hard and soft skills.
      </p>

      <Link to="/apply-now" title="Apply Now">
        <img src={cta} alt="" />
      </Link>
      <h2>What Skills Are Needed to Be a Good SDR?</h2>
      <p>
        The position of Sales Development Representative is an entry-level position and usually
        includes training from the start. Here are some practical skills that SDRs should nurture to
        help their sales career be even more successful.
      </p>

      <h2>Building Effective Outreach Strategies</h2>
      <p>
        Prospecting clients while also balancing quantity and quality may be demanding. On the one
        hand, an SDR needs to build a solid pipeline for sales representatives. On the other hand,
        they must understand that it may take time to establish qualified leads. Effective
        salespeople discover that spending more time on outreach pays off—even if that means
        speaking with fewer potential customers.
      </p>
      <p>
        This requires mastering and applying effective outreach strategies, such as cold phoning,
        emailing, and leaving voicemails. SDRs learn which methods perform best with various
        prospects. In addition to practicing, SDRs must also be aware of and ready for their
        prospects’ most common objections.
      </p>

      <h3>Organization</h3>
      <p>
        While they vary from person to person, a process is crucial to keeping things organized. By
        controlling their calendars and duties, such as research, email outreach, calls, and
        meetings, SDRs can plan their days and prioritize the items essential to their success.
      </p>

      <h3>A Thick Skin</h3>
      <p>
        SDRs have a challenging job. While AEs close sales, SDRs don’t often experience that
        pleasure directly. Instead, their job often relies on frequent phone and email communication
        for much of the day. And more often than not, they receive many “no” responses when reaching
        out to potential clients.
      </p>
      <p>
        Being someone with a thick skin in these cases requires effort. The secret is to develop the
        ability to overcome each rejection and avoid letting tiny failures spoil your day.
      </p>

      <img src={pic3} alt="" />
      <h3>Relationship-Building</h3>
      <p>
        As part of their job, an SDR must know how to prepare a prospect for sale before passing
        them off to the account executive to close. Successful SDRs can build genuine rapport with
        prospects and gain their confidence.
      </p>

      <h3>Active Listening</h3>
      <p>
        Regardless of the company’s product or service, an SDR needs to be acutely aware of the
        words and phrases that signal a potential client might be a good fit for what is being
        offered. An effective SDR focuses on gathering information about the client and how their
        product or service can help solve a client’s problems.
      </p>

      <h3>Self-Awareness</h3>
      <p>
        Finally, an SDR should be aware of its advantages and disadvantages. Self-aware SDRs will
        ask for feedback from managers and coworkers, refrain from taking criticism personally, and
        have a clear understanding of their strengths, weaknesses, and possible growth areas.
      </p>

      <img src={pic4} alt="" />
      <h2>What Are the Opportunities for Growth and Advancement for SDRs?</h2>
      <p>
        SDRs can look at various roles and learn about their advantages and disadvantages. As part
        of their current position, SDRs conduct prospect research, create emails, schedule meetings,
        and cultivate connections. From there, they can decide whether to explore places that
        feature process and strategy, relationship-building, client-facing communication, or peer
        leadership. Their future sales career is often guided by an early understanding of their
        current skills and growing interests.
      </p>
      <p>
        Eight career options may become available to you after a few years of working as an SDR:
      </p>
      <ul>
        <li>Account Executive</li>
        <li>Customer Success</li>
        <li>Account Manager</li>
        <li>Marketing</li>
        <li>Channel Sales</li>
        <li>Strategic Accounts</li>
        <li>Sales Training</li>
        <li>Sales Operations</li>
      </ul>
      <p>
        To find out more about prospects in sales,{' '}
        <a href={`https://${process.env.HOST}/apply-now/`}>contact Rainmakers</a> today to get
        started on a guaranteed path to success.
      </p>
    </div>
  );
}
