import { createStructuredSelector } from 'reselect';

import { getModal } from 'shared/store/app/selectors';
import selectBackLink from 'connectors/User/redux/selectors/selectBackLink';

import selectIsReady from './selectIsReady';

const selector = createStructuredSelector({
  isReady: selectIsReady,
  modal: getModal,
  backLink: selectBackLink,
});

export { selectIsReady, selector as default };
