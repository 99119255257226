import { handleError } from 'utils/common';
import { getRainScore } from 'api/apiSearch';

const promiseGetRainScore = ({ candidateId, requisitionOpeningId }) => {
  const onFulfilled = (response) => {
    const { data: { data: { score = '' } } = {} } = response;

    const rainScore = `${Math.round(score * 100)}%`;

    return { rainScore };
  };

  const args = {
    config: {
      params: {
        requisitionOpeningId,
        candidateId,
      },
    },
  };

  return getRainScore(args).then(onFulfilled).catch(handleError);
};

export default promiseGetRainScore;
