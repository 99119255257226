import { getSearchAnalysis } from 'api/apiSearch';

const promiseGetSearchAnalysis = ({ candidateId, searchString }) => {
  const onFulfilled = (response) => {
    const { data: { data = {} } = {} } = response;

    return data;
  };

  const args = {
    searchString,
    id: candidateId,
    config: {},
  };

  return getSearchAnalysis(args).then(onFulfilled);
};

export default promiseGetSearchAnalysis;
